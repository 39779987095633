import React from 'react';
import { ICreateStageFormErrors, ICreateStageFormValues, IUpdateStageFormErrors, IUpdateStageFormValues } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { View } from 'react-native';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';

interface IStageForm {
	values: ICreateStageFormValues | IUpdateStageFormValues;
	errors: ICreateStageFormErrors | IUpdateStageFormErrors;
	onChange: (val: ICreateStageFormValues | IUpdateStageFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'stageform';

export const StageForm = (props: IStageForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('stage');
	const { renderFormField } = useForm();

	return (
		<View>
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) => renderFormField('stage', TESTIDPREFIX, values, errors, field, onChange))}
				</HSCard>
			))}
		</View>
	);
};
