import { Icon } from 'components/Icon';
import { EDefaultIconSet } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { View } from 'react-native';
import { H1 } from './H1';

interface IVoteAverage {
	value?: string | number;
	largerDesign?: boolean;
}

export const VoteAverage = (props: IVoteAverage) => {
	const { value, largerDesign } = props;
	const { theme } = useTheme();

	return (
		<View style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center', justifyContent: 'center' }}>
			<Icon name={EDefaultIconSet.Average} size={largerDesign ? 72 : 48} color={theme.primary} />
			<H1 noBottomMargin style={{ marginLeft: 5, fontSize: largerDesign ? 72 : 48, color: theme.primary }}>
				{value?.toString()}
			</H1>
		</View>
	);
};
