import { IAttendee, ILauncherSpace, IVote, IVoteAnswer, TVotingType } from 'config/interfaces';
import i18next from 'i18next';
import { isEmptyString } from './string';

export const getExportDataForVotingType = (
	votingType: TVotingType,
	vote: IVote,
	votes: IVote[],
	voteAnswers: IVoteAnswer[],
	attendees: IAttendee[],
	space: ILauncherSpace
) => {
	let columns: string[] = [
		'ID',
		i18next.t('firstName'),
		i18next.t('lastName'),
		i18next.t('email'),
		i18next.t('jobTitle'),
		i18next.t('company')
	];

	if (vote.allowAnonymousAnswers === 'always') {
		columns = ['ID'];
	}

	switch (votingType) {
		case 'quiz':
		case 'survey':
			vote.options?.forEach((e) => {
				columns.push(e.description ?? '');
			});
			break;
		case 'applause':
			vote.options?.forEach((e) => {
				columns.push(`${i18next.t('Option')} ${((e.order ?? 0) + 1)?.toString()}`);
			});
			break;
		default:
			columns.push(vote.question);
			if (votingType === 'nps' && vote.requestReason) columns.push(i18next.t('Reason'));
			if (votingType === 'pinOnImage' && !vote.useAvatar) columns.push(i18next.t('Icon'));
			break;
	}

	const data: any[] = voteAnswers.map((e) => {
		const attendee = attendees.find((e2) => e.userId === e2.userId);
		let row = {
			['ID']: e.userId,
			[i18next.t('firstName')]: attendee?.firstName ?? '',
			[i18next.t('lastName')]: attendee?.lastName ?? '',
			[i18next.t('email')]: attendee?.email ?? '',
			[i18next.t('jobTitle')]: attendee?.jobTitle ?? '',
			[i18next.t('company')]: attendee?.company ?? ''
		};

		if (vote.allowAnonymousAnswers === 'always' || (vote.allowAnonymousAnswers === 'optin' && e.isAnonymousSubmission)) {
			row['ID'] = i18next.t('AnonymousUser');
			if (vote.allowAnonymousAnswers !== 'always') {
				row[i18next.t('firstName')] = '';
				row[i18next.t('lastName')] = '';
				row[i18next.t('email')] = '';
				row[i18next.t('jobTitle')] = '';
				row[i18next.t('company')] = '';
			}
		}

		switch (votingType) {
			case 'quiz':
			case 'survey':
				vote.options?.forEach((question) => {
					let _value = e.value;
					if (!_value) return;
					const answer = JSON.parse(_value).answers.find((e) => e.voteId.toString() === question.label);
					let _answertext = answer.answerText;
					if (answer.type === 'wordCloud') _answertext = _answertext.replaceAll('<%>', ', ');
					row[question.description ?? ''] = _answertext ?? '';
				});
				break;
			case 'applause':
				vote.options?.forEach((question) => {
					let _value = e.value;
					let _order = ((question.order ?? 0) + 1).toString();
					if (!_value) return;
					const answer = JSON.parse(_value);
					if (question.id) {
						row[`${i18next.t('Option')} ${_order}`] = answer[question.id?.toString()];
					}
				});
				break;
			case 'pinOnImage':
				let _pinAnswer = e.value;
				let _iconAnswer;
				let _answerObj = JSON.parse(e.value ?? '');
				if (Object.keys(_answerObj).length > 0) {
					_pinAnswer = `[x: ${Number(_answerObj['x'])?.toFixed(3)}, y: ${Number(_answerObj['y']).toFixed(3)}]`;
					if (Object.keys(_answerObj).includes('icon')) _iconAnswer = _answerObj['icon'];
				}
				row[vote.question] = _pinAnswer;
				if (!vote.useAvatar) {
					row[i18next.t('Icon')] = _iconAnswer;
				}
				break;
			default:
				let _answertext = e.value;
				if (votingType === 'multipleChoice') {
					let _voteAnswer = vote.options
						?.filter((o) => {
							// if keys are used for answers:
							if (o.key && !isEmptyString(o.key)) {
								return _answertext?.includes(o.key);
							}

							return _answertext?.includes(o.description);
						})
						.map((o) => o.description);
					if (_voteAnswer) {
						_answertext = _voteAnswer.join('\n');
					}
				}

				if (e.media && typeof e.media === 'object') {
					let _mediaText = `\n[${i18next.t('Media')}]\n`;
					if (Array.isArray(e.media)) {
						e.media.forEach((media) => (_mediaText = _mediaText + media.url + '\n'));
						_answertext = _answertext + '\n' + _mediaText;
					} else {
						_mediaText = _mediaText + e.media.url + '\n';
						_answertext = _answertext + '\n' + _mediaText;
					}
				}
				if (votingType === 'wordCloud') _answertext = _answertext?.replaceAll('<%>', ', ');
				row[vote.question] = _answertext;
				if (votingType === 'nps' && vote.requestReason) {
					row[i18next.t('Reason')] = !isEmptyString(e.reasonAnswer) ? e.reasonAnswer : '-';
				}
				break;
		}

		return row;
	});

	return {
		columns,
		data
	};
};
