import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { Animated, BackHandler, Dimensions, FlatList, Keyboard, ScrollView, View } from 'react-native';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { NoData } from 'components/NoData';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, getCardContainerStyle, getTimingAnimation, isEmptyString, IS_ANDROID, IS_WEB } from 'helper';
import { useTranslation } from 'react-i18next';
import { AuthenticationModal } from 'components/Modal';
import { IFeatureInfo, INetworkingRoom } from 'config/interfaces';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { NetworkingRoomListItem } from 'components/NetworkingRoom';
import { getNetworkingExample } from 'helper/content';
import _ from 'lodash';
import { SponsorBlock } from '../Sponsors';
import {
	hsBottomMargin,
	hsTopScreenPadding,
	LISTHEADER_ANIMATIONTIME_IN,
	LISTHEADER_ANIMATIONTIME_OUT,
	LISTHEADER_ANIMATION_GUESSED_HEIGHT,
	LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_OPACITY_HIGH,
	LISTHEADER_ANIMATION_OPACITY_LOW,
	LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION
} from 'config/styleConstants';
import { TABBAR_HEIGHT } from 'config/constants';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { ContentListHeader } from 'components/List';
import { ScrollEvent } from 'react-native-spring-scrollview';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';
import { Placeholder, PlaceholderLine, PlaceholderMedia, ShineOverlay } from 'rn-placeholder';

const IMAGESIZE = 50;

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NetworkingRoomList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NetworkingRoomList>;
type RouteParams = StackParamList[ERoutes.NetworkingRoomList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'networkingroomlist';

export const NetworkingRoomListScreen = ({ route, navigation }: Props) => {
	const { screenWidth, screenColumnCount } = useQuery();
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { exportAsCSV } = useExport();
	const { getContentTypeFields } = useContent('networkingroom');
	const { activeSpace } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [networkingRooms, setNetworkingRooms] = useState<INetworkingRoom[]>([]);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [searchedNetworks, setSearchedNetworks] = useState<INetworkingRoom[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
	const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
	const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(LISTHEADER_ANIMATION_GUESSED_HEIGHT));
	const [animatedOpacity, setAnimatedOpacity] = useState(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
	const [scrollableContentHeight, setScrollableContentHeight] = useState<number>(0);
	const scrollOffset = useRef<number>(0);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const content = useSelector((store: IRootState) => store.content.content);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);
	const networkingRoomAttendeeOverviews = useSelector((store: IRootState) => store.networking.networkingRoomAttendeeOverviews);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const createNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createNetworkingRoom);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route?.params?.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('networking')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	useEffect(() => {
		if (activeSpace && content?.networkingrooms && isScreenFocused) {
			let _networkingrooms: typeof networkingRooms = [];

			_networkingrooms = content.networkingrooms.filter((e) => {
				if (e.spaceId !== activeSpace.spaceId) {
					return false;
				}

				if (e.isRoomPrivate) {
					const meeting = meetings?.find((mt) => mt.id === e.meeting?.id);
					if (meeting?.ownerId === profile?.userId || meeting?.partnerId === profile?.userId) {
						return true;
					}

					return false;
				}

				return true;
			});

			if (_networkingrooms.length > 1) {
				_networkingrooms = _networkingrooms.sort((a, b) => a.title.localeCompare(b.title));
			}

			setNetworkingRooms(_networkingrooms);
		}
	}, [activeSpace, profile, content, isScreenFocused]);

	useEffect(() => {
		let _searched = [...networkingRooms];

		if (activeSpace && searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = _searched.filter((item) => {
				if (item.title.toLowerCase().includes(lowerSearch) || item.description?.toLowerCase().includes(lowerSearch)) {
					return true;
				}

				const overview = networkingRoomAttendeeOverviews.find(
					(e) => e.externalMeetingId === `${activeSpace.spaceId}_networkingroom_${item.id}`
				);

				if (overview) {
					for (const user of overview.roomUsers) {
						const attendee = attendees.find((e) => e.userId === user.userId);
						if (attendee) {
							if (
								attendee.firstName.toLowerCase().includes(lowerSearch) ||
								attendee.lastName.toLowerCase().includes(lowerSearch) ||
								attendee.company?.toLowerCase().includes(lowerSearch)
							) {
								return true;
							}
						}
					}
				}

				return false;
			});
		}

		setSearchedNetworks(_searched);
	}, [activeSpace, networkingRooms, searchTerm]);

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		const singleItem = networkingRooms.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'networkingroom' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.NetworkingRoomCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'networkingroom' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: INetworkingRoom[] = [];
				if (content.networkingrooms) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.networkingrooms.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.networkingrooms];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'networkingrooms');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'addExample':
				setIsLoading(true);
				const networkingExamples = getNetworkingExample();
				for (const networkingExample of networkingExamples) {
					await createNetworkingRoom({
						networkingroom: networkingExample,
						fields: getContentTypeFields(),
						noToast: true
					});
				}
				setIsLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.NetworkingRoomCreate, {
						spaceId: activeSpace?.spaceId,
						networkingRoomId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _animateHeader = (e: ScrollEvent) => {
		const { y } = e.nativeEvent.contentOffset;

		if (
			headerHeight &&
			!IS_WEB &&
			scrollableContentHeight > LISTHEADER_ANIMATION_HEIGHT_FACTOR * Dimensions.get('window').height - headerHeight
		) {
			if (y > headerHeight * LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR && y > scrollOffset.current) {
				if (isHeaderExpanded) {
					setIsHeaderExpanded(false);

					Animated.parallel([
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_LOW, LISTHEADER_ANIMATIONTIME_OUT, false),
						getTimingAnimation(animatedHeight, 0, LISTHEADER_ANIMATIONTIME_OUT, false)
					]).start();
				}
			} else if (
				y + (scrollOffset.current < LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION ? 0 : LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION) <
				scrollOffset.current
			) {
				if (!isHeaderExpanded) {
					setIsHeaderExpanded(true);
					Animated.parallel([
						getTimingAnimation(animatedHeight, headerHeight, LISTHEADER_ANIMATIONTIME_IN, false),
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_HIGH, LISTHEADER_ANIMATIONTIME_IN, false)
					]).start();
				}
			}
			scrollOffset.current = y;
		}
	};

	const _renderSelectAll = () => {
		if (isEditMode && searchedNetworks.length > 0) {
			return (
				<HSCard>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedEntries && selectedEntries.length === searchedNetworks.length}
						onPress={() => {
							if (selectedEntries && selectedEntries.length === searchedNetworks.length) {
								setSelectedEntries([]);
							} else {
								setSelectedEntries(searchedNetworks.map((sp) => sp.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderSeatPlaceholder = () => {
		return (
			<View
				style={{
					width: IMAGESIZE,
					height: IMAGESIZE,
					borderRadius: IMAGESIZE / 2,
					backgroundColor: theme.gray,
					marginHorizontal: 5
				}}
			/>
		);
	};

	const _renderEmptyMediaEntry = () => {
		return (
			<HSCard style={{ height: 320 }}>
				<Placeholder Animation={ShineOverlay}>
					<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
						{_renderSeatPlaceholder()}
						{_renderSeatPlaceholder()}
						{_renderSeatPlaceholder()}
					</View>
					<PlaceholderMedia style={{ width: '50%', height: 100, marginVertical: hsBottomMargin / 2, alignSelf: 'center' }} />
					<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
						{_renderSeatPlaceholder()}
						{_renderSeatPlaceholder()}
						{_renderSeatPlaceholder()}
					</View>
					<PlaceholderLine style={{ marginVertical: hsBottomMargin }} />
					<PlaceholderLine style={{ marginBottom: hsBottomMargin }} />
				</Placeholder>
			</HSCard>
		);
	};

	const _renderEmptyData = () => {
		return (
			<View>
				{_renderEmptyMediaEntry()}
				{_renderEmptyMediaEntry()}
				{_renderEmptyMediaEntry()}
			</View>
		);
	};

	const _renderNetworkingRooms = () => {
		if (profile) {
			if (activeSpace?.forceDoubleOptInForUsers && !profile?.isUserConfirmed) {
				return (
					<NoData type="NotConfirmed">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_authenticate`}
							onPress={() => setIsAuthenticationModalVisible(true)}
							icon={EDefaultIconSet.User}
							title={t('Login/Register')}
							isStacked
						/>
					</NoData>
				);
			}

			if (searchedNetworks.length === 0) {
				if (!isEmptyString(searchTerm)) {
					return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
				}

				if (waitingForSocketResponse?.networkingrooms) {
					return _renderEmptyData();
				}

				return <NoData type="NoNetworking"></NoData>;
			}

			if (profile.hideMyProfile) {
				return <NoData type="ProfileHidden" />;
			}

			if (!screenColumnCount) {
				return null;
			}

			return (
				<View
					style={{
						flexDirection: 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-between'
					}}
				>
					<FlatList
						data={searchedNetworks}
						numColumns={screenColumnCount}
						scrollEnabled
						renderItem={({ item, index }) => {
							return (
								<View
									key={`${TESTIDPREFIX}_networkingroom_${item.id}`}
									style={{
										...getCardContainerStyle(screenColumnCount, index, EHorizontalScreenPadding.Wide),
										width: `${100 / screenColumnCount}%`,
										flex: undefined
									}}
								>
									<NetworkingRoomListItem
										testID={`${TESTIDPREFIX}_networkingroom_${item.id}`}
										item={item}
										isLoading={isDeleteLoading}
										onPress={isEditMode ? () => _handleSelect(item.id) : undefined}
										onSelect={isEditMode ? () => _handleSelect(item.id) : undefined}
										onEdit={
											isEditMode
												? () =>
														navigation.navigate(ERoutes.NetworkingRoomUpdate, {
															spaceId: activeSpace?.spaceId,
															id: item.id
														})
												: undefined
										}
										onDelete={
											isEditMode
												? () => {
														setSelectedEntries([item.id]);
														_delete(item.id);
												  }
												: undefined
										}
										isSelected={selectedEntries.includes(item.id)}
									/>
								</View>
							);
						}}
						ListEmptyComponent={
							<HSCard>
								<NoData type="NoNetworking" />
							</HSCard>
						}
					/>
				</View>
			);
		}

		return (
			<NoData type="NoProfile">
				<RoundButton
					testID={`${TESTIDPREFIX}_button_authenticate`}
					onPress={() => setIsAuthenticationModalVisible(true)}
					icon={EDefaultIconSet.Add}
					title={t('Login/Register')}
					isStacked
				/>
			</NoData>
		);
	};

	return (
		<ScreenContainer contentKey="networkingrooms">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				<Animated.View
					style={{
						height: animatedHeight,
						opacity: animatedOpacity,
						marginBottom: isHeaderExpanded ? hsBottomMargin : 0
					}}
				>
					<ContentListHeader
						testID={TESTIDPREFIX}
						type="networkingroom"
						searchTerm={searchTerm}
						onSearchChange={(val) => setSearchTerm(val)}
						onLayout={(e) => {
							const { height } = e.nativeEvent.layout;

							if (!headerHeight || height > headerHeight) {
								setHeaderHeight(height);
								setAnimatedHeight(new Animated.Value(height));
							}
						}}
					/>
				</Animated.View>
				{_renderSelectAll()}
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					keyboardShouldPersistTaps="handled"
					onScrollBeginDrag={(e) => Keyboard.dismiss()}
					scrollEventThrottle={0}
					style={{
						marginBottom: activeSpace?.sponsorTypeNetworking?.startsWith('sponsor')
							? EHorizontalScreenPadding.Wide
							: isTabbarVisible
							? 0
							: hsBottomMargin,
						marginHorizontal: -EHorizontalScreenPadding.Wide
					}}
					contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
				>
					{_renderNetworkingRooms()}
				</ScrollView>
				<View style={{ width: '100%', alignSelf: 'center' }}>
					<SponsorBlock key={`${TESTIDPREFIX}_networkingroom_sponsor`} contentType={'networkingroom'} />
				</View>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_editmode`}
						icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
						label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('editMode')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						isLoading={isDeleteLoading}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isDisabled={isDeleteLoading || isLoading}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_clone`}
						icon={EDefaultIconSet.Clone}
						label={t('clone')}
						isDisabled={isDeleteLoading || selectedEntries.length !== 1}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('clone')}
					/>
				</BottomSheet>

				<AuthenticationModal
					isVisible={isAuthenticationModalVisible}
					onClose={() => setIsAuthenticationModalVisible(false)}
					onSubmit={() => setIsAuthenticationModalVisible(false)}
				/>
			</View>
		</ScreenContainer>
	);
};

export const NetworkingRoomListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};

export const NetworkingRoomListScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
