import React from 'react';
import { useTheme } from 'hooks/useTheme';
import { TFunctionResult } from 'i18next';
import { Text as RNText, TextProps } from 'react-native';

interface IHintComponent extends TextProps {
	bold?: boolean;
	center?: boolean;
	children?: string | string[] | number | Element | null | TFunctionResult;
	marginBottom?: boolean;
	largerText?: boolean;
}

export const Hint = (props: IHintComponent) => {
	const { bold, center, marginBottom, children, style, largerText } = props;
	const { theme } = useTheme();

	return (
		<RNText
			{...props}
			style={[
				{
					marginBottom: marginBottom ? 30 : undefined,
					color: theme.gray,
					fontSize: largerText ? 18 : 12,
					fontWeight: bold ? 'bold' : 'normal',
					textAlign: center ? 'center' : 'left'
				},
				style
			]}
		>
			{children}
		</RNText>
	);
};
