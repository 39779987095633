import React, { useState } from 'react';

import { StepWizard } from 'components/StepWizard';
import { IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { VoteTextForm } from './VoteText';
import { VoteRatingForm } from './VoteRating';
import { VoteScaleForm } from './VoteScale';
import { VoteWordCloudForm } from './VoteWordCloud';
import { VoteApplauseForm } from './VoteApplause';
import { VoteApplauseOptionsForm } from './VoteApplause/VoteApplauseOptionsForm';
import { VoteMultipleChoiceForm } from './VoteMultipleChoice';
import { VoteMultipleChoiceOptionsForm } from './VoteMultipleChoice/VoteMultipleChoiceOptionsForm';
import { VoteQuizForm, VoteQuizOptionsForm } from './VoteQuiz';
import { VoteSurveyForm, VoteSurveyOptionsForm } from './VoteSurvey';
import { t } from 'i18next';
import { isEmptyString } from 'helper';
import { VoteExternalUrlForm } from './VoteExternalUrl';
import { VoteNpsForm } from './VoteNps';
import { VotePinOnImageForm } from './VotePinOnImage';
import { VotePinOnImageOptionsForm } from './VotePinOnImage/VotePinOnImageOptionsForm';
import { VoteHappinessOMeterForm } from './VoteHappinessOMeter';

interface IVoteCreateForm {
	values: IEditVoteFormValues;
	errors: IEditVoteFormErrors;
	onChange: (val: IEditVoteFormValues) => void;
	onNext: (currentIdx: number) => void;
	onSubmit: (currentIdx: number) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'voteeditform';

export const VoteEditForm = (props: IVoteCreateForm) => {
	const { values, errors, onChange, onSubmit, isLoading, onNext } = props;

	const [stepIndex, setStepIndex] = useState<number>(0);

	const getVoteFormComponents = () => {
		switch (values.votingType) {
			case 'applause':
				return [
					<VoteApplauseForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>,
					<VoteApplauseOptionsForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>
				];
			case 'multipleChoice':
				return [
					<VoteMultipleChoiceForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>,
					<VoteMultipleChoiceOptionsForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>
				];
			case 'quiz':
				return [
					<VoteQuizForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} isLoading={isLoading} />,
					<VoteQuizOptionsForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>
				];
			case 'rating':
				return [
					<VoteRatingForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} isLoading={isLoading} />
				];
			case 'scale':
				return [
					<VoteScaleForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} isLoading={isLoading} />
				];
			case 'survey':
				return [
					<VoteSurveyForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>,
					<VoteSurveyOptionsForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>
				];
			case 'text':
				return [
					<VoteTextForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} isLoading={isLoading} />
				];
			case 'wordCloud':
				return [
					<VoteWordCloudForm
						testIdPrefix={TESTIDPREFIX}
						onChange={onChange}
						values={values}
						errors={errors}
						isLoading={isLoading}
					/>
				];
			case 'externalUrl':
				return [<VoteExternalUrlForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />];
			case 'nps':
				return [<VoteNpsForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />];
			case 'pinOnImage':
				if (values.useAvatar) {
					return [<VotePinOnImageForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />];
				}
				return [
					<VotePinOnImageForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />,
					<VotePinOnImageOptionsForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />
				];
			case 'happinessOMeter':
				return [<VoteHappinessOMeterForm testIdPrefix={TESTIDPREFIX} onChange={onChange} values={values} errors={errors} />];
			default:
				break;
		}
		return [];
	};

	const _isNextDisabled = () => {
		switch (stepIndex) {
			case 0:
				switch (values.votingType) {
					case 'scale':
					case 'wordCloud':
					case 'nps':
						return !(values.minValue !== undefined && values.maxValue && values.question);
					case 'externalUrl':
						return isEmptyString(values.externalUrl) || isEmptyString(values.question);
					case 'pinOnImage':
						return !values.question || !values.media || values.media.length < 1;
					default:
						return !values.question;
				}
			case 1:
				switch (values.votingType) {
					case 'applause':
						let hasIcon = true;
						values?.options?.forEach((element) => {
							if (hasIcon) {
								hasIcon = !isEmptyString(element.label);
							}
						});
						return !((values?.options?.length ?? 0) > 1 && hasIcon);
					case 'pinOnImage':
						hasIcon = true;
						values?.options?.forEach((element) => {
							if (hasIcon) {
								hasIcon = !isEmptyString(element.label);
							}
						});
						return !((values?.options?.length ?? 0) > 0 && hasIcon) || ((values.options?.length ?? 0) > 5 && hasIcon);
					case 'multipleChoice':
						let hasText = true;
						values?.options?.forEach((element) => {
							if (hasText) {
								hasText = !isEmptyString(element.description);
							}
						});
						return !((values?.options?.length ?? 0) > 1 && hasText);
					case 'quiz':
					case 'survey':
						let hasQuestions = true;
						let hasQuestionsOptionsWithAnswers = true;
						values.options?.forEach((opt) => {
							if (hasQuestions) {
								hasQuestions = !isEmptyString(opt.description);
							}
						});
						values?.optionsVote?.forEach((element) => {
							if (hasQuestionsOptionsWithAnswers && element.type === 'multipleChoice') {
								hasQuestionsOptionsWithAnswers = (element.options?.length ?? 0) > 1;
								element.options?.forEach((opt) => {
									if (hasQuestionsOptionsWithAnswers) {
										hasQuestionsOptionsWithAnswers = !isEmptyString(opt.description);
									}
								});
							}
						});
						return !((values?.options?.length ?? 0) > 1 && hasQuestions && hasQuestionsOptionsWithAnswers);
				}
			default:
				return false;
		}
	};

	const _getBtnText = () => {
		let btnText;
		switch (stepIndex) {
			case 0:
				switch (values.votingType) {
					case 'text':
					case 'rating':
					case 'scale':
					case 'wordCloud':
					case 'externalUrl':
					case 'nps':
					case 'happinessOMeter':
						btnText = t('Save');
						break;
					case 'pinOnImage':
						values.useAvatar ? (btnText = t('Save')) : (btnText = t('Next'));
						break;
					default:
						btnText = t('Next');
						break;
				}
				break;
			case 1:
				switch (values.votingType) {
					case 'applause':
					case 'multipleChoice':
					case 'quiz':
					case 'survey':
					case 'pinOnImage':
						btnText = t('Save');
						break;
					default:
						btnText = t('Next');
						break;
				}
				break;
			default:
				btnText = t('Next');
				break;
		}
		return btnText;
	};

	return (
		<StepWizard
			testIdPrefix={TESTIDPREFIX}
			getCurrentIndex={(val) => setStepIndex(val)}
			completeFunction={onSubmit}
			isLoading={isLoading}
			onNext={onNext}
			nextButtonText={_getBtnText()}
			isNextButtonDisabled={_isNextDisabled()}
			components={[...getVoteFormComponents()]}
		/>
	);
};
