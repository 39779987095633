import { hsBorderRadius } from 'config/styleConstants';
import React from 'react';
import { Text } from 'components/Text';
import { View, ViewStyle } from 'react-native';

interface IInfoPill {
	text: string;
	textColor?: string;
	backgroundColor?: string;
	borderColor?: string;
	containerStyle?: ViewStyle;
	center?: boolean;
	centerText?: boolean;
	size?: 'xs' | 'default';
}

export const InfoPill = (props: IInfoPill) => {
	const { text, textColor, backgroundColor, borderColor, containerStyle, center, centerText, size } = props;

	return (
		<View style={containerStyle}>
			<View
				style={{
					paddingHorizontal: size === 'xs' ? 4 : 6,
					paddingVertical: 2,
					marginBottom: center ? 0 : 5,
					borderRadius: hsBorderRadius,
					borderWidth: 1,
					alignSelf: center ? 'center' : 'flex-start',
					backgroundColor,
					borderColor: borderColor ?? backgroundColor
				}}
			>
				<Text center={centerText} style={{ fontSize: size === 'xs' ? 10 : 12, color: textColor }}>
					{text}
				</Text>
			</View>
		</View>
	);
};
