import React, { useState } from 'react';

import { View, ViewStyle } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { FormField } from '../FormField';
import { hexToRGBA } from 'helper/color';
import { BUTTONBORDERWIDTH } from 'components/Button/constants';
import { FormColorPickerModal } from './FormColorPickerModal';

interface IFormColorPicker {
	testID: string;
	value?: string;
	onChange: (value: string | undefined) => void;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	error?: string;
	description?: string;
	fallbackValue?: string;
	hideValues?: boolean;
	hideRGB?: boolean;
	hideTransparency?: boolean;
	isDisabled?: boolean;
	formStyle?: ViewStyle;
}

const SIZE = 50;

export const FormColorPicker = (props: IFormColorPicker) => {
	const {
		testID,
		value,
		onChange,
		label,
		isRequired,
		hint,
		error,
		description,
		fallbackValue,
		hideValues,
		hideRGB,
		hideTransparency,
		isDisabled,
		formStyle
	} = props;
	const { theme } = useTheme();

	const [isColorPickerModalVisible, setIsColorPickerModalVisible] = useState<boolean>(false);

	const _renderValues = () => {
		if (value) {
			const rgba = hexToRGBA(value);

			return (
				<View style={{ flexDirection: 'row', marginLeft: 15 }}>
					<View>
						{_renderText('HEX', true)}
						{hideRGB
							? hideTransparency
								? null
								: _renderText('Alpha', true)
							: hideTransparency
							? _renderText('RGB', true)
							: _renderText('RGBA', true)}
					</View>
					<View style={{ marginLeft: 5 }}>
						{_renderText(value)}
						{hideRGB
							? hideTransparency
								? null
								: _renderText(`${rgba.a}`)
							: hideTransparency
							? _renderText(`${rgba.r}, ${rgba.g}, ${rgba.b}`)
							: _renderText(`${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a}`)}
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderText = (label: string, bold?: boolean) => {
		return <Text bold={bold}>{label}</Text>;
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
				<ChildButton
					testID={testID}
					style={{
						backgroundColor: value,
						height: SIZE,
						width: SIZE,
						borderRadius: SIZE / 2,
						borderWidth: BUTTONBORDERWIDTH,
						borderColor: theme.lightgray,
						marginLeft: 5
					}}
					isDisabled={isDisabled}
					onPress={() => setIsColorPickerModalVisible(!isColorPickerModalVisible)}
				/>
				{!hideValues && _renderValues()}
			</View>
			<FormColorPickerModal
				testID={testID}
				onClose={() => setIsColorPickerModalVisible(false)}
				isVisible={isColorPickerModalVisible}
				value={value}
				onChange={(val) => onChange(val)}
				label={label}
				description={description}
				fallbackValue={fallbackValue}
				hideRGB={hideRGB}
				hideTransparency={hideTransparency}
			/>
		</FormField>
	);
};
