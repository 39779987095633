import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { IAttendee, ISupportRequest } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Keyboard, ScrollView, View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FormMultiSwitch, FormTextInput } from 'components/Form';
import { Text } from 'components/Text';
import { HSCard } from 'components/Card';
import { FormLabel } from 'components/Form/FormLabel';
import { AttendeeListItem } from 'components/Attendee';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { Spinner } from 'components/Spinner';
import moment from 'moment';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SupportRequest>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SupportRequest>;
type RouteParams = StackParamList[ERoutes.SupportRequest];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'supportrequest';

export const SupportRequestScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const supportRequests = useSelector((store: IRootState) => store.attendee.supportRequests);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [supportRequest, setSupportRequest] = useState<ISupportRequest | undefined>(undefined);
	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);
	const [closedBy, setClosedBy] = useState<IAttendee | undefined>(undefined);
	const [supportAnswer, setSupportAnswer] = useState<string>('');
	const [internalComment, setInternalComment] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<'open' | 'closed' | string>('');
	const [oldStatus, setOldStatus] = useState<'open' | 'closed' | string>('');
	const [isSupportRequestsLoading, setIsSupportRequestsLoading] = useState<boolean>(true);

	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateSupportRequest = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.updateSupportRequest);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, [supportRequest, supportAnswer, internalComment, status]);

	useEffect(() => {
		const _supportRequest = supportRequests?.find((e) => e.id === route.params.id);
		if (_supportRequest) {
			setSupportRequest(_supportRequest);
			setOldStatus(_supportRequest.status);
			setIsSupportRequestsLoading(false);
		}
	}, [supportRequests]);

	useEffect(() => {
		if (attendees && supportRequest) {
			const _attendee = attendees.find((a) => a.userId === supportRequest.userId);
			if (_attendee) {
				setAttendee(_attendee);
			}
			if (supportRequest?.closedById) {
				const _closedBy = attendees.find((a) => a.id === supportRequest.closedById);
				if (_closedBy) {
					setClosedBy(_closedBy);
				}
			}
		}
	}, [attendees, supportRequest]);

	useEffect(() => {
		if (!isEmptyString(supportRequest?.answer)) {
			setSupportAnswer(supportRequest!.answer);
		} else if (isEmptyString(supportRequest?.answer) && supportRequest?.name) {
			setSupportAnswer(`${t('Hello')} ${supportRequest.name},



${t('Kind regards')}`);
		}

		if (!isEmptyString(supportRequest?.internalComment)) {
			setInternalComment(supportRequest!.internalComment);
		}
	}, [supportRequest]);

	const _delete = async () => {
		if (supportRequest?.id) {
			showAlert({
				title: t('ConfirmDelete'),
				message: t('ConfirmDeleteSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Delete'),
						style: 'destructive',
						onPress: async () => {
							setIsLoading(true);
							const res = await multiDelete({ selectedIds: [supportRequest.id], type: 'attendeesupportrequest' });
							setIsLoading(false);

							if (res) {
								bottomSheetRef.current?.close();
								if (navigation.canGoBack()) {
									navigation.goBack();
								} else {
									navigation.navigate(ERoutes.SupportRequestList, { spaceId: activeSpace?.spaceId });
								}
							}
						}
					}
				]
			});
		}
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'save':
			case 'send':
				setIsLoading(true);
				let closedById: number | undefined = undefined;
				if (oldStatus === 'open' && status === 'closed') {
					closedById = attendees?.find((a) => a.userId === profile?.userId)?.id;
				}
				if (supportRequest && activeSpace) {
					await updateSupportRequest({
						id: supportRequest.id,
						space: activeSpace,
						internalComment: internalComment,
						answer: supportAnswer,
						isSend: action === 'send',
						status: !isEmptyString(status) ? status : supportRequest.status,
						closedById: closedById ?? 0
					});
				}
				navigation.setParams({ ...route?.params, prohibitNavigation: false });
				setIsLoading(false);
				bottomSheetRef.current?.close();
				if (navigation.canGoBack()) {
					navigation.goBack();
				} else {
					navigation.navigate(ERoutes.SupportRequestList, { spaceId: activeSpace?.spaceId });
				}
				break;
			case 'delete':
				setIsLoading(true);
				_delete();
				setIsLoading(false);
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _renderRequest = () => {
		if (isSupportRequestsLoading) {
			return (
				<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
					<Spinner />
				</View>
			);
		}

		if (supportRequest) {
			return (
				<View style={{ flexGrow: 1 }}>
					<HSCard>
						<FormMultiSwitch
							testID={`${TESTIDPREFIX}_multiswitch_filter`}
							label={t('Status')}
							value={!isEmptyString(status) ? status : supportRequest.status}
							onChange={(val) => setStatus(val)}
							options={[
								{
									key: 'open',
									label: t('open'),
									backgroundColor: theme.warning
								},
								{
									key: 'closed',
									label: t('closed'),
									backgroundColor: theme.success
								}
							]}
							formStyle={{ marginBottom: 10 }}
						/>

						<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
							<View>
								<FormLabel testID={`${TESTIDPREFIX}_attendee`} label={t('Created At')} style={{ marginBottom: 5 }} />
								<Text>{moment(supportRequest.created_at).format('DD.MM.YYYY HH:mm')}</Text>
							</View>
							{supportRequest.closed_at && (
								<View>
									<FormLabel testID={`${TESTIDPREFIX}_attendee`} label={t('Closed At')} style={{ marginBottom: 5 }} />
									<Text>{moment(supportRequest.closed_at).format('DD.MM.YYYY HH:mm')}</Text>
								</View>
							)}
						</View>
						{supportRequest.deviceInfo && (
							<View style={{ marginBottom: 10 }}>
								<FormLabel testID={`${TESTIDPREFIX}_deviceinfo`} label={t('Device Info')} style={{ marginBottom: 5 }} />
								{supportRequest.deviceInfo?.os && <Text>{`OS: ${supportRequest.deviceInfo?.os}`}</Text>}
								{supportRequest.deviceInfo?.buildNumber && (
									<Text>{`Build: ${supportRequest.deviceInfo?.buildNumber}`}</Text>
								)}
								{supportRequest.deviceInfo?.nativeVersion && (
									<Text>{`Version: ${supportRequest.deviceInfo?.nativeVersion}`}</Text>
								)}
							</View>
						)}

						{attendee && (
							<View>
								<FormLabel testID={`${TESTIDPREFIX}_attendee`} label={t('Attendee')} style={{ marginBottom: 5 }} />
								<AttendeeListItem isAdminArea testID={`${TESTIDPREFIX}_attendee`} item={attendee} />
							</View>
						)}
						{attendee?.email || supportRequest?.email ? (
							<View style={{ marginVertical: 10 }}>
								<Text bold style={{ marginBottom: 5 }}>
									{t('email')}
								</Text>
								<Text>{attendee?.email ?? supportRequest?.email}</Text>
							</View>
						) : null}
						{supportRequest?.ticketcode ? (
							<View style={{ marginVertical: 10 }}>
								<Text bold style={{ marginBottom: 5 }}>
									{t('ticketcode')}
								</Text>
								<Text>{supportRequest?.ticketcode}</Text>
							</View>
						) : null}
						<FormTextInput
							editable={false}
							multiline
							label={t('Subject')}
							testID={`${TESTIDPREFIX}_textinput_subject`}
							value={supportRequest.subject}
							skipTabPress
						/>
						<FormTextInput
							editable={false}
							multiline
							scrollEnabled={false}
							label={t('EmailBody')}
							testID={`${TESTIDPREFIX}_textinput_description`}
							value={supportRequest.text}
							style={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							containerStyle={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							skipTabPress
						/>
					</HSCard>
					<HSCard>
						<FormTextInput
							multiline
							scrollEnabled={false}
							label={t('Answer')}
							isRequired
							hint={t('supportRequestAnswerHint')}
							testID={`${TESTIDPREFIX}_textinput_supportanswer`}
							value={supportAnswer}
							onChangeText={(val) => {
								if (!route?.params?.prohibitNavigation) {
									navigation.setParams({ ...route?.params, prohibitNavigation: true });
								}
								setSupportAnswer(val);
							}}
							style={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							containerStyle={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							skipTabPress
						/>
					</HSCard>
					<HSCard>
						{closedBy && (
							<View>
								<FormLabel testID={`${TESTIDPREFIX}_closedby`} label={t('closedBy')} style={{ marginBottom: 5 }} />
								<AttendeeListItem testID={`${TESTIDPREFIX}_closedby`} item={closedBy} />
							</View>
						)}
						<FormTextInput
							multiline
							scrollEnabled={false}
							style={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							containerStyle={{ maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							label={t('InternalComment')}
							hint={t('supportRequestInternalCommentHint')}
							testID={`${TESTIDPREFIX}_textinput_internalcomment`}
							value={internalComment}
							onChangeText={(val) => {
								if (!route?.params?.prohibitNavigation) {
									navigation.setParams({ ...route?.params, prohibitNavigation: true });
								}
								setInternalComment(val);
							}}
							skipTabPress
						/>
					</HSCard>
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="administrate">
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderRequest()}
			</ScrollView>
			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_save`}
					icon={EDefaultIconSet.Save}
					label={`${t('SaveAndBack')} (${t('doesNotSendToAttendee')})`}
					isLoading={isLoading}
					onPress={() => _handleActionSheetPress('save')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_send`}
					icon={EDefaultIconSet.Rocket}
					label={t('SendAndBack')}
					isLoading={isLoading}
					isDisabled={!supportAnswer}
					onPress={() => _handleActionSheetPress('send')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={t('Delete')}
					onPress={() => _handleActionSheetPress('delete')}
					isLoading={isLoading}
					iconColor={theme.danger}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const SupportRequestScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Support Request')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
