import { Icon } from 'components/Icon';
import { hsBorderRadius } from 'config/styleConstants';
import { getTopRadiusStyle } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';
import { HORIZONTAL_BARCHART_MAXWIDTH } from './constants';

interface IVerticalBar {
	height: number;
	width: string;
	paddingHorizontal: number;
	children?: ReactNode | ReactNode[];
	largerDesign?: boolean;
	icon?: string;
}

export const VerticalBar = (props: IVerticalBar) => {
	const { height, width, paddingHorizontal, children, largerDesign, icon } = props;
	const { theme } = useTheme();

	const animatedHeight = useRef(new Animated.Value(1));

	useEffect(() => {
		Animated.timing(animatedHeight.current, {
			toValue: height,
			duration: 300,
			useNativeDriver: false
		}).start();
	}, [height]);

	return (
		<Animated.View
			style={{
				width,
				minHeight: 1,
				paddingHorizontal,
				alignSelf: 'flex-end',
				height: animatedHeight.current,
				alignItems: 'center',
				position: 'relative'
			}}
		>
			{icon && (
				<Animated.View style={{ position: 'absolute', bottom: animatedHeight.current, alignItems: 'center', paddingBottom: 5 }}>
					<Icon name={icon} size={largerDesign ? 35 : 25} color={theme.primary} />
				</Animated.View>
			)}
			<View
				style={{
					height: '100%',
					width: '100%',
					maxWidth: largerDesign ? HORIZONTAL_BARCHART_MAXWIDTH * 1.5 : HORIZONTAL_BARCHART_MAXWIDTH,
					backgroundColor: theme.primary,
					...getTopRadiusStyle(hsBorderRadius)
				}}
			></View>
			{children}
		</Animated.View>
	);
};
