import React from 'react';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

interface IFormHintComponent {
	testID: string;
	hint?: string;
	largerText?: boolean;
}

export const FormHint = (props: IFormHintComponent) => {
	const { testID, hint, largerText } = props;
	const { theme } = useTheme();

	if (hint) {
		return (
			<Text testID={`${testID}_hint`} style={{ color: theme.formgray, marginBottom: 5, fontSize: largerText ? 16 : 11 }}>
				{hint}
			</Text>
		);
	}

	return null;
};
