import React, { useState } from 'react';
import { HSModal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { ICreateStreamFormErrors, ICreateStreamFormValues } from 'config/interfaces';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { StreamCreateForm } from 'components/Forms/Stream/StreamCreateForm';
import { IMediaObject } from '../FormStreamSelect';
import { openURL, validateForm } from 'helper';
import { getAddStreamFormSchema } from 'config/yupSchemas';

interface IAddStreamModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: (streamId: number | IMediaObject) => void;
	hideSelection?: boolean;
	createMediaJson?: boolean;
	createStreamJson?: boolean;
	hideMediaItem?: boolean;
	isSelfService?: boolean;
}

const TESTIDPREFIX = 'addstreammodal';

export const AddStreamModal = (props: IAddStreamModal) => {
	const { isVisible, onClose, onSubmit, hideSelection, createMediaJson, createStreamJson, hideMediaItem, isSelfService } = props;
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [values, setValues] = useState<ICreateStreamFormValues>({});
	const [errors, setErrors] = useState<ICreateStreamFormErrors>({});

	const createStream = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createStream);

	const _createStream = async () => {
		const formErrors = await validateForm(getAddStreamFormSchema(values), values);
		if (formErrors) {
			setErrors(formErrors);
			return;
		}
		if (values) {
			if (createMediaJson || createStreamJson) {
				onSubmit(values);
				return;
			}
			setIsLoading(true);
			const res = await createStream({ stream: values });
			setIsLoading(false);

			if (res) {
				onSubmit(res.id);
			}
		}
	};

	return (
		<HSModal
			isVisible={isVisible}
			title={createMediaJson ? t('Add media') : t('Add Stream')}
			onClose={onClose}
			onHelp={
				values.streamType === 'contentflow' ? () => openURL('https://forum.hellospaces.de/t/contentflow-streams/34') : undefined
			}
		>
			<StreamCreateForm
				hidePreviewImage={createMediaJson}
				testID={TESTIDPREFIX}
				values={values}
				errors={errors}
				onSubmit={() => _createStream()}
				onSelect={hideSelection ? undefined : (streamId) => onSubmit(streamId)}
				isLoading={isLoading}
				onChange={(values) => setValues(values)}
				isModal
				hideMediaItem={hideMediaItem}
				isSelfService={isSelfService}
				hideLanguage={createMediaJson}
			/>
		</HSModal>
	);
};
