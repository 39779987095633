import React from 'react';
import { normalizeFontSize } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { TFunctionResult } from 'i18next';
import { Text as RNText, TextProps } from 'react-native';

interface IWelcomeHintComponent extends TextProps {
	bold?: boolean;
	center?: boolean;
	children?: string | string[] | number | Element | null | TFunctionResult;
}

export const WelcomeHint = (props: IWelcomeHintComponent) => {
	const { bold, center, children, style } = props;
	const { theme } = useTheme();

	return (
		<RNText
			{...props}
			style={[
				{
					color: theme.formgray,
					fontSize: normalizeFontSize(14),
					fontWeight: bold ? 'bold' : 'normal',
					textAlign: center ? 'center' : 'left'
				},
				style
			]}
		>
			{children}
		</RNText>
	);
};
