import React from 'react';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import moment from 'moment';
import { IMeetingTimeRangeInactive } from './MeetingTimeSelect';
import { ITimeSlot } from 'config/interfaces';
import { isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';

interface IMeetingTimeSelectListItem {
	slot: IMeetingTimeRangeInactive;
	isActive: boolean;
	onPress: () => void;
	isDisabled?: boolean;
	unavailableTimes?: ITimeSlot[];
	singleSelect?: boolean;
	style?: ViewStyle;
	isProhibited?: boolean;
}

export const MeetingTimeSelectListItem = (props: IMeetingTimeSelectListItem) => {
	const { slot, isActive, onPress, isDisabled, unavailableTimes, singleSelect, style, isProhibited } = props;
	const { theme } = useTheme();

	const _isActive = () => {
		return isActive || (unavailableTimes && unavailableTimes.find((e) => e.start === slot.start && e.end === slot.end) !== undefined);
	};

	const _getColor = () => {
		if (
			slot.partnerName ||
			(unavailableTimes && unavailableTimes.find((e) => e.start === slot.start && e.end === slot.end) !== undefined)
		) {
			return theme.lightgray;
		}

		if (isProhibited) {
			return theme.danger;
		}

		return _isActive() ? theme.primary : theme.contentBackgroundColor ?? theme.background;
	};

	return (
		<ChildButton
			testID={`meeting_time_${slot.start}`}
			onPress={onPress}
			isDisabled={isDisabled}
			style={{
				alignItems: 'center',
				paddingRight: 5,
				marginBottom: 10,
				...style
			}}
		>
			<View
				style={{
					padding: 10,
					borderWidth: 1,
					borderColor: isProhibited ? theme.primaryContrast : theme.primary,
					backgroundColor: _getColor(),
					borderRadius: 5
				}}
			>
				<Text
					bold
					numberOfLines={1}
					adjustsFontSizeToFit
					style={{ fontSize: 12, color: _isActive() ? theme.primaryContrast : theme.text }}
				>
					{moment(slot.start).format('HH:mm')}
				</Text>
				<Text
					bold
					numberOfLines={1}
					adjustsFontSizeToFit
					style={{ fontSize: 12, color: _isActive() ? theme.primaryContrast : theme.text }}
					center
				>{` - `}</Text>
				<Text
					bold
					numberOfLines={1}
					adjustsFontSizeToFit
					style={{ fontSize: 12, color: _isActive() ? theme.primaryContrast : theme.text }}
				>
					{moment(slot.end).format('HH:mm')}
				</Text>

				{!isEmptyString(slot.partnerName) && (
					<Text bold numberOfLines={1} adjustsFontSizeToFit style={{ fontSize: 12 }}>
						{slot.partnerName}
					</Text>
				)}
			</View>
		</ChildButton>
	);
};
