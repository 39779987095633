import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { H3, Text } from 'components/Text';
import { EDefaultIconSet, isEmptyString, normalizeFontSize } from 'helper';
import { useLike } from 'hooks/useLike';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { RoundButton } from 'components/Button/RoundButton';
import { IAttendee } from 'config/interfaces';
import { IVote } from 'config/interfaces/vote';
import { HSCard } from 'components/Card';
import { UserHeader } from 'components/User/UserHeader';
import { VoteMediaItem } from 'components/Vote/VoteMediaItem';
import { hsActionButtonMargin, hsBottomMargin, hsInnerPadding } from 'config/styleConstants';

interface IVoteAnswerTextListItem {
	vote: IVote;
	item: IVoteAnswer;
	isPreview?: boolean;
	onEdit?: () => void;
	itemIndex: number;
	disableAnmiation?: boolean;
	largerDesign?: boolean;
	resultsOnly?: boolean;
}

const LOWVALUE = 0.3;
const HIGHVALUE = 1;
const ANIMATIONDURATION = 600;
const ANIMATIONINITIALDELAY = 2500;

export const VoteAnswerTextListItem = (props: IVoteAnswerTextListItem) => {
	const { vote, item, isPreview, onEdit, itemIndex, disableAnmiation, largerDesign, resultsOnly } = props;
	const { t }: { t: any } = useTranslation();
	const { theme } = useTheme();
	const { getLikesForItem } = useLike();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const animatedOpacityValue = useRef(new Animated.Value(1));

	const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const [likedBy, setLikedBy] = useState<string[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isVisibilityLoading, setIsVisibilityLoading] = useState<boolean>(false);
	const [showControls, setShowControls] = useState<boolean>(false);
	const [containerWidth, setContainerWidth] = useState(0);
	const [animation, setAnimation] = useState<Animated.CompositeAnimation | undefined>(undefined);
	const [isAnimating, setIsAnimating] = useState<boolean>(false);
	const [hasPreviewLike, setHasPreviewLike] = useState<boolean>(false);
	const [likedByMe, setLikedByMe] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const likes = useSelector((store: IRootState) => store.like.likes);

	const toggleLike = useRematchDispatch((dispatch: Dispatch) => dispatch.like.toggleLike);
	const updateVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVoteAnswer);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (process.env.IS_TEST || disableAnmiation) {
			return;
		}
		const _animation = Animated.loop(
			Animated.sequence([
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					delay: ANIMATIONINITIALDELAY,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				})
			])
		);
		setAnimation(_animation);
	}, []);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (attendees && item.userId) {
			_attendee = attendees.find((e) => e.userId === item.userId);
		}

		setAttendee(_attendee);
	}, [attendees, item]);

	useEffect(() => {
		let res: string[] = [];
		res = getLikesForItem(item.tempId, 'voteanswer');
		if (res) setLikedBy(res);

		if (animation) {
			if (res?.includes(userInfos.userId)) {
				animation.stop();
				Animated.timing(animatedOpacityValue.current, {
					toValue: 1,
					duration: 100,
					useNativeDriver: true
				}).start();
				setIsAnimating(false);
			} else if (!isAnimating) {
				animation.start();
				setIsAnimating(true);
			}
		}
	}, [item, likes]);

	useEffect(() => {
		setShowControls((item.userId === userInfos.userId || iAmSpaceAdmin || iAmSpaceModerator) && !isPreview);
	}, [item, userInfos, iAmSpaceAdmin, iAmSpaceModerator, isPreview]);

	useEffect(() => {
		let _likedByMe = isPreview ? hasPreviewLike : false;
		likedBy.forEach((e) => {
			if (e.id === userInfos.userId) _likedByMe = true;
		});
		setLikedByMe(_likedByMe);
	}, [likedBy, hasPreviewLike, isPreview]);

	const _delete = async () => {
		setIsDeleteLoading(true);
		await updateVoteAnswer({ answer: { ...item, isDeleted: true }, isDeletion: true });
		setIsDeleteLoading(false);
	};

	const _updateVisibility = async () => {
		setIsVisibilityLoading(true);
		const _isAnswerVisible = item.status === 'Public';
		showAlert({
			title: _isAnswerVisible ? t('hideAnswer') : t('showAnswer'),
			message: _isAnswerVisible ? t('hideAnswerSubtitle') : t('showAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isAnswerVisible ? t('hideAnswer') : t('showAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsVisibilityLoading(true);
						await updateVoteAnswer({ answer: { ...item, status: _isAnswerVisible ? 'Waiting' : 'Public' } });
						setIsVisibilityLoading(false);
					}
				}
			]
		});

		setIsVisibilityLoading(false);
	};

	const _iAmAdminOrModerator = () => {
		return iAmSpaceAdmin || iAmSpaceModerator;
	};

	const _toggleLike = async () => {
		if (isPreview) {
			setHasPreviewLike(!hasPreviewLike);
		} else {
			setIsLikeLoading(true);
			await toggleLike({ contentType: 'voteanswer', itemId: item.tempId, type: 'like' });
			setIsLikeLoading(false);
		}
	};

	const _renderAnswer = () => {
		if (!isEmptyString(item.info)) {
			return (
				<View style={{ marginTop: 15, paddingLeft: 10 }}>
					<H3 largerText={largerDesign} style={{ marginBottom: 5 }}>
						Feedback
					</H3>
					<Text largerText={largerDesign}>{item.info}</Text>
				</View>
			);
		}

		return null;
	};

	const _renderLikes = () => {
		const likeCount = !isPreview ? likedBy.length : likedBy.length + (hasPreviewLike ? 1 : 0);

		return (
			<Animated.View style={{ opacity: animatedOpacityValue.current, flexDirection: 'row' }}>
				<RoundButton
					testID={`activevote_button_togglelike_${itemIndex}`}
					onPress={() => _toggleLike()}
					isLoading={isLikeLoading}
					icon={EDefaultIconSet.ThumbUp}
					isOutline={!likedByMe}
					badge={likeCount}
					boldBadge={likedByMe}
					size="sm"
					largerDesign={largerDesign}
				/>
			</Animated.View>
		);
	};

	const _renderControls = () => {
		const elements: ReactNode[] = [];

		if (_iAmAdminOrModerator() && vote.isModerated && !resultsOnly) {
			elements.push(
				<RoundButton
					key={`activevote_button_togglevisibility_${itemIndex}`}
					testID={`activevote_button_togglevisibility_${itemIndex}`}
					onPress={() => _updateVisibility()}
					isLoading={isVisibilityLoading}
					isOutline={item.status === 'Waiting'}
					icon={item.status === 'Public' ? EDefaultIconSet.AnswerPublic : EDefaultIconSet.AnswerWaiting}
					size="sm"
				/>
			);
		}
		if (_iAmAdminOrModerator() && onEdit && !resultsOnly) {
			elements.push(
				<View key={`votemoderation_button_edit_${item.id}`} style={{ marginLeft: hsActionButtonMargin }}>
					<RoundButton
						isOutline
						testID={`votemoderation_button_edit_${itemIndex}`}
						onPress={() => onEdit()}
						icon={EDefaultIconSet.Edit}
						size="sm"
					/>
				</View>
			);
		}

		if (!resultsOnly) {
			return (
				<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
					<RoundButton
						isOutline
						testID={`activevote_button_deleteanswer_${itemIndex}`}
						onPress={() =>
							showAlert({
								title: t('ConfirmDelete'),
								message: t('ConfirmDeleteSubtitle'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('Delete'),
										style: 'destructive',
										onPress: () => _delete()
									}
								]
							})
						}
						isLoading={isDeleteLoading}
						icon={EDefaultIconSet.Delete}
						color={theme.danger}
						size="sm"
					/>
					{elements.length > 0 && <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>{elements}</View>}
				</View>
			);
		}
		return null;
	};

	const _renderVisibility = () => {
		if (vote.isModerated && item.status === 'Waiting') {
			return (
				<View style={{ flex: 1 }}>
					<Text center style={{ fontSize: normalizeFontSize(12), color: theme.formgray, marginBottom: 15 }}>
						{t('AnswerWaiting')}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderMedia = () => {
		if (item.media) {
			return (
				<View
					style={{
						marginLeft: -hsInnerPadding,
						marginBottom: hsBottomMargin
					}}
				>
					<VoteMediaItem voteId={item.id} item={item.media} containerWidth={containerWidth} />
				</View>
			);
		}
		return null;
	};

	return (
		<HSCard testID={`activevote_item_voteanswer_${itemIndex}`} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
			{_renderVisibility()}
			<View style={{ flexDirection: 'row', marginBottom: 10, justifyContent: 'space-between' }}>
				<UserHeader
					largerDesign={largerDesign}
					attendee={attendee}
					isAnonymous={item.isAnonymousSubmission}
					createdAt={item.created_at}
				/>
				{_renderLikes()}
			</View>
			{_renderMedia()}
			<Text style={largerDesign ? { fontSize: 40 } : {}}>{item.value}</Text>
			{_renderAnswer()}
			{showControls && _renderControls()}
		</HSCard>
	);
};
