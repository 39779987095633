import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { IVote } from 'config/interfaces/vote';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ScrollView, View } from 'react-native';
import { H1 } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { addToClipboard, EDefaultIconSet, getVoteShareUrl, isEmptyString, IS_WEB, openURL } from 'helper';

import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { QRCodeShowCodeModal } from 'components/Modal/QRCode/QRCodeShowCodeModal';
import { VoteStatus } from 'components/Vote/VoteStatus';
import { NumberPresenter } from 'components/Info';
import { HSCard } from 'components/Card';
import { RoundButton } from 'components/Button';
import { hsTopScreenPadding } from 'config/styleConstants';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';
import { IVoteAnswer, IVoteResult } from 'config/interfaces';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ActiveVotingAdmin>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ActiveVotingAdmin>;
type RouteParams = StackParamList[ERoutes.ActiveVotingAdmin];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'activevoteadmin';

export const ActiveVoteAdminScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { exportVoteResultAsCSV } = useExport();

	const [vote, setVote] = useState<IVote | undefined>(undefined);
	const [isVoteLoading, setIsVoteLoading] = useState<boolean>(false);
	const [isVoteAnswersLoading, setIsVoteAnswersLoading] = useState<boolean>(false);
	const [isVoteResetLoading, setIsVoteResetLoading] = useState<boolean>(false);
	const [isVoteDeleteLoading, setIsVoteDeleteLoading] = useState<boolean>(false);
	const [isQRModalVisible, setIsQRModalVisible] = useState<boolean>(false);
	const [answerForThis, setAnswerForThis] = useState<IVoteResult | undefined>(undefined);

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const isMultiSpaceSocketConnected = useSelector((store: IRootState) => store.socket.isMultiSpaceSocketConnected);

	const updateVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVote);
	const resetVoteAnswers = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.resetVoteAnswers);
	const deleteVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.deleteVote);
	const loadVoteAnswersAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteAnswersAndStartSync);
	const closeMultiSpaceEventListener = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceEventListener);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);

	useEffect(() => {
		const vote = votes.find((e) => e.id === route.params.id);
		setVote(vote);
	}, [votes]);

	useEffect(() => {
		if (isMultiSpaceSocketConnected) {
			_reload();
		} else {
			closeMultiSpaceEventListener({ event: `${activeSpace?.spaceId}_voteanswers_${route.params.id}` });
		}
	}, [activeSpace && isMultiSpaceSocketConnected]);

	const _reload = async () => {
		setIsVoteAnswersLoading(true);
		await loadVoteAnswersAndStartSync({ reload: true, voteId: route.params.id, nosync: true });
		setIsVoteAnswersLoading(false);
	};

	useEffect(() => {
		if (voteAnswers && vote) {
			let _answerForThis: typeof answerForThis = undefined;
			switch (vote.votingType) {
				case 'text':
				case 'quiz':
				case 'survey':
					const _answers = voteAnswers.filter(
						(a) => !a.isResultObject && !a.isDeleted && a.spaceId === activeSpace?.spaceId && a.voteId === vote.id
					);
					setAnswerForThis({
						isResultObject: true,
						options: [],
						spaceId: vote.spaceId,
						voteId: vote.id,
						submissionCount: _answers.length
					});
					break;
				default:
					_answerForThis = voteAnswers.find(
						(a) => a.isResultObject && !a.isDeleted && a.spaceId === activeSpace?.spaceId && a.voteId === vote.id
					) as IVoteResult | undefined;
					setAnswerForThis(_answerForThis);
					break;
			}
		}
	}, [voteAnswers, vote]);

	const _updateVote = async (fieldName: string, value: any) => {
		if (vote) {
			setIsVoteLoading(true);
			await updateVote({ voteId: vote.id, vote: { [fieldName]: value } });
			setIsVoteLoading(false);
		}
	};

	const _renderActionButton = (
		key: string,
		icon: string,
		label: string,
		isActive?: boolean,
		isLoading?: boolean,
		color?: string,
		iconColor?: string
	) => {
		return (
			<View style={{ marginHorizontal: 5, marginBottom: 10, width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_${key}`}
					icon={icon}
					onPress={() => _handlePress(key)}
					title={label}
					isActive={isActive}
					isLoading={isLoading}
					isStacked
					color={color}
					iconColor={iconColor}
				/>
			</View>
		);
	};

	const _resetVoteAnswers = async () => {
		setIsVoteResetLoading(true);
		await resetVoteAnswers({ voteId: route.params.id });
		await _reload();
		setIsVoteResetLoading(false);
	};

	const _handlePress = (key: string) => {
		switch (key) {
			case 'edit':
				if (vote) {
					navigation.navigate(ERoutes.VotingEdit, { spaceId: activeSpace?.spaceId, id: vote.id, prohibitNavigation: false });
				}
				break;
			case 'present':
				if (vote && activeSpace) {
					navigation.navigate(ERoutes.ResultView, { spaceId: activeSpace.spaceId, id: route.params.id });
				}
				break;
			case 'sharePresenterWeb':
				if (vote?.speakerViewToken) {
					const url = `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}presentation/resultview/${encodeURIComponent(
						vote?.speakerViewToken
					)}`;
					showAlert({
						title: t('copyUrlAndShareForPresentation'),
						message: url,
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('openInNewTab'),
								onPress: () => openURL(`${url}newTabTrue`),
								style: 'default'
							},
							{
								text: t('copyUrl'),
								onPress: () => addToClipboard(url),
								style: 'default'
							}
						]
					});
				}
				break;
			case 'active':
				showAlert({
					title: vote?.isActive ? t('deactivateVote') : t('activateVote'),
					message: vote?.isActive ? t('deactivateVoteAlertSubtitle') : t('activateVoteAlertSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: vote?.isActive ? t('deactivateVote') : t('activateVote'),
							onPress: () => _updateVote('isActive', !vote?.isActive),
							style: 'default'
						}
					]
				});
				break;
			case 'share':
				if (vote && activeSpace) {
					setIsQRModalVisible(true);
				}
				break;
			case 'moderate':
				if (vote) {
					navigation.navigate(ERoutes.VotingModeration, { spaceId: activeSpace?.spaceId, id: vote.id });
				}
				break;
			case 'analytics':
				if (vote) {
					navigation.navigate(ERoutes.VoteAnalytics, { spaceId: activeSpace?.spaceId, id: vote.id });
				}
				break;
			case 'reset':
				showAlert({
					title: t('Reset Vote Answers'),
					message: t('Reset Vote Answers Subtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('Delete Answers'),
							onPress: () => _resetVoteAnswers(),
							style: 'destructive'
						}
					]
				});
				break;
			case 'participate':
				if (vote) {
					if (vote?.votingType === 'externalUrl') {
						if (vote.externalUrl) {
							openURL(vote.externalUrl);
						}
					} else {
						navigation.navigate(ERoutes.ActiveVoting, { spaceId: activeSpace?.spaceId, id: vote.id });
					}
				}
				break;
			case 'results':
				if (vote) {
					setVotingShowResults(true);
					navigation.navigate(ERoutes.ActiveVoting, { spaceId: activeSpace?.spaceId, id: vote.id });
				}
				break;
			case 'addModerator':
				navigation.navigate(ERoutes.SpaceAdmins, { spaceId: activeSpace?.spaceId, state: 'Moderators' });
				break;
			case 'delete':
				showAlert({
					title: t('Delete Vote'),
					message: t('DeleteVoteSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('Delete Vote'),
							onPress: () => _deleteCurrentVote(),
							style: 'destructive'
						}
					]
				});
				break;
			case 'export':
				exportVoteResultAsCSV(route.params.id);
				break;
			default:
				break;
		}
	};

	const _deleteCurrentVote = async () => {
		if (vote?.id) {
			setIsVoteDeleteLoading(true);
			const res = await deleteVote({ voteId: vote?.id });
			if (res) {
				setIsVoteDeleteLoading(false);
				navigation.goBack();
			}
		}
	};

	const _isModeratable = () => {
		switch (vote?.votingType) {
			case 'text':
			case 'wordCloud':
				return true;
			default:
				return false;
		}
	};

	const _renderVote = () => {
		if (vote) {
			return (
				<View>
					<HSCard>
						<View style={{ paddingHorizontal: EHorizontalScreenPadding.Wide, marginBottom: vote.isActive ? 20 : 0 }}>
							<H1 testID="vote_header" center noBottomMargin>
								{`${vote.question} (ID: ${vote.id})`}
							</H1>
						</View>
						{vote.isActive ? <VoteStatus testID="votestatus" status="live" label={t('VoteIsLive')} /> : null}
					</HSCard>
					<NumberPresenter
						number={answerForThis?.submissionCount ?? 0}
						title={t('answerAmount')}
						isLoading={isVoteAnswersLoading}
					/>
					<HSCard>
						<View
							style={{
								flexDirection: 'row',
								flexWrap: 'wrap',
								justifyContent: 'space-between'
							}}
						>
							{_renderActionButton(
								'active',
								vote.isActive ? EDefaultIconSet.Pause : EDefaultIconSet.Play,
								vote.isActive ? t('Deactivate') : t('Activate'),
								false,
								isVoteLoading,
								vote.isActive ? theme.danger : theme.success
							)}
							{_renderActionButton('edit', EDefaultIconSet.Edit, t('Edit'))}
							{_renderActionButton('participate', EDefaultIconSet.SubmitTextVoteAnswer, t('participate'))}
							{(vote?.votingType === 'survey' || vote?.hideResults) &&
								_renderActionButton('results', EDefaultIconSet.Result, t('result'))}
						</View>
					</HSCard>
					{vote?.votingType !== 'externalUrl' && (
						<HSCard>
							<View
								style={{
									flexDirection: 'row',
									flexWrap: 'wrap',
									justifyContent: 'space-between'
								}}
							>
								{_renderActionButton('share', EDefaultIconSet.Share, t('Share'))}
								{!IS_WEB && _renderActionButton('present', EDefaultIconSet.Present, t('Speaker View'))}
								{IS_WEB && _renderActionButton('sharePresenterWeb', EDefaultIconSet.Present, t('linkToPresenterView'))}
								{_renderActionButton('export', EDefaultIconSet.Export, t('ExportVotingResult'))}
							</View>
						</HSCard>
					)}
					<HSCard>
						<View
							style={{
								flexDirection: 'row',
								flexWrap: 'wrap',
								justifyContent: 'space-between'
							}}
						>
							{_isModeratable() && _renderActionButton('moderate', EDefaultIconSet.Moderate, t('Moderate'))}
							{_renderActionButton('addModerator', EDefaultIconSet.User, t('addModerator'))}

							{vote?.votingType !== 'externalUrl' &&
								_renderActionButton('reset', EDefaultIconSet.Archive, t('ResetVote'), false, isVoteResetLoading)}
							{_renderActionButton(
								'delete',
								EDefaultIconSet.Delete,
								t('deleteVote'),
								false,
								isVoteDeleteLoading,
								theme.danger,
								theme.white
							)}
						</View>
					</HSCard>
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderVote()}
				{activeSpace && vote && (
					<QRCodeShowCodeModal
						isVisible={isQRModalVisible}
						title={vote?.question}
						value={getVoteShareUrl(activeSpace?.spaceId, vote?.id)}
						onClose={() => setIsQRModalVisible(false)}
						cta={t('ShareMessageVote')}
					/>
				)}
			</ScrollView>
		</ScreenContainer>
	);
};

export const ActiveVoteAdminScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title="Vote Administration" />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
