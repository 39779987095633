import { RoundButton } from 'components/Button';
import { TPlayableMediaType } from 'config/interfaces';
import { getIconByMediaTyoe } from 'helper';
import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

interface IMediaTypeChooser {
	testID: string;
	value?: TPlayableMediaType;
	onChange: (value: TPlayableMediaType) => void;
	hideMediaItem?: boolean;
	allowedTypes?: string[];
}

export const MediaTypeChooser = (props: IMediaTypeChooser) => {
	const { testID, value, onChange, hideMediaItem, allowedTypes } = props;
	const { t } = useTranslation();

	const _renderButton = (mediaType: TPlayableMediaType, title: string) => {
		if (!allowedTypes || allowedTypes.includes(mediaType)) {
			return (
				<View style={{ width: '33%', marginBottom: 10 }}>
					<RoundButton
						isOutline={value !== mediaType}
						isStacked
						testID={`${testID}_mediatype_${mediaType}`}
						icon={getIconByMediaTyoe(mediaType)}
						onPress={() => onChange(mediaType)}
						title={title}
						alignSelf="center"
						iconText={mediaType === 'zoom' ? 'Z' : undefined}
					/>
				</View>
			);
		}

		return null;
	};

	return (
		<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
			{_renderButton('contentflow', 'Contentflow')}
			{_renderButton('youtube', 'YouTube')}
			{_renderButton('vimeo', 'Vimeo')}
			{_renderButton('iFrame', 'iFrame URL')}
			{!hideMediaItem && _renderButton('mediaLibrary', t('Media Library Content'))}
			{_renderButton('upload', 'Upload')}
			{_renderButton('externalMeeting', t('external'))}
			{_renderButton('zoom', t('Zoom'))}
			{_renderButton('networking', t('networking'))}
		</View>
	);
};
