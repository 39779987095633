import { IPushNotification } from 'config/interfaces';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { FlashSectionList } from 'components/List';
import { PushNotification, PUSHNOTIFICATION_LISTITEM_HEIGHT } from './PushNotification';
import { PushNotificationListHeader } from './PushNotificationListHeader';
import { isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';

interface IPushNotificationList {
	TESTIDPREFIX: string;
	notifications: IPushNotification[];
	onItemPress?: (item: IPushNotification) => void;
	onSelect?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	selectedEntries?: number[];
	isDeleteLoading?: number[];
	searchTerm?: string;
	noDataComponent?: JSX.Element;
}

interface IPushListData {
	header: string;
	items: IPushNotification[];
}

export const PushNotificationList = (props: IPushNotificationList) => {
	const {
		TESTIDPREFIX,
		notifications,
		onItemPress,
		onSelect,
		onDelete,
		onEdit,
		selectedEntries,
		isDeleteLoading,
		searchTerm,
		noDataComponent
	} = props;
	const { t } = useTranslation();

	const [sections, setSections] = useState<(string | IPushNotification)[]>([]);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	useEffect(() => {
		const _sections: IPushListData[] = [];
		let newSections: typeof sections = [];

		let _items = [...notifications];

		if (searchTerm && searchTerm.length > 0) {
			const lowerSearch = searchTerm.toLowerCase();
			_items = _items.filter((e) => {
				if (
					e.title?.toLowerCase().includes(lowerSearch) ||
					e.subtitle?.toLowerCase().includes(lowerSearch) ||
					e.webSubtitle?.toLowerCase().includes(lowerSearch)
				) {
					return true;
				}

				if (e.sendingInformation?.sentTo) {
					for (const userId of e.sendingInformation.sentTo) {
						const attendee = attendees.find((e) => e.userId === userId);
						if (attendee && `${attendee.firstName} ${attendee.lastName}`.toLowerCase().includes(lowerSearch)) {
							return true;
						}
					}
				}

				return false;
			});
		}

		_items.forEach((notification) => {
			const date = moment(notification.sendOn).isValid()
				? moment(notification.sendOn).format('DD.MM.YYYY')
				: moment().format('DD.MM.YYYY');

			const found = _sections.find((e) => e.header === date);
			if (found) {
				found.items.push(notification);
			} else {
				_sections.push({
					header: date,
					items: [notification]
				});
			}
		});

		_sections.forEach((section) => {
			const items = section.items.sort((a, b) => {
				const aVal = a.sendOn ?? a.created_at;
				const bVal = b.sendOn ?? b.created_at;

				return moment(aVal).isBefore(bVal) ? 1 : -1;
			});

			const _pending = items.filter((e) => !_hasNotificationBeenSent(e));
			const _sent = items.filter((e) => _hasNotificationBeenSent(e));

			newSections.push(section.header);
			newSections = newSections.concat(_pending.concat(_sent));
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setSections(newSections);
	}, [notifications, searchTerm]);

	const _hasNotificationBeenSent = (notification: IPushNotification) => {
		if (notification.hasBeenSend) {
			return true;
		}
		if (notification.receiver === 'web' && !notification.sendOn) {
			return true;
		}
		return false;
	};

	const _renderList = () => {
		if (sections.length === 0 && (!waitingForSocketResponse?.pushnotifications || !isEmptyString(searchTerm))) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			if (noDataComponent) {
				return noDataComponent;
			}
			return <NoData type="NoPushNotifications" />;
		}

		return (
			<FlashSectionList
				data={sections}
				renderItem={(renderItem) => (
					<View style={{ paddingRight: IS_WEB ? 10 : 0 }}>
						<PushNotification
							testID={`${TESTIDPREFIX}_notification_${renderItem.index}`}
							item={renderItem.item}
							isSelected={selectedEntries?.includes(renderItem.item.id)}
							onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
							onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
							onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
							onPress={onItemPress}
							isDeleteLoading={isDeleteLoading?.includes(renderItem.item.id)}
						/>
					</View>
				)}
				extraData={[onSelect, selectedEntries]}
				renderSectionHeader={(renderItem) => <PushNotificationListHeader text={renderItem.item} />}
				estimatedItemSize={PUSHNOTIFICATION_LISTITEM_HEIGHT}
				hideIndexButtons
			/>
		);
	};

	return <View style={{ flex: 1 }}>{_renderList()}</View>;
};
