import { ILauncherSpace, IRegistrationField } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView } from 'react-native';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { useForm } from 'hooks/useForm';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { showFormErrorToast, validateForm } from 'helper';

interface IRequiredProfileFieldsModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: () => void;
	space?: ILauncherSpace;
}

const TESTIDPREFIX = 'requiredprofilefieldsmodal';

export const RequiredProfileFieldsModal = (props: IRequiredProfileFieldsModal) => {
	const { isVisible, onClose, onSubmit, space } = props;
	const { renderFormField, getFormSchema } = useForm();
	const { t } = useTranslation();

	const [requiredFields, setRequiredFields] = useState<IRegistrationField[]>([]);
	const [formValues, setFormValues] = useState({});
	const [formErrors, setFormErrors] = useState({});
	const [isUpdateProfileLoading, setIsUpdateProfileLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);

	useEffect(() => {
		let _fields =
			space?.registrationFields?.fields?.filter((e) => {
				if (e.fieldType === 'password' || e.fieldName === 'id' || e.visibility === 'never') {
					return false;
				}

				return true;

				// LEAVE THIS HERE

				// if (e.isRequired) {

				//     switch (e.fieldType) {
				//         case 'avatar':
				//             if (!profile || !profile[e.fieldName] || !profile[e.fieldName].url) {
				//                 return true
				//             }
				//             break
				//         case 'boolean':
				//             if (!profile || !profile.hasOwnProperty(e.fieldName)) {
				//                 return true
				//             }
				//             break
				//         default:
				//             if (!profile || isEmptyString(profile[e.fieldName])) {
				//                 return true
				//             }
				//             break
				//     }
				// }

				// if (e.fieldType === 'category') {
				//     if (e.isRequired && (!profile || !profile[e.fieldName])) {
				//         return true
				//     }

				//     let selected: string[] = []
				//     if (profile && profile[e.fieldName]) {
				//         const splitted = profile[e.fieldName].split(',').filter(e => !isEmptyString(e))
				//         e.options?.forEach(option => {
				//             if (splitted.includes(option.key)) {
				//                 selected.push(option.key)
				//             }
				//         })
				//     }

				//     if (e.minCount && e.minCount > selected.length) {
				//         return true
				//     }

				//     if (e.maxCount && e.maxCount < selected.length) {
				//         return true
				//     }
				// }

				// return false
			}) ?? [];

		if (_fields.length === 0 && isVisible) {
			onSubmit();
		}

		setRequiredFields(_fields);
	}, [profile, space, isVisible]);

	useEffect(() => {
		let _values = {};
		if (profile) {
			requiredFields.forEach((field) => {
				if (profile.hasOwnProperty(field.fieldName)) {
					_values[field.fieldName] = profile[field.fieldName];
				}
			});
		}

		setFormValues(_values);
	}, [requiredFields, profile]);

	const _updateProfile = async () => {
		const errors = await validateForm(getFormSchema(requiredFields, formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors, requiredFields);
			return;
		} else {
			setFormErrors({});
		}

		setIsUpdateProfileLoading(true);
		const updateSuccess = await updateUser({ user: formValues });
		setIsUpdateProfileLoading(false);
		if (updateSuccess) {
			onSubmit();
		}
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			title={t('CompleteYourProfile')}
			onSubmit={() => _updateProfile()}
			isSubmitLoading={isUpdateProfileLoading}
		>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				style={{ flex: 1 }}
			>
				{requiredFields.map((field) =>
					renderFormField('attendee', TESTIDPREFIX, formValues, formErrors, field, (values) => setFormValues(values))
				)}
			</ScrollView>
		</HSModal>
	);
};
