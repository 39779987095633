import React from 'react';
import { View } from 'react-native';

import {
	ICreateNetworkingRoomFormErrors,
	ICreateNetworkingRoomFormValues,
	IUpdateNetworkingRoomFormErrors,
	IUpdateNetworkingRoomFormValues
} from 'config/interfaces';
import { HSCard } from 'components/Card';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';

interface INetworkingRoomForm {
	values: ICreateNetworkingRoomFormValues | IUpdateNetworkingRoomFormValues;
	errors: ICreateNetworkingRoomFormErrors | IUpdateNetworkingRoomFormErrors;
	onChange: (val: ICreateNetworkingRoomFormValues | IUpdateNetworkingRoomFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'networkingroomform';

export const NetworkingRoomForm = (props: INetworkingRoomForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('networkingroom');
	const { renderFormField } = useForm();

	return (
		<View>
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) => renderFormField('networkingroom', TESTIDPREFIX, values, errors, field, onChange))}
				</HSCard>
			))}
		</View>
	);
};
