import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { H1, Text } from 'components/Text';
import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { AddUserModal } from './AddUserModal';
import { Dimensions, ScrollView } from 'react-native';
import { SpaceAdministratorList } from 'components/SpaceAdministratorList';
import { HSCard } from 'components/Card';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useHeaderHeight } from '@react-navigation/elements';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SpaceAdmins>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SpaceAdmins>;
type RouteParams = StackParamList[ERoutes.SpaceAdmins];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export type TSpaceAdminState = 'Administrators' | 'Moderators';

export const SpaceAdminsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const screenHeight = Dimensions.get('window').height;
	const headerHeight = useHeaderHeight();
	const { activeSpace } = useSpace();

	const [state, setState] = useState<TSpaceAdminState>('Administrators');
	const [isAddUserModalVisible, setIsAddUserModalVisible] = useState<boolean>(false);
	const [isAddUserLoading, setIsAddUserLoading] = useState<boolean>(false);
	const [isRemoveUserLoading, setIsRemoveUserLoading] = useState<Record<string, boolean>>({});

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const getSpaceSubscription = useRematchDispatch((dispatch: Dispatch) => dispatch.subscription.getSpaceSubscription);

	useEffect(() => {
		setState(route.params.state);

		navigation.setOptions({
			onRightNavPress: () => setIsAddUserModalVisible(true),
			title: t(route.params.state)
		});
		getSpaceSubscription({});
	}, []);

	const _addUser = async (userId: number) => {
		if (activeSpace) {
			const admins = activeSpace?.admins ? activeSpace.admins.map((user) => user.id) : [];
			const moderators = activeSpace?.moderators ? activeSpace.moderators.map((user) => user.id) : [];

			if (state === 'Administrators') {
				const isAlreadyAdmin = activeSpace?.admins?.find((e) => e.id === userId);
				if (isAlreadyAdmin) {
					showAlert({
						title: 'Error',
						message: t('UserIsAlreadyAdmin'),
						buttons: [
							{
								text: t('Ok'),
								style: 'cancel',
								onPress: async () => {
									if (process.env.IS_TEST) {
										setIsAddUserModalVisible(false);
									}
								}
							}
						]
					});
				}
				admins.push(userId);
			} else if (state === 'Moderators') {
				const isAlreadyModerator = activeSpace?.moderators?.find((e) => e.id === userId);
				if (isAlreadyModerator) {
					showAlert({
						title: 'Error',
						message: t('UserIsAlreadyModerator'),
						buttons: [
							{
								text: t('Ok'),
								style: 'cancel',
								onPress: async () => {
									if (process.env.IS_TEST) {
										setIsAddUserModalVisible(false);
									}
								}
							}
						]
					});
					return;
				}
				moderators.push(userId);
			}
			setIsAddUserLoading(true);
			const res = await updateSpace({
				data: {
					title: activeSpace?.title,
					spaceId: activeSpace?.spaceId,
					admins,
					moderators
				}
			});
			setIsAddUserLoading(false);
			if (res) {
				setIsAddUserModalVisible(false);
			}
		}
	};

	const _removeUser = async (userId: string) => {
		if (activeSpace) {
			const user =
				state === 'Administrators'
					? activeSpace.admins?.find((e) => e.userId === userId)
					: activeSpace.moderators?.find((e) => e.userId === userId);

			if (!user) {
				return;
			}

			showAlert({
				title: state === 'Administrators' ? t('RemoveAdminTitle') : t('RemoveModeratorTitle'),
				message:
					state === 'Administrators'
						? t('RemoveAdminSubtitle').replace('%NAME%', `${user?.firstName} ${user?.lastName}`)
						: t('RemoveModeratorSubtitle').replace('%NAME%', `${user?.firstName} ${user?.lastName}`),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Revoke rights'),
						style: 'destructive',
						onPress: async () => {
							setIsRemoveUserLoading({ ...isRemoveUserLoading, [userId]: true });
							let admins = activeSpace?.admins ? activeSpace.admins.map((user) => user.id) : [];
							let moderators = activeSpace?.moderators ? activeSpace.moderators.map((user) => user.id) : [];
							const res = await updateSpace({
								data: {
									title: activeSpace?.title,
									spaceId: activeSpace?.spaceId,
									admins: admins.filter((id) => id !== user.id),
									moderators: moderators.filter((id) => id !== user.id)
								}
							});
							setIsRemoveUserLoading({ ...isRemoveUserLoading, [userId]: false });
						}
					}
				]
			});
		}
	};

	const _renderMyRole = () => {
		const foundAdmin = activeSpace?.admins?.find((a) => a.id === profile?.id);
		const foundMod = activeSpace?.moderators?.find((a) => a.id === profile?.id);

		if (foundAdmin && route.params.state === 'Administrators') {
			return <Text style={{ marginBottom: hsBottomMargin }}>{t('youAreAdmin')}</Text>;
		}
		if (foundMod && route.params.state === 'Moderators') {
			return <Text style={{ marginBottom: hsBottomMargin }}>{t('youAreModerator')}</Text>;
		}
		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				style={{ height: IS_WEB ? screenHeight - headerHeight : '100%' }}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<H1>{route.params.state === 'Administrators' ? t('AdminRights') : t('ModeratorRights')}</H1>
					{_renderMyRole()}
					<SpaceAdministratorList
						items={state === 'Administrators' ? activeSpace?.admins : activeSpace?.moderators}
						listEmpty={t('NoModeratorsInSpace')}
						onDeletePress={_removeUser}
						isLoading={isRemoveUserLoading}
					/>
				</HSCard>
			</ScrollView>
			<AddUserModal
				testID={`spaceadmins`}
				isVisible={isAddUserModalVisible}
				onSubmit={_addUser}
				isLoading={isAddUserLoading}
				onClose={() => setIsAddUserModalVisible(false)}
				type={route.params.state === 'Administrators' ? 'admin' : 'moderator'}
			/>
		</ScreenContainer>
	);
};

export const SpaceAdminsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderIconButton testID="header_button_add" icon={EDefaultIconSet.Add} onPress={props.options.onRightNavPress} />
		</NavigationHeader>
	);
};
