import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';

import { isEmptyString, IS_ANDROID, IS_WEB } from 'helper';
import { HSModal } from '../Modal';
import { Text } from 'components/Text';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { Keyboard, ScrollView } from 'react-native';
import { FormTextInput } from 'components/Form';
import { showToast } from 'helper/toast';
import { ILauncherSpace } from 'config/interfaces';
import { useSpace } from 'hooks/useSpace';
import * as Application from 'expo-application';

interface ISupportRequestModal {
	isVisible: boolean;
	onClose: () => void;
	space?: ILauncherSpace;
}

const testIdPrefix = 'supportrequestmodal';

export const SupportRequestModal = (props: ISupportRequestModal) => {
	const { isVisible, onClose, space } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);

	const createSupportRequest = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.createSupportRequest);

	const [values, setValues] = useState({
		subject: '',
		text: '',
		email: '',
		name: '',
		ticketcode: '',
		deviceInfo: {
			os: IS_WEB ? 'Web' : IS_ANDROID ? 'Android' : 'iOS',
			buildNumber: Application.nativeBuildVersion,
			nativeVersion: Application.nativeApplicationVersion
		}
	});

	useEffect(() => {
		if (isVisible) {
			if (profile) {
				let _values = values;
				if (
					profile?.tickets &&
					activeSpace &&
					profile.tickets[activeSpace?.spaceId] &&
					profile.tickets[activeSpace?.spaceId]?.ticketcode
				) {
					const code =
						profile?.tickets &&
						activeSpace &&
						profile.tickets[activeSpace?.spaceId] &&
						profile.tickets[activeSpace?.spaceId]?.ticketcode;
					_values.ticketcode = code;
				}
				_values.name = `${profile.firstName} ${profile.lastName}`;
				if (isEmptyString(_values.email && profile?.email)) {
					_values.email = profile.email;
				}
				if (isEmptyString(_values.text)) {
					_values.text = `${t('Hello')},



${t('Kind regards')}
${profile.firstName}${isEmptyString(profile.firstName) ? '' : ' '}${profile.lastName}`;
				}
				setValues({ ..._values });
				return;
			}
			if (userInfos) {
				let _values = values;
				_values.name = `${userInfos.firstName} ${userInfos.lastName}`;
				if (isEmptyString(_values.email && userInfos?.email)) {
					_values.email = userInfos.email;
				}
				if (isEmptyString(_values.text)) {
					_values.text = `${t('Hello')},



${t('Kind regards')}
${userInfos.firstName}${isEmptyString(userInfos.firstName) ? '' : ' '}${userInfos.lastName}`;
				}
				setValues({ ..._values });
				return;
			}
		} else {
			setValues({
				...values,
				subject: '',
				text: '',
				email: values.email,
				name: values.name
			});
		}
	}, [userInfos, profile, isVisible, activeSpace]);

	const _createSupportRequest = async () => {
		setIsLoading(true);
		let res = false;
		if (space || activeSpace) {
			res = await createSupportRequest({
				subject: values.subject,
				text: values.text,
				email: values.email,
				name: values.name,
				ticketcode: values.ticketcode,
				deviceInfo: values.deviceInfo,
				userId: profile?.userId ?? userInfos.userId,
				space: space ?? activeSpace!
			});
		}
		setIsLoading(false);
		return res;
	};

	const _getTicketProviderHint = () => {
		switch (space?.ticketSettings?.ticketProvider) {
			case 'xingevents':
				return t('xingTicketHint');

			default:
				return '';
		}
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			onSubmit={async () => {
				const res = await _createSupportRequest();
				if (res) {
					onClose();
					showToast('success', t('SupportRequestSend'), t('theOrganizerWillMessageYou'));
				} else {
					showToast('error', t('SupportRequestSendError'));
				}
			}}
			isSubmitDisabled={
				isEmptyString(values.subject) ||
				isEmptyString(values.text) ||
				(space?.ticketSettings?.ticketRequired && isEmptyString(values.ticketcode))
			}
			isSubmitLoading={isLoading}
		>
			<Text style={{ marginBottom: hsBottomMargin }}>{t('supportRequestModalHeader')}</Text>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				style={{ marginHorizontal: -hsInnerPadding }}
				contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
			>
				{(!values?.email || !profile?.email) && (
					<FormTextInput
						isDisabled={isLoading}
						label={t('email')}
						editable={true}
						isRequired
						testID={`${testIdPrefix}_textinput_email`}
						value={values.email}
						onChangeText={(val) => setValues({ ...values, email: val })}
						skipTabPress
					/>
				)}
				{!(profile?.firstName || userInfos.firstName) && !(profile?.lastName || userInfos.lastName) && (
					<FormTextInput
						isDisabled={isLoading}
						label={t('Name')}
						isRequired
						testID={`${testIdPrefix}_textinput_name`}
						value={values.name}
						onChangeText={(val) => setValues({ ...values, name: val })}
						skipTabPress
					/>
				)}
				{space?.ticketSettings?.ticketRequired && !activeSpace && (
					<FormTextInput
						isDisabled={isLoading}
						label={t('Ticketcode')}
						isRequired
						hint={`${t('supportRequestTicketcodeHint')} ${_getTicketProviderHint()}`}
						testID={`${testIdPrefix}_textinput_ticketcode`}
						value={values.ticketcode}
						onChangeText={(val) => setValues({ ...values, ticketcode: val })}
						skipTabPress
					/>
				)}
				<FormTextInput
					isDisabled={isLoading}
					label={t('Subject')}
					isRequired
					hint={t('supportRequestSubjectHint')}
					testID={`${testIdPrefix}_textinput_subject`}
					value={values.subject}
					onChangeText={(val) => setValues({ ...values, subject: val })}
					skipTabPress
				/>
				<FormTextInput
					isDisabled={isLoading}
					multiline
					label={t('Description')}
					isRequired
					hint={t('supportRequestTextHint')}
					testID={`${testIdPrefix}_textinput_description`}
					value={values.text}
					onChangeText={(val) => setValues({ ...values, text: val })}
					skipTabPress
				/>
			</ScrollView>
		</HSModal>
	);
};
