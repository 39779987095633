import { HSCard } from 'components/Card/HSCard';
import { HSDragCard } from 'components/Card/HSDragCard';
import { IFormBase, TMedia } from 'config/interfaces';
import { EDefaultIconSet, IS_ANDROID, swapArrayItems } from 'helper';
import React from 'react';
import { Vibration, View } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { FormError } from './FormError';
import { FormHint } from './FormHint';
import { FormLabel } from './FormLabel';
import { FormMediaPicker } from './Image';
import { useSpace } from 'hooks/useSpace';

interface IFormFileSelection extends IFormBase {
	media?: TMedia[];
	onChange: (value?: TMedia[]) => void;
	scrollEnabled?: boolean;
}

export const FormFileSelection = (props: IFormFileSelection) => {
	const { media, onChange, testID, label, isRequired, hint, error, isDisabled, scrollEnabled } = props;
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const _removeImage = (index: number) => {
		const _options = media;
		_options?.splice(index, 1);
		onChange(_options);
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!media) {
			return;
		}
		const list: TMedia[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, media);

		onChange(list);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!media) {
			return;
		}

		const list = [...media];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange(list);
	};

	const _renderDragImage = (params) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${testID}_image_${index}`}
				isActive={isActive}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (media?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
			>
				<FormMediaPicker
					isDisabled={isDisabled}
					isOutline
					formStyle={{ flex: 1, marginBottom: 0 }}
					testID={`${testID}_filepicker_file_${index}`}
					value={item}
					mediaTypes="files"
					onChange={(val) => {
						const _media = media ?? [];
						if (val) {
							_media[index] = val;
							onChange(_media);
						} else {
							_media?.splice(index, 1);
							onChange(_media);
						}
					}}
					hasDeleteButton
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
				/>
				<FormHint testID="abc" hint={item.name} />
			</HSDragCard>
		);
	};

	return (
		<View>
			<FormLabel testID={`${testID}_label`} label={label} isRequired={isRequired} />
			<FormHint testID={`${testID}_hint`} hint={hint} />

			<DraggableFlatList
				data={media ?? []}
				renderItem={(params) => _renderDragImage(params)}
				keyExtractor={(item, index) => `${testID}_filpicker_file_${index}`}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					onChange(data);
				}}
			/>
			{error && (
				<HSCard>
					<FormError testID={`${testID}_error`} error={error} />
				</HSCard>
			)}
			<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
				<FormMediaPicker
					isDisabled={isDisabled}
					buttonOnly
					formStyle={{ flex: 1, marginBottom: 0 }}
					testID={`${testID}_filepicker_file_newfile`}
					value={null}
					icon={EDefaultIconSet.Add}
					mediaTypes="files"
					onChange={(val) => {
						const _media = media ?? [];
						if (val) {
							_media.push(val);
							onChange(_media);
						}
					}}
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
				/>
			</View>
		</View>
	);
};
