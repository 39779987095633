import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import { ILike } from 'config/interfaces';
import { hasEntryChanged, isEmptyString, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import { IToggleLikeParams } from 'rematch/interfaces';
import { RootModel } from './index';

type IDefaultState = {
	likes: ILike[];
	isLikeBackdropVisible: boolean;
};

export const like = createModel<RootModel>()({
	state: {
		likes: [],
		isLikeBackdropVisible: false
	} as IDefaultState,
	reducers: {
		addLikes(state, { likes }: { likes: ILike[] }) {
			rematchLog('addLikes');
			const _newLikes = state.likes ? [...state.likes] : [];
			likes.forEach((like) => {
				const idx = _newLikes.findIndex((e) => e.itemId === like.itemId && e.contentType === like.contentType);
				if (idx !== -1) {
					_newLikes[idx] = like;
				} else {
					_newLikes.push(like);
				}
			});
			return {
				...state,
				likes: _newLikes
			};
		},
		setIsLikeBackdropVisible(state, isLikeBackdropVisible) {
			rematchLog('setIsLikeBackdropVisible');
			return {
				...state,
				isLikeBackdropVisible
			};
		}
	},
	effects: (dispatch) => ({
		async startLikeSync(payload, store) {
			try {
				if (store.socket.multiSpaceSocket && !store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_likes`)) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_likes`, (data) => {
						const _load = !Array.isArray(data) && data.load;
						const _items: ILike[] = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'likes received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'likes', value: false });
							if (!store.temp.hasLoadedData?.likes) {
								dispatch.temp.setHasLoadedData('likes');
							}
						}

						const likesToAdd = _items.filter((like) => {
							const prev = store.like.likes.find(
								(e2) => e2.itemId === like.itemId && e2.contentType === like.contentType && e2.type === like.type
							);
							return hasEntryChanged(prev, like);
						});

						if (likesToAdd.length > 0) {
							dispatch.like.addLikes({
								likes: likesToAdd
							});
						}
					});
				}
			} catch (error) {
				console.log('startLikeSync', error);
			}
		},
		async loadLikes(payload: { spaceId?: string }, store) {
			try {
				const { spaceId } = payload;
				let activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = { spaceId: spaceId ?? activeSpace?.spaceId, multispaceUrl: activeSpace?.multispaceUrl };
				if (currentSpace.spaceId) {
					if (store.temp.hasLoadedData?.likes) {
						contentLoadLog(3, 'likes already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.likes) {
						contentLoadLog(3, 'likes load already in progress. skipping');
						return;
					}

					const types = ['feed', 'voteanswer'];

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading likes');
							dispatch.temp.setWaitingForSocketResponse({ key: 'likes', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'likes',
									userId: store.auth.userInfos.userId,
									spaceId: currentSpace.spaceId,
									types
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const newBody = new FormData();

							dispatch.temp.setWaitingForSocketResponse({ key: 'likes', value: true });

							if (!currentSpace.multispaceUrl) {
								const spaceRes = await dispatch.request.anonymousRequest({
									url: `${MULTISPACEURL}/spaces/multispaceurl/${currentSpace.spaceId}`,
									method: 'GET'
								});

								if (spaceRes?.status === 200 && !isEmptyString(spaceRes?.multispaceUrl)) {
									currentSpace.multispaceUrl = spaceRes?.multispaceUrl;
								}
							}
							newBody.append('data', JSON.stringify({ spaceId: currentSpace.spaceId, types }));

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/likes/loadlikes`,
								method: 'POST',
								body: newBody
							});

							dispatch.temp.setWaitingForSocketResponse({ key: 'likes', value: false });

							if (res?.status === 200) {
								const likesToAdd = res.likes.filter((like) => {
									const prev = store.like.likes.find(
										(e2) => e2.itemId === like.itemId && e2.contentType === like.contentType && e2.type === like.type
									);
									return hasEntryChanged(prev, like);
								});

								if (likesToAdd.length > 0) {
									dispatch.like.addLikes({
										likes: likesToAdd
									});
								}
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadLikesError', error);
			}
		},
		async toggleLike(payload: IToggleLikeParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { itemId, contentType, type } = payload;
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							itemId,
							contentType,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId,
							type
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/likes/togglelike`,
						method: 'POST',
						body
					});

					if (res?.isOffline) {
						showToast('error', 'Error', i18next.t('NoNetworkConnection'));
						return undefined;
					}

					if (res?.myLike?.id) {
						dispatch.like.addLikes({ likes: [res.likeObj] });
						return res.myLike;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('toggleLke', error);
				return undefined;
			}
		}
	})
});
