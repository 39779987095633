import { TPlayableMediaType } from 'config/interfaces';
import { TVotingType } from 'config/interfaces/vote';
import { IToggleLikeParams } from 'rematch/interfaces';

export const getIconByVoteType = (type: TVotingType) => {
	switch (type) {
		case 'applause':
			return EDefaultIconSet.Applause;
		case 'multipleChoice':
			return EDefaultIconSet.MultipleChoice;
		case 'quiz':
			return EDefaultIconSet.Quiz;
		case 'rating':
			return EDefaultIconSet.Rating;
		case 'scale':
			return EDefaultIconSet.Scale;
		case 'survey':
			return EDefaultIconSet.Survey;
		case 'text':
			return EDefaultIconSet.Text;
		case 'wordCloud':
			return EDefaultIconSet.WordCloud;
		case 'externalUrl':
			return EDefaultIconSet.ExternalUrl;
		case 'nps':
			return EDefaultIconSet.Nps;
		case 'pinOnImage':
			return EDefaultIconSet.Pin;
		case 'happinessOMeter':
			return EDefaultIconSet.Smiley;
		default:
			return EDefaultIconSet.Quiz;
	}
};

export const getIconByMediaTyoe = (type: TPlayableMediaType) => {
	switch (type) {
		case 'contentflow':
			return EDefaultIconSet.Contentflow;
		case 'iFrame':
			return EDefaultIconSet.iFrame;
		case 'upload':
			return EDefaultIconSet.Upload;
		case 'mediaLibrary':
			return EDefaultIconSet.MediaLibrary;
		case 'vimeo':
			return EDefaultIconSet.Vimeo;
		case 'externalMeeting':
			return EDefaultIconSet.ExternalUrl;
		case 'zoom':
			return EDefaultIconSet.Zoom;
		case 'networking':
			return EDefaultIconSet.Networking;
		case 'youtube':
		default:
			return EDefaultIconSet.YouTube;
	}
};

export const getLikeIconByType = (type: IToggleLikeParams['type']) => {
	switch (type) {
		case 'applause':
			return EDefaultIconSet.Applause;
		case 'funny':
			return EDefaultIconSet.Smiley;
		case 'inspiring':
			return EDefaultIconSet.SmileyStarEyes;
		case 'love':
			return EDefaultIconSet.Heart;
		case 'thoughtful':
			return EDefaultIconSet.SmileyExcited;
		case 'like':
		default:
			return EDefaultIconSet.ThumbUp;
	}
};

export const EDefaultIconSet = {
	Webhook: 'MaterialCommunityIcons:webhook',
	Map: 'Ionicons:map',
	MapMarker: 'SimpleLineIcons:location-pin',
	Contentflow: 'HelloSpaces:hellospacesiconcontentflow',
	iFrame: 'HelloSpaces:hellospacesiconiframe',
	Upload: 'HelloSpaces:hellospacesiconupload',
	MediaLibrary: 'HelloSpaces:hellospacesiconmedialibrary',
	Facebook: 'HelloSpaces:hellospacesiconfacebook',
	YouTube: 'HelloSpaces:hellospacesiconyoutube',
	Vimeo: 'HelloSpaces:hellospacesiconvimeo',
	TikTok: 'HelloSpaces:hellospacesicontiktok',
	Instagram: 'HelloSpaces:hellospacesiconinstagram',
	Clone: 'HelloSpaces:hellospacesiconclone',
	Sponsors: 'HelloSpaces:hellospacesiconsponsor',
	Basket: 'HelloSpaces:hellospacesiconbuy',
	Credit: 'HelloSpaces:hellospacesiconcreditcard',
	SelfServiceSettings: 'HelloSpaces:hellospacesiconselfservicesettings',
	EditDesign: 'HelloSpaces:hellospacesiconeditdesign',
	EditSponsor: 'HelloSpaces:hellospacesiconeditsponsor',
	Invite: 'HelloSpaces:hellospacesiconinvite2',
	ScreenSharing: 'HelloSpaces:hellospacesiconscreensharing',
	ScreenSharingOff: 'HelloSpaces:hellospacesiconscreensharingoff',
	File: 'HelloSpaces:hellospacesiconcategory',
	Changelog: 'MaterialIcons:history',

	// // General
	Shop: 'HelloSpaces:hellospacesiconshop',
	Average: 'HelloSpaces:hellospacesiconaverage',
	Sum: 'HelloSpaces:hellospacesiconsum',
	Count: 'HelloSpaces:hellospacesiconhashtag',
	Import: 'HelloSpaces:hellospacesiconimport',
	Export: 'HelloSpaces:hellospacesiconexport',
	CameraOn: 'HelloSpaces:hellospacesiconvideo', // brauchen neu
	CameraOff: 'HelloSpaces:hellospacesiconvideocamoff', // brauchen neu
	HangUp: 'HelloSpaces:hellospacesiconhangup',
	Section: 'SimpleLineIcons:doc', // brauchen neu
	Label: 'MaterialCommunityIcons:label-outline', // brauchen neu

	// HSPX Icons Stand: 01.12.2021 13:30
	Add: 'HelloSpaces:hellospacesiconadd',
	AddCircle: 'HelloSpaces:hellospacesiconaddcircle',
	Analytics: 'HelloSpaces:hellospacesiconanalytics',
	AnswerPublic: 'HelloSpaces:hellospacesiconanswerpublic',
	AnswerWaiting: 'HelloSpaces:hellospacesiconanswerwaiting',
	Archive: 'HelloSpaces:hellospacesiconarchive',
	ArrowDown: 'HelloSpaces:hellospacesiconarrowdown',
	ArrowLeft: 'HelloSpaces:hellospacesiconarrowleft',
	ArrowUp: 'HelloSpaces:hellospacesiconarrowup',
	ArrowRight: 'HelloSpaces:hellospacesiconarrowright',
	ChevronDown: 'HelloSpaces:hellospacesiconchevrondown',
	ChevronLeft: 'HelloSpaces:hellospacesiconchevronleft',
	ChevronUp: 'HelloSpaces:hellospacesiconchevronup',
	ChevronRight: 'HelloSpaces:hellospacesiconchevronright',
	Clock: 'HelloSpaces:hellospacesiconclock',
	Close: 'HelloSpaces:hellospacesiconclose',
	Error: 'HelloSpaces:hellospacesiconerror',
	Components: 'HelloSpaces:hellospacesiconcomponents',
	CreateSpace: 'HelloSpaces:hellospacesiconcreatespace',
	Delete: 'HelloSpaces:hellospacesicondelete',
	DragHandler: 'HelloSpaces:hellospacesicondraghandler',
	Edit: 'HelloSpaces:hellospacesiconedit',
	ExternalUrl: 'HelloSpaces:hellospacesiconexternalurl',
	Help: 'HelloSpaces:hellospacesiconhelp',
	Info: 'HelloSpaces:hellospacesiconinfo',
	InternalUrl: 'HelloSpaces:hellospacesiconinternalurl',
	Login: 'HelloSpaces:hellospacesiconlogin',
	Login2: 'HelloSpaces:hellospacesiconlogin2',
	Logout: 'HelloSpaces:hellospacesiconlogout',
	Moderate: 'HelloSpaces:hellospacesiconmoderate',
	News: 'HelloSpaces:hellospacesiconnews',
	Notification: 'HelloSpaces:hellospacesiconnotification',
	Pause: 'HelloSpaces:hellospacesiconpause',
	Play: 'HelloSpaces:hellospacesiconplay',
	QRCode: 'HelloSpaces:hellospacesiconqrcode',
	Register: 'HelloSpaces:hellospacesiconregister',
	ResetApp: 'HelloSpaces:hellospacesiconresetapp',
	ResetPassword: 'HelloSpaces:hellospacesiconresetpassword',
	Rocket: 'HelloSpaces:hellospacesiconrocket',
	Save: 'HelloSpaces:hellospacesiconsave',
	Search: 'HelloSpaces:hellospacesiconsearch',
	Settings: 'HelloSpaces:hellospacesiconsettings',
	Settings2: 'HelloSpaces:hellospacesiconsettings2',
	Share: 'HelloSpaces:hellospacesiconshare',
	Star: 'HelloSpaces:hellospacesiconstar',
	StarOutline: 'HelloSpaces:hellospacesiconstaroutline',
	Stream: 'HelloSpaces:hellospacesiconstream',
	SubmitTextVoteAnswer: 'HelloSpaces:hellospacesiconsubmittextvoteanswer',
	Subscribe: 'HelloSpaces:hellospacesiconsubscribe',
	Success: 'HelloSpaces:hellospacesiconsuccess',
	ThumbUp: 'HelloSpaces:hellospacesiconthumbup',
	ThumbUpOutline: 'HelloSpaces:hellospacesiconthumbupoutline',
	Ticket: 'HelloSpaces:hellospacesiconticket',
	User: 'HelloSpaces:hellospacesiconuser',
	Vote: 'HelloSpaces:hellospacesiconvote',
	Result: 'HelloSpaces:hellospacesiconpresent',
	// Voting Types
	Applause: 'HelloSpaces:hellospacesvote-applause',
	MultipleChoice: 'HelloSpaces:hellospacesvote-multiplechoice',
	Quiz: 'HelloSpaces:hellospacesvote-quiz',
	Rating: 'HelloSpaces:hellospacesvote-rating',
	Scale: 'HelloSpaces:hellospacesvote-scale',
	Survey: 'HelloSpaces:hellospacesvote-survey',
	Text: 'HelloSpaces:hellospacesvote-text',
	Video: 'HelloSpaces:hellospacesvote-video',
	WordCloud: 'HelloSpaces:hellospacesvote-wordcloud',
	Nps: 'HelloSpaces:hellospacesiconcomponents',

	// Added 03.12.2021
	LeaveSpace: 'HelloSpaces:hellospacesiconleavespace',
	Subscription: 'HelloSpaces:hellospacesiconsubscribe',
	Media: 'HelloSpaces:hellospacesiconcamera',
	Reload: 'HelloSpaces:hellospacesiconreload',
	DeleteSpace: 'HelloSpaces:hellospacesicondeletespace',
	ExpandDiagonal: 'HelloSpaces:hellospacesiconexpand',
	CollapsDiagonal: 'HelloSpaces:hellospacesiconcollaps',
	Networking: 'HelloSpaces:hellospacesiconnetworking',
	Exhibition: 'HelloSpaces:hellospacesiconbuilding',
	Download: 'HelloSpaces:hellospacesicondownload',
	HelloSpaces: 'HelloSpaces:hellospacesiconhellospacesappicon',
	CalendarAdd: 'HelloSpaces:hellospacesiconcalendaradd',
	Calendar: 'HelloSpaces:hellospacesiconcalendar',
	Heart: 'HelloSpaces:hellospacesiconheartfilled',
	HeartOutline: 'HelloSpaces:hellospacesiconheartoutlines',
	ThumbDown: 'HelloSpaces:hellospacesiconthumbdown',
	ThumbDownOutline: 'HelloSpaces:hellospacesiconthumbdownoutline',
	Smiley: 'HelloSpaces:hellospacesiconsmiley',
	SmileyExcited: 'HelloSpaces:hellospacesiconsmileyexcited',
	SmileyStarEyes: 'HelloSpaces:hellospacesiconsmileystareyes',
	SmileyWinking: 'HelloSpaces:hellospacesiconsmileywinking',
	Microphone: 'HelloSpaces:hellospacesiconmicrophone',
	MicrophoneMuted: 'HelloSpaces:hellospacesiconmicrophoneoff',
	Telephone: 'HelloSpaces:hellospacesicontelephone',
	Warning: 'HelloSpaces:hellospacesiconwarning',
	DotsHorizontal: 'HelloSpaces:hellospacesicondotshorizontal',
	DotsVertical: 'HelloSpaces:hellospacesicondotsvertical',
	Mail: 'HelloSpaces:hellospacesiconmail',
	Privacy: 'HelloSpaces:hellospacesiconprivacy',
	Meetings: 'HelloSpaces:hellospacesiconhandshake',
	Chat: 'HelloSpaces:hellospacesiconchat',
	Pin: 'HelloSpaces:hellospacesiconpin',

	// Added 14.02.22
	LinkedIn: 'HelloSpaces:hellospacesiconlinkedin',
	Schedule: 'HelloSpaces:hellospacesiconschedule',
	Twitter: 'HelloSpaces:hellospacesicontwitter',
	Website: 'HelloSpaces:hellospacesiconhome',
	Blog: 'HelloSpaces:hellospacesiconbrowser',
	Xing: 'HelloSpaces:hellospacesiconxing',
	Form: 'HelloSpaces:hellospacesiconform',
	SelfService: 'HelloSpaces:hellospacesiconselfservice',
	MailHistory: 'HelloSpaces:hellospacesiconmailhistory',
	MailTemplates: 'HelloSpaces:hellospacesiconmailtemplates',
	MailSettings: 'HelloSpaces:hellospacesiconmailsettings',
	Speakers: 'HelloSpaces:hellospacesiconspeaker',
	Attendees: 'HelloSpaces:hellospacesiconattendees',
	Category: 'HelloSpaces:hellospacesiconcategory',
	Checkbox: 'HelloSpaces:hellospacesiconcheckbox',
	TextField: 'HelloSpaces:hellospacesicontextfieldsvg',
	Filter: 'HelloSpaces:hellospacesiconfilter',

	// Markdown Types
	Heading1: 'HelloSpaces:hellospacesiconh1',
	Heading2: 'HelloSpaces:hellospacesiconh2',
	Bold: 'HelloSpaces:hellospacesiconbold',
	Centered: 'Feather:align-center', // brauchen eigenes Icon
	Italic: 'FontAwesome:italic',
	Image: 'HelloSpaces:hellospacesiconimage',
	Link: 'FontAwesome:link',
	List: 'HelloSpaces:hellospacesiconlist',
	HorizontalRule: 'HelloSpaces:hellospacesiconquerstrich',

	//Added 28.04.
	Support: 'HelloSpaces:hellospacesiconsupportrequest',
	File2: 'HelloSpaces:hellospacesiconfile',
	Document: 'HelloSpaces:hellospacesicondocument',
	Bookings: 'HelloSpaces:hellospacesiconbookings',
	HelloHand: 'HelloSpaces:hellospacesiconhellohand',
	EditTextTranslation: 'HelloSpaces:edittexttranslation',

	Present: 'MaterialCommunityIcons:presentation',
	Home: 'HelloSpaces:hellospacesiconhome',
	Statistics: 'HelloSpaces:hellospacesiconchart',
	Features: 'Ionicons:hammer-outline', // brauchen eigenes Icon
	ArrowUpRight: 'Feather:arrow-up-right', // brauchen eigenes Icon
	Plus: 'Feather:plus', // brauchen eigenes Icon
	Minus: 'Feather:minus', // brauchen eigenes Icon
	Fullscreen: 'SimpleLineIcons:size-fullscreen',
	BackToFullScreen: 'Ionicons:resize',
	ZoomIn: 'Feather:zoom-in',
	ZoomOut: 'Feather:zoom-out',

	Zoom: 'Feather:activity',
	Lock: 'SimpleLineIcons:lock', // brauchen eigenes Icon
	Unlock: 'SimpleLineIcons:lock-open', // brauchen eigenes Icon

	SmileyHappy: 'Entypo:emoji-happy', // brauchen eigenes Icon
	SmileyNeutral: 'Entypo:emoji-neutral', // brauchen eigenes Icon
	SmileySad: 'Entypo:emoji-sad', // brauchen eigenes Icon

	ApiToken: 'Feather:key' // brauchen eigenes Icon
};
