import React from 'react';
import {
	IUpdatePushNotificationFormErrors,
	IUpdatePushNotificationFormValues,
	TPushNotificationDeeplinkType,
	TPushNotificationReceiver
} from 'config/interfaces';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormCheckbox, FormDatePicker, FormMediaPicker, FormMultiSwitch, FormReferenceSelect, FormTextInput } from 'components/Form';
import { HSCard } from 'components/Card';
import { RoundButton } from 'components/Button';
import { Dropdown } from 'components/Form/Dropdown';
import { FormHint } from 'components/Form/FormHint';
import { FormLabel } from 'components/Form/FormLabel';
import { EDefaultIconSet } from 'helper';
import { PushSendingInformation } from './PushSendingInformation';
import { H2 } from 'components/Text';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsBottomMargin } from 'config/styleConstants';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import * as ImagePicker from 'expo-image-picker';
interface IUpdateNotificationForm {
	values: IUpdatePushNotificationFormValues;
	errors: IUpdatePushNotificationFormErrors;
	onChange: (val: IUpdatePushNotificationFormValues) => void;
	isDisabled?: boolean;
	onError?: (fieldName: string, error: string | undefined) => void;
}

const TESTIDPREFIX = 'updatenotificationform';

export const UpdateNotificationForm = (props: IUpdateNotificationForm) => {
	const { values, errors, onChange, isDisabled, onError } = props;
	const { theme } = useTheme();
	const { t }: { t: any } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	return (
		<HSCard>
			{values.hasBeenSend && (
				<H2 center style={{ color: theme.danger, marginBottom: hsBottomMargin }}>
					{t('NotificationHasBeenSent')}
				</H2>
			)}
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_receiver`}
				label={t('Receivers')}
				isRequired
				hint={t('PushNotificationReceiverHint')}
				isDisabled={isDisabled}
				value={values.receiver?.toString()}
				onChange={(receiver) => onChange({ ...values, receiver: receiver as TPushNotificationReceiver })}
				error={errors.receiver}
				options={[
					{
						key: 'all',
						label: t('All')
					},
					{
						key: 'web',
						label: t('PushReceiverWebOnly')
					},
					{
						key: 'app',
						label: t('PushReceiverAppOnly')
					}
				]}
			/>
			<FormCheckbox
				testID={`${TESTIDPREFIX}_checkbox_isactive`}
				label={t('ActivateNotification')}
				hint={t('ActivateNotificationHint')}
				value={values.isActive}
				isDisabled={values.receiver !== 'app' ? false : isDisabled}
				onPress={() => onChange({ ...values, isActive: !values.isActive })}
			/>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_title`}
				label={t('Title')}
				hint={t('PushNotificationTitleHint')}
				value={values.title}
				error={errors.title}
				isDisabled={isDisabled}
				onChangeText={(val) => onChange({ ...values, title: val })}
				maxInputLength={255}
			/>
			{values.receiver !== 'web' ? (
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_subtitle`}
					label="Body"
					isRequired
					hint={t('PushNotificationBodyHint')}
					value={values.subtitle}
					error={errors.subtitle}
					isDisabled={isDisabled}
					onChangeText={(subtitle) => onChange({ ...values, subtitle })}
				/>
			) : null}
			{values.receiver !== 'app' ? (
				<FormMarkdownEditor
					testID={`${TESTIDPREFIX}_markdown_websubtitle`}
					label="Web Body"
					isRequired
					hint={t('WebPushNotificationBodyHint')}
					value={values.webSubtitle}
					error={errors.webSubtitle}
					isDisabled={isDisabled}
					onChange={(webSubtitle) => onChange({ ...values, webSubtitle })}
				/>
			) : null}
			<FormMediaPicker
				isDisabled={isDisabled}
				isOutline
				label={t('Image')}
				testID={`${TESTIDPREFIX}_imagepicker_image`}
				value={values.image}
				hasDeleteButton={values.image !== null && values.image !== undefined}
				mediaTypes={ImagePicker.MediaTypeOptions.Images}
				onChange={(image) => onChange({ ...values, image })}
				onDelete={() => onChange({ ...values, image: null })}
				deleteTitle={t('Delete Image')}
				deleteSubTitle={t('deleteImageSubtitle')}
				allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
			/>
			<FormDatePicker
				testID={`${TESTIDPREFIX}_datepicker_sendon`}
				label={t('Send On')}
				hint={t('PushNotificationSendOnHint')}
				value={values.sendOn}
				error={errors.sendOn}
				onChange={(val) => onChange({ ...values, sendOn: val })}
				mode="datetime"
				isDisabled={isDisabled}
				minimumDate={moment().toDate()}
				onError={(error) => {
					if (onError) {
						onError('sendOn', error);
					}
				}}
			/>
			<FormLabel testID={`${TESTIDPREFIX}_label_deeplink`} label="Deeplink" style={{ marginBottom: 5 }} />
			<FormHint testID={`${TESTIDPREFIX}_hint_deeplink`} hint={t('DeeplinkHint')} />
			<Dropdown
				testID={`${TESTIDPREFIX}_dropdown_deeplinktype`}
				value={values.deeplinkType}
				onSelect={(deeplinkType) =>
					onChange({ ...values, deeplinkType: deeplinkType as TPushNotificationDeeplinkType, deeplinkItemId: undefined })
				}
				placeholder={t('DeeplinkSelectType')}
				isDisabled={isDisabled}
				options={[
					{
						key: 'schedules',
						label: t('Agenda')
					},
					{
						key: 'speakers',
						label: t('Speaker')
					},
					{
						key: 'expos',
						label: t('Expo')
					},
					{
						key: 'mediaitems',
						label: t('Media Items')
					},
					{
						key: 'votings',
						label: 'Voting'
					},
					{
						key: 'newsitems',
						label: t('feed')
					}
				].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
				formStyle={{ marginBottom: 0 }}
			/>
			{values.deeplinkType && (
				<FormReferenceSelect
					testID={`${TESTIDPREFIX}_referenceselect_deeplinkitem`}
					value={values.deeplinkItemId}
					type={values.deeplinkType}
					isDisabled={isDisabled}
					onSelect={(deeplinkItemId) => onChange({ ...values, deeplinkItemId })}
					formStyle={{ marginBottom: 0 }}
				/>
			)}
			{(values.deeplinkType || values.deeplinkItemId) && (
				<RoundButton
					testID={`${TESTIDPREFIX}_button_removedeeplink`}
					icon={EDefaultIconSet.Delete}
					size="sm"
					title={t('RemoveDeeplink')}
					alignSelf="flex-end"
					isDisabled={isDisabled}
					onPress={() => onChange({ ...values, deeplinkType: undefined, deeplinkItemId: undefined })}
				/>
			)}
			<PushSendingInformation TESTIDPREFIX={TESTIDPREFIX} informations={values.sendingInformation} />
		</HSCard>
	);
};
