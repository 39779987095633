export const FormElementBottomMargin = 40;
export const FormElementBottomMarginSmall = 20;

export const FORMELEMENTBORDERWIDTH = 1;
export const FORMELEMENTBORDERRADIUS = 5;

export const NAVICONSIZE = 28;
export const NAVFONTSIZE = 18;

export const LISTITEMICONSIZE = 20;
export const LISTITEMMARGINBOTTOM = 10;
