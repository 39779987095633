import { IAttendee } from 'config/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IRosterMember } from '.';
import { Text } from 'components/Text';
import { EDefaultIconSet, hexTransparency } from 'helper';
import { Icon } from 'components/Icon';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
interface INetworkingRoomAttendeeVideo {
	id: string;
	rosterEntry?: IRosterMember;
	isContentShareActive: boolean;
	height: string | number;
	width: string | number;
}

export const NetworkingRoomAttendeeVideo = (props: INetworkingRoomAttendeeVideo) => {
	const { id, rosterEntry, isContentShareActive, height, width } = props;
	const { theme } = useTheme();
	const { isPhone, isTabletOrMobile } = useQuery();

	const containerRef = useRef<View>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const videoDimensionsRef = useRef({ width: 0, height: 0 });

	const [containerDimensions, _setContainerDimensions] = useState({
		height: 1,
		width: 1
	});
	const [videoRatio, setVideoRatio] = useState<number>(1);
	const [videoDimensions, _setVideoDimensions] = useState({ height: 1, width: 1 });
	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		_handleResize();
	}, [height, width, containerDimensions, videoRatio]);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (rosterEntry && attendees) {
			_attendee = attendees.find((e) => e.userId === rosterEntry.boundExternalUserId);
		}

		setAttendee(_attendee);
	}, [attendees, rosterEntry]);

	const setVideoDimensions = (newDimensions) => {
		_setVideoDimensions(newDimensions);
		videoDimensionsRef.current = newDimensions;
	};

	const _handleResize = () => {
		if (rosterEntry && videoRef.current) {
			let calculatedHeight = 1;
			let calculatedWidth = 1;

			if (videoRatio > 1) {
				// Webcam / Landscape
				calculatedWidth = containerDimensions.width;
				calculatedHeight = calculatedWidth / videoRatio;
				if (calculatedHeight > containerDimensions.height) {
					calculatedHeight = containerDimensions.height;
					calculatedWidth = calculatedHeight * videoRatio;
				}
			} else {
				// Mobile Portrait
				calculatedHeight = containerDimensions.height;
				calculatedWidth = calculatedHeight * videoRatio;
			}
			if (calculatedHeight !== 1 && calculatedWidth !== 1) {
				if (videoDimensions.width !== calculatedWidth || videoDimensions.height !== calculatedHeight) {
					setVideoDimensions({
						height: calculatedHeight,
						width: calculatedWidth
					});
				}
			}
		} else {
			setVideoDimensions({
				height: 1,
				width: 1
			});
		}
	};

	const _renderNameOverlay = () => {
		if (!isContentShareActive && rosterEntry) {
			return (
				<View
					style={{
						position: 'absolute',
						bottom: isTabletOrMobile ? 0 : 0.5 * (containerDimensions.height - videoDimensions.height),
						left: isTabletOrMobile ? 0 : 0.5 * (containerDimensions.width - videoDimensions.width),
						padding: 5,
						flexDirection: 'row',
						alignItems: 'center',
						backgroundColor: theme.contentBackgroundColor ?? theme.background + hexTransparency[50],
						maxWidth: isTabletOrMobile ? undefined : videoDimensions.width / 2
					}}
				>
					<Icon name={rosterEntry.isMuted ? EDefaultIconSet.MicrophoneMuted : EDefaultIconSet.Microphone} color={theme.text} />
					{attendee && (
						<Text
							style={{ color: theme.text, paddingHorizontal: 2, flexWrap: 'wrap' }}
							adjustsFontSizeToFit
							numberOfLines={2}
						>{`${attendee.firstName} ${attendee.lastName}`}</Text>
					)}
				</View>
			);
		}
	};

	return (
		<View
			key={id}
			ref={containerRef}
			style={[
				rosterEntry
					? { padding: isContentShareActive ? 5 : 15, height, width, minHeight: isContentShareActive ? 30 : 40 }
					: { height: 0, width: 0 }
			]}
		>
			<View
				style={{
					position: 'relative',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
					height: '100%',
					width: '100%'
				}}
				onLayout={(e) => _setContainerDimensions({ height: e.nativeEvent.layout.height, width: e.nativeEvent.layout.width })}
			>
				<video
					id={id}
					ref={videoRef}
					playsInline
					autoPlay
					height={rosterEntry ? '100%' : 0}
					width={rosterEntry ? '100%' : 0}
					onPlay={(e) => {
						if (e.nativeEvent?.path && e.nativeEvent.path[0]) {
							setVideoRatio(e.nativeEvent.path[0].videoWidth / e.nativeEvent.path[0].videoHeight);
						} else if (e.nativeEvent?.target) {
							setVideoRatio(e.nativeEvent?.target?.videoWidth / e.nativeEvent?.target?.videoHeight);
						}
					}}
				/>
				{_renderNameOverlay()}
			</View>
		</View>
	);
};
