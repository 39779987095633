import React, { memo, useRef } from 'react';
import { View } from 'react-native';

import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

export const SCHEDULELISTHEADERHEIGHT = 20;

interface IScheduleListHeader {
	text: string;
}

export const ScheduleListHeader = memo((props: IScheduleListHeader) => {
	const { text } = props;
	const { theme } = useTheme();

	const renderCount = useRef(0);

	return (
		<View
			style={{
				height: SCHEDULELISTHEADERHEIGHT,
				borderBottomWidth: 1,
				borderColor: theme.text,
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
		>
			<Text bold>{text}</Text>
		</View>
	);
});
