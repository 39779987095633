import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { Keyboard, RefreshControl, ScrollView } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { IMailSettingsErrors, ITempMailSettings } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { FormCheckbox, FormTextInput } from 'components/Form';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getMailSettingsFormSchema } from 'config/yupSchemas';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MailSettings>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MailSettings>;
type RouteParams = StackParamList[ERoutes.MailSettings];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mailsettings';

export const MailSettingsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();

	const [mailSettingsFormValues, setMailSettingsFormValues] = useState<ITempMailSettings>({});
	const [mailSettingsFormErrors, setMailSettingsFormErrors] = useState<IMailSettingsErrors>({});
	const [isLoadMailSettingsLoading, setIsLoadMailSettingsLoading] = useState<boolean>(false);
	const [isSaveMailSettingsLoading, setIsSaveMailSettingsLoading] = useState<boolean>(false);

	const loadMailSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadMailSettings);
	const updateMailSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMailSettings);

	useEffect(() => {
		_loadMailSettings();
	}, []);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMailSettings(),
			isLoading: isSaveMailSettingsLoading,
			isRightNavPressDisabled: _isRightNavPressDisabled()
		});
	}, [mailSettingsFormValues, isLoadMailSettingsLoading, isSaveMailSettingsLoading]);

	const _isRightNavPressDisabled = () => {
		return isLoadMailSettingsLoading;
	};

	const _loadMailSettings = async () => {
		setIsLoadMailSettingsLoading(true);
		const res = await loadMailSettings({});
		setIsLoadMailSettingsLoading(false);
		if (res?.id) {
			setMailSettingsFormValues({ ...res });
		}
	};

	const _updateMailSettings = async () => {
		const errors = await validateForm(getMailSettingsFormSchema(mailSettingsFormValues), mailSettingsFormValues);
		if (errors) {
			setMailSettingsFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setMailSettingsFormErrors({});
		}

		setIsSaveMailSettingsLoading(true);
		const res = await updateMailSettings({ values: mailSettingsFormValues });
		setIsSaveMailSettingsLoading(false);
		if (res?.id) {
			navigation.setParams({
				prohibitNavigation: false
			});
			setMailSettingsFormValues({ ...res });
			if (navigation.canGoBack()) {
				navigation.goBack();
			}
		}
	};

	const _updateValues = (newValues) => {
		setMailSettingsFormValues(newValues);
		if (!route.params.prohibitNavigation) {
			navigation.setParams({
				prohibitNavigation: true
			});
		}
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				refreshControl={<RefreshControl refreshing={isLoadMailSettingsLoading} onRefresh={_loadMailSettings} />}
				style={{
					flex: 1
				}}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_host`}
						label="SMTP Host"
						isRequired
						value={mailSettingsFormValues.host}
						error={mailSettingsFormErrors.host}
						onChangeText={(host) => _updateValues({ ...mailSettingsFormValues, host })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_port`}
						label="SMTP Port"
						isRequired
						hint={t('MailSettingsPortHint')}
						value={mailSettingsFormValues.port}
						error={mailSettingsFormErrors.port}
						onChangeText={(port) => _updateValues({ ...mailSettingsFormValues, port })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
						keyboardType="number-pad"
					/>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_secure`}
						label="Port Secure"
						hint={t('MailSettingsSecureHint')}
						error={mailSettingsFormErrors.secure}
						value={mailSettingsFormValues.secure}
						onPress={() => _updateValues({ ...mailSettingsFormValues, secure: !mailSettingsFormValues.secure })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_rejectunauthorized`}
						label="TLS Reject Unauthorized"
						hint={t('MailSettingsRejectUnauthorizedHint')}
						error={mailSettingsFormErrors.rejectUnauthorized}
						value={mailSettingsFormValues.rejectUnauthorized}
						onPress={() =>
							_updateValues({ ...mailSettingsFormValues, rejectUnauthorized: !mailSettingsFormValues.rejectUnauthorized })
						}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_from`}
						label={t('Sender Address')}
						isRequired
						hint={t('MailSettingsFromHint')}
						value={mailSettingsFormValues.from}
						error={mailSettingsFormErrors.from}
						onChangeText={(from) => _updateValues({ ...mailSettingsFormValues, from })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_user`}
						label={t('Username Mailserver')}
						isRequired
						hint={t('MailSettingsUserHint')}
						value={mailSettingsFormValues.user}
						error={mailSettingsFormErrors.user}
						onChangeText={(user) => _updateValues({ ...mailSettingsFormValues, user })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_password`}
						label={t('Password Mailserver')}
						isRequired
						hint={t('MailSettingsPasswordHint')}
						value={mailSettingsFormValues.password}
						error={mailSettingsFormErrors.password}
						onChangeText={(password) => _updateValues({ ...mailSettingsFormValues, password })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
						secureTextEntry
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_sendername`}
						label={t('Sender Name')}
						hint={t('MailSettingsSenderNameHint')}
						value={mailSettingsFormValues.senderName}
						error={mailSettingsFormErrors.senderName}
						onChangeText={(senderName) => _updateValues({ ...mailSettingsFormValues, senderName })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_replyto`}
						label={t('Reply Address')}
						hint={t('MailSettingsReplyToHint')}
						value={mailSettingsFormValues.replyTo}
						error={mailSettingsFormErrors.replyTo}
						onChangeText={(replyTo) => _updateValues({ ...mailSettingsFormValues, replyTo })}
						isDisabled={isLoadMailSettingsLoading || isSaveMailSettingsLoading}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const MailSettingsScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Mail Settings')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
