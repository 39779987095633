import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { EDefaultIconSet } from 'helper/icon';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import * as RootNavigation from '../../../RootNavigation';
import { ERoutes } from '../routes';
import { DEFAULTTHEME } from 'rematch/models/config';
import i18next from 'i18next';
import * as Localization from 'expo-localization';

interface INavigationHeaderSpaceCreateCancelButtonComponent {
	onPress?: Function;
	route?: any;
}

export const NavigationHeaderSpaceCreateCancelButton = (props: INavigationHeaderSpaceCreateCancelButtonComponent) => {
	const { route } = props;
	const navigation = useNavigation();
	const { t } = useTranslation();
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	const _handleBackPress = () => {
		if (!route?.params || !route?.params['prohibitNavigation']) {
			setTheme(DEFAULTTHEME);
			i18next.changeLanguage(Localization.locale.split('-')[0]);
			if (RootNavigation.canGoBack()) {
				navigation.goBack();
			} else {
				RootNavigation.replace(ERoutes.SpaceSelect);
			}
		} else {
			showAlert({
				title: t('unsavedChanges'),
				message: t('unsavedChangesSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('leaveWithoutSaving'),
						style: 'destructive',
						onPress: async () => {
							setTheme(DEFAULTTHEME);
							i18next.changeLanguage(Localization.locale.split('-')[0]);
							if (RootNavigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace(ERoutes.SpaceSelect);
							}
						}
					}
				]
			});
		}
	};

	return <NavigationHeaderIconButton testID="header_button_cancel" icon={EDefaultIconSet.Close} onPress={() => _handleBackPress()} />;
};
