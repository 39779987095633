import React, { useEffect, useState } from 'react';
import * as ImagePicker from 'expo-image-picker';
import { Alert, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as ImageManipulator from 'expo-image-manipulator';
import * as FileSystem from 'expo-file-system';
import { v4 } from 'uuid';
import * as DocumentPicker from 'expo-document-picker';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { IMedia, TMedia } from 'config/interfaces';
import { Image } from 'components/Image';
import { FormField } from '../FormField';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { ChildButton, RoundButton } from 'components/Button';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from '../constants';
import { IS_ANDROID, IS_IOS, IS_WEB } from 'helper/platform';
import { hsActionButtonMargin, hsImageResizeHeight, hsImageResizeWidth } from 'config/styleConstants';
import { SelectPreviewImagesModal } from 'components/Modal/SelectPreviewImagesModal';
import { SpaceMediaPickerModal } from 'components/Modal/SpaceMediaModal';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { getIsImageOrVideoByExtension } from 'helper/media';
import { StreamPlayer } from 'components/Stream';
import { showToast } from 'helper/toast';

interface IFormMediaPicker {
	testID: string;
	buttonOnly?: boolean;
	isOutline?: boolean;
	buttonSize?: 'sm';
	hasDeleteButton?: boolean;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	error?: string;
	onChange: (val: TMedia) => void;
	onChangePreview?: (val: string) => void;
	value?: TMedia;
	aspectRatio?: string;
	mediaTypes: ImagePicker.MediaTypeOptions | 'files';
	isPreview?: boolean;
	onPreview?: () => void;
	formStyle?: ViewStyle;
	useModal?: boolean;
	isDisabled?: boolean;
	previewImagesFor?: 'previewImage' | 'backgroundImage' | 'logoImage';
	icon?: string;
	allowMediaLibraryPicking?: boolean;
	setIsLoading?: (val) => void;
	preventVideoAutoplay?: boolean;
	onSizeError?: () => void;
	isDeleteDisabled?: boolean;
	onDelete?: () => void;
	deleteTitle?: string;
	deleteSubTitle?: string;
	ignoreAspectRatio?: boolean;
}

const PREVIEWWIDTH = 90;
const ALLOWEDIMAGETYPES = ['image/png', 'image/jpeg', 'image/jpg'];
const ALLOWEDVIDEOTYPES = ['video/mp4'];
export const MAX_FILE_SIZE_IN_MB = 40;

export const IMAGE_ASPECT_RATIO_TOLERANCE = 0.05;

export const FormMediaPicker = (props: IFormMediaPicker) => {
	const {
		testID,
		buttonOnly,
		isOutline,
		buttonSize,
		hasDeleteButton,
		label,
		hint,
		isRequired,
		error,
		onChange,
		onChangePreview,
		value,
		aspectRatio,
		mediaTypes,
		isPreview,
		onPreview,
		formStyle,
		useModal,
		isDisabled,
		previewImagesFor,
		icon,
		allowMediaLibraryPicking,
		setIsLoading,
		preventVideoAutoplay,
		onSizeError,
		isDeleteDisabled,
		onDelete,
		deleteTitle,
		deleteSubTitle,
		ignoreAspectRatio
	} = props;

	const { theme } = useTheme();
	const { t } = useTranslation();
	const { showActionSheetWithOptions } = useActionSheet();

	const [hasPermissions, setHasPermissions] = useState<boolean>(false);
	const [isSelectPreviewImagesModalVisible, setIsSelectPreviewImagesModalVisible] = useState<boolean>(false);
	const [showMediaLibraryModal, setShowMediaLibraryModal] = useState<boolean>(false);
	const [calculatedImageRatio, setCalculatedImageRatio] = useState<number>(1);
	const [hasSupportedTypesError, setHasSupportedTypesError] = useState<boolean>(false);
	const [hasSizeError, setHasSizeError] = useState<boolean>(false);

	const spaceMedia = useSelector((store: IRootState) => store.upload.spaceMedia);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		_requestPermission();
	}, []);

	const _getMediaFromLibrary = (id: number) => {
		if (spaceMedia) {
			return spaceMedia.find((e) => e.id === id);
		}
	};

	const _requestPermission = async () => {
		const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
		setHasPermissions(status === ImagePicker.PermissionStatus.GRANTED);
	};

	const _handleValueChange = async (newValue) => {
		setHasSizeError(false);

		let isSmallEnough = true;
		if (IS_WEB) {
			const base64Response = newValue?.url ? await fetch(newValue.url) : await fetch(newValue);
			const blob = await base64Response.blob();
			isSmallEnough = isLessThanTheMB(blob.size ?? 0, MAX_FILE_SIZE_IN_MB);
		} else {
			const fileInfo = newValue?.url ? await getFileInfo(newValue.url) : await getFileInfo(newValue);
			isSmallEnough = isLessThanTheMB(fileInfo?.size ?? 0, MAX_FILE_SIZE_IN_MB);
		}

		if (!isSmallEnough) {
			showToast('error', undefined, t('maxSizeError').replace('%SIZE%', MAX_FILE_SIZE_IN_MB.toString()));
			if (onSizeError) {
				onSizeError();
			}
			setHasSizeError(true);
			return;
		}

		if (value) {
			if (typeof value === 'string') {
				onChange(newValue);
			} else if (newValue && typeof value === 'object') {
				if (newValue.url) {
					onChange({ ...value, url: newValue.url, caption: newValue.caption });
				} else {
					onChange({ ...value, url: newValue });
				}
			} else {
				onChange(newValue);
			}
		} else {
			if (newValue.url) {
				onChange({ url: newValue.url, caption: newValue.caption });
			} else {
				onChange(newValue);
			}
		}
		if (setIsLoading) {
			setIsLoading(false);
		}
	};

	const _getTypes = () => {
		let type: string | string[] | undefined = [];
		switch (mediaTypes) {
			case ImagePicker.MediaTypeOptions.Videos:
				type = [...ALLOWEDVIDEOTYPES];
				break;
			case ImagePicker.MediaTypeOptions.All:
				type = [...ALLOWEDIMAGETYPES, ...ALLOWEDVIDEOTYPES];
				break;
			case 'files':
				type = undefined;
				break;
			case ImagePicker.MediaTypeOptions.Images:
				type = [...ALLOWEDIMAGETYPES];
				break;
			default:
				break;
		}
		return type;
	};

	const getFileInfo = async (fileURI: string) => {
		if (fileURI) {
			const fileInfo = await FileSystem.getInfoAsync(fileURI);
			return fileInfo;
		}
		return null;
	};

	const isLessThanTheMB = (fileSize: number, smallerThanSizeMB: number) => {
		const isOk = fileSize / 1024 / 1024 < smallerThanSizeMB;
		return isOk;
	};

	const _pickMedia = async () => {
		setHasSupportedTypesError(false);
		if (IS_WEB || mediaTypes === 'files') {
			const type = _getTypes();
			const selectedMedia = await DocumentPicker.getDocumentAsync({
				multiple: false,
				type
			});

			if (selectedMedia.type === 'success' && selectedMedia.mimeType) {
				if (!type || type?.length === 0) {
					_handleValueChange({ url: selectedMedia.uri, caption: selectedMedia.file?.name });
				} else if (type && type.includes(selectedMedia.mimeType)) {
					if (selectedMedia.mimeType.includes('image')) {
						let img = document.createElement('img');
						img.src = selectedMedia.uri;

						img.onload = async () => {
							if (IS_WEB && (img.width > 2000 || img.height > 2000)) {
								showToast(
									'error',
									t('ImageTooBig'),
									`${t('ImageTooBigHint')} ${t('MaxPixelHint')} ${t('yourImagePixelHint')
										.replace('%WIDTH%', img.width.toString())
										.replace('%HEIGHT%', img.height.toString())}`
								);
							} else {
								const uri = await _manipulateImage(selectedMedia.uri, img.width, img.height);
								if (uri) _handleValueChange({ url: uri, caption: selectedMedia.file?.name });
							}
						};
					} else {
						_handleValueChange({ url: selectedMedia.uri, caption: selectedMedia.file?.name });
					}
				} else {
					showToast('error', t('unsupportedMediaType'));
					setHasSupportedTypesError(true);
				}
			}
		} else {
			if (!hasPermissions) {
				Alert.alert(t('MissingCameraRollPermissionTitle'), t('MissingPermissionSubtitle'));
				return;
			}

			const pickedMedia = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: mediaTypes ?? ImagePicker.MediaTypeOptions.Images,
				quality: 0.5
			});

			if (!pickedMedia.canceled) {
				if (setIsLoading) {
					setIsLoading(true);
				}
				let uri = pickedMedia.assets[0].uri;
				if (pickedMedia.assets[0].type === 'video') {
					if (IS_IOS && uri.endsWith('mov')) {
						const path = FileSystem.cacheDirectory + v4() + '.mp4';
						await FileSystem.copyAsync({ from: uri, to: path });
						uri = path;
					}
				} else if (pickedMedia.assets[0].type === 'image' || pickedMedia.assets[0].uri.includes('image')) {
					uri = await _manipulateImage(pickedMedia.assets[0].uri, pickedMedia.assets[0].width, pickedMedia.assets[0].height);
				}

				errorLog(uri);
				_handleValueChange(uri);
			}
		}
	};

	const _manipulateImage = async (uri: string, width: number, height: number) => {
		let _uri = uri;

		if (width > height && width > hsImageResizeWidth) {
			const res = await ImageManipulator.manipulateAsync(_uri, [{ resize: { width: hsImageResizeWidth } }], {
				format: ImageManipulator.SaveFormat.PNG
			});

			_uri = res.uri;
		} else if (height > width && height > hsImageResizeHeight) {
			const res = await ImageManipulator.manipulateAsync(_uri, [{ resize: { height: hsImageResizeHeight } }], {
				format: ImageManipulator.SaveFormat.PNG
			});

			_uri = res.uri;
		}

		return _uri;
	};

	const _getActionSheetImageOrVideoAndroid = async (index) => {
		let mediaTaken;
		switch (index) {
			case 1:
				mediaTaken = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Videos,
					allowsEditing: false,
					aspect: [1, 1],
					videoExportPreset: ImagePicker.VideoExportPreset.MediumQuality,
					videoMaxDuration: 20
				});
				break;
			case 0:
				mediaTaken = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [1, 1],
					quality: 0.5
				});
				break;
			default:
				break;
		}
		if (mediaTaken && !mediaTaken.cancelled) {
			if (setIsLoading) {
				setIsLoading(true);
			}
			if (mediaTaken.type === 'video') {
				_handleValueChange(mediaTaken.uri);
			} else {
				const manipulationResult = await _manipulateImage(mediaTaken.uri, mediaTaken.width, mediaTaken.height);
				_handleValueChange(manipulationResult);
			}
		}
	};

	const _takeMediaAndroid = () => {
		try {
			if (mediaTypes === ImagePicker.MediaTypeOptions.Images) {
				_getActionSheetImageOrVideoAndroid(0);
			} else if (mediaTypes === ImagePicker.MediaTypeOptions.Videos) {
				_getActionSheetImageOrVideoAndroid(1);
			} else {
				showActionSheetWithOptions(
					{
						options: [t('Image'), t('Video'), t('Cancel')],
						cancelButtonIndex: 2,
						useModal: useModal ? useModal : false
					},
					_getActionSheetImageOrVideoAndroid
				);
			}
		} catch (error: any) {
			showToast('error', 'Error', error.toString());
			console.log('error when taking android media', error);
		}
	};

	const _takeMedia = async () => {
		const { status } = await ImagePicker.requestCameraPermissionsAsync();
		if (status !== ImagePicker.PermissionStatus.GRANTED) {
			Alert.alert(t('MissingCameraPermissionTitle', t('MissingPermissionSubtitle')));
			return;
		}

		const mediaTaken = await ImagePicker.launchCameraAsync({
			mediaTypes: mediaTypes as ImagePicker.MediaTypeOptions,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0.5
		});

		if (!mediaTaken.canceled) {
			if (setIsLoading) {
				setIsLoading(true);
			}
			if (mediaTaken.assets[0].type === 'video') {
				let uri = mediaTaken.assets[0].uri;
				if (IS_IOS) {
					const path = FileSystem.cacheDirectory + v4() + '.mp4';
					await FileSystem.copyAsync({ from: uri, to: path });
					uri = path;
				}
				_handleValueChange(uri);
			} else {
				const manipulationResult = await _manipulateImage(
					mediaTaken.assets[0].uri,
					mediaTaken.assets[0].width,
					mediaTaken.assets[0].height
				);
				_handleValueChange(manipulationResult);
			}
		}
	};

	const _renderNoValue = () => {
		return (
			<View
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderColor: theme.lightgray
				}}
			/>
		);
	};

	const _renderImagePreview = () => {
		if (value) {
			let url: string | number = '';

			if (typeof value === 'number') {
				const pickedMedia = _getMediaFromLibrary(value);
				if (pickedMedia) {
					url = pickedMedia.url;
				} else {
					url = value;
				}
			} else if (typeof value === 'string') {
				url = value;
			} else {
				url = value.url;
			}
			let isFile = false;
			let extension = '';
			try {
				if (value?.mime?.includes('image')) {
					isFile = false;
				} else if (typeof url === 'string') {
					if (url.startsWith('data:image') || url.startsWith('/static/')) {
						isFile = false;
					} else {
						const splitted = url.split('/');
						if (splitted) {
							const _extension = splitted[splitted.length - 1].split('.')[1];
							extension = _extension;
							isFile = !getIsImageOrVideoByExtension(_extension);
						}
					}
				}
			} catch (error) {}
			if (isFile) {
				return (
					<View style={{ position: 'absolute', width: '100%', height: '100%' }}>
						<Icon name={EDefaultIconSet.File} size={PREVIEWWIDTH} />
						<Text bold style={{ alignSelf: 'center' }}>
							{extension?.toUpperCase() ?? ''}
						</Text>
					</View>
				);
			}

			return (
				<View style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<Image
						onLoad={(e) => setCalculatedImageRatio(e.source.width / e.source.height)}
						style={{ width: PREVIEWWIDTH, height: PREVIEWWIDTH / _getRatio(), borderRadius: FORMELEMENTBORDERRADIUS }}
						url={url}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderVideoPreview = () => {
		if (value) {
			let url = '';

			if (typeof value === 'number') {
				const pickedMedia = _getMediaFromLibrary(value);
				if (pickedMedia) {
					url = pickedMedia.url;
				} else {
					return null;
				}
			} else if (typeof value === 'string') {
				url = value;
			} else {
				url = value.url;
			}

			return (
				<View
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						borderRadius: FORMELEMENTBORDERRADIUS
					}}
				>
					<StreamPlayer
						type={url.includes('mediadelivery') ? 'iFrame' : 'local'}
						url={url}
						controls={false}
						containerStyle={{ width: PREVIEWWIDTH, height: PREVIEWWIDTH * _getRatio() }}
						isAutoplay={false}
						isMute
						playerContainerStyle={IS_ANDROID ? { flex: 1 } : {}}
					/>
				</View>
			);
		}
		return null;
	};

	const _renderFile = () => {
		let pickedMedia: IMedia | undefined = undefined;

		if (typeof value === 'number') {
			pickedMedia = _getMediaFromLibrary(value);
		}

		if (
			(typeof value === 'string' && (value.endsWith('.mp4') || value.startsWith('data:video'))) ||
			pickedMedia?.mime?.includes('video') ||
			(typeof value !== 'string' && typeof value !== 'number' && value?.mime?.includes('video'))
		) {
			return _renderVideoPreview();
		}

		if (value) {
			return _renderImagePreview();
		}

		return _renderNoValue();
	};

	const _getRatio = () => {
		if (
			(typeof value === 'string' && value.endsWith('.mp4')) ||
			(typeof value !== 'string' && typeof value !== 'number' && value?.type === 'video')
		) {
			return 16 / 9;
		}

		if (typeof value === 'number') {
			const pickedMedia = _getMediaFromLibrary(value);
			if (pickedMedia?.mime?.includes('video')) {
				return 16 / 9;
			}
		}
		if (aspectRatio) {
			const splitted = aspectRatio.split('/');
			if (splitted.length === 2 && Number(splitted[0]) && Number(splitted[1])) {
				return Number(splitted[0]) / Number(splitted[1]);
			}
		}
		return calculatedImageRatio;
	};

	const _handlePress = () => {
		if (IS_WEB) {
			if (allowMediaLibraryPicking) {
				showAlert({
					title: t('Media Upload'),
					message: t('Media Upload Subtitle'),
					hint: ignoreAspectRatio
						? t('ImageFormatHintNoRatio')
						: t('ImageFormatHint').replace('%ASPECT%', aspectRatio ? aspectRatio.replace('/', ':') : '16:9'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},

						{
							text: t('Media Library'),
							onPress: async () => setShowMediaLibraryModal(true)
						},
						{
							text: t('Upload Media'),
							onPress: async () => _pickMedia()
						}
					]
				});
			} else {
				_pickMedia();
			}
		} else {
			let options = [];

			switch (mediaTypes) {
				case ImagePicker.MediaTypeOptions.All:
					options = [t('Take Image or record Video'), t('Select Image or Video'), t('Cancel')];
					break;
				case ImagePicker.MediaTypeOptions.Videos:
					options = [t('Record Video'), t('Select Video'), t('Cancel')];
					break;
				case ImagePicker.MediaTypeOptions.Images:
					options = [t('Take Image'), t('Select Image'), t('Cancel')];
					break;
				case 'files':
					options = [t('Take Image or record Video'), t('Select Image or Video'), t('Select File'), t('Cancel')];
					break;
				default:
					break;
			}

			if (previewImagesFor) {
				options.splice(options.length - 1, 0, t('Select HelloSpaces Image'));
			}

			if (allowMediaLibraryPicking) {
				options.splice(options.length - 1, 0, t('Media Library'));
			}

			showActionSheetWithOptions(
				{
					options: options,
					cancelButtonIndex: options.length - 1,
					useModal: useModal ? useModal : false
				},
				(index) => {
					if (index !== undefined) {
						_handleActionSheetPress(options[index]);
					}
				}
			);
		}
	};

	const _handleActionSheetPress = (selectedOption: string) => {
		switch (selectedOption) {
			case t('Take Image or record Video'):
			case t('Record Video'):
			case t('Take Image'):
				if (IS_ANDROID) {
					_takeMediaAndroid();
				} else {
					_takeMedia();
				}
				break;
			case t('Select Image or Video'):
			case t('Select Video'):
			case t('Select Image'):
			case t('Select File'):
				_pickMedia();
				break;
			case t('Select HelloSpaces Image'):
				setIsSelectPreviewImagesModalVisible(true);
				break;
			case t('Media Library'):
				setShowMediaLibraryModal(true);
				break;
			case t('Cancel'):
			default:
				break;
		}
	};

	const _renderRoundButton = () => {
		return (
			<RoundButton
				isOutline={isOutline}
				size={buttonSize}
				testID={`${testID}_button_edit`}
				onPress={() => {
					if (isDisabled) {
						return;
					}
					if (isPreview) {
						if (onPreview) {
							onPreview();
						}
						return;
					}

					_handlePress();
				}}
				isDisabled={isDisabled}
				icon={icon ?? EDefaultIconSet.Edit}
			/>
		);
	};

	const _renderDeleteButton = () => {
		if (hasDeleteButton) {
			return (
				<View style={{ marginLeft: hsActionButtonMargin }}>
					<RoundButton
						isOutline={isOutline}
						color={theme.danger}
						size={buttonSize}
						testID={`${testID}_button_delete`}
						onPress={() => {
							if (isDisabled || isPreview) {
								return;
							}
							if (onDelete) {
								showAlert({
									title: deleteTitle ?? t('Delete Option'),
									message: deleteSubTitle ?? t('DeleteOptionSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: deleteTitle ?? t('Delete Option'),
											style: 'destructive',
											onPress: async () => onDelete()
										}
									]
								});
							} else {
								_handleValueChange(null);
							}
						}}
						isDisabled={isDisabled || isDeleteDisabled}
						icon={icon ?? EDefaultIconSet.Delete}
					/>
				</View>
			);
		}

		return null;
	};

	if (buttonOnly) {
		return (
			<>
				{_renderRoundButton()}
				<SpaceMediaPickerModal
					isVisible={showMediaLibraryModal}
					mediaTypes={mediaTypes}
					onClose={() => setShowMediaLibraryModal(false)}
					onSelect={(val) => {
						if (val.height && val.width) {
							const _valueAspect = val.width / val.height;
							if (ignoreAspectRatio || Math.abs(_valueAspect - _getRatio()) < IMAGE_ASPECT_RATIO_TOLERANCE) {
								onChange(val.id);
								setShowMediaLibraryModal(false);
							} else {
								showAlert({
									title: t('wrongAspectRatio'),
									message: t('wrongAspectRatioHint').replace(
										'%ASPECT%',
										aspectRatio ? aspectRatio.replace('/', ':') : '16:9'
									),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('Continue'),
											style: 'destructive',
											onPress: async () => {
												onChange(val.id);
												setShowMediaLibraryModal(false);
											}
										}
									]
								});
							}
						} else {
							onChange(val.id);
							setShowMediaLibraryModal(false);
						}
					}}
				/>
			</>
		);
	}

	const _getTypesMessage = () => {
		let typesMessage = '';
		const types = _getTypes();
		if (types) {
			typesMessage = `${types.join(', ')}`;
			while (typesMessage.includes('image/')) {
				typesMessage = typesMessage.replace('image/', '');
			}
			while (typesMessage.includes('video/')) {
				typesMessage = typesMessage.replace('video/', '');
			}
			typesMessage = `${t('supportedTypesHint')}: ${typesMessage}.`;
		}
		return typesMessage;
	};

	const _generateHint = () => {
		let _hints: string[] = [];
		if (aspectRatio && !ignoreAspectRatio) {
			_hints.push(t('aspectHint').replace('%ASPECT%', aspectRatio?.replace('/', ':')));
		}

		const typesHint = _getTypesMessage();
		if (!isEmptyString(typesHint)) {
			_hints.push(typesHint);
		}

		if (IS_WEB) {
			_hints.push(t('MaxPixelHint'));
		}

		return _hints.join(' ');
	};

	const _getTypesError = () => {
		const typesHint = _getTypesMessage();
		if (!isEmptyString(typesHint)) {
			return typesHint;
		}
		return '';
	};

	return (
		<FormField
			testID={testID}
			label={label}
			isRequired={isRequired}
			hint={hint ? `${hint} ${_generateHint()}` : _generateHint()}
			error={`${error ? error + ' ' : ''}${hasSupportedTypesError ? _getTypesError() + ' ' : ''}${
				hasSizeError ? t('maxSizeError').replace('%SIZE%', MAX_FILE_SIZE_IN_MB.toString()) : ''
			}`}
			formStyle={{ flexGrow: 1, ...formStyle }}
		>
			<View
				style={{
					flexDirection: 'row',
					flex: 1,
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<ChildButton
					testID={`${testID}_button_mediapicker`}
					style={{
						width: PREVIEWWIDTH,
						height: PREVIEWWIDTH / _getRatio()
					}}
					isDisabled={isDisabled}
					onPress={() => {
						if (isPreview) {
							if (onPreview) {
								onPreview();
							}
							return;
						}

						_handlePress();
					}}
				>
					{_renderFile()}
				</ChildButton>
				<View
					style={{
						flexDirection: 'row',
						flex: 1,
						justifyContent: 'flex-end',
						alignItems: 'center'
					}}
				>
					{_renderRoundButton()}
					{_renderDeleteButton()}
				</View>
			</View>
			<SelectPreviewImagesModal
				imageRatio={_getRatio()}
				isVisible={isSelectPreviewImagesModalVisible}
				previewImagesFor={previewImagesFor}
				onClose={() => setIsSelectPreviewImagesModalVisible(false)}
				onSelect={(value) => {
					if (value.height && value.width) {
						const _valueAspect = value.width / value.height;
						if (ignoreAspectRatio || Math.abs(_valueAspect - _getRatio()) < IMAGE_ASPECT_RATIO_TOLERANCE) {
							if (onChangePreview) {
								onChangePreview(value);
							}
							setIsSelectPreviewImagesModalVisible(false);
						} else {
							showAlert({
								title: t('wrongAspectRatio'),
								message: t('wrongAspectRatioHint').replace(
									'%ASPECT%',
									aspectRatio ? aspectRatio.replace('/', ':') : '16:9'
								),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('Continue'),
										style: 'destructive',
										onPress: async () => {
											if (onChangePreview) {
												onChangePreview(value);
											}
											setIsSelectPreviewImagesModalVisible(false);
										}
									}
								]
							});
						}
					} else {
						if (onChangePreview) {
							onChangePreview(value);
						}
						setIsSelectPreviewImagesModalVisible(false);
					}
				}}
				onRemove={() => {
					setIsSelectPreviewImagesModalVisible(false);
					_handleValueChange(null);
				}}
			/>
			<SpaceMediaPickerModal
				isVisible={showMediaLibraryModal}
				mediaTypes={mediaTypes}
				onClose={() => setShowMediaLibraryModal(false)}
				onSelect={(value) => {
					if (value.height && value.width) {
						const _valueAspect = value.width / value.height;
						if (ignoreAspectRatio || Math.abs(_valueAspect - _getRatio()) < IMAGE_ASPECT_RATIO_TOLERANCE) {
							onChange(value.id);
							setShowMediaLibraryModal(false);
						} else {
							showAlert({
								title: t('wrongAspectRatio'),
								message: t('wrongAspectRatioHint').replace(
									'%ASPECT%',
									aspectRatio ? aspectRatio.replace('/', ':') : '16:9'
								),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('Continue'),
										style: 'destructive',
										onPress: async () => {
											onChange(value.id);
											setShowMediaLibraryModal(false);
										}
									}
								]
							});
						}
					} else {
						onChange(value.id);
						setShowMediaLibraryModal(false);
					}
				}}
			/>
		</FormField>
	);
};
