import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormSwitch } from 'components/Form/FormSwitch';
import { Icon } from 'components/Icon';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H1, Text } from 'components/Text';
import { IVote } from 'config/interfaces/vote';
import { IVoteAnswer, IVoteResult } from 'config/interfaces/voteanswer';
import { hsBorderRadius, hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { PreviewHappinessOMeterAnswer } from 'helper/previewAnswers';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageBackground, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ActiveVoteHeader } from '../ActiveVoteHeader';

interface IVoteHappinessOMeter {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

type IVoteHappinessOMeterOptions = 'sad' | 'neutral' | 'happy';

const TESTIDPREFIX = 'activevote';

export const VoteHappinessOMeter = (props: IVoteHappinessOMeter) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { isTabletOrMobile, screenWidth } = useQuery();

	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [valueSubmitted, setValueSubmitted] = useState<boolean>(false);
	const [answerForThis, setAnswerForThis] = useState<IVoteResult | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const options = [
		{ key: 'sad', icon: EDefaultIconSet.SmileySad, color: theme.danger },
		{ key: 'neutral', icon: EDefaultIconSet.SmileyNeutral, color: theme.warning },
		{ key: 'happy', icon: EDefaultIconSet.SmileyHappy, color: theme.success }
	];
	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [activeAnswer, setActiveAnswer] = useState<IVoteHappinessOMeterOptions | undefined>(undefined);
	const [mostAnswered, setMostAnswered] = useState<IVoteHappinessOMeterOptions[]>([]);

	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered && !vote.hideResults) {
			setValueSubmitted(true);
			setVotingShowResults(true);
		}
	}, [vote, votingIsAnswered]);

	useEffect(() => {
		let _answerForThis: IVoteResult | undefined = undefined;

		if (isPreview) {
			_answerForThis = PreviewHappinessOMeterAnswer;
		} else {
			_answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;
		}

		const _myAnswer = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === profile?.userId
		) as IVoteAnswer | undefined;

		setAnswerForThis(_answerForThis);
		if (_myAnswer) {
			setActiveAnswer(_myAnswer.value as IVoteHappinessOMeterOptions);
		}
	}, [voteAnswers, profile]);

	useEffect(() => {
		if (answerForThis) {
			const _maxAnswered: { key: string; value: number }[] = [];

			options.forEach((option) => {
				const found = answerForThis.options.find((e) => e.key === option.key);
				if (found) {
					_maxAnswered.push(found);
				} else {
					_maxAnswered.push({
						key: option.key,
						value: 0
					});
				}
			});
			if (_maxAnswered.length > 0) {
				_maxAnswered.sort((a, b) => (a.value > b.value ? -1 : 1));

				setMostAnswered(
					_maxAnswered.filter((e) => e.value === _maxAnswered[0].value).map((e) => e.key) as IVoteHappinessOMeterOptions[]
				);
			} else {
				setMostAnswered([]);
			}
		}
	}, [answerForThis, isPreview]);

	const _getColorDifferences = () => {
		const _danger = parseInt(theme.danger.replace('#', '').slice(0, 6), 16);
		const _warning = parseInt(theme.warning.replace('#', '').slice(0, 6), 16);
		const _success = parseInt(theme.success.replace('#', '').slice(0, 6), 16);
		const _bg = parseInt(theme.contentBackgroundColor.replace('#', '').slice(0, 6), 16);

		const difference = Math.min(Math.abs(_danger - _bg), Math.abs(_warning - _bg), Math.abs(_success - _bg));

		return difference;
	};

	const _submitVoteAnswer = async (option: IVoteHappinessOMeterOptions) => {
		if (isPreview) {
			setValueSubmitted(true);
			setVotingShowResults(true);
			return;
		}
		if (
			(vote.allowAnonymousAnswers === 'never' && !profile) ||
			(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
		) {
			setIsAuthenticationModalVisible(true);
			return;
		}
		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		let res, value;
		value = option;
		if (value) {
			res = await submitVoteAnswer({
				voteId: vote.id,
				value: value.toString(),
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
			});
			setValueSubmitted(true);
			setVotingShowResults(true);
		}

		setIsSubmitVoteAnswerLoading(false);
	};

	const _renderResult = (key: IVoteHappinessOMeterOptions) => {
		if (vote && (votingShowResults || resultsOnly) && !vote.hideResults) {
			const _thisCount = answerForThis?.options.find((e) => e.key === key)?.value ?? 0;
			const _thisPercent = Math.floor((_thisCount / (answerForThis?.submissionCount ?? 0)) * 100);

			return (
				<View style={{ alignSelf: 'center', alignItems: 'center', marginTop: hsTopScreenPadding, justifyContent: 'center' }}>
					<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 5 }}>
						<Icon name={EDefaultIconSet.Count} size={largerDesign ? 30 : 20} />
						<H1 largerText={largerDesign} noBottomMargin style={{ marginLeft: 5 }}>
							{_thisCount.toString()}
						</H1>
					</View>
					{!isNaN(Number(_thisPercent)) && <Text largerText={largerDesign} center>{`${_thisPercent}%`}</Text>}
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (vote) {
			return (
				<View
					style={{
						flex: 1,
						width: '100%',
						flexDirection: 'row',
						justifyContent: 'center',
						alignSelf: 'center'
					}}
				>
					{vote.media && vote.media[0] && (
						<ImageBackground
							source={{ uri: vote.media[0].media?.url }}
							style={{
								position: 'absolute',
								flex: 1,
								height: '100%',
								width: '100%',
								borderRadius: hsBorderRadius
							}}
							imageStyle={{ height: '100%', width: '100%', borderRadius: hsBorderRadius }}
							resizeMethod="scale"
							resizeMode="cover"
						/>
					)}
					{options.map((option, idx) => {
						const isActive =
							(votingShowResults || resultsOnly) && !vote.hideResults
								? isPreview
									? option.key === 'happy'
									: mostAnswered.includes(option.key as IVoteHappinessOMeterOptions)
								: option.key === activeAnswer;

						const size = Math.floor(isTabletOrMobile ? containerWidth : containerWidth * 0.8);

						let colorDifference = _getColorDifferences();

						const needsBackground = vote.media && vote.media[0] && vote.media[0].media?.url ? true : colorDifference < 25000;

						return (
							<View
								key={`${TESTIDPREFIX}_${option.key}`}
								style={{
									flex: 1,
									width: `${100 / (options.length ?? 3)}%`,
									justifyContent: 'center',
									alignItems: 'center',
									paddingLeft: idx === 0 ? 15 : 3,
									paddingRight: idx === (options.length ?? 3) - 1 ? 15 : 3
								}}
							>
								<View
									style={{
										flex: 1,
										width: '100%',
										justifyContent: 'center',
										alignItems: 'center',
										marginTop: votingShowResults ? hsTopScreenPadding : 0
									}}
									onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
								>
									<ChildButton
										testID={`${TESTIDPREFIX}_button_option_${option.key}`}
										onPress={() => {
											setActiveAnswer(option.key as IVoteHappinessOMeterOptions);
										}}
										style={{
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 999
										}}
										isDisabled={isSubmitVoteAnswerLoading || isActive || votingShowResults || resultsOnly}
									>
										<View
											style={{
												flex: 1,
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<View
												style={{
													position: 'absolute',
													height: isActive ? size * 0.9 : Math.floor(size * 0.75) * 0.9,
													aspectRatio: 1,
													backgroundColor: needsBackground ? theme.white : option.color,
													borderRadius: 999,
													opacity: needsBackground ? 0.75 : 0.1
												}}
											/>
											<Icon
												name={option.icon}
												color={option.color}
												size={isActive ? size : Math.floor(size * 0.75)}
												containerStyle={{
													aspectRatio: 1,
													alignItems: 'center',
													justifyContent: 'center',
													borderRadius: 999
												}}
											/>
										</View>
									</ChildButton>
								</View>
								{(votingShowResults || resultsOnly) && (
									<View
										style={{
											justifyContent: 'flex-start',
											marginBottom: hsBottomMargin,
											marginTop: -hsBottomMargin
										}}
									>
										{_renderResult(option.key as IVoteHappinessOMeterOptions)}
									</View>
								)}
							</View>
						);
					})}
				</View>
			);
		}

		return null;
	};

	const _renderAnonymousSwitch = () => {
		if (votingShowResults || resultsOnly) {
			return null;
		}

		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<View
					style={{
						marginTop: hsBottomMargin / 2,
						marginBottom: hsBottomMargin,
						flexDirection: 'row',
						justifyContent: 'flex-end'
					}}
				>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderActionButtons = () => {
		if (resultsOnly || !vote.isActive) {
			return null;
		}
		let element: ReactNode = null;
		if (votingShowResults && !vote?.hideResults) {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						onPress={() => {
							setVotingShowResults(false);
							setVotingIsAnswered(false);
						}}
						title={!valueSubmitted ? t('answerOptions') : t('changeAnswer')}
						alignSelf="center"
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						icon={EDefaultIconSet.Save}
						testID={`${TESTIDPREFIX}_button_submit`}
						title={t('submitAnswer')}
						isLoading={isSubmitVoteAnswerLoading}
						onPress={
							activeAnswer
								? () => {
										_submitVoteAnswer(activeAnswer);
										setVotingShowResults(true);
								  }
								: undefined
						}
						alignSelf="center"
						isDisabled={!activeAnswer}
					/>
				</HSCard>
			);
		}

		return (
			<View
				style={{
					width: '100%',
					alignSelf: 'center'
				}}
			>
				{element}
			</View>
		);
	};

	return (
		<View style={{ flex: 1, alignItems: 'center' }}>
			{vote ? (
				<View
					style={{
						flex: 1,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						width: fullwidth || isPreview ? '100%' : screenWidth
					}}
				>
					<ActiveVoteHeader activeVote={vote} showResult={resultsOnly} largerText={largerDesign} hideMedia />
					<HSCard style={{ flex: 1 }}>{_renderContent()}</HSCard>
					{_renderAnonymousSwitch()}
					{_renderActionButtons()}
				</View>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
		</View>
	);
};
