import { FormLabel } from 'components/Form/FormLabel';
import { Spinner } from 'components/Spinner';
import { hsBorderRadius } from 'config/styleConstants';
import { isDarkColor, IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import WebView, { WebViewMessageEvent } from 'react-native-webview';

interface IWebView {
	src: string;
	testIdPrefix?: string;
	label?: string;
	height?: number | string;
	width?: number | string;
	style?: ViewStyle;
	onMessage?: (event: WebViewMessageEvent) => void;
	iFrameOptions?: string;
	rounded?: boolean;
	noScroll?: boolean;
	cacheDisabled?: boolean;
}

export const HSWebView = React.forwardRef((props: IWebView, ref) => {
	let { src, testIdPrefix, label, height, width, style, onMessage, iFrameOptions, rounded, noScroll, cacheDisabled } = props;

	const { theme } = useTheme();

	const platformStyle = rounded ? { borderRadius: hsBorderRadius } : {};
	const mapInjection = `const meta = document.createElement('meta'); meta.setAttribute('content', 'initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);`;

	const renderContent = () => {
		if (IS_WEB) {
			return (
				<iframe
					id={`${testIdPrefix ?? 'HSWebView'}_iFrame`}
					key={`${testIdPrefix ?? 'HSWebView'}_iFrame`}
					ref={ref}
					defaultValue={src}
					src={src}
					style={{ ...platformStyle, flex: 1, border: 0, backgroundColor: 'transparent' }}
					allowFullScreen
					allow={iFrameOptions}
				/>
			);
		}

		return (
			<WebView
				testID={`${testIdPrefix ?? 'HSWebView'}_WebView`}
				key={`${testIdPrefix ?? 'HSWebView'}_WebView`}
				ref={ref}
				source={{ uri: src }}
				style={{
					...platformStyle,
					flex: 1,
					backgroundColor: 'transparent',
					zIndex: 1
				}}
				forceDarkOn={isDarkColor(theme.background)}
				startInLoadingState={true}
				renderLoading={() => (
					<View style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={'large'} />
					</View>
				)}
				cacheEnabled={!cacheDisabled}
				javaScriptEnabled
				onMessage={onMessage}
				scrollEnabled={!noScroll}
				overScrollMode={noScroll ? 'content' : 'always'}
				injectedJavaScript={noScroll ? mapInjection : undefined}
				containerStyle={{ backgroundColor: 'transparent' }}
				cacheMode={cacheDisabled ? 'LOAD_NO_CACHE' : 'LOAD_DEFAULT'}
			/>
		);
	};

	return (
		<View style={{ ...style, height: height ?? '100%', width: width ?? '100%' }}>
			{label && <FormLabel testID={`${testIdPrefix ?? 'HSWebView'}_label_${label}`} label={label} style={{ marginBottom: 10 }} />}
			{renderContent()}
		</View>
	);
});
