import { IVote } from 'config/interfaces';
import { useTheme } from 'hooks/useTheme';
import React, { useState } from 'react';
import { Text } from 'components/Text';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { View } from 'react-native';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { ChildButton } from 'components/Button';
import { Icon, VoteIcon } from 'components/Icon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AnimatedLive } from 'components/Animated';
import { useDate } from 'hooks/useDate';
import { hsBorderRadius, hsBottomMargin } from 'config/styleConstants';

interface IVoteReferenceItem {
	testID: string;
	item: IVote;
	onPress?: () => void;
}

export const VoteReferenceItem = (props: IVoteReferenceItem) => {
	const { testID, item, onPress } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { getComparedDateString } = useDate();

	const [liveWidth, setLiveWidth] = useState<number>(0);

	const _renderAvailability = () => {
		if (item.openFrom || item.openUntil) {
			const now = moment();
			const isFinished = item.openUntil && now.isAfter(item.openUntil);
			const hasStarted = !item.openFrom || now.isAfter(item.openFrom);

			let text = '';
			let color = theme.text;

			if (isFinished) {
				color = theme.danger ?? 'red';
			} else if (hasStarted) {
				color = theme.success ?? 'green';
			}

			if (item.openFrom) {
				const openFromMoment = moment(item.openFrom);

				if (now.isBefore(openFromMoment)) {
					text = t('VoteAvailableFrom').replace('%DATE%', getComparedDateString(item.openFrom));
				} else if (isEmptyString(item.openUntil)) {
					return null;
				}
			}

			if (item.openUntil && isEmptyString(text)) {
				const openUntilMoment = moment(item.openUntil);

				if (now.isBefore(openUntilMoment)) {
					text = t('VoteAvailableUntil').replace('%DATE%', getComparedDateString(item.openUntil));
				} else {
					text = t('VoteAvailableUntilDone').replace('%DATE%', getComparedDateString(item.openUntil));
				}
			}

			return (
				<View style={{ flexDirection: 'row', marginRight: 10 }}>
					<Icon name={EDefaultIconSet.Clock} color={color} size={15} />
					<Text style={{ fontSize: 12, marginLeft: 5, color }}>{text}</Text>
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress}
			isDisabled={!onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: hsBottomMargin,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: hsBorderRadius,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<VoteIcon hideText voteType={item.votingType} testID="votelist" />
			<View
				style={{
					flex: 1,
					paddingLeft: 10,
					paddingRight: SHOW_CHEVRON_IN_LIST_ITEM ? 10 : item.isActive ? liveWidth : 0
				}}
			>
				<Text bold>{item.question}</Text>
				{_renderAvailability()}
			</View>
			<AnimatedLive isVisible={item.isActive} onLayout={(width) => setLiveWidth(width)} containerStyle={{ top: 5, right: 10 }} />
			{SHOW_CHEVRON_IN_LIST_ITEM && <Icon name={EDefaultIconSet.ChevronRight} size={30} />}
		</ChildButton>
	);
};
