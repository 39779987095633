import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { IEditMapFormErrors, IEditMapFormValues } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { FormTextInput } from 'components/Form/FormTextInput';
import { Text } from 'components/Text';
import { t } from 'i18next';
import { FormMediaPicker } from 'components/Form/Image';
import * as ImagePicker from 'expo-image-picker';
import { FormLabel } from 'components/Form/FormLabel';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Button } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { hsTextBottomMargin } from 'config/styleConstants';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { isEmptyObject } from 'helper/object';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { FormMultiSwitch } from 'components/Form';

interface IMapForm {
	values: IEditMapFormValues;
	errors: IEditMapFormErrors;
	onChange: (val: IEditMapFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'mapform';

const mapExample = require('../../../assets/images/mapExample/mapExample.png');

export const MapForm = (props: IMapForm) => {
	const { values, errors, onChange, isLoading } = props;
	const route = useRoute();
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [mapPositionCount, setMapPositionCount] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		if (activeSpace) {
			const _positions = content.mappositions?.filter(
				(e) =>
					e.spaceId === activeSpace.spaceId &&
					e.map?.id === route.params?.id &&
					values.mappositions?.includes(e.id) &&
					!e.isDeleted
			);

			setMapPositionCount(_positions?.length ?? 0);
		}
	}, [content.mappositions, activeSpace, values.mappositions]);

	useEffect(() => {
		if (values.showBacklinks === undefined) {
			values.showBacklinks = true;
		}
	}, [values]);

	const _getPositionsHint = () => {
		let positionCountHint = `${t('MapPositionCountHint').replace('%COUNT%', mapPositionCount.toString() ?? '0')}`;
		if (mapPositionCount === 1) {
			positionCountHint = positionCountHint.replace('Positionen', 'Position').replace('positions', 'position');
		}
		return positionCountHint;
	};

	return (
		<View>
			<HSCard>
				<View style={{ flex: 1 }}>
					<FormTextInput
						testID={`${TESTIDPREFIX}_formtextinput_title`}
						label={t('title')}
						isRequired
						value={values.title}
						error={errors.title}
						onChangeText={(title) => onChange({ ...values, title })}
						isDisabled={isLoading}
					/>
					{values.image && (
						<View style={{ marginBottom: hsTextBottomMargin, marginTop: -(hsTextBottomMargin / 2) }}>
							<Text style={{ color: theme.text }}>{`${t('Change Map Hint AspectRatio')}`}</Text>
							<Text style={{ color: theme.text }}>{`${t('Change Map Hint Positions')}`}</Text>
						</View>
					)}
					<FormMediaPicker
						isOutline
						hasDeleteButton
						testID={`${TESTIDPREFIX}_mediapicker_image`}
						label={t('Map')}
						isRequired
						value={values.image ?? mapExample}
						error={errors.image}
						onChangePreview={(image) => {
							onChange({ ...values, image });
						}}
						onChange={(image) => {
							if (image === null) {
								let _values = { ...values };
								_values.image = mapExample ?? image;
								onChange({ ..._values });
							} else {
								onChange({ ...values, image });
							}
						}}
						isDisabled={isLoading}
						isDeleteDisabled={isEmptyObject(values.image)}
						mediaTypes={ImagePicker.MediaTypeOptions.Images}
					/>
					<FormLabel testID={`${TESTIDPREFIX}_label_mappositions`} label={t('Positions')} />
					<Text style={{ marginBottom: hsTextBottomMargin, color: theme.text }}>{`${t('EditMapPositionsHint')}`}</Text>
					{(route.params?.prohibitNavigation || !values?.image?.url) && (
						<Text style={{ marginBottom: 10, color: theme.danger }}>{`${t('MapFormSaveBeforeContinue')}`}</Text>
					)}
					<Button
						style={{ alignSelf: 'center', maxWidth: '100%' }}
						isDisabled={route.params?.prohibitNavigation || !values?.image?.url}
						testID={`${TESTIDPREFIX}_button_addmappositions`}
						title={values?.mappositions && values.mappositions.length > 0 ? t('EditMapPositions') : t('AddMapPositions')}
						onPress={() => navigation.navigate(ERoutes.MapPositionsEdit, { spaceId: activeSpace?.spaceId, id: values.id })}
					/>
					<Text style={{ color: theme.text, alignSelf: 'center', marginTop: 5, marginBottom: hsTextBottomMargin }}>
						{_getPositionsHint()}
					</Text>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_showbacklinks`}
						label={t('ShowBacklinksTitle')}
						value={values.showBacklinks ? 'true' : 'false'}
						options={[
							{
								key: 'true',
								label: t('Show')
							},
							{
								key: 'false',
								label: t('Hide')
							}
						]}
						onChange={(val) => onChange({ ...values, showBacklinks: val === 'true' ? true : false })}
						hint={t('ShowBacklinksHint')}
						isDisabled={isLoading}
						formStyle={{ marginBottom: 0 }}
					/>
				</View>
			</HSCard>
		</View>
	);
};
