import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { EDefaultIconSet, isEmptyString } from 'helper';

import { useContent } from 'hooks/useContent';
import { useFeature } from 'hooks/useFeature';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { hsTopScreenPadding } from 'config/styleConstants';
import { Keyboard, ScrollView, View } from 'react-native';
import { EditSpaceInitialFormValues, IContentTypeField, IEditSpaceFormValues, ISelfServiceSettings } from 'config/interfaces';
import { FormCheckbox, FormMultiSwitch, FormTagCloud } from 'components/Form';
import { HSCard } from 'components/Card';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import { SectionHeader } from 'components/Text';
import { selfServiceEditableOptions } from 'rematch/interfaces';
import i18next from 'i18next';
import { FormLabel } from 'components/Form/FormLabel';
import { FormHint } from 'components/Form/FormHint';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SelfServiceSettings>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SelfServiceSettings>;
type RouteParams = StackParamList[ERoutes.SelfServiceSettings];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'selfservicesettings';

export const SelfServiceSettingsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { getContentTypeFields } = useContent('mediaitem');

	const [isUpdateSpaceLoading, setIsUpdateSpaceLoading] = useState<boolean>(false);
	const [editSpaceFormValues, setEditSpaceFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [mediaItemCategoryFields, setMediaItemCategoryFields] = useState<IContentTypeField[]>([]);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);

	useEffect(() => {
		let _mediaItemCategoryFields: typeof mediaItemCategoryFields = [];
		if (activeSpace) {
			const selfServiceSettings: ISelfServiceSettings = activeSpace.selfServiceSettings
				? { ...activeSpace.selfServiceSettings }
				: {
						expoSelfServiceSettings: {
							editable: 'yes',
							allowReports: false
						},
						speakerSelfServiceSettings: {
							editable: 'yes',
							allowReports: false
						}
				  };
			setEditSpaceFormValues({
				spaceId: activeSpace.spaceId,
				selfServiceSettings
			});

			_mediaItemCategoryFields = getContentTypeFields().filter((e) => e.fieldType === 'category');
		}
		setMediaItemCategoryFields(_mediaItemCategoryFields);
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace()
		});
	}, [editSpaceFormValues]);

	useEffect(() => {
		navigation.setOptions({
			isLoading: isUpdateSpaceLoading
		});
	}, [isUpdateSpaceLoading]);

	const _updateSpace = async () => {
		setIsUpdateSpaceLoading(true);
		const updateSuccess = await updateSpace({ data: editSpaceFormValues });
		setIsUpdateSpaceLoading(false);

		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _handleChange = (values: IEditSpaceFormValues) => {
		setEditSpaceFormValues(values);
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _getVisibilityHint = (type: string, label: string) => {
		let fieldValue;

		switch (type) {
			case 'expo':
				fieldValue = editSpaceFormValues.selfServiceSettings?.expoSelfServiceSettings?.editable;
				break;
			case 'speaker':
				fieldValue = editSpaceFormValues.selfServiceSettings?.speakerSelfServiceSettings?.editable;
				break;
			default:
				break;
		}
		switch (fieldValue) {
			case 'yes':
				return t('SelfServiceEditableHintYes').replace('%TYPE%', label);
			case 'readOnly':
				return t('SelfServiceEditableHintReadOnly').replace('%TYPE%', label);
			case 'no':
				return t('SelfServiceEditableHintNo').replace('%TYPE%', label);
			default:
				return undefined;
		}
	};

	const _updateValue = (type: string, field: string, value: any) => {
		const values = { ...editSpaceFormValues };

		if (!values.selfServiceSettings) {
			values.selfServiceSettings = {};
		}

		switch (type) {
			case 'expo':
				if (!values.selfServiceSettings.expoSelfServiceSettings) {
					values.selfServiceSettings.expoSelfServiceSettings = {
						editable: 'yes'
					};
				}
				values.selfServiceSettings.expoSelfServiceSettings[field] = value;
				break;
			case 'speaker':
				if (!values.selfServiceSettings.speakerSelfServiceSettings) {
					values.selfServiceSettings.speakerSelfServiceSettings = {
						editable: 'yes'
					};
				}
				values.selfServiceSettings.speakerSelfServiceSettings[field] = value;
				break;
			default:
				break;
		}

		_handleChange(values);
	};

	const _getValueForField = (type: string, fieldName: string) => {
		switch (type) {
			case 'expo':
				if (editSpaceFormValues.selfServiceSettings?.expoSelfServiceSettings) {
					return editSpaceFormValues.selfServiceSettings?.expoSelfServiceSettings[fieldName];
				}
			case 'speaker':
				if (editSpaceFormValues.selfServiceSettings?.speakerSelfServiceSettings) {
					return editSpaceFormValues.selfServiceSettings.speakerSelfServiceSettings[fieldName];
				}
			default:
				return undefined;
		}
	};

	const _getTagCloudValueForField = (type: string, fieldName: string) => {
		switch (type) {
			case 'expo':
				if (editSpaceFormValues.selfServiceSettings?.expoSelfServiceSettings) {
					return (
						editSpaceFormValues.selfServiceSettings.expoSelfServiceSettings.uploadCategories?.find(
							(e) => e.fieldName === fieldName
						)?.keys ?? undefined
					);
				}
			case 'speaker':
				if (editSpaceFormValues.selfServiceSettings?.speakerSelfServiceSettings) {
					return (
						editSpaceFormValues.selfServiceSettings.speakerSelfServiceSettings.uploadCategories?.find(
							(e) => e.fieldName === fieldName
						)?.keys ?? undefined
					);
				}
			default:
				return undefined;
		}
	};

	const _updateTagCloudValueForField = (type: string, field: IContentTypeField, value: string) => {
		const values = { ...editSpaceFormValues };

		if (!values.selfServiceSettings) {
			values.selfServiceSettings = {};
		}

		// Checks if previous values still exist in field options
		let checkedValue = value;
		let splitted = checkedValue.split(',').filter((e) => !isEmptyString(e));
		splitted = splitted.filter((e) => field.options?.find((e2) => e2.key === e) !== undefined);
		checkedValue = splitted.join(',');

		switch (type) {
			case 'expo':
				if (!values.selfServiceSettings.expoSelfServiceSettings) {
					values.selfServiceSettings.expoSelfServiceSettings = {
						editable: 'yes'
					};
				}
				if (!values.selfServiceSettings.expoSelfServiceSettings.uploadCategories) {
					values.selfServiceSettings.expoSelfServiceSettings.uploadCategories = [];
				}

				const foundEx = values.selfServiceSettings.expoSelfServiceSettings.uploadCategories.find(
					(e) => e.fieldName === field.fieldName
				);
				if (foundEx) {
					foundEx.keys = checkedValue;
				} else {
					values.selfServiceSettings.expoSelfServiceSettings.uploadCategories.push({
						fieldName: field.fieldName,
						keys: checkedValue
					});
				}

				values.selfServiceSettings.expoSelfServiceSettings.uploadCategories =
					values.selfServiceSettings.expoSelfServiceSettings.uploadCategories.filter((e) => !isEmptyString(e.keys));

				break;
			case 'speaker':
				if (!values.selfServiceSettings.speakerSelfServiceSettings) {
					values.selfServiceSettings.speakerSelfServiceSettings = {
						editable: 'yes'
					};
				}
				if (!values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories) {
					values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories = [];
				}

				const foundSp = values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories.find(
					(e) => e.fieldName === field.fieldName
				);
				if (foundSp) {
					foundSp.keys = checkedValue;
				} else {
					values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories.push({
						fieldName: field.fieldName,
						keys: checkedValue
					});
				}

				values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories =
					values.selfServiceSettings.speakerSelfServiceSettings.uploadCategories.filter((e) => !isEmptyString(e.keys));
				break;
			default:
				break;
		}

		_handleChange(values);
	};

	const _renderVisibilityField = (type: string, label: string) => {
		return (
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_${type}editable`}
				label={t('SelfServiceEditableLabel').replace('%TYPE%', label)}
				hint={_getVisibilityHint(type, label)}
				value={_getValueForField(type, 'editable')}
				onChange={(val) => _updateValue(type, 'editable', val)}
				options={selfServiceEditableOptions()}
				isDisabled={isUpdateSpaceLoading}
			/>
		);
	};

	const _renderIntroText = (type: string) => {
		return (
			<FormMarkdownEditor
				testID={`${TESTIDPREFIX}_markdown_${type}_intro`}
				label={t('Self Service Intro')}
				hint={t('Self Service Intro Hint')}
				value={_getValueForField(type, 'introText')}
				onChange={(val) => _updateValue(type, 'introText', val)}
				isDisabled={isUpdateSpaceLoading}
			/>
		);
	};

	const _renderAllowReportsField = (type: string) => {
		return (
			<FormCheckbox
				testID={`${TESTIDPREFIX}_checkbox_allowreports`}
				label={t('allowReports')}
				hint={t('allowReportsHint')}
				value={_getValueForField(type, 'allowReports')}
				onPress={() => _updateValue(type, 'allowReports', !_getValueForField(type, 'allowReports'))}
				isDisabled={isUpdateSpaceLoading}
			/>
		);
	};

	const _renderMediaItemCategories = (type: string) => {
		if (mediaItemCategoryFields.length > 0) {
			return (
				<View>
					<FormLabel testID="" label={t('Upload Categories')} />
					<FormHint testID="" hint={t('Upload Categories Hint')} />
					{mediaItemCategoryFields.map((field) => {
						return (
							<FormTagCloud
								testID={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
								formStyle={{ marginBottom: 10 }}
								label={field.fieldLabel}
								options={field.options}
								isDisabled={isUpdateSpaceLoading}
								value={_getTagCloudValueForField(type, field.fieldName)}
								onChange={(val) => _updateTagCloudValueForField(type, field, val)}
							/>
						);
					})}
				</View>
			);
		}

		return null;
	};

	const _renderExpoSettings = () => {
		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'expos')) {
			return (
				<HSCard>
					<SectionHeader label={t('Expo Settings')} />
					{_renderVisibilityField('expo', 'Expo')}
					{_renderIntroText('expo')}
					{_renderAllowReportsField('expo')}
					{_renderMediaItemCategories('expo')}
				</HSCard>
			);
		}

		return null;
	};

	const _renderSpeakerSettings = () => {
		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'speakers')) {
			return (
				<HSCard>
					<SectionHeader label={t('Speaker Settings')} />
					{_renderVisibilityField('speaker', t('Speakers'))}
					{_renderIntroText('speaker')}
					{_renderAllowReportsField('speaker')}
					{_renderMediaItemCategories('speaker')}
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					width: screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding
				}}
			>
				{_renderExpoSettings()}
				{_renderSpeakerSettings()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const SelfServiceSettingsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Self Service Settings')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
