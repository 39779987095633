import React, { ReactNode } from 'react';
import { useTheme } from 'hooks/useTheme';
import { View } from 'react-native';

interface IHeader {
	children?: ReactNode | ReactNode[];
}

export const NavigationHeader = (props: IHeader) => {
	const { children } = props;
	const { theme } = useTheme();

	return (
		<View
			style={{
				width: '100%',
				zIndex: 999,
				paddingTop: 10,
				paddingBottom: 0,
				paddingHorizontal: 10,
				backgroundColor: theme.background,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				position: 'relative',
				shadowColor: theme.hsShadowColor,
				height: 60,
				overflow: 'visible'
			}}
		>
			{children}
		</View>
	);
};
