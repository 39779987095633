import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { Keyboard, ScrollView } from 'react-native';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { MeetingTableForm } from 'components/Forms';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { ICreateMeetingTableFormValues } from 'config/interfaces';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingTableCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingTableCreate>;
type RouteParams = StackParamList[ERoutes.MeetingTableCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'meetingtablecreate';

export const MeetingTableCreateScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('meetingtable');
	const { getFormSchema } = useForm();

	const [formValues, setFormValues] = useState<ICreateMeetingTableFormValues>({});
	const [formErrors, setFormErrors] = useState({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const createMeetingTable = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMeetingTable);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createMeetingTable(),
			isLoading
		});
	}, [formValues, isLoading]);

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _createMeetingTable = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await createMeetingTable({ meetingtable: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="meetingtables">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MeetingTableForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MeetingTableCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Meeting Tables')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
