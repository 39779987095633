import React, { useState } from 'react';
import { HSCard } from 'components/Card';
import { View } from 'react-native';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useTheme } from 'hooks/useTheme';
import {
	ICreateScheduleFormErrors,
	ICreateScheduleFormValues,
	IUpdateScheduleFormErrors,
	IUpdateScheduleFormValues
} from 'config/interfaces';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';
import { useTranslation } from 'react-i18next';
import { SpeakerGroupsModal } from 'components/Modal';
import { Text } from 'components/Text';
interface IScheduleForm {
	values: ICreateScheduleFormValues | IUpdateScheduleFormValues;
	errors: ICreateScheduleFormErrors | IUpdateScheduleFormErrors;
	onChange: (val: ICreateScheduleFormValues | IUpdateScheduleFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'scheduleform';

export const ScheduleForm = (props: IScheduleForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('schedule');
	const { renderFormField } = useForm();
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [isSpeakerGroupsModalVisible, setIsSpeakerGroupsModalVisible] = useState<boolean>(false);

	return (
		<View>
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) => {
						return (
							<View key={field.fieldName}>
								{renderFormField('schedule', TESTIDPREFIX, values, errors, field, onChange)}
								{field.fieldName === 'speakers' ? (
									<View style={{ marginTop: -20 }}>
										{(values.speakerGroups?.groups ?? []).length > 0 ? (
											<View style={{ marginBottom: 5 }}>
												<Text bold style={{ marginBottom: 5 }}>
													{t('ExistingGroups')}
												</Text>
												{values.speakerGroups?.groups.map((group) => {
													return (
														<View key={group.key} style={{ flexDirection: 'row', alignItems: 'center' }}>
															<View
																style={{
																	width: 4,
																	height: 4,
																	borderRadius: 2,
																	backgroundColor: theme.text,
																	marginRight: 4
																}}
															/>
															<Text bold>
																{group.title} <Text>{`(${group.speakerIds.length} ${t('Speakers')})`}</Text>
															</Text>
														</View>
													);
												})}
											</View>
										) : null}

										<RoundButton
											testID={`${TESTIDPREFIX}_button_speakergroup`}
											icon={EDefaultIconSet.Speakers}
											title={t('ManageSpeakerGroups')}
											onPress={() => setIsSpeakerGroupsModalVisible(true)}
											alignSelf="flex-end"
										/>
									</View>
								) : null}
							</View>
						);
					})}
				</HSCard>
			))}
			<SpeakerGroupsModal
				isVisible={isSpeakerGroupsModalVisible}
				values={values}
				onChange={onChange}
				onClose={() => setIsSpeakerGroupsModalVisible(false)}
			/>
		</View>
	);
};
