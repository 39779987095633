import React, { useState } from 'react';
import { View } from 'react-native';

interface IVimeoPlayer {
	url?: string;
	autoplay?: boolean;
}

export const VimeoPlayer = (props: IVimeoPlayer) => {
	const { url, autoplay } = props;

	const [containerWidth, setContainerWidth] = useState<number>(1);

	return (
		<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)} style={{ width: '100%' }}>
			<iframe
				src={url}
				style={{ width: '100%', height: (containerWidth / 16) * 9 }}
				allow="autoplay; fullscreen; camera; microphone; speaker; display-capture"
			/>
		</View>
	);
};
