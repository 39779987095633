import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultMeetingTableFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldHint: i18next.t('MeetingTableTitleHint'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'subtitle',
			fieldLabel: i18next.t('subtitle'),
			fieldHint: i18next.t('MeetingSubtitleTitleHint'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		}
	];

	return arr;
};
