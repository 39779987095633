import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { ICreateWebhookFormValues, IWebhookFormErrors } from 'config/interfaces';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { WebhookForm } from 'components/Forms/Webhook';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getCreateWebhookFormSchema } from 'config/yupSchemas';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.WebhookCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.WebhookCreate>;
type RouteParams = StackParamList[ERoutes.WebhookCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'webhookcreate';

export const WebhookCreateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [formValues, setFormValues] = useState<ICreateWebhookFormValues>({});
	const [formErrors, setFormErrors] = useState<IWebhookFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const createWebhook = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createWebhook);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createWebhook(),
			isLoading: isLoading
		});
	}, [activeSpace, formValues, isLoading, formErrors]);

	const _create = async () => {
		setIsLoading(true);
		const res = await createWebhook({ webhook: formValues });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	const _createWebhook = async () => {
		const errors = await validateForm(getCreateWebhookFormSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (formValues.isActive) {
			_create();
		} else {
			showAlert({
				title: t('WebhookInactive'),
				message: t('WebhookInactiveMessage'),
				buttons: [
					{
						text: t('Save'),
						onPress: () => _create()
					},
					{
						text: t('Cancel'),
						style: 'cancel'
					}
				]
			});
		}
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="webhooks">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<WebhookForm values={formValues} errors={formErrors} isLoading={isLoading} onChange={updateFormValues} />
			</ScrollView>
		</ScreenContainer>
	);
};

export const WebhookCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title="Webhooks" />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
