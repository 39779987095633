import { createModel } from '@rematch/core';
import { IS_WEB } from 'helper/platform';
import { Alert } from 'react-native';
import { HSAlert, HSAlertPayload } from 'rematch/interfaces/alert';
import { RootModel } from './index';

type IDefaultState = {
	isAlertVisible: boolean;
	alertData: HSAlert;
};

export const alert = createModel<RootModel>()({
	state: {
		isAlertVisible: false,
		alertData: {
			title: '',
			message: '',
			hint: '',
			buttons: [],
			options: undefined
		}
	} as IDefaultState,
	reducers: {
		setAlert(state, _isAlertVisible: IDefaultState['isAlertVisible']) {
			rematchLog('setAlert');
			return {
				...state,
				isAlertVisible: _isAlertVisible
			};
		},
		setAlertData(state, alertData: IDefaultState['alertData']) {
			rematchLog('setAlertData');
			return {
				...state,
				alertData
			};
		},
		toggleAlert(state) {
			rematchLog('toggleAlert');
			return {
				...state,
				isAlertVisible: !state.isAlertVisible
			};
		},
		clear(state) {
			rematchLog('clear alert');
			return {
				isAlertVisible: false,
				alertData: {
					title: '',
					message: '',
					buttons: [],
					options: undefined
				}
			};
		}
	},
	effects: (dispatch) => ({
		async showAlert(payload: HSAlertPayload, store) {
			try {
				const { title, message, hint, buttons, options } = payload;
				if (IS_WEB) {
					dispatch.alert.setAlert(true);
					dispatch.alert.setAlertData({ title: title, message: message, hint: hint, buttons: buttons, options: options });
				} else {
					Alert.alert(title, message, buttons, options);
				}
			} catch (error) {
				console.log('showAlert', error);
			}
		},
		async hideAlert(payload, store) {
			try {
				if (IS_WEB) {
					dispatch.alert.clear();
				}
			} catch (error) {
				console.log('hideAlert', error);
			}
		}
	})
});
