import React from 'react';
import { useTranslation } from 'react-i18next';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormErrors, IEditVoteFormValues } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';
import { FormTextInput } from 'components/Form/FormTextInput';

interface IVoteMultipleChoiceForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteMultipleChoiceForm = (props: IVoteMultipleChoiceForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading}>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_mincount`}
				label={t('CategoryMinCount')}
				hint={t('VoteMinCountHint')}
				value={values.minValue?.toString()}
				onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
				keyboardType="number-pad"
				onlyNumbers
				isRequired
			/>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_maxcount`}
				label={t('CategoryMaxCount')}
				hint={t('VoteMaxCountHint')}
				value={values.maxValue?.toString()}
				onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
				keyboardType="number-pad"
				onlyNumbers
				isRequired
			/>
		</CommonVoteForm>
		// 	<FormMultiSwitch
		// 		testID={`${testIdPrefix}_multiswitch_viewtype`}
		// 		label={t('viewType')}
		// 		hint={t('MultpleChoiceViewTypeHint')}
		// 		error={errors.viewType}
		// 		value={values.viewType}
		// 		options={[
		// 			{ key: 'bar', label: t('barViewType') },
		// 			{ key: 'pie', label: t('pieViewType') }
		// 		]}
		// 		onChange={(val) => onChange({ ...values, viewType: val })}
		// 		isDisabled={isLoading}
		// 	/>
		// </CommonVoteForm>
	);
};
