import { RoundButton } from 'components/Button';
import { IVote } from 'config/interfaces/vote';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString, IS_WEB, showFormErrorToast, validateForm } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { getSubmitWordCloudFormSchema } from 'config/yupSchemas';
import { useSelector } from 'react-redux';
import { WordCloud, IWordListItem } from 'components/Vote/ActiveVote/VoteWordCloud/WordCloud';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { PreviewWordList } from 'helper/previewAnswers/previewAnswersWordCloud';
import { HSCard } from 'components/Card';
import { useNavigation } from '@react-navigation/core';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';
import { WordCloudForm } from './WordCloudForm';
import { NoData } from 'components/NoData';
import { IVoteResult } from 'config/interfaces';

interface IVoteWordCloud {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	fullwidth?: boolean;
	isPresenterView?: boolean;
}

export const WORDCLOUDSPLITSEQUENCE = '<%>';

const TESTIDPREFIX = 'activevote';

export const VoteWordCloud = (props: IVoteWordCloud) => {
	const { vote, isPreview, resultsOnly, fullwidth, isPresenterView } = props;
	const { t } = useTranslation();
	const navigation = useNavigation();
	const { trackAction } = useTracker();
	const { screenWidth, isTabletOrMobile } = useQuery();

	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [value, setValue] = useState<string[]>([]);
	const [valueSubmitted, setValueSubmitted] = useState<boolean>(false);
	const [wordList, setWordList] = useState<IWordListItem[]>([]);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [showAnswersPressed, setShowAnswersPressed] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered && !vote.hideResults) {
			setVotingShowResults(true);
		}
	}, [vote]);

	useEffect(() => {
		const myAnswer = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		);
		if (!myAnswer && !showAnswersPressed && valueSubmitted) {
			setVotingShowResults(false);
			setValue([]);
			setValueSubmitted(false);
		}
		if (myAnswer && myAnswer.value && value.length === 0) {
			setValue(myAnswer.value.split(WORDCLOUDSPLITSEQUENCE));
			setValueSubmitted(true);
		}
	}, [voteAnswers]);

	useEffect(() => {
		_generateWordList();
	}, [voteAnswers]);

	const _generateWordList = () => {
		let _wordList: IWordListItem[] = [];

		if (isPreview) {
			_wordList = PreviewWordList.map((e) => {
				return { count: e.count, text: t(e.text) };
			});
		} else {
			const answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;

			_wordList = (answerForThis?.options ?? []).map((option) => {
				return {
					text: option.key,
					count: option.value
				};
			});
		}

		_wordList.sort((a, b) => {
			return a.count > b.count ? -1 : 1;
		});

		setWordList(_wordList);
	};

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		if (isPreview) {
			setVotingShowResults(true);
			return;
		}
		const errors = await validateForm(getSubmitWordCloudFormSchema(vote.minValue, vote.maxValue), { value });
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}
		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		const res = await submitVoteAnswer({
			voteId: vote.id,
			value: value.join(WORDCLOUDSPLITSEQUENCE),
			isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous,
			isDeleted: false
		});
		navigation.setParams({ prohibitNavigation: false });
		setIsSubmitVoteAnswerLoading(false);
		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			setVotingShowResults(true);
			setValueSubmitted(true);
		}
	};

	const _renderForm = () => {
		return (
			<WordCloudForm
				isLoading={isSubmitVoteAnswerLoading}
				value={value}
				onChange={(val) => {
					setValue(val);
					if (!isPreview) navigation.setParams({ prohibitNavigation: true });
				}}
				vote={vote}
				isPreview={isPreview}
				answerAnonymous={answerAnonymous}
				setAnswerAnonymous={(val) => setAnswerAnonymous(val)}
			/>
		);
	};

	const _renderContent = () => {
		if ((votingShowResults && !vote?.hideResults) || resultsOnly || !vote.isActive) {
			if (wordList.length === 0) {
				return <NoData type="NoWordCloudWords" />;
			}
			return (
				<HSCard style={{ flex: 1, width: '100%', height: '100%', alignItems: 'center' }}>
					<View style={{ flex: 1, width: '100%', height: '100%' }}>
						{wordList.length > 0 ? (
							<WordCloud
								wordList={wordList}
								minFontSize={isPresenterView ? 30 : IS_WEB && !isTabletOrMobile ? 20 : 10}
								maxFontSize={isPresenterView ? 56 : IS_WEB && !isTabletOrMobile ? 30 : 20}
							/>
						) : null}
					</View>
				</HSCard>
			);
		}

		return <View style={{ flex: 1 }}>{_renderForm()}</View>;
	};

	const _renderActionButtons = () => {
		if (resultsOnly || (votingShowResults && vote?.hideResults) || !vote.isActive) {
			return null;
		}
		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide
				}}
			>
				<HSCard>
					{votingShowResults && !vote?.hideResults ? (
						<RoundButton
							isFloatingButton
							testID={`${TESTIDPREFIX}_button_edit`}
							icon={EDefaultIconSet.Edit}
							onPress={() => {
								setVotingShowResults(false);
								setShowAnswersPressed(true);
								setVotingIsAnswered(false);
							}}
							alignSelf="center"
							title={!value || value?.length === 0 ? t('submitAnswer') : t('changeAnswer')}
						/>
					) : (
						<RoundButton
							isFloatingButton
							icon={EDefaultIconSet.Save}
							testID={`${TESTIDPREFIX}_button_submit`}
							title={t('submitAnswer')}
							isLoading={isSubmitVoteAnswerLoading}
							isDisabled={!value || value?.length === 0}
							onPress={() => {
								_submitVoteAnswer();
								setVotingShowResults(true);
							}}
							alignSelf="center"
						/>
					)}
				</HSCard>
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					contentContainerStyle={{
						flexGrow: 1,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					<ActiveVoteHeader activeVote={vote} showResult={resultsOnly} largerText={isPresenterView} />
					<View style={{ flex: 1 }}>{_renderContent()}</View>
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButtons()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
		</View>
	);
};
