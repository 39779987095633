import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { IAttendee, IContentTypeField, IExpo, ILauncherSpace, IMediaItem, ISchedule, ISpeaker, TSectionPosition } from 'config/interfaces';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';
import { useDetail } from 'hooks/useDetail';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { Image } from 'components/Image';
import { EDefaultIconSet } from 'helper';
import { getDefaultAspectRatioStyle, _getSectionWidth } from 'helper/layout';
import { isEmptyString } from 'helper/string';
import { IS_WEB } from 'helper/platform';

import { Subtitle, Title } from 'components/Text';
import { ChildButton } from 'components/Button/ChildButton';
import { ShareButton } from 'components/Button/ShareButton';
import { HSCard } from 'components/Card/HSCard';
import { ERoutes } from 'components/Navigation/routes';
import { Avatar } from 'components/User/Avatar';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { showToast } from 'helper/toast';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'components/Icon';

interface ISpeakerDetail {
	testID: string;
	detailFields: IContentTypeField[];
	speaker?: ISpeaker | any;
	space?: ILauncherSpace;
	referencedExpos?: IExpo[];
	referencedSchedules?: ISchedule[];
	referencedMediaItems?: IMediaItem[];
	referencedAttendee?: IAttendee;
	isSelfService?: boolean;
	noScreenPaddings?: boolean;
}

export const SPEAKER_COMPANY_IMAGE_SIZE = 130;

export const SpeakerDetail = (props: ISpeakerDetail) => {
	const {
		testID,
		detailFields,
		speaker,
		space,
		referencedExpos,
		referencedSchedules,
		referencedMediaItems,
		referencedAttendee,
		isSelfService,
		noScreenPaddings
	} = props;
	const { renderSocialMedia, renderSections } = useDetail();
	const { isTabletOrMobile, screenWidth } = useQuery();
	const { t } = useTranslation();
	const navigation = useNavigation();
	const { theme } = useTheme();

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (speaker?.jobTitle && speaker.company && !isEmptyString(speaker?.jobTitle) && !isEmptyString(speaker.company)) {
			str = `${speaker.jobTitle} | ${speaker.company}`;
		} else if (speaker?.jobTitle && !isEmptyString(speaker.jobTitle)) {
			str = speaker.jobTitle;
		} else if (speaker?.company && !isEmptyString(speaker.company)) {
			str = speaker.company;
		}

		if (!isEmptyString(str)) {
			return <Subtitle>{str}</Subtitle>;
		}

		return null;
	};

	const _renderCompanyImage = () => {
		if (speaker?.companyImage?.url) {
			return (
				<Image
					testID={`${testID}_image`}
					style={{ ...getDefaultAspectRatioStyle(SPEAKER_COMPANY_IMAGE_SIZE), marginVertical: 10, borderRadius: hsBorderRadius }}
					resizeMode="contain"
					url={speaker?.companyImage?.url}
					expectedRatio={16 / 9}
					imageSize={IS_WEB ? 'full' : 'small'}
				/>
			);
		}
		return null;
	};

	const _renderSpeaker = () => {
		if (speaker && space) {
			if (isTabletOrMobile) {
				return (
					<View
						style={{
							width: screenWidth,
							alignSelf: 'center'
						}}
					>
						{_renderStacked()}
					</View>
				);
			}
			const viewType = space?.speakerDetailViewType ?? space?.globalDetailViewType;
			switch (viewType) {
				case 'thirds_1/3_2/3':
				case 'thirds_2/3_1/3':
					return _renderThirds();
				default:
					return _renderStacked();
			}
		}

		return null;
	};

	const _renderSections = (position?: TSectionPosition, fields?: IContentTypeField[]) => {
		if (speaker) {
			return renderSections({
				contentType: 'speaker',
				testID: testID,
				item: { ...speaker, title: `${speaker.speakerTitle ? `${speaker.speakerTitle} ` : ''}${speaker.title}` },
				forbiddenFieldNames: ['selfServiceEditable', 'selfServiceEmail'],
				forbiddenFieldTypes: [],
				position: position,
				isSelfService: isSelfService,
				detailFields: fields ?? detailFields,
				space: space,
				referencedMediaItems: referencedMediaItems,
				referencedSchedules: referencedSchedules,
				referencedExpos: referencedExpos,
				referencedAttendee: referencedAttendee,
				logoWidth: SPEAKER_COMPANY_IMAGE_SIZE
			});
		}
		return null;
	};

	const _renderThirds = () => {
		if (!speaker) {
			return null;
		}
		const hasLeftSection = (_renderSections('left')?.filter((s) => s !== null).length ?? 0) > 0;
		const hasRightSection = (_renderSections('right')?.filter((s) => s !== null).length ?? 0) > 0;

		if (!hasLeftSection && !hasRightSection) {
			return (
				<View
					style={{
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderStacked()}
				</View>
			);
		}

		return (
			<View
				key={`${testID}_speaker_${speaker.id}`}
				style={{ flexDirection: 'row', justifyContent: hasLeftSection && hasRightSection ? 'space-between' : 'center' }}
			>
				{hasLeftSection && (
					<View
						key={`${testID}_sections_left`}
						testID={`${testID}_sections_left`}
						style={{
							alignItems: 'center',
							width: hasRightSection ? _getSectionWidth('left', 'speaker', space) : screenWidth,
							flexDirection: 'column',
							alignSelf: hasRightSection ? 'auto' : 'center'
						}}
					>
						{_renderSections('left')}
					</View>
				)}

				{hasRightSection && (
					<View
						key={`${testID}_sections_right`}
						testID={`${testID}_sections_right`}
						style={{
							alignItems: 'center',
							width: hasLeftSection ? _getSectionWidth('right', 'speaker', space) : screenWidth,
							flexDirection: 'column',
							alignSelf: hasLeftSection ? 'auto' : 'center'
						}}
					>
						{_renderSections('right')}
					</View>
				)}
			</View>
		);
	};

	const _getAttendeeMeetingAndChatButtons = () => {
		if (!referencedAttendee || isSelfService) {
			return null;
		}

		const elements: JSX.Element[] = [];

		if (space?.features?.list.find((e) => e.key === 'chats') && referencedAttendee?.userId !== profile?.userId) {
			elements.push(
				<ChildButton
					key={`${testID}_button_chat`}
					testID={`${testID}_button_chat`}
					style={{ marginHorizontal: 5, justifyContent: 'center' }}
					onPress={() => {
						if (!profile?.allowChats) {
							showToast('info', undefined, t('YouDoNotAllowChats'));
						} else if (referencedAttendee?.allowChats) {
							navigation.navigate(ERoutes.Chat, {
								spaceId: space?.spaceId,
								userId: referencedAttendee.userId
							});
						} else {
							showToast('info', undefined, t('AttendeeDoesNotAllowChats'));
						}
					}}
				>
					<Icon name={EDefaultIconSet.Chat} size={23} color={referencedAttendee?.allowChats ? theme.text : theme.formgray} />
				</ChildButton>
			);
		}

		if (space?.features?.list.find((e) => e.key === 'meetings')?.isActive && referencedAttendee?.userId !== profile?.userId) {
			elements.push(
				<ChildButton
					key={`${testID}_button_meeting`}
					testID={`${testID}_button_meeting`}
					style={{ marginHorizontal: 5, justifyContent: 'center' }}
					onPress={() => {
						if (!profile?.allowMeetingRequests) {
							showToast('info', undefined, t('YouDoNotAllowMeetings'));
						} else if (!profile.allowChats) {
							showToast('info', undefined, t('YouDoNotAllowChatsMeetings'));
						} else if (referencedAttendee?.allowMeetingRequests) {
							navigation.navigate(ERoutes.MeetingRequest, {
								spaceId: space?.spaceId,
								partnerId: referencedAttendee.userId
							});
						} else {
							showToast('info', undefined, t('AttendeeDoesNotAllowMeetings'));
						}
					}}
				>
					<Icon
						name={EDefaultIconSet.Meetings}
						size={23}
						color={
							referencedAttendee?.allowMeetingRequests &&
							referencedAttendee.allowChats &&
							profile?.allowChats &&
							profile?.allowMeetingRequests
								? theme.text
								: theme.formgray
						}
					/>
				</ChildButton>
			);
		}

		if (elements.length > 0) {
			return elements;
		}

		return null;
	};

	const _renderStacked = () => {
		if (speaker) {
			return (
				<View>
					<HSCard style={{ alignItems: 'center' }}>
						<Avatar testID={`${testID}_avatar`} size="xxl" avatar={speaker.image?.url} fullName={speaker.title} />
						<Title style={{ marginTop: 20, marginBottom: 5 }}>{`${speaker.speakerTitle ? `${speaker.speakerTitle} ` : ''}${
							speaker.title
						}`}</Title>
						{_renderCompanyImage()}
						{_renderJobtitleAndCompany()}
						{renderSocialMedia(
							'speaker',
							detailFields,
							speaker,
							testID,
							_getAttendeeMeetingAndChatButtons(),
							<ShareButton
								testID={`${testID}_button_share`}
								key={`${testID}_button_share`}
								itemId={speaker.id}
								title={speaker.title}
								message={speaker.bio}
								type="speaker"
								route={ERoutes.SpeakerDetails}
							/>
						)}
					</HSCard>
					{_renderSections(
						undefined,
						detailFields.filter(
							(e) =>
								(e.showOnDetailScreen || e.visibility === 'visible') &&
								e.fieldType !== 'avatar' &&
								e.fieldType !== 'logo' &&
								e.fieldName !== 'title' &&
								e.fieldName !== 'jobTitle' &&
								e.fieldName !== 'company' &&
								e.fieldName !== 'speakerTitle' &&
								e.fieldName !== 'selfServiceEditable' &&
								e.fieldName !== 'selfServiceEmail' &&
								e.fieldName !== 'companyImage' &&
								e.fieldName !== 'image' &&
								e.fieldType !== 'socialmedia' &&
								e.fieldType !== 'website'
						)
					)}
				</View>
			);
		}

		return null;
	};

	return (
		<View style={{ width: '100%' }}>
			{speaker ? (
				<ScrollView
					contentContainerStyle={{
						paddingTop: noScreenPaddings ? 0 : hsTopScreenPadding,
						paddingHorizontal: noScreenPaddings ? 0 : EHorizontalScreenPadding.Wide,
						width: '100%',
						alignSelf: 'center'
					}}
					testID={`${testID}_scrollview`}
				>
					{_renderSpeaker()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
		</View>
	);
};
