import { SearchBar } from 'components/SearchBar';
import { IMeetingTable } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { MeetingTableListItem } from './MeetingTableListItem';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { HSCard } from 'components/Card/HSCard';
import { useSpace } from 'hooks/useSpace';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { TABBAR_HEIGHT } from 'config/constants';

interface IMeetingTableList {
	testID: string;
	selectedTables?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: IMeetingTable) => void;
	isLoading?: boolean;
	isEditMode?: boolean;
}

export const MeetingTableList = (props: IMeetingTableList) => {
	const { testID, selectedTables, onSelectAll, onSelect, onEdit, onDelete, onPress, isLoading, isEditMode } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [spaceMeetingTables, setSpaceMeetingTables] = useState<IMeetingTable[]>([]);
	const [searchedMeetingTables, setSearchedMeetingTables] = useState<IMeetingTable[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const content = useSelector((store: IRootState) => store.content.content);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		let _tables: typeof spaceMeetingTables = [];

		if (activeSpace && content.meetingtables) {
			_tables = content.meetingtables.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setSpaceMeetingTables(_tables);
	}, [activeSpace, content]);

	useEffect(() => {
		let _searched = [...spaceMeetingTables];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter(
				(item) => item.title.toLowerCase().includes(lowerSearch) || item.subtitle?.toLowerCase().includes(lowerSearch)
			);
		}

		setSearchedMeetingTables(_searched);
	}, [spaceMeetingTables, searchTerm]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedMeetingTables.length > 0) {
			return (
				<FormCheckbox
					testID={`${testID}_checkbox_selectall`}
					label={t('SelectAll')}
					style={{ marginBottom: 10 }}
					value={selectedTables && selectedTables.length === searchedMeetingTables.length}
					onPress={() => {
						if (selectedTables && selectedTables.length === searchedMeetingTables.length) {
							onSelectAll([]);
						} else {
							onSelectAll(searchedMeetingTables.map((ex) => ex.id));
						}
					}}
				/>
			);
		}

		return null;
	};

	const _renderList = () => {
		if (searchedMeetingTables.length === 0) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoMeetingTables" />;
		}

		return (
			<HSCard style={{ flex: 1, marginBottom: hsBottomMargin, paddingHorizontal: hsInnerPadding }}>
				{_renderSelectAll()}
				<FlatList
					keyExtractor={(item) => `meetingtable_${item.id}`}
					data={searchedMeetingTables}
					renderItem={({ item, index }) => (
						<MeetingTableListItem
							testID={`${testID}_button_meetingtable_${index}`}
							item={item}
							isSelected={selectedTables?.includes(item.id)}
							onSelect={onSelect ? () => onSelect(item.id) : undefined}
							onEdit={onEdit ? () => onEdit(item.id) : undefined}
							onDelete={onDelete ? () => onDelete(item.id) : undefined}
							isLoading={isLoading}
						/>
					)}
				/>
			</HSCard>
		);
	};

	return (
		<View style={{ flex: 1, marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0 }}>
			<HSCard style={{ flexDirection: 'row', alignItems: 'center' }}>
				<View style={{ flex: 1 }}>
					<SearchBar testID={`${testID}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
				</View>
			</HSCard>
			{_renderList()}
		</View>
	);
};
