import { IMediaGalleryItem, IStrapiBase } from '.';

export interface ISendingInformation {
	linkedType?: string;
	linkedItemId?: number;
	sentTo?: string[];
}
export interface IMailHistory extends IStrapiBase {
	spaceId: string;
	receivers: string;
	result: {
		success: string[];
		error: {
			userId: string;
			error: string;
		}[];
	};
	templateTitle?: string;
	sentOn: string;
	mailTemplateId?: number;
	subject: string;
	body: string;
	useDefaultHeader?: boolean;
	useDefaultFooter?: boolean;
	attachments?: {
		attachments: {
			filename: string;
			path: string;
		}[];
	};
	sendingInformation?: ISendingInformation;
}

export type TMailTemplateType = 'admin' | 'moderator' | 'attendee' | 'expo' | 'speaker' | 'ticket' | 'invitation' | 'noTemplate';

export interface IMailTemplate extends IStrapiBase {
	key?: string;
	title: string;
	contentType: TMailTemplateType;
	subject: string;
	body: string;
	spaceId: string;
	attachments?: IMediaGalleryItem[];
	useDefaultHeader?: boolean;
	useDefaultFooter?: boolean;
}

export interface IEditMailTemplateFormValues {
	id?: number;
	key?: string;
	contentType?: TMailTemplateType;
	title: string;
	subject: string;
	body: string;
	isDeleted?: boolean;
	attachments?: IMediaGalleryItem[];
	useDefaultHeader?: boolean;
	useDefaultFooter?: boolean;
}

export interface IEditMailTemplateFormErrors {
	title?: string;
	subject?: string;
	body?: string;
	attachments?: string;
	useDefaultHeader?: string;
	useDefaultFooter?: string;
}

export const getEditMailTemplateFormInitialValues = () => {
	const obj: IEditMailTemplateFormValues = {
		title: '',
		subject: '',
		body: '',
		useDefaultHeader: true,
		useDefaultFooter: true
	};

	return obj;
};

export interface ITempMailSettings {
	id?: number;
	host?: string;
	from?: string;
	user?: string;
	password?: string;
	port?: number;
	senderName?: string;
	replyTo?: string;
	secure?: boolean;
	rejectUnauthorized?: boolean;
	spaceId?: string;
}

export interface IMailSettingsErrors {
	host?: string;
	from?: string;
	user?: string;
	password?: string;
	port?: string;
	senderName?: string;
	replyTo?: string;
	secure?: string;
	rejectUnauthorized?: string;
}
