import { IComment } from 'config/interfaces/comment';
import { hsBottomMargin } from 'config/styleConstants';
import { useSpace } from 'hooks/useSpace';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Comment } from './Comment';

interface ICommentList {
	itemId: string;
	testId: string;
	contentType: IComment['contentType'];
}

export const CommentList = (props: ICommentList) => {
	const { itemId, testId, contentType } = props;

	const { activeSpace } = useSpace();
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [foundComments, setFoundComments] = useState<IComment[]>([]);

	const comments = useSelector((store: IRootState) => store.comment.comments);

	useEffect(() => {
		if (activeSpace?.allowFeedComments && activeSpace?.allowFeedComments !== 'never') {
			let _comments: IComment[] = [];
			if (itemId && contentType && comments) {
				_comments = comments.filter(
					(c) => c.contentType === contentType && c.itemId === itemId && c.spaceId === activeSpace?.spaceId && !c.isDeleted
				);

				if (
					!activeSpace?.admins?.find((e) => e.id === profile?.id) &&
					!activeSpace?.moderators?.find((e) => e.id === profile?.id)
				) {
					_comments = _comments?.filter((e) => e.status === 'Public' || e.userId === profile?.userId);
				}
				_comments.sort((a, b) => {
					const aVal = a.updated_at ? moment(a.updated_at) : moment(a.updated_at);
					const bVal = b.updated_at ? moment(b.updated_at) : moment(b.updated_at);

					return aVal.isAfter(bVal) ? -1 : 1;
				});
			}
			setFoundComments(_comments);
		}
	}, [itemId, contentType, comments, activeSpace]);

	if (activeSpace?.allowFeedComments && activeSpace?.allowFeedComments !== 'never' && foundComments.length > 0) {
		return (
			<FlatList
				scrollEnabled={false}
				data={foundComments}
				renderItem={({ item, index }) => <Comment comment={item} index={index} />}
				style={{ marginBottom: -hsBottomMargin }}
			/>
		);
	}

	return null;
};
