import React from 'react';
import { Vibration, View, ScrollView } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { RoundButton } from 'components/Button';
import { NoData } from 'components/NoData';
import { EDefaultIconSet, IS_ANDROID, IS_WEB, openURL, swapArrayItems } from 'helper';
import { Text } from 'components/Text';
import { FormTextInput } from 'components/Form/FormTextInput';
import { HSDragCard } from 'components/Card/HSDragCard';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { EForumLinks, maxCategoryOptions } from 'config/constants';
import { FormColorPicker } from 'components/Form/FormColorPicker';
import { useTheme } from 'hooks/useTheme';

interface IContentTypeFieldsOptionsForm {
	testID: string;
	onChange: (field: string, value: any) => void;
	values: Record<string, any>;
	errors: Record<string, string>;
	hideCountFields?: boolean;
	isLoading?: boolean;
}

export const ContentTypeFieldsOptionsForm = (props: IContentTypeFieldsOptionsForm) => {
	const { testID, onChange, values, errors, hideCountFields, isLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		const list = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);

		onChange('options', list);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange('options', list);
	};

	const _checkForUniqueKey = (key?: string) => {
		const filtered = (values?.options?.map((o) => o.key) ?? []).filter((k) => k === key);
		return filtered.length > 1;
	};

	const _generateCategoryKey = (label: string, index) => {
		let newKey = label
			?.replace(/\s/g, '')
			.replace(/[^a-zA-Z0-9 ]/g, '')
			?.toLowerCase();

		while ((values?.options?.map((o) => o.key) ?? [])?.includes(newKey)) {
			newKey = newKey + v4().toString().replace(/-/g, '').substring(0, 2).toLowerCase();
		}
		const _options = values?.options ? [...values.options] : [];
		_options[index].key = newKey;
		onChange('options', _options);
	};

	const _renderOption = (params) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${testID}_option_${index}`}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
			>
				<View style={{ flexDirection: 'row' }}>
					<FormTextInput
						formStyle={{ flex: 1, justifyContent: 'space-between', marginRight: 3 }}
						testID={`${testID}_textinput_label_${index}`}
						label={t('Label')}
						hint={t('LabelHint')}
						isRequired
						value={item?.label}
						onChangeText={(value) => {
							const _options = values?.options ? [...values.options] : [];
							_options[index].label = value;
							onChange('options', _options);
						}}
						onEndEditing={(value) => {
							if (IS_WEB) {
								return;
							}
							if (!item.key) {
								_generateCategoryKey(value?.nativeEvent?.text, index);
							}
						}}
						onBlur={(value) => {
							if (!IS_WEB) {
								return;
							}
							if (!item.key) {
								_generateCategoryKey(value?.nativeEvent?.text, index);
							}
						}}
					/>
					<FormTextInput
						formStyle={{ flex: 1, justifyContent: 'space-between', marginLeft: 3 }}
						testID={`${testID}_textinput_key_${index}`}
						label={t('Key')}
						hint={t('KeyHint')}
						isRequired
						value={item?.key}
						error={_checkForUniqueKey(item?.key) ? t('duplicateKeyError') : ''}
						onChangeText={(value) => {
							const _options = values?.options ? [...values.options] : [];
							_options[index].key = value
								.trim()
								.replace(/[^a-zA-Z0-9 ]/g, '')
								.toLowerCase();
							onChange('options', _options);
						}}
						onEndEditing={(value) => {
							if (IS_WEB) {
								return;
							}
						}}
						onBlur={(value) => {
							if (!IS_WEB) {
								return;
							}
							_checkForUniqueKey();
						}}
					/>
				</View>
				<View style={{ flexDirection: 'row' }}>
					<FormColorPicker
						hideValues
						formStyle={{ flex: 1, marginBottom: 0 }}
						testID={`${testID}_colorpicker_backgroundColor_${index}`}
						onChange={(value) => {
							const _options = values?.options ? [...values.options] : [];
							_options[index].backgroundColor = value;
							onChange('options', _options);
						}}
						value={item?.backgroundColor}
						label={t('backgroundColor')}
						hint={t('ColorPickerHint')}
						description={t('CategoryOptionBackgroundColorDescription')}
					/>
					<FormColorPicker
						hideValues
						formStyle={{ flex: 1, marginBottom: 0 }}
						testID={`${testID}_colorpicker_textcolor_${index}`}
						onChange={(value) => {
							const _options = values?.options ? [...values.options] : [];
							_options[index].textColor = value;
							onChange('options', _options);
						}}
						value={item?.textColor}
						label={t('textColor')}
						hint={t('ColorPickerHint')}
						description={t('CategoryOptionTextColorDescription')}
					/>
				</View>
				<RoundButton
					isOutline
					size="sm"
					color={theme.danger}
					icon={EDefaultIconSet.Delete}
					testID={`comment_item_remove_${index}`}
					onPress={() => _removeOption(index)}
					isLoading={isLoading}
					alignSelf="flex-end"
				/>
			</HSDragCard>
		);
	};

	const _addOption = () => {
		let _options = values?.options ? [...values.options] : [];
		_options.unshift({
			key: '',
			label: ''
		});
		onChange('options', _options);
	};

	const _removeOption = (index) => {
		const _options = values?.options ? [...values.options] : [];
		_options?.splice(index, 1);
		onChange('options', _options);
	};

	const _renderCountFields = () => {
		if (hideCountFields) {
			return null;
		}

		return (
			<>
				<FormTextInput
					testID={`${testID}_textinput_mincount`}
					label={t('CategoryMinCount')}
					hint={t('CategoryMinCountHint')}
					value={values.minCount}
					onChangeText={(val) => onChange('minCount', val)}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormTextInput
					testID={`${testID}_textinput_maxcount`}
					label={t('CategoryMaxCount')}
					hint={t('CategoryMaxCountHint')}
					value={values.maxCount}
					onChangeText={(val) => onChange('maxCount', val)}
					keyboardType="number-pad"
					onlyNumbers
				/>
			</>
		);
	};

	return (
		<ScrollView style={{ flex: 1, marginHorizontal: -hsInnerPadding }} contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}>
			<DraggableFlatList
				data={values.options ?? []}
				renderItem={(params) => _renderOption(params)}
				keyExtractor={(item, index) => `${testID}_category_option_${index}`}
				ListHeaderComponent={() => {
					return (
						<View>
							<Text style={{ marginBottom: 10 }}>
								{t('AddMatchingPairIntro')}
								<Text onPress={() => openURL(EForumLinks.Matchings)} style={{ textDecorationLine: 'underline' }}>
									{t('here')}
								</Text>
								{'.'}
							</Text>
							{_renderCountFields()}
							<View style={{ marginBottom: hsBottomMargin, marginTop: -hsBottomMargin }}>
								<RoundButton
									testID={`${testID}_button_addoption`}
									size="sm"
									title={t('Add Option')}
									icon={EDefaultIconSet.Add}
									onPress={() => _addOption()}
									isDisabled={(values?.options?.length ?? 0) >= maxCategoryOptions}
									alignSelf="flex-end"
								/>
							</View>
						</View>
					);
				}}
				ListEmptyComponent={() => (
					<View style={{ alignSelf: 'center' }}>
						<NoData type="NoOptions" />
					</View>
				)}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => onChange('options', data)}
			/>
		</ScrollView>
	);
};
