import { IActiveScreenFilter, IExpo, IFeatureInfo } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { filterListByFeature, getCardContainerStyle, isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { FlashSectionList } from 'components/List';
import { ExpoListHeader } from './ExpoListHeader';
import { ExpoListItem, EXPOLISTITEMENTRYHEIGHT } from './ExpoListItem';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { HSCard } from 'components/Card/HSCard';
import { SponsorBlock } from 'screens/Space/Sponsors';
import { useMatching } from 'hooks/useMatching';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { ScrollEvent, Size } from 'react-native-spring-scrollview';
import { ContentCard, CONTENTCARD_HEIGHT } from 'components/Card';
import { useIsFocused } from '@react-navigation/native';

interface IExpoList {
	testID: string;
	selectedExpos?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: IExpo) => void;
	isLoading?: boolean;
	isSponsors?: boolean;
	isEditMode?: boolean;
	searchTerm: string;
	activeScreenFilter?: IActiveScreenFilter;
	activeModalFilters?: Record<string, string>;
	onScroll: (e: ScrollEvent) => void;
	onContentSizeChange: (e: Size) => void;
	feature?: IFeatureInfo;
	isMailReceiver?: boolean;
	noCard?: boolean;
}

interface IExpoData {
	header: string;
	items: IExpo[];
}

export const ExpoList = (props: IExpoList) => {
	const {
		testID,
		selectedExpos,
		onSelectAll,
		onSelect,
		onEdit,
		onDelete,
		onPress,
		isLoading,
		isSponsors,
		isEditMode,
		searchTerm,
		activeScreenFilter,
		activeModalFilters,
		onScroll,
		onContentSizeChange,
		feature,
		isMailReceiver,
		noCard
	} = props;
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getMatchCount } = useMatching();
	const { screenColumnCountSmallItems } = useQuery();

	const isScreenFocused = useIsFocused();

	const [spaceExpos, setSpaceExpos] = useState<IExpo[]>([]);
	const [filteredExpos, setFilteredExpos] = useState<IExpo[]>([]);
	const [searchedExpos, setSearchedExpos] = useState<IExpo[]>([]);
	const [expoSections, setExpoSections] = useState<(string | IExpo)[]>([]);
	const [numColumns, setNumColumns] = useState<number>(IS_WEB ? (screenColumnCountSmallItems ?? 3) + 1 : 2);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	useEffect(() => {
		let _numColumns = Math.floor(containerWidth / 220);

		if (_numColumns <= 2) _numColumns = 2;
		if (_numColumns >= 5) _numColumns = 5;
		if (numColumns !== _numColumns) setNumColumns(_numColumns);
	}, [containerWidth]);

	useEffect(() => {
		if (activeSpace && content.expos && isScreenFocused) {
			let _expos: typeof spaceExpos = [];

			if (isEditMode) {
				_expos = content.expos.filter(
					(e) => e.spaceId === activeSpace.spaceId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
			} else if (isSponsors) {
				_expos = content.expos.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						(e.sponsorType === 'sponsorOnly' || e.sponsorType === 'sponsor') &&
						(!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
			} else {
				_expos = content.expos.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						e.sponsorType !== 'sponsorOnly' &&
						(!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
			}

			setSpaceExpos(_expos);
		}
	}, [activeSpace, iAmSpaceAdmin, iAmSpaceModerator, content, isSponsors, isEditMode, isScreenFocused]);

	useEffect(() => {
		let _filtered: typeof filteredExpos = [...spaceExpos];
		_filtered = filterListByFeature(_filtered, feature);

		if (activeScreenFilter) {
			if (activeScreenFilter.filters.includes('all')) {
				// Nothing to do
			} else if (activeScreenFilter.filters.includes('favorites')) {
				_filtered = _filtered.filter((e) => profile?.favorites?.expo && profile.favorites.expo[e.id]);
			} else if (activeScreenFilter.filters.includes('matchings')) {
				_filtered = _filtered.filter((e) => getMatchCount('expo', e) > 0);
			} else {
				_filtered = _filtered.filter((e) => {
					if (activeScreenFilter.fieldName) {
						for (const catItemKey of activeScreenFilter.filters) {
							if (e[activeScreenFilter.fieldName]) {
								const itemSplit = e[activeScreenFilter.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
			}
		}

		if (activeModalFilters && Object.keys(activeModalFilters).length > 0) {
			_filtered = _filtered.filter((e) => {
				const catKeys = Object.keys(activeModalFilters);
				for (const catKey of catKeys) {
					const splitted = activeModalFilters[catKey].split(',');
					for (const catItemKey of splitted) {
						if (e[catKey]) {
							const itemSplit = e[catKey].split(',');
							if (itemSplit.includes(catItemKey)) {
								return true;
							}
						}
					}
				}

				return false;
			});
		}

		setFilteredExpos(_filtered);
	}, [activeSpace, feature, spaceExpos, activeModalFilters, activeScreenFilter, profile]);

	useEffect(() => {
		let _searched = [...filteredExpos];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter((item) => {
				let allCategoryString = '';
				for (let index = 1; index <= 10; index++) {
					const element = item[`category${index}`];
					allCategoryString = allCategoryString.concat(element);
				}

				return (
					item.title.toLowerCase().includes(lowerSearch) ||
					item.description?.toLowerCase().includes(lowerSearch) ||
					allCategoryString?.toLowerCase().includes(lowerSearch)
				);
			});
		}

		_searched.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));

		const pinned = _searched.filter((e) => e.isPinned);
		const unpinned = _searched.filter((e) => !e.isPinned);

		setSearchedExpos(pinned.concat(unpinned));
	}, [filteredExpos, searchTerm]);

	useEffect(() => {
		const sections: IExpoData[] = [];
		let newSections: typeof expoSections = [];

		let _searched = [...searchedExpos];

		_searched.forEach((item) => {
			let header;

			if (activeScreenFilter?.filters.includes('matchings')) {
				const matchCount = getMatchCount('expo', item);
				header = `${matchCount} ${matchCount === 1 ? 'Match' : 'Matchings'}`;
			} else {
				header = item.title.charAt(0).toUpperCase();
			}

			const found = sections.find((e) => e.header === header);
			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: header,
					items: [item]
				});
			}
		});

		if (activeScreenFilter?.filters.includes('matchings')) {
			sections.sort((a, b) => (Number(a.header.split(' ')[0]) > Number(b.header.split(' ')[0]) ? -1 : 1));
		} else {
			sections.sort((a, b) => (a.header.toLowerCase() < b.header.toLowerCase() ? -1 : 1));
		}

		sections.forEach((section) => {
			newSections = newSections.concat([section.header, ...section.items]);
		});
		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setExpoSections(newSections);
	}, [searchedExpos]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedExpos.length > 0) {
			if (IS_WEB ? activeSpace?.webLayout === 'list' : activeSpace?.appLayout === 'list') {
				return (
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 10, paddingHorizontal: IS_WEB ? hsInnerPadding : 0 }}
						value={selectedExpos && selectedExpos.length === searchedExpos.length}
						onPress={() => {
							if (selectedExpos && selectedExpos.length === searchedExpos.length) {
								onSelectAll([]);
							} else {
								onSelectAll(searchedExpos.map((ex) => ex.id));
							}
						}}
					/>
				);
			}

			return (
				<HSCard>
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedExpos && selectedExpos.length === searchedExpos.length}
						onPress={() => {
							if (selectedExpos && selectedExpos.length === searchedExpos.length) {
								onSelectAll([]);
							} else {
								onSelectAll(searchedExpos.map((ex) => ex.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderExpoList = () => {
		let _hideIndexButtons = activeScreenFilter?.filters?.includes('matchings') ?? false;
		let _numberOfColumns = 1;
		let _estimatedSize = EXPOLISTITEMENTRYHEIGHT;
		let _data = expoSections;
		const _viewType = isMailReceiver ? 'list' : IS_WEB ? activeSpace?.webLayout : activeSpace?.appLayout;

		switch (_viewType) {
			case 'cards':
				_hideIndexButtons = searchedExpos.length < 50;
				_numberOfColumns = numColumns;
				_estimatedSize = CONTENTCARD_HEIGHT;
				// This is done to prevent empty cells
				_data = searchedExpos;
				break;
			case 'list':
			default:
				// See default values above
				break;
		}

		return (
			<FlashSectionList
				data={_data}
				estimatedItemSize={_estimatedSize}
				extraData={[onSelect, selectedExpos]}
				numColumns={_numberOfColumns}
				showSectionButtonsOnCard={_viewType === 'cards'}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				sectionData={expoSections.filter((e) => typeof e === 'string') as string[]}
				renderItem={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return (
								<View
									style={getCardContainerStyle(_numberOfColumns, renderItem.index)}
									key={`${testID}_button_expo_${renderItem.index}`}
								>
									<ContentCard
										testID={testID}
										item={renderItem.item}
										contentType="expo"
										isLoading={isLoading}
										isSelected={selectedExpos?.includes(renderItem.item.id)}
										onPress={() => {
											if (onPress) {
												onPress(renderItem.item);
											}
										}}
										onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
										onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
										onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
										index={renderItem.index}
									/>
								</View>
							);
						case 'list':
						default:
							return (
								<ExpoListItem
									isLoading={isLoading}
									testID={`${testID}_button_expo_${renderItem.index}`}
									item={renderItem.item}
									onPress={() => {
										if (onPress) {
											onPress(renderItem.item);
										}
									}}
									isSelected={selectedExpos?.includes(renderItem.item.id)}
									onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
									onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
									onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
									hasSponsorFlag={
										isEditMode &&
										!isSponsors &&
										(renderItem.item.sponsorType === 'sponsor' || renderItem.item.sponsorType === 'sponsorOnly')
									}
									isMailReceiver={isMailReceiver}
								/>
							);
					}
				}}
				renderSectionHeader={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return null;
						case 'list':
						default:
							return <ExpoListHeader text={renderItem.item} />;
					}
				}}
				onScroll={onScroll}
				onContentSizeChange={(width, height) => {
					if (onContentSizeChange) {
						onContentSizeChange({ width, height });
					}
				}}
				hideIndexButtons={_hideIndexButtons}
			/>
		);
	};

	const _renderList = () => {
		if (
			expoSections.length === 0 &&
			(!waitingForSocketResponse?.expos || activeScreenFilter?.filters.includes('favorites') || !isEmptyString(searchTerm))
		) {
			if (!isEmptyString(searchTerm) || (activeModalFilters && Object.keys(activeModalFilters).length > 0)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			if (activeScreenFilter?.filters.includes('favorites')) {
				return <NoData type="NoFavorites" />;
			}

			return <NoData type={isSponsors ? 'NoSponsors' : 'NoExpos'} />;
		}

		if (noCard) {
			return (
				<View
					style={{
						flex: 1,
						marginBottom:
							activeSpace?.sponsorTypeExpo?.startsWith('sponsor') && !isSponsors
								? EHorizontalScreenPadding.Wide
								: hsBottomMargin
					}}
				>
					{_renderSelectAll()}
					{_renderExpoList()}
				</View>
			);
		}

		return (
			<HSCard
				style={{
					flex: 1,
					marginBottom:
						activeSpace?.sponsorTypeExpo?.startsWith('sponsor') && !isSponsors ? EHorizontalScreenPadding.Wide : hsBottomMargin,
					paddingLeft: IS_WEB ? 0 : hsInnerPadding,
					paddingRight: 0
				}}
			>
				{_renderSelectAll()}
				{_renderExpoList()}
			</HSCard>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderList()}
			<View style={{ width: '100%', alignSelf: 'center' }}>
				<SponsorBlock contentType={isSponsors ? 'sponsor' : 'expo'} />
			</View>
		</View>
	);
};
