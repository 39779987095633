import React, { useState } from 'react';
import { Dimensions, View } from 'react-native';
import { H1, Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { IS_WEB, normalizeFontSize } from 'helper';
import { VerticalBar } from './VerticalBar';
import { Icon } from 'components/Icon';
import { IWordListItem } from 'components/Vote/ActiveVote/VoteWordCloud/WordCloud';
import { VerticalBarStats } from './VerticalBarStats';
import { ChildButton } from 'components/Button';
interface IHorizontalBarChart {
	data: IWordListItem[];
	totalVotesSubmitted: number;
	distinctAnswersSubmitted?: number;
	highestCount: number;
	hideStats?: boolean;
	isIcons?: boolean;
	showStatsInBars?: boolean;
	largerDesign?: boolean;
	onPress?: (index: number) => any;
}

const MARGIN = 5;

export const HorizontalBarChart = (props: IHorizontalBarChart) => {
	const {
		data,
		highestCount,
		totalVotesSubmitted,
		distinctAnswersSubmitted,
		hideStats,
		isIcons,
		showStatsInBars,
		largerDesign,
		onPress
	} = props;
	const { t } = useTranslation();

	const [containerSize, setContainerSize] = useState<{ height: number; width: number }>({ height: 0, width: 0 });

	const _renderBarChildren = (item: IWordListItem, idx: number) => {
		if (showStatsInBars) {
			return <VerticalBarStats percentage={totalVotesSubmitted > 0 ? ((item.count / totalVotesSubmitted) * 100).toFixed(0) : 0} />;
		}
		return null;
	};

	const _renderItem = (item: IWordListItem, idx: number) => {
		return (
			<ChildButton
				key={`horizontalBarChart_verticalBar_${idx}`}
				testID={`horizontalBarChart_verticalBar_${idx}`}
				style={{
					marginTop: 20,
					width: `${100 / data.length}%`,
					height: '100%',
					justifyContent: 'flex-end',
					alignItems: 'center'
				}}
				onPress={() => {
					if (onPress) {
						onPress(idx);
					}
				}}
				touchSoundDisabled
				activeOpacity={1}
			>
				<VerticalBar
					width={`100%`}
					key={`barchart_bar_${item.text}`}
					height={highestCount ? (item.count / highestCount) * (containerSize.height - 30) : 1}
					paddingHorizontal={MARGIN}
					largerDesign={largerDesign}
					icon={showStatsInBars ? item.text : undefined}
				/>
				{_renderBarChildren(item, idx)}
			</ChildButton>
		);
	};

	const _renderLegendEntry = (item: IWordListItem) => {
		if (hideStats || showStatsInBars) {
			return null;
		}
		return (
			<View key={`barchart_legend_${item.text}`} style={[{ marginBottom: 10, flexDirection: isIcons ? 'row' : 'column' }]}>
				{isIcons ? (
					<Icon name={item.text} size={normalizeFontSize(16)} containerStyle={{ marginRight: 10 }} />
				) : (
					<Text bold style={{ fontSize: normalizeFontSize(16) }}>
						{item.text}
					</Text>
				)}
				<Text style={{ fontSize: normalizeFontSize(16) }}>
					{`${item.count} ${t('SubmittedVotes')} - ${((item.count / totalVotesSubmitted) * 100).toFixed(1)}%`}
				</Text>
			</View>
		);
	};

	const _renderHeader = () => {
		if (hideStats || showStatsInBars) {
			return null;
		}

		return <H1 center style={{ marginBottom: 30 }}>{`${t('SubmittedVotes')}: ${distinctAnswersSubmitted ?? totalVotesSubmitted}`}</H1>;
	};

	return (
		<View style={{ flex: 1, justifyContent: 'flex-end' }}>
			<View
				onLayout={(e) => setContainerSize(e.nativeEvent.layout)}
				style={{
					flexDirection: 'row',
					height:
						Dimensions.get('window').height *
						(containerSize.width <= 350 ? 0.35 : containerSize.width <= 500 ? 0.4 : containerSize.width <= 1000 ? 0.7 : 0.7),
					marginBottom: 20,
					flexGrow: IS_WEB ? 0 : 1
				}}
			>
				{data.map((item, idx) => _renderItem(item, idx))}
			</View>

			{_renderHeader()}
			<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
				{data.map((item) => _renderLegendEntry(item))}
			</View>
		</View>
	);
};
