import { ILauncherSpace } from 'config/interfaces';
import { ViewStyle } from 'react-native';

export const getFixedHeightForText = (fontSize: number, numberOfLines: number) => {
	return (fontSize + 4) * numberOfLines;
};

export const getCardContainerStyle = (numberOfColumns: number, index: number, padding?: number) => {
	const _padding = padding ?? 5;

	const style: ViewStyle = {
		flex: 1,
		paddingRight: numberOfColumns === 1 ? 0 : index % numberOfColumns === numberOfColumns - 1 ? 0 : _padding,
		paddingLeft: numberOfColumns === 1 ? 0 : index % numberOfColumns === 0 ? 0 : _padding
	};

	return style;
};

export const getDefaultAspectRatioStyle = (width: number, aspectRatio?: number) => {
	const _aspectRatio = aspectRatio ?? 16 / 9;

	const style = {
		width: width,
		height: width / _aspectRatio,
		aspectRatio: _aspectRatio
	};

	return style;
};

export const getTopRadiusStyle = (radius: number) => {
	const style = {
		borderTopLeftRadius: radius,
		borderTopRightRadius: radius
	};

	return style;
};

export const getDetailWidth = (viewType: string, defaultValue: number | string) => {
	switch (viewType) {
		case 'thirds_1/3_2/3':
		case 'thirds_2/3_1/3':
			return '100%';
		case 'stacked':
		default:
			return defaultValue;
	}
};

export const _getSectionWidth = (side: 'left' | 'right', type: 'expo' | 'speaker', space?: ILauncherSpace) => {
	if (space) {
		let viewType;
		switch (type) {
			case 'expo':
				viewType = space.expoDetailViewType ?? space.globalDetailViewType;
				break;

			case 'speaker':
				viewType = space.speakerDetailViewType ?? space.globalDetailViewType;
				break;

			default:
				break;
		}

		switch (viewType) {
			case 'thirds_1/3_2/3':
				switch (side) {
					case 'right':
						return '66%';
					case 'left':
					default:
						return '33%';
				}
			case 'thirds_2/3_1/3':
			default:
				switch (side) {
					case 'left':
						return '66%';
					case 'right':
					default:
						return '33%';
				}
		}
	}

	return '100%';
};
