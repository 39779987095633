import { HSCard } from 'components/Card';
import { FORMELEMENTBORDERWIDTH, FORMELEMENTBORDERRADIUS } from 'components/Form/constants';
import { H1, Hint } from 'components/Text';
import { IMedia, TMedia } from 'config/interfaces';
import { useTheme } from 'hooks/useTheme';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Image } from 'components/Image';
import { t } from 'i18next';
import { Text } from 'components/Text';
import { StreamPlayer } from 'components/Stream';
import { IS_WEB } from 'helper';
import WebView from 'react-native-webview';
interface IMailPreview {
	subject: string;
	body: string;
	attachments?: IMedia[];
}

const PREVIEWWIDTH = 60;

export const MailPreview = (props: IMailPreview) => {
	const { subject, body, attachments } = props;
	const { theme } = useTheme();

	const _getRatio = (file?: TMedia) => {
		if (
			(typeof file === 'string' && file.endsWith('.mp4')) ||
			(typeof file !== 'string' && typeof file !== 'number' && file?.type === 'video')
		) {
			return 16 / 9;
		}

		return 1;
	};

	const _renderNoValue = () => {
		return (
			<View
				style={{
					position: 'relative',
					width: '100%',
					height: '100%',
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderColor: theme.lightgray
				}}
			/>
		);
	};

	const _renderImagePreview = (file: IMedia) => {
		if (file) {
			let url: undefined | string | number = '';
			if (typeof file === 'string') {
				url = file;
			}
			return (
				<Image
					style={{ width: PREVIEWWIDTH, height: PREVIEWWIDTH * _getRatio(), borderRadius: FORMELEMENTBORDERRADIUS }}
					url={url}
					mediaObj={file}
					imageSize="medium"
				/>
			);
		}

		return null;
	};

	const _renderVideoPreview = (file: IMedia) => {
		if (file) {
			let url = '';

			if (typeof file === 'string') {
				url = file;
			} else if (typeof file !== 'number') {
				url = file.url;
			}
			return (
				<View
					style={{
						width: '100%'
					}}
				>
					<StreamPlayer
						type="local"
						url={url}
						controls={false}
						containerStyle={{ width: PREVIEWWIDTH, height: PREVIEWWIDTH * _getRatio() }}
						isAutoplay
					/>
				</View>
			);
		}
		return null;
	};

	const _renderFile = (file: IMedia) => {
		if (
			(typeof file === 'string' && file.endsWith('.mp4')) ||
			(typeof file !== 'string' && typeof file !== 'number' && file?.mime?.includes('video'))
		) {
			return _renderVideoPreview(file);
		}

		if (file) {
			return _renderImagePreview(file);
		}

		return _renderNoValue();
	};

	const _renderAttachments = () => {
		if (attachments && attachments?.length > 0) {
			return (
				<View style={{ marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderColor: theme.formgray }}>
					<H1>{t('Attachments')}</H1>

					{attachments.map((e, idx) => (
						<View key={`mailpreview_attachment_${idx}`} style={{ marginBottom: 10, flexDirection: 'row' }}>
							{_renderFile(e)}
							<View style={{ flex: 1, paddingLeft: 10 }}>
								<Text style={{ fontSize: 12, color: theme.formgray }}>{e.name}</Text>
							</View>
						</View>
					))}
				</View>
			);
		}

		return null;
	};

	return (
		<View style={{ flex: 1 }}>
			<HSCard>
				<Hint>{t('notReplacedPlaceholderInPreview')}</Hint>
			</HSCard>
			<HSCard style={{ flex: 1 }}>
				<H1>{subject}</H1>
				{IS_WEB ? <div dangerouslySetInnerHTML={{ __html: body }} /> : <WebView source={{ html: body }} />}

				{_renderAttachments()}
			</HSCard>
		</View>
	);
};
