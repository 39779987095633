import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import i18next from 'i18next';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { hsTopScreenPadding } from 'config/styleConstants';
import { getCreateScheduleInitialFormValues, ISchedule, IUpdateScheduleFormErrors, IUpdateScheduleFormValues } from 'config/interfaces';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { ScheduleForm } from 'components/Forms/Schedule';
import { LoadingModal } from 'components/Modal/LoadingModal';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ScheduleEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ScheduleEdit>;
type RouteParams = StackParamList[ERoutes.ScheduleEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'scheduleedit';

export const ScheduleEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();

	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('schedule');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IUpdateScheduleFormValues>({ ...getCreateScheduleInitialFormValues() });
	const [formErrors, setFormErrors] = useState<IUpdateScheduleFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateSchedule = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateSchedule);

	useEffect(() => {
		if (content.schedules && route.params.id) {
			const values = content.schedules.find((e) => e.id === route.params?.id);
			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSchedule(),
			isLoading: isLoading
		});
	}, [activeSpace, formValues, isLoading, isFormLocked]);

	const _applyFormValues = (values: ISchedule) => {
		setFormValues({
			...values,
			status: values.status?.id,
			speakers: values.speakers ? values.speakers.map((sp) => sp.id) : [],
			votes: values.votes ? values.votes.map((vo) => vo.id) : [],
			stage: values.stage?.id,
			stream: Array.isArray(values.stream)
				? values.stream?.map((s) => s.id) ?? []
				: typeof values.stream === 'number'
				? values.stream
				: values.stream?.id,
			expos: values.expos ? values.expos.map((ex) => ex.id) : []
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _save = async () => {
		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		if (values.speakerGroups) {
			values.speakerGroups.groups = values.speakerGroups.groups.filter((group) => group.speakerIds.length > 0);
		}

		setFormValues(values);

		setIsLoading(true);
		const res = await updateSchedule({ schedule: values });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	const _updateSchedule = async () => {
		if (!formValues.bookingCapacity && formValues.bookingCapacity !== 0) {
			formValues.bookingCapacity = null;
		}
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors, getContentTypeFields());
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = content.schedules.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		if (!formValues.stage) {
			showAlert({
				title: t('NoStageTitle'),
				message: t('NoStageSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Save Anyway'),

						onPress: () => _save()
					}
				]
			});
		} else {
			_save();
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="schedules">
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<ScheduleForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const ScheduleEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Schedule')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
