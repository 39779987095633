import React from 'react';
import { IStream } from 'config/interfaces';
import { ChildButton, RoundButton } from 'components/Button';
import { View } from 'react-native';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { EDefaultIconSet, getDefaultAspectRatioStyle, getIconForStreamType, getURLForStream, IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { IMediaObject } from 'components/Form';
import { Image } from 'components/Image';
import { hsBorderRadius } from 'config/styleConstants';

interface IStreamListItem {
	testID: string;
	item: IStream | IMediaObject;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
}

export const STREAMLISTITEMENTRYHEIGHT = 80;

export const StreamListItem = (props: IStreamListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, isLoading } = props;
	const { theme } = useTheme();
	const PREVIEWWIDTH = 90;

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isLoading}
						/>
					)}
				</View>
			);
		}

		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{ height: STREAMLISTITEMENTRYHEIGHT, flexDirection: 'row', alignItems: 'center', paddingRight: IS_WEB ? 10 : 0 }}
			isDisabled={!onPress}
			onPress={onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}
			<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
				{item.previewImage && (
					<View style={{ paddingVertical: 10 }}>
						<Image
							style={{ ...getDefaultAspectRatioStyle(PREVIEWWIDTH), borderRadius: hsBorderRadius }}
							mediaObj={item.previewImage}
							imageSize="thumbnail"
						/>
					</View>
				)}
				<View style={{ flex: 1, padding: 10 }}>
					<Text bold>{item.title}</Text>

					<View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
						<Icon name={getIconForStreamType(item.streamType) ?? EDefaultIconSet.Video} size={25} />
						<Text numberOfLines={2} style={{ marginHorizontal: 5, flexWrap: 'wrap' }}>
							{getURLForStream(item)}
						</Text>
					</View>
					{item.streamType === 'zoom' && <Text>{`ID: ${item.zoomMeetingId}`}</Text>}
				</View>
			</View>
			{_renderActions()}
		</ChildButton>
	);
};
