import React, { useEffect, useState } from 'react';
import {
	ICreateNewsFormErrors,
	ICreateNewsFormValues,
	IExpo,
	IMap,
	IMapPosition,
	IMediaItem,
	INetworkingRoom,
	ISchedule,
	ISpeaker,
	IStage,
	IVote,
	TNewsFeatureReference,
	TNewsReferenceType
} from 'config/interfaces';
import { FormFromUntilDatePicker, FormImageSelection, FormReferenceSelect, FormTextInput } from 'components/Form';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { HSCard } from 'components/Card';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor/FormMarkdownEditor';
import * as ImagePicker from 'expo-image-picker';
import { useSpace } from 'hooks/useSpace';
import { Dropdown } from 'components/Form/Dropdown';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { FormLabel } from 'components/Form/FormLabel';
import { FormHint } from 'components/Form/FormHint';
import { RoundButton } from 'components/Button';
import { useTheme } from 'hooks/useTheme';

interface ICreateNewsForm {
	values: ICreateNewsFormValues;
	errors: ICreateNewsFormErrors;
	onChange: (val: ICreateNewsFormValues) => void;
	isLoading: boolean;
	onError: (val: Record<string, string>) => void;
}

const TESTIDPREFIX = 'createnews';

const emptyItem: {
	expo: IExpo | null;
	externalUrl: string;
	feature: TNewsFeatureReference | null;
	map: IMap | null;
	mapposition: IMapPosition | null;
	mediaitem: IMediaItem | null;
	networkingroom: INetworkingRoom | null;
	schedule: ISchedule | null;
	speaker: ISpeaker | null;
	stage: IStage | null;
	vote: IVote | null;
} = {
	expo: null,
	externalUrl: '',
	feature: null,
	map: null,
	mapposition: null,
	mediaitem: null,
	networkingroom: null,
	schedule: null,
	speaker: null,
	stage: null,
	vote: null
};

export const CreateNewsForm = (props: ICreateNewsForm) => {
	const { values, errors, onChange, isLoading, onError } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const [selectedReference, setSelectedReference] = useState<any>(undefined);
	const [selectedMapPos, setSelectedMapPos] = useState<IMapPosition | undefined>(undefined);
	const [mapId, setMapId] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (
			!isEmptyString(values.referencetype) &&
			values.referencetype !== 'externalUrl' &&
			values.referencetype !== 'stages' &&
			activeSpace?.features?.list?.find(
				(e) => (e.key === values.referencetype && !e.isActive) || (e.key === values.feature?.key && !e.isActive)
			)
		) {
			setSelectedReference(undefined);
			onChange({ ...values, referencetype: undefined });
		}
	}, [activeSpace, values]);

	useEffect(() => {
		if (!selectedReference && !isEmptyString(values.referencetype)) {
			let _selectedRef;
			if (values.expo?.id && !values.expo.isDeleted) {
				_selectedRef = values.expo;
			}
			if (values.externalUrl && !isEmptyString(values.externalUrl)) {
				_selectedRef = values.externalUrl;
			}
			if (values.map?.id && !values.map.isDeleted) {
				_selectedRef = values.map;
				setMapId(values.map.id);
				if (values.mapposition?.id) {
					setSelectedMapPos(values.mapposition);
				}
			}
			if (values.mediaitem?.id && !values.mediaitem.isDeleted) {
				_selectedRef = values.mediaitem;
			}
			if (values.networkingroom?.id && !values.networkingroom.isDeleted) {
				_selectedRef = values.networkingroom;
			}
			if (values.schedule?.id && !values.schedule.isDeleted) {
				_selectedRef = values.schedule;
			}
			if (values.speaker?.id && !values.speaker.isDeleted) {
				_selectedRef = values.speaker;
			}
			if (values.stage?.id && !values.stage.isDeleted) {
				_selectedRef = values.stage;
			}
			if (values.vote?.id && !values.vote.isDeleted && values.vote.isActive) {
				_selectedRef = values.vote;
			}
			if (values.feature?.key) {
				if (activeSpace?.features?.list.find((e) => e.key === values.feature?.key && e.isActive)) {
					_selectedRef = values.feature;
				}
			}

			setSelectedReference(_selectedRef);
		}
	}, [values, selectedReference]);

	const _selectEntry = (id: any, isMap: boolean) => {
		const obj = {
			...values,
			...emptyItem
		};
		let _item;
		if (isMap) {
			let _mapPosition = content.mappositions.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
			_item = content.maps.find((e) => e.spaceId === activeSpace?.spaceId && e.id === (_mapPosition?.map?.id ?? values.map?.id));
			setSelectedMapPos(_mapPosition);
			obj['map'] = _item;
			obj['mapposition'] = _mapPosition ?? null;
		} else {
			switch (values.referencetype) {
				case 'expos':
					_item = content.expos.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['expo'] = _item;
					break;
				case 'features':
					_item = {
						key: activeSpace?.features?.list.find((e) => e.key === id)?.key,
						label: activeSpace?.features?.list.find((e) => e.key === id)?.label,
						type: activeSpace?.features?.list.find((e) => e.key === id)?.contentType
					};
					obj['feature'] = _item;
					break;
				case 'maps':
					_item = content.maps.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					setMapId(id);
					obj['map'] = _item;
					break;
				case 'mediaitems':
					_item = content.mediaitems.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['mediaitem'] = _item;
					break;
				case 'networkingrooms':
					_item = content.networkingrooms.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['networkingroom'] = _item;
					break;
				case 'schedules':
					_item = content.schedules.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['schedule'] = _item;
					break;
				case 'stages':
					_item = content.stages.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['stage'] = _item;
					break;
				case 'speakers':
					_item = content.speakers.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['speaker'] = _item;
					break;
				case 'votings':
					_item = votes.find((e) => e.spaceId === activeSpace?.spaceId && e.id === id);
					obj['vote'] = _item;
					break;
				default:
					break;
			}
		}

		setSelectedReference(_item);
		onChange(obj);
	};

	const _getOptions = () => {
		let _options = [
			{
				key: 'expos',
				label: t('Expo')
			},
			{
				key: 'externalUrl',
				label: t('External Url')
			},
			{
				key: 'maps',
				label: t('Maps')
			},
			{
				key: 'features',
				label: t('Features')
			},
			{
				key: 'mediaitems',
				label: t('Media Items')
			},
			{
				key: 'networkingrooms',
				label: t('networking')
			},
			{
				key: 'schedules',
				label: t('Schedules')
			},
			{
				key: 'speakers',
				label: t('Speakers')
			},
			{
				key: 'stages',
				label: t('Stage')
			},
			{
				key: 'votings',
				label: t('Votings')
			}
		];

		_options = _options.filter((e) => {
			let key = e.key;
			if (key === 'networkingrooms') {
				key = 'networking';
			}
			return (
				key === 'externalUrl' ||
				key === 'stages' ||
				(key === 'features' &&
					activeSpace?.features?.list.find(
						(e2) => (e2.contentType === 'iFrame' || e2.contentType === 'markdown') && e2.isActive
					)) ||
				activeSpace?.features?.list.find((e2) => key === e2.key && e2.isActive)
			);
		});

		_options = _options.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

		return _options;
	};

	const _getWarning = () => {
		let warning = '';

		switch (values.referencetype) {
			case 'schedules':
				if (values.schedule && values.schedule.hasNoDetails) {
					return `${t('InternalLinkingEntryHasNoDetails')} ${t('LinkingWillNotBeVisible')}`;
				}
				break;
			case 'stages':
				if (activeSpace?.features) {
					if (!activeSpace.features.list.find((e) => e.key === 'schedules' && e.isActive)) {
						return `${t('InternalLinkingFeatureIsNotActivated').replace('%FEATURE%', t('Schedules'))} ${t(
							'LinkingWillNotBeVisible'
						)}`;
					}
				}
				break;
			case 'votings':
				if (values.vote && !values.vote.isActive) {
					return `${t('VoteIsNotActive')}. ${t('LinkingWillNotBeVisible')}`;
				}
				break;
			default:
				break;
		}

		return warning;
	};

	return (
		<View>
			<HSCard>
				<FormTextInput
					isDisabled={isLoading}
					testID={`${TESTIDPREFIX}_textinput_title`}
					label={t('Title')}
					hint={t('titleHint')}
					isRequired
					value={values.title}
					error={errors.title}
					onChangeText={(value) => onChange({ ...values, title: value })}
				/>
				<FormTextInput
					isDisabled={isLoading}
					multiline
					testID={`${TESTIDPREFIX}_textinput_subtitle`}
					label={t('Subtitle')}
					hint={t('subtitleHint')}
					value={values.subtitle}
					error={errors.subtitle}
					onChangeText={(value) => onChange({ ...values, subtitle: value })}
				/>
				<FormMarkdownEditor
					formStyle={{ marginBottom: 10 }}
					testID={`${TESTIDPREFIX}_textinput_text`}
					label={t('Text')}
					hint={t('textHint')}
					value={values.text}
					error={errors.text}
					onChange={(value) => onChange({ ...values, text: value })}
					isDisabled={isLoading}
				/>
				<FormImageSelection
					label={`${t('Media')} (${t('Images')}/${t('Videos')})`}
					media={values.media?.map((m) => m.media)}
					onChange={(value) => {
						if (value)
							onChange({
								...values,
								media: value.map((v, index) => {
									return { media: v, order: index };
								})
							});
					}}
					mediaTypes={ImagePicker.MediaTypeOptions.All}
					testID={`${TESTIDPREFIX}_media_gallery`}
					isDisabled={isLoading}
				/>
				<FormFromUntilDatePicker
					isDisabled={isLoading}
					testIdPrefix={TESTIDPREFIX}
					from={values.showFrom}
					until={values.showUntil}
					fromError={errors.showFrom}
					untilError={errors.showUntil}
					fromLabel={t('showFromLabel')}
					untilLabel={t('showUntilLabel')}
					fromFieldName="showFrom"
					untilFieldName="showUntil"
					onChange={(field, value) => onChange({ ...values, [field]: value })}
					label={t('datePickerLabel')}
					hint={t('datePickerHintNews')}
					onError={(fieldName, error) => onError({ ...errors, [fieldName]: error })}
				/>
				<FormLabel testID={`${TESTIDPREFIX}_deeplinktype_label`} label={t('Link')} style={{ marginBottom: 5 }} />
				<FormHint testID={`${TESTIDPREFIX}_deeplinktype_hint`} hint={t('InternalLinkingEntryHint')} />
				<Dropdown
					testID={`${TESTIDPREFIX}_dropdown_deeplinktype`}
					value={values.referencetype}
					onSelect={(deeplinkType) => {
						onChange({ ...values, ...emptyItem, referencetype: deeplinkType as TNewsReferenceType });
						setSelectedReference(undefined);
					}}
					placeholder={t('DeeplinkSelectType')}
					options={_getOptions()}
					formStyle={{ marginBottom: 0, flex: 1 }}
				/>
				{!isEmptyString(values.referencetype) && (
					<View>
						{values.referencetype === 'externalUrl' ? (
							<FormTextInput
								isDisabled={isLoading}
								testID={`${TESTIDPREFIX}_textinput_externalUrl`}
								label={t('External Url')}
								hint={t('externalUrlHint')}
								value={values.externalUrl}
								error={errors.externalUrl}
								onChangeText={(value) => onChange({ ...values, ...emptyItem, externalUrl: value })}
								keyboardType="url"
								autoCapitalize="none"
							/>
						) : (
							<View>
								<FormHint testID={`${TESTIDPREFIX}_reference_hint`} hint={t('InternalLinkingReferenceHint')} />
								<FormReferenceSelect
									testID={`${TESTIDPREFIX}_reference`}
									onSelect={(itemId) => _selectEntry(itemId, false)}
									value={values.referencetype === 'features' ? selectedReference?.key : selectedReference?.id}
									error={!isEmptyString(_getWarning()) ? _getWarning() : errors.selectedReference}
									type={values.referencetype}
									fieldName={`${TESTIDPREFIX}_reference`}
									formStyle={{ marginBottom: 0, flex: 1 }}
									placeholder={t('Please select an entry')}
									isRequired={!isEmptyString(values.referencetype)}
								/>
								{values.referencetype === 'maps' && mapId && (
									<View>
										<FormHint testID={`${TESTIDPREFIX}_reference_entry_hint`} hint={t('InternalLinkingMapPosHint')} />
										<FormReferenceSelect
											testID={`${TESTIDPREFIX}_reference_mapposition`}
											onSelect={(itemId) => _selectEntry(itemId, true)}
											value={selectedMapPos ? selectedMapPos.id : -1}
											type={'mappositions'}
											fieldName={`${TESTIDPREFIX}_reference_mapposition`}
											mapId={mapId}
										/>
									</View>
								)}
								{selectedReference && (
									<RoundButton
										testID={`${TESTIDPREFIX}_reference_button_delete`}
										title={t('Reset')}
										alignSelf={'flex-end'}
										icon={EDefaultIconSet.Delete}
										color={theme.danger}
										size="sm"
										onPress={() => {
											onChange({ ...values, ...emptyItem });
											setSelectedReference(undefined);
										}}
										isOutline
									/>
								)}
							</View>
						)}
					</View>
				)}
			</HSCard>
		</View>
	);
};
