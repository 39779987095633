import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';

import { ChildButton } from 'components/Button/ChildButton';
import { HSModal } from '../Modal';
import { Image } from 'components/Image/Image';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBorderRadius } from 'config/styleConstants';

//backgroundImages
const spaceDefaultBlue = require('../../../assets/images/spaceDefault/spaceDefaultBlue.png');
const spaceDefaultRed = require('../../../assets/images/spaceDefault/spaceDefaultRed.png');
const spaceDefaultGreen = require('../../../assets/images/spaceDefault/spaceDefaultGreen.png');
const spaceDefaultPurple = require('../../../assets/images/spaceDefault/spaceDefaultPurple.png');

//previewImages
const gradientBlueLightblue = require('../../../assets/images/gradient/gradientBlueLightblue.png');
const gradientLightblueOrange = require('../../../assets/images/gradient/gradientLightblueOrange.png');
const gradientMagentaBlue = require('../../../assets/images/gradient/gradientMagentaBlue.png');
const gradientOrangeMagenta = require('../../../assets/images/gradient/gradientOrangeMagenta.png');

//logoImages
const defaultLogo = require('../../../assets/images/defaultLogo/defaultLogo.png');
const defaultLogoGray = require('../../../assets/images/defaultLogo/defaultLogoGray.png');

const TESTIDPREFIX = 'selectpreviewimagesmodal';
interface ISelectPreviewImagesModal {
	isVisible: boolean;
	imageRatio: number;
	previewImagesFor?: 'previewImage' | 'backgroundImage' | 'logoImage';
	onClose: () => void;
	onSelect: (val) => void;
	onRemove: () => void;
}

interface IPreviewMedia {
	name: string;
	uri: number;
}

export const SelectPreviewImagesModal = (props: ISelectPreviewImagesModal) => {
	const { isVisible, previewImagesFor, onClose, onSelect, onRemove, imageRatio } = props;

	const [images, setImages] = useState<IPreviewMedia[]>([]);

	useEffect(() => {
		let _images: IPreviewMedia[] = [];

		switch (previewImagesFor) {
			case 'backgroundImage':
				_images = [
					{ name: 'gradientBlueLightblue', uri: gradientBlueLightblue },
					{ name: 'gradientLightblueOrange', uri: gradientLightblueOrange },
					{ name: 'gradientMagentaBlue', uri: gradientMagentaBlue },
					{ name: 'gradientOrangeMagenta', uri: gradientOrangeMagenta }
				];
				break;
			case 'logoImage':
				_images = [
					{ name: 'defaultLogo', uri: defaultLogo },
					{ name: 'defaultLogoGray', uri: defaultLogoGray }
				];
				break;
			case 'previewImage':
				_images = [
					{ name: 'spaceDefaultBlue', uri: spaceDefaultBlue },
					{ name: 'spaceDefaultRed', uri: spaceDefaultRed },
					{ name: 'spaceDefaultGreen', uri: spaceDefaultGreen },
					{ name: 'spaceDefaultPurple', uri: spaceDefaultPurple }
				];
				break;

			default:
				break;
		}
		setImages(_images);
	}, [previewImagesFor]);

	const _getAspectRatio = () => {
		switch (previewImagesFor) {
			case 'previewImage':
				return 16 / 9;
			case 'backgroundImage':
				return 9 / 16;
			case 'logoImage':
			default:
				return 1;
		}
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			<FlatList
				style={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
				columnWrapperStyle={{ justifyContent: 'center' }}
				numColumns={2}
				data={images}
				keyExtractor={(item, index) => `${TESTIDPREFIX}_image_${index}`}
				renderItem={({ item, index }) => (
					<ChildButton
						testID={`${TESTIDPREFIX}_image_${index}`}
						onPress={() => {
							onSelect(item.name);
						}}
						style={{ padding: 5, width: '50%' }}
					>
						<Image url={item.uri} style={{ width: '100%', aspectRatio: _getAspectRatio(), borderRadius: hsBorderRadius }} />
					</ChildButton>
				)}
			/>
		</HSModal>
	);
};
