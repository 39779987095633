import { HSCard } from 'components/Card';
import { Icon } from 'components/Icon';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H1, H3 } from 'components/Text';
import { hsBottomMargin } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

interface ISpaceWizardDone {
	isLoading: boolean;
	isJoinSpaceLoading: boolean;
	spaceTitle: string;
	spaceId: string;
	isCloneData?: boolean;
}

export const SpaceWizardDone = (props: ISpaceWizardDone) => {
	const { isLoading, isJoinSpaceLoading, spaceTitle, spaceId, isCloneData } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();

	const _renderLoadingSpace = () => {
		return (
			<View
				style={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsBottomMargin,
					justifyContent: 'center',
					alignItems: 'center',
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<H1>{t('pleaseWaitForSpace')}</H1>
					{isCloneData && <H3>{t('cloningDataTakesSomeTime')}</H3>}
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={80} />
					</View>
				</HSCard>
			</View>
		);
	};

	const _renderSpaceReady = () => {
		return (
			<ScrollView
				style={{
					width: screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide
				}}
				contentContainerStyle={{
					justifyContent: 'center',
					alignItems: 'center',
					paddingVertical: hsBottomMargin
				}}
			>
				<HSCard style={{ marginBottom: 0 }}>
					<H1 center>{t('yourSpaceIsReady').replace('%TITLE%', spaceTitle).replace('%ID%', spaceId)}</H1>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<Icon name={EDefaultIconSet.Save} size={80} color={theme.success} />
					</View>
				</HSCard>
			</ScrollView>
		);
	};

	const _renderJoiningSpace = () => {
		return (
			<ScrollView
				style={{
					width: screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide
				}}
				contentContainerStyle={{
					justifyContent: 'center',
					alignItems: 'center',
					paddingVertical: hsBottomMargin
				}}
			>
				<HSCard style={{ marginBottom: 0 }}>
					<H1 center>{t('joiningYourSpaceNow').replace('%TITLE%', spaceTitle).replace('%ID%', spaceId)}</H1>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={80} />
					</View>
				</HSCard>
			</ScrollView>
		);
	};

	if (isJoinSpaceLoading) {
		return _renderJoiningSpace();
	}
	if (isLoading) {
		return _renderLoadingSpace();
	}
	return _renderSpaceReady();
};
