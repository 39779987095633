import { IVote } from 'config/interfaces';

export const previewVotingsQuiz: IVote[] = [
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage1',
		votingType: 'optionVote',
		id: 99999999999999999991,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		timeOut: 15,
		score: 100,
		options: [
			{
				id: 999991,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999992,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999993,
				description: 'correctAnswer',
				isCorrect: true
			},
			{
				id: 999994,
				description: 'wrongAnswer',
				isCorrect: false
			}
		]
	},
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage2',
		votingType: 'optionVote',
		id: 99999999999999999992,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		timeOut: 15,
		score: 100,
		options: [
			{
				id: 999991,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999992,
				description: 'correctAnswer',
				isCorrect: true
			},
			{
				id: 999993,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999994,
				description: 'wrongAnswer',
				isCorrect: false
			}
		]
	},
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage3',
		votingType: 'optionVote',
		id: 99999999999999999993,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		timeOut: 15,
		score: 100,
		options: [
			{
				id: 999991,
				description: 'correctAnswer',
				isCorrect: true
			},
			{
				id: 999992,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999993,
				description: 'wrongAnswer',
				isCorrect: false
			},
			{
				id: 999994,
				description: 'wrongAnswer',
				isCorrect: false
			}
		]
	}
];
