import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { NavigationHeaderTextButton } from './NavigationHeaderTextButton';

interface INavigationHeaderPreviewVoteButtonComponent {
	onPress?: Function;
	goBack?: boolean;
	options?: any;
}

export const NavigationHeaderPreviewVoteButton = (props: INavigationHeaderPreviewVoteButtonComponent) => {
	const { onPress, goBack, options } = props;
	const navigation = useNavigation();
	const { t } = useTranslation();

	switch (options?.votingType) {
		case 'survey':
		case 'externalUrl':
			return null;

		default:
			return (
				<NavigationHeaderTextButton
					testID="header_button_previewvote"
					onPress={() => {
						if (onPress) {
							onPress();
						}
						if (goBack) {
							navigation.goBack();
						}
					}}
					text={t('Preview')}
				/>
			);
	}
};
