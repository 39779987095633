import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';
import * as ImagePicker from 'expo-image-picker';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, getCardContainerStyle, IS_WEB } from 'helper';
import { IMedia } from 'config/interfaces';
import { H3 } from 'components/Text';
import { SearchBar } from 'components/SearchBar';
import { SpaceMediaPickerModalPreviewImage } from './SpaceMediaPickerModalPreviewImage';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { FlashList } from 'components/List';

interface ISpaceMediaPickerModal {
	onClose: () => void;
	onSelect: (media: IMedia) => void;
	isVisible: boolean;
	mediaTypes?: ImagePicker.MediaTypeOptions;
}

const TESTIDPREFIX = 'spacemediapickermodal';

export const SpaceMediaPickerModal = (props: ISpaceMediaPickerModal) => {
	const { onClose, onSelect, isVisible, mediaTypes } = props;
	const { t } = useTranslation();
	const { isTabletOrMobile, isTablet } = useQuery();
	const { activeSpace } = useSpace();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [filteredMedia, setFilteredMedia] = useState<IMedia[]>([]);
	const [searchedMedia, setSearchedMedia] = useState<IMedia[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const spaceMedia = useSelector((store: IRootState) => store.upload.spaceMedia);

	const getSpaceMedia = useRematchDispatch((dispatch: Dispatch) => dispatch.upload.getSpaceMedia);

	useEffect(() => {
		if (isVisible) {
			_loadMedia();
		}
	}, [isVisible]);

	useEffect(() => {
		let _filtered: typeof filteredMedia = [];

		if (activeSpace && spaceMedia) {
			switch (mediaTypes) {
				case ImagePicker.MediaTypeOptions.Images:
					_filtered = spaceMedia.filter((e) => e.mime?.includes('image') && e.spaceId === activeSpace.spaceId);
					break;
				case ImagePicker.MediaTypeOptions.Videos:
					_filtered = spaceMedia.filter((e) => e.mime?.includes('video') && e.spaceId === activeSpace.spaceId);
					break;
				case ImagePicker.MediaTypeOptions.All:
				default:
					_filtered = spaceMedia.filter((e) => e.spaceId === activeSpace.spaceId);
					break;
			}

			_filtered = _filtered.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setFilteredMedia(_filtered);
	}, [activeSpace, spaceMedia]);

	useEffect(() => {
		let _searched: typeof searchedMedia = [...filteredMedia];

		if (searchTerm.length > 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = _searched.filter(
				(media) =>
					media.alternativeText?.toLowerCase().includes(lowerSearch) ||
					media.caption?.toLowerCase().includes(lowerSearch) ||
					media.name?.toLowerCase().includes(lowerSearch)
			);
		}

		setSearchedMedia(_searched);
	}, [filteredMedia, searchTerm]);

	const _loadMedia = async () => {
		setIsLoading(true);
		await getSpaceMedia({});
		setIsLoading(false);
	};

	const _getNumberOfColumns = () => {
		return IS_WEB && !isTabletOrMobile ? 4 : isTablet || Dimensions.get('window').width >= 720 ? 3 : 2;
	};

	const _renderPreview = (item: IMedia, idx: number) => {
		if (item.mime?.includes('video')) {
		}

		if (item.mime?.includes('image')) {
			const numberOfColumns = _getNumberOfColumns();

			return (
				<View
					style={{
						...getCardContainerStyle(numberOfColumns, idx),
						width: `${100 / numberOfColumns}%`,
						marginBottom: hsBottomMargin / 2
					}}
				>
					<SpaceMediaPickerModalPreviewImage item={item} onPress={() => onSelect(item)} />
				</View>
			);
		}

		return null;
	};

	return (
		<HSModal isVisible={isVisible} onClose={() => onClose()} title={t('Media Library')}>
			<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
				<H3 noBottomMargin>{`${searchedMedia.length} ${t('Entries')}`}</H3>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_reload`}
					icon={EDefaultIconSet.Reload}
					onPress={() => _loadMedia()}
					isLoading={isLoading}
				/>
			</View>
			<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
			<FlashList
				data={searchedMedia}
				numColumns={_getNumberOfColumns()}
				contentContainerStyle={{ paddingTop: hsTopScreenPadding }}
				renderItem={({ item, index }) => _renderPreview(item, index)}
				estimatedItemSize={150}
				keyExtractor={(item) => `${TESTIDPREFIX}_preview_${item.id}`}
				style={{ marginBottom: IS_WEB ? 0 : hsBottomMargin }}
			/>
		</HSModal>
	);
};
