import React from 'react';
import { ChildButton } from 'components/Button/ChildButton';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';
import { Spinner } from 'components/Spinner';
import { EDefaultIconSet } from 'helper/icon';
import { isEmptyString } from 'helper/string';

interface IDrawerButton {
	testID: string;
	icon?: string;
	title?: string;
	onPress?: () => void;
	onLongPress?: () => void;
	isActive?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
	textColor?: string;
	iconColor?: string;
	leftBadge?: number;
	badge?: number;
	boldBadge?: boolean;
	size?: 'xs' | 'sm';
	alignSelf?: ViewStyle['alignSelf'];
	largerDesign?: boolean;
	isExternalButton?: boolean;
}

const MAGIC_NUMBER = 1.6;

export const DrawerButton = (props: IDrawerButton) => {
	const {
		testID,
		icon,
		title,
		onPress,
		onLongPress,
		isActive,
		isLoading,
		isDisabled,
		textColor,
		iconColor,
		leftBadge,
		badge,
		boldBadge,
		size,
		alignSelf,
		largerDesign,
		isExternalButton
	} = props;

	const { theme } = useTheme();

	let ICONSIZE = 25;

	if (testID.endsWith('button_validate')) ICONSIZE = 20;

	switch (size) {
		case 'xs':
			ICONSIZE = 15;
			break;
		case 'sm':
			ICONSIZE = 20;
			break;
		default:
			break;
	}

	if (largerDesign) {
		ICONSIZE = ICONSIZE * 1.5;
	}

	const _renderIconOrText = () => {
		if (icon) {
			return <Icon testID={`${testID}_${icon}`} name={icon} size={ICONSIZE} color={_getIconAndSpinnerColor()} />;
		}

		return null;
	};

	const _renderIconOrLoadingOrText = () => {
		if (icon) {
			if (isLoading) {
				return <Spinner size={ICONSIZE} color={_getIconAndSpinnerColor()} />;
			}
			return (
				<View>
					{_renderIconOrText()}
					{!badge && isExternalButton && (
						<Icon
							testID={`${testID}_${icon}_external`}
							name={EDefaultIconSet.ArrowUpRight}
							size={ICONSIZE * 0.5}
							containerStyle={{ position: 'absolute', top: -4, right: -10 }}
							color={theme.danger}
						/>
					)}
				</View>
			);
		}
		return null;
	};

	const _renderLeftBadge = () => {
		if (leftBadge && leftBadge > 0) {
			return (
				<View
					style={{
						borderRadius: 20,
						backgroundColor: theme.warning,
						position: 'absolute',
						top: -3,
						left: -4,
						paddingHorizontal: 6,
						paddingVertical: 2,
						alignItems: 'center'
					}}
				>
					<Text
						adjustsFontSizeToFit
						numberOfLines={1}
						bold={boldBadge}
						style={{
							fontSize: largerDesign ? 18 : 12,
							color: theme.white
						}}
					>
						{leftBadge}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderBadge = () => {
		if (badge && badge > 0) {
			return (
				<View
					style={{
						borderRadius: 20,
						backgroundColor: theme.danger,
						position: 'absolute',
						top: -3,
						right: -4,
						paddingHorizontal: 6,
						paddingVertical: 2,
						alignItems: 'center'
					}}
				>
					<Text
						testID={`${testID}_badge`}
						adjustsFontSizeToFit
						numberOfLines={1}
						bold={boldBadge}
						style={{
							fontSize: largerDesign ? 18 : 12,
							color: theme.white
						}}
					>
						{badge}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _getIconAndSpinnerColor = () => {
		if (isActive) {
			return theme.primaryContrast;
		}

		return iconColor ?? theme.primaryContrast;
	};

	const _getTextColor = () => {
		if (isDisabled) {
			return theme.formgray;
		}

		if (isActive) {
			return theme.primaryContrast;
		}

		return textColor ?? theme.text;
	};

	return (
		<ChildButton
			testID={testID}
			onPress={() => {
				if (onPress && !isLoading) {
					onPress();
				}
			}}
			onLongPress={() => {
				if (onLongPress && !isLoading) {
					onLongPress();
				}
			}}
			isDisabled={isDisabled}
			activeOpacity={!onPress && !onLongPress ? 1 : undefined}
			style={{
				alignSelf: alignSelf ?? 'flex-start',
				flexDirection: 'row',
				alignItems: 'center',
				paddingLeft: 5,
				paddingRight: 10,
				marginBottom: 5,
				width: '100%',
				backgroundColor: isActive ? theme.primary : undefined,
				borderRadius: 20
			}}
		>
			<View
				style={{
					position: 'relative',
					justifyContent: 'center',
					alignItems: 'center',
					height: ICONSIZE * MAGIC_NUMBER,
					width: isEmptyString(icon) && !isEmptyString(title) ? 'auto' : ICONSIZE * MAGIC_NUMBER,
					backgroundColor: 'transparent'
				}}
			>
				{_renderIconOrLoadingOrText()}
				{_renderLeftBadge()}
				{_renderBadge()}
			</View>

			{title && !isEmptyString(title) && (
				<Text
					bold={isActive}
					numberOfLines={1}
					adjustsFontSizeToFit
					style={{
						color: _getTextColor()
					}}
				>
					{title}
				</Text>
			)}
		</ChildButton>
	);
};
