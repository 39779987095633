import React, { useEffect } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderPlaceholder, NavigationHeaderTitle } from 'components/Navigation/Header';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Tutorial } from 'components/Tutorial';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.Onboarding>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.Onboarding>;
type RouteParams = NoSpaceStackParamList[ERoutes.Onboarding];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const OnboardingScreen = ({ route, navigation }: Props) => {
	const hasAcceptedPrivacyPolicy = useSelector((store: IRootState) => store.auth.hasAcceptedPrivacyPolicy);

	useEffect(() => {
		if (hasAcceptedPrivacyPolicy) {
			setTimeout(() => {
				navigation.replace(ERoutes.SpaceSelect, {});
			});
		}
	}, [hasAcceptedPrivacyPolicy]);

	return (
		<ScreenContainer>
			<Tutorial isOnBoarding />
		</ScreenContainer>
	);
};

export const OnboardingScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;

	return (
		<NavigationHeader>
			<NavigationHeaderPlaceholder />
			<NavigationHeaderTitle title="" />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
