import React from 'react';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { NavigationHeaderImageButton } from './NavigationHeaderImageButton';
import { EDefaultIconSet } from 'helper/icon';
import { IS_WEB } from 'helper/platform';
import { NavigationHeaderPlaceholder } from './NavigationHeaderPlaceholder';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';

export const NavigationHeaderMenuButton = () => {
	const navigation = useNavigation();
	const { isTabletOrMobile } = useQuery();
	const { theme } = useTheme();

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const jwt = useSelector((store: IRootState) => store.auth.jwt);

	if (IS_WEB && !isTabletOrMobile) {
		return <NavigationHeaderPlaceholder />;
	}

	return (
		<NavigationHeaderImageButton
			testID="header_button_openmenu"
			onPress={() => {
				if (!IS_WEB || isTabletOrMobile) {
					navigation.dispatch(DrawerActions.toggleDrawer());
				}
			}}
			url={jwt && profile?.avatar?.formats?.thumbnail?.url ? profile?.avatar?.formats?.thumbnail?.url : undefined}
			displayAsAvatar={jwt && (profile?.avatar?.formats?.thumbnail?.url || profile?.email) ? true : false}
			fallbackIcon={EDefaultIconSet.User}
			fallbackIconColor={theme.primary}
		/>
	);
};
