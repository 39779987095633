import React from 'react';
import { View } from 'react-native';

import { ICreateSpeakerFormErrors, ICreateSpeakerFormValues, IUpdateSpeakerFormErrors, IUpdateSpeakerFormValues } from 'config/interfaces';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { HSCard } from 'components/Card/HSCard';

interface ISpeakerForm {
	values: ICreateSpeakerFormValues | IUpdateSpeakerFormValues;
	errors: ICreateSpeakerFormErrors | IUpdateSpeakerFormErrors;
	onChange: (val: ICreateSpeakerFormValues | IUpdateSpeakerFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'speakerform';

export const SpeakerForm = (props: ISpeakerForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('speaker');
	const { renderFormField } = useForm();

	return (
		<View testID="speakeredit_card_scrollview">
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) => renderFormField('speaker', TESTIDPREFIX, values, errors, field, onChange))}
				</HSCard>
			))}
		</View>
	);
};
