import React from 'react';
import { FormReferenceSelect } from 'components/Form/FormReferenceSelect';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

interface IStreamInputMediaLibrary {
	testID: string;
	onChange: (value: number) => void;
	value?: number;
}

export const StreamInputMediaLibrary = (props: IStreamInputMediaLibrary) => {
	const { testID, onChange, value } = props;
	const { t } = useTranslation();

	return (
		<View>
			<FormReferenceSelect
				testID={`${testID}_referenceselect_mediaitem`}
				onSelect={(id) => onChange(id)}
				label={t('Media Items')}
				isRequired
				type="mediaitems"
				value={value}
				placeholder={t('PlaceholderMediaItem')}
			/>
		</View>
	);
};
