import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ICreateTicketPayload, IHSPXTicket, IUpdateTicketPayload } from 'config/interfaces/ticket';
import { translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import {
	ICheckTicketcodePayload,
	ITestEmendoSettingsPayload,
	ITestPretixSettingsPayload,
	ITestXINGEventsSettingsPayload,
	IUpdateTicketcodePayload
} from 'rematch/interfaces';
import { RootModel } from './index';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	tickets: IHSPXTicket[];
	lastTicketRead: Record<string, string>;
};

export const ticket = createModel<RootModel>()({
	state: {
		tickets: [],
		lastTicketRead: {}
	} as IDefaultState,
	reducers: {
		addTickets(state, newTickets: IDefaultState['tickets']) {
			rematchLog('addTickets');
			const _tickets = state.tickets ? [...state.tickets] : [];
			let lastRead = state.lastTicketRead ? { ...state.lastTicketRead } : {};

			newTickets.forEach((ticket) => {
				const idx = _tickets.findIndex((e) => e.id === ticket.id);
				if (idx !== -1) {
					if (ticket.isDeleted) {
						_tickets.splice(idx, 1);
					} else {
						_tickets[idx] = ticket;
					}
				} else if (!ticket.isDeleted) {
					_tickets.push(ticket);
				}

				if (!lastRead[ticket.spaceId] || ticket.updated_at > lastRead[ticket.spaceId]) {
					lastRead[ticket.spaceId] = ticket.updated_at;
				}
			});

			return {
				...state,
				tickets: _tickets,
				lastTicketRead: lastRead
			};
		},
		clear(state) {
			rematchLog('clear ticket');
			return {
				tickets: [],
				lastTicketRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async fetchTicket(payload: ICheckTicketcodePayload, store) {
			try {
				const { space, ticketcode, redeem, noToast } = payload;

				const body = new FormData();

				body.append(
					'data',
					JSON.stringify({
						ticketcode,
						spaceId: space.spaceId,
						userInfos: store.auth.userInfos
					})
				);
				const res = await dispatch.request.anonymousRequest({
					method: 'POST',
					url: `${MULTISPACEURL}/tickets/fetchticket`,
					body
				});

				if (res?.ticketcode) {
					return res;
				}

				const error = translateStrapiError(res?.message ? res : res.error);
				let _error;

				if (error === i18next.t('auth.form.error.ticketcode.alreadyinuse')) {
					_error = 'ticketInUse';
				}
				if (error === i18next.t('auth.form.error.ticketcode.existsforuser')) {
					_error = 'ticketExistsForMailButNoTicketcode';
				}
				if (error === i18next.t('auth.form.error.ticketcode.noticketforuser')) {
					_error = 'noTicketForUser';
				}
				if (!noToast) {
					showToast('error', 'Error', error);
				}

				return {
					error: _error,
					email: res?.email
				};
			} catch (error) {
				console.log('fetchTicket', error);
				return undefined;
			}
		},
		async loadTickets(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && activeSpace?.ticketSettings?.ticketProvider === 'hellospaces') {
					if (store.temp.waitingForSocketResponse?.tickets) {
						contentLoadLog(3, 'tickets load already in progress. skipping');
						return;
					}

					const _lastRead =
						store.ticket.lastTicketRead && store.ticket.lastTicketRead[activeSpace.spaceId]
							? store.ticket.lastTicketRead[activeSpace.spaceId]
							: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									userInfos: store.auth.userInfos,
									spaceId: dispatch.temp.getActiveSpaceId({}),
									lastRead: _lastRead
								})
							);

							dispatch.temp.setWaitingForSocketResponse({ key: 'tickets', value: true });

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/tickets`,
								method: 'POST',
								body
							});

							dispatch.temp.setWaitingForSocketResponse({ key: 'tickets', value: false });

							if (res && Array.isArray(res) && res.length > 0) {
								dispatch.ticket.addTickets(res);
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadTickets', error);
			}
		},
		async createTicket(payload: ICreateTicketPayload, store) {
			try {
				const { space, ticket, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && ticket[field.fieldName]) {
							tempMedia[field.fieldName] = ticket[field.fieldName];
							delete ticket[field.fieldName];
						}
					});

					newBody.append(
						'data',
						JSON.stringify({
							ticket: { ...ticket, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/tickets/create`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateTicket = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (typeof tempMedia[key] === 'string' || tempMedia[key].url?.startsWith('file://')) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateTicket[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateTicket[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									speaker: updateTicket,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/tickets/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.ticket.addTickets(res2 ? [res2] : [res]);

						if (!noToast) {
							showToast('success', i18next.t('Ticket Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', 'Error', translateStrapiError(res));
					}
					return false;
				}
			} catch (error) {
				console.log('createTicket', error);
			}
		},
		async updateTicket(payload: IUpdateTicketPayload, store) {
			try {
				const { ticket, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && ticket[field.fieldName]) {
							tempMedia[field.fieldName] = ticket[field.fieldName];
							!!delete ticket[field.fieldName];
						}
					});

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (typeof tempMedia[key] === 'string' || tempMedia[key].url?.startsWith('file://')) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										ticket[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									ticket[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ ticket, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/tickets/${ticket.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.ticket.addTickets([res]);

						showToast('success', isDeletion ? i18next.t('Ticket deleted') : i18next.t('Ticket updated'));
						return true;
					}
					showToast('error', 'Error', translateStrapiError(res));

					return false;
				}
			} catch (error) {
				console.log('updateTicket', error);
			}
		},
		async testXINGEventsSettings(payload: ITestXINGEventsSettingsPayload, store) {
			try {
				const { xingEventsAPIKey, xingEventsEventId } = payload;

				const params = {
					apikey: xingEventsAPIKey
				};

				const formBody: string[] = [];
				for (const property in params) {
					const encodedKey = encodeURIComponent(property);
					const encodedValue = encodeURIComponent(params[property]);
					formBody.push(encodedKey + '=' + encodedValue);
				}

				const res = await fetch(`https://www.xing-events.com/api/event/${xingEventsEventId}?${formBody.join('&')}`)
					.then((response) => response)
					.then((resp) => resp.json());
				if (res.errors) {
					let text;
					switch (res.errors[0]) {
						case 'com.amiando.api.rest.InvalidResourceId':
							text = i18next.t('XingEventsEventNotFound');
							break;
						case 'com.amiando.api.rest.MissingParam.apikey':
							text = i18next.t('MissingXingEventsApiKey');
							break;
						case 'com.amiando.api.rest.InvalidApiKey':
							text = i18next.t('InvalidXingEventsApiKey');
							break;
						default:
							text = res.errors[0];
							break;
					}
					showToast('error', i18next.t('AnErrorOccurred'), text);
				} else {
					showToast(
						'success',
						i18next.t('XingEventsSettingsCorrect'),
						i18next.t('XingEventsEventFound').replace('%TITLE%', res.event.title)
					);
				}
			} catch (error) {
				console.log('testXINGEventsSettings', error);
			}
		},
		async testEmendoSettings(payload: ITestEmendoSettingsPayload, store) {
			try {
				const { emendoApiUrl, emendoApiUsername, emendoApiPassword } = payload;

				const tokenRes = await fetch(
					`${emendoApiUrl}/public_api/get/token?username=${encodeURIComponent(emendoApiUsername)}&password=${encodeURIComponent(
						emendoApiPassword
					)}`,
					{
						method: 'POST'
					}
				)
					.then((response) => response)
					.then((res) => {
						return res.json();
					});

				if (!tokenRes || !tokenRes.token) {
					showToast('error', i18next.t('AnErrorOccurred'), tokenRes);
					return null;
				}

				showToast('success', i18next.t('Settings Correct'), i18next.t('EmendoSettingsCorrect'));
			} catch (error) {
				console.log('testEmendoSettings', error);
			}
		},
		async testPretixSettings(payload: ITestPretixSettingsPayload, store) {
			try {
				const { pretixApiKey, pretixOrganizer, pretixEvent, pretixCheckinList } = payload;

				const pretixResponse = await fetch(
					`https://pretix.eu/api/v1/organizers/${pretixOrganizer}/events/${pretixEvent}/checkinlists/${pretixCheckinList}/`,
					{
						headers: {
							Authorization: `Token ${pretixApiKey}`
						}
					}
				).then((res) => res.json());

				if (!pretixResponse.id) {
					showToast('error', i18next.t('AnErrorOccurred'), pretixResponse?.detail);
					return null;
				}

				showToast('success', i18next.t('Settings Correct'), i18next.t('PretixSettingsCorrect'));
			} catch (error) {
				errorLog('testEmendoSettings', error);
			}
		},
		async updateTicketcode(payload: IUpdateTicketcodePayload, store) {
			try {
				const { ticketcode, userId } = payload;
				const activeSpaceId = dispatch.temp.getActiveSpaceId({});

				const body = new FormData();
				body.append(
					'data',
					JSON.stringify({
						ticketcode,
						spaceId: activeSpaceId,
						userId,
						userInfos: store.auth.userInfos
					})
				);
				const res = await dispatch.request.authenticatedRequest({
					method: 'POST',
					url: `${MULTISPACEURL}/userinspaceids/updateticketcode`,
					body
				});

				if (res?.ticketcode) {
					return res;
				}

				showToast('error', 'Error', translateStrapiError(res));

				return undefined;
			} catch (error) {
				console.log('updateTicketcode', error);
				return undefined;
			}
		}
	})
});
