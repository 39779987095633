import { Spinner } from 'components/Spinner';
import { IInternalSettings } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { FormTextInput } from 'components/Form';
import { Button, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';

interface IPretixSettings {
	testID: string;
}

export const PretixSettings = (props: IPretixSettings) => {
	const { testID } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [settings, setSettings] = useState<IInternalSettings | undefined>(undefined);
	const [pretixApiKey, setPretixApiKey] = useState<string>('');
	const [pretixOrganizer, setPretixOrganizer] = useState<string>('');
	const [pretixEvent, setPretixEvent] = useState<string>('');
	const [pretixCheckinList, setPretixCheckinList] = useState<string>('');

	const [isInternalSettingsLoading, setIsInternalSettingsLoading] = useState<boolean>(false);
	const [isUpdateInternalSettingsLoading, setIsUpdateInternalSettingsLoading] = useState<boolean>(false);
	const [isTestSettingsLoading, setIsTestSettingsLoading] = useState<boolean>(false);

	const getInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.getInternalSettings);
	const setInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.setInternalSettings);
	const testPretixSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.testPretixSettings);

	useEffect(() => {
		_loadInternalSettings();
	}, []);

	const _loadInternalSettings = async () => {
		setIsInternalSettingsLoading(true);
		const res = await getInternalSettings({});
		setSettings(res);
		setPretixOrganizer(res?.pretixOrganizer);
		setPretixEvent(res?.pretixEvent);
		setPretixCheckinList(res?.pretixCheckinList);
		setIsInternalSettingsLoading(false);
	};

	const _setInternalSettings = async () => {
		setIsUpdateInternalSettingsLoading(true);
		const res = await setInternalSettings({ settings: { pretixApiKey, pretixOrganizer, pretixEvent, pretixCheckinList } });
		setSettings(res);
		setIsUpdateInternalSettingsLoading(false);
	};

	const _testPretixSettings = async () => {
		setIsTestSettingsLoading(true);
		await testPretixSettings({
			pretixApiKey: settings?.pretixApiKey ?? pretixApiKey,
			pretixEvent: settings?.pretixEvent ?? pretixEvent,
			pretixOrganizer: settings?.pretixOrganizer ?? pretixOrganizer,
			pretixCheckinList: settings?.pretixCheckinList ?? pretixCheckinList
		});
		setIsTestSettingsLoading(false);
	};

	const _renderPretixStatus = () => {
		if (isInternalSettingsLoading) {
			return <Spinner />;
		}

		if (!settings) {
			// This should only happen if backend throws any error
			return <Text>{t('Could not get settings')}</Text>;
		}

		if (!settings.pretixOrganizer || !settings.pretixEvent || !settings.pretixCheckinList) {
			return (
				<View>
					<Text style={{ color: theme.danger, marginBottom: 10 }}>{t('PretixAPINotConfigured')}</Text>
					<FormTextInput
						testID={`${testID}_textinput_pretixapikey`}
						label="Pretix API Key"
						hint={t('apiKeyHint')}
						isRequired
						value={pretixApiKey}
						onChangeText={(url) => setPretixApiKey(url)}
						formStyle={{ marginBottom: 10 }}
						secureTextEntry
					/>
					<FormTextInput
						testID={`${testID}_textinput_pretixorganizer`}
						label="Pretix Organizer"
						isRequired
						value={pretixOrganizer}
						onChangeText={(username) => setPretixOrganizer(username)}
						formStyle={{ marginBottom: 10 }}
					/>
					<FormTextInput
						testID={`${testID}_textinput_pretixevent`}
						label="Pretix Event"
						isRequired
						value={pretixEvent}
						onChangeText={(pw) => setPretixEvent(pw)}
						formStyle={{ marginBottom: 10 }}
					/>
					<FormTextInput
						testID={`${testID}_textinput_pretixcheckinlist`}
						label="Pretix Checkinlist"
						isRequired
						value={pretixCheckinList}
						onChangeText={(pw) => setPretixCheckinList(pw)}
						formStyle={{ marginBottom: 10 }}
					/>
					<RoundButton
						testID={`${testID}_button_savesettings`}
						icon={EDefaultIconSet.Save}
						onPress={_setInternalSettings}
						isLoading={isUpdateInternalSettingsLoading}
						isDisabled={isEmptyString(pretixOrganizer) || isEmptyString(pretixEvent)}
						alignSelf="flex-end"
					/>
				</View>
			);
		}

		return (
			<View>
				<Text style={{ color: theme.success, marginBottom: 10 }}>{t('PretixAPIConfigured')}</Text>
				<Button
					testID={`${testID}_button_testsettings`}
					title={t('Test XING Events Settings')}
					isLoading={isTestSettingsLoading}
					onPress={_testPretixSettings}
					style={{ alignSelf: 'center', marginBottom: 10 }}
					type="success"
				/>
				<Button
					testID={`${testID}_button_editsettings`}
					title={t('Swap XING Events Settings')}
					onPress={() => {
						const _settings = { ...settings };
						delete _settings.pretixApiKey;
						delete _settings.pretixEvent;
						delete _settings.pretixOrganizer;
						delete _settings.pretixCheckinList;
						setSettings(_settings);
					}}
					isDisabled={isTestSettingsLoading}
					style={{ alignSelf: 'center' }}
					isOutline
				/>
			</View>
		);
	};

	return <View>{_renderPretixStatus()}</View>;
};
