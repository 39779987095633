import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsTopScreenPadding } from 'config/styleConstants';
import { BackHandler, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, IS_ANDROID, IS_WEB } from 'helper';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import i18next from 'i18next';
import { IHSPXTicket } from 'config/interfaces';
import { TicketList } from 'components/Ticket';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { PRESET_SPACEIDS } from 'config/envConstants';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.TicketList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.TicketList>;
type RouteParams = StackParamList[ERoutes.TicketList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'ticketlist';

export const TicketListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { exportAsCSV } = useExport();
	const { getContentTypeFields } = useContent('ticket');
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [spaceTickets, setSpaceTickets] = useState<IHSPXTicket[]>([]);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const tickets = useSelector((store: IRootState) => store.ticket.tickets);

	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const loadTickets = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.loadTickets);
	const loadUserInSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.loadUserInSpace);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
		_loadTickets();

		navigation.addListener('focus', _onFocus);

		return () => navigation.removeListener('focus', _onFocus);
	}, []);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	useEffect(() => {
		let _tickets: typeof spaceTickets = [];

		if (activeSpace && tickets) {
			_tickets = tickets.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setSpaceTickets(_tickets);
	}, [activeSpace, tickets]);

	const _onFocus = () => {
		_loadTickets();
	};

	const _loadTickets = async () => {
		setIsLoading(true);
		await loadTickets({});
		await loadUserInSpace({});
		setIsLoading(false);
	};

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		showAlert({
			title: itemId ? t('ConfirmDelete') : t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'ticket' });
						await _loadTickets();
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.TicketCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'reload':
				_loadTickets();
				bottomSheetRef.current?.close();
				break;
			case 'sendMail':
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					contentType: 'ticket',
					selectedEntries: selectedEntries,
					entryPoint: 'template'
				});
				bottomSheetRef.current?.close();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'ticket' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IHSPXTicket[] = [];
				if (tickets) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = tickets.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...tickets].filter((e) => e.spaceId === activeSpace?.spaceId);
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'tickets');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			default:
				break;
		}
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="tickets">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<TicketList
					testID={TESTIDPREFIX}
					selectedTickets={selectedEntries}
					onSelectAll={(selected) => setSelectedEntries(selected)}
					onSelect={(itemId) => _handleSelect(itemId)}
					isLoading={isDeleteLoading}
					onPress={(item) => navigation.navigate(ERoutes.TicketEdit, { spaceId: activeSpace?.spaceId, id: item.id })}
					onEdit={(itemId) => navigation.navigate(ERoutes.TicketEdit, { spaceId: activeSpace?.spaceId, id: itemId })}
					onDelete={(itemId) => {
						setSelectedEntries([itemId]);
						_delete(itemId);
					}}
				/>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_reload`}
						icon={EDefaultIconSet.Reload}
						label={t('Reload Data')}
						isDisabled={isDeleteLoading}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('reload')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_sendmail`}
						icon={EDefaultIconSet.Mail}
						label={t('Send Mail Selection').replace(
							'%COUNT%',
							selectedEntries.length > 0 ? ` (${selectedEntries.length})` : ''
						)}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('sendMail')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						isLoading={isDeleteLoading}
						iconColor={theme.danger}
					/>
				</BottomSheet>
			</View>
			<LoadingModal isLoading={isLoading && !process.env.IS_TEST} />
		</ScreenContainer>
	);
};

export const TicketListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Tickets')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
