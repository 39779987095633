import { IVoteAnswer } from 'config/interfaces/voteanswer';

export const voteTextPreviewAnswers: IVoteAnswer[] = [
	{
		created_at: '2021-08-30T15:13:01.000Z',
		id: 153,
		info: null,
		isDeleted: false,
		media: null,
		optionId: null,
		optionIds: null,
		spaceId: 'testch',
		status: 'Public',
		tempId: '25f2d298-e804-4284-9d71-31e8d424454a',
		updated_at: '2021-08-30T15:13:01.000Z',
		userId: 'f95215c0-e8c7-46bf-8e22-7c82f95aa8b7',
		value: 'Hallo',
		voteId: 89
	},
	{
		created_at: '2021-08-30T13:35:02.000Z',
		id: 149,
		info: null,
		isDeleted: false,
		media: null,
		optionId: null,
		optionIds: null,
		spaceId: 'testch',
		status: 'Public',
		tempId: '923fdadb-5d3f-4631-8ae3-bb86f24826ba',
		updated_at: '2021-08-30T13:35:02.000Z',
		userId: '33e0c139-f37a-4b58-8c78-86bf5641dbef',
		value: 'Erste Antwort',
		voteId: 89
	}
];
