import { HSCard } from 'components/Card';
import { StepWizard } from 'components/StepWizard';
import { Title } from 'components/Text';
import { IEditMailTemplateFormErrors, IEditMailTemplateFormValues, TMailTemplateType } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString } from 'helper';
import React, { useState } from 'react';
import { View } from 'react-native';
import { MailTemplateForm } from './MailTemplateForm';
import { useTranslation } from 'react-i18next';
import { RoundButton } from 'components/Button';
import { useSpace } from 'hooks/useSpace';

interface IMailTemplateWizard {
	onSubmit: () => void;
	isLoading: boolean;
	values: IEditMailTemplateFormValues;
	errors: IEditMailTemplateFormErrors;
	onChange: (values: IEditMailTemplateFormValues) => void;
}

const TESTIDPREFIX = 'mailtemplatewizard';

export const MailTemplateWizard = (props: IMailTemplateWizard) => {
	const { onSubmit, isLoading, values, errors, onChange } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [stepIndex, setStepIndex] = useState<number>(0);

	const _isNextButtonDisabled = () => {
		if (values.id || values.key) {
			switch (stepIndex) {
				case 0:
					return isEmptyString(values.title) || isEmptyString(values.body) || isEmptyString(values.subject);
				default:
					return false;
			}
		} else {
			switch (stepIndex) {
				case 0:
					return !values.contentType;
				case 1:
					return isEmptyString(values.title) || isEmptyString(values.body) || isEmptyString(values.subject);
				default:
					return false;
			}
		}
	};

	const _getNextButtonText = () => {
		switch (stepIndex) {
			case 0:
				if (!values.id && !values.key) {
					return t('Next');
				}
				return t('Save');
			case 1:
			default:
				return t('Save');
		}
	};

	const _onNext = (currentIndex) => {
		switch (currentIndex) {
			default:
				break;
		}
	};

	const _renderFieldTypeButton = (key: TMailTemplateType, icon: string, title: string) => {
		return (
			<View style={{ marginRight: 20, marginBottom: 20 }}>
				<RoundButton
					isOutline={values.contentType !== key}
					testID={`${TESTIDPREFIX}_fieldtype_${key}`}
					icon={icon}
					onPress={() => onChange({ ...values, contentType: key })}
					title={title}
				/>
			</View>
		);
	};

	const _renderTypeSelect = () => {
		const inactiveFeatureKeys =
			activeSpace?.features?.list?.filter((feature) => !feature.isActive)?.map((f) => f.key?.slice(0, -1)) ?? [];

		if (!values.id && !values.key) {
			return [
				<View>
					<HSCard>
						<Title>{t('MailTemplateTypeCTA')}</Title>
					</HSCard>
					<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
						{_renderFieldTypeButton('attendee', EDefaultIconSet.Attendees, t('Attendees'))}
						{!inactiveFeatureKeys?.includes('expo') && _renderFieldTypeButton('expo', EDefaultIconSet.Exhibition, t('Expos'))}
						{!inactiveFeatureKeys?.includes('speaker') &&
							_renderFieldTypeButton('speaker', EDefaultIconSet.Speakers, t('Speaker'))}
						{_renderFieldTypeButton('ticket', EDefaultIconSet.Ticket, t('Ticket'))}
					</View>
				</View>
			];
		}

		return [];
	};

	return (
		<StepWizard
			testIdPrefix={TESTIDPREFIX}
			getCurrentIndex={(val) => setStepIndex(val)}
			completeFunction={onSubmit}
			isLoading={isLoading}
			onNext={_onNext}
			nextButtonText={_getNextButtonText()}
			isNextButtonDisabled={_isNextButtonDisabled()}
			values={values}
			components={[
				..._renderTypeSelect(),
				<MailTemplateForm
					values={values}
					errors={errors}
					onChange={onChange}
					isLoading={isLoading}
					contentType={values.contentType ?? 'noTemplate'}
				/>
			]}
		/>
	);
};
