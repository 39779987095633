import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { ScrollView } from 'react-native';
import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useQuery } from 'hooks/useQuery';
import { H1 } from 'components/Text';
import i18next from 'i18next';
import { hsTopScreenPadding } from 'config/styleConstants';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SpeakerView>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SpeakerView>;
type RouteParams = StackParamList[ERoutes.SpeakerView];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const SpeakerViewScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();

	return (
		<ScreenContainer>
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<H1>This Screen is not finished yet</H1>
			</ScrollView>
		</ScreenContainer>
	);
};

export const SpeakerViewScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Speaker View')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
