import React from 'react';

import { ChildButton } from 'components/Button';
import { H1, WelcomeHint } from 'components/Text';
import { ICreateSpaceFormValues } from 'config/interfaces';
import { generateTheme } from 'helper';
import { useTranslation } from 'react-i18next';
import { Dimensions, ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { hsBottomMargin, hsCard } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { HSCard } from 'components/Card';
import { DEFAULTTHEME } from 'rematch/models/config';

interface ISpaceWizardTheme {
	testID: string;
	setTempSpace: (val) => void;
	space: ICreateSpaceFormValues;
	stepIndex: number;
	setStepIndex: (val) => void;
}

export const SpaceWizardTheme = (props: ISpaceWizardTheme) => {
	const { testID, setTempSpace, space, stepIndex, setStepIndex } = props;

	const { theme } = useTheme();
	const { t } = useTranslation();
	const { width } = Dimensions.get('window');
	const { isTabletOrMobile, screenWidth } = useQuery();

	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	const _getBGColor = (type: string) => {
		switch (type) {
			case 'dark':
				return '#111827';
			case 'light':
				return '#ffffff';
			default:
				break;
		}
	};

	const _getTextColor = (type: string) => {
		switch (type) {
			case 'dark':
				return '#F3F4F6';
			case 'light':
				return '#1f1f1f';
			default:
				break;
		}
	};

	const _getLightGrayColor = (type: string) => {
		return type === 'dark' ? '#bababa' : theme.lightgray;
	};

	const _getGrayColor = (type: string) => {
		return type === 'dark' ? '#d0d0d0' : theme.gray;
	};

	const _changeWizardTheme = (type: string) => {
		const _space: typeof space = {
			...space,
			backgroundColor: _getBGColor(type),
			contentBackgroundColor: _getBGColor(type),
			textColor: _getTextColor(type),
			navigationTextColor: _getTextColor(type),
			lightGrayColor: _getLightGrayColor(type),
			grayColor: _getGrayColor(type),
			formGrayColor: DEFAULTTHEME.formgray,
			webLayout: 'cards',
			appLayout: 'cards'
		};
		setTempSpace(_space);
		setTheme(generateTheme(_space));
	};

	const _renderThemeButton = (type: string) => {
		return (
			<ChildButton
				key={`${testID}_button_changetheme_${type}`}
				testID={`${testID}_button_changetheme_${type}`}
				onPress={() => {
					_changeWizardTheme(type);
					setStepIndex(stepIndex + 1);
				}}
				style={{
					...hsCard,
					shadowColor: theme.hsShadowColor,
					backgroundColor: _getBGColor(type),
					width: isTabletOrMobile ? width / 3 : width / 8,
					height: isTabletOrMobile ? width / 3 : width / 8,
					borderRadius: 5,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<H1 noBottomMargin style={{ color: _getTextColor(type) }}>
					{t(type)}
				</H1>
			</ChildButton>
		);
	};

	return (
		<ScrollView
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingVertical: hsBottomMargin
			}}
		>
			<HSCard>
				<H1>{t('selectThemeHeader')}</H1>
				<WelcomeHint>{t('selectThemeHint')}</WelcomeHint>
			</HSCard>

			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-around'
				}}
			>
				{_renderThemeButton('light')}
				{_renderThemeButton('dark')}
			</View>
		</ScrollView>
	);
};
