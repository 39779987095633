import React from 'react';
import { Platform, View } from 'react-native';
import { Text } from 'components/Text';
import { lastPublish } from 'config/Publish';
import * as Application from 'expo-application';
// import * as Updates from 'expo-updates';
import { useTheme } from 'hooks/useTheme';

export const NativeVersionInfo = () => {
	const { theme } = useTheme();

	const _renderNativeVersion = () => {
		switch (Platform.OS) {
			case 'android':
			case 'ios':
				return (
					<View style={{ alignItems: 'center', marginTop: 5, flexDirection: 'column' }}>
						<View style={{ alignItems: 'center', marginTop: 5, flexDirection: 'row' }}>
							<Text style={{ fontSize: 10, paddingHorizontal: 5, color: theme.navigationTextColor ?? theme.text }}>
								Build: {Application.nativeBuildVersion ?? ''}
							</Text>

							<Text style={{ fontSize: 10, paddingHorizontal: 5, color: theme.navigationTextColor ?? theme.text }}>
								Version: {Application.nativeApplicationVersion ?? ''}
							</Text>
						</View>
					</View>
				);
			default:
				return null;
		}
	};

	return (
		<View style={{ alignItems: 'center' }}>
			<Text style={{ fontSize: 12, color: theme.navigationTextColor ?? theme.text }}>{lastPublish}</Text>
			{_renderNativeVersion()}
		</View>
	);
};
