import { Button, ChildButton, RoundButton } from 'components/Button';
import { Markdown } from 'components/Markdown/Markdown';
import { MarkdownExplanationModal } from 'components/Modal/Markdown';
import { IFormBase, ITabSwitcherButton } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, IS_WEB, openURL } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, ViewStyle } from 'react-native';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from '../constants';
import { FormField } from '../FormField';
import { FormMarkdownEditorModal } from './FormMarkdownEditorModal';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from '../FormTextInput';
import { FormHint } from '../FormHint';
import { hsBorderRadius } from 'config/styleConstants';
import { TabView } from 'components/TabView';
import { useQuery } from 'hooks/useQuery';
import { EForumLinks } from 'config/constants';

interface IFormMarkdownEditor extends IFormBase {
	value?: string;
	onChange: (val) => void;
	formStyle?: ViewStyle;
	useModal?: boolean;
}

interface ITextRange {
	start: number;
	end: number;
}

export const FormMarkdownEditor = (props: IFormMarkdownEditor) => {
	const { testID, label, isRequired, hint, error, isDisabled, value, onChange, formStyle, useModal } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { isTabletOrMobile } = useQuery();

	const MIN_LIVE_PREVIEW_WIDTH = 600;

	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isMarkdownModalVisible, setIsMarkdownModalVisible] = useState<boolean>(false);
	const [isPreview, setIsPreview] = useState<boolean>(!isEmptyString(value));
	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [showLivePreview, setShowLivePreview] = useState<boolean>(false);

	const [markdownText, setMarkdownText] = useState<string>(value ?? '');
	const [selectedText, setSelectedText] = useState<ITextRange>({ end: 0, start: 0 });

	const tabs: ITabSwitcherButton[] = [
		{ key: 'editor', label: t('Edit') },
		{ key: 'preview', label: t('Preview') }
	];

	useEffect(() => {
		if (isDisabled || isPreview || (!isEmptyString(value) && isEmptyString(markdownText))) {
			setMarkdownText(value ?? '');
			setIsPreview(true);
		}
	}, [value]);

	const _handleAction = (type: string) => {
		let _addText = '';
		let _newText = '';

		const preventNewlineAtTheStart = isEmptyString(markdownText?.slice(0, selectedText?.start)) || isEmptyString(markdownText);

		switch (type) {
			case 'addHeader':
				if (selectedText?.start === selectedText.end) {
					_addText = (preventNewlineAtTheStart ? '' : '\n') + `# ${t('Header')}` + '\n';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '# ', markdownText?.slice(selectedText?.start)].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addSmallHeader':
				if (selectedText?.start === selectedText.end) {
					_addText = (preventNewlineAtTheStart ? '' : '\n') + `## ${t('Header')}` + '\n';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '## ', markdownText?.slice(selectedText?.start)].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addBold':
				if (selectedText?.start === selectedText?.end) {
					_addText = '**' + t('addBold') + '**';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '**', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 2), '**', _newText?.slice(selectedText?.end + 2)].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addCenter':
				if (selectedText?.start === selectedText?.end) {
					_addText = '<center>' + t('addCenter') + '</center>';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '<center>', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 8), '</center>', _newText?.slice(selectedText?.end + 8)].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addItalic':
				if (selectedText?.start === selectedText?.end) {
					_addText = '*' + t('addItalic') + '*';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '*', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 1), '*', _newText?.slice(selectedText?.end + 1)].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addImage':
				_addText = `![${t('MarkdownImageText')}](https://hellospaces.b-cdn.net/hspx.png)`;
				break;
			case 'addLink':
				if (selectedText?.start === selectedText?.end) {
					_addText = `[${t('MarkdownLinkText')}](https://hellospaces.de)`;
				} else {
					_newText = [
						markdownText?.slice(0, selectedText?.start),
						`[${t('MarkdownLinkText')}](`,
						markdownText?.slice(selectedText?.start)
					].join('');
					_newText = [
						_newText?.slice(0, selectedText?.end + t('MarkdownLinkText').length + 3),
						')',
						_newText?.slice(selectedText?.end + t('MarkdownLinkText').length + 3)
					].join('');
					setMarkdownText(_newText);
					onChange(_newText);
					return;
				}
				break;
			case 'addList':
				_addText = '- A1' + '\n' + '- B2' + '\n' + '- C3';
				break;
			case 'addHorizontalLine':
				_addText = (!isEmptyString(markdownText) ? '\n' : '') + '***' + '\n\n';
				break;
			default:
				break;
		}

		if (selectedText?.start !== undefined && selectedText?.start !== null) {
			_newText = [
				markdownText?.slice(0, selectedText?.start),
				(preventNewlineAtTheStart ? '' : '\n') + _addText,
				markdownText?.slice(selectedText?.start)
			].join('');
		}

		setMarkdownText(_newText);
		onChange(_newText);
		setSelectedText({ end: 0, start: 0 });
	};

	const _renderEditor = () => {
		if (isPreview) {
			return null;
		}

		return (
			<View style={{ flex: 1 }}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: 5,
						borderBottomWidth: 1,
						borderColor: theme.lightgray,
						padding: 5
					}}
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				>
					<ScrollView
						horizontal
						showsHorizontalScrollIndicator={false}
						style={{ paddingRight: 5 }}
						scrollEnabled={true}
						keyboardShouldPersistTaps={'handled'}
						keyboardDismissMode={'none'}
					>
						<RoundButton
							testID={`${testID}_button_addheader`}
							icon={EDefaultIconSet.Heading1}
							size="sm"
							isStacked
							onPress={() => _handleAction('addHeader')}
						/>
						<RoundButton
							testID={`${testID}_button_addsmallheader`}
							icon={EDefaultIconSet.Heading2}
							size="sm"
							isStacked
							onPress={() => _handleAction('addSmallHeader')}
						/>
						<RoundButton
							testID={`${testID}_button_addbold`}
							icon={EDefaultIconSet.Bold}
							size="sm"
							isStacked
							onPress={() => _handleAction('addBold')}
						/>
						<RoundButton
							testID={`${testID}_button_additalic`}
							icon={EDefaultIconSet.Italic}
							size="sm"
							isStacked
							onPress={() => _handleAction('addItalic')}
						/>
						<RoundButton
							testID={`${testID}_button_addcenter`}
							icon={EDefaultIconSet.Centered}
							size="sm"
							isStacked
							onPress={() => _handleAction('addCenter')}
						/>
						<RoundButton
							testID={`${testID}_button_addimage`}
							icon={EDefaultIconSet.Image}
							size="sm"
							isStacked
							onPress={() => _handleAction('addImage')}
						/>
						<RoundButton
							testID={`${testID}_button_addlink`}
							icon={EDefaultIconSet.Link}
							size="sm"
							isStacked
							onPress={() => _handleAction('addLink')}
						/>
						<RoundButton
							testID={`${testID}_button_addlist`}
							icon={EDefaultIconSet.List}
							size="sm"
							isStacked
							onPress={() => _handleAction('addList')}
						/>
						<RoundButton
							testID={`${testID}_button_addhorizontalline`}
							icon={EDefaultIconSet.HorizontalRule}
							size="sm"
							isStacked
							onPress={() => _handleAction('addHorizontalLine')}
						/>
					</ScrollView>
					<RoundButton
						testID={`${testID}_button_openmarkdownhelp`}
						icon={EDefaultIconSet.Help}
						size="sm"
						isStacked
						onPress={() => openURL(EForumLinks.Markdown)}
					/>
					{IS_WEB && containerWidth >= MIN_LIVE_PREVIEW_WIDTH && (
						<RoundButton
							testID={`${testID}_button_showpreview`}
							icon={showLivePreview ? EDefaultIconSet.AnswerWaiting : EDefaultIconSet.AnswerPublic}
							size="sm"
							isStacked
							onPress={() => setShowLivePreview(!showLivePreview)}
						/>
					)}
				</View>
				<View
					style={{
						paddingBottom: 5,
						width: '100%',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<FormTextInput
						testID={`${testID}_textinput_markdowntext`}
						placeholder={t('markdownTextPlaceholder')}
						multiline={true}
						value={markdownText}
						onChangeText={(val) => {
							setMarkdownText(val);
							onChange(val);
						}}
						onSelectionChange={(nativeEvent) => {
							setSelectedText(nativeEvent?.selection);
						}}
						formStyle={{ marginBottom: 0, flex: 1 }}
						noBorder
						blurOnSubmit={false}
						isDisabled={isDisabled}
					/>
					{showLivePreview && IS_WEB && containerWidth >= MIN_LIVE_PREVIEW_WIDTH && (
						<View style={{ flex: 1, borderLeftWidth: 1, borderLeftColor: theme.lightgray, maxHeight: 160 }}>
							<View style={{ paddingHorizontal: 5, marginVertical: -5, maxHeight: 160 }}>
								{isEmptyString(markdownText) ? (
									<Markdown markdown={`${t('EmptyText')}`} scrollable />
								) : (
									<Markdown markdown={markdownText} scrollable />
								)}
							</View>
						</View>
					)}
				</View>
			</View>
		);
	};

	const _renderPreview = () => {
		if (isPreview) {
			return (
				<View style={{ flex: 1, paddingHorizontal: 5 }}>
					{isEmptyString(markdownText) ? (
						<Markdown markdown={`${t('EmptyText')}`} scrollable />
					) : (
						<Markdown markdown={markdownText} scrollable />
					)}
				</View>
			);
		}

		return null;
	};

	if (useModal) {
		return (
			<View>
				<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
					<View style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
						<ChildButton
							testID={`${testID}_button`}
							isDisabled={isDisabled}
							onPress={() => setIsModalVisible(true)}
							style={{ flex: 1, marginRight: 5 }}
						>
							<Markdown
								markdown={value}
								containerStyle={{
									borderRadius: FORMELEMENTBORDERRADIUS,
									borderWidth: FORMELEMENTBORDERWIDTH,
									borderColor: theme.lightgray,
									paddingHorizontal: 5
								}}
							/>
						</ChildButton>
						<RoundButton
							testID={`${testID}_button_edit`}
							isOutline
							icon={EDefaultIconSet.Edit}
							isDisabled={isDisabled}
							onPress={() => setIsModalVisible(true)}
						/>
					</View>
					<Button
						type="cancel"
						style={{ alignSelf: 'flex-end', marginTop: 10 }}
						testID={`markdown_button_openmarkdown`}
						isDisabled={isDisabled && testID.startsWith('importdata')}
						buttonType="sectionheader"
						title={t('howToFormatting')}
						onPress={() => setIsMarkdownModalVisible(true)}
						isOutline
					/>
				</FormField>
				<FormMarkdownEditorModal
					value={value}
					isVisible={isModalVisible}
					onClose={() => setIsModalVisible(false)}
					onChange={onChange}
					testID={testID}
				/>
				<MarkdownExplanationModal isVisible={isMarkdownModalVisible} onClose={() => setIsMarkdownModalVisible(false)} />
			</View>
		);
	}

	return (
		<View>
			<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
				<FormHint hint={t('markdownEditHint')} testID={`${testID}_textinput_markdowntext`} />
				<View style={{ borderWidth: 1, borderRadius: hsBorderRadius, borderColor: theme.lightgray }}>
					<TabView
						items={tabs}
						testIdPrefix={testID}
						onPress={(key) => {
							if (key === 'preview') {
								setShowLivePreview(false);
								setIsPreview(true);
							} else {
								setIsPreview(false);
							}
						}}
						activeKey={isPreview ? 'preview' : 'editor'}
						containerStyle={{
							marginBottom: 5,
							borderBottomWidth: 1,
							borderBottomColor: theme.lightgray
						}}
						autoWidth={!isTabletOrMobile}
						isDisabled={isDisabled}
					/>
					{_renderEditor()}
					{_renderPreview()}
				</View>
				{!isPreview && (
					<Button
						type="cancel"
						style={{ alignSelf: 'flex-end', marginTop: 10 }}
						testID={`markdown_button_openmarkdown`}
						isDisabled={isDisabled && testID.startsWith('importdata')}
						buttonType="sectionheader"
						title={t('howToFormatting')}
						onPress={() => setIsMarkdownModalVisible(true)}
						isOutline
					/>
				)}
			</FormField>
			<MarkdownExplanationModal isVisible={isMarkdownModalVisible} onClose={() => setIsMarkdownModalVisible(false)} />
		</View>
	);
};
