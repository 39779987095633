import React, { useEffect, useState } from 'react';
import { IAttendee, IUserProfile } from 'config/interfaces';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { RoundButton } from 'components/Button';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { EDefaultIconSet } from 'helper';
import { Avatar } from 'components/User';
import { useTheme } from 'hooks/useTheme';

interface ISpaceAdministratorListEntry {
	item: IUserProfile;
	onDeletePress?: (itemId: string) => void;
	isLoading?: boolean;
}

export const SpaceAdministratorListEntry = (props: ISpaceAdministratorListEntry) => {
	const { item, onDeletePress, isLoading } = props;
	const { theme } = useTheme();

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _attendee: typeof attendee = attendees.find((e) => e.userId === item.userId);

		setAttendee(_attendee);
	}, [attendees, item]);

	return (
		<View style={{ flexDirection: 'row', alignItems: 'center' }}>
			<Avatar
				avatar={attendee?.smallImageUrl ?? attendee?.imageUrl ?? item.avatar?.formats?.small?.url ?? item.avatar?.url}
				fullName={`${attendee?.firstName ?? item.firstName} ${attendee?.lastName ?? item.lastName}`}
				size="xs"
			/>
			<View style={{ marginLeft: 10, flex: 1 }}>
				<Text style={{ fontWeight: 'bold' }}>{`${attendee?.firstName ?? item.firstName} ${
					attendee?.lastName ?? item.lastName
				}`}</Text>
				<Text>{attendee?.email ?? item.email}</Text>
			</View>
			{onDeletePress && (attendee?.userId ?? item.userId) !== profile?.userId && (
				<RoundButton
					testID={`spaceadminlist_${attendee?.id ?? item.id}_delete`}
					icon={EDefaultIconSet.Close}
					color={theme.danger}
					size="sm"
					onPress={() => onDeletePress(attendee?.userId ?? item.userId)}
					isOutline
					isLoading={isLoading}
				/>
			)}
		</View>
	);
};
