import React from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper/fontSize';

interface IFormLabelComponent {
	testID: string;
	isRequired?: boolean;
	label?: string;
	noBoldLabel?: boolean;
	style?: ViewStyle;
	largerText?: boolean;
}

export const FormLabel = (props: IFormLabelComponent) => {
	const { testID, label, isRequired, noBoldLabel, style, largerText } = props;

	if (label) {
		return (
			<View style={[{ flexDirection: 'row' }, style]}>
				<Text
					testID={`${testID}_label`}
					bold={!noBoldLabel}
					style={{ fontSize: largerText ? normalizeFontSize(20) : normalizeFontSize(14) }}
				>
					{label}
				</Text>
				{isRequired && (
					<Text
						style={{
							color: 'red',
							marginLeft: 2,
							marginTop: -3,
							fontSize: largerText ? normalizeFontSize(20) : normalizeFontSize(14)
						}}
					>
						*
					</Text>
				)}
			</View>
		);
	}

	return null;
};
