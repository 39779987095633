import { TVotingType } from 'config/interfaces';
import { PreviewApplauseOptions, PreviewPinOnImageOptions, SpaceWizardMultipleChoiceOptions } from 'helper/previewAnswers';
import i18next from 'i18next';

export const getDefaultVotingQuestion = (type: TVotingType) => {
	switch (type) {
		case 'applause':
			return i18next.t('applauseSuggestionQuestion');
		case 'multipleChoice':
			return i18next.t('multipleChoiceSuggestionQuestion');
		case 'quiz':
			return i18next.t('quizSuggestionQuestion');
		case 'rating':
			return i18next.t('ratingSuggestionQuestion');
		case 'scale':
			return i18next.t('scaleSuggestionQuestion');
		case 'survey':
			return i18next.t('surveySuggestionQuestion');
		case 'text':
			return i18next.t('textSuggestionQuestion');
		case 'wordCloud':
			return i18next.t('wordCloudSuggestionQuestion');
		case 'nps':
			return i18next.t('npsSuggestionQuestion');
		case 'pinOnImage':
			return i18next.t('pinOnImageSuggestionQuestion');
		case 'happinessOMeter':
			return i18next.t('happinessOMeterSuggestionQuestion');
		default:
			return '';
	}
};

export const getDefaultVotingHint = (type: TVotingType) => {
	switch (type) {
		case 'applause':
			return i18next.t('applauseSuggestionHint');
		case 'multipleChoice':
			return i18next.t('multipleChoiceSuggestionHint');
		case 'quiz':
			return i18next.t('quizSuggestionHint');
		case 'rating':
			return i18next.t('ratingSuggestionHint');
		case 'scale':
			return i18next.t('scaleSuggestionHint');
		case 'survey':
			return i18next.t('surveySuggestionHint');
		case 'text':
			return i18next.t('textSuggestionHint');
		case 'wordCloud':
			return i18next.t('wordCloudSuggestionHint');
		case 'nps':
			return i18next.t('npsSuggestionHint');
		case 'pinOnImage':
			return i18next.t('pinOnImageSuggestionHint');
		case 'happinessOMeter':
			return i18next.t('happinessOMeterSuggestionHint');
		default:
			return '';
	}
};

export const getDefaultVotingOptions = (type: TVotingType) => {
	switch (type) {
		case 'applause':
			return [...PreviewApplauseOptions];
		case 'pinOnImage':
			return [...PreviewPinOnImageOptions];
		case 'multipleChoice':
			return SpaceWizardMultipleChoiceOptions.map((e) => {
				return { id: e.id, description: i18next.t(e.description ?? '') };
			});
		case 'quiz':
			return [
				{
					label: '',
					description: i18next.t('quizSuggestionQuestion1'),
					order: 0
				},
				{
					label: '',
					description: i18next.t('quizSuggestionQuestion2'),
					order: 1
				},
				{
					label: '',
					description: i18next.t('quizSuggestionQuestion3'),
					order: 2
				}
			];
		case 'rating':
			return [];
		case 'scale':
			return [];
		case 'survey':
			return [
				{
					label: '',
					description: i18next.t('surveySuggestionQuestion1'),
					order: 0
				},
				{
					label: '',
					description: i18next.t('surveySuggestionQuestion2'),
					order: 1
				},
				{
					label: '',
					description: i18next.t('surveySuggestionQuestion3'),
					order: 2
				}
			];
		case 'text':
			return [];
		case 'wordCloud':
			return [];
		default:
			return [];
	}
};

export const getDefaultOptionsVote = (type: TVotingType) => {
	switch (type) {
		case 'quiz':
			return [
				{
					question: i18next.t('quizSuggestionQuestion1'),
					hint: i18next.t('quizSuggestionHint1'),
					options: [
						{
							description: '1952',
							order: 0,
							isCorrect: false
						},
						{
							description: '1969',
							order: 1,
							isCorrect: true
						},
						{
							description: '2002',
							order: 2,
							isCorrect: false
						}
					],
					order: 0,
					score: 50,
					timeOut: 20
				},
				{
					question: i18next.t('quizSuggestionQuestion2'),
					hint: i18next.t('quizSuggestionHint2'),
					options: [
						{
							description: i18next.t('vatikanCityState'),
							order: 0,
							isCorrect: true
						},
						{
							description: 'Liechtenstein',
							order: 1,
							isCorrect: false
						},
						{
							description: i18next.t('Switzerland'),
							order: 2,
							isCorrect: false
						}
					],
					order: 1,
					score: 300,
					timeOut: 20
				},
				{
					question: i18next.t('quizSuggestionQuestion3'),
					hint: i18next.t('quizSuggestionHint3'),
					options: [
						{
							description: 'Amsterdam',
							order: 0,
							isCorrect: false
						},
						{
							description: 'New York',
							order: 1,
							isCorrect: false
						},
						{
							description: 'Gotham',
							order: 2,
							isCorrect: true
						}
					],
					order: 2,
					score: 200,
					timeOut: 20
				}
			];
		case 'applause':
		case 'multipleChoice':
		case 'rating':
		case 'scale':
		case 'survey':
			return [
				{
					question: i18next.t('surveySuggestionQuestion1'),
					// hint: i18next.t('surveySuggestionQuestionHint1'),
					order: 0,
					type: 'rating',
					starAndScaleAmount: 10
				},
				{
					question: i18next.t('surveySuggestionQuestion2'),
					hint: i18next.t('surveySuggestionQuestionHint2'),
					order: 1,
					type: 'text'
				},
				{
					question: i18next.t('surveySuggestionQuestion3'),
					hint: i18next.t('surveySuggestionQuestionHint3'),
					type: 'multipleChoice',
					options: [
						{
							description: i18next.t('surveySuggestionQuestionAnswer1'),
							order: 0
						},
						{
							description: i18next.t('surveySuggestionQuestionAnswer2'),
							order: 1
						},
						{
							description: i18next.t('surveySuggestionQuestionAnswer3'),
							order: 2
						}
					],
					order: 2
				}
			];
		case 'text':
		case 'wordCloud':
		case 'pinOnImage':
		default:
			return [];
	}
};
