import { IAttendee, IChatMessage } from 'config/interfaces';
import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { CHATMESSAGEBORDERRADIUS, CHATMESSAGEBOTTOMMARGIN, CHATMESSAGECONTENTSIZE } from '../constants';
import { Text } from 'components/Text';
import moment from 'moment';
import { ChildButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { Avatar } from 'components/User';
import { useNavigation } from '@react-navigation/native';
import ParsedText from 'react-native-parsed-text';
import { Icon } from 'components/Icon';
import { openURL } from 'helper';
import { useTheme } from 'hooks/useTheme';

interface IChatMessageComponent {
	item: IChatMessage;
	partner?: IAttendee;
	isMyMessage: boolean;
}

export const ChatMessage = memo((props: IChatMessageComponent) => {
	const { item, partner, isMyMessage } = props;
	const { theme } = useTheme();
	const navigation = useNavigation();

	const _renderAvatar = () => {
		if (partner && !isMyMessage) {
			return (
				<ChildButton
					testID={`chatmessage_${item.id}_avatar`}
					style={{ marginRight: 10 }}
					onPress={() =>
						navigation.navigate(ERoutes.AttendeeDetails, {
							userId: partner.userId
						})
					}
				>
					<Avatar
						testID={`chatmessage_avatar`}
						avatar={partner?.smallImageUrl ?? partner.imageUrl}
						fullName={`${partner.firstName} ${partner.lastName}`}
						userId={partner.userId}
						size="xs"
					/>
				</ChildButton>
			);
		}

		return null;
	};

	const _handleEmailPress = (email: string) => {
		openURL(`mailto:${email}`);
	};

	const _handlePhonePress = (phoneNumber: string) => {
		openURL(`tel:${phoneNumber}`);
	};

	const _handleUrlPress = (url: string) => {
		openURL(url);
	};

	return (
		<View
			style={{
				flexDirection: 'row',
				alignItems: 'flex-end',
				alignSelf: isMyMessage ? 'flex-end' : 'flex-start',
				marginBottom: CHATMESSAGEBOTTOMMARGIN
			}}
		>
			{_renderAvatar()}
			<View
				style={{
					maxWidth: '55%',
					minWidth: 65,
					borderRadius: CHATMESSAGEBORDERRADIUS,
					padding: 5,
					backgroundColor: isMyMessage ? theme.myChatBubble : theme.partnerChatBubble
				}}
			>
				<View style={{ padding: 5 }}>
					<ParsedText
						style={{
							fontSize: CHATMESSAGECONTENTSIZE,
							color: isMyMessage ? theme.myChatBubbleText : theme.partnerChatBubbleText
						}}
						parse={[
							{ type: 'url', style: styles.link, onPress: _handleUrlPress },
							{ type: 'phone', style: styles.link, onPress: _handlePhonePress },
							{ type: 'email', style: styles.link, onPress: _handleEmailPress },
							{ pattern: /#(\w+)/ }
						]}
					>
						{item.content}
					</ParsedText>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						paddingHorizontal: 5
					}}
				>
					<Icon
						name={`MaterialCommunityIcons:${item.status === 'sent' ? 'check' : 'check-all'}`}
						color={
							isMyMessage ? (item.hasBeenRead ? theme.chatMessageRead : theme.myChatBubbleText) : theme.partnerChatBubbleText
						}
						size={10}
						testID={`chatmessage_${item.id}_check_${item.status}`}
					/>
					<Text
						style={{
							fontSize: CHATMESSAGECONTENTSIZE - 4,
							color: isMyMessage ? theme.myChatBubbleText : theme.partnerChatBubbleText
						}}
					>
						{moment(item.created_at).format('HH:mm')}
					</Text>
				</View>
			</View>
		</View>
	);
});

const styles = StyleSheet.create({
	link: {
		textDecorationLine: 'underline'
	}
});
