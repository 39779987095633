import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form/FormTextInput';
import { Text } from 'components/Text';
import { IAttendee } from 'config/interfaces';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';

interface ISetAttendeeTicketModal {
	isVisible: boolean;
	onClose: () => void;
	attendee?: IAttendee;
	isLoading: boolean;
}

const TESTIDPREFIX = 'setticketmodal';

export const SetAttendeeTicketModal = (props: ISetAttendeeTicketModal) => {
	const { isVisible, onClose, attendee, isLoading } = props;

	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [newTicketcode, setNewTicketcode] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [isTicketcodeLoading, setIsTicketcodeLoading] = useState<boolean>(false);

	const updateTicketcode = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.updateTicketcode);

	const _updateTicketcode = async () => {
		setError('');
		if (attendee) {
			setIsTicketcodeLoading(true);
			const res = await updateTicketcode({ ticketcode: newTicketcode, userId: attendee?.userId });

			setIsTicketcodeLoading(false);
			if (res) {
				setNewTicketcode('');
				onClose();
			}
		}
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title={t('setTicketcode')}>
			<ScrollView style={{ marginHorizontal: -hsInnerPadding }} contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}>
				<Text style={{ marginBottom: hsBottomMargin }}>{t('setNewTicketcodeModalText')}</Text>
				{attendee?.tickets &&
				activeSpace &&
				attendee.tickets[activeSpace?.spaceId] &&
				attendee.tickets[activeSpace?.spaceId]?.ticketcode ? (
					<FormTextInput
						editable={false}
						key={'currentTicketcode'}
						testID={`${TESTIDPREFIX}_textinput_currentticketcode`}
						label={t('currentTicketcode')}
						hint={t('notEditable')}
						value={attendee.tickets[activeSpace.spaceId].ticketcode}
						error={error}
					/>
				) : (
					<Text style={{ marginBottom: hsBottomMargin }}>{t('attendeeHasNotTicketcode')}</Text>
				)}
				<FormTextInput
					key={'newTicketcode'}
					testID={`${TESTIDPREFIX}_textinput_newticketcode`}
					label={t('setNewTicketcodeLabel')}
					hint={t('setNewTicketcodeHint')}
					isRequired
					value={newTicketcode}
					error={error}
					onChangeText={(value) => setNewTicketcode(value)}
					isDisabled={isLoading}
				/>
				<RoundButton
					isOutline
					isLoading={isLoading || isTicketcodeLoading}
					color={theme.danger}
					testID={`${TESTIDPREFIX}_button_setticketcode`}
					icon={EDefaultIconSet.Save}
					onPress={() => _updateTicketcode()}
					title={t('setTicketcodeNow')}
					alignSelf="center"
					isDisabled={!isEmptyString(error) || isEmptyString(newTicketcode)}
				/>
			</ScrollView>
		</HSModal>
	);
};
