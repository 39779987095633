import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { Text } from 'components/Text';
import { IS_WEB, sortArrayAsPyramid } from 'helper';
import { useQuery } from 'hooks/useQuery';
import ReactWordcloud from 'react-wordcloud';

import { hsBorderRadius } from 'config/styleConstants';

export interface IWordListItem {
	text: string;
	count: number;
	color?: string;
	size?: number;
}
interface IWordCloud {
	wordList: IWordListItem[];
	minFontSize: number;
	maxFontSize: number;
	shuffle?: boolean;
}

export const WordCloud = (props: IWordCloud) => {
	const { wordList, minFontSize, maxFontSize, shuffle } = props;
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();

	const [minCount, setMinCount] = useState<number>(0);
	const [maxCount, setMaxCount] = useState<number>(0);
	const [renderData, setRenderData] = useState<IWordListItem[]>([]);

	useEffect(() => {
		let _data: typeof renderData = [];

		const counts = wordList.map((e) => e.count);

		let min: typeof minCount = Math.min(...counts);
		let max: typeof maxCount = Math.max(...counts);

		_data = wordList.map((e) => {
			return {
				...e,
				text: `${e.text}`,
				value: `${e.text}`
			};
		});

		_data = sortArrayAsPyramid(_data);

		setMinCount(min);
		setMaxCount(max);

		setRenderData(_data);
	}, [wordList, shuffle]);

	const _renderCachedWords = () => {
		if (IS_WEB) {
			const words = wordList
				.filter((e) => e.text.length <= 25)
				.map((item) => {
					return { text: item.text, value: item.count };
				});

			return (
				<View style={{ height: '100%', width: '100%' }}>
					<ReactWordcloud
						words={words}
						options={{
							deterministic: true,
							randomSeed: '1abc',
							enableTooltip: false,
							rotations: 0,
							rotationAngles: [0, 0],
							fontSizes: [minFontSize, maxFontSize * 2],
							padding: 5,
							spiral: 'archimedean',
							scale: 'log',
							fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
							transitionDuration: 100,
							colors: [theme.primary]
						}}
						maxWords={100}
					/>
				</View>
			);
		}
	};

	const _renderWords = () => {
		if (IS_WEB) {
			return _renderCachedWords();
		}

		return (
			<ScrollView
				style={{
					width: '100%',
					minHeight: '100%',
					borderRadius: hsBorderRadius
				}}
				centerContent
				contentContainerStyle={{
					width: '100%',
					alignContent: 'center',
					alignItems: 'center',
					justifyContent: 'center',
					flexWrap: 'wrap',
					flexDirection: 'row'
				}}
			>
				{renderData.map((item) => {
					const counts = renderData.map((e) => e.count).sort((a, b) => b - a);
					const maxBoldItems = renderData.length > 10 ? 3 : renderData.length > 5 ? 2 : 1;
					const isMaxCount = counts.slice(0, maxBoldItems).includes(item.count);

					let fontSize =
						(item.size ?? 14) +
						(item.count * 4 > 10 ? 10 + (item.count > 30 ? 30 : item.count) : item.count * 4) +
						(renderData.length < 10 ? 3 : 1);

					if (fontSize < minFontSize) fontSize = minFontSize;
					return (
						<View
							style={{
								paddingHorizontal: 4,
								paddingVertical: 2,
								margin: 4,
								borderRadius: hsBorderRadius
							}}
						>
							<Text
								style={{
									fontSize:
										renderData.length > 200
											? Math.ceil(fontSize * 0.4)
											: renderData.length > 80
											? Math.ceil(fontSize * 0.5)
											: renderData.length > 30
											? Math.ceil(fontSize * 0.66)
											: Math.ceil(fontSize * 0.72),
									color: theme.primary
								}}
								center
								bold={isMaxCount && item.count > 1}
							>
								{item.text}
							</Text>
						</View>
					);
				})}
			</ScrollView>
		);
	};

	return (
		<View
			style={{
				flex: 1,
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignSelf: 'center',
				alignContent: 'center',
				alignItems: 'center',
				borderRadius: 99,
				maxWidth: IS_WEB && !isTabletOrMobile ? '85%' : '100%'
			}}
		>
			<View
				style={{
					flex: 1,
					width: '100%',
					height: '100%',
					flexDirection: 'row',
					flexWrap: 'wrap',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: hsBorderRadius
				}}
			>
				{_renderWords()}
			</View>
		</View>
	);
};
