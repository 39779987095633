import { Icon } from 'components/Icon';
import { TTrackingContentType } from 'config/interfaces';
import { EDefaultIconSet } from 'helper';
import { showToast } from 'helper/toast';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ChildButton } from '.';
import { useTranslation } from 'react-i18next';

interface IFavoriteButton {
	testID: string;
	type: string;
	id: number | string;
	size?: 'detail' | 'grid';
}

export const FavoriteButton = (props: IFavoriteButton) => {
	const { testID, type, id, size } = props;
	const { theme } = useTheme();
	const { trackAction } = useTracker();
	const { t } = useTranslation();

	const [isFavorite, setIsFavorite] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const netInfoState = useSelector((store: IRootState) => store.temp.netInfoState);

	const toggleFavorite = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.toggleFavorite);

	useEffect(() => {
		let _isFavorite: typeof isFavorite = false;

		if (profile?.favorites && profile.favorites[type] && profile.favorites[type][id]) {
			_isFavorite = true;
			if (profile.favorites[type][id] !== isFavorite) {
				setIsLoading(false);
			}
		}

		setIsFavorite(_isFavorite);
	}, [type, id, profile]);

	const _toggleFavorite = async () => {
		if (!netInfoState?.isConnected) {
			showToast('error', 'Error', t('NoNetworkConnection'));
			return;
		}

		setIsLoading(true);
		const res = await toggleFavorite({ type, id });
		setIsLoading(false);

		if (res !== undefined) {
			trackAction(type as TTrackingContentType, res ? 'Add to Favorite' : 'Remove from Favorites', `${id}`);
		}
	};

	const _getSize = () => {
		switch (size) {
			case 'grid':
				return 20;
			case 'detail':
			default:
				return 25;
		}
	};

	return (
		<ChildButton
			testID={testID}
			onPress={_toggleFavorite}
			isDisabled={!profile}
			isLoading={isLoading}
			style={{ justifyContent: 'center' }}
		>
			<Icon name={isFavorite ? EDefaultIconSet.Star : EDefaultIconSet.StarOutline} size={_getSize()} color={theme.primary} />
		</ChildButton>
	);
};
