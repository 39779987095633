import { ChildButton } from 'components/Button';
import { PoweredByHelloSpaces } from 'components/HelloSpaces';
import { Icon } from 'components/Icon';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Text } from 'components/Text';
import { MAXTABCOUNT } from 'components/Forms/Space/SpaceWizard';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, getSettingsForNavigationKey, isEmptyString, openURL } from 'helper';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';

import { TabbarBadge } from 'components/Navigation/TabbarBadge';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useUnreadCount } from 'hooks/useUnreadCount';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
interface IMoreBottomView {
	onPress: () => void;
}

interface IMoreListEntry {
	key: string;
	label?: string;
	icon: string;
	contentType?: 'iFrame' | 'markdown' | 'externalUrl' | 'schedules' | 'expos' | 'speakers' | 'mediaitems' | 'attendees';
}

export const MoreBottomView = (props: IMoreBottomView) => {
	const { onPress } = props;
	const { t } = useTranslation();
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { activeSpace, iAmSpaceAdmin } = useSpace();

	const [data, setData] = useState<IMoreListEntry[]>([]);

	const { counter: pendingMeetingsCount } = useUnreadCount('meeting');
	const { counter: unreadSupportRequests } = useUnreadCount('supportrequest');
	const { counter: unreadChatCount } = useUnreadCount('chat');
	const { counter: unreadFeedCount } = useUnreadCount('feed');
	const { counter: liveScheduleEntries } = useUnreadCount('schedule');
	const { counter: activeVotings } = useUnreadCount('vote');
	const { counter: pendingFeedAndCommentCount } = useUnreadCount('feedandcomment');

	const needsLeftPadding = pendingFeedAndCommentCount > 0 && data.filter((item) => item.key === 'feed').length > 0;

	const jwt = useSelector((store: IRootState) => store.auth.jwt);

	useEffect(() => {
		let _data: typeof data = [];

		if (activeSpace?.features) {
			const features = activeSpace.features.list.filter((e) => e.isActive);
			let start = activeSpace.features.tabbarCount ?? MAXTABCOUNT;

			for (let i = start; i < features.length; i++) {
				const { label, icon } = _getLabelAndIcon(features[i].key);
				_data.push({
					key: features[i].key,
					label,
					icon,
					contentType: features[i].contentType
				});
			}
			if (jwt && iAmSpaceAdmin) {
				_data.push({
					key: 'administrate',
					label: t('Administrate'),
					icon: EDefaultIconSet.Settings
				});
			}
		}

		setData(_data);
	}, [activeSpace, iAmSpaceAdmin]);

	const _getLabelAndIcon = (key: string) => {
		const feature = activeSpace?.features?.list.find((e) => e.key === key);
		const { label, icon } = getSettingsForNavigationKey(key, feature);

		return {
			label,
			icon
		};
	};

	const _handlePress = (key: string) => {
		const feature = activeSpace?.features?.list.find((e) => e.key === key);

		const params = {
			spaceId: activeSpace?.spaceId
		};

		const { route } = getSettingsForNavigationKey(key, feature);

		switch (feature?.contentType) {
			case 'externalUrl':
				if (feature.externalUrl && !isEmptyString(feature.externalUrl)) {
					openURL(feature.externalUrl);
				}
				return;
			case 'schedules':
			case 'expos':
			case 'speakers':
			case 'mediaitems':
			case 'attendees':
			case 'iFrame':
			case 'votings':
			case 'markdown':
				params['key'] = feature.key;
				break;
			default:
				switch (key) {
					case 'sponsors':
						params['isSponsors'] = true;
						break;
					default:
						params['key'] = feature?.key;
						break;
				}
				break;
		}

		if (route) {
			if (route === ERoutes.Chat) {
				navigation.navigate(ERoutes.ChatOverview, params);
			} else {
				navigation.navigate(route, params);
			}
		}

		onPress();
	};

	const _renderLeftBadge = (key: string) => {
		let badge = 0;

		switch (key) {
			case 'feed':
				badge = pendingFeedAndCommentCount;
				break;
			default:
				break;
		}

		return <TabbarBadge top={-5} left={-5} value={badge} />;
	};

	const _renderBadge = (key: string) => {
		let badge = 0;

		switch (key) {
			case 'schedules':
				badge = liveScheduleEntries;
				break;
			case 'speakers':
				break;
			case 'votings':
				badge = activeVotings;
				break;
			case 'expos':
				break;
			case 'feed':
				badge = unreadFeedCount;
				break;
			case 'administrate':
				badge = unreadSupportRequests;
				break;
			case 'attendees':
				break;
			case 'chats':
				badge = unreadChatCount;
				break;
			case 'meetings':
				badge = pendingMeetingsCount;
				break;
			case 'networking':
				break;
			case 'mediaitems':
				break;
		}

		return <TabbarBadge top={-5} right={-10} value={badge} />;
	};

	const _renderItem = (item) => {
		return (
			<ChildButton
				testID={`more_button_${item.key}`}
				style={{
					position: 'relative',
					flexDirection: 'row',
					alignItems: 'center',
					overflow: 'visible',
					paddingVertical: 5,
					height: 40
				}}
				onPress={() => _handlePress(item.key)}
			>
				<View
					style={{
						position: 'relative',
						overflow: 'visible',
						width: 40,
						alignItems: 'center',
						marginLeft: needsLeftPadding ? 5 : 0
					}}
				>
					<Icon name={item.icon} size={30} color={theme.navigationTextColor ?? theme.gray ?? theme.text} />
					{_renderLeftBadge(item.key)}
					{_renderBadge(item.key)}
					{item.contentType === 'externalUrl' && (
						<Icon
							testID={`more_button_${item.key}_external`}
							name={EDefaultIconSet.ArrowUpRight}
							size={15}
							containerStyle={{ position: 'absolute', top: -4, right: -8 }}
							color={theme.danger}
						/>
					)}
				</View>
				<View style={{ flex: 1, marginLeft: 12 }}>
					<Text
						style={{ fontSize: 18, color: theme.navigationTextColor ?? theme.gray ?? theme.text }}
						numberOfLines={2}
						adjustsFontSizeToFit
					>
						{item.label}
					</Text>
				</View>
			</ChildButton>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<FlatList
				testID={`more_scrollview`}
				data={data}
				keyExtractor={(item) => `more_${item.key}`}
				renderItem={({ item }) => _renderItem(item)}
				style={{ marginHorizontal: -EHorizontalScreenPadding.Wide }}
				contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
			/>
			<PoweredByHelloSpaces />
		</View>
	);
};
