import React from 'react';
import { View, ViewStyle } from 'react-native';
import { HuePicker } from './HuePicker';
import { SaturationValuePicker } from './SaturationValuePicker';

interface IHsvColorPicker {
	containerStyle?: ViewStyle;
	hue: number;
	saturation: number;
	value: number;
	onHuePickerDragStart?: Function;
	onHuePickerDragMove: Function;
	onHuePickerDragEnd?: Function;
	onHuePickerPress: Function;
	onSatValPickerDragStart?: Function;
	onSatValPickerDragMove: Function;
	onSatValPickerDragEnd?: Function;
	onSatValPickerPress: Function;
	saturationValuePickerSize?: number;
}

export const HsvColorPicker = (props: IHsvColorPicker) => {
	const {
		containerStyle,
		hue,
		saturation,
		value,
		onHuePickerDragStart,
		onHuePickerDragMove,
		onHuePickerDragEnd,
		onHuePickerPress,
		onSatValPickerDragStart,
		onSatValPickerDragMove,
		onSatValPickerDragEnd,
		onSatValPickerPress,
		saturationValuePickerSize
	} = props;

	return (
		<View
			style={[{ position: 'relative', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }, containerStyle]}
		>
			<SaturationValuePicker
				hue={hue}
				saturation={saturation}
				value={value}
				onDragStart={onSatValPickerDragStart}
				onDragMove={onSatValPickerDragMove}
				onDragEnd={onSatValPickerDragEnd}
				onPress={onSatValPickerPress}
				saturationValuePickerSize={saturationValuePickerSize}
			/>
			<HuePicker
				hue={hue}
				saturation={saturation}
				value={value}
				onDragStart={onHuePickerDragStart}
				onDragMove={onHuePickerDragMove}
				onDragEnd={onHuePickerDragEnd}
				onPress={onHuePickerPress}
				barHeight={saturationValuePickerSize}
			/>
		</View>
	);
};
