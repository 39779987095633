import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ISupportRequest } from 'config/interfaces';
import { FlatList, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { NoData } from 'components/NoData';
import { FullScreenLoading } from 'components/Loading';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsTopScreenPadding } from 'config/styleConstants';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet } from 'helper';
import { SupportRequestListItem } from 'components/SupportRequest';
import { CardSeparationHeader } from 'components/Text';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SupportRequestList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SupportRequestList>;
type RouteParams = StackParamList[ERoutes.SupportRequestList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'supportrequestlist';

export const SupportRequestListScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [openSupportRequests, setOpenSupportRequests] = useState<ISupportRequest[]>([]);
	const [closedSupportRequests, setClosedSupportRequests] = useState<ISupportRequest[]>([]);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

	const supportRequests = useSelector((store: IRootState) => store.attendee.supportRequests);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		const open = supportRequests?.filter((e) => e.spaceId === activeSpace?.spaceId && e.status === 'open');
		const closed = supportRequests?.filter((e) => e.spaceId === activeSpace?.spaceId && e.status === 'closed');

		setOpenSupportRequests(open);
		setClosedSupportRequests(closed);
	}, [supportRequests, activeSpace]);

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected?.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		showAlert({
			title: t('ConfirmDeleteCount').replace('%COUNT%', itemId ? '1' : selectedEntries?.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'attendeesupportrequest' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.SupportRequest, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			default:
				break;
		}
	};

	const _renderSelectAll = () => {
		const allRequests = openSupportRequests.concat(closedSupportRequests);
		if (isEditMode && allRequests.length > 0) {
			return (
				<HSCard>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedEntries.length >= allRequests.length}
						onPress={() => {
							if (selectedEntries.length >= allRequests.length) {
								setSelectedEntries([]);
							} else {
								setSelectedEntries(allRequests.map((e) => e.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (waitingForSocketResponse?.attendeesupportrequests) {
			return <FullScreenLoading />;
		}

		if (openSupportRequests?.length + closedSupportRequests?.length === 0) {
			return <NoData type="NoSupportRequests" />;
		}

		return (
			<View>
				{openSupportRequests?.length > 0 && (
					<>
						<FlatList
							data={openSupportRequests}
							ListHeaderComponent={<CardSeparationHeader label={t('OpenSupportRequests')} />}
							renderItem={({ item }) => (
								<SupportRequestListItem
									onPress={() => {
										if (isEditMode) {
											_handleSelect(item.id);
										} else {
											navigation.navigate(ERoutes.SupportRequest, { spaceId: activeSpace?.spaceId, id: item.id });
										}
									}}
									testID={`${TESTIDPREFIX}_supportrequest_${item.id}`}
									item={item}
									isSelected={selectedEntries.includes(item.id)}
									onSelect={isEditMode ? () => _handleSelect(item.id) : undefined}
									onDelete={isEditMode ? () => _delete(item.id) : undefined}
									onEdit={
										isEditMode
											? () =>
													navigation.navigate(ERoutes.SupportRequest, {
														spaceId: activeSpace?.spaceId,
														id: item.id
													})
											: undefined
									}
								/>
							)}
							keyExtractor={(item) => `supportrequest_item_${item.id}`}
						/>
					</>
				)}
				{closedSupportRequests?.length > 0 && (
					<>
						<FlatList
							data={closedSupportRequests}
							ListHeaderComponent={<CardSeparationHeader label={t('ClosedSupportRequests')} />}
							renderItem={({ item }) => (
								<SupportRequestListItem
									onPress={() =>
										navigation.navigate(ERoutes.SupportRequest, { spaceId: activeSpace?.spaceId, id: item.id })
									}
									testID={`${TESTIDPREFIX}_supportrequest_${item.id}`}
									item={item}
									isSelected={selectedEntries.includes(item.id)}
									onSelect={isEditMode ? () => _handleSelect(item.id) : undefined}
									onDelete={isEditMode ? () => _delete(item.id) : undefined}
									onEdit={
										isEditMode
											? () =>
													navigation.navigate(ERoutes.SupportRequest, {
														spaceId: activeSpace?.spaceId,
														id: item.id
													})
											: undefined
									}
								/>
							)}
							keyExtractor={(item) => `supportrequest_item_${item.id}`}
						/>
					</>
				)}
			</View>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="administrate">
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderSelectAll()}
				{_renderContent()}
			</ScrollView>
			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					isDisabled={isDeleteLoading}
					onPress={() => _handleActionSheetPress('add')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_editmode`}
					icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
					label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
					isDisabled={isDeleteLoading}
					onPress={() => _handleActionSheetPress('editMode')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
					isDisabled={selectedEntries.length === 0}
					onPress={() => _handleActionSheetPress('delete')}
					isLoading={isDeleteLoading}
					iconColor={theme.danger}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const SupportRequestListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const { t }: { t: any } = useTranslation();

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={t('SupportRequests')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
