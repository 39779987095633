import { createModel } from '@rematch/core';
import { DISPLAY_TOAST_ON_CONNECTION_CHANGE, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import { IS_WEB } from 'helper';
import { showToast } from 'helper/toast';
import { ICloseLauncherEventListenerParams, IEmitToLauncherBackendParams, IInitMultiSpaceSocket } from 'rematch/interfaces';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { RootModel } from './index';
import { Alert } from 'react-native';
import * as Sentry from 'sentry-expo';

type IDefaultState = {
	multiSpaceSocket?: Socket<DefaultEventsMap, DefaultEventsMap>;
	isMultiSpaceSocketConnected: boolean;
	initiallySetMultispace: boolean;
};

export const socket = createModel<RootModel>()({
	state: {
		multiSpaceSocket: undefined,
		isMultiSpaceSocketConnected: false,
		initiallySetMultispace: false
	} as IDefaultState,
	reducers: {
		setMultiSpaceSocket(state, multiSpaceSocket: IDefaultState['multiSpaceSocket']) {
			return {
				...state,
				multiSpaceSocket
			};
		},
		setIsMultiSpaceSocketConnected(state, isMultiSpaceSocketConnected: boolean) {
			return {
				...state,
				isMultiSpaceSocketConnected,
				initiallySetMultispace: true
			};
		},
		resetInitialSet(state) {
			return {
				...state,
				initiallySetMultispace: false
			};
		}
	},
	effects: (dispatch) => ({
		async initMultiSpaceSocket(payload: IInitMultiSpaceSocket, store) {
			try {
				const { space } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = space ?? activeSpace;

				if (!store.socket.multiSpaceSocket) {
					const mySocket = io(MULTISPACEURL, {
						upgrade: false,
						transports: ['websocket'],
						reconnection: true,
						reconnectionAttempts: Infinity,
						reconnectionDelay: 1000
					});

					mySocket.on('connect', async () => {
						await dispatch.socket.shouldDisplayMultispaceToast({ isConnected: true });
						dispatch.socket.setIsMultiSpaceSocketConnected(true);
						mySocket.emit(IS_WEB ? 'joinSpaceWeb' : 'joinSpace', {
							spaceId: currentSpace?.spaceId
						});
					});

					mySocket.on('disconnect', async () => {
						await dispatch.socket.shouldDisplayMultispaceToast({ isConnected: false });
						dispatch.socket.setIsMultiSpaceSocketConnected(false);
						dispatch.temp.clearHasLoadedDataAndWaitingForSocketResponse();
					});

					dispatch.socket.setMultiSpaceSocket(mySocket);
				}
			} catch (error) {
				console.log('initMultiSpaceSocket', error);
				dispatch.socket.setMultiSpaceSocket(undefined);
				dispatch.socket.setIsMultiSpaceSocketConnected(false);
			}
		},
		async shouldDisplayMultispaceToast(payload, store) {
			const { isConnected } = payload;

			if (DISPLAY_TOAST_ON_CONNECTION_CHANGE && store.socket.initiallySetMultispace) {
				showToast(
					isConnected ? 'connectionEstablished' : 'connectionLost',
					isConnected ? 'MultiSpace Connected' : 'MultiSpace Disconnected'
				);
			}
		},
		async closeMultiSpaceSocket(payload, store) {
			try {
				if (store.socket.multiSpaceSocket) {
					store.socket.multiSpaceSocket.close();
					dispatch.socket.setMultiSpaceSocket(undefined);
					dispatch.socket.setIsMultiSpaceSocketConnected(false);
				}
			} catch (error) {
				console.log('closeMultiSpaceSocket', error);
			}
		},
		async emitToMultiSpaceBackend(payload: IEmitToLauncherBackendParams, store) {
			try {
				const { event, data } = payload;

				if (store.socket.multiSpaceSocket) {
					store.socket.multiSpaceSocket.emit(event, data);
				} else {
					errorLog('no multispace socket', event);
				}
			} catch (error) {
				console.log('emitToMultiSpaceBackend', error);
			}
		},
		async closeMultiSpaceEventListener(payload: ICloseLauncherEventListenerParams, store) {
			try {
				const { event } = payload;

				if (store.socket.multiSpaceSocket) {
					store.socket.multiSpaceSocket.off(`${ENVIRONMENT}_${event}`);
				}
			} catch (error) {
				console.log('closeMultiSpaceEventListener', error);
			}
		}
	})
});
