import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Flag from 'react-native-flags';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';

import { ChildButton } from 'components/Button';
import { ISchedule, IScheduleStatus, ISpeaker, IStream, IUserBooking } from 'config/interfaces';
import { EDefaultIconSet, getAgendaSpeakerString, isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { GRID_SCHEDULE_BORDER_RADIUS, GRID_SCHEDULE_LEFT_BORDER_WIDTH } from '../constants';
import { Text } from 'components/Text';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { AnimatedLive } from 'components/Animated';
import { useSpace } from 'hooks/useSpace';
import { InfoPill } from 'components/InfoPill';
import { Icon } from 'components/Icon';
import { hsBottomMargin } from 'config/styleConstants';

interface IListItem {
	item: ISchedule;
	onPress?: () => void;
	backgroundColor?: string;
	isPublic?: boolean;
}

export const SCHEDULELISTITEMHEIGHT = 160;

export const ScheduleItem = (props: IListItem) => {
	const { item, onPress, backgroundColor, isPublic } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [speakers, setSpeakers] = useState<ISpeaker[]>([]);
	const [scheduleStatus, setScheduleStatus] = useState<IScheduleStatus | undefined>(undefined);
	const [isBooked, setIsBooked] = useState<boolean>(false);
	const [streams, setStreams] = useState<IStream[]>([]);
	const [bookedSlots, setBookedSlots] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);
	const bookings = useSelector((store: IRootState) => store.booking.bookings);
	const allBookingCounts = useSelector((store: IRootState) => store.booking.bookingCounts);

	useEffect(() => {
		let _isBooked = false;

		if (activeSpace && bookings) {
			_isBooked = bookings.find((e) => e.spaceId === activeSpace.spaceId && e.itemId === item?.id && item?.isBookable) !== undefined;
		}

		setIsBooked(_isBooked);
	}, [activeSpace, bookings]);

	useEffect(() => {
		let _bookedSlots = 0;

		if (activeSpace && item.isBookable && allBookingCounts) {
			_bookedSlots = allBookingCounts.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === item.id)?.length ?? 0;
		}

		if (_bookedSlots !== bookedSlots) {
			setBookedSlots(_bookedSlots);
		}
	}, [activeSpace, item, allBookingCounts]);

	useEffect(() => {
		let _speakers: typeof speakers = [];
		let _scheduleStatus: typeof scheduleStatus = undefined;
		let _streams: typeof streams = [];

		if (isPublic) {
			if (item.speakers) {
				_speakers = item.speakers;

				if (item.status) {
					_scheduleStatus = item.status;
				}
			}
		} else {
			if (content.speakers && item.speakers) {
				item.speakers.forEach((speaker) => {
					const _sp = content.speakers.find((sp) => sp.id === speaker.id);
					if (_sp) {
						_speakers.push(_sp);
					}
				});

				_speakers.sort((a, b) => {
					const aSplit = a.title.split(' ');
					const bSplit = b.title.split(' ');

					const aVal = aSplit[aSplit.length - 1] + aSplit[0];
					const bVal = bSplit[bSplit.length - 1] + bSplit[0];

					return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
				});
			}

			if (content.schedulestatuses) {
				_scheduleStatus = content.schedulestatuses.find((e) => e.schedule?.id === item.id);
			}

			if (content.streams && item.stream) {
				if (Array.isArray(item.stream)) {
					item.stream.forEach((stream) => {
						const _st = content.streams.find((st) => st.id === stream.id);
						if (_st) {
							_streams.push(_st);
						}
					});
				} else if (typeof item.stream === 'number') {
					const _st = content.streams.find((st) => st.id === item.stream);
					if (_st) {
						_streams.push(_st);
					}
				} else {
					const _st = content.streams.find((st) => st.id === item.stream?.id);
					if (_st) {
						_streams.push(_st);
					}
				}
			}
		}

		setSpeakers(_speakers);
		setStreams(_streams);
		setScheduleStatus(_scheduleStatus);
	}, [item, content]);

	const _renderSpeakers = () => {
		if (speakers.length > 0) {
			const elements: JSX.Element[] = [];
			const unhandledSpeakers: ISpeaker[] = [];

			if ((item.speakerGroups?.groups ?? []).length > 0) {
				item.speakerGroups?.groups.forEach((group) => {
					const _groupSpeakers = speakers.filter((e) => group.speakerIds.includes(e.id));
					const _speakerStrings = _groupSpeakers.map((e) => getAgendaSpeakerString(e));

					const textStyle = { fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text };

					elements.push(
						<Text key={`speakerstring_${group.key}`} bold style={textStyle}>
							{`${group.title}: `}
							<Text style={textStyle}>{_speakerStrings.join(', ')}</Text>
						</Text>
					);
				});
			}

			speakers.forEach((speaker) => {
				const foundInGroup = item.speakerGroups?.groups.find((e) => e.speakerIds.includes(speaker.id));
				if (!foundInGroup) {
					unhandledSpeakers.push(speaker);
				}
			});

			if (unhandledSpeakers.length > 0) {
				const _speakerStrings = unhandledSpeakers.map((e) => getAgendaSpeakerString(e));
				elements.push(
					<Text
						key={`speakerstring_${item.id}`}
						style={{ fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text }}
					>
						{_speakerStrings.join(', ')}
					</Text>
				);
			}

			return <View style={{ marginTop: 10 }}>{elements}</View>;
		}

		return null;
	};

	const _renderBooking = () => {
		if (!item.bookingCapacity) {
			return null;
		}

		if (isBooked) {
			return (
				<InfoPill
					text={t('Booked')}
					backgroundColor={theme.success}
					textColor={theme.white}
					containerStyle={{ marginLeft: 10, marginBottom: -5 }}
				/>
			);
		}

		if (item?.isBookable) {
			if (item?.bookingCapacity !== undefined && item?.bookingCapacity !== null && bookedSlots >= item.bookingCapacity) {
				return (
					<InfoPill
						text={t('Fully booked')}
						borderColor={theme.danger}
						textColor={!isEmptyString(item?.textColor) ? item.textColor : theme.danger}
						containerStyle={{ marginLeft: 10, marginBottom: -5 }}
					/>
				);
			}

			const bookingPercent = bookedSlots / item.bookingCapacity;
			let pillColor = !isEmptyString(item?.textColor) ? item.textColor : theme.text;

			if (bookingPercent <= 0.25) {
				pillColor = theme.warning;
			}

			return (
				<InfoPill
					text={t('Bookable')}
					borderColor={pillColor}
					textColor={pillColor}
					containerStyle={{ marginLeft: 10, marginBottom: -5 }}
				/>
			);
		}

		return null;
	};

	const _renderStatus = () => {
		if (scheduleStatus) {
			if (scheduleStatus.status === 'Finished' || (scheduleStatus.status !== 'Replay' && moment().isAfter(item.endDate))) {
				return null;
			}

			return (
				<InfoPill
					text={t(scheduleStatus.status)}
					borderColor={!isEmptyString(item?.textColor) ? item.textColor : theme.text}
					textColor={!isEmptyString(item?.textColor) ? item.textColor : theme.text}
					containerStyle={{ marginBottom: -5 }}
				/>
			);
		}

		return null;
	};

	const _renderLanguages = () => {
		let _languages: string[] = [];

		if (item.languages) {
			const _itemLanguages = item.languages.split(',');
			_languages = _languages.concat(_itemLanguages);
		} else {
			streams.forEach((s) => {
				if (s.languages && !isEmptyString(s.languages)) {
					const _streamLanguages = s.languages.split(',');
					_languages = _languages.concat(_streamLanguages);
				}
			});
		}

		if (_languages.length > 0) {
			return (
				<View style={{ flexDirection: 'row' }}>
					{_languages.map((flag) => (
						<View key={`list_schedule_${item.id}_flag_${flag}`} style={{ paddingRight: 2 }}>
							<Flag code={flag} size={16} />
						</View>
					))}
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			key={`list_schedule_${item.id}`}
			testID={`list_schedule_${item.id}`}
			activeOpacity={1}
			style={{
				minHeight: SCHEDULELISTITEMHEIGHT,
				paddingBottom: hsBottomMargin / 2
			}}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View
				style={{
					flex: 1,
					opacity:
						moment().isSameOrAfter(item?.endDate) && scheduleStatus?.status !== 'Live' && scheduleStatus?.status !== 'Replay'
							? 0.6
							: 1
				}}
			>
				<View
					style={{
						flex: 1,
						paddingVertical: 5,
						paddingHorizontal: 10,
						backgroundColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: theme.contentBackgroundColor ?? theme.background,
						borderWidth: 1,
						borderLeftWidth: GRID_SCHEDULE_LEFT_BORDER_WIDTH,
						borderRadius: GRID_SCHEDULE_BORDER_RADIUS,
						borderColor: theme.lightgray,
						borderLeftColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: !isEmptyString(backgroundColor)
							? backgroundColor
							: theme.primary
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 5
						}}
					>
						<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
							<Text
								style={{
									color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
									fontSize: 10,
									marginRight: 10
								}}
							>{`${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('HH:mm')}`}</Text>
							{scheduleStatus?.status === 'Live' ? (
								<AnimatedLive isVisible={scheduleStatus?.status === 'Live'} containerStyle={{ position: 'relative' }} />
							) : (
								_renderStatus()
							)}
							{_renderBooking()}
						</View>
						{!isPublic && !item.isBookable && (
							<FavoriteButton testID={`listitemcell_${item.id}_button_favorite`} type="schedule" id={item.id} size="grid" />
						)}
					</View>
					<View style={{ flexDirection: 'row' }}>
						<Text
							bold
							numberOfLines={2}
							style={{
								color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
								marginRight: item.isHidden ? 5 : undefined
							}}
						>
							{item.title}
						</Text>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					{_renderLanguages()}
					{!isEmptyString(item.subtitle) && (
						<Text
							numberOfLines={2}
							style={{ marginTop: 5, color: !isEmptyString(item.textColor) ? item.textColor : theme.text, fontSize: 10 }}
						>
							{item.subtitle}
						</Text>
					)}
					{_renderSpeakers()}
					{item.stage && (
						<Text
							numberOfLines={1}
							style={{
								color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
								fontSize: 10,
								marginBottom: 5,
								marginTop: 5
							}}
							bold
						>
							{`${item.stage.title}`} {item.stage.subtitle ? ` - ${item.stage.subtitle}` : null}
						</Text>
					)}
				</View>
			</View>
		</ChildButton>
	);
};
