export const byOrder = (a, b) => {
	if (!isNaN(Number(a.order)) && !isNaN(Number(b.order))) {
		const aVal = Number(a.order) ? Number(a.order) : 9999999999999;
		const bVal = Number(b.order) ? Number(b.order) : 9999999999999;
		return aVal < bVal ? -1 : 1;
	}
	const aVal = a.order ? a.order.toString().toLowerCase() : '';
	const bVal = b.order ? b.order.toString().toLowerCase() : '';
	return aVal < bVal ? -1 : 1;
};
