import { IFormBase } from 'config/interfaces/form';
import React from 'react';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';
import { FormField } from './FormField';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';

export interface IKVP {
	key: string | null;
	label: string;
	backgroundColor?: string;
	textColor?: string;
	isDisabled?: boolean;
	avatar?: string;
}

interface IFormMultiSwitchComponent extends IFormBase {
	testID: string;
	value?: string;
	options?: IKVP[];
	onChange?: (val: IKVP['key']) => void;
	formStyle?: ViewStyle;
	size?: 'xs' | 'default';
}

export const FormMultiSwitch = (props: IFormMultiSwitchComponent) => {
	const { testID, label, isRequired, hint, error, value, isDisabled, onChange, options, formStyle, size } = props;
	const { theme } = useTheme();

	const _getSize = (type: 'paddingHorizontal' | 'margins' | 'paddingVertical' | 'fontSize') => {
		switch (type) {
			case 'paddingHorizontal':
				switch (size) {
					case 'xs':
						return 5;
					default:
						return 10;
				}
			case 'margins':
				switch (size) {
					case 'xs':
						return 5;
					default:
						return 10;
				}
			case 'paddingVertical':
				switch (size) {
					case 'xs':
						return 2;
					default:
						return 5;
				}
			case 'fontSize':
				switch (size) {
					case 'xs':
						return 10;
					default:
						return 14;
				}
			default:
				break;
		}
	};

	const _renderOption = (option: IKVP) => {
		const _isActive = value === option.key;
		const _isDisabled = isDisabled || option?.isDisabled;
		const _isLastItem = option.key === (options ? options[options?.length - 1].key : '');

		return (
			<ChildButton
				key={`${testID}_option_${option.key}`}
				testID={`${testID}_option_${option.key}`}
				isDisabled={_isDisabled}
				style={[
					{
						marginRight: _isLastItem ? 5 : _getSize('margins'),
						marginBottom: _getSize('margins'),
						backgroundColor: _isActive ? option.backgroundColor ?? theme.primary : _isDisabled ? theme.lightgray : undefined,
						paddingHorizontal: _getSize('paddingHorizontal'),
						paddingVertical: _getSize('paddingVertical'),
						borderRadius: 999
					},
					!_isActive ? { borderWidth: 1, borderColor: option.backgroundColor ?? theme.lightgray } : null
				]}
				onPress={() => {
					if (onChange) {
						onChange(option.key);
					}
				}}
			>
				<Text
					style={{
						color: _isActive ? option.textColor ?? theme.primaryContrast : option.backgroundColor ?? theme.text,
						fontSize: _getSize('fontSize')
					}}
				>
					{option.label}
				</Text>
			</ChildButton>
		);
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<View
				style={{
					flexWrap: 'wrap',
					flexDirection: 'row',
					alignItems: 'center'
				}}
			>
				{options?.map((option) => _renderOption(option))}
			</View>
		</FormField>
	);
};
