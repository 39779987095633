import React from 'react';
import { ChildButton } from 'components/Button';
import { IMedia } from 'config/interfaces';
import { Alert, View, ViewStyle } from 'react-native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useTheme } from 'hooks/useTheme';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { FORMELEMENTBORDERWIDTH, FormElementBottomMargin } from '../constants';
import { EDefaultIconSet } from 'helper';
import { Avatar, TAvatarSize } from 'components/User/Avatar';
import { FormHint } from '../FormHint';
import { FormError } from '../FormError';

interface IFormAvatarPicker {
	testID: string;
	value?: IMedia | string | null;
	hint?: string;
	error?: string;
	fullName: string;
	onChange: (value: IMedia | string | undefined | null) => void;
	size?: TAvatarSize;
	containerStyle?: ViewStyle;
	useModal?: boolean;
}

export const FormAvatarPicker = (props: IFormAvatarPicker) => {
	const { testID, value, fullName, onChange, size, containerStyle, hint, error, useModal } = props;
	const { showActionSheetWithOptions } = useActionSheet();
	const { t } = useTranslation();

	const { theme } = useTheme();

	const _renderValue = () => {
		let url;
		if (value) {
			if (typeof value === 'string') {
				url = value;
			} else {
				url = value.url;
			}
		}

		return <Avatar avatar={url} fullName={fullName} size={size ?? 'xxl'} />;
	};

	const _handleImageChange = (newValue) => {
		if (value) {
			if (typeof value === 'string') {
				onChange(newValue);
			} else {
				onChange({ ...value, url: newValue });
			}
		} else {
			onChange(newValue);
		}
	};

	const _handlePress = () => {
		showActionSheetWithOptions(
			{
				useModal: useModal ? useModal : false,
				options: value
					? [t('Remove Profile Image'), t('Take Profile Image'), t('Select Profile Image'), t('Cancel')]
					: [t('Take Profile Image'), t('Select Profile Image'), t('Cancel')],
				destructiveButtonIndex: value ? 0 : undefined,
				cancelButtonIndex: value ? 3 : 2
			},
			_handleActionSheetPress
		);
	};

	const _handleActionSheetPress = (index: number) => {
		if (value) {
			switch (index) {
				case 0:
					onChange(null);
					break;
				case 1:
					_takeImage();
					break;
				case 2:
					_pickImage();
					break;
				default:
					break;
			}
		} else {
			switch (index) {
				case 0:
					_takeImage();
					break;
				case 1:
					_pickImage();
					break;
				default:
					break;
			}
		}
	};

	const _takeImage = async () => {
		const { status } = await ImagePicker.requestCameraPermissionsAsync();
		if (status !== ImagePicker.PermissionStatus.GRANTED) {
			Alert.alert(t('MissingCameraPermissionTitle', t('MissingPermissionSubtitle')));
			return;
		}

		const imageTaken = await ImagePicker.launchCameraAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0.4
		});

		if (!imageTaken.canceled) {
			const manipulationResult = await _manipulateImage(imageTaken.assets[0].uri);
			_handleImageChange(manipulationResult.uri);
		}
	};

	const _pickImage = async () => {
		const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
		if (status !== ImagePicker.PermissionStatus.GRANTED) {
			Alert.alert(t('MissingCameraRollPermissionTitle', t('MissingPermissionSubtitle')));
			return;
		}

		const pickedImage = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [1, 1],
			quality: 0.4
		});

		if (!pickedImage.canceled) {
			const manipulationResult = await _manipulateImage(pickedImage.assets[0].uri);
			_handleImageChange(manipulationResult.uri);
		}
	};

	const _manipulateImage = async (imageUri) => {
		return await ImageManipulator.manipulateAsync(imageUri, [{ resize: { width: 300 } }], {
			format: ImageManipulator.SaveFormat.PNG
		});
	};

	const _renderActionButton = () => {
		let icon;
		if (value) {
			icon = EDefaultIconSet.Close;
		} else {
			icon = EDefaultIconSet.Add;
		}

		return (
			<View
				style={{
					position: 'absolute',
					right: '4%',
					top: 0,
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderColor: theme.lightgray,
					borderRadius: 999,
					paddingHorizontal: 3,
					paddingVertical: 2,
					backgroundColor: theme.contentBackgroundColor ?? theme.background
				}}
			>
				<Icon name={icon} />
			</View>
		);
	};

	return (
		<ChildButton
			testID={testID}
			style={{ alignSelf: 'flex-start', position: 'relative', marginBottom: FormElementBottomMargin, ...containerStyle }}
			onPress={_handlePress}
		>
			{_renderValue()}
			<FormHint testID="formavatarpicker_hint" hint={hint} />
			<FormError testID="formavatarpicker_error" error={error} />
			{_renderActionButton()}
		</ChildButton>
	);
};
