import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

interface IIFramePlayer {
	url?: string;
	autoplay?: boolean;
	onPlay?: (e: any) => void;
}

export const IFramePlayer = (props: IIFramePlayer) => {
	const { url, autoplay, onPlay } = props;

	const [containerWidth, setContainerWidth] = useState<number>(1);

	const iframeRef = useRef<null | HTMLIFrameElement>(null);

	const iframeCallbackRef = useCallback((node: null | HTMLIFrameElement): void => {
		iframeRef.current = node;
	}, []);

	useEffect(() => {
		const onBlur = (e) => {
			if (
				document.activeElement &&
				document.activeElement.nodeName.toLowerCase() === 'iframe' &&
				iframeRef.current &&
				iframeRef.current === document.activeElement
			) {
				// infer a click event
				if (onPlay) {
					onPlay(e);
				}
			}
		};

		window.addEventListener('blur', onBlur);

		return () => {
			window.removeEventListener('blur', onBlur);
		};
	}, []);

	const _renderPlayer = () => {
		if (!autoplay) {
			return (
				<iframe
					src={url}
					style={{ width: containerWidth * 0.99, height: (containerWidth / 16) * 9 }}
					allow={'accelerometer; gyroscope; encrypted-media; picture-in-picture'}
					allowFullScreen
					ref={iframeCallbackRef}
				>
					<video src={url} autoPlay={false} width={'100%'} height={'100%'} />
				</iframe>
			);
		}

		return (
			<iframe
				ref={iframeCallbackRef}
				src={url}
				style={{ width: containerWidth * 0.99, height: (containerWidth / 16) * 9 }}
				allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
				allowFullScreen
			/>
		);
	};

	return <View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>{_renderPlayer()}</View>;
};
