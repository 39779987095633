import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Vibration, View } from 'react-native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

import { RoundButton } from 'components/Button';
import { HSDragCard } from 'components/Card';
import { FormError } from 'components/Form/FormError';
import { NoData } from 'components/NoData';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Text } from 'components/Text';
import { QUIZ_QUESTION_DEFAULTSCORE, QUIZ_QUESTION_DEFAULTTIME } from 'components/Vote/ActiveVote/VoteQuiz';
import {
	ICreateVoteFormErrors,
	ICreateVoteFormValues,
	IEditVoteFormErrors,
	IEditVoteFormValues,
	IVoteOption,
	TVotingType
} from 'config/interfaces';
import { EDefaultIconSet, swapArrayItems } from 'helper';
import { IS_ANDROID } from 'helper/platform';
import { useQuery } from 'hooks/useQuery';
import { VoteQuizVoteOptionModal } from 'components/Modal/VoteQuizVoteOptionModal/VoteQuizVoteOptionModal';

interface IVoteQuizOptionsForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export interface IVoteOptionVote {
	id?: number;
	question?: string;
	hint?: string;
	options?: IVoteOption[];
	order?: number;
	timeOut: number;
	score: number;
	type?: TVotingType;
}

export const VoteQuizOptionsForm = (props: IVoteQuizOptionsForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const votes = useSelector((store: IRootState) => store.vote.votes);

	const [optionsVote, setOptionsVote] = useState<IVoteOptionVote[]>([]);
	const [modalVoteIndex, setModalVoteIndex] = useState(-1);
	const [filled, setFilled] = useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	const [tempOptionVote, setTempOptionVote] = useState<IVoteOptionVote>({
		question: '',
		hint: '',
		options: [],
		order: optionsVote?.length ?? 0,
		score: QUIZ_QUESTION_DEFAULTSCORE,
		timeOut: QUIZ_QUESTION_DEFAULTTIME
	});

	const [tempOption, setTempOption] = useState<IVoteOption>({
		label: '', // is used as id here
		description: '', // is used as preview for the quiz vote question here
		order: values.options?.length ?? 0
	});

	useEffect(() => {
		if (values && !filled) {
			const _quizVotes: IVoteOptionVote[] = [];
			values?.options?.forEach((v) => {
				const found = votes.find((vote) => vote.id.toString() === v.label);
				if (found) {
					const obj = {
						id: found.id,
						question: found.question ?? '',
						hint: found.hint ?? '',
						options: found.options ?? [],
						order: found.order ?? 0,
						score: found.score ?? 0,
						timeOut: found.timeOut ?? 0
					};
					_quizVotes.push(obj);
				}
			});
			setFilled(true);
			setOptionsVote([..._quizVotes]);
		}
	}, [values]);

	const _removeOption = (index) => {
		const _optionsVote = values.optionsVote ?? optionsVote ?? [];
		_optionsVote?.splice(index, 1);
		const _options = values.options;
		_options?.splice(index, 1);
		setOptionsVote(_optionsVote);
		onChange({ ...values, options: _options, optionsVote: _optionsVote });
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		let list: IVoteOption[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);
		list = list.map((l, index) => {
			l.order = index;
			return l;
		});
		onChange({ ...values, options: list });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ ...values, options: list });
	};

	const _renderItem = (params: RenderItemParams<IVoteOption>) => {
		const { item, index, drag, isActive } = params;

		if (index !== undefined) {
			return (
				<HSDragCard
					testID={`${testIdPrefix}_option_${index}`}
					isActive={isActive}
					onDrag={drag}
					index={index}
					handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
					onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
					onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
					onEdit={() => {
						setTempOption(values[index]);
						setTempOptionVote(optionsVote[index]);
						setModalVoteIndex(index);
						setIsModalVisible(true);
					}}
					onRemove={() => _removeOption(index)}
				>
					<Text bold style={{ marginBottom: 5 }}>
						{item.description}
					</Text>
					{optionsVote[index] && (
						<>
							<Text>
								{`${t('timeOut')}: `}
								<Text bold>{`${optionsVote[index].timeOut} ${t('Seconds')}`}</Text>
							</Text>
							<Text>
								{`${t('Points')}: `}
								<Text bold>{optionsVote[index].score}</Text>
							</Text>
						</>
					)}
				</HSDragCard>
			);
		}

		return null;
	};

	return (
		<View style={{ flex: 1 }}>
			<DraggableFlatList
				containerStyle={{ flex: 1 }}
				contentContainerStyle={{ flexGrow: 1, width: screenWidth, alignSelf: 'center', padding: EHorizontalScreenPadding.Wide }}
				data={values.options ?? []}
				renderItem={(params) => _renderItem(params)}
				ListEmptyComponent={() => {
					return <NoData type="NoQuizOptions" />;
				}}
				ListFooterComponent={() => (
					<View style={{ width: '100%' }}>
						<RoundButton
							testID={`${testIdPrefix}_button_add`}
							icon={EDefaultIconSet.Add}
							onPress={() => {
								setIsModalVisible(true);
								setModalVoteIndex(values?.options?.length);
							}}
							isDisabled={(values?.options?.length ?? 0) > 100 || isLoading}
							alignSelf="flex-end"
							title={t('addOptionQuiz')}
						/>
						<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
							<FormError
								testID={`${testIdPrefix}_textinput_multiplechoice_options_error`}
								error={errors['options']?.toString()}
							/>
						</View>
					</View>
				)}
				keyExtractor={(item, index) => `${testIdPrefix}_createMultipleChoiceOption_${index}`}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					data.forEach((opt, index) => (opt.order = index));
					onChange({ ...values, options: data });
				}}
			/>
			<VoteQuizVoteOptionModal
				testID={''}
				isVisible={isModalVisible}
				onClose={() => {
					setIsModalVisible(false);
					setTempOptionVote({
						question: '',
						hint: '',
						options: [],
						order: optionsVote?.length ?? 0,
						score: QUIZ_QUESTION_DEFAULTSCORE,
						timeOut: QUIZ_QUESTION_DEFAULTTIME
					});
					setTempOption({});
					setModalVoteIndex(-1);
				}}
				onSubmit={() => {
					const _options = values.options ?? [];
					const _optionsVote = optionsVote ?? [];
					if (_options[modalVoteIndex]) {
						_options[modalVoteIndex].description = tempOptionVote.question; // is used as preview for the quiz vote question here
						_optionsVote[modalVoteIndex] = tempOptionVote;
					} else {
						_options.push({ ...tempOption, description: tempOptionVote.question });
						_optionsVote.push(tempOptionVote);
					}
					setOptionsVote(_optionsVote);
					onChange({ ...values, options: _options, optionsVote: _optionsVote });
					setIsModalVisible(false);
					setTempOptionVote({
						question: '',
						hint: '',
						options: [],
						order: optionsVote?.length ?? 0,
						score: QUIZ_QUESTION_DEFAULTSCORE,
						timeOut: QUIZ_QUESTION_DEFAULTTIME
					});
					setTempOption({});
					setModalVoteIndex(-1);
				}}
				values={tempOptionVote}
				onChange={(val) => {
					setTempOptionVote(val);
				}}
			/>
		</View>
	);
};
