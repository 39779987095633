import i18next, { TFunctionResult } from 'i18next';
import { setString } from 'expo-clipboard';
import { showToast } from './toast';
import { ISpeaker } from 'config/interfaces';

export const isEmptyString = (str: string | undefined | TFunctionResult) => {
	return !str || (str + '').replace(/\s/g, '') === '';
};

export const addToClipboard = (value: string) => {
	setString(value);
	setTimeout(() => {
		showToast('info', undefined, i18next.t('Added To Clipboard Subtitle').replace('%VALUE%', value));
	}, 150);
};

export const getReversedName = (name: string) => {
	// This reverses the lastName parts
	// e.b van Gaal -> Gaal van
	// This is used to sort names in the correct section (G in this case)
	if (isEmptyString(name)) {
		return '';
	}

	return name.split(' ').reverse().join(' ');
};

export const concatJobtitleAndCompany = (jobTitle?: string, company?: string) => {
	let str = '';

	if (jobTitle && !isEmptyString(jobTitle)) {
		str = jobTitle;
	}

	if (company && !isEmptyString(company)) {
		if (!isEmptyString(jobTitle)) {
			str = `${jobTitle} | ${company}`;
		} else {
			str = company;
		}
	}

	return str;
};

export const getAgendaSpeakerString = (speaker: ISpeaker) => {
	let str = '';

	if (speaker.speakerTitle) {
		str = `${speaker.speakerTitle} `;
	}

	str += speaker.title;

	if (!isEmptyString(speaker.company)) {
		str += ` (${speaker.company})`;
	}

	return str;
};
