import React from 'react';

import {
	AntDesign,
	Entypo,
	Feather,
	FontAwesome,
	MaterialCommunityIcons,
	MaterialIcons,
	Ionicons,
	SimpleLineIcons
} from '@expo/vector-icons';
import { View, ViewStyle } from 'react-native';
import { normalizeFontSize } from 'helper';
import { useTheme } from 'hooks/useTheme';

import AntDesignGlyphs from './glyphmaps/AntDesign.json';
import EntypoGlyphs from './glyphmaps/Entypo.json';
import FeatherGlyphs from './glyphmaps/Feather.json';
import FontAwesomeGlyphs from './glyphmaps/FontAwesome.json';
import IoniconsGlyphs from './glyphmaps/Ionicons.json';
import MaterialCommunityIconsGlyphs from './glyphmaps/MaterialCommunityIcons.json';
import MaterialIconsGlyphs from './glyphmaps/MaterialIcons.json';
import SimpleLineIconsGlyphs from './glyphmaps/SimpleLineIcons.json';
import HelloSpacesGlyphs from './glyphmaps/HelloSpaces.json';
import { createIconSetFromFontello } from '@expo/vector-icons';

interface IIconComponent {
	name: string;
	size?: number;
	color?: string;
	containerStyle?: ViewStyle;
	testID?: string;
}

export const IconGlyphs = {
	SimpleLineIconsGlyphs,
	FeatherGlyphs,
	FontAwesomeGlyphs,
	AntDesignGlyphs,
	EntypoGlyphs,
	IoniconsGlyphs,
	MaterialCommunityIconsGlyphs,
	MaterialIconsGlyphs,
	HelloSpacesGlyphs
};

export const Icon = (props: IIconComponent) => {
	const { name, size, color, containerStyle, testID } = props;
	const { theme } = useTheme();
	try {
		const splitted = name.split(':');
		const _familyName = splitted.length === 2 ? splitted[0] : 'FontAwesome';
		const _iconName: any = splitted.length === 2 ? splitted[1] : splitted[0];
		const _size = size || normalizeFontSize(18);
		const _color = color ?? theme.text;

		const _getIconSizeFactor = () => {
			switch (_familyName) {
				case 'FontAwesome':
					return 0.75;
				case 'SimpleLineIcons':
					return 0.85;
				case 'Ionicons':
					return 0.9;
				default:
					return 1;
			}
		};

		const _renderIcon = () => {
			try {
				switch (_familyName) {
					case 'HelloSpaces':
						const HelloSpacesIcon = createIconSetFromFontello(HelloSpacesGlyphs, 'Fontello', 'Fontello.ttf');

						return <HelloSpacesIcon name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'SimpleLineIcons':
						return <SimpleLineIcons name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'Feather':
						return <Feather name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'AntDesign':
						return <AntDesign name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'Entypo':
						return <Entypo name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'Ionicons':
						return <Ionicons name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'MaterialCommunityIcons':
						return <MaterialCommunityIcons name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					case 'MaterialIcons':
						return <MaterialIcons name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
					default:
						return <FontAwesome name={_iconName} size={_size * _getIconSizeFactor()} color={_color} />;
				}
			} catch (error) {
				console.log('error', error);
				return null;
			}
		};

		return <View style={containerStyle}>{_renderIcon()}</View>;
	} catch (e) {
		return null;
	}
};
