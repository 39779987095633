import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderIconButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { EditSpaceInitialFormValues, IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getEditSpaceDesignFormSchema } from 'config/yupSchemas';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import i18next from 'i18next';
import { Keyboard, ScrollView } from 'react-native';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { EditSpaceTextsForm } from 'components/Forms/Space';
import * as RootNavigation from '../../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditSpaceDesign>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditSpaceDesign>;
type RouteParams = StackParamList[ERoutes.EditSpaceDesign];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editspacetexts';

export const EditSpaceTextsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [formErrors, setFormErrors] = useState<IEditSpaceFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.addListener('focus', () => _updateValues());
		_updateValues();

		return () => navigation.removeListener('focus', () => _updateValues());
	}, []);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateValues();
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading
		});
	}, [formValues, isLoading, isFormLocked]);

	const _updateValues = () => {
		if (activeSpace) {
			setFormValues({
				updated_at: activeSpace.updated_at,
				spaceId: activeSpace.spaceId,
				primaryColor: activeSpace.primaryColor ?? theme.primary,
				primaryContrastColor: activeSpace.primaryContrastColor ?? theme.primaryContrast,
				textColor: activeSpace.textColor ?? theme.text,
				successColor: activeSpace.successColor ?? theme.success,
				dangerColor: activeSpace.dangerColor ?? theme.danger,
				backgroundColor: activeSpace.backgroundColor ?? theme.background,
				contentBackgroundColor: activeSpace.contentBackgroundColor ?? theme.contentBackgroundColor,
				contrastColor: activeSpace.contrastColor ?? theme.contrast,
				grayColor: activeSpace.grayColor ?? theme.gray,
				previewImage: activeSpace.previewImage,
				backgroundImage: activeSpace.backgroundImage,
				logoImage: activeSpace.logoImage,
				previewImageName: activeSpace.previewImageName,
				backgroundImageName: activeSpace.backgroundImageName,
				logoImageName: activeSpace.logoImageName,
				navigationTextColor: activeSpace.navigationTextColor,
				features: activeSpace.features ? JSON.parse(JSON.stringify(activeSpace.features)) : { list: [] }
			});
		}
	};

	const _updateSpace = async () => {
		const errors = await validateForm(getEditSpaceDesignFormSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Space has changed'),
				message: t('SpaceFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsLoading(true);
		const updateSuccess = await updateSpace({ data: formValues });
		setIsLoading(false);
		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _handleUpdate = (values) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(values);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<EditSpaceTextsForm values={formValues} errors={formErrors} onChange={_handleUpdate} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const EditSpaceTextsScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Edit')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
