import { TVotingType } from 'config/interfaces/vote';
import { hsBlue } from 'config/styleConstants';

export interface IRGBA {
	r: number;
	g: number;
	b: number;
	a: number;
}

export interface IHSV {
	hue: number;
	sat: number;
	val: number;
}

export const hexColorRegex = /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i;

export const isDarkColor = (color: string) => {
	color = color?.substring(1); // strip #
	const rgb = parseInt(color, 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

	return luma < 80;
};

export const hsvToRGBA = (h: number, s: number, v: number, a: number) => {
	let r = 0;
	let g = 0;
	let b = 0;

	h = h / 360;
	var i = Math.floor(h * 6);
	var f = h * 6 - i;
	var p = v * (1 - s);
	var q = v * (1 - f * s);
	var t = v * (1 - (1 - f) * s);

	switch (i % 6) {
		case 0:
			(r = v), (g = t), (b = p);
			break;
		case 1:
			(r = q), (g = v), (b = p);
			break;
		case 2:
			(r = p), (g = v), (b = t);
			break;
		case 3:
			(r = p), (g = q), (b = v);
			break;
		case 4:
			(r = t), (g = p), (b = v);
			break;
		case 5:
			(r = v), (g = p), (b = q);
			break;
	}

	const rgba: IRGBA = { r: Math.floor(r * 255), g: Math.floor(g * 255), b: Math.floor(b * 255), a };
	return rgba;
};

export const rgbaToHex = (rgba: IRGBA) => {
	let alpha = ((rgba.a * 255) | (1 << 8)).toString(16).slice(1);
	let hex =
		(rgba.r | (1 << 8)).toString(16).slice(1) + (rgba.g | (1 << 8)).toString(16).slice(1) + (rgba.b | (1 << 8)).toString(16).slice(1);

	return `#${hex}${alpha}`;
};

export const hexToRGBA = (hexstring: string) => {
	let hex = hexstring.replace('#', '');
	const isThreeDigit = hex.length === 3;
	if (isThreeDigit) {
		hex = hex.replace(/./g, '$&$&');
	}
	const rgba: IRGBA = {
		r: parseInt(hex.substring(0, 2), 16),
		g: parseInt(hex.substring(2, 4), 16),
		b: parseInt(hex.substring(4, 6), 16),
		a: hex.length === 6 ? 1 : +(parseInt(hex.substring(6, 8), 16) / 255).toFixed(2)
	};
	return rgba;
};

export const rgbToHSV = (r: number, g: number, b: number) => {
	(r /= 255), (g /= 255), (b /= 255);

	var max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	var hue = 0,
		sat = 0,
		val = max;

	var d = max - min;
	sat = max == 0 ? 0 : d / max;

	if (max == min) {
		hue = 0; // achromatic
	} else {
		switch (max) {
			case r:
				hue = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				hue = (b - r) / d + 2;
				break;
			case b:
				hue = (r - g) / d + 4;
				break;
		}

		hue *= 60;
	}

	const hsv: IHSV = { hue: +hue.toFixed(2), sat: +sat.toFixed(2), val: +val.toFixed(2) };
	return hsv;
};

export const shadeColor = (color: string, percent: number) => {
	let R = parseInt(color.substring(1, 3), 16);
	let G = parseInt(color.substring(3, 5), 16);
	let B = parseInt(color.substring(5, 7), 16);

	R = (R * (100 + percent)) / 100;
	G = (G * (100 + percent)) / 100;
	B = (B * (100 + percent)) / 100;

	R = R < 255 ? R : 255;
	G = G < 255 ? G : 255;
	B = B < 255 ? B : 255;

	R = Math.round(R);
	G = Math.round(G);
	B = Math.round(B);

	let RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
	let GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
	let BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

	const string = '#' + RR + GG + BB;
	return string;
};

export const invertColor = (hex) => {
	if (hex.indexOf('#') === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length < 6) {
		return hex;
	}
	// invert color components
	var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
		g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
		b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
	// pad each with zeros and return
	return '#' + padZero(r, r.length) + padZero(g, g.length) + padZero(b, b.length);
};

const padZero = (str, len) => {
	len = len || 2;
	var zeros = new Array(len).join('0');
	return (zeros + str).slice(-len);
};

export const getDominantHexColorValue = (hex: string) => {
	if (hex.indexOf('#') === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	let R = parseInt(hex.substring(1, 3), 16);
	let G = parseInt(hex.substring(3, 5), 16);
	let B = parseInt(hex.substring(5, 7), 16);
	const maxValue = Math.max(R, G, B);
	switch (maxValue) {
		case R:
			return 'red';
		case G:
			return 'green';
		case B:
			return 'blue';

		default:
			return 'none';
	}
};

const colorChannelMixer = (colorChannelA: number, colorChannelB: number, amountToMix: number) => {
	var channelA = colorChannelA * amountToMix;
	var channelB = colorChannelB * (1 - amountToMix);
	return Math.round(channelA + channelB);
};

export const colorMixer = (rgbA: IRGBA, rgbB: IRGBA, amountToMix: number) => {
	var r = colorChannelMixer(rgbA.r, rgbB.r, amountToMix);
	var g = colorChannelMixer(rgbA.g, rgbB.g, amountToMix);
	var b = colorChannelMixer(rgbA.b, rgbB.b, amountToMix);
	var a = colorChannelMixer(rgbA.a, rgbB.a, amountToMix);
	const rgba: IRGBA = {
		r: r,
		g: g,
		b: b,
		a: a
	};
	return rgba;
};

export const EDefaultColorSet = {
	// Voting Types
	Applause: '#D8F1E4',
	MultipleChoice: '#DEEAFC',
	Quiz: '#D8F1E4',
	Rating: '#D8F1E4',
	Scale: '#FCDEF4',
	Survey: '#D8F1E4',
	Text: '#DFDEFC',
	WordCloud: '#D8F1E4',
	LinkedIn: '#0e76a8',
	Xing: '#126567',
	Twitter: '#1DA1F2',
	Facebook: '#3b5998',
	Instagram: '#cd486b',
	YouTube: '#FF0000',
	TikTok: '#010101'
};

export const EDefaultIconColorSet = {
	// Voting Types
	Applause: '#D8F1E4',
	MultipleChoice: '#3E6AA4',
	Quiz: '#D8F1E4',
	Rating: '#D8F1E4',
	Scale: '#E6425A',
	Survey: '#D8F1E4',
	Text: '#9C58B0',
	WordCloud: '#D8F1E4'
};

export const getIconBackgroundColorByVoteType = (type: TVotingType) => {
	switch (type) {
		case 'applause':
			return EDefaultColorSet.Applause;
		case 'multipleChoice':
			return EDefaultColorSet.MultipleChoice;
		case 'quiz':
			return EDefaultColorSet.Quiz;
		case 'rating':
			return EDefaultColorSet.Rating;
		case 'scale':
			return EDefaultColorSet.Scale;
		case 'survey':
			return EDefaultColorSet.Survey;
		case 'text':
			return EDefaultColorSet.Text;
		case 'wordCloud':
			return EDefaultColorSet.WordCloud;
		default:
			return hsBlue;
	}
};

export const getIconColorByVoteType = (type: TVotingType) => {
	switch (type) {
		// case 'applause':
		// 	return EDefaultIconColorSet.Applause;
		case 'multipleChoice':
			return EDefaultIconColorSet.MultipleChoice;
		// case 'quiz':
		// 	return EDefaultIconColorSet.Quiz;
		// case 'rating':
		// 	return EDefaultIconColorSet.Rating;
		case 'scale':
			return EDefaultIconColorSet.Scale;
		// case 'survey':
		// 	return EDefaultIconColorSet.Survey;
		case 'text':
			return EDefaultIconColorSet.Text;
		// case 'video':
		// 	return EDefaultIconColorSet.Video;
		// case 'wordCloud':
		// 	return EDefaultIconColorSet.WordCloud;
		default:
			return '#1e1e1e';
	}
};

export const hellospacesGradient = ['#f8961e', '#e00061', '#3dabdb', '#3feaff'];

export const hexTransparency = {
	100: 'FF',
	99: 'FC',
	98: 'FA',
	97: 'F7',
	96: 'F5',
	95: 'F2',
	94: 'F0',
	93: 'ED',
	92: 'EB',
	91: 'E8',
	90: 'E6',
	89: 'E3',
	88: 'E0',
	87: 'DE',
	86: 'DB',
	85: 'D9',
	84: 'D6',
	83: 'D4',
	82: 'D1',
	81: 'CF',
	80: 'CC',
	79: 'C9',
	78: 'C7',
	77: 'C4',
	76: 'C2',
	75: 'BF',
	74: 'BD',
	73: 'BA',
	72: 'B8',
	71: 'B5',
	70: 'B3',
	69: 'B0',
	68: 'AD',
	67: 'AB',
	66: 'A8',
	65: 'A6',
	64: 'A3',
	63: 'A1',
	62: '9E',
	61: '9C',
	60: '99',
	59: '96',
	58: '94',
	57: '91',
	56: '8F',
	55: '8C',
	54: '8A',
	53: '87',
	52: '85',
	51: '82',
	50: '80',
	49: '7D',
	48: '7A',
	47: '78',
	46: '75',
	45: '73',
	44: '70',
	43: '6E',
	42: '6B',
	41: '69',
	40: '66',
	39: '63',
	38: '61',
	37: '5E',
	36: '5C',
	35: '59',
	34: '57',
	33: '54',
	32: '52',
	31: '4F',
	30: '4D',
	29: '4A',
	28: '47',
	27: '45',
	26: '42',
	25: '40',
	24: '3D',
	23: '3B',
	22: '38',
	21: '36',
	20: '33',
	19: '30',
	18: '2E',
	17: '2B',
	16: '29',
	15: '26',
	14: '24',
	13: '21',
	12: '1F',
	11: '1C',
	10: '1A',
	9: '17',
	8: '14',
	7: '12',
	6: '0F',
	5: '0D',
	4: '0A',
	3: '08',
	2: '05',
	1: '03',
	0: '00'
};

export const colorSuggestions = [
	'#55c1ff',
	'#72fcea',
	'#89fa4f',
	'#fff056',
	'#ff968d',
	'#ff95ca',
	'#00a1ff',
	'#14e7cf',
	'#60d837',
	'#ffd933',
	'#ff634e',
	'#ff43a1',
	'#0076ba',
	'#00ab8e',
	'#1eb100',
	'#feae00',
	'#ee230d',
	'#d41876',
	'#014d80',
	'#026c65',
	'#027101',
	'#f27200',
	'#b41700',
	'#970d53',
	'#ffffff',
	'#d5d5d5',
	'#929292',
	'#5e5e5e',
	'#2a2a2a',
	'#000000'
];
