import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react';

export const createMatomoInstance = (settings) => {
	return createInstance(settings);
};

export const HSPXMatomoProvider = (props) => {
	return <MatomoProvider value={props.instance}>{props.children}</MatomoProvider>;
};

export const useHspxMatomo = () => {
	const hook = useMatomo();

	return hook;
};
