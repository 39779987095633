import { IRegistrationField } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { View } from 'react-native';
import { StepWizard } from 'components/StepWizard';
import { isEmptyString } from 'helper';
import { RegistrationFieldsMetaForm } from 'components/Forms/Space/RegistrationFields/RegistrationFieldsMetaForm';
import { ContentTypeFieldsOptionsForm } from 'components/Forms';

interface IEditRegistrationFieldModal {
	field?: IRegistrationField;
	onChange: (field: IRegistrationField) => void;
	onClose: () => void;
	onSubmit: () => void;
}

const TESTIDPREFIX = 'editregistraionfieldmodal';

export const EditRegistrationFieldModal = (props: IEditRegistrationFieldModal) => {
	const { field, onClose, onSubmit, onChange } = props;
	const { t } = useTranslation();

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [currentStep, setCurrentStep] = useState<number>(0);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!field) {
			setErrors({});
		}
	}, [field]);

	const _updateField = (fieldName: string, value: any) => {
		if (field) {
			onChange({ ...field, [fieldName]: value });
			setIsDirty(true);
		}
	};

	const _submit = () => {
		if (field?.minCount && field.maxCount) {
			if (field.minCount > field.maxCount) {
				let _error = t('FormMinMaxError').replace('%FIELD1%', t('CategoryMinCount')).replace('%FIELD2%', t('CategoryMaxCount'));
				setErrors({ maxCount: _error });
				return;
			}
		}
		onSubmit();
		setErrors({});
	};

	const _isNextButtonDisabled = () => {
		switch (currentStep) {
			case 1:
				return !field?.options ||
					field?.options.length === 0 ||
					field?.options?.find((f) => isEmptyString(f.key) || isEmptyString(f.label))
					? true
					: false;
			case 0:
				return isEmptyString(field?.fieldLabel);
			default:
				return true;
		}
	};

	const _getExtraStep = () => {
		if (field?.fieldType === 'category' || field?.fieldType === 'multiswitch') {
			return [
				<ContentTypeFieldsOptionsForm
					testID={TESTIDPREFIX}
					values={field}
					errors={errors}
					onChange={(field, value) => _updateField(field, value)}
					hideCountFields={field.fieldType !== 'category'}
				/>
			];
		}

		return [];
	};

	return (
		<HSModal
			isVisible={field !== undefined}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={_submit}
			isSubmitDisabled={
				!field?.fieldType ||
				isEmptyString(field?.fieldName) ||
				isEmptyString(field?.fieldLabel) ||
				(field?.fieldType === 'category' && (!field.options || field.options.length === 0))
			}
			title={t('Edit Field')}
		>
			<View style={{ flex: 1 }}>
				{field && (
					<StepWizard
						testIdPrefix={TESTIDPREFIX}
						getCurrentIndex={(val) => setCurrentStep(val)}
						completeFunction={() => _submit()}
						isLoading={false}
						components={[
							<RegistrationFieldsMetaForm
								testID={TESTIDPREFIX}
								values={field}
								errors={errors}
								onChange={(field, value) => _updateField(field, value)}
							/>,
							..._getExtraStep()
						]}
						isNextButtonDisabled={_isNextButtonDisabled()}
					/>
				)}
			</View>
		</HSModal>
	);
};
