import { HSCard } from 'components/Card';
import { IContentTypeField, ILauncherSpace } from 'config/interfaces';
import { TContentType, useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import React from 'react';
import { View } from 'react-native';

interface ISelfServiceForm {
	contentType: TContentType;
	item: Record<string, any>;
	errors: Record<string, string>;
	onChange: (item: Record<string, any>) => void;
	fields: IContentTypeField[];
	space: ILauncherSpace;
}

export const SelfServiceForm = (props: ISelfServiceForm) => {
	const { contentType, item, errors, onChange, fields, space } = props;
	const { getContentTypeSections } = useContent(contentType);
	const { renderFormField } = useForm();

	const _isDisabled = () => {
		switch (contentType) {
			case 'expo':
				return (
					space.selfServiceSettings?.expoSelfServiceSettings?.editable === 'readOnly' || item.selfServiceEditable === 'readOnly'
				);
			case 'speaker':
				return (
					space.selfServiceSettings?.speakerSelfServiceSettings?.editable === 'readOnly' ||
					item.selfServiceEditable === 'readOnly'
				);
			default:
				return false;
		}
	};

	return (
		<View>
			{getContentTypeSections(true, fields).map((section, idx) => {
				const _fieldAmount = section.fields.filter((f) => f.fieldType !== 'section').length;
				if (_fieldAmount > 0) {
					return (
						<HSCard key={`selfservice_section_${idx}`}>
							{section.fields.map((field) =>
								renderFormField(
									contentType,
									'selfservice',
									item,
									errors,
									field,
									onChange,
									field.selfServiceVisibility === 'disabled' || _isDisabled(),
									space,
									true
								)
							)}
						</HSCard>
					);
				}
				return null;
			})}
			{/* {getContentTypeSections(true, fields).map((field) =>
				renderFormField('selfservice', item, errors, field, onChange, field.selfServiceVisibility === 'disabled' || _isDisabled())
			)} */}
		</View>
	);
};
