import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { View } from 'react-native';
import { useQuery } from 'hooks/useQuery';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { NetworkingRoomWrapper } from 'components/NetworkingRoom';
import { hsTopScreenPadding } from 'config/styleConstants';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NetworkingRoom>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NetworkingRoom>;
type RouteParams = StackParamList[ERoutes.NetworkingRoom];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'networkingroom';

export const NetworkingRoomScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();

	const activeNetworkingRoom = useSelector((store: IRootState) => store.networking.activeNetworkingRoom);

	const _renderContent = () => {
		return <NetworkingRoomWrapper networkingRoomId={route.params.id} testIdPrefix={TESTIDPREFIX} />;
	};

	return (
		<ScreenContainer contentKey="networkingrooms">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: activeNetworkingRoom ? '100%' : screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderContent()}
			</View>
		</ScreenContainer>
	);
};

export const NetworkingRoomScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} options={props.options} />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
