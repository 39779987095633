import { IContentFlowMediaEntry } from 'config/interfaces';
import React from 'react';
import { Text } from 'components/Text';
import { View } from 'react-native';
import { ChildButton } from 'components/Button';
import { FormCheckbox } from 'components/Form';

interface IContentflowListEntry {
	testID: string;
	item: IContentFlowMediaEntry;
	onPress?: () => void;
	onSelect?: () => void;
	isSelected?: boolean;
}

export const CONTENTFLOW_LIST_ENTRY_HEIGHT = 80;

export const ContentflowListEntry = (props: IContentflowListEntry) => {
	const { testID, item, onPress, onSelect, isSelected } = props;

	return (
		<ChildButton
			testID={testID}
			style={{ height: CONTENTFLOW_LIST_ENTRY_HEIGHT, padding: 5, flexDirection: 'row' }}
			isDisabled={!onPress}
			onPress={onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}
			<View style={{ flex: 1 }}>
				<Text bold>{item.title}</Text>
				<Text numberOfLines={2} style={{ fontSize: 12 }}>
					{item.url}
				</Text>
			</View>
		</ChildButton>
	);
};
