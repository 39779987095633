import { useSpace } from './useSpace';

export const useFeature = () => {
	const { activeSpace } = useSpace();

	const isFeatureActive = (feature?: string) => {
		return (
			feature && activeSpace?.features?.list.find((e) => e.isActive && (e.key === feature || e.contentType === feature)) !== undefined
		);
	};

	return {
		isFeatureActive
	};
};
