import React, { useEffect, useState } from 'react';
import { IFormBase } from 'config/interfaces/form';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { FormField } from './FormField';
import { useTheme } from 'hooks/useTheme';
import { ILauncherSpace, IOption, IUploadCategory } from 'config/interfaces';
import { Text } from 'components/Text';
import { BUTTONBORDERWIDTH, BUTTONBORDERRADIUS } from 'components/Button/constants';
import { EDefaultIconSet } from 'helper/icon';
import { isEmptyString } from 'helper/string';
import { t } from 'i18next';
import { FormHint } from './FormHint';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Icon } from 'components/Icon';

interface IFormTagCloud extends IFormBase {
	testID: string;
	formStyle?: ViewStyle;
	options?: IOption[];
	value?: string;
	onChange?: (val: string) => void;
	showOnlyValues?: boolean;
	minCount?: number;
	maxCount?: number;
	fieldName?: string;
	matchProfileCategory?: string;
	contentType?: string;
	isSelfService?: boolean;
	space?: ILauncherSpace;
	targetType?: string;
}

export const FormTagCloud = (props: IFormTagCloud) => {
	const {
		label,
		isRequired,
		hint,
		error,
		isDisabled,
		testID,
		formStyle,
		options,
		value,
		onChange,
		showOnlyValues,
		minCount,
		maxCount,
		fieldName,
		matchProfileCategory,
		contentType,
		isSelfService,
		space,
		targetType
	} = props;
	const { theme } = useTheme();

	const [myOptions, setMyOptions] = useState<IOption[]>([]);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		let _options: typeof myOptions = [];

		if (options) {
			_options = [...options];
		}

		if (targetType && isSelfService && space && fieldName) {
			let entry: IUploadCategory | undefined = undefined;

			switch (targetType) {
				case 'expo':
					if ((space.selfServiceSettings?.expoSelfServiceSettings?.uploadCategories ?? []).length > 0) {
						entry = space.selfServiceSettings?.expoSelfServiceSettings?.uploadCategories?.find(
							(e) => e.fieldName === fieldName
						);
						if (entry) {
							const splitted = entry.keys.split(',');
							_options = _options.filter((e) => splitted.includes(e.key));
						} else {
							_options = [];
						}
					}
					break;
				case 'speaker':
					if ((space.selfServiceSettings?.speakerSelfServiceSettings?.uploadCategories ?? []).length > 0) {
						entry = space.selfServiceSettings?.speakerSelfServiceSettings?.uploadCategories?.find(
							(e) => e.fieldName === fieldName
						);
						if (entry) {
							const splitted = entry.keys.split(',');
							_options = _options.filter((e) => splitted.includes(e.key));
						} else {
							_options = [];
						}
					}
					break;
				default:
					break;
			}
		}

		setMyOptions(_options);
	}, [options, contentType, fieldName, isSelfService, space]);

	const _getBackgroundColor = (option: IOption, isActive: boolean) => {
		if (isActive) {
			if (!isEmptyString(option.backgroundColor)) {
				return option.backgroundColor;
			}

			return theme.primary;
		}

		return undefined;
	};

	const _getBorderColor = (option: IOption) => {
		if (!isEmptyString(option.backgroundColor)) {
			return option.backgroundColor;
		}

		return theme.primary;
	};

	const _getTextColor = (option: IOption, isActive: boolean) => {
		if (isActive) {
			if (!isEmptyString(option.textColor)) {
				return option.textColor;
			}

			return theme.primaryContrast;
		}

		return theme.text;
	};

	const _renderCountHint = () => {
		if (minCount && maxCount) {
			if (Number(minCount) === 0) {
				return (
					<FormHint
						testID="counthint"
						hint={
							Number(maxCount) === 1
								? t('CategoryErrorMaxCountIs1')
								: t('CategoryErrorMaxCount').replace('%MAX%', maxCount.toString())
						}
					/>
				);
			}
			return (
				<FormHint
					testID="counthint"
					hint={
						Number(minCount) === 1 && Number(maxCount) === 1
							? t('CategoryErrorRangeCountSingle')
							: minCount === maxCount
							? t('CategoryErrorExactCount').replace('%COUNT%', minCount.toString())
							: t('CategoryErrorRangeCount').replace('%MIN%', minCount.toString()).replace('%MAX%', maxCount.toString())
					}
				/>
			);
		}

		if (minCount) {
			if (minCount.toString() === '1') return <FormHint testID="counthint" hint={t('CategoryErrorMinCountIs1')} />;
			return <FormHint testID="counthint" hint={t('CategoryErrorMinCount').replace('%MIN%', minCount.toString())} />;
		}

		if (maxCount) {
			if (maxCount.toString() === '1') return <FormHint testID="counthint" hint={t('CategoryErrorMaxCountIs1')} />;
			return <FormHint testID="counthint" hint={t('CategoryErrorMaxCount').replace('%MAX%', maxCount.toString())} />;
		}

		return null;
	};

	const _renderMatch = (key: string) => {
		if (matchProfileCategory && profile && profile[matchProfileCategory]) {
			const splitted = profile[matchProfileCategory].split(',');
			if (splitted.includes(key)) {
				return (
					<View style={{ position: 'absolute', bottom: -5, right: -5, backgroundColor: theme.success, borderRadius: 999 }}>
						<Icon name={EDefaultIconSet.Save} color={theme.white} size={15} />
					</View>
				);
			}
		}

		return null;
	};

	const _renderOptions = () => {
		return myOptions.map((option, index) => {
			const isActive = value ? value?.split(',').includes(option.key) : false;

			if (showOnlyValues && !isActive) {
				return null;
			}

			return (
				<TouchableOpacity
					testID={`${testID}_option_${index}`}
					key={`tagcloud_option_${option.key}`}
					disabled={isDisabled}
					style={{
						paddingVertical: 5,
						paddingHorizontal: 10,
						marginRight: 10,
						marginBottom: 10,
						justifyContent: 'center',
						alignItems: 'center',
						borderWidth: BUTTONBORDERWIDTH,
						borderRadius: BUTTONBORDERRADIUS,
						borderColor: _getBorderColor(option),
						backgroundColor: _getBackgroundColor(option, isActive)
					}}
					onPress={() => {
						if (onChange) {
							let newValue = value?.split(',') ?? [];
							if (isActive) {
								newValue = newValue.filter((e) => e !== option.key);
							} else {
								newValue.push(option.key);
							}
							onChange(newValue?.filter((v) => !isEmptyString(v))?.join(',') ?? []);
						}
					}}
				>
					<Text numberOfLines={1} adjustsFontSizeToFit style={{ color: _getTextColor(option, isActive) }}>
						{option.label}
					</Text>
					{_renderMatch(option.key)}
				</TouchableOpacity>
			);
		});
	};

	if (showOnlyValues && myOptions?.filter((e) => (value ? value?.split(',').includes(e.key) : false))?.length === 0) {
		return null;
	}

	if (myOptions.length === 0) {
		return null;
	}

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					flexWrap: 'wrap',
					alignItems: 'flex-start',
					justifyContent: formStyle?.justifyContent
				}}
			>
				{_renderOptions()}
			</View>
			{_renderCountHint()}
		</FormField>
	);
};
