import { ListBubbles } from 'components/List';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FlatList, View, ScrollView, Text } from 'react-native';
import { TutorialItem } from './TutorialItem';
import { useTranslation } from 'react-i18next';

import step1 from '../../assets/images/tutorial/step1.png';
import step2 from '../../assets/images/tutorial/step2.png';
import step3 from '../../assets/images/tutorial/step3.png';
import step4 from '../../assets/images/tutorial/step4.png';

import { ChildButton, RoundButton } from 'components/Button';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FormCheckbox } from 'components/Form';
import { DATAPROTECTIONURL } from 'config/constants';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { EDefaultIconSet, openURL } from 'helper';
import { IS_WEB } from 'helper/platform';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useSelector } from 'react-redux';
import { Icon } from 'components/Icon';

interface ITutorial {
	onAfterEnd?: () => void;
	isOnBoarding?: boolean;
}

export interface ITutorialItem {
	headerText?: string;
	description?: string;
	imageUrl?: string;
	icon?: string;
	children?: ReactNode | ReactNode[];
}

const TESTIDPREFIX = 'onboarding';

export const Tutorial = (props: ITutorial) => {
	const { onAfterEnd, isOnBoarding } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [offset, setOffset] = useState<number>(0);
	const [hasCheckedTerms, setHasCheckedTerms] = useState<boolean>(false);
	const [indexNavHeight, setIndexNavHeight] = useState<number>(0);

	const flatlistRef = useRef<any | undefined>(undefined);

	const setHasAcceptedPrivacyPolicy = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.setHasAcceptedPrivacyPolicy);

	useEffect(() => {
		setActiveIndex(0);
	}, []);

	const _renderDataProtectionPolicyHeader = () => {
		if (isOnBoarding) {
			return (
				<HSCard
					style={{
						width: containerWidth - EHorizontalScreenPadding.Wide * 2,
						marginHorizontal: EHorizontalScreenPadding.Wide,
						marginTop: 16
					}}
				>
					<View
						style={{
							alignSelf: 'center',
							alignItems: 'center',
							paddingHorizontal: 20
						}}
					>
						<FormCheckbox
							testID={`${TESTIDPREFIX}_checkbox_privacypolicy`}
							url={DATAPROTECTIONURL}
							value={hasCheckedTerms}
							onPress={() => setHasCheckedTerms(!hasCheckedTerms)}
							style={{ marginBottom: 0, paddingHorizontal: 20 }}
							thickerBorder
							label={t('dataProtectionPolicy')}
						/>
					</View>

					<View style={{ marginTop: hsBottomMargin }}>{_renderContinueButton()}</View>
				</HSCard>
			);
		}

		return null;
	};

	const _renderContinueButton = () => {
		return (
			<View style={{ alignSelf: 'center' }}>
				<RoundButton
					isDisabled={!hasCheckedTerms}
					isFloatingButton
					alignSelf="flex-end"
					testID={`${TESTIDPREFIX}_button_continue`}
					title={t('Confirm and continue')}
					icon={EDefaultIconSet.Save}
					textColor={theme.text}
					isOutline
					onPress={() => setHasAcceptedPrivacyPolicy(true)}
				/>
			</View>
		);
	};

	const tutorialData: ITutorialItem[] = isOnBoarding
		? [
				{
					headerText: t('TutorialStep1Header'),
					description: t('TutorialStep1Text'),
					imageUrl: step1
				},
				{
					headerText: t('TutorialStep2Header'),
					description: t('TutorialStep2Text'),
					imageUrl: step2
				},
				{
					headerText: t('TutorialStep3Header'),
					description: t('TutorialStep3Text'),
					imageUrl: step3
				},
				{
					headerText: t('TutorialStep4Header'),
					description: t('TutorialStep4Text'),
					imageUrl: step4
				}
		  ]
		: [
				{
					headerText: t('TutorialStep1Header'),
					description: t('TutorialStep1Text'),
					imageUrl: step1
				},
				{
					headerText: t('TutorialStep2Header'),
					description: t('TutorialStep2Text'),
					imageUrl: step2
				},
				{
					headerText: t('TutorialStep3Header'),
					description: t('TutorialStep3Text'),
					imageUrl: step3
				},
				{
					headerText: t('TutorialStep4Header'),
					description: t('TutorialStep4Text'),
					imageUrl: step4
				}
		  ];

	const duringScroll = (e) => {
		const currentOffset = e.nativeEvent.contentOffset.x;
		const isRight = currentOffset > offset;

		if (IS_WEB) {
			setActiveIndex(e.nativeEvent.contentOffset.x / containerWidth);
		}
		if (isRight || activeIndex < tutorialData.length - 1) {
			setOffset(currentOffset);
		}
		if (isRight && activeIndex === tutorialData.length - 1) {
			if (onAfterEnd) {
				onAfterEnd();
			}
		}
	};

	const _renderHorizontalScroll = () => {
		if (IS_WEB) {
			return (
				<View
					onLayout={(e) => setIndexNavHeight(e.nativeEvent.layout.height)}
					style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', marginVertical: -8 }}
				>
					<View>
						<ChildButton
							style={{ marginRight: 30 }}
							testID={`onboarding_button_left`}
							onPress={() => {
								flatlistRef.current.scrollToOffset({ offset: (activeIndex - 1) * containerWidth });
							}}
						>
							<Icon name={EDefaultIconSet.ChevronLeft} />
						</ChildButton>
					</View>
					<ListBubbles totalCount={tutorialData.length} activeIndex={activeIndex} noTopMargin />

					<View>
						<ChildButton
							style={{ marginLeft: 30 }}
							testID={'onboarding_button_right'}
							onPress={() => {
								flatlistRef.current.scrollToOffset({ offset: (activeIndex + 1) * containerWidth });
							}}
						>
							<Icon name={EDefaultIconSet.ChevronRight} />
						</ChildButton>
					</View>
				</View>
			);
		}

		return <ListBubbles totalCount={tutorialData.length} activeIndex={activeIndex} noTopMargin />;
	};

	return (
		<View
			style={{
				flex: 1,
				marginBottom: hsBottomMargin,
				width: screenWidth === '60%' ? '45%' : '100%',
				alignSelf: 'center'
			}}
			onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
		>
			<ScrollView showsHorizontalScrollIndicator={!IS_WEB}>
				<FlatList
					testID="onboarding_scrollview"
					ref={flatlistRef}
					data={tutorialData}
					pagingEnabled
					onScrollToIndexFailed={(e) => console.log('scrollToIndexFailed', e)}
					showsHorizontalScrollIndicator={false}
					renderItem={({ item }) => <TutorialItem item={item} containerWidth={containerWidth} webHeight={indexNavHeight} />}
					keyExtractor={(item, index) => `tutorial_screen_${index}`}
					horizontal
					style={{ width: containerWidth }}
					onMomentumScrollEnd={(e) => {
						setActiveIndex(e.nativeEvent.contentOffset.x / containerWidth);
					}}
					onScroll={(e) => duringScroll(e)}
				/>
				{_renderHorizontalScroll()}
			</ScrollView>
			<View>{_renderDataProtectionPolicyHeader()}</View>
		</View>
	);
};
