import React, { useEffect, useRef, useState } from 'react';
import { GestureResponderEvent, PanResponder, TouchableWithoutFeedback, View } from 'react-native';
import chroma from 'chroma-js';
import { LinearGradient } from 'expo-linear-gradient';
import { IS_WEB } from 'helper';

interface ISaturationValuePicker {
	hue: number;
	saturation: number;
	value: number;
	onPress: Function;
	onDragStart?: Function;
	onDragMove: Function;
	onDragEnd?: Function;
	saturationValuePickerSize?: number;
}

const SIZE = 200;
const SLIDERSIZE = 24;

export const SaturationValuePicker = (props: ISaturationValuePicker) => {
	const { hue, saturation, value, onPress, onDragStart, onDragEnd, saturationValuePickerSize } = props;

	const sizeRef = useRef<number>(SIZE);

	useEffect(() => {
		sizeRef.current = saturationValuePickerSize ?? SIZE;
	}, [saturationValuePickerSize]);

	const dragStartValue = useRef({
		saturation: 1,
		value: 1,
		hue: 0
	});
	const currentVal = useRef({
		saturation: 1,
		value: 1,
		hue: 0
	});

	const [dragStart, setDragStart] = useState({
		saturation: 1,
		value: 1,
		hue: 0
	});
	const [current, setCurrent] = useState({
		saturation: 1,
		value: 1,
		hue: 0
	});

	const [panResponder, setPanResponder] = useState(
		PanResponder.create({
			onStartShouldSetPanResponder: () => true,
			onStartShouldSetPanResponderCapture: () => true,
			onMoveShouldSetPanResponder: () => true,
			onMoveShouldSetPanResponderCapture: () => true,
			onPanResponderGrant: (evt, gestureState) => {
				if (onDragStart) {
					onDragStart();
				}
				_reset();
				_fireDragEvent('onDragStart', gestureState);
			},
			onPanResponderMove: (evt, gestureState) => {
				_fireDragEvent('onDragMove', gestureState);
			},
			onPanResponderTerminationRequest: () => true,
			onPanResponderRelease: (evt, gestureState) => {
				if (onDragEnd) {
					onDragEnd();
				}
				_fireDragEvent('onDragEnd', gestureState);
			},
			onPanResponderTerminate: (evt, gestureState) => {
				if (onDragEnd) {
					onDragEnd();
				}
				_fireDragEvent('onDragTerminate', gestureState);
			},
			onShouldBlockNativeResponder: () => true
		})
	);

	useEffect(() => {
		currentVal.current = {
			saturation,
			value,
			hue
		};
		setCurrent({
			saturation,
			value,
			hue
		});
	}, [saturation, value, hue]);

	const _reset = () => {
		dragStartValue.current = {
			...currentVal.current
		};
	};

	const _getCurrentColor = () => {
		const { hue, saturation, value } = currentVal.current;

		return chroma.hsv(hue, saturation, value).hex();
	};

	const _normalizeValue = (value: number) => {
		if (value < 0) return 0;
		if (value > 1) return 1;
		return value;
	};

	const _computeSatValDrag = (gestureState) => {
		const { dx, dy } = gestureState;
		const { saturation, value, hue } = dragStartValue.current;
		const diffX = dx / sizeRef.current;
		const diffY = dy / sizeRef.current;

		return {
			saturation: _normalizeValue(saturation + diffX),
			value: _normalizeValue(value - diffY),
			hue
		};
	};

	const _computeSatValPress = (event) => {
		const { nativeEvent } = event;
		if (IS_WEB) {
			const { layerX, layerY } = nativeEvent;
			return {
				saturation: _normalizeValue(layerX / sizeRef.current),
				value: 1 - _normalizeValue(layerY / sizeRef.current),
				hue: currentVal.current.hue
			};
		}
		const { locationX, locationY } = nativeEvent;
		return {
			saturation: _normalizeValue(locationX / sizeRef.current),
			value: 1 - _normalizeValue(locationY / sizeRef.current),
			hue: currentVal.current.hue
		};
	};

	const _fireDragEvent = (eventName: string, gestureState) => {
		if (props[eventName]) {
			props[eventName]({
				..._computeSatValDrag(gestureState),
				gestureState
			});
		}
	};

	const _firePressEvent = (event: GestureResponderEvent) => {
		if (onPress) {
			onPress({
				..._computeSatValPress(event),
				nativeEvent: event.nativeEvent
			});
		}
	};

	return (
		<View
			style={{
				position: 'relative',
				justifyContent: 'center',
				alignItems: 'center',
				height: (saturationValuePickerSize ?? SIZE) + SLIDERSIZE,
				width: (saturationValuePickerSize ?? SIZE) + SLIDERSIZE
			}}
		>
			<TouchableWithoutFeedback onPress={_firePressEvent}>
				<LinearGradient
					style={[{ borderRadius: 5, overflow: 'hidden' }]}
					colors={['#fff', chroma.hsl(hue, 1, 0.5).hex()]}
					start={[0, 0.5]}
					end={[1, 0.5]}
				>
					<LinearGradient colors={['rgba(0, 0, 0, 0)', '#000']}>
						<View
							style={{
								height: saturationValuePickerSize ?? SIZE,
								width: saturationValuePickerSize ?? SIZE
							}}
						/>
					</LinearGradient>
				</LinearGradient>
			</TouchableWithoutFeedback>
			<View
				{...panResponder.panHandlers}
				style={{
					top: 0,
					left: 0,
					position: 'absolute',
					borderColor: '#fff',
					width: SLIDERSIZE,
					height: SLIDERSIZE,
					borderRadius: SLIDERSIZE / 2,
					borderWidth: SLIDERSIZE / 10,
					backgroundColor: _getCurrentColor(),
					transform: [
						{ translateX: (saturationValuePickerSize ?? SIZE) * saturation },
						{ translateY: (saturationValuePickerSize ?? SIZE) * (1 - value) }
					]
				}}
			/>
		</View>
	);
};
