import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { EDefaultIconSet, normalizeFontSize } from 'helper';
import { ChildButton } from 'components/Button';
import { Avatar } from 'components/User';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';

interface IDrawerViewProfileInfo {
	onPress?: () => void;
}

export const DrawerViewProfileInfo = (props: IDrawerViewProfileInfo) => {
	const { onPress } = props;
	const { theme } = useTheme();

	const profile = useSelector((store: IRootState) => store.auth.profile);

	if (profile) {
		return (
			<ChildButton
				testID="drawer_button_profile"
				style={{ alignItems: 'center', marginBottom: 10, flexDirection: 'row', paddingLeft: 13, paddingRight: 10 }}
				onPress={onPress}
			>
				<Avatar
					testID={`drawer_profile_avatar`}
					avatarObj={profile?.avatar}
					size="xxs"
					fullName={`${profile.firstName} ${profile.lastName}`}
					userId={profile.userId}
				/>
				<View style={{ paddingHorizontal: 8, flex: 1 }}>
					<Text
						bold
						style={{ marginBottom: 0, color: theme.navigationTextColor ?? theme.text }}
					>{`${profile.firstName} ${profile.lastName}`}</Text>
					<Text style={{ fontSize: normalizeFontSize(12), color: theme.navigationTextColor ?? theme.text }}>{profile.email}</Text>
				</View>
				<Icon name={EDefaultIconSet.Edit} size={25} color={theme.navigationTextColor ?? theme.text} />
			</ChildButton>
		);
	}

	return null;
};
