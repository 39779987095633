import React from 'react';
import { IUserProfile } from 'config/interfaces';
import { FlatList, View } from 'react-native';
import { SpaceAdministratorListEntry } from './SpaceAdministratorListEntry';
import { Text } from 'components/Text';
import { HorizontalListSeperator } from 'components/Seperator';

interface ISpaceAdministratorList {
	items?: IUserProfile[];
	listEmpty: string;
	onDeletePress?: (userId: string) => void;
	isLoading?: Record<number, boolean>;
}

export const SpaceAdministratorList = (props: ISpaceAdministratorList) => {
	const { items, listEmpty, onDeletePress, isLoading } = props;

	return (
		<View style={{ marginBottom: 20 }}>
			<FlatList
				scrollEnabled={false}
				data={items}
				keyExtractor={(item, idx) => `${idx}_${item.id}`}
				renderItem={({ item }) => (
					<SpaceAdministratorListEntry
						item={item}
						onDeletePress={onDeletePress}
						isLoading={isLoading && isLoading[item.userId]}
					/>
				)}
				ItemSeparatorComponent={() => <HorizontalListSeperator />}
				ListEmptyComponent={() => (
					<View style={{ padding: 10 }}>
						<Text>{listEmpty}</Text>
					</View>
				)}
			/>
		</View>
	);
};
