import React from 'react';
import { View } from 'react-native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { IAttendee } from 'config/interfaces';
import { isEmptyString, IS_WEB } from 'helper';
import { Text } from 'components/Text';
import { Avatar } from './Avatar';
import { ChildButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { useSpace } from 'hooks/useSpace';
import { useNavigation } from '@react-navigation/native';

interface IUserHeader {
	attendee?: IAttendee;
	isAnonymous?: boolean;
	createdAt?: string;
	largerDesign?: boolean;
	isPreview?: boolean;
}

export const UserHeader = (props: IUserHeader) => {
	const { attendee, isAnonymous, createdAt, largerDesign, isPreview } = props;
	const { t }: { t: any } = useTranslation();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();

	const _renderAvatar = () => {
		let url;
		let fullName;

		if (attendee && !isAnonymous && !isEmptyString(attendee.firstName) && !isEmptyString(attendee.lastName)) {
			fullName = `${attendee.firstName} ${attendee.lastName}`;
			url = IS_WEB ? attendee.imageUrl : attendee?.smallImageUrl ?? attendee.imageUrl;
		}

		return <Avatar testID={`userheader_avatar`} largerDesign={largerDesign} avatar={url} fullName={fullName} size="xs" />;
	};

	const _renderName = () => {
		let name = t('AnonymousUser');

		if (attendee && !isAnonymous && !isEmptyString(attendee.firstName) && !isEmptyString(attendee.lastName)) {
			name = `${attendee.firstName} ${attendee.lastName}`;
		}

		return (
			<Text largerText={largerDesign} bold>
				{name}
			</Text>
		);
	};

	const _renderTime = () => {
		if (!createdAt) {
			return null;
		}
		let time = '';
		const now = moment();
		const created = moment(createdAt);
		const diff = now.diff(created, 'minutes');
		if (diff < 0) {
			time = t('TimeDiffSeconds');
		} else if (diff === 1) {
			time = t('TimeDiffMinute').replace('%VALUE%', diff.toString());
		} else if (diff < 60) {
			time = t('TimeDiffMinuntes').replace('%VALUE%', diff.toString());
		} else if (diff < 60 * 24) {
			if (now.diff(created, 'hours') === 1) {
				time = t('TimeDiffHour').replace('%VALUE%', now.diff(created, 'hours').toString());
			} else {
				time = t('TimeDiffHours').replace('%VALUE%', now.diff(created, 'hours').toString());
			}
		} else if (created.isSame(now.subtract(1, 'days'), 'date')) {
			time = t('YesterdayAt').replace('%VALUE%', created.format('HH:mm'));
		} else {
			time = created.format('HH:mm DD.MM.YYYY');
		}

		return <Text style={{ fontSize: largerDesign ? 18 : 12 }}>{time}</Text>;
	};

	return (
		<ChildButton
			testID="userheader_button"
			style={{ flexDirection: 'row', alignItems: 'center' }}
			isDisabled={!attendee}
			onPress={() => {
				if (!isPreview) navigation.navigate(ERoutes.AttendeeDetails, { spaceId: activeSpace?.spaceId, userId: attendee?.userId });
			}}
		>
			{_renderAvatar()}
			<View style={{ paddingLeft: 10, justifyContent: 'center' }}>
				{_renderName()}
				{_renderTime()}
			</View>
		</ChildButton>
	);
};
