import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { IPushNotification } from 'config/interfaces';
import { IS_WEB, translateStrapiError } from 'helper';
import i18next from 'i18next';
import { ICreatePushNotificationParams, IUpdatePushNotificationParams } from 'rematch/interfaces';
import { RootModel } from './index';
import moment from 'moment';
import { showToast } from 'helper/toast';
import { ENVIRONMENT } from 'config/envConstants';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	pushNotifications: IPushNotification[];
	lastPushNotificationsRead: Record<string, string>;
};

export const pushNotification = createModel<RootModel>()({
	state: {
		pushNotifications: [],
		lastPushNotificationsRead: {}
	} as IDefaultState,
	reducers: {
		addPushNotifications(state, { load, notifications }: { load: boolean; notifications: IPushNotification[] }) {
			rematchLog('addPushNotifications');
			let _pushNotifications = [...state.pushNotifications];
			const _lastRead = state.lastPushNotificationsRead ? { ...state.lastPushNotificationsRead } : {};

			notifications.forEach((notification) => {
				if (notification.isDeleted) {
					_pushNotifications = _pushNotifications.filter((e) => e.id !== notification.id);
				} else {
					const idx = _pushNotifications.findIndex((e) => e.id === notification.id);
					if (idx === -1) {
						_pushNotifications.push(notification);
					} else {
						_pushNotifications[idx] = notification;
					}
				}

				if (load && (!_lastRead[notification.spaceId] || notification.updated_at > _lastRead[notification.spaceId])) {
					_lastRead[notification.spaceId] = notification.updated_at;
				}
			});
			return {
				...state,
				pushNotifications: _pushNotifications.sort((a, b) => (moment(a.sendOn).isAfter(moment(b.sendOn)) ? -1 : 1)),
				lastPushNotificationsRead: _lastRead
			};
		},
		clear(state) {
			rematchLog('clear Push Notifications');
			return {
				pushNotifications: [],
				lastPushNotificationsRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async startPushNotificationsSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (
					activeSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_pushnotifications`)
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_pushnotifications`, (data) => {
						const _load = !Array.isArray(data) && data.load;
						const _items = Array.isArray(data) ? data : data.items;

						_items.forEach((item: IPushNotification) => {
							if (!item.isDeleted && item.receiver !== 'app' && IS_WEB && item.isActive) {
								if (!item.sendOn || moment().utc().isAfter(moment(item.sendOn))) {
									showToast(
										'pushNotification',
										item.title,
										item.webSubtitle,
										item.image?.formats?.medium?.url ??
											item.image?.url ??
											dispatch.content.getLinkedImage({ type: item.deeplinkType, itemId: item.deeplinkItemId }),
										item.deeplinkType,
										item.deeplinkItemId
									);
								}
							}
						});

						if (_load) {
							contentLoadLog(4, 'push notifications received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'pushnotifications', value: false });
							if (!store.temp.hasLoadedData?.pushnotifications) {
								dispatch.temp.setHasLoadedData('pushnotifications');
							}
						}

						if (_items.length > 0) {
							dispatch.pushNotification.addPushNotifications({ load: _load, notifications: _items });
						}
					});
				}
			} catch (error) {
				errorLog('startPushNotificationsSync', error);
			}
		},
		async loadPushNotifications(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const _lastRead =
						store.pushNotification.lastPushNotificationsRead &&
						store.pushNotification.lastPushNotificationsRead[activeSpace.spaceId]
							? store.pushNotification.lastPushNotificationsRead[activeSpace.spaceId]
							: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Socket:
							if (store.temp.hasLoadedData?.pushnotifications) {
								contentLoadLog(3, 'pushnotifications already loaded. skipping');
								return;
							}

							if (store.temp.waitingForSocketResponse?.pushnotifications) {
								contentLoadLog(3, 'pushnotifications load already in progress. skipping');
								return;
							}

							contentLoadLog(2, 'loading push notifications');
							dispatch.temp.setWaitingForSocketResponse({ key: 'pushnotifications', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'pushnotifications',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									spaceId: activeSpace.spaceId,
									userInfos: store.auth.userInfos,
									lastRead: _lastRead
								})
							);

							dispatch.temp.setWaitingForSocketResponse({ key: 'pushnotifications', value: true });
							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/pushnotifications/find`,
								method: 'POST',
								body
							});
							dispatch.temp.setWaitingForSocketResponse({ key: 'pushnotifications', value: false });

							if (res && Array.isArray(res) && res.length > 0) {
								res.forEach((item: IPushNotification) => {
									if (!item.isDeleted && item.receiver !== 'app' && IS_WEB && item.isActive) {
										if (!item.sendOn || moment().utc().isAfter(moment(item.sendOn))) {
											showToast(
												'pushNotification',
												item.title,
												item.webSubtitle,
												item.image?.formats?.medium?.url ??
													item.image?.url ??
													dispatch.content.getLinkedImage({
														type: item.deeplinkType,
														itemId: item.deeplinkItemId
													}),
												item.deeplinkType,
												item.deeplinkItemId
											);
										}
									}
								});

								dispatch.pushNotification.addPushNotifications({ load: true, notifications: res });
							}
						default:
							break;
					}
				}
			} catch (error) {
				console.log('loadPushNotifications', error);
				dispatch.temp.setWaitingForSocketResponse({ key: 'pushnotifications', value: false });
			}
		},
		async createPushNotification(payload: ICreatePushNotificationParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { notification } = payload;

					const tempMedia = {};

					if (notification['image']) {
						tempMedia['image'] = notification.image;
						delete notification.image;
					}

					for (const key of Object.keys(tempMedia)) {
						if (typeof tempMedia[key] !== 'number') {
							if (
								typeof tempMedia[key] === 'string' ||
								tempMedia[key].url?.startsWith('file://') ||
								tempMedia[key].url?.startsWith('data:')
							) {
								let imageId;
								if (typeof tempMedia[key] !== 'string') {
									imageId = tempMedia[key].id;
								}

								const mediaResponse = await dispatch.upload.uploadMedia({
									endpoint: MULTISPACEURL,
									uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
									imageId,
									caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
								});
								if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
									notification[key] = mediaResponse[0];
								}
							}
						} else {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
							if (libEntry) {
								notification[key] = libEntry;
							}
						}
					}

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							notification
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/pushnotifications`,
						method: 'POST',
						body
					});

					if (res?.id) {
						dispatch.pushNotification.addPushNotifications({ load: false, notifications: [res] });
						if (res.isActive) {
							showToast('success', i18next.t('NotificationCreated'));
						} else {
							showToast('success', i18next.t('NotificationCreatedNotActive'));
						}

						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('createPushNotification', error);
				return false;
			}
		},
		async updatePushNotification(payload: IUpdatePushNotificationParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { notification } = payload;

					const body = new FormData();

					const tempMedia = {};

					if (notification.image) {
						tempMedia['image'] = notification.image;
						delete notification.image;
					}

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										notification[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									notification[key] = libEntry;
								}
							}
						}
					}

					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							notification
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/pushnotifications/${notification.id}`,
						method: 'PUT',
						body
					});

					if (res?.id) {
						dispatch.pushNotification.addPushNotifications({ load: false, notifications: [res] });
						if (res.isDeleted) {
							showToast('success', undefined, i18next.t('NotificationDeleted'));
						} else if (res.isActive) {
							showToast('success', undefined, i18next.t('NotificationUpdated'));
						} else {
							showToast('success', undefined, i18next.t('NotificationUpdatedNotActive'));
						}

						return res;
					}
					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('updatePushNotification', error);
				return false;
			}
		}
	})
});
