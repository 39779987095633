import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import {
	hsBottomMargin,
	hsTopScreenPadding,
	LISTHEADER_ANIMATIONTIME_IN,
	LISTHEADER_ANIMATIONTIME_OUT,
	LISTHEADER_ANIMATION_GUESSED_HEIGHT,
	LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_OPACITY_HIGH,
	LISTHEADER_ANIMATION_OPACITY_LOW,
	LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION
} from 'config/styleConstants';
import { Animated, BackHandler, Dimensions, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, getTimingAnimation, IS_ANDROID, IS_WEB } from 'helper';
import { SpeakerList } from 'components/Speaker';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { getSpeakerExample } from 'helper/content';
import { IActiveScreenFilter, IFeatureInfo, ISpeaker } from 'config/interfaces';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { ContentListHeader } from 'components/List';
import { ScrollEvent } from 'react-native-spring-scrollview';
import { TABBAR_HEIGHT } from 'config/constants';
import { filterListByFeature } from 'helper';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Speakers>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Speakers>;
type RouteParams = StackParamList[ERoutes.Speakers];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'speakerlist';

export const SpeakerListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { exportAsCSV } = useExport();
	const { getContentTypeFields } = useContent('speaker');
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [feature, setFeature] = useState<IFeatureInfo | undefined>(undefined);

	const [spaceSpeakers, setSpaceSpeakers] = useState<ISpeaker[]>([]);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const [activeScreenFilters, setActiveScreenFilters] = useState<IActiveScreenFilter>({ filters: ['all'] });
	const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
	const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
	const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(LISTHEADER_ANIMATION_GUESSED_HEIGHT));
	const [animatedOpacity, setAnimatedOpacity] = useState(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
	const [scrollableContentHeight, setScrollableContentHeight] = useState<number>(0);
	const scrollOffset = useRef<number>(0);

	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);
	const content = useSelector((store: IRootState) => store.content.content);

	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const createSpeaker = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createSpeaker);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		let _feature: typeof feature = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Speakers')
			});
		}

		setFeature(_feature);
	}, [route, activeSpace]);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	useEffect(() => {
		if (activeSpace && content.speakers && isScreenFocused) {
			let _speakers: typeof spaceSpeakers = [];
			_speakers = content.speakers.filter(
				(e) => e.spaceId === activeSpace.spaceId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
			);
			_speakers = filterListByFeature(_speakers, feature);

			setSpaceSpeakers(_speakers);
		}
	}, [activeSpace, feature, content, iAmSpaceAdmin, iAmSpaceModerator, isScreenFocused]);

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		const singleItem = spaceSpeakers.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'speaker' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.SpeakerCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'sendMail':
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					contentType: 'speaker',
					selectedEntries: selectedEntries,
					entryPoint: 'template'
				});
				bottomSheetRef.current?.close();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'speaker' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: ISpeaker[] = [];
				if (content.speakers) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.speakers.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.speakers];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'speakers');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'addExample':
				setIsLoading(true);
				const speakersExamples = getSpeakerExample();
				for (const speakerExample of speakersExamples) {
					await createSpeaker({
						speaker: speakerExample,
						fields: getContentTypeFields(),
						noToast: true
					});
				}
				setIsLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.SpeakerCreate, {
						spaceId: activeSpace?.spaceId,
						speakerId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _animateHeader = (e: ScrollEvent) => {
		const { y } = e.nativeEvent.contentOffset;

		if (
			headerHeight &&
			!IS_WEB &&
			scrollableContentHeight > LISTHEADER_ANIMATION_HEIGHT_FACTOR * Dimensions.get('window').height - headerHeight
		) {
			if (y > headerHeight * LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR && y > scrollOffset.current) {
				if (isHeaderExpanded) {
					setIsHeaderExpanded(false);

					Animated.parallel([
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_LOW, LISTHEADER_ANIMATIONTIME_OUT, false),
						getTimingAnimation(animatedHeight, 0, LISTHEADER_ANIMATIONTIME_OUT, false)
					]).start();
				}
			} else if (
				y + (scrollOffset.current < LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION ? 0 : LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION) <
				scrollOffset.current
			) {
				if (!isHeaderExpanded) {
					setIsHeaderExpanded(true);
					Animated.parallel([
						getTimingAnimation(animatedHeight, headerHeight, LISTHEADER_ANIMATIONTIME_IN, false),
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_HIGH, LISTHEADER_ANIMATIONTIME_IN, false)
					]).start();
				}
			}
			scrollOffset.current = y;
		}
	};

	return (
		<ScreenContainer contentKey="speakers">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				<Animated.View
					style={{
						height: animatedHeight,
						opacity: animatedOpacity,
						marginBottom: isHeaderExpanded ? hsBottomMargin : 0
					}}
				>
					<ContentListHeader
						testID={TESTIDPREFIX}
						type="speaker"
						searchTerm={searchTerm}
						onSearchChange={(val) => setSearchTerm(val)}
						modalFilterTitle={t('Filter Speaker')}
						activeScreenFilters={activeScreenFilters.filters}
						onScreenFilterChange={(values) => setActiveScreenFilters(values)}
						activeModalFilters={activeFilters}
						onModalFilterChange={(val) => setActiveFilters(val)}
						feature={feature}
						onLayout={(e) => {
							const { height } = e.nativeEvent.layout;

							if (!headerHeight || height > headerHeight) {
								setHeaderHeight(height);
								setAnimatedHeight(new Animated.Value(height));
							}
						}}
					/>
				</Animated.View>

				<SpeakerList
					testID={TESTIDPREFIX}
					selectedSpeakers={selectedEntries}
					onSelectAll={isEditMode ? (selected) => setSelectedEntries(selected) : undefined}
					onSelect={isEditMode ? (itemId) => _handleSelect(itemId) : undefined}
					isLoading={isDeleteLoading}
					searchTerm={searchTerm}
					activeScreenFilter={activeScreenFilters}
					activeModalFilters={activeFilters}
					onScroll={(e) => _animateHeader(e)}
					onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
					feature={feature}
					onEdit={
						isEditMode
							? (itemId) => navigation.navigate(ERoutes.SpeakerEdit, { spaceId: activeSpace?.spaceId, id: itemId })
							: undefined
					}
					onDelete={
						isEditMode
							? (itemId) => {
									setSelectedEntries([itemId]);
									_delete(itemId);
							  }
							: undefined
					}
					onPress={(item) => {
						if (isEditMode) {
							_handleSelect(item.id);
						} else {
							navigation.navigate(ERoutes.SpeakerDetails, {
								id: item.id,
								spaceId: activeSpace?.spaceId
							});
						}
					}}
					noCard={IS_WEB ? activeSpace?.webLayout === 'cards' : activeSpace?.appLayout === 'cards'}
				/>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_editmode`}
						icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
						label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('editMode')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_sendmail`}
						icon={EDefaultIconSet.Mail}
						label={t('Send Mail Selection').replace(
							'%COUNT%',
							selectedEntries.length > 0 ? ` (${selectedEntries.length})` : ''
						)}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('sendMail')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						isLoading={isDeleteLoading}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isDisabled={isDeleteLoading || isLoading}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_clone`}
						icon={EDefaultIconSet.Clone}
						label={t('clone')}
						isDisabled={isDeleteLoading || selectedEntries.length !== 1}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('clone')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const SpeakerListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};

export const SpeakerListScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
