import { ISpeaker } from 'config/interfaces';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { SPEAKERLISTITEMENTRYHEIGHT } from './SpeakerListItem';
import { isEmptyString } from 'helper';
import { t } from 'i18next';
import { useTheme } from 'hooks/useTheme';
import { Avatar } from 'components/User';

interface ISpeakerMailReceiverListItem {
	item: ISpeaker;
}

const IMAGEWIDTH = 60;

export const SpeakerMailReceiverListItem = (props: ISpeakerMailReceiverListItem) => {
	const { item } = props;
	const { theme } = useTheme();

	return (
		<View style={{ height: SPEAKERLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
			<View style={{ width: IMAGEWIDTH }}>
				<Avatar avatar={item?.image?.formats?.small?.url ?? item.image?.url} fullName={item.title} size="listItem" />
			</View>

			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text bold style={{ marginBottom: 2 }}>
					{`${item.speakerTitle ? `${item.speakerTitle} ` : ''}${item.title}`}
				</Text>
				<Text italic style={{ fontSize: 12, color: isEmptyString(item.selfServiceEmail) ? theme.danger : theme.success }}>
					{isEmptyString(item.selfServiceEmail) ? t('MissingSelfServiceEmail') : item.selfServiceEmail}
				</Text>
			</View>
		</View>
	);
};
