import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import { FormTextInput } from 'components/Form';
import { SectionHeader } from 'components/Text';
import { HSCard } from 'components/Card';
import { useSpace } from 'hooks/useSpace';

interface IEditSpaceTextsForm {
	values: IEditSpaceFormValues;
	errors: IEditSpaceFormErrors;
	onChange: (val: IEditSpaceFormValues) => void;
	isLoading?: boolean;
}
const TESTIDPREFIX = 'editspacetexts';

export const EditSpaceTextsForm = (props: IEditSpaceTextsForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const _renderFeatures = () => {
		if (activeSpace?.features) {
			let activeFeatures = Object.values(activeSpace.features.list).filter((e) => e.isActive);

			return (
				<HSCard>
					<SectionHeader label={t('Features')} isLoading={isLoading} />
					{activeFeatures.map((feature) => {
						return (
							<FormTextInput
								testID={`${TESTIDPREFIX}_textinput_${feature.key}`}
								key={`${TESTIDPREFIX}_textinput_${feature.key}`}
								label={t(feature.label ?? feature.key)}
								isRequired
								value={values.features?.list.find((e) => e.key === feature.key)?.label ?? t(feature.label ?? feature.key)}
								error={errors.title}
								onChangeText={(value) => {
									let _features = values.features;

									if (_features && _features.list) {
										const entry = _features.list.find((e) => e.key === feature.key);
										if (entry) {
											entry.label = value;
										}

										onChange({
											...values,
											features: { ..._features }
										});
									}
								}}
								isDisabled={isLoading}
							/>
						);
					})}
				</HSCard>
			);
		}

		return null;
	};

	return <View>{_renderFeatures()}</View>;
};
