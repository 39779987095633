import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FlatList, View } from 'react-native';

import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import { IAttendee, IChatMessage } from 'config/interfaces';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import moment from 'moment';
import { v4 } from 'uuid';
import { ChatMessage, MeetingRequestMessage } from 'components/Chat';
import { Text } from 'components/Text';
import { hsBorderRadius, hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { FormTextInput } from 'components/Form';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from 'components/Form/constants';
import { AutoGrowingTextInput } from 'react-native-autogrow-textinput';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Chat>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Chat>;
type RouteParams = StackParamList[ERoutes.Chat];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'chat';

export const ChatScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [partner, setPartner] = useState<IAttendee | undefined>(undefined);
	const [message, setMessage] = useState<string>('');
	const [messages, setMessages] = useState<IChatMessage[]>([]);
	const [chatScreenWidth, setChatScreenWidth] = useState<number>(0);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [isMarkAsReadLoading, setIsMarkAsReadLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const chatMessages = useSelector((store: IRootState) => store.chat.chatMessages);

	const addMessages = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.addMessages);
	const sendChatMessage = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.sendChatMessage);
	const markChatMessagesAsRead = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.markChatMessagesAsRead);

	const platformStyle = IS_WEB ? { outline: 'none' } : {};

	useEffect(() => {
		let _partner: typeof partner = undefined;

		if (attendees && !partner) {
			_partner = attendees.find((e) => e.userId === route.params.userId);

			if (_partner) {
				navigation.setOptions({ title: `${_partner.firstName} ${_partner.lastName}` });
			}

			setPartner(_partner);
		}
	}, [attendees]);

	useEffect(() => {
		let _messages: typeof messages = [];

		if (activeSpace && partner && chatMessages) {
			_messages = chatMessages.filter(
				(e) => e.spaceId === activeSpace.spaceId && (e.senderId === partner.userId || e.receiverId === partner.userId)
			);
		}

		_messages.sort((a, b) => {
			const aValue = a.type === 'meetingRequest' ? (!isEmptyString(a.updated_at) ? a.updated_at : a.created_at) : a.created_at;
			const bValue = b.type === 'meetingRequest' ? (!isEmptyString(b.updated_at) ? b.updated_at : b.created_at) : b.created_at;
			return moment(aValue).isAfter(moment(bValue)) ? -1 : 1;
		});
		setMessages(_messages);
	}, [activeSpace, partner, chatMessages]);

	useEffect(() => {
		_markChatMessagesAsRead();
	}, [messages, partner]);

	const _markChatMessagesAsRead = async () => {
		if (partner && !isMarkAsReadLoading) {
			const messageIds = messages.filter((e) => e.senderId !== profile?.userId && !e.hasBeenRead).map((e) => e.id);

			if (messageIds.length > 0) {
				setIsMarkAsReadLoading(true);
				await markChatMessagesAsRead({ messageIds, partnerId: partner.userId });
				setIsMarkAsReadLoading(false);
			}
		}
	};

	const _send = async () => {
		if (profile && activeSpace && partner) {
			const now = moment();
			const tempId = v4();

			let _message = message;

			_message = _message.trim();

			addMessages({
				load: false,
				newMessages: [
					{
						updated_at: now.toISOString(),
						published_at: now.toISOString(),
						content: _message,
						created_at: now.toISOString(),
						id: tempId,
						receiverId: partner.userId,
						senderId: profile.userId,
						status: 'sent',
						type: 'chatMessage',
						spaceId: activeSpace.spaceId
					}
				]
			});

			setMessage('');
			const res = await sendChatMessage({ content: _message, receiverId: partner.userId, senderId: profile.userId, tempId });

			if (res) {
				trackAction('chat', 'Send Message', `${res.id}`);
			}
		}
	};

	const _renderDate = (item: IChatMessage, prevMessage: IChatMessage) => {
		const itemMoment = moment(item.type === 'chatMessage' ? item.created_at : item.updated_at);

		if (
			!prevMessage ||
			!itemMoment.isSame(moment(prevMessage.type === 'chatMessage' ? prevMessage.created_at : prevMessage.updated_at), 'date')
		) {
			const now = moment();
			let str = itemMoment.format('DD.MM.YYYY');
			if (now.isSame(itemMoment, 'date')) {
				str = t('TodayChat');
			} else if (now.subtract(1, 'day').isSame(itemMoment, 'date')) {
				str = t('YesterdayChat');
			}

			return (
				<View
					style={{
						flexDirection: 'row',
						alignSelf: 'center',
						backgroundColor: theme.primary,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingVertical: 5,
						marginBottom: 15,
						borderRadius: hsBorderRadius
					}}
				>
					<Text bold style={{ color: theme.white, fontSize: 12 }}>
						{str}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderMessage = (item: IChatMessage) => {
		switch (item.type) {
			case 'meetingRequest':
				return <MeetingRequestMessage item={item} partner={partner} />;
			case 'videoChatInvitation':
				return null;
			case 'chatMessage':
			default:
				return <ChatMessage item={item} partner={partner} isMyMessage={item.senderId === profile?.userId} />;
		}
	};

	return (
		<ScreenContainer handleBackPress contentKey="chats">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<FlatList
					data={messages}
					keyboardDismissMode="none"
					contentContainerStyle={{ justifyContent: 'flex-end', flexDirection: IS_WEB ? 'column-reverse' : 'column' }}
					renderItem={({ item, index }) => {
						if (item.type === 'chatMessage' && isEmptyString(item.content)) {
							return null;
						}

						return (
							<View>
								{_renderDate(item, messages[index + 1])}
								{_renderMessage(item)}
							</View>
						);
					}}
					inverted={!IS_WEB}
					keyExtractor={(item) => `${TESTIDPREFIX}_message_${item.id}`}
					style={{ paddingHorizontal: EHorizontalScreenPadding.Wide, flexDirection: IS_WEB ? 'column-reverse' : 'column' }}
					onLayout={(e) => setChatScreenWidth(e.nativeEvent.layout.width)}
				/>
				<View
					style={{
						flexDirection: 'row',
						borderWidth: 1,
						borderTopWidth: 1,
						borderRadius: hsBorderRadius,
						borderColor: theme.lightgray,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: isFocused ? 5 : 0,
						paddingBottom: isFocused ? 10 : 5,
						backgroundColor: theme.contentBackgroundColor ?? theme.background,
						marginBottom: hsBottomMargin
					}}
				>
					<View
						style={{
							flex: 1,
							paddingRight: 5,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop: 5
						}}
					>
						{IS_WEB ? (
							<FormTextInput
								testID={'votetext_textinput'}
								value={message}
								multiline
								messageType
								style={{
									color: theme.text
								}}
								formStyle={{ flex: 1, marginBottom: 5, marginTop: 5, paddingRight: 5 }}
								onChangeText={(val) => setMessage(val)}
								placeholderTextColor={theme.formgray}
								placeholder={t('SubmitQuestionPlaceholder')}
							/>
						) : (
							<View
								style={{
									flex: 1,
									borderWidth: FORMELEMENTBORDERWIDTH,
									borderRadius: FORMELEMENTBORDERRADIUS,
									borderColor: theme.lightgray,
									marginRight: 5,
									paddingVertical: 5,
									paddingHorizontal: 10
								}}
							>
								<AutoGrowingTextInput
									testID="chatmessage_textinput"
									value={message}
									onFocus={() => setIsFocused(true)}
									onBlur={() => setIsFocused(false)}
									onChangeText={(val) => setMessage(val)}
									placeholderTextColor={theme.formgray}
									style={{
										...platformStyle,
										color: theme.text,
										minHeight: IS_WEB ? 100 : 17,
										maxHeight: 400,
										marginVertical: 'auto'
									}}
									placeholder={t('ChatMessagePlaceholder')}
								/>
							</View>
						)}
						<RoundButton
							testID={`${TESTIDPREFIX}_button_send`}
							isDisabled={isEmptyString(message) || !partner}
							onPress={_send}
							icon={EDefaultIconSet.Rocket}
							size="sm"
							alignSelf="flex-end"
							isStacked
						/>
					</View>
				</View>
			</View>
		</ScreenContainer>
	);
};

export const ChatScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
