import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultScheduleFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'subtitle',
			fieldLabel: i18next.t('subtitle'),
			fieldHint: i18next.t('scheduleSubtitleHint'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'description',
			fieldLabel: i18next.t('Description'),
			fieldType: 'markdown',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'languages',
			fieldLabel: i18next.t('Language'),
			fieldType: 'language',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxCount: 5
		},
		{
			fieldName: 'startDate',
			fieldLabel: i18next.t('agendaEntryDate'),
			fieldType: 'dateRange',
			isDefaultField: true,
			showOnDetailScreen: true,
			visibility: 'visible',
			fieldName2: 'endDate',
			extraLabel1: i18next.t('agendaStartDate'),
			extraLabel2: i18next.t('agendaEndDate')
		},
		{
			fieldName: 'isHidden',
			fieldLabel: i18next.t('isHidden'),
			fieldHint: i18next.t('isHiddenHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'autoUpdateStatus',
			fieldLabel: i18next.t('autoUpdateStatus'),
			fieldHint: i18next.t('autoUpdateStatusHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: false
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Media'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'previewImage',
			fieldLabel: i18next.t('previewImage'),
			fieldHint: i18next.t('previewImageHint'),
			fieldType: 'image',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'stream',
			fieldLabel: i18next.t('Stream'),
			fieldHint: i18next.t('scheduleStreamHint'),
			fieldType: 'stream',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'section3',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'stage',
			fieldLabel: i18next.t('Stage'),
			fieldType: 'singlereference',
			referenceType: 'stages',
			placeholder: i18next.t('PlaceholderStage'),
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'speakers',
			fieldLabel: i18next.t('Speakers'),
			fieldType: 'reference',
			referenceType: 'speakers',
			placeholder: i18next.t('PlaceholderSpeakers'),
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'expos',
			fieldLabel: i18next.t('Expos'),
			fieldType: 'reference',
			referenceType: 'expos',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderExpos')
		},
		{
			fieldName: 'votes',
			fieldLabel: i18next.t('Votings'),
			fieldType: 'reference',
			referenceType: 'votings',
			placeholder: i18next.t('PlaceholderVotings'),
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'section4',
			fieldLabel: i18next.t('Booking Settings'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'isBookable',
			fieldLabel: i18next.t('isBookable'),
			fieldHint: i18next.t('isBookableHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'bookingCapacity',
			fieldLabel: i18next.t('bookingCapacity'),
			fieldHint: i18next.t('bookingCapacityHint'),
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'section5',
			fieldLabel: i18next.t('Additional Settings'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'backgroundColor',
			fieldLabel: i18next.t('backgroundColor'),
			fieldHint: i18next.t('scheduleBackgroundColorHint'),
			fieldType: 'color',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'textColor',
			fieldLabel: i18next.t('textColor'),
			fieldHint: i18next.t('scheduleTextColorHint'),
			fieldType: 'color',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'hasNoDetails',
			fieldLabel: i18next.t('hasNoDetails'),
			fieldHint: i18next.t('hasNoDetailsHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		}
	];

	return arr;
};
