import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Alert, AppState, AppStateStatus, View } from 'react-native';
import * as Updates from 'expo-updates';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import i18next from 'i18next';
import { IS_ANDROID, IS_IOS, IS_WEB } from 'helper';
import * as Localization from 'expo-localization';
import { useSpace } from 'hooks/useSpace';
import { NewVersion } from 'components/NewVersion';
import * as Application from 'expo-application';
import { MIN_BUILD_VERSION_IOS, MIN_BUILD_VERSION_ANDROID } from 'config/envConstants';
import { showToast } from 'helper/toast';
import Helmet from 'react-helmet';
import ReactGA from 'react-ga4';
import { HSPXMatomoProvider, createMatomoInstance } from 'components/Matomo';

const defaultMatomoInstance = {
	urlBase: 'https://matomo.events66.com',
	siteId: -1,
	disabled: true
};

interface ISpaceGate {
	children?: ReactNode;
}
export const SpaceGate = (props: ISpaceGate) => {
	const { children } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const conectionState = useRef();

	const onlineInterval = useRef<NodeJS.Timer | undefined>(undefined);

	const [shouldShowConnectionChangeToast, setShouldShowConnectionChangedToast] = useState<boolean>(false);
	const [shouldShowReconnectedToast, setShouldShowReconnectedToast] = useState<boolean>(false);

	const [matomoInstance, setMatomoInstance] = useState(createMatomoInstance(defaultMatomoInstance));

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const multiSpaceSocket = useSelector((store: IRootState) => store.socket.multiSpaceSocket);
	const netInfoState = useSelector((store: IRootState) => store.temp.netInfoState);

	const initMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.initMultiSpaceSocket);
	const closeMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceSocket);
	const startProfileSync = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.startProfileSync);
	const reloadSpaceAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.space.reloadSpaceAndStartSync);

	const emitToMultiSpaceBackend = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.emitToMultiSpaceBackend);
	const markMyselfAsOnline = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.markMyselfAsOnline);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	// NEU
	const startAllSyncs = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.startAllSyncs);

	const resetInitialSet = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.resetInitialSet);
	const clearHasLoadedDataAndWaitingForSocketResponse = useRematchDispatch(
		(dispatch: Dispatch) => dispatch.temp.clearHasLoadedDataAndWaitingForSocketResponse
	);

	useEffect(() => {
		if (activeSpace) {
			if (activeSpace.gaTrackingId && !ReactGA.isInitialized) {
				ReactGA.initialize([
					{
						trackingId: activeSpace.gaTrackingId,
						gaOptions: {}
					}
				]);
			}

			if (activeSpace.matomoTrackingUrl && activeSpace.matomoSiteId) {
				setMatomoInstance(
					createMatomoInstance({
						...defaultMatomoInstance,
						siteId: activeSpace.matomoSiteId,
						disabled: false,
						urlBase: activeSpace.matomoTrackingUrl
					})
				);
			}
		} else {
			if (ReactGA.isInitialized) {
				ReactGA.reset();
			}
			setMatomoInstance(createMatomoInstance(defaultMatomoInstance));
		}
	}, [activeSpace]);

	useEffect(() => {
		if (activeSpace && profile) {
			if (activeSpace.matomoTrackingUrl && activeSpace.matomoSiteId) {
				setMatomoInstance(
					createMatomoInstance({
						...defaultMatomoInstance,
						siteId: activeSpace.matomoSiteId,
						disabled: false,
						userId: activeSpace.anonymousTracking ? undefined : profile.userId,
						urlBase: activeSpace.matomoTrackingUrl
					})
				);
			}
		} else {
			setMatomoInstance(createMatomoInstance(defaultMatomoInstance));
		}
	}, [activeSpace, profile]);

	useEffect(() => {
		_init(true);
		i18next.changeLanguage(activeSpace?.language ?? Localization.locale.split('-')[0]);

		let appStateListener;

		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		return () => {
			if (!IS_WEB && appStateListener) {
				appStateListener.remove();
			}
		};
	}, []);

	useEffect(() => {
		if (activeSpace && multiSpaceSocket) {
			emitToMultiSpaceBackend({ event: IS_WEB ? 'joinSpaceWeb' : 'joinSpace', data: { spaceIds: [activeSpace.spaceId] } });
			emitToMultiSpaceBackend({ event: 'login', data: { userId: userInfos.userId } });
		}
	}, [activeSpace, multiSpaceSocket]);

	useEffect(() => {
		conectionState.current = netInfoState;

		if (netInfoState?.isConnected) {
			if (shouldShowReconnectedToast) {
				_init(true);
				showToast('connectionEstablished', t('Connection Established'));
				setShouldShowReconnectedToast(false);
			}
		} else {
			if (shouldShowConnectionChangeToast) {
				showToast('connectionLost', undefined, i18next.t('NoNetworkConnection'));
				closeMultiSpaceSocket({});

				setTimeout(() => {
					_checkConnection();
				}, 15000);
			}
		}
	}, [shouldShowConnectionChangeToast, netInfoState]);

	const _checkConnection = () => {
		if (!conectionState.current?.isConnected) {
			showToast('connectionLost', t('Connection Lost'), t('PleaseCheckConnection'));
			setShouldShowReconnectedToast(true);
		}
	};

	const _startSyncs = async (spaceLoadSuccess?: boolean) => {
		if (spaceLoadSuccess) {
			startAllSyncs({});
		}

		if (profile) {
			infoLog('starting profile sync');
			startProfileSync({ load: true });
		}

		if (IS_WEB && spaceLoadSuccess) {
			markMyselfAsOnline({});
			if (!onlineInterval.current) {
				const _interval = setInterval(() => {
					markMyselfAsOnline({});
				}, 60000);
				onlineInterval.current = _interval;
			}
		}
	};

	const _init = async (initial?: boolean) => {
		await initMultiSpaceSocket({});

		const res = await reloadSpaceAndStartSync({ reload: true });

		setTimeout(() => {
			_startSyncs(res);
		}, 100);

		if (initial) {
			setShouldShowConnectionChangedToast(true);
		}
	};

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			infoLog('App became active');
			await _checkForUpdates();
			// Restart Syncs
		} else if (nextState === 'background') {
			infoLog('App going to Background');
			if (onlineInterval.current) {
				clearInterval(onlineInterval.current);
				onlineInterval.current = undefined;
			}
			// Stop Syncs
			await closeMultiSpaceSocket({});
			resetInitialSet();
			clearHasLoadedDataAndWaitingForSocketResponse();
		}
	};

	const _checkForUpdates = async () => {
		if (!__DEV__ && netInfoState?.isConnected) {
			const { isAvailable } = await Updates.checkForUpdateAsync();

			if (isAvailable) {
				Alert.alert(t('UpdateAvailableTitle'), t('UpdateaAvailableSubtitle'), [
					{
						text: 'OK',
						onPress: () => _downloadUpdate()
					}
				]);
			} else {
				_init();
			}
		} else {
			_init();
		}
	};

	const _downloadUpdate = async () => {
		try {
			const { isNew } = await Updates.fetchUpdateAsync();

			if (isNew) {
				showAlert({
					title: t('UpdateDoneTitle'),
					message: t('UpdateDoneSubtitle'),
					buttons: [
						{
							text: t('Restart now'),
							onPress: () => Updates.reloadAsync()
						}
					]
				});
			}
		} catch (error: any) {
			showAlert({
				title: t('Update Error'),
				message: t('UpdateErrorHint'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel',
						onPress: () => _init()
					},
					{
						text: t('Try Again'),
						onPress: () => _downloadUpdate()
					}
				]
			});
		}
	};

	if (!IS_WEB && !__DEV__) {
		const versionCode = Number(Application.nativeBuildVersion);
		if ((IS_IOS && versionCode < MIN_BUILD_VERSION_IOS) || (IS_ANDROID && versionCode < MIN_BUILD_VERSION_ANDROID)) {
			return <NewVersion />;
		}
	}

	return (
		<HSPXMatomoProvider instance={matomoInstance}>
			<View style={{ flex: 1 }}>
				<Helmet>
					<title>{activeSpace?.title ?? 'HelloSpaces'}</title>
					{activeSpace?.logoImage?.url || activeSpace?.squareImage?.url ? (
						<link rel="icon" type="image/png" href={activeSpace?.squareImage?.url ?? activeSpace?.logoImage?.url} />
					) : null}
				</Helmet>
				{children}
			</View>
		</HSPXMatomoProvider>
	);
};
