import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';

import { ChildButton } from 'components/Button';
import { ISchedule, IScheduleStatus, ISpeaker, IStream } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { GRID_SCHEDULE_BORDER_RADIUS, GRID_SCHEDULE_LEFT_BORDER_WIDTH } from './constants';
import { Text } from 'components/Text';
import { useSpace } from 'hooks/useSpace';
import { hsBorderRadius } from 'config/styleConstants';
import { Icon } from 'components/Icon';
import Flag from 'react-native-flags';

interface IGalleryItem {
	testIdPrefix: string;
	item: ISchedule;
	onPress?: () => void;
	backgroundColor?: string;
	isPublic?: boolean;
}

export const SCHEDULEGALLERYITEMHEIGHT = 80;

export const ScheduleGalleryItem = (props: IGalleryItem) => {
	const { item, onPress, backgroundColor, isPublic, testIdPrefix } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [speakers, setSpeakers] = useState<ISpeaker[]>([]);
	const [streams, setStreams] = useState<IStream[]>([]);
	const [scheduleStatus, setScheduleStatus] = useState<IScheduleStatus | undefined>(undefined);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _speakers: typeof speakers = [];
		let _scheduleStatus: typeof scheduleStatus = undefined;
		let _streams: typeof streams = [];

		if (isPublic) {
			if (item.speakers) {
				_speakers = item.speakers;

				if (item.status) {
					_scheduleStatus = item.status;
				}
			}
		} else {
			if (content.speakers && item.speakers) {
				item.speakers.forEach((speaker) => {
					const _sp = content.speakers.find((sp) => sp.id === speaker.id);
					if (_sp) {
						_speakers.push(_sp);
					}
				});
			}

			if (content.schedulestatuses) {
				_scheduleStatus = content.schedulestatuses.find((e) => e.schedule?.id === item.id);
			}

			if (content.streams && item.stream) {
				if (Array.isArray(item.stream)) {
					item.stream.forEach((stream) => {
						const _st = content.streams.find((st) => st.id === stream.id);
						if (_st) {
							_streams.push(_st);
						}
					});
				} else if (typeof item.stream === 'number') {
					const _st = content.streams.find((st) => st.id === item.stream);
					if (_st) {
						_streams.push(_st);
					}
				} else {
					const _st = content.streams.find((st) => st.id === item.stream?.id);
					if (_st) {
						_streams.push(_st);
					}
				}
			}
		}

		setSpeakers(_speakers);
		setStreams(_streams);
		setScheduleStatus(_scheduleStatus);
	}, [activeSpace, item, content]);

	const _renderLanguages = () => {
		let _languages: string[] = [];

		if (item.languages) {
			const _itemLanguages = item.languages.split(',');
			_languages = _languages.concat(_itemLanguages);
		} else {
			streams.forEach((s) => {
				if (s.languages && !isEmptyString(s.languages)) {
					const _streamLanguages = s.languages.split(',');
					_languages = _languages.concat(_streamLanguages);
				}
			});
		}

		if (_languages.length > 0) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{_languages.map((flag) => (
						<View key={`list_schedule_${item.id}_flag_${flag}`} style={{ paddingRight: 2 }}>
							<Flag code={flag} size={16} style={{ height: 12, width: 12 }} />
						</View>
					))}
				</View>
			);
		}

		return null;
	};

	const _renderSpeakers = (speakers: ISpeaker[]) => {
		if (speakers?.length > 0) {
			const speakersString: string[] = [];
			speakers.forEach((sp) => {
				if (sp.title && !isEmptyString(sp.title)) speakersString.push(`${sp.speakerTitle ? sp.speakerTitle + ' ' : ''}${sp.title}`);
			});

			return (
				<View style={{ marginTop: 5, marginBottom: 2 }}>
					<Text
						bold
						numberOfLines={1}
						style={{
							fontSize: 12,
							color: !isEmptyString(item.textColor) ? item.textColor : theme.text
						}}
					>
						{speakersString.join(', ')}
					</Text>
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			key={`${testIdPrefix}_${item.id}`}
			testID={`${testIdPrefix}_${item.id}`}
			activeOpacity={1}
			style={{
				height: SCHEDULEGALLERYITEMHEIGHT
			}}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ flex: 1, opacity: moment().isSameOrAfter(item?.endDate) ? 0.6 : 1 }}>
				<View
					style={{
						flex: 1,
						paddingVertical: 5,
						paddingHorizontal: 10,
						backgroundColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: theme.contentBackgroundColor ?? theme.background,
						borderWidth: 1,
						borderLeftWidth: GRID_SCHEDULE_LEFT_BORDER_WIDTH,
						borderRadius: GRID_SCHEDULE_BORDER_RADIUS,
						borderColor: theme.lightgray,
						borderLeftColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: !isEmptyString(backgroundColor)
							? backgroundColor
							: theme.primary
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 4,
							flexWrap: 'wrap'
						}}
					>
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between'
							}}
						>
							<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}>
								<Text
									style={{
										color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
										fontSize: 12,
										marginRight: 10
									}}
								>
									{`${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('HH:mm')}`}
								</Text>
								{item.stage && (
									<Text
										numberOfLines={1}
										style={{
											color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
											fontSize: 12,
											flex: 1,
											marginRight: 10
										}}
									>
										{`${item.stage.title}`}
									</Text>
								)}
							</View>
							{scheduleStatus && (
								<View
									style={{
										backgroundColor: scheduleStatus.status === 'Live' ? theme.danger : theme.warning,
										paddingHorizontal: 6,
										paddingVertical: 2,
										borderRadius: hsBorderRadius
									}}
								>
									{scheduleStatus.status === 'Live' ? (
										<Text style={{ fontSize: 12, color: theme.primaryContrast }}>Live</Text>
									) : (
										<Icon
											name={EDefaultIconSet.Clock}
											size={12}
											color={!isEmptyString(item.textColor) ? item.textColor : theme.text}
										/>
									)}
								</View>
							)}
						</View>
					</View>
					<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
						<Text
							bold
							numberOfLines={1}
							style={{
								color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
								fontSize: 18,
								flex: 1,
								paddingBottom: 8,
								marginBottom: -8
							}}
							adjustsFontSizeToFit
							minimumFontScale={0.85}
							maxFontSizeMultiplier={1.18}
						>
							{item.title}
						</Text>
						{_renderLanguages()}
					</View>
					{item.speakers ? _renderSpeakers(item.speakers) : null}
				</View>
			</View>
		</ChildButton>
	);
};
