import React, { useEffect, useState } from 'react';

import { Text } from 'components/Text';
import { useMatching } from 'hooks/useMatching';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IListItemMatchCount {
	contentType: string;
	item: any;
}

export const ListItemMatchCount = (props: IListItemMatchCount) => {
	const { contentType, item } = props;
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const { getMatchCount } = useMatching();

	const [matchCount, setMatchCount] = useState<number>(0);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		const counter = getMatchCount(contentType, item);
		setMatchCount(counter);
	}, [activeSpace, item, profile]);

	if (matchCount > 0) {
		return (
			<View
				style={{
					marginLeft: 3
				}}
			>
				<View
					style={{
						backgroundColor: theme.success,
						paddingVertical: 2,
						paddingHorizontal: 5,
						borderRadius: 999
					}}
				>
					<Text
						style={{
							color: theme.text,
							fontSize: 12
						}}
						adjustsFontSizeToFit
					>
						{matchCount}
					</Text>
				</View>
			</View>
		);
	}

	return null;
};
