import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { ScrollView, Vibration, View } from 'react-native';

import { HSDragCard } from 'components/Card';
import { EDefaultIconSet, IS_ANDROID, IS_WEB, swapArrayItems } from 'helper';
import { hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { IEditSpaceFormValues, IRegistrationField } from 'config/interfaces';

import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { LoadingModal } from 'components/Modal/LoadingModal';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { AddRegistrationFieldModal, EditRegistrationFieldModal } from 'components/Modal';

import { FormLabel } from 'components/Form/FormLabel';
import { FormHint } from 'components/Form/FormHint';
import i18next from 'i18next';
import { showToast } from 'helper/toast';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheetViewButton, BottomSheet } from 'components/BottomSheet';
import { CardSeparationHeader, Text } from 'components/Text';
import { FormMultiSwitch } from 'components/Form';
import * as RootNavigation from '../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SpaceRegistrationFields>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SpaceRegistrationFields>;
type RouteParams = StackParamList[ERoutes.SpaceRegistrationFields];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const LISTICONSIZE = 24;

const TESTIDPREFIX = 'spaceregistrationfields';

export const SpaceRegistrationFieldsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { getRegistrationFields } = useRegistration();
	const { screenWidth, isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);
	const scrollView = useRef<ScrollView>(null);

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const [tempField, setTempField] = useState<IRegistrationField | undefined>(undefined);
	const [outerScrollEnabled, setOuterScrollEnabled] = useState<boolean>(true);
	const [isAddFieldModalVisible, setIsAddFieldModalVisible] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open(),
			onBackProhibitedSave: async () => await _updateSpace()
		});
	}, [formValues, setIsFormLocked]);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateValues();
		}
	}, [activeSpace]);

	const _updateValues = () => {
		if (activeSpace) {
			setFormValues({
				updated_at: activeSpace.updated_at,
				spaceId: activeSpace.spaceId,
				registrationFields: { fields: getRegistrationFields(true) }
			});
		}
	};

	const _updateSpace = async () => {
		if (isFormLocked) {
			showAlert({
				title: t('Space has changed'),
				message: t('SpaceFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'meetings')) {
			const hasAllowChatsVisible = formValues.registrationFields?.fields.find(
				(e) => e.fieldName === 'allowChats' && e.visibility !== 'never'
			);
			if (!hasAllowChatsVisible) {
				showToast('error', t('Missing Field'), t('Missing Field Meetings'));
				return;
			}
			const hasAllowMeetingRequestsVisible = formValues.registrationFields?.fields.find(
				(e) => e.fieldName === 'allowMeetingRequests' && e.visibility !== 'never'
			);
			if (!hasAllowMeetingRequestsVisible) {
				showToast('error', t('Missing Field'), t('Missing Field Meetings'));
				return;
			}
		}
		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'chats')) {
			const hasAllowChatsVisible = formValues.registrationFields?.fields.find(
				(e) => e.fieldName === 'allowChats' && e.visibility !== 'never'
			);
			if (!hasAllowChatsVisible) {
				showToast('error', t('Missing Field'), t('Missing Field Chats'));

				return;
			}
		}

		setIsLoading(true);
		const updateSuccess = await updateSpace({ data: formValues });
		setIsLoading(false);

		if (updateSuccess) {
			if (IS_WEB) {
				window.onbeforeunload = null;
			}
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _handleActionSheetPress = async (action: string) => {
		switch (action) {
			case 'save':
				await _updateSpace();
				bottomSheetRef.current?.close();
				break;
			case 'add':
				setTimeout(() => {
					setIsAddFieldModalVisible(true);
				}, 200);
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _renderDivider = () => {
		return <View style={{ width: 1, backgroundColor: theme.gray, height: 16, marginHorizontal: 5 }} />;
	};

	const _renderFieldType = (field: IRegistrationField) => {
		if (field.fieldType) {
			return <Text style={{ fontSize: 10 }}>{`${t(field.fieldType)}`}</Text>;
		}

		return null;
	};

	const _renderCustomFieldHint = (field: IRegistrationField) => {
		if (!field.isCustomField) {
			return <Text style={{ fontSize: 10, marginBottom: 5 }}>{t('isCustomFieldCannotDelete')}</Text>;
		}

		return null;
	};

	const _renderFieldCsvName = (field: IRegistrationField) => {
		if (field.fieldType !== 'section' && field.fieldType !== 'label') {
			return <Text style={{ fontSize: 10 }}>{`CSV: ${field.fieldName}`}</Text>;
		}

		return null;
	};

	const _renderFieldHint = (field: IRegistrationField) => {
		if (field.fieldHint) {
			return <FormHint testID={`${TESTIDPREFIX}_contenttypefield_${field.fieldName}`} hint={field.fieldHint} />;
		}

		return null;
	};

	const _renderFieldKeys = (field: IRegistrationField) => {
		if (field.fieldType === 'category') {
			return <Text style={{ fontSize: 10 }}>{`${t('Keys')}: ${field.options?.map((o) => o.key).join(', ')}`}</Text>;
		}

		return null;
	};

	const _renderFieldVisibility = (field: IRegistrationField) => {
		if (!field.isDefaultField) {
			return (
				<View style={{ flexDirection: IS_WEB ? 'row' : 'column', alignItems: IS_WEB ? 'center' : 'flex-start' }}>
					<Text style={{ fontSize: 10, marginRight: 5 }}>{t('FieldVisibility')}:</Text>
					<FormMultiSwitch
						formStyle={{ marginBottom: -5 }}
						testID={`${TESTIDPREFIX}_multiswitch_visibility`}
						isRequired
						options={[
							{
								key: 'always',
								label: t('FieldVisibilityAlways')
							},
							{
								key: 'registrationOnly',
								label: t('FieldVisibilityRegistrationOnly')
							},
							{
								key: 'editOnly',
								label: t('FieldVisibilityEditProfileOnly')
							},
							{
								key: 'never',
								label: t('FieldVisibilityNever')
							}
						]}
						isDisabled={field.isDefaultField}
						value={field.visibility}
						onChange={(value) => {
							const fields = formValues.registrationFields?.fields;
							if (fields) {
								field.visibility = value;
								fields[field.fieldName] = field;
								_handleChange(fields);
							}
						}}
						size="xs"
					/>
				</View>
			);
		}
	};

	const _renderShowOnDetails = (field: IRegistrationField) => {
		if (!field.isDefaultField) {
			return (
				<View style={{ flexDirection: IS_WEB ? 'row' : 'column', alignItems: IS_WEB ? 'center' : 'flex-start' }}>
					<Text style={{ fontSize: 10, marginRight: 5 }}>{t('showOnDetailScreen')}:</Text>
					<FormMultiSwitch
						formStyle={{ marginBottom: -5, flex: 1 }}
						testID={`${TESTIDPREFIX}_multiswitch_showondetailscreen`}
						isRequired
						options={[
							{
								key: 'always',
								label: t('FieldVisibilityVisible')
							},
							{
								key: 'never',
								label: t('FieldVisibilityHidden')
							}
						]}
						isDisabled={field.isDefaultField}
						value={field.showOnDetailScreen ? 'always' : 'never'}
						onChange={(value) => {
							const fields = formValues.registrationFields?.fields;
							if (fields) {
								field.showOnDetailScreen = value === 'always' ? true : false;
								fields[field.fieldName] = field;
								_handleChange(fields);
							}
						}}
						size="xs"
					/>
				</View>
			);
		}
		return null;
	};

	const _renderFormLabel = (field: IRegistrationField) => {
		return (
			<FormLabel
				testID={`${TESTIDPREFIX}_contenttypefield_${field.fieldName}`}
				label={field.fieldLabel}
				isRequired={field.isRequired}
				style={{ marginBottom: 0, flexShrink: isTabletOrMobile ? 0 : 1 }}
			/>
		);
	};

	const _renderCardContent = (field: IRegistrationField, index: number) => {
		return (
			<View>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5, flex: 1, flexWrap: 'wrap' }}>
					{_renderFormLabel(field)}
					{_renderDivider()}
					{_renderFieldType(field)}
					{_renderDivider()}
					{_renderFieldCsvName(field)}
				</View>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>{_renderShowOnDetails(field)}</View>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>{_renderFieldVisibility(field)}</View>
				{_renderFieldKeys(field)}
			</View>
		);
	};

	const _handleMove = (index: number, direction: 'up' | 'down', moveInvisibleFields: boolean) => {
		let fields = formValues?.registrationFields?.fields?.filter((e) => e.visibility !== 'never');
		if (!fields) {
			return;
		}

		let invisibleFields = [...(formValues.registrationFields?.fields ?? [])].filter((e) => e.visibility === 'never');

		if (moveInvisibleFields) {
			invisibleFields = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, invisibleFields);
		} else {
			fields = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, fields);
		}

		_handleChange([...fields, ...invisibleFields]);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number, moveInvisibleFields: boolean) => {
		const fields = formValues?.registrationFields?.fields?.filter((e) => e.visibility !== 'never');
		if (!fields) {
			return;
		}

		const invisibleFields = [...(formValues.registrationFields?.fields ?? [])].filter((e) => e.visibility === 'never');

		if (moveInvisibleFields) {
			const element = invisibleFields.splice(oldIndex, 1)[0];
			invisibleFields.splice(newIndex, 0, element);
		} else {
			const element = fields.splice(oldIndex, 1)[0];
			fields.splice(newIndex, 0, element);
		}

		_handleChange([...fields, ...invisibleFields]);
	};

	const _renderElement = (params) => {
		const { item, index, drag, isActive } = params;

		const _fields = formValues.registrationFields?.fields?.filter((e) => e.visibility !== 'never') ?? [];

		const prevItem = _fields[index - 1];
		const nextItem = _fields[index + 1];

		let _style = {};

		if (prevItem && item.fieldType !== 'section') {
			_style['borderTopLeftRadius'] = 0;
			_style['borderTopRightRadius'] = 0;
		}

		if (nextItem && nextItem.fieldType !== 'section') {
			_style['marginBottom'] = 0;
			_style['borderBottomLeftRadius'] = 0;
			_style['borderBottomRightRadius'] = 0;
		}

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_list_visiblefield_${index}`}
				isActive={isActive}
				onDrag={drag}
				isDisabled={isLoading}
				onEdit={() => setTempField(item)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up', false)}
				onDownPress={
					index === (formValues?.registrationFields?.fields?.filter((e) => e.visibility !== 'never')?.length ?? 9999) - 1
						? undefined
						: () => _handleMove(index, 'down', false)
				}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex, false)}
				onRemove={
					item.isCustomField
						? () => {
								setFormValues({
									...formValues,
									registrationFields: {
										fields: formValues.registrationFields?.fields.filter((e) => e.fieldName !== item.fieldName) ?? []
									}
								});
						  }
						: undefined
				}
				deleteTitle={t('DeleteField')}
				deleteSubTitle={t('DeleteFieldSubtitle')}
				style={{ ..._style, padding: hsInnerPadding / 2 }}
			>
				{_renderCardContent(item, index)}
			</HSDragCard>
		);
	};

	const _handleChange = (fields: IRegistrationField[]) => {
		setFormValues({ ...formValues, registrationFields: { fields } });
		if (!route.params?.prohibitNavigation) {
			if (IS_WEB) {
				window.onbeforeunload = function (e) {
					var dialogText = t('unsavedChangesSubtitle');
					e.returnValue = dialogText;
					return dialogText;
				};
			}
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _renderInvisibleFields = () => {
		const fields = formValues.registrationFields?.fields?.filter((e) => e.visibility === 'never') ?? [];

		if (fields.length > 0) {
			return (
				<View>
					<CardSeparationHeader label={t('InvisibleFields')} />
					{fields.map((field, idx) => {
						let _style = {};

						const prevItem = fields[idx - 1];
						const nextItem = fields[idx + 1];

						if (prevItem && field.fieldType !== 'section') {
							_style['borderTopLeftRadius'] = 0;
							_style['borderTopRightRadius'] = 0;
						}

						if (nextItem && nextItem.fieldType !== 'section') {
							_style['marginBottom'] = 0;
							_style['borderBottomLeftRadius'] = 0;
							_style['borderBottomRightRadius'] = 0;
						}

						return (
							<HSDragCard
								testID={`${TESTIDPREFIX}_list_invisiblefield_${idx}`}
								key={`invisiblefield_${field.fieldName}`}
								onEdit={() => setTempField(field)}
								style={_style}
								onUpPress={idx === 0 ? undefined : () => _handleMove(idx, 'up', true)}
								onDownPress={
									idx ===
									(formValues?.registrationFields?.fields?.filter((e) => e.visibility === 'hidden')?.length ?? 9999) - 1
										? undefined
										: () => _handleMove(idx, 'down', true)
								}
								index={idx}
								handleIndexChange={(newIndex) => _handleIndexChange(idx, newIndex, true)}
							>
								{_renderCardContent(field, idx)}
							</HSDragCard>
						);
					})}
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
				scrollEnabled={outerScrollEnabled}
			>
				<CardSeparationHeader label={t('VisibleFields')} />
				<DraggableFlatList
					scrollEnabled={false}
					data={formValues.registrationFields?.fields?.filter((e) => e.visibility !== 'never') ?? []}
					renderItem={(params) => _renderElement(params)}
					keyExtractor={(item, index) => `registrationfieldlist_${item.fieldName}`}
					onDragBegin={() => {
						setOuterScrollEnabled(false);
						if (IS_ANDROID) {
							Vibration.vibrate(10, false);
						}
					}}
					onDragEnd={({ data }) => {
						const invisibleFields = [...(formValues.registrationFields?.fields ?? [])].filter((e) => e.visibility === 'never');

						_handleChange([...data, ...invisibleFields]);
						setOuterScrollEnabled(true);
					}}
					// simultaneousHandlers={scrollView}
					activationDistance={20}
				/>

				{_renderInvisibleFields()}
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
			<AddRegistrationFieldModal
				isVisible={isAddFieldModalVisible}
				onClose={() => setIsAddFieldModalVisible(false)}
				currentFields={formValues.registrationFields?.fields ?? []}
				onSubmit={(newField) => {
					const fields = [...(formValues.registrationFields?.fields ?? [])];
					const visibleFields = fields.filter((e) => e.visibility !== 'never');
					const invisibleFields = fields.filter((e) => e.visibility === 'never');
					visibleFields.push(newField as IRegistrationField);

					setFormValues({
						...formValues,
						registrationFields: { fields: [...visibleFields, ...invisibleFields] }
					});

					if (!route.params.prohibitNavigation) {
						navigation.setParams({
							prohibitNavigation: true
						});
					}

					setIsAddFieldModalVisible(false);
				}}
			/>
			<EditRegistrationFieldModal
				field={tempField}
				onClose={() => setTempField(undefined)}
				onChange={(value) => setTempField(value)}
				onSubmit={() => {
					if (tempField) {
						const fields = [...(formValues.registrationFields?.fields ?? [])];
						const idx = fields.findIndex((e) => e.fieldName === tempField?.fieldName);
						fields[idx] = tempField;
						setFormValues({ ...formValues, registrationFields: { fields } });
					}

					if (!route.params.prohibitNavigation) {
						navigation.setParams({
							prohibitNavigation: true
						});
					}

					setTempField(undefined);
				}}
			/>

			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_save`}
					icon={EDefaultIconSet.Save}
					label={t('Save')}
					isLoading={isLoading}
					onPress={() => _handleActionSheetPress('save')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add Field')}
					onPress={() => _handleActionSheetPress('add')}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const SpaceRegistrationFieldsHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} options={props.options} />
			<NavigationHeaderTitle title={i18next.t('Profile Fields')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
