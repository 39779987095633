import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Button, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormCheckbox, FormTextInput } from 'components/Form';
import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderBackButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H2 } from 'components/Text';
import { EditSpaceInitialFormValues, IEditSpaceFormValues, IInternalSettings } from 'config/interfaces';
import { hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertButton, ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.HspxAdmin>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.HspxAdmin>;
type RouteParams = NoSpaceStackParamList[ERoutes.HspxAdmin];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface ISafetyConsent {
	refillRedis: boolean;
}

const TESTIDPREFIX = 'hspxadmin';

export const HspxAdminScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { t } = useTranslation();

	const [isRefillRedisLoading, setIsRefillRedisLoading] = useState<boolean>(false);
	const [isUpdateSpaceLoading, setIsUpdateSpaceLoading] = useState<boolean>(false);
	const [isInternalSettingsLoading, setIsInternalSettingsLoading] = useState<boolean>(false);
	const [editSpaceFormValues, setEditSpaceFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [internalSettingsState, setInternalSettingsState] = useState<IInternalSettings | undefined>(undefined);

	const [safetyConsent, setSafetyConsent] = useState<ISafetyConsent>({
		refillRedis: false
	});

	const refillRedis = useRematchDispatch((dispatch: Dispatch) => dispatch.content.refillRedis);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const getInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.getInternalSettings);
	const setInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.setInternalSettings);

	useEffect(() => {
		if (activeSpace) {
			setEditSpaceFormValues({
				title: activeSpace.title,
				spaceId: activeSpace.spaceId,
				cacheTimerContent: activeSpace.cacheTimerContent,
				cacheTimerAttendee: activeSpace.cacheTimerAttendee,
				reloadIntervalContent: activeSpace.reloadIntervalContent,
				reloadIntervalAttendee: activeSpace.reloadIntervalAttendee
			});
		}
	}, [activeSpace]);

	useEffect(() => {
		_reloadInternalSettings();
	}, []);

	const _reloadInternalSettings = async () => {
		setIsInternalSettingsLoading(true);
		const res = await getInternalSettings({});
		setInternalSettingsState(res);
		setIsInternalSettingsLoading(false);
	};

	const _refillRedis = async (fillCurrent?: boolean) => {
		setIsRefillRedisLoading(true);
		await refillRedis({ onlyCurrentSpace: fillCurrent });
		setIsRefillRedisLoading(false);
	};

	const _updateSpace = async () => {
		setIsUpdateSpaceLoading(true);
		await updateSpace({ data: editSpaceFormValues, noToast: true });
		setIsUpdateSpaceLoading(false);
	};

	const _updateInternalSettings = async () => {
		setIsInternalSettingsLoading(true);
		const res = await setInternalSettings({ settings: internalSettingsState });
		setInternalSettingsState(res);
		setIsInternalSettingsLoading(false);
	};

	const _setInternalSettingsState = (field: string, value: any) => {
		let val: any = internalSettingsState ? { ...internalSettingsState } : {};
		val[field] = value;
		setInternalSettingsState(val);
	};

	const _renderInternalSettings = () => {
		if (!activeSpace) {
			return null;
		}

		if (!editSpaceFormValues) {
			return (
				<HSCard>
					<Spinner />
				</HSCard>
			);
		}

		return (
			<View>
				<HSCard>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_customattendeelimit`}
						label="Custom Attendee Limit"
						value={internalSettingsState?.customAttendeeLimit?.toString()}
						onChangeText={(value) => _setInternalSettingsState('customAttendeeLimit', Number(value) ?? null)}
						onlyNumbers
						keyboardType="number-pad"
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_customadminlimit`}
						label="Custom Admin Limit"
						value={internalSettingsState?.customAdminLimit?.toString()}
						onChangeText={(value) => _setInternalSettingsState('customAdminLimit', Number(value) ?? null)}
						onlyNumbers
						keyboardType="number-pad"
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_custommoderatorlimit`}
						label="Custom Moderator Limit"
						value={internalSettingsState?.customModeratorLimit?.toString()}
						onChangeText={(value) => _setInternalSettingsState('customModeratorLimit', Number(value) ?? null)}
						onlyNumbers
						keyboardType="number-pad"
					/>
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_reloadinternalsettings`}
							icon={EDefaultIconSet.Reload}
							isLoading={isInternalSettingsLoading}
							isDisabled={isUpdateSpaceLoading || isRefillRedisLoading}
							onPress={_reloadInternalSettings}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_updateinternalsettings`}
							icon={EDefaultIconSet.Save}
							isLoading={isInternalSettingsLoading}
							isDisabled={isUpdateSpaceLoading || isRefillRedisLoading}
							onPress={_updateInternalSettings}
						/>
					</View>
				</HSCard>

				<HSCard>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_cachetimer`}
						label="Content Cache Timer"
						value={editSpaceFormValues.cacheTimerContent?.toString()}
						hint={t('CacheTimerHint')}
						onChangeText={(value) => setEditSpaceFormValues({ ...editSpaceFormValues, cacheTimerContent: Number(value) })}
						onlyNumbers
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_cachetimer`}
						label="Content Load Timer"
						value={editSpaceFormValues.reloadIntervalContent?.toString()}
						hint={t('LoadTimerHint')}
						onChangeText={(value) => setEditSpaceFormValues({ ...editSpaceFormValues, reloadIntervalContent: Number(value) })}
						onlyNumbers
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_cachetimer`}
						label="Attendee Cache Timer"
						value={editSpaceFormValues.cacheTimerAttendee?.toString()}
						hint={t('CacheTimerHint')}
						onChangeText={(value) => setEditSpaceFormValues({ ...editSpaceFormValues, cacheTimerAttendee: Number(value) })}
						onlyNumbers
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_cachetimer`}
						label="Attendee Load Timer"
						value={editSpaceFormValues.reloadIntervalAttendee?.toString()}
						hint={t('LoadTimerHint')}
						onChangeText={(value) => setEditSpaceFormValues({ ...editSpaceFormValues, reloadIntervalAttendee: Number(value) })}
						onlyNumbers
					/>

					<RoundButton
						testID={`${TESTIDPREFIX}_button_updatespace`}
						icon={EDefaultIconSet.Save}
						isLoading={isUpdateSpaceLoading}
						isDisabled={isInternalSettingsLoading || isRefillRedisLoading}
						onPress={_updateSpace}
						alignSelf="flex-end"
					/>
				</HSCard>
			</View>
		);
	};

	return (
		<ScreenContainer>
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<H2>Redis</H2>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_refillredis`}
						onPress={() => {
							setSafetyConsent({ ...safetyConsent, refillRedis: !safetyConsent.refillRedis });
						}}
						value={safetyConsent.refillRedis}
						label="Ich bin mir sicher, dass ich Redis neu füllen möchte"
					/>
					<Button
						onPress={() => {
							const buttons: AlertButton[] = [
								{ text: t('Cancel'), style: 'cancel' },
								{ text: t('refillAllSpacesRedis'), onPress: () => _refillRedis(false) }
							];
							if (activeSpace) {
								buttons.splice(1, 0, { text: t('refillCurrentSpaceRedis'), onPress: () => _refillRedis(true) });
							}

							showAlert({
								title: t('refillRedis'),
								message: activeSpace ? t('refillRedisMessage') : t('refillRedisMessagesAll'),
								buttons
							});
						}}
						testID={`${TESTIDPREFIX}_button_refillredis`}
						title={'Refill Redis'}
						isLoading={isRefillRedisLoading}
						isDisabled={isInternalSettingsLoading || !safetyConsent.refillRedis}
					/>
				</HSCard>

				{_renderInternalSettings()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const HspxAdminScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title="Admin" />
		</NavigationHeader>
	);
};
