import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsTopScreenPadding } from 'config/styleConstants';
import { View } from 'react-native';

import i18next from 'i18next';
import { ISchedule, IUserBooking } from 'config/interfaces';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FlashSectionList } from 'components/List';
import { BookingListHeader, BookingListItem, BOOKINGLISTITEMENTRYHEIGHT } from 'components/Booking';
import { HSCard } from 'components/Card';
import { SearchBar } from 'components/SearchBar';
import { Spinner } from 'components/Spinner';
import { EDefaultIconSet } from 'helper';
import { H2 } from 'components/Text';
import { Button } from 'components/Button';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Features>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Features>;
type RouteParams = StackParamList[ERoutes.Features];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'bookinglist';

export interface IBookingEntry {
	item: ISchedule;
	bookings: IUserBooking[];
}

interface IBookingData {
	header: string;
	items: IBookingEntry[];
}

export const BookingListScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { exportAllBookingsAsCSV } = useExport();

	const [bookingData, setBookingData] = useState<IBookingData[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [searchedBookingData, setSearchedBookingData] = useState<(string | IBookingEntry)[]>([]);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const content = useSelector((store: IRootState) => store.content.content);
	const allBookings = useSelector((store: IRootState) => store.booking.allBookings);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const loadAllBookingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadAllBookingsDelta);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _reloadBookings(),
			isLoading: waitingForSocketResponse?.bookings
		});
	}, [waitingForSocketResponse]);

	useEffect(() => {
		let sections: typeof bookingData = [];

		if (activeSpace && allBookings && content.schedules) {
			content.schedules
				.filter((e) => e.isBookable && e.spaceId === activeSpace.spaceId)
				.forEach((item) => {
					const itemDate = moment(item.startDate).set('hours', 12).set('minutes', 0).set('seconds', 0);
					const idx = sections.findIndex((e) => e.header === itemDate.format('DD.MM.YYYY'));

					const bookings = allBookings.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === item.id);
					if (idx === -1) {
						sections.push({
							header: itemDate.format('DD.MM.YYYY'),
							items: [
								{
									item,
									bookings
								}
							]
						});
					} else {
						sections[idx].items.push({
							item,
							bookings
						});
					}
				});
		}

		sections.sort((a, b) => (moment(a.header, 'DD.MM.YYYY').isBefore(moment(b.header, 'DD.MM.YYYY')) ? -1 : 1));
		sections.forEach((section) => {
			section.items.sort((a, b) => (moment(a.item.startDate).isBefore(moment(b.item.startDate)) ? -1 : 1));
		});

		setBookingData(sections);
	}, [activeSpace, content.schedules, allBookings]);

	useEffect(() => {
		let sections: IBookingData[] = [...bookingData];
		let newSections: typeof searchedBookingData = [];

		if (searchTerm.length >= 3) {
			sections = [];
			const lowerSearch = searchTerm.toLowerCase();

			bookingData.forEach((section) => {
				const items = section.items.filter((e) => {
					if (e.item.title.toLowerCase().includes(lowerSearch)) {
						return true;
					}
					for (const booking of e.bookings) {
						const user = attendees.find((e) => e.userId === booking.userId);

						if (
							user?.firstName.toLowerCase().includes(lowerSearch) ||
							user?.lastName.toLowerCase().includes(lowerSearch) ||
							user?.email.includes(lowerSearch)
						) {
							return true;
						}
					}

					return false;
				});
				if (items.length > 0) {
					sections.push({
						header: section.header,
						items
					});
				}
			});
		}

		sections.forEach((section) => {
			newSections.push(section.header);
			newSections = newSections.concat(section.items);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setSearchedBookingData(newSections);
	}, [attendees, searchTerm, bookingData]);

	const _reloadBookings = async () => {
		loadAllBookingsDelta({});
	};

	const _renderContent = () => {
		if (waitingForSocketResponse?.bookings) {
			return (
				<HSCard style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner />
				</HSCard>
			);
		}

		if (searchedBookingData?.length === 0) {
			return <H2 center> {`${t('No Data')}`}</H2>;
		}

		return (
			<HSCard style={{ flex: 1 }}>
				<FlashSectionList
					data={searchedBookingData}
					extraData={content}
					renderItem={(renderItem) => (
						<BookingListItem
							testID={`${TESTIDPREFIX}_button_booking_${renderItem.index}`}
							entry={renderItem.item}
							onPress={() => {
								navigation.navigate(ERoutes.BookingDetails, {
									spaceId: activeSpace?.spaceId,
									id: renderItem.item.item.id
								});
							}}
						/>
					)}
					renderSectionHeader={(renderItem) => <BookingListHeader text={renderItem.item} />}
					estimatedItemSize={BOOKINGLISTITEMENTRYHEIGHT}
					hideIndexButtons
				/>
			</HSCard>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="bookings">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
					<Button
						testID={`${TESTIDPREFIX}_button_export`}
						title={t('Export Bookings')}
						style={{ marginTop: 20, alignSelf: 'center' }}
						isDisabled={allBookings.length === 0}
						onPress={() => exportAllBookingsAsCSV()}
					/>
				</HSCard>
				{_renderContent()}
			</View>
		</ScreenContainer>
	);
};

export const BookingListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Bookings')} />
			<NavigationHeaderIconButton
				testID="header_button_reload"
				icon={EDefaultIconSet.Reload}
				onPress={props.options.onRightNavPress}
				isLoading={props.options.isLoading}
			/>
		</NavigationHeader>
	);
};
