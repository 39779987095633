import { IVoteOption } from 'config/interfaces';
import { IVoteResult } from 'config/interfaces/voteanswer';

export const PreviewApplauseOptions: IVoteOption[] = [
	{ description: 'Smile', label: 'SimpleLineIcons:emotsmile', order: 0 },
	{ description: 'Heart', label: 'SimpleLineIcons:heart', order: 1 },
	{ description: 'Rocket', label: 'SimpleLineIcons:rocket', order: 2 }
];

export const PreviewApplauseAnswer: IVoteResult = {
	isResultObject: true,
	spaceId: 'testch',
	voteId: 90,
	submissionCount: 7,
	options: [
		{
			key: '56',
			value: 2
		},
		{
			key: '57',
			value: 4
		},
		{
			key: '58',
			value: 1
		}
	]
};
