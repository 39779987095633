import React from 'react';
import { TabSwitcher } from 'components/TabSwitcher';
import { EDefaultIconSet } from 'helper/icon';
import { useSpace } from 'hooks/useSpace';
import { StackParamList } from '../interfaces';
import { ERoutes } from '../routes';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';

type RouteParams = StackParamList[ERoutes.Schedule];

interface INavigationHeaderScheduleTypeButton {
	params: RouteParams;
	onPress: (key) => void;
}

export const NavigationHeaderScheduleTypeButton = (props: INavigationHeaderScheduleTypeButton) => {
	const { params, onPress } = props;
	const { activeSpace } = useSpace();

	const setAgendaViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setAgendaViewType);
	const agendaViewType = useSelector((store: IRootState) => store.config.agendaViewType);

	switch (activeSpace?.agendaViewType) {
		case 'gridOnly':
		case 'listOnly':
			return null;
		case 'gridAndList':
			return (
				<TabSwitcher
					containerStyle={{ marginBottom: 4, marginTop: 'auto' }}
					items={[
						{
							key: 'verticalGrid',
							icon: EDefaultIconSet.Schedule
						},
						{
							key: 'list',
							icon: EDefaultIconSet.List
						}
					]}
					activeKey={agendaViewType ?? 'verticalGrid'}
					onPress={(key) => setAgendaViewType(key)}
				/>
			);

		default:
		case 'listAndGrid':
			return (
				<TabSwitcher
					containerStyle={{ marginBottom: 4, marginTop: 'auto' }}
					items={[
						{
							key: 'list',
							icon: EDefaultIconSet.List
						},
						{
							key: 'verticalGrid',
							icon: EDefaultIconSet.Schedule
						}
					]}
					activeKey={agendaViewType ?? 'list'}
					onPress={(key) => setAgendaViewType(key)}
				/>
			);
	}
};
