import React, { useEffect, useRef, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import Flag from 'react-native-flags';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { ChildButton } from 'components/Button';
import { ISchedule, IScheduleStatus, ISpeaker, IStream, IUserBooking } from 'config/interfaces';
import { EDefaultIconSet, getAgendaSpeakerString, isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { GRID_SCHEDULE_BORDER_RADIUS, GRID_SCHEDULE_INNER_HORIZONTAL_PADDING, GRID_SCHEDULE_LEFT_BORDER_WIDTH } from '../constants';
import { Text } from 'components/Text';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { AnimatedLive } from 'components/Animated';
import { useSpace } from 'hooks/useSpace';
import { InfoPill } from 'components/InfoPill';
import { Icon } from 'components/Icon';

interface IVerticalGridItemCell {
	item: ISchedule;
	style: ViewStyle;
	onPress?: () => void;
	backgroundColor?: string;
	textColor?: string;
	isPublic?: boolean;
	isMyDay?: boolean;
}

interface IStateContent {
	speakers: ISpeaker[];
	streams: IStream[];
	status: IScheduleStatus | undefined;
}

export const VerticalGridItemCell = (props: IVerticalGridItemCell) => {
	const { item, style, onPress, backgroundColor, textColor, isPublic, isMyDay } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const viewRef = useRef<View>(null);

	const [stateContent, setStateContent] = useState<IStateContent>({ speakers: [], streams: [], status: undefined });

	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [isBooked, setIsBooked] = useState<boolean>(false);
	const [bookedSlots, setBookedSlots] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);
	const bookings = useSelector((store: IRootState) => store.booking.bookings);
	const allBookingCounts = useSelector((store: IRootState) => store.booking.bookingCounts);

	useEffect(() => {
		let _isBooked = false;

		if (activeSpace && bookings) {
			_isBooked = bookings.find((e) => e.spaceId === activeSpace.spaceId && e.itemId === item?.id && item?.isBookable) !== undefined;
		}

		setIsBooked(_isBooked);
	}, [activeSpace, bookings]);

	useEffect(() => {
		let _bookedSlots = 0;

		if (activeSpace && item.isBookable && allBookingCounts) {
			_bookedSlots = allBookingCounts.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === item.id)?.length ?? 0;
		}

		if (_bookedSlots !== bookedSlots) {
			setBookedSlots(_bookedSlots);
		}
	}, [activeSpace, item, allBookingCounts]);

	useEffect(() => {
		let _speakers: ISpeaker[] = [];
		let _streams: IStream[] = [];
		let _scheduleStatus: IScheduleStatus | undefined = undefined;

		if (isPublic) {
			if (item.speakers) {
				_speakers = item.speakers;

				if (item.status) {
					_scheduleStatus = item.status;
				}
			}
		} else {
			if (content.speakers && item.speakers) {
				item.speakers.forEach((speaker) => {
					const _sp = content.speakers.find((sp) => sp.id === speaker.id);
					if (_sp) {
						_speakers.push(_sp);
					}
				});
			}

			if (content.schedulestatuses) {
				_scheduleStatus = content.schedulestatuses.find((e) => e.schedule?.id === item.id);
			}

			if (content.streams && item.stream) {
				if (Array.isArray(item.stream)) {
					item.stream.forEach((stream) => {
						const _st = content.streams.find((st) => st.id === stream.id);
						if (_st) {
							_streams.push(_st);
						}
					});
				} else if (typeof item.stream === 'number') {
					const _st = content.streams.find((st) => st.id === item.stream);
					if (_st) {
						_streams.push(_st);
					}
				} else {
					const _st = content.streams.find((st) => st.id === item.stream?.id);
					if (_st) {
						_streams.push(_st);
					}
				}
			}
		}

		setStateContent({
			speakers: _speakers,
			streams: _streams,
			status: _scheduleStatus
		});
	}, [item, content]);

	const _renderSpeakers = () => {
		if (stateContent.speakers.length > 0) {
			const elements: JSX.Element[] = [];
			const unhandledSpeakers: ISpeaker[] = [];

			if ((item.speakerGroups?.groups ?? []).length > 0) {
				item.speakerGroups?.groups.forEach((group) => {
					const _groupSpeakers = stateContent.speakers.filter((e) => group.speakerIds.includes(e.id));
					const _speakerStrings = _groupSpeakers.map((e) => getAgendaSpeakerString(e));

					const textStyle = { fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text };

					elements.push(
						<Text key={`speakerstring_${group.key}`} bold style={textStyle}>
							{`${group.title}: `}
							<Text style={textStyle}>{_speakerStrings.join(', ')}</Text>
						</Text>
					);
				});
			}

			stateContent.speakers.forEach((speaker) => {
				const foundInGroup = item.speakerGroups?.groups.find((e) => e.speakerIds.includes(speaker.id));
				if (!foundInGroup) {
					unhandledSpeakers.push(speaker);
				}
			});

			if (unhandledSpeakers.length > 0) {
				const _speakerStrings = unhandledSpeakers.map((e) => getAgendaSpeakerString(e));
				elements.push(
					<Text
						key={`speakerstring_${item.id}`}
						style={{ fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text }}
					>
						{_speakerStrings.join(', ')}
					</Text>
				);
			}

			return <View style={{ marginTop: 10 }}>{elements}</View>;
		}

		return null;
	};

	const _renderLanguages = () => {
		let _languages: string[] = [];

		if (item.languages) {
			const _itemLanguages = item.languages.split(',');
			_languages = _languages.concat(_itemLanguages);
		} else {
			stateContent.streams.forEach((s) => {
				if (s.languages && !isEmptyString(s.languages)) {
					const _streamLanguages = s.languages.split(',');
					_languages = _languages.concat(_streamLanguages);
				}
			});
		}

		if (_languages.length > 0) {
			return (
				<View style={{ flexDirection: 'row' }}>
					{_languages.map((flag) => (
						<View key={`grid_schedule_${item.id}_flag_${flag}`} style={{ paddingRight: 2 }}>
							<Flag code={flag} size={16} />
						</View>
					))}
				</View>
			);
		}

		return null;
	};

	const _renderBooking = () => {
		if (!item.bookingCapacity) {
			return null;
		}

		if (isBooked) {
			return <InfoPill text={t('Booked')} backgroundColor={theme.success} textColor={theme.white} />;
		}

		if (item?.isBookable) {
			if (item?.bookingCapacity !== undefined && item?.bookingCapacity !== null && bookedSlots >= item.bookingCapacity) {
				return (
					<InfoPill
						text={t('Fully booked')}
						borderColor={theme.danger}
						textColor={!isEmptyString(item.textColor) ? item.textColor : theme.danger}
					/>
				);
			}

			const bookingPercent = bookedSlots / item.bookingCapacity;
			let pillColor = !isEmptyString(item.textColor) ? item.textColor : !isEmptyString(textColor) ? textColor : theme.text;

			if (bookingPercent <= 0.25) {
				pillColor = theme.warning;
			}

			return <InfoPill text={t('Bookable')} borderColor={pillColor} textColor={pillColor} />;
		}

		return null;
	};

	return (
		<ChildButton
			key={`grid_schedule_${item.id}`}
			testID={`grid_schedule_${item.id}`}
			activeOpacity={1}
			style={[
				style,
				{
					paddingRight: GRID_SCHEDULE_INNER_HORIZONTAL_PADDING,
					overflow: isHovered ? 'visible' : 'hidden',
					height: isHovered ? undefined : style.height,
					zIndex: isHovered ? 10 : undefined
				}
			]}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View
				ref={viewRef}
				style={{ height: '100%', width: '100%' }}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<View
					style={{
						width: '100%',
						height: isHovered ? undefined : '100%',
						minHeight: style.height,
						paddingVertical: 5,
						paddingHorizontal: 10,
						backgroundColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: theme.contentBackgroundColor ?? theme.background,
						borderWidth: 1,
						borderLeftWidth: GRID_SCHEDULE_LEFT_BORDER_WIDTH,
						borderRadius: GRID_SCHEDULE_BORDER_RADIUS,
						borderColor: theme.lightgray,
						borderLeftColor: !isEmptyString(item.backgroundColor)
							? item.backgroundColor
							: !isEmptyString(backgroundColor)
							? backgroundColor
							: theme.primary,
						overflow: 'hidden'
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 5
						}}
					>
						<Text
							style={{
								color: !isEmptyString(item.textColor) ? item.textColor : !isEmptyString(textColor) ? textColor : theme.text,
								fontSize: 10
							}}
						>{`${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('HH:mm')}`}</Text>
						{!isPublic && !item.isBookable && (
							<FavoriteButton testID={`griditemcell_${item.id}_button_favorite`} type="schedule" id={item.id} size="grid" />
						)}
					</View>
					<View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
						{isMyDay && item.stage && (
							<View style={{ width: '100%' }}>
								<Text
									style={{
										color: !isEmptyString(item.textColor)
											? item.textColor
											: !isEmptyString(textColor)
											? textColor
											: theme.text,
										fontSize: 10,
										marginBottom: 5,
										marginTop: profile?.favorites?.schedule && profile.favorites.schedule[item.id] ? -5 : 0
									}}
								>
									{`${t('Stage')}: ${item.stage.title}`}
								</Text>
							</View>
						)}
						{stateContent.status ? (
							<AnimatedLive
								isVisible={stateContent.status.status === 'Live'}
								containerStyle={{ position: 'relative', marginBottom: 5 }}
							/>
						) : null}
						{_renderBooking()}
					</View>
					<View style={{ flexDirection: 'row' }}>
						<Text
							bold
							style={{
								color: !isEmptyString(item.textColor) ? item.textColor : !isEmptyString(textColor) ? textColor : theme.text,
								fontSize: 14,
								marginRight: item.isHidden ? 5 : undefined
							}}
						>
							{item.title}
						</Text>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					{!isEmptyString(item.subtitle) && (
						<Text
							style={{
								marginTop: 5,
								color: !isEmptyString(item.textColor) ? item.textColor : !isEmptyString(textColor) ? textColor : theme.text,
								fontSize: 10
							}}
						>
							{item.subtitle}
						</Text>
					)}
					{_renderLanguages()}
					{_renderSpeakers()}
				</View>
			</View>
		</ChildButton>
	);
};
