import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

import { IVote } from 'config/interfaces/vote';
import { View } from 'react-native';
import { isEmptyString } from 'helper';
import { VoteStatus } from '../VoteStatus';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { NumberPresenter } from 'components/Info';
import { hsBottomMargin } from 'config/styleConstants';
import { VoteQuizAnalytics } from './VoteQuizAnalytics';
import { useSpace } from 'hooks/useSpace';

interface IVoteAnalytics {
	vote?: IVote;
}

export const VoteAnalytics = (props: IVoteAnalytics) => {
	const { vote } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [answerAmount, setAnswerAmount] = useState<number>(0);
	const [uniqueUser, setUniqueUser] = useState<number>(0);
	const [answers, setAnswers] = useState<IVoteAnswer[]>([]);

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);

	useEffect(() => {
		if (activeSpace && voteAnswers && vote) {
			const _voteanswers = voteAnswers?.filter((e) => e.spaceId === activeSpace.spaceId && !e.isDeleted);
			// get answer amount
			const _answerAmount =
				_voteanswers?.filter((e) => e.spaceId === activeSpace.spaceId && e.voteId === vote.id && !isEmptyString(e.value))?.length ??
				0;
			setAnswerAmount(_answerAmount);

			// get unique user
			const _userIDs: string[] = [];
			_voteanswers.forEach((e) => {
				if (e.userId) {
					const idx = _userIDs.findIndex((u) => u === e.userId);
					if (idx === -1) {
						_userIDs.push(e.userId);
					}
				}
			});
			setUniqueUser(_userIDs.length);

			const _answersForThis = voteAnswers?.filter(
				(e) => !e.isDeleted && e.spaceId === activeSpace.spaceId && e.voteId === vote.id && !isEmptyString(e.value)
			);
			setAnswers(_answersForThis);
		}
	}, [activeSpace, voteAnswers, vote]);

	const _renderUniqueUser = () => {
		switch (vote?.votingType) {
			case 'applause':
			case 'text':
				return <NumberPresenter number={uniqueUser} title={t('uniqueUser')} />;
			default:
				return null;
		}
	};

	const _renderAnalyticsByType = () => {
		switch (vote?.votingType) {
			case 'quiz':
				return <VoteQuizAnalytics answers={answers} />;
			default:
				return null;
		}
	};

	if (vote) {
		return (
			<View style={{ marginTop: hsBottomMargin }}>
				<NumberPresenter number={answerAmount} title={t('answerAmount')} />
				{vote?.isActive ? <VoteStatus testID="votestatus" status="live" label={t('VoteIsLive')} /> : null}
				{_renderUniqueUser()}
				{_renderAnalyticsByType()}
			</View>
		);
	}

	return null;
};
