import { logger } from 'react-native-logs';

const log = logger.createLogger({
	levels: {
		debug: 0,
		info: 1,
		warn: 2,
		error: 3,
		success: 4,
		should_load: 5,
		load_begin: 6,
		load_aborted: 7,
		load_done: 8
	},
	transportOptions: {
		colors: {
			success: 'green',
			debug: 'grey',
			info: 'blue',
			warn: 'yellow',
			error: 'red',
			should_load: 'magenta',
			load_begin: 'cyan',
			load_aborted: 'yellow',
			load_done: 'green'
		},
		extensionColors: {
			success: 'green',
			should_load: 'magenta',
			load_begin: 'cyan',
			load_aborted: 'yellow',
			load_done: 'green'
		}
	}
});

const shouldRematchLog = false;
const shouldLogContentLoad = false;

const rematchLog = (...args: unknown[]) => {
	if (shouldRematchLog) {
		warnLog(args);
	}
};

const contentLoadLog = (level: number, args: unknown[]) => {
	if (shouldLogContentLoad && __DEV__) {
		switch (level) {
			case 1:
				log.should_load(args);
				break;
			case 2:
				log.load_begin(args);
				break;
			case 3:
				log.load_aborted(args);
				break;
			case 4:
				log.load_done(args);
				break;
			default:
				break;
		}
	}
};

const successLog = (...args: unknown[]) => {
	if (__DEV__) {
		log.success(args);
	}
};

const debugLog = (...args: unknown[]) => {
	if (__DEV__) {
		log.debug(args);
	}
};

const infoLog = (...args: unknown[]) => {
	if (__DEV__) {
		log.info(args);
	}
};

const warnLog = (...args: unknown[]) => {
	if (__DEV__) {
		log.warn(args);
	}
};

const errorLog = (...args: unknown[]) => {
	if (__DEV__) {
		log.error(args);
	}
};

const profilerCallback = (
	id: string,
	phase: 'mount' | 'update',
	actualDuration: number,
	baseDuration: number,
	startTime: number,
	commitTime: number,
	interactions: any
) => {
	infoLog(id, phase, actualDuration, baseDuration, startTime, commitTime, interactions);
};

globalThis['successLog'] = successLog;
globalThis['debugLog'] = debugLog;
globalThis['infoLog'] = infoLog;
globalThis['warnLog'] = warnLog;
globalThis['errorLog'] = errorLog;
globalThis['rematchLog'] = rematchLog;
globalThis['contentLoadLog'] = contentLoadLog;
globalThis['profilerCallback'] = profilerCallback;
