import { ContentFlowList } from 'components/Contentflow';
import { HSModal } from 'components/Modal/Modal';
import { IContentFlowMediaEntry } from 'config/interfaces';
import React from 'react';

interface IContentFlowVideoPickerModal {
	isVisible: boolean;
	onSubmit: (val: IContentFlowMediaEntry) => void;
	onClose: () => void;
	selectedUrl?: string;
}

const TESTIDPREFIX = 'contentflowvideopickermodal';

export const ContentFlowVideoPickerModal = (props: IContentFlowVideoPickerModal) => {
	const { isVisible, onSubmit, onClose, selectedUrl } = props;

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title="Contentflow">
			<ContentFlowList
				testID={TESTIDPREFIX}
				type="video"
				selectedUrls={selectedUrl ? [selectedUrl] : []}
				onSelect={(item) => onSubmit(item)}
			/>
		</HSModal>
	);
};
