import React, { useEffect, useState } from 'react';
import { FormTextInput } from 'components/Form';
import { FormElementBottomMargin } from 'components/Form/constants';
import { Text } from 'components/Text';
import { hsBottomMargin } from 'config/styleConstants';
import { addToClipboard, EDefaultIconSet, isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { Keyboard, ScrollView, View } from 'react-native';
import { RoundButton } from 'components/Button';

interface IExpoContactModal {
	isVisible: boolean;
	onClose: () => void;
	testIdPrefix: string;
	id: number;
	type: string;
	title: string;
	expoMail?: string;
}

export const ExpoContactModal = (props: IExpoContactModal) => {
	const { isVisible, onClose, testIdPrefix, id, type, title, expoMail } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);

	const sendUserMail = useRematchDispatch((dispatch: Dispatch) => dispatch.content.sendUserMail);

	const [values, setValues] = useState({
		subject: '',
		text: '',
		email: '',
		name: ''
	});

	useEffect(() => {
		if (isVisible) {
			if (profile) {
				let _values = values;
				if (isEmptyString(_values.email && profile?.email)) {
					_values.email = profile.email;
				}
				if (isEmptyString(_values.subject) && activeSpace) {
					_values.subject = `${profile.firstName} ${profile.lastName} - ${activeSpace.title}`;
				}
				if (isEmptyString(_values.text) && title) {
					_values.text = `${t('Hello')} ${title},



${t('Kind regards')}
${profile.firstName}${isEmptyString(profile.firstName) ? '' : ' '}${profile.lastName}`;
				}
				setValues({ ..._values });
				return;
			}
			if (userInfos) {
				let _values = values;
				if (isEmptyString(_values.email && userInfos?.email)) {
					_values.email = userInfos.email;
				}
				if (isEmptyString(_values.subject) && activeSpace) {
					_values.subject = `${userInfos.firstName} ${userInfos.lastName} - ${activeSpace.title}`;
				}
				if (isEmptyString(_values.text) && title) {
					_values.text = `${t('Hello')} ${title},



${t('Kind regards')}
${userInfos.firstName}${isEmptyString(userInfos.firstName) ? '' : ' '}${userInfos.lastName}`;
				}
				setValues({ ..._values });
				return;
			}
		} else {
			setValues({
				subject: '',
				text: '',
				email: values.email,
				name: values.name
			});
		}
	}, [userInfos, profile, title, isVisible]);

	const _sendMail = async () => {
		setIsLoading(true);
		let lastname: string | undefined = undefined;
		let firstName: string | undefined = undefined;
		if (!(profile?.firstName || userInfos.firstName) && !(profile?.lastName || userInfos.lastName)) {
			const splitted = values.name.split(' ');
			firstName = splitted.splice(0, splitted.length - 2).join(' ');
			lastname = splitted[splitted.length - 1];
		}
		if (id > 0) {
			const res = await sendUserMail({
				subject: values.subject,
				body: values.text,
				type: type,
				id: id,
				userMail: values.email,
				userFirstname: firstName,
				userLastname: lastname
			});
			if (res) {
				trackAction('expo', 'Open Email', id.toString());
				onClose();
			}
		}
		setIsLoading(false);
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			title={t('contactContentTypeModalHeader')}
			onSubmit={_sendMail}
			isSubmitLoading={isLoading}
			isSubmitDisabled={
				isEmptyString(values.subject) ||
				isEmptyString(values.email) ||
				isEmptyString(values.text) ||
				(!profile && isEmptyString(values.name))
			}
		>
			<Text style={{ marginBottom: hsBottomMargin }}>
				{t('contactContentTypeModalText').replace('%EXPO%', title).replace('%EXPO%', title)}
			</Text>
			<ScrollView keyboardShouldPersistTaps="handled" onScrollBeginDrag={() => Keyboard.dismiss()} scrollEventThrottle={0}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						marginBottom: FormElementBottomMargin
					}}
				>
					<FormTextInput
						label={t('email')}
						isRequired
						isDisabled
						selectTextOnFocus
						testID={`${testIdPrefix}_textinput_email`}
						value={expoMail}
						formStyle={{ flex: 1, marginBottom: 0 }}
					/>

					<View style={{ paddingLeft: 10 }}>
						<RoundButton
							testID={`${testIdPrefix}_button_copymail`}
							icon={EDefaultIconSet.Clone}
							onPress={() => addToClipboard(values.email)}
							size="sm"
							isStacked
							isOutline
						/>
					</View>
				</View>

				{!(profile?.firstName || userInfos.firstName) && !(profile?.lastName || userInfos.lastName) && (
					<FormTextInput
						isDisabled={isLoading}
						editable={false}
						label={t('Name')}
						isRequired
						testID={`${testIdPrefix}_textinput_name`}
						value={values.name}
						onChangeText={(val) => setValues({ ...values, name: val })}
					/>
				)}
				<FormTextInput
					isDisabled={isLoading}
					label={t('Subject')}
					isRequired
					hint={t('expoSubjectHint')}
					testID={`${testIdPrefix}_textinput_subject`}
					value={values.subject}
					onChangeText={(val) => setValues({ ...values, subject: val })}
				/>
				<FormTextInput
					isDisabled={isLoading}
					multiline
					label={t('EmailBody')}
					isRequired
					hint={t('expoTextHint').replace('%EXPO%', title)}
					testID={`${testIdPrefix}_textinput_description`}
					value={values.text}
					onChangeText={(val) => setValues({ ...values, text: val })}
				/>
			</ScrollView>
		</HSModal>
	);
};
