import React from 'react';
import { ISpaceForgotPasswordFormErrors, ISpaceForgotPasswordFormValues } from 'config/interfaces';
import { FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface ISpaceForgotPasswordForm {
	values: ISpaceForgotPasswordFormValues;
	errors: ISpaceForgotPasswordFormErrors;
	onChange: (val: ISpaceForgotPasswordFormValues) => void;
	onSubmit?: () => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'forgotpwform';

export const SpaceForgotPasswordForm = (props: ISpaceForgotPasswordForm) => {
	const { values, errors, onChange, onSubmit, isLoading } = props;
	const { t } = useTranslation();

	return (
		<View>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_email`}
				label={t('email')}
				value={values.email}
				error={errors.email}
				onChangeText={(value) => onChange({ ...values, email: value })}
				autoCompleteType="email"
				keyboardType="email-address"
				isRequired
				skipTabPress
			/>
		</View>
	);
};
