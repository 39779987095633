import { ITabSwitcherButton } from 'config/interfaces';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import { TabSwitcherButton } from './TabSwitcherButton';

interface ITabSwitcher {
	items: ITabSwitcherButton[];
	onPress: (key: string) => void;
	activeKey?: string;
	containerStyle?: ViewStyle;
}

export const TabSwitcher = (props: ITabSwitcher) => {
	const { items, onPress, activeKey, containerStyle } = props;

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'center',
				marginBottom: 20,
				...containerStyle
			}}
		>
			{items?.map((item, idx) => {
				return (
					<TabSwitcherButton
						key={item.key}
						testID={`tabswitcher_button_${item.key}`}
						label={item.label}
						icon={item.icon}
						isActive={activeKey !== undefined && activeKey === item.key}
						isFirst={idx === 0}
						isLast={idx === items.length - 1}
						onPress={() => onPress(item.key)}
					/>
				);
			})}
		</View>
	);
};
