import React from 'react';
import { VotePreview } from 'components/VotePreview';
import { ICreateVoteFormValues, IEditVoteFormValues } from 'config/interfaces';
import { IVote } from 'config/interfaces/vote';
import { HSModal } from '../Modal';

interface IPreviewVoteModal {
	isVisible: boolean;
	onClose: () => void;
	vote: ICreateVoteFormValues | IEditVoteFormValues | IVote;
}

export const PreviewVoteModal = (props: IPreviewVoteModal) => {
	const { isVisible, onClose, vote } = props;

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			<VotePreview item={vote} isModal onClose={onClose} />
		</HSModal>
	);
};
