import React from 'react';
import { View } from 'react-native';

import { ICreateWebhookFormValues, IUpdateWebhookFormValues, IWebhookFormErrors } from 'config/interfaces';
import { HSCard } from 'components/Card/HSCard';
import { FormCheckbox, FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { FormLabel } from 'components/Form/FormLabel';
import { FormHint } from 'components/Form/FormHint';
import { FormError } from 'components/Form/FormError';
import { H3 } from 'components/Text';

interface IWebhookForm {
	values: ICreateWebhookFormValues | IUpdateWebhookFormValues;
	errors: IWebhookFormErrors;
	onChange: (val: ICreateWebhookFormValues | IUpdateWebhookFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'webhookform';

export const WebhookForm = (props: IWebhookForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { t } = useTranslation();

	const _updateEvents = (key: string) => {
		let splitted = values.events ? values.events.split(',') : [];

		if (splitted.includes(key)) {
			splitted = splitted.filter((e) => e !== key);
		} else {
			splitted.push(key);
		}

		onChange({ ...values, events: splitted.join(',') });
	};

	const _isEventActive = (key: string) => {
		const splitted = values.events ? values.events.split(',') : [];

		return splitted.includes(key);
	};

	const _renderEventCheckbox = (key: string, label: string) => {
		return (
			<FormCheckbox
				testID={`${TESTIDPREFIX}_checkbox_${key}`}
				label={label}
				value={_isEventActive(key)}
				onPress={() => _updateEvents(key)}
			/>
		);
	};

	const _renderSectionLabel = (label: string) => {
		return <H3>{label}</H3>;
	};

	return (
		<View testID="webhookedit_card_scrollview">
			<HSCard>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_title`}
					label={t('Title')}
					isRequired
					value={values.title}
					hint={t('WebhookFormTitleHint')}
					onChangeText={(title) => onChange({ ...values, title })}
					error={errors.title}
					isDisabled={isLoading}
				/>
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_isactive`}
					label={t('WebhookIsActive')}
					hint={t('WebhookFormIsActiveHint')}
					value={values.isActive}
					error={errors.isActive}
					onPress={() => onChange({ ...values, isActive: !values.isActive })}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_url`}
					label={t('URL')}
					isRequired
					value={values.url}
					hint={t('WebhookFormURLHint')}
					onChangeText={(url) => onChange({ ...values, url })}
					error={errors.url}
					isDisabled={isLoading}
					keyboardType="url"
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_header`}
					label={t('Request Header')}
					value={values.header}
					hint={t('WebhookFormHeadersHint')}
					onChangeText={(header) => onChange({ ...values, header })}
					error={errors.header}
					isDisabled={isLoading}
					multiline
				/>

				<FormLabel testID={`${TESTIDPREFIX}_checkbox_events`} label="Events" isRequired />
				<FormHint testID={`${TESTIDPREFIX}_checkbox_events`} hint={t('WebhookFormEventsHint')} />
				{_renderSectionLabel('User')}
				{_renderEventCheckbox('user.joinedspace', t('WebhookUserJoinedSpace'))}
				{_renderSectionLabel(t('SupportRequests'))}
				{_renderEventCheckbox('supportrequest.create', t('Created'))}
				{_renderEventCheckbox('supportrequest.update', t('Updated'))}
				{_renderSectionLabel(t('Bookings'))}
				{_renderEventCheckbox('booking.create', t('Booked'))}
				{_renderEventCheckbox('booking.cancel', t('BookingCancelled'))}
				{_renderSectionLabel(t('Self Service'))}
				{_renderEventCheckbox('selfservice.speakerupdate', t('SpeakerUpdated'))}
				{_renderEventCheckbox('selfservice.expoupdate', t('ExpoUpdated'))}
				<FormError testID={`${TESTIDPREFIX}_checkbox_events`} error={errors.events} />
			</HSCard>
		</View>
	);
};
