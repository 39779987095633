import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { NAVFONTSIZE } from 'components/Form/constants';

interface INavigationHeaderTextButtonComponent {
	testID: string;
	onPress?: Function;
	goBack?: boolean;
	text?: string;
	isLoading?: boolean;
}

export const NavigationHeaderTextButton = (props: INavigationHeaderTextButtonComponent) => {
	const { testID, onPress, goBack, text, isLoading } = props;
	const navigation = useNavigation();
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={testID}
			onPress={() => {
				if (onPress) {
					onPress();
				}
				if (goBack) {
					navigation.goBack();
				}
			}}
			style={{ padding: 5 }}
			isLoading={isLoading}
		>
			<Text style={{ color: theme.navbarButton, fontSize: NAVFONTSIZE }}>{text}</Text>
		</ChildButton>
	);
};
