import * as Yup from 'yup';
import { ISpaceForgotPasswordFormValues, ISpaceLoginFormValues, ISpaceResetPasswordFormValues } from 'config/interfaces';
import i18next from 'i18next';
import { getEmailError, getMinError, getRequiredError } from './errors';

export const getSpaceForgotPasswordFormSchema = (item: ISpaceForgotPasswordFormValues) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(getEmailError('E-Mail'))
			.required(getRequiredError(i18next.t('E-Mail')))
	});
};

export const getSpaceResetPasswordFormSchema = (item: ISpaceResetPasswordFormValues) => {
	return Yup.object().shape({
		password: Yup.string()
			.min(6, getMinError(6))
			.required(getRequiredError(i18next.t('password'))),
		passwordConfirmation: Yup.string()
			.min(6, getMinError(6))
			.test('passwordEQ', i18next.t('PWEQ'), () => item.password === item.passwordConfirmation)
			.required(getRequiredError(i18next.t('passwordConfirmation')))
	});
};

export const getSpaceLoginFormSchema = (item: ISpaceLoginFormValues) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(getEmailError('E-Mail'))
			.required(getRequiredError(i18next.t('E-Mail'))),
		password: Yup.string()
			.min(6)
			.required(getRequiredError(i18next.t('password')))
	});
};
