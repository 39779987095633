import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from 'components/Form/FormTextInput';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, getValidURL, isEmptyString } from 'helper';
import { IFramePlayer } from 'components/Video';
import * as Yup from 'yup';
import { showToast } from 'helper/toast';

interface IStreamInputIFrame {
	testID: string;
	onChange: (vimeoUrl: string | null) => void;
	value?: string;
	error?: string;
}

export const StreamInputIFrame = (props: IStreamInputIFrame) => {
	const { testID, onChange, value, error } = props;
	const { t } = useTranslation();

	const [input, setInput] = useState<string>('');
	const [validatedUrl, setValidatedUrl] = useState<string | undefined>(undefined);
	const [urlError, setUrlError] = useState<string | undefined>(undefined);
	const [showPlayer, setShowPlayer] = useState<boolean>(false);

	useEffect(() => {
		if (value) {
			setInput(value);
			setValidatedUrl(value);
		}
	}, [value]);

	useEffect(() => {
		setShowPlayer(false);
	}, [input]);

	const _validateUrl = async () => {
		if (input?.toLowerCase().includes('zoom.us')) {
			showToast(
				'error',
				t('notSupportedByIframeError').replace('%EXTERNAL%', 'Zoom'),
				t('notSupportedByIframeErrorSubtitle').replace('%EXTERNAL%', 'Zoom')
			);
			setUrlError(`${t('notSupportedByIframeErrorSubtitle').replace('%EXTERNAL%', 'Zoom')} ${t('goBackAndChangeTypeErrorHint')}`);
			return;
		}
		if (input?.toLowerCase().includes('teams.microsoft')) {
			showToast(
				'error',
				t('notSupportedByIframeError').replace('%EXTERNAL%', 'Teams'),
				t('notSupportedByIframeErrorSubtitle').replace('%EXTERNAL%', 'Teams')
			);
			setUrlError(`${t('notSupportedByIframeErrorSubtitle').replace('%EXTERNAL%', 'Teams')} ${t('goBackAndChangeTypeErrorHint')}`);
			return;
		}

		let _input = input;

		if (!isEmptyString(_input)) {
			_input = getValidURL(_input);
			setInput(_input);
		}

		const schema = Yup.string().required().url().nullable();
		const res = await schema.isValid(_input);
		if (res) {
			setValidatedUrl(_input);
			onChange(_input);
			setShowPlayer(true);
		} else {
			showToast('error', t('Invalid URL'), t('Invalid URL Subtitle'));
		}
	};

	return (
		<View>
			<FormTextInput
				testID={`${testID}_textinput_url`}
				label="URL"
				isRequired
				hint={t('IFrameUrlHint')}
				value={input}
				error={urlError ?? error}
				onChangeText={(val) => {
					setInput(val);
					setValidatedUrl(val);
					onChange(null);
				}}
				formStyle={{ marginBottom: 10 }}
			/>
			<RoundButton
				testID={`${testID}_button_validate`}
				icon={EDefaultIconSet.Save}
				onPress={_validateUrl}
				title={t('Validate')}
				isDisabled={isEmptyString(input)}
				alignSelf="flex-end"
			/>
			{showPlayer && <IFramePlayer url={validatedUrl} />}
		</View>
	);
};
