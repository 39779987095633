import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper';

interface ITabbarBadge {
	value?: number | string;
	top?: number;
	left?: number;
	right?: number;
	textBadge?: boolean;
	size?: 'sm' | undefined;
}

export const TabbarBadge = (props: ITabbarBadge) => {
	const { value, top, left, right, textBadge, size } = props;
	const { theme } = useTheme();

	const animatedOpacityValue = useRef(new Animated.Value(0));

	useEffect(() => {
		if (value) {
			Animated.timing(animatedOpacityValue.current, {
				toValue: 1,
				duration: 100,
				useNativeDriver: true
			}).start();
		} else {
			Animated.timing(animatedOpacityValue.current, {
				toValue: 0,
				duration: 100,
				useNativeDriver: true
			}).start();
		}
	}, [value]);

	if (textBadge) {
		return (
			<Animated.View
				style={{
					opacity: animatedOpacityValue.current,
					backgroundColor: theme.danger,
					paddingHorizontal: value === '!' ? 10 : 6,
					paddingVertical: 2,
					marginRight: 6,
					borderRadius: 99
				}}
			>
				<Text style={{ color: theme.white, fontSize: size === 'sm' ? normalizeFontSize(10) : normalizeFontSize(12) }}>{value}</Text>
			</Animated.View>
		);
	}

	return (
		<Animated.View
			style={{
				opacity: animatedOpacityValue.current,
				position: 'absolute',
				right: right !== undefined ? right : left ? undefined : -15,
				left,
				top: top ?? -10,
				backgroundColor: left ? theme.warning : theme.danger,
				paddingHorizontal: value === '!' ? 10 : 6,
				paddingVertical: 2,
				borderRadius: 999
			}}
		>
			<Text style={{ color: theme.white, fontSize: size === 'sm' ? normalizeFontSize(10) : normalizeFontSize(12) }}>{value}</Text>
		</Animated.View>
	);
};
