import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { HSHeaderCard } from 'components/Card/HSHeaderCard';
import { H3 } from 'components/Text';
import { hsBottomMargin } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useQuery } from 'hooks/useQuery';

interface IConfirmationView {
	isModal?: boolean;
}

export const UserConfirmationView = (props: IConfirmationView) => {
	const { isModal } = props;
	const { t } = useTranslation();

	const [isResendConfirmationLoading, setIsResendConfirmationLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const resendConfirmationLink = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.resendConfirmationLink);
	const { screenWidth } = useQuery();

	const _resendConfirmationLink = async () => {
		setIsResendConfirmationLoading(true);
		await resendConfirmationLink({});
		setIsResendConfirmationLoading(false);
	};

	return (
		<View
			style={{
				paddingTop: hsBottomMargin,
				alignSelf: 'center',
				width: isModal ? '100%' : screenWidth,
				flexGrow: 1
			}}
		>
			<HSHeaderCard
				title={t('ConfirmationRequiredTitle')}
				subtitle={
					isModal
						? t('ConfirmationRequiredSubtitleJoinSpace').replace('%EMAIL%', profile?.email ?? '')
						: t('ConfirmationRequiredSubtitleCreateSpace').replace('%EMAIL%', profile?.email ?? '')
				}
			/>
			<HSCard style={{ alignItems: 'center' }}>
				<H3>{t('CTAResendConfirmation')}</H3>
				<RoundButton
					testID="spacecreate_button_resendconfirmation"
					icon={EDefaultIconSet.Mail}
					title={t('Resend Confirmation')}
					onPress={() => _resendConfirmationLink()}
					isLoading={isResendConfirmationLoading}
					alignSelf="center"
					isStacked
				/>
			</HSCard>
		</View>
	);
};
