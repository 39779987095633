import React from 'react';
import { normalizeFontSize } from 'helper';
import { TFunctionResult } from 'i18next';
import { Text } from './Text';

interface IListItemTitle {
	children: string | TFunctionResult;
}

export const ListItemTitle = (props: IListItemTitle) => {
	const { children } = props;

	return (
		<Text bold style={{ fontSize: normalizeFontSize(16), marginBottom: 5 }}>
			{children}
		</Text>
	);
};
