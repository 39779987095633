import { useNavigation } from '@react-navigation/core';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormSwitch } from 'components/Form/FormSwitch';
import { FormTextInput } from 'components/Form/FormTextInput';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H3 } from 'components/Text';
import { Text } from 'components/Text/Text';
import { ISurveyAnswerValue } from 'config/interfaces';
import { IVote } from 'config/interfaces/vote';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { previewVotingsSurvey } from 'helper/previewVotings/previewVotingsSurvey';
import { byOrder } from 'helper/sort';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { VoteMultipleChoiceOption } from '../VoteMultipleChoice';
import { NpsScale } from '../VoteNps';
import { Rating } from '../VoteRating/Rating';
import { VoteScaleSlider } from '../VoteScale/VoteScaleSlider';
import { WordCloudForm, WORDCLOUDSPLITSEQUENCE } from '../VoteWordCloud';
import { WordCloud } from '../VoteWordCloud/WordCloud';
import { SurveyReport } from './SurveyReport';

interface IVoteSurvey {
	vote: IVote;
	isPreview?: boolean;
	onClose?: () => void;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

const TESTIDPREFIX = 'activevote';

export const VoteSurvey = (props: IVoteSurvey) => {
	const { vote, isPreview, onClose, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const navigation = useNavigation();
	const { trackAction } = useTracker();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);

	const [surveyVoteList, setSurveyVoteList] = useState<IVote[]>([]);
	const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1);
	const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(-1);
	const [yourAnswers, setYourAnswer] = useState<ISurveyAnswerValue[]>([]);
	const [isFinished, setIsFinished] = useState<boolean>(false);
	const [currentAnswer, setCurrentAnswer] = useState<string>('');

	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered) setIsFinished(true);
	}, []);

	useEffect(() => {
		const myResult = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		);
		if (!myResult && isFinished) {
			setYourAnswer([]);
			setActiveQuestionIndex(-1);
			setSelectedOptionIndex(-1);
			setIsFinished(false);
		}

		if (vote) {
			let _surveyVotes: IVote[] = [];
			if (isPreview) {
				const previews = previewVotingsSurvey.map((p) => {
					p.options?.map((opt) => {
						opt.description = t(`${opt.description}`);
						return opt;
					});
					return p;
				});
				setSurveyVoteList(previews);
			} else {
				const options = vote?.options?.sort(byOrder);
				options?.forEach((v) => {
					const found = votes.find((vote) => vote.id.toString() === v.label);
					if (found) {
						_surveyVotes.push(found);
					}
				});
			}

			if (myResult?.value) {
				const parsedAnswer = JSON.parse(myResult.value);
				const notAnswered: IVote[] = [];
				const answered: IVote[] = [];
				_surveyVotes.forEach((quiz) => {
					const found = parsedAnswer.answers.find((answer) => quiz.id === answer.voteId);
					if (found) {
						answered.push(quiz);
					} else {
						notAnswered.push(quiz);
					}
				});
				if (notAnswered.length > 0 && activeQuestionIndex === -1) {
					_surveyVotes = [...answered, ...notAnswered];

					const newIndex = _surveyVotes.length - notAnswered.length;
					setYourAnswer(parsedAnswer.answers);
					setActiveQuestionIndex(newIndex);
					setSelectedOptionIndex(-1);
				} else if (_surveyVotes.length > 0 && activeQuestionIndex === -1) {
					setYourAnswer(parsedAnswer.answers);
					setActiveQuestionIndex(_surveyVotes.length - 1);
					setSelectedOptionIndex(0);
					setIsFinished(true);
				}
			}

			if (activeQuestionIndex === -1) {
				setSurveyVoteList(_surveyVotes);
			}
		}
	}, [voteAnswers, vote]);

	useEffect(() => {
		if (!isEmptyString(currentAnswer) && !isPreview) {
			navigation.setParams({ prohibitNavigation: true });
		}
	}, [currentAnswer]);

	const _endSurvey = async () => {
		setIsFinished(true);
		if (!isPreview) {
			setIsSubmitLoading(true);
			const res = await submitVoteAnswer({
				voteId: vote.id,
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous,
				value: JSON.stringify({ answers: yourAnswers })
			});
			if (res) {
				trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			}
			navigation.setParams({ prohibitNavigation: false });
			setIsSubmitLoading(false);
		}
	};

	const _startSurveyStep = () => {
		setSelectedOptionIndex(-1);
		setCurrentAnswer('');
		const newIndex = activeQuestionIndex + 1;
		setActiveQuestionIndex(newIndex);
	};

	const _endSurveyStep = () => {
		const _vote = surveyVoteList[activeQuestionIndex];
		if (_vote?.options) {
			let _newAnswer = '';
			if (selectedOptionIndex > -1) {
				const option = _vote?.options[selectedOptionIndex];
				_newAnswer = option?.description ?? '';
			} else {
				_newAnswer = currentAnswer;
			}
			const _yourAnswers = yourAnswers;

			_yourAnswers.push({
				answerText: _newAnswer,
				type: _vote.viewType,
				maxValue: _vote.maxValue,
				voteId: _vote.id
			});
			setYourAnswer([..._yourAnswers]);
		}
		if (surveyVoteList?.length - 2 < activeQuestionIndex) {
			_endSurvey();
		} else {
			_startSurveyStep();
		}
	};

	const _renderAnswerFormByType = (vote: IVote) => {
		switch (vote.viewType) {
			case 'multipleChoice':
				return (
					<View>
						{vote.options?.map((voteOption, index) => {
							const isActive = selectedOptionIndex === index;

							return (
								<VoteMultipleChoiceOption
									key={`activevote_button_surveyoption_${index}`}
									testID={`activevote_button_surveyoption_${index}`}
									isActive={isActive}
									onPress={() => setSelectedOptionIndex(isActive ? -1 : index)}
									label={voteOption.description}
								/>
							);
						})}
					</View>
				);
			case 'text':
				return (
					<HSCard>
						<FormTextInput
							testID={`activevote_textinput_survey`}
							value={currentAnswer}
							multiline
							onChangeText={(value) => setCurrentAnswer(value)}
							formStyle={{ marginBottom: 0 }}
						/>
					</HSCard>
				);
			case 'rating':
				let empty = EDefaultIconSet.StarOutline;
				let filled = EDefaultIconSet.Star;
				if (vote.ratingType === 'thumbs') {
					empty = EDefaultIconSet.ThumbUpOutline;
					filled = EDefaultIconSet.ThumbUp;
				}
				return (
					<HSCard>
						<Rating
							value={!isNaN(Number(currentAnswer)) ? Number(currentAnswer) : 0}
							onChange={(val) => setCurrentAnswer(val.toString())}
							maxValue={vote.maxValue}
							emptyIcon={empty}
							filledIcon={filled}
						/>
					</HSCard>
				);
			case 'scale':
				return (
					<HSCard>
						<VoteScaleSlider
							testID="activevote_slider_value"
							minValue={vote.minValue ?? 0}
							maxValue={vote.maxValue}
							value={!isNaN(Number(currentAnswer)) ? Number(currentAnswer) : 0}
							onChange={(val) => setCurrentAnswer(val.toString())}
						/>
					</HSCard>
				);
			case 'nps':
				return (
					<HSCard>
						<NpsScale
							minValue={vote.minValue ?? 0}
							maxValue={vote.maxValue}
							value={!isNaN(Number(currentAnswer)) ? Number(currentAnswer) : 0}
							onChange={(val) => setCurrentAnswer(val.toString())}
						/>
					</HSCard>
				);
			case 'wordCloud':
				return (
					<WordCloudForm
						isLoading={false}
						value={isEmptyString(currentAnswer) ? [] : currentAnswer.split(WORDCLOUDSPLITSEQUENCE)}
						onChange={(val) => setCurrentAnswer(val.join(WORDCLOUDSPLITSEQUENCE))}
						vote={vote}
						isPreview={isPreview}
					/>
				);
			default:
				return null;
		}
	};

	const _renderSurveyVote = () => {
		const _vote = surveyVoteList[activeQuestionIndex];

		if (_vote) {
			return (
				<View style={{ flex: 1 }}>
					<ActiveVoteHeader
						activeVote={{ ..._vote, isActive: true }}
						showResult={resultsOnly || votingShowResults}
						largerText={largerDesign}
					/>
					{_renderAnswerFormByType(_vote)}
				</View>
			);
		}

		return null;
	};

	const _renderPrestart = () => {
		return (
			<View style={{ flex: 1 }}>
				<ActiveVoteHeader activeVote={vote} />
			</View>
		);
	};

	const _renderMyResultByType = (answer: ISurveyAnswerValue, question: IVote) => {
		switch (answer?.type) {
			case 'multipleChoice':
			case 'text':
				return <Text>{answer.answerText}</Text>;
			case 'rating':
				let empty = EDefaultIconSet.StarOutline;
				let filled = EDefaultIconSet.Star;
				if (question.ratingType === 'thumbs') {
					empty = EDefaultIconSet.ThumbUpOutline;
					filled = EDefaultIconSet.ThumbUp;
				}
				return (
					<Rating
						isDisabled
						value={!isNaN(Number(answer?.answerText)) ? Number(answer?.answerText) : 0}
						maxValue={question?.maxValue ?? 5}
						emptyIcon={empty}
						filledIcon={filled}
						isResult={true}
					/>
				);
			case 'scale':
				return (
					<VoteScaleSlider
						isDisabled
						testID="activevote_slider_value"
						minValue={0}
						maxValue={question?.maxValue ?? 10}
						value={!isNaN(Number(answer.answerText)) ? Number(answer.answerText) : 0}
						isResult={true}
					/>
				);
			case 'nps':
				return (
					<NpsScale
						isDisabled
						minValue={0}
						maxValue={question?.maxValue ?? 10}
						value={!isNaN(Number(answer.answerText)) ? Number(answer.answerText) : 0}
					/>
				);
			case 'wordCloud':
				return (
					<WordCloud
						wordList={answer.answerText.split(WORDCLOUDSPLITSEQUENCE).map((t) => {
							return { text: t, count: 1 };
						})}
						minFontSize={largerDesign ? 20 : 12}
						maxFontSize={largerDesign ? 48 : 18}
					/>
				);
			default:
				return null;
		}
	};

	const _renderResult = () => {
		return (
			<View>
				<ActiveVoteHeader activeVote={{ ...vote, isActive: true }} />
				<ActiveVoteHeader
					activeVote={{ hint: t('youCanNowViewYourAnswers'), question: t('thanksForParticipating'), isActive: true }}
				/>
				<FlatList
					data={surveyVoteList}
					renderItem={({ item, index }) => {
						const myAnswer = yourAnswers.find((a) => item.id === a.voteId);
						return (
							<HSCard>
								<H3>{item.question}</H3>
								<Text style={{ marginBottom: hsBottomMargin }}>{t('yourAnswer')}:</Text>
								{_renderMyResultByType(myAnswer ?? yourAnswers[index], item)}
							</HSCard>
						);
					}}
					keyExtractor={(item, idx) => `votesurveyansweroption_${item.id}_${idx}`}
				/>
			</View>
		);
	};

	const _renderCurrentVoteOrResult = () => {
		if (activeQuestionIndex === -1) {
			return _renderPrestart();
		}

		if (isFinished) {
			return _renderResult();
		}

		return _renderSurveyVote();
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<FormSwitch
					testID="votetext_switch_anonymous"
					onChange={() => setAnswerAnonymous(!answerAnonymous)}
					leftLabel={t('Answer Anonymous')}
					value={answerAnonymous}
					size="sm"
					formStyle={{ width: undefined, marginBottom: 0, marginRight: 10 }}
				/>
			);
		}

		return null;
	};

	const _renderActionButton = () => {
		if (resultsOnly || votingShowResults) {
			return null;
		}
		let element: ReactNode = null;
		if (activeQuestionIndex === -1) {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_startsurvey`}
						icon={EDefaultIconSet.Play}
						title={t('Start Survey')}
						alignSelf="flex-end"
						onPress={() => {
							if (
								(vote.allowAnonymousAnswers === 'never' && !profile) ||
								(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
							) {
								setIsAuthenticationModalVisible(true);
								return;
							} else {
								_startSurveyStep();
							}
						}}
						isDisabled={!vote.isActive}
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton={!isFinished}
						isOutline={isFinished}
						testID={`${TESTIDPREFIX}_button_submit`}
						icon={isFinished ? EDefaultIconSet.Save : EDefaultIconSet.ArrowRight}
						isLoading={isSubmitLoading}
						title={isFinished ? t('endSurvey') : t('Next')}
						alignSelf={isFinished ? 'center' : 'flex-end'}
						onPress={() => {
							if (isFinished) {
								if (isPreview && onClose) {
									onClose();
								} else {
									navigation.goBack();
								}
							} else {
								_endSurveyStep();
							}
						}}
						isDisabled={selectedOptionIndex === -1 && isEmptyString(currentAnswer)}
					/>
				</HSCard>
			);
		}
		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	if (resultsOnly || votingShowResults) {
		if (iAmSpaceAdmin || iAmSpaceModerator) {
			return <SurveyReport vote={vote} fullwidth={fullwidth} resultsOnly={resultsOnly} />;
		} else {
			return <Text>No admin</Text>;
		}
	}

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					contentContainerStyle={{
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderCurrentVoteOrResult()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButton()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => {
					setIsAuthenticationModalVisible(false);
					_startSurveyStep();
				}}
			/>
		</View>
	);
};
