import React, { useEffect } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderHelpButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderSpaceCreateCancelButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import i18next from 'i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, IS_ANDROID, IS_WEB, openURL } from 'helper';

import { useSelector } from 'react-redux';
import { BackHandler, View } from 'react-native';
import { RoundButton } from 'components/Button';
import { useTranslation } from 'react-i18next';
import { H1, Text } from 'components/Text';
import * as Localization from 'expo-localization';
import { HSCard } from 'components/Card';
import { hsTopScreenPadding } from 'config/styleConstants';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { FloatingView } from 'components/FloatingView';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { DEFAULTTHEME } from 'rematch/models/config';
import { SpaceWizard } from 'components/Forms/Space/SpaceWizard';
import { UserConfirmationView } from 'components/Confirmation';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceCreate>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const SpaceCreationScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();

	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		if (!IS_ANDROID) {
			return;
		}
		const backAction = () => {
			if (route?.params?.currentIndex === 5) {
				return false;
			} else if (!route?.params || !route?.params?.prohibitNavigation) {
				setTheme(DEFAULTTHEME);
				i18next.changeLanguage(Localization.locale.split('-')[0]);
				navigation.goBack();
			} else {
				showAlert({
					title: t('unsavedChanges'),
					message: t('unsavedChangesSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('leaveWithoutSaving'),
							style: 'destructive',
							onPress: async () => {
								setTheme(DEFAULTTHEME);
								i18next.changeLanguage(Localization.locale.split('-')[0]);
								navigation.goBack();
							}
						}
					]
				});
			}
			return true;
		};
		const backHandler = BackHandler.addEventListener('hardwareBackPress', () => backAction());

		return () => backHandler.remove();
	}, [route?.params]);

	const _renderActions = () => {
		if (!profile) {
			return (
				<>
					<FloatingView alignSelf="left">
						<View>
							<RoundButton
								isFloatingButton
								title={t('Tutorial')}
								testID="createspace_button_tutorial"
								onPress={() => {
									openURL('https://forum.hellospaces.de/t/wie-erstelle-ich-einen-space/46');
								}}
								icon={EDefaultIconSet.Help}
								textColor={theme.text}
							/>
							<RoundButton
								isFloatingButton
								title={t('Register')}
								testID="createspace_button_register"
								onPress={() => {
									navigation.navigate(ERoutes.SpaceAuth, {
										authState: 'register',
										email: undefined,
										code: undefined,
										redirectRoute: ERoutes.SpaceCreate
									});
								}}
								icon={EDefaultIconSet.Register}
								textColor={theme.text}
							/>
						</View>
					</FloatingView>
					<FloatingView alignSelf="right">
						<RoundButton
							isFloatingButton
							title={t('Login')}
							testID="createspace_button_login"
							onPress={() => {
								navigation.navigate(ERoutes.SpaceAuth, {
									authState: 'login',
									email: undefined,
									code: undefined
								});
							}}
							icon={EDefaultIconSet.Login2}
							textColor={theme.text}
						/>
					</FloatingView>
				</>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (profile) {
			if (!profile.isUserConfirmed) {
				return (
					<View style={{ paddingHorizontal: EHorizontalScreenPadding.Small }}>
						<UserConfirmationView />
					</View>
				);
			}

			return (
				<SpaceWizard
					onFinish={() => {
						if (navigation.canGoBack()) {
							if (IS_WEB) {
								RootNavigation.replace(ERoutes.SpaceSelect, {});
							} else {
								RootNavigation.popToTop();
							}
						} else {
							RootNavigation.navigate(ERoutes.SpaceSelect, {});
						}
					}}
				/>
			);
		}

		return (
			<View
				style={{
					flex: 1,
					paddingBottom: !profile ? SCROLL_PADDING_FLOATING : 0,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<H1>{`${t('accountRequired')}`}</H1>
					<Text>{`${t('accountRequiredSubtitle')}`}</Text>
				</HSCard>
			</View>
		);
	};

	return (
		<ScreenContainer route={route}>
			{_renderContent()}
			{_renderActions()}
		</ScreenContainer>
	);
};

export const SpaceCreationScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const _getTitle = () => {
		if (profile) {
			if (!profile.isUserConfirmed) {
				return `${i18next.t('CreateSpace')}`;
			}
			if (params?.currentIndex && params?.currentIndex > 5) {
				return `${params?.spaceTitle}`;
			}

			return `${i18next.t('CreateSpace')} ${(params?.currentIndex ?? 0) + 1} / 6`;
		} else {
			return `${i18next.t('CreateSpace')}`;
		}
	};

	return (
		<NavigationHeader>
			{params?.currentIndex === 6 ? <NavigationHeaderPlaceholder /> : <NavigationHeaderSpaceCreateCancelButton route={route} />}
			<NavigationHeaderTitle title={_getTitle()} />
			{params?.currentIndex === 6 ? (
				<NavigationHeaderPlaceholder />
			) : (
				<NavigationHeaderHelpButton url="https://forum.hellospaces.de/t/wie-erstelle-ich-einen-space/46" />
			)}
		</NavigationHeader>
	);
};
