import { IVoteResult } from 'config/interfaces/voteanswer';

export const PreviewHappinessOMeterAnswer: IVoteResult = {
	isResultObject: true,
	spaceId: 'testch',
	voteId: 103,
	submissionCount: 4,
	options: [
		{
			key: 'sad',
			value: 3
		},
		{
			key: 'neutral',
			value: 2
		},
		{
			key: 'happy',
			value: 10
		}
	]
};
