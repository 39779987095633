import React from 'react';
import { IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import {
	FormTextInput,
	FormMultiSwitch,
	FormFromUntilDatePicker,
	FormMediaPicker,
	FormShare,
	FormCheckbox,
	FormDatePicker
} from 'components/Form';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Hint, SectionHeader } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { useSpace } from 'hooks/useSpace';
import { HSCard } from 'components/Card';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import * as ImagePicker from 'expo-image-picker';
import { isEmptyString } from 'helper';
import { hsBottomMargin } from 'config/styleConstants';

interface IEditSpaceMetaForm {
	values: IEditSpaceFormValues;
	errors: IEditSpaceFormErrors;
	onChange: (val: IEditSpaceFormValues) => void;
	isLoading?: boolean;
}

const TESTIDPREFIX = 'editspace';

export const EditSpaceMetaForm = (props: IEditSpaceMetaForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { theme } = useTheme();

	const isTicketRequired = activeSpace?.ticketSettings?.ticketRequired;

	return (
		<View>
			<HSCard>
				<SectionHeader label={t('General Informations')} />
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_title`}
					label={t('Space Title')}
					isRequired
					value={values.title}
					error={errors.title}
					onChangeText={(value) => onChange({ ...values, title: value })}
					isDisabled={isLoading}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_sidebartitle`}
					label={t('Sidebar Title')}
					value={values.sidebarTitle}
					error={errors.sidebarTitle}
					onChangeText={(sidebarTitle) => onChange({ ...values, sidebarTitle })}
					isDisabled={isLoading}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_spaceid`}
					label="Space ID"
					isRequired
					hint={t('spaceIdHintEdit')}
					isDisabled
					value={values.spaceId}
					error={errors.spaceId}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_description`}
					label={t('Description')}
					hint={t('spaceDescriptionHintEdit')}
					value={values.description}
					error={errors.description}
					onChangeText={(value) => onChange({ ...values, description: value })}
					isDisabled={isLoading}
					multiline
					maxInputLength={255}
				/>
				<FormFromUntilDatePicker
					testIdPrefix={`${TESTIDPREFIX}_datepicker`}
					fromFieldName="startDate"
					fromLabel={t('StartDate')}
					from={values.startDate}
					fromError={errors.startDate}
					untilFieldName="endDate"
					untilLabel={t('EndDate')}
					until={values.endDate}
					untilError={errors.endDate}
					label={t('SpaceStartEndDate')}
					isRequired
					isDisabled={isLoading}
					hint={t('SpaceStartEndDateHint')}
					mode="date"
					onChange={(field, value) => {
						let val = value;
						if (field === 'startDate') {
							val = moment(val).set('hours', 0).set('minutes', 0).set('seconds', 0).toISOString(true);
						} else {
							val = moment(val).set('hours', 23).set('minutes', 59).set('seconds', 59).toISOString(true);
						}
						onChange({ ...values, [field]: val });
					}}
				/>
				{values.endDate && moment(values.endDate).isAfter(moment()) && (
					<FormDatePicker
						testID={`${TESTIDPREFIX}_datepicker_promotionstartdate`}
						label={t('PromoteSpaceLabel')}
						hint={t('PromoteSpaceHint')}
						value={values.promotionStartDate}
						error={errors.promotionStartDate}
						onError={undefined}
						onChange={(promotionStartDate) => onChange({ ...values, promotionStartDate })}
						mode="datetime"
						minuteInterval={15}
					/>
				)}
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_showinsearch`}
					label={t('showInSearch')}
					error={errors.showInSearch}
					value={values.showInSearch ? 'true' : 'false'}
					options={[
						{
							key: 'true',
							label: t('Show Space')
						},
						{
							key: 'false',
							label: t('Hide Space')
						}
					]}
					onChange={(val) => onChange({ ...values, showInSearch: val === 'true' ? true : false })}
					hint={t('showInSearchHint')}
					isDisabled={isLoading}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_spacepin`}
					label={t('spacePin')}
					hint={t('spacePinHint')}
					value={values.spacePin}
					error={errors.spacePin}
					onChangeText={(val) => onChange({ ...values, spacePin: isEmptyString(val) ? null : val })}
				/>
				{!isEmptyString(values.spacePin) && (
					<View>
						<FormShare
							formStyle={{ marginBottom: hsBottomMargin }}
							testIdPrefix={TESTIDPREFIX}
							type="spacePin"
							label={t('SpacePinLinkTitle')}
							hint={t('SpacePinLinkHint')}
							tempPin={values.spacePin}
						/>
						{!isEmptyString(activeSpace?.spacePin) ? (
							<FormShare testIdPrefix={TESTIDPREFIX} type="prestart" label={t('prestartLink')} />
						) : (
							<FormTextInput
								multiline
								label={t('prestartLink')}
								testID={`${TESTIDPREFIX}_prestart_url`}
								value={t('saveSpaceToActivate')}
								isCopyField
								editable={false}
							/>
						)}
						<FormMarkdownEditor
							testID={`${TESTIDPREFIX}_textinput_prestarttext`}
							label={t('prestartText')}
							hint={t('prestartTextHint')}
							value={values.prestartText}
							onChange={(value) => onChange({ ...values, prestartText: value })}
							error={errors.prestartText}
							isDisabled={isLoading}
						/>
					</View>
				)}
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_language`}
					label={t('Language')}
					hint={t('LanguageHint')}
					error={errors.language}
					value={values.language}
					options={[
						{
							key: 'de',
							label: 'Deutsch'
						},
						{
							key: 'en',
							label: 'English'
						}
					]}
					onChange={(val) => {
						let _val = val;

						if (_val !== 'de' && _val !== 'en') _val = 'de';

						onChange({ ...values, language: _val });
					}}
					isDisabled={isLoading}
				/>
				<FormShare
					testIdPrefix={TESTIDPREFIX}
					type="spaceOnboarding"
					label={t('SpaceOnboardingLinkTitle')}
					hint={t('SpaceOnboardingLinkHint')}
				/>
			</HSCard>

			<HSCard>
				<SectionHeader label={t('registration')} />
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_registrationrequired`}
					label={t('RegistrationRequired')}
					hint={t('RegistrationRequiredHint')}
					error={errors.registrationRequired}
					value={values.registrationRequired ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, registrationRequired: val === 'yes' ? true : false })}
					isDisabled={isLoading || isTicketRequired}
				/>
				{isTicketRequired ? (
					<Hint bold style={{ marginTop: -20, marginBottom: 20, color: theme.danger }}>
						{t('RegistrationRequiredForTicketsHint')}
					</Hint>
				) : null}
				<FormTextInput
					multiline
					testID={`${TESTIDPREFIX}_textinput_registrationintro`}
					label={t('registrationIntro')}
					hint={t('registrationIntroHint')}
					value={values.registrationIntro}
					onChangeText={(val) => onChange({ ...values, registrationIntro: val })}
					error={errors.registrationIntro}
					isDisabled={isLoading}
				/>
				<FormTextInput
					multiline
					testID={`${TESTIDPREFIX}_textinput_loginintro`}
					label={t('loginIntro')}
					hint={t('loginIntroHint')}
					value={values.loginIntro}
					onChangeText={(val) => onChange({ ...values, loginIntro: val })}
					error={errors.loginIntro}
					isDisabled={isLoading}
				/>

				<FormMarkdownEditor
					testID={`${TESTIDPREFIX}_textinput_authhelptext`}
					label={t('AuthHelpText')}
					hint={t('AuthHelpTextHint')}
					value={values.authHelpText}
					onChange={(value) => onChange({ ...values, authHelpText: value })}
					error={errors.authHelpText}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_forcedoubleoptinforusers`}
					label={t('forceDoubleOptInForUsers')}
					hint={t('forceDoubleOptInForUsersHint')}
					error={errors.forceDoubleOptInForUsers}
					value={values.forceDoubleOptInForUsers ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, forceDoubleOptInForUsers: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
			</HSCard>

			<HSCard>
				<SectionHeader label={t('Feed Settings')} />
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_showUpcomingOnFeed`}
					label={t('showUpcomingSchedule')}
					hint={t('showUpcomingScheduleHint')}
					error={errors.showUpcomingScheduleOnFeed}
					value={values.showUpcomingScheduleOnFeed ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, showUpcomingScheduleOnFeed: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_allowusergeneratedcontentinfeed`}
					label={t('allowUserGeneratedContentInFeed')}
					error={errors.allowUserGeneratedContentInFeed}
					value={values.allowUserGeneratedContentInFeed}
					options={[
						{
							key: 'always',
							label: t('allow')
						},
						{
							key: 'moderated',
							label: t('moderated')
						},
						{
							key: 'never',
							label: t('neverAllow')
						}
					]}
					onChange={(val) => onChange({ ...values, allowUserGeneratedContentInFeed: val })}
					hint={t('allowUserGeneratedContentInFeedHint')}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_allowfeedcomments`}
					label={t('allowFeedComments')}
					hint={t('allowFeedCommentsHint')}
					error={errors.allowFeedComments}
					value={values.allowFeedComments}
					options={[
						{
							key: 'always',
							label: t('allow')
						},
						{
							key: 'moderated',
							label: t('moderated')
						},
						{
							key: 'never',
							label: t('neverAllow')
						}
					]}
					onChange={(val) => onChange({ ...values, allowFeedComments: val })}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_allowfeedlikes`}
					label={t('allowFeedLikes')}
					hint={t('allowFeedLikesHint')}
					error={errors.allowFeedLikes}
					value={values.allowFeedLikes ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, allowFeedLikes: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_allowfeedsharing`}
					label={t('allowFeedSharing')}
					hint={t('allowFeedSharingHint')}
					error={errors.allowSharing}
					value={values.allowSharing ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, allowSharing: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
			</HSCard>

			<HSCard>
				<SectionHeader label={t('Gallery Settings')} />
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_enablegalleryscroll`}
					label={t('enableGalleryScroll')}
					hint={t('enableGalleryScrollHint')}
					error={errors.enableGalleryScroll}
					value={values.enableGalleryScroll ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, enableGalleryScroll: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
				{values.enableGalleryScroll && (
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_galleryscrollinterval`}
						label={t('GalleryScrollInterval')}
						hint={t('GalleryScrollIntervalHint')}
						value={values.galleryScrollInterval?.toString()}
						error={errors.galleryScrollInterval}
						onChangeText={(val) => {
							let _value = Number(val);

							if (isNaN(_value)) _value = 0;

							onChange({ ...values, galleryScrollInterval: _value });
						}}
						onlyNumbers
					/>
				)}
			</HSCard>

			<HSCard>
				<SectionHeader label={t('Attendee Settings')} />
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_hiddendomains`}
					label={t('Hide Attendees')}
					hint={t('Hide Attendees Hint')}
					value={values.attendeeSettings?.hiddenDomains}
					error={errors.attendeeSettings?.hiddenDomains}
					onChangeText={(val) => {
						const _values = { ...values };
						if (!_values.attendeeSettings) {
							_values.attendeeSettings = {};
						}
						_values.attendeeSettings.hiddenDomains = val;
						onChange(_values);
					}}
				/>
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_disabletracking`}
					value={values.disableTracking}
					error={errors.disableTracking}
					label={t('doNotTrack')}
					hint={t('doNotTrackHint')}
					onPress={() => onChange({ ...values, disableTracking: !values.disableTracking })}
					style={{ marginBottom: 0 }}
				/>
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_anonymoustracking`}
					value={values.anonymousTracking}
					error={errors.anonymousTracking}
					label={t('anonymousTracking')}
					hint={t('anonymousTrackingHint')}
					onPress={() => onChange({ ...values, anonymousTracking: !values.anonymousTracking })}
					style={{ marginBottom: 0 }}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_gatrackingid`}
					label="Google Analytics Tracking ID"
					hint={t('GATrackingIdHint')}
					value={values.gaTrackingId}
					error={errors.gaTrackingId}
					onChangeText={(gaTrackingId) => onChange({ ...values, gaTrackingId })}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_matomotrackingurl`}
					label="Matomo Tracking URL"
					hint={t('MatomoTrackingUrlHint')}
					value={values.matomoTrackingUrl}
					error={errors.matomoTrackingUrl}
					onChangeText={(matomoTrackingUrl) => onChange({ ...values, matomoTrackingUrl })}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_matomositeid`}
					label="Matomo Site ID"
					hint={t('MatomoSiteIdHint')}
					value={values.matomoSiteId?.toString()}
					error={errors.matomoSiteId}
					keyboardType="number-pad"
					onlyNumbers
					onChangeText={(matomoSiteId) => onChange({ ...values, matomoSiteId: Number(matomoSiteId) })}
				/>
			</HSCard>

			<HSCard>
				<SectionHeader label={t('Agenda')} />
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_agendaviewtype`}
					label={t('agendaViewType')}
					hint={t('agendaViewTypeHint')}
					error={errors.agendaViewType}
					value={values.agendaViewType}
					options={[
						{
							key: 'listAndGrid',
							label: t('listAndGrid')
						},
						{
							key: 'gridAndList',
							label: t('gridAndList')
						},
						{
							key: 'listOnly',
							label: t('listOnly')
						},
						{
							key: 'gridOnly',
							label: t('gridOnly')
						}
					]}
					onChange={(val) => onChange({ ...values, agendaViewType: val })}
					isDisabled={isLoading}
				/>
				{values.agendaViewType !== 'gridOnly' && (
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_agendalistviewtype`}
						label={t('agendaListViewType')}
						hint={t('agendaListViewTypeHint')}
						error={errors.agendaListViewType}
						value={values.agendaListViewType}
						options={[
							{
								key: 'list',
								label: t('List')
							},
							{
								key: 'cards',
								label: t('Cards')
							}
						]}
						onChange={(val) => onChange({ ...values, agendaListViewType: val })}
						isDisabled={isLoading}
					/>
				)}
				<FormMediaPicker
					isOutline
					hasDeleteButton
					testID={`${TESTIDPREFIX}_imagepicker_agendaMyDayImage`}
					label={t('agendaMyDayImage')}
					hint={t('agendaMyDayImageHint')}
					aspectRatio="16/9"
					value={values.agendaMyDayImage}
					error={errors.agendaMyDayImage}
					onChange={(val) => {
						if (val === null) {
							let _values = { ...values };
							_values.agendaMyDayImage = val;
							onChange({ ..._values });
						} else {
							onChange({ ...values, agendaMyDayImage: val });
						}
					}}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					isDisabled={isLoading}
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_agendaheightperminuteweb`}
					label={t('agendaHeightPerMinuteWeb')}
					hint={t('agendaHeightPerMinuteWebHint')}
					value={values.agendaHeightPerMinuteWeb?.toString()}
					error={errors.agendaHeightPerMinuteWeb}
					onChangeText={(val) => onChange({ ...values, agendaHeightPerMinuteWeb: Number(val) })}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_agendaWidthperstageweb`}
					label={t('agendaWidthPerStageWeb')}
					hint={t('agendaWidthPerStageWebHint')}
					value={values.agendaWidthPerStageWeb?.toString()}
					error={errors.agendaWidthPerStageWeb}
					onChangeText={(val) => onChange({ ...values, agendaWidthPerStageWeb: Number(val) })}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_agendaheightperminuteapp`}
					label={t('agendaHeightPerMinuteApp')}
					hint={t('agendaHeightPerMinuteAppHint')}
					value={values.agendaHeightPerMinuteApp?.toString()}
					error={errors.agendaHeightPerMinuteApp}
					onChangeText={(val) => onChange({ ...values, agendaHeightPerMinuteApp: Number(val) })}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_agendaWidthPerStageApp`}
					label={t('agendaWidthPerStageApp')}
					hint={t('agendaWidthPerStageAppHint')}
					value={values.agendaWidthPerStageApp?.toString()}
					error={errors.agendaWidthPerStageApp}
					onChangeText={(val) => onChange({ ...values, agendaWidthPerStageApp: Number(val) })}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_agendastageautowidth`}
					label={t('agendaStageAutoWidth')}
					hint={t('agendaStageAutoWidthHint')}
					error={errors.agendaStageAutoWidth}
					value={values.agendaStageAutoWidth ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, agendaStageAutoWidth: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_enablepublicagenda`}
					label={t('enablePublicAgenda')}
					hint={t('enablePublicAgendaHint')}
					error={errors.enablePublicAgenda}
					value={values.enablePublicAgenda ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, enablePublicAgenda: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
				{values.enablePublicAgenda && (
					<View>
						{activeSpace?.enablePublicAgenda ? (
							<FormShare
								testIdPrefix={TESTIDPREFIX}
								type="publicAgenda"
								label={t('publicAgendaLink')}
								formStyle={{ marginBottom: hsBottomMargin }}
							/>
						) : (
							<FormTextInput
								multiline
								label={t('publicAgendaLink')}
								testID={`${TESTIDPREFIX}_publicAgenda_url`}
								value={t('saveSpaceToActivate')}
								isCopyField
								editable={false}
							/>
						)}
					</View>
				)}
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_allowparallelbooking`}
					label={t('allowParallelBooking')}
					hint={t('allowParallelBookingHint')}
					error={errors.allowParallelBooking}
					value={values.allowParallelBooking ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, allowParallelBooking: val === 'yes' ? true : false })}
					isDisabled={isLoading}
				/>
			</HSCard>

			<HSCard>
				<SectionHeader label={t('CustomAttendeeConsentText')} />
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_dataprotectionacknowledgedtext`}
					label={t('dataProtectionAcknowledgedText')}
					hint={t('dataProtectionAcknowledgedTextHint')}
					value={values.dataProtectionAcknowledgedText}
					error={errors.dataProtectionAcknowledgedText}
					onChangeText={(val) => onChange({ ...values, dataProtectionAcknowledgedText: val })}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_dataProtectionAcceptedText`}
					label={t('dataProtectionAcceptedText')}
					hint={t('dataProtectionAcceptedTextHint')}
					value={values.dataProtectionAcceptedText}
					error={errors.dataProtectionAcceptedText}
					onChangeText={(val) => onChange({ ...values, dataProtectionAcceptedText: val })}
				/>
				<FormTextInput
					isDisabled={isLoading}
					testID={`${TESTIDPREFIX}_textinput_readtermsurl`}
					label={t('readTermsUrl')}
					hint={t('readTermsUrlHint')}
					value={values.readTermsUrl}
					error={errors.readTermsUrl}
					onChangeText={(val) => onChange({ ...values, readTermsUrl: val })}
					keyboardType="url"
					autoCapitalize="none"
				/>
				<FormTextInput
					isDisabled={isLoading}
					testID={`${TESTIDPREFIX}_textinput_dataprotectionurl`}
					label={t('dataProtectionUrl')}
					hint={t('dataProtectionUrlHint')}
					value={values.dataProtectionUrl}
					error={errors.dataProtectionUrl}
					onChangeText={(val) => onChange({ ...values, dataProtectionUrl: val })}
					keyboardType="url"
					autoCapitalize="none"
				/>
				<FormMarkdownEditor
					testID={`${TESTIDPREFIX}_textinput_customuserconsenttext`}
					hint={t('CustomAttendeeConsentTextHint')}
					value={values.customUserConsentText}
					onChange={(val) => onChange({ ...values, customUserConsentText: val })}
					error={errors.customUserConsentText}
				/>
			</HSCard>
		</View>
	);
};
