import React from 'react';
import { KeyboardTypeOptions, View } from 'react-native';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as ImagePicker from 'expo-image-picker';

import { FormCheckbox } from 'components/Form/FormCheckbox';
import { FormColorPicker } from 'components/Form/FormColorPicker';
import { FormFromUntilDatePicker } from 'components/Form/FormFromUntilDatePicker';
import { FormMediaPicker, FormAvatarPicker } from 'components/Form/Image';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';
import { FormTagCloud } from 'components/Form/FormTagCloud';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormContentFlowPicker } from 'components/Form/FormContentFlowPicker';
import { FormImageSelection } from 'components/Form/FormImageSelection';
import { CountryDropdown } from 'components/Form/CountryDropdown';
import { FormReferenceSelect } from 'components/Form/FormReferenceSelect';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import { ILauncherSpace, IRegistrationField } from 'config/interfaces';
import {
	getEmailError,
	getMaxError,
	getMaxNumberError,
	getMinError,
	getMinNumberError,
	getRequiredError,
	getWebsiteError
} from 'config/yupSchemas/errors';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor/FormMarkdownEditor';
import { EDefaultIconSet, getSelfServiceUrl, getValidURL, isEmptyString, openURL } from 'helper';
import { SectionHeader } from 'components/Text';
import { Markdown } from 'components/Markdown';
import { RoundButton } from 'components/Button';
import { useSpace } from './useSpace';
import { FormStreamSelect } from 'components/Form';

export const useForm = () => {
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator, activeSpace } = useSpace();

	const renderFormField = (
		contentType: string,
		testIDPrefix: string,
		values,
		errors,
		field: IRegistrationField,
		onChange,
		isDisabled?: boolean,
		space?: ILauncherSpace,
		isSelfService?: boolean,
		targetType?: string,
		isModal?: boolean
	) => {
		const spaceId = space?.spaceId ?? activeSpace?.spaceId;

		switch (field.fieldType) {
			case 'avatar':
				return (
					<FormAvatarPicker
						key={field.fieldName}
						containerStyle={{ alignSelf: 'center' }}
						testID={`${testIDPrefix}_avatarpicker_avatar`}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						hint={field.fieldHint}
						fullName={`${values.firstName} ${values.lastName}`}
						onChange={(value) => onChange({ ...values, [field.fieldName]: value })}
						useModal={isModal}
					/>
				);
			case 'section':
				return <SectionHeader key={field.fieldName} label={field.fieldLabel ?? ''} />;

			case 'label':
				return <Markdown key={field.fieldName} markdown={field.fieldLabel} />;
			case 'mediaselection':
				return (
					<FormStreamSelect
						hideMediaItem
						createMediaJson
						hideEditWarning
						key={field.fieldName}
						testID={`${testIDPrefix}_mediawizard_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						isDisabled={isDisabled}
						hint={field.fieldHint}
						error={errors[field.fieldName]}
						objectValue={{
							iFrameUrl: values.iFrameUrl,
							isAutoplay: values.isAutoplay,
							isLoop: values.isLoop,
							isMute: values.isMute,
							streamType: values.mediaType,
							contentflowUrl: values.contentflowUrl,
							youTubeUrl: values.youTubeUrl,
							vimeoUrl: values.vimeoUrl,
							media: values.media,
							title: values.mediaTitle,
							externalMeetingUrl: values.externalMeetingUrl,
							externalMeetingButtonText: values.externalMeetingButtonText
						}}
						onChange={(value) => {
							if (!value) {
								onChange({
									...values,
									...{
										iFrameUrl: null,
										isAutoplay: null,
										isLoop: null,
										isMute: null,
										streamType: null,
										contentflowUrl: null,
										youTubeUrl: null,
										vimeoUrl: null,
										media: null,
										mediaType: null,
										externalMeetingUrl: null,
										externalMeetingButtonText: null
									},
									mediaTitle: ''
								});
								return;
							}
							let mediaTitle = '';
							if (value?.title) {
								mediaTitle = value?.title;
								delete value?.title;
							}
							onChange({ ...values, ...value, mediaType: value.streamType, mediaTitle });
						}}
						isSelfService={isSelfService}
					/>
				);
			case 'stream':
				return (
					<FormStreamSelect
						key={field.fieldName}
						testID={`${testIDPrefix}_mediawizard_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						isDisabled={isDisabled}
						hint={field.fieldHint}
						error={errors[field.fieldName]}
						value={values[field.fieldName]}
						onChange={(value) => onChange({ ...values, [field.fieldName]: value })}
						objectValue={{
							iFrameUrl: values.iFrameUrl,
							isAutoplay: values.isAutoplay,
							isLoop: values.isLoop,
							isMute: values.isMute,
							streamType: values.mediaType,
							contentflowUrl: values.contentflowUrl,
							youTubeUrl: values.youTubeUrl,
							vimeoUrl: values.vimeoUrl,
							media: values.media,
							title: values.mediaTitle
						}}
					/>
				);
			case 'contentflow':
				return (
					<FormContentFlowPicker
						key={field.fieldName}
						testID={`${testIDPrefix}_contentflowpicker_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						error={errors[field.fieldName]}
						value={values[field.fieldName]}
						onChange={(value) => onChange({ ...values, [field.fieldName]: value })}
					/>
				);
			case 'image':
			case 'logo':
			case 'video':
			case 'media':
				return (
					<FormMediaPicker
						key={field.fieldName}
						isOutline
						hasDeleteButton
						aspectRatio={field.aspectRatio ?? '16/9'}
						testID={`${testIDPrefix}_mediapicker_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						onChangePreview={(media) => onChange({ ...values, [field.fieldName]: media })}
						onChange={(media) => {
							if (media === null) {
								let _values = { ...values };
								_values[field.fieldName] = media;
								onChange({ ..._values });
							} else {
								onChange({ ...values, [field.fieldName]: media });
							}
						}}
						isDisabled={isDisabled}
						mediaTypes={
							field.fieldType === 'image' || field.fieldType === 'logo'
								? ImagePicker.MediaTypeOptions.Images
								: field.fieldType === 'video'
								? ImagePicker.MediaTypeOptions.Videos
								: ImagePicker.MediaTypeOptions.All
						}
						allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
					/>
				);
			case 'spaceBoolean':
				if (spaceId) {
					return (
						<FormCheckbox
							key={field.fieldName}
							testID={`${testIDPrefix}_checkbox_${field.fieldName}`}
							label={field.fieldLabel}
							isRequired={field.isRequired}
							hint={field.fieldHint}
							value={values[field.fieldName] && values[field.fieldName][spaceId]}
							error={errors[field.fieldName]}
							isDisabled={isDisabled}
							onPress={() => {
								let _values = { ...values };
								if (!_values[field.fieldName]) {
									_values[field.fieldName] = {};
								}
								if (_values[field.fieldName][spaceId]) {
									_values[field.fieldName][spaceId] = false;
								} else {
									_values[field.fieldName][spaceId] = true;
								}
								onChange({ ..._values });
							}}
						/>
					);
				}

				return null;
			case 'boolean':
				return (
					<FormCheckbox
						key={field.fieldName}
						testID={`${testIDPrefix}_checkbox_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						isDisabled={isDisabled}
						onPress={() => onChange({ ...values, [field.fieldName]: !values[field.fieldName] })}
					/>
				);
			case 'category':
				return (
					<FormTagCloud
						key={field.fieldName}
						fieldName={field.fieldName}
						testID={`${testIDPrefix}_tagcloud_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						options={field.options}
						isDisabled={isDisabled}
						onChange={(value) => onChange({ ...values, [field.fieldName]: value })}
						minCount={field.minCount}
						maxCount={field.maxCount}
						contentType={contentType}
						isSelfService={isSelfService}
						space={space}
						targetType={targetType}
					/>
				);
			case 'multiswitch':
				return (
					<View
						key={field.fieldName}
						style={field.fieldName === 'selfServiceEditable' ? { marginBottom: FormElementBottomMarginSmall } : {}}
					>
						<FormMultiSwitch
							formStyle={field.fieldName === 'selfServiceEditable' ? { marginBottom: FormElementBottomMarginSmall } : {}}
							testID={`${testIDPrefix}_multiswitch_${field.fieldName}`}
							label={field.fieldLabel}
							isRequired={field.isRequired}
							hint={field.fieldHint}
							value={values[field.fieldName]}
							error={errors[field.fieldName]}
							options={field.options}
							isDisabled={isDisabled}
							onChange={(val) => onChange({ ...values, [field.fieldName]: val })}
						/>
						{field.fieldName === 'selfServiceEditable' && values.selfServiceToken && (
							<View style={{ marginLeft: -5 }}>
								<RoundButton
									isFloatingButton
									testID={`${testIDPrefix}_multiswitch_openselfservice`}
									icon={EDefaultIconSet.SelfService}
									title={t('openSelfService')}
									size="xs"
									onPress={() => openURL(getSelfServiceUrl(values.selfServiceToken))}
								/>
							</View>
						)}
					</View>
				);
			case 'markdown':
				return (
					<FormMarkdownEditor
						key={field.fieldName}
						testID={`${testIDPrefix}_markdowneditor_${field.fieldName}`}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						isDisabled={isDisabled}
						onChange={(val) => onChange({ ...values, [field.fieldName]: val })}
					/>
				);
			case 'dateRange':
				return (
					<FormFromUntilDatePicker
						key={testIDPrefix}
						testIdPrefix={testIDPrefix}
						from={values[field.fieldName]}
						until={field.fieldName2 ? values[field.fieldName2] : null}
						fromError={errors[field.fieldName]}
						untilError={field.fieldName2 ? errors[field.fieldName2] : undefined}
						fromLabel={field.extraLabel1}
						untilLabel={field.extraLabel2}
						fromFieldName={field.fieldName}
						untilFieldName={field.fieldName2}
						onChange={(field, value) => onChange({ ...values, [field]: value })}
						onRangeChange={(field1, value1, field2, value2) => onChange({ ...values, [field1]: value1, [field2]: value2 })}
						label={field.fieldLabel ?? ''}
						hint={field.fieldHint}
						isDisabled={isDisabled}
					/>
				);
			case 'color':
				return (
					<FormColorPicker
						key={`${testIDPrefix}_colorpicker_${field.fieldName}`}
						testID={`${testIDPrefix}_colorpicker_${field.fieldName}`}
						onChange={(value) => onChange({ ...values, [field.fieldName]: value })}
						value={values[field.fieldName]}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						hint={field.fieldHint}
						error={errors[field.fieldName]}
						isDisabled={isDisabled}
					/>
				);
			case 'reference':
			case 'singlereference':
				return (
					<FormReferenceSelect
						key={`${testIDPrefix}_referenceselect_${field.fieldName}`}
						testID={`${testIDPrefix}_referenceselect_${field.fieldName}`}
						fieldName={field.fieldName}
						label={field.fieldLabel}
						isRequired={field.isRequired}
						placeholder={field.placeholder}
						hint={field.fieldHint}
						type={field.referenceType}
						multiSelect={field.fieldType === 'reference'}
						error={errors[field.fieldName]}
						value={values[field.fieldName]}
						onSelect={(value) => onChange({ ...values, [field.fieldName]: value })}
						isDisabled={isDisabled}
						contentType={contentType}
						space={space}
						isSelfService={isSelfService}
						itemId={values.id}
					/>
				);
			case 'email':
			case 'number':
			case 'password':
			case 'website':
			case 'phoneNumber':
			case 'string':
			case 'title':
			case 'subtitle':
			case 'contactEmail':
			case 'location':
			case 'socialmedia':
				let keyboardType: KeyboardTypeOptions = 'default';

				if (field.fieldType === 'email') {
					keyboardType = 'email-address';
				} else if (field.fieldType === 'number') {
					keyboardType = 'number-pad';
				} else if (field.fieldType === 'website') {
					keyboardType = 'url';
				}

				const formsWithTabSkip = ['registrationform', 'editprofile', 'selfservice'];

				return (
					<FormTextInput
						key={field.fieldName}
						testID={`${testIDPrefix}_textinput_${field.fieldName}`}
						label={field.fieldLabel}
						hint={field.fieldHint}
						isRequired={field.isRequired}
						value={field.fieldType === 'number' ? values[field.fieldName]?.toString() : values[field.fieldName]}
						error={errors[field.fieldName]}
						onChangeText={(value) => onChange({ ...values, [field.fieldName]: value })}
						keyboardType={keyboardType}
						autoCapitalize={field.autoCapitalize}
						secureTextEntry={field.fieldType === 'password'}
						isDisabled={isDisabled}
						multiline={field.multiline ?? false}
						skipTabPress={formsWithTabSkip.includes(testIDPrefix)}
						onlyNumbers={field.fieldType === 'number'}
						maxInputLength={field.maxLength}
					/>
				);
			case 'gallery':
				return (
					<FormImageSelection
						key={field.fieldName}
						testID={`${testIDPrefix}_media_gallery`}
						label={field.fieldLabel ?? `${t('Media')} (${t('Images')}/${t('Videos')})`}
						hint={field.fieldHint}
						media={values[field.fieldName]?.map((m) => m.media)}
						isDisabled={isDisabled}
						onChange={(value) => {
							if (value) {
								onChange({
									...values,
									[field.fieldName]: value.map((v, index) => {
										return { media: v, order: index };
									})
								});
							}
						}}
					/>
				);
			case 'language':
				return (
					<CountryDropdown
						key={field.fieldName}
						label={field.fieldLabel}
						hint={
							field.fieldHint ?? field.maxCount !== undefined
								? t('CountryHintLimit').replace('%LIMIT%', `${field.maxCount}`)
								: t('CountryHint')
						}
						onSelect={(languages) => onChange({ ...values, [field.fieldName]: languages })}
						testID={`${testIDPrefix}_textinput_languages`}
						multi
						search
						value={values[field.fieldName]?.toString()}
						selectionLimit={field.maxCount}
					/>
				);
			default:
				return null;
		}
	};

	const getFormSchema = (fields: IRegistrationField[], values: Record<string, any>) => {
		const obj = {};

		fields.forEach((field) => {
			let regexp;

			switch (field.fieldType) {
				// case 'avatar':
				// 	regexp = field.isRequired ? Yup.string().required(getRequiredError(field.fieldLabel)) : Yup.string().nullable();
				// 	break;
				case 'boolean':
					regexp = field.isRequired
						? Yup.boolean()
								.oneOf([true], getRequiredError(field.fieldLabel))
								.required(getRequiredError(field.fieldLabel))
								.nullable()
						: Yup.boolean().nullable();
					break;
				case 'category':
					const selected: string[] = [];
					if (values && values[field.fieldName] && typeof values[field.fieldName] === 'string') {
						const splitted = values[field.fieldName]?.split(',')?.filter((e) => !isEmptyString(e));
						field.options?.forEach((option) => {
							if (splitted.includes(option.key)) {
								selected.push(option.key);
							}
						});
					}

					if (field.minCount && field.maxCount) {
						if (field.minCount === field.maxCount) {
							regexp = field.isRequired
								? Yup.string()
										.test(
											'exactCount',
											Number(field.minCount) === 1
												? t('CategoryErrorRangeCountSingle')
												: t('CategoryErrorExactCount').replace('%COUNT%', field.minCount.toString()),
											() => {
												return (
													field.minCount !== undefined &&
													field.maxCount !== undefined &&
													selected.length >= field.minCount &&
													selected.length <= field.maxCount
												);
											}
										)
										.required(getRequiredError(field.fieldLabel))
										.nullable()
								: Yup.string()
										.test(
											'exactCount',
											Number(field.minCount) === 1
												? t('CategoryErrorRangeCountSingle')
												: t('CategoryErrorExactCount').replace('%COUNT%', field.minCount.toString()),
											() => {
												return (
													field.minCount !== undefined &&
													field.maxCount !== undefined &&
													selected.length >= field.minCount &&
													selected.length <= field.maxCount
												);
											}
										)
										.nullable();
						} else {
							regexp = field.isRequired
								? Yup.string()
										.test(
											'exactCount',
											t('CategoryErrorRangeCount')
												.replace('%MIN%', field.minCount.toString())
												.replace('%MAX%', field.maxCount.toString()),
											() => {
												return (
													field.minCount !== undefined &&
													field.maxCount !== undefined &&
													selected.length >= field.minCount &&
													selected.length <= field.maxCount
												);
											}
										)
										.required(getRequiredError(field.fieldLabel))
										.nullable()
								: Yup.string()
										.test(
											'exactCount',
											t('CategoryErrorRangeCount')
												.replace('%MIN%', field.minCount.toString())
												.replace('%MAX%', field.maxCount.toString()),
											() => {
												return (
													field.minCount !== undefined &&
													field.maxCount !== undefined &&
													selected.length >= field.minCount &&
													selected.length <= field.maxCount
												);
											}
										)
										.nullable();
						}
					} else if (field.minCount) {
						regexp = field.isRequired
							? Yup.string()
									.test(
										'exactCount',
										Number(field.minCount) === 1
											? t('CategoryErrorMinCountIs1')
											: t('CategoryErrorMinCount').replace('%MIN%', field.minCount.toString()),
										() => {
											return field.minCount !== undefined && selected.length >= field.minCount;
										}
									)
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: Yup.string()
									.test(
										'exactCount',
										Number(field.minCount) === 1
											? t('CategoryErrorMinCountIs1')
											: t('CategoryErrorMinCount').replace('%MIN%', field.minCount.toString()),
										() => {
											return field.minCount !== undefined && selected.length >= field.minCount;
										}
									)
									.nullable();
					} else if (field.maxCount) {
						regexp = field.isRequired
							? Yup.string()
									.test(
										'exactCount',
										Number(field.maxCount) === 1
											? t('CategoryErrorMaxCountIs1')
											: t('CategoryErrorMaxCount').replace('%MAX%', field.maxCount.toString()),
										() => {
											return field.maxCount !== undefined && selected.length <= field.maxCount;
										}
									)
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: Yup.string()
									.test(
										'exactCount',
										Number(field.maxCount) === 1
											? t('CategoryErrorMaxCountIs1')
											: t('CategoryErrorMaxCount').replace('%MAX%', field.maxCount.toString()),
										() => {
											return field.maxCount !== undefined && selected.length <= field.maxCount;
										}
									)
									.nullable();
					} else {
						regexp = field.isRequired
							? Yup.string().required(getRequiredError(field.fieldLabel)).nullable()
							: Yup.string().nullable();
					}
					break;
				case 'markdown':
					regexp = field.isRequired
						? Yup.string().required(getRequiredError(field.fieldLabel)).nullable()
						: Yup.string().nullable();
					break;
				case 'color':
					regexp = field.isRequired
						? Yup.string().max(9).required(getRequiredError(field.fieldLabel)).nullable()
						: Yup.string().max(9).nullable();
					break;
				case 'number':
					regexp = field.isRequired
						? field.minCount && field.maxCount
							? Yup.number()
									.min(field.minCount, getMinNumberError(field.minCount))
									.max(field.maxCount, getMaxNumberError(field.maxCount))
									.typeError(t('FormValidationErrorNumber'))
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: field.maxCount
							? Yup.number()
									.max(field.maxCount, getMaxNumberError(field.maxCount))
									.typeError(t('FormValidationErrorNumber'))
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: field.minCount
							? Yup.number()
									.min(field.minCount, getMinNumberError(field.minCount))
									.typeError(t('FormValidationErrorNumber'))
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: Yup.number().typeError(t('FormValidationErrorNumber')).required(getRequiredError(field.fieldLabel)).nullable()
						: field.minCount && field.maxCount
						? Yup.number()
								.min(field.minCount, getMinNumberError(field.minCount))
								.max(field.maxCount, getMaxNumberError(field.maxCount))
								.typeError(t('FormValidationErrorNumber'))
								.nullable()
						: field.maxCount
						? Yup.number()
								.max(field.maxCount, getMaxNumberError(field.maxCount))
								.typeError(t('FormValidationErrorNumber'))
								.nullable()
						: field.minCount
						? Yup.number()
								.min(field.minCount, getMinNumberError(field.minCount))
								.typeError(t('FormValidationErrorNumber'))
								.nullable()
						: Yup.number().typeError(t('FormValidationErrorNumber')).nullable();
					break;
				case 'email':
					regexp = field.isRequired
						? Yup.string().email(getEmailError(field.fieldLabel)).required(getRequiredError(field.fieldLabel)).nullable()
						: Yup.string().email(getEmailError(field.fieldLabel)).nullable();
					break;
				case 'multiswitch':
					regexp = field.isRequired
						? Yup.string().required(getRequiredError(field.fieldLabel)).nullable()
						: Yup.string().nullable();
					break;
				case 'password':
					if (field.fieldName === 'passwordConfirmation') {
						regexp = field.isRequired
							? Yup.string()
									.min(6, getMinError(6))
									.test('passwordEQ', t('PWEQ'), () => values.password === values.passwordConfirmation)
									.required(getRequiredError(field.fieldLabel))
									.nullable()
							: Yup.string()
									.min(6, getMinError(6))
									.test('passwordEQ', t('PWEQ'), () => values.password === values.passwordConfirmation)
									.nullable();
					} else {
						regexp = field.isRequired
							? Yup.string().min(6, getMinError(6)).required(getRequiredError(field.fieldLabel)).nullable()
							: Yup.string().min(6, getMinError(6)).nullable();
					}
					break;
				case 'socialmedia':
				case 'website':
					if (values[field.fieldName] && !isEmptyString(values[field.fieldName])) {
						values[field.fieldName] = getValidURL(values[field.fieldName]);
					}

					if (field.isRequired) {
						regexp = field.maxLength
							? Yup.string()
									.url(getWebsiteError(values[field.fieldName]))
									.required(getRequiredError(field.fieldLabel))
									.max(field.maxLength, getMaxError(field.maxLength))
									.nullable()
							: Yup.string()
									.url(getWebsiteError(values[field.fieldName]))
									.required(getRequiredError(field.fieldLabel))
									.nullable();
					} else {
						regexp = field.maxLength
							? Yup.string()
									.url(getWebsiteError(values[field.fieldName]))
									.max(field.maxLength, getMaxError(field.maxLength))
									.nullable()
							: Yup.string().url(getWebsiteError(values[field.fieldName])).nullable();
					}
					break;
				case 'phoneNumber':
				case 'title':
				case 'string':
					if (field.isRequired) {
						regexp = field.maxLength
							? Yup.string()
									.required(getRequiredError(field.fieldLabel))
									.max(field.maxLength, getMaxError(field.maxLength))
									.nullable()
							: Yup.string().required(getRequiredError(field.fieldLabel)).nullable();
					} else {
						regexp = field.maxLength
							? Yup.string().max(field.maxLength, getMaxError(field.maxLength)).nullable()
							: Yup.string().nullable();
					}

					break;
				default:
					break;
			}

			obj[field.fieldName] = regexp;
		});

		return Yup.object().shape(obj);
	};

	const renderFormFieldUserSettings = (
		testIDPrefix: string,
		values,
		errors,
		field: IRegistrationField,
		onChange,
		isDisabled?: boolean
	) => {
		switch (field.fieldName) {
			case 'email':
				return (
					<FormCheckbox
						key={`${field.fieldName}_settings`}
						testID={`${testIDPrefix}_checkbox_${field.fieldName}_settings`}
						label={t('showFieldOnProfile').replace('%FIELDNAME%', field.fieldLabel ?? '')}
						// hint={field.fieldHint}
						value={values[field.fieldName]}
						error={errors[field.fieldName]}
						isDisabled={isDisabled}
						onPress={() => onChange({ ...values, [field.fieldName]: !values[field.fieldName] })}
					/>
				);

			default:
				return null;
		}
	};

	return {
		renderFormField,
		getFormSchema,
		renderFormFieldUserSettings
	};
};
