import React from 'react';
import { hsBlue, hsTextBottomMargin } from 'config/styleConstants';
import { isEmptyString, normalizeFontSize } from 'helper';
import { TFunctionResult } from 'i18next';
import { TextStyle, TextProps } from 'react-native';

import { Text } from './Text';

interface IWelcomeHeading {
	children?: string | TFunctionResult;
	style?: TextStyle;
	center?: boolean;
}
export const WelcomeHeading = (props: IWelcomeHeading & TextProps) => {
	const { center, children, style } = props;

	if (!isEmptyString(children)) {
		return (
			<Text
				{...props}
				bold
				center={center}
				style={[{ fontSize: normalizeFontSize(42), color: hsBlue, marginBottom: hsTextBottomMargin * 1.5 }, style]}
			>
				{children}
			</Text>
		);
	}

	return null;
};
