import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Svg, { Path } from 'react-native-svg';

import {
	Dimensions,
	GestureResponderEvent,
	LayoutChangeEvent,
	PanResponder,
	PanResponderGestureState,
	PanResponderInstance,
	View,
	StyleSheet
} from 'react-native';
import { Text } from 'components/Text';
import { DEFAULTSCALEMAXVALUE, DEFAULTSCALEMINVALUE } from './VoteScaleSlider';
import Animated, { Easing, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useTranslation } from 'react-i18next';
import { getTopRadiusStyle } from 'helper';

interface IVoteScaleTacho {
	minValue?: number;
	maxValue?: number;
	value?: number;
	onChange?: (val: number) => void;
	isDisabled?: boolean;
	answerCount?: number;
	myValue?: number;
	lockScrolling?: (val: boolean) => void;
}

const secondHandOffset = 0;
const secondHandWidth = 1.5;
const INITIALROTATION = -90;
const MAXROTATION = 180;

export const VoteScaleTacho = (props: IVoteScaleTacho) => {
	const { minValue, maxValue, value, onChange, answerCount, myValue, lockScrolling } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const containerHeight = useRef(0);
	const containerWidth = useRef(0);
	const locationDiffX = useRef(0);
	const elementWidthRef = useRef(1);
	const [elementWidth, setElementWidth] = useState<number>(1);

	const startLocation = useRef({
		x: 0,
		y: 0
	});
	const location = useRef({
		x: 0,
		y: 0
	});

	const animation = useSharedValue(INITIALROTATION);
	const iconAnimationStyle = useAnimatedStyle(() => {
		const rotationValue = withTiming(`${animation.value}deg`, {
			duration: myValue ? 200 : 1,
			easing: Easing.linear
		});

		('worklet');
		return {
			transform: [{ rotate: rotationValue }, { translateY: -(secondHandOffset + (containerWidth.current * 0.5) / 2) }]
		};
	});

	const _rotation = useRef(INITIALROTATION);
	const _myValueRotation = useRef(INITIALROTATION);

	const tempValue = useRef(0);
	const isDisabledRef = useRef(props.isDisabled);

	const [rotation, _setRotation] = useState<number>(INITIALROTATION);
	const [myValueRotation, _setMyValueRotation] = useState<number>(INITIALROTATION);
	const [_tempValue, _setTempValue] = useState<number>(0);
	const [panResponder, setPanResponder] = useState<PanResponderInstance>(
		PanResponder.create({
			onStartShouldSetPanResponder: (e, gestureState) => _handleOnStartShouldSetPanResponder(e, gestureState),
			onStartShouldSetPanResponderCapture: (e, gestureState) => _handleOnStartShouldSetPanResponderCapture(e, gestureState),
			onMoveShouldSetPanResponder: (e, gestureState) => _handleOnMoveShouldSetPanResponder(e, gestureState),
			onMoveShouldSetPanResponderCapture: (e, gestureState) => _handleOnMoveShouldSetPanResponderCapture(e, gestureState),
			onPanResponderGrant: (e, gestureState) => _handleOnPanResponderGrant(e, gestureState),
			onPanResponderMove: (e, gestureState) => _handleOnPanResponderMove(e, gestureState),
			onPanResponderRelease: (e, gestureState) => _handlePanResponderEnd(e, gestureState),
			onPanResponderTerminationRequest: (evt, gestureState) => true,
			onPanResponderTerminate: (e, gestureState) => _handlePanResponderEnd(e, gestureState),
			onShouldBlockNativeResponder: (e, gestureState) => true
		})
	);

	useEffect(() => {
		elementWidthRef.current = elementWidth;
	}, [elementWidth]);

	const setTempValue = (val) => {
		tempValue.current = Math.floor(val);
		_setTempValue(Math.floor(val));
	};

	const setRotation = (val) => {
		_rotation.current = val;
		_setRotation(val);
		animation.value = val;
	};

	const setMyValueRotation = (val) => {
		_myValueRotation.current = val;
		_setMyValueRotation(val);
	};

	useEffect(() => {
		isDisabledRef.current = props.isDisabled;
	}, [props.isDisabled]);

	useEffect(() => {
		if (myValue) {
			const range = _getMaximum() - _getMinimum();
			const relative = (myValue - _getMinimum()) / range;
			setMyValueRotation(relative * MAXROTATION + INITIALROTATION);
		}
	}, [myValue]);

	useEffect(() => {
		if (value) {
			setTempValue(value);

			const range = _getMaximum() - _getMinimum();
			const relative = (value - _getMinimum()) / range;
			setRotation(relative * MAXROTATION + INITIALROTATION);
		}
	}, [value]);

	const _handleOnStartShouldSetPanResponder = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		// Should we become active when the user presses down on the circle?
		return !isDisabledRef.current && onChange !== undefined;
	};

	const _handleOnStartShouldSetPanResponderCapture = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		return false;
	};

	const _handleOnMoveShouldSetPanResponder = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		// Should we become active when the user moves a touch over the circle?

		return !isDisabledRef.current && onChange !== undefined;
	};

	const _handleOnMoveShouldSetPanResponderCapture = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		return false;
	};

	const _handleOnPanResponderGrant = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		if (!isDisabledRef.current && onChange) {
			if (lockScrolling) {
				lockScrolling(true);
			}
			startLocation.current = {
				x: e.nativeEvent.pageX,
				y: e.nativeEvent.pageY
			};

			location.current = {
				x: e.nativeEvent.pageX,
				y: e.nativeEvent.pageY
			};
		}
	};

	const _handleOnPanResponderMove = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		location.current = {
			x: startLocation.current.x + gestureState.dx,
			y: startLocation.current.y + gestureState.dy
		};

		const progress = _transFormStarValueByLocationX(
			startLocation.current.x + gestureState.dx,
			e.nativeEvent.locationY - containerHeight.current
		);
		const range = _getMaximum() - _getMinimum();
		const relative = progress / range;
		setRotation(relative * MAXROTATION + INITIALROTATION);
		setTempValue(relative * range + _getMinimum());
	};

	const _handlePanResponderEnd = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		if (lockScrolling) {
			lockScrolling(false);
		}
		if (onChange) {
			const progress = _transFormStarValueByLocationX(location.current.x, e.nativeEvent.locationY - containerHeight.current);
			const range = _getMaximum() - _getMinimum();
			const relative = progress / range;

			onChange(relative * range + _getMinimum());
		}
	};

	const _transFormStarValueByLocationX = (newXValue: number, heightInElement: number) => {
		const actualLocationX = newXValue - locationDiffX.current;
		const heightInElementPercentage = (heightInElement / containerHeight.current) * 100;
		if (actualLocationX > containerWidth.current) {
			return _getMaximum() - _getMinimum();
		} else {
			const val = Math.max(Math.floor(actualLocationX / elementWidthRef.current), 0);
			const isUnderScale = containerHeight.current + 20 < heightInElement;
			if (
				(val === _getMaximum() - 1 && heightInElementPercentage < 5) ||
				(isUnderScale && val > (_getMaximum() - _getMinimum()) / 2)
			) {
				return _getMaximum();
			}
			if (
				(val === _getMinimum() + 1 && heightInElementPercentage < 5) ||
				(isUnderScale && val < (_getMaximum() - _getMinimum()) / 2)
			) {
				return _getMinimum();
			}
			return val;
		}
	};

	const _getMinimum = () => {
		return minValue ?? DEFAULTSCALEMINVALUE;
	};

	const _getMaximum = () => {
		return maxValue ?? DEFAULTSCALEMAXVALUE;
	};

	const _getHandWidth = () => {
		return containerWidth.current * 0.5;
	};

	const _renderValue = () => {
		let str = `${tempValue.current}`;
		if (answerCount) {
			str += ` (${answerCount})`;
		}

		if (str) {
			return (
				<Text bold style={{ color: answerCount ? theme.contrast : theme.text }}>
					{str}
				</Text>
			);
		}
		return null;
	};

	const _renderScaleSteps = () => {
		const mySlices: any[] = [];
		const count = 9;
		for (let i = 0; i < count; i++) {
			const color = i % 2 === 0 ? theme.lightgray : theme.contentBackgroundColor ?? theme.background;
			mySlices.push({ percent: 0.5 / count, color });
		}

		let cumulativePercent = 0;

		function getCoordinatesForPercent(percent) {
			const x = Math.cos(2 * Math.PI * percent);
			const y = Math.sin(2 * Math.PI * percent);
			return [x, y];
		}

		let arr: ReactNode[] = [];
		mySlices.forEach((slice) => {
			const [startX, startY] = getCoordinatesForPercent(cumulativePercent);
			cumulativePercent += slice.percent;
			const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

			const largeArcFlag = slice.percent > 0.5 ? 1 : 0;
			const pathData = [
				`M ${startX} ${startY}`, // Move
				`A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
				'L 0 0' // Line
			].join(' ');
			arr.push(<Path d={pathData} fill={slice.color} key={pathData} />);
		});

		return (
			<View style={[StyleSheet.absoluteFill, { position: 'absolute', left: 0 }]}>
				<Svg
					height={containerWidth.current}
					width={containerWidth.current}
					viewBox="-1 -1 2 2"
					style={{ transform: [{ rotate: '180deg' }] }}
				>
					{arr}
				</Svg>
			</View>
		);
	};

	const _renderRotation = (style, color: string) => {
		return (
			<Animated.View
				style={[
					{
						width: 0,
						height: 0,
						position: 'absolute',
						backgroundColor: color,
						top: containerWidth.current / 2,
						left: containerWidth.current / 2,
						marginTop: -(_getHandWidth() / 2),
						paddingTop: _getHandWidth(),
						paddingHorizontal: secondHandWidth
					},
					style
				]}
			/>
		);
	};

	const _renderMyValue = () => {
		if (myValue) {
			return <Text center style={{ color: theme.primary, marginTop: 5 }}>{`${t('MyAnswer')}: ${myValue}`}</Text>;
		}

		return null;
	};

	const _renderRotationOnMyValue = () => {
		if (myValue) {
			return _renderRotation(
				{
					transform: [
						{ rotate: `${myValueRotation}deg` },
						{ translateY: -(secondHandOffset + (containerWidth.current * 0.5) / 2) }
					]
				},
				theme.primary
			);
		}
		return null;
	};

	return (
		<View style={{ paddingTop: 20 }}>
			<View style={{ width: '100%', position: 'absolute', top: 0, flexDirection: 'row', justifyContent: 'center' }}>
				<Text>{(_getMinimum() + _getMaximum()) / 2}</Text>
			</View>
			<View
				testID="activevote_panresponder"
				{...panResponder?.panHandlers}
				style={{
					flexDirection: 'row',
					width: '100%',
					alignItems: 'center',
					height: containerWidth.current / 2,
					borderWidth: 1,
					borderColor: theme.lightgray,
					...getTopRadiusStyle(containerWidth.current / 2),
					paddingBottom: 50
				}}
				onLayout={(e: LayoutChangeEvent) => {
					containerWidth.current = e.nativeEvent.layout.width;
					containerHeight.current = e.nativeEvent.layout.height;
					setElementWidth(e.nativeEvent.layout.width / (_getMaximum() - _getMinimum()));

					locationDiffX.current = (Dimensions.get('window').width - e.nativeEvent.layout.width) / 2;
				}}
			>
				{_renderScaleSteps()}

				{_renderRotationOnMyValue()}
				{_renderRotation(iconAnimationStyle, theme.contrast)}
			</View>

			<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
				<Text testID="activevote_slider_min">{_getMinimum()}</Text>
				{_renderValue()}
				<Text testID="activevote_slider_max">{_getMaximum()}</Text>
			</View>
			{_renderMyValue()}
		</View>
	);
};
