import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView, View } from 'react-native';

import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';
import { Icon, IconGlyphs } from 'components/Icon';
import { ChildButton } from 'components/Button';
import { normalizeFontSize } from 'helper';
import { BUTTONBORDERRADIUS } from 'components/Button/constants';
import { SearchBar } from 'components/SearchBar';
import { HSModal } from 'components/Modal/Modal';
import { hsInnerPadding } from 'config/styleConstants';

interface IFormIconPickerModal {
	testID: string;
	isVisible: boolean;
	onChange: (value: string | undefined) => void;
	onClose: () => void;
	label?: string;
}

interface IFamily {
	key: string;
	icons: any;
}

export const FormIconPickerModal = (props: IFormIconPickerModal) => {
	const { testID, isVisible, onChange, onClose, label } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [iconFamilies, setIconFamilies] = useState<IFamily[]>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchedIconFamilies, setSearchedFamilies] = useState<IFamily[]>([]);
	const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({});
	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [numColumns, setNumColumns] = useState<number>(2);
	const iconWidth = normalizeFontSize(80);

	useEffect(() => {
		if (isVisible) {
			const families: IFamily[] = [];

			Object.keys(IconGlyphs).forEach((key) => {
				const glyphKey = key.slice(0, -6);
				let icons: string[] = [];

				if (key === 'HelloSpacesGlyphs') {
					icons = IconGlyphs[key].glyphs.map((e) => e.css);
				} else {
					icons = Object.keys(IconGlyphs[key]);
				}

				if (icons?.length > 0) {
					families.push({
						key: glyphKey,
						icons
					});
				}
			});
			setIconFamilies(families);
		} else {
			setIsExpanded({});
		}
	}, [isVisible, searchTerm]);

	useEffect(() => {
		let families: IFamily[] = [...iconFamilies];

		if (searchTerm.length >= 3) {
			families.forEach((family) => {
				family.icons = family.icons.filter((name) => name.includes(searchTerm.toLowerCase()));
			});
		}

		families = families.filter((family) => family.icons.length > 0);

		const _expanded = { ...isExpanded };
		Object.keys(_expanded).forEach((key) => {
			const found = families.find((e) => e.key === key);
			if (!found) {
				delete _expanded[key];
			}
		});

		setIsExpanded(_expanded);
		setSearchedFamilies(families);
	}, [iconFamilies, searchTerm]);

	useEffect(() => {
		if (containerWidth > 0) {
			let _num = Math.round(containerWidth / iconWidth);
			setNumColumns(_num > 8 ? 8 : _num);
		}
	}, [containerWidth]);

	const _renderIcons = (family, index) => {
		if (isExpanded[family.key]) {
			return (
				<FlatList
					columnWrapperStyle={{ justifyContent: 'center', marginVertical: 8 }}
					horizontal={false}
					data={family.icons}
					numColumns={numColumns}
					key={`${testID}_${numColumns}_${family.key}`}
					renderItem={({ item }) => (
						<ChildButton
							testID={`iconpicker_${family.key}_${item}`}
							onPress={() => {
								onChange(`${family.key}:${item}`);
							}}
							key={`iconpicker_${family.key}_${item}`}
							style={{
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'center',
								maxWidth: iconWidth
							}}
						>
							<Icon
								size={normalizeFontSize(32)}
								containerStyle={{ paddingHorizontal: 12, paddingVertical: 4 }}
								name={`${family.key}:${item}`}
							/>
						</ChildButton>
					)}
					keyExtractor={(item) => `iconpicker_${family.key}_${item}`}
				/>
			);
		}

		return null;
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title={label}>
			<SearchBar
				testID="iconpicker_searchbar"
				value={searchTerm}
				onChange={(val) => setSearchTerm(val)}
				containerStyle={{ marginBottom: 19, marginTop: 30 }}
			/>
			<ScrollView
				style={{ marginHorizontal: -hsInnerPadding }}
				contentContainerStyle={{
					paddingHorizontal: hsInnerPadding
				}}
			>
				{searchedIconFamilies.map((family, index) => {
					return (
						<View
							key={`iconpicker_${family.key}_header`}
							style={{ width: '100%' }}
							onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
						>
							<ChildButton
								testID={`iconpicker_${family.key}_header`}
								onPress={() => {
									let _exp = { ...isExpanded };
									if (_exp[family.key]) {
										delete _exp[family.key];
									} else {
										_exp[family.key] = true;
									}
									setIsExpanded(_exp);
								}}
								style={{
									borderWidth: isExpanded[family.key] ? 2 : 1,
									borderColor: isExpanded[family.key] ? theme.primary : theme.lightgray,
									alignItems: 'center',
									padding: 4,
									borderRadius: BUTTONBORDERRADIUS,
									justifyContent: 'center',
									marginVertical: 8
								}}
							>
								<Text
									center
									style={{
										paddingVertical: 4,
										fontSize: normalizeFontSize(24)
									}}
								>
									{family.key}
								</Text>
							</ChildButton>
							{_renderIcons(family, index)}
						</View>
					);
				})}
			</ScrollView>
		</HSModal>
	);
};
