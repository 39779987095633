import {
	IAttendee,
	IContentTypeField,
	IExpo,
	ILauncherSpace,
	IMedia,
	IMediaItem,
	ISchedule,
	IStrapiBase,
	TMedia,
	TSelfServiceEditable
} from '.';

export interface ISpeaker extends IStrapiBase {
	speakerTitle?: string;
	title: string;
	bio?: string;
	jobTitle?: string;
	company?: string;
	image?: IMedia;
	companyImage?: IMedia;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	facebookProfileUrl?: string;
	youTubeProfileUrl?: string;
	tikTokProfileUrl?: string;
	selfServiceToken: string;
	website?: string;
	email?: string;
	selfServiceEmail?: string;
	spaceId: string;
	schedules?: ISchedule[];
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	expos: IExpo[];
	selfServiceEditable: TSelfServiceEditable;
	mediaitems?: IMediaItem[];
	attendee?: IAttendee;
	isHidden?: boolean;
}

export interface ICreateSpeakerFormValues {
	speakerTitle?: string;
	title: string;
	bio?: string;
	jobTitle?: string;
	company?: string;
	image?: TMedia;
	companyImage?: TMedia;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	facebookProfileUrl?: string;
	youTubeProfileUrl?: string;
	tikTokProfileUrl?: string;
	website?: string;
	email?: string;
	schedules?: number[];
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	expos?: number[];
	selfServiceEditable: TSelfServiceEditable;
	mediaitems?: number[];
	attendee?: number;
	isHidden?: boolean;
}

export interface IUpdateSpeakerFormValues {
	id?: number;
	selfServiceToken?: string;
	speakerTitle?: string;
	title: string;
	bio?: string;
	jobTitle?: string;
	company?: string;
	image?: TMedia;
	companyImage?: TMedia;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	facebookProfileUrl?: string;
	youTubeProfileUrl?: string;
	tikTokProfileUrl?: string;
	website?: string;
	email?: string;
	schedules?: number[];
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	expos?: number[];
	selfServiceEditable: TSelfServiceEditable;
	mediaitems?: number[];
	attendee?: number;
	isHidden?: boolean;
	updated_at?: string;
}

export interface ICreateSpeakerFormErrors {
	speakerTitle?: string;
	title?: string;
	bio?: string;
	jobTitle?: string;
	company?: string;
	image?: string;
	companyImage?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	facebookProfileUrl?: string;
	youTubeProfileUrl?: string;
	tikTokProfileUrl?: string;
	website?: string;
	email?: string;
	schedules?: string;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	expos?: string;
	selfServiceEditable?: string;
	mediaitems?: string;
	attendee?: string;
	isHidden?: string;
}

export interface IUpdateSpeakerFormErrors {
	speakerTitle?: string;
	title?: string;
	bio?: string;
	jobTitle?: string;
	company?: string;
	image?: string;
	companyImage?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	facebookProfileUrl?: string;
	youTubeProfileUrl?: string;
	tikTokProfileUrl?: string;
	website?: string;
	email?: string;
	schedules?: string;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	expos?: string;
	selfServiceEditable?: string;
	mediaitems?: string;
	attendee?: string;
	isHidden?: string;
}

export interface ICreateSpeakerPayload {
	space?: ILauncherSpace;
	speaker: ICreateSpeakerFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateSpeakerPayload {
	speaker: ISpeaker | IUpdateSpeakerFormValues;
	fields: IContentTypeField[];
	isDeletion?: boolean;
}

export const getCreateSpeakerInitialFormValues = () => {
	const values: ICreateSpeakerFormValues = {
		title: '',
		schedules: [],
		expos: [],
		mediaitems: [],
		selfServiceEditable: 'yes'
	};

	return values;
};
