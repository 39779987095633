import { ICreateVoteFormValues, IEditVoteFormErrors, ICreateVoteFormErrors, IEditVoteFormValues } from 'config/interfaces';
import React, { ReactNode, useState } from 'react';
import { Animated, Keyboard, ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { FormFromUntilDatePicker, FormImageSelection, FormReferenceSelect } from 'components/Form';
import { FormTextInput } from 'components/Form/FormTextInput';
import { TAllowAnonymousAnswers } from 'config/interfaces/vote';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';
import { H2 } from 'components/Text';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, normalizeFontSize } from 'helper';
import { NAVICONSIZE } from 'components/Form/constants';
import { HSCard } from 'components/Card';
import { useQuery } from 'hooks/useQuery';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { MediaTypeOptions } from 'expo-image-picker';

interface ICommenVoteForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	children?: ReactNode | ReactNode[];
	additionalFields?: ReactNode | ReactNode[];
	isLoading?: boolean;
	noMedia?: boolean;
}

export const CommonVoteForm = (props: ICommenVoteForm) => {
	const { testIdPrefix, values, errors, onChange, children, isLoading, additionalFields, noMedia } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const hasMediaLimit = values.votingType === 'pinOnImage' || values.votingType === 'happinessOMeter';

	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const _renderHideResults = () => {
		switch (values?.votingType) {
			case 'text':
			case 'applause':
			case 'externalUrl':
				return null;
			default:
				return (
					<FormMultiSwitch
						testID={`${testIdPrefix}_multiswitch_hideresults`}
						label={t('HideResultsLabel')}
						hint={t('HideResultsHint')}
						error={errors.hideResults}
						value={values.hideResults ? 'true' : 'false'}
						options={[
							{
								key: 'false',
								label: t('ShowResults')
							},
							{
								key: 'true',
								label: t('HideResults')
							}
						]}
						onChange={(val) => onChange({ ...values, hideResults: val === 'true' ? true : false })}
						isDisabled={isLoading}
					/>
				);
		}
	};

	return (
		<ScrollView
			keyboardShouldPersistTaps="handled"
			onScrollBeginDrag={() => Keyboard.dismiss()}
			scrollEventThrottle={0}
			testID={`${testIdPrefix}_scrollview`}
			contentContainerStyle={{ width: screenWidth, alignSelf: 'center', padding: EHorizontalScreenPadding.Wide }}
		>
			<View>
				<HSCard>
					<FormTextInput
						multiline
						testID={`${testIdPrefix}_textinput_question`}
						label={values.votingType === 'quiz' || values.votingType === 'survey' ? t('Title') : t('Question')}
						hint={values.votingType === 'quiz' || values.votingType === 'survey' ? t('VotingTitleHint') : t('questionHint')}
						isRequired
						value={values.question}
						error={errors.question}
						onChangeText={(value) => onChange({ ...values, question: value })}
						isDisabled={isLoading}
					/>

					<FormTextInput
						multiline
						testID={`${testIdPrefix}_textinput_hint`}
						label={t('Hint')}
						hint={values.votingType === 'quiz' || values.votingType === 'survey' ? t('VoteHintTitle') : t('VoteHint')}
						value={values.hint}
						error={errors.hint}
						onChangeText={(value) => onChange({ ...values, hint: value })}
						isDisabled={isLoading}
					/>

					{!noMedia && (
						<FormImageSelection
							key={`${testIdPrefix}_media_gallery`}
							testID={`${testIdPrefix}_media_gallery`}
							label={hasMediaLimit ? `${t('Image')}` : `${t('Media')} (${t('Images')}/${t('Videos')})`}
							media={values.media?.map((m) => m.media)}
							onChange={(value) => {
								if (value) {
									onChange({
										...values,
										media: value.map((v, index) => {
											return { media: v, order: index };
										})
									});
								}
							}}
							mediaTypes={MediaTypeOptions.All}
							limit={hasMediaLimit ? 1 : undefined}
							isRequired={values.votingType === 'pinOnImage'}
							ignoreAspectRatio={values.votingType === 'pinOnImage'}
						/>
					)}
					{values.votingType === 'pinOnImage' && (
						<FormMultiSwitch
							testID={`${testIdPrefix}_multiswitch_useAvatar`}
							label={t('UseAvatar')}
							hint={t('UseAvatarHint')}
							value={values.useAvatar ? 'true' : 'false'}
							error={errors.useAvatar}
							options={[
								{
									key: 'true',
									label: t('Yes')
								},
								{
									key: 'false',
									label: t('No')
								}
							]}
							onChange={(val) => onChange({ ...values, useAvatar: val === 'true' ? true : false })}
							isDisabled={isLoading}
						/>
					)}
					<FormReferenceSelect
						testID={`${testIdPrefix}_referenceselect_schedules`}
						label={t('schedules')}
						placeholder={t('PlaceholderSchedules')}
						hint={t('ScheduleReferenceVotingHint')}
						type="schedules"
						multiSelect
						error={errors.schedules}
						value={values.schedules}
						onSelect={(value) => onChange({ ...values, schedules: value as any })}
						isDisabled={isLoading}
					/>
					<FormReferenceSelect
						testID={`${testIdPrefix}_referenceselect_networkingrooms`}
						label={t('networking')}
						placeholder={t('PlaceholderNetworkingRooms')}
						hint={t('NetworkingRoomsReferenceVotingHint')}
						type="networkingrooms"
						multiSelect
						error={errors.networkingrooms}
						value={values.networkingrooms}
						onSelect={(value) => onChange({ ...values, networkingrooms: value as any })}
						isDisabled={isLoading}
					/>
					{children}
				</HSCard>
				<HSCard>
					<Animated.View>
						<ChildButton
							testID={`${testIdPrefix}_button_toggleexpand`}
							onPress={() => setIsExpanded(!isExpanded)}
							style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
						>
							<H2>{t('Extended Settings')}</H2>
							<Icon
								size={normalizeFontSize(NAVICONSIZE)}
								name={EDefaultIconSet.ChevronDown}
								containerStyle={{
									marginBottom: 20,
									transform: [{ rotate: isExpanded ? '180deg' : '0deg' }]
								}}
							/>
						</ChildButton>
					</Animated.View>
					<View style={{ height: isExpanded ? 'auto' : 0, overflow: 'hidden' }}>
						<FormMultiSwitch
							testID={`${testIdPrefix}_multiswitch_isactive`}
							label={t('VoteActive')}
							hint={t('IsActiveHint')}
							error={errors.isActive}
							value={values.isActive ? 'true' : 'false'}
							options={[
								{
									key: 'true',
									label: t('Active')
								},
								{
									key: 'false',
									label: t('Inactive')
								}
							]}
							onChange={(val) => onChange({ ...values, isActive: val === 'true' ? true : false })}
							isDisabled={isLoading}
						/>
						{values.votingType !== 'externalUrl' && (
							<FormMultiSwitch
								testID={`${testIdPrefix}_multiswitch_anonymousanswers`}
								label={t('AnonymousAnswersLabel')}
								hint={t('anonymousAnswersHint')}
								error={errors.allowAnonymousAnswers}
								value={values.useAvatar ? 'never' : values.allowAnonymousAnswers}
								options={[
									{ key: 'always', label: t('Yes') },
									{ key: 'optin', label: t('OptInAnonymous') },
									{ key: 'never', label: t('No') }
								]}
								onChange={(val) => onChange({ ...values, allowAnonymousAnswers: val as TAllowAnonymousAnswers })}
								isDisabled={isLoading || values.useAvatar}
							/>
						)}
						{_renderHideResults()}
						{additionalFields}
						<FormFromUntilDatePicker
							testIdPrefix={testIdPrefix}
							from={values.openFrom}
							until={values.openUntil}
							fromError={errors.openFrom}
							untilError={errors.openUntil}
							fromLabel={t('OpenFromLabel')}
							untilLabel={t('OpenUntilLabel')}
							hint={t('VoteDateHint')}
							label={t('datePickerLabel')}
							fromFieldName="openFrom"
							untilFieldName="openUntil"
							onChange={(field, value) => onChange({ ...values, [field]: value })}
							isDisabled={isLoading}
						/>
					</View>
				</HSCard>
			</View>
		</ScrollView>
	);
};
