import React, { ReactNode, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { IVote } from 'config/interfaces/vote';
import { DEFAULTSCALEMAXVALUE, DEFAULTSCALEMINVALUE, VoteScaleSlider } from './VoteScaleSlider';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getSubmitScaleFormSchema } from 'config/yupSchemas';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { VoteScaleTacho } from './VoteScaleTacho';
import { IVoteResult } from 'config/interfaces/voteanswer';
import { previewAnswerSlider } from 'helper/previewAnswers/previewAnswersSlider';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { FormSwitch } from 'components/Form/FormSwitch';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { RoundButton } from 'components/Button';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';
import { useNavigation } from '@react-navigation/native';

interface IVoteScale {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

interface IScaleResult {
	answerCount: number;
	averageAnswer: number;
}

const TESTIDPREFIX = 'activevote';

export const VoteScale = (props: IVoteScale) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { trackAction } = useTracker();
	const navigation = useNavigation();

	const [value, setValue] = useState<number | undefined>(
		Math.round(((vote.minValue ?? DEFAULTSCALEMINVALUE) + (vote.maxValue ?? DEFAULTSCALEMAXVALUE)) / 2)
	);
	const [tempValue, setTempValue] = useState<number | undefined>(value);
	const [result, setResult] = useState<IScaleResult>({
		answerCount: 0,
		averageAnswer: 0
	});
	const [valueSubmitted, setValueSubmitted] = useState<boolean>(false);
	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [lockScrolling, setLockScrolling] = useState<boolean>(false);
	const [showAnswersPressed, setShowAnswersPressed] = useState<boolean>(false);

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered) {
			setValueSubmitted(true);
			if (!vote.hideResults) {
				setVotingShowResults(true);
			}
		}
	}, [vote, votingIsAnswered]);

	useEffect(() => {
		let answerForThis: IVoteResult | undefined = undefined;

		if (isPreview) {
			answerForThis = previewAnswerSlider;
		} else {
			answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;
		}

		const myAnswer = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		);
		if (myAnswer) {
			setValue(Number(myAnswer.value));
			setValueSubmitted(true);
			setVotingIsAnswered(true);
		} else if (!showAnswersPressed && valueSubmitted) {
			setValue(5);
			setValueSubmitted(false);
			setVotingShowResults(false);
		}

		if (answerForThis) {
			setResult({
				answerCount: answerForThis.submissionCount,
				averageAnswer: Math.floor(answerForThis.options[0].value / answerForThis?.submissionCount)
			});
		} else {
			setResult({
				answerCount: 0,
				averageAnswer: 0
			});
		}
	}, [voteAnswers]);

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		const errors = await validateForm(
			getSubmitScaleFormSchema(vote.minValue ?? DEFAULTSCALEMINVALUE, vote.maxValue ?? DEFAULTSCALEMAXVALUE),
			{ value: tempValue }
		);
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}

		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		const res = await submitVoteAnswer({
			voteId: vote.id,
			value: tempValue,
			isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
		});
		navigation.setParams({ prohibitNavigation: false });
		setIsSubmitVoteAnswerLoading(false);
		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			setValueSubmitted(true);
			setVotingShowResults(true);
		}
	};

	const _renderResultByViewType = () => {
		switch (vote.viewType) {
			case 'tacho':
				return (
					<VoteScaleTacho
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						value={result.averageAnswer}
						isDisabled
						answerCount={result.answerCount}
						myValue={value}
					/>
				);
			case 'slider':
			default:
				return (
					<VoteScaleSlider
						testID={`${TESTIDPREFIX}_slider_value`}
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						value={value}
						isDisabled
						answerCount={result.answerCount}
						averageValue={result.averageAnswer}
						submitted={votingShowResults}
						resultsOnly={resultsOnly || vote?.hideResults || !vote.isActive}
						largerDesign={largerDesign}
						isResult={true}
					/>
				);
		}
	};

	const _renderFormScaleByViewType = () => {
		switch (vote.viewType) {
			case 'tacho':
				return (
					<VoteScaleTacho
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						value={tempValue}
						onChange={(val) => {
							setTempValue(val);
							if (!isPreview) navigation.setParams({ prohibitNavigation: true });
						}}
						lockScrolling={(val) => setLockScrolling(val)}
					/>
				);
			case 'slider':
			default:
				return (
					<VoteScaleSlider
						testID={`${TESTIDPREFIX}_slider_value`}
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						value={tempValue}
						onChange={(val) => {
							setTempValue(val);
							if (!isPreview) navigation.setParams({ prohibitNavigation: true });
						}}
						largerDesign={largerDesign}
					/>
				);
		}
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<View style={{ marginTop: 20, flexDirection: 'row', justifyContent: 'flex-end' }}>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}
		return null;
	};

	const _renderContent = () => {
		if ((votingShowResults && !vote?.hideResults) || resultsOnly || !vote.isActive) {
			return <HSCard>{_renderResultByViewType()}</HSCard>;
		}

		return <HSCard>{_renderFormScaleByViewType()}</HSCard>;
	};

	const _renderActionButtons = () => {
		if (resultsOnly || !vote.isActive) {
			return null;
		}
		let element: ReactNode = null;
		if (votingShowResults && !vote?.hideResults) {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						onPress={() => {
							setVotingShowResults(false);
							setShowAnswersPressed(false);
							setVotingShowResults(false);
							setVotingIsAnswered(false);
						}}
						title={!valueSubmitted ? t('answerOptions') : t('changeAnswer')}
						alignSelf="center"
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						icon={EDefaultIconSet.Save}
						testID={`${TESTIDPREFIX}_button_submit`}
						title={t('submitAnswer')}
						isLoading={isSubmitVoteAnswerLoading}
						onPress={() => {
							_submitVoteAnswer();
							setVotingShowResults(true);
						}}
						alignSelf="center"
					/>
				</HSCard>
			);
		}

		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					scrollEnabled={!lockScrolling}
					contentContainerStyle={{
						paddingBottom: SCROLL_PADDING_FLOATING,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					<ActiveVoteHeader largerText={largerDesign} showResult={resultsOnly} activeVote={vote} />
					{_renderContent()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButtons()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
		</View>
	);
};
