import React from 'react';
import { Button, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Text } from 'components/Text';
import { MULTISPACEURL } from 'config/constants';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';

export const SocketInfo = () => {
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const { isMultiSpaceSocketConnected } = useSelector((store: IRootState) => store.socket);

	const closeMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceSocket);
	const initMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.initMultiSpaceSocket);

	const _renderInfo = (label: string, isConnected: boolean, url: string) => {
		return (
			<View>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<Text style={{ color: theme.navigationTextColor ?? theme.text }}>{label}</Text>
					<Text style={{ color: isConnected ? theme.success : theme.danger }}>{isConnected ? 'Connected' : 'Disconnected'}</Text>
				</View>

				<Text numberOfLines={1} adjustsFontSizeToFit style={{ color: theme.navigationTextColor ?? theme.text }}>
					{url}
				</Text>
			</View>
		);
	};

	const _renderMultiSpaceInfo = () => {
		if (activeSpace && !isMultiSpaceSocketConnected) {
			return (
				<View style={{ marginTop: 10 }}>
					{_renderInfo('MultiSpace Info', isMultiSpaceSocketConnected, MULTISPACEURL)}
					<RoundButton
						testID="abc123"
						onPress={async () => {
							await closeMultiSpaceSocket({});
							await initMultiSpaceSocket({});
						}}
						icon={EDefaultIconSet.Reload}
						size="xs"
						title="Reconnect Socket"
					/>
				</View>
			);
		}

		return null;
	};

	if (activeSpace && !isMultiSpaceSocketConnected) {
		return <View style={{ paddingHorizontal: 10 }}>{_renderMultiSpaceInfo()}</View>;
	}

	return null;
};
