import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteScaleForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteScaleForm = (props: IVoteScaleForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm
			testIdPrefix={testIdPrefix}
			values={values}
			errors={errors}
			onChange={onChange}
			isLoading={isLoading}
			// additionalFields={
			// 	<FormMultiSwitch
			// 		testID={`${testIdPrefix}_multiswitch_scaleviewtype`}
			// 		label={t('Scale View Type')}
			// 		hint={t('Scale View Type Hint')}
			// 		error={errors.viewType}
			// 		value={values.viewType ?? 'slider'}
			// 		options={[
			// 			{ key: 'slider', label: 'Slider' },
			// 			{ key: 'tacho', label: 'Tacho' }
			// 		]}
			// 		onChange={(val) => onChange({ ...values, viewType: val })}
			// 		isDisabled={isLoading}
			// 	/>
			// }
		>
			<View>
				<FormTextInput
					testID={`${testIdPrefix}_textinput_minvalue`}
					label={t('VoteFormScaleMinValue')}
					hint={t('VoteFormScaleMinValueHint')}
					isRequired
					value={values.minValue?.toString()}
					error={errors.minValue}
					onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
					isDisabled={isLoading}
					keyboardType="number-pad"
					onlyNumbers
				/>
				<FormTextInput
					testID={`${testIdPrefix}_textinput_maxvalue`}
					label={t('VoteFormScaleMaxValue')}
					hint={t('VoteFormScaleMaxValueHint')}
					isRequired
					value={values.maxValue?.toString()}
					error={errors.maxValue}
					onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
					isDisabled={isLoading}
					keyboardType="number-pad"
					onlyNumbers
				/>
			</View>
		</CommonVoteForm>
	);
};
