import { IVote } from 'config/interfaces';

export const previewVotingsSurvey: IVote[] = [
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage1',
		votingType: 'optionVote',
		id: 99999999999999999991,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		options: [
			{
				id: 999991,
				description: 'Answer'
			},
			{
				id: 999992,
				description: 'Answer'
			},
			{
				id: 999993,
				description: 'Answer'
			},
			{
				id: 999994,
				description: 'Answer'
			}
		]
	},
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage2',
		votingType: 'optionVote',
		id: 99999999999999999992,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		options: [
			{
				id: 999991,
				description: 'Answer'
			},
			{
				id: 999992,
				description: 'Answer'
			}
		]
	},
	{
		spaceId: 'hspxPreview',
		publicId: 'hspxPreview',
		question: 'Frage3',
		votingType: 'optionVote',
		id: 99999999999999999993,
		updated_at: 'hspxPreview',
		created_at: 'hspxPreview',
		published_at: 'hspxPreview',
		options: [
			{
				id: 999991,
				description: 'Answer'
			},
			{
				id: 999992,
				description: 'Answer'
			},
			{
				id: 999993,
				description: 'Answer'
			}
		]
	}
];
