import { ChildButton, RoundButton, ShareButton } from 'components/Button';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { HSCard } from 'components/Card';
import { Icon } from 'components/Icon';
import { ListBubbles } from 'components/List';
import { Markdown } from 'components/Markdown';
import { ExpoContactModal } from 'components/Modal/ExpoContactModal';
import { ERoutes } from 'components/Navigation/routes';
import { NewsMediaItem } from 'components/News/NewsMediaItem';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H2, Title, Subtitle } from 'components/Text';
import { IAttendee, IContentTypeField, IExpo, ILauncherSpace, IMediaItem, ISchedule, ISpeaker, TSectionPosition } from 'config/interfaces';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, getDefaultAspectRatioStyle, isEmptyString, IS_WEB, openURL, _getSectionWidth } from 'helper';
import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { useDetail } from 'hooks/useDetail';
import { t } from 'i18next';
import React, { ReactNode, useRef, useState } from 'react';
import { FlatList, ScrollView, View } from 'react-native';
import { Text } from 'components/Text';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Image, PlaceholderGradient } from 'components/Image';
import { getIsImageOrVideoByExtension } from 'helper/media';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EXPO_DETAIL_IMAGEWIDTH, EXPO_DETAIL_IMAGEWIDTH_WEB } from 'config/constants';

interface IExpoDetail {
	testID: string;
	detailFields: IContentTypeField[];
	expo?: IExpo;
	isSelfService?: boolean;
	space?: ILauncherSpace;
	referencedSpeakers?: ISpeaker[];
	referencedMediaItems?: IMediaItem[];
	referencedAttendees?: IAttendee[];
	referencedSchedules?: ISchedule[];
	noScreenPaddings?: boolean;
}

export const ExpoDetail = (props: IExpoDetail) => {
	const {
		testID,
		expo,
		detailFields,
		isSelfService,
		space,
		referencedAttendees,
		referencedMediaItems,
		referencedSpeakers,
		referencedSchedules,
		noScreenPaddings
	} = props;
	const { renderSocialMedia, renderSections } = useDetail();
	const { trackAction } = useTracker();
	const { getContentTypeSections } = useContent('expo');
	const { isTabletOrMobile, screenWidth } = useQuery();
	const navigation = useNavigation();

	const flatlistRef = useRef<any | undefined>(undefined);

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

	const [isScrollStopped, setIsScrollStopped] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const isContactModalVisible = useSelector((store: IRootState) => store.temp.isContactModalVisible);

	const setIsContactModalVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setIsContactModalVisible);

	useFocusEffect(
		React.useCallback(() => {
			let interval;
			if (space?.enableGalleryScroll && flatlistRef.current && containerWidth > 1) {
				let _currentIndex = -1;
				let _iteration = 0;

				interval = setInterval(() => {
					if (isScrollStopped) {
						clearInterval(interval);
						return;
					}
					if (flatlistRef.current && expo?.imageGallery && expo.imageGallery.length > 1) {
						if (_currentIndex >= expo.imageGallery.length) {
							_iteration++;
							if (_iteration >= 5) {
								clearInterval(interval);
								return;
							} else {
								_currentIndex = -1;
							}
						}

						_currentIndex = _currentIndex + 1;
						flatlistRef.current.scrollToOffset({ offset: _currentIndex * containerWidth });

						if (expo.imageGallery[_currentIndex]?.media && expo.imageGallery[_currentIndex].media?.mime?.includes('video')) {
							clearInterval(interval);
							return;
						}
					}
				}, (space?.galleryScrollInterval ?? 5) * 1000);
			}
			return () => {
				clearInterval(interval);
			};
		}, [flatlistRef, space?.enableGalleryScroll, containerWidth, isScrollStopped, expo])
	);

	const _renderGallery = () => {
		if (expo?.imageGallery && expo?.imageGallery?.length > 0) {
			return (
				<View key={`${testID}_gallery`} style={{ marginBottom: hsBottomMargin }}>
					<FlatList
						ref={flatlistRef}
						scrollEnabled={expo?.imageGallery?.length > 1}
						data={expo.imageGallery}
						renderItem={({ item }) => {
							if (!item.media) {
								return null;
							}
							return (
								<NewsMediaItem
									item={item}
									width={containerWidth}
									isRounded
									onPlay={() => setIsScrollStopped(true)}
									openImageInNewTab
								/>
							);
						}}
						keyExtractor={(item, index) => `news_${item.id}_${index}`}
						style={{ width: '100%' }}
						pagingEnabled
						horizontal
						showsHorizontalScrollIndicator={false}
						onMomentumScrollEnd={(e) => setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth)}
						onScroll={(e) => {
							if (IS_WEB) {
								setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth);
							}
						}}
						onTouchMove={() => setIsScrollStopped(true)}
					/>
					<View style={{ position: 'relative', marginTop: 10 }}>{_renderCurrentMediaIndex()}</View>
				</View>
			);
		}
		return null;
	};

	const _renderCurrentMediaIndex = () => {
		if (expo?.imageGallery && expo?.imageGallery?.length > 1) {
			const length = expo?.imageGallery?.filter((i) => i.media !== undefined && i.media !== null)?.length;

			if (length > 0) {
				if (IS_WEB) {
					return (
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
								marginTop: 0,
								alignSelf: 'center',
								position: 'absolute',
								left: 0,
								right: 0
							}}
						>
							<ChildButton
								style={{ marginRight: 30 }}
								testID={`${testID}_button_left_${expo.id}`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex - 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronLeft} />
							</ChildButton>
							<ListBubbles totalCount={length} activeIndex={currentMediaIndex} noTopMargin />
							<ChildButton
								style={{ marginLeft: 30 }}
								testID={`${testID}_button_right_${expo.id}`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex + 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronRight} />
							</ChildButton>
						</View>
					);
				}
				return (
					<View style={{ alignSelf: 'center', position: 'absolute', left: 0, right: 0, paddingTop: 5 }}>
						<ListBubbles totalCount={length} activeIndex={currentMediaIndex} size="sm" noTopMargin />
					</View>
				);
			}
		}
		return null;
	};

	const _renderButtons = () => {
		const buttons: ReactNode[] = [];
		if (expo?.contactEmail) {
			buttons.push(
				<View key={`${testID}_button_contact`} style={{ flexDirection: 'row', justifyContent: 'center' }}>
					<RoundButton
						testID={`${testID}_button_contact`}
						title={t('contactButtonText')}
						icon={EDefaultIconSet.Mail}
						onPress={() => setIsContactModalVisible(true)}
						isStacked={!isTabletOrMobile}
						isDisabled={isSelfService}
					/>
				</View>
			);
		}

		const spaceMaps = content?.maps.filter((m) => m.spaceId === space?.spaceId && !m.isDeleted);

		let _mapPosition = expo?.mappositions?.find(
			(e) => e.map && spaceMaps?.find((m) => (typeof e.map === 'number' ? m.id === e.map : m.id === e.map?.id)) && !e.isDeleted
		);

		if (!_mapPosition) {
			spaceMaps?.forEach((map) => {
				const found = map?.mappositions?.find((p) => p?.expo === expo?.id && !p?.isDeleted);
				if (found) {
					_mapPosition = found;
				}
			});
		}

		const mapPosition = content?.mappositions?.find((e) => e.id === _mapPosition?.id && !e.isDeleted);

		if (mapPosition && expo) {
			buttons.push(
				<View style={{ flexDirection: 'row', justifyContent: 'center' }} key={`${testID}_button_map`}>
					<RoundButton
						testID={`${testID}_button_map`}
						title={expo.location !== null && !isEmptyString(expo.location) ? expo.location : t('locationButtonText')}
						icon={EDefaultIconSet.MapMarker}
						isDisabled={isSelfService}
						onPress={() => {
							trackAction('expo', 'Show On Map', expo.id.toString());
							navigation.navigate(ERoutes.Maps, {
								spaceId: space?.spaceId,
								mapId: mapPosition?.map?.id,
								positionId: mapPosition.id,
								key: 'maps'
							});
						}}
						isStacked={!isTabletOrMobile}
					/>
				</View>
			);
		}
		if (buttons.length > 0) {
			return (
				<View
					style={{
						flexDirection: 'row',
						justifyContent: buttons.length === 1 ? 'center' : 'space-between',
						flexWrap: 'wrap'
					}}
				>
					{buttons}
				</View>
			);
		}
		return null;
	};

	const _renderContactSection = (expo: IExpo, noCard?: boolean) => {
		const items: ReactNode[] = [];
		items.push(<H2>{t('Contact')}</H2>);

		const fields = ['contactName', 'street', 'zip', 'city', 'country', 'contactEmail', 'contactPhone'];
		const actionFields = ['contactEmail', 'contactPhone'];

		fields.forEach((f) => {
			if (detailFields.find((field) => field.fieldName === f && field.showOnDetailScreen) && expo[f]) {
				items.push(
					<ChildButton
						testID={`${testID}_button_${f}`}
						key={`${testID}_button_${f}`}
						isDisabled={!actionFields.includes(f)}
						onPress={() => {
							switch (f) {
								case 'contactEmail':
									openURL(`mailto:${expo[f]}`);
									break;
								case 'contactPhone':
									openURL(`tel:${expo[f]}`);
									break;
								default:
									break;
							}
						}}
					>
						<Text
							bold={f === 'contactName'}
							style={{
								marginTop: f === 'contactName' ? 5 : 0,
								textDecorationLine: actionFields.includes(f) ? 'underline' : 'none'
							}}
						>
							{expo[f]}
						</Text>
					</ChildButton>
				);
			}
		});
		if (items.length > 2) {
			if (noCard) {
				return <View style={{}}>{items}</View>;
			}
			return <HSCard style={{}}>{items}</HSCard>;
		}
		return null;
	};

	const _renderDescriptionSection = (expo) => {
		const desc = detailFields.filter((e) => e.fieldName === 'description' && e.visibility === 'visible');
		if (desc.length > 0 && expo?.description) {
			return (
				<HSCard style={{}} key={`${testID}_description_${expo?.id}`}>
					<H2>{t('About')}</H2>
					<Markdown markdown={expo?.description} />
				</HSCard>
			);
		}
		return null;
	};

	const _renderImage = (fullWidth?: boolean) => {
		let url: string | number = '';

		if (typeof expo?.logo === 'number') {
			url = expo.logo;
		} else if (typeof expo?.logo === 'string') {
			url = expo.logo;
		} else if (expo?.logo?.url) {
			url = expo.logo.url;
		}
		let isFile = false;
		let extension = '';
		try {
			if (expo?.logo?.mime?.includes('image')) {
				isFile = false;
			} else if (typeof url === 'string') {
				if (url.startsWith('data:image') || url.startsWith('/static/')) {
					isFile = false;
				} else {
					const splitted = url.split('/');
					if (splitted) {
						const _extension = splitted[splitted.length - 1].split('.')[1];
						extension = _extension;
						isFile = !getIsImageOrVideoByExtension(_extension);
					}
				}
			}
		} catch (error) {}

		if (url) {
			const _width = fullWidth ? '100%' : IS_WEB && !isTabletOrMobile ? EXPO_DETAIL_IMAGEWIDTH_WEB : EXPO_DETAIL_IMAGEWIDTH;
			return (
				<Image
					testID={`${testID}_image`}
					style={{ ...getDefaultAspectRatioStyle(_width), borderRadius: hsBorderRadius }}
					url={url}
					expectedRatio={16 / 9}
					imageSize={IS_WEB ? 'full' : 'small'}
					resizeMode={'contain'}
				/>
			);
		}

		if (expo && space?.showGradientOnMissingImage) {
			return (
				<PlaceholderGradient
					itemId={expo?.id}
					title={expo?.title}
					width={IS_WEB && !isTabletOrMobile ? EXPO_DETAIL_IMAGEWIDTH_WEB : EXPO_DETAIL_IMAGEWIDTH}
					rounded={'full'}
					smallText
				/>
			);
		}

		return null;
	};

	const _renderSections = (position?: TSectionPosition, fields?: IContentTypeField[], logoWidth?: number | string) => {
		if (expo) {
			return renderSections({
				contentType: 'expo',
				testID: testID,
				item: expo,
				forbiddenFieldNames: ['selfServiceEditable', 'selfServiceEmail'],
				forbiddenFieldTypes: [],
				position: position,
				isSelfService: isSelfService,
				detailFields: fields ?? detailFields,
				space: space,
				referencedSpeakers: referencedSpeakers,
				referencedMediaItems: referencedMediaItems,
				referencedAttendees: referencedAttendees,
				referencedSchedules: referencedSchedules,
				renderGallery: _renderGallery,
				logoWidth: logoWidth
			});
		}
		return null;
	};

	const _renderThirds = () => {
		if (!expo) {
			return null;
		}
		const hasLeftSection = (_renderSections('left')?.filter((s) => s !== null).length ?? 0) > 0;
		const hasRightSection = (_renderSections('right')?.filter((s) => s !== null).length ?? 0) > 0;

		const logoWidth = hasLeftSection && hasRightSection ? '100%' : IS_WEB ? EXPO_DETAIL_IMAGEWIDTH_WEB : EXPO_DETAIL_IMAGEWIDTH;

		if (!hasLeftSection && !hasRightSection) {
			return (
				<View
					style={{
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderStacked()}
				</View>
			);
		}

		const sectionWithGallery = getContentTypeSections(isSelfService, detailFields, true, space).find((section) => {
			return section.fields.find((f) => f.fieldType === 'gallery');
		});

		const galleryPosition = sectionWithGallery?.fields?.find((f) => f.fieldType === 'section')?.sectionPosition;

		return (
			<View
				key={`${testID}_expo_${expo.id}`}
				style={{ flexDirection: 'row', justifyContent: hasLeftSection && hasRightSection ? 'space-between' : 'center' }}
			>
				{hasLeftSection && (
					<View
						key={`${testID}_sections_left`}
						testID={`${testID}_sections_left`}
						onLayout={(e) => {
							if (galleryPosition === 'left') {
								setContainerWidth(e.nativeEvent.layout.width - hsInnerPadding * 2);
							}
						}}
						style={{
							alignItems: 'center',
							width: hasRightSection ? _getSectionWidth('left', 'expo', space) : screenWidth,
							flexDirection: 'column',
							alignSelf: hasRightSection ? 'auto' : 'center'
						}}
					>
						{_renderSections('left', undefined, logoWidth)}
					</View>
				)}

				{hasRightSection && (
					<View
						key={`${testID}_sections_right`}
						testID={`${testID}_sections_right`}
						onLayout={(e) => {
							if (galleryPosition === 'right') {
								setContainerWidth(e.nativeEvent.layout.width - hsInnerPadding * 2);
							}
						}}
						style={{
							alignItems: 'center',
							width: hasLeftSection ? _getSectionWidth('right', 'expo', space) : screenWidth,
							flexDirection: 'column',
							alignSelf: hasLeftSection ? 'auto' : 'center'
						}}
					>
						{_renderSections('right', undefined, logoWidth)}
					</View>
				)}
			</View>
		);
	};

	const _renderStacked = () => {
		if (!expo) {
			return null;
		}
		return (
			<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width - hsInnerPadding * 2)} key={`${testID}_expo_${expo.id}`}>
				<HSCard style={{ alignItems: 'center' }}>
					{_renderImage()}
					<Title style={{ marginTop: 20, marginBottom: 5 }}>{expo.title}</Title>
					<Subtitle>{expo.catchphrase}</Subtitle>
					{renderSocialMedia(
						'expo',
						detailFields,
						expo,
						testID,
						<View style={{ marginHorizontal: 5 }}>
							<FavoriteButton testID={`${testID}_button_favorite`} id={expo.id} type="expo" />
						</View>,
						<ShareButton
							testID={`${testID}_button_share`}
							key={`${testID}_button_share`}
							type="expo"
							itemId={expo.id}
							title={expo.title}
							message={expo.description}
							route={ERoutes.ExpoDetails}
						/>
					)}
					{_renderButtons()}
				</HSCard>
				{_renderDescriptionSection(expo)}
				{_renderContactSection(expo)}
				{_renderSections(
					undefined,
					detailFields.filter(
						(e) =>
							e.fieldName !== 'logo' &&
							e.fieldName !== 'title' &&
							e.fieldName !== 'catchphrase' &&
							e.fieldName !== 'selfServiceEditable' &&
							e.fieldName !== 'selfServiceEmail' &&
							// Contact
							e.fieldName !== 'description' &&
							e.fieldName !== 'location' &&
							e.fieldName !== 'contactEmail' &&
							e.fieldName !== 'contactName' &&
							e.fieldName !== 'contactPhone' &&
							e.fieldName !== 'street' &&
							e.fieldName !== 'city' &&
							e.fieldName !== 'zip' &&
							e.fieldName !== 'country' &&
							e.fieldName !== 'website' &&
							// category
							e.fieldType !== 'category' &&
							e.fieldType !== 'socialmedia'
					)
				)}
			</View>
		);
	};

	const _renderExpo = () => {
		if (expo && space) {
			if (isTabletOrMobile) {
				return (
					<View
						style={{
							width: screenWidth,
							alignSelf: 'center'
						}}
					>
						{_renderStacked()}
					</View>
				);
			}
			const viewType = space.expoDetailViewType ?? space.globalDetailViewType;

			switch (viewType) {
				case 'thirds_1/3_2/3':
				case 'thirds_2/3_1/3':
					return _renderThirds();
				case 'stacked':
				default:
					return _renderStacked();
			}
		}

		return null;
	};

	return (
		<View style={{ width: '100%' }}>
			{expo ? (
				<ScrollView
					contentContainerStyle={{
						paddingTop: noScreenPaddings ? 0 : hsTopScreenPadding,
						paddingHorizontal: noScreenPaddings ? 0 : EHorizontalScreenPadding.Wide,
						alignSelf: 'center',
						width: '100%'
					}}
					testID={`${testID}_scrollview`}
				>
					{_renderExpo()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			<ExpoContactModal
				isVisible={isContactModalVisible}
				onClose={() => setIsContactModalVisible(false)}
				testIdPrefix={testID}
				type="expo"
				id={expo?.id ?? 0}
				title={expo?.title ?? ''}
				expoMail={expo?.contactEmail}
			/>
		</View>
	);
};
