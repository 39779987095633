import { IVote } from 'config/interfaces/vote';
import { getSubmitNpsFormSchema } from 'config/yupSchemas';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import React, { ReactNode, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { IVoteAnswer, IVoteResult } from 'config/interfaces/voteanswer';
import { previewAnswerRating } from 'helper/previewAnswers/previewAnswersRating';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { FormSwitch } from 'components/Form/FormSwitch';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { RoundButton } from 'components/Button';
import { H1, Text, VoteSum } from 'components/Text';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { hsActionButtonMargin, hsTextBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';
import { NpsScale } from './NpsScale';
import { useNavigation } from '@react-navigation/native';
import { HSModal } from 'components/Modal/Modal';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormLabel } from 'components/Form/FormLabel';

interface IVoteNps {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

interface INpsResult {
	answerCount: number;
	averageAnswer: number;
}

const TESTIDPREFIX = 'activevote';

export const VoteNps = (props: IVoteNps) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { trackAction } = useTracker();
	const navigation = useNavigation();

	const [result, setResult] = useState<INpsResult>({
		answerCount: 0,
		averageAnswer: 0
	});

	const [valueSubmitted, setValueSubmitted] = useState<boolean>(false);
	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [value, setValue] = useState<number>(0);
	const [myAnswer, setMyAnswer] = useState<IVoteAnswer | undefined>(undefined);
	const [reasonAnswer, setReasonAnswer] = useState<string>('');

	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isReasonModalVisible, setIsReasonModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [lockScrolling, setLockScrolling] = useState<boolean>(false);
	const [showAnswersPressed, setShowAnswersPressed] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered) {
			setValueSubmitted(true);
			if (!vote.hideResults) {
				setVotingShowResults(true);
			}
		}
	}, [vote, votingIsAnswered]);

	useEffect(() => {
		let total = 0;

		let answerForThis: IVoteResult | undefined = undefined;
		if (isPreview) {
			answerForThis = previewAnswerRating;
		} else {
			answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;
		}

		const _myAnswer = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		) as IVoteAnswer | undefined;

		if (_myAnswer) {
			setValue(Number(_myAnswer.value));
			setMyAnswer(_myAnswer);
			setValueSubmitted(true);
			setVotingIsAnswered(true);
		} else if (!showAnswersPressed && valueSubmitted) {
			setValue(0);
			setValueSubmitted(false);
			setVotingShowResults(false);
		}

		if (answerForThis) {
			answerForThis.options.forEach((option) => {
				total += Number(option.key) * option.value;
			});
		}

		const averageAnswer = Math.round((total / (answerForThis?.submissionCount ?? 0) + Number.EPSILON) * 10) / 10;

		setResult({
			answerCount: answerForThis?.submissionCount ?? 0,
			averageAnswer
		});
	}, [voteAnswers]);

	useEffect(() => {
		if (myAnswer?.reasonAnswer) {
			setReasonAnswer(myAnswer.reasonAnswer);
		}
	}, [myAnswer?.reasonAnswer]);

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		if (isPreview) {
			setVotingShowResults(true);
			return;
		}
		const errors = await validateForm(getSubmitNpsFormSchema(), { value });
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}

		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		const res = await submitVoteAnswer({
			voteId: vote.id,
			value,
			reasonAnswer,
			isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
		});
		navigation.setParams({ prohibitNavigation: false });
		setIsSubmitVoteAnswerLoading(false);
		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			setValueSubmitted(true);
			setVotingShowResults(true);
		}
		if (!isReasonModalVisible && vote.requestReason && vote.requestReasonOptions?.includes(value.toString())) {
			setReasonAnswer('');
			setIsReasonModalVisible(true);
		} else {
			setIsReasonModalVisible(false);
		}
	};

	const _renderResult = () => {
		return (
			<View>
				<HSCard>
					<NpsScale
						value={Math.round(result.averageAnswer)}
						isDisabled
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						numberOfVotes={result.answerCount}
						largerDesign={largerDesign}
						minValueText={vote.minValueText}
						maxValueText={vote.maxValueText}
					/>
					<View style={{ marginTop: hsTopScreenPadding / 2 }}>
						<VoteSum largerDesign={largerDesign} value={result.answerCount} />
					</View>
				</HSCard>
				{!resultsOnly && vote.requestReason && !isEmptyString(reasonAnswer) && !isReasonModalVisible && (
					<HSCard>
						<FormLabel testID={`${TESTIDPREFIX}_label_reasonanswer`} label={t('Reason')} />
						<Text>{reasonAnswer}</Text>
					</HSCard>
				)}
			</View>
		);
	};

	const _renderAnonymousSwitch = () => {
		if (vote?.allowAnonymousAnswers === 'optin') {
			return (
				<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: hsActionButtonMargin }}>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderForm = () => {
		return (
			<View>
				<HSCard>
					<NpsScale
						value={value}
						onChange={(val) => {
							setValue(val);
							if (!isPreview) navigation.setParams({ prohibitNavigation: true });
						}}
						minValue={vote.minValue}
						maxValue={vote.maxValue}
						largerDesign={largerDesign}
						minValueText={vote.minValueText}
						maxValueText={vote.maxValueText}
					/>
				</HSCard>
				{!resultsOnly && vote.requestReason && !isEmptyString(reasonAnswer) && !isReasonModalVisible && (
					<HSCard>
						<FormLabel testID={`${TESTIDPREFIX}_label_reasonanswer`} label={t('Reason')} />
						<Text>{reasonAnswer}</Text>
					</HSCard>
				)}
			</View>
		);
	};

	const _renderContent = () => {
		if ((votingShowResults && !vote?.hideResults) || resultsOnly || !vote.isActive) {
			return _renderResult();
		}

		return _renderForm();
	};

	const _renderActionButtons = () => {
		if (resultsOnly || (votingShowResults && vote?.hideResults) || !vote.isActive) {
			return null;
		}
		let element: ReactNode = null;
		if (votingShowResults && !vote?.hideResults) {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						onPress={() => {
							setVotingShowResults(false);
							setShowAnswersPressed(false);
							setVotingIsAnswered(false);
						}}
						title={!valueSubmitted ? t('answerOptions') : t('changeAnswer')}
						alignSelf="center"
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						icon={EDefaultIconSet.Save}
						testID={`${TESTIDPREFIX}_button_submit`}
						title={t('submitAnswer')}
						isLoading={isSubmitVoteAnswerLoading}
						onPress={() => {
							_submitVoteAnswer();
							setVotingShowResults(true);
						}}
						alignSelf="center"
					/>
				</HSCard>
			);
		}

		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					scrollEnabled={!lockScrolling}
					contentContainerStyle={{
						paddingBottom: SCROLL_PADDING_FLOATING,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					<ActiveVoteHeader largerText={largerDesign} showResult={resultsOnly} activeVote={vote} />
					{_renderContent()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButtons()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
			<HSModal
				isVisible={isReasonModalVisible}
				onClose={() => {
					setIsReasonModalVisible(false);
					setReasonAnswer('');
				}}
			>
				<ScrollView style={{ width: '100%' }}>
					<View style={{ flex: 1, justifyContent: 'center' }}>
						<H1 largerText>{t('Thank you!')}</H1>
						<Text largerText style={{ marginBottom: hsTextBottomMargin }}>
							{t('reasonText')
								.replace('%VALUE%', value.toString())
								.replace(
									'%CUSTOM%',
									!vote.requestReasonText || isEmptyString(vote.requestReasonText)
										? t('reasonTextDefault')
										: vote.requestReasonText
								)}
						</Text>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_reasonanswer`}
							multiline
							placeholder={`${t('Reason')}...`}
							value={reasonAnswer}
							onChangeText={(val) => setReasonAnswer(val)}
						/>
						<RoundButton
							isFloatingButton
							icon={EDefaultIconSet.Save}
							testID={`${TESTIDPREFIX}_button_submitreason`}
							title={t('submitAnswer')}
							isLoading={isSubmitVoteAnswerLoading}
							onPress={() => _submitVoteAnswer()}
							alignSelf="center"
							isDisabled={isEmptyString(reasonAnswer)}
						/>
					</View>
				</ScrollView>
			</HSModal>
		</View>
	);
};
