import { ICreateSpaceFormValues, IEditSpaceFormValues, ILandingpageFormValues, ISpaceAdminAddUserFormValues } from 'config/interfaces';
import { getValidURL, isEmptyString } from 'helper';
import i18next from 'i18next';
import * as Yup from 'yup';
import {
	getEmailError,
	getMaxError,
	getMaxNumberError,
	getMinError,
	getMinNumberError,
	getMinOptionsError,
	getRequiredError,
	getWebsiteError
} from './errors';
import moment from 'moment';

export const getCreateSpaceFormSchema = (item: ICreateSpaceFormValues) => {
	return Yup.object().shape({
		title: Yup.string()
			.min(6)
			.required(getRequiredError(i18next.t('Space Title'))),
		spaceId: Yup.string()
			.min(6, getMinError(6))
			.max(12, getMaxError(12))
			.matches(/^[a-zA-Z0-9]*$/, i18next.t('spaceidRegexError'))
			.required(getRequiredError(i18next.t('SpaceID'))),
		startDate: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('StartDate')))
			.test('formNewsShowFromError', i18next.t('formNewsShowFromError'), () => {
				if (moment(item.startDate).isValid()) {
					return true;
				}
				return false;
			})
			.test(
				'dateAfterMaxiumumError',
				i18next.t('dateAfterMaxiumumError').replace('%MAXDATE%', moment(item.endDate).format('DD.MM.YYYY HH:mm')),
				() => {
					if (isEmptyString(item.endDate) || isEmptyString(item.startDate)) {
						return true;
					}
					if (moment(item.startDate).isAfter(moment(item.endDate))) {
						return false;
					}
					return true;
				}
			),
		endDate: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('EndDate')))
			.test('formNewsUntilFromError', i18next.t('formNewsShowUntilError'), () => {
				if (isEmptyString(item.endDate)) {
					return false;
				}
				if (moment(item.endDate).isValid()) {
					return true;
				}
				return false;
			})
			.test(
				'dateBeforeMinmumError',
				i18next.t('dateBeforeMinmumError').replace('%MINDATE%', moment(item.startDate).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.endDate) || isEmptyString(item.startDate)) {
						return true;
					}
					if (moment(item.endDate).isBefore(moment(item.startDate))) {
						return false;
					}
					return true;
				}
			)
	});
};

export const getEditSpaceFormSchema = (item: IEditSpaceFormValues) => {
	if (!isEmptyString(item.dataProtectionUrl) || !isEmptyString(item.readTermsUrl)) {
		if (item.dataProtectionUrl && !isEmptyString(item.dataProtectionUrl)) {
			item.dataProtectionUrl = getValidURL(item.dataProtectionUrl);
		}

		if (item.readTermsUrl && !isEmptyString(item.readTermsUrl)) {
			item.readTermsUrl = getValidURL(item.readTermsUrl);
		}
	}

	return Yup.object().shape({
		title: Yup.string()
			.min(6, getMinError(6))
			.required(getRequiredError(i18next.t('Space Title'))),
		spaceId: Yup.string()
			.min(6, getMinError(6))
			.max(12, getMaxError(12))
			.matches(/^[a-zA-Z0-9]*$/, i18next.t('spaceidRegexError'))
			.required(getRequiredError(i18next.t('SpaceID'))),
		language: Yup.string().nullable(),
		description: Yup.string().max(255).nullable(),
		customUserConsentText: Yup.string().nullable(),
		defaultMailTemplateHeader: Yup.string().nullable(),
		defaultMailTemplateFooter: Yup.string().nullable(),
		spacePin: Yup.string().min(4, getMinError(4)).max(6, getMaxError(6)).nullable(),
		startDate: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('StartDate')))
			.test('formNewsShowFromError', i18next.t('formNewsShowFromError'), () => {
				if (moment(item.startDate).isValid()) {
					return true;
				}
				return false;
			})
			.test(
				'dateAfterMaxiumumError',
				i18next.t('dateAfterMaxiumumError').replace('%MAXDATE%', moment(item.endDate).format('DD.MM.YYYY HH:mm')),
				() => {
					if (isEmptyString(item.endDate) || isEmptyString(item.startDate)) {
						return true;
					}
					if (moment(item.startDate).isAfter(moment(item.endDate))) {
						return false;
					}
					return true;
				}
			),
		endDate: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('EndDate')))
			.test('formNewsUntilFromError', i18next.t('formNewsShowUntilError'), () => {
				if (isEmptyString(item.endDate)) {
					return false;
				}
				if (moment(item.endDate).isValid()) {
					return true;
				}
				return false;
			})
			.test(
				'dateBeforeMinmumError',
				i18next.t('dateBeforeMinmumError').replace('%MINDATE%', moment(item.startDate).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.endDate) || isEmptyString(item.startDate)) {
						return true;
					}
					if (moment(item.endDate).isBefore(moment(item.startDate))) {
						return false;
					}
					return true;
				}
			),
		agendaHeightPerMinuteWeb: Yup.number()
			.min(1, getMinNumberError(1))
			.required(getRequiredError(i18next.t('agendaHeightPerMinuteWeb'))),
		agendaWidthPerStageWeb: Yup.number()
			.min(10, getMinNumberError(10))
			.required(getRequiredError(i18next.t('agendaWidthPerStageWeb'))),
		agendaHeightPerMinuteApp: Yup.number()
			.min(1, getMinNumberError(1))
			.required(getRequiredError(i18next.t('agendaHeightPerMinuteApp'))),
		agendaWidthPerStageApp: Yup.number()
			.min(10, getMinNumberError(10))
			.required(getRequiredError(i18next.t('agendaWidthPerStageApp'))),
		dataProtectionUrl: Yup.string().nullable().url(getWebsiteError(item.dataProtectionUrl)),
		readTermsUrl: Yup.string().nullable().url(getWebsiteError(item.readTermsUrl)),
		enableGalleryScroll: Yup.boolean().nullable(),
		galleryScrollInterval: Yup.number()
			.nullable()
			.when('enableGalleryScroll', {
				is: true,
				then: Yup.number().min(1, getMinNumberError(1)).max(60, getMaxNumberError(60)).required()
			}),
		gaTrackingId: Yup.string().nullable(),
		matomoTrackingUrl: Yup.string().url(getWebsiteError()).nullable(),
		matomoSiteId: Yup.number().nullable()
	});
};

export const getEditSpaceDesignFormSchema = (item: IEditSpaceFormValues) => {
	return Yup.object().shape({
		spaceId: Yup.string()
			.min(6, getMinError(6))
			.max(12, getMaxError(12))
			.matches(/^[a-zA-Z0-9]*$/, i18next.t('spaceidRegexError'))
			.required(getRequiredError(i18next.t('SpaceID'))),
		primaryColor: Yup.string().max(9).nullable(),
		textColor: Yup.string().max(9).nullable(),
		backgroundColor: Yup.string().max(9).nullable(),
		contrastColor: Yup.string().max(9).nullable()
	});
};

export const getEditSpaceSponsorsFormSchema = (item: IEditSpaceFormValues) => {
	return Yup.object().shape({
		spaceId: Yup.string()
			.min(6, getMinError(6))
			.max(12, getMaxError(12))
			.matches(/^[a-zA-Z0-9]*$/, i18next.t('spaceidRegexError'))
			.required(getRequiredError(i18next.t('SpaceID'))),
		sponsorTypeFeed: Yup.string().nullable(),
		sponsorTypeAgenda: Yup.string().nullable(),
		sponsorTypeExpo: Yup.string().nullable(),
		sponsorTypeAttendees: Yup.string().nullable(),
		sponsorTypeNetworking: Yup.string().nullable(),
		sponsorTypeVotings: Yup.string().nullable(),
		sponsorTypeSpeaker: Yup.string().nullable(),
		sponsorTypeMediaItem: Yup.string().nullable()
	});
};

export const getEditSpaceFeaturesFormSchema = (item: IEditSpaceFormValues) => {
	return Yup.object().shape({
		spaceId: Yup.string()
			.min(6, getMinError(6))
			.max(12, getMaxError(12))
			.matches(/^[a-zA-Z0-9]*$/, i18next.t('spaceidRegexError'))
			.required(getRequiredError(i18next.t('SpaceID'))),
		features: Yup.object().shape({
			tabbarCount: Yup.number()
				.min(1, getMinNumberError(1))
				.max(5, getMaxNumberError(5))
				.required(getRequiredError(i18next.t('TabBarCount')))
		})
	});
};

export const getSpaceAdminAddUserFormSchema = (item: ISpaceAdminAddUserFormValues) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(getEmailError('E-Mail'))
			.required(getRequiredError(i18next.t('E-Mail')))
	});
};

export const getFeatureValidationSchema = (item) => {
	if (!isEmptyString(item.externalUrl) || !isEmptyString(item.iFrameUrl)) {
		if (item.externalUrl && !isEmptyString(item.externalUrl)) {
			item.externalUrl = getValidURL(item.externalUrl);
		}

		if (item.iFrameUrl && !isEmptyString(item.iFrameUrl)) {
			item.iFrameUrl = getValidURL(item.iFrameUrl);
		}
	}

	return Yup.object().shape({
		label: Yup.string().required(getRequiredError('Label')).nullable().max(32, getMaxError(32)),
		icon: Yup.string().required(getRequiredError('Icon')).nullable(),
		contentType: Yup.string()
			.test('error', getRequiredError(i18next.t('ContentType')), () => {
				if (item.isCustom && !item.contentType) {
					return false;
				}

				return true;
			})
			.nullable(),
		iFrameUrl: Yup.string()
			.url(getWebsiteError(item.iFrameUrl))
			.test('error', getRequiredError('iFrame URL'), () => {
				if (item.contentType === 'iFrame' && isEmptyString(item.iFrameUrl)) {
					return false;
				}

				return true;
			})
			.nullable(),
		externalUrl: Yup.string()
			.url(getWebsiteError(item.externalUrl))
			.test('error', getRequiredError(i18next.t('External Url')), () => {
				if (item.contentType === 'externalUrl' && isEmptyString(item.externalUrl)) {
					return false;
				}

				return true;
			})
			.nullable(),
		content: Yup.string()
			.test('error', getRequiredError(i18next.t('Content')), () => {
				if (item.contentType === 'markdown' && isEmptyString(item.content)) {
					return false;
				}

				return true;
			})
			.nullable(),
		filter: Yup.object()
			.shape({
				fieldName: Yup.string().nullable(),
				keys: Yup.string()
					.test('error', getMinOptionsError(1), () => {
						if (item.filter?.fieldName && !isEmptyString(item.filter?.fieldName)) {
							const myValue = item.filter.keys.split(',');

							if (isEmptyString(item.filter.keys) || myValue.length === 0) {
								return false;
							}
						}

						return true;
					})
					.nullable()
			})
			.nullable()
	});
};

export const getSpaceLandingpageFormSchema = (item: ILandingpageFormValues) => {
	if (item.landingpageUrl && !isEmptyString(item.landingpageUrl)) {
		item.landingpageUrl = getValidURL(item.landingpageUrl);
	}

	return Yup.object().shape({
		landingpageUrl: Yup.string().url(getWebsiteError(item.landingpageUrl)).nullable()
	});
};
