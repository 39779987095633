import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { AttendeeList } from 'components/Attendee';
import { Animated, BackHandler, Dimensions, View } from 'react-native';
import {
	hsBottomMargin,
	hsTopScreenPadding,
	LISTHEADER_ANIMATIONTIME_IN,
	LISTHEADER_ANIMATIONTIME_OUT,
	LISTHEADER_ANIMATION_GUESSED_HEIGHT,
	LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_OPACITY_HIGH,
	LISTHEADER_ANIMATION_OPACITY_LOW,
	LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION
} from 'config/styleConstants';
import { EDefaultIconSet, getTimingAnimation, IS_ANDROID, IS_WEB } from 'helper';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';

import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { IActiveScreenFilter, IAttendee, IFeatureInfo } from 'config/interfaces';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { ScrollEvent } from 'react-native-spring-scrollview';
import { ContentListHeader } from 'components/List';
import { TABBAR_HEIGHT } from 'config/constants';
import moment from 'moment';
import { showToast } from 'helper/toast';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.AttendeesList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.AttendeesList>;
type RouteParams = StackParamList[ERoutes.AttendeesList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'attendeelistscreen';

export const AttendeeListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { getRegistrationFields } = useRegistration();
	const { exportAsCSV } = useExport();
	const { activeSpace } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [feature, setFeature] = useState<IFeatureInfo | undefined>(undefined);

	const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [isReloadLoading, setIsReloadLoading] = useState<boolean>(false);

	const [activeScreenFilters, setActiveScreenFilters] = useState<IActiveScreenFilter>({ filters: ['all'] });
	const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
	const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
	const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(LISTHEADER_ANIMATION_GUESSED_HEIGHT));
	const [animatedOpacity, setAnimatedOpacity] = useState(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
	const [scrollableContentHeight, setScrollableContentHeight] = useState<number>(0);
	const [lastReload, setLastReload] = useState<number>(0);

	const scrollOffset = useRef<number>(0);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const loadAttendeeDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeDelta);

	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (activeSpace?.features && route.params.key) {
			let _feature: typeof feature = undefined;

			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Attendees')
			});

			setFeature(_feature);
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const _reloadData = () => {
		const now = moment().unix();

		if (now - lastReload > 10) {
			showToast('info', undefined, t('Reloading Attendees'));

			setIsReloadLoading(true);
			loadAttendeeDelta({ ignoreInterval: true });
			setLastReload(now);
			setTimeout(() => {
				setIsReloadLoading(false);
			}, 10000);
		}
	};

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	const _handleSelect = (itemId: string) => {
		let _selected = [...selectedEntries];
		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _handleActionSheetPress = (action: string) => {
		switch (action) {
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'sendMail':
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					contentType: 'attendee',
					selectedEntries: selectedEntries,
					entryPoint: 'template'
				});
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'attendee' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IAttendee[] = [];

				if (selectedEntries.length > 0) {
					selectedEntries.forEach((id) => {
						const _item = attendees.find((e) => e.userId === id);
						if (_item) {
							items.push(_item);
						}
					});
				} else {
					items = [...attendees];
				}

				exportAsCSV(getRegistrationFields(), items, 'attendees');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _animateHeader = (e: ScrollEvent) => {
		const { y } = e.nativeEvent.contentOffset;

		if (
			headerHeight &&
			!IS_WEB &&
			scrollableContentHeight > LISTHEADER_ANIMATION_HEIGHT_FACTOR * Dimensions.get('window').height - headerHeight
		) {
			if (y > headerHeight * LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR && y > scrollOffset.current) {
				if (isHeaderExpanded) {
					setIsHeaderExpanded(false);

					Animated.parallel([
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_LOW, LISTHEADER_ANIMATIONTIME_OUT, false),
						getTimingAnimation(animatedHeight, 0, LISTHEADER_ANIMATIONTIME_OUT, false)
					]).start();
				}
			} else if (
				y + (scrollOffset.current < LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION ? 0 : LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION) <
				scrollOffset.current
			) {
				if (!isHeaderExpanded) {
					setIsHeaderExpanded(true);
					Animated.parallel([
						getTimingAnimation(animatedHeight, headerHeight, LISTHEADER_ANIMATIONTIME_IN, false),
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_HIGH, LISTHEADER_ANIMATIONTIME_IN, false)
					]).start();
				}
			}
			scrollOffset.current = y;
		}
	};

	if (!isScreenFocused) {
		return null;
	}

	return (
		<ScreenContainer contentKey="attendees">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				<Animated.View
					style={{
						height: animatedHeight,
						opacity: animatedOpacity,
						marginBottom: isHeaderExpanded ? hsBottomMargin : 0
					}}
				>
					<ContentListHeader
						testID={TESTIDPREFIX}
						type="attendee"
						searchTerm={searchTerm}
						onSearchChange={(val) => setSearchTerm(val)}
						modalFilterTitle={t('Filter Attendees')}
						activeScreenFilters={activeScreenFilters.filters}
						onScreenFilterChange={(values) => setActiveScreenFilters(values)}
						activeModalFilters={activeFilters}
						onModalFilterChange={(val) => setActiveFilters(val)}
						feature={feature}
						onLayout={(e) => {
							const { height } = e.nativeEvent.layout;

							if (!headerHeight || height > headerHeight) {
								setHeaderHeight(height);
								setAnimatedHeight(new Animated.Value(height));
							}
						}}
						reload={() => _reloadData()}
						isReloadDisabled={isReloadLoading}
					/>
				</Animated.View>
				<AttendeeList
					noSponsors={route?.params?.noSponsors}
					testID={TESTIDPREFIX}
					onSelectAll={isEditMode ? (selected) => setSelectedEntries(selected) : undefined}
					onSelect={isEditMode ? (itemId) => _handleSelect(itemId) : undefined}
					selectedAttendees={selectedEntries}
					searchTerm={searchTerm}
					activeScreenFilter={activeScreenFilters}
					activeModalFilters={activeFilters}
					feature={feature}
					onScroll={(e) => _animateHeader(e)}
					onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
					onPress={(item) => {
						if (isEditMode) {
							_handleSelect(item.userId);
						} else {
							navigation.navigate(ERoutes.AttendeeDetails, {
								userId: item.userId,
								spaceId: activeSpace?.spaceId
							});
						}
					}}
					noCard={IS_WEB ? activeSpace?.webLayout === 'cards' : activeSpace?.appLayout === 'cards'}
				/>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_editmode`}
						icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
						label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
						onPress={() => _handleActionSheetPress('editMode')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_sendmail`}
						icon={EDefaultIconSet.Mail}
						label={t('Send Mail')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('sendMail')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Data')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const AttendeeListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const screenWidth = Dimensions.get('window').width;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				{/* <NavigationHeaderIconButton
					buttonText={screenWidth > 720 ? i18next.t('Export Scan') : ''}
					testID={`${TESTIDPREFIX}_button_exportscannedattendees`}
					icon={EDefaultIconSet.Export}
					onPress={props.options.onExportScanned}
					textLeft
				/>
				{!IS_WEB && <NavigationHeaderScanUser />} */}
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};

export const AttendeeListScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const screenWidth = Dimensions.get('window').width;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				{/* <NavigationHeaderIconButton
					buttonText={screenWidth > 720 ? i18next.t('Export Scan') : ''}
					testID={`${TESTIDPREFIX}_button_exportscannedattendees`}
					icon={EDefaultIconSet.Export}
					onPress={props.options.onExportScanned}
					textLeft
				/>
				{!IS_WEB && <NavigationHeaderScanUser />} */}
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};
