import React, { useState } from 'react';
import { ChildButton } from 'components/Button';

import { Image } from 'components/Image';
import { Icon } from 'components/Icon';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Avatar } from 'components/User/Avatar';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { ImageStyle } from 'react-native';

interface INavigationHeaderImageButtonComponent {
	testID: string;
	onPress?: Function;
	url?: string;
	displayAsAvatar?: boolean;
	fallbackIcon: string;
	fallbackIconColor?: string;
	imageStyle: ImageStyle;
}

export const NavigationHeaderImageButton = (props: INavigationHeaderImageButtonComponent) => {
	const { testID, onPress, url, displayAsAvatar, fallbackIcon, fallbackIconColor, imageStyle } = props;

	const [imageRatio, setImageRatio] = useState<number>(1);
	const [hasRatio, setHasRatio] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const _renderImage = () => {
		if (displayAsAvatar) {
			return <Avatar size="xxs" avatar={url} fullName={profile ? `${profile.firstName} ${profile.lastName}` : undefined} />;
		}

		if (url) {
			return (
				<Image
					url={url}
					style={imageStyle ? imageStyle : { height: 22, aspectRatio: imageRatio, marginTop: 5 }}
					onLoad={(e) => {
						if (!hasRatio) {
							setImageRatio(e.source.width / e.source.height);
							setHasRatio(true);
						}
					}}
				/>
			);
		}

		return <NavigationHeaderIconButton onPress={onPress} testID="header_button_icon" icon={fallbackIcon} color={fallbackIconColor} />;
	};

	return (
		<ChildButton
			testID={testID}
			onPress={() => {
				if (onPress) {
					onPress();
				}
			}}
			style={{ padding: 5, marginTop: 3 }}
		>
			{_renderImage()}
		</ChildButton>
	);
};
