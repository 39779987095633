import React from 'react';
import { ChildButton } from 'components/Button/ChildButton';
import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';
import { Spinner } from 'components/Spinner';
import { EDefaultIconSet, isEmptyString } from 'helper';

interface IRoundButton {
	testID: string;
	icon?: string;
	title?: string;
	onPress?: () => void;
	onLongPress?: () => void;
	isActive?: boolean;
	isLoading?: boolean;
	isDisabled?: boolean;
	isStacked?: boolean;
	color?: string;
	textColor?: string;
	iconColor?: string;
	borderColor?: string;
	leftBadge?: number;
	badge?: number;
	boldBadge?: boolean;
	size?: 'xs' | 'sm';
	isOutline?: boolean;
	alignSelf?: ViewStyle['alignSelf'];
	largerDesign?: boolean;
	isFloatingButton?: boolean;
	noMargin?: boolean;
	isExternalButton?: boolean;
	isAdministrateButton?: boolean;
	isAdministratePinButton?: boolean;
	iconText?: string;
}

const BORDER_WIDTH = 1;
const BORDER_WIDTH_FLOATING = 2;
const MAGIC_NUMBER = 1.6;

export const RoundButton = (props: IRoundButton) => {
	const {
		testID,
		icon,
		title,
		onPress,
		onLongPress,
		isActive,
		isLoading,
		isDisabled,
		isStacked,
		color,
		textColor,
		iconColor,
		borderColor,
		leftBadge,
		badge,
		boldBadge,
		size,
		isOutline,
		alignSelf,
		largerDesign,
		isFloatingButton,
		noMargin,
		isExternalButton,
		isAdministrateButton,
		isAdministratePinButton,
		iconText
	} = props;

	const { theme } = useTheme();

	let ICONSIZE = 25;

	if (testID.endsWith('button_validate')) ICONSIZE = 20;

	switch (size) {
		case 'xs':
			ICONSIZE = 15;
			break;
		case 'sm':
			ICONSIZE = 20;
			break;
		default:
			break;
	}

	if (largerDesign) {
		ICONSIZE = ICONSIZE * 1.5;
	}

	const _renderIconOrText = () => {
		if (!isEmptyString(iconText)) {
			return (
				<Text adjustsFontSizeToFit style={{ color: _getIconAndSpinnerColor(), fontSize: size === 'xs' ? 14 : 18 }}>
					{iconText}
				</Text>
			);
		}
		if (icon) {
			return <Icon testID={`${testID}_${icon}`} name={icon} size={ICONSIZE} color={_getIconAndSpinnerColor()} />;
		}

		return null;
	};

	const _renderIconOrLoadingOrText = () => {
		if (icon) {
			if (isLoading) {
				return <Spinner size={ICONSIZE} color={_getIconAndSpinnerColor()} />;
			}
			return (
				<View>
					{_renderIconOrText()}
					{!badge && isExternalButton && (
						<Icon
							testID={`${testID}_${icon}_external`}
							name={EDefaultIconSet.ArrowUpRight}
							size={ICONSIZE * 0.5}
							containerStyle={{ position: 'absolute', top: -4, right: -10 }}
							color={theme.danger}
						/>
					)}
				</View>
			);
		}
		return null;
	};

	const _renderLeftBadge = () => {
		if (leftBadge && leftBadge > 0) {
			return (
				<View
					style={{
						borderRadius: 20,
						backgroundColor: theme.warning,
						position: 'absolute',
						top: isAdministrateButton || isAdministratePinButton ? -3 : -6,
						left: isAdministrateButton || isAdministratePinButton ? -4 : -10,
						paddingHorizontal: 6,
						paddingVertical: 2
					}}
				>
					<Text
						adjustsFontSizeToFit
						numberOfLines={1}
						bold={boldBadge}
						style={{
							fontSize: largerDesign ? 18 : 12,
							color: theme.white
						}}
					>
						{leftBadge}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderBadge = () => {
		if (badge && badge > 0) {
			return (
				<View
					style={{
						borderRadius: 20,
						backgroundColor: theme.danger,
						position: 'absolute',
						top: isAdministrateButton || isAdministratePinButton ? -3 : -6,
						right: isAdministrateButton || isAdministratePinButton ? -4 : -10,
						paddingHorizontal: 6,
						paddingVertical: 2
					}}
				>
					<Text
						testID={`${testID}_badge`}
						adjustsFontSizeToFit
						numberOfLines={1}
						bold={boldBadge}
						style={{
							fontSize: largerDesign ? 18 : 12,
							color: theme.white
						}}
					>
						{badge}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _getButtonBackgroundColor = () => {
		if (isFloatingButton) {
			return color;
		}
		if (isOutline || isAdministratePinButton) {
			return undefined;
		} else {
			if (isDisabled) {
				return theme.formgray;
			}

			return color ?? theme.primary;
		}
	};

	const _getButtonBorderColor = () => {
		if (isDisabled) {
			return theme.formgray;
		}

		if (isFloatingButton) {
			return borderColor ?? theme.contrast;
		}

		return borderColor ?? color ?? theme.primary;
	};

	const _getBorderWidth = () => {
		if (isFloatingButton && title) {
			return BORDER_WIDTH_FLOATING;
		}
		return 0;
	};

	const _getButtonBorderColorInner = () => {
		if (isFloatingButton && !title) {
			return theme.contrast;
		}
		if (isDisabled) {
			return theme.formgray;
		}
		return color ?? theme.primary;
	};

	const _getBorderWidthInner = () => {
		if (isFloatingButton && !title) {
			return BORDER_WIDTH_FLOATING;
		}
		if (isFloatingButton || isAdministrateButton || isAdministratePinButton) {
			return 0;
		}
		return BORDER_WIDTH;
	};

	const _getIconAndSpinnerColor = () => {
		if (isFloatingButton) {
			if (isDisabled) {
				return theme.formgray;
			}
			return iconColor ?? color ?? theme.primary;
		}
		if (isOutline) {
			if (isDisabled) {
				return theme.formgray;
			}

			return iconColor ?? color ?? theme.primary;
		}

		return iconColor ?? theme.primaryContrast;
	};

	const _getTextColor = () => {
		if (isDisabled) {
			return theme.formgray;
		}

		if (isActive) {
			return theme.primary;
		}

		return textColor ?? theme.text;
	};

	const _getBackgroundColor = () => {
		return isAdministratePinButton ? 'transparent' : theme.contentBackgroundColor;
	};

	return (
		<ChildButton
			testID={testID}
			onPress={() => {
				if (onPress && !isLoading) {
					onPress();
				}
			}}
			onLongPress={() => {
				if (onLongPress && !isLoading) {
					onLongPress();
				}
			}}
			isDisabled={isDisabled}
			activeOpacity={!onPress && !onLongPress ? 1 : undefined}
			style={[
				{
					alignSelf: alignSelf ?? 'flex-start',
					flexDirection: isStacked ? 'column' : 'row',
					alignItems: 'center',
					paddingVertical: 5,
					paddingHorizontal: 5,
					paddingRight: title && !isStacked && !noMargin ? 20 : 5,
					backgroundColor: title && !isStacked ? _getBackgroundColor() : 'transparent',
					borderRadius: 9999,
					marginBottom: !isStacked && !noMargin ? 5 : 0,
					borderWidth: _getBorderWidth(),
					borderColor: _getButtonBorderColor()
				},
				isAdministrateButton || isAdministratePinButton ? { maxWidth: '100%', paddingRight: 10 } : null,
				isEmptyString(icon) && !isEmptyString(title) ? { paddingLeft: title && !isStacked && !noMargin ? 20 : 5 } : {}
			]}
		>
			<View
				style={{
					position: 'relative',
					justifyContent: 'center',
					alignItems: 'center',
					height: ICONSIZE * MAGIC_NUMBER,
					width: isEmptyString(icon) && !isEmptyString(title) ? 'auto' : ICONSIZE * MAGIC_NUMBER,
					borderRadius: 9999,
					borderColor: _getButtonBorderColorInner(),
					backgroundColor: _getButtonBackgroundColor(),
					borderWidth: _getBorderWidthInner()
				}}
			>
				{_renderIconOrLoadingOrText()}
				{_renderLeftBadge()}
				{_renderBadge()}
			</View>

			{title && !isEmptyString(title) && (
				<Text
					largerText={largerDesign}
					bold={isActive}
					numberOfLines={title.includes('\n') ? 2 : 1}
					adjustsFontSizeToFit
					style={{
						color: _getTextColor(),
						marginLeft: isStacked || isFloatingButton || isAdministrateButton || isAdministratePinButton ? 0 : 5,
						marginTop: isStacked ? 5 : 0
					}}
				>
					{title}
				</Text>
			)}
		</ChildButton>
	);
};
