import { ChildButton } from 'components/Button';

import { BOTTOM_SHEET_BOTTOM_PADDING, TABBAR_HEIGHT } from 'config/constants';
import { hsBorderRadius } from 'config/styleConstants';
import { getTopRadiusStyle, IS_WEB } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import RBSheet from 'react-native-raw-bottom-sheet';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BOTTOMSHEETVIEWBUTTONHEIGHT } from './BottomSheetViewButton';

interface IProps {
	children?: ReactNode[];
}

export const BottomSheet = React.forwardRef((props: IProps, ref: React.ForwardedRef<RBSheet>) => {
	const { children } = props;
	const { isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const { top } = useSafeAreaInsets();

	const [sheetHeight, setSheetHeight] = useState<number>(0);
	const webHeight = Dimensions.get('window').height - TABBAR_HEIGHT;

	const isSideSheet = IS_WEB && !isTabletOrMobile;

	useEffect(() => {
		let _height = 0;

		if (children) {
			_height = children.filter((e) => e).length * BOTTOMSHEETVIEWBUTTONHEIGHT + BOTTOM_SHEET_BOTTOM_PADDING;
		}

		setSheetHeight(_height);
	}, [children]);

	const _renderChildren = () => {
		if (isSideSheet) {
			return (
				<View style={{ flex: 1, flexDirection: 'row' }}>
					<ChildButton
						testID="sidesheet__button_backdrop"
						onPress={() => ref?.current?.close()}
						style={{ flex: 1, backgroundColor: 'transparent' }}
					/>
					<View
						style={{
							height: '100%',
							width: 300,
							paddingTop: top + 5,
							alignSelf: 'flex-end',
							backgroundColor: theme.background
						}}
					>
						{children}
					</View>
				</View>
			);
		}

		return children;
	};

	return (
		<RBSheet
			ref={ref}
			openDuration={isSideSheet ? 100 : 250}
			height={isSideSheet ? webHeight : sheetHeight}
			customStyles={{
				container: {
					...getTopRadiusStyle(isSideSheet ? 0 : hsBorderRadius),
					paddingTop: isSideSheet ? 0 : 5,
					backgroundColor: isSideSheet ? 'transparent' : theme.background
				}
			}}
		>
			{_renderChildren()}
		</RBSheet>
	);
});
