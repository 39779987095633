import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IStream, IUpdateStreamFormValues } from 'config/interfaces';
import { EDefaultIconSet } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import { StreamUpdateForm } from 'components/Forms/Stream/StreamUpdateForm';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StreamEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StreamEdit>;
type RouteParams = StackParamList[ERoutes.StreamEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'streamupdate';

export const StreamUpdateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IUpdateStreamFormValues>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateStream = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateStream);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateStream(),
			isLoading
		});
	}, [activeSpace, formValues, isLoading, isFormLocked]);

	useEffect(() => {
		let _stream: typeof formValues = {};

		if (activeSpace && content.streams) {
			const values: any = content.streams.find((e) => e.id === route.params.id);

			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}

		setFormValues(_stream);
	}, [activeSpace, content, route.params.id]);

	const _applyFormValues = (values: IStream) => {
		setFormValues({
			...values,
			schedules: values.schedules ? values.schedules.map((sc) => sc.id) : [],
			mediaitem: values.mediaitem?.id
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _updateStream = async () => {
		if (formValues) {
			if (isFormLocked) {
				showAlert({
					title: t('Item has changed'),
					message: t('ItemFormLockedSubtitle'),
					buttons: [
						{
							text: t('Apply Changes'),
							onPress: () => {
								const values = content.streams.find((e) => e.id === route.params.id);
								_applyFormValues(values!);
							}
						},
						{
							text: t('Cancel'),
							style: 'destructive'
						}
					]
				});
				return;
			}

			setIsLoading(true);
			const res = await updateStream({ stream: formValues });
			if (res) {
				setIsLoading(false);
				navigation.setParams({ prohibitNavigation: false });
				navigation.goBack();
			} else {
				setIsLoading(false);
			}
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="streams">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{formValues && (
					<StreamUpdateForm
						testID={TESTIDPREFIX}
						values={formValues}
						onSubmit={_updateStream}
						isLoading={isLoading}
						onChange={(values) => updateFormValues(values)}
						allowReferences
					/>
				)}
				<LoadingModal isLoading={isLoading} />
			</View>
		</ScreenContainer>
	);
};

export const StreamUpdateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Stream')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
