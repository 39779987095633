import React from 'react';
import { View } from 'react-native';

import { ChildButton } from 'components/Button';
import { ISpeaker } from 'config/interfaces';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { Avatar } from 'components/User';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { hsBorderRadius, hsBottomMargin } from 'config/styleConstants';
interface ISpeakerReferenceItem {
	testID: string;
	item: ISpeaker;
	onPress: () => void;
}

export const SpeakerReferenceItem = (props: ISpeakerReferenceItem) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (!isEmptyString(item.jobTitle) && !isEmptyString(item.company)) {
			str = `${item.jobTitle} | ${item.company}`;
		} else if (item.jobTitle && !isEmptyString(item.jobTitle)) {
			str = item.jobTitle;
		} else if (item.company && !isEmptyString(item.company)) {
			str = item.company;
		}

		if (!isEmptyString(str)) {
			return <Text style={{ fontSize: 12 }}>{str}</Text>;
		}

		return null;
	};

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: hsBottomMargin,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: hsBorderRadius,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<Avatar avatar={item.image?.formats?.small?.url ?? item.image?.url} fullName={item.title} size="listItem" />
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row' }}>
					<Text bold style={{ marginRight: item.isHidden ? 5 : 0 }}>{`${item.speakerTitle ? `${item.speakerTitle} ` : ''}${
						item.title
					}`}</Text>
					{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
				</View>
				{_renderJobtitleAndCompany()}
			</View>
			{SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
