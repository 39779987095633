import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderBackButton, NavigationHeaderTitle } from 'components/Navigation/Header';

import { Tutorial } from 'components/Tutorial';
import { useTranslation } from 'react-i18next';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.Tutorial>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.Tutorial>;
type RouteParams = NoSpaceStackParamList[ERoutes.Tutorial];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const TutorialScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();

	return (
		<ScreenContainer>
			<Tutorial
				onAfterEnd={() => {
					if (navigation.canGoBack()) {
						navigation.goBack();
					}
				}}
			/>
		</ScreenContainer>
	);
};

export const TutorialScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title="" />
		</NavigationHeader>
	);
};
