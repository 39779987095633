import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

const _getGeneralInfo = () => {
	let md = ``;

	if (i18next.language === 'en') {
		md = `Please see the bullet points below when typing in a ticketcode:

- A ticketcode has to be unique across your space.
- A ticketcode can only be used by one attendee
- Attendees have to exactly type in their ticketcode (We automatically transform ticketcodes to lowercase when saving)
- Ideally use the attendee's mail as ticketcode
- You can find more help and informations in our [ticket help page](https://forum.hellospaces.de/c/ticketing/8)

		`;
	} else {
		md = `Bitte beachte bei der Vergabe eines Ticketcodes die folgenden Punkte:

- Ein Ticketcode darf nur 1x pro Space vorkommen
- Ein Ticketcode kann nur von einem Teilnehmer verwendet werden
- Teilnehmer müssen den gewählten Ticketcode exakt eingeben (Wir wandeln den Ticketcode automatisch in nur Kleinbuchstaben beim speichern um)
- Nutze idealerweise die E-Mail Adresse des Teilnehmers als Ticketcode
- Weitere Hilfe und Informationen findest du in userer [Ticket Hilfe](https://forum.hellospaces.de/c/ticketing/8)

		`;
	}

	return md;
};

const _getPersonalInfo = () => {
	let md = ``;

	if (i18next.language === 'en') {
		md = `All of the following fields are optional. Feel free to prefill them for your attendees. After typing in their ticketcode all prefilled fields will be applied to the registration form.

		`;
	} else {
		md = `Die folgenden Felder sind alle Optional. Nutze Sie, um Daten für deine Teilnehmer vorauszufüllen. Nach Eingabe des Ticketcodes werden alle vorausgefüllten Felder im Registrierungsformular übernommen.

		`;
	}

	return md;
};


export const getDefaultTicketFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'label1',
			fieldLabel: _getGeneralInfo(),
			fieldType: 'label',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never',
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'ticketcode',
			fieldLabel: i18next.t('ticketcode'),
			fieldType: 'string',
			isRequired: true,
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			autoCapitalize: 'none'
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Personal Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'label2',
			fieldLabel: _getPersonalInfo(),
			fieldType: 'label',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'firstName',
			fieldLabel: i18next.t('firstName'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'lastName',
			fieldLabel: i18next.t('lastName'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'email',
			fieldLabel: i18next.t('email'),
			fieldType: 'email',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'jobTitle',
			fieldLabel: i18next.t('jobTitle'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'company',
			fieldLabel: i18next.t('company'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'website',
			fieldLabel: i18next.t('website'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'linkedInProfileUrl',
			fieldLabel: i18next.t('linkedInProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'xingProfileUrl',
			fieldLabel: i18next.t('xingProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'twitterProfileUrl',
			fieldLabel: i18next.t('twitterProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'blogUrl',
			fieldLabel: i18next.t('blog'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'facebookProfileUrl',
			fieldLabel: i18next.t('facebookProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'instagramProfileUrl',
			fieldLabel: i18next.t('instagramProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'youTubeProfileUrl',
			fieldLabel: i18next.t('youTubeProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'tikTokProfileUrl',
			fieldLabel: i18next.t('tikTokProfile'),
			fieldType: 'website',
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
	];

	return [...arr];
};
