import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import { isFeatureActive, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import { IActiveNetworkingRoom, IJoinNetworkingRoomPayload, INetworkingRoomAttendeeOverview } from 'rematch/interfaces';
import { RootModel } from './index';

type IDefaultState = {
	activeNetworkingRoom?: IActiveNetworkingRoom;
	networkingRoomAttendeeOverviews: INetworkingRoomAttendeeOverview[];
};

export const networking = createModel<RootModel>()({
	state: {
		activeNetworkingRoom: undefined,
		networkingRoomAttendeeOverviews: []
	} as IDefaultState,
	reducers: {
		setActiveNetworkingRoom(state, activeNetworkingRoom: IDefaultState['activeNetworkingRoom']) {
			rematchLog('setActiveNetworkingRoom');
			return {
				...state,
				activeNetworkingRoom
			};
		},
		addNetworkingRoomAttendeeOverviews(state, newOverviews: IDefaultState['networkingRoomAttendeeOverviews']) {
			rematchLog('addNetworkingRoomAttendeeOverviews');
			const networkingRoomAttendeeOverviews = state.networkingRoomAttendeeOverviews ? [...state.networkingRoomAttendeeOverviews] : [];

			newOverviews.forEach((overview) => {
				const index = networkingRoomAttendeeOverviews.findIndex((e) => e.externalMeetingId === overview.externalMeetingId);
				if (index !== -1) {
					networkingRoomAttendeeOverviews[index] = overview;
				} else {
					networkingRoomAttendeeOverviews.push(overview);
				}
			});
			return {
				...state,
				networkingRoomAttendeeOverviews
			};
		},
		clear(state) {
			rematchLog('clear networking');
			return {
				activeNetworkingRoom: undefined,
				networkingRoomAttendeeOverviews: []
			};
		}
	},
	effects: (dispatch) => ({
		async startNetworkingRoomAttendeesSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (
					activeSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_networkingroomattendees`) &&
					isFeatureActive(activeSpace, 'networking')
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_networkingroomattendees`, async (data) => {
						const _load = data.load;
						const _items = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'networkingroomattendees received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'networkingroomattendees', value: false });
							if (!store.temp.hasLoadedData?.networkingroomattendees) {
								dispatch.temp.setHasLoadedData('networkingroomattendees');
							}
						}
						dispatch.networking.addNetworkingRoomAttendeeOverviews(_items);
					});
				}
			} catch (error) {
				errorLog('startNetworkingRoomAttendeesSync', error);
			}
		},
		async loadNetworkingRoomAttendees(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && isFeatureActive(activeSpace, 'networking')) {
					if (store.temp.hasLoadedData?.networkingroomattendees) {
						contentLoadLog(3, 'networkingroomattendees already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.networkingroomattendees) {
						contentLoadLog(3, 'networkingroomattendees load already in progress. skipping');
						return;
					}

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading networkingroomattendees');
							dispatch.temp.setWaitingForSocketResponse({ key: 'networkingroomattendees', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'networkingroomattendees',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									userInfos: store.auth.userInfos,
									spaceId: activeSpace.spaceId
								})
							);
							dispatch.temp.setWaitingForSocketResponse({ key: 'networkingroomattendees', value: true });

							const res = await dispatch.request.anonymousRequest({
								method: 'POST',
								url: `${MULTISPACEURL}/networkingrooms/attendees`,
								body
							});

							dispatch.temp.setWaitingForSocketResponse({ key: 'networkingroomattendees', value: false });

							if (res && Array.isArray(res)) {
								dispatch.networking.addNetworkingRoomAttendeeOverviews(res);
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadNetworkingRoomAttendees', error);
			}
		},
		async joinNetworkingRoom(payload: IJoinNetworkingRoomPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { networkingRoomId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							networkingRoomId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms/join`,
						method: 'POST',
						body
					});

					if (res.attendee && res.meeting) {
						dispatch.networking.setActiveNetworkingRoom({
							networkingRoomId,
							attendee: res.attendee,
							meeting: res.meeting
						});
						return true;
					}
					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('joinNetworkingRoom', error);
			}
		},
		async addMyselfToNetworkingRoom(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.networking.activeNetworkingRoom) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							networkingRoomId: store.networking.activeNetworkingRoom.networkingRoomId,
							attendee: {
								AttendeeId: store.networking.activeNetworkingRoom.attendee.Attendee.AttendeeId,
								ExternalUserId: store.networking.activeNetworkingRoom.attendee.Attendee.ExternalUserId,
								userId: store.auth.userInfos.userId
							},
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms/addmyself`,
						method: 'POST',
						body
					});
				}
			} catch (error) {
				console.log('addMyselfToNetworkingRoom', error);
			}
		},
		async leaveNetworkingRoom(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.networking.activeNetworkingRoom) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							networkingRoomId: store.networking.activeNetworkingRoom.networkingRoomId,
							attendee: {
								AttendeeId: store.networking.activeNetworkingRoom.attendee.Attendee.AttendeeId,
								ExternalUserId: store.networking.activeNetworkingRoom.attendee.Attendee.ExternalUserId
							},
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms/leave`,
						method: 'POST',
						body
					});
				}
			} catch (error) {
				console.log('leaveNetworkingRoom', error);
			}
		},
		async sendNetworkingRoomHeartbeat(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.networking.activeNetworkingRoom) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							networkingRoomId: store.networking.activeNetworkingRoom.networkingRoomId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms/heartbeat`,
						method: 'POST',
						body
					});
				}
			} catch (error) {
				console.log('sendNetworkingRoomHeartbeat', error);
			}
		}
	})
});
