import { Avatar } from 'components/User';
import { IAttendee } from 'config/interfaces';

import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useTranslation } from 'react-i18next';

export const QUIZLEADERBOARDLISTENTRYHEIGHT = 80;

export interface ILeaderboardEntry {
	attendee?: IAttendee;
}

interface IQuizLeaderboardListEntry {
	item: ILeaderboardEntry;
	largerDesign?: boolean;
}

export const QuizLeaderboardListEntry = (props: IQuizLeaderboardListEntry) => {
	const { item, largerDesign } = props;
	const { t } = useTranslation();

	return (
		<View
			style={{
				height: largerDesign ? QUIZLEADERBOARDLISTENTRYHEIGHT * 1.5 : QUIZLEADERBOARDLISTENTRYHEIGHT,
				paddingVertical: 5,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<Avatar
				avatar={item?.attendee?.smallImageUrl ?? item.attendee?.imageUrl}
				fullName={item.attendee ? `${item.attendee.firstName} ${item.attendee.lastName}` : undefined}
				size="listItem"
				largerDesign={largerDesign}
			/>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text largerText={largerDesign} bold>
					{item.attendee ? `${item.attendee.firstName} ${item.attendee.lastName}` : t('AnonymousUser')}
				</Text>
			</View>
		</View>
	);
};
