import moment from 'moment';
import { TMedia } from './forms';
import { ISendingInformation } from './mailing';
import { IStrapiBase, IMedia } from './main';

export type TPushNotificationReceiver = 'all' | 'web' | 'app';
export type TPushNotificationDeeplinkType = 'schedules' | 'speakers' | 'expos' | 'mediaitems' | 'votings' | 'newsitems';
export interface IPushNotification extends IStrapiBase {
	title: string;
	subtitle?: string;
	webSubtitle?: string;
	sendOn?: string;
	spaceId: string;
	hasBeenSend?: boolean;
	deeplinkType?: TPushNotificationDeeplinkType;
	deeplinkItemId?: number;
	sendingInformation?: ISendingInformation;
	receiver?: TPushNotificationReceiver;
	hasBeenDismissed?: boolean;
	image?: IMedia;
	isActive?: boolean;
}

export interface ICreatePushNotificationFormValues {
	title?: string;
	subtitle: string;
	webSubtitle: string;
	sendOn?: string | null;
	deeplinkType?: TPushNotificationDeeplinkType;
	deeplinkItemId?: number;
	sendingInformation?: ISendingInformation;
	receiver: TPushNotificationReceiver | null;
	image?: TMedia;
	isActive?: boolean;
}

export interface ICreatePushNotificationFormErrors {
	title?: string;
	subtitle?: string;
	webSubtitle?: string;
	sendOn?: string;
	deeplinkType?: string;
	deeplinkItemId?: string;
	receiver?: string;
	isActive?: string;
}

export const CreatePushNotificationInitialFormValues: ICreatePushNotificationFormValues = {
	subtitle: '',
	webSubtitle: '',
	receiver: 'all'
};

export interface IUpdatePushNotificationFormValues {
	id: number;
	title?: string;
	isDeleted?: boolean;
	subtitle?: string;
	webSubtitle?: string;
	sendOn?: string | null;
	hasBeenSend?: boolean;
	updated_at: string;
	created_at: string;
	deeplinkType?: TPushNotificationDeeplinkType;
	deeplinkItemId?: number;
	sendingInformation?: ISendingInformation;
	receiver: TPushNotificationReceiver | null;
	image?: TMedia;
	isActive?: boolean;
}

export interface IUpdatePushNotificationFormErrors {
	title?: string;
	subtitle?: string;
	webSubtitle?: string;
	sendOn?: string;
	deeplinkType?: string;
	deeplinkItemId?: string;
	receiver?: string;
	isActive?: boolean;
}

export const UpdatePushNotificationInitialFormValues: IUpdatePushNotificationFormValues = {
	id: 0,
	subtitle: '',
	webSubtitle: '',
	receiver: 'all',
	updated_at: moment().toISOString(),
	created_at: moment().toISOString()
};
