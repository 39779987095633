import React from 'react';
import { Vibration, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';

import { FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormErrors, IEditVoteFormValues, IVoteOption } from 'config/interfaces';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, swapArrayItems } from 'helper';
import { FormError } from 'components/Form/FormError';
import { IS_ANDROID } from 'helper/platform';
import { HSDragCard } from 'components/Card';
import { NoData } from 'components/NoData';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { v4 } from 'uuid';

interface IVoteMultipleChoiceOptionsForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteMultipleChoiceOptionsForm = (props: IVoteMultipleChoiceOptionsForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const _addOption = () => {
		let _options = values.options ?? [];
		_options.push({
			description: '', // is used as text here
			order: values.options?.length ?? 0,
			key: v4()
		});
		onChange({ ...values, options: _options });
	};

	const _removeOption = (index) => {
		const _options = values.options;
		_options?.splice(index, 1);
		onChange({ ...values, options: _options });
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		const list: IVoteOption[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);

		onChange({ ...values, options: list });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ ...values, options: list });
	};

	const _renderItem = (params: RenderItemParams<IVoteOption>) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${testIdPrefix}_option_${index}`}
				isActive={isActive}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				onRemove={() => _removeOption(index)}
				isDisabled={isLoading}
			>
				<FormTextInput
					formStyle={{ flex: 1, marginBottom: 0, justifyContent: 'center' }}
					testID={`${testIdPrefix}_textinput_multiplechoicedescription_${index}`}
					isRequired
					placeholder="Option..."
					error={errors[`options[${index}].description`]}
					value={item.description}
					onChangeText={(value) => {
						if (values.options && index !== undefined) {
							let _options = values.options;
							_options[index].description = value;
							onChange({ ...values, options: _options });
						}
					}}
					isDisabled={isLoading}
				/>
			</HSDragCard>
		);
	};

	return (
		<DraggableFlatList
			containerStyle={{ flex: 1 }}
			contentContainerStyle={{ flexGrow: 1, width: screenWidth, alignSelf: 'center', padding: EHorizontalScreenPadding.Wide }}
			data={values.options ?? []}
			renderItem={(params) => _renderItem(params)}
			ListEmptyComponent={() => {
				return <NoData type="NoMultipleChoiceOptions" />;
			}}
			ListFooterComponent={() => (
				<View style={{ width: '100%', alignSelf: 'center' }}>
					<RoundButton
						testID={`${testIdPrefix}_button_add`}
						icon={EDefaultIconSet.Add}
						onPress={() => _addOption()}
						isDisabled={
							(values?.options?.length ?? 0) > 100 ||
							isLoading ||
							(values.options?.find((o) => isEmptyString(o.description)) ? true : false)
						}
						alignSelf="flex-end"
						title={t('addOptionMultipleChoice')}
					/>
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
						<FormError
							testID={`${testIdPrefix}_textinput_multiplechoice_options_error`}
							error={errors['options']?.toString()}
						/>
					</View>
				</View>
			)}
			keyExtractor={(item, index) => `${testIdPrefix}_createMultipleChoiceOption_${index}`}
			onDragBegin={() => {
				if (IS_ANDROID) {
					Vibration.vibrate(10, false);
				}
			}}
			onDragEnd={({ data }) => {
				data.forEach((opt, index) => (opt.order = index));
				onChange({ ...values, options: data });
			}}
		/>
	);
};
