import { ICreatePushNotificationFormValues, IUpdatePushNotificationFormValues } from 'config/interfaces';
import { isEmptyString } from 'helper';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getRequiredError } from './errors';

export const getCreatePushNotificationFormSchema = (item: ICreatePushNotificationFormValues) => {
	return Yup.object().shape({
		receiver: Yup.string()
			.required(getRequiredError(i18next.t('Receivers')))
			.nullable(),
		title: Yup.string().nullable(),
		subtitle: Yup.string()
			.test('required', getRequiredError('Body'), () => {
				if (item.receiver === 'web') {
					return true;
				}

				return !isEmptyString(item.subtitle);
			})
			.nullable(),
		webSubtitle: Yup.string()
			.test('required', getRequiredError('Web Body'), () => {
				if (item.receiver === 'app') {
					return true;
				}

				return !isEmptyString(item.webSubtitle);
			})
			.nullable(),
		sendOn: Yup.string().nullable(),
		deeplink: Yup.string().nullable()
	});
};

export const getUpdatePushNotificationFormSchema = (item: IUpdatePushNotificationFormValues) => {
	return Yup.object().shape({
		receiver: Yup.string()
			.required(getRequiredError(i18next.t('Receivers')))
			.nullable(),
		id: Yup.number().required(),
		title: Yup.string().nullable(),
		subtitle: Yup.string()
			.test('required', getRequiredError('Body'), () => {
				if (item.receiver === 'web') {
					return true;
				}

				return !isEmptyString(item.subtitle);
			})
			.nullable(),
		webSubtitle: Yup.string()
			.test('required', getRequiredError('Web Body'), () => {
				if (item.receiver === 'app') {
					return true;
				}

				return !isEmptyString(item.webSubtitle);
			})
			.nullable(),
		sendOn: Yup.string().nullable(),
		deeplink: Yup.string().nullable()
	});
};
