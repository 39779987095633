import { ChildButton } from 'components/Button';
import { IMailHistory } from 'config/interfaces';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import moment from 'moment';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';

interface IMailHistoryListItem {
	testID: string;
	onPress?: () => void;
	item: IMailHistory;
}

export const MAILHISTORYLISTITEMHEIGHT = 85;

const COUNTFONTSIZE = 12;

export const MailHistoryListItem = (props: IMailHistoryListItem) => {
	const { testID, onPress, item } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const _renderSuccessCount = (count: number) => {
		return <Text style={{ fontSize: COUNTFONTSIZE, color: theme.success }}>{`${count} ${t('Success')}`}</Text>;
	};

	const _renderErrorCount = (count: number) => {
		return <Text style={{ fontSize: COUNTFONTSIZE, color: theme.danger }}>{`${count} ${t('Errors')}`}</Text>;
	};

	const _renderCount = () => {
		const successCount = item.result.success.length;
		const errorCount = item.result.error.length;

		if (successCount > 0 && errorCount > 0) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{_renderSuccessCount(successCount)}
					<Text style={{ fontSize: COUNTFONTSIZE, marginHorizontal: 5 }}>|</Text>
					{_renderErrorCount(errorCount)}
				</View>
			);
		}

		if (successCount > 0) {
			return _renderSuccessCount(successCount);
		}

		if (errorCount > 0) {
			return _renderErrorCount(errorCount);
		}

		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{
				height: MAILHISTORYLISTITEMHEIGHT,
				paddingVertical: 5,
				flexDirection: 'row',
				alignItems: 'center'
			}}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text
					bold={!isEmptyString(item.templateTitle)}
					style={{ marginBottom: 5 }}
					italic={isEmptyString(item.templateTitle)}
					numberOfLines={1}
				>
					{isEmptyString(item.templateTitle) ? t('NoTemplate') : item.templateTitle}
				</Text>
				<Text italic style={{ fontSize: 12, marginBottom: 5 }} numberOfLines={1}>
					{item.subject}
				</Text>
				<Text italic style={{ fontSize: 12, marginBottom: 5 }} numberOfLines={1}>
					{`${moment(item.sentOn).format('HH:mm')} - ${item.receivers.split(',').length} ${t('Receivers')}`}
				</Text>
				{_renderCount()}
			</View>
			{onPress && SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
