import { NetInfoState } from '@react-native-community/netinfo';
import { createModel } from '@rematch/core';
import { IMedia, IMediaItem, ISchedule } from 'config/interfaces';
import { IS_ANDROID, IS_WEB } from 'helper';
import { TContentTypePlural } from 'hooks/useContent';
import { RootModel } from './index';

export type TMediaDetailViewType = 'full' | 'collapsed';
export type TPlaybackStatus = 'playing' | 'paused';

type IDefaultState = {
	mediaDetail: {
		itemId?: number;
		itemType?: 'schedule' | 'mediaitem';
		viewType: TMediaDetailViewType;
		playbackStatus: TPlaybackStatus;
		item?: ISchedule | IMediaItem;
		previewImage?: IMedia;
		isPublic?: boolean;
		activeStreamIndex?: number;
	};
	currentMediaPlaybackTime?: number;
	isTabbarVisible: boolean;
	tempSpaceId?: string;
	netInfoState?: NetInfoState;
	deviceInfo?: {
		brand?: string | null;
		deviceName?: string | null;
		deviceYearClass?: number | null;
		factor: number;
	};
	selfServiceToken?: string;
	lastContentLoad: number;
	votingShowResults: boolean;
	votingIsAnswered: boolean;
	lastAttendeeLoad: number;
	waitingForSocketResponse?: Record<TContentTypePlural, boolean>;
	hasLoadedData?: Record<TContentTypePlural, boolean>;
	isContactModalVisible?: boolean;
};

const emptyContentTypes: Record<TContentTypePlural, boolean> = {
	attendees: false,
	attendeesupportrequests: false,
	bookings: false,
	changelogs: false,
	chats: false,
	comments: false,
	expos: false,
	likes: false,
	newsitems: false,
	mediaitems: false,
	meetings: false,
	mybookings: false,
	schedulestatuses: false,
	mailtemplates: false,
	mailhistories: false,
	sendmail: false,
	maps: false,
	mappositions: false,
	meetingtables: false,
	myvoteanswers: false,
	networkingrooms: false,
	networkingroomattendees: false,
	pushnotifications: false,
	schedules: false,
	speakers: false,
	userinspace: false,
	subscription: false,
	stages: false,
	streams: false,
	tickets: false,
	votings: false,
	webhooks: false,
	allBookingCounts: false
};

export const temp = createModel<RootModel>()({
	state: {
		mediaDetail: {
			itemId: undefined,
			viewType: 'full',
			playbackStatus: 'paused',
			activeStreamIndex: 0
		},
		currentMediaPlaybackTime: 0,
		isTabbarVisible: false,
		netInfoState: undefined,
		deviceInfo: undefined,
		selfServiceToken: undefined,
		lastContentLoad: 0,
		votingShowResults: false,
		votingIsAnswered: false,
		lastAttendeeLoad: 0,
		waitingForSocketResponse: { ...emptyContentTypes },
		hasLoadedData: { ...emptyContentTypes },
		isContactModalVisible: false
	} as IDefaultState,
	reducers: {
		setMediaDetail(store, mediaDetail: IDefaultState['mediaDetail']) {
			return {
				...store,
				mediaDetail: {
					...mediaDetail,
					activeStreamIndex: store.mediaDetail.activeStreamIndex
				}
			};
		},
		setMediaDetailViewType(store, viewType: IDefaultState['mediaDetail']['viewType']) {
			return {
				...store,
				mediaDetail: {
					...store.mediaDetail,
					viewType
				}
			};
		},
		setMediaDetailPlaybackStatus(store, playbackStatus: IDefaultState['mediaDetail']['playbackStatus']) {
			return {
				...store,
				mediaDetail: {
					...store.mediaDetail,
					playbackStatus
				}
			};
		},
		setMediaDetailActiveStreamIndex(store, activeStreamIndex: IDefaultState['mediaDetail']['activeStreamIndex']) {
			return {
				...store,
				mediaDetail: {
					...store.mediaDetail,
					activeStreamIndex
				}
			};
		},
		setCurrentMediaPlaybackTime(store, currentMediaPlaybackTime: IDefaultState['currentMediaPlaybackTime']) {
			return {
				...store,
				currentMediaPlaybackTime
			};
		},
		setIsTabbarVisible(store, isTabbarVisible: IDefaultState['isTabbarVisible']) {
			return {
				...store,
				isTabbarVisible
			};
		},
		setTempSpaceId(store, tempSpaceId: string | undefined) {
			return {
				...store,
				tempSpaceId
			};
		},
		updateNetInfoState(store, netInfoState) {
			return {
				...store,
				netInfoState
			};
		},
		setDeviceInfo(store, deviceInfo: IDefaultState['deviceInfo']) {
			return {
				...store,
				deviceInfo
			};
		},
		setSelfServiceToken(store, selfServiceToken: IDefaultState['selfServiceToken']) {
			return {
				...store,
				selfServiceToken
			};
		},
		setLastContentLoad(state, lastContentLoad) {
			return {
				...state,
				lastContentLoad
			};
		},
		setLastAttendeeLoad(state, lastAttendeeLoad) {
			return {
				...state,
				lastAttendeeLoad
			};
		},
		setVotingShowResults(state, votingShowResults) {
			return {
				...state,
				votingShowResults
			};
		},
		setVotingIsAnswered(state, votingIsAnswered) {
			return {
				...state,
				votingIsAnswered
			};
		},
		setWaitingForSocketResponse(state, { key, value }: { key: TContentTypePlural; value: boolean }) {
			const _updated: IDefaultState['waitingForSocketResponse'] = state.waitingForSocketResponse
				? { ...state.waitingForSocketResponse }
				: { ...emptyContentTypes };
			_updated[key] = value;

			return {
				...state,
				waitingForSocketResponse: _updated
			};
		},
		setHasLoadedData(state, key: TContentTypePlural) {
			const _updated: Record<TContentTypePlural, boolean> = state.hasLoadedData
				? { ...state.hasLoadedData }
				: { ...emptyContentTypes };
			_updated[key] = true;

			return {
				...state,
				hasLoadedData: _updated
			};
		},
		clearHasLoadedDataAndWaitingForSocketResponse(state) {
			return {
				...state,
				hasLoadedData: { ...emptyContentTypes },
				waitingForSocketResponse: { ...emptyContentTypes }
			};
		},
		setIsContactModalVisible(state, isContactModalVisible) {
			return {
				...state,
				isContactModalVisible
			};
		},
		clear(state) {
			return {
				...state,
				mediaDetail: {
					itemId: undefined,
					viewType: 'full',
					playbackStatus: 'paused',
					activeStreamIndex: 0
				},
				currentMediaPlaybackTime: 0,
				isTabbarVisible: false,
				selfServiceToken: undefined,
				lastContentLoad: 0,
				votingShowResults: false,
				votingIsAnswered: false,
				lastAttendeeLoad: 0,
				waitingForSocketResponse: { ...emptyContentTypes },
				hasLoadedData: { ...emptyContentTypes },
				isContactModalVisible: false
			};
		}
	},
	effects: (dispatch) => ({
		setActiveSpaceId(payload, store) {
			const { spaceId } = payload;

			if (IS_WEB) {
				sessionStorage.setItem('activeSpaceId', spaceId ?? '');
				const event = new Event('itemInserted');
				event.value = spaceId;
				event.key = 'activeSpaceId';
				document.dispatchEvent(event);
			} else {
				dispatch.space.setActiveSpaceId(spaceId);
			}
		},
		getActiveSpaceId(payload, store) {
			return IS_WEB ? sessionStorage.getItem('activeSpaceId') : store.space.activeSpaceId;
		},
		getActiveSpace(payload, store) {
			const activeSpaceId = IS_WEB ? sessionStorage.getItem('activeSpaceId') : store.space.activeSpaceId;

			if (activeSpaceId) {
				return store.space.spaceHistory.find((e) => e.space.spaceId === activeSpaceId)?.space;
			}

			return undefined;
		},
		async setNetInfoState(payload, store) {
			dispatch.temp.updateNetInfoState(payload.netInfoState);

			if (IS_ANDROID && payload.netInfoState?.isConnected !== store.temp.netInfoState?.isConnected) {
				if (payload.netInfoState.isConnected) {
					await dispatch.socket.initMultiSpaceSocket({});

					dispatch.temp.startAllSyncs({});
				} else {
					dispatch.socket.closeMultiSpaceSocket({});
				}
			}
		},
		async startAllSyncs(payload, store) {
			try {
				dispatch.attendee.startAttendeeSync({});
				dispatch.chat.startChatSync({});
				dispatch.content.startContentSync({});
				dispatch.meeting.startMyMeetingsSync({});
				dispatch.vote.startVotesSync({});
				dispatch.vote.startMyVoteAnswersSync({});
				dispatch.booking.startMyBookingsSync({});
				dispatch.comment.startCommentSync({});
				dispatch.networking.startNetworkingRoomAttendeesSync({});
				dispatch.like.startLikeSync({});
				dispatch.pushNotification.startPushNotificationsSync({});
				dispatch.attendee.startAttendeeSupportRequestsSync({});
				dispatch.content.startChangelogSync({});
				dispatch.content.startWebhooksSync({});
			} catch (error) {
				errorLog('startAllSyncs', error);
			}
		}
	})
});
