import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { FormTextInput } from 'components/Form/FormTextInput';

interface IStreamInputZoom {
	testID: string;
	onChange: (field: string, value: string | null) => void;
	value: { zoomMeetingUrl: string; zoomMeetingId: string; zoomMeetingPassword: string };
	error: { zoomMeetingUrl?: string; zoomMeetingId?: string; zoomMeetingPassword?: string };
}

export const StreamInputZoom = (props: IStreamInputZoom) => {
	const { testID, onChange, value, error } = props;
	const { t } = useTranslation();

	return (
		<View>
			<FormTextInput
				isRequired
				testID={`${testID}_textinput_zoommeetingurl`}
				onChangeText={(val) => onChange('zoomMeetingUrl', val)}
				label={t('zoomMeetingUrl')}
				hint={t('zoomMeetingUrlHint')}
				value={value.zoomMeetingUrl}
				error={error.zoomMeetingUrl}
			/>
			<FormTextInput
				isRequired
				testID={`${testID}_textinput_zoommeetingid`}
				onChangeText={(val) => onChange('zoomMeetingId', val)}
				label={t('zoomMeetingId')}
				hint={t('zoomMeetingIdHint')}
				value={value.zoomMeetingId}
				error={error.zoomMeetingId}
			/>
			<FormTextInput
				isRequired
				testID={`${testID}_textinput_zoommeetingpassword`}
				onChangeText={(val) => onChange('zoomMeetingPassword', val)}
				label={t('zoomMeetingPassword')}
				hint={t('zoomMeetingPasswordHint')}
				value={value.zoomMeetingPassword}
				error={error.zoomMeetingPassword}
			/>
		</View>
	);
};
