import { IAdministrateData, ILauncherSpace, IUserProfile } from 'config/interfaces';
import { isEmptyString } from './string';
import { t } from 'i18next';
import { EDefaultIconSet } from './icon';
import { HELLOSPACES_USERS } from 'config/constants';

export const canJoinSpace = (space: ILauncherSpace, profile?: IUserProfile, pin?: string) => {
	const isAdmin = space.admins?.find((a) => a.id === profile?.id);
	const isModerator = space.admins?.find((a) => a.id === profile?.id);
	const isHelloSpacesUser = HELLOSPACES_USERS.includes(profile?.email ?? 'undefined');

	let isDisabledBySuperAdmin = space.disabledBySuperAdmin;
	let isDeactivated = space.isDeactivated;

	let needsRegistration = space.registrationRequired && !profile;
	let needsConfirmation = space.forceDoubleOptInForUsers && !profile?.isUserConfirmed;
	let needsToFilloutProfileFields = false;

	let needsTicket = space.ticketSettings?.ticketRequired;

	let needsPin = !isEmptyString(space.spacePin);

	if (needsPin && !isEmptyString(pin)) {
		needsPin = space.spacePin !== pin;
	}

	if (profile) {
		const requiredRegistrationFields = space.registrationFields?.fields?.filter((e) => e.isRequired && e.fieldType !== 'password');
		const categoryFields = space.registrationFields?.fields?.filter((e) => e.fieldType === 'category');

		requiredRegistrationFields?.forEach((field) => {
			switch (field.fieldType) {
				case 'avatar':
					if (!profile[field.fieldName] || !profile[field.fieldName].url) {
						needsToFilloutProfileFields = true;
					}
					break;
				case 'boolean':
					if (!profile.hasOwnProperty(field.fieldName)) {
						needsToFilloutProfileFields = true;
					}
					break;
				default:
					if (isEmptyString(profile[field.fieldName])) {
						needsToFilloutProfileFields = true;
					}
					break;
			}
		});

		categoryFields?.forEach((field) => {
			if (field.isRequired) {
				if (!profile[field.fieldName]) {
					needsToFilloutProfileFields = true;
				}
			}
			let selected: string[] = [];
			if (profile[field.fieldName]) {
				const splitted = profile[field.fieldName]?.split(',')?.filter((e) => !isEmptyString(e));
				field.options?.forEach((option) => {
					if (splitted.includes(option.key)) {
						selected.push(option.key);
					}
				});
			}

			if (field.minCount && field.minCount > selected.length) {
				needsToFilloutProfileFields = true;
			}

			if (field.maxCount && field.maxCount < selected.length) {
				needsToFilloutProfileFields = true;
			}
		});

		if (isAdmin || (profile.tickets && profile.tickets[space.spaceId])) {
			needsTicket = false;
		}
	}

	let needsCustomDataProtection = false;

	return {
		isAdmin,
		isModerator,
		isDisabledBySuperAdmin,
		isDeactivated,
		needsRegistration,
		needsConfirmation,
		needsToFilloutProfileFields,
		needsTicket,
		needsCustomDataProtection,
		needsPin,
		isHelloSpacesUser
	};
};

export const getAdministrateData = () => {
	const data: IAdministrateData[] = [
		{
			key: 'settings',
			cardTitle: t('Settings'),
			column: 1,
			items: [
				{
					key: 'editspace',
					title: t('Edit Space'),
					icon: EDefaultIconSet.Edit,
					subtitle: t('editspaceSubtitle'),
					keywords: [
						'information',
						t('registration'),
						t('feed'),
						'feed',
						t('Attendee'),
						'agenda',
						t('CustomAttendeeConsentText'),
						t('Language'),
						'space onboarding',
						'spaceonboarding',
						'tracking'
					]
				},
				{
					key: 'changelog',
					title: 'Changelog',
					subtitle: t('changelogSubtitle'),
					icon: EDefaultIconSet.Changelog,
					keywords: [t('Changes'), 'Logs']
				},
				{
					key: 'editspacedesign',
					title: t('Design'),
					icon: EDefaultIconSet.EditDesign,
					subtitle: t('editspacedesignSubtitle'),
					keywords: [t('Colors'), t('Media'), t('Layout')]
				},
				{
					key: 'administrators',
					title: t('Administrators'),
					icon: EDefaultIconSet.Attendees,
					subtitle: t('administratorsSubtitle'),
					keywords: []
				},
				{
					key: 'moderators',
					title: t('Moderators'),
					icon: EDefaultIconSet.Attendees,
					subtitle: t('moderatorsSubtitle'),
					keywords: []
				},
				{
					key: 'editspacesponsors',
					title: t('Sponsors'),
					icon: EDefaultIconSet.EditSponsor,
					subtitle: t('editspacesponsorsSubtitle'),
					keywords: []
				},
				{
					key: 'features',
					title: t('Features'),
					icon: EDefaultIconSet.Features,
					subtitle: t('featuresSubtitle'),
					keywords: []
				},
				{
					key: 'editspacetexts',
					title: t('Edit Space Texts'),
					icon: EDefaultIconSet.TextField,
					subtitle: t('editspacetextsSubtitle'),
					keywords: []
				},
				{
					key: 'selfServiceSettings',
					title: t('Self Service Settings'),
					icon: EDefaultIconSet.SelfServiceSettings,
					subtitle: t('selfServiceSettingsSubtitle'),
					keywords: [t('Expo Settings'), t('Speaker Settings')]
				},
				{
					key: 'editlandingpage',
					title: t('Edit Landingpage'),
					icon: EDefaultIconSet.Home,
					subtitle: t('editLandingpageSubtitle'),
					keywords: ['home']
				},
				{
					key: 'token',
					title: t('API Tokens'),
					icon: EDefaultIconSet.ApiToken,
					subtitle: t('apiTokenSubtitle'),
					keywords: ['api', 'token', 'access']
				},
				{
					key: 'webhooks',
					title: 'Webhooks',
					icon: EDefaultIconSet.Webhook,
					subtitle: t('WebhooksSubtitle'),
					keywords: ['Webhook']
				}
			]
		},
		{
			key: 'communication',
			cardTitle: t('Communication'),
			column: 1,
			items: [
				{
					key: 'sendMail',
					title: t('Send Mail'),
					icon: EDefaultIconSet.Mail,
					subtitle: t('sendMailSubtitle'),
					keywords: ['mail', 'email', 'e-mail', 'send', 'attendee']
				},
				{
					key: 'push',
					title: t('Push Notifications'),
					icon: EDefaultIconSet.Notification,
					subtitle: t('pushNotificationsSubtitle'),
					keywords: ['push', 'send', 'attendee']
				},
				{
					key: 'reports',
					title: t('Reports'),
					icon: EDefaultIconSet.Analytics,
					subtitle: t('reportsSubtitle'),
					keywords: ['reports', 'data']
				},
				{
					key: 'statistics',
					title: t('Statistics'),
					icon: EDefaultIconSet.Statistics,
					subtitle: t('statisticsSubtitle'),
					keywords: ['chart', 'data']
				}
			]
		},
		{
			key: 'attendees',
			cardTitle: t('Attendees'),
			column: 2,
			items: [
				{
					key: 'attendees',
					title: t('Attendees'),
					icon: EDefaultIconSet.Attendees,
					subtitle: t('attendeesSubtitle'),
					keywords: ['attendee', 'attendees']
				},
				{
					key: 'resetAttendePw',
					title: t('Reset attendee password'),
					icon: EDefaultIconSet.ResetPassword,
					subtitle: t('resetAttendePwSubtitle'),
					keywords: ['password', 'reset', 'send', 'attendee', 'mail', 'email', 'e-mail']
				},
				{
					key: 'invite',
					title: t('Invite Attendees'),
					icon: EDefaultIconSet.Share,
					subtitle: t('inviteSubtitle'),
					keywords: ['attendee', 'attendees', 'invite', 'share']
				},
				{
					key: 'qrcode',
					title: t('QR Code'),
					icon: EDefaultIconSet.QRCode,
					subtitle: t('qrcodeSubtitle'),
					keywords: ['qrcode', 'qr', 'code']
				},
				{
					key: 'supportRequests',
					title: t('administrateSupportRequests'),
					icon: EDefaultIconSet.Support,
					subtitle: t('supportRequestsSubtitle'),
					keywords: ['attendee', 'attendees', 'support', 'request']
				},
				{
					key: 'welcometext',
					title: t('welcomeText'),
					icon: EDefaultIconSet.HelloHand,
					subtitle: t('welcometextSubtitle'),
					keywords: ['attendee', 'attendees', 'welcome', 'text', 'start', 'join', 'space']
				},
				{
					key: 'matchings',
					title: t('Matchings'),
					icon: EDefaultIconSet.Category,
					subtitle: t('matchingsSubtitle'),
					keywords: ['attendee', 'attendees', 'matchings']
				},
				{
					key: 'allBookings',
					title: t('All Bookings'),
					icon: EDefaultIconSet.Bookings,
					subtitle: t('allBookingsSubtitle'),
					keywords: ['bookings']
				}
			]
		},
		{
			key: 'tickets',
			cardTitle: t('Tickets'),
			column: 2,
			items: [
				{
					key: 'ticketSettings',
					title: t('Ticket Settings'),
					icon: EDefaultIconSet.Settings,
					subtitle: t('ticketSettingsSubtitle'),
					keywords: ['ticket', 'settings']
				},
				{
					key: 'tickets',
					title: t('HelloSpaces Tickets'),
					icon: EDefaultIconSet.Ticket,
					subtitle: t('ticketsSubtitle'),
					keywords: ['tickets']
				}
			]
		},
		{
			key: 'meetings',
			cardTitle: t('Meetings'),
			column: 2,
			items: [
				{
					key: 'meetingSettings',
					title: t('Meeting Settings'),
					icon: EDefaultIconSet.Meetings,
					subtitle: t('meetingSettingsSubtitle'),
					keywords: ['meeting', 'settings']
				},
				{
					key: 'meetingTables',
					title: t('Meeting Tables'),
					icon: EDefaultIconSet.Meetings,
					subtitle: t('meetingTablesSubtitle'),
					keywords: ['meeting', 'tables']
				}
			]
		},
		{
			key: 'fields',
			cardTitle: t('Fields'),
			column: 3,
			items: [
				{
					key: 'spaceRegistration',
					title: t('Profile Fields'),
					icon: EDefaultIconSet.Register,
					subtitle: t('profileFieldsSubtitle'),
					keywords: [t('Category')]
				},
				{
					key: 'scheduleFields',
					title: t('Agenda Fields'),
					icon: EDefaultIconSet.Schedule,
					subtitle: t('scheduleFieldsSubtitle'),
					keywords: [t('Category')]
				},
				{
					key: 'speakerFields',
					title: t('Speaker Fields'),
					icon: EDefaultIconSet.Speakers,
					subtitle: t('speakerFieldsSubtitle'),
					keywords: [t('Category')]
				},
				{
					key: 'expoFields',
					title: t('Expo Fields'),
					icon: EDefaultIconSet.Exhibition,
					subtitle: t('expoFieldsSubtitle'),
					keywords: [t('Category')]
				},
				{
					key: 'mediaItemFields',
					title: t('Media Items Fields'),
					icon: EDefaultIconSet.MediaLibrary,
					subtitle: t('mediaItemFieldsSubtitle'),
					keywords: [t('Category'), t('media')]
				}
			]
		},
		{
			key: 'mailing',
			cardTitle: t('Mailing'),
			column: 3,
			items: [
				{
					key: 'mailhistory',
					title: t('Mail History'),
					icon: EDefaultIconSet.MailHistory,
					subtitle: t('mailhistorySubtitle'),
					keywords: ['mail', 'email', 'e-mail']
				},
				{
					key: 'mailtemplates',
					title: t('Mail Templates'),
					icon: EDefaultIconSet.MailTemplates,
					subtitle: t('mailtemplatesSubtitle'),
					keywords: ['header', 'footer', 'mail', 'email', 'e-mail']
				},
				{
					key: 'mailsettings',
					title: t('Mail Settings'),
					icon: EDefaultIconSet.MailSettings,
					subtitle: t('mailsettingsSubtitle'),
					keywords: ['mail', 'email', 'e-mail']
				}
			]
		}
	];

	return data;
};

export const getAccessRightsForSpace = (userId: string, space?: ILauncherSpace) => {
	const accessRights = {
		isAdmin: false,
		isModerator: false
	};

	if (space) {
		accessRights.isAdmin = space.admins?.find((e) => e.userId === userId) !== undefined;
		accessRights.isModerator = space.moderators?.find((e) => e.userId === userId) !== undefined;
	}

	return accessRights;
};

export const isFeatureActive = (activeSpace?: ILauncherSpace, feature?: string) => {
	if (!activeSpace) {
		return false;
	}

	switch (feature) {
		case 'mailtemplates':
		case 'mailhistories':
			return true;
		case 'streams':
		case 'stages':
		case 'schedulestatuses':
			return activeSpace.features?.list.find((e) => e.key === 'schedules' && e.isActive);
		case 'newsitems':
			return activeSpace.features?.list.find((e) => e.key === 'feed' && e.isActive);
		case 'networkingrooms':
			return activeSpace.features?.list.find((e) => e.key === 'networking' && e.isActive);
		case 'meetingtables':
			return activeSpace.features?.list.find((e) => e.key === 'meetings' && e.isActive);
		case 'mappositions':
			return activeSpace.features?.list.find((e) => e.key === 'maps' && e.isActive);
		default:
			return activeSpace.features?.list.find((e) => e.key === feature && e.isActive);
	}
};
