import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useSpace } from './useSpace';
import moment from 'moment';

export const useUnreadCount = (type: string, itemId?: number) => {
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const [counter, setCounter] = useState<number>(0);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const chatMessages = useSelector((store: IRootState) => store.chat.chatMessages);
	const supportRequests = useSelector((store: IRootState) => store.attendee.supportRequests);
	const comments = useSelector((store: IRootState) => store.comment.comments);

	useEffect(() => {
		if (activeSpace && (type === 'feed' || type === 'schedule')) {
			let _count: typeof counter = 0;

			switch (type) {
				case 'feed':
					if (content.newsitems && profile) {
						let newsForThisSpace = content?.newsitems?.filter((e) => e.spaceId === activeSpace?.spaceId);
						if (
							newsForThisSpace &&
							profile?.lastRead &&
							profile?.lastRead[activeSpace?.spaceId] &&
							profile?.lastRead[activeSpace?.spaceId]?.newsitems
						) {
							newsForThisSpace = newsForThisSpace?.filter(
								(e) =>
									(iAmSpaceAdmin ||
										iAmSpaceModerator ||
										(e.isVisible && (e.showFrom ? moment(e.showFrom).isSameOrBefore(moment()) : true))) &&
									moment(e.updated_at).isAfter(moment(profile.lastRead![activeSpace.spaceId]!.newsitems!))
							);
						}
						_count = newsForThisSpace.length;
					}
					break;
				case 'schedule':
					if (content?.schedules && content?.schedulestatuses) {
						_count = content?.schedules?.filter((schedule) => {
							if (schedule.spaceId !== activeSpace.spaceId) {
								return false;
							}
							const isLive = content.schedulestatuses.find(
								(scSt) => scSt.schedule && scSt.schedule.id === schedule.id && scSt.status === 'Live'
							);
							return isLive;
						}).length;
					}
					break;
				default:
					break;
			}

			setCounter(_count);
		}
	}, [content, activeSpace, iAmSpaceAdmin, iAmSpaceModerator]);

	useEffect(() => {
		if (activeSpace && type === 'feedandcomment') {
			let _count: typeof counter = 0;

			if (content.newsitems && profile) {
				_count = content.newsitems.filter((e) => e.spaceId === activeSpace?.spaceId && !e.isVisible).length;
			}

			if (comments && activeSpace) {
				_count += comments.filter(
					(e) =>
						(iAmSpaceAdmin || iAmSpaceModerator) &&
						e.status === 'Waiting' &&
						e.spaceId === activeSpace.spaceId &&
						(!itemId || e.itemId === itemId?.toString()) &&
						!e.isDeleted
				).length;
			}

			setCounter(_count);
		}
	}, [content, comments, activeSpace, profile, iAmSpaceAdmin, iAmSpaceModerator]);

	useEffect(() => {
		if (type === 'chat') {
			let _count: typeof counter = 0;

			if (activeSpace && chatMessages) {
				_count = chatMessages.filter(
					(e) => e.spaceId === activeSpace.spaceId && e.senderId !== profile?.userId && !e.hasBeenRead
				).length;
			}

			setCounter(_count);
		}
	}, [chatMessages, activeSpace, profile]);

	useEffect(() => {
		if (type === 'vote') {
			let _count: typeof counter = 0;

			if (activeSpace && votes && voteAnswers && profile) {
				_count = votes.filter((e) => {
					if (e.spaceId !== activeSpace.spaceId || !e.isActive || e.isDeleted) {
						return false;
					}

					if (e.openFrom && moment().isBefore(moment(e.openFrom))) {
						return false;
					}

					if (e.openUntil && moment().isAfter(moment(e.openUntil))) {
						return false;
					}

					const iHaveAnsweredThisVoting = voteAnswers.find(
						(voteAnswer) =>
							voteAnswer.spaceId === activeSpace.spaceId &&
							voteAnswer.userId === profile.userId &&
							voteAnswer.voteId === e.id &&
							!voteAnswer.isDeleted
					);

					return !iHaveAnsweredThisVoting;
				}).length;
			}

			setCounter(_count);
		}
	}, [votes, voteAnswers, profile, activeSpace]);

	useEffect(() => {
		if (type === 'meeting') {
			let _count: typeof counter = 0;

			if (activeSpace && meetings && profile) {
				_count = meetings.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						e.status === 'Pending' &&
						e.lastEditBy !== profile.userId &&
						moment(e.timeSlot.end).isAfter(moment())
				).length;
			}

			setCounter(_count);
		}
	}, [meetings, activeSpace, profile]);

	useEffect(() => {
		if (type === 'supportrequest') {
			let _count: typeof counter = 0;

			if (supportRequests && activeSpace) {
				_count = supportRequests.filter((sr) => sr.spaceId === activeSpace.spaceId && sr.status === 'open').length;
			}

			setCounter(_count);
		}
	}, [supportRequests, activeSpace]);

	useEffect(() => {
		if (type === 'comment') {
			let _count: typeof counter = 0;

			if (comments && activeSpace) {
				_count = comments.filter(
					(e) =>
						(iAmSpaceAdmin || iAmSpaceModerator) &&
						e.status === 'Waiting' &&
						e.spaceId === activeSpace.spaceId &&
						(!itemId || e.itemId === itemId?.toString()) &&
						!e.isDeleted
				).length;
			}

			setCounter(_count);
		}
	}, [comments, activeSpace, profile, iAmSpaceAdmin, iAmSpaceModerator]);

	return {
		counter
	};
};
