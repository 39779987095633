import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { IEditMapFormErrors, IEditMapFormValues } from 'config/interfaces';
import { getMapValidationSchema } from 'config/yupSchemas';
import { MapForm } from 'components/Forms';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MapCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MapCreate>;
type RouteParams = StackParamList[ERoutes.MapCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mapcreate';

export const MapCreateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditMapFormValues>({});
	const [formErrors, setFormErrors] = useState<IEditMapFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const createMap = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMap);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createMap(),
			isLoading: isLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [formValues, isLoading, formErrors]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [formValues]);

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _createMap = async () => {
		const errors = await validateForm(getMapValidationSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		const orders = content?.maps?.filter((map) => map.spaceId === activeSpace?.spaceId).map((m) => (m.order ? m.order : 0));
		const maxOrder = orders.length === 0 ? -1 : Math.max(...orders);

		if (!formValues.order) formValues.order = maxOrder + 1;

		setIsLoading(true);
		if (!formValues.image) formValues.image = require('../../../assets/images/mapExample/mapExample.png');

		const res = await createMap({ map: formValues });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="maps">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MapForm values={formValues} errors={formErrors} isLoading={isLoading} onChange={updateFormValues} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MapCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const { t } = useTranslation();
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={t('Maps')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
