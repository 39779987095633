import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { MapForm } from 'components/Forms';
import { IEditMapFormErrors, IEditMapFormValues, IMap } from 'config/interfaces';
import i18next from 'i18next';
import { getMapValidationSchema } from 'config/yupSchemas';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MapEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MapEdit>;
type RouteParams = StackParamList[ERoutes.MapEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mapedit';

export const MapEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditMapFormValues>({});
	const [formErrors, setFormErrors] = useState<IEditMapFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateMap = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMap);

	useEffect(() => {
		if (content.maps && route.params.id) {
			const values = content.maps.find((e) => e.id === route.params.id);
			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMap(),
			onRightDeletePress: () => _deleteMap(),
			isLoading: isLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [formValues, isLoading, formErrors, isFormLocked]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [formValues]);

	const _applyFormValues = (values: IMap) => {
		setFormValues({
			...values,
			order: values.order,
			mappositions: values.mappositions ? values.mappositions.map((pos) => pos.id) : [],
			showBacklinks: values.showBacklinks !== undefined ? values.showBacklinks : true
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _deleteMap = async () => {
		showAlert({
			title: t('Delete map'),
			message: t('deleteMapSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete map'),
					style: 'destructive',
					onPress: async () => {
						setIsLoading(true);

						const res = await updateMap({ map: { isDeleted: true, id: formValues.id } });
						if (res) {
							setIsLoading(false);
							navigation.goBack();
						} else {
							setIsLoading(false);
						}
					}
				}
			]
		});
	};

	const _updateMap = async () => {
		const errors = await validateForm(getMapValidationSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = content.maps.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		const orders = content?.maps?.filter((map) => map.spaceId === activeSpace?.spaceId).map((m) => (m.order ? m.order : 0));
		const maxOrder = orders.length === 0 ? -1 : Math.max(...orders);

		if (!formValues.order && formValues.order !== 0) formValues.order = maxOrder + 1;

		setIsLoading(true);
		const res = await updateMap({ map: formValues });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="maps">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MapForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MapEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Maps')} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderIconButton
					testID="header_button_delete"
					icon={EDefaultIconSet.Delete}
					onPress={props.options.onRightDeletePress}
					isLoading={props.options?.isLoading}
					textLeft
				/>
				<NavigationHeaderIconButton
					testID="header_button_save"
					icon={EDefaultIconSet.Save}
					onPress={props.options.onRightNavPress}
					isLoading={props.options?.isLoading}
					isDisabled={props.options?.isDisabled}
				/>
			</View>
		</NavigationHeader>
	);
};
