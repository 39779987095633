import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { HSCard } from 'components/Card/HSCard';
import { FormCheckbox, FormFileSelection, FormTextInput } from 'components/Form';
import { IEditMailTemplateFormErrors, IEditMailTemplateFormValues, IRegistrationField, TMailTemplateType } from 'config/interfaces';
import { useRegistration } from 'hooks/useRegistration';
import { useContent } from 'hooks/useContent';
import { useSpace } from 'hooks/useSpace';
import { Button } from 'components/Button';
import {
	_getAccountConfirmationEmail,
	_getDefaultHelloSpacesInvitation,
	_getExpoSelfServiceInvitation,
	_getHelloSpacesTicketInvitation,
	_getInviteUnregisteredAdmin,
	_getNewAdministratorEmail,
	_getNewModeratorEmail,
	_getPasswordResetEmail,
	_getSpeakerSelfServiceInvitation
} from 'config/mailing';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IMailTemplateForm {
	values: IEditMailTemplateFormValues;
	errors: IEditMailTemplateFormErrors;
	onChange: (val: IEditMailTemplateFormValues) => void;
	isLoading: boolean;
	hideFileSelect?: boolean;
	hideTitle?: boolean;
	contentType: TMailTemplateType;
}

const TESTIDPREFIX = 'editmailtemplate';

export const MailTemplateForm = (props: IMailTemplateForm) => {
	const { values, errors, onChange, isLoading, hideFileSelect, hideTitle, contentType } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const { getRegistrationFields } = useRegistration();
	const { getContentTypeFields: getExpoTypeFields } = useContent('expo');
	const { getContentTypeFields: getSpeakerTypeFields } = useContent('speaker');

	const [profileFields, setProfileFields] = useState<IRegistrationField[]>([]);

	useEffect(() => {
		const fields = getRegistrationFields().filter((e) => e.fieldType !== 'password' && e.visibility !== 'never');

		setProfileFields(fields);
	}, [activeSpace]);

	const _getPlaceholderOptions = () => {
		const spacePlaceholders = {
			prefix: 'space',
			fields: [
				{
					key: 'ONBOARDINGLINK',
					label: 'Onboarding Link'
				},
				{
					key: 'LANDINGPAGELINK',
					label: 'Landingpage Link'
				}
			].sort((a, b) => (a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1)),
			placeholder: t('DropdownPlaceholderSpace')
		};

		switch (contentType) {
			case 'attendee':
				return [
					{
						prefix: 'user',
						fields: profileFields.map((e) => {
							return {
								key: e.fieldName,
								label: e.fieldLabel
							};
						}),
						placeholder: t('DropdownPlaceholderProfile')
					},
					spacePlaceholders
				];
			case 'expo':
				return [
					{
						prefix: 'item',
						fields: [
							...getExpoTypeFields().map((e) => {
								return {
									key: e.fieldName,
									label: e.fieldLabel
								};
							}),
							{
								key: 'SELFSERVICELINK',
								label: 'Self Service Link'
							}
						].sort((a, b) => (a.label?.toLowerCase() < b.label?.toLowerCase() ? -1 : 1)),
						placeholder: t('SelectPlaceholder')
					},
					spacePlaceholders
				];
			case 'speaker':
				return [
					{
						prefix: 'item',
						fields: [
							...getSpeakerTypeFields().map((e) => {
								return {
									key: e.fieldName,
									label: e.fieldLabel
								};
							}),
							{
								key: 'SELFSERVICELINK',
								label: 'Self Service Link'
							}
						].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))
					},
					spacePlaceholders
				];
			default:
				return [spacePlaceholders];
		}
	};

	return (
		<View>
			<HSCard>
				{!hideTitle && (
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_title`}
						label={t('Title')}
						isRequired
						hint={t('MailTemplateTitleHint')}
						value={values.title}
						error={errors.title}
						onChangeText={(title) => onChange({ ...values, title })}
						isDisabled={isLoading}
					/>
				)}
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_subject`}
					label={t('Subject')}
					isRequired
					value={values.subject}
					error={errors.subject}
					onChangeText={(subject) => onChange({ ...values, subject })}
					isDisabled={isLoading}
				/>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_body`}
					label={t('EmailBody')}
					hint={contentType && contentType !== 'invitation' ? t('EmailBodyHint') : t('EmailBodyHintNoPlaceholder')}
					isRequired
					value={values.body}
					error={errors.body}
					multiline
					onChangeText={(body) => onChange({ ...values, body })}
					isDisabled={isLoading}
					insertionOptions={_getPlaceholderOptions()}
				/>
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_usedefaultheader`}
					label={t('useDefaultHeader')}
					hint={t('useDefaultHeaderHint')}
					value={values.useDefaultHeader}
					error={errors.useDefaultHeader}
					isDisabled={isLoading}
					onPress={() => onChange({ ...values, useDefaultHeader: !values.useDefaultHeader })}
				/>
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_usedefaultfooter`}
					label={t('useDefaultFooter')}
					hint={t('useDefaultFooterHint')}
					value={values.useDefaultFooter}
					error={errors.useDefaultFooter}
					isDisabled={isLoading}
					onPress={() => onChange({ ...values, useDefaultFooter: !values.useDefaultFooter })}
				/>
			</HSCard>
			{!hideFileSelect && (
				<HSCard>
					<FormFileSelection
						label={t('Attachments')}
						media={values.attachments?.map((m) => m.media)}
						onChange={(value) =>
							onChange({
								...values,
								attachments: value.map((v, index) => {
									return { media: v, order: index };
								})
							})
						}
						testID={`${TESTIDPREFIX}_media_gallery`}
						isDisabled={isLoading}
					/>
				</HSCard>
			)}
			{profile?.email?.includes('@hellospaces.de') && profile?.isUserConfirmed && !hideTitle && (
				<HSCard>
					<Button
						testID={`${TESTIDPREFIX}_button_getdefaulttemplate`}
						title={`${t('Template')} - ${t('Reset')}`}
						style={{ alignSelf: 'center' }}
						onPress={() => {
							let _title = '';
							let _subject = '';
							let _body = '';
							let _contentType: TMailTemplateType = 'noTemplate';
							switch (values.key) {
								case 'expoSelfServiceInvitation':
									_title = _getExpoSelfServiceInvitation().title;
									_subject = _getExpoSelfServiceInvitation().subject;
									_body = _getExpoSelfServiceInvitation().body;
									_contentType = _getExpoSelfServiceInvitation().contentType;
									break;
								case 'speakerSelfServiceInvitation':
									_title = _getSpeakerSelfServiceInvitation().title;
									_subject = _getSpeakerSelfServiceInvitation().subject;
									_body = _getSpeakerSelfServiceInvitation().body;
									_contentType = _getSpeakerSelfServiceInvitation().contentType;
									break;
								case 'newAdmin':
									_title = _getNewAdministratorEmail().title;
									_subject = _getNewAdministratorEmail().subject;
									_body = _getNewAdministratorEmail().body;
									_contentType = _getNewAdministratorEmail().contentType;
									break;
								case 'newUnregisteredAdmin':
									_title = _getInviteUnregisteredAdmin().title;
									_subject = _getInviteUnregisteredAdmin().subject;
									_body = _getInviteUnregisteredAdmin().body;
									_contentType = _getInviteUnregisteredAdmin().contentType;
									break;
								case 'newModerator':
									_title = _getNewModeratorEmail().title;
									_subject = _getNewModeratorEmail().subject;
									_body = _getNewModeratorEmail().body;
									_contentType = _getNewModeratorEmail().contentType;
									break;
								case 'helloSpacesTicketInvitation':
									_title = _getHelloSpacesTicketInvitation().title;
									_subject = _getHelloSpacesTicketInvitation().subject;
									_body = _getHelloSpacesTicketInvitation().body;
									_contentType = _getHelloSpacesTicketInvitation().contentType;
									break;
								case 'defaultHelloSpacesInvitation':
									_title = _getDefaultHelloSpacesInvitation(activeSpace?.ticketSettings?.ticketRequired).title;
									_subject = _getDefaultHelloSpacesInvitation(activeSpace?.ticketSettings?.ticketRequired).subject;
									_body = _getDefaultHelloSpacesInvitation(activeSpace?.ticketSettings?.ticketRequired).body;
									_contentType = _getDefaultHelloSpacesInvitation(
										activeSpace?.ticketSettings?.ticketRequired
									).contentType;
									break;
								case 'confirmAccount':
									_title = _getAccountConfirmationEmail().title;
									_subject = _getAccountConfirmationEmail().subject;
									_body = _getAccountConfirmationEmail().body;
									_contentType = _getAccountConfirmationEmail().contentType;
									break;
								case 'passwordRecovery':
									_title = _getPasswordResetEmail().title;
									_subject = _getPasswordResetEmail().subject;
									_body = _getPasswordResetEmail().body;
									_contentType = _getPasswordResetEmail().contentType;
									break;
								default:
									break;
							}
							onChange({
								...values,
								title: _title,
								subject: _subject,
								body: _body,
								contentType: _contentType ?? values.contentType
							});
						}}
					/>
				</HSCard>
			)}
		</View>
	);
};
