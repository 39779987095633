import React from 'react';
import { Text as RNText, TextProps, View } from 'react-native';
import { TFunctionResult } from 'i18next';

import { useTheme } from 'hooks/useTheme';
import TextTruncate from './TextTruncate';
import { useTranslation } from 'react-i18next';
interface ITextComponent extends TextProps {
	bold?: boolean;
	center?: boolean;
	children?: string | string[] | number | Element | null | TFunctionResult;
	marginBottom?: boolean;
	italic?: boolean;
	numberOfLines?: number;
	doTruncate?: boolean;
	largerText?: boolean;
}

const TOPMARGIN = 0;
const ALLOWFONTSCALING = false;

export const Text = (props: ITextComponent) => {
	const { bold, center, marginBottom, children, style, italic, numberOfLines, doTruncate, largerText } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const renderExpand = () => {
		return (
			<RNText
				allowFontScaling={ALLOWFONTSCALING}
				style={{ color: theme.text, fontSize: largerText ? 21 : 14, textAlign: 'right', fontWeight: bold ? 'bold' : 'normal' }}
			>
				{t('showMore')}
			</RNText>
		);
	};

	const renderCollapse = () => {
		return (
			<RNText
				allowFontScaling={ALLOWFONTSCALING}
				style={{ color: theme.text, fontSize: largerText ? 21 : 14, textAlign: 'right', fontWeight: bold ? 'bold' : 'normal' }}
			>
				{t('showLess')}
			</RNText>
		);
	};

	if (doTruncate) {
		return (
			<View style={{ marginBottom: marginBottom ? 30 : undefined }}>
				<TextTruncate
					{...props}
					renderExpandor={renderExpand}
					renderCollapsar={renderCollapse}
					expandorStyle={{ width: '100%', marginTop: TOPMARGIN }}
					collapsarStyle={{ width: '100%', marginTop: TOPMARGIN }}
					style={{ marginBottom: 0 }}
					numberOfLines={numberOfLines}
				>
					<RNText
						allowFontScaling={ALLOWFONTSCALING}
						style={[
							{
								marginBottom: marginBottom ? 30 : undefined,
								color: theme.text,
								fontSize: largerText ? 21 : 14,
								fontWeight: bold ? 'bold' : 'normal',
								textAlign: center ? 'center' : 'left',
								fontStyle: italic ? 'italic' : 'normal'
							},
							style
						]}
					>
						{children}
					</RNText>
				</TextTruncate>
			</View>
		);
	}

	return (
		<RNText
			{...props}
			allowFontScaling={ALLOWFONTSCALING}
			style={[
				{
					marginBottom: marginBottom ? 30 : undefined,
					color: theme.text,
					fontSize: largerText ? 21 : 14,
					fontWeight: bold ? 'bold' : 'normal',
					textAlign: center ? 'center' : 'left',
					fontStyle: italic ? 'italic' : 'normal'
				},
				style
			]}
		>
			{children}
		</RNText>
	);
};
