import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IPushNotification } from 'config/interfaces';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { PushNotificationList } from 'components/PushNotification';
import { NoData } from 'components/NoData';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsTopScreenPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet } from 'helper';
import i18next from 'i18next';
import { RoundButton } from 'components/Button';
import { FormCheckbox } from 'components/Form';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.PushNotificationList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.PushNotificationList>;
type RouteParams = StackParamList[ERoutes.PushNotificationList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'pushnotificationslist';

export const PushNotificationListScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [isEditMode, setIsEditMode] = useState<boolean>(true);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<number[]>([]);
	const [notifications, setNotifications] = useState<IPushNotification[]>([]);

	const pushNotifications = useSelector((store: IRootState) => store.pushNotification.pushNotifications);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		let _notifications: typeof notifications = [];

		if (activeSpace) {
			_notifications = pushNotifications.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setNotifications(_notifications);
	}, [activeSpace, pushNotifications]);

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		showAlert({
			title: t('ConfirmDeleteCount').replace('%COUNT%', itemId ? '1' : selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(itemId ? [itemId] : selectedEntries);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'pushnotification' });
						setIsDeleteLoading([]);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.PushNotification, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			default:
				break;
		}
	};

	const _renderSelectAll = () => {
		if (notifications.length > 0) {
			return (
				<HSCard>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedEntries && selectedEntries.length === notifications.length}
						onPress={() => {
							if (selectedEntries && selectedEntries.length === notifications.length) {
								setSelectedEntries([]);
							} else {
								setSelectedEntries(notifications.map((sp) => sp.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (notifications.length === 0) {
			return (
				<NoData type="NoPushNotifications">
					<RoundButton
						testID={`${TESTIDPREFIX}_button_addpushnotification`}
						onPress={() => navigation.navigate(ERoutes.PushNotification, { spaceId: activeSpace?.spaceId })}
						icon={EDefaultIconSet.Add}
						title={t('CreateNotificationSubmitButton')}
						isStacked
					/>
				</NoData>
			);
		}

		return (
			<HSCard style={{ flex: 1 }}>
				<PushNotificationList
					TESTIDPREFIX={TESTIDPREFIX}
					notifications={notifications}
					onSelect={(itemId) => _handleSelect(itemId)}
					onDelete={(itemId) => _delete(itemId)}
					onEdit={(itemId) =>
						navigation.navigate(ERoutes.PushNotification, {
							spaceId: activeSpace?.spaceId,
							id: itemId
						})
					}
					selectedEntries={selectedEntries}
					isDeleteLoading={isDeleteLoading}
				/>
			</HSCard>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="pushnotifications">
			<View
				style={{
					flex: 1,
					width: screenWidth,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					alignSelf: 'center'
				}}
			>
				{_renderSelectAll()}
				{_renderContent()}
			</View>

			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					isDisabled={isDeleteLoading.length > 0}
					onPress={() => _handleActionSheetPress('add')}
				/>
				{/* <BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_editmode`}
					icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
					label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
					isDisabled={isDeleteLoading.length > 0}
					onPress={() => _handleActionSheetPress('editMode')}
				/> */}
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
					isDisabled={selectedEntries.length === 0}
					onPress={() => _handleActionSheetPress('delete')}
					isLoading={isDeleteLoading.length > 0}
					iconColor={theme.danger}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const PushNotificationListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Push Notifications')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
