import React from 'react';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteSurveyForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteSurveyForm = (props: IVoteSurveyForm) => {
	const { onChange, values, errors, testIdPrefix } = props;

	return <CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} />;
};
