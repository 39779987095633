import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderHelloSpaces,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { SpaceSummary } from 'components/SpacePreview';
import { DEFAULTTHEME } from 'rematch/models/config';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { IS_ANDROID } from 'helper';
import { BackHandler } from 'react-native';
import { ILauncherSpace } from 'config/interfaces';
import { PRESET_SPACEIDS, PRESET_SPACE_FALLBACK } from 'config/envConstants';
import { Spinner } from 'components/Spinner';
import { useSpace } from 'hooks/useSpace';
import i18next from 'i18next';
import * as Localization from 'expo-localization';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceSummary>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceSummary>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceSummary];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const SpaceSummaryScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();

	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [isSpaceLoading, setIsSpaceLoading] = useState<boolean>(false);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);
	const setTempSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setTempSpaceId);

	useEffect(() => {
		if (!IS_ANDROID) {
			return;
		}

		const backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
			if (PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
				return;
			}
			setTheme(DEFAULTTHEME);
			i18next.changeLanguage(Localization.locale.split('-')[0]);
			navigation.goBack();
			return true;
		});

		return () => backHandler.remove();
	}, []);

	useEffect(() => {
		navigation.addListener('focus', () => _onFocus());
	}, [activeSpace]);

	useEffect(() => {
		if (route.params?.spaceId) {
			_findSpaceById(route.params.spaceId);
		} else {
			_findSpaceById();
		}
	}, [route.params]);

	const _onFocus = () => {
		const _active = getActiveSpace({});
		if (_active) {
			navigation.replace('tab');
		}
	};

	const _findSpaceById = async (id?) => {
		if (id) {
			setIsSpaceLoading(true);
			const res = await findSpaceById({ spaceId: id });
			setIsSpaceLoading(false);
			if (res?.spaceId) {
				if (res?.spaceId) {
					setTempSpaceId(res.spaceId);
				}
				setSpace(res);
				i18next.changeLanguage(res.language);
			}
		} else if (PRESET_SPACEIDS) {
			setIsSpaceLoading(true);
			navigation.setParams({
				spaceId: PRESET_SPACEIDS[0]
			});
			const res = await findSpaceById({ spaceId: PRESET_SPACEIDS[0] });
			setIsSpaceLoading(false);
			if (res?.spaceId) {
				setTempSpaceId(res.spaceId);
				setSpace(res);
			}
		}
	};

	const _renderContent = () => {
		if (isSpaceLoading) {
			return <Spinner />;
		}

		if (space) {
			return <SpaceSummary space={space} spaceId={space.spaceId} />;
		}

		if (PRESET_SPACE_FALLBACK) {
			return <SpaceSummary space={PRESET_SPACE_FALLBACK} spaceId={PRESET_SPACE_FALLBACK.spaceId} />;
		}

		return null;
	};

	return <ScreenContainer>{_renderContent()}</ScreenContainer>;
};

export const SpaceSummaryScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	return (
		<NavigationHeader>
			{PRESET_SPACEIDS?.length === 1 ? (
				<NavigationHeaderPlaceholder />
			) : (
				<NavigationHeaderBackButton
					onPress={() => {
						setTheme(DEFAULTTHEME);
						i18next.changeLanguage(Localization.locale.split('-')[0]);
					}}
				/>
			)}
			<NavigationHeaderTitle title={''} />
			<NavigationHeaderHelloSpaces />
		</NavigationHeader>
	);
};
