import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import moment from 'moment';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { HSCard } from 'components/Card';
import { IWelcomeMessageFormErrors, IWelcomeMessageFormValues } from 'config/interfaces';
import { FormImageSelection, FormMultiSwitch, FormTextInput } from 'components/Form';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import { EDefaultIconSet } from 'helper';
import { isEmptyObject } from 'helper/object';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.WelcomeEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.WelcomeEdit>;
type RouteParams = StackParamList[ERoutes.WelcomeEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'welcomeedit';

export const WelcomeEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [welcomeMessageValues, setWelcomeMessageValues] = useState<IWelcomeMessageFormValues>({});
	const [errors, setErrors] = useState<IWelcomeMessageFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);

	useEffect(() => {
		if (activeSpace && isEmptyObject(welcomeMessageValues)) {
			setWelcomeMessageValues({
				spaceId: activeSpace.spaceId,
				welcomeGallery: activeSpace.welcomeGallery ?? [],
				welcomeShowType: activeSpace.welcomeShowType ?? 'never',
				welcomeText: activeSpace.welcomeText ?? '',
				welcomeTitle: activeSpace.welcomeTitle ?? '',
				welcomeLastUpdateTimeStamp: activeSpace.welcomeLastUpdateTimeStamp ?? moment().toISOString()
			});
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading
		});
	}, [welcomeMessageValues, isLoading]);

	const _updateSpace = async () => {
		setIsLoading(true);
		const res = await updateSpace({ data: welcomeMessageValues });
		if (res) {
			navigation.goBack();
		}
		setIsLoading(false);
	};

	const _updateValues = (fieldName, value) => {
		setWelcomeMessageValues({ ...welcomeMessageValues, [fieldName]: value });

		if (!route.params.prohibitNavigation) {
			navigation.setParams({
				prohibitNavigation: true
			});
		}
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_welcomeshowtype`}
						label={t('welcomeShowType')}
						hint={t('welcomeShowTypeHint')}
						error={errors.welcomeShowType}
						value={welcomeMessageValues.welcomeShowType}
						options={[
							{
								key: 'never',
								label: t('never')
							},
							{
								key: 'once',
								label: t('once')
							},
							{
								key: 'always',
								label: t('always')
							}
						]}
						onChange={(val) => _updateValues('welcomeShowType', val)}
						isDisabled={isLoading}
					/>
					{welcomeMessageValues.welcomeShowType === 'once' && (
						<FormMultiSwitch
							testID={`${TESTIDPREFIX}_multiswitch_welcomelastupdatetimestamp`}
							label={t('welcomeLastUpdateTimeStamp')}
							hint={t('welcomeLastUpdateTimeStampHint')}
							error={errors.welcomeLastUpdateTimeStamp}
							value={
								welcomeMessageValues.welcomeLastUpdateTimeStamp === activeSpace?.welcomeLastUpdateTimeStamp
									? 'false'
									: 'true'
							}
							options={[
								{
									key: 'false',
									label: t('No')
								},
								{
									key: 'true',
									label: t('Yes')
								}
							]}
							onChange={(val) =>
								_updateValues(
									'welcomeLastUpdateTimeStamp',
									val === 'true' ? moment().toISOString() : welcomeMessageValues.welcomeLastUpdateTimeStamp
								)
							}
							isDisabled={isLoading}
						/>
					)}
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_welcometitle`}
						label={t('welcomeTitle')}
						hint={t('welcomeTitleHint')}
						value={welcomeMessageValues.welcomeTitle}
						error={errors.welcomeTitle}
						onChangeText={(val) => _updateValues('welcomeTitle', val)}
						isDisabled={isLoading}
					/>
					<FormMarkdownEditor
						testID={`${TESTIDPREFIX}_textinput_welcometext`}
						label={t('welcomeText')}
						hint={t('welcomeTextHint')}
						value={welcomeMessageValues.welcomeText}
						error={errors.welcomeText}
						onChange={(val) => _updateValues('welcomeText', val)}
						isDisabled={isLoading}
					/>
					<FormImageSelection
						label={`${t('Media')} (${t('Images')}/${t('Videos')})`}
						media={welcomeMessageValues.welcomeGallery?.map((m) => m.media)}
						onChange={(value) => {
							_updateValues(
								'welcomeGallery',
								value.map((v, index) => {
									return { media: v, order: index };
								})
							);
						}}
						testID={`${TESTIDPREFIX}_media_welcomemessagevalues`}
						isDisabled={isLoading}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const WelcomeEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Welcome')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
