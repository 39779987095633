import { Avatar } from 'components/User';
import { IAttendee, IHSPXTicket } from 'config/interfaces';

import React, { ReactNode, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { ListItemMatchCount } from 'components/Matching';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IUserInSpaceId } from 'rematch/interfaces';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

export const ATTENDEELISTITEMENTRYHEIGHT = 80;

export interface IAttendeeListItem {
	testID: string;
	item: IAttendee;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
	isAdminArea?: boolean;
	hideInteractions?: boolean;
	isMailReceiver?: boolean;
}

export const AttendeeListItem = (props: IAttendeeListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onDelete, isLoading, isAdminArea, hideInteractions, isMailReceiver } = props;
	const { theme } = useTheme();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const navigation = useNavigation();
	const { t } = useTranslation();

	const [ticket, setTicket] = useState<IHSPXTicket | undefined>(undefined);
	const [userInSpaceId, setUserInSpaceId] = useState<IUserInSpaceId | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const tickets = useSelector((store: IRootState) => store.ticket.tickets);
	const showAboutMe = activeSpace?.registrationFields?.fields?.find((f) => f.fieldName === 'aboutMe' && f.showOnPreview === true);

	useEffect(() => {
		if (userInSpaces && activeSpace && (iAmSpaceAdmin || iAmSpaceModerator)) {
			const e = userInSpaces.find((e) => e?.spaceId === activeSpace.spaceId && e?.userId === item.userId);
			setUserInSpaceId(e);
		}
	}, [activeSpace, item, iAmSpaceAdmin, iAmSpaceModerator, userInSpaces]);

	useEffect(() => {
		if (userInSpaceId?.ticketProvider === 'hellospaces' && tickets) {
			let _ticket: typeof ticket = undefined;

			_ticket = tickets.find((e) => e.ticketcode === userInSpaceId.ticketcode);

			setTicket(_ticket);
		}
	}, [userInSpaceId, tickets]);

	const _renderAdminInformation = () => {
		if (!onSelect && !isAdminArea) {
			return null;
		}
		const elements: ReactNode[] = [];
		if (userInSpaceId?.ticketcode) {
			elements.push(
				<ChildButton
					testID={`${testID}_button_ticket`}
					onPress={() => navigation.navigate(ERoutes.TicketEdit, { spaceId: activeSpace?.spaceId, id: ticket?.id })}
					style={{ marginTop: 5 }}
					isDisabled={!ticket}
				>
					<Text style={{ fontSize: 9 }}>{`Ticketcode: ${userInSpaceId.ticketcode}`}</Text>
				</ChildButton>
			);
		}
		if (item?.email) {
			elements.push(<Text style={{ fontSize: 9 }}>{`${t('email')}: ${item?.email}`}</Text>);
		}
		if (elements.length > 0) {
			return <View>{elements}</View>;
		}
		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{
				height: ATTENDEELISTITEMENTRYHEIGHT,
				paddingVertical: 5,
				// Keep this padding for new flashlist
				// to prevent overlap of scrollbar and fav button
				paddingRight: IS_WEB ? 10 : 0,
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
			isDisabled={!onPress || isLoading}
			onPress={isMailReceiver ? onSelect : onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}
			{isMailReceiver ? (
				<ChildButton testID={`${testID}_avatar`} onPress={onPress}>
					<Avatar
						avatar={item?.smallImageUrl ?? item?.imageUrl}
						fullName={`${item?.firstName} ${item?.lastName}`}
						userId={item?.userId}
						size="listItem"
					/>
				</ChildButton>
			) : (
				<Avatar
					testID={`${testID}_avatar`}
					avatar={item?.smallImageUrl ?? item?.imageUrl}
					fullName={`${item?.firstName} ${item?.lastName}`}
					userId={item?.userId}
					size="listItem"
				/>
			)}
			<View style={{ flex: 1, flexDirection: 'row', paddingLeft: 10, alignItems: 'center' }}>
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingRight: 5 }}>
						<Text bold style={{ marginBottom: 2, flex: 1 }}>{`${item?.firstName} ${item?.lastName}`}</Text>
						{item?.userId !== profile?.userId && !hideInteractions && <ListItemMatchCount item={item} contentType="attendee" />}
					</View>
					{(!isEmptyString(item?.jobTitle) || !isEmptyString(item?.company)) && (
						<Text style={{ flexDirection: 'row' }} numberOfLines={2}>
							{!isEmptyString(item?.jobTitle) && (
								<Text style={{ fontSize: 12 }}>{`${item?.jobTitle}${!isEmptyString(item?.company) ? ' | ' : ''}`}</Text>
							)}
							{!isEmptyString(item?.company) && <Text style={{ fontSize: 12 }}>{item?.company}</Text>}
						</Text>
					)}
					{showAboutMe && !isEmptyString(item.aboutMe) && (
						<Text style={{ fontSize: 12 }} numberOfLines={1}>
							{item.aboutMe}
						</Text>
					)}
					{_renderAdminInformation()}
				</View>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					{onDelete && (
						<View style={{ alignSelf: 'center', alignItems: 'center' }}>
							<RoundButton
								isOutline
								size="xs"
								onPress={onDelete}
								testID={`${testID}_button_delete`}
								icon={EDefaultIconSet.Delete}
								color={theme.danger}
								isLoading={isLoading}
							/>
						</View>
					)}
					{onPress && SHOW_CHEVRON_IN_LIST_ITEM && (
						<View>
							<Icon name={EDefaultIconSet.ChevronRight} size={30} />
						</View>
					)}

					{item?.userId !== profile?.userId && !hideInteractions && (
						<FavoriteButton testID={`${testID}_button_favorite`} id={item?.userId} type="attendee" />
					)}
				</View>
			</View>
		</ChildButton>
	);
};
