import { createModel } from '@rematch/core';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import { IAPICall } from 'rematch/interfaces';
import { RootModel } from './index';

type IDefaultState = {};

let hasShownToast = false;

export const request = createModel<RootModel>()({
	state: {} as IDefaultState,
	reducers: {},
	effects: (dispatch) => ({
		async anonymousRequest(payload: IAPICall, store) {
			try {
				const { url, method, body, contentType } = payload;

				if (!store.temp.netInfoState?.isConnected) {
					return {
						isOffline: true
					};
				}

				let res;
				if (method === 'GET' || method === 'DELETE') {
					res = await fetch(url, {
						method
					}).then((response) => response.json());
				} else {
					const headers = {};

					if (contentType) {
						headers['Content-Type'] = contentType;
					}

					res = await fetch(url, {
						method,
						headers,
						body
					}).then((response) => response.json());
				}

				return res;
			} catch (error) {
				console.log('anonymousRequest', payload.url);
				console.log('anonymousRequest', error);
			}
		},
		async authenticatedRequest(payload: IAPICall, store) {
			try {
				const { url, method, body, contentType } = payload;

				if (!store.temp.netInfoState?.isConnected) {
					return {
						isOffline: true
					};
				}

				let res;
				if (method === 'GET' || method === 'DELETE') {
					res = await fetch(url, {
						method,
						headers: {
							Authorization: `Bearer ${store.auth.jwt}`
						}
					}).then((response) => response.json());
				} else {
					const headers = {
						Authorization: `Bearer ${store.auth.jwt}`
					};
					if (contentType) {
						headers['Content-Type'] = contentType;
					}
					res = await fetch(url, {
						method,
						headers,
						body
					}).then((response) => response.json());
				}

				if (res && (res.message === 'Invalid token' || res.message === 'Invalid token.')) {
					if (store.auth.profile) {
						if (!hasShownToast) {
							showToast('error', i18next.t('Session expired'), i18next.t('Your session has expired. Please log in again'));
							hasShownToast = true;
						}
						dispatch.auth.logout({ noToast: true });
						setTimeout(() => {
							hasShownToast = false;
						}, 1000);
					}
					return;
				}

				return res;
			} catch (error) {
				console.log('authenticatedRequest', payload.url);
				console.log('authenticatedRequest', error);
			}
		}
	})
});
