import React, { useState, useEffect, useRef } from 'react';
import { RouteProp, StackActions, useFocusEffect, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Placeholder, PlaceholderLine, ShineOverlay } from 'rn-placeholder';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderCreateNewsButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { NewsListItem } from 'components/News';
import { AppState, AppStateStatus, BackHandler, ScrollView, View } from 'react-native';
import moment from 'moment';
import { IFeatureInfo, INews } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { NoData } from 'components/NoData';
import { RoundButton } from 'components/Button';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet, IS_ANDROID, IS_WEB } from 'helper';
import RBSheet from 'react-native-raw-bottom-sheet';
import { getFeedExamples } from 'helper/content';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { SponsorBlock } from '../Sponsors';
import { TABBAR_HEIGHT } from 'config/constants';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { ScheduleGallery } from 'components/Schedule';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NewsList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NewsList>;
type RouteParams = StackParamList[ERoutes.NewsList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'newslist';

export const NewsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenColumnCount, screenWidth } = useQuery();
	const { exportAsCSV } = useExport();
	const { theme } = useTheme();
	const { getContentTypeFields } = useContent('feed');
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	let timer = useRef<NodeJS.Timer>(null);

	const [filteredNews, setFilteredNews] = useState<INews[]>([]);
	const [currentDate, setCurrentDate] = useState<string>(moment().toISOString());
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isExampleLoading, setIsExampleLoading] = useState<boolean>(false);

	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const bottomSheetRef = useRef<RBSheet>(null);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const content = useSelector((store: IRootState) => store.content.content);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const updateLastRead = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateLastRead);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const createNews = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createNews);

	const isScreenFocused = useIsFocused();

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});

		return () => {
			_stopTimer();
		};
	}, []);

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('feed')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (isScreenFocused) {
			_startTimer();
		} else {
			_stopTimer();
		}
	}, [isScreenFocused]);

	useEffect(() => {
		let appStateListener;

		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		return () => {
			if (!IS_WEB && appStateListener) {
				appStateListener.remove();
			}
		};
	}, []);

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			_startTimer();
		} else if (nextState === 'background') {
			_stopTimer();
		}
	};

	useEffect(() => {
		_getNews();
	}, [
		activeSpace,
		iAmSpaceAdmin,
		iAmSpaceModerator,
		content,
		activeSpace?.allowUserGeneratedContentInFeed,
		userInfos,
		currentDate,
		isScreenFocused
	]);

	useFocusEffect(
		React.useCallback(() => {
			if (content?.newsitems) {
				updateLastRead({ types: ['newsitems'] });
			}

			return () => updateLastRead({ types: ['newsitems'] });
		}, [content?.newsitems])
	);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const _startTimer = () => {
		timer.current = setInterval(() => {
			const now = moment();
			if (now.get('seconds') === 0) {
				setCurrentDate(now.toISOString());
			}
		}, 1000);
	};

	const _stopTimer = () => {
		if (timer.current) {
			clearInterval(timer.current);
			timer.current = undefined;
		}
	};

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	const _setMediaDetail = (itemId: number) => {
		navigation.navigate(ERoutes.Media, {
			itemId,
			mediaType: 'schedule',
			spaceId: activeSpace?.spaceId
		});
	};

	const _delete = async (itemId?: number) => {
		const singleItem = filteredNews.find((e) => e?.id === itemId);
		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'newsitem' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.NewsCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'newsitem' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: INews[] = [];
				if (content?.newsitems) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content?.newsitems?.find((e) => e?.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.newsitems];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'feed');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'addExample':
				setIsExampleLoading(true);
				const feedExamples = getFeedExamples();
				for (const feedExample of feedExamples) {
					await createNews({
						news: feedExample,
						noToast: true
					});
				}
				setIsExampleLoading(false);
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _iAmSpaceAdminOrModerator = () => {
		return iAmSpaceAdmin || iAmSpaceModerator;
	};

	const _getNews = () => {
		if (activeSpace && content?.newsitems && isScreenFocused) {
			let _filtered: typeof filteredNews = [];

			const now = moment(currentDate);
			if (_iAmSpaceAdminOrModerator()) {
				_filtered = content?.newsitems?.filter((e) => e.spaceId === activeSpace.spaceId && !e.isDeleted);
			} else {
				_filtered = content?.newsitems?.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						(!e.showFrom || now.isAfter(moment(e.showFrom))) &&
						(!e.showUntil || now.isBefore(moment(e.showUntil))) &&
						!e.isDeleted &&
						(activeSpace?.allowUserGeneratedContentInFeed !== 'moderated' || e.userId === userInfos?.userId || e.isVisible)
				);
			}
			if (!activeSpace?.allowUserGeneratedContentInFeed || activeSpace?.allowUserGeneratedContentInFeed === 'never') {
				const admins = activeSpace?.admins?.map((a) => a.userId) ?? [];
				const moderators = activeSpace?.moderators?.map((a) => a.userId) ?? [];
				_filtered = _filtered.filter(
					(item) =>
						!item.userId ||
						admins.includes(item.userId) ||
						moderators.includes(item.userId) ||
						item.userId === userInfos?.userId
				);
			}

			_filtered.sort((a, b) => {
				const aVal = a.showFrom ? moment(a.showFrom) : moment(a.created_at);
				const bVal = b.showFrom ? moment(b.showFrom) : moment(b.created_at);

				return aVal.isAfter(bVal) ? -1 : 1;
			});

			const pinned = _filtered.filter((e) => e.isPinned);
			const notPinned = _filtered.filter((e) => !e.isPinned);

			let _news = [...pinned, ...notPinned];

			setFilteredNews(_news);
		}
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _renderSelectAll = () => {
		if (isEditMode && filteredNews.length > 0) {
			return (
				<View
					style={{
						width: screenWidth,
						alignSelf: 'center',
						alignItems: 'center',
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: hsTopScreenPadding
					}}
				>
					<HSCard>
						<FormCheckbox
							testID={`${TESTIDPREFIX}_checkbox_selectall`}
							label={t('SelectAll')}
							style={{ marginBottom: 0 }}
							value={selectedEntries && selectedEntries.length === filteredNews.length}
							onPress={() => {
								if (selectedEntries && selectedEntries.length === filteredNews.length) {
									setSelectedEntries([]);
								} else {
									setSelectedEntries(filteredNews.map((sp) => sp?.id));
								}
							}}
						/>
					</HSCard>
				</View>
			);
		}

		return null;
	};

	const _renderEmptyFeedEntry = () => {
		return (
			<HSCard style={{ height: 160 }}>
				<Placeholder Animation={ShineOverlay}>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<PlaceholderLine width={50} />
						<PlaceholderLine width={10} />
					</View>
					<View style={{ marginBottom: hsBottomMargin }}>
						<PlaceholderLine />
					</View>
					<View style={{ marginBottom: hsBottomMargin }}>
						<PlaceholderLine />
						<PlaceholderLine />
					</View>
				</Placeholder>
			</HSCard>
		);
	};

	const _renderNoData = () => {
		if (waitingForSocketResponse?.newsitems) {
			return (
				<View>
					{_renderEmptyFeedEntry()}
					{_renderEmptyFeedEntry()}
					{_renderEmptyFeedEntry()}
					{_renderEmptyFeedEntry()}
					{_renderEmptyFeedEntry()}
				</View>
			);
		}

		return (
			<NoData type="NoNews">
				{(activeSpace?.allowUserGeneratedContentInFeed === 'moderated' ||
					activeSpace?.allowUserGeneratedContentInFeed === 'always' ||
					_iAmSpaceAdminOrModerator()) && (
					<RoundButton
						testID={'news_button_createfirstnews'}
						onPress={() => navigation.navigate(ERoutes.NewsCreate, { spaceId: activeSpace?.spaceId })}
						icon={EDefaultIconSet.Add}
						title={t('Add')}
						isStacked
					/>
				)}
			</NoData>
		);
	};

	const _renderContent = () => {
		if (filteredNews?.length === 0) {
			return (
				<View
					style={{
						flex: 1,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderNoData()}
				</View>
			);
		}

		if (filteredNews?.length > 0 && screenColumnCount) {
			return (
				<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
					{filteredNews.map((item, index) => {
						return (
							<View key={`news_${item?.id}_${index}`} style={{ width: `${100 / screenColumnCount}%` }}>
								<NewsListItem
									item={item}
									isEditMode={isEditMode}
									containerStyle={{
										paddingHorizontal: EHorizontalScreenPadding.Wide
									}}
									onSelect={() => _handleSelect(item?.id)}
									testID={`${TESTIDPREFIX}_news_${index}`}
									isSelected={selectedEntries.includes(item?.id)}
								/>
							</View>
						);
					})}
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer contentKey="newsitems">
			<View style={{ flex: 1, marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0 }}>
				{_renderSelectAll()}
				<ScrollView
					contentContainerStyle={{
						paddingTop: isEditMode && filteredNews.length > 0 ? 0 : hsTopScreenPadding,
						width: screenWidth,
						alignSelf: 'center'
					}}
					style={{
						marginBottom: activeSpace?.sponsorTypeFeed?.startsWith('sponsor')
							? EHorizontalScreenPadding.Wide
							: isTabbarVisible
							? 0
							: hsBottomMargin
					}}
					testID={`${TESTIDPREFIX}_scrollview`}
				>
					{!isEditMode && (
						<ScheduleGallery
							key={`${TESTIDPREFIX}_schedulegallery`}
							onItemPress={(item) => {
								if (!item.hasNoDetails) {
									_setMediaDetail(item?.id);
								}
							}}
							isVisible={activeSpace?.showUpcomingScheduleOnFeed}
						/>
					)}
					{_renderContent()}
				</ScrollView>
				<View style={{ paddingHorizontal: EHorizontalScreenPadding.Wide, width: screenWidth, alignSelf: 'center' }}>
					<SponsorBlock contentType={'feed'} />
				</View>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_editmode`}
						icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
						label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('editMode')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						isLoading={isDeleteLoading}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isDisabled={isDeleteLoading || isExampleLoading}
						isLoading={isExampleLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const NewsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				{/* {!IS_WEB && <NavigationHeaderScanUser />} */}
				<NavigationHeaderCreateNewsButton options={props.options} />
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};

export const NewsScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				{/* {!IS_WEB && <NavigationHeaderScanUser />} */}
				<NavigationHeaderCreateNewsButton options={props.options} />
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};
