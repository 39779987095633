import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { IMailTemplate } from 'config/interfaces';
import { EDefaultIconSet } from 'helper';
import { t } from 'i18next';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTheme } from 'hooks/useTheme';
import { FormCheckbox } from 'components/Form';
import { hsActionButtonMargin } from 'config/styleConstants';

interface IMailTemplateListItem {
	testID: string;
	item: IMailTemplate;
	onSelect?: (itemId: number) => void;
	isSelected?: boolean;
	onEdit?: () => void;
	onDelete?: () => void;
	isDeleteLoading?: boolean;
}

export const MailTemplateListItem = (props: IMailTemplateListItem) => {
	const { testID, item, onSelect, isSelected, onEdit, onDelete, isDeleteLoading } = props;
	const { theme } = useTheme();

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	return (
		<HSCard style={{ flexDirection: 'row', alignItems: 'center' }}>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect(item.id)}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}
			<View style={{ flex: 1, paddingHorizontal: 20 }}>
				<Text bold>{item.title}</Text>
				<Text italic style={{ fontSize: 12 }}>
					{item.subject}
				</Text>
				{item.attachments && item.attachments.length > 0 && (
					<Text style={{ fontSize: 12 }}>{`${item.attachments.length} ${t('Attachments')}`}</Text>
				)}
				<Text>{`Typ: ${item.contentType}`}</Text>
			</View>

			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				{onEdit && (
					<View style={{ marginRight: hsActionButtonMargin }}>
						<RoundButton
							isOutline
							icon={EDefaultIconSet.Edit}
							testID={`${testID}_edit_${item.id}`}
							isDisabled={isDeleteLoading}
							onPress={() => onEdit()}
						/>
					</View>
				)}
				{!item.key && onDelete && (
					<RoundButton
						isOutline
						color={theme.danger}
						icon={EDefaultIconSet.Delete}
						testID={`${testID}_remove_${item.id}`}
						isLoading={isDeleteLoading}
						onPress={() => {
							showAlert({
								title: t('Delete Template'),
								message: t('Delete Template Subtitle'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('Delete Template'),
										style: 'destructive',
										onPress: async () => onDelete()
									}
								]
							});
						}}
					/>
				)}
			</View>
		</HSCard>
	);
};
