import React, { useEffect, useState } from 'react';
import { IVote } from 'config/interfaces/vote';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { TSort } from '../../ActiveVote';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useQuery } from 'hooks/useQuery';
import { useLike } from 'hooks/useLike';
import moment from 'moment';
import { TabSwitcher } from 'components/TabSwitcher';
import { FlatList, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'components/SearchBar';
import { VoteAnswerTextListItem } from 'components/Vote/VoteAnswer/Text/VoteAnswerTextListItem';
import { HSCard } from 'components/Card/HSCard';
import { ActiveVoteHeader } from 'components/Vote/ActiveVote';
import { EDefaultIconSet } from 'helper';
import { ItemTitle } from 'components/Text';
import { VoteModerationTextModal } from 'components/Modal';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface IVoteModerationText {
	vote: IVote;
	answers: IVoteAnswer[];
	isLoading: Record<number, boolean>;
	updateVoteAnswer: (val: IVoteAnswer) => Promise<boolean>;
}

type TFilter = 'All' | 'Public' | 'Waiting';

export const VoteModerationText = (props: IVoteModerationText) => {
	const { vote, updateVoteAnswer, isLoading } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const { getLikesForItem } = useLike();

	const [filterBy, setFilterBy] = useState<TFilter>('All');
	const [filteredAnswers, setFilteredAnswers] = useState<IVoteAnswer[]>([]);
	const [searchedAnswers, setSearchedAnswers] = useState<IVoteAnswer[]>([]);
	const [sortedAnswers, setSortedAnswers] = useState<IVoteAnswer[]>([]);

	const [sortBy, setSortBy] = useState<TSort>('creation');
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [tempItem, setTempItem] = useState<IVoteAnswer | undefined>(undefined);

	const likes = useSelector((store: IRootState) => store.like.likes);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _filtered: typeof filteredAnswers = [...props.answers];

		switch (filterBy) {
			case 'Public':
				_filtered = _filtered.filter((e) => e.status === 'Public');
				break;
			case 'Waiting':
				_filtered = _filtered.filter((e) => e.status === 'Waiting');
				break;
			case 'All':
			default:
				break;
		}

		let _cleanedAnswers: typeof filteredAnswers = [];

		_filtered.forEach((a) => {
			if (!_cleanedAnswers.find((b) => b.tempId === a.tempId)) _cleanedAnswers.push(a);
		});

		setFilteredAnswers(_cleanedAnswers);
	}, [props.answers, filterBy]);

	useEffect(() => {
		let items = [...filteredAnswers];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			items = items.filter((item) => {
				let attendeeName = '';

				if (attendees) {
					const attendee = attendees.find((e) => e.userId === item.userId);
					if (attendee) {
						attendeeName = `${attendee.firstName} ${attendee.lastName}`.toLowerCase();
					}
				}

				return (
					item.value?.toLowerCase().includes(lowerSearch) ||
					item.info?.toLowerCase().includes(lowerSearch) ||
					attendeeName.includes(lowerSearch)
				);
			});
		}

		setSearchedAnswers(items);
	}, [filteredAnswers, searchTerm]);

	useEffect(() => {
		const _sorted: typeof sortedAnswers = [...searchedAnswers];

		switch (sortBy) {
			case 'creation':
				_sorted.sort(_byCreation);
				break;
			case 'likes':
				_sorted.sort(_byLikes);
				break;
			default:
				break;
		}

		setSortedAnswers(_sorted);
	}, [searchedAnswers, likes, sortBy]);

	const _byCreation = (a: IVoteAnswer, b: IVoteAnswer) => {
		return moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1;
	};

	const _byLikes = (a: IVoteAnswer, b: IVoteAnswer) => {
		const aVal = getLikesForItem(a.tempId, 'voteanswer');
		const bVal = getLikesForItem(b.tempId, 'voteanswer');

		return aVal.length > bVal.length ? -1 : 1;
	};

	return (
		<View style={{ flex: 1, width: screenWidth, alignSelf: 'center' }}>
			<HSCard style={{ flexDirection: 'row', width: '100%', paddingVertical: 0 }}>
				<View
					style={{
						flex: 1,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					{vote.isModerated && (
						<View style={{ paddingTop: 20, paddingRight: 20, alignItems: 'center' }}>
							<ItemTitle text="Filter" />
							<TabSwitcher
								items={[
									{
										key: 'All',
										label: t('All')
									},
									{
										key: 'Public',
										icon: EDefaultIconSet.AnswerPublic
									},
									{
										key: 'Waiting',
										icon: EDefaultIconSet.AnswerWaiting
									}
								]}
								activeKey={filterBy}
								onPress={(key) => setFilterBy(key as TFilter)}
							/>
						</View>
					)}
					<View style={{ flex: 1, paddingRight: 20 }}>
						<SearchBar testID="votemoderationtext_searchbar" value={searchTerm} onChange={(val) => setSearchTerm(val)} />
					</View>
					<View style={{ paddingTop: 20 }}>
						<ItemTitle text={t('Sort')} />
						<TabSwitcher
							items={[
								{
									key: 'creation',
									icon: EDefaultIconSet.Clock
								},
								{
									key: 'likes',
									icon: EDefaultIconSet.ThumbUp
								}
							]}
							activeKey={sortBy}
							onPress={(key) => setSortBy(key as TSort)}
						/>
					</View>
				</View>
			</HSCard>
			<FlatList
				data={sortedAnswers}
				extraData={sortedAnswers}
				ListHeaderComponent={<ActiveVoteHeader activeVote={vote} isCondensed />}
				renderItem={({ item, index }) => (
					<VoteAnswerTextListItem itemIndex={index} vote={vote} item={item} onEdit={() => setTempItem(item)} />
				)}
				style={{ marginHorizontal: -EHorizontalScreenPadding.Wide }}
				contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
				keyExtractor={(item, idx) => `voteanswertext_${item.id}_${idx}`}
			/>
			<VoteModerationTextModal
				item={tempItem}
				vote={vote}
				onClose={() => setTempItem(undefined)}
				onSubmit={(answer) => updateVoteAnswer(answer)}
				isLoading={tempItem && isLoading[tempItem?.id]}
			/>
		</View>
	);
};
