import { IFeatureInfo } from 'config/interfaces';
import { detailedDiff } from 'deep-object-diff';
import { isEmptyString } from './string';

export const filterListByFeature = (items: any[], feature?: IFeatureInfo) => {
	let _filtered = [...items];

	if (feature && feature.filter?.fieldName && !isEmptyString(feature.filter.keys)) {
		const splittedKeys = feature.filter.keys.split(',');
		if (splittedKeys.length > 0) {
			_filtered = _filtered.filter((e) => {
				if (!feature.filter?.fieldName) {
					return true;
				}
				if (!e[feature.filter?.fieldName]) {
					return false;
				}
				const splittedItemValue = e[feature.filter.fieldName].split(',');
				for (const key of splittedItemValue) {
					if (splittedKeys.includes(key)) {
						return true;
					}
				}

				return false;
			});
		}
	}

	return _filtered;
};

export const sortListByFeature = (items: any[], feature?: IFeatureInfo) => {
	let _sorted = [...items];

	if (feature && !isEmptyString(feature.sorting)) {
		switch (feature.sorting) {
			case 'expoNameAlphabetical':
				let withExpo = items.filter((i) => i.expos && i.expos[0] && i.expos[0].title);
				let withoutExpo = items.filter((i) => !(i.expos && i.expos[0] && i.expos[0].title));
				withExpo.sort((a, b) => {
					return a.expos[0].title.toLowerCase() < b.expos[0].title.toLowerCase() ? -1 : 1;
				});
				withoutExpo.sort((a, b) => {
					return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
				});

				_sorted = [...withExpo, ...withoutExpo];
				break;
			case 'alphabetical':
				_sorted.sort((a, b) => {
					return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
				});
				break;
			default:
				break;
		}
	}

	return _sorted;
};

export const stripNullValues = (obj?: object) => {
	if (!obj) return {};
	const _updated = { ...obj };
	Object.keys(_updated).forEach((key) => {
		if (_updated[key] === undefined || _updated[key] === null) {
			delete _updated[key];
		}
	});

	return _updated;
};

export const hasEntryChanged = (prev, updated) => {
	if (!prev) {
		return true;
	}

	const diff = detailedDiff(prev, updated);
	const changedKeys = [...Object.keys(diff['added']), ...Object.keys(diff['updated']), ...Object.keys(diff['deleted'])];

	return changedKeys.length > 0;
};
