import React, { ReactNode, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { IVote } from 'config/interfaces/vote';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { RoundButton } from 'components/Button';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { getSubmitMultipleChoiceFormSchema } from 'config/yupSchemas';
import { VoteMultipleChoiceResults } from './VoteMultipleChoiceResults';
import { PreviewWordListMultipleChoice } from 'helper/previewAnswers/previewAnswersMultipleChoice';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { FormSwitch } from 'components/Form/FormSwitch';
import { useTranslation } from 'react-i18next';
import { IWordListItem } from '../VoteWordCloud/WordCloud';
import { HSCard } from 'components/Card';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { VoteMultipleChoiceOption } from './VoteMultipleChoiceOption';
import { useNavigation } from '@react-navigation/native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';
import { IVoteAnswer, IVoteResult } from 'config/interfaces';

interface IVoteMultipleChoice {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

const TESTIDPREFIX = 'activevote';

export const VoteMultipleChoice = (props: IVoteMultipleChoice) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const navigation = useNavigation();
	const { trackAction } = useTracker();

	const [selectedIndexes, setSelectedIndexes] = useState<number[]>();
	const [valueSubmitted, setValueSubmitted] = useState<number | undefined>(undefined);
	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [wordList, setWordList] = useState<IWordListItem[]>([]);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [showAnswersPressed, setShowAnswersPressed] = useState<boolean>(false);
	const [distinctAnswers, setDistinctAnswers] = useState<number>(0);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered && !vote.hideResults) {
			setVotingShowResults(true);
		}
	}, [vote]);

	useEffect(() => {
		_generateWordList();
		const _myAnswer = voteAnswers.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		) as IVoteAnswer | undefined;
		if (!_myAnswer && !showAnswersPressed) {
			setValueSubmitted(undefined);
			setVotingShowResults(false);
		}
		if (_myAnswer && _myAnswer.value && !selectedIndexes) {
			const indexes: number[] = [];
			vote.options?.forEach((option, index) => {
				if (_myAnswer.value) {
					if (!isEmptyString(option.key)) {
						try {
							if (JSON.parse(_myAnswer.value).includes(option.key)) {
								indexes.push(index);
							}
						} catch {
							if (_myAnswer.value === option.key) {
								indexes.push(index);
							}
						}
					} else {
						try {
							if (JSON.parse(_myAnswer.value).includes(option.description)) {
								indexes.push(index);
							}
						} catch {
							if (_myAnswer.value === option.description) {
								indexes.push(index);
							}
						}
					}
				}
			});
			setSelectedIndexes(indexes ?? []);
			setValueSubmitted(indexes.length);
			setVotingShowResults(true);
		}
	}, [vote, voteAnswers]);

	const _generateWordList = () => {
		let _wordList: IWordListItem[] = [];
		if (!isPreview) {
			const answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;

			vote.options?.forEach((option) => {
				_wordList.push({
					text: option.description ?? '',
					count: answerForThis?.options.find((e) => e.key === (option.key ?? option.description))?.value ?? 0
				});
			});

			setDistinctAnswers(answerForThis?.submissionCount ?? 0);
		} else {
			_wordList = PreviewWordListMultipleChoice.map((e) => {
				return { count: e.count, text: t(e.text) };
			});
		}
		_wordList = _wordList.sort((a, b) => (a.count > b.count ? -1 : 1));
		setWordList(_wordList);
	};

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		if (isPreview) {
			setVotingShowResults(true);
			return;
		}
		const errors = await validateForm(getSubmitMultipleChoiceFormSchema(), { value: selectedIndexes });
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}
		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		let res, value;
		if (selectedIndexes && selectedIndexes.length > 0) {
			if (selectedIndexes.length >= (vote.minValue ?? 1) && selectedIndexes.length <= (vote.maxValue ?? 1) && vote && vote.options) {
				let _value: string[] = [];
				selectedIndexes.forEach((i) => {
					if (vote.options && vote.options[i]) {
						_value.push(vote.options[i].key ?? vote.options[i].description ?? '');
					}
				});
				value = JSON.stringify(_value);
			}
		}
		if (value) {
			res = await submitVoteAnswer({
				voteId: vote.id,
				value: value,
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
			});
		}
		setIsSubmitVoteAnswerLoading(false);
		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			navigation.setParams({ prohibitNavigation: false });
			setVotingShowResults(true);
			setValueSubmitted(selectedIndexes?.length);
		}
	};

	const _renderResult = () => {
		return (
			<HSCard>
				<VoteMultipleChoiceResults
					wordList={wordList}
					answerCount={distinctAnswers}
					viewType={vote.viewType}
					largerDesign={largerDesign}
				/>
			</HSCard>
		);
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<FormSwitch
					testID="votemultiplechoice_switch_anonymous"
					onChange={() => setAnswerAnonymous(!answerAnonymous)}
					leftLabel={t('Answer Anonymous')}
					value={answerAnonymous}
					size="sm"
					isDisabled={isSubmitVoteAnswerLoading}
					formStyle={{ width: undefined, marginBottom: 0, marginRight: 10 }}
				/>
			);
		}
		return null;
	};

	const _handleChange = (index, isActive) => {
		if (index !== valueSubmitted && !isPreview) {
			navigation.setParams({ prohibitNavigation: true });
		} else {
			navigation.setParams({ prohibitNavigation: false });
		}

		let _indexes = selectedIndexes ?? [];

		if (_indexes?.includes(index)) {
			_indexes = _indexes.filter((i) => i !== index);
		} else {
			if (_indexes.length >= (vote.maxValue ?? 1)) {
				_indexes = [index];
			} else {
				_indexes?.push(index);
			}
		}

		setSelectedIndexes(_indexes);
	};

	const _renderForm = () => {
		if ((vote?.options?.length ?? 0) > 0) {
			return vote.options?.map((item, index) => {
				const isActive = selectedIndexes?.includes(index);

				return (
					<VoteMultipleChoiceOption
						key={`multiple_choice_option_${index}`}
						testID={`${TESTIDPREFIX}_button_multiplechoiceoption_${index}`}
						isActive={isActive}
						onPress={() => _handleChange(index, isActive)}
						label={item.description}
					/>
				);
			});
		}

		return null;
	};

	const _renderContent = () => {
		if ((votingShowResults && !vote?.hideResults) || resultsOnly || !vote.isActive) {
			return _renderResult();
		}

		return _renderForm();
	};

	const _renderActionButtons = () => {
		if (resultsOnly || (votingShowResults && vote?.hideResults) || !vote.isActive) {
			return null;
		}
		let element: ReactNode = null;
		if (votingShowResults && !vote?.hideResults) {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						onPress={() => {
							setVotingShowResults(false);
							setShowAnswersPressed(false);
							setVotingIsAnswered(false);
						}}
						title={!valueSubmitted ? t('answerOptions') : t('changeAnswer')}
						alignSelf="center"
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						icon={EDefaultIconSet.Save}
						testID={`${TESTIDPREFIX}_button_submit`}
						title={t('submitAnswer')}
						isDisabled={
							!selectedIndexes ||
							selectedIndexes.length <= 0 ||
							selectedIndexes.length < (vote.minValue ?? 1) ||
							selectedIndexes.length > (vote.maxValue ?? 1)
						}
						isLoading={isSubmitVoteAnswerLoading}
						onPress={() => {
							_submitVoteAnswer();
							setVotingShowResults(true);
						}}
						alignSelf="center"
					/>
				</HSCard>
			);
		}

		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					contentContainerStyle={{
						paddingBottom: SCROLL_PADDING_FLOATING,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center',
						paddingHorizontal: EHorizontalScreenPadding.Wide
					}}
				>
					<ActiveVoteHeader largerText={largerDesign} showResult={resultsOnly} activeVote={vote} />
					{_renderContent()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButtons()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
		</View>
	);
};
