import React, { useEffect, useRef, useState } from 'react';
import { Animated, ScrollView, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';

import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from './constants';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { IKVP } from './FormMultiSwitch';
import { hsBottomMargin } from 'config/styleConstants';
import { FormField } from './FormField';
import { Avatar } from 'components/User';

interface IDropdownBase {
	testID: string;
	options?: IKVP[];
	placeholder?: string;
	isDisabled?: boolean;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	formStyle?: ViewStyle;
}
interface IDropdownSingle extends IDropdownBase {
	multi?: false;
	onSelect: (val: string | null) => void;
	value?: string | null;
}

interface IDropdownMulti extends IDropdownBase {
	multi: true;
	onSelect: (val: string[] | null) => void;
	value?: string[] | null;
}

type IDropdown = IDropdownSingle | IDropdownMulti;

export const Dropdown = (props: IDropdown) => {
	const { testID, options, onSelect, placeholder, value, isDisabled, multi, label, isRequired, hint, formStyle } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const animatedRotation = useRef(new Animated.Value(0));

	useEffect(() => {
		Animated.timing(animatedRotation.current, {
			toValue: isExpanded ? 90 : 0,
			duration: 300,
			useNativeDriver: true
		}).start();
	}, [isExpanded]);

	const _renderAvatar = (url?: string, label?: string) => {
		if (!isEmptyString(url)) {
			return (
				<View style={{ marginLeft: 5 }}>
					<Avatar avatar={url} fullName={label} size="xxs" />
				</View>
			);
		}

		return null;
	};

	const _renderOptions = () => {
		if (isExpanded) {
			return (
				<ScrollView
					style={{
						maxHeight: 150,
						marginBottom: hsBottomMargin,
						borderWidth: FORMELEMENTBORDERWIDTH,
						borderRadius: FORMELEMENTBORDERRADIUS,
						borderColor: theme.formgray,
						padding: 5
					}}
				>
					{options?.length === 0 ? (
						<Text>{t('noEntriesAvailable')}</Text>
					) : (
						options?.map((option, idx) => (
							<ChildButton
								testID={`${testID}_button_option_${idx}`}
								key={`${testID}_button_option_${idx}`}
								onPress={() => {
									if (multi) {
										let val: string[] = value && Array.isArray(value) ? [...value] : [];
										if (!option.key) {
											onSelect(null);
										} else {
											if (val.includes(option.key)) {
												val = val.filter((e) => e !== option.key);
											} else {
												val.push(option.key);
											}
											onSelect(val);
										}
									} else {
										onSelect(option.key);
										setIsExpanded(false);
									}
								}}
								style={{
									marginBottom: 5,
									flexDirection: 'row',
									flexWrap: 'wrap',
									paddingBottom: !IS_WEB && idx === options.length - 1 ? 8 : 0
								}}
							>
								{multi ? (
									<View style={{ flexDirection: 'row', alignItems: 'center' }}>
										<View
											style={{
												height: 20,
												width: 20,
												borderWidth: 1,
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											{option.key && value?.includes(option.key) ? <Text>X</Text> : null}
										</View>
										<View style={{ flexDirection: 'row', alignItems: 'center' }}>
											{_renderAvatar(option.avatar)}
											<Text style={{ marginLeft: 5 }}>{option.label}</Text>
										</View>
									</View>
								) : (
									<View style={{ flexDirection: 'row', alignItems: 'center' }}>
										<Text>{`- `}</Text>
										{_renderAvatar(option.avatar)}
										<Text style={{ marginLeft: !isEmptyString(option.avatar) ? 5 : 0 }}>{option.label}</Text>
									</View>
								)}
							</ChildButton>
						))
					)}
				</ScrollView>
			);
		}

		return null;
	};

	const _renderValue = () => {
		let label = '';
		if (value) {
			if (multi && Array.isArray(value)) {
				const _options: string[] = [];
				value.forEach((key) => {
					const option = options?.find((e) => e.key === key);
					if (option) {
						_options.push(option.label);
					}
				});
				label = _options.join(', ');
			} else {
				const option = options?.find((e) => e.key === value);
				if (option) {
					label = option.label;
				}
			}

			return <Text style={{ color: isDisabled ? theme.lightgray : theme.text }}>{label}</Text>;
		}

		return <Text style={{ color: isDisabled ? theme.lightgray : theme.text }}>{placeholder ?? t('DropdownPlaceholder')}</Text>;
	};

	return (
		<FormField testID={testID} label={label} hint={hint} isRequired={isRequired} formStyle={formStyle}>
			<ChildButton
				testID={`${testID}_button_expand`}
				onPress={() => setIsExpanded(!isExpanded)}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: 10,
					padding: 5,
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderColor: theme.formgray
				}}
				isDisabled={isDisabled}
			>
				{_renderValue()}
				<Animated.View
					style={{
						transform: [
							{
								rotateZ: animatedRotation.current.interpolate({
									inputRange: [0, 90],
									outputRange: ['0deg', '90deg']
								})
							}
						]
					}}
				>
					<Icon name={EDefaultIconSet.ChevronRight} color={isDisabled ? theme.lightgray : theme.text} />
				</Animated.View>
			</ChildButton>
			{_renderOptions()}
		</FormField>
	);
};
