import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { IVote } from 'config/interfaces/vote';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { VoteModerationText, VoteModerationWordCloud } from 'components/Vote';
import { View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import i18next from 'i18next';
import { useSpace } from 'hooks/useSpace';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.VotingModeration>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.VotingModeration>;
type RouteParams = StackParamList[ERoutes.VotingModeration];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const VoteModerationScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();

	const [vote, setVote] = useState<IVote | undefined>(undefined);
	const [answers, setAnswers] = useState<IVoteAnswer[]>([]);
	const [isLoading, setIsLoading] = useState<Record<number, boolean>>({});

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);

	const updateVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVoteAnswer);
	const moderateVoteWordCloudAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.moderateVoteWordCloudAnswer);

	useEffect(() => {
		if (activeSpace) {
			const _vote = votes.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);

			setVote(_vote);
		}
	}, [activeSpace, votes]);

	useEffect(() => {
		let items: IVoteAnswer[] = [];
		if (activeSpace && vote) {
			items = voteAnswers.filter((e) => e.spaceId === activeSpace.spaceId && e.voteId === vote.id && !e.isDeleted);
		}

		setAnswers(items);
	}, [activeSpace, vote, voteAnswers]);

	const _updateVoteAnswer = async (answer: IVoteAnswer) => {
		setIsLoading({ ...isLoading, [answer.id]: true });

		let res;
		if (vote?.votingType === 'text') {
			res = await updateVoteAnswer({ answer });
		} else {
			res = await moderateVoteWordCloudAnswer({
				voteId: vote?.id,
				value: answer.value,
				userId: answer.userId,
				isDeleted: answer.isDeleted
			});
		}
		setIsLoading({ ...isLoading, [answer.id]: false });
		return res?.id !== undefined || res;
	};

	const _renderContent = () => {
		if (vote) {
			switch (vote.votingType) {
				case 'text':
					return <VoteModerationText vote={vote} answers={answers} isLoading={isLoading} updateVoteAnswer={_updateVoteAnswer} />;
				case 'wordCloud':
					return <VoteModerationWordCloud answers={answers} isLoading={isLoading} updateVoteAnswer={_updateVoteAnswer} />;
				default:
					return null;
			}
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="votings">
			<View style={{ flex: 1, paddingTop: hsTopScreenPadding }}>{_renderContent()}</View>
		</ScreenContainer>
	);
};

export const VoteModerationScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Voting moderation')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
