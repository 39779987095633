import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { Text } from 'components/Text';

interface IVoteQuizAnalytics {
	answers: IVoteAnswer[];
}

interface IAnswerWithPoints {
	answerText: string;
	pointsGained: number;
}

interface IUserWithAnswersAndPoints {
	userId: string;
	points: number;
	answers: IAnswerWithPoints[];
}

const topAndBottomPerformerAmount = 5;

export const VoteQuizAnalytics = (props: IVoteQuizAnalytics) => {
	const { answers } = props;

	const [topPerformer, setTopPerformer] = useState<IUserWithAnswersAndPoints[]>([]);
	const [bottomPerformer, setBottomPerformer] = useState<IUserWithAnswersAndPoints[]>([]);

	useEffect(() => {
		if (answers?.length > 0) {
			const _usersWithAnswersAndPoints: IUserWithAnswersAndPoints[] = [];
			answers.forEach((answer) => {
				if (answer.value && answer.userId) {
					const value = JSON.parse(answer.value);
					_usersWithAnswersAndPoints.push({
						userId: answer.userId,
						points: Number(value.score),
						answers: value.answers
					});
				}
			});

			_usersWithAnswersAndPoints.sort((a, b) => a?.points ?? 0 > b?.points ?? 0);

			const _topPerformer: IUserWithAnswersAndPoints[] = _usersWithAnswersAndPoints.slice(0, topAndBottomPerformerAmount);
			const _bottomPerformer = _usersWithAnswersAndPoints.slice(
				Math.max(_usersWithAnswersAndPoints.length - topAndBottomPerformerAmount, 0)
			);
			setTopPerformer(_topPerformer);
			setBottomPerformer(_bottomPerformer);
		}
	}, [answers]);

	if (answers?.length > 0) {
		return (
			<View>
				<Text>{topPerformer?.toString()}</Text>
				<Text>{bottomPerformer?.toString()}</Text>
			</View>
		);
	}
	return null;
};
