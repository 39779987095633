import React, { ReactNode, useEffect, useState } from 'react';
import { ILauncherSpace, IRegistrationField, ISpaceRegistrationFormErrors, ISpaceRegistrationFormValues } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { useForm } from 'hooks/useForm';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { Markdown } from 'components/Markdown';
import { getTopRadiusStyle, isEmptyString } from 'helper';
import { View } from 'react-native';
interface ISpaceRegistrationForm {
	values: ISpaceRegistrationFormValues;
	errors: ISpaceRegistrationFormErrors;
	onChange: (val: ISpaceRegistrationFormValues) => void;
	intro?: string;
	space?: ILauncherSpace;
	fields?: IRegistrationField[];
	registrationIntro?: string;
	registerButton?: ReactNode;
	hideTitle?: boolean;
	noRoundedTop?: boolean;
	isModal?: boolean;
}

const TESTIDPREFIX = 'registrationform';

export const SpaceRegistrationForm = (props: ISpaceRegistrationForm) => {
	const { values, errors, onChange, space, fields, registrationIntro, registerButton, hideTitle, noRoundedTop, isModal } = props;
	const { getRegistrationFields, getRegistrationSections } = useRegistration();
	const { renderFormField } = useForm();
	const { activeSpace } = useSpace();

	const [formFields, setFormFields] = useState<IRegistrationField[]>([]);

	useEffect(() => {
		setFormFields(fields?.filter((e) => e.visibility === 'always' || e.visibility === 'registrationOnly') ?? getRegistrationFields());
	}, [fields, activeSpace]);

	const _renderRegistrationIntro = () => {
		if (hideTitle) {
			return null;
		}
		const elements: ReactNode[] = [];

		if (!isEmptyString(registrationIntro) || !isEmptyString(activeSpace?.registrationIntro)) {
			elements.push(<Markdown markdown={registrationIntro ?? activeSpace?.registrationIntro} />);
		}

		return <View>{elements}</View>;
	};

	const sections = getRegistrationSections(formFields);

	return (
		<View style={{ flex: 1 }}>
			{sections.map((section, sectionIdx) => (
				<HSCard
					key={`${TESTIDPREFIX}_section_${sectionIdx}`}
					style={noRoundedTop ? { ...getTopRadiusStyle(0), borderTopWidth: 0 } : {}}
				>
					{sectionIdx === 0 && _renderRegistrationIntro()}
					{section.fields.map((field) =>
						renderFormField(
							'spaceregistration',
							TESTIDPREFIX,
							values,
							errors,
							field,
							onChange,
							undefined,
							space,
							undefined,
							undefined,
							isModal
						)
					)}
					{sections.length - 1 === sectionIdx && registerButton}
				</HSCard>
			))}
		</View>
	);
};
