import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { FlatList, Keyboard, ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsTopScreenPadding } from 'config/styleConstants';

import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { MeetingTableForm } from 'components/Forms';
import { IEditMeetingTableFormErrors, IEditMeetingTableFormValues, IMeeting, IMeetingTable } from 'config/interfaces';
import i18next from 'i18next';
import { HSCard } from 'components/Card';
import { H2, H3 } from 'components/Text';
import moment from 'moment';
import { MeetingListItem } from 'components/Meeting';
import { ChildButton, RoundButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingTableEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingTableEdit>;
type RouteParams = StackParamList[ERoutes.MeetingTableEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'meetingtableedit';

interface IMeetingData {
	header: string;
	items: IMeeting[];
}

export const MeetingTableEditScreen = ({ route, navigation }: Props) => {
	const { theme } = useTheme();
	const { t } = useTranslation();

	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('meetingtable');
	const { getFormSchema } = useForm();
	const { exportMeetingsAsCSV } = useExport();

	const [formValues, setFormValues] = useState<IEditMeetingTableFormValues>({});
	const [formErrors, setFormErrors] = useState<IEditMeetingTableFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadMeetingsLoading, setIsLoadMeetingsLoading] = useState<boolean>(false);
	const [acceptedMeetings, setAcceptedMeetings] = useState<IMeetingData[]>([]);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const updateMeetingTable = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMeetingTable);
	const loadMeetings = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadMeetings);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (content.meetingtables && route.params.id) {
			const values = content.meetingtables.find((e) => e.id === route.params.id);
			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
				setFormValues({
					...values
				});
			}
		}
	}, [content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMeetingTable(),
			isLoading
		});
	}, [formValues, isLoading, isFormLocked]);

	const _applyFormValues = (values: IMeetingTable) => {
		setFormValues({
			...values
		});
		setIsFormLocked(false);
	};

	const _loadMeetings = async () => {
		setIsLoadMeetingsLoading(true);
		const res = await loadMeetings({
			meetingTableIds: [route.params.id]
		});
		if (res?.length === 1) {
			setAcceptedMeetings(res[0].sections);
		}
		setIsLoadMeetingsLoading(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _updateMeetingTable = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = content.meetingtables.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await updateMeetingTable({ meetingtable: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	const _renderMeetings = () => {
		return (
			<View style={{ flex: 1 }}>
				<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
					<H2 noBottomMargin>{`Meetings (${acceptedMeetings.length})`}</H2>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_reload`}
						style={{ marginLeft: 5 }}
						isLoading={isLoadMeetingsLoading}
						onPress={_loadMeetings}
					>
						<Icon name={EDefaultIconSet.Reload} />
					</ChildButton>
				</View>

				{acceptedMeetings.map((day) => {
					return (
						<View key={day.header} style={{ marginBottom: 10 }}>
							<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
								<H3 center style={{ alignSelf: 'center' }}>
									{moment(day.header).format('DD.MM.YYYY')}
								</H3>
							</View>
							<FlatList
								data={day.items}
								keyExtractor={(item) => `${day.header}_meeting_${item.id}`}
								renderItem={({ item }) => <MeetingListItem item={item} />}
								ItemSeparatorComponent={() => (
									<View style={{ width: '95%', height: 1, marginBottom: 10, backgroundColor: theme.lightgray }} />
								)}
							/>
						</View>
					);
				})}
				<RoundButton
					testID="export"
					icon={EDefaultIconSet.Export}
					onPress={() =>
						exportMeetingsAsCSV([
							{
								id: route.params.id,
								title: formValues.title ?? '',
								subtitle: formValues.subtitle ?? '',
								days: acceptedMeetings
							}
						])
					}
					size="sm"
					title="Export"
					alignSelf="flex-end"
					isDisabled={isLoadMeetingsLoading || acceptedMeetings.length === 0}
				/>
			</View>
		);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="meetingtables">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MeetingTableForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
				<HSCard>{_renderMeetings()}</HSCard>
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MeetingTableEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Meeting Tables')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
