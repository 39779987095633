import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import {
	NavigationHeader,
	NavigationHeaderTitle,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton
} from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { MailTemplateWizard } from 'components/Forms';
import { EDefaultIconSet, isEmptyString, validateForm } from 'helper';
import { Keyboard, ScrollView } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { getEditMailTemplateFormInitialValues, IEditMailTemplateFormErrors, IEditMailTemplateFormValues } from 'config/interfaces';
import { getEditMailTemplateFormSchema } from 'config/yupSchemas';
import {
	confirmAccountEmailLinkPlaceholder,
	passwordResetEmailLinkPlaceholder,
	_getAccountConfirmationEmail,
	_getPasswordResetEmail,
	_getNewAdministratorEmail,
	_getNewModeratorEmail,
	_getExpoSelfServiceInvitation,
	_getSpeakerSelfServiceInvitation,
	selfServiceInvitationEmailLinkPlaceholder,
	_getHelloSpacesTicketInvitation,
	helloSpacesTicketInvitationEmailPlaceholder,
	_getInviteUnregisteredAdmin,
	_getDefaultHelloSpacesInvitation
} from 'config/mailing';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditMailTemplate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditMailTemplate>;
type RouteParams = StackParamList[ERoutes.EditMailTemplate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editmailtemplate';

export const EditMailTemplateScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [tempValue, setTempValue] = useState<IEditMailTemplateFormValues>({ ...getEditMailTemplateFormInitialValues() });
	const [errors, setErrors] = useState<IEditMailTemplateFormErrors>({});
	const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const createMailTemplate = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMailTemplate);
	const updateMailTemplate = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMailTemplate);

	useEffect(() => {
		let template;

		if (route.params.id && content.mailtemplates && activeSpace?.spaceId) {
			template = content.mailtemplates.find((e) => e.id === route.params.id && e.spaceId === activeSpace.spaceId);
		} else {
			switch (route.params.key) {
				case 'passwordRecovery':
					template = _getPasswordResetEmail();
					break;
				case 'confirmAccount':
					template = _getAccountConfirmationEmail();
					break;
				case 'newAdmin':
					template = _getNewAdministratorEmail();
					break;
				case 'newModerator':
					template = _getNewModeratorEmail();
					break;
				case 'expoSelfServiceInvitation':
					template = _getExpoSelfServiceInvitation();
					break;
				case 'speakerSelfServiceInvitation':
					template = _getSpeakerSelfServiceInvitation();
					break;
				case 'helloSpacesTicketInvitation':
					template = _getHelloSpacesTicketInvitation();
					break;
				case 'newUnregisteredAdmin':
					template = _getInviteUnregisteredAdmin();
					break;
				case 'defaultHelloSpacesInvitation':
					template = _getDefaultHelloSpacesInvitation(activeSpace?.ticketSettings?.ticketRequired);
					break;
				default:
					break;
			}
		}
		if (template) {
			setTempValue({ ...template });
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMailTemplate(),
			isLoading: isSaveLoading,
			isRightNavPressDisabled: _isRightNavPressDisabled()
		});
	}, [tempValue, isSaveLoading]);

	const _isRightNavPressDisabled = () => {
		return (
			!tempValue.contentType || isEmptyString(tempValue.title) || isEmptyString(tempValue.subject) || isEmptyString(tempValue.body)
		);
	};

	const _updateMailTemplate = async () => {
		let errors = await validateForm(getEditMailTemplateFormSchema(tempValue), tempValue);
		switch (tempValue.key) {
			case 'passwordRecovery':
				if (!tempValue.body.includes(passwordResetEmailLinkPlaceholder) && !tempValue.body.includes('%PLACEHOLDER%')) {
					if (!errors) {
						errors = {};
					}
					errors['body'] = t('EmailLinkPlaceholderMissing').replace('%PLACEHOLDER%', passwordResetEmailLinkPlaceholder);
				}
				break;
			case 'confirmAccount':
				if (!tempValue.body.includes(confirmAccountEmailLinkPlaceholder)) {
					if (!errors) {
						errors = {};
					}
					errors['body'] = t('EmailLinkPlaceholderMissing').replace('%PLACEHOLDER%', confirmAccountEmailLinkPlaceholder);
				}
				break;
			case 'expoSelfServiceInvitation':
			case 'speakerSelfServiceInvitation':
				if (!tempValue.body.includes(selfServiceInvitationEmailLinkPlaceholder)) {
					if (!errors) {
						errors = {};
					}
					errors['body'] = t('EmailLinkPlaceholderMissing').replace('%PLACEHOLDER%', selfServiceInvitationEmailLinkPlaceholder);
				}
				break;
			case 'helloSpacesTicketInvitation':
				if (!tempValue.body.includes(helloSpacesTicketInvitationEmailPlaceholder)) {
					if (!errors) {
						errors = {};
					}
					errors['body'] = t('EmailLinkPlaceholderMissing').replace('%PLACEHOLDER%', helloSpacesTicketInvitationEmailPlaceholder);
				}
				break;
			default:
				break;
		}
		if (errors) {
			setErrors(errors);
			return;
		} else {
			setErrors({});
		}

		setIsSaveLoading(true);
		let res;

		if (tempValue.id) {
			res = await updateMailTemplate({ mailTemplate: tempValue });
		} else {
			res = await createMailTemplate({ mailTemplate: tempValue });
		}
		if (res) {
			setIsSaveLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsSaveLoading(false);
		}
	};

	const _updateFormValues = (val: IEditMailTemplateFormValues) => {
		navigation.setParams({ prohibitNavigation: true });
		setTempValue(val);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="mailtemplates">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MailTemplateWizard
					values={tempValue}
					errors={errors}
					onChange={_updateFormValues}
					isLoading={isSaveLoading}
					onSubmit={_updateMailTemplate}
				/>
			</ScrollView>
		</ScreenContainer>
	);
};

export const EditMailTemplateScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Edit')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
