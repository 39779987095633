import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form/FormTextInput';
import { Text } from 'components/Text';
import { ISetAttendeePasswordFormValues, ISpaceResetPasswordFormErrors } from 'config/interfaces';
import { hsBottomMargin } from 'config/styleConstants';
import { getSpaceResetPasswordFormSchema } from 'config/yupSchemas';
import { EDefaultIconSet, isEmptyString, validateForm } from 'helper';
import { isEmptyObject } from 'helper/object';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HSModal } from '../Modal';

interface ISetAttendeePasswordModal {
	isVisible: boolean;
	onClose: () => void;
	onPress: () => void;
	onChange: (formValues: ISetAttendeePasswordFormValues) => void;
	isLoading: boolean;
	values?: ISetAttendeePasswordFormValues;
}

const TESTIDPREFIX = 'setpasswordmodal';

export const SetAttendeePasswordModal = (props: ISetAttendeePasswordModal) => {
	const { isVisible, onClose, onPress, onChange, isLoading, values } = props;

	const { t } = useTranslation();
	const { theme } = useTheme();

	const [formErrors, setFormErrors] = useState<ISpaceResetPasswordFormErrors>({});

	useEffect(() => {
		if (!isEmptyObject(formErrors)) {
			setFormErrors({});
		}
	}, [values]);

	const validate = async () => {
		if (values) {
			const errors = await validateForm(
				getSpaceResetPasswordFormSchema({
					...values,
					code: '',
					email: ''
				}),
				values
			);
			if (errors) {
				setFormErrors(errors);
				return;
			} else {
				onPress();
				setFormErrors({});
			}
		}
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title={t('setPassword')}>
			<Text style={{ marginBottom: hsBottomMargin }}>{t('setNewPasswordModalText')}</Text>
			<FormTextInput
				key={'newPassword'}
				testID={`${TESTIDPREFIX}_textinput_newpassword`}
				label={t('setNewPasswordLabel')}
				hint={t('setNewPasswordHint')}
				isRequired
				value={values?.password}
				error={formErrors.password}
				onChangeText={(value) => onChange({ passwordConfirmation: values?.passwordConfirmation ?? '', password: value })}
				secureTextEntry
				isDisabled={isLoading}
			/>
			<FormTextInput
				key={'confirmPassword'}
				testID={`${TESTIDPREFIX}_textinput_confirmpassword`}
				label={t('setConfirmPasswordLabel')}
				hint={t('setConfirmPasswordHint')}
				isRequired
				value={values?.passwordConfirmation}
				error={formErrors.passwordConfirmation}
				onChangeText={(value) => onChange({ password: values?.password ?? '', passwordConfirmation: value })}
				secureTextEntry
				isDisabled={isLoading}
			/>
			<RoundButton
				isOutline
				isLoading={isLoading}
				color={theme.danger}
				testID={`${TESTIDPREFIX}_button_setpassword`}
				icon={EDefaultIconSet.Save}
				onPress={() => validate()}
				title={t('setPasswordNow')}
				alignSelf="center"
				isDisabled={!isEmptyObject(formErrors) || isEmptyString(values?.password) || isEmptyString(values?.passwordConfirmation)}
			/>
		</HSModal>
	);
};
