import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBottomMargin } from 'config/styleConstants';
import { useHeaderHeight } from '@react-navigation/elements';
import React from 'react';
import { Dimensions, Vibration, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HSCard, HSDragCard } from 'components/Card';
import { H1, ItemTitle, WelcomeHint } from 'components/Text';
import { IS_ANDROID, IS_WEB, swapArrayItems } from 'helper';
import { FormCheckbox } from 'components/Form';
import { FormHint } from 'components/Form/FormHint';
import { IFeatureInfo, ILauncherSpace } from 'config/interfaces';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { useQuery } from 'hooks/useQuery';
import { TPossibleContentType } from './SpaceWIzardContent';

interface ISpaceWizardFeatures {
	testID: string;
	isLoading?: boolean;
	features?: ILauncherSpace['features'];
	onChange: (val: ILauncherSpace['features']) => void;
}

export const possibleFeatures = [
	{
		key: 'feed'
	},
	{
		key: 'schedules'
	},
	{
		key: 'speakers'
	},
	{
		key: 'votings'
	},
	{
		key: 'expos'
	},
	{
		key: 'attendees'
	},
	{
		key: 'chats'
	},
	{
		key: 'meetings'
	},
	{
		key: 'networking'
	},
	{
		key: 'mediaitems'
	},
	{
		key: 'maps'
	}
];

export const MAXTABCOUNT = 5;

export const SpaceWizardFeatures = (props: ISpaceWizardFeatures) => {
	const { testID, isLoading, features, onChange } = props;
	const { t } = useTranslation();
	const headerHeight = useHeaderHeight();
	const buttonHeight = 55;
	const screenHeight = Dimensions.get('window').height;
	const { screenWidth } = useQuery();

	const _renderSelectionButton = (type: TPossibleContentType | string, isDisabled) => {
		return (
			<FormCheckbox
				style={{ marginBottom: 0, marginLeft: 10 }}
				isDisabled={isDisabled || isLoading}
				testID={`${testID}_checkbox_${type}`}
				onPress={() => {
					let _active: ILauncherSpace['features'] = features ? { ...features } : { tabbarCount: MAXTABCOUNT, list: [] };
					const found = _active.list.find((e) => e.key === type);
					if (found) {
						if (found.isActive && found.key === 'attendees') {
							const attendeeDependent = _active.list.filter(
								(e) => e.key === 'chats' || e.key === 'meetings' || e.key === 'networking'
							);
							attendeeDependent?.map((d) => {
								d.isActive = false;
							});
						}
						found.isActive = !found.isActive;
					} else {
						_active.list.push({
							key: type,
							isActive: true
						});
					}
					possibleFeatures.forEach((feature) => {
						const found = _active?.list.find((e) => e.key === feature.key);
						if (!found) {
							_active?.list.push({
								key: feature.key,
								isActive: false
							});
						}
					});
					onChange(_active);
				}}
				value={features?.list?.find((e) => e.key === type)?.isActive}
			/>
		);
	};

	const _getHint = (type: TPossibleContentType | string, isDisabled) => {
		switch (type) {
			case 'chats':
				if (isDisabled) {
					return t('DisabledInfoAttendeeFeatures').replace('%FEATURE%', t(type));
				}
				return t('chatFeatureHint');
			case 'meetings':
				if (isDisabled) {
					return t('DisabledInfoAttendeeFeatures').replace('%FEATURE%', t(type));
				}
				return t('meetingsFeatureHint');
			case 'networking':
				if (isDisabled) {
					return t('DisabledInfoAttendeeFeatures').replace('%FEATURE%', t(type));
				}
				return t('networkingFeatureHint');
			case 'expos':
				return t('exposFeatureHint');
			case 'feed':
				return t('feedFeatureHint');
			case 'schedules':
				return t('schedulesFeatureHint');
			case 'speakers':
				return t('speakersFeatureHint');
			case 'votings':
				return t('votingsFeatureHint');
			case 'attendees':
				return t('attendeesFeatureHint');
			case 'mediaitems':
				return t('mediaItemsFeatureHint');
			case 'maps':
				return t('mapsFeatureHint');
			default:
				return '';
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!features?.list) {
			return;
		}
		const list: IFeatureInfo[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, features?.list);
		const newOrder: ILauncherSpace['features'] = { list: [] };
		newOrder.list = list;
		onChange(newOrder);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!features?.list) {
			return;
		}

		const list = [...features.list];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ list });
	};

	const _renderItem = (params) => {
		const { item, index, drag, isActive } = params;

		let isDisabled = false;

		switch (item.key) {
			case 'chats':
			case 'meetings':
			case 'networking':
				isDisabled = !features || !features.list || !features.list.find((e) => e.key === 'attendees')?.isActive;
				break;
			default:
				break;
		}

		return (
			<HSDragCard
				testID={`${testID}_option_${index}`}
				isActive={isActive || isDisabled}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				isDisabled={isDisabled}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (features?.list?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<View style={{ flex: 1, alignItems: 'flex-start', marginLeft: EHorizontalScreenPadding.Wide }}>
						<ItemTitle text={t(item.key)} hasNoMargin />
						<FormHint testID={`${item.key}_hint`} hint={_getHint(item.key, isDisabled)} />
					</View>

					{_renderSelectionButton(item.key, isDisabled || isActive)}
				</View>
			</HSDragCard>
		);
	};

	return (
		<DraggableFlatList
			testID={`${testID}_spacewizardfeatures_scrollview`}
			data={features?.list ?? possibleFeatures}
			style={{ height: IS_WEB ? screenHeight - headerHeight - buttonHeight : '100%' }}
			contentContainerStyle={{
				paddingHorizontal: EHorizontalScreenPadding.Wide,
				paddingTop: hsBottomMargin,
				width: screenWidth,
				alignSelf: 'center'
			}}
			renderItem={(params) => _renderItem(params)}
			keyExtractor={(item, index) => `${testID}_filpicker_file_${index}`}
			ListHeaderComponent={
				<HSCard
					style={{
						marginBottom: hsBottomMargin
					}}
				>
					<H1>{t('selectFeaturesHeader')}</H1>
					<WelcomeHint>{IS_WEB ? t('selectFeaturesHintWeb') : t('selectFeaturesHintMobile')}</WelcomeHint>
				</HSCard>
			}
			onDragBegin={() => {
				if (IS_ANDROID) {
					Vibration.vibrate(10, false);
				}
			}}
			onDragEnd={({ data }) => {
				const newOrder: ILauncherSpace['features'] = { list: [] };
				data.forEach((entry) => {
					const found = features?.list.find((e) => e.key === entry.key);
					if (found) {
						newOrder.list.push(found);
					} else {
						newOrder.list.push({
							key: entry.key,
							isActive: false
						});
					}
				});

				onChange(newOrder);
			}}
		/>
	);
};
