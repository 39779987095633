import { hsBorderRadius } from 'config/styleConstants';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useRef } from 'react';
import { Animated, ViewStyle } from 'react-native';
import { Text } from 'components/Text';

interface IAnimatedLive {
	isVisible?: boolean;
	onLayout?: (neededWidth: number) => void;
	containerStyle: ViewStyle;
}

const LOWVALUE = 0.3;
const HIGHVALUE = 1;
const ANIMATIONDURATION = 600;
const ANIMATIONINITIALDELAY = 2500;

export const AnimatedLive = (props: IAnimatedLive) => {
	const { isVisible, onLayout, containerStyle } = props;
	const { theme } = useTheme();

	const animatedOpacityValue = useRef(new Animated.Value(1));

	useEffect(() => {
		if (process.env.IS_TEST) {
			return;
		}
		// Animated.loop(
		// 	Animated.sequence([
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: LOWVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			delay: ANIMATIONINITIALDELAY,
		// 			useNativeDriver: true
		// 		}),
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: HIGHVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			useNativeDriver: true
		// 		}),
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: LOWVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			useNativeDriver: true
		// 		}),
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: HIGHVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			useNativeDriver: true
		// 		}),
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: LOWVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			useNativeDriver: true
		// 		}),
		// 		Animated.timing(animatedOpacityValue.current, {
		// 			toValue: HIGHVALUE,
		// 			duration: ANIMATIONDURATION,
		// 			useNativeDriver: true
		// 		})
		// 	])
		// ).start();
	}, []);

	if (isVisible) {
		return (
			<Animated.View
				style={[
					{
						position: 'absolute',
						opacity: animatedOpacityValue.current,
						backgroundColor: theme.danger,
						paddingHorizontal: 6,
						paddingVertical: 2,
						borderRadius: hsBorderRadius,
						alignSelf: 'flex-start'
					},
					containerStyle
				]}
				onLayout={(e) => {
					if (onLayout) {
						onLayout(e.nativeEvent.layout.width + 5);
					}
				}}
			>
				<Text style={{ fontSize: 12, color: theme.primaryContrast }}>Live</Text>
			</Animated.View>
		);
	}

	return null;
};
