import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form';
import { FlashSectionList } from 'components/List';
import { Spinner } from 'components/Spinner';
import { IContentFlowMediaEntry, IInternalSettings } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, IS_WEB, openURL } from 'helper';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { ContentflowListEntry, CONTENTFLOW_LIST_ENTRY_HEIGHT } from './ContentflowListEntry';
import { ContentflowListHeader, CONTENTFLOWLISTHEADERHEIGHT } from './ContentFlowListHeader';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';

type TContentFlowType = 'all' | 'stream' | 'video';

interface IContentFlowList {
	testID: string;
	type: TContentFlowType;
	selectedUrls?: string[];
	onSelect?: (url: IContentFlowMediaEntry) => void;
}

interface IContentFlowData {
	header: string;
	items: IContentFlowMediaEntry[];
}

export const ContentFlowList = (props: IContentFlowList) => {
	const { testID, type, selectedUrls, onSelect } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [settings, setSettings] = useState<IInternalSettings | undefined>(undefined);
	const [isInternalSettingsLoading, setIsInternalSettingsLoading] = useState<boolean>(false);
	const [isUpdateInternalSettingsLoading, setIsUpdateInternalSettingsLoading] = useState<boolean>(false);

	const [isContentFlowMediaLoading, setIsContentFlowMediaLoading] = useState<boolean>(false);
	const [contentFlowMedia, setContentFlowMedia] = useState<(string | IContentFlowMediaEntry)[]>([]);

	const [apiKey, setApiKey] = useState<string>('');
	const [corporationId, setCorporationId] = useState<string>('');

	const getInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.getInternalSettings);
	const setInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.setInternalSettings);
	const getContentflowMedia = useRematchDispatch((dispatch: Dispatch) => dispatch.content.getContentflowMedia);
	const getContentflowStreams = useRematchDispatch((dispatch: Dispatch) => dispatch.content.getContentflowStreams);
	const getContentflowVideos = useRematchDispatch((dispatch: Dispatch) => dispatch.content.getContentflowVideos);

	useEffect(() => {
		_loadInternalSettings();
	}, []);

	useEffect(() => {
		_getContentFlowMedia();
	}, [settings]);

	const _loadInternalSettings = async () => {
		setIsInternalSettingsLoading(true);
		const res = await getInternalSettings({});
		setSettings(res);
		setIsInternalSettingsLoading(false);
	};

	const _setInternalSettings = async () => {
		setIsUpdateInternalSettingsLoading(true);
		const res = await setInternalSettings({ settings: { contentflowApiKey: apiKey, contentflowCorporationId: corporationId } });
		setSettings(res);
		setIsUpdateInternalSettingsLoading(false);
	};

	const _getContentFlowMedia = async () => {
		if (settings?.contentflowApiKey && settings?.contentflowCorporationId) {
			setIsContentFlowMediaLoading(true);

			const _sections: IContentFlowData[] = [];
			let newSections: typeof contentFlowMedia = [];

			switch (type) {
				case 'stream':
					const streamRes = await getContentflowStreams({
						contentflowApiKey: settings?.contentflowApiKey,
						contentflowCorporationId: settings?.contentflowCorporationId
					});
					if (streamRes.length) {
						_sections.push({
							header: 'Streams',
							items: streamRes
						});
					}
					break;
				case 'video':
					const videoRes = await getContentflowVideos({
						contentflowApiKey: settings?.contentflowApiKey,
						contentflowCorporationId: settings?.contentflowCorporationId
					});
					if (videoRes.length) {
						_sections.push({
							header: 'Videos',
							items: videoRes
						});
					}
					break;
				case 'all':
				default:
					const res = await getContentflowMedia({
						contentflowApiKey: settings?.contentflowApiKey,
						contentflowCorporationId: settings?.contentflowCorporationId
					});
					if (res.streams.length > 0) {
						_sections.push({ header: 'Streams', items: res.streams });
					}
					if (res.videos.length > 0) {
						_sections.push({
							header: 'Videos',
							items: res.videos
						});
					}

					break;
			}

			_sections.forEach((section) => {
				newSections.push(section.header);
				newSections = newSections.concat(section.items);
			});
			if (newSections.length > 0) {
				newSections = ['', ...newSections];
			}
			setContentFlowMedia(newSections);
			setIsContentFlowMediaLoading(false);
		}
	};

	const _renderContentFlowStatus = () => {
		if (isInternalSettingsLoading) {
			return <Spinner />;
		}

		if (!settings) {
			// This should only happen if backend throws any error
			return <Text>Could not get settings</Text>;
		}

		if (!settings.contentflowApiKey || !settings.contentflowCorporationId) {
			return (
				<View>
					<Text style={{ color: theme.danger, marginBottom: 10 }}>Contentflow API Key not configured</Text>
					<FormTextInput
						testID={`${testID}_textinput_contentflowapikey`}
						label="Contentflow API Key"
						hint={t('apiKeyHint')}
						isRequired
						value={apiKey}
						onChangeText={(key) => setApiKey(key)}
						formStyle={{ marginBottom: 10 }}
						secureTextEntry
					/>
					<FormTextInput
						testID={`${testID}_textinput_contentflowcorporationid`}
						label="Contentflow Corporation Id"
						hint={t('apiKeyHint')}
						isRequired
						value={corporationId}
						onChangeText={(key) => setCorporationId(key)}
						formStyle={{ marginBottom: 10 }}
					/>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<RoundButton
							title={t('buyContentflow')}
							testID={`${testID}_button_ordercontentflow`}
							icon={EDefaultIconSet.Contentflow}
							onPress={() => openURL('https://contentflow.de/hellospaces/')}
							isLoading={isUpdateInternalSettingsLoading}
						/>
						<RoundButton
							testID={`${testID}_button_savesettings`}
							icon={EDefaultIconSet.Save}
							onPress={_setInternalSettings}
							isLoading={isUpdateInternalSettingsLoading}
							isDisabled={isEmptyString(apiKey) || isEmptyString(corporationId)}
						/>
					</View>
				</View>
			);
		}

		return (
			<View>
				<Text style={{ color: theme.success, marginBottom: 10 }}>Contentflow API Key configured</Text>
				<RoundButton
					testID={`${testID}_button_editsettings`}
					icon={EDefaultIconSet.Edit}
					title={t('Swap Key')}
					isOutline
					onPress={() => {
						const _settings = { ...settings };
						delete _settings.contentflowApiKey;
						delete _settings.contentflowCorporationId;
						setSettings(_settings);
					}}
					alignSelf="flex-end"
				/>
			</View>
		);
	};

	const _renderList = () => {
		if (isContentFlowMediaLoading) {
			return <Spinner />;
		}

		return (
			<View style={{ flex: 1 }}>
				<FlashSectionList
					data={contentFlowMedia}
					renderItem={(renderItem) => (
						<ContentflowListEntry
							testID={`${testID}_button_contentflow_${renderItem.index}`}
							item={renderItem.item}
							onSelect={onSelect ? () => onSelect(renderItem.item) : undefined}
							isSelected={selectedUrls?.includes(renderItem.item.url)}
						/>
					)}
					renderSectionHeader={(renderItem) => <ContentflowListHeader text={renderItem.item} />}
					estimatedItemSize={CONTENTFLOW_LIST_ENTRY_HEIGHT}
					hideIndexButtons
				/>
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderContentFlowStatus()}
			{_renderList()}
		</View>
	);
};
