import { openURL } from 'helper/url';
import { isDarkColor } from 'helper/color';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { NavigationHeaderImageButton } from './NavigationHeaderImageButton';

const HelloSpaces = require('../../../../assets/hellospaces.png');
const HelloSpacesWhite = require('../../../../assets/hellospacesWhite.png');

interface INavigationHeaderHelloSpacesComponent {
	onPress?: Function;
}

export const NavigationHeaderHelloSpaces = (props: INavigationHeaderHelloSpacesComponent) => {
	const { onPress } = props;
	const { theme } = useTheme();

	return (
		<NavigationHeaderImageButton
			testID="header_button_hellospaces"
			onPress={() => {
				if (onPress) {
					onPress();
				}
				openURL('https://hellospaces.de/?pk_campaign=app_navbar');
			}}
			url={isDarkColor(theme.background) ? HelloSpacesWhite : HelloSpaces}
			fallbackIcon=""
			imageStyle={{ height: 22, width: 153 }}
		/>
	);
};
