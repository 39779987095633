import React, { ReactNode, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ScrollView, View, ViewProps } from 'react-native';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderBackButton, NavigationHeaderTitle } from 'components/Navigation/Header';

import { H1, Text } from 'components/Text';

import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { EDefaultIconSet, normalizeFontSize } from 'helper';
import { Button, ChildButton, RoundButton } from 'components/Button';
import { FormTextInput, FormCheckbox, FormColorPicker } from 'components/Form';
import { VoteAdminButton } from 'components/Vote/AdminVote';
import { Avatar } from 'components/User';
import { useQuery } from 'hooks/useQuery';
import { hsTopScreenPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.Tutorial>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.Tutorial>;
type RouteParams = NoSpaceStackParamList[ERoutes.Tutorial];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface IComponentContainer extends ViewProps {
	testID: string;
	children?: ReactNode | ReactNode[];
	title?: string;
}

const ComponentContainer = (props: IComponentContainer) => {
	const { children, title } = props;

	return (
		<View style={{ marginBottom: 40 }}>
			<H1 center style={{ fontWeight: 'bold', color: 'blue' }}>
				{title}
			</H1>
			{children}
		</View>
	);
};

export const ComponentsScreen = ({ route, navigation }: Props) => {
	const { theme } = useTheme();
	const { screenWidth } = useQuery();

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [color, setColor] = useState<string>('');

	const _renderAvatar = () => {
		if (profile) {
			return (
				<View style={{ flexDirection: 'row' }}>
					<Avatar
						avatar={profile?.avatar?.formats?.small?.url ?? profile.avatar?.url}
						fullName={`${profile.firstName} ${profile.lastName}`}
					/>
					<Avatar fullName={`Hello Spaces`} />
				</View>
			);
		}
		return <Avatar fullName={`Hello Spaces`} />;
	};

	return (
		<ScreenContainer>
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{/* <ComponentContainer title="TypoGraphy" testID={''}>
					<WelcomeHeading>WelcomeHeading</WelcomeHeading>
					<WelcomeHint>WelcomeHint</WelcomeHint>
					<Title>Title: Title</Title>
					<H1>H1: Header 1</H1>
					<H2>H2: Header 2</H2>
					<H3>H3: Header 3</H3>
					<Text>Text: Text</Text>
					<Hint>Hint: Hint</Hint>
					<SectionHeader label={'Section Header'} action={{ label: 'action label', testID: 'componentscreen_button_action' }} />
				</ComponentContainer>

				<ComponentContainer title="Avatar" testID={''}>
					{_renderAvatar()}
				</ComponentContainer> */}

				<ComponentContainer title="RoundButton" testID={''}>
					<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon Only
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Floating
							</Text>
							<RoundButton isFloatingButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Centered
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isStacked />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon Loading
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} isLoading />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Loading
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isLoading />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Loading Floating
							</Text>
							<RoundButton isFloatingButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isLoading />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Loading Centered
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isLoading isStacked />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon Outline
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} isOutline />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Outline
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isOutline />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Outline Centered
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isOutline isStacked />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon Outline Loading
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isOutline isLoading />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Outline Loading Centered
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isOutline isLoading isStacked />
						</View>

						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon Disabled
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} isDisabled />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon isOutline Disabled
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} isDisabled isOutline />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Disabled
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isDisabled />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Disabled Centered
							</Text>
							<RoundButton testID="" icon={EDefaultIconSet.CreateSpace} title="Add" isDisabled isStacked />
						</View>
						<View style={{ width: '50%' }}>
							<Text bold numberOfLines={1} adjustsFontSizeToFit>
								Icon & Text Outline Disabled Centered
							</Text>
							<RoundButton
								testID=""
								icon={EDefaultIconSet.CreateSpace}
								title="Add"
								isDisabled
								isOutline
								isStacked
								badge={12}
							/>
						</View>
					</View>
				</ComponentContainer>

				<ComponentContainer title="Buttons" testID={''}>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'FullWidthButton'}
						onPress={() => {}}
						style={{ marginBottom: 5 }}
						fullWidth
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'FullWidthButton'}
						onPress={() => {}}
						isDisabled
						style={{ marginBottom: 5 }}
						fullWidth
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'FullWidthButton'}
						onPress={() => {}}
						isLoading
						style={{ marginBottom: 5 }}
						fullWidth
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'Outline'}
						onPress={() => {}}
						isOutline={true}
						style={{ marginBottom: 5 }}
						fullWidth
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'Outline disabled'}
						onPress={() => {}}
						isOutline
						isDisabled
						style={{ marginBottom: 5 }}
						fullWidth
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'Outline success'}
						onPress={() => {}}
						isOutline={true}
						style={{ marginBottom: 5 }}
						fullWidth
						type={'success'}
					/>
					<Button
						testID={`$FullWidthButton_button_submit`}
						title={'Outline success disabled'}
						onPress={() => {}}
						isOutline
						isDisabled
						style={{ marginBottom: 5 }}
						fullWidth
						type={'success'}
					/>
					<Button testID="spaceselect_button_normal" onPress={() => {}} title={'Button'} style={{ marginBottom: 5 }} />
					<Button testID="spaceselect_button_normal" onPress={() => {}} title={'Button'} style={{ marginBottom: 5 }} fullWidth />
					<Button
						testID="spaceselect_button_disabled"
						onPress={() => {}}
						isDisabled
						title={'Disabled Button'}
						style={{ marginBottom: 5 }}
					/>
					<Button
						testID="spaceselect_button_loading"
						onPress={() => {}}
						isLoading
						title={'Loading Button'}
						style={{ marginBottom: 5 }}
					/>
					<ChildButton
						testID={`$ChildButton_button_submit`}
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: 5
						}}
						onPress={() => {}}
					>
						<Text>ChildButton Without Styling</Text>
					</ChildButton>
					<ChildButton
						testID={`$ChildButton_button_submit`}
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: theme.primary,
							flexDirection: 'row',
							marginBottom: 5
						}}
						onPress={() => {}}
					>
						<Icon size={normalizeFontSize(24)} name={'home'} />
						<Text style={{ marginLeft: 8 }}>Primary ChildButton mit Icon</Text>
					</ChildButton>

					<VoteAdminButton testID="voteAdminButton" icon="star" label="VoteAdminButton" containerStyle={{ marginBottom: 5 }} />
				</ComponentContainer>

				<ComponentContainer title="Form Elements" testID={''}>
					<FormTextInput testID={`$textinput_passwordconfirmation`} label={'FormTextInput'} isRequired onChangeText={() => {}} />
					<FormCheckbox
						testID={`_checkbox_hellospacesNewsletter`}
						label="Sign up for our HelloSpaces newsletter with latest events and feautures that fits to your profile. With a longer Text"
						onPress={() => {}}
					/>
					<FormCheckbox
						testID={`_checkbox_hellospacesNewsletter`}
						label="Sign up for our HelloSpaces newsletter with latest events and feautures that fits to your profile. With a longer Text"
						onPress={() => {}}
						value={true}
					/>
					<FormColorPicker
						testID={`componentscreen_colorpicker`}
						onChange={(value) => setColor(value ?? '')}
						value={color}
						label={'color'}
						hint={'color hint'}
						error={'color error'}
						description={'color description'}
					/>
				</ComponentContainer>
				<ComponentContainer title="Used Icons" testID={''}>
					<HSCard style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
						{Object.keys(EDefaultIconSet).map((e) => {
							return (
								<View key={e} style={{ width: '33%', paddingHorizontal: 5, alignItems: 'center', marginBottom: 20 }}>
									<Icon name={EDefaultIconSet[e]} size={40} />
									<Text numberOfLines={2} bold adjustsFontSizeToFit center style={{ marginTop: 5 }}>
										{e}
									</Text>
									<Text numberOfLines={2} adjustsFontSizeToFit center style={{ marginTop: 5 }}>
										{EDefaultIconSet[e]}
									</Text>
								</View>
							);
						})}
					</HSCard>
				</ComponentContainer>
			</ScrollView>
		</ScreenContainer>
	);
};

export const ComponentsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title="Components" />
		</NavigationHeader>
	);
};
