import { IMedia } from 'config/interfaces';
import { isEmptyString, IS_ANDROID, IS_WEB } from 'helper';
import React from 'react';
import { Image as EImage, ImageProps } from 'expo-image';

const placeholder = require('../../assets/images/defaultPlaceholder/defaultPlaceholderGray.png');

interface IImageComponent extends Omit<ImageProps, 'source'> {
	url?: string | number;
	mediaObj?: IMedia;
	imageSize?: 'thumbnail' | 'small' | 'medium' | 'large' | 'full';
}

export const Image = (props: IImageComponent) => {
	const { url, testID, mediaObj, imageSize, contentFit } = props;

	const getImageUrl = () => {
		let url: string | undefined = undefined;

		switch (imageSize) {
			case 'thumbnail':
				if (IS_WEB) {
					url = mediaObj?.formats?.medium?.url ?? mediaObj?.formats?.large?.url;
				} else {
					url = mediaObj?.formats?.thumbnail?.url ?? mediaObj?.formats?.small?.url;
				}
				break;
			case 'medium':
				if (IS_WEB) {
					url = mediaObj?.formats?.large?.url ?? mediaObj?.url;
				} else {
					url = mediaObj?.formats?.medium?.url ?? mediaObj?.formats?.large?.url;
				}
				break;
			case 'large':
				url = mediaObj?.formats?.large?.url ?? mediaObj?.url;
				break;
			case 'full':
				url = mediaObj?.url;
				break;
			case 'small':
			default:
				if (IS_WEB) {
					url = mediaObj?.formats?.medium?.url ?? mediaObj?.formats?.large?.url;
				} else {
					url = mediaObj?.formats?.small?.url ?? mediaObj?.formats?.medium?.url;
				}
				break;
		}

		if (isEmptyString(url)) {
			if (typeof mediaObj === 'number') {
				url = mediaObj;
			} else if (typeof mediaObj === 'string') {
				url = mediaObj;
			} else {
				url = mediaObj?.url;
			}
		}

		return url;
	};

	const _url = getImageUrl() ?? url ?? placeholder;

	if (!_url) {
		return null;
	}

	if (IS_ANDROID && typeof props?.style?.width !== 'string' && props?.style?.width < 2) {
		return null;
	}

	return (
		<>
			<EImage
				testID={testID}
				{...props}
				// key={IS_ANDROID ? v4() : _url} // this key is needed for android -> blurry images
				key={_url} // this key is needed for android -> blurry images
				onLoad={(e) => {
					if (props.onLoad) {
						props.onLoad(e);
					}
				}}
				cachePolicy="memory-disk"
				contentFit={contentFit ?? 'contain'}
				style={props.style}
				source={_url}
			/>
		</>
	);
};
