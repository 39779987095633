import { RoundButton } from 'components/Button';
import React, { useEffect, useRef, useState } from 'react';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, getTopRadiusStyle, isEmptyString, IS_WEB, showFormErrorToast, translateStrapiError, validateForm } from 'helper';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { getSpaceForgotPasswordFormSchema, getSpaceLoginFormSchema } from 'config/yupSchemas';
import { TAuthState } from 'screens';
import { Keyboard, ScrollView, View } from 'react-native';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';

import {
	ILauncherSpace,
	ISpaceEmailOrTicketcodeFormErrors,
	ISpaceEmailOrTicketcodeFormValues,
	ISpaceForgotPasswordFormErrors,
	ISpaceForgotPasswordFormValues,
	ISpaceLoginFormErrors,
	ISpaceLoginFormValues,
	ISpaceRegistrationFormErrors,
	ISpaceRegistrationFormValues,
	ISpaceTicketFormValues,
	SpaceForgotPasswordFormInitialValues,
	SpaceLoginFormInitialValues,
	SpaceRegistrationFormInitialValues
} from 'config/interfaces';
import { HSHeaderCard } from 'components/Card/HSHeaderCard';
import { UserConfirmationView } from 'components/Confirmation';
import { Hint } from 'components/Text';
import { SpaceForgotPasswordForm, SpaceLoginForm, SpaceRegistrationForm } from 'components/Forms/SpaceAuthentication';
import { Markdown } from 'components/Markdown';
import { SupportRequestModal } from 'components/Modal/SupportRequestModal';
import { hsBottomMargin } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { QRCodeScannerModal } from '../QRCode';
import { showToast } from 'helper/toast';
import { SpaceEmailOrTicketcodeForm } from 'components/Forms/SpaceAuthentication/SpaceEmailOrTicketcode';
import { FormTextInput } from 'components/Form';

interface IAuthenticationModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: () => void;
	space?: ILauncherSpace;
	routeParams?: Record<string, any>;
	isJoinSpaceLoading?: boolean;
}

const TESTIDPREFIX = 'authenticationmodal';

export const AuthenticationModal = (props: IAuthenticationModal) => {
	const { isVisible, onClose, onSubmit, space, routeParams, isJoinSpaceLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { getRegistrationFields, getInitialFormValues, isRegisterSubmitDisabled } = useRegistration();
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();
	const { isTabletOrMobile } = useQuery();

	const scrollRef = useRef<ScrollView>(null);

	const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState<boolean>(false);
	const [forgotPasswordFormValues, setForgotPasswordFormValues] = useState<ISpaceForgotPasswordFormValues>({
		...SpaceForgotPasswordFormInitialValues
	});
	const [forgotPasswordFormErrors, setForgotPasswordFormErrors] = useState<ISpaceForgotPasswordFormErrors>({});

	const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
	const [loginFormValues, setLoginFormValues] = useState<ISpaceLoginFormValues>({ ...SpaceLoginFormInitialValues });
	const [loginFormErrors, setLoginFormErrors] = useState<ISpaceLoginFormErrors>({});

	const [ticketFormValues, setTicketFormValues] = useState<ISpaceTicketFormValues>({ ticketcode: '' });
	const [isTicketcheckLoading, setIsTicketCheckLoading] = useState<boolean>(false);

	const [isRegistrationLoading, setIsRegistrationLoading] = useState<boolean>(false);
	const [registrationFormValues, setRegistrationFormValues] = useState<ISpaceRegistrationFormValues>({
		...getInitialFormValues()
	});
	const [registrationFormErrors, setRegistrationFormErrors] = useState<ISpaceRegistrationFormErrors>({});

	const [isSupportRequestModalVisible, setIsSupportRequestModalVisible] = useState<boolean>(false);
	const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const forgotPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.forgotPassword);
	const loginWithEmailAndPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.loginWithEmailAndPassword);
	const registerWithEmailAndPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.registerWithEmailAndPassword);
	const fetchTicket = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.fetchTicket);

	// new
	const [authState, setAuthState] = useState<TAuthState>('emailOrTicket');
	const [emailOrTicketFormValues, setEmailOrTicketFormValues] = useState<ISpaceEmailOrTicketcodeFormValues>({ emailOrTicket: '' });
	const [emailOrTicketFormErrors, setEmailOrTicketFormErrors] = useState<ISpaceEmailOrTicketcodeFormErrors>({});
	const [isHelpExpanded, setIsHelpExpanded] = useState<boolean>(false);
	const [isEmailOrTicketLoading, setIsEmailOrTicketLoading] = useState<boolean>(false);
	const [useTicket, setUseTicket] = useState<boolean>(false);
	const [showTicketAvailableHint, setShowTicketAvailableHint] = useState<boolean>(false);

	const checkIfUserExists = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.checkIfUserExists);
	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);

	useEffect(() => {
		if (!isVisible) {
			setForgotPasswordFormValues({ ...SpaceForgotPasswordFormInitialValues });
			setForgotPasswordFormErrors({});
			setLoginFormValues({ ...SpaceLoginFormInitialValues });
			setLoginFormErrors({});
			setRegistrationFormValues({ ...SpaceRegistrationFormInitialValues });
			setRegistrationFormErrors({});
			setTicketFormValues({ ticketcode: '' });
			setAuthState('emailOrTicket');
		} else {
			if (routeParams?.ticketcode && routeParams.ticketcode !== '_') {
				setEmailOrTicketFormValues({ emailOrTicket: decodeURIComponent(routeParams.ticketcode) });
			}
			setRegistrationFormValues({ ...getInitialFormValues(space?.registrationFields?.fields) });
		}
	}, [isVisible, activeSpace, space]);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			switch (authState) {
				case 'login':
					_login();
					break;
				case 'register':
				case 'registerAfterTicket':
					_register();
					break;
				case 'emailOrTicket':
					_checkTicketCodeOrEmail();
					break;
				case 'ticket':
					_fetchTicket();
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		if (isVisible) {
			if (IS_WEB) {
				document.addEventListener('keydown', _handleEnter, false);
			}
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, [authState, loginFormValues, registrationFormValues, forgotPasswordFormValues, forgotPasswordFormValues]);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollTo({ y: 0, animated: true });
		}
	}, [authState]);

	const _fetchTicket = async (ticketcode?: string) => {
		setShowTicketAvailableHint(false);
		if (space) {
			setIsTicketCheckLoading(true);
			const res = await fetchTicket({ space, ticketcode: ticketcode ?? ticketFormValues.ticketcode });
			switch (res?.error) {
				case undefined:
				case 'noTicketForUser':
					showToast('error', t('Error'), t('auth.form.error.ticketcode.notfound'));
					break;
				case 'ticketExistsForMailButNoTicketcode':
					showToast('info', t('Ticket available'), t('auth.form.error.ticketcode.existsforuser'));
					setShowTicketAvailableHint(true);
					break;
				case 'ticketInUse':
					showAlert({
						title: t('auth.form.error.ticketcode.alreadyinuse'),
						message: t('LoginNow?'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('Login'),
								onPress: () => setAuthState('login')
							}
						]
					});
					break;
				default:
					// Ticket fetch success
					setRegistrationFormValues({ ...getInitialFormValues(space?.registrationFields?.fields, res) });
					setAuthState('registerAfterTicket');
					break;
			}

			setIsTicketCheckLoading(false);
		}
	};

	const _isLoginDisabled = () => {
		return isEmptyString(loginFormValues.email) || isEmptyString(loginFormValues.password);
	};

	const _isForgotPasswordDisabled = () => {
		return isEmptyString(forgotPasswordFormValues.email);
	};

	const _isFormDisabled = () => {
		switch (authState) {
			case 'ticket':
				return isEmptyString(ticketFormValues.ticketcode);
			case 'forgotpw':
				return _isForgotPasswordDisabled();
			case 'login':
				return _isLoginDisabled();
			case 'registerAfterTicket':
			case 'register':
				return isRegisterSubmitDisabled(registrationFormValues);
			case 'emailOrTicket':
				return isEmptyString(emailOrTicketFormValues.emailOrTicket);
			default:
				return false;
		}
	};

	const _forgotPassword = async () => {
		const errors = await validateForm(getSpaceForgotPasswordFormSchema(forgotPasswordFormValues), forgotPasswordFormValues);
		if (errors) {
			setForgotPasswordFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setForgotPasswordFormErrors({});
		}

		setIsForgotPasswordLoading(true);
		const res = await forgotPassword({ ...forgotPasswordFormValues, spaceId: space?.spaceId });
		setIsForgotPasswordLoading(false);

		setLoginFormValues({ ...loginFormValues, email: forgotPasswordFormValues.email });
		setAuthState('login');

		showToast(
			'success',
			t('ForgotPWSuccessTitle'),
			t('ForgotPWSuccessSubitle').replace('%EMAIL%', forgotPasswordFormValues.email as string)
		);
	};

	const _login = async () => {
		const errors = await validateForm(getSpaceLoginFormSchema(loginFormValues), loginFormValues);
		if (errors) {
			setLoginFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setLoginFormErrors({});
		}

		setIsLoginLoading(true);
		const res = await loginWithEmailAndPassword(loginFormValues);
		if (res?.userId) {
			if (useTicket) {
				await _redeemTicketCode(emailOrTicketFormValues.emailOrTicket, res);
			}
			trackAction('space', 'Login', undefined, undefined, space);
			if (!activeSpace?.forceDoubleOptInForUsers || res.isUserConfirmed) {
				onSubmit();
			}
			setIsLoginLoading(false);
		} else {
			showAlert({ title: t('Login failed'), message: translateStrapiError(res) });
		}

		setIsLoginLoading(false);
	};

	const _redeemTicketCode = async (ticketcode, user) => {
		if (space && user) {
			const res = await fetchTicket({ space, ticketcode, redeem: true });
			if (!res?.error) {
				const tickets = user.tickets ? { ...user.tickets } : {};
				tickets[space.spaceId] = res;
				const updateSuccess = await updateUser({ user: { ...user, tickets } });
			}
		}
	};

	const _register = async () => {
		const errors = await validateForm(
			getFormSchema(space?.registrationFields?.fields ?? getRegistrationFields(), registrationFormValues),
			registrationFormValues
		);
		if (errors) {
			setRegistrationFormErrors(errors);
			showFormErrorToast(errors, getRegistrationFields());
			return;
		} else {
			setRegistrationFormErrors({});
		}

		let _sendConfirmationMail = space?.forceDoubleOptInForUsers ?? false;

		setIsRegistrationLoading(true);
		const res = await registerWithEmailAndPassword({
			user: {
				...registrationFormValues,
				userId: userInfos.userId
			},
			fields: getRegistrationFields(),
			ticketcode: !isEmptyString(ticketFormValues.ticketcode)
				? ticketFormValues.ticketcode
				: useTicket
				? emailOrTicketFormValues.emailOrTicket
				: undefined,
			space: space ?? activeSpace,
			handleErrorLocally: true,
			shouldSendConfirmationEmail: _sendConfirmationMail
		});
		setIsRegistrationLoading(false);

		if (!res) {
			showToast('error', 'Error', t('Unknown Error'));
			return;
		}

		let code = '';
		if (res.message && typeof res.message === 'string') {
			code = res.message;
		} else if (Array.isArray(res.message) && res.message[0] && res.message[0].messages && res.message[0].messages[0]) {
			code = res.message[0].messages[0].id;
		}
		if (!isEmptyString(code)) {
			if ((code = 'Auth.form.error.email.taken')) {
				showAlert({
					title: t('accountAlreadyInUse'),
					message: t('accountAlreadyInUseSubtitle'),
					buttons: [
						{
							text: t('Ok'),
							style: 'default'
						},
						{
							text: t('login'),
							style: 'default',
							onPress: async () => {
								setAuthState('login');
							}
						}
					]
				});
			} else {
				showAlert({ title: 'Error', message: translateStrapiError(res) });
			}
		} else {
			trackAction('space', 'Register', undefined, undefined, space);

			if (res.shouldSendConfirmationEmail) {
				showToast(
					'custom',
					t('RegistrationConfirmation'),
					t('RegistrationConfirmationHint').replace('%EMAIL%', registrationFormValues.email)
				);
			}

			if (!activeSpace?.forceDoubleOptInForUsers) {
				if (useTicket) {
					await _redeemTicketCode(emailOrTicketFormValues.emailOrTicket, res);
				}
				onSubmit();
			}
		}
	};

	const _renderTicketIntroText = () => {
		if (!isEmptyString(space?.ticketSettings?.ticketIntro)) {
			return <Markdown markdown={space?.ticketSettings?.ticketIntro} />;
		}

		switch (space?.ticketSettings?.ticketProvider) {
			case 'doo':
				return <Markdown markdown={t('DooTicketIntroPlaceholder')} />;
			case 'xingevents':
				return <Markdown markdown={t('XINGTicketIntroPlaceholder')} />;
			case 'emendo':
				return <Markdown markdown={t('EmendoTicketIntroPlaceholder')} />;
			case 'pretix':
				return <Markdown markdown={t('PretixTicketIntroPlaceholder')} />;
			case 'hellospaces':
			default:
				return <Markdown markdown={t('HelloSpacesTicketIntroPlaceholder')} />;
		}
	};

	const _checkTicketCodeOrEmail = async () => {
		setShowTicketAvailableHint(false);
		if (space) {
			setIsEmailOrTicketLoading(true);
			const emailOrticketcode = emailOrTicketFormValues;
			let _cleanedValue = emailOrticketcode?.emailOrTicket?.toLowerCase().trim();
			let userExists;

			if (space.ticketSettings?.ticketRequired) {
				if (space?.ticketSettings?.ticketProvider === 'xingevents') {
					const hyphenIndexes = [4, 9];
					if (hyphenIndexes.includes(_cleanedValue.length) && ticketFormValues.ticketcode.length < _cleanedValue.length) {
						_cleanedValue += '-';
					}
				}
				userExists = await checkIfUserExists({ email: _cleanedValue, spaceId: space.spaceId });
				if (userExists.exist && userExists.alreadyIn) {
					setLoginFormValues({ ...loginFormValues, email: _cleanedValue });
					setAuthState('login');
					setUseTicket(true);
					setIsEmailOrTicketLoading(false);
					return;
				}

				const res = await fetchTicket({ space, ticketcode: _cleanedValue });

				switch (res?.error) {
					case 'ticketInUse':
						setLoginFormValues({ ...loginFormValues, email: res.email });
						setAuthState('login');
						setIsEmailOrTicketLoading(false);

						return;
					default:
						// Ticket fetch success
						setRegistrationFormValues({ ...getInitialFormValues(space?.registrationFields?.fields, res) });
						setAuthState('registerAfterTicket');
						setUseTicket(true);

						setIsEmailOrTicketLoading(false);

						return;
				}
			}
			userExists = await checkIfUserExists({ email: _cleanedValue, spaceId: space.spaceId });
			if (userExists.exist) {
				setAuthState('login');
				setLoginFormValues({ ...loginFormValues, email: emailOrticketcode.emailOrTicket });
			} else {
				setAuthState('register');
				setRegistrationFormValues({ ...registrationFormValues, email: emailOrticketcode.emailOrTicket });
			}
		}
		setIsEmailOrTicketLoading(false);
	};

	const _renderButtons = () => {
		let _forgotPwButton;

		switch (authState) {
			case 'emailOrTicket':
			case 'login':
				_forgotPwButton = (
					<RoundButton
						isOutline
						testID="authentication_button_forgotpw"
						title={t('ForgotPw?')}
						onPress={() => setAuthState('forgotpw')}
						icon={EDefaultIconSet.ResetPassword}
						textColor={theme.text}
						isDisabled={isLoginLoading || isJoinSpaceLoading}
					/>
				);
		}

		let _helpButton = (
			<RoundButton
				isOutline
				testID="authentication_button_togglehelp"
				title={t('Help')}
				onPress={() => setIsHelpExpanded(!isHelpExpanded)}
				icon={EDefaultIconSet.Help}
				textColor={theme.text}
				isDisabled={isLoginLoading || isJoinSpaceLoading}
			/>
		);

		return (
			<View
				style={{
					marginTop: 10,
					paddingTop: 20,
					borderTopWidth: 1,
					borderTopColor: theme.lightgray,
					flexDirection: isTabletOrMobile ? 'column' : 'row',
					flexWrap: 'wrap',
					justifyContent: 'space-between'
				}}
			>
				{_forgotPwButton}
				<RoundButton
					isOutline
					testID="authentication_button_supportrequest"
					title={t('sideBarSupportRequest')}
					onPress={() => setIsSupportRequestModalVisible(true)}
					icon={EDefaultIconSet.Support}
					textColor={theme.text}
					isDisabled={isLoginLoading || isRegistrationLoading || isJoinSpaceLoading}
				/>
				{_helpButton}
			</View>
		);
	};

	const _renderForm = () => {
		if (profile) {
			let _space = space ?? activeSpace;
			if (_space?.forceDoubleOptInForUsers && !profile?.isUserConfirmed) {
				return <UserConfirmationView isModal />;
			}
		}

		switch (authState) {
			case 'emailOrTicket':
				const ticketRequired = space?.ticketSettings?.ticketRequired;
				return (
					<View style={{ flex: 1 }}>
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<HSHeaderCard
								noShadow
								title={ticketRequired ? t('emailOrTicketTitle') : t('emailTitle')}
								subtitle={ticketRequired ? t('emailOrTicketSubtitle') : t('emailSubtitle')}
							/>
							{space?.ticketSettings?.ticketRequired && (
								<View style={{ marginBottom: hsBottomMargin }}>{_renderTicketIntroText()}</View>
							)}
							<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
								<SpaceEmailOrTicketcodeForm
									ticketRequired={ticketRequired}
									values={emailOrTicketFormValues}
									errors={emailOrTicketFormErrors}
									onChange={(val) => setEmailOrTicketFormValues(val)}
									isLoading={isForgotPasswordLoading}
									loginIntro={space?.loginIntro}
								/>
								{!IS_WEB && ticketRequired && (
									<View style={{ marginBottom: 10, paddingLeft: 10 }}>
										<RoundButton
											isOutline
											testID="spaceselect_button_qrcodescanner"
											isDisabled={isTicketcheckLoading}
											onPress={() => setIsQRCodeModalVisible(!isQRCodeModalVisible)}
											icon={EDefaultIconSet.QRCode}
										/>
									</View>
								)}
							</View>
							{showTicketAvailableHint && (
								<Hint style={{ marginBottom: hsBottomMargin, color: theme.warning }}>
									{t('auth.form.error.ticketcode.existsforuser')}
								</Hint>
							)}

							<RoundButton
								isFloatingButton
								testID="authentication_button_emailorticketcode"
								onPress={() => _checkTicketCodeOrEmail()}
								title={ticketRequired ? t('CheckEmailOrTicketcode') : t('checkEmail')}
								icon={EDefaultIconSet.Save}
								textColor={theme.text}
								isLoading={isEmailOrTicketLoading}
								alignSelf="center"
							/>
							{_renderButtons()}
							{isHelpExpanded && (
								<Markdown
									markdown={!isEmptyString(space?.authHelpText) ? space?.authHelpText : t('SpaceAuthHelpMarkdown')}
								/>
							)}
						</HSCard>
						<QRCodeScannerModal
							isVisible={isQRCodeModalVisible}
							onResult={(result) => {
								setTicketFormValues({ ...ticketFormValues, ticketcode: result.toLowerCase() });
								_fetchTicket(result.toLowerCase());
								setIsQRCodeModalVisible(false);
							}}
							onClose={() => setIsQRCodeModalVisible(false)}
						/>
					</View>
				);
			case 'forgotpw':
				return (
					<View style={{ flex: 1 }}>
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<HSHeaderCard noShadow title={t('forgotPasswordTitle')} subtitle={t('forgotPasswordSubtitle')} />

							<SpaceForgotPasswordForm
								values={forgotPasswordFormValues}
								errors={forgotPasswordFormErrors}
								onChange={(val) => setForgotPasswordFormValues(val)}
								isLoading={isForgotPasswordLoading}
							/>
							<RoundButton
								isFloatingButton
								testID="authentication_button_login"
								onPress={() => _forgotPassword()}
								title={t('requestPassword')}
								icon={EDefaultIconSet.Save}
								textColor={theme.text}
								isLoading={isForgotPasswordLoading}
								alignSelf="center"
							/>
							{_renderButtons()}
						</HSCard>
					</View>
				);
			case 'login':
				return (
					<View style={{ flex: 1 }}>
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<HSHeaderCard noShadow title={t('loginTitle')} subtitle={t('loginSubtitle')} />
							<SpaceLoginForm
								values={loginFormValues}
								errors={loginFormErrors}
								onChange={(val) => setLoginFormValues(val)}
								loginIntro={space?.loginIntro}
							/>
							<RoundButton
								testID="authentication_button_login"
								onPress={() => _login()}
								title={t('Login')}
								icon={EDefaultIconSet.Save}
								textColor={theme.text}
								isLoading={isLoginLoading || isJoinSpaceLoading}
								alignSelf="center"
								isFloatingButton
							/>
							{_renderButtons()}
						</HSCard>
					</View>
				);
			case 'registerAfterTicket':
				return (
					<View style={{ flex: 1 }}>
						<HSHeaderCard noShadow title={t('registerAfterTicketTitle')} subtitle={t('registerAfterTicketSubtitle')} />
						<SpaceRegistrationForm
							noRoundedTop
							values={registrationFormValues}
							errors={registrationFormErrors}
							onChange={(val) => setRegistrationFormValues(val)}
							isModal
							space={space}
							fields={space?.registrationFields?.fields}
							registrationIntro={space?.registrationIntro}
							registerButton={
								<View>
									<RoundButton
										isFloatingButton
										testID="authentication_button_register"
										onPress={() => _register()}
										title={t('Register')}
										icon={EDefaultIconSet.Save}
										textColor={theme.text}
										isLoading={isRegistrationLoading || isJoinSpaceLoading}
										alignSelf="center"
									/>
									{_renderButtons()}
								</View>
							}
						/>
					</View>
				);
			case 'register':
				return (
					<View style={{ flex: 1 }}>
						<HSHeaderCard noShadow title={t('registerTitle')} subtitle={t('registerSubtitleModal')} />
						<SpaceRegistrationForm
							noRoundedTop
							isModal
							values={registrationFormValues}
							errors={registrationFormErrors}
							onChange={(val) => setRegistrationFormValues(val)}
							space={space}
							fields={space?.registrationFields?.fields}
							registrationIntro={space?.registrationIntro}
							registerButton={
								<View>
									<RoundButton
										isFloatingButton
										testID="authentication_button_register"
										onPress={() => _register()}
										title={t('Register')}
										icon={EDefaultIconSet.Save}
										textColor={theme.text}
										isLoading={isRegistrationLoading || isJoinSpaceLoading}
										alignSelf="center"
									/>
									{_renderButtons()}
								</View>
							}
						/>
					</View>
				);
			case 'ticket':
				return (
					<View style={{ flex: 1 }}>
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							{_renderTicketIntroText()}
							<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
								<FormTextInput
									formStyle={{ marginBottom: hsBottomMargin, flex: 1 }}
									testID={`${TESTIDPREFIX}_formtextinput_ticketcode`}
									label={t('Ticketcode')}
									isRequired
									value={ticketFormValues.ticketcode}
									hint={
										space?.ticketSettings?.ticketProvider === 'xingevents'
											? t('TicketCodeHintXingEvents')
											: t('TicketCodeHint')
									}
									onChangeText={(ticketcode) => {
										let _value = ticketcode?.toLowerCase().trim();

										if (space?.ticketSettings?.ticketProvider === 'xingevents') {
											const hyphenIndexes = [4, 9];
											if (
												hyphenIndexes.includes(_value.length) &&
												ticketFormValues.ticketcode.length < _value.length
											) {
												_value += '-';
											}
										}
										setTicketFormValues({ ...ticketFormValues, ticketcode: _value });
									}}
									autoCapitalize="none"
									autoCompleteType="off"
									autoCorrect={false}
									keyboardType="email-address"
								/>
								{!IS_WEB && (
									<View style={{ marginBottom: 5, paddingLeft: 10 }}>
										<RoundButton
											isOutline
											testID="spaceselect_button_qrcodescanner"
											isDisabled={isTicketcheckLoading}
											onPress={() => setIsQRCodeModalVisible(!isQRCodeModalVisible)}
											icon={EDefaultIconSet.QRCode}
										/>
									</View>
								)}
							</View>
							{_renderButtons()}
						</HSCard>
						<QRCodeScannerModal
							isVisible={isQRCodeModalVisible}
							onResult={(result) => {
								setTicketFormValues({ ...ticketFormValues, ticketcode: result.toLowerCase() });
								_fetchTicket(result.toLowerCase());
								setIsQRCodeModalVisible(false);
							}}
							onClose={() => setIsQRCodeModalVisible(false)}
						/>
					</View>
				);
			default:
				return null;
		}
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={() => {
				onClose();
			}}
			isSubmitLoading={isRegistrationLoading || isLoginLoading || isForgotPasswordLoading || isEmailOrTicketLoading}
			isSubmitDisabled={_isFormDisabled()}
			headerImage={space ? space?.logoImage : activeSpace?.logoImage}
		>
			<ScrollView
				ref={scrollRef}
				keyboardShouldPersistTaps="handled"
				testID={`${TESTIDPREFIX}_scrollview`}
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				style={{ width: '100%', alignSelf: 'center' }}
				contentContainerStyle={{ width: '100%', alignSelf: 'center' }}
			>
				{_renderForm()}
			</ScrollView>
			<SupportRequestModal
				space={space}
				isVisible={isSupportRequestModalVisible}
				onClose={() => setIsSupportRequestModalVisible(false)}
			/>
		</HSModal>
	);
};
