import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useDetail } from 'hooks/useDetail';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { Keyboard, ScrollView, View } from 'react-native';

import { hsTopScreenPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { getDefaultStreamFields } from 'config/defaultFields/defaultStreamFields';
import { IContentTypeField, IMediaItem, ISchedule, IStream } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Icon } from 'components/Icon';
import { getIconForStreamType, getURLForStream, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { StreamPlayer } from 'components/Stream';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StreamDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StreamDetails>;
type RouteParams = StackParamList[ERoutes.StreamDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'streamdetails';

export const StreamDetailsScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { renderReferenceField } = useDetail();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [scheduleReferenceField, setScheduleReferenceField] = useState<IContentTypeField | undefined>(undefined);
	const [schedules, setSchedules] = useState<ISchedule[]>([]);
	const [stream, setStream] = useState<IStream | undefined>(undefined);
	const [streamMediaItem, setStreamMediaItem] = useState<IMediaItem | undefined>(undefined);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		const fields = getDefaultStreamFields();
		const _referenceField = fields.find((e) => e.fieldName === 'schedules');

		setScheduleReferenceField(_referenceField);
	}, [activeSpace]);

	useEffect(() => {
		let _stream: typeof stream = undefined;
		let _mediaItem: typeof streamMediaItem = undefined;
		let _schedules: typeof schedules = [];

		if (activeSpace && content.streams) {
			_stream = content.streams.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);

			_stream?.schedules?.forEach((sc) => {
				const scItem = content.schedules?.find((e) => e.spaceId === activeSpace.spaceId && e.id === sc.id);
				if (scItem) {
					_schedules.push(scItem);
				}
			});
		}

		navigation.setOptions({
			title: _stream?.title ?? ''
		});

		if (activeSpace && _stream?.mediaitem && content.mediaitems) {
			_mediaItem = content.mediaitems.find((e) => e.spaceId === activeSpace.spaceId && e.id === _stream?.mediaitem?.id);
		}

		setStream(_stream);
		setStreamMediaItem(_mediaItem);
		setSchedules(_schedules);
	}, [activeSpace, content]);

	const _getStreamURL = () => {
		switch (stream?.streamType) {
			case 'contentflow':
				return undefined;
			case 'iFrame':
				return stream?.iFrameUrl;
			case 'mediaLibrary':
				if (streamMediaItem) {
					if (streamMediaItem.media?.url) {
						return streamMediaItem.media.url;
					}

					if (!isEmptyString(streamMediaItem.iFrameUrl)) {
						return streamMediaItem.iFrameUrl;
					}
				}
				return undefined;
			case 'upload':
				return stream?.media?.url;
			case 'vimeo':
				return stream?.vimeoUrl;
			case 'youtube':
				return stream?.youTubeUrl;
			default:
				return undefined;
		}
	};

	const _getStreamType = () => {
		switch (stream?.streamType) {
			case 'contentflow':
				return undefined;
			case 'mediaLibrary':
				if (streamMediaItem) {
					if (streamMediaItem.media?.url) {
						return 'upload';
					}
					if (!isEmptyString(streamMediaItem.iFrameUrl)) {
						return 'iFrame';
					}
				}
			default:
				return stream?.streamType;
		}
	};

	const _renderStream = () => {
		if (stream) {
			return (
				<HSCard style={{ flex: 1 }}>
					<StreamPlayer
						url={_getStreamURL()}
						type={_getStreamType()}
						previewImage={streamMediaItem?.previewImage?.url}
						containerStyle={{ marginBottom: 20 }}
						isMute={streamMediaItem?.isMute}
						isAutoplay={streamMediaItem?.isAutoplay}
						isLoop={streamMediaItem?.isLoop}
						onExternalClick={() => trackAction('stream', 'Open External URL', `${streamMediaItem?.id}`)}
					/>
					<View style={{ flexDirection: 'row', marginBottom: 20 }}>
						<Icon name={getIconForStreamType(stream.streamType)} size={30} />
						<View style={{ flex: 1, paddingLeft: 10 }}>
							<Text>{getURLForStream(stream)}</Text>
						</View>
					</View>
					{scheduleReferenceField && renderReferenceField(scheduleReferenceField, schedules, TESTIDPREFIX, activeSpace)}
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer contentKey="streams">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderStream()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const StreamDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />

			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
