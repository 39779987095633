import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import {
	IChangelog,
	IContentFlowMediaEntry,
	ICreateExpoPayload,
	ICreateInitialMailTemplates,
	ICreateMapPayload,
	ICreateMediaItemPayload,
	ICreateNetworkingRoomPayload,
	ICreateNewsPayload,
	ICreateStagePayload,
	ICreateStreamPayload,
	IExpo,
	ILoadPublicAgendaPayload,
	IMailHistory,
	IMailTemplate,
	IMap,
	IMapPosition,
	IMediaGalleryItem,
	IMediaItem,
	IMeetingTable,
	INetworkingRoom,
	INews,
	IRefillRedisPayload,
	ISpaceLastRead,
	IStage,
	IStream,
	IUpdateExpoPayload,
	IUpdateMailTemplatePayload,
	IUpdateMapPayload,
	IUpdateMediaItemPayload,
	IUpdateNetworkingRoomPayload,
	IUpdateNewsPayload,
	IUpdateScheduleStatusPayload,
	IUpdateStagePayload,
	IUpdateStreamPayload
} from 'config/interfaces';
import { ICreateSchedulePayload, ISchedule, IScheduleStatus, IUpdateSchedulePayload } from 'config/interfaces/schedule';
import { ICreateSpeakerPayload, ISpeaker, IUpdateSpeakerPayload } from 'config/interfaces/speaker';
import { IWebhook } from 'config/interfaces/webhook';
import {
	_getPasswordResetEmail,
	_getAccountConfirmationEmail,
	_getNewAdministratorEmail,
	_getNewModeratorEmail,
	_getExpoSelfServiceInvitation,
	_getSpeakerSelfServiceInvitation,
	_getHelloSpacesTicketInvitation,
	_getInviteUnregisteredAdmin,
	_getDefaultHelloSpacesInvitation
} from 'config/mailing';
import {
	getAccessRightsForSpace,
	getContentFlowIFrameURL,
	getContentFlowVideoUrl,
	isEmptyString,
	IS_WEB,
	translateStrapiError,
	isFeatureActive
} from 'helper';
import { showToast } from 'helper/toast';
import { TContentTypePlural } from 'hooks/useContent';
import i18next from 'i18next';
import moment from 'moment';
import {
	ICheckImportItemsPayload,
	ICreateMeetingTablePayload,
	ICreateWebhookPayload,
	IGetContentFlowPayload,
	IImportDataPayload,
	ILoadContentDelta,
	ILoadContentPayload,
	ILoadMeetingsPayload,
	IMultiDeletePayload,
	IMultiUpdatePayload,
	ISendMailPayload,
	ISendUserMailPayload,
	IUpdateMailSettingsPayload,
	IUpdateMeetingTablePayload,
	IUpdateWebhookPayload,
	TRestApiMethod
} from 'rematch/interfaces';
import { RootModel } from './index';

interface IContent {
	expos: IExpo[];
	mailhistories: IMailHistory[];
	mailtemplates: IMailTemplate[];
	maps: IMap[];
	mappositions: IMapPosition[];
	mediaitems: IMediaItem[];
	meetingtables: IMeetingTable[];
	newsitems: INews[];
	networkingrooms: INetworkingRoom[];
	schedules: ISchedule[];
	schedulestatuses: IScheduleStatus[];
	speakers: ISpeaker[];
	stages: IStage[];
	streams: IStream[];
}

interface INewContentType {
	type: TContentTypePlural;
	items: any[];
}

type IDefaultState = {
	content: IContent;
	lastContentRead: Record<string, ISpaceLastRead>;
	onlineUsers: any[];
	changelogs: IChangelog[];
	lastChangelogsRead: Record<string, string>;
	webhooks: IWebhook[];
	lastWebhooksRead: Record<string, string>;
};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const content = createModel<RootModel>()({
	state: {
		content: {
			expos: [],
			mailhistories: [],
			mailtemplates: [],
			maps: [],
			mappositions: [],
			mediaitems: [],
			meetingtables: [],
			newsitems: [],
			networkingrooms: [],
			schedules: [],
			schedulestatuses: [],
			speakers: [],
			stages: [],
			streams: []
		},
		webhooks: [],
		lastWebhooksRead: {},
		lastContentRead: {},
		onlineUsers: [],
		changelogs: [],
		lastChangelogsRead: {}
	} as IDefaultState,
	reducers: {
		addContent(state, { load, content }: { load: boolean; content: INewContentType[] }) {
			rematchLog('addContent');
			const _lastContentRead = state.lastContentRead ? { ...state.lastContentRead } : {};
			const _content: IContent = state.content
				? { ...state.content }
				: {
						expos: [],
						mailhistories: [],
						mailtemplates: [],
						maps: [],
						mappositions: [],
						mediaitems: [],
						meetingtables: [],
						newsitems: [],
						networkingrooms: [],
						schedules: [],
						schedulestatuses: [],
						speakers: [],
						stages: [],
						streams: []
				  };

			content.forEach((obj) => {
				obj.items.forEach((item) => {
					if (!_lastContentRead[item.spaceId]) {
						_lastContentRead[item.spaceId] = {};
					}
					if (!_lastContentRead[item.spaceId][obj.type]) {
						_lastContentRead[item.spaceId][obj.type] = ZEROHOUR;
					}
					if (!_content[obj.type]) {
						_content[obj.type] = [];
					}
					if (item?.id) {
						const idx = _content[obj.type].findIndex((e) => e.id === item.id);
						if (idx !== -1) {
							if (item.isDeleted || !item.published_at) {
								_content[obj.type].splice(idx, 1);
							} else {
								_content[obj.type][idx] = item;
							}
						} else if (!item.isDeleted && item.published_at) {
							_content[obj.type].push(item);
						}

						if (load && _lastContentRead[item.spaceId][obj.type] < item.updated_at) {
							_lastContentRead[item.spaceId][obj.type] = item.updated_at;
						}
					}
				});
			});

			return { ...state, content: _content, lastContentRead: _lastContentRead };
		},
		setOnlineUsers(state, onlineUsers) {
			rematchLog('setOnlineUsers');
			return {
				...state,
				onlineUsers
			};
		},
		addChangelogs(state, { load, changelogs }: { load: boolean; changelogs: IChangelog[] }) {
			rematchLog('addChangelogs');

			const _lastRead = state.lastChangelogsRead ? { ...state.lastChangelogsRead } : {};
			const _logs = state.changelogs ? [...state.changelogs] : [];

			changelogs.forEach((log) => {
				const idx = _logs.findIndex((e) => e.id === log.id);
				if (idx !== -1) {
					if (log.isDeleted) {
						_logs.splice(idx, 1);
					} else {
						_logs[idx] = log;
					}
				} else if (!log.isDeleted) {
					_logs.push(log);
				}
				if (load && _lastRead[log.spaceId] < log.updated_at) {
					_lastRead[log.spaceId] = log.updated_at;
				}
			});

			return {
				...state,
				changelogs: _logs,
				lastChangelogsRead: _lastRead
			};
		},
		addWebhooks(state, { load, webhooks }: { load: boolean; webhooks: IWebhook[] }) {
			rematchLog('addWebhooks');

			const _lastRead = state.lastWebhooksRead ? { ...state.lastWebhooksRead } : {};
			const _webhooks = state.webhooks ? [...state.webhooks] : [];

			webhooks.forEach((webhook) => {
				const idx = _webhooks.findIndex((e) => e.id === webhook.id);
				if (idx !== -1) {
					if (webhook.isDeleted) {
						_webhooks.splice(idx, 1);
					} else {
						_webhooks[idx] = webhook;
					}
				} else if (!webhook.isDeleted) {
					_webhooks.push(webhook);
				}
				if (load && _lastRead[webhook.spaceId] < webhook.updated_at) {
					_lastRead[webhook.spaceId] = webhook.updated_at;
				}
			});

			return {
				...state,
				webhooks: _webhooks,
				lastWebhooksRead: _lastRead
			};
		},
		clear(state) {
			rematchLog('clear content');
			return {
				content: {
					expos: [],
					mailhistories: [],
					mailtemplates: [],
					maps: [],
					mappositions: [],
					mediaitems: [],
					meetingtables: [],
					newsitems: [],
					networkingrooms: [],
					schedules: [],
					schedulestatuses: [],
					speakers: [],
					stages: [],
					streams: []
				},
				webhooks: [],
				lastWebhooksRead: {},
				lastContentRead: {},
				onlineUsers: [],
				changelogs: [],
				lastChangelogsRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async loadContent(payload: ILoadContentPayload, store) {
			const { force } = payload;
			try {
				dispatch.content.loadContentDelta({ startSync: true, force });
				dispatch.content.startOnlineStatusSync({});
				dispatch.networking.loadNetworkingRoomAttendees({});
				dispatch.booking.startMyBookingsSync({});
				dispatch.like.loadLikes({});
				dispatch.comment.loadComments({});
			} catch (error) {
				console.log('loadContent', error);
			}
		},
		async startContentSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (
					activeSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_content`)
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_content`, async (data) => {
						const result: INewContentType[] = [];
						let load = !Array.isArray(data) && data.load;
						let _items = Array.isArray(data) ? data : data.items;

						_items.forEach((item) => {
							const alreadyIn = result.find((e) => e.type === item.type);
							if (alreadyIn) {
								alreadyIn.items.push(item);
							} else {
								result.push({
									items: [item],
									type: item.type
								});
							}
						});

						if (load) {
							data.loadedTypes.forEach((type) => {
								contentLoadLog(4, `${type} received`);

								dispatch.temp.setWaitingForSocketResponse({ key: type, value: false });
								if (!store.temp.hasLoadedData || !store.temp.hasLoadedData[type]) {
									dispatch.temp.setHasLoadedData(type);
								}
							});
						}

						if (result.length > 0) {
							dispatch.content.addContent({ load, content: result });
						}
					});
				}
			} catch (error) {
				errorLog('startContentSync', error);
			}
		},
		async loadContentDelta(payload: ILoadContentDelta, store) {
			try {
				const { type } = payload;

				let typesToLoad: TContentTypePlural[] = [];

				switch (type) {
					case 'changelogs':
						typesToLoad = [
							'expos',
							'maps',
							'mappositions',
							'mediaitems',
							'meetingtables',
							'networkingrooms',
							'newsitems',
							'pushnotifications',
							'schedules',
							'schedulestatuses',
							'speakers',
							'stages',
							'streams',
							'votings'
						];
						break;
					case 'references':
						typesToLoad = ['expos', 'maps', 'mediaitems', 'schedules', 'speakers', 'stages'];
						break;
					case 'attendees':
						typesToLoad = ['expos', 'speakers'];
						break;
					case 'expos':
						typesToLoad = ['expos', 'mediaitems', 'schedules', 'speakers', 'maps', 'mappositions'];
						break;
					case 'interactive':
						typesToLoad = ['schedules', 'schedulestatuses'];
						break;
					case 'mailtemplates':
						typesToLoad = ['mailtemplates', 'mailhistories'];
						break;
					case 'maps':
						typesToLoad = ['maps', 'mappositions', 'expos', 'meetingtables', 'stages'];
						break;
					case 'mediaitems':
						typesToLoad = ['expos', 'mediaitems', 'speakers'];
						break;
					case 'meetings':
						typesToLoad = ['meetingtables'];
						break;
					case 'meetingtables':
						typesToLoad = ['meetingtables'];
						break;
					case 'networkingrooms':
						typesToLoad = ['networkingrooms'];
						break;
					case 'newsitems':
						typesToLoad = ['newsitems'];
						break;
					case 'schedules':
						typesToLoad = ['expos', 'schedules', 'schedulestatuses', 'speakers', 'stages', 'streams', 'networkingrooms'];
						break;
					case 'speakers':
						typesToLoad = ['expos', 'mediaitems', 'schedules', 'speakers'];
						break;
					case 'stages':
						typesToLoad = ['schedules', 'stages'];
						break;
					case 'streams':
						typesToLoad = ['mediaitems', 'schedules', 'streams', 'networkingrooms'];
						break;
					case 'votings':
						typesToLoad = ['schedules'];
						break;
					default:
						break;
				}

				const activeSpace = dispatch.temp.getActiveSpace({});

				if (!isFeatureActive(activeSpace, 'feed')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'newsitems');
				}

				if (!isFeatureActive(activeSpace, 'schedules')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'schedules' && e !== 'schedulestatuses' && e !== 'streams');
				}

				if (!isFeatureActive(activeSpace, 'networking')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'networkingrooms');
				}

				if (!isFeatureActive(activeSpace, 'mediaitems')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'mediaitems');
				}

				if (!isFeatureActive(activeSpace, 'meetings')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'meetingtables');
				}

				if (!isFeatureActive(activeSpace, 'maps')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'maps' && e !== 'mappositions');
				}

				if (!isFeatureActive(activeSpace, 'speakers')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'speakers');
				}

				if (!isFeatureActive(activeSpace, 'expos')) {
					typesToLoad = typesToLoad.filter((e) => e !== 'expos');
				}

				const now = moment().unix();

				if (activeSpace) {
					const _types: {
						type: TContentTypePlural;
						query: Record<string, any>;
					}[] = [];

					// if (
					// 	!store.temp.lastContentLoad ||
					// 	!activeSpace.reloadIntervalContent ||
					// 	now - store.temp.lastContentLoad > activeSpace.reloadIntervalContent * 60
					// ) {
					for (const type of typesToLoad) {
						if (!store.temp.hasLoadedData || store.temp.hasLoadedData[type]) {
							contentLoadLog(3, `${type} already loaded. skipping`);
							continue;
						}

						if (store.temp.waitingForSocketResponse && store.temp.waitingForSocketResponse[type]) {
							contentLoadLog(3, `${type} load already in progress. skipping`);
							continue;
						}

						const updated_at_gt =
							store.content.lastContentRead[activeSpace.spaceId] && store.content.lastContentRead[activeSpace.spaceId][type]
								? store.content.lastContentRead[activeSpace.spaceId][type]
								: ZEROHOUR;

						_types.push({
							type,
							query: {
								updated_at_gt
							}
						});
					}
					// }

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							if (_types.length > 0) {
								_types.forEach((typeObj) => {
									contentLoadLog(2, `loading ${typeObj.type}`);
									dispatch.temp.setWaitingForSocketResponse({ key: typeObj.type, value: true });
								});

								dispatch.socket.emitToMultiSpaceBackend({
									event: 'loadContent',
									data: {
										type: 'content',
										userId: store.auth.userInfos.userId,
										spaceId: activeSpace.spaceId,
										types: _types
									}
								});
								dispatch.temp.setLastContentLoad(moment().unix());
							}
							break;
						case EDataLoadStrategy.Default:
						default:
							if (_types.length > 0) {
								const newBody = new FormData();

								newBody.append('data', JSON.stringify({ types: _types, spaceId: activeSpace.spaceId }));

								_types.forEach((typeObj) => {
									contentLoadLog(2, `loading ${typeObj.type}`);
									dispatch.temp.setWaitingForSocketResponse({ key: typeObj.type, value: true });
								});

								const res = await dispatch.request.anonymousRequest({
									url: `${MULTISPACEURL}/content/loadall`,
									method: 'POST',
									body: newBody
								});

								if (res?.status === 200) {
									let _items = res.result;

									const result: any[] = [];

									_items.forEach((item) => {
										const alreadyIn = result.find((e) => e.type === item.type);
										if (alreadyIn) {
											alreadyIn.items.push(item);
										} else {
											result.push({
												items: [item],
												type: item.type
											});
										}
									});

									_types.forEach((type) => {
										contentLoadLog(4, `${type.type} received`);

										dispatch.temp.setWaitingForSocketResponse({ key: type.type, value: false });
										dispatch.temp.setHasLoadedData(type.type);
									});

									if (result.length > 0) {
										dispatch.content.addContent({ load: true, content: result });
									}
								}
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadContentDelta', error);
			}
		},
		async startChangelogSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (
					activeSpace &&
					(isAdmin || isModerator) &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_changelogs`)
				) {
					contentLoadLog(3, 'starting changelog sync');
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_changelogs`, async (data) => {
						let load = !Array.isArray(data) && data.load;
						let _items = Array.isArray(data) ? data : data.items;

						if (load) {
							contentLoadLog(4, 'changelogs received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'changelogs', value: false });
							if (!store.temp.hasLoadedData?.changelogs) {
								dispatch.temp.setHasLoadedData('changelogs');
							}
						}

						if (_items.length > 0) {
							dispatch.content.addChangelogs({ load, changelogs: _items });
						}
					});
				}
			} catch (error) {
				console.log('startChangelogSync', error);
			}
		},
		async loadChangelogDelta(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const lastRead = store.content.lastChangelogsRead ? { ...store.content.lastChangelogsRead } : {};

				if (activeSpace) {
					if (!store.temp.hasLoadedData || store.temp.hasLoadedData.changelogs) {
						contentLoadLog(3, 'changelogs already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse && store.temp.waitingForSocketResponse.changelogs) {
						contentLoadLog(3, 'changelogs load already in progress. skipping');
						return;
					}

					switch (DATA_LOAD_STRATEGY) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading changelogs');
							dispatch.temp.setWaitingForSocketResponse({ key: 'changelogs', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'changelogs',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: lastRead[activeSpace.spaceId] ?? ZEROHOUR
								}
							});

							break;
						case EDataLoadStrategy.Default:
						default:
							// TODO
							break;
					}
				}
			} catch (error) {
				console.log('loadChangelogDelta', error);
			}
		},
		async startOnlineStatusSync(payload, store) {
			try {
				if (store.socket.multiSpaceSocket && !store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_onlineusers`) && IS_WEB) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_onlineusers`, async (data) => {
						if (data) {
							dispatch.content.setOnlineUsers(data);
						}
					});
				}
			} catch (error) {
				console.log('startOnlineStatusSync', error);
			}
		},
		async multiDelete(payload: IMultiDeletePayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { selectedIds, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							selectedIds,
							type,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/content/multidelete`,
						method: 'POST',
						body
					});
					if (res && Array.isArray(res)) {
						const _items: any[] = [];
						res.forEach((item) => {
							const alreadyIn = _items.find((e) => e.type === item.type);
							if (alreadyIn) {
								alreadyIn.items.push(item);
							} else {
								_items.push({
									items: [item],
									type: item.type
								});
							}
						});

						dispatch.content.addContent({
							load: false,
							content: [{ type: type as TContentTypePlural, items: _items }]
						});

						showToast('success', i18next.t('Entries Deleted'));
						return true;
					}
					showToast('error', i18next.t('Could not delete Entries'));
					return false;
				}
			} catch (error) {
				console.log('multiDelete', error);
			}
		},
		async multiUpdate(payload: IMultiUpdatePayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { items, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							items,
							type,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/content/multiupdate`,
						method: 'POST',
						body
					});
					if (res && Array.isArray(res)) {
						const _items: any[] = [];
						res.forEach((item) => {
							const alreadyIn = _items.find((e) => e.type === item.type);
							if (alreadyIn) {
								alreadyIn.items.push(item);
							} else {
								_items.push({
									items: [item],
									type: item.type
								});
							}
						});

						dispatch.content.addContent({
							load: false,
							content: [{ type: type as TContentTypePlural, items: _items }]
						});

						showToast('success', i18next.t('Entries Updated'));
						return true;
					}
					showToast('error', i18next.t('Could not update Entries'));
					return false;
				}
			} catch (error) {
				console.log('multiUpdate', error);
			}
		},
		async createNews(payload: ICreateNewsPayload, store) {
			try {
				const { space, news, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					let mediaToUpload: IMediaGalleryItem[] = [];

					if (news.media) {
						mediaToUpload = [...news.media];
						news.media = [];
					}

					newBody.append(
						'data',
						JSON.stringify({
							news: { ...news, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/newsitems`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						for (let i = 0; i < mediaToUpload.length; i++) {
							const mediaObj = mediaToUpload[i];

							if (typeof mediaObj.media !== 'number') {
								if (
									typeof mediaObj.media === 'string' ||
									mediaObj.media?.url?.startsWith('file://') ||
									mediaObj.media?.url?.startsWith('data:')
								) {
									let imageId;
									if (typeof mediaObj !== 'string') {
										imageId = mediaObj.id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
										imageId,
										caption: typeof mediaObj.media === 'object' ? mediaObj.media?.caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										res.media[i] = {
											...mediaObj,
											media: mediaResponse[0]
										};
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
								if (libEntry) {
									res.media[i] = {
										...mediaObj,
										media: { ...libEntry }
									};
								}
							}
						}

						if (mediaToUpload.length > 0) {
							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									news: { media: res.media },
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/newsitems/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'newsitems', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('News Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create News'));
					}
					return false;
				}
			} catch (error) {
				console.log('createNews', error);
			}
		},
		async updateNews(payload: IUpdateNewsPayload, store) {
			try {
				const { news, isDeletion, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					let mediaToUpload: IMediaGalleryItem[] = [];

					if (news.media) {
						mediaToUpload = [...news.media];
						news.media = [];
					}
					if (!news.media) {
						mediaToUpload = [];
					}

					for (let i = 0; i < mediaToUpload.length; i++) {
						const mediaObj = mediaToUpload[i];

						if (typeof mediaObj.media !== 'number' && news.media) {
							if (
								typeof mediaObj.media === 'string' ||
								mediaObj.media?.url?.startsWith('file://') ||
								mediaObj.media?.url?.startsWith('data:')
							) {
								let imageId;
								if (typeof mediaObj !== 'string') {
									imageId = mediaObj.id;
								}

								const imageResponse = await dispatch.upload.uploadMedia({
									endpoint: MULTISPACEURL,
									uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
									imageId,
									caption: typeof mediaObj.media === 'object' ? mediaObj.media?.caption : undefined
								});

								if (imageResponse) {
									news.media[i] = {
										...mediaObj,
										media: imageResponse[0]
									};
								}
							} else {
								news.media[i] = mediaObj;
							}
						} else if (news.media) {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
							if (libEntry) {
								news.media[i] = {
									...mediaObj,
									media: { ...libEntry }
								};
							}
						}
					}

					newBody.append('data', JSON.stringify({ news: news, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/newsitems/${news.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'newsitems', items: [res] }]
						});

						if (!noToast) {
							showToast('success', isDeletion ? i18next.t('News deleted') : i18next.t('News updated'));
						}
						return true;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete News') : i18next.t('Could not update News'));
					return false;
				}
			} catch (error) {
				console.log('updateNews', error);
			}
		},
		async createExpo(payload: ICreateExpoPayload, store) {
			try {
				const { space, expo, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && expo[field.fieldName]) {
							tempMedia[field.fieldName] = expo[field.fieldName];
							delete expo[field.fieldName];
						}
						if (field.fieldType === 'gallery' && expo[field.fieldName]) {
							tempMedia[field.fieldName] = expo[field.fieldName];
							expo[field.fieldName] = [];
						}
					});

					newBody.append(
						'data',
						JSON.stringify({
							expo: { ...expo, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/expos`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateExpo = { imageGallery: [] };
							for (const key of keys) {
								if (Array.isArray(tempMedia[key])) {
									for (let i = 0; i < tempMedia[key].length; i++) {
										let mediaObj = tempMedia[key][i];
										if (key === 'imageGallery') {
											if (typeof mediaObj.media !== 'number') {
												if (
													typeof mediaObj.media === 'string' ||
													mediaObj.media?.url?.startsWith('file://') ||
													mediaObj.media?.url?.startsWith('data:')
												) {
													let imageId;
													if (typeof mediaObj !== 'string') {
														imageId = mediaObj.id;
													}

													const imageResponse = await dispatch.upload.uploadMedia({
														endpoint: MULTISPACEURL,
														uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
														imageId,
														caption: typeof mediaObj.media === 'object' ? mediaObj.media?.caption : undefined
													});

													if (imageResponse) {
														updateExpo[key][i] = {
															...mediaObj,
															media: imageResponse[0]
														};
													}
												} else {
													updateExpo[key][i] = mediaObj;
												}
											} else if (tempMedia[key]) {
												const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
												if (libEntry) {
													updateExpo[key][i] = {
														...mediaObj,
														media: { ...libEntry }
													};
												}
											}
										}
									}
								} else if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateExpo[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateExpo[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									expo: updateExpo,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/expos/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'expos', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Expo Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Expo'));
					}
					return false;
				}
			} catch (error) {
				console.log('createExpo', error);
			}
		},
		async updateExpo(payload: IUpdateExpoPayload, store) {
			try {
				const { expo, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && expo[field.fieldName]) {
							tempMedia[field.fieldName] = expo[field.fieldName];
							delete expo[field.fieldName];
						}
						if (field.fieldType === 'gallery' && expo[field.fieldName]) {
							tempMedia[field.fieldName] = expo[field.fieldName];
							expo[field.fieldName] = [];
						}
					});

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (Array.isArray(tempMedia[key])) {
								for (let i = 0; i < tempMedia[key].length; i++) {
									let mediaObj = tempMedia[key][i];
									if (key === 'imageGallery') {
										if (typeof mediaObj.media !== 'number') {
											if (
												typeof mediaObj.media === 'string' ||
												mediaObj.media?.url?.startsWith('file://') ||
												mediaObj.media?.url?.startsWith('data:')
											) {
												let imageId;
												if (typeof mediaObj !== 'string') {
													imageId = mediaObj.id;
												}

												const imageResponse = await dispatch.upload.uploadMedia({
													endpoint: MULTISPACEURL,
													uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
													imageId,
													caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
												});

												if (imageResponse) {
													expo[key][i] = {
														...mediaObj,
														media: imageResponse[0]
													};
												}
											} else {
												expo[key][i] = mediaObj;
											}
										} else if (tempMedia[key]) {
											const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
											if (libEntry) {
												expo[key][i] = {
													...mediaObj,
													media: { ...libEntry }
												};
											}
										}
									}
								}
							} else if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										expo[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									expo[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ expo, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/expos/${expo.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'expos', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Expo deleted') : i18next.t('Expo updated'));
						return true;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Expo') : i18next.t('Could not update Expo'));
					return false;
				}
			} catch (error) {
				console.log('updateExpo', error);
			}
		},
		async createSpeaker(payload: ICreateSpeakerPayload, store) {
			try {
				const { space, speaker, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && speaker[field.fieldName]) {
							tempMedia[field.fieldName] = speaker[field.fieldName];
							delete speaker[field.fieldName];
						}
					});

					newBody.append(
						'data',
						JSON.stringify({
							speaker: { ...speaker, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/speakers`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateSpeaker = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateSpeaker[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateSpeaker[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									speaker: updateSpeaker,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/speakers/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'speakers', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Speaker Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Speaker'));
					}
					return false;
				}
			} catch (error) {
				console.log('createSpeaker', error);
			}
		},
		async updateSpeaker(payload: IUpdateSpeakerPayload, store) {
			try {
				const { speaker, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && speaker[field.fieldName]) {
							tempMedia[field.fieldName] = speaker[field.fieldName];
							!!delete speaker[field.fieldName];
						}
					});

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										speaker[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									speaker[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ speaker, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/speakers/${speaker.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'speakers', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Speaker deleted') : i18next.t('Speaker updated'));
						return true;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Speaker') : i18next.t('Could not update Speaker'));
					return false;
				}
			} catch (error) {
				console.log('updateSpeaker', error);
			}
		},
		async createSchedule(payload: ICreateSchedulePayload, store) {
			try {
				const { space, schedule, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const tempMedia = {};

					if (schedule.previewImage) {
						tempMedia['previewImage'] = schedule.previewImage;
						delete schedule.previewImage;
					}

					for (const key of Object.keys(tempMedia)) {
						if (typeof tempMedia[key] !== 'number') {
							if (
								typeof tempMedia[key] === 'string' ||
								tempMedia[key].url?.startsWith('file://') ||
								tempMedia[key].url?.startsWith('data:')
							) {
								let imageId;
								if (typeof tempMedia[key] !== 'string') {
									imageId = tempMedia[key].id;
								}

								const mediaResponse = await dispatch.upload.uploadMedia({
									endpoint: MULTISPACEURL,
									uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
									imageId,
									caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
								});
								if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
									schedule[key] = mediaResponse[0];
								}
							}
						} else {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
							if (libEntry) {
								schedule[key] = libEntry;
							}
						}
					}

					const newBody = new FormData();

					newBody.append(
						'data',
						JSON.stringify({
							schedule: { ...schedule, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/schedules`,
						method: 'POST',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'schedules', items: [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Schedule Created'));
						}
						return res;
					}
					showToast('error', i18next.t('Could not create Schedule'));
					return undefined;
				}
			} catch (error) {
				console.log('createSchedule', error);
				return undefined;
			}
		},
		async updateSchedule(payload: IUpdateSchedulePayload, store) {
			try {
				const { schedule, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const tempMedia = {};

					if (schedule.previewImage) {
						tempMedia['previewImage'] = schedule.previewImage;
						delete schedule.previewImage;
					}

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										schedule[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									schedule[key] = libEntry;
								}
							}
						}
					}

					const newBody = new FormData();

					newBody.append('data', JSON.stringify({ schedule, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/schedules/${schedule.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'schedules', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Schedule deleted') : i18next.t('Schedule updated'));
						return res;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Schedule') : i18next.t('Could not update Schedule'));
					return undefined;
				}
			} catch (error) {
				console.log('updateSchedule', error);
			}
		},
		async updateScheduleStatus(payload: IUpdateScheduleStatusPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { newStatus, statusId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							newStatus,
							statusId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'PUT',
						url: `${MULTISPACEURL}/schedulestatuses/update`,
						body
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'schedulestatuses', items: [res] }]
						});

						return res;
					}

					return undefined;
				}
			} catch (error) {
				console.log('updateScheduleStatus', error);
			}
		},
		async createStream(payload: ICreateStreamPayload, store) {
			try {
				const { space, stream, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					if (stream.media) {
						tempMedia['media'] = stream.media;
						delete stream.media;
					}
					if (stream.previewImage) {
						tempMedia['previewImage'] = stream.previewImage;
						delete stream.previewImage;
					}

					newBody.append(
						'data',
						JSON.stringify({
							stream: { ...stream, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/streams`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateStream = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateStream[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateStream[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									stream: updateStream,
									spaceId: currentSpace.spaceId,
									userInfos: store.auth.userInfos
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/streams/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}

					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'streams', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Stream Created'));
						}

						return res2 ?? res;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Stream'));
					}
					return undefined;
				}
			} catch (error) {
				console.log('createStream', error);
				return undefined;
			}
		},
		async updateStream(payload: IUpdateStreamPayload, store) {
			try {
				const { stream, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					if (stream.media) {
						tempMedia['media'] = stream.media;
						delete stream.media;
					}
					if (stream.previewImage) {
						tempMedia['previewImage'] = stream.previewImage;
						delete stream.previewImage;
					}

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										stream[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									stream[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ stream, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/streams/${stream.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'streams', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Stream deleted') : i18next.t('Stream updated'));
						return res;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Stream') : i18next.t('Could not update Stream'));
					return undefined;
				}
			} catch (error) {
				console.log('updateStream', error);
			}
		},
		async createMediaItem(payload: ICreateMediaItemPayload, store) {
			try {
				const { space, mediaitem, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						switch (field.fieldType) {
							case 'image':
							case 'video':
							case 'logo':
							case 'media':
								if (mediaitem[field.fieldName]) {
									tempMedia[field.fieldName] = mediaitem[field.fieldName];
									delete mediaitem[field.fieldName];
								}
								break;
							default:
								break;
						}
					});

					if (mediaitem?.media) {
						tempMedia.media = mediaitem.media;
						delete mediaitem.media;
					}

					newBody.append(
						'data',
						JSON.stringify({
							mediaitem: { ...mediaitem, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId,
							selfServiceToken: store.temp.selfServiceToken
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/mediaitems`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateMediaItem = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}
										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined,
											space: currentSpace
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateMediaItem[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateMediaItem[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									mediaitem: updateMediaItem,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId,
									selfServiceToken: store.temp.selfServiceToken
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/mediaitems/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'mediaitems', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Media Item Created'));
						}
						return res2 ?? res;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Media Item'));
					}
					return undefined;
				}
			} catch (error) {
				console.log('createMediaItem', error);
				return undefined;
			}
		},
		async updateMediaItem(payload: IUpdateMediaItemPayload, store) {
			try {
				const { mediaitem, fields, isDeletion, noToast, space } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});
				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						switch (field.fieldType) {
							case 'image':
							case 'video':
							case 'logo':
							case 'media':
								if (mediaitem[field.fieldName]) {
									tempMedia[field.fieldName] = mediaitem[field.fieldName];
									delete mediaitem[field.fieldName];
								}
								break;
							default:
								break;
						}
					});

					if (mediaitem?.media) {
						tempMedia.media = mediaitem.media;
						delete mediaitem.media;
					}

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined,
										space: currentSpace
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										mediaitem[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									mediaitem[key] = libEntry;
								}
							}
						}
					}

					newBody.append(
						'data',
						JSON.stringify({
							mediaitem,
							spaceId: currentSpace.spaceId,
							userInfos: store.auth.userInfos,
							selfServiceToken: store.temp.selfServiceToken
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/mediaitems/${mediaitem.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'expos', items: [res] }]
						});

						if (!noToast) {
							showToast('success', isDeletion ? i18next.t('Media Item deleted') : i18next.t('Media Item updated'));
						}
						return res;
					}
					if (!noToast) {
						showToast(
							'error',
							isDeletion ? i18next.t('Could not delete Media Item') : i18next.t('Could not update Media Item')
						);
					}
					return undefined;
				}
			} catch (error) {
				console.log('updateMediaItem', error);
			}
		},
		async createStage(payload: ICreateStagePayload, store) {
			try {
				const { space, stage, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && stage[field.fieldName]) {
							tempMedia[field.fieldName] = stage[field.fieldName];
							delete stage[field.fieldName];
						}
					});

					if (store.content.content.stages) {
						const withOrder = store.content.content.stages.filter((e) => e.spaceId === currentSpace?.spaceId && e.order);
						withOrder.sort((a, b) => {
							const aOrder = a.order ? a.order : 1;
							const bOrder = b.order ? b.order : 1;
							return aOrder > bOrder ? -1 : 1;
						});
						if (withOrder.length > 0 && withOrder[0].order) {
							stage.order = withOrder[0].order + 1;
						}
					}

					newBody.append(
						'data',
						JSON.stringify({
							stage: { ...stage, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/stages`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updateStage = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updateStage[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updateStage[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									stage: updateStage,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/stages/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}

					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'stages', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Stage Created'));
						}

						return res2 ?? res;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Stage'));
					}
					return undefined;
				}
			} catch (error) {
				console.log('createStage', error);
				return undefined;
			}
		},
		async updateStage(payload: IUpdateStagePayload, store) {
			try {
				const { stage, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && stage[field.fieldName]) {
							tempMedia[field.fieldName] = stage[field.fieldName];
							!!delete stage[field.fieldName];
						}
					});

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										stage[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									stage[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ stage, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/stages/${stage.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'stages', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Stage deleted') : i18next.t('Stage updated'));
						return res;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Stage') : i18next.t('Could not update Stage'));
					return undefined;
				}
			} catch (error) {
				console.log('updateStage', error);
			}
		},
		async createNetworkingRoom(payload: ICreateNetworkingRoomPayload, store) {
			try {
				const { space, networkingroom, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && networkingroom[field.fieldName]) {
							tempMedia[field.fieldName] = networkingroom[field.fieldName];
							delete networkingroom[field.fieldName];
						}
					});

					newBody.append(
						'data',
						JSON.stringify({
							networkingroom: { ...networkingroom, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updatedNetworkingRoom = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updatedNetworkingRoom[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updatedNetworkingRoom[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									networkingroom: updatedNetworkingRoom,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/networkingrooms/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'networkingrooms', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('NetworkingRoom Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create NetworkingRoom'));
					}
					return false;
				}
			} catch (error) {
				console.log('createNetworkingRoom', error);
			}
		},
		async updateNetworkingRoom(payload: IUpdateNetworkingRoomPayload, store) {
			try {
				const { networkingroom, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					fields.forEach((field) => {
						if ((field.fieldType === 'image' || field.fieldType === 'logo') && networkingroom[field.fieldName]) {
							tempMedia[field.fieldName] = networkingroom[field.fieldName];
							!!delete networkingroom[field.fieldName];
						}
					});

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										networkingroom[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									networkingroom[key] = libEntry;
								}
							}
						}
					}

					newBody.append(
						'data',
						JSON.stringify({ networkingroom, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos })
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/networkingrooms/${networkingroom.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'networkingrooms', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('NetworkingRoom deleted') : i18next.t('NetworkingRoom updated'));
						return true;
					}
					showToast(
						'error',
						isDeletion ? i18next.t('Could not delete NetworkingRoom') : i18next.t('Could not update NetworkingRoom')
					);
					return false;
				}
			} catch (error) {
				console.log('updateNetworkingRoom', error);
			}
		},
		async createMap(payload: ICreateMapPayload, store) {
			try {
				const { space, map, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					if (map.image) {
						tempMedia['image'] = map.image;
						delete map.image;
					}

					newBody.append(
						'data',
						JSON.stringify({
							map: { ...map, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/maps`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						const keys = Object.keys(tempMedia);
						if (keys.length > 0) {
							const updatedMap = {};
							for (const key of keys) {
								if (typeof tempMedia[key] !== 'number') {
									if (
										typeof tempMedia[key] === 'string' ||
										tempMedia[key].url?.startsWith('file://') ||
										tempMedia[key].url?.startsWith('data:')
									) {
										let imageId;
										if (typeof tempMedia[key] !== 'string') {
											imageId = tempMedia[key].id;
										}

										const mediaResponse = await dispatch.upload.uploadMedia({
											endpoint: MULTISPACEURL,
											uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
											imageId,
											caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
										});
										if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
											updatedMap[key] = mediaResponse[0];
										}
									}
								} else {
									const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
									if (libEntry) {
										updatedMap[key] = libEntry;
									}
								}
							}

							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									map: updatedMap,
									userInfos: store.auth.userInfos,
									spaceId: currentSpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/maps/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'maps', items: res2 ? [res2] : [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('Map Created'));
						}
						return true;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create Map'));
					}
					return false;
				}
			} catch (error) {
				console.log('createMap', error);
			}
		},
		async updateMap(payload: IUpdateMapPayload, store) {
			try {
				const { map, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					const tempMedia = {};

					if (map.image) {
						tempMedia['image'] = map.image;
						!!delete map.image;
					}

					if (map.mappositions) {
						delete map.mappositions;
					}

					const keys = Object.keys(tempMedia);
					if (keys.length > 0) {
						for (const key of keys) {
							if (typeof tempMedia[key] !== 'number') {
								if (
									typeof tempMedia[key] === 'string' ||
									tempMedia[key].url?.startsWith('file://') ||
									tempMedia[key].url?.startsWith('data:')
								) {
									let imageId;
									if (typeof tempMedia[key] !== 'string') {
										imageId = tempMedia[key].id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
										imageId,
										caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										map[key] = mediaResponse[0];
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
								if (libEntry) {
									map[key] = libEntry;
								}
							}
						}
					}

					newBody.append('data', JSON.stringify({ map, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/maps/${map.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'maps', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('Map deleted') : i18next.t('Map updated'));
						return true;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Map') : i18next.t('Could not update Map'));
					return false;
				}
			} catch (error) {
				console.log('updateMap', error);
			}
		},
		async updateMapPositions(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { addedPositions, updatedPositions, deletedPositions } = payload;

					const _positions: any[] = [];

					for (const position of addedPositions) {
						const body = new FormData();
						body.append(
							'data',
							JSON.stringify({
								mapPosition: position,
								spaceId: activeSpace.spaceId,
								userInfos: store.auth.userInfos
							})
						);
						const addRes = await dispatch.request.anonymousRequest({
							method: 'POST',
							url: `${MULTISPACEURL}/mappositions`,
							body
						});

						if (addRes?.id) {
							_positions.push(addRes);
						}
					}

					for (const position of updatedPositions) {
						const body = new FormData();
						body.append(
							'data',
							JSON.stringify({
								mapPosition: position,
								spaceId: activeSpace.spaceId,
								userInfos: store.auth.userInfos
							})
						);
						const updateRes = await dispatch.request.anonymousRequest({
							method: 'PUT',
							url: `${MULTISPACEURL}/mappositions/${position.id}`,
							body
						});

						if (updateRes?.id) {
							_positions.push(updateRes);
						}
					}

					for (const position of deletedPositions) {
						const body = new FormData();
						body.append(
							'data',
							JSON.stringify({
								mapPosition: { ...position, isDeleted: true },
								spaceId: activeSpace.spaceId,
								userInfos: store.auth.userInfos
							})
						);
						const deleteRes = await dispatch.request.anonymousRequest({
							method: 'PUT',
							url: `${MULTISPACEURL}/mappositions/${position.id}`,
							body
						});

						if (deleteRes?.id) {
							_positions.push(deleteRes);
						}
					}

					if (_positions.length > 0) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'mappositions', items: _positions }]
						});
					}
				}
			} catch (error) {
				console.log('updateMapPositions', error);
			}
		},
		async createMeetingTable(payload: ICreateMeetingTablePayload, store) {
			try {
				const { space, meetingtable, fields, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = activeSpace ?? space;

				if (currentSpace) {
					const newBody = new FormData();

					newBody.append(
						'data',
						JSON.stringify({
							meetingtable: { ...meetingtable, spaceId: currentSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: currentSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/meetingtables`,
						method: 'POST',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'meetingtables', items: [res] }]
						});

						if (!noToast) {
							showToast('success', i18next.t('MeetingTable Created'));
						}

						return res;
					}
					if (!noToast) {
						showToast('error', i18next.t('Could not create MeetingTable'));
					}
					return undefined;
				}
			} catch (error) {
				console.log('createMeetingTable', error);
				return undefined;
			}
		},
		async updateMeetingTable(payload: IUpdateMeetingTablePayload, store) {
			try {
				const { meetingtable, fields, isDeletion } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					newBody.append('data', JSON.stringify({ meetingtable, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/meetingtables/${meetingtable.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						dispatch.content.addContent({
							load: false,
							content: [{ type: 'meetingtables', items: [res] }]
						});

						showToast('success', isDeletion ? i18next.t('MeetingTable deleted') : i18next.t('MeetingTable updated'));
						return res;
					}
					showToast(
						'error',
						isDeletion ? i18next.t('Could not delete MeetingTable') : i18next.t('Could not update MeetingTable')
					);
					return undefined;
				}
			} catch (error) {
				console.log('updateMeetingTable', error);
			}
		},
		async loadMeetings(payload: ILoadMeetingsPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (activeSpace && (isAdmin || isModerator)) {
					const { meetingTableIds } = payload;

					if (store.temp.waitingForSocketResponse?.meetings) {
						contentLoadLog(3, 'meetings load already in progress. skipping');
						return;
					}

					contentLoadLog(2, 'loading meetings');
					dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: true });

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							meetingTableIds,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/meetingtables/getmeetings`,
						body
					});

					dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: false });

					if (res && Array.isArray(res)) {
						return res;
					}
				}

				return [];
			} catch (error) {
				console.log('loadMeetings', error);
				dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: false });

				return [];
			}
		},
		async checkImportItems(payload: ICheckImportItemsPayload, store) {
			const { items, type } = payload;
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							items,
							type,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);
					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/content/checkimportitems`,
						method: 'POST',
						body
					});

					if (res && Array.isArray(res)) {
						return res;
					}

					return items;
				}

				return items;
			} catch (error) {
				console.log('checkImportItems', error);
				return items;
			}
		},
		async importData(payload: IImportDataPayload, store) {
			try {
				const { items, type } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				let createdItems: any[] = [];
				let updatedItems: any[] = [];

				if (activeSpace) {
					let _items = [...items];

					if (type === 'stage') {
						const usedOrderValues: number[] = [];
						let withOrder: IStage[] = [];
						if (store.content.content.stages) {
							withOrder = store.content.content.stages.filter((e) => e.spaceId === activeSpace?.spaceId && e.order);
							withOrder.sort((a, b) => {
								const aOrder = a.order ? a.order : 1;
								const bOrder = b.order ? b.order : 1;
								return aOrder > bOrder ? -1 : 1;
							});
						}

						_items = _items.map((item) => {
							const obj = {
								...item
							};
							if (store.content.content.stages && !item.order) {
								if (withOrder.length > 0 && withOrder[0].order) {
									item.order = withOrder[0].order + 1;
									while (usedOrderValues.includes(item.order)) {
										item.order += 1;
									}
									usedOrderValues.push(item.order);
								}
							} else if (item.order) {
								usedOrderValues.push(item.order);
							}

							return obj;
						});
					}

					const promises: any[] = [];

					while (_items.length > 0) {
						const part = _items.splice(0, 25);
						if (type === 'schedule') {
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									items: part,
									type,
									spaceId: activeSpace?.spaceId,
									userInfos: store.auth.userInfos
								})
							);

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/content/import`,
								method: 'POST',
								body
							});
							if (res.createdItems && res.updatedItems) {
								createdItems = createdItems.concat(res.createdItems);
								updatedItems = updatedItems.concat(res.updatedItems);
							}
						} else {
							const promise = new Promise(async (resolve) => {
								const body = new FormData();
								body.append(
									'data',
									JSON.stringify({
										items: part,
										type,
										spaceId: activeSpace?.spaceId,
										userInfos: store.auth.userInfos
									})
								);

								const res = await dispatch.request.anonymousRequest({
									url: `${MULTISPACEURL}/content/import`,
									method: 'POST',
									body
								});
								resolve(res);
							});
							promises.push(promise);
						}
					}

					const promiseRes = await Promise.all(promises);

					promiseRes.forEach((promiseRes) => {
						if (promiseRes.createdItems && promiseRes.updatedItems) {
							createdItems = createdItems.concat(promiseRes.createdItems);
							updatedItems = updatedItems.concat(promiseRes.updatedItems);
						}
					});
				}

				return {
					createdItems,
					updatedItems
				};
			} catch (error) {
				console.log('importData', error);
				return {
					createdItems: [],
					updatedItems: []
				};
			}
		},
		async createInitialMailTemplates(payload: ICreateInitialMailTemplates, store) {
			try {
				const { space } = payload;

				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getPasswordResetEmail() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getAccountConfirmationEmail() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getNewAdministratorEmail() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getNewModeratorEmail() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getExpoSelfServiceInvitation() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getSpeakerSelfServiceInvitation() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					space,
					mailTemplate: { ..._getHelloSpacesTicketInvitation() },
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getInviteUnregisteredAdmin() },
					space,
					noToast: true
				});
				await dispatch.content.createMailTemplate({
					mailTemplate: { ..._getDefaultHelloSpacesInvitation(space?.ticketSettings?.ticketRequired) },
					space,
					noToast: true
				});
			} catch (error) {
				console.log('createInitialMailTemplates', error);
			}
		},
		async createMailTemplate(payload: IUpdateMailTemplatePayload, store) {
			try {
				const { mailTemplate, noToast, space } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const mySpace = space ?? activeSpace;

				if (mySpace) {
					const newBody = new FormData();

					let mediaToUpload: IMediaGalleryItem[] = [];

					if (mailTemplate.attachments) {
						mediaToUpload = [...mailTemplate.attachments];
						mailTemplate.attachments = [];
					}

					newBody.append(
						'data',
						JSON.stringify({
							mailTemplate: { ...mailTemplate, spaceId: mySpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: mySpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/mailtemplates`,
						method: 'POST',
						body: newBody
					});

					let res2: any = null;
					if (res?.id) {
						for (let i = 0; i < mediaToUpload.length; i++) {
							const mediaObj = mediaToUpload[i];

							if (typeof mediaObj.media !== 'number') {
								if (
									typeof mediaObj.media === 'string' ||
									mediaObj.media?.url?.startsWith('file://') ||
									mediaObj.media?.url?.startsWith('data:')
								) {
									let imageId;
									if (typeof mediaObj !== 'string') {
										imageId = mediaObj.id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
										imageId,
										caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										res.media[i] = {
											...mediaObj,
											media: mediaResponse[0]
										};
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
								if (libEntry) {
									res.media[i] = {
										...mediaObj,
										media: { ...libEntry }
									};
								}
							}
						}

						if (mediaToUpload.length > 0) {
							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									mailTemplate: { media: res.media },
									userInfos: store.auth.userInfos,
									spaceId: mySpace.spaceId
								})
							);

							res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/mailtemplates/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}
					}
					if (res2?.id || res?.id) {
						if (!noToast) {
							showToast('success', i18next.t('MailTemplate Created'));
						}
						return true;
					}
					showToast('error', i18next.t('Could not create Mailtemplate'));
					return false;
				}
			} catch (error) {
				console.log('createMailTemplate', error);
			}
		},
		async updateMailTemplate(payload: IUpdateMailTemplatePayload, store) {
			try {
				const { mailTemplate } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					let mediaToUpload: IMediaGalleryItem[] = [];

					if (mailTemplate.attachments) {
						mediaToUpload = [...mailTemplate.attachments];
						mailTemplate.attachments = [];
					}
					if (!mailTemplate.attachments) {
						mediaToUpload = [];
					}

					for (let i = 0; i < mediaToUpload.length; i++) {
						const mediaObj = mediaToUpload[i];

						if (typeof mediaObj.media !== 'number' && mailTemplate.attachments) {
							if (
								typeof mediaObj.media === 'string' ||
								mediaObj.media?.url?.startsWith('file://') ||
								mediaObj.media?.url?.startsWith('data:')
							) {
								let imageId;
								if (typeof mediaObj !== 'string') {
									imageId = mediaObj.id;
								}

								const mediaResponse = await dispatch.upload.uploadMedia({
									endpoint: MULTISPACEURL,
									uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
									imageId,
									refId: mailTemplate.id,
									ref: 'mailtemplate',
									caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
								});

								if (mediaResponse) {
									mailTemplate.attachments[i] = {
										...mediaObj,
										media: mediaResponse[0]
									};
								}
							} else {
								mailTemplate.attachments[i] = mediaObj;
							}
						} else if (mailTemplate.attachments) {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
							if (libEntry) {
								mailTemplate.attachments[i] = {
									...mediaObj,
									media: { ...libEntry }
								};
							}
						}
					}

					newBody.append('data', JSON.stringify({ mailTemplate, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/mailtemplates/${mailTemplate.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						showToast('success', res.isDeleted ? i18next.t('MailTemplate deleted') : i18next.t('MailTemplate updated'));
						return true;
					}
					showToast(
						'error',
						mailTemplate.isDeleted ? i18next.t('Could not delete MailTemplate') : i18next.t('Could not update MailTemplate')
					);
					return false;
				}
			} catch (error) {
				console.log('createMailTemplate', error);
			}
		},
		async sendMail(payload: ISendMailPayload, store) {
			try {
				const {
					mailTemplateId,
					mailTemplateKey,
					selectedEntries,
					subject,
					body,
					useDefaultHeader,
					useDefaultFooter,
					noHistory,
					contentType,
					sendingInformation
				} = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && selectedEntries.length > 0) {
					let res;
					const promises: any[] = [];

					let _selected = [...selectedEntries];

					while (_selected.length > 0) {
						const part = _selected.splice(0, 100);

						switch (mailTemplateKey) {
							case 'passwordRecovery':
							case 'confirmAccount':
								const bodyFormData = new FormData();
								bodyFormData.append(
									'data',
									JSON.stringify({
										mailTemplateKey,
										selectedEntries: part,
										subject,
										body,
										useDefaultHeader,
										useDefaultFooter,
										spaceId: activeSpace.spaceId,
										contentType,
										sendingInformation
									})
								);
								const promise = new Promise(async (resolve) => {
									res = await dispatch.request.authenticatedRequest({
										method: 'POST',
										url: `${MULTISPACEURL}/auth/send-mass-mail`,
										body: bodyFormData
									});
									resolve(res);
								});
								promises.push(promise);
								break;
							default:
								const formBody = new FormData();

								formBody.append(
									'data',
									JSON.stringify({
										mailTemplateId,
										selectedEntries: part,
										subject,
										body,
										useDefaultHeader,
										useDefaultFooter,
										noHistory,
										spaceId: activeSpace.spaceId,
										userInfos: store.auth.userInfos,
										contentType,
										sendingInformation
									})
								);

								const defaultPromise = new Promise(async (resolve) => {
									res = await dispatch.request.anonymousRequest({
										url: `${MULTISPACEURL}/mailhistories/sendmail`,
										method: 'POST',
										body: formBody
									});
									resolve(res);
								});
								promises.push(defaultPromise);

								break;
						}
					}

					const promiseRes = await Promise.all(promises);

					if (promiseRes[0].id || promiseRes[0].status === 200) {
						showToast('success', i18next.t('MailTemplate Sent'));
						return promiseRes[0].id ? promiseRes[0] : true;
					}
					showToast('error', 'Error', translateStrapiError(promiseRes[0]));
				}

				return undefined;
			} catch (error) {
				console.log('sendMail', error);
			}
		},
		async sendUserMail(payload: ISendUserMailPayload, store) {
			try {
				const { subject, body, type, id, userMail, userFirstname, userLastname } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const formBody = new FormData();
					const user = {
						...store.auth.userInfos,
						firstName: userFirstname ?? store.auth.profile?.firstName ?? store.auth.userInfos.firstName,
						lastName: userLastname ?? store.auth.profile?.lastName ?? store.auth.userInfos.lastName,
						email: userMail ?? store.auth.profile?.email ?? store.auth.userInfos.email
					};

					formBody.append(
						'data',
						JSON.stringify({
							subject,
							body,
							spaceId: activeSpace.spaceId,
							id,
							type,
							userInfos: user
						})
					);

					const res = await await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/mailhistories/sendusermail`,
						method: 'POST',
						body: formBody
					});

					if (res?.id) {
						showToast('success', i18next.t('Mail Sent'));
						return true;
					}
					showToast('error', i18next.t('Could not send Mail'));
					return false;
				}

				return undefined;
			} catch (error) {
				console.log('sendMail', error);
			}
		},
		async loadMailSettings(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					return await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/getmailsettings`,
						method: 'POST',
						body
					});
				}
			} catch (error) {
				console.log('loadMailSettings', error);
			}
		},
		async updateMailSettings(payload: IUpdateMailSettingsPayload, store) {
			try {
				const { values } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							mailSettings: payload.values,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					let url = `${MULTISPACEURL}/mailsettings`;
					let method: TRestApiMethod = 'POST';

					if (values.id) {
						url += `/${values.id}`;
						method = 'PUT';
					}

					const res = await dispatch.request.anonymousRequest({
						url,
						method,
						body
					});

					if (res?.id) {
						showToast('success', i18next.t('Settings Updated'));
						return res;
					}
					showToast('error', 'Error', translateStrapiError(res));
				}
			} catch (error) {
				console.log('updateMailSettings', error);
			}
		},
		async getContentflowMedia(payload: IGetContentFlowPayload, store) {
			try {
				const { contentflowApiKey, contentflowCorporationId } = payload;

				const streams: IContentFlowMediaEntry[] = await dispatch.content.getContentflowStreams({
					contentflowApiKey,
					contentflowCorporationId
				});
				const videos: IContentFlowMediaEntry[] = await dispatch.content.getContentflowVideos({
					contentflowApiKey,
					contentflowCorporationId
				});

				return {
					streams,
					videos
				};
			} catch (error) {
				console.log('', error);
				return {
					streams: [],
					videos: []
				};
			}
		},
		async getContentflowStreams(payload: IGetContentFlowPayload, store) {
			try {
				const { contentflowApiKey, contentflowCorporationId } = payload;

				let streams: IContentFlowMediaEntry[] = [];

				const res = await fetch(`https://api.contentflow.net/v1/corporations/${contentflowCorporationId}/outputs`, {
					method: 'GET',
					headers: {
						Authorization: `Token ${contentflowApiKey}`
					}
				}).then((response) => response.json());

				if (res && Array.isArray(res)) {
					streams = res.map((stream) => {
						return {
							url: getContentFlowIFrameURL(stream.id),
							title: stream.name
						};
					});
				}

				return streams;
			} catch (error) {
				console.log('', error);
				return [];
			}
		},
		async getContentflowVideos(payload: IGetContentFlowPayload, store) {
			try {
				const { contentflowApiKey, contentflowCorporationId } = payload;

				let videos: IContentFlowMediaEntry[] = [];

				const res = await fetch(`https://api.contentflow.net/v1/corporations/${contentflowCorporationId}/medias`, {
					method: 'GET',
					headers: {
						Authorization: `Token ${contentflowApiKey}`
					}
				}).then((response) => response.json());

				if (res && Array.isArray(res)) {
					videos = res.map((video) => {
						return {
							url: getContentFlowVideoUrl(video.id),
							title: video.name
						};
					});
				}

				return videos;
			} catch (error) {
				console.log('', error);
				return [];
			}
		},
		async loadPublicAgenda(payload: ILoadPublicAgendaPayload, store) {
			try {
				const { spaceId } = payload;
				if (spaceId) {
					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/spaces/multispaceurl/${spaceId}`,
						method: 'GET'
					});
					if (res?.status === 200 && !isEmptyString(res?.multispaceUrl)) {
						const res2 = await dispatch.request.anonymousRequest({
							url: `${MULTISPACEURL}/schedules/publicagenda/${spaceId}`,
							method: 'GET'
						});
						if (res2.status === 200) {
							const result: any[] = [];
							result.push({
								items: res2.schedule,
								type: 'schedules'
							});
							result.push({
								items: res2.schedulestatus,
								type: 'schedulestatuses'
							});
							result.push({
								items: res2.speaker,
								type: 'speakers'
							});
							result.push({
								items: res2.stage,
								type: 'stages'
							});
							dispatch.content.addContent({ load: false, content: result });
						}

						return res2;
					}
					return res;
				}
			} catch (error) {
				console.log('loadPublicAgenda', error);
			}
		},
		async refillRedis(payload: IRefillRedisPayload, store) {
			const { onlyCurrentSpace } = payload;

			const space = dispatch.temp.getActiveSpace({});

			const body = new FormData();
			body.append(
				'data',
				JSON.stringify({
					spaceIds: onlyCurrentSpace ? (space ? [space.spaceId] : undefined) : undefined,
					userInfos: store.auth.userInfos,
					jwt: store.auth.jwt
				})
			);

			await dispatch.request.authenticatedRequest({
				method: 'POST',
				url: `${MULTISPACEURL}/content/fillredis`,
				body
			});
		},
		async clearAllContent(payload, store) {
			dispatch.temp.clearHasLoadedDataAndWaitingForSocketResponse();
			dispatch.attendee.clear();
			dispatch.content.clear();
			dispatch.chat.clear();
			dispatch.meeting.clear();
			dispatch.networking.clear();
			dispatch.pushNotification.clear();
			dispatch.vote.clear();
			dispatch.upload.clear();
			dispatch.vote.clear();
			dispatch.booking.clear();
			dispatch.comment.clear();
		},
		getLinkedImage(payload, store) {
			const { type, itemId } = payload;

			if (type && itemId) {
				let _linkedImage;
				switch (type) {
					case 'expos':
						_linkedImage = store.content.content.expos.find((e) => e.id === itemId)?.logo;
						break;
					case 'mediaitems':
						_linkedImage = store.content.content.mediaitems.find((e) => e.id === itemId)?.previewImage;
						break;
					case 'newsitems':
						const _item = store.content.content.newsitems.find((e) => e.id === itemId);
						_linkedImage = _item?.media?.find(
							(e) => e.media && typeof e.media === 'object' && e.media.mime?.includes('image')
						)?.media;
						break;
					case 'schedules':
						_linkedImage = store.content.content.schedules.find((e) => e.id === itemId)?.previewImage;
						break;
					case 'speakers':
						_linkedImage = store.content.content.speakers.find((e) => e.id === itemId)?.image;
						break;
					case 'votings':
						// Votings has no usable image
						// Media belongs to the question itself and should not be used here
						break;
					default:
						break;
				}

				if (_linkedImage && typeof _linkedImage === 'object') {
					return _linkedImage.formats?.medium?.url ?? _linkedImage.url;
				}
			}

			return undefined;
		},
		async startWebhooksSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (
					activeSpace &&
					(isAdmin || isModerator) &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_webhooks`)
				) {
					contentLoadLog(3, 'starting webhooks sync');
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_webhooks`, async (data) => {
						let load = !Array.isArray(data) && data.load;
						let _items = Array.isArray(data) ? data : data.items;

						if (load) {
							contentLoadLog(4, 'webhooks received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'webhooks', value: false });
							if (!store.temp.hasLoadedData?.webhooks) {
								dispatch.temp.setHasLoadedData('webhooks');
							}
						}

						if (_items.length > 0) {
							dispatch.content.addWebhooks({ load, webhooks: _items });
						}
					});
				}
			} catch (error) {
				console.log('startChangelogSync', error);
			}
		},
		async loadWebhooksDelta(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const lastRead = store.content.lastWebhooksRead ? { ...store.content.lastWebhooksRead } : {};

				if (activeSpace) {
					if (!store.temp.hasLoadedData || store.temp.hasLoadedData.webhooks) {
						contentLoadLog(3, 'webhooks already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse && store.temp.waitingForSocketResponse.webhooks) {
						contentLoadLog(3, 'webhooks load already in progress. skipping');
						return;
					}

					switch (DATA_LOAD_STRATEGY) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading webhooks');
							dispatch.temp.setWaitingForSocketResponse({ key: 'webhooks', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'webhooks',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: lastRead[activeSpace.spaceId] ?? ZEROHOUR
								}
							});

							break;
						case EDataLoadStrategy.Default:
						default:
							// TODO
							break;
					}
				}
			} catch (error) {
				console.log('loadWebhooksDelta', error);
			}
		},
		async createWebhook(payload: ICreateWebhookPayload, store) {
			const { webhook } = payload;

			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					newBody.append(
						'data',
						JSON.stringify({
							webhook: { ...webhook, spaceId: activeSpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/webhooks`,
						method: 'POST',
						body: newBody
					});

					if (res?.id) {
						showToast('success', i18next.t('Webhook Created'));
						return true;
					}

					showToast('error', i18next.t('Could not create Webhook'));
					return false;
				}
			} catch (error) {
				console.log('createWebhook', error);
			}
		},
		async updateWebhook(payload: IUpdateWebhookPayload, store) {
			const { webhook, isDeletion } = payload;

			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const newBody = new FormData();

					newBody.append('data', JSON.stringify({ webhook, spaceId: activeSpace.spaceId, userInfos: store.auth.userInfos }));

					const res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/webhooks/${webhook.id}`,
						method: 'PUT',
						body: newBody
					});

					if (res?.id) {
						showToast('success', isDeletion ? i18next.t('Webhook deleted') : i18next.t('Webhook updated'));
						return true;
					}
					showToast('error', isDeletion ? i18next.t('Could not delete Webhook') : i18next.t('Could not update Webhook'));
					return false;
				}
			} catch (error) {
				console.log('updateWebhook', error);
			}
		},
		async loadWebhookHistory(payload, store) {
			try {
				const { webhookId } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							webhookId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);
					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/webhooks/history/`,
						body
					});

					if (res && Array.isArray(res)) {
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return [];
			} catch (error) {
				console.log('loadWebhookHistory', error);
				return [];
			}
		}
	})
});
