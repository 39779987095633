import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, PermissionsAndroid, View, ScrollView } from 'react-native';
import ViewShot from 'react-native-view-shot';
import * as MediaLibrary from 'expo-media-library';
import { HSModal } from '../Modal';
import { RoundButton } from 'components/Button';
import { H1, Text } from 'components/Text';
import { addToClipboard, EDefaultIconSet, normalizeFontSize } from 'helper';
import { exportRefAsPNG, share } from 'helper/share';
import { IS_WEB } from 'helper/platform';
import { useSpace } from 'hooks/useSpace';
import { hsTopScreenPadding } from 'config/styleConstants';
import { HSQRCode } from 'components/QRCode';
interface IQRCodeShowCodeModal {
	isVisible: boolean;
	onClose: () => void;
	title?: string;
	value: string;
	cta?: string;
}

export const QRCodeShowCodeModal = (props: IQRCodeShowCodeModal) => {
	const { isVisible, onClose, title, value, cta } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const componentRef = useRef<ViewShot>(null);

	const [qrCapture, setQrCapture] = useState<string>('');
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [containerHeight, setContainerHeight] = useState<number>(1);
	const [isDownloadModalVisible, setIsDownloadModalVisible] = useState<boolean>(false);

	const _shareQR = async () => {
		if (qrCapture) {
			share(activeSpace?.title ?? '', activeSpace?.title ?? '', qrCapture, activeSpace?.description ?? t('ShareMessageVote'));
		}
	};

	const _saveQR = async () => {
		if (IS_WEB) {
			setIsDownloadModalVisible(true);
			exportRefAsPNG(componentRef, `qrcode_${activeSpace?.spaceId}`);
			setIsDownloadModalVisible(false);
		} else {
			try {
				const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE);
				if (granted === PermissionsAndroid.RESULTS.GRANTED) {
					if (qrCapture) {
						const asset = await MediaLibrary.createAssetAsync(qrCapture);
						if (!asset) {
							return;
						}
						const album = await MediaLibrary.getAlbumAsync('HelloSpaces');
						if (album) {
							MediaLibrary.addAssetsToAlbumAsync([asset], album, false);
						} else {
							const created = await MediaLibrary.createAlbumAsync('HelloSpaces', asset, false);
						}
					}
				} else {
					Alert.alert(t('MissingMediaLibraryPermissionTitle'), t('MissingPermissionSubtitle'));
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	const _renderButtons = () => {
		return (
			<View
				style={{
					flexDirection: IS_WEB ? 'row' : 'column',
					marginVertical: 10,
					alignSelf: IS_WEB ? 'center' : undefined,
					justifyContent: 'space-between',
					flexWrap: 'wrap'
				}}
			>
				<RoundButton
					title={t('copyUrl')}
					testID="spaceadmin_url share"
					onPress={() => addToClipboard(value)}
					icon={EDefaultIconSet.Clone}
				/>
				{!IS_WEB && (
					<RoundButton
						title={t('shareAndroid')}
						testID="spaceadmin_qr_save"
						onPress={() => _shareQR()}
						icon={EDefaultIconSet.Share}
					/>
				)}
				<RoundButton title={t('saveAndroid')} testID="spaceadmin_qr_save" onPress={() => _saveQR()} icon={EDefaultIconSet.QRCode} />
			</View>
		);
	};

	const ComponentToPrint = React.forwardRef<ViewShot>((props, ref) => {
		let qrSize = containerHeight < containerHeight ? containerHeight * 0.8 : containerWidth * 0.8;

		if (IS_WEB) qrSize = qrSize * 0.8;

		return (
			<ViewShot
				ref={ref}
				options={{ format: 'png' }}
				style={{ alignItems: 'center' }}
				onCapture={(uri) => setQrCapture(uri)}
				captureMode="mount"
			>
				<HSQRCode value={value} size={qrSize} />
			</ViewShot>
		);
	});

	const _renderContent = () => {
		return (
			<ScrollView
				style={{ flex: 1 }}
				contentContainerStyle={{ justifyContent: 'space-between' }}
				onLayout={(e) => {
					setContainerWidth(e.nativeEvent.layout.width);
					setContainerHeight(e.nativeEvent.layout.height);
				}}
			>
				<View style={{ flex: 1, alignSelf: 'center', justifyContent: 'center' }}>
					<H1 center>QR Code - {title}</H1>
					<ComponentToPrint ref={componentRef} />
					<Text center style={{ fontSize: 10, marginTop: 10 }}>
						{value}
					</Text>
					{cta && (
						<Text center style={{ fontSize: normalizeFontSize(24), marginTop: hsTopScreenPadding }}>
							{cta}
						</Text>
					)}
				</View>
				<View>{_renderButtons()}</View>
			</ScrollView>
		);
	};

	return (
		<View>
			{isVisible && (
				<HSModal isVisible={isVisible} onClose={onClose}>
					{_renderContent()}
				</HSModal>
			)}
			<HSModal isVisible={isDownloadModalVisible} onClose={() => setIsDownloadModalVisible(false)}></HSModal>
		</View>
	);
};
