import React from 'react';
import { TVotingType } from 'config/interfaces/vote';
import { getIconByVoteType } from 'helper';
import { Dimensions, View, ViewStyle } from 'react-native';
import { RoundButton } from 'components/Button/RoundButton';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text';

interface IVoteIcon {
	voteType: TVotingType;
	testID: string;
	onPress?: () => void;
	alignSelf?: ViewStyle['alignSelf'];
	isActive?: boolean;
	hideText?: boolean;
	containerStyle?: ViewStyle;
	smallerSize?: boolean;
	isCardLayout?: boolean;
}

export const VoteIcon = (props: IVoteIcon) => {
	const { voteType, testID, onPress, alignSelf, isActive, hideText, containerStyle, smallerSize, isCardLayout } = props;
	const { t } = useTranslation();

	const getTitle = () => {
		switch (voteType) {
			case 'text':
				return t('chat');
			case 'multipleChoice':
				if (
					!isCardLayout &&
					((containerStyle?.maxWidth && containerStyle?.maxWidth < 100) || Dimensions.get('window').width < 650)
				) {
					return t(voteType).replace(' ', '\n');
				}
			case 'externalUrl':
				if (
					!isCardLayout &&
					((containerStyle?.maxWidth && containerStyle?.maxWidth < 90) || Dimensions.get('window').width < 650)
				) {
					return t(voteType).replace(' ', '\n');
				}
			case 'pinOnImage':
				if (
					!isCardLayout &&
					((containerStyle?.maxWidth && containerStyle?.maxWidth < 90) || Dimensions.get('window').width < 650)
				) {
					let _votetype = t(voteType).split(' ');
					if (_votetype.length === 3) {
						return _votetype[0] + ' ' + _votetype[1] + '\n' + _votetype[2];
					}
					return t(voteType).replace(' ', '\n');
				}
			case 'happinessOMeter':
				if (
					!isCardLayout &&
					((containerStyle?.maxWidth && containerStyle?.maxWidth < 90) || Dimensions.get('window').width < 650)
				) {
					return t(voteType).replace('-', '-\n');
				}
			default:
				if (!isCardLayout && Dimensions.get('window').width < 520) {
					return t(voteType).replace(' ', '\n');
				}
				return t(voteType);
		}
	};

	if (isCardLayout) {
		return (
			<View style={{ ...containerStyle, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginLeft: -5 }}>
				<RoundButton
					isOutline={!isActive}
					testID={`${testID}_voteicon_${voteType}`}
					icon={getIconByVoteType(voteType)}
					isDisabled={!onPress}
					onPress={onPress}
					alignSelf={alignSelf ?? 'center'}
					size={smallerSize ? 'xs' : undefined}
					noMargin
					//title={getTitle()}
				/>
				{!hideText && (
					<Text
						center
						style={{
							marginLeft: 5,
							alignSelf: 'center',
							alignItems: 'center',
							justifyContent: 'center'
						}}
						adjustsFontSizeToFit
						numberOfLines={1}
					>
						{getTitle()}
					</Text>
				)}
			</View>
		);
	}

	return (
		<View style={{ ...containerStyle, alignItems: 'center', justifyContent: 'center' }}>
			<RoundButton
				isStacked
				isOutline={!isActive}
				testID={`${testID}_voteicon_${voteType}`}
				icon={getIconByVoteType(voteType)}
				isDisabled={!onPress}
				onPress={onPress}
				alignSelf={alignSelf ?? 'center'}
				size={smallerSize ? 'xs' : undefined}
				//title={getTitle()}
			/>
			{!hideText && (
				<Text
					center
					style={{
						alignSelf: 'center',
						alignItems: 'center',
						justifyContent: 'center',
						maxWidth: containerStyle?.maxWidth ?? '80%',
						flexWrap: 'wrap'
					}}
					adjustsFontSizeToFit
					numberOfLines={getTitle().includes('\n') ? 2 : 1}
				>
					{getTitle()}
				</Text>
			)}
		</View>
	);
};
