import { Avatar } from 'components/User';
import { IAttendee } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IRosterMember } from '.';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { EDefaultIconSet } from 'helper';

interface INetworkingRoomAttendeeAudio {
	rosterEntry: IRosterMember;
}

export const NetworkingRoomAttendeeAudio = (props: INetworkingRoomAttendeeAudio) => {
	const { rosterEntry } = props;

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (attendees) {
			_attendee = attendees.find((e) => e.userId === rosterEntry.boundExternalUserId);
		}

		setAttendee(_attendee);
	}, [rosterEntry, attendees]);

	const _renderAttendee = () => {
		if (attendee) {
			return (
				<View style={{ alignItems: 'center' }}>
					<Avatar avatar={attendee?.smallImageUrl ?? attendee.imageUrl} fullName={`${attendee.firstName} ${attendee.lastName}`} />
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<Icon name={rosterEntry.isMuted ? EDefaultIconSet.MicrophoneMuted : EDefaultIconSet.Microphone} />
						<Text>{`${attendee.firstName} ${attendee.lastName}`}</Text>
					</View>
				</View>
			);
		}

		return null;
	};

	return <View style={{ marginRight: 5 }}>{_renderAttendee()}</View>;
};
