import { RouteProp } from '@react-navigation/core';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { SelfServiceForm } from 'components/Forms';
import { Markdown } from 'components/Markdown';
import {
	NoSpaceStackParamList,
	ERoutes,
	NavigationHeader,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton
} from 'components/Navigation';
import { NoData, TNoDataType } from 'components/NoData';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import {
	getTrackingActions,
	IAttendee,
	IContentTypeField,
	IExpo,
	ILauncherSpace,
	IMediaItem,
	IReport,
	ISchedule,
	ISpeaker,
	TTrackingAction,
	TTrackingContentType
} from 'config/interfaces';
import { hsBorderRadius, hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, generateTheme, isEmptyString, showFormErrorToast, validateForm } from 'helper';

import { TContentType } from 'hooks/useContent';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text';
import { ExpoDetail } from 'components/Expo';
import { SpeakerDetail } from 'components/Speaker';
import { NumberPresenter } from 'components/Info';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SelfService>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SelfService>;
type RouteParams = NoSpaceStackParamList[ERoutes.SelfService];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'selfservice';

interface IDefaultReports {
	visitorsAll: IReport[];
	visitorsYesterday: IReport[];
	interactionsAll: IReport[];
	interactionsYesterday: IReport[];
}

export const SelfServiceScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { getFormSchema } = useForm();
	const { t } = useTranslation();
	const { exportSelfServiceReports } = useExport();
	const { theme } = useTheme();

	const [isResolveTokenLoading, setIsResolveTokenLoading] = useState<boolean>(false);
	const [tokenError, setTokenError] = useState<TNoDataType | undefined>(undefined);
	const [contentType, setContentType] = useState<TContentType | undefined>(undefined);
	const [formFields, setFormFields] = useState<IContentTypeField[]>([]);
	const [formValues, setFormValues] = useState<Record<string, any> | undefined>(undefined);
	const [formErrors, setFormErrors] = useState<Record<string, string>>({});
	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [spaceAttendees, setSpaceAttendees] = useState<IAttendee[]>([]);

	const [referencedMediaItems, setReferencedMediaItems] = useState<IMediaItem[]>([]);
	const [referencedSpeakers, setReferencedSpeakers] = useState<ISpeaker[]>([]);
	const [referencedSchedules, setReferencedSchedules] = useState<ISchedule[]>([]);
	const [referencedExpos, setReferencedExpos] = useState<IExpo[]>([]);
	const [referencedAttendees, setReferencedAttendees] = useState<IAttendee[]>([]);

	const [detailFields, setDetailFields] = useState<IContentTypeField[]>([]);

	const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

	const [activeTab, setActiveTab] = useState<string>('form');
	const [defaultReports, setDefaultReports] = useState<IDefaultReports | undefined>(undefined);
	const [isLoadReportsLoading, setIsLoadReportsLoading] = useState<boolean>(false);

	const { getSelfServiceFields } = useContent(contentType);

	const resolveSelfServiceToken = useRematchDispatch((dispatch: Dispatch) => dispatch.selfservice.resolveSelfServiceToken);
	const updateSelfServiceEntry = useRematchDispatch((dispatch: Dispatch) => dispatch.selfservice.updateSelfServiceEntry);
	const loadReports = useRematchDispatch((dispatch: Dispatch) => dispatch.tracking.loadReports);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const addContent = useRematchDispatch((dispatch: Dispatch) => dispatch.content.addContent);
	const setSelfServiceToken = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setSelfServiceToken);

	useEffect(() => {
		if (route?.params?.token) {
			_resolveToken();
		}
	}, [route?.params?.token]);

	useEffect(() => {
		let _formFields: IContentTypeField[] = [];
		let _fields: IContentTypeField[] = [];

		switch (contentType) {
			case 'speaker':
				_formFields = getSelfServiceFields(space?.speakerFields?.fields ?? []);
				_fields =
					_formFields?.filter((e) => e.showOnDetailScreen || e.fieldType === 'section' || e.fieldType === 'mainsection') ?? [];
				break;
			case 'expo':
				_formFields = getSelfServiceFields(space?.expoFields?.fields ?? []);
				_fields =
					_formFields?.filter((e) => e.showOnDetailScreen || e.fieldType === 'section' || e.fieldType === 'mainsection') ?? [];
				break;
			default:
				break;
		}

		setFormFields(_formFields);
		setDetailFields(_fields);
	}, [contentType, space]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateEntry(),
			isLoading: isUpdateLoading,
			isDisabled: _isSaveDisabled()
		});
	}, [formValues, isUpdateLoading, space]);

	const _loadReports = async () => {
		if (contentType && formValues && space) {
			const actions: TTrackingAction[] = [];
			getTrackingActions(contentType as TTrackingContentType).forEach((e) => {
				if (e.key) {
					actions.push(e.key as TTrackingAction);
				}
			});
			setIsLoadReportsLoading(true);

			const res: IReport[] = await loadReports({
				type: contentType as TTrackingContentType,
				itemIds: [formValues.id],
				actions,
				from: space.created_at,
				until: moment().subtract(1, 'day').set('hours', 23).set('minutes', 59).set('seconds', 59).toISOString(),
				token: decodeURIComponent(route.params.token),
				space
			});

			const visitorsAll: IReport[] = res?.filter((item) => item.action === 'Open Detail');
			const visitorsYesterday: IReport[] = visitorsAll?.filter((e) => moment(e.date).isSame(moment().subtract(1, 'day'), 'date'));
			const interactionsAll: IReport[] = [...res];
			const interactionsYesterday: IReport[] = interactionsAll?.filter((e) =>
				moment(e.date).isSame(moment().subtract(1, 'day'), 'date')
			);

			setDefaultReports({
				visitorsAll,
				visitorsYesterday,
				interactionsAll,
				interactionsYesterday
			});

			setIsLoadReportsLoading(false);
		}
	};

	const _resolveToken = async () => {
		setIsResolveTokenLoading(true);
		const {
			error,
			type,
			item,
			space,
			attendees,
			referencedContent
		}: { error: TNoDataType; type: TContentType; item: any; space: ILauncherSpace; attendees: IAttendee[]; referencedContent: any[] } =
			await resolveSelfServiceToken({
				token: decodeURIComponent(route.params.token)
			});
		if (space) {
			setTheme(generateTheme(space));
		}
		setTokenError(error);
		setContentType(type);
		setSpace(space);
		if (!isEmptyString(space?.language)) {
			i18next.changeLanguage(space.language);
		}
		setSpaceAttendees(attendees);
		if (item) {
			setSelfServiceToken(decodeURIComponent(route.params.token));
			navigation.setOptions({ title: item.title });
			let referenceFields: IContentTypeField[] = [];
			let singeReferenceFields: IContentTypeField[] = [];

			switch (type) {
				case 'speaker':
					referenceFields = space.speakerFields?.fields?.filter((e) => e.fieldType === 'reference') ?? [];
					singeReferenceFields = space.speakerFields?.fields?.filter((e) => e.fieldType === 'singlereference') ?? [];

					break;
				case 'expo':
					referenceFields = space.expoFields?.fields?.filter((e) => e.fieldType === 'reference') ?? [];
					singeReferenceFields = space.expoFields?.fields?.filter((e) => e.fieldType === 'singlereference') ?? [];

					break;
				default:
					break;
			}

			addContent({ load: false, content: referencedContent });

			const _referencedMediaItems = item?.mediaitems?.filter((m) => !m.isDeleted) ?? [];
			const _referencedSpeakers = item?.speakers?.filter((m) => !m.isDeleted) ?? [];
			const _referencedSchedules = item?.schedules?.filter((m) => !m.isDeleted) ?? [];
			const _referencedExpos = item?.expos?.filter((m) => !m.isDeleted) ?? [];
			const _referencedAttendees = item?.attendees?.filter((m) => !m.isDeleted) ?? [];
			setReferencedMediaItems(_referencedMediaItems);
			setReferencedSpeakers(_referencedSpeakers);
			setReferencedSchedules(_referencedSchedules);
			setReferencedExpos(_referencedExpos);
			setReferencedAttendees(_referencedAttendees);

			referenceFields.forEach((field) => {
				if (item[field.fieldName]) {
					item[field.fieldName] = item[field.fieldName].map((e) => e.id);
				}
			});

			singeReferenceFields.forEach((field) => {
				if (item[field.fieldName]) {
					item[field.fieldName] = item[field.fieldName].id;
				}
			});
		}

		setFormValues(item);
		setIsResolveTokenLoading(false);
	};

	const _updateEntry = async () => {
		if (formValues && space) {
			const errors = await validateForm(getFormSchema(formFields, formValues), formValues);
			if (errors) {
				setFormErrors(errors);
				showFormErrorToast(errors, formFields);
				return;
			} else {
				setFormErrors({});
			}
			setIsUpdateLoading(true);
			const res = await updateSelfServiceEntry({
				token: decodeURIComponent(route.params.token),
				fields: formFields,
				item: {
					...formValues
				},
				space
			});
			setIsUpdateLoading(false);
			if (res) {
				_resolveToken();
			}
		}
	};

	const _isSaveDisabled = () => {
		switch (contentType) {
			case 'expo':
				return (
					space?.selfServiceSettings?.expoSelfServiceSettings?.editable === 'readOnly' ||
					formValues?.selfServiceEditable === 'readOnly'
				);
			case 'speaker':
				return (
					space?.selfServiceSettings?.speakerSelfServiceSettings?.editable === 'readOnly' ||
					formValues?.selfServiceEditable === 'readOnly'
				);
			default:
				return false;
		}
	};

	const _renderIntroText = () => {
		let introText;
		switch (contentType) {
			case 'expo':
				if (!isEmptyString(space?.selfServiceSettings?.expoSelfServiceSettings?.introText)) {
					introText = space?.selfServiceSettings?.expoSelfServiceSettings?.introText;
				}
				break;
			case 'speaker':
				if (!isEmptyString(space?.selfServiceSettings?.speakerSelfServiceSettings?.introText)) {
					introText = space?.selfServiceSettings?.speakerSelfServiceSettings?.introText;
				}
				break;
			default:
				break;
		}

		if (!isEmptyString(introText)) {
			return (
				<HSCard>
					<Markdown markdown={introText} />
				</HSCard>
			);
		}

		return null;
	};

	const getCount = (reports: IReport[]) => {
		let sum = 0;

		reports.forEach((report) => {
			if (report?.data?.userIds) {
				sum += report.data?.userIds.length;
			}
		});

		return sum;
	};

	const _renderNumberPresentor = () => {
		if (defaultReports && space && formValues) {
			return (
				<View>
					<View style={{ flexDirection: 'row' }}>
						<NumberPresenter
							cardStyle={{ flex: 1, marginRight: 5 }}
							number={getCount(defaultReports.visitorsAll)}
							title={t('visitorsAll')}
							buttonText={t('Download')}
							icon={EDefaultIconSet.Download}
							onButtonPress={() =>
								exportSelfServiceReports(defaultReports.visitorsAll, space, spaceAttendees, formValues.selfServiceEmail)
							}
						/>
						<NumberPresenter
							cardStyle={{ flex: 1, marginLeft: 5 }}
							number={getCount(defaultReports.visitorsYesterday)}
							title={t('visitors24h')}
							buttonText={t('Download')}
							icon={EDefaultIconSet.Download}
							onButtonPress={() =>
								exportSelfServiceReports(
									defaultReports.visitorsYesterday,
									space,
									spaceAttendees,
									formValues.selfServiceEmail
								)
							}
						/>
					</View>
					<View style={{ flexDirection: 'row' }}>
						<NumberPresenter
							cardStyle={{ flex: 1, marginRight: 5 }}
							number={getCount(defaultReports.interactionsAll)}
							title={t('interactionsAll')}
							buttonText={t('Download')}
							icon={EDefaultIconSet.Download}
							onButtonPress={() =>
								exportSelfServiceReports(defaultReports.interactionsAll, space, spaceAttendees, formValues.selfServiceEmail)
							}
						/>
						<NumberPresenter
							cardStyle={{ flex: 1, marginLeft: 5 }}
							number={getCount(defaultReports.interactionsYesterday)}
							title={t('interactions24h')}
							buttonText={t('Download')}
							icon={EDefaultIconSet.Download}
							onButtonPress={() =>
								exportSelfServiceReports(
									defaultReports.interactionsYesterday,
									space,
									spaceAttendees,
									formValues.selfServiceEmail
								)
							}
						/>
					</View>
				</View>
			);
		}

		return (
			<HSCard>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_loadreports`}
					icon={EDefaultIconSet.Analytics}
					onPress={_loadReports}
					isLoading={isLoadReportsLoading}
					title={t('Load Reports')}
					alignSelf="center"
				/>
				<Text center>{t('SelfServiceLoadReportsHint')}</Text>
			</HSCard>
		);
	};

	const _renderReportForm = () => {
		if (contentType) {
			return <View>{_renderNumberPresentor()}</View>;
		}

		return null;
	};

	const _renderTabs = () => {
		const tabs = [
			{
				key: 'form',
				label: t('Form')
			}
		];

		tabs.push({
			key: 'preview',
			label: t('Preview')
		});

		if (space?.selfServiceSettings) {
			switch (contentType) {
				case 'expo':
					if (space.selfServiceSettings.expoSelfServiceSettings?.allowReports) {
						tabs.push({
							key: 'reports',
							label: 'Reports'
						});
					}
					break;
				case 'speaker':
					if (space.selfServiceSettings.speakerSelfServiceSettings?.allowReports) {
						tabs.push({
							key: 'reports',
							label: 'Reports'
						});
					}
					break;
				default:
					break;
			}
		}

		return (
			<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: hsBottomMargin }}>
				{tabs.map((tab) => (
					<ChildButton
						key={`${TESTIDPREFIX}_button_${tab.key}`}
						testID={`${TESTIDPREFIX}_button_${tab.key}`}
						style={{
							paddingHorizontal: 10,
							paddingVertical: 5,
							marginRight: 10,
							backgroundColor: activeTab === tab.key ? theme.primary : theme.background,
							borderRadius: hsBorderRadius,
							borderWidth: 1,
							borderColor: theme.primary
						}}
						onPress={() => setActiveTab(tab.key)}
					>
						<Text bold={activeTab === tab.key} style={{ color: activeTab === tab.key ? theme.primaryContrast : theme.primary }}>
							{tab.label}
						</Text>
					</ChildButton>
				))}
			</View>
		);
	};

	const _renderFormOrReports = () => {
		if (contentType && formValues && space) {
			switch (activeTab) {
				case 'reports':
					return <View style={{ flex: 1 }}>{_renderReportForm()}</View>;
				case 'preview':
					switch (contentType) {
						case 'speaker':
							return (
								<SpeakerDetail
									testID={TESTIDPREFIX}
									speaker={formValues}
									detailFields={detailFields}
									isSelfService
									space={space}
									referencedExpos={referencedExpos}
									referencedMediaItems={referencedMediaItems}
									referencedSchedules={referencedSchedules}
									// referencedAttendee={formValues.attendee}
									noScreenPaddings
								/>
							);
						case 'expo':
							return (
								<ExpoDetail
									testID={TESTIDPREFIX}
									expo={formValues}
									detailFields={detailFields}
									isSelfService
									space={space}
									referencedAttendees={referencedAttendees}
									referencedSpeakers={referencedSpeakers}
									referencedMediaItems={referencedMediaItems}
									referencedSchedules={referencedSchedules}
									noScreenPaddings
								/>
							);
						default:
							return null;
					}
				case 'mydata':
				default:
					return (
						<View style={{ flex: 1 }}>
							{_renderIntroText()}

							<SelfServiceForm
								contentType={contentType}
								item={formValues}
								errors={formErrors}
								onChange={(values) => setFormValues(values)}
								fields={formFields}
								space={space}
							/>
						</View>
					);
			}
		}

		return null;
	};

	const _renderContent = () => {
		if (isResolveTokenLoading) {
			return (
				<HSCard style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner />
				</HSCard>
			);
		}

		if (!isEmptyString(tokenError)) {
			return <NoData type={tokenError} />;
		}

		if (contentType && formValues && space) {
			return (
				<View style={{ flex: 1 }}>
					{_renderTabs()}
					{_renderFormOrReports()}
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer bgImage={space?.backgroundImage} bgImageName={space?.backgroundImageName}>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderContent()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const SelfServiceScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			{navigation.canGoBack() ? <NavigationHeaderBackButton /> : <NavigationHeaderPlaceholder />}
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options.isLoading}
				isDisabled={props.options.isDisabled}
			/>
		</NavigationHeader>
	);
};
