import { IS_WEB } from 'helper/platform';
import { useEffect, useRef, useState } from 'react';
import { Dimensions } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as Device from 'expo-device';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { t } from 'i18next';
import { ERoutes } from 'components/Navigation/routes';
import { alert } from 'rematch/models/alert';
import { isEmptyObject } from 'helper/object';

const MOBILE_BREAKPOINT = 1224;
const SMALL_BREAKPOINT = 1600;
const FULLHD_BREAKPOINT = 1920;

export const useQuery = () => {
	const isTabletOrMobileRef = useRef(Dimensions.get('window').width <= MOBILE_BREAKPOINT);

	const [deviceType, setDeviceType] = useState<Device.DeviceType>(Device.DeviceType.UNKNOWN);
	const [isTabletOrMobile, setIsTabletOrMobile] = useState<boolean>(Dimensions.get('window').width <= MOBILE_BREAKPOINT);
	const [isPortrait, setIsPortrait] = useState<boolean>(true);
	const [screenWidth, setScreenWidth] = useState<number | string>(Dimensions.get('window').width <= MOBILE_BREAKPOINT ? '100%' : '60%');
	const [screenColumnCount, setScreenColumnCount] = useState<number | undefined>(undefined);
	const [screenColumnCountSmallItems, setScreenColumnCountSmallItems] = useState<number | undefined>(undefined);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const hideAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.hideAlert);
	const currentView = isTabletOrMobile ? 'mobile' : 'desktop';

	const pathnamesWithoutResize = [
		ERoutes.PublicAgenda,
		ERoutes.Landingpage,
		ERoutes.SpaceOnboarding,
		ERoutes.Onboarding,
		ERoutes.ResultView,
		ERoutes.ExternalMaps,
		'presentation'
	];

	useEffect(() => {
		if (IS_WEB) {
			window.addEventListener('resize', _handleResize);
			if (!isEmptyObject(alert?.state?.alertData)) {
				hideAlert({});
			}
		}
		ScreenOrientation.addOrientationChangeListener((sub) => {
			const newOrientation = sub.orientationInfo.orientation;
			setIsPortrait(
				newOrientation === ScreenOrientation.Orientation.PORTRAIT_DOWN ||
					newOrientation === ScreenOrientation.Orientation.PORTRAIT_UP
			);
		});

		_getDeviceType();

		return () => {
			if (IS_WEB) {
				window.removeEventListener('resize', _handleResize);
			}
		};
	}, []);

	useEffect(() => {
		if (deviceType !== Device.DeviceType.UNKNOWN) {
			if (!screenColumnCount) {
				setScreenColumnCount(IS_WEB && !isTabletOrMobile ? 2 : deviceType === Device.DeviceType.TABLET ? 2 : 1);
			}

			if (!screenColumnCountSmallItems) {
				setScreenColumnCountSmallItems(IS_WEB && !isTabletOrMobile ? 4 : deviceType === Device.DeviceType.TABLET ? 3 : 2);
			}
		}
	}, [deviceType]);

	const getScreenType = (width: number) => {
		if (width <= MOBILE_BREAKPOINT) {
			return 'mobile';
		}
		if (width <= SMALL_BREAKPOINT) {
			return 'small';
		}
		if (width <= FULLHD_BREAKPOINT) {
			return 'fullhd';
		}
		return 'xl';
	};

	const [screenType, setScreenType] = useState<string>(getScreenType(Dimensions.get('window').width));
	const screenTypeRef = useRef(getScreenType(Dimensions.get('window').width));

	const _handleResize = (e: UIEvent) => {
		const pathname = window.location.pathname;
		const _hideAlert = pathnamesWithoutResize.some((path) => {
			if (pathname.includes(path)) {
				if (path === ERoutes.Onboarding && !pathname.match(/app\/[a-zA-Z0-9]+\/onboarding/g)) {
					return false;
				}
				return true;
			}
			return false;
		});

		if (_hideAlert) {
			return;
		}

		const newScreenType = getScreenType(Dimensions.get('window').width);
		if (newScreenType !== screenTypeRef.current) {
			screenTypeRef.current = newScreenType;
			setScreenType(newScreenType);
		}

		const isNowTabletOrMobile = Dimensions.get('window').width <= MOBILE_BREAKPOINT;

		if (isNowTabletOrMobile !== isTabletOrMobileRef.current) {
			isTabletOrMobileRef.current = isNowTabletOrMobile;
			if (isNowTabletOrMobile && currentView === 'desktop') {
				showAlert({
					title: t('Change View'),
					message: t('Change View Mobile'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							text: t('Change View'),
							onPress: () => {
								hideAlert({});
								window.location.reload();
							}
						}
					]
				});
			} else if (!isNowTabletOrMobile && currentView === 'mobile') {
				showAlert({
					title: t('Change View'),
					message: t('Change View Desktop'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							text: t('Change View'),
							onPress: () => {
								hideAlert({});
								window.location.reload();
							}
						}
					]
				});
			} else {
				hideAlert({});
			}
		}
	};

	const _getDeviceType = async () => {
		const type = await Device.getDeviceTypeAsync();
		setDeviceType(type);
	};

	return {
		isTabletOrMobile,
		isPortrait,
		MOBILE_BREAKPOINT,
		screenColumnCount,
		screenColumnCountSmallItems,
		screenWidth,
		isPhone: deviceType === Device.DeviceType.PHONE,
		isTablet: deviceType === Device.DeviceType.TABLET,
		isWeb: deviceType === Device.DeviceType.DESKTOP,
		screenType
	};
};
