import { createModel } from '@rematch/core';
import { MULTISPACEURL } from 'config/constants';
import { RootModel } from './index';

type IDefaultState = {};

export const internalsettings = createModel<RootModel>()({
	state: {} as IDefaultState,
	reducers: {},
	effects: (dispatch) => ({
		async getInternalSettings(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append('data', JSON.stringify({}));

					return await dispatch.request.authenticatedRequest({
						method: 'GET',
						url: `${MULTISPACEURL}/internalsettings/${activeSpace.spaceId}`
					});
				}

				return undefined;
			} catch (error) {
				console.log('getInternalSettings', error);
				return undefined;
			}
		},
		async setInternalSettings(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { settings } = payload;
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							settings,
							spaceId: activeSpace.spaceId
						})
					);

					return await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/internalsettings`,
						body
					});
				}

				return undefined;
			} catch (error) {
				console.log('setInternalSettings');
				return undefined;
			}
		}
	})
});
