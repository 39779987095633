import moment from 'moment';
import React from 'react';
import { View } from 'react-native';
import { FormDatePicker } from './FormDatePicker';
import { Text } from 'components/Text/Text';
import { FormHint } from './FormHint';
import { FormLabel } from './FormLabel';
import { FormElementBottomMarginSmall } from './constants';
import { useSpace } from 'hooks/useSpace';
import { isEmptyString } from 'helper';

interface IFormFromUntilDatePicker {
	testIdPrefix: string;
	label: string;
	hint?: string;
	fromLabel?: string;
	untilLabel?: string;
	fromFieldName: string;
	untilFieldName?: string;
	from?: string | null;
	until?: string | null;
	fromError?: string;
	untilError?: string;
	onChange: (fieldName: string, value: string | null | undefined) => void;
	onRangeChange?: (
		fromFieldName: string,
		fromValue: string | null | undefined,
		untilFieldName: string,
		untilValue: string | null | undefined
	) => void;
	isDisabled?: boolean;
	isRequired?: boolean;
	mode?: 'date' | 'time' | 'datetime';
	onError?: (fieldName: string, error: string | undefined) => void;
}

export const FormFromUntilDatePicker = (props: IFormFromUntilDatePicker) => {
	const {
		testIdPrefix,
		label,
		fromLabel,
		untilLabel,
		hint,
		fromFieldName,
		untilFieldName,
		from,
		until,
		fromError,
		untilError,
		onChange,
		isDisabled,
		isRequired,
		mode,
		onRangeChange,
		onError
	} = props;
	const { activeSpace } = useSpace();

	const _getFormat = () => {
		let format = 'DD.MM.YY HH:mm';
		switch (mode) {
			case 'datetime':
				format = 'DD.MM.YY HH:mm';
				break;
			case 'time':
				format = 'HH:mm';
				break;
			case 'date':
				format = 'DD.MM.YY';
			default:
				break;
		}
		return format;
	};

	const _handleStartDateChange = (newStartDate?: string | null) => {
		if (onRangeChange && untilFieldName) {
			if (!newStartDate) {
				onRangeChange(fromFieldName, newStartDate, untilFieldName, until);
			} else {
				if (until) {
					if (from) {
						if (moment(newStartDate).isSameOrAfter(moment(until))) {
							const diff = Math.abs(moment(from).diff(until, 'minutes'));
							onRangeChange(
								fromFieldName,
								newStartDate,
								untilFieldName,
								moment(newStartDate).clone().add(diff, 'minutes').toISOString()
							);
						} else {
							onRangeChange(fromFieldName, newStartDate, untilFieldName, until);
						}
					} else {
						if (moment(newStartDate).isSameOrAfter(moment(until))) {
							onRangeChange(
								fromFieldName,
								newStartDate,
								untilFieldName,
								moment(fromFieldName).clone().add(1, 'hours').toISOString()
							);
						} else {
							onRangeChange(fromFieldName, newStartDate, untilFieldName, until);
						}
					}
				} else {
					onRangeChange(fromFieldName, newStartDate, untilFieldName, moment(newStartDate).clone().add(1, 'hours').toISOString());
				}
			}
		}
	};

	const _handleEndDateChange = (newEndDate?: string | null) => {
		if (onRangeChange && untilFieldName) {
			if (!newEndDate) {
				onRangeChange(fromFieldName, from, untilFieldName, newEndDate);
			} else {
				if (from) {
					if (until) {
						if (moment(newEndDate).isSameOrBefore(moment(from))) {
							const diff = moment(until).diff(moment(from), 'minutes');
							onRangeChange(
								fromFieldName,
								moment(newEndDate).subtract(diff, 'minutes').toISOString(),
								untilFieldName,
								newEndDate
							);
						} else {
							onRangeChange(fromFieldName, from, untilFieldName, newEndDate);
						}
					} else {
						if (moment(newEndDate).isSameOrBefore(moment(from))) {
							onRangeChange(
								fromFieldName,
								moment(newEndDate).clone().subtract(1, 'hours').toISOString(),
								untilFieldName,
								newEndDate
							);
						} else {
							onRangeChange(fromFieldName, from, untilFieldName, newEndDate);
						}
					}
				} else {
					onRangeChange(fromFieldName, from, untilFieldName, moment(newEndDate).clone().subtract(1, 'hours').toISOString());
				}
			}
		}
	};

	return (
		<View style={{ marginBottom: FormElementBottomMarginSmall }}>
			<FormLabel testID={`${testIdPrefix}_datepicker`} label={label} isRequired={isRequired} style={{ marginBottom: 5 }} />
			<FormHint testID={`${testIdPrefix}_datepicker`} hint={hint} />
			<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
				<FormDatePicker
					placeholder={fromLabel}
					formLabel={fromLabel}
					formStyle={{ width: '46%', marginBottom: 0 }}
					testID={`${testIdPrefix}_datepicker_openFrom`}
					value={from}
					error={fromError}
					date={from ? moment(from).toDate() : moment(activeSpace?.startDate).toDate()}
					onChange={(value) => {
						if (!isDisabled) {
							if (onRangeChange && untilFieldName) {
								_handleStartDateChange(value);
							} else {
								onChange(fromFieldName, value);
							}
						}
					}}
					mode={mode ?? 'datetime'}
					isDisabled={isDisabled}
					onError={(error) => {
						if (onError) {
							onError(fromFieldName, error);
						}
					}}
					isRequired={isRequired}
				/>
				<View
					style={{
						width: '8%',
						justifyContent: 'flex-end',
						alignItems: 'center',
						flex: 1
					}}
				>
					<Text center style={{ fontSize: 14, marginBottom: !isEmptyString(fromError) || !isEmptyString(untilError) ? 32 : 12 }}>
						–
					</Text>
				</View>
				{untilFieldName && (
					<FormDatePicker
						placeholder={untilLabel}
						formLabel={untilLabel}
						formStyle={{ width: '46%', marginBottom: 0 }}
						testID={`${testIdPrefix}_datepicker_openUntil`}
						value={until}
						error={untilError}
						date={from ? moment(until).toDate() : moment(activeSpace?.startDate).toDate()}
						onChange={(value) => {
							if (!isDisabled) {
								if (onRangeChange) {
									_handleEndDateChange(value);
								} else {
									onChange(untilFieldName, value);
								}
							}
						}}
						mode={mode ?? 'datetime'}
						isDisabled={isDisabled}
						onError={(error) => {
							if (onError) {
								onError(untilFieldName, error);
							}
						}}
						isRequired={isRequired}
					/>
				)}
			</View>
		</View>
	);
};
