import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { EDefaultIconSet } from 'helper/icon';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import * as RootNavigation from '../../../RootNavigation';
import { useSpace } from 'hooks/useSpace';
import { ERoutes } from '../routes';
import { useSelector } from 'react-redux';

interface INavigationHeaderCancelButtonComponent {
	onPress?: Function;
	route?: any;
	options?: any;
}

export const NavigationHeaderCancelButton = (props: INavigationHeaderCancelButtonComponent) => {
	const { onPress, route, options } = props;
	const navigation = useNavigation();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const activeNetworkingRoom = useSelector((store: IRootState) => store.networking.activeNetworkingRoom);

	return (
		<NavigationHeaderIconButton
			testID="header_button_cancel"
			icon={EDefaultIconSet.Close}
			onPress={() => {
				if (onPress) {
					onPress();
				} else if (activeNetworkingRoom) {
					showAlert({
						title: t('LeaveNetworkingRoom'),
						message: t('LeaveNetworkingRoomSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('LeaveNetworkingRoom'),
								onPress: () => {
									if (options?.onBack) {
										options.onBack();
									}
								}
							}
						]
					});
				} else if (!route?.params || !route?.params['prohibitNavigation']) {
					if (RootNavigation.canGoBack()) {
						navigation.goBack();
					} else {
						RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
					}
				} else {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: async () => {
									if (RootNavigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
									}
								}
							}
						]
					});
				}
			}}
		/>
	);
};
