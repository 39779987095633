import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ExpoForm } from 'components/Forms';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { getCreateExpoInitialFormValues, ICreateExpoFormValues } from 'config/interfaces';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ExpoCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ExpoCreate>;
type RouteParams = StackParamList[ERoutes.ExpoCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'expocreate';

export const ExpoCreateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('expo');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<ICreateExpoFormValues>({ ...getCreateExpoInitialFormValues(route?.params?.isSponsor) });
	const [formErrors, setFormErrors] = useState({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const expos = useSelector((store: IRootState) => store.content.content.expos);

	const createExpo = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createExpo);

	useEffect(() => {
		if (activeSpace && route?.params?.expoId) {
			const found = expos.find((s) => s.id === route?.params?.expoId);
			if (found) {
				let newItem: ICreateExpoFormValues = { ...formValues };
				const fields = getContentTypeFields();
				fields.forEach((field) => {
					if (field.fieldName === 'title') {
						newItem[field.fieldName] = `${found[field.fieldName]} - ${t('copy')}`;
					} else if (field.fieldType === 'image' || field.fieldType === 'logo') {
						newItem[field.fieldName] = found[field.fieldName]?.url;
					} else if (field.fieldType === 'reference') {
						newItem[field.fieldName] = found[field.fieldName] ? found[field.fieldName].map((item) => item.id) : [];
					} else if (field.fieldType === 'singlereference') {
						newItem[field.fieldName] = found[field.fieldName]?.id;
					} else {
						newItem[field.fieldName] = found[field.fieldName];
					}
				});
				setFormValues(newItem);
			}
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createExpo(),
			isLoading: isLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [activeSpace, formValues, isLoading, formErrors]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [formValues]);

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _createExpo = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors, getContentTypeFields());
			return;
		} else {
			setFormErrors({});
		}

		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'socialmedia':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await createExpo({ expo: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="expos">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<ExpoForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const ExpoCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const { t } = useTranslation();
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={t('Expos')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
