import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Button, RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form';
import {
	ISpaceAdminAddUserFormErrors,
	ISpaceAdminAddUserFormValues,
	IUserProfile,
	SpaceAdminAddUserInitialFormValues
} from 'config/interfaces';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getSpaceAdminAddUserFormSchema } from 'config/yupSchemas';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { SpaceAdministratorListEntry } from 'components/SpaceAdministratorList';
import { HSModal } from 'components/Modal/Modal';
import { HSCard } from 'components/Card';
import { hsActionButtonMargin } from 'config/styleConstants';

interface IAddUserModal {
	testID: string;
	isVisible: boolean;
	onSubmit: (userId: number) => void;
	isLoading: boolean;
	onClose: () => void;
	type: 'admin' | 'moderator';
}

interface IInviteResponse {
	type?: 'success' | 'error';
	text?: string;
}

export const AddUserModal = (props: IAddUserModal) => {
	const { testID, isVisible, onSubmit, isLoading, onClose, type } = props;
	const { t } = useTranslation();

	const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
	const [searchResult, setSearchResult] = useState<IUserProfile | undefined>(undefined);
	const [formValues, setFormValues] = useState<ISpaceAdminAddUserFormValues>({ ...SpaceAdminAddUserInitialFormValues });
	const [formErrors, setFormErrors] = useState<ISpaceAdminAddUserFormErrors>({});
	const [isSendInviteLoading, setIsSendInviteLoading] = useState<boolean>(false);

	const searchUserByEmail = useRematchDispatch((dispatch: Dispatch) => dispatch.space.searchUserByEmail);
	const inviteUnregisteredAdminOrModerator = useRematchDispatch(
		(dispatch: Dispatch) => dispatch.space.inviteUnregisteredAdminOrModerator
	);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!isVisible) {
			setSearchResult(undefined);
			setFormValues({ ...SpaceAdminAddUserInitialFormValues });
			setFormErrors({});
		}
	}, [isVisible]);

	const _searchUser = async () => {
		const errors = await validateForm(getSpaceAdminAddUserFormSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		setIsSearchLoading(true);
		const res = await searchUserByEmail({ email: formValues.email });

		setSearchResult(res);
		if (!res) {
			showAlert({
				title: t('NoUserWithWithEmail'),
				message: `${t('inviteAdminToSpace').replace('%EMAIL%', formValues.email)} ${t('inviteUnregisteredAdminHint')}`,
				buttons: [
					{
						text: t('Invite'),
						style: 'default',
						onPress: async () => {
							setIsSendInviteLoading(true);
							await inviteUnregisteredAdminOrModerator({ email: formValues.email, type });
							setIsSendInviteLoading(false);
							onClose();
						}
					},
					{
						text: t('Cancel'),
						style: 'cancel',
						onPress: async () => {
							onClose();
						}
					}
				]
			});
		}
		setIsSearchLoading(false);
	};

	const _renderResult = () => {
		if (searchResult) {
			return (
				<HSCard>
					<SpaceAdministratorListEntry item={searchResult} />
					<Button
						testID={`${testID}_button_submit`}
						style={{ marginTop: 20 }}
						isLoading={isLoading}
						onPress={() => onSubmit(searchResult?.id)}
						title={t('Add User')}
						fullWidth
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			_searchUser();
		}
	};

	let adminHints = t('UserMustBeRegistered') + '\n' + t('AdminRights') + '\n' + t('UserWillGetAnEmailAdmin');
	let moderatorHints = t('UserMustBeRegistered') + '\n' + t('ModeratorRights') + '\n' + t('UserWillGetAnEmailAdmin');

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			<View style={{ paddingRight: 45, flexDirection: 'row', alignItems: 'center', marginBottom: 6 }}>
				<FormTextInput
					testID={`${testID}_textinput_email`}
					label={t('email')}
					hint={type === 'moderator' ? moderatorHints : adminHints}
					isRequired
					formStyle={{ marginBottom: 14, marginRight: hsActionButtonMargin }}
					value={formValues.email}
					error={formErrors.email}
					onChangeText={(val) => setFormValues({ ...formValues, email: val })}
					keyboardType="email-address"
					onKeyPress={_handleEnter}
				/>
				<RoundButton
					alignSelf="flex-end"
					testID={`${testID}_button_search`}
					icon={EDefaultIconSet.Search}
					onPress={_searchUser}
					isLoading={isSearchLoading || isSendInviteLoading}
				/>
			</View>
			{_renderResult()}
		</HSModal>
	);
};
