import { ChildButton } from 'components/Button';
import { ICategoryMatching, IContentTypeField, IFeatureInfo, ILauncherSpace, IOption } from 'config/interfaces';
import { TContentType, useContent } from 'hooks/useContent';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';

import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text';
import { EDefaultIconSet } from 'helper';
import { Icon } from 'components/Icon';
import { hsBottomMargin } from 'config/styleConstants';

interface IScreenFilterBar {
	activeFilters: string[];
	onChange: (newFilters: string[], fieldName?: string) => void;
	type: TContentType;
	space?: ILauncherSpace;
	feature?: IFeatureInfo;
}

const TESTIDPREFIX = 'screenfilterbar';

export const ScreenFilterbar = (props: IScreenFilterBar) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeFilters, onChange, type, space, feature } = props;
	const { activeSpace } = useSpace();

	const { getContentTypeFields: getScheduleFields } = useContent('schedule');
	const { getContentTypeFields: getExpoFields } = useContent('expo');
	const { getContentTypeFields: getSpeakerFields } = useContent('speaker');
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');
	const { getRegistrationFields } = useRegistration();

	const [fieldName, setFieldName] = useState<string | undefined>(undefined);
	const [filters, setFilters] = useState<IOption[]>([]);

	useEffect(() => {
		let _filters: typeof filters = [];

		let fields: IContentTypeField[] = [];
		let hasMatchings: ICategoryMatching | undefined = undefined;

		const mySpace = space ?? activeSpace;

		switch (type) {
			case 'attendee':
				fields = getRegistrationFields(true);
				_filters = [
					{
						key: 'all',
						label: t('All')
					},
					{
						key: 'favorites',
						label: t('Favorites')
					}
					// {
					// 	key: 'profileVisitors',
					// 	label: t('ProfileVisitors')
					// }
					// {
					// 	key: 'scannedUser',
					// 	label: t('scannedUser')
					// }
				];
				hasMatchings = mySpace?.attendeeSettings?.matching?.find((e) => e.type1 === 'attendee' && e.type2 === 'attendee');
				if (hasMatchings) {
					_filters.splice(2, 0, {
						key: 'matchings',
						label: 'Matchings'
					});
				}

				break;
			case 'schedule':
				fields = getScheduleFields(true, mySpace?.scheduleFields?.fields);
				break;
			case 'expo':
				//case 'sponsors':
				fields = getExpoFields(true);
				_filters = [
					{
						key: 'favorites',
						label: t('Favorites')
					}
				];
				hasMatchings = mySpace?.attendeeSettings?.matching?.find((e) => e.type1 === 'expo' || e.type2 === 'expo');
				break;
			case 'speaker':
				fields = getSpeakerFields(true);
				hasMatchings = mySpace?.attendeeSettings?.matching?.find((e) => e.type1 === 'speaker' || e.type2 === 'speaker');
				break;
			case 'mediaitem':
				fields = getMediaItemFields(true);
				hasMatchings = mySpace?.attendeeSettings?.matching?.find((e) => e.type1 === 'mediaitem' || e.type2 === 'mediaitem');

				break;
			default:
				break;
		}

		const cat = fields.find((e) => e.fieldType === 'category' && e.useAsFilter === 'screen');
		if (cat?.options) {
			if (cat.fieldName === feature?.filter?.fieldName) {
				const newFilters = cat.options.filter((option) => feature?.filter?.keys.includes(option.key));
				_filters = _filters.concat(newFilters);
			} else {
				_filters = _filters.concat(cat.options);
			}
			setFieldName(cat.fieldName);
		} else {
			setFieldName(undefined);
		}

		switch (type) {
			case 'schedule':
			case 'expo':
			//case 'sponsors':
			case 'mediaitem':
			case 'speaker':
				if (hasMatchings) {
					_filters.splice(0, 0, {
						key: 'matchings',
						label: 'Matchings'
					});
				}
				if (_filters.length > 0) {
					_filters.unshift({
						key: 'all',
						label: t('All')
					});
				}

				break;
			default:
				break;
		}

		setFilters(_filters);
	}, [type, activeSpace, space, feature]);

	const _handlePress = (key: string) => {
		let _val = [...activeFilters];

		switch (key) {
			case 'all':
				_val = ['all'];
				break;
			case 'favorites':
				_val = ['favorites'];
				break;
			case 'matchings':
				_val = ['matchings'];
				break;
			case 'profileVisitors':
				_val = ['profileVisitors'];
				break;
			default:
				if (_val.includes('all') || _val.includes('favorites') || _val.includes('matchings') || _val.includes('profileVisitors')) {
					_val = [];
				}
				if (_val.includes(key)) {
					_val = _val.filter((e) => e !== key);
				} else {
					_val.push(key);
				}
				if (_val.length === 0) {
					_val = ['all'];
				}
				break;
		}

		onChange(_val, fieldName);
	};

	if (filters.length > 0) {
		return (
			<View style={{ marginBottom: hsBottomMargin / 2 }}>
				<ScrollView horizontal contentContainerStyle={{ paddingBottom: hsBottomMargin / 2 }}>
					{filters.map((filter) => {
						const isActive = activeFilters.includes(filter.key);
						const isLastItem = filter.key === filters[filters.length - 1].key;
						return (
							<ChildButton
								key={`${TESTIDPREFIX}_button_${filter.key}`}
								testID={`${TESTIDPREFIX}_button_${filter.key}`}
								onPress={() => _handlePress(filter.key)}
								style={[
									{
										marginRight: isLastItem ? 0 : 10,
										borderWidth: 1,
										borderRadius: 5,
										borderColor: theme.primary,
										paddingHorizontal: 5,
										paddingVertical: 2,
										justifyContent: 'center',
										alignItems: 'center'
									},
									isActive ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
								]}
							>
								{filter.key === 'favorites' ? (
									<Icon
										name={isActive ? EDefaultIconSet.Star : EDefaultIconSet.StarOutline}
										color={isActive ? theme.primaryContrast : theme.primary}
										size={15}
									/>
								) : (
									<Text
										bold={isActive}
										style={{
											color: isActive ? theme.primaryContrast : theme.primary
										}}
									>
										{filter.label}
									</Text>
								)}
							</ChildButton>
						);
					})}
				</ScrollView>
			</View>
		);
	}

	return null;
};
