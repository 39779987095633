import React from 'react';

import { useTranslation } from 'react-i18next';

import { FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteWordCloudForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteWordCloudForm = (props: IVoteWordCloudForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading}>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_minvalue`}
				label={t('VoteFormWordCloudMinValue')}
				hint={t('VoteFormWordCloudMinValueHint')}
				isRequired
				value={values.minValue?.toString()}
				error={errors.minValue}
				onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
				isDisabled={isLoading}
				keyboardType="number-pad"
				onlyNumbers
			/>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_maxvalue`}
				label={t('VoteFormWordCloudMaxValue')}
				hint={t('VoteFormWordCloudMaxValueHint')}
				isRequired
				value={values.maxValue?.toString()}
				error={errors.maxValue}
				onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
				isDisabled={isLoading}
				keyboardType="number-pad"
				onlyNumbers
			/>
		</CommonVoteForm>
	);
};
