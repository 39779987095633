import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import Slider from '@react-native-community/slider';
import { StyleSheet, View } from 'react-native';
import { Text, VoteAverage, VoteSum } from 'components/Text';
import { isEmptyString } from 'helper';

interface IVoteScaleSlider {
	testID: string;
	minValue?: number;
	maxValue?: number;
	value?: number;
	onChange?: (val: number) => void;
	isDisabled?: boolean;
	answerCount?: number;
	averageValue?: number;
	submitted?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	isResult?: boolean;
}

export const DEFAULTSCALEMINVALUE = 0;
export const DEFAULTSCALEMAXVALUE = 10;
const COMPONENTHEIGHT = 40;
const AVERAGEVALUEDIMENSIONS = COMPONENTHEIGHT / 2;
const SCALETEXTSIZE = 18;

export const VoteScaleSlider = (props: IVoteScaleSlider) => {
	const {
		testID,
		minValue,
		maxValue,
		value,
		onChange,
		isDisabled,
		answerCount,
		averageValue,
		submitted,
		resultsOnly,
		largerDesign,
		isResult
	} = props;
	const { theme } = useTheme();
	const { convertDecimal } = useSpace();

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [averageValueOffset, setAverageValueOffset] = useState<number>(0);
	const [initialValue, setInitialValue] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (resultsOnly && averageValue) {
			setInitialValue(averageValue);
		}
		if (value !== undefined && (submitted || !initialValue)) {
			setInitialValue(value);
		}
	}, [value]);

	useEffect(() => {
		if (averageValue) {
			const range = _getMaximum() - _getMinimum();
			const relative = (averageValue - _getMinimum()) / range;
			setAverageValueOffset(relative * (containerWidth - AVERAGEVALUEDIMENSIONS / 2));
		}
	}, [averageValue, containerWidth]);

	const _getMinimum = () => {
		return minValue ?? DEFAULTSCALEMINVALUE;
	};

	const _getMaximum = () => {
		return maxValue ?? DEFAULTSCALEMAXVALUE;
	};

	const _renderValue = () => {
		if (isResult && !answerCount) {
			return null;
		}

		let str = '';
		if (value !== undefined && !isNaN(value)) {
			str += `${value}`;
		}

		if (!isEmptyString(str) && !answerCount) {
			return (
				<Text bold style={{ color: theme.text, fontSize: 48 }}>
					{str}
				</Text>
			);
		}

		return null;
	};

	const _renderMyValue = () => {
		if (resultsOnly || (isResult && !answerCount)) {
			return null;
		}
		if (averageValue !== undefined) {
			return (
				<View
					pointerEvents="none"
					style={{
						width: '100%',
						height: COMPONENTHEIGHT,
						justifyContent: 'center',
						position: 'absolute',
						left: 0
					}}
				>
					<View
						style={{
							width: AVERAGEVALUEDIMENSIONS,
							height: AVERAGEVALUEDIMENSIONS,
							borderRadius: AVERAGEVALUEDIMENSIONS / 2,
							backgroundColor: theme.primary,
							position: 'absolute',
							left: averageValueOffset
						}}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderResult = () => {
		if (answerCount) {
			let str = '';
			if (value !== undefined && !isNaN(value)) {
				str += `${value}`;
			}

			return (
				<View style={{ alignItems: 'center', justifyContent: 'center', marginTop: -40 }}>
					<VoteAverage largerDesign={largerDesign} value={convertDecimal(averageValue)} />
					{!resultsOnly && (
						<Text bold style={{ color: theme.text, fontSize: 35 }}>
							{str}
						</Text>
					)}
					<VoteSum largerDesign={largerDesign} value={answerCount} />
				</View>
			);
		}

		return null;
	};

	return (
		<View>
			<View
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				style={{ position: 'relative', justifyContent: 'center' }}
			>
				<Slider
					testID={testID}
					style={{
						width: '100%',
						height: isDisabled ? 0 : COMPONENTHEIGHT,
						marginVertical: isDisabled ? COMPONENTHEIGHT / 2 : 0
					}}
					step={resultsOnly ? 1 : undefined}
					thumbTintColor={resultsOnly ? theme.primary : isDisabled && !answerCount ? theme.gray : theme.contrast}
					disabled={isDisabled}
					minimumValue={minValue ?? DEFAULTSCALEMINVALUE}
					maximumValue={maxValue ?? DEFAULTSCALEMAXVALUE}
					minimumTrackTintColor={theme.primary}
					maximumTrackTintColor={theme.lightgray}
					value={!answerCount ? undefined : value ?? initialValue}
					tapToSeek={!isDisabled}
					onValueChange={(val) => {
						if (onChange) {
							onChange(Math.round(val));
						}
					}}
				/>
				{_renderMyValue()}
			</View>

			<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
				<Text testID="activevote_slider_min" bold style={{ fontSize: largerDesign ? SCALETEXTSIZE * 1.5 : SCALETEXTSIZE }}>
					{_getMinimum()}
				</Text>
				{_renderValue()}
				<Text testID="activevote_slider_max" bold style={{ fontSize: largerDesign ? SCALETEXTSIZE * 1.5 : SCALETEXTSIZE }}>
					{_getMaximum()}
				</Text>
			</View>
			{_renderResult()}
		</View>
	);
};

const _styles = StyleSheet.create({
	scaleText: {
		fontSize: 18
	}
});
