import { ISpaceHistoryProfile } from 'rematch/interfaces';
import { IExpo, IFieldSettings, IPushToken, IUserProfileTicket } from '.';
import { IStrapiBase } from './main';
import { ITimeSlot } from './meeting';
import { ILauncherSpace } from './space';

export interface IAttendee {
	id: number;
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
	imageUrl?: string;
	smallImageUrl?: string;
	updated_at: string;
	created_at: string;
	userSpaceHistory?: {
		spaces: ISpaceHistoryProfile[];
	};
	userPushTokens?: {
		tokens: IPushToken[];
	};
	xingProfileUrl?: string;
	linkedInProfileUrl?: string;
	jobTitle?: string;
	company?: string;
	hideMyProfile?: boolean;
	allowChats?: boolean;
	allowMeetingRequests?: boolean;
	unavailable?: {
		meetingSlots?: ITimeSlot[];
	};
	expos?: IExpo[];
	tickets?: Record<string, IUserProfileTicket>;
	fieldSettings?: { fields: IFieldSettings[] };
	aboutMe?: string;
}

export interface ISupportRequest extends IStrapiBase {
	id: number;
	subject: string;
	text: string;
	email: string;
	name: string;
	userId: string;
	spaceId: string;
	ticketcode: string;
	status: 'open' | 'closed';
	internalComment: string;
	answer: string;
	closed_at?: string;
	deviceInfo?: {
		os: string;
		nativeVersion?: string | null;
		buildNumber?: string | null;
	};
	closedById;
}
export interface ICreateSupportRequestPayload {
	subject: string;
	text: string;
	email: string;
	name: string;
	userId: string;
	ticketcode: string;
	space: ILauncherSpace;
	deviceInfo: ISupportRequest['deviceInfo'];
}

export interface IUpdateSupportRequestPayload {
	id: number;
	space: ILauncherSpace;
	status: 'open' | 'closed';
	internalComment: string;
	answer: string;
	isSend: boolean;
	closedById: number;
}

export interface IEditAttendeeFormValues {
	firstName?: string;
	lastName?: string;
	email?: string;
	jobTitle?: string;
	company?: string;
	expos?: IExpo[];
	tickets?: Record<string, IUserProfileTicket>;
}

export interface IEditAttendeeFormErros {
	firstName?: string;
	lastName?: string;
	email?: string;
	jobTitle?: string;
	company?: string;
	expos?: string;
	tickets?: string;
}

export interface IUpdateAttendeePayload {
	attendee: IAttendee;
	noToast?: boolean;
}

export interface ISetAttendeePasswordFormValues {
	password: string;
	passwordConfirmation: string;
}

export const attendeeUpdateFields = [
	'firstName',
	'lastName',
	'imageUrl',
	'smallImageUrl',
	'jobTitle',
	'company',
	'linkedInProfileUrl',
	'xingProfileUrl',
	'twitterProfileUrl',
	'website',
	'blogUrl',
	'instagramProfileUrl',
	'facebookProfileUrl',
	'youTubeProfileUrl',
	'tikTokProfileUrl',
	'allowChats',
	'allowMeetings',
	'hideMyProfile',
	'category1',
	'category2',
	'category3',
	'category4',
	'category5',
	'category6',
	'category7',
	'category8',
	'category9',
	'category10',
	'extra1',
	'extra2',
	'extra3',
	'extra4',
	'extra5',
	'extra6',
	'extra7',
	'extra8',
	'extra9',
	'extra10',
	'checkbox1',
	'checkbox2',
	'checkbox3',
	'checkbox4',
	'checkbox5',
	'checkbox6',
	'checkbox7',
	'checkbox8',
	'checkbox9',
	'checkbox10',
	'spaceHistory',
	'userSpaceHistory',
	'userPushTokens'
];
