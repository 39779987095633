import React, { ReactNode, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { ListItemTitle, Text } from 'components/Text';
import { Image } from 'components/Image';
import { ISpaceHistory } from 'rematch/interfaces';
import { ChildButton } from 'components/Button';
import { EDefaultIconSet, getFixedHeightForText, IS_WEB } from 'helper';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { Icon } from 'components/Icon';

const placeholderSquare = require('../../../../../../assets/placeholder-square.png');
const spaceDefaultBlue = require('../../../../../assets/images/spaceDefault/spaceDefaultBlue.png');
const spaceDefaultRed = require('../../../../../assets/images/spaceDefault/spaceDefaultRed.png');
const spaceDefaultGreen = require('../../../../../assets/images/spaceDefault/spaceDefaultGreen.png');
const spaceDefaultPurple = require('../../../../../assets/images/spaceDefault/spaceDefaultPurple.png');

interface ISpaceTemplateItemComponent {
	item: ISpaceHistory;
	containerStyle?: ViewStyle;
	childButtonStyle?: ViewStyle;
	onDeletePress?: () => void;
	children?: ReactNode | ReactNode[];
}

export const SpaceTemplateItem = (props: ISpaceTemplateItemComponent) => {
	const { item, containerStyle, onDeletePress, children, childButtonStyle } = props;
	const { theme } = useTheme();

	const [imageWidth, setImageWidth] = useState<number>(1);

	const _renderImage = () => {
		let src = placeholderSquare;

		switch (item.space?.previewImageName) {
			case 'spaceDefaultRed':
				src = spaceDefaultRed;
				break;
			case 'spaceDefaultGreen':
				src = spaceDefaultGreen;
				break;
			case 'spaceDefaultBlue':
				src = spaceDefaultBlue;
				break;
			case 'spaceDefaultPurple':
				src = spaceDefaultPurple;
				break;
			default:
				src = undefined;
				break;
		}

		if (!src && !item.space.previewImage) {
			return (
				<View
					onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
					style={{
						height: (imageWidth / 16) * 9,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: theme.primary,
						borderRadius: hsBorderRadius,
						borderBottomRightRadius: 0,
						borderBottomLeftRadius: 0,
						marginBottom: hsBottomMargin / 2
					}}
				>
					<Icon size={((imageWidth / 16) * 9) / 2} name={EDefaultIconSet.AddCircle} color={theme.white} />
				</View>
			);
		}

		return (
			<View
				style={{ position: 'relative', padding: 0, marginBottom: hsBottomMargin / 2 }}
				onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
			>
				<View
					style={{ flex: 1, position: 'relative' }}
					testID={`spacehistory_list_${item.space.spaceId}_overlay`}
					onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
				>
					<Image
						mediaObj={item.space.previewImage}
						imageSize={IS_WEB ? 'full' : 'small'}
						url={src}
						style={{
							width: imageWidth,
							aspectRatio: 16 / 9,
							marginRight: 10,
							borderRadius: hsBorderRadius,
							borderBottomRightRadius: 0,
							borderBottomLeftRadius: 0
						}}
						expectedRatio={16 / 9}
					/>
				</View>
			</View>
		);
	};

	return (
		<View style={[containerStyle, { flex: 1, marginBottom: hsBottomMargin }]}>
			<ChildButton testID={`spacehistory_list_${item?.space?.spaceId}`} style={childButtonStyle ? childButtonStyle : {}}>
				<HSCard style={{ flex: 1, marginBottom: 0, padding: 0 }}>
					{_renderImage()}
					<View style={{ padding: hsInnerPadding, paddingTop: 0, flexDirection: 'row' }}>
						<View style={{ flex: 1 }}>
							<ListItemTitle>{item?.space?.title}</ListItemTitle>
							<Text style={{ fontSize: 12, height: getFixedHeightForText(12, 2) }} numberOfLines={2}>
								{item?.space?.description}
							</Text>
						</View>
						{onDeletePress && (
							<ChildButton testID={`spacehistory_list_${item?.space?.spaceId}_remove`} onPress={onDeletePress}>
								<Icon color={theme.gray} name={EDefaultIconSet.Delete} size={15} />
							</ChildButton>
						)}
					</View>
					{children}
				</HSCard>
			</ChildButton>
		</View>
	);
};
