import {
	IContentTypeField,
	IFeatureInfo,
	ILauncherSpace,
	IMedia,
	IMediaGalleryItem,
	IMeetingSettings,
	IPushSettings,
	IRegistrationField,
	ISelfServiceSettings,
	ITimeSlot,
	TAllowUserGeneratedContentInFeed,
	TBackgroundImageName,
	TLogoImageName,
	TPreviewImageName,
	TSponsorTypes
} from '..';
import * as Localization from 'expo-localization';
import moment from 'moment';
import {
	ISpaceAttendeeSettings,
	ISpaceAttendeeSettingsErrors,
	ITicketSettings,
	TAgendaViewTypes,
	TAllowFeedComments,
	TDetailReferencesViewType,
	TDetailViewType,
	TAgendaListViewType
} from '../space';
import { getDefaultRegistrationFields, getDefaultExpoFields, getDefaultSpeakerFields, getDefaultTicketFields } from 'config/defaultFields';
export interface ISpaceEmailOrTicketcodeFormValues {
	emailOrTicket: string;
}

export interface ISpaceEmailOrTicketcodeFormErrors {
	emailOrTicket?: string;
}

export interface ISpaceForgotPasswordFormValues {
	email: string;
}

export interface ISpaceForgotPasswordFormErrors {
	email?: string;
}

export const SpaceForgotPasswordFormInitialValues: ISpaceForgotPasswordFormValues = {
	email: ''
};

export interface ISpaceResetPasswordFormValues {
	email: string;
	code: string;
	password: string;
	passwordConfirmation: string;
}

export const SpaceResetPasswordFormInitialValues: ISpaceResetPasswordFormValues = {
	email: '',
	code: '',
	password: '',
	passwordConfirmation: ''
};
export interface ISpaceResetPasswordFormErrors {
	email?: string;
	code?: string;
	password?: string;
	passwordConfirmation?: string;
}

export interface ISpaceLoginFormValues {
	email: string;
	password: string;
}

export interface ISpaceLoginFormErrors {
	email?: string;
	password?: string;
}

export const SpaceLoginFormInitialValues: ISpaceLoginFormValues = {
	email: '',
	password: ''
};

export interface ISpaceTicketFormValues {
	ticketcode: string;
}

export interface ISpaceTicketFormErrors {
	ticketcode?: string;
}

export interface ISpaceRegistrationFormValues {
	firstName: string;
	lastName: string;
	email: string;
	avatar?: IMedia | string;
	password: string;
	passwordConfirmation: string;
	jobTitle?: string;
	company?: string;
	hellospacesNewsletter: boolean;
	dataProtectionPolicy: boolean;
}

export interface ISpaceRegistrationFormErrors {
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	passwordConfirmation?: string;
	jobTitle?: string;
	company?: string;
	hellospacesNewsletter?: string;
	dataProtectionPolicy?: string;
}

export const SpaceRegistrationFormInitialValues: ISpaceRegistrationFormValues = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	passwordConfirmation: '',
	jobTitle: undefined,
	company: undefined,
	hellospacesNewsletter: false,
	dataProtectionPolicy: false
};

export interface ICreateSpaceFormValues {
	title: string;
	sidebarTitle: string;
	spaceId: string;
	language: string;
	backgroundColor?: string;
	primaryColor?: string;
	primaryContrastColor?: string;
	textColor?: string;
	contrastColor?: string;
	successColor?: string;
	warningColor?: string;
	dangerColor?: string;
	previewImageName?: TPreviewImageName;
	backgroundImageName?: TBackgroundImageName;
	logoImageName?: TLogoImageName;
	forceDoubleOptInForUsers?: boolean;
	showInSearch?: boolean;
	registrationRequired?: boolean;
	startDate: string;
	endDate: string;
	features?: {
		tabbarCount?: number;
		list: IFeatureInfo[];
	};
	registrationFields?: { fields: IRegistrationField[] };
	scheduleFields?: { fields: IContentTypeField[] };
	expoFields?: { fields: IContentTypeField[] };
	speakerFields?: { fields: IContentTypeField[] };
	ticketFields?: { fields: IContentTypeField[] };
	grayColor?: string;
	lightGrayColor?: string;
	contentBackgroundColor?: string;
	navigationTextColor?: string;
	formGrayColor?: string;
	webLayout?: 'cards' | 'list';
	appLayout?: 'cards' | 'list';
	cloneSpaceId?: string;
	cloneData?: boolean;
}

export interface ICreateSpaceFormErrors {
	title?: string;
	sidebarTitle?: string;
	spaceId?: string;
	language?: string;
	registrationRequired?: string;
	startDate?: string;
	endDate?: string;
	contentBackgroundColor?: string;
}

export const getCreateSpaceFormInitialValues = () => {
	const val: ICreateSpaceFormValues = {
		title: '',
		sidebarTitle: '',
		spaceId: '',
		language: Localization.locale.split('-')[0],
		logoImageName: 'default',
		showInSearch: false,
		forceDoubleOptInForUsers: false,
		registrationRequired: false,
		startDate: moment().set('hours', 0).set('minutes', 0).set('seconds', 0).toISOString(true),
		endDate: moment().set('hours', 23).set('minutes', 59).set('seconds', 59).add(1, 'day').toISOString(true),
		registrationFields: { fields: [...getDefaultRegistrationFields()] },
		expoFields: {
			fields: [...getDefaultExpoFields()]
		},
		speakerFields: {
			fields: [...getDefaultSpeakerFields()]
		},
		ticketFields: {
			fields: [...getDefaultTicketFields()]
		},
		features: {
			tabbarCount: 5,
			list: [
				{
					key: 'feed',
					isActive: true
				},
				{
					key: 'schedules',
					isActive: true
				},
				{
					key: 'speakers',
					isActive: true
				},
				{
					key: 'votings',
					isActive: true
				},
				{
					key: 'expos',
					isActive: true
				},
				{
					key: 'attendees',
					isActive: true
				},
				{
					key: 'chats',
					isActive: true
				},
				{
					key: 'meetings',
					isActive: true
				},
				{
					key: 'networking',
					isActive: true
				},
				{
					key: 'mediaitems',
					isActive: true
				},
				{
					key: 'maps',
					isActive: true
				}
			]
		}
	};

	return val;
};

export interface IEditSpaceFormValues {
	space?: ILauncherSpace;
	dontSetActive?: boolean;
	title?: string;
	sidebarTitle?: string;
	spaceId?: string;
	language?: string;
	description?: string;
	backgroundColor?: string;
	primaryColor?: string;
	primaryContrastColor?: string;
	textColor?: string;
	contrastColor?: string;
	successColor?: string;
	warningColor?: string;
	dangerColor?: string;
	customUserConsentText?: string;
	previewImage?: IMedia | string | null;
	backgroundImage?: IMedia | string | null;
	logoImage?: IMedia | string | null;
	admins?: number[];
	moderators?: number[];
	showInSearch?: boolean;
	previewImageName?: TPreviewImageName;
	backgroundImageName?: TBackgroundImageName;
	logoImageName?: TLogoImageName;
	isDeleted?: boolean;
	isDeactivated?: boolean;
	forceDoubleOptInForUsers?: boolean;
	allowUserGeneratedContentInFeed?: TAllowUserGeneratedContentInFeed;
	registrationRequired?: boolean;
	registrationFields?: { fields: IRegistrationField[] };
	registrationIntro?: string;
	loginIntro?: string;
	authHelpText?: string;
	defaultMailTemplateHeader?: string;
	defaultMailTemplateFooter?: string;
	startDate?: string;
	endDate?: string;
	features?: {
		tabbarCount?: number;
		list: IFeatureInfo[];
	};
	scheduleFields?: { fields: IContentTypeField[] };
	expoFields?: { fields: IContentTypeField[] };
	speakerFields?: { fields: IContentTypeField[] };
	mediaItemFields?: { fields: IContentTypeField[] };
	ticketFields?: { fields: IContentTypeField[] };
	ticketSettings?: ITicketSettings;
	meetingSettings?: IMeetingSettings;
	selfServiceSettings?: ISelfServiceSettings;
	sponsorTypeFeed?: TSponsorTypes;
	sponsorTypeAgenda?: TSponsorTypes;
	sponsorTypeExpo?: TSponsorTypes;
	sponsorTypeAttendees?: TSponsorTypes;
	sponsorTypeNetworking?: TSponsorTypes;
	sponsorTypeVotings?: TSponsorTypes;
	sponsorTypeSpeaker?: TSponsorTypes;
	sponsorTypeMediaItem?: TSponsorTypes;
	sponsorTitle?: string;
	grayColor?: string;
	lightGrayColor?: string;
	contentBackgroundColor?: string;
	navigationTextColor?: string;
	enablePublicAgenda?: boolean;
	welcomeTitle?: string;
	welcomeText?: string;
	welcomeGallery?: IMediaGalleryItem[];
	welcomeShowType?: 'always' | 'once' | 'never';
	attendeeSettings?: ISpaceAttendeeSettings;
	showUpcomingScheduleOnFeed?: boolean;
	allowFeedLikes?: boolean;
	allowFeedComments?: TAllowFeedComments;
	agendaHeightPerMinuteWeb?: number;
	agendaHeightPerMinuteApp?: number;
	agendaWidthPerStageWeb?: number;
	agendaWidthPerStageApp?: number;
	agendaStageAutoWidth?: boolean;
	agendaMyDayImage?: IMedia | string | null;
	agendaViewType?: TAgendaViewTypes;
	spacePin?: string | null;
	disableTracking?: boolean;
	anonymousTracking?: boolean;
	dataProtectionAcknowledgedText?: string;
	dataProtectionAcceptedText?: string;
	dataProtectionUrl?: string;
	readTermsUrl?: string;
	allowSharing?: boolean;
	cacheTimerContent?: number;
	cacheTimerAttendee?: number;
	reloadIntervalContent?: number;
	reloadIntervalAttendee?: number;
	enableGalleryScroll?: boolean;
	galleryScrollInterval?: number;
	webLayout?: 'cards' | 'list';
	appLayout?: 'cards' | 'list';
	promotionStartDate?: string;
	enablePrestart?: boolean;
	prestartText?: string;
	squareImage?: IMedia | string | null;
	globalDetailViewType?: TDetailViewType;
	expoDetailViewType?: TDetailViewType;
	speakerDetailViewType?: TDetailViewType;
	attendeeDetailViewType?: TDetailViewType;
	detailReferencesViewType?: TDetailReferencesViewType;
	showGradientOnMissingImage?: boolean;
	updated_at?: string;
	agendaListViewType?: TAgendaListViewType;
	gaTrackingId?: string;
	matomoTrackingUrl?: string;
	matomoSiteId?: number;
	allowParallelBooking?: boolean;
}
export interface IEditSpacePayload {
	data: IEditSpaceFormValues;
	noToast?: boolean;
}

export interface IEditSpaceFormErrors {
	title?: string;
	sidebarTitle?: string;
	spaceId?: string;
	language?: string;
	description?: string;
	backgroundColor?: string;
	primaryColor?: string;
	primaryContrastColor?: string;
	textColor?: string;
	contrastColor?: string;
	successColor?: string;
	warningColor?: string;
	dangerColor?: string;
	customUserConsentText?: string;
	previewImage?: string;
	backgroundImage?: string;
	logoImage?: string;
	admins?: string;
	moderators?: string;
	showInSearch?: string;
	forceDoubleOptInForUsers?: string;
	allowUserGeneratedContentInFeed?: string;
	registrationRequired?: string;
	registrationIntro?: string;
	loginIntro?: string;
	authHelpText?: string;
	defaultMailTemplateHeader?: string;
	defaultMailTemplateFooter?: string;
	startDate?: string;
	endDate?: string;
	tabbarCount?: string;
	sponsorTypeFeed?: string;
	sponsorTypeAgenda?: string;
	sponsorTypeExpo?: string;
	sponsorTypeAttendees?: string;
	sponsorTypeNetworking?: string;
	sponsorTypeVotings?: string;
	sponsorTypeSpeaker?: string;
	sponsorTypeMediaItem?: string;
	sponsorTitle?: string;
	grayColor?: string;
	lightGrayColor?: string;
	contentBackgroundColor?: string;
	navigationTextColor?: string;
	enablePublicAgenda?: string;
	attendeeSettings?: ISpaceAttendeeSettingsErrors;
	showUpcomingScheduleOnFeed?: string;
	allowFeedLikes?: string;
	allowFeedComments?: string;
	agendaStageAutoWidth?: string;
	agendaMyDayImage?: string;
	agendaViewType?: string;
	agendaHeightPerMinuteWeb?: string;
	agendaWidthPerStageWeb?: string;
	agendaHeightPerMinuteApp?: string;
	agendaWidthPerStageApp?: string;
	spacePin?: string;
	disableTracking?: string;
	anonymousTracking?: string;
	dataProtectionAcknowledgedText?: string;
	dataProtectionAcceptedText?: string;
	dataProtectionUrl?: string;
	readTermsUrl?: string;
	allowSharing?: string;
	enableGalleryScroll?: string;
	galleryScrollInterval?: string;
	webLayout?: string;
	appLayout?: string;
	promotionStartDate?: string;
	prestartText?: string;
	squareImage?: string;
	globalDetailViewType?: string;
	expoDetailViewType?: string;
	speakerDetailViewType?: string;
	attendeeDetailViewType?: string;
	detailReferencesViewType?: string;
	showGradientOnMissingImage?: string;
	agendaListViewType?: string;
	gaTrackingId?: string;
	matomoTrackingUrl?: string;
	matomoSiteId?: string;
	allowParallelBooking?: string;
}

export const EditSpaceInitialFormValues: IEditSpaceFormValues = {
	title: '',
	spaceId: '',
	language: undefined,
	description: undefined,
	backgroundColor: undefined,
	primaryColor: undefined,
	textColor: undefined,
	contrastColor: undefined,
	customUserConsentText: undefined,
	previewImage: undefined,
	backgroundImage: undefined,
	logoImage: undefined,
	showInSearch: false,
	forceDoubleOptInForUsers: false,
	registrationRequired: false,
	squareImage: undefined
};

export interface IEditProfileFormValues {
	firstName: string;
	lastName: string;
	email: string;
	avatar?: IMedia | string;
	unavailable?: {
		meetingSlots?: ITimeSlot[];
	};
	pushSettings?: IPushSettings;
	fieldSettings?: { fields: IFieldSettings[] };
	password?: string;
	passwordConfirmation?: string;
}

export interface IEditProfileFormErrors {
	firstName?: string;
	lastName?: string;
	email?: string;
	avatar?: string;
}

export const EditProfileInitialFormValues: IEditProfileFormValues = {
	firstName: '',
	lastName: '',
	email: '',
	avatar: undefined
};

export interface ISpaceAdminAddUserFormValues {
	email: string;
}

export interface ISpaceAdminAddUserFormErrors {
	email?: string;
}

export const SpaceAdminAddUserInitialFormValues = {
	email: ''
};

export interface IMediaGalleryFormErrors {
	title?: string;
	subtitle?: string;
	media?: string;
	order?: string;
}
export interface IProfileFieldSettingsErrors {
	fields: IFieldSettings[];
}

export interface IFieldSettings {
	fieldName: string;
	showOnPublic: boolean;
}

export const ProfileSettingsInitialValues: IFieldSettings[] = [{ fieldName: 'email', showOnPublic: true }];
