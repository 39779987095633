import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useDetail } from 'hooks/useDetail';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import {
	IAttendee,
	IContentTypeField,
	IExpo,
	IHSPXTicket,
	IRegistrationField,
	ISchedule,
	ISetAttendeePasswordFormValues,
	ISpeaker,
	TSectionPosition
} from 'config/interfaces';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSCard } from 'components/Card';
import { ScrollView, View } from 'react-native';
import { Avatar } from 'components/User';
import { H2, SectionHeader, Text, Title } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { EDefaultColorSet, EDefaultIconSet, getDetailWidth, isEmptyString, IS_WEB, openURL } from 'helper';
import { showToast } from 'helper/toast';
import { hsTopScreenPadding } from 'config/styleConstants';
import { FormLabel } from 'components/Form/FormLabel';
import { FormTextInput } from 'components/Form';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import moment from 'moment';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { EditAttendeeModal, SetAttendeePasswordModal, SetAttendeeTicketModal } from 'components/Modal';
import { IUserInSpaceId } from 'rematch/interfaces';
import { ScheduleItem } from 'components/Schedule';
import { HorizontalListSeperator } from 'components/Seperator';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.AttendeeDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.AttendeeDetails>;
type RouteParams = StackParamList[ERoutes.AttendeeDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface IStateContent {
	speaker?: ISpeaker;
	expos: IExpo[];
}

const TESTIDPREFIX = 'attendeedetails';

export const AttendeeDetailsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { getRegistrationFields, getProfileFieldSettings } = useRegistration();
	const { renderSocialMedia, renderSections } = useDetail();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);
	const [attendeeDetailFields, setAttendeeDetailFields] = useState<IRegistrationField[]>([]);
	const [isEditUserNote, setIsEditUserNote] = useState<boolean>(false);
	const [newUserNote, setNewUserNote] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSetAttendePasswordModalVisible, setIsSetAttendePasswordModalVisible] = useState<boolean>(false);
	const [isEditAttendeModalVisible, setIsEditAttendeModalVisible] = useState<boolean>(false);
	const [isSetTicketcodeModalVisible, setIsSetTicketcodeModalVisible] = useState<boolean>(false);
	const [passwordValues, setPasswordValues] = useState<ISetAttendeePasswordFormValues>({ password: '', passwordConfirmation: '' });

	const [isRemoveFromSpaceLoading, setIsRemoveFromSpaceLoading] = useState<boolean>(false);

	const [ticket, setTicket] = useState<IHSPXTicket | undefined>(undefined);
	const [userInSpaceId, setUserInSpaceId] = useState<IUserInSpaceId | undefined>(undefined);
	const [bookedSchedules, setBookedSchedules] = useState<ISchedule[]>([]);

	const [stateContent, setStateContent] = useState<IStateContent>({ speaker: undefined, expos: [] });

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const tickets = useSelector((store: IRootState) => store.ticket.tickets);
	const bookings = useSelector((store: IRootState) => store.booking.allBookings);
	const content = useSelector((store: IRootState) => store.content.content);

	const removeUserFromSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.removeUserFromSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const resetPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.resetPassword);
	const startAttendeeSync = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.startAttendeeSync);

	useEffect(() => {
		const filteredBookings = bookings?.filter((b) => b.userId === attendee?.userId && activeSpace?.spaceId === b.spaceId);
		const _schedules: ISchedule[] = [];
		filteredBookings.forEach((filtered) => {
			const found = content?.schedules?.find((s) => s.id === filtered.itemId);
			if (found) {
				_schedules.push(found);
			}
		});
		setBookedSchedules(_schedules);
	}, [bookings, attendee, content, activeSpace]);

	useEffect(() => {
		if (attendee) {
			const _speaker = content?.speakers?.find((sp) => sp.spaceId === activeSpace?.spaceId && sp.attendee?.id === attendee.id);

			const _expos = content?.expos?.filter(
				(ex) => ex.spaceId === activeSpace?.spaceId && ex.attendees?.map((a) => a.id)?.includes(attendee.id)
			);

			setStateContent({
				speaker: _speaker,
				expos: _expos
			});
		}
	}, [attendee, content, activeSpace]);

	useEffect(() => {
		if (attendee && userInSpaces && activeSpace && (iAmSpaceAdmin || iAmSpaceModerator)) {
			const e = userInSpaces.find((e) => e && e.spaceId === activeSpace.spaceId && e.userId === attendee.userId);
			setUserInSpaceId(e);
		}
	}, [attendee, iAmSpaceAdmin, iAmSpaceModerator, userInSpaces]);

	useEffect(() => {
		let _ticket: typeof ticket = undefined;

		if (userInSpaceId && tickets) {
			_ticket = tickets.find((e) => userInSpaceId.ticketProvider === 'hellospaces' && e.ticketcode === userInSpaceId.ticketcode);
		}

		setTicket(_ticket);
	}, [userInSpaceId, tickets]);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (activeSpace && attendees) {
			_attendee = attendees.find((e) => e.userId === route.params.userId);
			navigation.setOptions({ title: _attendee ? `${_attendee.firstName} ${_attendee.lastName}` : '' });
		}

		setAttendee(_attendee);
	}, [activeSpace, attendees]);

	useEffect(() => {
		const _fields = getRegistrationFields(true).filter((e) => e.showOnDetailScreen);
		setAttendeeDetailFields(_fields);
	}, [activeSpace]);

	const _removeUserFromSpace = async () => {
		showAlert({
			title: t('DeleteSpaceDataForUser'),
			message: t('DeleteSpaceDataForUserSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('DeleteSpaceDataForUser'),
					style: 'destructive',
					onPress: async () => {
						setIsRemoveFromSpaceLoading(true);
						const success = await removeUserFromSpace({ userId: route.params.userId, shouldDeleteSpace: false });
						if (success) {
							await startAttendeeSync({ force: true });
							navigation.goBack();
						}

						setIsRemoveFromSpaceLoading(false);
					}
				}
			]
		});
	};

	const _renderChatButton = () => {
		if (activeSpace?.features?.list.find((e) => e.key === 'chats') && attendee?.userId !== profile?.userId) {
			return (
				<ChildButton
					key={`${TESTIDPREFIX}_button_chat`}
					testID={`${TESTIDPREFIX}_button_chat`}
					style={{ marginHorizontal: 5, justifyContent: 'center' }}
					onPress={() => {
						if (!profile?.allowChats) {
							showToast('info', undefined, t('YouDoNotAllowChats'));
						} else if (attendee?.allowChats) {
							navigation.navigate(ERoutes.Chat, {
								spaceId: activeSpace?.spaceId,
								userId: route.params.userId
							});
						} else {
							showToast('info', undefined, t('AttendeeDoesNotAllowChats'));
						}
					}}
				>
					<Icon name={EDefaultIconSet.Chat} size={23} color={attendee?.allowChats ? theme.text : theme.formgray} />
				</ChildButton>
			);
		}

		return null;
	};

	const _renderMeetingButton = () => {
		if (activeSpace?.features?.list.find((e) => e.key === 'meetings')?.isActive && attendee?.userId !== profile?.userId) {
			return (
				<ChildButton
					key={`${TESTIDPREFIX}_button_meeting`}
					testID={`${TESTIDPREFIX}_button_meeting`}
					style={{ marginHorizontal: 5, justifyContent: 'center' }}
					onPress={() => {
						if (!profile?.allowMeetingRequests) {
							showToast('info', undefined, t('YouDoNotAllowMeetings'));
						} else if (!profile.allowChats) {
							showToast('info', undefined, t('YouDoNotAllowChatsMeetings'));
						} else if (attendee?.allowMeetingRequests) {
							navigation.navigate(ERoutes.MeetingRequest, {
								spaceId: activeSpace?.spaceId,
								partnerId: attendee.userId
							});
						} else {
							showToast('info', undefined, t('AttendeeDoesNotAllowMeetings'));
						}
					}}
				>
					<Icon
						name={EDefaultIconSet.Meetings}
						size={23}
						color={
							attendee?.allowMeetingRequests && attendee.allowChats && profile?.allowChats && profile?.allowMeetingRequests
								? theme.text
								: theme.formgray
						}
					/>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderFavoriteButton = () => {
		if (attendee?.userId && attendee?.userId !== profile?.userId) {
			return (
				<View style={{ marginHorizontal: 5, justifyContent: 'center' }} key={`${TESTIDPREFIX}_button_favorite`}>
					<FavoriteButton testID={`${TESTIDPREFIX}_button_favorite`} id={attendee?.userId} type="attendee" />
				</View>
			);
		}

		return null;
	};

	const _renderXINGSearchButton = () => {
		if (attendee && activeSpace) {
			let hasLinkedInField =
				activeSpace.registrationFields?.fields?.find((e) => e.fieldName === 'xingProfileUrl')?.visibility === 'always'
					? true
					: false;
			if (hasLinkedInField && !attendee.xingProfileUrl) {
				return (
					<ChildButton
						key={`${TESTIDPREFIX}_button_xingProfileUrl`}
						testID={`${TESTIDPREFIX}_button_xingProfileUrl`}
						style={{ marginHorizontal: 5, justifyContent: 'center' }}
						onPress={() => {
							openURL(`https://www.xing.com/search/members?keywords=${attendee.firstName}%20${attendee.lastName}`);
							// trackAction('attendee', 'Open LinkedIn', `${attendee.id}`);
						}}
					>
						<Icon name={EDefaultIconSet.Xing} size={25} color={EDefaultColorSet.Xing} />
						<Icon
							name={EDefaultIconSet.Search}
							size={12}
							color={theme.white}
							containerStyle={{ position: 'absolute', top: 0, right: 0, backgroundColor: theme.formgray, borderRadius: 999 }}
						/>
					</ChildButton>
				);
			}
		}
		return null;
	};

	const _renderAttendeNote = () => {
		const _scannedUser = profile?.scannedUser?.find((u) => u.userId === attendee?.userId);
		if (!isEmptyString(_scannedUser?.userNote)) {
			if (isEditUserNote) {
				return (
					<View style={{ width: '100%' }}>
						<FormTextInput
							multiline
							testID={`${TESTIDPREFIX}_textinput_attendeenote`}
							value={newUserNote}
							onChangeText={(val) => setNewUserNote(val)}
							formStyle={{ marginBottom: FormElementBottomMarginSmall }}
						/>
						<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
							<RoundButton
								size="sm"
								icon={EDefaultIconSet.Close}
								testID={`${TESTIDPREFIX}_button_closeattendeenote`}
								title={t('Cancel')}
								isLoading={isLoading}
								onPress={() => {
									setNewUserNote(_scannedUser?.userNote ?? '');
									setIsEditUserNote(false);
								}}
							/>
							<RoundButton
								size="sm"
								icon={EDefaultIconSet.Save}
								title={t('Save')}
								testID={`${TESTIDPREFIX}_button_submitattendeenote`}
								isLoading={isLoading}
								onPress={async () => {
									setIsLoading(true);
									let _scannedUsers = profile?.scannedUser ?? [];
									const foundIndex = profile?.scannedUser?.findIndex((a) => a.userId === attendee?.userId);
									if ((foundIndex ?? -1) > -1) {
										_scannedUsers[foundIndex] = {
											spaceId: activeSpace?.spaceId,
											scanTime: moment().toISOString(),
											userId: attendee?.userId,
											userNote: newUserNote
										};
									}

									setIsLoading(false);
									setNewUserNote(_scannedUser?.userNote ?? '');
									setIsEditUserNote(false);
								}}
							/>
						</View>
					</View>
				);
			}
			return (
				<ChildButton
					testID={`${TESTIDPREFIX}_attendeenote`}
					onPress={() => {
						setNewUserNote(_scannedUser?.userNote ?? '');
						setIsEditUserNote(true);
					}}
				>
					<FormLabel testID={`${TESTIDPREFIX}_attendeenote`} />
					<Text>{_scannedUser?.userNote}</Text>
				</ChildButton>
			);
		}
		return null;
	};

	const _isFieldAllowed = (fieldName) => {
		if (attendee) {
			const settings = getProfileFieldSettings(attendee);
			if (settings[fieldName] !== undefined && settings[fieldName] !== null) {
				return settings[fieldName];
			}
		}
		return true;
	};

	const _renderSections = (position?: TSectionPosition, fields?: IContentTypeField[], logoWidth?: number | string) => {
		if (attendee) {
			const attendeFields = fields ?? attendeeDetailFields;
			const foundFirstNameIndex = attendeFields.findIndex((f) => f.fieldName === 'firstName');

			if (attendeFields[foundFirstNameIndex]) {
				attendeFields[foundFirstNameIndex] = { ...attendeFields[foundFirstNameIndex], fieldType: 'title', fieldName: 'title' };
			}
			return renderSections({
				contentType: 'attendee',
				testID: TESTIDPREFIX,
				item: {
					...attendee,
					avatar: { url: IS_WEB ? attendee.imageUrl : attendee?.smallImageUrl ?? attendee?.imageUrl },
					title: `${attendee.firstName} ${attendee.lastName}`
				},
				forbiddenFieldNames: ['selfServiceEditable', 'selfServiceEmail', 'firstName', 'lastName'],
				forbiddenFieldTypes: [],
				position: position,
				detailFields: attendeFields,
				space: activeSpace,
				referencedSpeakers: stateContent.speaker ? [stateContent.speaker] : [],
				referencedExpos: stateContent.expos,
				logoWidth: logoWidth
			});
		}
		return null;
	};

	const _renderAttendee = () => {
		return _renderStacked();
		// maybe enable later
		// if (attendee && activeSpace) {
		// 	if (isTabletOrMobile) {
		// 		return _renderStacked();
		// 	}
		// 	const viewType = activeSpace.attendeeDetailViewType ?? activeSpace.globalDetailViewType;
		// 	switch (viewType) {
		// 		case 'thirds':
		// 			return _renderThirds();
		// 		case 'stacked':
		// 		default:
		// 			return _renderStacked();
		// 	}
		// }

		// return null;
	};

	const _renderStacked = () => {
		if (attendee) {
			return (
				<View>
					<HSCard style={{ alignItems: 'center' }}>
						<Avatar
							testID={`${TESTIDPREFIX}_avatar`}
							size="xxl"
							avatar={IS_WEB ? attendee.imageUrl : attendee?.smallImageUrl ?? attendee?.imageUrl}
							fullName={`${attendee?.firstName} ${attendee?.lastName}`}
							userId={attendee?.userId}
						/>
						<View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 20 }}>
							<Title style={{ marginBottom: 0 }}>{`${attendee.firstName} ${attendee.lastName}`}</Title>
						</View>
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							{renderSocialMedia(
								'attendee',
								attendeeDetailFields,
								attendee,
								TESTIDPREFIX,
								[_renderFavoriteButton(), _renderXINGSearchButton()],
								[_renderChatButton(), _renderMeetingButton()]
							)}
						</View>
						{_renderAttendeNote()}
					</HSCard>
					{_renderSections(
						undefined,
						attendeeDetailFields.filter(
							(field) =>
								_isFieldAllowed(field.fieldName) &&
								field.fieldName !== 'firstName' &&
								field.fieldName !== 'lastName' &&
								field.fieldName !== 'avatar' &&
								field.fieldType !== 'socialmedia'
						)
					)}
					{iAmSpaceAdmin && route.params.userId !== profile?.userId && (
						<HSCard>
							<SectionHeader label={t('Administrate')} />
							<View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
								<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
									<RoundButton
										isOutline
										isStacked
										testID={`${TESTIDPREFIX}_button_removefromspace`}
										icon={EDefaultIconSet.Delete}
										color={theme.danger}
										isLoading={isRemoveFromSpaceLoading}
										isDisabled={isLoading}
										size="sm"
										title={t('DeleteSpaceDataForUser')}
										onPress={_removeUserFromSpace}
										alignSelf="center"
									/>
								</View>
								<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
									<RoundButton
										isOutline
										isStacked
										testID={`${TESTIDPREFIX}_button_setpassword`}
										icon={EDefaultIconSet.ResetPassword}
										color={theme.danger}
										isLoading={isRemoveFromSpaceLoading}
										isDisabled={isLoading}
										size="sm"
										title={t('setPassword')}
										onPress={() => setIsSetAttendePasswordModalVisible(true)}
										alignSelf="center"
									/>
								</View>
								<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
									<RoundButton
										isOutline
										isStacked
										testID={`${TESTIDPREFIX}_button_edit`}
										icon={EDefaultIconSet.Edit}
										isLoading={isRemoveFromSpaceLoading}
										size="sm"
										title={t('editAttendee')}
										isDisabled={isLoading}
										color={theme.danger}
										onPress={() => setIsEditAttendeModalVisible(true)}
										alignSelf="center"
									/>
								</View>
								{userInSpaceId?.ticketcode && (
									<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
										<RoundButton
											isOutline
											isStacked
											testID={`${TESTIDPREFIX}_button_ticket`}
											icon={EDefaultIconSet.Ticket}
											isLoading={isRemoveFromSpaceLoading}
											size="sm"
											title={t('editTicket')}
											isDisabled={!ticket || isLoading}
											onPress={() =>
												navigation.navigate(ERoutes.TicketEdit, {
													spaceId: activeSpace?.spaceId,
													id: ticket?.id
												})
											}
											alignSelf="center"
										/>
									</View>
								)}
								{activeSpace?.ticketSettings?.ticketRequired && (
									<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
										<RoundButton
											isOutline
											isStacked
											testID={`${TESTIDPREFIX}_button_setticketcode`}
											icon={EDefaultIconSet.Ticket}
											isLoading={isRemoveFromSpaceLoading}
											size="sm"
											title={t('setTicketcode')}
											isDisabled={isLoading}
											color={theme.danger}
											onPress={() => setIsSetTicketcodeModalVisible(true)}
											alignSelf="center"
										/>
									</View>
								)}
							</View>
						</HSCard>
					)}
					{iAmSpaceAdmin && bookedSchedules?.length > 0 && (
						<HSCard>
							<H2 center>{t('Bookings')}</H2>
							{bookedSchedules.map((schedule, idx) => {
								return (
									<View>
										<ScheduleItem
											item={schedule}
											onPress={() =>
												navigation.navigate(ERoutes.BookingDetails, {
													spaceId: activeSpace?.spaceId,
													id: schedule.id
												})
											}
										/>
										{idx < bookedSchedules.length - 1 && <HorizontalListSeperator />}
									</View>
								);
							})}
						</HSCard>
					)}
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer handleBackPress contentKey="attendees">
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: getDetailWidth(activeSpace?.attendeeDetailViewType ?? activeSpace?.globalDetailViewType ?? '', screenWidth),
					alignSelf: 'center'
				}}
			>
				{_renderAttendee()}
			</ScrollView>
			<SetAttendeePasswordModal
				isLoading={isLoading}
				isVisible={isSetAttendePasswordModalVisible}
				onClose={() => {
					setIsSetAttendePasswordModalVisible(false);
					setPasswordValues({ password: '', passwordConfirmation: '' });
				}}
				onPress={async () => {
					setIsLoading(true);
					const res = await resetPassword({ ...passwordValues, email: attendee?.email });
					setIsLoading(false);

					if (res) {
						showAlert({
							title: t('SetPWSuccessTitle'),
							message: t('SetPWSuccessSubtitle'),
							buttons: [
								{
									onPress: () => setIsSetAttendePasswordModalVisible(false),
									text: 'OK'
								}
							]
						});
						setPasswordValues({ password: '', passwordConfirmation: '' });
					}
				}}
				onChange={(values) => setPasswordValues(values)}
				values={passwordValues}
			/>
			<EditAttendeeModal
				isLoading={isLoading}
				isVisible={isEditAttendeModalVisible}
				onClose={() => setIsEditAttendeModalVisible(false)}
				attendee={attendee}
			/>
			<SetAttendeeTicketModal
				isLoading={isLoading}
				isVisible={isSetTicketcodeModalVisible}
				onClose={() => setIsSetTicketcodeModalVisible(false)}
				attendee={attendee}
			/>
		</ScreenContainer>
	);
};

export const AttendeeDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { route } = props;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
