import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { IMeeting, ISchedule } from 'config/interfaces';
import { getCardContainerStyle, IS_WEB } from 'helper';
import { ERoutes } from 'components/Navigation/routes';
import { hsBottomMargin } from 'config/styleConstants';
import { t } from 'i18next';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ScheduleItem, SCHEDULELISTITEMHEIGHT } from './ScheduleItem';
import { ScheduleMeetingItem } from './ScheduleMeetingItem';
import { FlashList } from 'components/List';
import { AgendaCard } from 'components/Card/AgendaCard';

interface IScheduleList {
	items: ISchedule[];
	meetings: IMeeting[];
	isPublic?: boolean;
	onScroll?: (e) => void;
	onContentSizeChange?: (e) => void;
	testId?: string;
}

interface IScheduleItem {
	meeting?: IMeeting;
	schedule?: ISchedule;
}

export const ScheduleList = (props: IScheduleList) => {
	const { items, meetings, isPublic, onScroll, onContentSizeChange, testId } = props;
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const {  isTabletOrMobile } = useQuery();

	const [scheduleItems, setScheduleItems] = useState<IScheduleItem[]>([]);

	const [hasJumpedToNow, setHasJumpedToNow] = useState<boolean>(false);

	const listRef = useRef<any>(null);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);

	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const setMediaDetailViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailViewType);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _setMediaDetail = (itemId: number) => {
		let _item;
		if (isPublic) {
			_item = items?.find((i) => i.id === itemId);
			_item.votes = [];
		}
		if (!isPublic) {
			navigation.navigate(ERoutes.Media, {
				itemId,
				mediaType: 'schedule',
				spaceId: activeSpace?.spaceId
			});
			setMediaDetail({
				itemId: undefined,
				viewType: 'full',
				playbackStatus: 'paused'
			});
			return;
		}
		if (mediaDetail.itemId) {
			if (mediaDetail.itemId === itemId) {
				setMediaDetailViewType('full');
			} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
				setMediaDetail({
					itemId,
					itemType: 'schedule',
					viewType: 'full',
					playbackStatus: 'paused',
					item: _item,
					previewImage: _item?.previewImage,
					isPublic: isPublic
				});
			} else {
				showAlert({
					title: t('Switch Stream'),
					message: t('Switch Stream Subtitle'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							style: 'destructive',
							text: t('Switch Stream'),
							onPress: () =>
								setMediaDetail({
									itemId,
									itemType: 'schedule',
									viewType: 'full',
									playbackStatus: 'paused',
									item: _item,
									previewImage: _item?.previewImage,
									isPublic: isPublic
								})
						}
					]
				});
			}
		} else {
			setMediaDetail({
				itemId,
				itemType: 'schedule',
				viewType: 'full',
				playbackStatus: 'paused',
				item: _item,
				previewImage: _item?.previewImage,
				isPublic: isPublic
			});
		}
	};

	useEffect(() => {
		if (items?.length > 0 || meetings?.length > 0) {
			let scheduleItems: IScheduleItem[] = [];

			items.forEach((item) => scheduleItems.push({ schedule: item, meeting: undefined }));
			meetings.forEach((meeting) => scheduleItems.push({ schedule: undefined, meeting: meeting }));

			if (activeSpace?.agendaListViewType !== 'list') {
				scheduleItems = scheduleItems.filter((e) => !e?.schedule?.hasNoDetails);
			}

			scheduleItems.sort((a,b) => (a.meeting?.timeSlot.start ?? a.schedule?.startDate) < (b.meeting?.timeSlot.start ?? b.schedule?.startDate) ? -1: 1)
			setScheduleItems(scheduleItems);
		}
	}, [items, meetings, activeSpace]);

	useEffect(() => {
		const now = moment();
		const index = scheduleItems.findIndex((item) => {
			if (item.schedule?.startDate && item.schedule?.endDate) {
				const itemStartDate = moment(item.schedule.startDate);
				const itemEndDate = moment(item.schedule.endDate);
				if (now.isSame(itemStartDate, 'date') && now.isSameOrAfter(itemStartDate) && now.isSameOrBefore(itemEndDate)) {
					return true;
				}
			}

			if (item.meeting) {
				const meetingStartDate = moment(item.meeting.timeSlot.start);
				const meetingEndDate = moment(item.meeting.timeSlot.end);

				if (now.isSame(meetingStartDate, 'date') && now.isSameOrAfter(meetingStartDate) && now.isSameOrBefore(meetingEndDate)) {
					return true;
				}
			}
			return false;
		});

		if (index !== -1 && !hasJumpedToNow && listRef?.current) {
			if (process.env.IS_TEST) {
				setHasJumpedToNow(true);
				return;
			}

			setTimeout(() => {
				listRef.current?.scrollToIndex({ index: index, viewPosition: 0, animated: true });
			}, 1000);
			setHasJumpedToNow(true);
		}
	}, [listRef.current, scheduleItems]);

	const _renderItem = (item: IScheduleItem, index: number, numColumns: number, mostSpeakerAmount?: number) => {
		const _viewType = activeSpace?.agendaListViewType;

		if (item?.schedule) {
			switch (_viewType) {
				case 'cards':
					return (
						<View style={getCardContainerStyle(numColumns, index)} key={`${testId}_card_schedule_${index}`}>
							<AgendaCard
								testID={testId ?? ''}
								item={item?.schedule}
								// isLoading={isDisabled}
								index={index}
								onPress={!item.schedule.hasNoDetails ? () => _setMediaDetail(item.schedule.id) : undefined}
								mostSpeakerAmount={mostSpeakerAmount}
								contentType={'schedule'}
							/>
						</View>
					);

				case 'list':
				default:
					return (
						<ScheduleItem
							isPublic={isPublic}
							key={`list_entry_${item.schedule.id}`}
							item={item.schedule}
							onPress={!item.schedule.hasNoDetails ? () => _setMediaDetail(item.schedule.id) : undefined}
							backgroundColor={item.schedule?.stage?.backgroundColor}
						/>
					);
			}
		}

		if (item?.meeting) {
			switch (_viewType) {
				case 'cards':
					return (
						<View style={getCardContainerStyle(numColumns, index)} key={`${testId}_card_schedule_${index}`}>
							<AgendaCard
								testID={testId ?? ''}
								item={item?.meeting}
								// isLoading={isDisabled}
								index={index}
								onPress={() => {
									navigation.navigate(ERoutes.Chat, {
										spaceId: activeSpace?.spaceId,
										userId:
											item?.meeting?.ownerId === profile?.userId ? item?.meeting?.partnerId : item?.meeting?.ownerId
									});
								}}
								mostSpeakerAmount={mostSpeakerAmount}
								contentType={'meeting'}
							/>
						</View>
					);

				case 'list':
				default:
					return (
						<ScheduleMeetingItem
							key={`list_entry_${item.meeting.id}`}
							item={item.meeting}
							style={{}}
							onPress={() => {
								navigation.navigate(ERoutes.Chat, {
									spaceId: activeSpace?.spaceId,
									userId: item?.meeting?.ownerId === profile?.userId ? item?.meeting?.partnerId : item?.meeting?.ownerId
								});
							}}
						/>
					);
			}
		}

		return <View />;
	};

	const _getMostSpeakerAmount = () => {
		const speakerAmounts = scheduleItems.map((s) => s.schedule?.speakers?.length ?? 0);
		return Math.max(...speakerAmounts);
	};

	const _renderScheduleList = () => {
		let _numberOfColumns = 1;
		let _estimatedSize = SCHEDULELISTITEMHEIGHT;
		// let _data = expoSections;
		const _viewType = activeSpace?.agendaListViewType;

		switch (_viewType) {
			case 'cards':
				_numberOfColumns = isTabletOrMobile ? 1 : 4;
				_estimatedSize = 400;
				break;
			case 'list':
			default:
				// See default values above
				break;
		}

		const mostSpeakerAmount = _getMostSpeakerAmount();

		return (
			<FlashList
				data={scheduleItems}
				ref={listRef}
				renderItem={(renderItem) => _renderItem(renderItem.item, renderItem.index, _numberOfColumns, mostSpeakerAmount)}
				estimatedItemSize={_estimatedSize}
				numColumns={_numberOfColumns}
				onScroll={onScroll}
				onContentSizeChange={(width, height) => {
					if (onContentSizeChange) {
						onContentSizeChange({
							width,
							height
						});
					}
				}}
			/>
		);
	};

	return (
		<View
			style={{
				flex: 1,
				flexDirection: 'row',
				marginBottom: activeSpace?.sponsorTypeAgenda?.startsWith('sponsor') ? EHorizontalScreenPadding.Wide : hsBottomMargin
			}}
		>
			{_renderScheduleList()}
		</View>
	);
};
