import * as Calendar from 'expo-calendar';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTheme } from './useTheme';
import { useTranslation } from 'react-i18next';
import { IS_WEB } from 'helper';
import { showToast } from 'helper/toast';
import { downloadCalenderEntry } from 'helper/calender';
import { useSpace } from './useSpace';

export const useCalendar = () => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const hasPermissions = async () => {
		const { status } = await Calendar.requestCalendarPermissionsAsync();

		if (status !== 'granted') {
			showToast('error', t('MissingCalendarPermissions'), t('MissingPermissionSubtitle'));
		}

		return status === 'granted';
	};

	const _getEventCalendarId = async () => {
		const calendars = await Calendar.getCalendarsAsync(Calendar.EntityTypes.EVENT);

		const defaultCalendar = calendars.find((e) => e.allowsModifications);
		if (defaultCalendar) {
			return defaultCalendar.id;
		}

		const eventTile = activeSpace?.title;
		let eventCalendar = calendars.find((e) => e.title === eventTile);

		if (eventCalendar) {
			return eventCalendar.id;
		}

		return await Calendar.createCalendarAsync({
			title: eventTile,
			color: theme.primary,
			entityType: Calendar.EntityTypes.EVENT,
			name: eventTile,
			ownerAccount: 'personal',
			timeZone: 'Europe/Berlin'
		});
	};

	const addToCalendar = async (title: string, startDate: string, endDate: string, notes?: string, location?: string) => {
		if (IS_WEB) {
			downloadCalenderEntry(
				{
					title,
					startDate,
					endDate,
					timeZone: 'Europe/Berlin',
					notes: notes ?? '',
					location: location ?? ''
				},
				activeSpace?.title ?? ''
			);
		} else {
			const permission = await hasPermissions();

			if (permission) {
				showAlert({
					title: t('AddToCalendar'),
					message: t('AddToCalendarSubtitle').replace('%TITLE%', title),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('AddToCalendar'),

							onPress: async () => {
								const eventCalendarId = await _getEventCalendarId();

								await Calendar.createEventAsync(eventCalendarId, {
									title,
									startDate,
									endDate,
									timeZone: 'Europe/Berlin',
									alarms: [{ relativeOffset: -15 }],
									notes: notes ?? '',
									location: location ?? ''
								});
								showToast('success', undefined, t('AddedToCalendar').replace('%TITLE%', title));
							}
						}
					]
				});
			} else {
				showToast('error', t('MissingCalendarPermissions'), t('MissingPermissionSubtitle'));
			}
		}
	};

	return {
		addToCalendar
	};
};
