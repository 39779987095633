import { IExpo, IHSPXTicket } from 'config/interfaces';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Image } from 'components/Image/Image';
import { Text } from 'components/Text';
import { isEmptyString } from 'helper';
import { t } from 'i18next';
import { useTheme } from 'hooks/useTheme';
import { TICKETLISTITEMENTRYHEIGHT } from './TicketListItem';

interface ITicketMailReceiverListItem {
	item: IHSPXTicket;
}

export const TicketMailReceiverListItem = (props: ITicketMailReceiverListItem) => {
	const { item } = props;
	const { theme } = useTheme();

	const _renderName = () => {
		let name = '';

		if (item.firstName && item.lastName && !isEmptyString(item.firstName) && !isEmptyString(item.lastName)) {
			name = `${item.firstName} ${item.lastName}`;
		} else if (item.firstName && !isEmptyString(item.firstName)) {
			name = item.firstName;
		} else if (item.lastName && !isEmptyString(item.lastName)) {
			name = item.lastName;
		}

		if (!isEmptyString(name)) {
			return <Text style={{ fontSize: 12 }}>{name}</Text>;
		}

		return null;
	};

	return (
		<View style={{ height: TICKETLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text bold style={{ marginBottom: 2 }}>
					{`Ticketcode: ${item.ticketcode}`}
				</Text>
				{_renderName()}
				<Text italic style={{ fontSize: 12, color: isEmptyString(item.email) ? theme.danger : theme.success }}>
					{isEmptyString(item.email) ? t('MissingTicketEmail') : item.email}
				</Text>
			</View>
		</View>
	);
};
