import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { Icon } from 'components/Icon';
import {
	EDefaultIconSet,
	getKeyForRoute,
	getSettingsForNavigationKey,
	getTopRadiusStyle,
	isEmptyString,
	IS_ANDROID,
	IS_WEB,
	openURL
} from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useUnreadCount } from 'hooks/useUnreadCount';
import React, { useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ERoutes } from './routes';
import { Text } from 'components/Text';
import RBSheet from 'react-native-raw-bottom-sheet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { TabbarBadge } from './TabbarBadge';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { MoreBottomView } from 'components/BottomSheet';
import { MAXTABCOUNT } from 'components/Forms/Space/SpaceWizard';
import { hsBorderRadius } from 'config/styleConstants';
import { TABBAR_HEIGHT } from 'config/constants';

export const TabBar = (props: BottomTabBarProps) => {
	const { descriptors, navigation, state } = props;
	const { isTabletOrMobile } = useQuery();
	const { activeSpace, iAmSpaceAdmin } = useSpace();
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { counter: activeVotings } = useUnreadCount('vote');
	const { counter: unreadFeedCount } = useUnreadCount('feed');
	const { counter: unreadChatCount } = useUnreadCount('chat');
	const { counter: pendingMeetingsCount } = useUnreadCount('meeting');
	const { counter: liveScheduleEntries } = useUnreadCount('schedule');
	const { counter: unreadSupportRequests } = useUnreadCount('supportrequest');
	const { counter: pendingFeedAndCommentCount } = useUnreadCount('feedandcomment');

	const [bottomSheetHeight, setBottomSheetHeight] = useState<number>(350);

	const bottomSheetRef = useRef<RBSheet>(null);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		if (activeSpace) {
			const features = activeSpace?.features?.list.filter((e) => e.isActive);
			if ((features?.length ?? 0) > 0) {
				let amount = features!.length - (activeSpace?.features?.tabbarCount ?? MAXTABCOUNT) + (iAmSpaceAdmin ? 1 : 0);

				setBottomSheetHeight(amount * 45 + 100);
			}
		}
	}, [activeSpace, iAmSpaceAdmin, profile]);

	const _isFeatureActive = (feature: string) => {
		return activeSpace?.features?.list.find((e) => e.key === feature && e.isActive);
	};

	const _getMoreUnreadCount = () => {
		let counter = 0;

		if (_isFeatureActive('votings') && !state?.routes?.find((route) => route.name === ERoutes.VotingsTab)) {
			counter += activeVotings;
		}
		if (_isFeatureActive('feed') && !state?.routes?.find((route) => route.name === ERoutes.FeedTab)) {
			counter += unreadFeedCount;
		}
		if (_isFeatureActive('chats') && !state?.routes?.find((route) => route.name === ERoutes.ChatTab)) {
			counter += unreadChatCount;
		}
		if (_isFeatureActive('schedules') && !state?.routes?.find((route) => route.name === ERoutes.ScheduleTab)) {
			counter += liveScheduleEntries;
		}
		if (_isFeatureActive('meetings') && !state?.routes?.find((route) => route.name === ERoutes.MeetingTab)) {
			counter += pendingMeetingsCount;
		}

		counter += unreadSupportRequests ?? 0;

		return counter;
	};

	return (
		<View
			style={{
				flexDirection: 'row',
				height: !IS_WEB || (IS_WEB && isTabletOrMobile) ? TABBAR_HEIGHT : 0,
				// display: isTabbarVisible ? 'flex' : 'none'
				display: !isTabbarVisible || (IS_WEB && !isTabletOrMobile) ? 'none' : 'flex',
				backgroundColor: theme.background,
				paddingBottom: IS_WEB || IS_ANDROID ? 3 : 0
			}}
		>
			{state?.routes?.map((tabRoute, index) => {
				const { options } = descriptors[tabRoute.key];

				const isFocused = state.index === index;

				const onPress = () => {
					const event = navigation.emit({
						type: 'tabPress',
						target: tabRoute.key,
						canPreventDefault: true
					});

					if (!isFocused && !event.defaultPrevented) {
						// The `merge: true` option makes sure that the params inside the tab screen are preserved
						navigation.navigate({ name: tabRoute.name, merge: true });
					}
				};

				const onLongPress = () => {
					navigation.emit({
						type: 'tabLongPress',
						target: tabRoute.key
					});
				};

				const feature = activeSpace?.features?.list.find((e) => e.key === (getKeyForRoute(tabRoute.name) ?? tabRoute.params?.key));
				const { icon, label } = getSettingsForNavigationKey(tabRoute.name, feature);

				let tabBarBadge: number | string = 0;
				let leftTabBarBadge: number | string = 0;

				switch (tabRoute.name) {
					case ERoutes.MeetingTab:
						tabBarBadge = pendingMeetingsCount;
						break;
					case ERoutes.VotingsTab:
						tabBarBadge = activeVotings;
						break;
					case ERoutes.ScheduleTab:
						tabBarBadge = liveScheduleEntries;
						break;
					case ERoutes.FeedTab:
						leftTabBarBadge = pendingFeedAndCommentCount;
						tabBarBadge = unreadFeedCount;
						break;
					case ERoutes.SettingsTab:
						if (activeSpace?.isDeactivated) {
							tabBarBadge = '!';
						} else {
							tabBarBadge = unreadSupportRequests;
						}
						break;
					case ERoutes.More:
						leftTabBarBadge = state?.routes?.find((e) => e.name === ERoutes.FeedTab) ? 0 : pendingFeedAndCommentCount;
						tabBarBadge = _getMoreUnreadCount();
						break;
					case ERoutes.ChatTab:
						tabBarBadge = unreadChatCount;
						break;
					default:
						break;
				}

				return (
					<TouchableOpacity
						key={tabRoute.name}
						activeOpacity={1}
						accessibilityRole="button"
						accessibilityState={isFocused ? { selected: true } : {}}
						accessibilityLabel={options.tabBarAccessibilityLabel}
						testID={options.tabBarTestID}
						onPress={() => {
							if (tabRoute.name === ERoutes.More) {
								bottomSheetRef.current?.open();
							} else if (feature?.contentType === 'externalUrl') {
								if (feature.externalUrl && !isEmptyString(feature.externalUrl)) {
									openURL(feature.externalUrl);
								}
							} else {
								onPress();
							}
						}}
						onLongPress={onLongPress}
						style={{
							flex: 1,
							alignItems: 'center',
							backgroundColor: 'transparent',
							paddingBottom: IS_WEB || IS_ANDROID ? 0 : 2,
							paddingTop: IS_WEB || IS_ANDROID ? 5 : 10
						}}
					>
						<View style={{ position: 'relative', alignItems: 'center', height: '100%', width: '100%' }}>
							{icon && !isEmptyString(icon) && (
								<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingBottom: 4 }}>
									<Icon
										name={icon}
										size={IS_WEB || IS_ANDROID ? 30 : 28}
										color={isFocused ? theme.primary : theme.navigationTextColor ?? theme.gray}
									/>
									<TabbarBadge value={leftTabBarBadge} top={-2} left={-10} />
									<TabbarBadge value={tabBarBadge} top={-2} right={-10} />
									{feature?.contentType === 'externalUrl' && (
										<Icon
											testID={`${options.tabBarTestID}_external`}
											name={EDefaultIconSet.ArrowUpRight}
											size={15}
											containerStyle={{ position: 'absolute', top: -2, right: -10 }}
											color={theme.danger}
										/>
									)}
								</View>
							)}
							<View
								style={{
									width: '100%',
									justifyContent: 'flex-end',
									alignItems: 'center'
								}}
							>
								<Text
									numberOfLines={1}
									adjustsFontSizeToFit
									minimumFontScale={0.7}
									center
									style={{
										color: isFocused ? theme.primary : theme.navigationTextColor ?? theme.gray,
										fontSize: 12,
										paddingHorizontal: 2
									}}
								>
									{label}
								</Text>
							</View>
						</View>
					</TouchableOpacity>
				);
			})}
			<RBSheet
				ref={bottomSheetRef}
				openDuration={250}
				height={bottomSheetHeight}
				customStyles={{
					container: {
						...getTopRadiusStyle(hsBorderRadius),
						paddingTop: EHorizontalScreenPadding.Wide,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						backgroundColor: theme.background,
						maxHeight: '66%'
					}
				}}
			>
				<MoreBottomView onPress={() => bottomSheetRef.current?.close()} />
			</RBSheet>
		</View>
	);
};
