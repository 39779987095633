import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { GestureResponderEvent, LayoutChangeEvent, PanResponder, PanResponderGestureState, PanResponderInstance, View } from 'react-native';
import { RatingIcon } from './RatingIcon';
import { VoteAverage, VoteSum } from 'components/Text';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { isEmptyString, IS_WEB } from 'helper';

interface IRating {
	maxValue?: number;
	value: number;
	isDisabled?: boolean;
	onChange?: (value: number) => void;
	emptyIcon: string;
	filledIcon: string;
	numberOfVotes?: number;
	lockScrolling?: (val: boolean) => void;
	precision?: 0 | 0.5 | 1;
	largerDesign?: boolean;
	isResult?: boolean;
}

const DEFAULTMINVALUE = 0;
const DEFAULTMAXVALUE = 5;
const SPACING = 10;

export const Rating = (props: IRating) => {
	const { maxValue, value, onChange, emptyIcon, filledIcon, numberOfVotes, lockScrolling, precision, largerDesign, isResult } = props;
	const { theme } = useTheme();
	const { convertDecimal } = useSpace();

	const tempValue = useRef(0);
	const location = useRef({ x: 0, y: 0 });
	const locationDiffX = useRef(1);
	const startLocation = useRef({ x: 0, y: 0 });
	const iconSize = useRef(1);
	const containerWidth = useRef(1);

	const isDisabled = useRef(props.isDisabled);

	const [_isDisabled, _setIsDisabled] = useState<boolean>(false);
	const [panResponder, setPanResponder] = useState<PanResponderInstance>(
		PanResponder.create({
			onStartShouldSetPanResponder: (e, gestureState) => _handleOnStartShouldSetPanResponder(e, gestureState),
			onStartShouldSetPanResponderCapture: (e, gestureState) => _handleOnStartShouldSetPanResponderCapture(e, gestureState),
			onMoveShouldSetPanResponder: (e, gestureState) => _handleOnMoveShouldSetPanResponder(e, gestureState),
			onMoveShouldSetPanResponderCapture: (e, gestureState) => _handleOnMoveShouldSetPanResponderCapture(e, gestureState),
			onPanResponderGrant: (e, gestureState) => _handleOnPanResponderGrant(e, gestureState),
			onPanResponderMove: (e, gestureState) => _handleOnPanResponderMove(e, gestureState),
			onPanResponderRelease: (e, gestureState) => _handlePanResponderEnd(e, gestureState),
			onPanResponderTerminationRequest: (evt, gestureState) => true,
			onPanResponderTerminate: (e, gestureState) => _handlePanResponderEnd(e, gestureState),
			onShouldBlockNativeResponder: (e, gestureState) => true
		})
	);

	const [_iconSize, _setIconSize] = useState<number>(1);
	const [_tempValue, setTempValue] = useState<number>(0);

	useEffect(() => {
		_iconSizeSetter();
	}, [containerWidth.current, maxValue]);

	const _iconSizeSetter = () => {
		let iconWidth = containerWidth.current;
		const count = _isValidMaximum();
		const neededSpacing = count - 1 * SPACING;
		iconWidth = iconWidth - neededSpacing;
		iconWidth = iconWidth / count;
		setIconSize(iconWidth);
	};

	useEffect(() => {
		isDisabled.current = props.isDisabled;
		_setIsDisabled(props.isDisabled ?? false);
	}, [props.isDisabled]);

	useEffect(() => {
		tempValue.current = value && !isNaN(value) ? value : 0;
		setTempValue(value && !isNaN(value) ? value : 0);
	}, [value]);

	const setIconSize = (value) => {
		_setIconSize(value);
		iconSize.current = value;
	};

	const _handleOnStartShouldSetPanResponder = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		// Should we become active when the user presses down on the circle?
		return !isDisabled.current;
	};

	const _handleOnStartShouldSetPanResponderCapture = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		return false;
	};

	const _handleOnMoveShouldSetPanResponder = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		// Should we become active when the user moves a touch over the circle?

		return !isDisabled.current;
	};

	const _handleOnMoveShouldSetPanResponderCapture = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		return false;
	};

	const _handleOnPanResponderGrant = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		if (!isDisabled.current) {
			if (lockScrolling) {
				lockScrolling(true);
			}
			if (IS_WEB) {
				startLocation.current = {
					x: e.nativeEvent.locationX,
					y: e.nativeEvent.locationY
				};

				location.current = {
					x: e.nativeEvent.locationX,
					y: e.nativeEvent.locationY
				};
			} else {
				startLocation.current = {
					x: e.nativeEvent.pageX,
					y: e.nativeEvent.pageY
				};

				location.current = {
					x: e.nativeEvent.pageX,
					y: e.nativeEvent.pageY
				};
			}
		}
	};

	const _handleOnPanResponderMove = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		location.current = {
			x: startLocation.current.x + gestureState.dx,
			y: startLocation.current.y + gestureState.dy
		};
		_setMyValue(_transFormStarValueByLocationX(startLocation.current.x + gestureState.dx));
	};

	const _handlePanResponderEnd = (e: GestureResponderEvent, gestureState: PanResponderGestureState) => {
		if (lockScrolling) {
			lockScrolling(false);
		}
		if (onChange) {
			const value = _transFormStarValueByLocationX(location.current.x);
			onChange(value);
		}
	};

	const _isValidMaximum = () => {
		let _maxValue = 0;
		if ((maxValue ?? 0) > 20) {
			_maxValue = 20;
		} else {
			_maxValue = maxValue ?? 0 < 0 ? maxValue ?? 0 : DEFAULTMAXVALUE;
		}
		return Math.ceil(Math.max(DEFAULTMINVALUE, _maxValue));
	};

	const _setMyValue = (newValue) => {
		if (newValue !== tempValue.current && newValue <= _isValidMaximum() && newValue >= DEFAULTMINVALUE) {
			tempValue.current = newValue;
			setTempValue(newValue);
		}
	};

	const _transFormStarValueByLocationX = (newXValue: number) => {
		const actualLocationX = newXValue - 1.5 * SPACING - locationDiffX.current;

		const cellWidth = iconSize.current;

		if (actualLocationX >= containerWidth.current) {
			return _isValidMaximum();
		} else {
			let val = actualLocationX / cellWidth;

			switch (precision) {
				case 1:
					val = Math.round((val + Number.EPSILON) * 10) / 10;
					break;
				case 0.5:
					val = Math.round(val * 2) / 2;
					break;
				default:
				case 0:
					val = Math.ceil(val);
					break;
			}

			return val;
		}
	};

	const _renderIcons = () => {
		const icons: ReactNode[] = [];

		for (let i = 0; i < _isValidMaximum(); i++) {
			icons.push(
				<RatingIcon
					key={`activevote_button_ratingicon_${i}`}
					testID={`activevote_button_ratingicon_${i}`}
					style={{ marginRight: SPACING }}
					color={theme.primary}
					emptyIcon={emptyIcon}
					filledIcon={filledIcon}
					value={tempValue.current - i}
					iconSize={_iconSize - SPACING < 0 ? 0 : _iconSize - SPACING}
				/>
			);
		}

		if (icons.length > 0 && _iconSize > 1) {
			return icons;
		}

		return null;
	};

	const _renderValue = () => {
		if (isResult) {
			let str = '';
			if (numberOfVotes) {
				str = tempValue.current.toString();
			}

			return (
				<View style={{ alignItems: 'center', justifyContent: 'center' }}>
					{!isEmptyString(str) && <VoteAverage largerDesign={largerDesign} value={convertDecimal(tempValue.current)} />}
					<VoteSum largerDesign={largerDesign} value={numberOfVotes} />
				</View>
			);
		}

		return null;
	};

	return (
		<View>
			<View
				testID="activevote_panresponder"
				{...panResponder?.panHandlers}
				style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}
				onLayout={(e: LayoutChangeEvent) => {
					containerWidth.current = e.nativeEvent.layout.width;
					setTimeout(() => {
						_iconSizeSetter();
					}, 100);
					locationDiffX.current = e.nativeEvent.layout.x;
				}}
			>
				{_renderIcons()}
			</View>

			{_renderValue()}
		</View>
	);
};
