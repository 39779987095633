import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultStageFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			visibility: 'visible',
			isCustomField: true,
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			maxLength: 255
		},
		{
			fieldName: 'subtitle',
			fieldLabel: i18next.t('subtitle'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			maxLength: 255
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Media'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'image',
			fieldLabel: i18next.t('Image'),
			fieldHint: i18next.t('stageImageHint'),
			fieldType: 'image',
			aspectRatio: '16/9',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'section3',
			fieldLabel: i18next.t('Additional Settings'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'backgroundColor',
			fieldLabel: i18next.t('backgroundColor'),
			fieldType: 'color',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'textColor',
			fieldLabel: i18next.t('textColor'),
			fieldType: 'color',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'isCrossLane',
			fieldLabel: i18next.t('isCrossLane'),
			fieldHint: i18next.t('isCrossLaneHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'order',
			fieldLabel: i18next.t('order'),
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'section4',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'schedules',
			fieldLabel: i18next.t('Schedules'),
			fieldType: 'reference',
			referenceType: 'schedules',
			isDefaultField: true,
			visibility: 'visible'
		}
	];

	return arr;
};
