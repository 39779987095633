import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { DrawerActions, StackActions, useRoute } from '@react-navigation/native';
import { H1, Text } from 'components/Text';
import { ChildButton, DrawerButton } from 'components/Button';
import {
	addToClipboard,
	canJoinSpace,
	EDefaultIconSet,
	getSettingsForNavigationKey,
	getSpaceShareUrl,
	isDarkColor,
	isEmptyString,
	normalizeFontSize,
	openURL,
	share
} from 'helper';
import { IMPRINTURL, DATAPROTECTIONURL, HELLOSPACES_USERS } from 'config/constants';
import { ERoutes } from '../routes';
import { Icon } from 'components/Icon';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import { useUnreadCount } from 'hooks/useUnreadCount';
import { DrawerViewProfileInfo } from './DrawerViewProfileInfo';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { NAVFONTSIZE, NAVICONSIZE } from 'components/Form/constants';
import { Image } from 'components/Image';
import { IS_WEB } from 'helper/platform';
import { NativeVersionInfo } from './NativeVersionInfo';
import _ from 'lodash';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SocketInfo } from './SocketInfo';
import { SupportRequestModal } from 'components/Modal/SupportRequestModal';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { DrawerSeparator } from './DrawerSeparator';
import { IMediaItem, ISchedule } from 'config/interfaces';

const HelloSpaces = require('../../../../assets/hellospaces.png');
const HelloSpacesWhite = require('../../../../assets/hellospacesWhite.png');

const defaultLogo = require('../../../assets/images/defaultLogo/defaultLogo.png');
const defaultLogoGray = require('../../../assets/images/defaultLogo/defaultLogoGray.png');

const SCROLLBAR_WIDTH = 8;

export const DrawerView = (props: DrawerContentComponentProps) => {
	const { t } = useTranslation();
	const { navigation } = props;
	const { theme } = useTheme();
	const { isTabletOrMobile, isPortrait } = useQuery();
	const { left } = useSafeAreaInsets();
	const { activeSpace, iAmSpaceAdmin } = useSpace();
	const { trackAction } = useTracker();
	const { counter: pendingMeetingsCount } = useUnreadCount('meeting');
	const { counter: unreadSupportRequests } = useUnreadCount('supportrequest');
	const { counter: unreadChatCount } = useUnreadCount('chat');
	const { counter: unreadFeedCount } = useUnreadCount('feed');
	const { counter: liveScheduleEntries } = useUnreadCount('schedule');
	const { counter: activeVotings } = useUnreadCount('vote');
	const { counter: pendingFeedAndCommentCount } = useUnreadCount('feedandcomment');

	const drawerRef = useRef<any>(null);
	const scrollerRef = useRef<any>(null);
	const hideTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isSupportRequestModalVisible, setIsSupportRequestModalVisible] = useState<boolean>(false);
	const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

	const canHideScrollbar = IS_WEB && !isTabletOrMobile && document && !('ontouchstart' in document.documentElement);
	const [showDrawerScroll, setShowDrawerScroll] = useState<boolean>(canHideScrollbar ? false : true);
	const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);

	const jwt = useSelector((store: IRootState) => store.auth.jwt);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const activeNetworkingRoom = useSelector((store: IRootState) => store.networking.activeNetworkingRoom);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const setMediaDetailPlaybackStatus = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailPlaybackStatus);
	const content = useSelector((store: IRootState) => store.content.content);

	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const resetApp = useRematchDispatch((dispatch: Dispatch) => dispatch.config.resetApp);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const startProfileSync = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.startProfileSync);
	const clearAllContent = useRematchDispatch((dispatch: Dispatch) => dispatch.content.clearAllContent);
	const startAllSyncs = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.startAllSyncs);
	const loadAttendeeDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeDelta);
	const loadMyMeetingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.loadMyMeetingsDelta);
	const loadContent = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadContent);
	const loadChatMessagesDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.loadChatMessagesDelta);
	const loadVoteDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteDelta);
	const loadMyVoteAnswers = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadMyVoteAnswers);
	const loadMyBookings = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadMyBookings);
	const loadAllBookingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadAllBookingsDelta);
	const loadComments = useRematchDispatch((dispatch: Dispatch) => dispatch.comment.loadComments);
	const loadNetworkingRoomAttendees = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.loadNetworkingRoomAttendees);
	const loadLikes = useRematchDispatch((dispatch: Dispatch) => dispatch.like.loadLikes);
	const loadPushNotifications = useRematchDispatch((dispatch: Dispatch) => dispatch.pushNotification.loadPushNotifications);
	const loadAttendeeSupportRequests = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeSupportRequests);
	const getSpaceSubscription = useRematchDispatch((dispatch: Dispatch) => dispatch.subscription.getSpaceSubscription);

	useEffect(() => {
		if (IS_WEB) {
			window.addEventListener('resize', handleSizes);
		}
		handleSizes();
		return () => {
			if (IS_WEB) {
				window.removeEventListener('resize', handleSizes);
			}
		};
	}, [drawerRef?.current?.clientheight, scrollerRef?.current?.scrollHeight]);

	const handleSizes = () => {
		if (scrollerRef?.current?.scrollHeight > drawerRef?.current?.clientHeight) {
			setIsScrollbarVisible(true);
		} else {
			setIsScrollbarVisible(false);
			setShowDrawerScroll(false);
		}
	};

	const _reloadAllData = async () => {
		setIsDataLoading(true);

		await clearAllContent({});
		await startAllSyncs({});

		if (profile) {
			await startProfileSync({ load: true });
		}

		await loadAttendeeDelta({});
		await loadChatMessagesDelta({});
		await loadMyMeetingsDelta({});
		await loadContent({});
		await loadVoteDelta({});
		await loadMyVoteAnswers({});
		await loadMyBookings({});
		await loadComments({});
		await loadNetworkingRoomAttendees({});
		await loadLikes({});
		await loadAllBookingsDelta({});
		await loadPushNotifications({});
		await loadAttendeeSupportRequests({});
		await getSpaceSubscription({});

		setIsDataLoading(false);
	};

	const _isActiveRoute = (routeName: string) => {
		if (props?.state?.routes && props?.state?.routes[props?.state?.index]) {
			if (props?.state?.routes[props?.state?.index]?.state) {
				const tabState = props?.state?.routes[props?.state?.index]?.state;

				if (tabState?.routes && tabState.routes.length > 0) {
					const stackState = tabState?.routes[0]?.state;

					if (stackState?.routes && stackState?.index !== undefined) {
						const currentActiveTab = stackState?.routes[stackState?.index];
						return currentActiveTab?.name === routeName;
					} else {
						// Should get here when entering the space via deeplink
						// if case does not apply their due to missing screens in the navigation tree.

						// if the current route is a screen, check the screenname
						if (tabState?.routes[tabState.routes.length - 1].params?.screen) {
							return tabState?.routes[tabState.routes.length - 1].params?.screen === routeName;
						}

						// if the current route is not a screen, check on the current route tab to see if it matches
						//  e.g.
						// chat -> . chattab
						// attendeedetails -> attendeetab
						const currentRoute = tabState?.routes[tabState.routes.length - 1].name as ERoutes | undefined;
						if (currentRoute) {
							switch (routeName) {
								case ERoutes.VotingsTab:
									return [
										ERoutes.VotingCreate,
										ERoutes.VotingEdit,
										ERoutes.VotingModeration,
										ERoutes.ActiveVoting,
										ERoutes.ActiveVotingAdmin
									].includes(currentRoute);
								case ERoutes.FeedTab:
									return [ERoutes.NewsCreate, ERoutes.NewsDetails, ERoutes.NewsEdit, ERoutes.NewsList].includes(
										currentRoute
									);
								case ERoutes.SpeakersTab:
									return [ERoutes.SpeakerCreate, ERoutes.SpeakerDetails, ERoutes.SpeakerEdit, ERoutes.Speakers].includes(
										currentRoute
									);
								case ERoutes.AttendeesTab:
									return [ERoutes.AttendeeDetails, ERoutes.AttendeesList].includes(currentRoute);
								case ERoutes.ExposTab:
									const _expoId = tabState.routes[tabState.routes.length - 1].params?.id;
									if (_expoId) {
										const found = content.expos.find(
											(e) => e.spaceId === activeSpace?.spaceId && e.id === _expoId && e.sponsorType === 'sponsorOnly'
										);
										if (found) {
											return false;
										}
									}
									return [ERoutes.ExpoCreate, ERoutes.ExpoDetails, ERoutes.ExpoEdit, ERoutes.Expos].includes(
										currentRoute
									);
								case ERoutes.SponsorsTab:
									const _sponsorId = tabState.routes[tabState.routes.length - 1].params?.id;
									if (_sponsorId) {
										const found = content.expos.find(
											(e) =>
												e.spaceId === activeSpace?.spaceId && e.id === _sponsorId && e.sponsorType !== 'sponsorOnly'
										);
										if (found) {
											return false;
										}
									}
									return [ERoutes.Sponsors, ERoutes.ExpoDetails, ERoutes.ExpoEdit].includes(currentRoute);
								case ERoutes.ScheduleTab:
									return [
										ERoutes.Schedule,
										ERoutes.ScheduleCreate,
										ERoutes.ScheduleDetails,
										ERoutes.ScheduleEdit,
										ERoutes.ScheduleList,
										ERoutes.StreamCreate,
										ERoutes.StreamDetails,
										ERoutes.StreamEdit,
										ERoutes.StreamList,
										ERoutes.StageCreate,
										ERoutes.StageEdit,
										ERoutes.StageList,
										ERoutes.Media
									].includes(currentRoute);
								case ERoutes.ChatTab:
									return [ERoutes.Chat, ERoutes.ChatOverview].includes(currentRoute);
								case ERoutes.MeetingTab:
									return [ERoutes.MeetingList, ERoutes.MeetingRequest].includes(currentRoute);
								case ERoutes.NetworkingTab:
									return [
										ERoutes.NetworkingRoom,
										ERoutes.NetworkingRoomCreate,
										ERoutes.NetworkingRoomList,
										ERoutes.NetworkingRoomUpdate
									].includes(currentRoute);
								case ERoutes.MediaItemsTab:
									return [
										ERoutes.MediaItemCreate,
										ERoutes.MediaItemDetails,
										ERoutes.MediaItemEdit,
										ERoutes.MediaItemList
									].includes(currentRoute);
								case ERoutes.MapTab:
									return [
										ERoutes.MapCreate,
										ERoutes.MapEdit,
										ERoutes.MapOrder,
										ERoutes.MapPositionsEdit,
										ERoutes.Maps
									].includes(currentRoute);
								case ERoutes.WebTab:
									return [ERoutes.Web].includes(currentRoute);
								case ERoutes.MarkdownTab:
									return [ERoutes.Markdown].includes(currentRoute);
								case ERoutes.SettingsTab:
									return [
										ERoutes.ApiToken,
										ERoutes.Administrate,
										ERoutes.BookingDetails,
										ERoutes.BookingImport,
										ERoutes.BookingList,
										ERoutes.ContentTypeFields,
										ERoutes.CustomUserConstent,
										ERoutes.EditFeature,
										ERoutes.EditLandingpage,
										ERoutes.EditMailTemplate,
										ERoutes.EditSpace,
										ERoutes.EditSpaceDesign,
										ERoutes.EditSpaceSponsors,
										ERoutes.EditSpaceTexts,
										ERoutes.Features,
										ERoutes.HspxAdmin,
										ERoutes.ImportData,
										ERoutes.MailHistory,
										ERoutes.MailHistoryDetails,
										ERoutes.MailSettings,
										ERoutes.MailTemplateList,
										ERoutes.Matchings,
										ERoutes.More,
										ERoutes.PushNotification,
										ERoutes.PushNotificationList,
										ERoutes.Reports,
										ERoutes.SelfServiceSettings,
										ERoutes.SendMail,
										ERoutes.SpaceAdmins,
										ERoutes.SpaceRegistrationFields,
										ERoutes.Statistics,
										ERoutes.SupportRequest,
										ERoutes.SupportRequestList,
										ERoutes.TicketCreate,
										ERoutes.TicketEdit,
										ERoutes.TicketList,
										ERoutes.TicketSettings,
										ERoutes.WelcomeEdit
									].includes(currentRoute);
								default:
									break;
							}
						}
					}
				}
			}
		}
		return false;
	};

	const _renderBackButton = () => {
		if (!IS_WEB || isTabletOrMobile) {
			let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
			let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
			let color = primary === bg ? theme.navigationTextColor : theme.primary;

			return (
				<ChildButton
					testID="drawer_button_close"
					style={{
						flexDirection: 'row',
						alignSelf: 'flex-start',
						backgroundColor: 'transparent',
						marginBottom: 10
					}}
					onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
				>
					<Icon name={EDefaultIconSet.ArrowLeft} color={color} size={normalizeFontSize(NAVICONSIZE)} />
				</ChildButton>
			);
		}

		return null;
	};

	const _renderShareButton = () => {
		if (activeSpace) {
			let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
			let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
			let color = primary === bg ? theme.navigationTextColor : theme.primary;

			return (
				<ChildButton
					testID="drawer_button_share"
					style={{
						flexDirection: 'row-reverse',
						alignSelf: 'flex-end',
						alignItems: 'center',
						backgroundColor: 'transparent',
						marginBottom: 10
					}}
					onPress={async () => {
						if (Platform.OS === 'android' || Platform.OS === 'ios') {
							const didShare = await share(
								activeSpace?.title ?? '',
								activeSpace?.title ?? '',
								getSpaceShareUrl(activeSpace?.spaceId),
								activeSpace?.description ?? t('ShareMessageSpace')
							);
							if (didShare) {
								trackAction('space', 'Share');
							}
						} else {
							trackAction('space', 'Share');

							showAlert({
								title: t('Invite Attendees'),
								message: t('ShareSpaceText'),
								buttons: [
									{
										text: `shareUrl_${getSpaceShareUrl(activeSpace?.spaceId)}`
									},
									{
										text: t('CopyLink'),
										onPress: () => addToClipboard(getSpaceShareUrl(activeSpace?.spaceId)),
										style: 'default'
									},
									{
										text: t('email'),
										onPress: () => {
											navigation.navigate(ERoutes.SendMail, {
												spaceId: activeSpace.spaceId,
												entryPoint: 'receivers',
												contentType: 'invitation'
											});
										},
										style: 'default'
									}
								]
							});
						}
					}}
				>
					<Icon name={EDefaultIconSet.Share} color={color} size={normalizeFontSize(NAVICONSIZE)} />
					<Text
						style={{
							color: color,
							fontSize: NAVFONTSIZE,
							paddingHorizontal: 5
						}}
					>
						{t('Share')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderDrawerEntry = (
		testID: string,
		onPress: () => void,
		icon: string,
		title: string,
		isActive?: boolean,
		badge?: number,
		leftBadge?: number,
		contentType?: string
	) => {
		return (
			<DrawerButton
				key={testID}
				testID={testID}
				icon={icon}
				title={title}
				onPress={onPress}
				isActive={isActive}
				leftBadge={leftBadge}
				badge={badge}
				iconColor={isActive ? theme.primary : theme.navigationTextColor ?? theme.text}
				textColor={theme.navigationTextColor ?? theme.text}
				isExternalButton={contentType === 'externalUrl'}
			/>
		);
	};

	const _renderLogo = () => {
		if (activeSpace) {
			let src = '';
			switch (activeSpace.logoImageName) {
				case 'defaultLogoGray':
					src = defaultLogoGray;
					break;
				case 'defaultLogo':
				case 'default':
				default:
					src = defaultLogo;
					break;
			}
			if (src || activeSpace?.logoImage) {
				return (
					<View style={{ marginBottom: hsBottomMargin }}>
						<Image
							mediaObj={activeSpace?.logoImage}
							imageSize="small"
							url={src}
							style={{ width: '70%', aspectRatio: 16 / 9, alignSelf: 'center', marginBottom: 2 }}
						/>
						<H1 testID="drawer_title" center noBottomMargin style={{ color: theme.navigationTextColor ?? theme.text }}>
							{activeSpace.sidebarTitle}
						</H1>
					</View>
				);
			}
		}

		return null;
	};

	const _getRouteDetailsByKey = (key: string, index: number) => {
		const feature = activeSpace?.features?.list.find((e) => e.key === key);

		const { tab, route, icon, label } = getSettingsForNavigationKey(key, feature);

		const obj = { tab, route, icon, label, badge: 0, leftBadge: 0, params: {} };

		switch (key) {
			case 'meetings':
				obj.badge = pendingMeetingsCount;
				break;
			case 'chats':
				obj.badge = unreadChatCount;
				break;
			case 'feed':
				obj.leftBadge = pendingFeedAndCommentCount;
				obj.badge = unreadFeedCount;
				break;
			case 'sponsors':
				obj.params = { isSponsors: true };
				break;
			case 'schedules':
				obj.badge = liveScheduleEntries;
				break;
			default:
				const featureIndex = (activeSpace?.features?.list ?? [])
					.filter((e) => e.contentType === feature?.contentType && e.isActive)
					.findIndex((e) => e.key === key);

				switch (feature?.contentType) {
					case 'externalUrl':
						obj.params = { key: feature.key, target: 'external', url: feature.externalUrl };
						break;
					case 'schedules':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.ScheduleTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'expos':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.ExposTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'speakers':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.SpeakersTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'mediaitems':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.MediaItemsTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'attendees':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.AttendeesTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'iFrame':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.WebTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'markdown':
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.MarkdownTab + (featureIndex + 1)) as ERoutes;
						break;
					case 'votings':
						if (!feature.itemId) {
							obj.badge = activeVotings;
						}
						obj.params = { key: feature.key };
						obj.tab = (ERoutes.VotingsTab + (featureIndex + 1)) as ERoutes;
						break;
					default:
						if (key === 'votings') {
							obj.badge = activeVotings;
						}
						break;
				}
		}

		return obj;
	};

	const handleNavigationPress = (routeDetails) => {
		let prohibited = false;
		let isNetworkingRoom = false;
		let isInZoomCall = false;

		let index: number | undefined = 0;
		const state = navigation.getState();
		if (state?.routes) {
			index = state?.routes[0]?.state?.index;
		}
		if (activeNetworkingRoom) {
			isNetworkingRoom = true;
		}
		if (index && state?.routes && state.routes[0]?.state?.routes) {
			prohibited = state?.routes[0]?.state?.routes[index]?.params?.prohibitNavigation;
			isNetworkingRoom = prohibited && state?.routes[0]?.state?.routes[index]?.name === 'networkingroom';
		}

		if (mediaDetail?.itemId && mediaDetail.itemType) {
			let _item: IMediaItem | ISchedule | undefined = undefined;
			switch (mediaDetail.itemType) {
				case 'mediaitem':
					_item = content.mediaitems.find((e) => e.id === mediaDetail.itemId);
					if (_item?.mediaType === 'zoom' && mediaDetail.playbackStatus === 'playing') {
						isInZoomCall = true;
					}
					break;
				case 'schedule':
					_item = content.schedules.find((e) => e.id === mediaDetail.itemId);
					if (
						_item?.status?.status === 'Live' &&
						_item?.stream &&
						mediaDetail.hasOwnProperty('activeStreamIndex') &&
						_item.stream[mediaDetail.activeStreamIndex!]?.streamType === 'zoom' &&
						mediaDetail.playbackStatus === 'playing'
					) {
						isInZoomCall = true;
					}
					break;
				default:
					break;
			}
		}

		try {
			if (isInZoomCall && IS_WEB) {
				showAlert({
					title: t('leaveZoomCallTitle'),
					message: t('leaveZoomCallSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('leaveZoomCallTitle'),
							style: 'destructive',
							onPress: async () => {
								setMediaDetailPlaybackStatus('paused');
								trackAction(mediaDetail.itemType ?? 'schedule', 'Stop Video', `${mediaDetail.itemId}`);
								navigation.navigate('tab', {
									screen: routeDetails.tab,
									params: {
										screen: routeDetails.route,
										spaceId: activeSpace?.spaceId,
										...(routeDetails.params ?? {})
									}
								});
							}
						}
					]
				});
			} else if (prohibited || isNetworkingRoom) {
				showAlert({
					title: isNetworkingRoom ? t('LeaveNetworkingRoom') : t('unsavedChanges'),
					message: isNetworkingRoom ? t('LeaveNetworkingRoomSubtitle') : t('unsavedChangesSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: isNetworkingRoom ? t('LeaveNetworkingRoom') : t('leaveWithoutSaving'),
							style: 'destructive',
							onPress: async () => {
								if (routeDetails?.params?.target === 'external') {
									if (routeDetails.params?.url && !isEmptyString(routeDetails.params.url)) {
										openURL(routeDetails.params.url);
									}
								} else {
									navigation.navigate('tab', {
										screen: routeDetails.tab,
										params: {
											screen: routeDetails.route,
											spaceId: activeSpace?.spaceId,
											...(routeDetails.params ?? {})
										}
									});
								}
							}
						}
					]
				});
			} else {
				if (routeDetails?.params?.target === 'external') {
					if (routeDetails.params?.url && !isEmptyString(routeDetails.params.url)) {
						openURL(routeDetails.params.url);
					}
				} else {
					navigation.navigate('tab', {
						screen: routeDetails.tab,
						params: { screen: routeDetails.route, spaceId: activeSpace?.spaceId, ...(routeDetails.params ?? {}) }
					});
				}
			}
		} catch (error) {
			console.error('cannotNavigate', error);
		}
	};

	const _renderTabNavigationWeb = () => {
		if (isProtectedbyPin()) {
			return null;
		}

		if (activeSpace?.features && IS_WEB && !isTabletOrMobile) {
			let activeFeatures = Object.values(activeSpace.features.list).filter((e) => e.isActive);
			const hasAttendeeActive = activeFeatures.find((e) => e.key === 'attendees' && e.isActive);
			if (!hasAttendeeActive) {
				const forbidden = ['chats', 'meetings', 'networking'];
				activeFeatures = activeFeatures.filter((e) => !forbidden.includes(e.key));
			}

			return (
				<View>
					{activeFeatures.map((feature, index) => {
						const routeDetails = _getRouteDetailsByKey(feature.key, index);
						if (routeDetails) {
							return _renderDrawerEntry(
								`drawer_button_${feature.key}`,
								() => handleNavigationPress(routeDetails),
								routeDetails.icon,
								routeDetails.label ?? '',
								_isActiveRoute(routeDetails.tab),
								routeDetails.badge,
								routeDetails.leftBadge,
								feature.contentType
							);
						}
					})}
				</View>
			);
		}

		return null;
	};

	const _renderAdminScreen = () => {
		if (__DEV__ || HELLOSPACES_USERS.includes(profile?.email)) {
			return (
				<>
					<DrawerSeparator />
					{_renderDrawerEntry(
						'drawer_button_components',
						() => {
							navigation.dispatch(DrawerActions.closeDrawer());
							navigation.navigate(ERoutes.HspxAdmin);
						},
						EDefaultIconSet.Moderate,
						t('HspxAdmin')
					)}
				</>
			);
		}
		return null;
	};

	const isProtectedbyPin = () => {
		if (activeSpace) {
			const { isAdmin, needsPin } = canJoinSpace(activeSpace, profile);

			if (!isAdmin && needsPin) {
				return true;
			}
		}

		return false;
	};

	return (
		<View
			style={{ flex: 1, backgroundColor: theme.background }}
			onMouseMove={
				canHideScrollbar && isScrollbarVisible
					? () => {
							setShowDrawerScroll(true);

							clearTimeout(hideTimeout.current);
							hideTimeout.current = setTimeout(() => {
								if (showDrawerScroll) {
									setShowDrawerScroll(false);
								}
							}, 1500);
					  }
					: undefined
			}
			onMouseLeave={canHideScrollbar && isScrollbarVisible ? () => setShowDrawerScroll(false) : undefined}
			ref={drawerRef}
		>
			<DrawerContentScrollView
				{...props}
				ref={scrollerRef}
				style={{ width: '100%', paddingHorizontal: !isPortrait ? (left === 0 ? 20 : 0) : 10 }}
				contentContainerStyle={{
					width: '100%',
					paddingTop: isPortrait ? 20 : 20,
					justifyContent: 'space-between',
					flex: 1
				}}
				showsVerticalScrollIndicator={showDrawerScroll}
				testID="drawer_scrollview"
				onScroll={
					canHideScrollbar && isScrollbarVisible
						? () => {
								setShowDrawerScroll(true);

								clearTimeout(hideTimeout.current);
								hideTimeout.current = setTimeout(() => {
									if (showDrawerScroll) {
										setShowDrawerScroll(false);
									}
								}, 1500);
						  }
						: undefined
				}
				scrollEventThrottle={16}
			>
				<View style={{ marginRight: activeSpace && canHideScrollbar && showDrawerScroll ? -SCROLLBAR_WIDTH : 0 }}>
					<View
						style={{
							flexDirection: !IS_WEB || isTabletOrMobile ? 'row' : 'row-reverse',
							width: '100%',
							justifyContent: 'space-between',
							paddingRight: 20
						}}
					>
						{_renderBackButton()}
						{/* {_renderShareButton()} */}
					</View>
					{_renderLogo()}
					{jwt && (
						<DrawerViewProfileInfo
							onPress={() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.EditProfile, { spaceId: activeSpace?.spaceId });
							}}
						/>
					)}
					{_renderTabNavigationWeb()}
					{activeSpace &&
						iAmSpaceAdmin &&
						IS_WEB &&
						!isTabletOrMobile &&
						_renderDrawerEntry(
							'drawer_button_administrate',
							() => handleNavigationPress({ tab: ERoutes.SettingsTab, route: ERoutes.Administrate }),
							EDefaultIconSet.Settings,
							t('Administrate'),
							_isActiveRoute(ERoutes.SettingsTab),
							unreadSupportRequests
						)}
					{activeSpace && IS_WEB && !isTabletOrMobile && <View style={{ marginVertical: 10 }} />}
					{!jwt &&
						_renderDrawerEntry(
							'drawer_button_login',
							() => {
								const state = navigation.getState();
								const activeRoute = state.routes[state.index];

								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.SpaceAuth, {
									authState: 'login',
									redirectUrl: activeRoute?.state?.routes[0].params?.redirectUrl,
									redirectRoute: activeSpace ? 'tab' : undefined
								});
							},
							EDefaultIconSet.Login2,
							t('sidebarLogin')
						)}
					{!jwt &&
						_renderDrawerEntry(
							'drawer_button_register',
							() => {
								const state = navigation.getState();
								const activeRoute = state.routes[state.index];

								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.SpaceAuth, {
									authState: 'register',
									redirectUrl: activeRoute?.state?.routes[0].params?.redirectUrl
								});
							},
							EDefaultIconSet.Register,
							t('sidebarRegister')
						)}
					{!activeSpace &&
						jwt &&
						profile &&
						((profile?.adminInSpaces?.length ?? 0) > 0 || (profile?.moderatorInSpaces?.length ?? 0) > 0) &&
						_renderDrawerEntry(
							'drawer_button_myspaces',
							() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.MySpaces);
							},
							EDefaultIconSet.HelloSpaces,
							t('mySpacesSidebar')
						)}
					{activeSpace &&
						_renderDrawerEntry(
							'drawer_button_supportrequest',
							() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								setIsSupportRequestModalVisible(true);
							},
							EDefaultIconSet.Support,
							t('sideBarSupportRequest')
						)}
					{activeSpace &&
						!isEmptyString(activeSpace?.customUserConsentText) &&
						_renderDrawerEntry(
							'drawer_button_userconstent',
							() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.CustomUserConstent, { spaceId: activeSpace?.spaceId });
							},
							EDefaultIconSet.Privacy,
							t('sideBarUserConsent')
						)}
					{activeSpace
						? (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) &&
						  _renderDrawerEntry(
								'drawer_button_leaveSpace',
								async () => {
									navigation.dispatch(DrawerActions.closeDrawer());
									await leaveSpace({});

									if (navigation.canGoBack()) {
										navigation.dispatch(StackActions.popToTop());
									}

									navigation.dispatch(
										StackActions.replace(
											!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
										)
									);
								},
								EDefaultIconSet.LeaveSpace,
								t('Leave Space')
						  )
						: PRESET_SPACEIDS
						? null
						: _renderDrawerEntry(
								'drawer_button_createSpace',
								() => {
									navigation.dispatch(DrawerActions.closeDrawer());
									navigation.navigate(ERoutes.SpaceCreate);
								},
								EDefaultIconSet.CreateSpace,
								t('Create Space')
						  )}
					<SocketInfo />
					{/* {__DEV__ ? (
					<>
						<DrawerSeparator />

						{_renderDrawerEntry(
							'drawer_button_components',
							() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								navigation.navigate(ERoutes.Components);
							},
							EDefaultIconSet.Components,
							t('Components')
						)}
					</>
				) : null} */}
					{_renderAdminScreen()}
					<AuthenticationModal
						isVisible={isAuthenticationModalVisible}
						onClose={() => setIsAuthenticationModalVisible(false)}
						onSubmit={() => setIsAuthenticationModalVisible(false)}
					/>
					<SupportRequestModal
						space={activeSpace}
						isVisible={isSupportRequestModalVisible}
						onClose={() => setIsSupportRequestModalVisible(false)}
					/>
					{activeSpace && IS_WEB && !isTabletOrMobile && <View style={{ marginVertical: 10 }} />}
				</View>

				<View style={{ marginRight: activeSpace && canHideScrollbar && showDrawerScroll ? -SCROLLBAR_WIDTH : 0 }}>
					<View style={{ marginVertical: hsInnerPadding }}>
						<ChildButton
							testID="drawer_button_hellospaces"
							onPress={() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								openURL('https://hellospaces.de/?pk_campaign=app_sidebar');
							}}
							style={{ paddingHorizontal: 20, alignItems: 'center' }}
						>
							<Image
								url={isDarkColor(theme.background) ? HelloSpacesWhite : HelloSpaces}
								style={{ width: '75%', aspectRatio: 7 }}
							/>
						</ChildButton>
						{activeSpace?.spaceId && (
							<Text center style={{ fontSize: 12, color: theme.navigationTextColor ?? theme.gray, marginTop: 2 }}>
								Space ID: {activeSpace?.spaceId}
							</Text>
						)}
					</View>
					<NativeVersionInfo />
					<View
						style={{
							flexDirection: 'row',
							flexWrap: 'wrap',
							alignItems: 'center',
							justifyContent: 'center',
							marginVertical: hsBottomMargin
						}}
					>
						<ChildButton
							testID={'drawer_button_imprint'}
							onPress={() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								openURL(`${IMPRINTURL}?pk_campaign=app_sidebar`);
							}}
							style={{ marginBottom: 5 }}
						>
							<Text
								center
								style={{
									color: theme.navigationTextColor ?? theme.gray,
									fontSize: normalizeFontSize(10),
									opacity: 0.7
								}}
							>
								{t('Imprint')}
							</Text>
						</ChildButton>

						<ChildButton
							testID={'drawer_button_dataProtection'}
							style={{
								borderLeftWidth: 1,
								borderColor: theme.lightgray,
								marginLeft: 10,
								paddingLeft: 10,
								marginBottom: 5
							}}
							onPress={() => {
								navigation.dispatch(DrawerActions.closeDrawer());
								openURL(`${DATAPROTECTIONURL}?pk_campaign=app_sidebar`);
							}}
						>
							<Text
								center
								style={{
									color: theme.navigationTextColor ?? theme.gray,
									fontSize: normalizeFontSize(10),
									opacity: 0.7
								}}
							>
								{t('Data Protection')}
							</Text>
						</ChildButton>

						<ChildButton
							testID={'drawer_button_reloaddata'}
							style={{
								borderLeftWidth: 1,
								borderColor: theme.lightgray,
								marginLeft: 10,
								paddingLeft: 10,
								marginBottom: 5
							}}
							onPress={() => {
								showAlert({
									title: t('Reload Data'),
									message: t('Reload Data Subtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('Reload Data'),
											style: 'destructive',
											onPress: () => _reloadAllData()
										}
									]
								});

								// navigation.dispatch(DrawerActions.closeDrawer());
							}}
						>
							<Text center style={{ color: theme.danger, fontSize: normalizeFontSize(10), opacity: 0.7 }}>
								{t('Reload Data')}
							</Text>
						</ChildButton>

						<ChildButton
							testID={'drawer_button_resetApp'}
							style={{
								borderLeftWidth: 1,
								borderColor: theme.lightgray,
								marginLeft: 10,
								paddingLeft: 10,
								marginBottom: 5
							}}
							onPress={() => {
								showAlert({
									title: t('Reset App'),
									message: t('Reset App Subtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('Reset App'),
											style: 'destructive',
											onPress: () => resetApp({})
										}
									]
								});

								navigation.dispatch(DrawerActions.closeDrawer());
							}}
						>
							<Text center style={{ color: theme.danger, fontSize: normalizeFontSize(10), opacity: 0.7 }}>
								{t('Reset App')}
							</Text>
						</ChildButton>
					</View>
				</View>
			</DrawerContentScrollView>
			<LoadingModal isLoading={isDataLoading} />
		</View>
	);
};
