import moment from 'moment';
import { ILauncherSpace } from './interfaces';

export const ENVIRONMENT: 'dev' | 'stage' | 'prod' | 'bits' | 'k5' | 'nwx' | 'metro' = 'prod';
export const DEFAULT_MULTISPACE_URL = 'https://multispace-prod.hspx.de';
export const DEFAULT_PLATFORM_URL = 'https://app.nocoderocks.com/';
export const DEFAULT_PLATFORM_SHARE_URL = 'https://app.nocoderocks.com/app/spacesummary/';
export const PRESET_SPACEIDS: string[] | undefined = ['nocode'];
export const PRESET_SPACE_FALLBACK: ILauncherSpace | undefined = {
	id: 393,
	title: 'NoCode Rocks',
	spaceId: 'nocode',
	multispaceId: 393,
	multispaceUrl: 'https://multispace-prod.hspx.de',
	startDate: moment('31.01.2023', 'DD.MM.YYYY').toISOString(),
	endDate: moment('31.01.2023', 'DD.MM.YYYY').toISOString(),
	created_at: moment('01.01.2023', 'DD.MM.YYYY').toISOString(),
	published_at: moment('01.01.2023', 'DD.MM.YYYY').toISOString(),
	updated_at: moment('01.01.2023', 'DD.MM.YYYY').toISOString()
};
export const SENTRY_DSN = 'https://f86f16d7055845e5be2f36fb93365f87@sentry.hspx.de/2';
export const MIN_BUILD_VERSION_IOS = 1;
export const MIN_BUILD_VERSION_ANDROID = 1;
export const APP_STORE_URL_IOS = 'https://apps.apple.com/us/app/nocode-rocks/id1662364945';
export const APP_STORE_URL_ANDROID = 'https://play.google.com/store/apps/details?id=de.hellospaces.nocoderocks';
export const DEEPLINK_SHORT = 'nocoderocks://';

export const APP_LANGUAGE: 'de' | 'en' | undefined = undefined;

export const QuotaLimits = {
	attendees: 50,
	admins: 1,
	moderators: 1
};
