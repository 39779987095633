import { Icon } from 'components/Icon';
import { ERoutes } from 'components/Navigation/routes';
import { TTrackingContentType } from 'config/interfaces';
import { addToClipboard } from 'helper/string';
import { EDefaultIconSet } from 'helper/icon';
import { IS_WEB } from 'helper/platform';
import { share } from 'helper/share';
import { getDetailShareUrl } from 'helper/url';

import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, ViewStyle } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { ChildButton } from './ChildButton';
import { RoundButton } from './RoundButton';
import * as MailComposer from 'expo-mail-composer';

interface IShareButton {
	testID: string;
	title?: string;
	message?: string;
	itemId: number;
	route: ERoutes;
	containerStyle?: ViewStyle;
	isRoundButton?: boolean;
	type: TTrackingContentType;
	isLoading?: boolean;
}

export const ShareButton = (props: IShareButton) => {
	const { testID, title, message, itemId, route, containerStyle, isRoundButton, type, isLoading } = props;
	const { activeSpace } = useSpace();
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const { t } = useTranslation();
	const { trackAction } = useTracker();

	const _handleShare = async () => {
		if (activeSpace) {
			if (Platform.OS === 'android' || Platform.OS === 'ios') {
				const didShare = await share(
					title ?? '',
					title ?? '',
					getDetailShareUrl(activeSpace.spaceId, route, itemId),
					message ?? ''
				);
				if (didShare) {
					trackAction(type, 'Share', itemId.toString());
				}
			} else {
				trackAction(type, 'Share', itemId.toString());
				showAlert({
					title: `${t('Share')}`,
					message: `${title ? title + '\n' : ''}${message ?? ''}`,
					buttons: [
						{
							text: `shareUrl_${getDetailShareUrl(activeSpace.spaceId, route, itemId)}`
						},
						{
							text: t('CopyLink'),
							onPress: () => addToClipboard(getDetailShareUrl(activeSpace.spaceId, route, itemId)),

							style: 'default'
						},
						{
							text: t('email'),
							onPress: async () => {
								let subjectText = `${title ?? ''}`;
								let bodyText = `${title ? title + '\n' : ''}${message ? message + '\n' : ''}${getDetailShareUrl(
									activeSpace.spaceId,
									route,
									itemId
								)}`;
								const isAvailable = await MailComposer.isAvailableAsync();
								if (isAvailable) {
									const res = await MailComposer.composeAsync({
										subject: subjectText,
										body: bodyText,
										recipients: []
									});
								}
							},
							style: 'default'
						}
					]
				});
			}
		}
	};

	if (IS_WEB) {
		return null;
	}

	if (isRoundButton) {
		return (
			<View style={{ justifyContent: 'center', ...containerStyle }}>
				<RoundButton
					testID={testID}
					onPress={_handleShare}
					isDisabled={!activeSpace}
					icon={EDefaultIconSet.Share}
					size="sm"
					isOutline
					isLoading={isLoading ?? false}
				/>
			</View>
		);
	}
	return (
		<ChildButton
			testID={testID}
			style={{ justifyContent: 'center', marginHorizontal: 5, ...containerStyle }}
			isDisabled={!activeSpace}
			onPress={_handleShare}
		>
			<Icon name={EDefaultIconSet.Share} size={25} />
		</ChildButton>
	);
};
