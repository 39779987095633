import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultVotingFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'publicId',
			fieldLabel: 'Public ID',
			fieldType: 'string',
			visibility: 'never'
		},
		{
			fieldName: 'votingType',
			fieldLabel: 'Voting Type',
			isRequired: true,
			fieldType: 'multiswitch',
			options: [
				{
					key: 'text',
					label: 'Text'
				},
				{
					key: 'rating',
					label: 'Rating'
				},
				{
					key: 'scale',
					label: 'Scale'
				},
				{
					key: 'wordCloud',
					label: 'Word Cloud'
				},
				{
					key: 'applause',
					label: 'Applause'
				},
				{
					key: 'multipleChoice',
					label: 'Multiple Choice'
				},
				{
					key: 'quiz',
					label: 'Quiz'
				},
				{
					key: 'survey',
					label: 'Survey'
				},
				{
					key: 'nps',
					label: 'NPS'
				},
				{
					key: 'externalUrl',
					label: 'External URL'
				},
				{
					key: 'optionVote',
					label: 'Vote Option'
				}
			],
			visibility: 'visible'
		},
		{
			fieldName: 'question',
			fieldLabel: i18next.t('Question'),
			fieldHint: i18next.t('questionHint'),
			fieldType: 'string',
			isRequired: true,
			visibility: 'visible'
		},
		{
			fieldName: 'hint',
			fieldLabel: i18next.t('Hint'),
			fieldHint: i18next.t('VoteHint'),
			fieldType: 'string',
			visibility: 'visible'
		},
		{
			fieldName: 'isActive',
			fieldLabel: i18next.t('VoteActive'),
			fieldHint: i18next.t('IsActiveHint'),
			fieldType: 'multiswitch',
			options: [
				{
					key: 'true',
					label: i18next.t('Active')
				},
				{
					key: 'false',
					label: i18next.t('Inactive')
				}
			],
			visibility: 'visible'
		},
		{
			fieldName: 'allowAnonymousAnswers',
			fieldLabel: i18next.t('AnonymousAnswersLabel'),
			fieldHint: i18next.t('anonymousAnswersHint'),
			fieldType: 'multiswitch',
			options: [
				{ key: 'always', label: i18next.t('Yes') },
				{ key: 'optin', label: i18next.t('OptInAnonymous') },
				{ key: 'never', label: i18next.t('No') }
			],
			visibility: 'visible'
		},
		{
			fieldName: 'openFrom',
			fieldName2: 'openUntil',
			fieldLabel: i18next.t('datePickerLabel'),
			fieldHint: i18next.t('VoteDateHint'),
			extraLabel1: i18next.t('OpenFromLabel'),
			extraLabel2: i18next.t('OpenUntilLabel'),
			fieldType: 'dateRange',
			visibility: 'visible'
		},
		{
			fieldName: 'viewType',
			fieldLabel: i18next.t('viewType'),
			fieldType: 'multiswitch',
			options: [
				{ key: 'bar', label: i18next.t('barViewType') },
				{ key: 'pie', label: i18next.t('pieViewType') },
				{ key: 'star', label: i18next.t('Star') },
				{ key: 'thumbs', label: i18next.t('Thumbs') }
			],
			visibility: 'visible'
		},
		{
			fieldName: 'options',
			fieldLabel: i18next.t('Options'),
			fieldType: 'component',
			visibility: 'visible'
		},
		{
			fieldName: 'minValue',
			fieldLabel: i18next.t('maxValRating'),
			fieldType: 'number',
			visibility: 'visible'
		},
		{
			fieldName: 'maxValue',
			fieldLabel: i18next.t('maxValRating'),
			fieldType: 'number',
			visibility: 'visible'
		},
		{
			fieldName: 'isModerated',
			fieldLabel: i18next.t('IsModerated'),
			fieldHint: i18next.t('IsModeratedHint'),
			fieldType: 'boolean',
			visibility: 'visible'
		},
		{
			fieldName: 'allowMedia',
			fieldLabel: i18next.t('allowMedia'),
			fieldHint: i18next.t('allowMediaHint'),
			fieldType: 'multiswitch',
			options: [
				{
					key: 'true',
					label: i18next.t('allowMediaTrue')
				},
				{
					key: 'false',
					label: i18next.t('allowMediaFalse')
				}
			],
			visibility: 'visible'
		},
		{
			fieldName: 'media',
			fieldLabel: 'Media',
			fieldType: 'component',
			visibility: 'visible'
		},
		{
			fieldName: 'shareText',
			fieldLabel: 'Share Text',
			fieldType: 'string',
			visibility: 'visible'
		},
		{
			fieldName: 'timeOut',
			fieldLabel: 'Timeout',
			fieldType: 'number',
			visibility: 'visible'
		},
		{
			fieldName: 'hideResults',
			fieldLabel: i18next.t('HideResults'),
			fieldType: 'multiswitch',
			options: [
				{
					key: 'false',
					label: i18next.t('ShowResults')
				},
				{
					key: 'true',
					label: i18next.t('HideResults')
				}
			],
			visibility: 'visible'
		}
	];

	return arr;
};
