import { Title } from 'components/Text';
import { TVotingType } from 'config/interfaces/vote';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { VoteIcon } from 'components/Icon';
import { useQuery } from 'hooks/useQuery';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { FormHint } from 'components/Form/FormHint';

interface IVoteSelectType {
	testID: string;
	onChange: (val) => void;
	value?: TVotingType;
}

export const VoteSelectType = (props: IVoteSelectType) => {
	const { testID, onChange, value } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const _renderButton = (voteType: TVotingType) => {
		return (
			<View style={{ width: '33%' }}>
				<VoteIcon
					voteType={voteType}
					testID={testID}
					onPress={() => {
						if (onChange) {
							onChange(voteType);
						}
					}}
					alignSelf="center"
					isActive={voteType === value}
				/>
			</View>
		);
	};

	return (
		<ScrollView contentContainerStyle={{ width: screenWidth, alignSelf: 'center', padding: EHorizontalScreenPadding.Wide }}>
			<HSCard>
				<Title>{t('VoteSelectType')}</Title>
			</HSCard>
			<HSCard>
				<View style={styles.row}>
					{_renderButton('text')}
					{_renderButton('rating')}
					{_renderButton('scale')}
				</View>
				<View style={styles.row}>
					{_renderButton('wordCloud')}
					{_renderButton('applause')}
					{_renderButton('multipleChoice')}
				</View>
				<View style={styles.row}>
					{_renderButton('survey')}
					{_renderButton('quiz')}
					{_renderButton('externalUrl')}
				</View>
				<View style={styles.row}>
					{_renderButton('nps')}
					{_renderButton('pinOnImage')}
					{_renderButton('happinessOMeter')}
				</View>
				<FormHint testID={`${testID}_hint_vote`} hint={t(`create${value}SubtitleHint`)} />
			</HSCard>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		marginBottom: 10
	},
	container: {
		marginHorizontal: 5,
		flex: 1
	}
});
