import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

export const QUIZLEADERBOARDLISTHEADERHEIGHT = 20;

interface IQuizLeaderboardListHeader {
	text: string;
	largerDesign?: boolean;
}

export const QuizLeaderboardListHeader = (props: IQuizLeaderboardListHeader) => {
	const { text, largerDesign } = props;
	const { theme } = useTheme();
	return (
		<View
			style={{
				height: largerDesign ? QUIZLEADERBOARDLISTHEADERHEIGHT * 1.5 : QUIZLEADERBOARDLISTHEADERHEIGHT,
				borderBottomWidth: 1,
				borderColor: theme.text,
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
		>
			<Text largerText={largerDesign} bold>
				{text}
			</Text>
		</View>
	);
};
