import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FlatList, View, ScrollView } from 'react-native';

import { useSpace } from 'hooks/useSpace';
import { Markdown } from 'components/Markdown';
import { HSModal } from '../Modal';
import { EDefaultIconSet, isEmptyString, IS_IOS, IS_WEB } from 'helper';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { ListBubbles } from 'components/List';
import { NewsMediaItem } from 'components/News/NewsMediaItem';
import { hsInnerPadding } from 'config/styleConstants';
import { useFocusEffect } from '@react-navigation/native';

interface IWelcomeModal {}

const TESTIDPREFIX = 'welcome';

export const WelcomeModal = (props: IWelcomeModal) => {
	const {} = props;
	const { activeSpace } = useSpace();

	const isWelcomeModalVisible = useSelector((store: IRootState) => store.space.isWelcomeModalVisible);

	const setIsWelcomeModalVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.space.setIsWelcomeModalVisible);

	const flatlistRef = useRef<any | undefined>(undefined);

	const [isVisible, setIsVisible] = useState<boolean>(false);

	const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [isScrollStopped, setIsScrollStopped] = useState<boolean>(false);

	useFocusEffect(
		React.useCallback(() => {
			let interval;
			if (activeSpace?.enableGalleryScroll && flatlistRef.current && containerWidth > 1 && isVisible) {
				let _currentIndex = -1;
				let _iteration = 0;

				interval = setInterval(() => {
					if (isScrollStopped) {
						clearInterval(interval);
						return;
					}
					if (flatlistRef.current && activeSpace.welcomeGallery && activeSpace.welcomeGallery.length > 1) {
						if (_currentIndex >= activeSpace.welcomeGallery.length) {
							_iteration++;
							if (_iteration >= 5) {
								clearInterval(interval);
								return;
							} else {
								_currentIndex = -1;
							}
						}

						_currentIndex = _currentIndex + 1;
						flatlistRef.current.scrollToOffset({ offset: _currentIndex * containerWidth });

						if (
							activeSpace.welcomeGallery[_currentIndex]?.media &&
							activeSpace.welcomeGallery[_currentIndex].media?.mime?.includes('video')
						) {
							clearInterval(interval);
							return;
						}
					}
				}, (activeSpace?.galleryScrollInterval ?? 5) * 1000);
			}
			return () => {
				clearInterval(interval);
			};
		}, [flatlistRef, activeSpace?.enableGalleryScroll, containerWidth, isScrollStopped])
	);

	useEffect(() => {
		// wait with showing the modal on ios, since it cannot close one modal and show another right after
		if (isWelcomeModalVisible && IS_IOS) {
			setTimeout(() => {
				setIsVisible(isWelcomeModalVisible);
			}, 2500);
		} else {
			setIsVisible(isWelcomeModalVisible);
		}
	}, [isWelcomeModalVisible]);

	const _renderCurrentMediaIndex = () => {
		if (activeSpace?.welcomeGallery && activeSpace?.welcomeGallery?.length > 1) {
			const length = activeSpace?.welcomeGallery?.filter((i) => i.media !== undefined && i.media !== null)?.length;

			if (length > 0) {
				if (IS_WEB) {
					return (
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
								marginTop: 0,
								alignSelf: 'center',
								position: 'absolute',
								left: 0,
								right: 0
							}}
						>
							<ChildButton
								style={{ marginRight: 30 }}
								testID={`${TESTIDPREFIX}_button_left`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex - 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronLeft} />
							</ChildButton>
							<ListBubbles totalCount={length} activeIndex={currentMediaIndex} noTopMargin />
							<ChildButton
								style={{ marginLeft: 30 }}
								testID={`${TESTIDPREFIX}_button_right`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex + 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronRight} />
							</ChildButton>
						</View>
					);
				}
				return (
					<View style={{ alignSelf: 'center', position: 'absolute', left: 0, right: 0, paddingTop: 1 }}>
						<ListBubbles totalCount={length} activeIndex={currentMediaIndex} size="sm" noTopMargin />
					</View>
				);
			}
		}
		return null;
	};

	if (activeSpace && (!isEmptyString(activeSpace.welcomeTitle) || !isEmptyString(activeSpace.welcomeText))) {
		return (
			<HSModal
				isVisible={isVisible}
				title={activeSpace?.welcomeTitle}
				onClose={() => {
					setIsWelcomeModalVisible(false);
					setIsVisible(false);
				}}
			>
				<ScrollView
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width - 2 * hsInnerPadding)}
					style={{ flex: 1, marginHorizontal: -hsInnerPadding }}
					contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
				>
					<FlatList
						ref={flatlistRef}
						scrollEnabled={activeSpace?.welcomeGallery && activeSpace.welcomeGallery.length > 1}
						data={activeSpace?.welcomeGallery}
						renderItem={({ item }) => {
							if (!item.media) {
								return null;
							}
							return <NewsMediaItem item={item} width={containerWidth} isRounded onPlay={() => setIsScrollStopped(true)} />;
						}}
						keyExtractor={(item, index) => `news_${item.id}_${index}`}
						pagingEnabled
						horizontal
						showsHorizontalScrollIndicator={false}
						onMomentumScrollEnd={(e) => setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth)}
						onScroll={(e) => {
							if (IS_WEB) {
								setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth);
							}
						}}
						onTouchMove={() => setIsScrollStopped(true)}
					/>
					{activeSpace?.welcomeGallery && activeSpace?.welcomeGallery?.length > 1 && (
						<View
							style={{
								alignSelf: 'center',
								zIndex: 1,
								marginTop: 5,
								marginBottom: IS_WEB ? 10 : 0
							}}
						>
							{_renderCurrentMediaIndex()}
						</View>
					)}
					<Markdown markdown={activeSpace?.welcomeText} />
				</ScrollView>
			</HSModal>
		);
	}

	return null;
};
