import React, { useEffect, useState } from 'react';
import { IAttendee, ISendingInformation } from 'config/interfaces';
import { ERoutes } from 'components/Navigation/routes';
import { ScheduleReferenceItem } from 'components/Schedule';
import { H2, H3, Text } from 'components/Text';
import { t } from 'i18next';
import { FlatList, View } from 'react-native';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useNavigation } from '@react-navigation/native';
import { AttendeeListItem } from 'components/Attendee';

interface IPushSendingInformation {
	TESTIDPREFIX: string;
	informations?: ISendingInformation;
}

export const PushSendingInformation = (props: IPushSendingInformation) => {
	const { TESTIDPREFIX, informations } = props;
	const { theme } = useTheme();
	const navigation = useNavigation();
	const { activeSpace } = useSpace();

	const [sendingInformationItem, setSendingInformationItem] = useState<any>(undefined);
	const [receivers, setReceivers] = useState<IAttendee[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _item: typeof sendingInformationItem = undefined;

		if (informations?.linkedType && informations.linkedItemId) {
			switch (informations.linkedType) {
				case 'schedule':
					_item = content.schedules.find((e) => e.id === informations?.linkedItemId);
					break;
				default:
					break;
			}
		}

		setSendingInformationItem(_item);
	}, [content, informations]);

	useEffect(() => {
		let _receviers: typeof receivers = [];

		if (informations?.sentTo && attendees) {
			informations.sentTo.forEach((userId) => {
				const attendee = attendees.find((e) => e.userId === userId);
				if (attendee) {
					_receviers.push(attendee);
				}
			});
		}

		setReceivers(_receviers);
	}, [attendees, informations]);

	const _renderSendingInformationItem = () => {
		if (sendingInformationItem) {
			switch (informations?.linkedType) {
				case 'schedule':
					return (
						<View>
							<Text style={{ fontSize: 12, marginBottom: 10 }}>{t('SendingInformationTextMeeting')}</Text>
							<ScheduleReferenceItem
								testID={`referenceitem`}
								item={sendingInformationItem}
								onPress={() =>
									navigation.navigate(ERoutes.Media, {
										itemId: sendingInformationItem.id,
										mediaType: 'schedule',
										spaceId: activeSpace?.spaceId
									})
								}
							/>
							{receivers.length > 0 && (
								<FlatList
									data={receivers}
									ListHeaderComponent={<H3>{t('Receivers')}</H3>}
									renderItem={({ item, index }) => (
										<AttendeeListItem
											testID={`${TESTIDPREFIX}_attendee_${index}`}
											item={item}
											onPress={() =>
												navigation.navigate(ERoutes.AttendeeDetails, {
													spaceId: activeSpace?.spaceId,
													userId: item.userId
												})
											}
										/>
									)}
								/>
							)}
						</View>
					);
				default:
					return null;
			}
		}

		return null;
	};

	if (informations) {
		return (
			<View style={{ marginTop: 20, paddingTop: 20, borderTopWidth: 1, borderTopColor: theme.lightgray }}>
				<H2>{t('SendingInformations')}</H2>
				{_renderSendingInformationItem()}
			</View>
		);
	}

	return null;
};
