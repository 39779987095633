import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderIconButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { ICreateNewsFormErrors, ICreateNewsFormValues } from 'config/interfaces';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { CreateNewsForm } from 'components/Forms/News';
import { EDefaultIconSet, IS_ANDROID, showFormErrorToast, validateForm } from 'helper';
import { getCreateNewsFormSchema } from 'config/yupSchemas/news';
import { BackHandler, Keyboard, ScrollView } from 'react-native';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';

import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import { NewsPreview } from 'components/News/NewsPreview';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NewsCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NewsCreate>;
type RouteParams = StackParamList[ERoutes.NewsCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const NewsCreateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formErrors, setFormErrors] = useState<ICreateNewsFormErrors>({});
	const [newNews, setNewNews] = useState<ICreateNewsFormValues>({});
	const [isNewsLoading, setIsNewsLoading] = useState<boolean>(false);

	const createNews = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createNews);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createNews(),
			isLoading: isNewsLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [activeSpace, newNews, isNewsLoading, formErrors]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [newNews]);

	useEffect(() => {
		const backAction = () => {
			if (!IS_ANDROID) {
				return;
			}
			if (!route?.params || !route?.params?.prohibitNavigation) {
				navigation.goBack();
			} else {
				showAlert({
					title: t('unsavedChanges'),
					message: t('unsavedChangesSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('leaveWithoutSaving'),
							style: 'destructive',
							onPress: async () => {
								navigation.goBack();
							}
						}
					]
				});
			}
			return true;
		};
		const backHandler = BackHandler.addEventListener('hardwareBackPress', () => backAction());

		return () => backHandler.remove();
	}, [route?.params]);

	const _createNews = async () => {
		const errors = await validateForm(getCreateNewsFormSchema(newNews), newNews);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		setIsNewsLoading(true);
		const res = await createNews({ news: newNews });
		if (res) {
			setIsNewsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsNewsLoading(false);
		}
	};

	const updateFormValues = (val) => {
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setNewNews(val);
	};

	return (
		<ScreenContainer
			isProtectedRoute={
				!(activeSpace?.allowUserGeneratedContentInFeed === 'always' || activeSpace?.allowUserGeneratedContentInFeed === 'moderated')
			}
			contentKey="allreferences"
		>
			<ScrollView
				testID={'createnews_scrollview'}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<CreateNewsForm
					values={newNews}
					onChange={updateFormValues}
					errors={formErrors}
					isLoading={isNewsLoading}
					onError={(newErrors) => {
						const _errors = { ...newErrors };
						Object.keys(_errors).forEach((key) => {
							if (!_errors[key]) {
								delete _errors[key];
							}
						});
						setFormErrors(_errors);
					}}
				/>
				<NewsPreview news={newNews} />
			</ScrollView>
			<LoadingModal isLoading={isNewsLoading} />
		</ScreenContainer>
	);
};

export const NewsCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('News')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
