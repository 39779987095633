import { ChildButton } from 'components/Button/ChildButton';
import { RoundButton } from 'components/Button/RoundButton';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { ListItemMatchCount } from 'components/Matching';
import { Avatar } from 'components/User/Avatar';
import { IAttendee, IExpo, IHSPXTicket, ISchedule, ISpeaker } from 'config/interfaces';
import { hsBorderRadius, hsBottomMargin, hsTextBottomMarginSmall } from 'config/styleConstants';
import React, { ReactNode, useEffect, useState } from 'react';
import { TextStyle, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSCard } from './HSCard';
import { Image } from 'components/Image';
import { H2 } from 'components/Text';
import {
	concatJobtitleAndCompany,
	EDefaultIconSet,
	getFixedHeightForText,
	getDefaultAspectRatioStyle,
	isEmptyString,
	getAgendaSpeakerString
} from 'helper';
import { Text } from 'components/Text';
import { ERoutes } from 'components/Navigation/routes';
import { t } from 'i18next';
import { IUserInSpaceId } from 'rematch/interfaces';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'components/Icon';
import moment from 'moment';
import { useContent } from 'hooks/useContent';

interface BaseProps {
	testID: string;
	isSelected?: boolean;
	isLoading?: boolean;
	hideInfos?: boolean;
	index?: number;
	onPress?: () => void;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
}

interface IAttendeeProps extends BaseProps {
	item: IAttendee;
	contentType: 'attendee';
}

interface IExpoProps extends BaseProps {
	item: IExpo;
	contentType: 'expo';
}

interface ISpeakerProps extends BaseProps {
	item: ISpeaker;
	contentType: 'speaker';
}

interface IScheduleProps extends BaseProps {
	item: ISchedule;
	contentType: 'schedule';
}

type IProps = IAttendeeProps | IExpoProps | ISpeakerProps | IScheduleProps;

export const CONTENTCARD_HEIGHT = 200;

enum EStyleConstants {
	FontSize_Title = 16,
	FontSize_JobTitleCompany = 12,
	FontSize_Info = 12,
	FontSize_BottomRow = 12,
	NumberOfLines_Title = 1,
	NumberOfLines_Title_Expo = 2,
	NumberOfLines_JobtitleCompany = 2,
	NumberOfLines_Info = 2,
	NumberOfLines_BottomRow = 2,
	ImageWidth = 80,
	ImageWidth_Edit = 60,
	TopMargin_Title = hsTextBottomMarginSmall,
	Height_AdditionalInfoSchedule = 40
}

export const ContentCard = (props: IProps) => {
	const { testID, item, contentType, isSelected, isLoading, onPress, onSelect, onEdit, onDelete, hideInfos, index } = props;
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { getContentTypeFields } = useContent(contentType);

	const [ticket, setTicket] = useState<IHSPXTicket | undefined>(undefined);
	const [userInSpaceId, setUserInSpaceId] = useState<IUserInSpaceId | undefined>(undefined);
	const [isPinLoading, setIsPinLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const tickets = useSelector((store: IRootState) => store.ticket.tickets);

	const updateExpo = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateExpo);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (contentType === 'attendee' && userInSpaces && activeSpace && (iAmSpaceAdmin || iAmSpaceModerator)) {
			const e = userInSpaces.find((e) => e?.spaceId === activeSpace.spaceId && e?.userId === item.userId);
			setUserInSpaceId(e);
		}
	}, [activeSpace, item, iAmSpaceAdmin, iAmSpaceModerator, userInSpaces]);

	useEffect(() => {
		let _ticket: typeof ticket = undefined;

		if (userInSpaceId && tickets) {
			_ticket = tickets.find((e) => userInSpaceId.ticketProvider === 'hellospaces' && e.ticketcode === userInSpaceId.ticketcode);
		}

		setTicket(_ticket);
	}, [userInSpaceId, tickets]);

	const _getTitle = () => {
		let line1 = ' ';
		let line2 = ' ';

		switch (contentType) {
			case 'attendee':
				line1 = item.firstName;
				line2 = item.lastName;
				break;
			case 'expo':
				line1 = item.title;
				break;
			case 'speaker':
				const _titleParts = (isEmptyString(item.speakerTitle) ? item.title : `${item.speakerTitle} ${item.title}`).split(' ');
				const half = Math.ceil(_titleParts.length / 2);

				line1 = _titleParts.slice(0, half).join(' ');
				line2 = _titleParts.slice(half).join(' ');
				break;
			case 'schedule':
				line1 = item.title;
				break;
			default:
				break;
		}

		return { line1, line2 };
	};

	const _updatePinned = async () => {
		setIsPinLoading(true);
		const _isPinned = item.isPinned;
		showAlert({
			title: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
			message: _isPinned ? t('removePinAnswerSubtitle') : t('pinAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsPinLoading(true);
						await updateExpo({ expo: { id: item?.id, isPinned: !_isPinned }, fields: getContentTypeFields(), noToast: true });
						setIsPinLoading(false);
					}
				}
			]
		});

		setIsPinLoading(false);
	};

	const _renderSelection = () => {
		if (onSelect) {
			return (
				<View style={{ position: 'absolute', left: 0, top: 0 }}>
					<FormCheckbox
						testID={`${testID}_${contentType}_${index}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderMatchCount = () => {
		switch (contentType) {
			case 'attendee':
				if (item.userId === profile?.userId) return null;
				break;
			case 'expo':
				break;
			case 'speaker':
				break;
			default:
				break;
		}

		return <ListItemMatchCount item={item} contentType={contentType} />;
	};

	const _renderFavoriteButton = () => {
		let id;
		switch (contentType) {
			case 'attendee':
				if (item.userId === profile?.userId) return null;
				id = item.userId;
				break;
			case 'expo':
				id = item.id;
				break;
			case 'speaker':
				return null;
			case 'schedule':
				id = item.id;
				break;
			default:
				break;
		}

		return (
			<View style={{ marginLeft: 5 }}>
				<FavoriteButton testID={`${testID}_button_${contentType}_${index}_favorite`} id={id} type={contentType} />
			</View>
		);
	};

	const _renderLeftAbsolutePositionedView = () => {
		const elements: JSX.Element[] = [];

		if (contentType !== 'attendee' && item.isHidden) {
			elements.push(
				<Icon key={`${testID}_${contentType}_${index}_hidden`} name={EDefaultIconSet.AnswerWaiting} color={theme.warning} />
			);
		}

		if (contentType === 'expo' && item.isPinned) {
			elements.push(
				<Icon
					key={`${testID}_${contentType}_${index}_pin`}
					name={EDefaultIconSet.Pin}
					containerStyle={elements.length > 0 ? { marginLeft: 5 } : undefined}
				/>
			);
		}

		if (elements.length > 0) {
			return (
				<View
					style={{
						position: 'absolute',
						left: -10,
						top: -10,
						zIndex: 5,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end'
					}}
				>
					{elements}
				</View>
			);
		}

		return null;
	};

	const _renderRightAbsolutePositionedView = () => {
		return (
			<View
				style={{
					position: 'absolute',
					right: -10,
					top: -10,
					zIndex: 5,
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end'
				}}
			>
				{_renderMatchCount()}
				{_renderFavoriteButton()}
			</View>
		);
	};

	const _renderImage = () => {
		switch (contentType) {
			case 'attendee':
			case 'speaker':
				const name = contentType === 'attendee' ? `${item.firstName} ${item.lastName}` : item.title;
				const avatar = contentType === 'attendee' ? item.smallImageUrl ?? item.imageUrl : undefined;
				const avatarObj = contentType === 'attendee' ? undefined : item.image;
				const userId = contentType === 'attendee' ? item.userId : item.attendee?.userId;

				return <Avatar size="listItem" avatar={avatar} avatarObj={avatarObj} fullName={name} userId={userId} />;
			case 'expo':
				return (
					<Image
						style={{
							...getDefaultAspectRatioStyle(onSelect ? EStyleConstants.ImageWidth_Edit : EStyleConstants.ImageWidth),
							borderRadius: hsBorderRadius
						}}
						mediaObj={item.logo}
						imageSize="small"
						expectedRatio={16 / 9}
						resizeMode={'contain'}
					/>
				);
			case 'schedule':
				return (
					<Image
						style={{
							...getDefaultAspectRatioStyle(onSelect ? EStyleConstants.ImageWidth_Edit : EStyleConstants.ImageWidth),
							borderRadius: hsBorderRadius
						}}
						mediaObj={item.previewImage}
						imageSize="small"
						expectedRatio={16 / 9}
						resizeMode={'contain'}
					/>
				);
			default:
				return null;
		}
	};

	const _renderTitleLine = (title: string, numberOfLines: number, style?: TextStyle) => {
		return (
			<H2
				center
				numberOfLines={numberOfLines}
				style={[
					{
						height: getFixedHeightForText(EStyleConstants.FontSize_Title, numberOfLines),
						lineHeight: getFixedHeightForText(EStyleConstants.FontSize_Title, numberOfLines) / numberOfLines,
						fontSize: EStyleConstants.FontSize_Title
					},
					style
				]}
			>
				{title}
			</H2>
		);
	};

	const _renderTitle = () => {
		const { line1, line2 } = _getTitle();

		switch (contentType) {
			case 'expo':
				return _renderTitleLine(line1, EStyleConstants.NumberOfLines_Title_Expo, { marginTop: EStyleConstants.TopMargin_Title });
			case 'attendee':
			case 'speaker':
			case 'schedule':
				return (
					<>
						{_renderTitleLine(line1, 1, { marginTop: EStyleConstants.TopMargin_Title, marginBottom: 0 })}
						{_renderTitleLine(line2, 1)}
					</>
				);
			default:
				return null;
		}
	};

	const _renderInfo = () => {
		if (hideInfos) {
			return null;
		}

		let info: string | undefined = undefined;

		switch (contentType) {
			case 'attendee':
				info = item.aboutMe;
				break;
			case 'expo':
				info = item.catchphrase;
				break;
			case 'schedule':
				info = item.subtitle;
				break;
			case 'speaker':
			default:
				return null;
		}

		return (
			<Text
				style={{
					fontSize: EStyleConstants.FontSize_Info,
					height: getFixedHeightForText(EStyleConstants.FontSize_Info, EStyleConstants.NumberOfLines_Info),
					lineHeight:
						getFixedHeightForText(EStyleConstants.FontSize_Info, EStyleConstants.NumberOfLines_Info) /
						EStyleConstants.NumberOfLines_Info
				}}
				numberOfLines={EStyleConstants.NumberOfLines_Info}
				center
			>
				{info}
			</Text>
		);
	};

	const _renderJobtitle = () => {
		switch (contentType) {
			case 'attendee':
			case 'speaker':
				return (
					<Text
						style={{
							fontSize: EStyleConstants.FontSize_JobTitleCompany,
							height: getFixedHeightForText(
								EStyleConstants.FontSize_JobTitleCompany,
								EStyleConstants.NumberOfLines_JobtitleCompany
							),
							lineHeight:
								getFixedHeightForText(
									EStyleConstants.FontSize_JobTitleCompany,
									EStyleConstants.NumberOfLines_JobtitleCompany
								) / EStyleConstants.NumberOfLines_JobtitleCompany
						}}
						numberOfLines={EStyleConstants.NumberOfLines_JobtitleCompany}
						center
						adjustsFontSizeToFit={false}
					>
						{concatJobtitleAndCompany(item.jobTitle, item.company)}
					</Text>
				);
			case 'schedule':
				const foundStage = content.stages.find((s) => s.id === item.stage?.id && !s.isDeleted);
				if (!foundStage) {
					return null;
				}
				return (
					<Text
						style={{
							fontSize: EStyleConstants.FontSize_JobTitleCompany,
							height: getFixedHeightForText(
								EStyleConstants.FontSize_JobTitleCompany,
								EStyleConstants.NumberOfLines_JobtitleCompany
							),
							lineHeight:
								getFixedHeightForText(
									EStyleConstants.FontSize_JobTitleCompany,
									EStyleConstants.NumberOfLines_JobtitleCompany
								) / EStyleConstants.NumberOfLines_JobtitleCompany
						}}
						numberOfLines={EStyleConstants.NumberOfLines_JobtitleCompany}
						center
						adjustsFontSizeToFit={false}
					>
						{foundStage.title}
					</Text>
				);
			case 'expo':
			default:
				return null;
		}
	};

	const _renderActions = () => {
		switch (contentType) {
			case 'attendee':
				return null;
			case 'expo':
			case 'speaker':
				if (onEdit || onDelete) {
					return (
						<View style={{ flexDirection: 'row', alignItems: 'flex-end', paddingLeft: 10 }}>
							{onEdit && (
								<RoundButton
									testID={`${testID}_button_${contentType}_${index}_edit`}
									icon={EDefaultIconSet.Edit}
									size="sm"
									onPress={onEdit}
									isOutline
									isLoading={isLoading}
								/>
							)}
							{onDelete && (
								<RoundButton
									testID={`${testID}_button_${contentType}_${index}_delete`}
									icon={EDefaultIconSet.Delete}
									color={theme.danger}
									size="sm"
									onPress={onDelete}
									isOutline
									isLoading={isLoading}
								/>
							)}
							{contentType === 'expo' ? (
								<RoundButton
									testID={`${testID}_button_${contentType}_${index}_pin`}
									onPress={() => _updatePinned()}
									isLoading={isPinLoading}
									isOutline={!item.isPinned}
									icon={EDefaultIconSet.Pin}
									size="sm"
								/>
							) : null}
						</View>
					);
				}
			default:
				return null;
		}
	};

	const _renderSponsorInfo = () => {
		switch (contentType) {
			case 'expo':
				if (onEdit && item.sponsorType) {
					return (
						<View
							style={{
								marginLeft: 10,
								backgroundColor: theme.primary,
								paddingHorizontal: 10,
								paddingVertical: 5,
								borderRadius: 999,
								height: 25
							}}
						>
							<Text center style={{ color: theme.primaryContrast, fontSize: 12 }} numberOfLines={1}>
								{t(item.sponsorType)}
							</Text>
						</View>
					);
				}
			case 'attendee':
			case 'speaker':
			default:
				return null;
		}
	};

	const _renderBottomRow = () => {
		switch (contentType) {
			case 'attendee':
				return null;
			case 'schedule':
				return (
					<Text
						style={{
							fontSize: EStyleConstants.FontSize_BottomRow,
							height: getFixedHeightForText(EStyleConstants.FontSize_BottomRow, EStyleConstants.NumberOfLines_BottomRow),
							lineHeight:
								getFixedHeightForText(EStyleConstants.FontSize_BottomRow, EStyleConstants.NumberOfLines_BottomRow) /
								EStyleConstants.NumberOfLines_BottomRow
						}}
						numberOfLines={EStyleConstants.NumberOfLines_BottomRow}
						center
						adjustsFontSizeToFit={false}
					>{`${moment(item.startDate).format('HH:mm')} - ${moment(item.endDate).format('HH:mm')}`}</Text>
				);
			case 'expo':
			case 'speaker':
				return (
					<View
						style={{
							marginTop: 10,
							alignSelf: 'flex-end',
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'flex-end'
						}}
					>
						{_renderActions()}
						{_renderSponsorInfo()}
					</View>
				);
			default:
				return null;
		}
	};

	const _renderPin = () => {
		if (contentType === 'expo' && item.isPinned) {
			return (
				<View style={{ marginLeft: 6 }}>
					<Icon name={EDefaultIconSet.Pin} />
				</View>
			);
		}

		return null;
	};

	const _renderAdditionalInformation = () => {
		const elements: ReactNode[] = [];
		let style = {};

		switch (contentType) {
			case 'attendee':
				if (onSelect && (iAmSpaceAdmin || iAmSpaceModerator)) {
					if (userInSpaceId?.ticketcode) {
						elements.push(
							<ChildButton
								key={`${testID}_button_${contentType}_${index}_ticket`}
								testID={`${testID}_button_${contentType}_${index}_ticket`}
								onPress={() => navigation.navigate(ERoutes.TicketEdit, { spaceId: activeSpace?.spaceId, id: ticket?.id })}
								style={{ marginTop: 5 }}
								isDisabled={!ticket}
							>
								<Text style={{ fontSize: 9 }}>{`Ticketcode: ${userInSpaceId.ticketcode}`}</Text>
							</ChildButton>
						);
					}

					if (item?.email) {
						elements.push(
							<Text
								key={`${testID}_text_${contentType}_${index}_mail`}
								style={{
									marginTop: 5,
									fontSize: 9,
									height: getFixedHeightForText(9, 1),
									lineHeight: getFixedHeightForText(9, 1)
								}}
								numberOfLines={1}
							>{`${t('email')}: ${item?.email}`}</Text>
						);
					}
				}
				break;
			case 'schedule':
				style = { height: EStyleConstants.Height_AdditionalInfoSchedule };
				if (item?.speakers && item?.speakers.length > 0) {
					let speakers = item.speakers;
					// const elements: JSX.Element[] = [];
					const unhandledSpeakers: ISpeaker[] = [];

					if ((item.speakerGroups?.groups ?? []).length > 0) {
						item.speakerGroups?.groups.forEach((group) => {
							const _groupSpeakers = speakers.filter((e) => group.speakerIds.includes(e.id));
							const _speakerStrings = _groupSpeakers.map((e) => getAgendaSpeakerString(e));

							const textStyle = { fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text };

							elements.push(
								<Text key={`speakerstring_${group.key}`} bold style={textStyle}>
									{`${group.title}: `}
									<Text style={textStyle}>{_speakerStrings.join(', ')}</Text>
								</Text>
							);
						});
					}

					speakers.forEach((speaker) => {
						const foundInGroup = item.speakerGroups?.groups.find((e) => e.speakerIds.includes(speaker.id));
						if (!foundInGroup) {
							unhandledSpeakers.push(speaker);
						}
					});

					if (unhandledSpeakers.length > 0) {
						const _speakerStrings = unhandledSpeakers.map((e) => getAgendaSpeakerString(e));
						elements.push(
							<Text
								key={`speakerstring_${item.id}`}
								style={{ fontSize: 10, color: !isEmptyString(item.textColor) ? item.textColor : theme.text }}
							>
								{_speakerStrings.join(', ')}
							</Text>
						);
					}

					// return <View style={{ marginTop: 10 }}>{elements}</View>;
				}

			// return null;
			case 'expo':
			case 'speaker':
				break;
			default:
				break;
		}

		// if (elements.length > 0) {
		return <View style={{ overflow: 'hidden', ...style }}>{elements}</View>;
		// }

		return null;
	};

	return (
		<ChildButton testID={`${testID}_button_${contentType}_${index}`} style={{ flex: 1, alignItems: 'center' }} onPress={onPress}>
			<HSCard style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: hsBottomMargin / 2 }}>
				<View style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					{_renderLeftAbsolutePositionedView()}
					{_renderRightAbsolutePositionedView()}
					{_renderSelection()}
					{_renderImage()}
					<View style={{ width: '100%' }}>
						{_renderTitle()}
						{_renderInfo()}
						{_renderJobtitle()}
						{_renderBottomRow()}
						{_renderAdditionalInformation()}
					</View>
				</View>
			</HSCard>
		</ChildButton>
	);
};
