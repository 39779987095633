import React, { useEffect, useRef, useState } from 'react';
import { BarCodeEvent, BarCodeScanner, PermissionStatus } from 'expo-barcode-scanner';
import { View, ViewStyle } from 'react-native';
import { Spinner } from 'components/Spinner';
import { useTranslation } from 'react-i18next';
import { MissingPermission } from 'components/Info';

interface IQRCodeScannerComponent {
	onCodeScanned: (val: string) => void;
	style: ViewStyle;
}

export const QRCodeScanner = (props: IQRCodeScannerComponent) => {
	const { onCodeScanned, style } = props;
	const { t } = useTranslation();
	const scannerRef = useRef<BarCodeScanner | null>(null);
	const cameraRef = useRef<View | null>(null);

	const [hasPermission, setHasPermission] = useState<boolean | null>(null);
	const [lastResult, setLastResult] = useState<string | undefined>(undefined);

	useEffect(() => {
		_requestPermissions();

		return () => {
			scannerRef.current = null;
			cameraRef.current = null;
		};
	}, []);

	const _requestPermissions = async () => {
		const { status } = await BarCodeScanner.requestPermissionsAsync();
		setHasPermission(status === PermissionStatus.GRANTED);
	};

	if (hasPermission === false) {
		return <MissingPermission type="camera" />;
	}

	const _renderCamera = () => {
		if (hasPermission === null) {
			return <Spinner />;
		}

		return <BarCodeScanner ref={scannerRef} onBarCodeScanned={_handleBarCodeScanned} style={{ height: '100%', width: '100%' }} />;
	};

	const _handleBarCodeScanned = (res: BarCodeEvent) => {
		if (res.data !== lastResult) {
			setLastResult(res.data);
			onCodeScanned(res.data);
		}
	};

	return (
		<View ref={cameraRef} style={{ ...style, justifyContent: 'center', alignItems: 'center' }}>
			{_renderCamera()}
		</View>
	);
};
