import React, { useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { TMedia } from 'config/interfaces';
import { FormMediaPicker } from 'components/Form/Image';
import * as ImagePicker from 'expo-image-picker';
import { StreamPlayer } from 'components/Stream';
import { getDefaultAspectRatioStyle, IS_ANDROID, IS_WEB } from 'helper';
import * as mime from 'react-native-mime-types';

interface IStreamInputUpload {
	testID: string;
	onChange: (value: TMedia) => void;
	value?: TMedia;
	mediaTypes?: ImagePicker.MediaTypeOptions | 'files';
}

export const StreamInputUpload = (props: IStreamInputUpload) => {
	const { testID, onChange, value, mediaTypes } = props;
	const { t } = useTranslation();

	const [containerWidth, setContainerWidth] = useState<number>(1);

	const getType = () => {
		const url = typeof value === 'object' ? value?.url : value;
		if (typeof url === 'string' && url.includes('mediadelivery')) {
			return 'iFrame';
		}
		return 'local';
	};

	const _renderPlayPreview = () => {
		if (value) {
			let mimeType: string | undefined = undefined;

			if (typeof value === 'string') {
				if (IS_WEB) {
					const splitted = value.split(';');
					mimeType = splitted[0]?.split(':')[1];
				} else {
					const match = /\.(\w+)$/.exec(value);
					if (match && match[0]) {
						mimeType = mime.lookup(match[0]);
					}
				}
			}

			if (mimeType?.includes('video')) {
				return (
					<StreamPlayer
						type={getType()}
						url={typeof value === 'object' ? value?.url : value}
						containerStyle={getDefaultAspectRatioStyle(containerWidth)}
						playerContainerStyle={IS_ANDROID ? { flex: 1 } : {}}
					/>
				);
			}
		}

		return null;
	};

	return (
		<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
			<FormMediaPicker
				testID={`${testID}_mediapicker_media`}
				label={t('Media')}
				isRequired
				mediaTypes={mediaTypes ?? ImagePicker.MediaTypeOptions.Videos}
				onChange={(val) => onChange(val)}
				value={value}
				useModal
				preventVideoAutoplay
			/>

			{_renderPlayPreview()}
		</View>
	);
};
