import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoundButton } from 'components/Button';
import { HSModal } from '../Modal';
import { ScrollView, Vibration, View } from 'react-native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { IVoteOption, TVotingType } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, swapArrayItems, validateForm } from 'helper';
import { FormMultiSwitch, FormTextInput } from 'components/Form';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import { IS_ANDROID } from 'helper/platform';
import { getSurveyOptionFormSchema } from 'config/yupSchemas';
import { HSDragCard } from 'components/Card';
import { FormError } from 'components/Form/FormError';
import { FormLabel } from 'components/Form/FormLabel';
import { VoteIcon } from 'components/Icon';
import { IVoteOptionVoteSurvey } from 'components/Forms/Vote/VoteSurvey';
import { hsBottomMargin } from 'config/styleConstants';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface IVoteSurveyVoteOptionModal {
	testID: string;
	isVisible: boolean;
	onChange: (value: IVoteOptionVoteSurvey) => void;
	onClose: () => void;
	onSubmit: () => void;
	values: IVoteOptionVoteSurvey;
}
const TESTIDPREFIX = 'votesurveymodal';

export const VoteSurveyVoteOptionModal = (props: IVoteSurveyVoteOptionModal) => {
	const { testID, isVisible, onChange, onClose, values, onSubmit } = props;
	const { t } = useTranslation();

	const [errors, setErrors] = useState<Record<string, string>>({});

	const _renderButton = (voteType: TVotingType) => {
		return (
			<View style={{ width: '33%' }}>
				<VoteIcon
					voteType={voteType}
					testID={TESTIDPREFIX}
					onPress={() => {
						onChange({ ...values, type: voteType });
					}}
					alignSelf="center"
					isActive={voteType === values?.type}
				/>
			</View>
		);
	};

	const _addOption = () => {
		let _options = values?.options ?? [];
		_options.push({
			description: '', // is used as answer for the question here
			order: values?.options?.length ?? 0
		});
		onChange({ ...values, options: _options });
	};

	const _removeOption = (index) => {
		const _options = values.options;
		_options?.splice(index, 1);
		onChange({ ...values, options: _options });
	};

	const _submit = async () => {
		if (values.type !== 'multipleChoice') values.options = [];
		const errors = await validateForm(getSurveyOptionFormSchema(values), values);
		if (errors) {
			setErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setErrors({});
		}

		if (onSubmit) {
			onSubmit();
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		const list: IVoteOption[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);

		onChange({ ...values, options: list });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ ...values, options: list });
	};

	const _renderItem = (params: RenderItemParams<IVoteOption>) => {
		const { item, index, drag, isActive } = params;
		switch (values?.type) {
			case 'multipleChoice':
				return (
					<HSDragCard
						testID={`${TESTIDPREFIX}_option_${index}`}
						isActive={isActive}
						onDrag={drag}
						index={index}
						handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
						onRemove={() => _removeOption(index)}
						onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
						onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
					>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_multiplechoicedescription_${index}`}
							placeholder={`${t('Answer')}*`}
							isRequired
							formStyle={{ marginBottom: 10 }}
							error={errors[`options[${index}].description`]}
							value={item.description}
							onChangeText={(value) => {
								if (values.options && index !== undefined) {
									let _options = values.options;
									_options[index].description = value;
									onChange({ ...values, options: _options });
								}
							}}
						/>
					</HSDragCard>
				);
			case 'text':
			case 'rating':
			case 'scale':
			case 'wordCloud':
			case 'applause':
			default:
				return null;
		}
	};

	const _isSubmitDisabled = () => {
		switch (values?.type) {
			case 'multipleChoice':
				return (
					!values?.question ||
					!values?.options ||
					values?.options?.length < 2 ||
					values?.options?.filter((e) => isEmptyString(e.description))?.length > 0
				);
			case 'nps':
			case 'scale':
				return (
					!values?.question ||
					(!values?.minValue && values.minValue !== 0) ||
					!values?.maxValue ||
					values?.minValue > values?.maxValue
				);
			case 'rating':
				return !values?.question || !values?.maxValue || !values.ratingType;
			case 'wordCloud':
				return (
					!values?.question ||
					(!values?.minValue && values?.minValue !== 0) ||
					!values?.maxValue ||
					values?.minValue > values?.maxValue
				);
			case 'text':
			case 'applause':
			default:
				return !values?.question;
		}
	};

	const _renderFooter = () => {
		switch (values?.type) {
			case 'text':
			case 'applause':
				return null;
			case 'wordCloud':
				return (
					<View>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_minvalue`}
							label={t('VoteFormWordCloudMinValue')}
							hint={`${t('VoteFormWordCloudMinValueHint')} (min: 1)`}
							isRequired
							value={values.minValue?.toString()}
							error={errors.minValue}
							onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
							keyboardType="number-pad"
							onlyNumbers
						/>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_maxvalue`}
							label={t('VoteFormWordCloudMaxValue')}
							hint={`${t('VoteFormWordCloudMaxValueHint')} (max: 100)`}
							isRequired
							value={values.maxValue?.toString()}
							error={errors.maxValue}
							onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
							keyboardType="number-pad"
							onlyNumbers
						/>
					</View>
				);
			case 'scale':
			case 'nps':
				return (
					<View>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_minvalue`}
							label={t('VoteFormScaleMinValue')}
							hint={`${t('VoteFormScaleMinValueHint')} (min: 0)`}
							isRequired
							value={values.minValue?.toString()}
							error={errors.minValue}
							onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
							keyboardType="number-pad"
							onlyNumbers
						/>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_maxvalue`}
							label={t('VoteFormScaleMaxValue')}
							hint={`${t('VoteFormScaleMaxValueHint')} (max: 1000)`}
							isRequired
							value={values.maxValue?.toString()}
							error={errors.maxValue}
							onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
							keyboardType="number-pad"
							onlyNumbers
						/>
					</View>
				);
			case 'rating':
				if (values.maxValue === undefined) values.maxValue = 5;
				return (
					<View>
						<FormMultiSwitch
							testID={`${TESTIDPREFIX}_multiswitch_ratingviewtype`}
							label={t('Rating View Type')}
							hint={t('Rating View Type Hint')}
							error={errors.ratingType}
							value={values.ratingType}
							options={[
								{ key: 'star', label: t('Star') },
								{ key: 'thumbs', label: t('Thumbs') }
							]}
							onChange={(val) => onChange({ ...values, ratingType: val })}
							formStyle={{ marginBottom: hsBottomMargin }}
						/>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_maxvalue`}
							label={t('maxValRating')}
							hint={`${t('MaxValue Hint')} (min: 2, max: 10)`}
							value={values.maxValue?.toString()}
							error={errors.maxValue}
							onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
							keyboardType="number-pad"
							onlyNumbers
						/>
					</View>
				);
			case 'multipleChoice':
				return (
					<DraggableFlatList
						scrollEnabled={false}
						testID={`${TESTIDPREFIX}_options_scrollview`}
						ListFooterComponent={
							<>
								<RoundButton
									testID={`${TESTIDPREFIX}_button_add`}
									icon={EDefaultIconSet.Add}
									onPress={() => _addOption()}
									isDisabled={(values?.options?.length ?? 0) > 100}
									alignSelf="flex-end"
									title={t('addAnswerQuiz')}
								/>
								<FormError testID={`${TESTIDPREFIX}_createMultipleChoiceOption`} error={errors.options} />
							</>
						}
						data={values?.options ?? []}
						renderItem={(params) => _renderItem(params)}
						keyExtractor={(item, index) => `${TESTIDPREFIX}_createMultipleChoiceOption_${index}`}
						onDragBegin={() => {
							if (IS_ANDROID) {
								Vibration.vibrate(10, false);
							}
						}}
						onDragEnd={({ data }) => {
							data.forEach((opt, index) => (opt.order = index));
							onChange({ ...values, options: data });
						}}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose} onSubmit={() => _submit()} isSubmitDisabled={_isSubmitDisabled()}>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				style={{ flex: 1, marginHorizontal: -EHorizontalScreenPadding.Wide }}
				contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
			>
				<View>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_question`}
						label={t('Question')}
						hint={t('questionHint')}
						isRequired
						value={values?.question}
						error={errors.question}
						onChangeText={(value) => onChange({ ...values, question: value })}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_hint`}
						label={t('Hint')}
						hint={t('VoteHint')}
						value={values?.hint}
						error={errors.hint}
						onChangeText={(value) => onChange({ ...values, hint: value })}
					/>
					<FormLabel
						style={{ marginBottom: FormElementBottomMarginSmall }}
						testID={`${TESTIDPREFIX}_list_answeroptions`}
						label={t('questionTypeSurvey')}
					/>
					<View
						style={{
							flexDirection: 'row',
							marginBottom: hsBottomMargin / 2
						}}
					>
						{_renderButton('text')}
						{_renderButton('rating')}
						{_renderButton('scale')}
					</View>
					<View
						style={{
							flexDirection: 'row',
							marginBottom: hsBottomMargin
						}}
					>
						{_renderButton('wordCloud')}
						{_renderButton('multipleChoice')}
						{_renderButton('nps')}
					</View>
					{values?.type === 'multipleChoice' && (
						<FormLabel testID={`${TESTIDPREFIX}_list_answeroptions`} label={t('answerOptionsQuiz')} />
					)}
				</View>
				{_renderFooter()}
			</ScrollView>
		</HSModal>
	);
};
