import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { ISchedule, IScheduleStatus, IVote } from 'config/interfaces';
import { FormCheckbox, FormMultiSwitch } from 'components/Form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { Spinner } from 'components/Spinner';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { FormHint } from 'components/Form/FormHint';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';

export const SCHEDULELISTITEMENTRYHEIGHT = 155;

export interface IScheduleListItem {
	testID: string;
	item: ISchedule;
	onPress?: () => void;
	onEditPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	onStatusChange?: (status: IScheduleStatus['status'], statusId: number, scheduleId: number) => void;
	isLoading?: boolean;
	isStatusLoading?: number;
}

export const ScheduleListItem = (props: IScheduleListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, onStatusChange, isLoading, isStatusLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const navigation = useNavigation();
	const { activeSpace } = useSpace();

	const [scheduleStatus, setScheduleStatus] = useState<IScheduleStatus | undefined>(undefined);

	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (content.schedulestatuses) {
			const _status = content.schedulestatuses.find((e) => item && e.schedule?.id === item.id);
			setScheduleStatus(_status);
		}
	}, [item, content]);

	const _renderDate = () => {
		let str = '';

		if (!isEmptyString(item.startDate) && !isEmptyString(item.endDate)) {
			str = `${moment(item.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(item.endDate).format('HH:mm')}`;
		} else if (!isEmptyString(item.startDate)) {
			str = `${t('Start')}: ${moment(item.startDate).format('DD.MM.YYYY HH:mm')}`;
		} else if (!isEmptyString(item.endDate)) {
			str = `${t('End')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
		}

		if (!isEmptyString(str)) {
			return <Text style={{ fontSize: 12 }}>{str}</Text>;
		}

		return null;
	};

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isLoading}
						/>
					)}
				</View>
			);
		}

		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	const _renderLivestatus = () => {
		if (isStatusLoading === item.id) {
			return (
				<View style={{ alignItems: 'flex-end' }}>
					<Spinner />
				</View>
			);
		}
		if (onStatusChange && scheduleStatus) {
			return (
				<View style={{ alignItems: 'flex-end' }}>
					<FormMultiSwitch
						formStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
						testID={`${testID}_multiswitch_status`}
						onChange={(newValue) => {
							if (newValue && newValue !== scheduleStatus?.status) {
								showAlert({
									title: t('UpdateStatusTitle'),
									message: t('UpdateStatusSubtitle').replace('%STATUS%', newValue),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('UpdateStatusTitle'),
											onPress: () => {
												onStatusChange(newValue as IScheduleStatus['status'], scheduleStatus.id, item.id);
											}
										}
									]
								});
							}
						}}
						value={scheduleStatus?.status}
						options={[
							{
								key: 'Upcoming',
								label: t('Upcoming')
							},
							{
								key: 'Live',
								label: t('Live'),
								backgroundColor: theme.danger,
								textColor: theme.white
							},
							{
								key: 'Replay',
								label: t('Replay')
							},
							{
								key: 'Finished',
								label: t('Finished')
							}
						]}
					/>
					<FormHint testID={''} hint={`${t('autoUpdateStatus')}: ${item?.autoUpdateStatus ? t('active') : t('inactive')}`} />
				</View>
			);
		}
	};

	const _isVotingActive = (voting: IVote) => {
		const now = moment(moment());

		if (!voting.isActive) {
			return false;
		}
		if (voting.openUntil && voting.openFrom) {
			return now.isBefore(moment(voting.openUntil)) && now.isAfter(moment(voting.openFrom));
		}
		if (voting.openFrom) {
			return now.isSameOrAfter(moment(voting.openFrom));
		}
		if (voting.openUntil) {
			return now.isBefore(moment(voting.openUntil));
		}
		return true;
	};

	const _renderLinkedVotings = () => {
		if (item?.votes && item?.votes?.length > 0) {
			return (
				<ScrollView horizontal contentContainerStyle={{ flexDirection: 'row' }}>
					{item?.votes
						.filter((_vote) => _vote.id && !_vote.isDeleted)
						.map((v, index) => {
							const found = votes.find((_v) => _v.id === v.id && !_v.isDeleted);
							if (found) {
								const isActive = _isVotingActive(found);
								return (
									<ChildButton
										testID={`${testID}_button_voting_${found.id}`}
										onPress={() =>
											navigation.navigate(ERoutes.ActiveVotingAdmin, { spaceId: activeSpace?.spaceId, id: found.id })
										}
										style={{ flexDirection: 'row' }}
									>
										{index !== 0 && <Text> | </Text>}
										<Text style={{ paddingRight: 5 }}>{found.question}:</Text>
										<Text style={{ color: isActive ? theme.success : theme.danger, paddingRight: 5 }}>
											{isActive ? t('active') : t('inactive')}
										</Text>
									</ChildButton>
								);
							}
							return null;
						})}
				</ScrollView>
			);
		}
		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{ height: SCHEDULELISTITEMENTRYHEIGHT, paddingRight: IS_WEB ? 10 : 0 }}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
				{onSelect && (
					<View style={{ paddingRight: 10 }}>
						<FormCheckbox
							testID={`${testID}_checkbox_select`}
							value={isSelected}
							onPress={() => onSelect()}
							style={{ marginBottom: 0 }}
						/>
					</View>
				)}

				<View style={{ flex: 1, paddingLeft: 10 }}>
					<View style={{ flexDirection: 'row', marginBottom: 2 }}>
						<Text bold numberOfLines={2} style={{ marginRight: item.isHidden ? 5 : undefined }}>
							{item.title}
						</Text>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					{!isEmptyString(item.subtitle) && (
						<Text numberOfLines={1} style={{ fontSize: 12 }}>
							{item.subtitle}
						</Text>
					)}
					{_renderDate()}

					<Text style={{ fontSize: 12, color: item.stage ? theme.text : theme.danger }}>
						{item.stage?.title ?? t('NoStageSelected')}
					</Text>
				</View>
				{_renderActions()}
			</View>
			{_renderLivestatus()}
			{item.isHidden ? <FormHint testID={''} hint={t('ItemHiddenHint')} /> : null}
			{_renderLinkedVotings()}
		</ChildButton>
	);
};
