import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { NoSpaceStackParamList } from 'components/Navigation';
import { ERoutes } from 'components/Navigation/routes';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { NavigationHeader, NavigationHeaderHelloSpaces, NavigationHeaderMenuButton } from 'components/Navigation/Header';
import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FlatList, Keyboard, ScrollView, View } from 'react-native';
import { SpaceHistoryList, SpaceHistoryListItem } from 'components/SpaceHistory';
import { canJoinSpace, EDefaultIconSet, getCardContainerStyle, isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';
import { WelcomeHint, WelcomeHeading, CardSeparationHeader } from 'components/Text';
import { QRCodeScannerModal } from 'components/Modal';
import { HSCard } from 'components/Card';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { IS_WEB } from 'helper/platform';
import { hsTopScreenPadding } from 'config/styleConstants';
import { showToast } from 'helper/toast';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { DEFAULTTHEME } from 'rematch/models/config';
import { ILauncherSpace } from 'config/interfaces';
import { Spinner } from 'components/Spinner';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceSelect>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceSelect>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceSelect];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const SpaceSelectScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth, screenColumnCount } = useQuery();
	const { activeSpace } = useSpace();

	const [spaceId, setSpaceId] = useState<string>('');
	const [isCheckSpaceIdLoading, setIsCheckSpaceIdLoading] = useState<string | undefined>(undefined);
	const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState<boolean>(false);
	const [isPromotedSpacesLoading, setIsPromotedSpacesLoading] = useState<boolean>(false);
	const [promotedSpaces, setPromotedSpaces] = useState<ILauncherSpace[]>([]);

	const spaceHistory = useSelector((store: IRootState) => store.space.spaceHistory);
	const hasAcceptedPrivacyPolicy = useSelector((store: IRootState) => store.auth.hasAcceptedPrivacyPolicy);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const searchSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.searchSpace);
	const setSpaceHistory = useRematchDispatch((dispatch: Dispatch) => dispatch.space.setSpaceHistory);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const removeFromSpaceHistory = useRematchDispatch((dispatch: Dispatch) => dispatch.space.removeFromSpaceHistory);
	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const loadUpcomingSpaces = useRematchDispatch((dispatch: Dispatch) => dispatch.space.loadUpcomingSpaces);

	useEffect(() => {
		// this is needed to keep user that havent accepted privacy policy from using the app
		if (!hasAcceptedPrivacyPolicy && !PRESET_SPACEIDS) {
			setTimeout(() => {
				navigation.replace(ERoutes.Onboarding, { setLastPage: false });
			});
		}
	}, [hasAcceptedPrivacyPolicy]);

	useEffect(() => {
		navigation.addListener('focus', () => _onFocus());
	}, [activeSpace]);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			_searchForSpace();
		}
	};

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('keydown', _handleEnter, false);
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, [spaceId]);

	const _onFocus = async () => {
		const _activeSpace = getActiveSpace({});
		if (_activeSpace) {
			const { isAdmin, needsPin } = canJoinSpace(_activeSpace, profile);

			if (needsPin && !isAdmin) {
				navigation.replace(ERoutes.Prestart, { spaceId: _activeSpace.spaceId });
				return;
			} else {
				navigation.replace('tab');
			}
		} else {
			setTheme(DEFAULTTHEME);
			setIsPromotedSpacesLoading(true);
			const res = await loadUpcomingSpaces({});
			setPromotedSpaces(res);
			setIsPromotedSpacesLoading(false);
		}
	};

	const _findSpaceById = async (id: string) => {
		setIsCheckSpaceIdLoading(id);
		const res = await findSpaceById({ spaceId: id });
		setIsCheckSpaceIdLoading(undefined);
		if (res?.spaceId) {
			const foundAdmin = res.admins?.find((a) => a.id === profile?.id);
			const foundMod = res.admins?.find((a) => a.id === profile?.id);
			if (res.isDeactivated && !foundAdmin && !foundMod) {
				if (foundAdmin) {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitleAdmin'));
				} else {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
				}
			} else {
				setSpaceId(id);
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: res.spaceId });
			}
		} else if (!res?.isOffline) {
			setSpaceHistory(spaceHistory.filter((e) => e.space.spaceId !== id));
		}
	};

	const _searchForSpace = async () => {
		const trimmed = spaceId.trim();
		setSpaceId(trimmed);
		setIsCheckSpaceIdLoading(trimmed);
		const res = await searchSpace({ spaceId: trimmed });

		if (res) {
			if (res?.id) {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: res.spaceId });
			} else {
				if (res.length === 0) {
					showAlert({
						title: t('SpaceSearchNoResultTitle'),
						message: t('SpaceSearchNoResultSubtitle')
					});
				} else {
					navigation.navigate(ERoutes.SpaceSearch, { spaces: res, searchTerm: trimmed });
				}
			}
		} else {
			showAlert({
				title: t('SpaceSearchNoResultTitle'),
				message: t('SpaceSearchNoResultSubtitle')
			});
		}
		setIsCheckSpaceIdLoading(undefined);
	};

	const _renderHint = () => {
		if (IS_WEB) {
			return <WelcomeHint>{t('EnterSpaceIdHintWeb')}</WelcomeHint>;
		}
		return <WelcomeHint>{t('EnterSpaceIdHint')}</WelcomeHint>;
	};

	const _renderQrButton = () => {
		if (IS_WEB) {
			return null;
		}
		return (
			<View style={{ marginRight: 5 }}>
				<RoundButton
					isOutline
					testID="spaceselect_button_qrcodescanner"
					isDisabled={isCheckSpaceIdLoading !== undefined}
					onPress={() => setIsQRCodeModalVisible(!isQRCodeModalVisible)}
					icon={EDefaultIconSet.QRCode}
				/>
			</View>
		);
	};

	return (
		<ScreenContainer>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<WelcomeHeading>{t('Launch your Space')}</WelcomeHeading>
					{_renderHint()}
				</HSCard>
				{!PRESET_SPACEIDS && (
					<HSCard>
						<View
							style={{
								width: '100%',
								flexDirection: 'row',
								justifyContent: 'space-between'
							}}
						>
							<FormTextInput
								formStyle={{ marginBottom: 0, flex: 1, justifyContent: 'center' }}
								testID="spaceselect_textinput_spaceid"
								placeholder={IS_WEB ? 'Space ID' : t('Space ID or scan')}
								value={spaceId}
								onChangeText={(val) => setSpaceId(val)}
								autoCapitalize="none"
								onKeyPress={_handleEnter}
								biggerTextInput
								numberOfLines={1}
							/>
							<View
								style={{
									flexDirection: 'row',
									paddingLeft: 10,
									marginBottom: IS_WEB ? 0 : -5
								}}
							>
								{_renderQrButton()}
								<View style={{ marginRight: -5 }}>
									<RoundButton
										isStacked
										testID="spaceselect_button_search"
										onPress={() => _searchForSpace()}
										isLoading={isCheckSpaceIdLoading !== undefined}
										isDisabled={isEmptyString(spaceId) || spaceId.length < 3 || isCheckSpaceIdLoading !== undefined}
										icon={EDefaultIconSet.Search}
									/>
								</View>
							</View>
						</View>
					</HSCard>
				)}
				{screenColumnCount ? (
					<FlatList
						data={promotedSpaces}
						contentContainerStyle={{
							marginBottom: isPromotedSpacesLoading || promotedSpaces.length > 0 ? 20 : 0
						}}
						ListHeaderComponent={
							isPromotedSpacesLoading ? (
								<Spinner />
							) : promotedSpaces.length > 0 ? (
								<CardSeparationHeader label={t('SoonAvailable')} />
							) : undefined
						}
						numColumns={screenColumnCount}
						renderItem={({ item, index }) => (
							<SpaceHistoryListItem
								item={{ space: item, date: item.promotionStartDate! }}
								onItemPress={() => _findSpaceById(item.spaceId)}
								isLoading={isCheckSpaceIdLoading === item.spaceId}
								containerStyle={{
									...getCardContainerStyle(screenColumnCount, index, EHorizontalScreenPadding.Wide),
									maxWidth: `${100 / screenColumnCount}%`,
									flex: undefined
								}}
							/>
						)}
					/>
				) : null}
				<SpaceHistoryList
					key={'spaceselect_list'}
					label={t('Your Spaces')}
					onItemPress={(id) => _findSpaceById(id)}
					isLoading={isCheckSpaceIdLoading}
					onDeletePress={async (item) => {
						setIsCheckSpaceIdLoading(item.space.spaceId);
						await removeFromSpaceHistory({ spaceId: item.space.spaceId });
						setIsCheckSpaceIdLoading(undefined);
					}}
					sortBy={'visited'}
				/>
			</ScrollView>

			<QRCodeScannerModal
				isVisible={isQRCodeModalVisible}
				onResult={(result) => {
					_findSpaceById(result.toLowerCase());
					setIsQRCodeModalVisible(false);
				}}
				onClose={() => setIsQRCodeModalVisible(false)}
			/>
		</ScreenContainer>
	);
};

export const SpaceSelectScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation } = props;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderHelloSpaces />
		</NavigationHeader>
	);
};
