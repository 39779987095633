import React, { useState } from 'react';
import { IMedia, TMedia } from 'config/interfaces';
import { Image } from 'components/Image';
import { hsBorderRadius } from 'config/styleConstants';
import { StreamPlayer } from 'components/Stream';
import { useTracker } from 'hooks/useTracker';
import { getDefaultAspectRatioStyle, getTopRadiusStyle } from 'helper';

interface IVoteMediaItem {
	voteId: number;
	item: TMedia | undefined;
	containerWidth: number;
	roundedTop?: boolean;
}

export const VoteMediaItem = (props: IVoteMediaItem) => {
	const { voteId, item, containerWidth, roundedTop } = props;
	const { trackAction } = useTracker();

	if (typeof item === 'string' || typeof item === 'number') {
		if (typeof item === 'string' && item.endsWith('.mp4')) {
			return (
				<StreamPlayer
					type="upload"
					url={item}
					isAutoplay={false}
					testID={`mediaitem_${item}`}
					onPlay={(e) => trackAction('feed', 'Play Video', `${voteId}`)}
					onPause={(e) => trackAction('feed', 'Stop Video', `${voteId}`)}
				/>
			);
		}
		return (
			<Image
				url={item}
				style={{
					...getDefaultAspectRatioStyle(containerWidth),
					...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
				}}
				resizeMode="contain"
				expectedRatio={16 / 9}
			/>
		);
	} else if (item) {
		let _item = item;
		if (item[0]?.mime) _item = item[0];

		if (!_item.mime) {
			return null;
		}

		if (_item.mime?.includes('image')) {
			return (
				<Image
					mediaObj={_item}
					imageSize="medium"
					url={_item.url}
					style={{
						...getDefaultAspectRatioStyle(containerWidth),
						...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
					}}
					resizeMode="contain"
					expectedRatio={16 / 9}
				/>
			);
		} else if (_item.mime?.includes('video')) {
			return (
				<StreamPlayer
					type={_item.url.includes('mediadelivery') ? 'iFrame' : 'upload'}
					url={_item.url}
					containerStyle={getDefaultAspectRatioStyle(containerWidth)}
					testID={`mediaitem_${_item}`}
					onPlay={(e) => trackAction('vote', 'Play Video', `${voteId}`)}
					onPause={(e) => trackAction('vote', 'Stop Video', `${voteId}`)}
				/>
			);
		}
	}

	return null;
};
