import React, { useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, getDefaultAspectRatioStyle, isEmptyString, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { IExpo } from 'config/interfaces';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { Image } from 'components/Image/Image';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { t } from 'i18next';
import { ListItemMatchCount } from 'components/Matching';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { hsBorderRadius } from 'config/styleConstants';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useContent } from 'hooks/useContent';

export const EXPOLISTITEMENTRYHEIGHT = 80;

export interface IExpoListItem {
	testID: string;
	item: IExpo;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
	hasSponsorFlag?: boolean;
	isMailReceiver?: boolean;
}

const IMAGEWIDTH = 60;
const EDITMODE_IMAGEWIDTH = 45;

export const ExpoListItem = (props: IExpoListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, isLoading, hasSponsorFlag, isMailReceiver } = props;
	const { theme } = useTheme();
	const { getContentTypeFields } = useContent('expo');

	const [isPinLoading, setIsPinLoading] = useState<boolean>(false);

	const updateExpo = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateExpo);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _renderActions = () => {
		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return (
			<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
				{onEdit && (
					<RoundButton
						testID={`${testID}_button_edit`}
						icon={EDefaultIconSet.Edit}
						size="sm"
						onPress={onEdit}
						isOutline
						isLoading={isLoading}
					/>
				)}
				{onDelete && (
					<RoundButton
						testID={`${testID}_button_delete`}
						icon={EDefaultIconSet.Delete}
						color={theme.danger}
						size="sm"
						onPress={onDelete}
						isOutline
						isLoading={isLoading}
					/>
				)}
				{onEdit && (
					<RoundButton
						testID={`${testID}_button_pin`}
						onPress={() => _updatePinned()}
						isLoading={isPinLoading}
						isOutline={!item.isPinned}
						icon={EDefaultIconSet.Pin}
						size="sm"
					/>
				)}
				<FavoriteButton testID={`${testID}_button_favorite`} id={item?.id} type="expo" />
			</View>
		);
	};

	const _updatePinned = async () => {
		setIsPinLoading(true);
		const _isPinned = item.isPinned;
		showAlert({
			title: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
			message: _isPinned ? t('removePinAnswerSubtitle') : t('pinAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsPinLoading(true);
						await updateExpo({ expo: { id: item?.id, isPinned: !_isPinned }, fields: getContentTypeFields(), noToast: true });
						setIsPinLoading(false);
					}
				}
			]
		});

		setIsPinLoading(false);
	};

	const _renderSponsorFlag = () => {
		if (hasSponsorFlag && item.sponsorType) {
			return (
				<View
					style={[
						{
							marginLeft: 10,
							backgroundColor: theme.primary,
							paddingHorizontal: 10,
							paddingVertical: 5,
							borderRadius: 999
						}
					]}
				>
					<Text center style={{ color: theme.primaryContrast, fontSize: 12 }}>
						{t(item.sponsorType)}
					</Text>
				</View>
			);
		}
		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{
				height: EXPOLISTITEMENTRYHEIGHT,
				paddingVertical: 5,
				// Keep this padding for new flashlist
				// to prevent overlap of scrollbar and fav button
				paddingRight: IS_WEB ? 10 : 0,
				flexDirection: 'row',
				alignItems: 'center'
			}}
			isDisabled={!onPress}
			onPress={isMailReceiver ? onSelect : onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}

			<View style={{ width: onSelect ? EDITMODE_IMAGEWIDTH : IMAGEWIDTH }}>
				{isMailReceiver ? (
					<ChildButton testID={`${testID}_image`} onPress={onPress}>
						<Image
							style={{
								...getDefaultAspectRatioStyle(onSelect ? EDITMODE_IMAGEWIDTH : IMAGEWIDTH),
								borderRadius: hsBorderRadius
							}}
							mediaObj={item.logo}
							imageSize="small"
							expectedRatio={16 / 9}
							resizeMode={'contain'}
						/>
					</ChildButton>
				) : (
					<Image
						style={{
							...getDefaultAspectRatioStyle(onSelect ? EDITMODE_IMAGEWIDTH : IMAGEWIDTH),
							borderRadius: hsBorderRadius
						}}
						mediaObj={item.logo}
						imageSize="small"
						expectedRatio={16 / 9}
						resizeMode={'contain'}
					/>
				)}
			</View>

			<View style={{ flex: 1, paddingLeft: 10, justifyContent: 'flex-start' }}>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<View style={{ flex: 1, flexDirection: 'row', marginBottom: 5 }}>
						<Text bold style={{ marginRight: item.isHidden ? 5 : undefined }}>
							{item.title}
						</Text>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					<ListItemMatchCount item={item} contentType="expo" />
				</View>
				{!isEmptyString(item.catchphrase) && (
					<Text italic numberOfLines={2} style={{ fontSize: 12 }}>
						{item.catchphrase}
					</Text>
				)}
			</View>
			<View>
				{_renderActions()}
				{_renderSponsorFlag()}
			</View>
		</ChildButton>
	);
};
