import { createModel } from '@rematch/core';
import { RootModel } from './index';
import { IVote } from 'config/interfaces/vote';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { hasEntryChanged, isFeatureActive, translateStrapiError } from 'helper';
import { ICreateVotePayload, IMediaGalleryItem } from 'config/interfaces';
import {
	IDeleteVote,
	ILoadVoteAnswersAndStartSync,
	IModerateVoteWordCloud,
	ILoadVotesPayload,
	IResetVoteAnswers,
	IResolvedToken,
	IResolveTokenPayload,
	ISubmitVoteAnswer,
	IUpdateVoteAnswerParams,
	IUpdateVoteParams
} from 'rematch/interfaces';
import i18next from 'i18next';
import { TVoteResult } from 'config/interfaces/voteanswer';
import { showToast } from 'helper/toast';
import { ENVIRONMENT } from 'config/envConstants';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	votes: IVote[];
	voteAnswers: TVoteResult[];
	lastVoteRead: Record<string, string>;
	lastVoteAnswerRead: Record<number, string>;
	lastVoteAnswerReset?: string;
};

export const vote = createModel<RootModel>()({
	state: {
		votes: [],
		voteAnswers: [],
		lastVoteRead: {},
		lastVoteAnswerRead: {},
		lastVoteAnswerReset: undefined
	} as IDefaultState,
	reducers: {
		addVotes(state, { load, newVotes }: { load: boolean; newVotes: IVote[] }) {
			rematchLog('addVotes');
			const _votes = state.votes ? [...state.votes] : [];
			const _lastRead = state.lastVoteRead ? { ...state.lastVoteRead } : {};

			newVotes.forEach((vote) => {
				const idx = _votes.findIndex((e) => e.id === vote.id);
				if (idx !== -1) {
					if (vote.isDeleted) {
						_votes.splice(idx, 1);
					} else {
						_votes[idx] = vote;
					}
				} else if (!vote.isDeleted) {
					_votes.push(vote);
				}

				if (load && (!_lastRead[vote.spaceId] || _lastRead[vote.spaceId] < vote.updated_at)) {
					_lastRead[vote.spaceId] = vote.updated_at;
				}
			});

			return {
				...state,
				votes: _votes,
				lastVoteRead: _lastRead
			};
		},
		addVoteAnswers(state, { load, voteAnswers }: { load: boolean; voteAnswers: IDefaultState['voteAnswers'] }) {
			rematchLog('addVoteAnswers');
			let _voteAnswers = state.voteAnswers ? [...state.voteAnswers] : [];
			const _lastRead = state.lastVoteAnswerRead ? { ...state.lastVoteAnswerRead } : {};

			voteAnswers?.forEach((answer) => {
				let foundIndex = _voteAnswers.findIndex(
					(e) =>
						(answer.isResultObject && e.isResultObject && e.voteId === answer.voteId) ||
						(!e.isResultObject && !answer.isResultObject && e.tempId === answer.tempId)
				);
				if (foundIndex === -1) {
					foundIndex = _voteAnswers.findIndex(
						(e) =>
							(e.isResultObject && e.voteId === answer.voteId) ||
							(!answer.isResultObject && answer.id && !e.isResultObject && e.id === answer.id)
					);

					if (foundIndex === -1) {
						_voteAnswers.push(answer);
					}
				}
				if (foundIndex > -1) {
					if (answer.isDeleted) {
						_voteAnswers.splice(foundIndex, 1);
					} else {
						_voteAnswers[foundIndex] = answer;
					}
				}
				if (load && answer.updated_at && (!_lastRead[answer.voteId] || _lastRead[answer.voteId] < answer.updated_at)) {
					_lastRead[answer.voteId] = answer.updated_at;
				}
			});

			return {
				...state,
				voteAnswers: _voteAnswers,
				lastVoteAnswerRead: _lastRead
			};
		},
		resetLocalVoteAnswers(state, date) {
			return {
				...state,
				voteAnswers: [],
				lastVoteAnswerRead: {},
				lastVoteAnswerReset: date
			};
		},
		clear(state) {
			rematchLog('clear vote');
			return {
				votes: [],
				voteAnswers: [],
				lastVoteRead: {},
				lastVoteAnswerRead: {},
				lastVoteAnswerReset: undefined
			};
		}
	},
	effects: (dispatch) => ({
		async startVotesSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (
					activeSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_votes`) &&
					isFeatureActive(activeSpace, 'votings')
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_votes`, (data) => {
						const _load = !Array.isArray(data) && data.load;
						const _items = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'votings received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'votings', value: false });
							if (!store.temp.hasLoadedData?.votings) {
								dispatch.temp.setHasLoadedData('votings');
							}
						}

						if (_items.length > 0) {
							dispatch.vote.addVotes({
								load: _load,
								newVotes: _items
							});
						}
					});
				}
			} catch (error) {
				errorLog('startVotesSync', error);
			}
		},
		async loadVoteDelta(payload: ILoadVotesPayload, store) {
			try {
				const { load } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && (isFeatureActive(activeSpace, 'votings') || load)) {
					if (store.temp.hasLoadedData?.votings) {
						contentLoadLog(3, 'votings already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.votings) {
						contentLoadLog(3, 'votings load already in progress. skipping');
						return;
					}

					const _lastRead =
						store.vote.lastVoteRead && store.vote.lastVoteRead[activeSpace.spaceId]
							? store.vote.lastVoteRead[activeSpace.spaceId]
							: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading votings');
							dispatch.temp.setWaitingForSocketResponse({ key: 'votings', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'votes',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								})
							);
							dispatch.temp.setWaitingForSocketResponse({ key: 'votings', value: true });

							const res = await dispatch.request.anonymousRequest({
								method: 'POST',
								url: `${MULTISPACEURL}/votes/find`,
								body
							});

							dispatch.temp.setWaitingForSocketResponse({ key: 'votings', value: false });

							if (res && Array.isArray(res)) {
								dispatch.temp.setHasLoadedData('votings');
								dispatch.vote.addVotes({ load: true, newVotes: res });
							}

							break;
					}
				}
			} catch (error) {
				console.log('reloadSpaceVotesAndStartSync', error);
			}
		},
		async createVote(payload: ICreateVotePayload, store) {
			try {
				const { data, noToast } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const mySpace = data.space ?? activeSpace;
				if (mySpace && store.auth.profile) {
					const body = new FormData();

					let mediaToUpload: IMediaGalleryItem[] = [];

					if (data.media) {
						mediaToUpload = [...data.media];
						data.media = [];
					}

					body.append(
						'data',
						JSON.stringify({
							vote: { ...data, spaceId: mySpace.spaceId },
							userInfos: store.auth.userInfos,
							spaceId: mySpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/votes`,
						method: 'POST',
						body
					});

					if (res?.id) {
						if (!res.media) {
							res.media = [];
						}
						for (let i = 0; i < mediaToUpload.length; i++) {
							const mediaObj = mediaToUpload[i];

							if (mediaObj && typeof mediaObj.media !== 'number') {
								if (
									typeof mediaObj.media === 'string' ||
									mediaObj.media?.url?.startsWith('file://') ||
									mediaObj.media?.url?.startsWith('data:')
								) {
									let imageId;
									if (typeof mediaObj !== 'string') {
										imageId = mediaObj.id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
										imageId,
										caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
									});
									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										res.media[i] = {
											...mediaObj,
											media: mediaResponse[0]
										};
									}
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
								if (libEntry) {
									res.media[i] = {
										...mediaObj,
										media: { ...libEntry }
									};
								}
							}
						}

						if (mediaToUpload.length > 0) {
							const updateBody = new FormData();
							updateBody.append(
								'data',
								JSON.stringify({
									vote: { media: res.media },
									userInfos: store.auth.userInfos,
									spaceId: mySpace.spaceId
								})
							);

							const res2 = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/votes/${res.id}`,
								method: 'PUT',
								body: updateBody
							});
						}

						if (!noToast) {
							showToast('success', i18next.t('Vote created'));
						}
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('createVote', error);
				return undefined;
			}
		},
		async updateVote(payload: IUpdateVoteParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.auth.profile) {
					const { voteId, vote, noToast } = payload;

					const body = new FormData();

					if (vote.media) {
						const mediaToUpload: IMediaGalleryItem[] = [...vote.media];
						vote.media = [];

						for (let i = 0; i < mediaToUpload.length; i++) {
							const mediaObj = mediaToUpload[i];

							if (typeof mediaObj.media !== 'number') {
								if (
									typeof mediaObj.media === 'string' ||
									mediaObj.media?.url?.startsWith('file://') ||
									mediaObj.media?.url?.startsWith('data:')
								) {
									let imageId;
									if (typeof mediaObj !== 'string') {
										imageId = mediaObj.id;
									}

									const mediaResponse = await dispatch.upload.uploadMedia({
										endpoint: MULTISPACEURL,
										uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
										imageId,
										caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
									});

									if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
										vote.media[i] = {
											...mediaObj,
											media: mediaResponse[0]
										};
									}
								} else {
									vote.media[i] = mediaObj;
								}
							} else {
								const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
								if (libEntry) {
									vote.media[i] = {
										...mediaObj,
										media: { ...libEntry }
									};
								}
							}
						}
					}

					body.append(
						'data',
						JSON.stringify({
							vote: payload.vote,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/votes/${voteId}`,
						method: 'PUT',
						body
					});

					if (res?.id) {
						if (!noToast) {
							showToast('success', i18next.t('Vote updated'));
						}
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('updateVote', error);
			}
		},
		async deleteVote(payload: IDeleteVote, store) {
			try {
				const { voteId } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const body = new FormData();

				if (activeSpace) {
					const vote = { id: voteId, isDeleted: true };

					body.append(
						'data',
						JSON.stringify({
							vote,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/votes/${voteId}`,
						method: 'PUT',
						body
					});

					if (res?.id) {
						showToast('success', i18next.t('Vote deleted'));
						return res;
					} else {
						showToast('error', i18next.t('VoteNotDeleted'));
						return false;
					}
				}
			} catch (error) {
				console.log('deleteVote', error);
			}
		},
		async startMyVoteAnswersSync(payload, store) {
			const currentSpace = dispatch.temp.getActiveSpace({});

			if (
				currentSpace &&
				store.socket.multiSpaceSocket &&
				!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${currentSpace.spaceId}_voteanswers`) &&
				isFeatureActive(currentSpace, 'votings')
			) {
				store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${currentSpace.spaceId}_voteanswers`, (data) => {
					const _load = data.load;

					if (_load) {
						contentLoadLog(4, 'myvoteanswers received');
						dispatch.temp.setWaitingForSocketResponse({ key: 'myvoteanswers', value: false });
						if (!store.temp.hasLoadedData?.myvoteanswers) {
							dispatch.temp.setHasLoadedData('myvoteanswers');
						}
					}

					if (data.voteAnswers?.length > 0) {
						const changedVoteAnswers: TVoteResult[] = data.voteAnswers.filter((answer: TVoteResult) => {
							if (answer.isResultObject) {
								return true;
							}
							const prev = store.vote.voteAnswers.find((e) => e.tempId === answer.tempId);

							if (!prev && !answer.isDeleted) {
								return true;
							}

							return prev && hasEntryChanged(prev, answer);
						});

						if (changedVoteAnswers.length > 0) {
							dispatch.vote.addVoteAnswers({ load: false, voteAnswers: changedVoteAnswers });
						}
					}

					if (data.likes?.length > 0) {
						dispatch.like.addLikes({ likes: data.likes });
					}
				});
			}
		},
		async loadMyVoteAnswers(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				// JW:
				// We should load all vote answers once
				// -> answer counts between two devices could be out of sync
				// -> we still sync only the active voting to prevent too much data transfer from happening
				// -> we sync our own vote answers to make sure the view is the same on multiple devices (I have answeered)

				if (activeSpace && isFeatureActive(activeSpace, 'votings')) {
					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Socket:
							if (store.temp.hasLoadedData?.myvoteanswers) {
								contentLoadLog(3, 'myvoteanswers already loaded. skipping');
								return;
							}

							if (store.temp.waitingForSocketResponse?.myvoteanswers) {
								contentLoadLog(3, 'myvoteanswers load already in progress. skipping');
								return;
							}

							contentLoadLog(2, 'loading myvoteanswers');
							dispatch.temp.setWaitingForSocketResponse({ key: 'myvoteanswers', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'myvoteanswers',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/voteanswers/findbyvoteid?spaceId=${activeSpace.spaceId}&userId=${store.auth.userInfos.userId}`,
								method: 'GET'
							});

							if (res.answers?.length > 0) {
								const changedVoteAnswers: TVoteResult[] = res.answers.filter((answer: TVoteResult) => {
									if (answer.isResultObject) {
										return true;
									}
									const prev = store.vote.voteAnswers.find((e) => e.tempId === answer.tempId);

									if (!prev && !answer.isDeleted) {
										return true;
									}

									return prev && hasEntryChanged(prev, answer);
								});

								if (changedVoteAnswers.length > 0) {
									dispatch.vote.addVoteAnswers({ load: false, voteAnswers: changedVoteAnswers });
								}
							}

							break;
					}
				}
			} catch (error) {
				errorLog('loadMyVoteAnswers', error);
			}
		},
		async loadVoteAnswersAndStartSync(payload: ILoadVoteAnswersAndStartSync, store) {
			try {
				const { reload, voteId, space, nosync, load } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				const currentSpace = space ?? activeSpace;
				if (currentSpace && (isFeatureActive(currentSpace, 'votings') || load)) {
					if (
						!nosync &&
						store.socket.multiSpaceSocket &&
						!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${currentSpace.spaceId}_voteanswers_${voteId}`)
					) {
						store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${currentSpace.spaceId}_voteanswers_${voteId}`, (data) => {
							if (data.voteAnswers?.length > 0) {
								const updatedAnswers = data.voteAnswers.filter((answer: TVoteResult) => {
									if (answer.isResultObject) {
										return true;
									}
									const prev = store.vote.voteAnswers.find((e) => e.tempId === answer.tempId);

									return hasEntryChanged(prev, answer);
								});

								if (updatedAnswers.length > 0) {
									dispatch.vote.addVoteAnswers({ voteAnswers: updatedAnswers, load: data.load });
								}
							}

							if (data.likes?.length > 0) {
								dispatch.like.addLikes({ likes: data.likes });
							}
						});
					}

					let _dataLoadStrategy = DATA_LOAD_STRATEGY;

					if (reload) {
						if (nosync || (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected)) {
							_dataLoadStrategy = EDataLoadStrategy.Default;
						}
						switch (_dataLoadStrategy) {
							case EDataLoadStrategy.Socket:
								dispatch.socket.emitToMultiSpaceBackend({
									event: 'loadContent',
									data: {
										type: 'voteanswers',
										userId: store.auth.userInfos.userId,
										spaceId: currentSpace.spaceId,
										voteId
									}
								});
								break;
							case EDataLoadStrategy.Default:
							default:
								const _lastRead =
									store.vote.lastVoteAnswerRead && store.vote.lastVoteAnswerRead[voteId]
										? store.vote.lastVoteAnswerRead[voteId]
										: ZEROHOUR;

								const res = await dispatch.request.anonymousRequest({
									url: `${MULTISPACEURL}/voteanswers/findbyvoteid?spaceId=${currentSpace.spaceId}&voteId=${voteId}&lastRead=${_lastRead}`,
									method: 'GET'
								});

								if (res && Array.isArray(res.answers) && res.answers.length > 0) {
									const updatedVoteAnswers = res.answers.filter((answer: TVoteResult) => {
										if (answer.isResultObject) {
											const prev = store.vote.voteAnswers.find((e) => e.voteId === answer.voteId && e.isResultObject);
											return hasEntryChanged(prev, answer);
										}

										const prev = store.vote.voteAnswers.find((e) => e.tempId === answer.tempId);

										return hasEntryChanged(prev, answer);
									});

									if (updatedVoteAnswers.length > 0) {
										dispatch.vote.addVoteAnswers({ load: true, voteAnswers: updatedVoteAnswers });
									}
								}
								if (res?.likes?.length > 0) {
									dispatch.like.addLikes({ likes: res.likes });
								}
								break;
						}
					}
				}
			} catch (error) {
				console.log('loadVoteAnswersAndStartSync', error);
			}
		},
		async updateVoteAnswer(payload: IUpdateVoteAnswerParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.auth.profile) {
					const { answer, isDeletion } = payload;

					const body = new FormData();

					body.append(
						'data',
						JSON.stringify({
							answer,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/voteanswers/${answer.tempId}`,
						method: 'PUT',
						body
					});

					if (res?.id || res?.tempId) {
						dispatch.vote.addVoteAnswers({ load: false, voteAnswers: [res] });

						if (isDeletion) {
							showToast('success', i18next.t('Vote answer deleted'));
						} else {
							showToast('success', i18next.t('Vote answer updated'));
						}

						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('updateVote', error);
			}
		},
		async submitVoteAnswer(payload: ISubmitVoteAnswer, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { voteId, media, value, isAnonymousSubmission, noToast, reasonAnswer } = payload;

					let uploadedMedia = {};
					if (media) {
						if (typeof media === 'string' || media.url.startsWith('file://')) {
							let imageId;
							if (typeof media !== 'string') {
								imageId = media.id;
							}

							const imageResponse = await dispatch.upload.uploadMedia({
								endpoint: MULTISPACEURL,
								uri: typeof media === 'string' ? media : media.url,
								imageId,
								caption: typeof media === 'object' ? media.caption : undefined
							});

							if (imageResponse) {
								uploadedMedia = imageResponse;
							}
						}
					}
					const body = new FormData();

					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							voteId,
							media: uploadedMedia,
							value: value.toString(),
							isAnonymousSubmission,
							reasonAnswer
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/voteanswers/submitvoteanswer`,
						method: 'POST',
						body
					});

					if (res && ('id' in res?.myAnswer || 'tempId' in res?.myAnswer)) {
						let text2 = '';

						if (res.myAnswer.status === 'Waiting') {
							text2 = i18next.t('VoteTextInfoWaiting');
						}

						dispatch.vote.addVoteAnswers({ load: false, voteAnswers: res.answers });

						if (!noToast) {
							showToast('success', i18next.t('Answer submitted'), text2);
						}

						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				errorLog('submitVoteAnswer', error);
				return false;
			}
		},
		async moderateVoteWordCloudAnswer(payload: IModerateVoteWordCloud, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { voteId, value, userId, isDeleted } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							userId,
							voteId,
							value,
							isDeleted
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/voteanswers/moderatevotewordcloudanswer`,
						method: 'PUT',
						body
					});

					if (res && ('id' in res?.myAnswer || 'tempId' in res?.myAnswer)) {
						dispatch.vote.addVoteAnswers({ load: false, voteAnswers: res.answers });
						showToast('success', i18next.t('Answer submitted'));
						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('moderateVoteWordCloudAnswer', error);
			}
		},
		async resetVoteAnswers(payload: IResetVoteAnswers, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { voteId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							voteId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/voteanswers/resetvoteanswers`,
						method: 'POST',
						body
					});

					if (res) {
						showToast('success', i18next.t('Answers resetted'));
					}
				}
			} catch (error) {
				console.log('resetVoteAnswer', error);
			}
		},
		async resolveVoteToken(payload: IResolveTokenPayload, store) {
			try {
				const { token } = payload;
				const res: IResolvedToken = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/spaces/resolvevotetoken`,
					method: 'POST',
					body: JSON.stringify({
						token: token
					})
				});

				if (res?.status === 200) {
					return res;
				}
			} catch (error) {
				console.log('resolveVoteToken', error);
			}
		}
	})
});
