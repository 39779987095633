import { FormCheckbox, FormTextInput, FormMultiSwitch } from 'components/Form';
import { hsInnerPadding } from 'config/styleConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';

interface IRegistrationFieldsMetaForm {
	testID: string;
	onChange: (field: string, value: any) => void;
	values: Record<string, any>;
	errors: Record<string, string>;
}

export const RegistrationFieldsMetaForm = (props: IRegistrationFieldsMetaForm) => {
	const { testID, values, errors, onChange } = props;
	const { t } = useTranslation();

	const _renderInitialValueField = () => {
		if (!_shouldRenderField()) {
			return null;
		}

		switch (values.fieldType) {
			case 'boolean':
				return (
					<FormCheckbox
						testID={`${testID}_checkbox_defaultvalue`}
						label={t('InitialValue')}
						hint={t('InitialValueHintBoolean')}
						value={values.defaultValue as boolean}
						onPress={() => onChange('defaultValue', values.defaultValue ? false : true)}
					/>
				);
			default:
				return null;
		}
	};

	const _getFieldVisibility = () => {
		switch (values?.visibility) {
			case 'always':
				return t('FieldVisibilityInfoAlways');
			case 'registrationOnly':
				return t('FieldVisibilityInfoRegistrationOnly');
			case 'editOnly':
				return t('FieldVisibilityInfoEditProfileOnly');
			case 'never':
				return t('FieldVisibilityInfoNever');
			default:
				return undefined;
		}
	};

	const _renderSectionPosition = () => {
		if (values.fieldType === 'section' || values.fieldType === 'mainsection') {
			return (
				<FormMultiSwitch
					testID={`${testID}_multiswitch_sectionposition`}
					label={t('sectionPosition')}
					isRequired
					options={[
						{
							key: 'left',
							label: t('Left')
						},
						{
							key: 'right',
							label: t('Right')
						}
					]}
					// isDisabled={values.isDefaultField}
					hint={t('sectionPositionHint')}
					value={values.sectionPosition}
					// error={_getFieldVisibility()}
					onChange={(value) => onChange('sectionPosition', value)}
				/>
			);
		}
		return null;
	};

	const _shouldRenderField = () => {
		return values.fieldType !== 'section' && values.fieldType !== 'label' && values.visibility !== 'never';
	};

	return (
		<ScrollView
			keyboardShouldPersistTaps="handled"
			onScrollBeginDrag={() => Keyboard.dismiss()}
			scrollEventThrottle={0}
			style={{ marginHorizontal: -hsInnerPadding }}
			contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
		>
			<FormTextInput
				testID={`${testID}_textinput_fieldlabel`}
				label={t('Field Label')}
				isRequired
				value={values.fieldLabel}
				error={errors['fieldLabel']}
				onChangeText={(value) => onChange('fieldLabel', value)}
			/>
			<FormTextInput
				testID={`${testID}_textinput_fieldcsv`}
				hint={t('cannotBeEdited')}
				label={t('Name in CSV')}
				value={values.fieldName}
				editable={false}
			/>
			{_renderSectionPosition()}
			<FormMultiSwitch
				testID={`${testID}_multiswitch_visibility`}
				label={t('FieldVisibility')}
				isRequired
				options={[
					{
						key: 'always',
						label: t('FieldVisibilityAlways')
					},
					{
						key: 'registrationOnly',
						label: t('FieldVisibilityRegistrationOnly')
					},
					{
						key: 'editOnly',
						label: t('FieldVisibilityEditProfileOnly')
					},
					{
						key: 'never',
						label: t('FieldVisibilityNever')
					}
				]}
				isDisabled={values.isDefaultField}
				hint={t('FieldVisibilityHint')}
				value={values.visibility}
				error={_getFieldVisibility()}
				onChange={(value) => onChange('visibility', value)}
			/>
			{_shouldRenderField() && (
				<FormCheckbox
					testID={`${testID}_switch_isrequired`}
					label={t('isRequired')}
					value={values.isRequired}
					hint={t('isRequiredHint')}
					isDisabled={values.isDefaultField}
					onPress={() => onChange('isRequired', values.isRequired ? false : true)}
				/>
			)}
			{_shouldRenderField() && (
				<FormTextInput
					testID={`${testID}_textinput_fieldhint`}
					label={t('Field Hint')}
					hint={t('Field Hint Hint')}
					value={values.fieldHint}
					multiline
					onChangeText={(value) => onChange('fieldHint', value)}
				/>
			)}
			{_renderInitialValueField()}
			{_shouldRenderField() && (
				<FormCheckbox
					testID={`${testID}_switch_showondetailscreen`}
					label={t('showOnDetailScreen')}
					hint={values.fieldType === 'category' ? t('isRequiredForMatchings') : undefined}
					value={values.showOnDetailScreen}
					isDisabled={!values.activateShowOnDetailsEdit && values.isDefaultField}
					onPress={() => onChange('showOnDetailScreen', values.showOnDetailScreen ? false : true)}
				/>
			)}
			{_shouldRenderField() && values.fieldName === 'aboutMe' && (
				<FormCheckbox
					testID={`${testID}_switch_showonpreview`}
					label={t('showOnPreview')}
					value={values.showOnPreview}
					isDisabled={!values.activateShowOnDetailsEdit && values.isDefaultField}
					onPress={() => onChange('showOnPreview', values.showOnPreview ? false : true)}
				/>
			)}

			{_shouldRenderField() && values.fieldType === 'category' && (
				<FormMultiSwitch
					testID={`${testID}_multiswitch_filter`}
					label={t('useAsFilter')}
					hint={t('useAsFilterHint')}
					error={errors.useAsFilter}
					value={values.useAsFilter}
					onChange={(val) => onChange('useAsFilter', val)}
					options={[
						{
							key: 'screen',
							label: t('useAsFilterScreen')
						},
						{
							key: 'modal',
							label: t('useAsFilterModal')
						},
						{
							key: 'never',
							label: t('useAsFilterNever')
						}
					]}
				/>
			)}
		</ScrollView>
	);
};
