import { useNavigation } from '@react-navigation/native';
import { ChildButton, RoundButton } from 'components/Button';
import { BUTTONBORDERRADIUS } from 'components/Button/constants';
import { HSCard } from 'components/Card';
import { FormError } from 'components/Form/FormError';
import { FormHint } from 'components/Form/FormHint';
import { FormSwitch } from 'components/Form/FormSwitch';
import { FormTextInput } from 'components/Form/FormTextInput';
import { Icon } from 'components/Icon';
import { H3, Text } from 'components/Text';
import { IVote } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';

interface IWordCloudForm {
	isLoading?: boolean;
	value: string[];
	onChange: (value: string[]) => void;
	vote: IVote;
	isPreview?: boolean;
	answerAnonymous?: boolean;
	setAnswerAnonymous?: (val) => void;
}

const WORD_MAX_LENGTH = 25;

export const WordCloudForm = (props: IWordCloudForm) => {
	const { isLoading, value, onChange, vote, isPreview, answerAnonymous, setAnswerAnonymous } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const navigation = useNavigation();

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const [hasDuplicate, setHasDuplicate] = useState<boolean>(false);
	const [newWord, setNewWord] = useState<string>('');
	const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
	const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);

	const _renderMyWords = () => {
		if (value.length > 0) {
			return (
				<View style={{ width: '100%' }}>
					<H3 style={{ marginBottom: 15 }}>{t('MyChoice')}</H3>
					<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
						{value.map((word, idx) => {
							return (
								<ChildButton
									key={`activevote_button_submittedword_${idx}`}
									testID={`activevote_button_submittedword_${idx}`}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										borderRadius: BUTTONBORDERRADIUS,
										backgroundColor: theme.primary,
										marginBottom: 20,
										marginRight: 20,
										paddingHorizontal: 10,
										paddingVertical: 5,
										borderWidth: idx === activeIndex ? 2 : 0,
										borderColor: idx === activeIndex ? theme.primaryContrast : undefined
									}}
									isDisabled={isLoading}
									onPress={() => {
										if (idx === activeIndex) {
											setActiveIndex(undefined);
											if (newWord === word) setNewWord('');
										} else {
											setActiveIndex(idx);
											setNewWord(word);
										}
									}}
								>
									<Text style={{ color: theme.primaryContrast, fontSize: 16 }} bold={idx === activeIndex}>
										{word}
									</Text>
									<ChildButton
										testID={`activevote_button_submittedword_delete_${idx}`}
										style={{
											position: 'absolute',
											top: -10,
											right: -7,
											borderWidth: 1,
											borderColor: theme.primary,
											paddingHorizontal: 2,
											paddingVertical: 1,
											backgroundColor: theme.primaryContrast,
											borderRadius: 999
										}}
										isDisabled={isRemoveLoading}
										onPress={() => {
											showAlert({
												title: t('removeWordFromWordCloud').replace('%VALUE%', word),
												buttons: [
													{
														text: t('Cancel'),
														style: 'cancel'
													},
													{
														text: t('Remove'),
														style: 'destructive',
														onPress: async () => {
															setIsRemoveLoading(true);
															navigation.setParams({ prohibitNavigation: true });
															const _newValue = [...value];
															_newValue.splice(idx, 1);
															onChange(_newValue);
															setIsRemoveLoading(false);
														}
													}
												]
											});
										}}
									>
										<Icon name={EDefaultIconSet.Close} color={theme.primary} size={14} />
									</ChildButton>
								</ChildButton>
							);
						})}
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderDuplicateError = () => {
		if (hasDuplicate) {
			return <FormError testID="activevote_error_duplicateAnswers" error={t('duplicateError')} />;
		}
		return null;
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin' && setAnswerAnonymous) {
			return (
				<View style={{ marginTop: 30, flexDirection: 'row', justifyContent: 'flex-end' }}>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}
		return null;
	};

	const _addWord = () => {
		const match = value.find(
			(v, index) =>
				v?.toLowerCase().trim() === newWord?.toLowerCase().trim() && (activeIndex !== undefined ? index !== activeIndex : true)
		);
		if (match) {
			setHasDuplicate(true);
			return;
		}
		setHasDuplicate(false);
		if (activeIndex !== undefined && value[activeIndex]) {
			value[activeIndex] = newWord.trim();
		} else {
			onChange([...value, newWord.trim()]);
		}
		setNewWord('');
		setActiveIndex(undefined);
	};

	const _renderRestrictions = () => {
		const elements: ReactNode[] = [];

		if (vote.minValue && vote.maxValue && ((vote.maxValue && vote.maxValue > 0) || isPreview)) {
			elements.push(
				<FormHint
					key={`activevote_textinput_value_minCountHint`}
					testID={`activevote_textinput_value_minCountHint`}
					hint={t('WordCloudMinMaxWordHint')
						.replace('%MIN%', vote.minValue.toString())
						.replace('%MAX%', vote.maxValue.toString())}
				/>
			);
		} else {
			if (vote.minValue) {
				elements.push(
					<FormHint
						key={`activevote_textinput_value_minCountHint`}
						testID={`activevote_textinput_value_minCountHint`}
						hint={t('WordCloudMinWordHint').replace('%COUNT%', vote.minValue.toString())}
					/>
				);
			}

			if ((vote.maxValue && vote.maxValue > 0) || isPreview) {
				elements.push(
					<FormHint
						key={`activevote_textinput_value_maxCountHint`}
						testID={`activevote_textinput_value_maxCountHint`}
						hint={t('WordCloudMaxWordHint').replace('%COUNT%', (vote.maxValue ? vote.maxValue : 3).toString())}
					/>
				);
			}
		}

		return <View style={{ marginBottom: 20, marginTop: 20 }}>{elements}</View>;
	};

	return (
		<View style={{ flex: 1 }}>
			<HSCard>
				{_renderMyWords()}
				<View style={{ flexDirection: 'row', paddingRight: 40 }}>
					<FormTextInput
						blurOnSubmit={false}
						testID="activevote_textinput_value"
						label={t('New Entry')}
						hint={t('wordcloudTextSubmitHint')}
						placeholder={t('WordCloudNewWordPlaceholder')}
						value={newWord}
						onChangeText={(val) => {
							if (val.includes('_')) {
								val = val.replace('_', '');
							}
							navigation.setParams({ prohibitNavigation: true });
							setNewWord(val);
						}}
						maxInputLength={WORD_MAX_LENGTH}
						formStyle={{ marginBottom: 5, paddingRight: 5 }}
						onKeyPress={(e) => {
							if (e.nativeEvent.key === 'Enter') {
								if (!isLoading && !isEmptyString(newWord) && (vote.maxValue ? value.length < vote.maxValue : true)) {
									_addWord();
								}
							}
						}}
					/>
					<RoundButton
						testID="activevote_button_addword"
						icon={activeIndex !== undefined ? EDefaultIconSet.Save : EDefaultIconSet.Add}
						size="sm"
						alignSelf="flex-end"
						isStacked
						isDisabled={
							isLoading ||
							isEmptyString(newWord) ||
							newWord.length > WORD_MAX_LENGTH ||
							(vote.maxValue ? value.length >= vote.maxValue : false)
						}
						onPress={() => _addWord()}
					/>
				</View>

				{_renderDuplicateError()}
				{_renderRestrictions()}
				{_renderAnonymousSwitch()}
			</HSCard>
		</View>
	);
};
