import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/core';
import { v4 } from 'uuid';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';

import { StepWizard } from 'components/StepWizard';
import {
	getCreateSpaceFormInitialValues,
	ICreateSpaceFormErrors,
	ICreateSpaceFormValues,
	ICreateVotePayload,
	ILauncherSpace,
	ISchedule,
	TVotingType
} from 'config/interfaces';
import { SpaceWizardTheme } from './SpaceWizardTheme';
import { SpaceWizardColors } from './SpaceWizardColors';
import { MAXTABCOUNT, SpaceWizardFeatures } from './SpaceWizardFeatures';
import { SpaceWizardDone } from './SpaceWizardDone';
import { getCreateSpaceFormSchema } from 'config/yupSchemas';
import {
	getDefaultVotingQuestion,
	getDefaultVotingOptions,
	isEmptyString,
	validateForm,
	getDefaultVotingHint,
	getDefaultOptionsVote
} from 'helper';
import { CreateSpaceForm } from 'components/Forms';
import { SpaceWizardContent, TPossibleContentType } from './SpaceWIzardContent';
import { useContent } from 'hooks/useContent';
import {
	getExpoExamples,
	getFeedExamples,
	getMapExample,
	getMediaItemExample,
	getNetworkingExample,
	getSchedulesExample,
	getSpeakerExample,
	getStageExample,
	getStreamExample
} from 'helper/content';
import { SpaceWizardSpaceId } from './SpaceWizardSpaceId';
import { isEmptyObject } from 'helper/object';
import { getDefaultExpoFields, getDefaultRegistrationFields, getDefaultSpeakerFields, getDefaultTicketFields } from 'config/defaultFields';
import { DEFAULTTHEME } from 'rematch/models/config';
import { SpaceWizardTemplate } from './Template';

interface ISpaceWizard {
	onFinish: () => void;
}

const TESTIDPREFIX = 'createspace';

export const SpaceWizard = (props: ISpaceWizard) => {
	const { onFinish } = props;
	const { t } = useTranslation();
	const navigation = useNavigation();
	const route = useRoute();

	const { getContentTypeFields: getContentTypeFieldsExpo } = useContent('expo');
	const { getContentTypeFields: getContentTypeFieldsSchedule } = useContent('schedule');
	const { getContentTypeFields: getContentTypeFieldsSpeaker } = useContent('speaker');
	const { getContentTypeFields: getContentTypeFieldsStage } = useContent('stage');
	const { getContentTypeFields: getContentTypeFieldsNetworkingRoom } = useContent('networkingroom');
	const { getContentTypeFields: getContentTypeFieldMediaItem } = useContent('mediaitem');

	const [isCreateSpaceLoading, setIsCreateSpaceLoading] = useState<boolean>(false);
	const [createSpaceFormValues, setCreateSpaceFormValues] = useState<ICreateSpaceFormValues>({ ...getCreateSpaceFormInitialValues() });
	const [createSpaceFormErrors, setCreateSpaceFormErrors] = useState<ICreateSpaceFormErrors>({});

	const [spaceToJoin, setSpaceToJoin] = useState<ILauncherSpace | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isJoinSpaceLoading, setIsJoinSpaceLoading] = useState<boolean>(false);
	const [isCheckSpaceIdLoading, setIsCheckSpaceIdLoading] = useState<boolean>(false);
	const [stepIndex, setStepIndex] = useState<number>(0);
	const [selectedExampleContentTypes, setSelectedExampleContentTypes] = useState<TPossibleContentType[]>([]);
	const [customColor, setCustomColor] = useState<string>('');

	const [isSpacesLoading, setIsSpacesLoading] = useState<boolean>(false);
	const [isOwnSpacesLoading, setIsOwnSpacesLoading] = useState<boolean>(false);
	const [ownSpaces, setOwnSpaces] = useState<ILauncherSpace[]>([]);
	const [suggestedSpaces, setSuggestedSpaces] = useState<ILauncherSpace[]>([]);
	const [loaded, setIsLoaded] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const loadSuggestedSpaces = useRematchDispatch((dispatch: Dispatch) => dispatch.space.loadSuggestedSpaces);
	const createVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.createVote);
	const joinSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.joinSpace);
	const createInitialMailTemplates = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createInitialMailTemplates);
	const createSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.createSpace);
	const getIsSpaceIdAvailable = useRematchDispatch((dispatch: Dispatch) => dispatch.space.getIsSpaceIdAvailable);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	const {
		createExpo,
		createNews,
		createSchedule,
		createSpeaker,
		createStage,
		createNetworkingRoom,
		createStream,
		createMediaItem,
		createMap
	} = useRematchDispatch((dispatch: Dispatch) => dispatch.content);

	useEffect(() => {
		if (profile && !loaded) {
			setIsLoaded(true);
			_loadOwnSpaces();
		}
	}, [profile]);

	useEffect(() => {
		_loadSpaces();
	}, [createSpaceFormValues.language]);

	const _loadSpaces = async () => {
		setIsSpacesLoading(true);
		let _suggestedSpaces: ILauncherSpace[] = [];

		_suggestedSpaces = await loadSuggestedSpaces({ language: createSpaceFormValues?.language });

		setSuggestedSpaces(_suggestedSpaces);
		setIsSpacesLoading(false);
	};

	const _loadOwnSpaces = async () => {
		setIsOwnSpacesLoading(true);
		let _ownSpaces: ILauncherSpace[] = [];

		if (profile?.adminInSpaces && profile?.adminInSpaces?.length > 0) {
			for (const ownSpace of profile?.adminInSpaces) {
				const space = await findSpaceById({ spaceId: ownSpace.spaceId, noToast: true });
				if (space && !space.isDeleted && !space.disabledBySuperAdmin && !isEmptyString(space.title)) {
					_ownSpaces.push(space);
				}
			}
		}

		setOwnSpaces(_ownSpaces);
		setIsOwnSpacesLoading(false);
	};

	useEffect(() => {
		if (stepIndex < 4) {
			const _selected: TPossibleContentType[] = createSpaceFormValues.features?.list?.filter((i) => i.isActive)?.map((l) => l.key);
			if (_selected && _selected?.length > 0) {
				setSelectedExampleContentTypes([..._selected]);
			}
		}
	}, [createSpaceFormValues]);

	const _isSpaceIdTaken = async (spaceID: string) => {
		setIsCheckSpaceIdLoading(true);
		const errors = await validateForm(getCreateSpaceFormSchema({ ...createSpaceFormValues, spaceId: spaceID }), {
			...createSpaceFormValues,
			spaceId: spaceID
		});

		if (errors) {
			setCreateSpaceFormErrors(errors);
			return true;
		} else {
			setCreateSpaceFormErrors({});
		}
		const res = await getIsSpaceIdAvailable({ spaceID: spaceID });

		if (res === false) {
			setCreateSpaceFormErrors({ spaceId: t('Space.create.spaceId.taken') });
			setIsCheckSpaceIdLoading(false);
			return true;
		} else {
			navigation.setParams({
				spaceTitle: !isEmptyString(createSpaceFormValues?.title) ? createSpaceFormValues?.title : spaceID
			});
		}

		setIsCheckSpaceIdLoading(false);
		return false;
	};

	const _createVotings = async (space: ILauncherSpace) => {
		const types: TVotingType[] = ['applause', 'multipleChoice', 'nps', 'quiz', 'rating', 'scale', 'survey', 'text', 'wordCloud'];
		const promises = types.map((type) => {
			const createVotePayload: ICreateVotePayload = {
				data: {
					space: space,
					publicId: v4(),
					question: getDefaultVotingQuestion(type),
					hint: getDefaultVotingHint(type),
					isActive: true,
					votingType: type,
					minValue: type === 'multipleChoice' || type === 'wordCloud' ? 1 : 0,
					maxValue: type === 'multipleChoice' ? 1 : type === 'rating' ? 5 : 10,
					options: getDefaultVotingOptions(type),
					optionsVote: getDefaultOptionsVote(type),
					minValueText: type === 'nps' ? t('notLikely') : undefined,
					maxValueText: type === 'nps' ? t('veryLikely') : undefined,
					requestReason: type === 'nps' ? true : undefined,
					requestReasonText: type === 'nps' ? t('reasonTextDefault') : undefined,
					requestReasonOptions: type === 'nps' ? JSON.stringify([0, 1, 2, 3, 4, 5, 6, 7]) : undefined
				},
				noToast: true
			};
			createVote(createVotePayload);
		});
		await Promise.all(promises);
	};

	const _createContent = async (space: ILauncherSpace, exampleContent?: TPossibleContentType[]) => {
		const schedules: ISchedule[] = [];
		let types = exampleContent ?? selectedExampleContentTypes;
		types = types.sort((a, b) => {
			if (a === 'schedules') {
				-1;
			}
			return 0;
		});

		let speaker;

		const promises = types.map(async (type) => {
			switch (type) {
				case 'votings':
					await _createVotings(space);
					break;
				case 'expos':
					const expoExamples = getExpoExamples();
					for (const expoExample of expoExamples) {
						await createExpo({
							space,
							expo: expoExample,
							fields: getContentTypeFieldsExpo(),
							noToast: true
						});
					}
					break;
				case 'feed':
					const feedExamples = getFeedExamples();
					for (const feedExample of feedExamples) {
						await createNews({
							space,
							news: feedExample,
							noToast: true
						});
					}
					break;
				case 'networking':
					const networkingExamples = getNetworkingExample();
					for (const networkingExample of networkingExamples) {
						await createNetworkingRoom({
							space,
							networkingroom: networkingExample,
							fields: getContentTypeFieldsNetworkingRoom(),
							noToast: true
						});
					}
					break;
				case 'schedules':
					const exampleStage = getStageExample();
					const scheduleExamples = getSchedulesExample(createSpaceFormValues);
					const stage = await createStage({
						space: space,
						stage: exampleStage,
						fields: getContentTypeFieldsStage(),
						noToast: true
					});
					for (const scheduleExample of scheduleExamples) {
						let schedule = { ...scheduleExample, stage: stage?.id };
						if (speaker) {
							schedule = { ...schedule, speakers: [speaker.id] };
						}
						const scheduleRes = await createSchedule({
							space,
							schedule: schedule,
							fields: getContentTypeFieldsSchedule(),
							noToast: true
						});
						if (scheduleRes) {
							schedules.push(scheduleRes);
						}
					}
					break;
				case 'speakers':
					const speakersExamples = getSpeakerExample();
					for (const speakerExample of speakersExamples) {
						speaker = await createSpeaker({
							space,
							speaker: { ...speakerExample, schedules: schedules.map((e) => e.id) },
							fields: getContentTypeFieldsSpeaker(),
							noToast: true
						});
					}
					break;
				case 'mediaitems':
					const mediaItemExamples = getMediaItemExample();
					for (const mediaItemExample of mediaItemExamples) {
						await createMediaItem({
							space,
							mediaitem: { ...mediaItemExample },
							fields: getContentTypeFieldMediaItem(),
							noToast: true
						});
					}
					break;
				case 'maps':
					const mapExamples = getMapExample();
					for (const mapExample of mapExamples) {
						await createMap({
							space,
							map: { ...mapExample },
							noToast: true
						});
					}
				default:
					break;
			}
		});
		await Promise.all(promises);
	};

	const _createStreams = async (space: ILauncherSpace) => {
		const streamExamples = getStreamExample();

		const promises = streamExamples.map(async (streamExample) => {
			return createStream({
				stream: streamExample,
				noToast: true,
				space
			});
		});

		await Promise.all(promises);
	};

	const _createCustomSpace = async () => {
		const allowedTypes: TPossibleContentType[] = [
			'schedules',
			'speakers',
			'expos',
			'feed',
			'networking',
			'votings',
			'mediaitems',
			'maps'
		];

		const spaceData = { ...createSpaceFormValues };

		const hasMeetingsActive = spaceData.features?.list.find((e) => e.isActive && e.key === 'meetings');
		if (hasMeetingsActive) {
			const allowChatsField = spaceData.registrationFields?.fields?.find((e) => e.fieldName === 'allowChats');
			if (allowChatsField) {
				allowChatsField.visibility = 'always';
			}
			const allowMeetingRequestsField = spaceData.registrationFields?.fields?.find((e) => e.fieldName === 'allowMeetingRequests');
			if (allowMeetingRequestsField) {
				allowMeetingRequestsField.visibility = 'always';
			}
		}

		const hasChatsActive = spaceData.features?.list.find((e) => e.isActive && e.key === 'chats');
		if (hasChatsActive) {
			const allowChatsField = spaceData.registrationFields?.fields?.find((e) => e.fieldName === 'allowChats');
			if (allowChatsField) {
				allowChatsField.visibility = 'always';
			}
		}

		setCreateSpaceFormValues(spaceData);

		spaceData.registrationFields = { fields: [...getDefaultRegistrationFields()] };
		spaceData.expoFields = {
			fields: [...getDefaultExpoFields()]
		};
		spaceData.speakerFields = {
			fields: [...getDefaultSpeakerFields()]
		};
		spaceData.ticketFields = {
			fields: [...getDefaultTicketFields()]
		};
		const _selectedExampleContentTypes = selectedExampleContentTypes.filter((content) => allowedTypes.find((type) => content === type));

		const res = await createSpace({ data: spaceData, noToast: true });

		if (res) {
			setSpaceToJoin(res);

			await createInitialMailTemplates({ space: res });

			if (_selectedExampleContentTypes.length > 0) {
				await _createContent(res, _selectedExampleContentTypes);
			}

			await _createStreams(res);
		}

		return res;
	};

	const _createCloneSpace = async () => {
		const spaceData = { ...createSpaceFormValues };
		const res = await createSpace({ data: spaceData, noToast: true });
		if (res) {
			setSpaceToJoin(res);
		}
		return res;
	};

	const _createSpace = async () => {
		setIsLoading(true);

		let res;

		if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) {
			res = await _createCloneSpace();
		} else {
			res = await _createCustomSpace();
		}

		setIsLoading(false);

		if (res) {
			return false;
		}

		return true;
	};

	const updateFormValues = (val) => {
		if (!route?.params?.prohibitNavigation) {
			navigation.setParams({ ...route?.params, prohibitNavigation: true });
		}
		setCreateSpaceFormValues(val);
	};

	const _getIncudesExamplesTypes = () => {
		const featureList = createSpaceFormValues.features?.list?.filter((i) => i.isActive)?.map((l) => l.key);
		if (featureList) {
			return (
				featureList.includes('expos') ||
				featureList.includes('feed') ||
				featureList.includes('schedules') ||
				featureList.includes('votings') ||
				featureList.includes('speakers') ||
				featureList.includes('mediaitems') ||
				selectedExampleContentTypes.includes('networking')
			);
		}
		return false;
	};

	const _onNext = async (currentIndex: number) => {
		switch (currentIndex) {
			case 0:
				const newSpaceId = createSpaceFormValues?.title
					?.replace(/\s/g, '')
					?.replace(/[^a-zA-Z0-9 ]/g, '')
					?.substring(0, 11)
					?.toLowerCase();
				updateFormValues({
					...createSpaceFormValues,
					spaceId: newSpaceId
				});
				_isSpaceIdTaken(newSpaceId);
				return false;
			case 3:
				if (!_getIncudesExamplesTypes()) {
					setStepIndex(5);
					return true;
				}
				return false;
			case 5:
				if (isEmptyString(createSpaceFormValues?.cloneSpaceId)) return false;

				_createSpace();
				return false;
			case 6:
				_createSpace();
				return false;
			default:
				return false;
		}
	};

	const _onBack = async (currentIndex: number) => {
		switch (currentIndex) {
			case 2:
				setTheme(DEFAULTTHEME);
				return false;
			default:
				return false;
		}
	};

	const _onSubmit = async () => {
		if (spaceToJoin?.spaceId && spaceToJoin?.id) {
			setIsJoinSpaceLoading(true);
			await joinSpace({ space: spaceToJoin });
			onFinish();
			setIsJoinSpaceLoading(false);
		}
	};

	const _checkDateFields = () => {
		if (moment(createSpaceFormValues.endDate).isSameOrBefore(moment(createSpaceFormValues.startDate))) {
			return true;
		}

		if (moment(createSpaceFormValues.endDate).isBefore(moment())) {
			return true;
		}

		// if date includes a letter

		return false;
	};

	const _isNextButtonDisabled = () => {
		switch (stepIndex) {
			case 0:
				return (
					isEmptyString(createSpaceFormValues.title) ||
					createSpaceFormValues?.title?.length < 6 ||
					isEmptyString(createSpaceFormValues.startDate) ||
					isEmptyString(createSpaceFormValues.endDate) ||
					_checkDateFields()
				);
			case 1:
				return false;
			case 2:
				return true;
			case 3:
				return isEmptyString(customColor);
			case 4:
				if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) {
					return true;
				}

				return (
					!createSpaceFormValues.features ||
					createSpaceFormValues.features.list.filter((e) => e.isActive).length === 0 ||
					(createSpaceFormValues.features?.tabbarCount && createSpaceFormValues.features.tabbarCount > MAXTABCOUNT)
				);
			case 5:
				if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) {
					return !isEmptyObject(createSpaceFormErrors) || !createSpaceFormValues.spaceId || isCheckSpaceIdLoading;
				}
				return true;
			case 6:
				return !isEmptyObject(createSpaceFormErrors) || !createSpaceFormValues.spaceId || isCheckSpaceIdLoading;
			default:
				return false;
		}
	};

	const _getButtonText = () => {
		switch (stepIndex) {
			case 1:
				return t('emptySpaceDoPreceedWithoutSettingSpace');
			case 5:
				if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) return t('createSpaceNow');
				return undefined;
			case 6:
				if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) return t('Join Space');
				return t('createSpaceNow');
			case 7:
				return t('Join Space');

			default:
				return undefined;
		}
	};

	const _getSpaceWizardSteps = () => {
		if (!isEmptyString(createSpaceFormValues?.cloneSpaceId)) {
			return [
				<SpaceWizardContent
					testID={TESTIDPREFIX}
					features={createSpaceFormValues.features?.list?.filter((i) => i.isActive)?.map((l) => l.key)}
					setSelectedContentTypes={(examples) => setSelectedExampleContentTypes(examples)}
					setStepIndex={(val) => setStepIndex(val)}
					stepIndex={stepIndex}
					isClone
					onChange={(cloneData) => setCreateSpaceFormValues({ ...createSpaceFormValues, cloneData })}
				/>
			];
		}

		return [
			<SpaceWizardFeatures
				testID={TESTIDPREFIX}
				isLoading={isLoading}
				features={createSpaceFormValues.features}
				onChange={(features) => setCreateSpaceFormValues({ ...createSpaceFormValues, features })}
			/>,
			<SpaceWizardContent
				testID={TESTIDPREFIX}
				features={createSpaceFormValues.features?.list?.filter((i) => i.isActive)?.map((l) => l.key)}
				setSelectedContentTypes={(examples) => setSelectedExampleContentTypes(examples)}
				setStepIndex={(val) => setStepIndex(val)}
				stepIndex={stepIndex}
			/>
		];
	};

	return (
		<StepWizard
			testIdPrefix={TESTIDPREFIX}
			getCurrentIndex={(val) => {
				navigation.setParams({ ...route?.params, currentIndex: val });
				setStepIndex(val);
			}}
			customCurrentIndex={stepIndex}
			onNext={(currentIndex) => _onNext(currentIndex)}
			onBack={(currentIndex) => _onBack(currentIndex)}
			completeFunction={() => _onSubmit()}
			nextButtonText={_getButtonText()}
			isLoading={isLoading || isJoinSpaceLoading}
			isNextButtonDisabled={_isNextButtonDisabled()}
			hideBackButtonOnLastStep
			components={[
				<CreateSpaceForm
					values={createSpaceFormValues}
					errors={createSpaceFormErrors}
					onChange={(val) => updateFormValues(val)}
					isDisabled={isCreateSpaceLoading}
					testID={TESTIDPREFIX}
				/>,
				<SpaceWizardTemplate
					setTempSpace={(val) => updateFormValues(val)}
					space={createSpaceFormValues}
					testID={TESTIDPREFIX}
					setStepIndex={(val) => setStepIndex(val)}
					stepIndex={stepIndex}
					ownSpaces={ownSpaces}
					suggestedSpaces={suggestedSpaces}
					isSpacesLoading={isSpacesLoading}
					isOwnSpacesLoading={isOwnSpacesLoading}
				/>,
				<SpaceWizardTheme
					setTempSpace={(val) => updateFormValues(val)}
					space={createSpaceFormValues}
					testID={TESTIDPREFIX}
					setStepIndex={(val) => setStepIndex(val)}
					stepIndex={stepIndex}
				/>,
				<SpaceWizardColors
					setTempSpace={(val) => updateFormValues(val)}
					space={createSpaceFormValues}
					testID={TESTIDPREFIX}
					customColor={customColor}
					setCustomColor={(val) => setCustomColor(val)}
				/>,
				..._getSpaceWizardSteps(),
				<SpaceWizardSpaceId
					values={createSpaceFormValues}
					errors={createSpaceFormErrors}
					onChange={(val) => updateFormValues(val)}
					isDisabled={isCreateSpaceLoading}
					testID={TESTIDPREFIX}
					isSpaceIdTaken={(val) => _isSpaceIdTaken(val)}
				/>,
				<SpaceWizardDone
					isLoading={isLoading}
					isJoinSpaceLoading={isJoinSpaceLoading}
					spaceId={createSpaceFormValues.spaceId}
					spaceTitle={createSpaceFormValues.title}
					isCloneData={!isEmptyString(createSpaceFormValues?.cloneSpaceId) && createSpaceFormValues?.cloneData}
				/>
			]}
		/>
	);
};
