import { SpaceSummary } from 'components/SpacePreview';
import { ILauncherSpace } from 'config/interfaces';
import React from 'react';
import { HSModal } from '../Modal';

interface ISpacePreviewModal {
	isVisible: boolean;
	onClose: () => void;
	space?: ILauncherSpace;
}

export const SpacePreviewModal = (props: ISpacePreviewModal) => {
	const { isVisible, onClose, space } = props;

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			{space && (
				<SpaceSummary
					spaceId={space.spaceId}
					space={space}
					hideButtons
					backgroundBorderRadius
					contentContainerStyle={{ width: '100%' }}
					isPreview
				/>
			)}
		</HSModal>
	);

	return null;
};
