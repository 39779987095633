import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ScrollView } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpaceHistoryList } from 'components/SpaceHistory';
import { showToast } from 'helper/toast';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { ISpaceHistory } from 'rematch/interfaces';
import { hsTopScreenPadding } from 'config/styleConstants';
import moment from 'moment';
import { t } from 'i18next';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.MySpaces>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.MySpaces>;
type RouteParams = NoSpaceStackParamList[ERoutes.MySpaces];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface ISpaceData {
	title: string;
	data: ISpaceHistory[];
}

export const MySpacesScreen = ({ route, navigation }: Props) => {
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const { t } = useTranslation();
	const { screenWidth, screenColumnCount } = useQuery();
	const { theme } = useTheme();

	const [spaces, setSpaces] = useState<ISpaceData[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const loadProfile = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.loadProfile);

	useEffect(() => {
		loadProfile({});
	}, []);

	useEffect(() => {
		if (!profile || (profile?.adminInSpaces?.length === 0 && profile?.moderatorInSpaces?.length === 0)) {
			navigation.goBack();
		} else {
			let _spaces: ISpaceData[] = [];
			if (profile) {
				if (profile?.adminInSpaces?.length ?? 0 > 0) {
					_spaces.push({
						title: t('Admin'),
						data: profile?.adminInSpaces
							?.filter((e) => !e.isDeleted)
							?.map((s) => {
								return { space: s, date: '' };
							})
					});
				}
				if (profile?.moderatorInSpaces?.length ?? 0 > 0) {
					const _moderatorInSpaces = profile?.moderatorInSpaces?.filter(
						(e) => !e.isDeleted && !profile.adminInSpaces?.map((s) => s.spaceId)?.includes(e.spaceId)
					);
					_spaces.push({
						title: t('Moderator'),
						data: _moderatorInSpaces?.map((s) => {
							return { space: s, date: '' };
						})
					});
				}
			}

			_spaces = sortSpaces(_spaces);

			setSpaces([..._spaces]);
		}
	}, [profile]);

	const sortSpaces = (list: ISpaceData[]) => {
		let spaces = list;

		spaces = list.map((space) => {
			space.data.sort((a, b) => {
				let aVal = moment(a.space.endDate);
				let bVal = moment(b.space.endDate);
				return aVal.isBefore(bVal) ? -1 : 1;
			});
			return space;
		});

		spaces.forEach((space) => {
			space.data.forEach((data) => {
				let endDate = moment(data.space.endDate);
				let now = moment();
				if (endDate.isBefore(now)) {
					space.data = space.data.filter((item) => {
						return item != data;
					});
					space.data.push(data);
				}
			});
		});

		return spaces;
	};

	const _findSpaceById = async (id: string) => {
		setIsLoading(true);
		const res = await findSpaceById({ spaceId: id });
		setIsLoading(false);
		if (res) {
			const foundAdmin = res.admins?.find((a) => a.id === profile?.id);
			const foundMod = res.admins?.find((a) => a.id === profile?.id);
			if (res.isDeactivated && !foundAdmin && !foundMod) {
				if (foundAdmin) {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitleAdmin'));
				} else {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
				}
			} else {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: res.spaceId });
			}
		}
	};

	const _renderContent = () => {
		if (spaces?.length > 0) {
			return (
				<ScrollView
					contentContainerStyle={{
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: hsTopScreenPadding,
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					{spaces.map((s) => {
						if (s.data?.length > 0) {
							return (
								<SpaceHistoryList
									disableScrolling
									label={s.title}
									list={s.data}
									onItemPress={(item) => _findSpaceById(item)}
									key={s.title}
									sortBy={'date'}
								/>
							);
						}
						return null;
					})}
				</ScrollView>
			);
		}
		return null;
	};

	return <ScreenContainer>{_renderContent()}</ScreenContainer>;
};

export const MySpacesScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={t('My Spaces')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
