import { MULTISPACE_LOCAL_URL, MULTISPACE_LOCAL_URL_ANDROID } from 'react-native-dotenv';

import { IS_ANDROID } from 'helper/platform';
import { ISponsorBlock } from './interfaces';
import { DEFAULT_MULTISPACE_URL } from './envConstants';

export const MULTISPACEURL = __DEV__ ? (IS_ANDROID ? MULTISPACE_LOCAL_URL_ANDROID : MULTISPACE_LOCAL_URL) : DEFAULT_MULTISPACE_URL;

export const IMPRINTURL = 'https://nocoderocks.com/privacy-policy/';
export const DATAPROTECTIONURL = 'https://nocoderocks.com/privacy-policy/';
export const CREATESPACEURL = 'https://hellospaces.de?target=createyourwon';
export const AGBURL = 'https://hellospaces.de/agb';
export const HELLOSPACES_USERS = ['johannes@hellospaces.de', 'christian@hellospaces.de', 'sven@hellospaces.de'];

export const SCROLL_PADDING_FLOATING = 50;
export const BOTTOM_SHEET_BOTTOM_PADDING = 30;
export const SPONSOR_FREQUENCY = 8;

export const SPONSOR_BLOCK: ISponsorBlock = {
	id: 0,
	title: 'SponsorBlock'
};

const version = 26;

export const DISPLAY_TOAST_ON_CONNECTION_CHANGE = false;
export const SHOW_CHEVRON_IN_LIST_ITEM = false;

export enum EForumLinks {
	Matchings = 'https://forum.hellospaces.de/t/matching-von-teilnehmern/65',
	Markdown = 'https://forum.hellospaces.de/t/markdown-verwenden/97'
}

export const maxCategoryOptions = 100;

export enum EDataLoadStrategy {
	Default = 'default',
	Socket = 'socket',
	Offline = 'offline'
}

export const DATA_LOAD_STRATEGY: EDataLoadStrategy = EDataLoadStrategy.Socket;

export const HsZoomProxyUrl = 'https://zoomclient.hspx.de';

export const TABBAR_HEIGHT = 60;

export const EXPO_DETAIL_IMAGEWIDTH = 130;
export const EXPO_DETAIL_IMAGEWIDTH_WEB = 230;
