import { Avatar, TAvatarSize } from 'components/User';

import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { ISpeaker } from 'config/interfaces';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { ListItemMatchCount } from 'components/Matching';

export const SPEAKERLISTITEMENTRYHEIGHT = 80;

export interface ISpeakerListItem {
	testID: string;
	item: ISpeaker;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
	isMailReceiver?: boolean;
	avatarSize?: TAvatarSize;
	height?: number;
}

export const SpeakerListItem = (props: ISpeakerListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, isLoading, isMailReceiver, avatarSize, height } = props;
	const { theme } = useTheme();

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isLoading}
						/>
					)}
				</View>
			);
		}

		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	if (!item) {
		return null;
	}

	return (
		<ChildButton
			testID={testID}
			style={{ height: height ?? SPEAKERLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
			isDisabled={!onPress}
			onPress={isMailReceiver ? onSelect : onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}
			{isMailReceiver ? (
				<ChildButton testID={`${testID}_avatar`} onPress={onPress}>
					<Avatar avatarObj={item.image} fullName={item.title} size={onSelect ? 'listItemEdit' : avatarSize ?? 'listItem'} />
				</ChildButton>
			) : (
				<Avatar
					testID={`${testID}_avatar`}
					avatarObj={item.image}
					fullName={item.title}
					size={onSelect ? 'listItemEdit' : avatarSize ?? 'listItem'}
				/>
			)}
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<View style={{ flexDirection: 'row', flex: 1 }}>
						<Text bold style={{ marginRight: item.isHidden ? 5 : undefined }}>
							{`${item.speakerTitle ? `${item.speakerTitle} ` : ''}${item.title}`}
						</Text>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					<ListItemMatchCount item={item} contentType="speaker" />
				</View>
				{!isEmptyString(item.jobTitle) && <Text style={{ fontSize: 12 }}>{item.jobTitle}</Text>}
				{!isEmptyString(item.company) && <Text style={{ fontSize: 12 }}>{item.company}</Text>}
			</View>
			{_renderActions()}
		</ChildButton>
	);
};
