import { ICreateWebhookFormValues, IUpdateWebhookFormValues } from 'config/interfaces';
import { isEmptyString } from 'helper';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getMinOptionsError, getRequiredError, getWebsiteError } from './errors';

export const getCreateWebhookFormSchema = (values: ICreateWebhookFormValues) => {
	return Yup.object().shape({
		title: Yup.string()
			.required(getRequiredError(i18next.t('Title')))
			.nullable(),
			url: Yup.string().url(getWebsiteError(values.url)).required('URL').nullable(),
			headers: Yup.string().test('atLeastOneEvent', 'Incorrect JSON', () => {
				if(!values.header || isEmptyString(values.header)) {
					return true
				}

				try {
					JSON.parse(values.header)
					return true
				} catch (error) {
					return false
				}
			}).nullable(),
		events: Yup.string()
			.test('atLeastOneEvent', getMinOptionsError(1), () => {
				if (!values.events || isEmptyString(values.events)) {
					return false;
				}

				const splitted = values.events.split(',');

				return splitted.length > 0;
			})
			.nullable()
	});
};

export const getUpdateWebhookFormSchema = (values: IUpdateWebhookFormValues) => {
	return Yup.object().shape({
		title: Yup.string()
			.required(getRequiredError(i18next.t('Title')))
			.nullable(),
		url: Yup.string().url(getWebsiteError(values.url)).required('URL').nullable(),
		headers: Yup.string().test('atLeastOneEvent', 'Incorrect JSON', () => {
			if(!values.header || isEmptyString(values.header)) {
				return true
			}

			try {
				JSON.parse(values.header)
				return true
			} catch (error) {
				return false
			}
		}).nullable(),
		events: Yup.string()
			.test('atLeastOneEvent', getMinOptionsError(1), () => {
				if (!values.events || isEmptyString(values.events)) {
					return false;
				}

				const splitted = values.events.split(',');

				return splitted.length > 0;
			})
			.nullable()
	});
};
