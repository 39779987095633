import React from 'react';
import { View, ViewStyle } from 'react-native';
import { TFunctionResult } from 'i18next';

import { H1 } from './H1';
import { EDefaultIconSet } from 'helper';
import { RoundButton } from 'components/Button/RoundButton';
import { H2 } from './H2';

interface ISectionHeader {
	label: string;
	action?: {
		label: string | TFunctionResult;
		onPress?: () => void;
		testID: string;
	};
	style?: ViewStyle;
	isLoading?: boolean;
}

export const SectionHeader = (props: ISectionHeader) => {
	const { label, action, style, isLoading } = props;

	// old code, remove if not used anywhere else!
	if (action && action.onPress) {
		return (
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: 5,
					...style
				}}
			>
				<H1>{label}</H1>
				<RoundButton
					testID={`${action.testID}`}
					isDisabled={!action.onPress || isLoading}
					onPress={action.onPress}
					icon={EDefaultIconSet.Edit}
				/>
			</View>
		);
	}

	return (
		<H2 center style={[{ fontSize: 18 }, style]}>
			{label}
		</H2>
	);
};
