import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet, getCardContainerStyle } from 'helper';
import { IWebhook } from 'config/interfaces';
import { NoData } from 'components/NoData';
import { Placeholder, PlaceholderMedia, ShineOverlay, PlaceholderLine } from 'rn-placeholder';
import { FlashList } from 'components/List';
import { WebhookListItem, WEBHOOKLISTITEM_SIZE } from 'components/Webhook';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Webhooks>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Webhooks>;
type RouteParams = StackParamList[ERoutes.Webhooks];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'webhooks';

export const WebhooksListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth, screenColumnCountSmallItems } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [spaceWebhooks, setSpaceWebhooks] = useState<IWebhook[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<number[]>([]);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);

	const bottomSheetRef = useRef<RBSheet>(null);

	const webhooks = useSelector((store: IRootState) => store.content.webhooks);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		let _hooks: typeof spaceWebhooks = [];

		if (webhooks && activeSpace) {
			_hooks = webhooks.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setSpaceWebhooks(_hooks);
	}, [activeSpace, webhooks]);

	const _delete = async (itemId?: number) => {
		const singleItem = spaceWebhooks.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(itemId ? [itemId] : [...selectedEntries]);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'webhook' });
						setIsDeleteLoading([]);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.WebhookCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			default:
				break;
		}
	};

	const _renderEmptyElements = () => {
		const emptyListLength = 10;
		const emptyList: ReactNode[] = [];

		for (let index = 0; index < emptyListLength; index++) {
			emptyList.push(
				<View key={`emptylist_element_${index}`} style={{ height: 100 }}>
					<Placeholder Left={PlaceholderMedia} Animation={ShineOverlay}>
						<PlaceholderLine />
						<PlaceholderLine width={50} />
					</Placeholder>
				</View>
			);
		}

		return emptyList;
	};

	const _onSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _renderList = () => {
		if (spaceWebhooks.length === 0 || !screenColumnCountSmallItems) {
			if (waitingForSocketResponse?.webhooks) {
				return _renderEmptyElements();
			}
			return <NoData type="NoWebhooks" />;
		}

		return (
			<FlashList
				data={spaceWebhooks}
				extraData={[selectedEntries, isDeleteLoading, _delete]}
				renderItem={(renderItem) => (
					<View style={getCardContainerStyle(screenColumnCountSmallItems, renderItem.index)}>
						<WebhookListItem
							testID={`${TESTIDPREFIX}_webhook_${renderItem.index}`}
							onPress={() =>
								navigation.navigate(ERoutes.WebhookHistory, { id: renderItem.item.id, spaceId: activeSpace?.spaceId })
							}
							item={renderItem.item}
							isSelected={selectedEntries.includes(renderItem.item.id)}
							onSelect={isEditMode ? () => _onSelect(renderItem.item.id) : undefined}
							onEdit={
								isEditMode
									? () =>
											navigation.navigate(ERoutes.WebhookUpdate, {
												id: renderItem.item.id,
												spaceId: activeSpace?.spaceId
											})
									: undefined
							}
							isDeleteLoading={isDeleteLoading.includes(renderItem.item.id)}
							isLoading={isDeleteLoading.length > 0}
							onDelete={isEditMode ? () => _delete(renderItem.item.id) : undefined}
						/>
					</View>
				)}
				estimatedItemSize={WEBHOOKLISTITEM_SIZE}
				numColumns={screenColumnCountSmallItems}
			/>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="webhooks">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderList()}
			</ScrollView>
			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					isDisabled={isDeleteLoading.length > 0}
					onPress={() => _handleActionSheetPress('add')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_editmode`}
					icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
					label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
					isDisabled={isDeleteLoading.length > 0}
					onPress={() => _handleActionSheetPress('editMode')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
					isDisabled={selectedEntries.length === 0}
					isLoading={isDeleteLoading.length > 0}
					onPress={() => _handleActionSheetPress('delete')}
					iconColor={theme.danger}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const WebhooksListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title="Webhooks" />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
