import { RoundButton } from 'components/Button';
import { FormTagCloud } from 'components/Form/FormTagCloud';
import { IRegistrationField } from 'config/interfaces';
import { EDefaultIconSet } from 'helper/icon';
import { isEmptyString } from 'helper/string';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';

interface ICategoryFilterModal {
	title?: string;
	isVisible: boolean;
	activeFilters: Record<string, string>;
	onClose: () => void;
	onSubmit: (newFilters: Record<string, string>) => void;
	categoryFields: IRegistrationField[];
}

const TESTIDPREFIX = 'categoryfiltermodal';

export const CategoryFilterModal = (props: ICategoryFilterModal) => {
	const { title, isVisible, activeFilters, onClose, onSubmit, categoryFields } = props;
	const { t } = useTranslation();

	const [tempValue, setTempValue] = useState<Record<string, string>>({});
	const [isDirty, setIsDirty] = useState(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (isVisible) {
			setTempValue({ ...activeFilters });
		} else {
			setTempValue({});
			setIsDirty(false);
		}
	}, [isVisible]);

	const _updateValue = (newValue: Record<string, string>) => {
		setTempValue(newValue);
		setIsDirty(true);
	};

	const _renderTagCloud = (field: IRegistrationField) => {
		return (
			<FormTagCloud
				key={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
				options={field.options}
				label={field.fieldLabel}
				testID={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
				value={tempValue[field.fieldName]}
				onChange={(val) => _updateValue({ ...tempValue, [field.fieldName]: val })}
			/>
		);
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={() => {
				let _val = { ...tempValue };
				Object.keys(_val).forEach((key) => {
					if (isEmptyString(_val[key])) {
						delete _val[key];
					}
				});
				onSubmit(_val);
			}}
			title={title}
		>
			<RoundButton
				testID={`${TESTIDPREFIX}_button_clearfilter`}
				onPress={() => _updateValue({})}
				icon={EDefaultIconSet.Close}
				isOutline
				size="sm"
				title={t('ClearAllFilter')}
			/>
			<ScrollView>{categoryFields.map((field) => _renderTagCloud(field))}</ScrollView>
		</HSModal>
	);
};
