import React from 'react';
import { ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { MediaTypeOptions } from 'expo-image-picker';

import { FormTextInput } from 'components/Form/FormTextInput';
import { ICreateStreamFormErrors, ICreateStreamFormValues, IUpdateStreamFormErrors, IUpdateStreamFormValues } from 'config/interfaces';
import { StreamInputVimeo } from './StreamInputVimeo';
import { StreamInputYouTube } from './StreamInputYouTube';
import { StreamInputIFrame } from './StreamInputIFrame';
import { StreamInputUpload } from './StreamInputUpload';
import { StreamInputMediaLibrary } from './StreamInputMediaLibrary';
import { ContentFlowList } from 'components/Contentflow';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { FormMediaPicker } from 'components/Form/Image';
import { StreamInputExternalMeeting } from './StreamInputExternalMeeting';
import { CountryDropdown } from 'components/Form/CountryDropdown';
import { IS_WEB } from 'helper';
import * as mime from 'react-native-mime-types';
import { StreamInputZoom } from './StreamInputZoom';
import { StreamInputNetworking } from './StreamInputNetworking';

interface IStreamInput {
	testID: string;
	values: ICreateStreamFormValues | IUpdateStreamFormValues;
	errors: ICreateStreamFormErrors | IUpdateStreamFormErrors;
	onChange: (value: ICreateStreamFormValues | IUpdateStreamFormValues) => void;
	hidePreviewImage?: boolean;
	isMediaItem?: boolean;
	isSelfService?: boolean;
	hideLanguage?: boolean;
}

export const StreamInput = (props: IStreamInput) => {
	const { testID, values, errors, onChange, hidePreviewImage, isMediaItem, isSelfService, hideLanguage } = props;
	const { t } = useTranslation();

	const _renderInput = () => {
		switch (values?.streamType) {
			case 'contentflow':
				return (
					<ContentFlowList
						testID={testID}
						type="all"
						selectedUrls={values?.contentflowUrl ? [values?.contentflowUrl] : []}
						onSelect={(item) => onChange({ ...values, contentflowUrl: item.url })}
					/>
				);

			case 'mediaLibrary':
				return (
					<StreamInputMediaLibrary
						testID={testID}
						value={values?.mediaitem}
						onChange={(mediaitem) => onChange({ ...values, mediaitem })}
					/>
				);
			case 'upload':
				return (
					<StreamInputUpload
						testID={testID}
						value={values?.media}
						onChange={(media) => onChange({ ...values, media })}
						mediaTypes={isMediaItem ? 'files' : undefined}
					/>
				);
			case 'iFrame':
				return (
					<StreamInputIFrame
						testID={testID}
						value={values?.iFrameUrl}
						error={errors?.iFrameUrl}
						onChange={(iFrameUrl) => onChange({ ...values, iFrameUrl })}
					/>
				);
			case 'vimeo':
				return (
					<StreamInputVimeo
						testID={testID}
						value={values?.vimeoUrl}
						error={errors?.vimeoUrl}
						onChange={(vimeoUrl) => onChange({ ...values, vimeoUrl })}
					/>
				);
			case 'youtube':
				return (
					<StreamInputYouTube
						testID={testID}
						value={values?.youTubeUrl}
						error={errors?.youTubeUrl}
						onChange={(youTubeUrl) => onChange({ ...values, youTubeUrl })}
					/>
				);
			case 'externalMeeting':
				return (
					<StreamInputExternalMeeting
						testID={testID}
						value={{
							externalMeetingUrl: values?.externalMeetingUrl ?? '',
							externalMeetingButtonText: values?.externalMeetingButtonText ?? ''
						}}
						error={errors?.externalMeetingUrl}
						onChange={(externalMeetingUrl) => onChange({ ...values, externalMeetingUrl })}
						onTitleChange={(externalMeetingButtonText) => onChange({ ...values, externalMeetingButtonText })}
					/>
				);
			case 'zoom':
				return (
					<StreamInputZoom
						testID={testID}
						value={{
							zoomMeetingUrl: values?.zoomMeetingUrl ?? '',
							zoomMeetingId: values?.zoomMeetingId ?? '',
							zoomMeetingPassword: values?.zoomMeetingPassword ?? ''
						}}
						error={{
							zoomMeetingUrl: errors?.zoomMeetingUrl ?? '',
							zoomMeetingId: errors?.zoomMeetingId ?? '',
							zoomMeetingPassword: errors?.zoomMeetingPassword ?? ''
						}}
						onChange={(field, value) => onChange({ ...values, [field]: value })}
					/>
				);
			case 'networking':
				return (
					<StreamInputNetworking
						testID={testID}
						value={values?.networkinRoomId}
						error={errors?.networkinRoomId}
						onChange={(networkinRoomId) => onChange({ ...values, networkinRoomId })}
					/>
				);
			default:
				return null;
		}
	};

	const _getType = () => {
		let mimeType: string | undefined = undefined;
		if (values?.streamType === 'upload') {
			if (typeof values?.media === 'object') {
			} else if (typeof values?.media === 'string') {
				if (IS_WEB) {
					const splitted = values?.media.split(';');
					mimeType = splitted[0]?.split(':')[1];
				} else {
					const match = /\.(\w+)$/.exec(values?.media);
					if (match && match[0]) {
						mimeType = mime.lookup(match[0]);
					}
				}
			}
		}

		return mimeType;
	};

	const _isUploadImage = () => {
		return values?.streamType && values?.streamType === 'upload' && _getType()?.includes('image');
	};

	const _renderLanguagePicker = () => {
		if (!_isUploadImage() && !isSelfService && !hideLanguage) {
			return (
				<CountryDropdown
					label={t('Language')}
					hint={t('CountryHint')}
					onSelect={(languages) => onChange({ ...values, languages })}
					testID={`${testID}_textinput_languages`}
					multi
					search
					value={values?.languages}
				/>
			);
		}

		return null;
	};

	return (
		<ScrollView style={{ marginHorizontal: -hsInnerPadding }} contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}>
			{values?.streamType && values?.streamType !== 'upload' && (
				<FormTextInput
					testID={`${testID}_textinput_title`}
					label={t('Title')}
					hint={isMediaItem ? t('VideoTitleHint') : t('StreamTitleHint')}
					isRequired
					value={values?.title}
					onChangeText={(title) => onChange({ ...values, title })}
				/>
			)}
			<View style={{ marginBottom: hsBottomMargin }}>{_renderInput()}</View>
			{!hidePreviewImage && (
				<FormMediaPicker
					testID={`${testID}_textinput_previewimage`}
					label={t('StreamPreviewImageLabel')}
					hint={t('StreamPreviewImageHint')}
					value={values?.previewImage}
					onChange={(previewImage) => onChange({ ...values, previewImage })}
					mediaTypes={MediaTypeOptions.Images}
					aspectRatio="16/9"
				/>
			)}
			{_renderLanguagePicker()}
		</ScrollView>
	);
};
