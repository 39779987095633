import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderMenuButton,
	NavigationHeaderPickAttendeeButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { FlatList, View } from 'react-native';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { IChatOverview, IFeatureInfo } from 'config/interfaces';
import { ChatOverviewListItem } from 'components/Chat';
import { HSCard } from 'components/Card';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { AuthenticationModal, PickAttendeeModal } from 'components/Modal';
import { NoData } from 'components/NoData';
import { RoundButton } from 'components/Button';
import { TABBAR_HEIGHT } from 'config/constants';
import { Placeholder, PlaceholderLine, PlaceholderMedia, ShineOverlay } from 'rn-placeholder';
import { showToast } from 'helper/toast';
import moment from 'moment';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ChatOverview>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ChatOverview>;
type RouteParams = StackParamList[ERoutes.ChatOverview];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'chatoverview';

export const ChatOverviewScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [overviews, setOverviews] = useState<IChatOverview[]>([]);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isPickAttendeeModalVisible, setIsPickAttendeeModalVisible] = useState<boolean>(false);
	const [lastReload, setLastReload] = useState<number>(0);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const chatMessages = useSelector((store: IRootState) => store.chat.chatMessages);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const loadChatMessagesDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.loadChatMessagesDelta);

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Chats')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onReloadPress: () => _reloadData()
		});
	}, [lastReload]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => setIsPickAttendeeModalVisible(!isPickAttendeeModalVisible)
		});
	}, [isPickAttendeeModalVisible]);

	useEffect(() => {
		if (activeSpace && chatMessages && profile && attendees.length > 0) {
			const _overviews: typeof overviews = [];
			chatMessages
				.filter((e) => e.spaceId === activeSpace.spaceId)
				.forEach((message) => {
					const partnerId = message.senderId === profile.userId ? message.receiverId : message.senderId;
					const found = _overviews.find((e) => e.messages[0].receiverId === partnerId || e.messages[0].senderId === partnerId);
					if (found) {
						found.messages.push(message);
					} else {
						const partner = attendees?.find((e) => e.userId === partnerId);

						_overviews.push({
							partner,
							messages: [message]
						});
					}
				});

			setOverviews(_overviews);
		}
	}, [activeSpace, attendees, chatMessages, profile]);

	const _reloadData = () => {
		const now = moment().unix();

		showToast('info', undefined, t('Reloading Chats'));

		if (now - lastReload > 10) {
			loadChatMessagesDelta({});
			setLastReload(now);
		}
	};

	const _renderEmptyChatEntry = () => {
		return (
			<Placeholder style={{ height: 70 }} Left={PlaceholderMedia} Animation={ShineOverlay}>
				<View style={{ flexDirection: 'row', marginBottom: hsBottomMargin / 2, justifyContent: 'space-between' }}>
					<PlaceholderLine width={50} />
					<PlaceholderLine width={20} />
				</View>
				<PlaceholderLine />
			</Placeholder>
		);
	};

	const _renderEmptyData = () => {
		return (
			<HSCard style={{ flex: 1 }}>
				{_renderEmptyChatEntry()}
				{_renderEmptyChatEntry()}
				{_renderEmptyChatEntry()}
			</HSCard>
		);
	};

	const _renderOverview = () => {
		if (profile) {
			if (activeSpace?.forceDoubleOptInForUsers && !profile.isUserConfirmed) {
				return (
					<NoData type="NotConfirmed">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_authenticate`}
							onPress={() => setIsAuthenticationModalVisible(true)}
							icon={EDefaultIconSet.User}
							title={t('Login/Register')}
							isStacked
						/>
					</NoData>
				);
			}
			if (!profile.allowChats) {
				return <NoData type="AllowChats" />;
			}

			if (profile.hideMyProfile) {
				return <NoData type="ProfileHidden" />;
			}

			if (overviews.length === 0) {
				if (waitingForSocketResponse?.chats) {
					return _renderEmptyData();
				}
				return (
					<NoData type="NoChats">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_chat`}
							onPress={() => setIsPickAttendeeModalVisible(true)}
							icon={EDefaultIconSet.Add}
							title={t('FindAttendee')}
							isStacked
						/>
					</NoData>
				);
			}

			return (
				<HSCard style={{ flex: 1, paddingHorizontal: IS_WEB ? 0 : EHorizontalScreenPadding.Wide }}>
					<FlatList
						data={overviews}
						renderItem={({ item }) => (
							<ChatOverviewListItem
								testID={`${TESTIDPREFIX}_${item.partner?.id}`}
								item={item}
								onPress={() => {
									if (item.partner) {
										navigation.navigate(ERoutes.Chat, {
											spaceId: activeSpace?.spaceId,
											userId: item.partner.userId
										});
									}
								}}
							/>
						)}
						keyExtractor={(item, index) => `${TESTIDPREFIX}_overview_${index}`}
						style={{ paddingHorizontal: IS_WEB ? EHorizontalScreenPadding.Wide : 0 }}
					/>
				</HSCard>
			);
		}

		return (
			<NoData type="NoProfile">
				<RoundButton
					testID={`${TESTIDPREFIX}_button_authenticate`}
					onPress={() => setIsAuthenticationModalVisible(true)}
					icon={EDefaultIconSet.Add}
					title={t('Login/Register')}
					isStacked
				/>
			</NoData>
		);
	};

	return (
		<ScreenContainer contentKey="chats">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				{_renderOverview()}
			</View>
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
			<PickAttendeeModal
				isVisible={isPickAttendeeModalVisible}
				onPress={(item) => {
					navigation.navigate(ERoutes.Chat, {
						spaceId: activeSpace?.spaceId,
						userId: item.userId
					});

					setIsPickAttendeeModalVisible(false);
				}}
				onClose={() => setIsPickAttendeeModalVisible(false)}
				filter="chat"
			/>
		</ScreenContainer>
	);
};

export const ChatOverviewScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderIconButton
					testID="header_button_reload"
					icon={EDefaultIconSet.Reload}
					isDisabled={!props.options.onReloadPress}
					onPress={() => props.options.onReloadPress()}
				/>
				<NavigationHeaderPickAttendeeButton options={props.options} />
			</View>
		</NavigationHeader>
	);
};

export const ChatOverviewScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderIconButton
					testID="header_button_reload"
					icon={EDefaultIconSet.Reload}
					isDisabled={!props.options.onReloadPress}
					onPress={() => props.options.onReloadPress()}
				/>
				<NavigationHeaderPickAttendeeButton options={props.options} />
			</View>
		</NavigationHeader>
	);
};
