import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { Keyboard, ScrollView } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import i18next, { t } from 'i18next';
import { getCreateTicketInitialFormValues, IHSPXTicket, IUpdateTicketFormErrors, IUpdateTicketFormValues } from 'config/interfaces/ticket';
import { TicketForm } from 'components/Forms/Ticket';
import { IUserInSpaceId } from 'rematch/interfaces';
import { IAttendee } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { AttendeeListItem } from 'components/Attendee';
import { H2 } from 'components/Text';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.TicketEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.TicketEdit>;
type RouteParams = StackParamList[ERoutes.TicketEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'ticketedit';

export const TicketEditScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('ticket');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IUpdateTicketFormValues>({ ...getCreateTicketInitialFormValues() });
	const [formErrors, setFormErrors] = useState<IUpdateTicketFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const [userInSpaceIdEntry, setUserInSpaceIdEntry] = useState<IUserInSpaceId | undefined>(undefined);
	const [ticketHolder, setTicketHolder] = useState<IAttendee | undefined>(undefined);

	const tickets = useSelector((store: IRootState) => store.ticket.tickets);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	const updateTicket = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.updateTicket);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		let _attendee: typeof ticketHolder = undefined;

		if (attendees && userInSpaceIdEntry) {
			_attendee = attendees.find((e) => e.userId === userInSpaceIdEntry.userId);
		}

		setTicketHolder(_attendee);
	}, [attendees, userInSpaceIdEntry]);

	useEffect(() => {
		let _userInSpaceIdEntry: typeof userInSpaceIdEntry = undefined;

		if (activeSpace && userInSpaces) {
			_userInSpaceIdEntry = userInSpaces
				.filter((e) => e)
				.find(
					(e) => e.spaceId === activeSpace.spaceId && e.ticketcode === formValues.ticketcode && e.ticketProvider === 'hellospaces'
				);
		}

		setUserInSpaceIdEntry(_userInSpaceIdEntry);
	}, [userInSpaces, activeSpace, formValues]);

	useEffect(() => {
		if (activeSpace && tickets && route.params.id) {
			const values = tickets.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);

			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [activeSpace, tickets]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateTicket(),
			isLoading
		});
	}, [activeSpace, formValues, isLoading, isFormLocked]);

	const _applyFormValues = (values: IHSPXTicket) => {
		setFormValues({
			...values
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _updateTicket = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (
			!isEmptyString(formValues.email) &&
			tickets.find((t) => t.spaceId === activeSpace?.spaceId && t.email === formValues.email && t.id !== formValues.id)
		) {
			setFormErrors({ email: t('TicketcodeEmailInUse') });
			return;
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = tickets.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		const values = { ...formValues };
		values.ticketcode = values.ticketcode.toLowerCase().trim();
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await updateTicket({ ticket: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="tickets">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{ticketHolder && (
					<HSCard>
						<H2 key={'ticketHolder'} center>
							{t('ticketHolder')}
						</H2>
						<AttendeeListItem testID={`${TESTIDPREFIX}_attendee`} item={ticketHolder} />
					</HSCard>
				)}
				<TicketForm
					values={formValues}
					onChange={updateFormValues}
					errors={formErrors}
					isLoading={isLoading}
					ticketInUse={ticketHolder ? true : false}
				/>
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const TicketEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Ticket')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
