import { IAttendee } from 'config/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IRosterMember } from '.';

interface INetworkingRoomContentShare {
	id: string;
	rosterEntry?: IRosterMember;
}

export const NetworkingRoomContentShare = (props: INetworkingRoomContentShare) => {
	const { id, rosterEntry } = props;

	const videoRef = useRef<HTMLVideoElement>(null);
	const videoDimensionsRef = useRef({ width: 0, height: 0 });

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);
	const [videoDimensions, _setVideoDimensions] = useState({ height: 1, width: 1 });
	const [videoRatio, setVideoRatio] = useState<number>(1);
	const [containerDimensions, setContainerDimensions] = useState({
		height: 1,
		width: 1
	});

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (rosterEntry && attendees) {
			_attendee = attendees.find((e) => e.userId === rosterEntry.boundExternalUserId);
		}

		setAttendee(_attendee);
	}, [rosterEntry, attendees]);

	useEffect(() => {
		if (rosterEntry && videoRef.current) {
			let calculatedHeight = 1;
			let calculatedWidth = 1;

			if (videoRatio > 1) {
				// Webcam / Landscape
				calculatedWidth = containerDimensions.width;
				calculatedHeight = calculatedWidth / videoRatio;
				if (calculatedHeight > containerDimensions.height) {
					calculatedHeight = containerDimensions.height;
					calculatedWidth = calculatedHeight * videoRatio;
				}
			} else {
				// Mobile Portrait
				calculatedHeight = containerDimensions.height;
				calculatedWidth = calculatedHeight * videoRatio;
			}
			if (calculatedHeight !== 1 && calculatedWidth !== 1) {
				if (videoDimensions.width !== calculatedWidth || videoDimensions.height !== calculatedHeight) {
					setVideoDimensions({
						height: calculatedHeight,
						width: calculatedWidth
					});
				}
			}
		} else {
			setVideoDimensions({
				height: 1,
				width: 1
			});
		}
	}, [videoRatio, containerDimensions]);

	const setVideoDimensions = (newDimensions) => {
		_setVideoDimensions(newDimensions);
		videoDimensionsRef.current = newDimensions;
	};

	return (
		<View
			key={id}
			style={{ position: 'relative', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}
			onLayout={(e) => setContainerDimensions({ height: e.nativeEvent.layout.height, width: e.nativeEvent.layout.width })}
		>
			<video
				id={id}
				ref={videoRef}
				playsInline
				autoPlay
				height={rosterEntry ? '100%' : 0}
				width={rosterEntry ? '100%' : 0}
				onPlay={(e) => {
					if (e.nativeEvent?.path && e.nativeEvent.path[0]) {
						setVideoRatio(e.nativeEvent.path[0].videoWidth / e.nativeEvent.path[0].videoHeight);
					} else if (e.nativeEvent?.target) {
						setVideoRatio(e.nativeEvent?.target?.videoWidth / e.nativeEvent?.target?.videoHeight);
					}
				}}
			/>
		</View>
	);
};
