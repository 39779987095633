import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IMailHistory } from 'config/interfaces';
import { FlatList, ScrollView, View, ViewStyle } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { SectionHeader, Text } from 'components/Text';
import { MailPreview, MailResultListItem } from 'components/Mailing';
import { getCardContainerStyle, isEmptyString } from 'helper';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MailHistoryDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MailHistoryDetails>;
type RouteParams = StackParamList[ERoutes.MailHistoryDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mailhistorydetails';

export const MailHistoryDetailsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth, screenColumnCount, isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [historyItem, setHistoryItem] = useState<IMailHistory | undefined>(undefined);
	const [successItems, setSuccessItems] = useState<any[]>([]);
	const [errorItems, setErrorItems] = useState<any[]>([]);
	const [numColumns, setNumColumns] = useState<number>(screenColumnCount ?? isTabletOrMobile ? 1 : 2);

	const itemStyle: ViewStyle = { width: `${100 / numColumns}%`, flex: undefined };

	const content = useSelector((store: IRootState) => store.content.content);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const tickets = useSelector((store: IRootState) => store.ticket.tickets);

	useEffect(() => {
		let _historyitem: typeof historyItem = undefined;

		if (content?.mailhistories) {
			_historyitem = content.mailhistories.find((e) => e.id === route.params.id && e.spaceId === activeSpace?.spaceId);
		}

		setHistoryItem(_historyitem);
	}, [activeSpace, content]);

	useEffect(() => {
		let _success: typeof successItems = [];
		let _errors: typeof errorItems = [];

		let _type = content.mailtemplates.find((e) => e.id === historyItem?.mailTemplateId)?.contentType;

		if (historyItem) {
			switch (_type) {
				case 'attendee':
				case 'admin':
				case 'moderator':
					historyItem.result?.success.forEach((sucObj) => {
						const user = attendees?.find((e) => e.userId === sucObj);
						if (user) {
							_success.push(user);
						}
					});
					historyItem.result?.error.forEach((errObj) => {
						const user = attendees?.find((e) => e.userId === errObj.userId);
						if (user) {
							_errors.push(user);
						}
					});
					break;
				case 'ticket':
					historyItem.result?.success.forEach((sucObj) => {
						const ticket = tickets?.find((e) => e.spaceId === activeSpace?.spaceId && e.id === Number(sucObj));
						if (ticket) {
							_success.push(ticket);
						} else {
							_success.push(sucObj);
						}
					});
					historyItem.result.error.forEach((errObj) => {
						const ticket = tickets?.find((e) => e.spaceId === activeSpace?.spaceId && e.id === Number(errObj.userId));
						if (ticket) {
							_errors.push(ticket);
						} else {
							_success.push(errObj.userId);
						}
					});
					break;
				case 'expo':
					historyItem.result?.success.forEach((sucObj) => {
						const expo = content?.expos?.find((e) => e.id === Number(sucObj));
						if (expo) {
							_success.push(expo);
						}
					});
					historyItem.result?.error.forEach((errObj) => {
						const expo = content?.expos?.find((e) => e.id === Number(errObj.userId));
						if (expo) {
							_errors.push(expo);
						}
					});
					break;
				case 'speaker':
					historyItem.result?.success.forEach((itemId) => {
						const speaker = content?.speakers?.find((e) => e.id === Number(itemId));
						if (speaker) {
							_success.push(speaker);
						}
					});
					historyItem.result?.error.forEach((errObj) => {
						const speaker = content?.speakers?.find((e) => e.id === Number(errObj.userId));
						if (speaker) {
							_errors.push(speaker);
						}
					});
					break;
				case 'invitation':
				case 'noTemplate':
				default:
					historyItem.result?.success.forEach((itemId) => {
						_success.push(itemId);
					});
					historyItem.result?.error.forEach((errObj) => {
						_errors.push(errObj.userId);
					});
					break;
			}
		}

		setSuccessItems(_success);
		setErrorItems(_errors);
	}, [historyItem, attendees, content, tickets]);

	const _getBodyBackgroundColor = () => {
		let _bgColor;
		let _body = historyItem?.body;
		if (_body) {
			if (
				_body?.trim().startsWith(`<div style="background: #ffffff;`) ||
				_body?.trim().startsWith(`<div style="background-color: #ffffff`)
			) {
				_bgColor = '#ffffff';
			}
		}

		return _bgColor;
	};

	const _renderSuccess = () => {
		let _type = content.mailtemplates?.find((e) => e.id === historyItem?.mailTemplateId)?.contentType;
		if (successItems && successItems.length > 0) {
			return (
				<HSCard>
					<FlatList
						data={historyItem?.result.success}
						keyExtractor={(userId) => `mailhistory_result_success_${userId}`}
						ListHeaderComponent={<SectionHeader label={t('Success')} />}
						numColumns={numColumns}
						renderItem={({ item, index }) => {
							switch (_type) {
								case 'attendee':
								case 'admin':
								case 'moderator':
									const attendee = successItems.find((e) => e.userId === item);
									if (attendee) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_success_${item}`}
													attendee={attendee}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.AttendeeDetails, {
															spaceId: activeSpace?.spaceId,
															userId: attendee.userId
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'ticket':
									const ticket = successItems.find((e) => e.id === item);
									if (ticket) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<HSCard>
													<View style={{ flexDirection: 'row', marginBottom: 5 }}>
														<Text>
															{t('ticketcode')}
															{': '}
														</Text>
														<Text bold>{ticket.ticketcode}</Text>
													</View>
												</HSCard>
											</View>
										);
									}
									return (
										<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
											<HSCard>
												<View style={{ flexDirection: 'row', marginBottom: 5 }}>
													<Text>
														{t('Ticket ID')}
														{': '}
													</Text>
													<Text bold>{item}</Text>
												</View>
											</HSCard>
										</View>
									);
								case 'expo':
									const expo = successItems.find((e) => e.id === item);
									if (expo) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_success_${item}`}
													expo={expo}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.ExpoDetails, {
															spaceId: activeSpace?.spaceId,
															id: expo.id
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'speaker':
									const speaker = successItems.find((e) => e.id === item);
									if (speaker) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_success_${item}`}
													speaker={speaker}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.SpeakerDetails, {
															spaceId: activeSpace?.spaceId,
															id: speaker.id
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'invitation':
								case 'noTemplate':
								default:
									return (
										<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
											<HSCard>
												<Text style={{ marginBottom: 5 }} bold>
													{item}
												</Text>
											</HSCard>
										</View>
									);
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderError = () => {
		let _type = content.mailtemplates.find((e) => e.id === historyItem?.mailTemplateId)?.contentType;
		if (errorItems && errorItems.length > 0) {
			return (
				<HSCard>
					<FlatList
						data={historyItem?.result.error}
						keyExtractor={(item) => `mailhistory_result_success_${item?.userId}`}
						ListHeaderComponent={<SectionHeader label={t('Errors')} />}
						numColumns={numColumns}
						renderItem={({ item, index }) => {
							switch (_type) {
								case 'attendee':
								case 'admin':
								case 'moderator':
									const attendee = errorItems.find((e) => e.userId === item?.userId);
									if (attendee) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_error_${item}`}
													attendee={attendee}
													error={item?.error}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.AttendeeDetails, {
															spaceId: activeSpace?.spaceId,
															userId: attendee.userId
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'ticket':
									const ticket = errorItems.find((e) => e.id === item.userId);
									if (ticket) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<HSCard>
													<View style={{ flexDirection: 'row', marginBottom: 5 }}>
														<Text>
															{t('ticketCode')}
															{': '}
														</Text>
														<Text bold>{ticket.ticketcode}</Text>
													</View>
													<View style={{ flexDirection: 'row', marginBottom: 5 }}>
														<Text>
															{t('email')}
															{': '}
														</Text>
														<Text bold>
															{!isEmptyString(ticket.email?.toString()) ? ticket.email : t('invalid')}
														</Text>
													</View>
												</HSCard>
											</View>
										);
									}
									return null;
								case 'expo':
									const expo = errorItems.find((e) => e.id === item?.userId);
									if (expo) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_error_${item}`}
													expo={expo}
													error={item?.error}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.ExpoDetails, {
															spaceId: activeSpace?.spaceId,
															id: expo.id
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'speaker':
									const speaker = errorItems.find((e) => e.id === item?.userId);
									if (speaker) {
										return (
											<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
												<MailResultListItem
													testID={`${TESTIDPREFIX}_list_error_${item}`}
													speaker={speaker}
													error={item?.error}
													contentType={_type}
													onPress={() =>
														navigation.navigate(ERoutes.SpeakerDetails, {
															spaceId: activeSpace?.spaceId,
															id: speaker.id
														})
													}
												/>
											</View>
										);
									}
									return null;
								case 'invitation':
								case 'noTemplate':
								default:
									return (
										<View style={[getCardContainerStyle(numColumns, index), itemStyle]}>
											<HSCard>
												<Text style={{ marginBottom: 5 }} bold>
													{item?.userId}
												</Text>
												{item?.error && (
													<Text style={{ fontSize: 12, color: theme.danger, marginTop: 5 }}>{t(item.error)}</Text>
												)}
											</HSCard>
										</View>
									);
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="mailtemplates">
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{historyItem && <MailPreview subject={historyItem.subject} body={historyItem.body} bgColor={_getBodyBackgroundColor()} />}

				{_renderSuccess()}
				{_renderError()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const MailHistoryDetailsScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Mail History')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
