import { ILauncherSpace } from 'config/interfaces';
import { isEmptyString, IS_WEB } from 'helper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

export const useSpace = () => {
	const [activeSpace, setActiveSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [iAmSpaceAdmin, setIAmSpaceAdmin] = useState<boolean>(false);
	const [iAmSpaceModerator, setIAmSpaceModerator] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const activeSpaceId = useSelector((store: IRootState) => store.space.activeSpaceId);
	const spaceHistory = useSelector((store: IRootState) => store.space.spaceHistory);

	const getActiveSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpaceId);

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('itemInserted', (e) => _updateSpace());
		}
	}, []);

	useEffect(() => {
		_updateSpace();
	}, [activeSpaceId, spaceHistory]);

	useEffect(() => {
		if (activeSpace && profile) {
			const _admin: typeof iAmSpaceAdmin = activeSpace.admins?.find((e) => e.id === profile?.id) !== undefined;
			const _moderator: typeof iAmSpaceModerator = activeSpace.moderators?.find((e) => e.id === profile?.id) !== undefined;

			if (_admin !== iAmSpaceAdmin) {
				setIAmSpaceAdmin(_admin);
			}

			if (_admin !== iAmSpaceModerator) {
				setIAmSpaceModerator(_moderator);
			}
		}
	}, [activeSpace, profile?.id]);

	const _updateSpace = () => {
		let _space: typeof activeSpace = undefined;

		const spaceId = getActiveSpaceId({});
		if (!isEmptyString(spaceId)) {
			_space = spaceHistory.find((e) => e.space.spaceId === spaceId)?.space;
		}

		setActiveSpace(_space);
	};

	const convertDecimal = (value?: number) => {
		if (value) {
			if (activeSpace?.language === 'de') {
				return value.toString().replace('.', ',');
			}

			return value;
		}

		return undefined;
	};

	return {
		activeSpace,
		iAmSpaceAdmin,
		iAmSpaceModerator,
		convertDecimal
	};
};
