import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { HSCard } from 'components/Card';
import { ILandingpageFormErrors, ILandingpageFormValues } from 'config/interfaces';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import { EDefaultIconSet, validateForm } from 'helper';
import { isEmptyObject } from 'helper/object';
import { FormMultiSwitch, FormShare, FormTextInput } from 'components/Form';
import { getSpaceLandingpageFormSchema } from 'config/yupSchemas';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditLandingpage>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditLandingpage>;
type RouteParams = StackParamList[ERoutes.WelcomeEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'landingpageedit';

export const LandingpageEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [landingpageValues, setLandingpageValues] = useState<ILandingpageFormValues>({});
	const [landingpageErrors, setLandingpageErrors] = useState<ILandingpageFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);

	useEffect(() => {
		if (activeSpace && isEmptyObject(landingpageValues)) {
			setLandingpageValues({
				spaceId: activeSpace.spaceId,
				landingpageText: activeSpace.landingpageText ?? '',
				landingpageUrl: activeSpace.landingpageUrl ?? '',
				landingpageButtonText: activeSpace.landingpageButtonText ?? '',
				landingpageShowLoginButton: activeSpace.landingpageShowLoginButton,
				landingpageShowRegisterButton: activeSpace.landingpageShowRegisterButton
			});
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading
		});
	}, [landingpageValues, isLoading]);

	const _updateSpace = async () => {
		const _values: typeof landingpageValues = { ...landingpageValues, landingpageUrl: _checkUrl(landingpageValues.landingpageUrl) };
		const errors = await validateForm(getSpaceLandingpageFormSchema(_values), _values);

		if (errors) {
			setLandingpageErrors(errors);
			return;
		} else {
			setLandingpageErrors({});
			landingpageValues.landingpageUrl = _values.landingpageUrl;
		}

		setIsLoading(true);
		const res = await updateSpace({ data: landingpageValues });
		setIsLoading(false);
		if (res) {
			navigation.goBack();
		}
	};

	const _checkUrl = (url: string | undefined) => {
		if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
			return 'http://' + url;
		}
		return url;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<FormMarkdownEditor
						testID={`${TESTIDPREFIX}_textinput_markdowntext`}
						label={t('landingpageText')}
						hint={t('landingpageTextHint')}
						value={landingpageValues.landingpageText}
						error={landingpageErrors.landingpageText}
						onChange={(val) => setLandingpageValues({ ...landingpageValues, landingpageText: val })}
						isDisabled={isLoading}
					/>
					<FormTextInput
						isDisabled={isLoading}
						testID={`${TESTIDPREFIX}_textinput_externalurl`}
						label={`${t('Link')}: ${t('External Url')}`}
						hint={t('externalUrlHint')}
						value={landingpageValues.landingpageUrl}
						error={landingpageErrors.landingpageUrl}
						onChangeText={(value) => setLandingpageValues({ ...landingpageValues, landingpageUrl: value })}
						keyboardType="url"
						autoCapitalize="none"
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_buttontext`}
						label={t('landingpageButtonText')}
						hint={t('landingpageButtonTextHint').replace('%TEXT%', t('website'))}
						value={landingpageValues.landingpageButtonText}
						error={landingpageErrors.landingpageButtonText}
						onChangeText={(val) => setLandingpageValues({ ...landingpageValues, landingpageButtonText: val })}
						isDisabled={isLoading}
					/>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_showlogin`}
						label={t('landingPageShowLogin')}
						hint={t('landingPageShowLoginHint')}
						error={landingpageErrors.landingpageShowLoginButton}
						value={landingpageValues.landingpageShowLoginButton ? 'yes' : 'no'}
						options={[
							{
								key: 'yes',
								label: t('Yes')
							},
							{
								key: 'no',
								label: t('No')
							}
						]}
						onChange={(val) =>
							setLandingpageValues({ ...landingpageValues, landingpageShowLoginButton: val === 'yes' ? true : false })
						}
						isDisabled={isLoading}
					/>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_showregister`}
						label={t('landingPageShowRegister')}
						hint={t('landingPageShowRegisterHint')}
						error={landingpageErrors.landingpageShowRegisterButton}
						value={landingpageValues.landingpageShowRegisterButton ? 'yes' : 'no'}
						options={[
							{
								key: 'yes',
								label: t('Yes')
							},
							{
								key: 'no',
								label: t('No')
							}
						]}
						onChange={(val) =>
							setLandingpageValues({ ...landingpageValues, landingpageShowRegisterButton: val === 'yes' ? true : false })
						}
						isDisabled={isLoading}
					/>
					<FormShare
						testIdPrefix={TESTIDPREFIX}
						type="landingpage"
						label={t('landingpageLinkTitle')}
						hint={t('landingpageLinkHint')}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const LandingpageEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Welcome')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
