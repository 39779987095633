import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsTopScreenPadding } from 'config/styleConstants';
import { FlatList, ScrollView, View } from 'react-native';

import i18next from 'i18next';
import { IMailHistory, IPushNotification, ISchedule, IStage, ITabSwitcherButton, IUserBooking } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { EDefaultIconSet, getTopRadiusStyle, isEmptyString, IS_WEB } from 'helper';
import { HSCard } from 'components/Card';
import { BookingAttendeeListItem } from 'components/Booking';
import { H1, H2, Subtitle } from 'components/Text';
import { FormTextInput } from 'components/Form';
import { Button, RoundButton } from 'components/Button';
import moment from 'moment';
import { Text } from 'components/Text';
import { SearchBar } from 'components/SearchBar';
import { NoData } from 'components/NoData';
import { TabView } from 'components/TabView';
import { MailHistoryList } from 'components/Mailing';
import { PushNotificationList } from 'components/PushNotification';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.BookingDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.BookingDetails>;
type RouteParams = StackParamList[ERoutes.BookingDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'bookingdetails';

export const BookingDetailsScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth, isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();
	const { exportBookingsAsCSV } = useExport();

	const [item, setItem] = useState<ISchedule | undefined>(undefined);
	const [stage, setStage] = useState<IStage | undefined>(undefined);

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [bookings, setBookings] = useState<IUserBooking[]>([]);
	const [searchedBookings, setSearchedBookings] = useState<IUserBooking[]>([]);
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
	const [isRemoveLoading, setIsRemoveLoading] = useState<string | undefined>(undefined);

	const [notifications, setNotifications] = useState<IPushNotification[]>([]);
	const [notificationsSearchTerm, setNotificationsSearchTerm] = useState<string>('');

	const [sentMails, setSentMails] = useState<IMailHistory[]>([]);
	const [mailSearchTerm, setMailSearchTerm] = useState<string>('');

	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabs, setTabs] = useState<ITabSwitcherButton[]>([
		{
			key: 'bookings',
			label: i18next.t('Bookings')
		},
		{
			key: 'emails',
			label: i18next.t('E-Mails')
		},
		{
			key: 'pushNotifications',
			label: i18next.t('Notifications')
		}
	]);

	const content = useSelector((store: IRootState) => store.content.content);
	const allBookings = useSelector((store: IRootState) => store.booking.allBookings);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const pushNotifications = useSelector((store: IRootState) => store.pushNotification.pushNotifications);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const loadAllBookingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadAllBookingsDelta);

	const addBooking = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.addBooking);
	const removeBooking = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.removeBooking);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _reloadBookings(),
			isLoading: waitingForSocketResponse?.bookings
		});
	}, [waitingForSocketResponse]);

	useEffect(() => {
		let _item: typeof item = undefined;
		let _stage: typeof stage = undefined;

		if (activeSpace && content.schedules) {
			_item = content.schedules.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);
			if (_item && content.stages) {
				_stage = content.stages.find((e) => e.id === _item?.stage?.id);
			}
		}

		setItem(_item);
		setStage(_stage);
	}, [activeSpace, content]);

	useEffect(() => {
		let _push: typeof notifications = [];
		let _mails: typeof sentMails = [];

		if (activeSpace && item) {
			_push = pushNotifications.filter(
				(e) =>
					e.spaceId === activeSpace.spaceId &&
					e.sendingInformation?.linkedType === 'schedule' &&
					e.sendingInformation.linkedItemId === item.id
			);
			if (content.mailhistories) {
				_mails = content.mailhistories.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						e.sendingInformation?.linkedType === 'schedule' &&
						e.sendingInformation.linkedItemId === item.id
				);
			}
		}

		setNotifications(_push);
		setSentMails(_mails);
	}, [activeSpace, item, pushNotifications, content]);

	useEffect(() => {
		let _bookings: typeof bookings = [];

		if (activeSpace && allBookings) {
			_bookings = allBookings.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === route.params.id);
		}

		setBookings(_bookings);
	}, [activeSpace, allBookings]);

	useEffect(() => {
		let _searched: typeof searchedBookings = [...bookings];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = bookings.filter((booking) => {
				const user = attendees.find((e) => e.userId === booking.userId);

				if (user) {
					if (
						user.firstName.toLowerCase().includes(lowerSearch) ||
						user.lastName.toLowerCase().includes(lowerSearch) ||
						user.company?.toLowerCase().includes(lowerSearch) ||
						user.email.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}
				}

				const userInSpaceId = userInSpaces?.find((e) => e.userId === booking.userId && e.spaceId === activeSpace?.spaceId);
				if (userInSpaceId) {
					if (
						userInSpaceId.ticketIdentifier?.toLowerCase().includes(lowerSearch) ||
						userInSpaceId.ticketcode?.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}
				}

				return false;
			});
		}

		_searched.sort((a, b) => {
			const userA = attendees.find((e) => e.userId === a.userId)?.lastName ?? '';
			const userB = attendees.find((e) => e.userId === b.userId)?.lastName ?? '';

			return userA.toLowerCase() < userB.toLowerCase() ? -1 : 1;
		});

		setSearchedBookings(_searched);
	}, [searchTerm, bookings, attendees, userInSpaces]);

	useEffect(() => {}, [notifications]);

	const _reloadBookings = async () => {
		await loadAllBookingsDelta({});
	};

	const _addBooking = async () => {
		setIsAddLoading(true);
		const success = await addBooking({ emails: [email], itemId: route.params.id, type: 'schedule' });
		setIsAddLoading(false);
		if (success) {
			setEmail('');
			setError('');
		} else {
			const _attendee = attendees.find((a) => a.email === email);

			if (email.match(/[a-zA-Z0-9]+@[a-zA-Z0-9]+/)) {
				if (bookings.filter((b) => b.userId === _attendee?.userId).length === 0) {
					setError(t('AttendeeNotFound'));
				} else {
					setError(t('UserHasBookedAlready'));
				}
			} else {
				setError(t('NotAValidEmail').replace('%Email%', email));
			}
		}
	};

	const _removeBooking = async (userId: string) => {
		setIsRemoveLoading(userId);
		await removeBooking({ userId, itemId: route.params.id, type: 'schedule' });
		setError('');
		setIsRemoveLoading(undefined);
	};

	const _renderDate = () => {
		let str = '';

		if (item) {
			if (!isEmptyString(item.startDate) && !isEmptyString(item.endDate)) {
				str = `${moment(item.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(item.endDate).format('HH:mm')}`;
			} else if (!isEmptyString(item.startDate)) {
				str = `${t('Start')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
			} else if (!isEmptyString(item.endDate)) {
				str = `${t('End')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
			}

			if (!isEmptyString(str)) {
				return <Text>{str}</Text>;
			}
		}

		return null;
	};

	const _renderItem = () => {
		if (item) {
			return (
				<View style={{ alignItems: 'center', marginBottom: 20 }}>
					<H1>{item.title}</H1>
					{_renderDate()}
					{stage && <Text>{stage.title}</Text>}
					{!isEmptyString(item.subtitle) && <Subtitle>{item.subtitle}</Subtitle>}
				</View>
			);
		}

		return null;
	};

	const _renderBookings = () => {
		return (
			<ScrollView>
				<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
					{_renderItem()}
					<View style={{ marginBottom: 5 }}>
						<FormTextInput
							testID={`${TESTIDPREFIX}_input_email`}
							formStyle={{ marginBottom: 5 }}
							label={t('Add Booking')}
							hint={t('AddBookingHint')}
							isRequired
							placeholder={t('email')}
							value={email}
							onChangeText={(e) => setEmail(e)}
							keyboardType="email-address"
							error={error}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_add`}
							icon={EDefaultIconSet.Add}
							title={t('Add User')}
							size="sm"
							isDisabled={isEmptyString(email)}
							onPress={_addBooking}
							isLoading={isAddLoading}
							alignSelf="flex-end"
							color={theme.success}
						/>
					</View>
					{item && (
						<View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
							<Button
								testID={`${TESTIDPREFIX}_button_export`}
								title={t('Export Bookings')}
								isDisabled={bookings.length === 0}
								onPress={() => {
									if (item) {
										exportBookingsAsCSV(bookings, item);
									}
								}}
							/>
							{IS_WEB && !isTabletOrMobile && (
								<Button
									testID={`${TESTIDPREFIX}_button_import`}
									title={t('Import Bookings')}
									style={{ marginLeft: 20 }}
									onPress={() =>
										navigation.navigate(ERoutes.BookingImport, {
											spaceId: activeSpace?.spaceId,
											type: 'schedule',
											id: item.id
										})
									}
								/>
							)}
						</View>
					)}
					<FlatList
						data={searchedBookings}
						ListHeaderComponent={
							<View style={{ marginBottom: 20, paddingHorizontal: 5 }}>
								<H2 center>{`${t('Bookings')} (${bookings.length})`}</H2>
								<SearchBar testID={`${TESTIDPREFIX}_searchbar`} onChange={(val) => setSearchTerm(val)} value={searchTerm} />
							</View>
						}
						ListEmptyComponent={<NoData type={searchTerm.length >= 3 ? 'NoSearchResult' : 'NoBookings'} />}
						keyExtractor={(item) => `${TESTIDPREFIX}_item_${item.id}`}
						renderItem={({ item }) => (
							<BookingAttendeeListItem
								testID={`${TESTIDPREFIX}_list_${item.id}`}
								item={item}
								onRemove={() =>
									showAlert({
										title: t('Remove Booking'),
										message: t('Remove Booking Subtitle'),
										buttons: [
											{
												text: t('Cancel'),
												style: 'cancel'
											},
											{
												text: t('Remove Booking'),
												style: 'destructive',
												onPress: () => _removeBooking(item.userId)
											}
										]
									})
								}
								isLoading={isRemoveLoading === item.userId}
								isDisabled={isRemoveLoading !== undefined}
							/>
						)}
					/>
				</HSCard>
			</ScrollView>
		);
	};

	const _renderEmails = () => {
		if (item) {
			return (
				<View style={{ flex: 1 }}>
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<SearchBar
							testID={`${TESTIDPREFIX}_searchbar_mails`}
							value={mailSearchTerm}
							onChange={(val) => setMailSearchTerm(val)}
							containerStyle={{ marginBottom: 10 }}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_sendmail`}
							title={t('Send Mail')}
							icon={EDefaultIconSet.Mail}
							onPress={() => {
								navigation.navigate(ERoutes.SendMail, {
									spaceId: activeSpace?.spaceId,
									selectedEntries: searchedBookings.map((e) => e.userId),
									contentType: 'attendee',
									linkedType: 'schedule',
									linkedItemId: item.id
								});
							}}
							isDisabled={searchedBookings.map((e) => e.userId).length === 0}
							alignSelf="flex-end"
							noMargin
						/>
					</HSCard>

					{sentMails.length > 0 && !waitingForSocketResponse?.bookings ? (
						<MailHistoryList TESTIDPREFIX={TESTIDPREFIX} items={sentMails} searchTerm={mailSearchTerm} />
					) : (
						<NoData type="NoMailHistory" />
					)}
				</View>
			);
		}

		return null;
	};

	const _renderPushNotifications = () => {
		if (item) {
			return (
				<View style={{ flex: 1 }}>
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<SearchBar
							testID={`${TESTIDPREFIX}_searchbar_notifications`}
							value={notificationsSearchTerm}
							onChange={(val) => setNotificationsSearchTerm(val)}
							containerStyle={{ marginBottom: 10 }}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_createnotification`}
							title={t('CreateNotificationSubmitButton')}
							icon={EDefaultIconSet.Notification}
							onPress={() => {
								navigation.navigate(ERoutes.PushNotification, {
									spaceId: activeSpace?.spaceId,
									linkedType: 'schedule',
									linkedItemId: item.id
								});
							}}
							isDisabled={searchedBookings.map((e) => e.userId).length === 0}
							alignSelf="flex-end"
							noMargin
						/>
					</HSCard>

					{notifications.length > 0 && !waitingForSocketResponse?.bookings ? (
						<HSCard style={{ flex: 1 }}>
							<PushNotificationList
								TESTIDPREFIX={TESTIDPREFIX}
								notifications={notifications}
								searchTerm={notificationsSearchTerm}
								onItemPress={(item) =>
									navigation.navigate(ERoutes.PushNotification, { spaceId: activeSpace?.spaceId, id: item.id })
								}
							/>
						</HSCard>
					) : (
						<NoData type="NoPushNotifications" />
					)}
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		switch (tabs[activeTabIndex].key) {
			case 'emails':
				return _renderEmails();
			case 'pushNotifications':
				return _renderPushNotifications();
			case 'bookings':
			default:
				return _renderBookings();
		}
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="bookings">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<TabView
					testIdPrefix={TESTIDPREFIX}
					activeKey={tabs[activeTabIndex]?.key}
					items={tabs}
					onPress={(key) => {
						let index = tabs
							.map((e) => {
								return e.key;
							})
							.indexOf(key);
						setActiveTabIndex(index);
					}}
					isDisabled={tabs.length < 1}
				/>
				{_renderContent()}
			</View>
		</ScreenContainer>
	);
};

export const BookingDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Bookings')} />
			<NavigationHeaderIconButton
				testID="header_button_reload"
				icon={EDefaultIconSet.Reload}
				onPress={props.options.onRightNavPress}
				isLoading={props.options.isLoading}
			/>
		</NavigationHeader>
	);
};
