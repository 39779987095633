import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';
import * as RootNavigation from '../../../RootNavigation';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderEditButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { ISpeaker } from 'config/interfaces/speaker';
import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { IAttendee, IContentTypeField, IExpo, IMediaItem, ISchedule } from 'config/interfaces';
import { SpeakerDetail } from 'components/Speaker';
import { ScrollView } from 'react-native';
import { showToast } from 'helper/toast';
import { useTranslation } from 'react-i18next';
import { getDetailWidth } from 'helper';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SpeakerDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SpeakerDetails>;
type RouteParams = StackParamList[ERoutes.SpeakerDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'speakerdetails';

export const SpeakerDetailsScreen = ({ route, navigation }: Props) => {
	const { getContentTypeFields } = useContent('speaker');
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();
	const { screenWidth } = useQuery();
	const { t } = useTranslation();

	const [speaker, setSpeaker] = useState<ISpeaker | undefined>(undefined);
	const [detailFields, setDetailFields] = useState<IContentTypeField[]>([]);
	const [referencedSchedules, setReferencedSchedules] = useState<ISchedule[]>([]);
	const [referencedMediaItems, setReferencedMediaItems] = useState<IMediaItem[]>([]);
	const [referencedExpos, setReferencedExpos] = useState<IExpo[]>([]);
	const [referencedAttendee, setReferencedAttendee] = useState<IAttendee>();

	const content = useSelector((store: IRootState) => store.content.content);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		trackAction('speaker', 'Open Detail', `${route.params.id}`);
	}, []);

	useEffect(() => {
		let _speaker: typeof speaker = undefined;
		let _referencedExpos: typeof referencedExpos = [];
		let _referencedSchedules: typeof referencedSchedules = [];
		let _referencedMediaItems: typeof referencedMediaItems = [];
		let _referencedAttendee: typeof referencedAttendee = undefined;

		const iAmSpaceAdmin = activeSpace?.admins?.find((e) => e.userId === profile?.userId);
		const iAmSpaceModerator = activeSpace?.moderators?.find((e) => e.userId === profile?.userId);

		if (activeSpace && content.speakers) {
			_speaker = content.speakers.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);

			if (_speaker) {
				if (_speaker.isHidden && !iAmSpaceAdmin && !iAmSpaceModerator) {
					showToast('info', t('EntryNotFound'));
					if (RootNavigation.canGoBack()) {
						navigation.goBack();
					} else {
						RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
					}
					return;
				}
				navigation.setOptions({ title: `${_speaker.speakerTitle ? `${_speaker.speakerTitle} ` : ''}${_speaker.title}` });
			}
		}

		if (activeSpace && _speaker) {
			_speaker.expos?.forEach((sc) => {
				const found = content.expos?.find(
					(e2) => e2.spaceId === activeSpace.spaceId && e2.id === sc.id && (!e2.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
				if (found) {
					_referencedExpos.push(found);
				}
			});
			_speaker.schedules?.forEach((sc) => {
				const found = content.schedules?.find(
					(e2) => e2.spaceId === activeSpace.spaceId && e2.id === sc.id && (!e2.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
				if (found) {
					_referencedSchedules.push(found);
				}
			});
			_speaker.mediaitems?.forEach((sc) => {
				const found = content.mediaitems?.find(
					(e2) => e2.spaceId === activeSpace.spaceId && e2.id === sc.id && (!e2.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
				if (found) {
					_referencedMediaItems.push(found);
				}
			});
			if (_speaker.attendee) {
				const found = attendees?.find((e2) => e2.id === _speaker?.attendee?.id && !e2.hideMyProfile);
				if (found) {
					_referencedAttendee = found;
				}
			}
		}

		_referencedSchedules = _referencedSchedules
			.filter((e) => e.startDate)
			.sort((a, b) => {
				return a.startDate! < b.startDate! ? -1 : 1;
			});

		setSpeaker(_speaker);
		setReferencedExpos(_referencedExpos);
		setReferencedSchedules(_referencedSchedules);
		setReferencedMediaItems(_referencedMediaItems);
		setReferencedAttendee(_referencedAttendee);
	}, [activeSpace, profile, content, route.params.id]);

	useEffect(() => {
		const _fields = getContentTypeFields(true).filter(
			(e) => e.showOnDetailScreen || e.fieldType === 'section' || e.fieldType === 'mainsection'
		);
		setDetailFields(_fields);
	}, [activeSpace]);

	return (
		<ScreenContainer handleBackPress contentKey="speakers">
			<ScrollView
				contentContainerStyle={{
					flex: 1,
					width: getDetailWidth(activeSpace?.speakerDetailViewType ?? activeSpace?.globalDetailViewType ?? '', screenWidth),
					alignSelf: 'center'
				}}
			>
				<SpeakerDetail
					testID={TESTIDPREFIX}
					speaker={speaker}
					referencedExpos={referencedExpos}
					referencedSchedules={referencedSchedules}
					referencedMediaItems={referencedMediaItems}
					referencedAttendee={referencedAttendee}
					detailFields={detailFields}
					space={activeSpace}
				/>
			</ScrollView>
		</ScreenContainer>
	);
};

export const SpeakerDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.SpeakerEdit} id={params?.id} title={params.title} />
		</NavigationHeader>
	);
};
