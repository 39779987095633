import { createNavigationContainerRef, StackActions } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
	if (navigationRef.isReady()) {
		navigationRef.navigate(name, params);
	}
}

export function canGoBack() {
	if (navigationRef.isReady()) {
		return navigationRef.canGoBack();
	}
}
export function popToTop() {
	if (navigationRef.isReady()) {
		navigationRef.dispatch(StackActions.popToTop());
	}
}
export function replace(...args) {
	if (navigationRef.isReady()) {
		navigationRef.dispatch(StackActions.replace(...args));
	}
}
