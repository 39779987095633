import { IContentTypeField, ILauncherSpace, IMapPosition, IMedia, ISchedule, IStrapiBase, TMedia } from '.';

export interface IStage extends IStrapiBase {
	title: string;
	spaceId: string;
	backgroundColor?: string;
	textColor?: string;
	order?: number;
	isCrossLane?: boolean;
	subtitle?: string;
	schedules?: ISchedule[];
	image?: IMedia;
	mapposition?: IMapPosition;
}

export interface ICreateStageFormValues {
	title: string;
	spaceId?: string;
	backgroundColor?: string;
	textColor?: string;
	order?: number;
	isCrossLane?: boolean;
	subtitle?: string;
	schedules?: number[];
	image?: TMedia;
}

export interface IUpdateStageFormValues {
	id?: number;
	title: string;
	spaceId?: string;
	backgroundColor?: string;
	textColor?: string;
	order?: number;
	isCrossLane?: boolean;
	subtitle?: string;
	schedules?: number[];
	image?: TMedia;
	mapposition?: number;
	updated_at?: string;
}

export interface ICreateStageFormErrors {
	title?: string;
	backgroundColor?: string;
	textColor?: string;
	order?: string;
	isCrossLane?: string;
	subtitle?: string;
	schedules?: string;
	image?: string;
}

export interface IUpdateStageFormErrors {
	title?: string;
	backgroundColor?: string;
	textColor?: string;
	order?: string;
	isCrossLane?: string;
	subtitle?: string;
	schedules?: string;
	image?: string;
}

export interface ICreateStagePayload {
	space?: ILauncherSpace;
	stage: ICreateStageFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateStagePayload {
	stage: IStage | IUpdateStageFormValues;
	fields: IContentTypeField[];
	isDeletion?: boolean;
}

export const getCreateStageInitialFormValues = () => {
	return {
		title: '',
		schedules: []
	};
};
