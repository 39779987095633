import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { CommentForm } from 'components/Comment';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderBackButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { NewsListItem } from 'components/News';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { IMediaGalleryItem, INews } from 'config/interfaces';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';

import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { useSpace } from 'hooks/useSpace';

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NewsDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NewsDetails>;
type RouteParams = StackParamList[ERoutes.NewsDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'newsdetails';

export const NewsDetailsScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [item, setItem] = useState<INews | undefined>(undefined);

	const [sortedMedia, setSortedMedia] = useState<IMediaGalleryItem[]>([]);
	const [showComments, setShowComments] = useState<boolean>(false);
	const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		trackAction('feed', 'Open Detail', route.params.id.toString());
	}, []);

	useEffect(() => {
		let _showComments = false;
		if (activeSpace?.allowFeedComments !== 'never') {
			_showComments = true;
		}

		if (showComments !== _showComments) setShowComments(_showComments);
	}, [item, activeSpace]);

	useEffect(() => {
		let _item: typeof item = undefined;

		if (content?.newsitems) {
			_item = content?.newsitems?.find((e) => e.spaceId === activeSpace?.spaceId && e.id === route.params.id);
		}

		setItem(_item);
	}, [activeSpace, content]);

	useEffect(() => {
		let _sorted: typeof sortedMedia = [];

		if (item?.media) {
			_sorted = [...item.media];
			_sorted.sort((a, b) => {
				const aVal = a.order ? a.order : 9999;
				const bVal = b.order ? b.order : 9999;
				return aVal < bVal ? -1 : 1;
			});
		}

		if (!item?.media || !item?.media[currentMediaIndex]) {
			setCurrentMediaIndex(0);
		}

		setSortedMedia(_sorted);
	}, [item]);

	const _renderItem = () => {
		if (item) {
			return <NewsListItem testID={TESTIDPREFIX} item={item} showText isInDetail />;
		}

		return null;
	};

	return (
		<ScreenContainer contentKey="newsitems">
			{item ? (
				<ScrollView
					contentContainerStyle={{
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingBottom: hsBottomMargin,
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderItem()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{item?.id && showComments && (
				<View style={{ paddingHorizontal: EHorizontalScreenPadding.Wide, width: screenWidth, alignSelf: 'center' }}>
					<CommentForm itemId={item.id.toString()} contentType="feed" />
				</View>
			)}
		</ScreenContainer>
	);
};

export const NewsDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title="News" />
		</NavigationHeader>
	);
};
