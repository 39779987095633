import React from 'react';
import { FlatList, View } from 'react-native';
import { Markdown } from './Markdown';
import { H1, H2, Text } from 'components/Text';
import { HSCard } from 'components/Card';
import { t } from 'i18next';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet } from 'helper';
import { RoundButton } from 'components/Button';
import { useTheme } from 'hooks/useTheme';

interface IMarkdownExplanation {}

const markdownText = [
	{ title: t('Header'), markdown: '# Header 1', icon: EDefaultIconSet.Heading1 },
	{ title: t('Header'), markdown: '## Header 2', icon: EDefaultIconSet.Heading2 },
	{ title: t('boldText'), markdown: '**bold** text', icon: EDefaultIconSet.Bold },
	{ title: t('italicText'), markdown: '*italic* text', icon: EDefaultIconSet.Italic },
	{ title: t('addCenter'), markdown: '<center>centered text</center>', icon: EDefaultIconSet.Centered },
	{ title: t('Image'), markdown: '![text](https://hellospaces.b-cdn.net/hspx.png)', icon: EDefaultIconSet.Image },
	{ title: t('Link'), markdown: `[linktext](https://hellospaces.de)`, icon: EDefaultIconSet.Link },
	{
		title: t('List'),
		markdown: `* List 1
* List 2
* List 3`,
		icon: EDefaultIconSet.List
	},
	{ title: t('addHorizontalLine'), markdown: '***', icon: EDefaultIconSet.HorizontalRule }
];

export const MarkdownExplanation = (props: IMarkdownExplanation) => {
	const {} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	return (
		<FlatList
			contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide, paddingBottom: 20 }}
			data={markdownText}
			ListHeaderComponent={<H1>{t('formatExplainerHeader')}</H1>}
			renderItem={({ item, index }) => (
				<View key={`markdownexplanation_button_${index}`}>
					<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5 }}>
						<H2 noBottomMargin style={{ marginRight: 10 }}>
							{item.title}
						</H2>
						<RoundButton
							testID={`markdownexplanation_button_${index}`}
							icon={item.icon}
							size="xs"
							// title={t('addHorizontalLine')}
							isStacked
							isDisabled
						/>
					</View>
					<HSCard style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<View
							style={{
								width: '46%',
								flexDirection: 'row',
								alignItems: 'center',
								alignContent: 'center'
							}}
						>
							<Text style={{ textAlignVertical: 'bottom' }}>{item.markdown}</Text>
						</View>
						<View style={{ width: 1, backgroundColor: theme.text }} />
						<Markdown containerStyle={{ width: '46%' }} markdown={item.markdown} />
					</HSCard>
				</View>
			)}
			keyExtractor={(item, index) => `markdownexplanation_${item.title}_${index}`}
		/>
	);
};
