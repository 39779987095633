import { ChildButton } from 'components/Button';
import { isEmptyString, openURL } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { LayoutChangeEvent, ScrollView, View, ViewStyle } from 'react-native';
import { MarkdownView } from 'react-native-markdown-view';
import * as SimpleMarkdown from 'simple-markdown';

interface IMarkdown {
	markdown?: string;
	containerStyle?: ViewStyle;
	onPress?: () => void;
	onLayout?: (e: LayoutChangeEvent) => void;
	scrollable?: boolean;
	largerDesign?: boolean;
}

export const Markdown = (props: IMarkdown) => {
	const { markdown, containerStyle, onPress, onLayout, scrollable, largerDesign } = props;
	const { theme } = useTheme();

	const centeredRule = {
		match: SimpleMarkdown.anyScopeRegex(new RegExp('^<center>((.|\n)+?)<\\/center>')),
		order: 1,
		quality: () => 100,
		parse: (capture, nestedParse, state) => {
			return { text: SimpleMarkdown.parseBlock(nestedParse, capture[1], state) };
		},
		render: (node, output, state, styles) => {
			return (
				<View key={state.key} style={styles.centered}>
					{output(node.text, state)}
				</View>
			);
		}
	};

	const _renderMarkdown = () => {
		return (
			<MarkdownView
				styles={{
					centered: { width: '100%', alignSelf: 'center', alignItems: 'center' },
					paragraph: { color: theme.text, fontSize: largerDesign ? 21 : 14 },
					heading1: {
						fontWeight: '700',
						fontSize: largerDesign ? 30 : 20,
						color: theme.text
					},
					heading2: {
						fontWeight: '700',
						fontSize: largerDesign ? 24 : 16,
						color: theme.text
					},
					heading3: {
						fontWeight: '700',
						fontSize: largerDesign ? 21 : 14,
						color: theme.text
					},
					link: {
						color: theme.hsBlue,
						textDecorationLine: 'underline'
					},
					imageWrapper: {
						maxWidth: 500
					},
					inlineCode: {
						fontSize: 12
					},
					hr: {
						backgroundColor: theme.gray,
						width: '100%',
						marginTop: 8,
						marginBottom: 8,
						height: 1
					},
					list: {
						margin: 8,
						color: theme.text
					},
					listItem: {
						flexDirection: 'row',
						color: theme.text
					},
					listItemNumber: {
						minWidth: 32,
						paddingRight: 4,
						color: theme.text
					},
					listItemBullet: {
						minWidth: 32,
						paddingRight: 4,
						color: theme.text
					},
					listItemOrderedContent: {
						flex: 1,
						color: theme.text
					},
					listItemUnorderedContent: {
						flex: 1,
						color: theme.text
					}
				}}
				rules={{
					centered: centeredRule
				}}
				onLinkPress={(url: string) => openURL(url)}
			>
				{markdown}
			</MarkdownView>
		);
	};

	if (!isEmptyString(markdown)) {
		if (onPress) {
			return (
				<ChildButton
					testID="button_open_markdown"
					style={[{ width: '100%' }, containerStyle]}
					onPress={onPress}
					onLayout={onLayout}
				>
					{_renderMarkdown()}
				</ChildButton>
			);
		}

		if (scrollable) {
			return (
				<ScrollView style={[{ width: '100%' }, containerStyle]} onLayout={onLayout}>
					{_renderMarkdown()}
				</ScrollView>
			);
		}

		return (
			<View style={[{ width: '100%' }, containerStyle]} onLayout={onLayout}>
				{_renderMarkdown()}
			</View>
		);
	}

	return null;
};
