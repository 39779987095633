import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { Dimensions, Vibration, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString, IS_ANDROID, IS_WEB, swapArrayItems } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { IMap } from 'config/interfaces';
import i18next from 'i18next';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { HSCard, HSDragCard } from 'components/Card';
import { H1, ItemTitle, Text, WelcomeHint } from 'components/Text';
import { useHeaderHeight } from '@react-navigation/elements';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MapOrder>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MapOrder>;
type RouteParams = StackParamList[ERoutes.MapOrder];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'maporder';

export const MapOrderScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { screenWidth, isTabletOrMobile } = useQuery();
	const headerHeight = useHeaderHeight();
	const screenHeight = Dimensions.get('window').height;

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [spaceMaps, setSpaceMaps] = useState<IMap[]>([]);
	const [tempMaps, setTempMaps] = useState<IMap[]>([]);

	const multiUpdate = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiUpdate);
	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateOrders()
		});
	}, [tempMaps]);

	useEffect(() => {
		if (content.maps) {
			const _maps = content.maps.filter((e) => e.spaceId === activeSpace?.spaceId);
			if (_maps) {
				setSpaceMaps(_maps);
			}
		}
	}, [content, activeSpace]);

	useEffect(() => {
		if (spaceMaps) {
			let _maps = spaceMaps.sort((a, b) => ((a.order ?? 9999) < (b.order ?? 9999) ? -1 : 1));
			setTempMaps(_maps);
		}
	}, [spaceMaps]);

	const _updateOrders = async () => {
		if (tempMaps) {
			const items: IMap[] = [];
			tempMaps.forEach((map, idx) => {
				items.push({
					...map,
					order: idx
				});
			});

			setIsLoading(true);
			const res = await multiUpdate({ items, type: 'map' });
			setIsLoading(false);

			if (res) {
				if (navigation.canGoBack()) {
					navigation.goBack();
				} else {
					navigation.navigate(ERoutes.Maps, {
						spaceId: activeSpace?.spaceId,
						key: 'maps'
					});
				}
			}
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!tempMaps) {
			return;
		}

		const list: IMap[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, tempMaps);
		const newList: IMap[] = [];

		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}

		list.forEach((item, idx) => {
			newList.push({
				...item,
				order: idx
			});
		});

		setTempMaps([...newList]);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!tempMaps) {
			return;
		}

		let list = [...tempMaps];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);
		list = list.map((e, i) => {
			e.order = i;
			return e;
		});

		setTempMaps([...tempMaps]);
	};

	const _renderItem = (params) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_dragitem_${item.id}`}
				isActive={isActive}
				isDisabled={isLoading}
				onEdit={() => navigation.navigate(ERoutes.MapEdit, { spaceId: activeSpace?.spaceId, id: item.id })}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (spaceMaps.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				style={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
			>
				<View style={{ flex: 1, alignItems: 'center', marginRight: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
					<ItemTitle hasNoMargin text={item.title} />
					<Text>{`${isTabletOrMobile ? '' : `${t('order')}: `}${(item.order ?? index) + 1}`}</Text>
				</View>
			</HSDragCard>
		);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="maps">
			<DraggableFlatList
				testID={`${TESTIDPREFIX}_scrollview`}
				data={tempMaps ?? []}
				style={{ height: IS_WEB ? screenHeight - headerHeight : '100%' }}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
				renderItem={(params) => _renderItem(params)}
				keyExtractor={(item, index) => `${TESTIDPREFIX}_${item.id}`}
				ListHeaderComponent={
					<HSCard>
						<H1 style={{ marginBottom: isEmptyString(t('ChangeMapOrderHint')) ? 0 : hsTextBottomMarginSmall }}>
							{t('ChangeMapOrderHeader')}
						</H1>
						<WelcomeHint>{t('ChangeMapOrderHint')}</WelcomeHint>
					</HSCard>
				}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					setTempMaps(data);
					if (!route.params?.prohibitNavigation) {
						navigation.setParams({ prohibitNavigation: true });
					}
				}}
			/>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MapOrderScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Maps')} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderIconButton
					testID="header_button_save"
					icon={EDefaultIconSet.Save}
					onPress={props.options.onRightNavPress}
				/>
			</View>
		</NavigationHeader>
	);
};
