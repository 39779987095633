import { hsQRCodeBorderWidth } from 'config/styleConstants';
import React from 'react';
import { View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

interface IHSQRCode {
	value?: string;
	size?: number;
	onCapture?: (uri: string) => void;
}

export const HSQRCode = (props: IHSQRCode) => {
	const { value, size } = props;

	return (
		<View style={{ borderWidth: hsQRCodeBorderWidth, borderColor: 'white' }}>
			<QRCode value={value} size={size} backgroundColor={'white'} />
		</View>
	);
};
