import React, { ReactNode, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import i18next from 'i18next';
import { NoData } from 'components/NoData';
import { Placeholder, PlaceholderMedia, ShineOverlay, PlaceholderLine } from 'rn-placeholder';
import { FlashSectionList } from 'components/List';
import { IWebhookHistoryItem } from 'config/interfaces';
import { WebhookHistoryListHeader, WebhookHistoryListItem } from 'components/Webhook';
import moment from 'moment';
import { HSCard } from 'components/Card';
import { WebhookHistoryModal } from 'components/Modal/WebhookHistoryModal';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.WebhookHistory>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.WebhookHistory>;
type RouteParams = StackParamList[ERoutes.WebhookHistory];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface IHistoryData {
	header: string;
	items: IWebhookHistoryItem[];
}

const TESTIDPREFIX = 'webhookhistory';

export const WebhookHistoryScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [history, setHistory] = useState<IWebhookHistoryItem[]>([]);
	const [groupedHistory, setGroupedHistory] = useState<(string | IWebhookHistoryItem)[]>([]);
	const [selectedEntry, setSelectedEntry] = useState<IWebhookHistoryItem | undefined>(undefined);

	const loadWebhookHistory = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadWebhookHistory);

	useEffect(() => {
		_load();
	}, []);

	const _load = async () => {
		setIsLoading(true);
		const res = await loadWebhookHistory({ webhookId: route.params.id, spaceId: route.params.spaceId });
		if (res) {
			setHistory(res);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		const sections: IHistoryData[] = [];
		let grouped: typeof groupedHistory = [];

		history.forEach((item) => {
			const itemMoment = moment(item.date);
			const found = sections.find((e) => e.header === itemMoment.format('DD.MM.YYYY'));

			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: itemMoment.format('DD.MM.YYYY'),
					items: [item]
				});
			}
		});

		sections.sort((a, b) => {
			const aVal = moment(a.header, 'DD.MM.YYYY');
			const bVal = moment(b.header, 'DD.MM.YYYY');

			return aVal.isSameOrAfter(bVal) ? -1 : 1;
		});

		sections.forEach((section) => {
			section.items.sort((a, b) => (a.date > b.date ? -1 : 1));
			grouped.push(section.header);
			grouped = grouped.concat(section.items);
		});

		setGroupedHistory(grouped);
	}, [history]);

	const _renderEmptyElements = () => {
		const emptyListLength = 10;
		const emptyList: ReactNode[] = [];

		for (let index = 0; index < emptyListLength; index++) {
			emptyList.push(
				<View key={`emptylist_element_${index}`} style={{ height: 100 }}>
					<Placeholder Left={PlaceholderMedia} Animation={ShineOverlay}>
						<PlaceholderLine />
						<PlaceholderLine width={50} />
					</Placeholder>
				</View>
			);
		}

		return emptyList;
	};

	const _renderList = () => {
		if (history.length === 0) {
			if (isLoading) {
				return _renderEmptyElements();
			}
			return <NoData type="NoWebhookHistory" />;
		}

		return (
			<HSCard>
				<FlashSectionList
					data={groupedHistory}
					extraData={[selectedEntry]}
					renderItem={(renderItem) => (
						<WebhookHistoryListItem
							testID={`${TESTIDPREFIX}_item_${renderItem.index}`}
							item={renderItem.item}
							onPress={() => setSelectedEntry(renderItem.item)}
						/>
					)}
					renderSectionHeader={(renderItem) => <WebhookHistoryListHeader text={renderItem.item} />}
					estimatedItemSize={50}
					hideIndexButtons
				/>
			</HSCard>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="webhooks">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderList()}
			</ScrollView>
			<WebhookHistoryModal item={selectedEntry} onClose={() => setSelectedEntry(undefined)} />
		</ScreenContainer>
	);
};

export const WebhookHistoryScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('History')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
