import React from 'react';
import { Spinner } from 'components/Spinner';
import { View } from 'react-native';

export const FullScreenLoading = () => {
	return (
		<View style={{ flex: 1, justifyContent: 'center' }}>
			<Spinner />
		</View>
	);
};
