import React from 'react';
import { View } from 'react-native';

import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { Colorpicker } from 'components/Colorpicker/Colorpicker';
import { HSModal } from 'components/Modal/Modal';
import { hsBorderRadius } from 'config/styleConstants';

interface IFormColorPickerModal {
	testID: string;
	isVisible: boolean;
	value?: string;
	onChange: (value: string | undefined) => void;
	onClose: () => void;
	label?: string;
	description?: string;
	fallbackValue?: string;
	hideTransparency?: boolean;
	hideRGB?: boolean;
}

const TESTIDPREFIX = 'formcolorpickermodal';

export const FormColorPickerModal = (props: IFormColorPickerModal) => {
	const { testID, isVisible, value, onChange, onClose, label, description, fallbackValue, hideTransparency, hideRGB } = props;
	const { theme } = useTheme();

	return (
		<HSModal isVisible={isVisible} onClose={onClose} containerStyle={{ borderRadius: hsBorderRadius }} title={label}>
			<View
				style={{
					flex: 1,
					backgroundColor: theme.contentBackgroundColor ?? theme.background,
					height: '100%'
				}}
			>
				{description && <Text style={{ marginBottom: 5 }}>{description}</Text>}
				{fallbackValue && <Text style={{ fontSize: normalizeFontSize(12), marginBottom: 20 }}>{fallbackValue}</Text>}
				<Colorpicker
					onClose={onClose}
					testID={testID}
					value={value}
					onColorChange={onChange}
					hideTransparency={hideTransparency}
					hideRGB={hideRGB}
				/>
			</View>
		</HSModal>
	);
};
