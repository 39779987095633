import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { VerticalBarChart } from './VerticalBarChart';
import { HorizontalBarChart } from './HorizontalBarChart';
import { IWordListItem } from 'components/Vote/ActiveVote/VoteWordCloud/WordCloud';

interface IBarChart {
	data: IWordListItem[];
	answerCount?: number;
	showVertical?: boolean;
	hideStats?: boolean;
	isIcons?: boolean;
	showStatsInBars?: boolean;
	largerDesign?: boolean;
	onPress?: (index: number) => any;
}

export const BarChart = (props: IBarChart) => {
	const { data, answerCount, showVertical, hideStats, isIcons, showStatsInBars, largerDesign, onPress } = props;

	const [containerWidth, setContainerWidth] = useState<number>(0);
	const [totalVotesSubmitted, setTotalVotesSubmitted] = useState<number>(0);
	const [highestCount, setHighestCount] = useState<number>(0);

	useEffect(() => {
		let total = 0;
		let high = 0;

		if (data) {
			data.forEach((item) => {
				total += item.count;
				if (item.count > high) {
					high = item.count;
				}
			});
		}

		setHighestCount(high);
		setTotalVotesSubmitted(total);
	}, [containerWidth, data]);

	const _renderBarChart = () => {
		if (showVertical) {
			return (
				<VerticalBarChart
					data={data}
					highestCount={highestCount}
					totalVotesSubmitted={totalVotesSubmitted}
					distinctAnswersSubmitted={answerCount}
					hideStats={hideStats}
					isIcons={isIcons}
					largerDesign={largerDesign}
					onPress={onPress}
				/>
			);
		}

		return (
			<HorizontalBarChart
				data={data}
				highestCount={highestCount}
				totalVotesSubmitted={totalVotesSubmitted}
				distinctAnswersSubmitted={answerCount}
				hideStats={hideStats}
				showStatsInBars={showStatsInBars}
				isIcons={isIcons}
				largerDesign={largerDesign}
				onPress={onPress}
			/>
		);
	};

	return (
		<View style={{ flex: 1 }} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
			{_renderBarChart()}
		</View>
	);
};
