import React, { useState } from 'react';
import { IContentFlowMediaEntry, IFormBase } from 'config/interfaces';
import { FormField } from '../FormField';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';
import { ContentflowListEntry } from 'components/Contentflow';
import { View } from 'react-native';
import { ContentFlowVideoPickerModal } from './ContentFlowVideoPickerModal';
import { useTheme } from 'hooks/useTheme';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';

interface IFormContentFlowPicker extends IFormBase {
	value?: IContentFlowMediaEntry;
	onChange?: (url: IContentFlowMediaEntry | null) => void;
}

export const FormContentFlowPicker = (props: IFormContentFlowPicker) => {
	const { testID, label, isRequired, hint, error, value, onChange } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _renderValue = () => {
		if (value) {
			return (
				<View>
					<ContentflowListEntry testID={testID} item={value} />
					<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							onPress={() => setIsModalVisible(true)}
							alignSelf="flex-end"
						/>
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							onPress={() => {
								showAlert({
									title: t('DeleteVideoReferenceTitle'),
									message: t('DeleteContentflowVideoSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('DeleteVideoReferenceTitle'),
											style: 'destructive',
											onPress: () => {
												if (onChange) {
													onChange(null);
												}
											}
										}
									]
								});
							}}
							alignSelf="flex-end"
						/>
					</View>
				</View>
			);
		}

		return (
			<RoundButton
				testID={`${testID}_button_add`}
				icon={EDefaultIconSet.Add}
				onPress={() => setIsModalVisible(true)}
				alignSelf="flex-end"
			/>
		);
	};
	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error}>
			{_renderValue()}
			<ContentFlowVideoPickerModal
				isVisible={isModalVisible}
				onClose={() => setIsModalVisible(false)}
				onSubmit={(val) => {
					if (onChange) {
						onChange(val);
					}
					setIsModalVisible(false);
				}}
				selectedUrl={value?.url}
			/>
		</FormField>
	);
};
