import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';
import { hsBottomMargin } from 'config/styleConstants';
interface IVoteRatingForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteRatingForm = (props: IVoteRatingForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm
			testIdPrefix={testIdPrefix}
			values={values}
			errors={errors}
			onChange={onChange}
			isLoading={isLoading}
			additionalFields={
				<View>
					<FormMultiSwitch
						testID={`${testIdPrefix}_multiswitch_ratingviewtype`}
						label={t('Rating View Type')}
						hint={t('Rating View Type Hint')}
						error={errors.viewType}
						value={values.viewType}
						options={[
							{ key: 'star', label: t('Star') },
							{ key: 'thumbs', label: t('Thumbs') }
						]}
						onChange={(val) => onChange({ ...values, viewType: val })}
						isDisabled={isLoading}
						formStyle={{ marginBottom: hsBottomMargin }}
					/>
					<FormTextInput
						testID={`${testIdPrefix}_textinput_maxvalue`}
						label={t('maxValRating')}
						hint={t('MaxValue Hint')}
						value={values.maxValue?.toString()}
						error={errors.maxValue}
						onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
						isDisabled={isLoading}
						keyboardType="number-pad"
						onlyNumbers
					/>
				</View>
			}
		/>
	);
};
