import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderIconButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { IEditNewsFormErrors, IEditNewsFormValues, INews } from 'config/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { CreateNewsForm } from 'components/Forms/News';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { getEditNewsFormSchema } from 'config/yupSchemas/news';
import { useSelector } from 'react-redux';
import { Keyboard, ScrollView } from 'react-native';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import { NewsPreview } from 'components/News/NewsPreview';
import * as RootNavigation from '../../../RootNavigation';
import { useTranslation } from 'react-i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.NewsEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.NewsEdit>;
type RouteParams = StackParamList[ERoutes.NewsEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const NewsEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formErrors, setFormErrors] = useState<IEditNewsFormErrors>({});
	const [news, setNews] = useState<IEditNewsFormValues>({});
	const [isNewsLoading, setIsNewsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const newsitems = useSelector((store: IRootState) => store.content.content.newsitems);
	const updateNews = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateNews);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateNews(),
			isLoading: isNewsLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [activeSpace, news, isNewsLoading, formErrors, isFormLocked]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [news]);

	useEffect(() => {
		if (activeSpace && route.params.id) {
			const values = newsitems?.find((n) => n.spaceId === activeSpace?.spaceId && n.id === route.params.id);
			if (values) {
				if (news.updated_at && values.updated_at !== news.updated_at && !isNewsLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [activeSpace, route.params.id, newsitems]);

	const _applyFormValues = (values: INews) => {
		setNews({ ...values });
		setIsFormLocked(false);
	};

	const _updateNews = async () => {
		const errors = await validateForm(getEditNewsFormSchema(news), news);

		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = newsitems.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsNewsLoading(true);
		const res = await updateNews({ news: news });
		if (res) {
			setIsNewsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsNewsLoading(false);
		}
	};

	const updateFormValues = (val) => {
		navigation.setParams({ prohibitNavigation: true });
		if (activeSpace) {
			const _news = newsitems?.find((n) => n.spaceId === activeSpace?.spaceId && n.id === route.params.id);
			if (_news && _news.isClickedOn) _news.isClickedOn = false;
		}
		setNews(val);
	};

	return (
		<ScreenContainer
			handleBackPress
			isProtectedRoute={
				!(activeSpace?.allowUserGeneratedContentInFeed === 'always' || activeSpace?.allowUserGeneratedContentInFeed === 'moderated')
			}
			contentKey="newsitems"
		>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<CreateNewsForm
					values={news}
					onChange={updateFormValues}
					errors={formErrors}
					isLoading={isNewsLoading}
					onError={(newErrors) => {
						const _errors = { ...newErrors };
						Object.keys(_errors).forEach((key) => {
							if (!_errors[key]) {
								delete _errors[key];
							}
						});
						setFormErrors(_errors);
					}}
				/>
				<NewsPreview news={news} />
			</ScrollView>
			<LoadingModal isLoading={isNewsLoading} />
		</ScreenContainer>
	);
};

export const NewsEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('News')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
