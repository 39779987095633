import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { View } from 'react-native';

import { IContentTypeField, INewContentTypeField } from 'config/interfaces';
import { HSModal } from '../Modal';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { FormHint } from 'components/Form/FormHint';
import { StepWizard } from 'components/StepWizard';
import { TContentType, useContent } from 'hooks/useContent';
import { useTheme } from 'hooks/useTheme';
import { ContentTypeFieldsOptionsForm } from 'components/Forms/ContentType/ContentTypeFieldsOptionsForm';
import { ContentTypeFieldsMetaForm } from 'components/Forms/ContentType/ContentTypeFieldsMetaForm';
import { hsTextBottomMarginSmall } from 'config/styleConstants';

interface IAddContentTypeFieldModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: (field: INewContentTypeField) => void;
	currentFields: IContentTypeField[];
	type: TContentType;
}

const TESTIDPREFIX = 'addcontenttypefieldmodal';

export const AddContentTypeFieldModal = (props: IAddContentTypeFieldModal) => {
	const { isVisible, onClose, onSubmit, type, currentFields } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { getContentTypeFields, maxFieldCountForFieldType } = useContent(type);

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [values, setValues] = useState<INewContentTypeField>({});
	const [currentStep, setCurrentStep] = useState<number>(0);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!isVisible) {
			setValues({});
			setErrors({});
			setCurrentStep(0);
		}
	}, [isVisible]);

	const _updateField = (fieldName: string, value: any) => {
		setValues({ ...values, [fieldName]: value });
		setIsDirty(true);
	};

	const _isFieldTypeButtonDisabled = (type: string) => {
		const currentFieldsForType = currentFields.filter((e) => e.fieldName.startsWith(type));

		return currentFieldsForType.length === maxFieldCountForFieldType[type];
	};

	const _getNewFieldNameForFieldType = (type: string) => {
		let currentieldsForType = currentFields.filter((e) => e.fieldName.startsWith(type));

		for (let i = 1; i <= maxFieldCountForFieldType[type]; i++) {
			const field = currentieldsForType.find((e) => e.fieldName === type + i);
			if (!field) {
				return type + i;
			}
		}
	};

	const _renderFieldTypeButton = (key: string, icon: string, title: string) => {
		let fieldType;

		if (type === 'schedule') {
			switch (key) {
				case 'extra':
				case 'checkbox':
					return null;
			}
		}

		switch (key) {
			case 'extra':
				fieldType = 'string';
				break;
			case 'checkbox':
				fieldType = 'boolean';
				break;
			case 'category':
				fieldType = 'category';
				break;
			case 'section':
				fieldType = 'section';
				break;
			case 'label':
				fieldType = 'label';
				break;
			default:
				break;
		}

		return (
			<View style={{ width: '33%', flexDirection: 'row', justifyContent: 'center' }}>
				<RoundButton
					isStacked
					isOutline={!values.fieldName?.includes(key)}
					testID={`${TESTIDPREFIX}_fieldtype_${key}`}
					icon={icon}
					onPress={() => {
						setValues({
							fieldName: _getNewFieldNameForFieldType(key),
							fieldType,
							visibility: 'visible',
							selfServiceVisibility: 'always',
							isCustomField: true,
							useAsFilter: 'never'
						});
					}}
					title={title}
					isDisabled={_isFieldTypeButtonDisabled(key)}
				/>
			</View>
		);
	};

	const _renderFieldTypeInfo = () => {
		if (!isEmptyString(values.fieldName)) {
			let str = '';
			if (values.fieldName?.startsWith('extra')) {
				str = t('FieldTypeInfoExtra');
			} else if (values.fieldName?.startsWith('checkbox')) {
				str = t('FieldTypeInfoCheckbox');
			} else if (values.fieldName?.startsWith('section')) {
				str = t('FieldTypeInfoSection');
			} else if (values.fieldName?.startsWith('label')) {
				str = t('FieldTypeInfoLabel');
			}

			return (
				<View style={{ marginBottom: 5, width: '90%', alignSelf: 'center' }}>
					<FormHint testID={`${TESTIDPREFIX}_fieldtype`} hint={str} />
				</View>
			);
		}

		return null;
	};

	const _renderCurrentCountForFieldType = (type: string, label: string) => {
		const currentieldsForType = getContentTypeFields().filter((e) => e.fieldName.startsWith(type));

		if (props.type === 'schedule') {
			switch (type) {
				case 'extra':
				case 'checkbox':
					return null;
			}
		}

		return (
			<Text
				style={{
					fontSize: 12,
					color:
						currentieldsForType.length === maxFieldCountForFieldType[type]
							? theme.danger
							: currentieldsForType.length > maxFieldCountForFieldType[type] * 0.6
							? theme.warning
							: theme.text
				}}
			>
				{t('FieldTypeCountInfo')
					.replace('%CURRENT%', currentieldsForType.length.toString())
					.replace('%MAX%', maxFieldCountForFieldType[type])
					.replace('%LABEL%', label)}
			</Text>
		);
	};

	const _isNextButtonDisabled = () => {
		switch (currentStep) {
			case 2:
				return !values.options ||
					values.options.length === 0 ||
					values.options.find((o) => isEmptyString(o.key) || isEmptyString(o.label))
					? true
					: false;
			case 1:
				return isEmptyString(values.fieldLabel);
			case 0:
				return isEmptyString(values.fieldName);
			default:
				return true;
		}
	};

	const _getExtraStep = () => {
		if (values.fieldType === 'category' || values.fieldType === 'multiswitch') {
			return [
				<ContentTypeFieldsOptionsForm
					testID={TESTIDPREFIX}
					values={values}
					errors={errors}
					onChange={(field, value) => _updateField(field, value)}
					hideCountFields={values.fieldType !== 'category'}
				/>
			];
		}

		return [];
	};

	const _renderFieldTypeButtons = () => {
		return (
			<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
				{_renderFieldTypeButton('extra', EDefaultIconSet.TextField, 'Text')}
				{_renderFieldTypeButton('checkbox', EDefaultIconSet.Checkbox, 'Checkbox')}
				{_renderFieldTypeButton('category', EDefaultIconSet.Category, t('Category'))}
				{_renderFieldTypeButton('section', EDefaultIconSet.Section, t('Section'))}
				{_renderFieldTypeButton('label', EDefaultIconSet.Label, t('Label'))}
			</View>
		);
	};

	const _isCategoryInvalid = () => {
		if (!values.options || values.options?.length === 0) {
			return true;
		}
		const foundIndex = values.options.findIndex((o) => isEmptyString(o.key) || isEmptyString(o.label));
		return foundIndex > -1;
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={() => onSubmit(values)}
			isSubmitDisabled={
				!values.fieldType ||
				isEmptyString(values.fieldName) ||
				isEmptyString(values.fieldLabel) ||
				(values.fieldType === 'category' && _isCategoryInvalid())
			}
			title={t('Add Field')}
		>
			<View style={{ flex: 1 }}>
				<StepWizard
					testIdPrefix={TESTIDPREFIX}
					getCurrentIndex={(val) => setCurrentStep(val)}
					completeFunction={() => onSubmit(values)}
					isLoading={false}
					nextButtonText={currentStep < 1 || (values.fieldType === 'category' && currentStep < 2) ? t('Next') : t('Save')}
					components={[
						<View style={{ flex: 1 }}>
							<Text center style={{ marginBottom: hsTextBottomMarginSmall }}>
								{t('RegistrationFieldSelectType')}
							</Text>
							{_renderFieldTypeButtons()}
							{_renderFieldTypeInfo()}
							<View style={{ width: '90%', alignSelf: 'center' }}>
								{_renderCurrentCountForFieldType('extra', 'Text')}
								{_renderCurrentCountForFieldType('checkbox', 'Checkbox')}
								{_renderCurrentCountForFieldType('category', t('Category'))}
							</View>
						</View>,
						<ContentTypeFieldsMetaForm
							testID={TESTIDPREFIX}
							values={values}
							errors={errors}
							onChange={(field, value) => _updateField(field, value)}
							contentType={type}
						/>,
						..._getExtraStep()
					]}
					isNextButtonDisabled={_isNextButtonDisabled()}
				/>
			</View>
		</HSModal>
	);
};
