import * as Yup from 'yup';
import { showToast } from './toast';
import { t } from 'i18next';
import { IRegistrationField } from 'config/interfaces';

export const validateForm = async (schema: Yup.AnyObjectSchema, item: Record<string, any>) => {
	try {
		await schema.validate(item, { abortEarly: false, context: { item } });
		return null;
	} catch (error: any) {
		let errors = {};
		if (error && error.inner) {
			error.inner.forEach((innerError) => {
				if (innerError?.path.includes('files.')) {
					errors[innerError.path.replace('files.', '')] = innerError.message;
				} else {
					errors[innerError.path] = innerError.message;
				}
			});
			if (Object.keys(errors).length > 0) {
				// Todo: zeige was an wenn Fehler vorhanden sind
			}
		}

		return errors;
	}
};

export const showFormErrorToast = (errors: Record<string, string>, fields?: IRegistrationField[]) => {
	const errorList = Object.entries(errors ?? {});
	const _errors: string[] = [];

	errorList?.forEach((errArr) => {
		const key = fields?.find((e) => e.fieldName === errArr[0])?.fieldLabel ?? t(errArr[0]);
		const msg = errArr[1];
		_errors.push(`${key}: ${msg}`);
	});

	showToast('error', t('yourFormContainsErrors'), _errors.join('\n'));
};
