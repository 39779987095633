import React, { createRef, useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';
import { useTheme } from 'hooks/useTheme';
import { hsInnerPadding } from 'config/styleConstants';
import { Animated, View, ImageBackground, TouchableOpacity, Dimensions,  } from 'react-native';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { ILauncherSpace, IMap, IMapPosition } from 'config/interfaces';
import { ChildButton } from 'components/Button';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import { Icon } from 'components/Icon';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { isEmptyObject } from 'helper/object';


type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ExternalMaps>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ExternalMaps>;
type RouteParams = StackParamList[ERoutes.ExternalMaps];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'maps';
const LOWVALUE = 0;
const HIGHVALUE = 1.0;
const ANIMATIONDURATION = 500;
const ANIMATIONINITIALDELAY = 1000;

export const ExternalMapsScreen = ({ route, navigation }: Props) => {
	const { theme } = useTheme();
	const zoomRef = createRef<ReactNativeZoomableView>();
	const animatedOpacityValue = useRef(new Animated.Value(1));
	const mapRef = useRef<IMap | undefined>();

	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [mapPositions, setMapPositions] = useState<IMapPosition[]>([]);
	const [map, setMap] = useState<IMap | undefined>(undefined);
	const [mapMarker, setMapMarker] = useState<IMapPosition | undefined>(undefined);

	const [isMinZoomed, setIsMinZoomed] = useState<boolean>(true);
	const [isMaxZoomed, setIsMaxZoomed] = useState<boolean>(false);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);

	useEffect(() => {
		document.addEventListener('message', (event) => _handleMessage(event));
		window.addEventListener('message', (event) => _handleMessage(event));

		_postMessage({
			event: 'ready'
		});

		return () => {
			// document.removeEventListener('message', _handleMessage);
			// window.removeEventListener('message', _handleMessage);

			// if (IS_WEB && __DEV__) {
			// 	window.removeEventListener('wheel', (event) => _handleZoom(event));
			// }
		};
	}, []);

	useEffect(() => {
		if (mapRef.current?.id) {
			setIsMinZoomed(true);
			setIsMaxZoomed(false);
		}
	}, [mapRef.current?.id]);

	useEffect(() => {
		if (zoomRef.current) {
			if (IS_WEB && __DEV__) {
				window.addEventListener('wheel', (event) => _handleZoom(event));
			}
		}
	}, [zoomRef]);

	useEffect(() => {
		Animated.loop(
			Animated.sequence([
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					delay: ANIMATIONINITIALDELAY,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: 2500,
					useNativeDriver: true
				})
			])
		).start();
	});

	useEffect(() => {
		if (mapMarker && mapMarker.map?.id === map?.id && map?.image) {
			const { x1, y1, x2, y2 } = mapMarker;

			const xPos = (x1 + x2) / 2;
			const yPos = (y1 + y2) / 2;

			if (zoomRef.current) {
				zoomRef.current
					._zoomToLocation(
						(xPos / 100) * Dimensions.get('window').width,
						(yPos / 100) * Dimensions.get('window').height,
						zoomRef.current['zoomLevel'] + 0.3
					)
					.then(() => {
						setTimeout(() => {
							if (zoomRef.current && zoomRef.current['zoomLevel'] > _getInitialZoom()) {
								const offsetX = (xPos - Dimensions.get('window').width / 2) * zoomRef.current['zoomLevel'];
								const offsetY = (yPos - Dimensions.get('window').height / 2) * zoomRef.current['zoomLevel'];
								zoomRef.current.moveBy((offsetX / 100) * Dimensions.get('window').width, (offsetY / 100) * Dimensions.get('window').height);
							}
						}, 1000);
					});
				setIsMinZoomed(false);
			}
		}
	}, [mapMarker, map?.image, zoomRef.current]);

	const _postMessage = (payload) => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(JSON.stringify(payload));
		} else {
			window.parent.postMessage(payload);
		}
	};

	const _handleMessage = async (event) => {
		if (typeof event.data === 'string') {
			try {
				const parsedData = JSON.parse(event.data);
				setMapMarker(parsedData.mapMarker);
				setMapPositions(parsedData.mapPositions);
				setMap(parsedData.map);
				mapRef.current = parsedData.map;

				if (parsedData.space) {
					setSpace(parsedData.space);
				} else {
					if (route.params.spaceId) {
						const _space = await findSpaceById({ spaceId: route.params.spaceId, noToast: true });

						if (_space?.spaceId) {
							setSpace(_space);
						}
					}
				}
			} catch (error) {}
		}
	};

	const _handleZoom = (event) => {
		if (event.deltaY < 0) {
			if (zoomRef.current && Number(zoomRef.current['zoomLevel'].toFixed(2)) < 1.5) {
				if (Number((zoomRef.current['zoomLevel'] + 0.15).toFixed(2)) >= 1.5) {
					zoomRef.current._zoomToLocation(event.clientX, event.clientY, 1.5);
					setIsMaxZoomed(true);
				} else {
					zoomRef.current._zoomToLocation(event.clientX, event.clientY, zoomRef.current['zoomLevel'] + 0.15);
					setIsMinZoomed(false);
				}
			}
		}

		if (event.deltaY > 0) {
			if (zoomRef.current && zoomRef.current['zoomLevel'] > _getInitialZoom()) {
				if (Number((zoomRef.current['zoomLevel'] - 0.15).toFixed(2)) <= Number(_getInitialZoom().toFixed(2))) {
					zoomRef.current._zoomToLocation(event.clientX, event.clientY, _getInitialZoom());
					setIsMinZoomed(true);
				} else {
					zoomRef.current._zoomToLocation(event.clientX, event.clientY, zoomRef.current['zoomLevel'] - 0.15);
					setIsMaxZoomed(false);
				}
			}
		}
	};

	const _getInitialZoom = () => {
		if (map?.image) {
			if (map?.image.width && map?.image.height) {
				const neededWidthFactor = Dimensions.get('window').width / map?.image.width;
				const neededHeightFactor = Dimensions.get('window').height / map?.image.height;
				return Math.min(neededHeightFactor, neededWidthFactor);
			}
		}
		return 0.4;
	};

	const _renderMapMarker = () => {
		if (mapMarker && map && map.image?.width && map.image?.height && mapMarker.map?.id === map?.id) {
			const { x1, y1, x2, y2 } = mapMarker;

			let calculatedLeft = (x1 / 100) * map.image.width;
			let calculatedTop = (y1 / 100) * map.image.height;
			let calculatedWidth = ((x2 - x1) / 100) * map.image.width;
			let calculatedHeight = ((y2 - y1) / 100) * map.image.height;

			const bubbleSize = Math.min(calculatedHeight, calculatedWidth);

			const borderWidth = Math.min(bubbleSize * 0.15, 30);

			return (
				<TouchableOpacity
					key={`${TESTIDPREFIX}_position_${mapMarker.id}`}
					testID={`${TESTIDPREFIX}_position_${mapMarker.id}`}
					style={{
						position: 'absolute',
						left: calculatedLeft,
						top: calculatedTop,
						width: calculatedWidth,
						height: calculatedHeight
					}}
					activeOpacity={1}
					disabled={map.showBacklinks === false || (!mapMarker.expo && !mapMarker.stage)}
					onPress={() => _handlePress(mapMarker)}
				>
					<Animated.View
						style={{
							opacity: animatedOpacityValue.current,
							height: '100%',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<View style={{ width: bubbleSize, height: bubbleSize, borderRadius: 9999, borderWidth, borderColor: 'red' }} />
					</Animated.View>
				</TouchableOpacity>
			);
		}

		if (map?.showBacklinks === false) {
			return null;
		}

		const marker: JSX.Element[] = [];
		mapPositions.forEach((position) => {
			if (position.expo || position.stage) {
				if (map && map.image?.width && map.image?.height && position.map?.id === map.id) {
					const { x1, y1, x2, y2 } = position;

					let calculatedLeft = (x1 / 100) * map.image.width;
					let calculatedTop = (y1 / 100) * map.image.height;
					let calculatedWidth = ((x2 - x1) / 100) * map.image.width;
					let calculatedHeight = ((y2 - y1) / 100) * map.image.height;

					const bubbleSize = Math.min(calculatedHeight, calculatedWidth) * 0.5;

					marker.push(
						<TouchableOpacity
							key={`${TESTIDPREFIX}_position_${position.id}`}
							testID={`${TESTIDPREFIX}_position_${position.id}`}
							activeOpacity={1}
							disabled={!position.expo && !position.stage}
							style={{
								position: 'absolute',
								left: calculatedLeft,
								top: calculatedTop,
								width: calculatedWidth,
								height: calculatedHeight
							}}
							onPress={() => _handlePress(position)}
						>
							<Animated.View
								key={`mapposition_${position.id}`}
								style={{
									opacity: animatedOpacityValue.current,
									width: '100%',
									height: '100%',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<View
									style={{
										width: bubbleSize,
										height: bubbleSize,
										borderRadius: 9999,
										backgroundColor: theme.lightgray,
										opacity: 0.7
									}}
								/>
							</Animated.View>
						</TouchableOpacity>
					);
				}
			}
		});

		return marker;
	};

	const _renderButtons = () => {
		if (window.parent?.outerWidth && window.parent.outerWidth > 1224) {
			return (
				<View style={{ position: 'absolute', bottom: 10, right: 10, margin: hsInnerPadding, alignItems: 'center' }}>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomIn`}
						onPress={() => {
							if (zoomRef.current && Number(zoomRef.current['zoomLevel'].toFixed(2)) < 1.5) {
								if (Number((zoomRef.current['zoomLevel'] + 0.3).toFixed(2)) >= 1.5) {
									zoomRef.current._zoomToLocation(Dimensions.get('window').width / 2, Dimensions.get('window').height / 2, 1.5);
									setIsMaxZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										zoomRef.current['zoomLevel'] + 0.3
									);
									setIsMinZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMaxZoomed ? 0.6 : 1
						}}
						isDisabled={isMaxZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomIn} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomOut`}
						onPress={() => {
							if (
								zoomRef.current &&
								Number(zoomRef.current['zoomLevel'].toFixed(2)) >= Number(_getInitialZoom().toFixed(2))
							) {
								if (Number((zoomRef.current['zoomLevel'] - 0.3).toFixed(2)) <= Number(_getInitialZoom().toFixed(2))) {
									zoomRef.current._zoomToLocation(Dimensions.get('window').width / 2, Dimensions.get('window').height / 2, _getInitialZoom());
									setIsMinZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										zoomRef.current['zoomLevel'] - 0.3
									);
									setIsMaxZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomOut} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_resetZoom`}
						onPress={() => {
							if (zoomRef.current) {
								zoomRef.current._zoomToLocation(Dimensions.get('window').width / 2, Dimensions.get('window').height / 2, _getInitialZoom());
								setIsMinZoomed(true);
								setIsMaxZoomed(false);
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.Fullscreen} color={theme.primaryContrast} />
					</ChildButton>
				</View>
			);
		}

		return null;
	};

	const _handlePress = (pos: IMapPosition) => {
		let _route = pos.expo ? ERoutes.ExpoDetails : ERoutes.Schedule;
		let _id = pos.expo?.id;
		let _filter = _route === ERoutes.Schedule ? pos.stage?.id : undefined;

		_postMessage({
			event: 'navigate',
			spaceId: space?.spaceId,
			route: _route,
			id: _id,
			filter: _filter
		});
	};

	const _renderMap = () => {
		if (mapRef.current) {
			return (
				<ReactNativeZoomableView
					key={mapRef.current.id}
					ref={zoomRef}
					maxZoom={1.5}
					initialZoom={_getInitialZoom()}
					initialOffsetX={0}
					initialOffsetY={0}
					minZoom={_getInitialZoom()}
					zoomStep={0.3}
					contentWidth={mapRef.current.image?.width}
					contentHeight={mapRef.current.image?.height}
					style={{
						backgroundColor: 'transparent'
					}}
					onDoubleTapAfter={(e, zoom) => {
						if (zoom.zoomLevel) {
							setIsMaxZoomed(Number(zoom.zoomLevel.toFixed(2)) >= 1.5);
							setIsMinZoomed(Number(zoom.zoomLevel.toFixed(2)) <= Number(_getInitialZoom().toFixed(2)));
						}
					}}
				>
					<ImageBackground
						source={{ uri: mapRef.current.image?.url, scale: 1.0 }}
						imageStyle={{
							width: mapRef.current.image?.width,
							height: mapRef.current.image?.height,
							backgroundColor: 'transparent'
						}}
						style={{
							width: mapRef.current.image?.width,
							height: mapRef.current.image?.height
						}}
					>
						{_renderMapMarker()}
					</ImageBackground>
				</ReactNativeZoomableView>
			);
		}

		return null;
	};

	return (
		<ScreenContainer
			bgImageName={space?.backgroundImageName}
			bgImage={space?.backgroundImage}
			style={{
				backgroundColor:
					isEmptyObject(space?.backgroundImage) && isEmptyString(space?.backgroundImageName)
						? space?.backgroundColor ?? theme.background ?? '#000'
						: 'transparent'
			}}
		>
				<View
					style={{
						width: '100%',
						height: '100%',
						alignSelf: 'center'
					}}
				>				
					{_renderMap()}
					{_renderButtons()}
				</View>
		</ScreenContainer>
	);
};

export const ExternalMapsScreenHeader = (props: NativeStackHeaderProps) => {
	return null;
};

export const ExternalMapsScreenRootHeader = (props: NativeStackHeaderProps) => {
	return null;
};
