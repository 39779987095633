import React from 'react';
import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper/fontSize';
import { useTheme } from 'hooks/useTheme';
import { NAVFONTSIZE } from 'components/Form/constants';
import { Dimensions, View } from 'react-native';
import { IS_WEB } from 'helper/platform';

interface INavigationHeaderTitleComponent {
	title?: string;
	color?: string;
}

export const NavigationHeaderTitle = (props: INavigationHeaderTitleComponent) => {
	const { title, color } = props;
	const { theme } = useTheme();
	const screenWidth = Dimensions.get('window').width;
	return (
		<View
			style={{
				position: 'absolute',
				top: 10,
				left: IS_WEB && screenWidth >= 500 ? 160 : 80,
				right: IS_WEB && screenWidth >= 500 ? 160 : 80,
				bottom: 0,
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Text
				testID="header_text_title"
				bold
				center
				style={{
					color: color ?? theme.navigationTextColor ?? theme.text,
					fontSize: normalizeFontSize(NAVFONTSIZE)
				}}
				numberOfLines={2}
			>
				{title}
			</Text>
		</View>
	);
};
