import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderIconButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { EditSpaceInitialFormValues, IContentTypeField, IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import { EditSpaceMetaForm } from 'components/Forms/Space';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { getEditSpaceFormSchema } from 'config/yupSchemas';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import i18next from 'i18next';
import { Keyboard, ScrollView } from 'react-native';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { HEIGHT_PER_MINUTE, LANE_COLUMN_WIDTH_WEB, LANE_COLUMN_WIDTH_APP } from 'components/Schedule';
import {
	getDefaultExpoFields,
	getDefaultRegistrationFields,
	getDefaultScheduleFields,
	getDefaultSpeakerFields
} from 'config/defaultFields';
import { getDefaultMediaItemfields } from 'config/defaultFields/defaultMediaItemFields';
import * as RootNavigation from '../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditSpace>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditSpace>;
type RouteParams = StackParamList[ERoutes.EditSpace];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editspace';

export const EditSpaceScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [formErrors, setFormErrors] = useState<IEditSpaceFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.addListener('focus', () => _updateValues());

		return () => navigation.removeListener('focus', () => _updateValues());
	}, []);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateValues();
		}
	}, [activeSpace]);

	const _updateValues = () => {
		if (activeSpace) {
			setTimeout(() => {
				setFormValues({
					updated_at: activeSpace.updated_at,
					title: activeSpace.title,
					sidebarTitle: activeSpace.sidebarTitle,
					spaceId: activeSpace.spaceId,
					description: activeSpace.description,
					language: activeSpace.language,
					customUserConsentText: activeSpace.customUserConsentText,
					showInSearch: activeSpace.showInSearch,
					forceDoubleOptInForUsers: activeSpace.forceDoubleOptInForUsers,
					allowUserGeneratedContentInFeed: activeSpace.allowUserGeneratedContentInFeed,
					registrationRequired: activeSpace.registrationRequired,
					enablePublicAgenda: activeSpace.enablePublicAgenda,
					startDate: activeSpace.startDate,
					endDate: activeSpace.endDate,
					attendeeSettings: activeSpace.attendeeSettings,
					showUpcomingScheduleOnFeed: activeSpace.showUpcomingScheduleOnFeed,
					allowFeedComments: activeSpace.allowFeedComments,
					allowFeedLikes: activeSpace.allowFeedLikes,
					agendaHeightPerMinuteWeb: activeSpace.agendaHeightPerMinuteWeb ?? HEIGHT_PER_MINUTE,
					agendaWidthPerStageWeb: activeSpace.agendaWidthPerStageWeb ?? LANE_COLUMN_WIDTH_WEB,
					agendaHeightPerMinuteApp: activeSpace.agendaHeightPerMinuteApp ?? HEIGHT_PER_MINUTE,
					agendaWidthPerStageApp: activeSpace.agendaWidthPerStageApp ?? LANE_COLUMN_WIDTH_APP,
					agendaStageAutoWidth: activeSpace.agendaStageAutoWidth ?? false,
					agendaMyDayImage: activeSpace.agendaMyDayImage,
					agendaViewType: activeSpace.agendaViewType ?? 'listAndGrid',
					registrationIntro: activeSpace.registrationIntro,
					loginIntro: activeSpace.loginIntro,
					authHelpText: isEmptyString(activeSpace.authHelpText) ? t('SpaceAuthHelpMarkdown') : activeSpace.authHelpText,
					spacePin: activeSpace.spacePin,
					dataProtectionAcceptedText: activeSpace.dataProtectionAcceptedText,
					dataProtectionAcknowledgedText: activeSpace.dataProtectionAcknowledgedText,
					dataProtectionUrl: activeSpace.dataProtectionUrl,
					readTermsUrl: activeSpace.readTermsUrl,
					allowSharing: activeSpace.allowSharing,
					disableTracking: activeSpace.disableTracking,
					anonymousTracking: activeSpace.anonymousTracking,
					gaTrackingId: activeSpace.gaTrackingId,
					matomoTrackingUrl: activeSpace.matomoTrackingUrl,
					matomoSiteId: activeSpace.matomoSiteId,
					enableGalleryScroll: activeSpace.enableGalleryScroll,
					galleryScrollInterval: activeSpace.galleryScrollInterval ?? 5,
					promotionStartDate: activeSpace.promotionStartDate,
					enablePrestart: activeSpace.enablePrestart ?? false,
					prestartText: activeSpace.prestartText,
					agendaListViewType: activeSpace.agendaListViewType ?? 'list',
					allowParallelBooking: activeSpace.allowParallelBooking ?? false
				});
			}, 100);
		}
	};

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading,
			isRightNavPressDisabled: _isRightNavPressDisabled()
		});
	}, [formValues, isLoading, isFormLocked]);

	const _isRightNavPressDisabled = () => {
		return isEmptyString(formValues.title) || isEmptyString(formValues.startDate) || isEmptyString(formValues.endDate);
	};

	const _updateSpace = async () => {
		const errors = await validateForm(getEditSpaceFormSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Space has changed'),
				message: t('SpaceFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsLoading(true);

		let updateSuccess;

		if (activeSpace?.language !== formValues.language) {
			const oldDefaultFields = {
				registration: getDefaultRegistrationFields(),
				schedule: getDefaultScheduleFields(),
				expo: getDefaultExpoFields(),
				speaker: getDefaultSpeakerFields(),
				mediaItem: getDefaultMediaItemfields()
			};

			const oldSpaceFields = {
				registration: activeSpace?.registrationFields?.fields,
				schedule: activeSpace?.scheduleFields?.fields,
				expo: activeSpace?.expoFields?.fields,
				speaker: activeSpace?.speakerFields?.fields,
				mediaItem: activeSpace?.mediaItemFields?.fields
			};

			updateSuccess = await updateSpace({ data: formValues, noToast: true });

			const newDefaultFields = {
				registration: getDefaultRegistrationFields(),
				schedule: getDefaultScheduleFields(),
				expo: getDefaultExpoFields(),
				speaker: getDefaultSpeakerFields(),
				mediaItem: getDefaultMediaItemfields()
			};

			const translatedFields = {
				registration: [],
				schedule: [],
				expo: [],
				speaker: [],
				mediaItem: []
			};

			Object.keys(oldSpaceFields).map((fieldCatKey) => {
				oldSpaceFields[fieldCatKey]?.map((field: IContentTypeField) => {
					const found = oldDefaultFields[fieldCatKey].find(
						(d) => d.fieldLabel === field.fieldLabel && field.fieldHint === d.fieldHint
					);
					if (found) {
						const newFound = newDefaultFields[fieldCatKey].find((d) => d.fieldName === field.fieldName);
						if (newFound) {
							translatedFields[fieldCatKey].push({
								...field,
								fieldLabel: newFound.fieldLabel,
								fieldHint: newFound.fieldHint
							});
						}
					} else {
						translatedFields[fieldCatKey].push(field);
					}
				});
			});

			const newSpace = {
				...formValues,
				registrationFields: {
					fields: translatedFields.registration
				},
				scheduleFields: {
					fields: translatedFields.schedule
				},
				expoFields: {
					fields: translatedFields.expo
				},
				speakerFields: {
					fields: translatedFields.speaker
				},
				mediaItemFields: {
					fields: translatedFields.mediaItem
				}
			};

			updateSuccess = await updateSpace({ data: newSpace });
		} else {
			updateSuccess = await updateSpace({ data: formValues });
		}

		setIsLoading(false);
		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _handleUpdate = (values) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(values);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<EditSpaceMetaForm values={formValues} errors={formErrors} onChange={_handleUpdate} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const EditSpaceScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Edit')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
