import { createModel } from '@rematch/core';
import { IUploadMediaParams } from 'rematch/interfaces';
import { RootModel } from './index';
import * as mime from 'react-native-mime-types';
import { MULTISPACEURL } from 'config/constants';
import { IS_WEB } from 'helper/platform';
import { IMedia } from 'config/interfaces';
import { v4 } from 'uuid';

type IDefaultState = {
	spaceMedia: IMedia[];
	lastMediaRead: Record<string, string>;
};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const upload = createModel<RootModel>()({
	state: {
		spaceMedia: [],
		lastMediaRead: {}
	} as IDefaultState,
	reducers: {
		addMedia(state, newMedia: IDefaultState['spaceMedia']) {
			rematchLog('addMedia');
			const _updatedMedia = state.spaceMedia ? [...state.spaceMedia] : [];
			let lastRead = state.lastMediaRead ? { ...state.lastMediaRead } : {};

			newMedia.forEach((media) => {
				const found = _updatedMedia.findIndex((e) => e.id === media.id);

				if (found !== -1) {
					_updatedMedia[found] = media;
				} else {
					_updatedMedia.push(media);
				}
				if (!lastRead[media.spaceId] || media.updated_at > lastRead[media.spaceId]) {
					lastRead[media.spaceId] = media.updated_at;
				}
			});

			return {
				...state,
				spaceMedia: _updatedMedia,
				lastMediaRead: lastRead
			};
		},
		clear(state) {
			rematchLog('clear upload');
			return {
				spaceMedia: [],
				lastMediaRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async getSpaceMedia(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const spacePrefix = `__hspx__${activeSpace.spaceId}__`;
					const lastRead =
						store.upload.lastMediaRead && store.upload.lastMediaRead[activeSpace.spaceId]
							? store.upload.lastMediaRead[activeSpace.spaceId]
							: ZEROHOUR;

					const res = await dispatch.request.anonymousRequest({
						method: 'GET',
						url: `${MULTISPACEURL}/upload/files?updated_at_gt=${lastRead}&name_contains=${spacePrefix}&_limit=-1`
					});

					if (res && Array.isArray(res)) {
						dispatch.upload.addMedia(
							res.map((item) => {
								return {
									...item,
									spaceId: activeSpace.spaceId
								};
							})
						);
					}
				}
			} catch (error) {
				console.log('getSpaceMedia', error);
			}
		},
		async uploadMedia(payload: IUploadMediaParams, store) {
			try {
				const { uri, imageId, refId, ref, field, source, endpoint, space, caption } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				let mySpace = space ?? activeSpace;

				let file;

				const fileNamePrefix = mySpace?.spaceId ? `__hspx__${mySpace.spaceId}__` : '';

				const imageBody = new FormData();
				if (IS_WEB) {
					const base64Response = await fetch(uri);
					const blob = await base64Response.blob();
					const splitted = uri.split(';');
					const mimeType = splitted[0]?.split(':')[1] ?? blob.type;
					file = new File([blob], `${fileNamePrefix}${v4()}.${mime.extension(mimeType)}`, { type: mimeType });
					imageBody.append('files', file);
				} else {
					const match = /\.(\w+)$/.exec(uri as string);

					if (match && match[0]) {
						const mimeType = mime.lookup(match[0]);
						imageBody.append('files', {
							uri,
							name: `${fileNamePrefix}${v4()}.${mime.extension(mimeType)}`,
							type: mimeType
						}); // FEHLER IGNORIEREN
					}
				}
				if (refId) {
					imageBody.append('refId', refId.toString());
				}
				if (ref) {
					imageBody.append('ref', ref);
				}
				if (field) {
					imageBody.append('field', field);
				}

				if (source) {
					imageBody.append('source', source);
				}

				imageBody.append('fileInfo', JSON.stringify({ caption: caption ?? null }));

				let url = `${endpoint ?? MULTISPACEURL}/upload`;
				if (imageId) {
					url = `${url}?id=${imageId}`;
				}

				return await dispatch.request.anonymousRequest({
					method: 'POST',
					url,
					body: imageBody
				});
			} catch (error) {
				console.log('uploadMedia', error);
				return null;
			}
		}
	})
});
