import { DEFAULT_PLATFORM_URL, GOOGLE_PLAYSTORE_URL, IOS_APPSTORE_URL } from 'config/envConstants';
import { IMailTemplate } from 'config/interfaces';
import i18next from 'i18next';

export const _getPasswordResetEmail = () => {
	const obj = {
		key: 'passwordRecovery',
		contentType: 'attendee',
		title: i18next.t('PasswortRecoveryEmail'),
		subject: i18next.t('PasswortRecoveryEmail'),
		body: i18next.t('PasswortRecoveryEmailBody').replace('%PLACEHOLDER%', passwordResetEmailLinkPlaceholder)
	};

	return obj as IMailTemplate;
};

export const _getAccountConfirmationEmail = () => {
	const obj = {
		key: 'confirmAccount',
		contentType: 'attendee',
		title: i18next.t('AccountConfirmationEmail'),
		subject: i18next.t('AccountConfirmationEmail'),
		body: i18next.t('AccountConfirmationEmailBody').replace('%PLACEHOLDER%', confirmAccountEmailLinkPlaceholder)
	};

	return obj as IMailTemplate;
};

export const _getNewAdministratorEmail = () => {
	const obj = {
		key: 'newAdmin',
		contentType: 'admin',
		title: i18next.t('NewAdmin'),
		subject: i18next.t('NewAdminEmailSubject'),
		body: i18next.t('NewAdminEmailBody').replace('%SPACEID%', spaceIdPlaceholder).replace('%LINK%', shareLinkPlaceholder)
	};

	return obj as IMailTemplate;
};

export const _getNewModeratorEmail = () => {
	const obj = {
		key: 'newModerator',
		contentType: 'moderator',
		title: i18next.t('NewModerator'),
		subject: i18next.t('NewModeratorEmailSubject'),
		body: i18next.t('NewModeratorEmailBody').replace('%SPACEID%', spaceIdPlaceholder).replace('%LINK%', shareLinkPlaceholder)
	};

	return obj as IMailTemplate;
};

export const _getExpoSelfServiceInvitation = () => {
	const obj = {
		key: 'expoSelfServiceInvitation',
		contentType: 'expo',
		title: i18next.t('ExpoSelfServiceInvitation'),
		subject: i18next.t('ExpoSelfServiceInvitationEmailSubject'),
		body: i18next.t('ExpoSelfServiceInvitationEmailBody')
	};

	return obj as IMailTemplate;
};

export const _getSpeakerSelfServiceInvitation = () => {
	const obj = {
		key: 'speakerSelfServiceInvitation',
		contentType: 'speaker',
		title: i18next.t('SpeakerSelfServiceInvitation'),
		subject: i18next.t('SpeakerSelfServiceInvitationEmailSubject'),
		body: i18next.t('SpeakerSelfServiceInvitationEmailBody')
	};

	return obj as IMailTemplate;
};

export const _getHelloSpacesTicketInvitation = () => {
	const obj = {
		key: 'helloSpacesTicketInvitation',
		contentType: 'ticket',
		title: i18next.t('HelloSpacesTicketInvitation'),
		subject: i18next.t('HelloSpacesTicketInvitationEmailSubject'),
		body: i18next.t('HelloSpacesTicketInvitationEmailBody')
	};

	return obj as IMailTemplate;
};

export const _getInviteUnregisteredAdmin = () => {
	const obj = {
		key: 'newUnregisteredAdmin',
		contentType: 'invitation',
		title: i18next.t('inviteUnregisteredAdminEmailTitle'),
		subject: i18next.t('inviteUnregisteredAdminEmailSubject'),
		body: i18next.t('inviteUnregisteredAdminEmailBody').replace('%REGISTRATIONURL%', `${DEFAULT_PLATFORM_URL}app/auth/register`).replace('%IOS_APPSTORE_URL%',IOS_APPSTORE_URL).replace('%GOOGLE_PLAYSTORE_URL%',GOOGLE_PLAYSTORE_URL)
	};

	return obj as IMailTemplate;
};

export const _getDefaultHelloSpacesInvitation = (hasTickets?: boolean) => {
	const obj = {
		key: 'defaultHelloSpacesInvitation',
		contentType: 'invitation',
		title: i18next.t('DefaultHelloSpacesInvitation'),
		subject: i18next.t('YouHaveBeenInvited'),
		body: hasTickets ? i18next.t('DefaultHelloSpacesEmailBody') : i18next.t('DefaultHelloSpacesEmailBodyNoTicket')
	};

	return obj as IMailTemplate;
};

export const passwordResetEmailLinkPlaceholder = '<%= RECOVERYLINK %>';
export const spaceIdPlaceholder = '<%= space.spaceId %>';
export const shareLinkPlaceholder = '<%= SHARELINK %>';
export const onboardingLinkPlaceholder = '<%= ONBOARDINGLINK %>';
export const confirmAccountEmailLinkPlaceholder = '<%= CONFIRMATIONLINK %>';
export const selfServiceInvitationEmailLinkPlaceholder = '<%= item.SELFSERVICELINK %>';
export const helloSpacesTicketInvitationEmailPlaceholder = '<%= item.TICKETCODELINK %>';
