import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from 'components/Form/FormTextInput';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { VimeoPlayer } from 'components/Video';
interface IStreamInputVimeo {
	testID: string;
	onChange: (vimeoUrl: string | null) => void;
	value?: string;
	error?: string;
}

export const StreamInputVimeo = (props: IStreamInputVimeo) => {
	const { testID, onChange, value, error } = props;
	const { t } = useTranslation();

	const [input, setInput] = useState<string>('');
	const [validatedUrl, setValidatedUrl] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (value) {
			setInput(value);
			setValidatedUrl(value);
		}
	}, [value]);

	const _validateUrl = () => {
		if (!input.startsWith('https://') && !input.startsWith('www')) {
			_setValidatedUrl(input);
		} else if (input.startsWith('https://player.vimeo.com/video') || input.startsWith('https://www.player.vimeo.com/video')) {
			const splitted = input.split('?');
			let split: string[] = [];
			if (splitted[0].startsWith('https://player.vimeo.com/video')) {
				split = splitted[0].split('https://player.vimeo.com/video/');
			} else {
				split = splitted[0].split('https://www.player.vimeo.com/video/');
			}
			_setValidatedUrl(split[1]);
		} else if (input.startsWith('https://player.vimeo.com/event') || input.startsWith('https://www.player.vimeo.com/event')) {
			const splitted = input.split('?');
			let split: string[] = [];
			if (splitted[0].startsWith('https://player.vimeo.com/event')) {
				split = splitted[0].split('https://player.vimeo.com/event/');
			} else {
				split = splitted[0].split('https://www.player.vimeo.com/event/');
			}
			_setValidatedUrl(`event/${split[1]}`, true);
		} else if (input.startsWith('https://vimeo.com/') || input.startsWith('https://www.vimeo.com/')) {
			const splitted = input.split('?');
			let split: string[] = [];
			if (splitted[0].startsWith('https://vimeo.com/')) {
				split = splitted[0].split('https://vimeo.com/');
			} else {
				split = splitted[0].split('https://www.vimeo.com/');
			}
			if (split[1]?.includes('event') && !split[1]?.includes('embed')) {
				const eventSplitted = split[1].split('/');
				if (eventSplitted[0] === 'event') {
					eventSplitted.push(eventSplitted[2]);
					eventSplitted[2] = 'embed';
				}
				_setValidatedUrl(eventSplitted.join('/'));
			} else {
				_setValidatedUrl(split[1]);
			}
		} else {
			setValidatedUrl(input);
		}
	};

	const _setValidatedUrl = (videoId: string, isEvent?: boolean) => {
		if (videoId.includes('?')) {
			videoId = videoId.split('?')[0];
		}
		if (videoId.includes('.com/')) {
			videoId = videoId.split('.com/')[1];
		}
		let embedUrl = '';
		if (videoId.includes('event') || isEvent) {
			embedUrl = `https://vimeo.com/${videoId}`;
		} else {
			embedUrl = `https://player.vimeo.com/video/${videoId}`;
		}
		setValidatedUrl(embedUrl);
		onChange(embedUrl);
	};

	return (
		<View>
			<FormTextInput
				testID={`${testID}_textinput_url`}
				label="URL"
				isRequired
				hint={t('VimeoUrlHint')}
				value={input}
				error={error}
				onChangeText={(val) => {
					setInput(val);
					setValidatedUrl(undefined);
					onChange(null);
				}}
				formStyle={{ marginBottom: 10 }}
			/>
			<RoundButton
				testID={`${testID}_button_validate`}
				icon={EDefaultIconSet.Save}
				onPress={_validateUrl}
				title={t('Validate')}
				isDisabled={isEmptyString(input)}
				alignSelf="flex-end"
			/>
			{!isEmptyString(validatedUrl) && <VimeoPlayer url={validatedUrl} />}
		</View>
	);
};
