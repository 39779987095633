import * as Sharing from 'expo-sharing';
import { exportComponentAsPNG } from 'react-component-export-image';

export const share = (title: string, subject: string, url: string, message: string) => {
	Sharing.shareAsync(url, { dialogTitle: title });
};

export const exportRefAsPNG = (ref, fileName) => {
	try {
		// Drin lassen - JW
		exportComponentAsPNG(ref, { fileName });
	} catch {}
};
