import React, { useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { Text, VoteSum } from 'components/Text';
import { HorizontalBar } from './HorizontalBar';
import { IWordListItem } from 'components/Vote/ActiveVote/VoteWordCloud/WordCloud';
import { Icon } from 'components/Icon';
import { ChildButton } from 'components/Button';
import { hsBottomMargin } from 'config/styleConstants';

interface IVerticalBarChart {
	data: IWordListItem[];
	totalVotesSubmitted: number;
	distinctAnswersSubmitted?: number;
	highestCount: number;
	hideStats?: boolean;
	isIcons?: boolean;
	largerDesign?: boolean;
	onPress?: (index: number) => any;
	hideSum?: boolean;
}

export const VerticalBarChart = (props: IVerticalBarChart) => {
	const { data, highestCount, totalVotesSubmitted, distinctAnswersSubmitted, hideStats, isIcons, largerDesign, onPress, hideSum } = props;

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [barHeight, setBarHeight] = useState<number>(largerDesign ? 40 : 30);
	const [textSize, setTextSize] = useState<number>(largerDesign ? 24 : 16);
	const [answerValue, setAnswerValue] = useState<string>((distinctAnswersSubmitted ?? totalVotesSubmitted).toString());

	useEffect(() => {
		let _answerValue = (distinctAnswersSubmitted ?? totalVotesSubmitted).toString();

		if (distinctAnswersSubmitted && totalVotesSubmitted > distinctAnswersSubmitted) {
			_answerValue = `${distinctAnswersSubmitted} (${totalVotesSubmitted})`;
		}

		if (answerValue !== _answerValue) {
			setAnswerValue(_answerValue);
		}
	}, [totalVotesSubmitted, distinctAnswersSubmitted]);

	useEffect(() => {
		let _barHeight;
		let _textSize;
		if (data.length >= 9) {
			_barHeight = largerDesign ? 25 : 15;
			_textSize = largerDesign ? 16 : 11;
		} else if (data.length >= 7) {
			_barHeight = largerDesign ? 30 : 20;
			_textSize = largerDesign ? 18 : 12;
		} else if (data.length >= 5) {
			_barHeight = largerDesign ? 35 : 25;
			_textSize = largerDesign ? 21 : 14;
		} else {
			_barHeight = largerDesign ? 40 : 30;
			_textSize = largerDesign ? 24 : 16;
		}

		if (Dimensions.get('window').height > 1280) {
			_barHeight = _barHeight + 10;
			_textSize = _textSize + 4;
		} else if (Dimensions.get('window').height > 900) {
			_barHeight = _barHeight + 5;
			_textSize = _textSize + 2;
		}
		setBarHeight(_barHeight);
		setTextSize(_textSize);
	}, [data]);

	const _renderItem = (item: IWordListItem, index: number) => {
		return (
			<ChildButton
				key={`barchart_item_${index}`}
				testID={`barchart_item_${index}`}
				style={{ marginBottom: hsBottomMargin }}
				onPress={() => {
					if (onPress) {
						onPress(index);
					}
				}}
				touchSoundDisabled
				activeOpacity={1}
			>
				<View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
					{isIcons ? (
						<Icon name={item.text} size={largerDesign ? 30 : 20} />
					) : (
						<Text bold style={{ flex: 1, fontSize: textSize }}>
							{item.text}
						</Text>
					)}
					{!hideStats && (
						<Text bold style={{ fontSize: textSize, marginBottom: 5 }}>{`${
							totalVotesSubmitted > 0 ? ((item.count / totalVotesSubmitted) * 100).toFixed(0) : 0
						}%`}</Text>
					)}
				</View>

				<HorizontalBar height={barHeight} width={highestCount > 0 ? (item.count / highestCount) * containerWidth : 1} />
			</ChildButton>
		);
	};

	return (
		<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
			{data.map((item, idx) => _renderItem(item, idx))}
			{!hideSum && <VoteSum largerDesign={largerDesign} value={answerValue} />}
		</View>
	);
};
