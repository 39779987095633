import { IKVP } from 'components/Form';
import i18next, { t } from 'i18next';
import { IStrapiBase } from './main';

export type TTrackingContentType =
	| 'attendee'
	| 'schedule'
	| 'speaker'
	| 'expo'
	| 'expodownload'
	| 'mediaitem'
	| 'networkingroom'
	| 'meeting'
	| 'chat'
	| 'vote'
	| 'feed'
	| 'stream'
	| 'space';

export type TTrackingAction =
	| 'Open Detail'
	| 'Add to Favorite'
	| 'Remove from Favorites'
	| 'Join Room'
	| 'Add to Calendar'
	| 'Request Meeting'
	| 'Accept Meeting'
	| 'Decline Meeting'
	| 'Reschedule Meeting'
	| 'Send Message'
	| 'Download File'
	| 'Play Video'
	| 'Stop Video'
	| 'Submit Vote Answer'
	| 'Like'
	| 'Un-Like'
	| 'Comment'
	| 'Lead Scan'
	| 'Register'
	| 'Login'
	| 'Open External URL'
	| 'Open Internal Reference'
	| 'Book Slot'
	| 'Cancel Booking'
	| 'Open LinkedIn'
	| 'Open XING'
	| 'Open Twitter'
	| 'Open Instagram'
	| 'Open Facebook'
	| 'Open YouTube'
	| 'Open TikTok'
	| 'Open Blog'
	| 'Open Website'
	| 'Open Email'
	| 'Show On Map'
	| 'Share';

export interface IReportData {
	itemId: string;
	type: TTrackingContentType;
	items: IReport[];
	date: string;
}

const TYPES_WITH_FAVORITE = ['attendee', 'schedule', 'expo'];
const TYPES_WITH_CALENDAR = ['meeting', 'schedule'];
const TYPES_WITH_SOCIAL = ['expo'];
const TYPES_WITH_SHARE = ['space', 'schedule', 'speaker', 'expo', 'mediaitem'];
const TYPES_WITH_MAPPOSITION = ['expo'];
const TYPES_WITH_VIDEO = ['mediaitem', 'expo', 'feed', 'schedule'];

export interface ITrackingObject {
	type: TTrackingContentType;
	action: TTrackingAction;
	spaceId: string;
	userId: string;
	date?: string;
	itemId?: string;
	info?: Record<string, string>;
}

export interface IReport extends IStrapiBase {
	type: TTrackingContentType;
	action: TTrackingAction;
	date: string;
	spaceId: string;
	data?: Record<string, any>;
	itemId?: string;
}

export const getTrackingTypes = () => {
	const arr: IKVP[] = [
		{
			key: 'attendee',
			label: i18next.t('Attendees')
		},
		{
			key: 'space',
			label: 'Space'
		},
		{
			key: 'schedule',
			label: i18next.t('agenda')
		},
		{
			key: 'expo',
			label: i18next.t('expos')
		},
		{
			key: 'speaker',
			label: i18next.t('speakers')
		},
		{
			key: 'mediaitem',
			label: i18next.t('Media Items')
		},
		{
			key: 'networkingroom',
			label: i18next.t('networking')
		},
		{
			key: 'vote',
			label: i18next.t('votings')
		},
		{
			key: 'feed',
			label: i18next.t('feed')
		}
	].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

	return arr;
};

export const getTrackingActions = (type: TTrackingContentType) => {
	let arr: IKVP[] = [];

	switch (type) {
		case 'meeting':
			arr = [
				{
					key: 'Request Meeting',
					label: i18next.t('Request Meeting')
				},
				{
					key: 'Accept Meeting',
					label: i18next.t('Accept Meeting')
				},
				{
					key: 'Decline Meeting',
					label: i18next.t('Decline Meeting')
				},
				{
					key: 'Reschedule Meeting',
					label: i18next.t('Reschedule Meeting')
				}
			];
			break;
		case 'networkingroom':
			break;
		case 'vote':
			arr = [
				{
					key: 'Submit Vote Answer',
					label: i18next.t('Submit Vote Answer')
				}
			];
			break;
		default:
			arr = [
				{
					key: 'Open Detail',
					label: i18next.t('Open Detail')
				}
			];
			break;
	}

	if (TYPES_WITH_FAVORITE.includes(type)) {
		arr.push({
			key: 'Add to Favorite',
			label: i18next.t('Add to Favorite')
		});
		arr.push({
			key: 'Remove from Favorites',
			label: i18next.t('Remove from Favorites')
		});
	}

	if (TYPES_WITH_CALENDAR.includes(type)) {
		arr.push({
			key: 'Add to Calendar',
			label: i18next.t('Add to Calendar')
		});
	}

	if (TYPES_WITH_SOCIAL.includes(type)) {
		arr.push({
			key: 'Open LinkedIn',
			label: i18next.t('Open LinkedIn')
		});
		arr.push({
			key: 'Open XING',
			label: i18next.t('Open XING')
		});
		arr.push({
			key: 'Open Twitter',
			label: i18next.t('Open Twitter')
		});
		arr.push({
			key: 'Open Instagram',
			label: i18next.t('Open Instagram')
		});
		arr.push({
			key: 'Open Facebook',
			label: i18next.t('Open Facebook')
		});
		arr.push({
			key: 'Open YouTube',
			label: i18next.t('Open YouTube')
		});
		arr.push({
			key: 'Open TikTok',
			label: i18next.t('Open TikTok')
		});
		arr.push({
			key: 'Open Blog',
			label: i18next.t('Open Blog')
		});
		arr.push({
			key: 'Open Website',
			label: i18next.t('Open Website')
		});
		arr.push({
			key: 'Open Email',
			label: i18next.t('Open Email')
		});
	}

	if (TYPES_WITH_SHARE.includes(type)) {
		arr.push({
			key: 'Share',
			label: i18next.t('Share')
		});
	}

	if (TYPES_WITH_MAPPOSITION.includes(type)) {
		arr.push({
			key: 'Show On Map',
			label: i18next.t('Open Map')
		});
	}

	if (TYPES_WITH_VIDEO.includes(type)) {
		arr.push({
			key: 'Play Video',
			label: i18next.t('Play Video')
		});
		arr.push({
			key: 'Stop Video',
			label: i18next.t('Stop Video')
		});
	}

	// Für zusätzliche Actions
	switch (type) {
		case 'attendee':
			arr.push({
				key: 'Lead Scan',
				label: i18next.t('Lead Scan')
			});
			break;
		case 'expo':
			arr.push({
				key: 'Download File',
				label: i18next.t('Download File')
			});
			break;
		case 'feed':
			arr.push({
				key: 'Like',
				label: i18next.t('Like')
			});
			arr.push({
				key: 'Un-Like',
				label: i18next.t('Un-Like')
			});
			arr.push({
				key: 'Comment',
				label: i18next.t('Comment')
			}),
				arr.push({
					key: 'Open External URL',
					label: i18next.t('Open External URL')
				});
			break;
		case 'schedule':
			arr.push({
				key: 'Book Slot',
				label: i18next.t('Book Slot')
			});
			arr.push({
				key: 'Cancel Booking',
				label: i18next.t('Cancel Booking')
			});
			break;
		case 'networkingroom':
			arr.push({
				key: 'Join Room',
				label: i18next.t('Join Room')
			});
			break;
		default:
			break;
	}

	arr.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

	return arr;
};

interface IBasicStatistic {
	overall: number;
	today: number;
}
interface IMeetingStatistic {
	request: number;
	accepted: number;
	rescheduled: number;
	declined: number;
}
interface IChatStatistic {
	messagesSent: number;
	uniqueUsers: number;
}
interface IFavoriteStatistic {
	overall: {
		added: number;
		removed: number;
	};
	today: {
		added: number;
		removed: number;
	};
}

export interface IStatistics {
	onlineUsers: number;
	user: {
		inSpace: number;
		devices: {
			android: number;
			ios: number;
		};
		chatNotAllowed: number;
		meetingNotAllowed: number;
		profileHidden: number;
	};
	leadScan: IBasicStatistic;
	push: IBasicStatistic;
	meeting: {
		overall: IMeetingStatistic;
		today: IMeetingStatistic;
	};
	chat: {
		overall: IChatStatistic;
		today: IChatStatistic;
	};
	feedComments: IBasicStatistic;
	favorites: {
		attendee: IFavoriteStatistic;
		schedule: IFavoriteStatistic;
		expo: IFavoriteStatistic;
	};
}
