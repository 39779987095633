import { Audio } from 'expo-av';

const testSound = require('../assets/sounds/test_sound.wav');
const userJoin = require('../assets/sounds/user_join.wav');
const userLeave = require('../assets/sounds/user_leave.wav');

type TSoundFile = 'testSound' | 'userJoin' | 'userLeave';

export const playSound = async (file: TSoundFile) => {
	let res;
	switch (file) {
		case 'testSound':
			res = await Audio.Sound.createAsync(
				{
					uri: testSound
				},
				{ shouldPlay: true }
			);
			res.sound.playAsync();

			break;
		case 'userJoin':
			res = await Audio.Sound.createAsync(
				{
					uri: userJoin
				},
				{ shouldPlay: true }
			);
			res.sound.playAsync();
			break;
		case 'userLeave':
			res = await Audio.Sound.createAsync(
				{
					uri: userLeave
				},
				{ shouldPlay: true }
			);
			res.sound.playAsync();
			break;

		default:
			break;
	}
};
