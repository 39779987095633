import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import { IBookingCount, IUserBooking } from 'config/interfaces';
import { getAccessRightsForSpace, hasEntryChanged, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import {
	IAddBookingPayload,
	IBookSlotPayload,
	ICancelBookingPayload,
	IGetBookingCountPayload,
	IRemoveBookingPayload
} from 'rematch/interfaces';
import { RootModel } from './index';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	allBookings: IUserBooking[];
	lastAllBookingsRead: string;
	bookings: IUserBooking[];
	bookingCounts: IBookingCount[];
};

export const booking = createModel<RootModel>()({
	state: {
		allBookings: [],
		lastAllBookingsRead: ZEROHOUR,
		bookings: [],
		bookingCounts: []
	} as IDefaultState,
	reducers: {
		addToAllBookings(state, { spaceId, load, bookings }: { spaceId: string; load: boolean; bookings: IUserBooking[] }) {
			rematchLog('addToAllBookings');
			let _bookings = state.allBookings ? [...state.allBookings] : [];

			_bookings = _bookings.filter((e) => e.spaceId === spaceId);
			_bookings = _bookings.concat(bookings);

			return {
				...state,
				allBookings: _bookings
			};
		},
		addBookings(state, { spaceId, bookings }) {
			rematchLog('addBookings');
			let _bookings = state.bookings ? [...state.bookings] : [];

			_bookings = _bookings.filter((e) => e.spaceId !== spaceId);
			_bookings = _bookings.concat(bookings);

			return {
				...state,
				bookings: _bookings
			};
		},
		setBookingCounts(state, counts) {
			return {
				...state,
				bookingCounts: counts
			};
		},
		addBookingCount(state, bookingCount) {
			const counts = state.bookingCounts ? [...state.bookingCounts] : [];

			const idx = counts.findIndex(
				(e) => e.itemId === bookingCount.itemId && e.spaceId === bookingCount.spaceId && e.type === bookingCount.type
			);
			if (idx === -1) {
				counts.push(bookingCount);
			} else {
				counts[idx] = bookingCount;
			}

			return {
				...state,
				bookingCounts: counts
			};
		},
		clear(state) {
			rematchLog('clearing bookings');
			return {
				allBookings: [],
				bookings: [],
				lastAllBookingsRead: ZEROHOUR,
				bookingCounts: []
			};
		}
	},
	effects: (dispatch) => ({
		async startMyBookingsSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (
					activeSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_mybookings`)
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_mybookings`, async (data) => {
						const _load = data.load;
						const _bookings: IUserBooking[] = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'mybookings received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'mybookings', value: false });
							if (!store.temp.hasLoadedData) {
								dispatch.temp.setHasLoadedData('mybookings');
							}
						}

						dispatch.booking.addBookings({
							spaceId: activeSpace.spaceId,
							bookings: _bookings,
							load: _load
						});
					});
				}
			} catch (error) {
				errorLog('startMyBookingsSync', error);
			}
		},
		async loadMyBookings(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					if (store.temp.hasLoadedData?.mybookings) {
						contentLoadLog(3, 'mybookings already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.mybookings) {
						contentLoadLog(3, 'mybookings load already in progress. skipping');
						return;
					}

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading mybookings');
							dispatch.temp.setWaitingForSocketResponse({ key: 'mybookings', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'mybookings',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									spaceId: activeSpace.spaceId,
									userInfos: store.auth.userInfos
								})
							);

							dispatch.temp.setWaitingForSocketResponse({ key: 'mybookings', value: true });

							const res = await dispatch.request.anonymousRequest({
								method: 'POST',
								url: `${MULTISPACEURL}/userbookings/mybookings`,
								body
							});

							dispatch.temp.setWaitingForSocketResponse({ key: 'mybookings', value: false });

							if (res && Array.isArray(res)) {
								dispatch.booking.addBookings({ spaceId: activeSpace.spaceId, bookings: res });
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadMyBookings', error);
			}
		},
		async getAllBookingCounts(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					if (store.temp.hasLoadedData?.allBookingCounts && !payload.skip) {
						contentLoadLog(3, 'allBookingCounts already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.allBookingCounts) {
						contentLoadLog(3, 'allBookingCounts load already in progress. skipping');
						return;
					}

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId
						})
					);

					dispatch.temp.setWaitingForSocketResponse({ key: 'allBookingCounts', value: true });

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userbookings/allbookingcounts`,
						body
					});
					dispatch.temp.setWaitingForSocketResponse({ key: 'allBookingCounts', value: false });

					if (res && Array.isArray(res)) {
						dispatch.temp.setHasLoadedData('allBookingCounts');
						dispatch.booking.setBookingCounts(res);
					}
				}
			} catch (error) {
				console.log('getBookingCount', error);
			}
		},
		async getBookingCount(payload: IGetBookingCountPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { itemId, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							itemId,
							type
						})
					);

					return await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userbookings/bookingcount`,
						body
					});
				}

				return 0;
			} catch (error) {
				console.log('getBookingCount', error);
				return 0;
			}
		},
		async bookSlot(payload: IBookSlotPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { itemId, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							itemId,
							type,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userbookings/bookslot`,
						body
					});

					if (res && Array.isArray(res)) {
						dispatch.booking.addBookings({
							spaceId: activeSpace.spaceId,
							bookings: res
						});
						showToast('success', i18next.t('Slot Booked'));
						dispatch.booking.getAllBookingCounts({ skip: true });
						return true;
					}
					await dispatch.booking.getBookingCount({ itemId, type });
					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('bookSlot', error);
			}
		},
		async cancelBooking(payload: ICancelBookingPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { itemId, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							itemId,
							type,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userbookings/cancelbooking`,
						body
					});

					if (res.bookings) {
						dispatch.booking.addBookings({
							spaceId: activeSpace.spaceId,
							bookings: res.bookings
						});
						dispatch.booking.getAllBookingCounts({ skip: true });

						return res.count;
					}
				}

				return 0;
			} catch (error) {
				console.log('cancelBooking', error);
			}
		},
		async addBooking(payload: IAddBookingPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { emails, itemId, type } = payload;

					const userIds: string[] = [];

					for (const email of emails) {
						const user = await dispatch.space.searchUserByEmail({ email });
						if (!user) {
							showToast('error', 'Error', i18next.t('NoUserWithWithEmail'));
						} else {
							userIds.push(user.userId);
						}
					}

					if (userIds.length > 0) {
						const body = new FormData();
						body.append(
							'data',
							JSON.stringify({
								spaceId: activeSpace.spaceId,
								userIds,
								itemId,
								type,
								userInfos: store.auth.userInfos
							})
						);

						const res = await dispatch.request.anonymousRequest({
							method: 'POST',
							url: `${MULTISPACEURL}/userbookings/addbooking`,
							body
						});

						if (res && Array.isArray(res)) {
							showToast('success', i18next.t('Booking Added'));
							dispatch.booking.addToAllBookings({
								spaceId: activeSpace.spaceId,
								load: false,
								bookings: res
							});

							return true;
						}
						await dispatch.booking.loadAllBookingsDelta({});
						showToast('error', 'Error', translateStrapiError(res));
					}
				}

				return false;
			} catch (error) {
				console.log('addBooking', error);
				return false;
			}
		},
		async removeBooking(payload: IRemoveBookingPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { userId, itemId, type } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userId,
							itemId,
							type,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userbookings/removebooking`,
						body
					});

					if (res && Array.isArray(res)) {
						showToast('success', i18next.t('Booking Removed'));
						dispatch.booking.addToAllBookings({
							spaceId: activeSpace.spaceId,
							load: false,
							bookings: res
						});

						await dispatch.booking.loadAllBookingsDelta({});
						return true;
					}
					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('removeBooking', error);
				return false;
			}
		},
		async loadAllBookingsDelta(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);
				if (activeSpace && (isAdmin || isModerator)) {
					// Check here if user is admin
					switch (DATA_LOAD_STRATEGY) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
						case EDataLoadStrategy.Default:
						default:
							if (store.temp.waitingForSocketResponse?.bookings) {
								contentLoadLog(3, 'bookings load already in progress. skipping');
							}

							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									spaceId: activeSpace.spaceId,
									userInfos: store.auth.userInfos
								})
							);

							const _lastRead = store.booking.lastAllBookingsRead ?? ZEROHOUR;

							dispatch.temp.setWaitingForSocketResponse({ key: 'bookings', value: true });
							const res = await dispatch.request.anonymousRequest({
								method: 'POST',
								url: `${MULTISPACEURL}/userbookings/allbookings?lastRead=${_lastRead}`,
								body
							});

							contentLoadLog(4, 'bookings received');
							dispatch.temp.setWaitingForSocketResponse({ key: 'bookings', value: false });

							if (res && Array.isArray(res)) {
								const changedBookings = res.filter((booking: IUserBooking) => {
									const prev = store.booking.allBookings.find((e) => e.id === booking.id);
									if (!prev && !booking.isDeleted) {
										return true;
									}

									return prev && hasEntryChanged(prev, booking);
								});

								if (changedBookings.length > 0) {
									dispatch.booking.addToAllBookings({
										spaceId: activeSpace.spaceId,
										bookings: changedBookings,
										load: true
									});
								}
								return;
							}

							if (!res?.isOffline) {
								showToast('error', 'Error', translateStrapiError(res));
							}
							break;
					}
				}
			} catch (error) {
				errorLog('loadAllBookingsDelta', error);
				dispatch.temp.setWaitingForSocketResponse({ key: 'bookings', value: false });
				return [];
			}
		}
	})
});
