import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isDarkColor, isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode } from 'react';
import { Dimensions, ModalProps, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'modal-enhanced-react-native-web';
import { hsBorderRadius, hsBottomMargin, hsTextBottomMarginSmall, hsToastOffsetWeb } from 'config/styleConstants';
import { H1 } from 'components/Text';
import Toast from 'react-native-toast-notifications';
import { BaseToast } from 'components/Toast/BaseToast';
import { BlurView } from 'expo-blur';
import { Image } from 'components/Image';
import { IMedia } from 'config/interfaces';
import { useQuery } from 'hooks/useQuery';

interface IModal extends ModalProps {
	isVisible: boolean;
	onClose?: () => void;
	onSubmit?: () => void;
	onHelp?: () => void;
	isSubmitLoading?: boolean;
	isSubmitDisabled?: boolean;
	children?: ReactNode | ReactNode[];
	title?: string;
	headerImage?: IMedia;
}
const ACTIONS_BOTTOM_MARGIN = 10;

export const HSModal = (props: IModal) => {
	const { isVisible, onClose, onSubmit, onHelp, isSubmitLoading, isSubmitDisabled, children, title, headerImage } = props;
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { top, right, bottom, left } = useSafeAreaInsets();
	const screenWidth = Dimensions.get('screen').width;
	const screenHeight = Dimensions.get('screen').height;

	const _renderButtons = () => {
		if (!isVisible) {
			return null;
		}

		if (onClose || onSubmit) {
			return (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: !onClose && !onHelp ? 'flex-end' : 'space-between',
						marginBottom: ACTIONS_BOTTOM_MARGIN
					}}
				>
					{onClose && <RoundButton isOutline testID={'modal_button_close'} icon={EDefaultIconSet.Close} onPress={onClose} />}
					{onHelp && <RoundButton testID={'modal_button_help'} icon={EDefaultIconSet.Help} onPress={onHelp} />}
					{onSubmit && (
						<RoundButton
							testID={'modal_button_submit'}
							icon={EDefaultIconSet.Save}
							isLoading={isSubmitLoading}
							isDisabled={isSubmitDisabled}
							onPress={onSubmit}
						/>
					)}
				</View>
			);
		}

		return null;
	};

	const _renderTitle = () => {
		if (!isEmptyString(title)) {
			return (
				<H1
					numberOfLines={title?.includes(' ') ? 2 : 1}
					center
					adjustsFontSizeToFit
					style={{
						alignSelf: 'center',
						marginTop: headerImage ? 0 : -hsTextBottomMarginSmall
					}}
				>
					{title}
				</H1>
			);
		}

		return null;
	};

	const _getSize = () => {
		if (!isTabletOrMobile) {
			return '46%';
		} else if (screenWidth >= 800) {
			return '58%';
		} else if (screenWidth >= 650) {
			return '70%';
		} else if (screenWidth >= 500) {
			return '82%';
		} else {
			return '94%';
		}
	};

	return (
		<Modal
			animationType="slide"
			visible={isVisible}
			supportedOrientations={['portrait', 'landscape-left', 'landscape-right']}
			animationInTiming={1000}
			animationOutTiming={1000}
			style={{ width: '100%', alignSelf: 'center' }}
		>
			<ChildButton
				style={{
					position: 'absolute',
					width: screenWidth,
					height: screenHeight,
					zIndex: isVisible ? 100 : -5,
					flex: 1
				}}
				testID={'webmodal_backdrop'}
				onPress={onClose}
			>
				<BlurView
					intensity={15}
					tint={isDarkColor(theme.contentBackgroundColor ?? theme.background) ? 'light' : 'dark'}
					style={{ width: '100%', height: '100%' }}
				/>
			</ChildButton>
			<View
				style={{
					flex: 1,
					backgroundColor: theme.contentBackgroundColor ?? theme.background,
					paddingTop: Math.max(20, top),
					paddingBottom: Math.max(20, bottom),
					paddingLeft: Math.max(20, left),
					paddingRight: Math.max(20, right),
					width: _getSize(),
					height: '50%',
					alignSelf: 'center',
					zIndex: 200,
					borderRadius: hsBorderRadius
				}}
			>
				<View>
					{_renderButtons()}
					{headerImage && (
						<Image
							mediaObj={headerImage}
							imageSize="thumbnail"
							expectedRatio={16 / 9}
							style={{ width: '25%', aspectRatio: 16 / 9, alignSelf: 'center', marginTop: -60, marginBottom: hsBottomMargin }}
						/>
					)}
					{_renderTitle()}
				</View>
				{children}
				{isVisible && (
					<Toast
						ref={(ref) => (global['toastmodal'] = ref)}
						renderToast={(e) => <BaseToast {...e} isModal />}
						offsetTop={hsToastOffsetWeb}
						animationType={'slide-in'}
					/>
				)}
			</View>
		</Modal>
	);
};
