import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import { IComment, ICreateCommentPayload, IUpdateCommentPayload } from 'config/interfaces/comment';
import { showToast } from 'helper/toast';
import { TStrapiError, isFeatureActive } from 'helper';
import { RootModel } from './index';
import i18next from 'i18next';

type IDefaultState = {
	comments: IComment[];
	lastCommentsRead: Record<string, string>;
};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const comment = createModel<RootModel>()({
	state: {
		comments: [],
		lastCommentsRead: {}
	} as IDefaultState,
	reducers: {
		addComments(state, { load, comments }: { load: boolean; comments: IComment[] }) {
			rematchLog('addComments');
			const _newComments = state.comments ? [...state.comments] : [];
			let _lastCommentsRead = state.lastCommentsRead ? state.lastCommentsRead : {};

			comments.forEach((comment) => {
				const idx = _newComments.findIndex((e) => e.id === comment.id);
				if (idx !== -1) {
					_newComments[idx] = comment;
				} else {
					_newComments.push(comment);
				}

				if (load && (!_lastCommentsRead[comment.spaceId] || _lastCommentsRead[comment.spaceId] < comment.updated_at)) {
					_lastCommentsRead[comment.spaceId] = comment.updated_at;
				}
			});

			return {
				...state,
				comments: _newComments
			};
		},
		clear(state) {
			rematchLog('clear comment');
			return {
				comments: [],
				lastCommentsRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async startCommentSync(payload, store) {
			try {
				if (store.socket.multiSpaceSocket && !store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_comments`)) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_comments`, (data) => {
						const _load = !Array.isArray(data) && data.load;
						const _items = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'comments received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'comments', value: false });
							if (!store.temp.hasLoadedData?.comments) {
							}
							dispatch.temp.setHasLoadedData('comments');
						}

						if (_items.length > 0) {
							dispatch.comment.addComments({
								load: _load,
								comments: _items
							});
						}
					});
				}
			} catch (error) {
				console.log('startCommentSync', error);
			}
		},
		async loadComments(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && isFeatureActive(activeSpace, 'feed')) {
					if (store.temp.hasLoadedData?.comments) {
						contentLoadLog(3, 'comments already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.comments) {
						contentLoadLog(3, 'comments load already in progress. skipping');
						return;
					}

					const _lastRead = store.comment.lastCommentsRead
						? store.comment.lastCommentsRead[activeSpace.spaceId] ?? ZEROHOUR
						: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading comments');
							dispatch.temp.setWaitingForSocketResponse({ key: 'comments', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'comments',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							dispatch.temp.setWaitingForSocketResponse({ key: 'comments', value: true });

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/comments/${activeSpace.spaceId}?lastRead=${_lastRead}`,
								method: 'GET'
							});
							dispatch.temp.setWaitingForSocketResponse({ key: 'comments', value: false });

							if (res?.comments && res?.spaceId === activeSpace.spaceId) {
								// dispatch.temp.setHasLoadedData('comments');

								if (res.comments.length > 0) {
									dispatch.comment.addComments({ load: true, comments: res.comments });
								}
							}
							break;
					}
				}
			} catch (error) {
				console.log('loadComments', error);
			}
		},
		async createComment(payload: ICreateCommentPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				if (activeSpace && store.auth.userInfos) {
					const { itemId, contentType, text } = payload;
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							itemId,
							contentType,
							text,
							status: 'Waiting',
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res: IComment | TStrapiError = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/comments`,
						method: 'POST',
						body: body
					});

					if (res && 'id' in res) {
						let text2 = '';

						if (res.status === 'Waiting') {
							text2 = i18next.t('VoteTextInfoWaiting');
						}

						showToast('success', i18next.t('commentCreated'), text2);

						dispatch.comment.addComments({
							load: false,
							comments: [res]
						});

						return res;
					}

					showToast('error', i18next.t('couldNotCreateComment'));
					return undefined;
				}
			} catch (error) {}
		},
		async updateComment(payload: IUpdateCommentPayload, store) {
			try {
				const { id, text, isDeletion, status } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});
				const comment = store.comment.comments.find((e) => e.id === id);

				if (activeSpace) {
					const body = new FormData();

					body.append(
						'data',
						JSON.stringify({
							text,
							comment,
							status,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							isDeleted: isDeletion
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/comments/${id}`,
						method: 'PUT',
						body: body
					});

					if (res?.id) {
						dispatch.comment.addComments({
							load: false,
							comments: [res]
						});

						showToast('success', isDeletion ? i18next.t('commentDeleted') : i18next.t('commentUpdated'));
						return res;
					}
					showToast('error', isDeletion ? i18next.t('couldNotDeleteComment') : i18next.t('couldNotUpdateComment'));
					return undefined;
				}
			} catch (error) {
				console.log('updateSchedule', error);
			}
		}
	})
});
