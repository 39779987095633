import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import {
	StackParamList,
	ERoutes,
	NavigationHeaderBackButton,
	NavigationHeader,
	NavigationHeaderTitle,
	NavigationHeaderPlaceholder
} from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { SectionHeader, Text } from 'components/Text';
import { IApiToken, ICreateApiToken } from 'config/interfaces';
import { hsTopScreenPadding } from 'config/styleConstants';
import { addToClipboard, EDefaultIconSet } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { FormTextInput } from 'components/Form';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ApiToken>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ApiToken>;
type RouteParams = StackParamList[ERoutes.ApiToken];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'apitoken';

export const ApiTokenScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [apiTokens, setApiTokens] = useState<IApiToken[]>([]);
	const [newApiToken, setNewApiToken] = useState<ICreateApiToken>({ title: '' });

	const loadTokens = useRematchDispatch((dispatch: Dispatch) => dispatch.token.loadTokens);
	const createToken = useRematchDispatch((dispatch: Dispatch) => dispatch.token.createToken);
	const deleteToken = useRematchDispatch((dispatch: Dispatch) => dispatch.token.deleteToken);

	useEffect(() => {
		_loadTokens();
	}, []);

	const _loadTokens = async () => {
		setIsLoading(true);
		const res = await loadTokens({});
		if (res?.length > 0 || Array.isArray(res)) {
			setApiTokens(res);
		}
		setIsLoading(false);
	};

	const _createToken = async () => {
		setIsLoading(true);
		const res = await createToken({ title: newApiToken.title });
		if (res) {
			setNewApiToken(res);
		}
		setIsLoading(false);
	};

	const _deleteToken = async (id) => {
		setIsLoading(true);
		const res = await deleteToken({ tokenId: id });
		await _loadTokens();
		setIsLoading(false);
	};

	const _renderCreateOrNewButton = () => {
		if (newApiToken.spaceId) {
			return (
				<RoundButton
					testID={`${TESTIDPREFIX}_button_newtoken`}
					onPress={() => {
						setNewApiToken({ title: '' });
						_loadTokens();
					}}
					icon={EDefaultIconSet.ApiToken}
					isLoading={isLoading}
					title={t('New token')}
					alignSelf="center"
				/>
			);
		}
		return (
			<RoundButton
				testID={`${TESTIDPREFIX}_button_createtoken`}
				onPress={_createToken}
				icon={EDefaultIconSet.Save}
				isLoading={isLoading}
				title={t('Create token')}
				isDisabled={newApiToken?.title?.length < 3}
				alignSelf="center"
			/>
		);
	};

	const _renderFreshApiToken = () => {
		if (newApiToken?.apiToken) {
			return (
				<View key={`${TESTIDPREFIX}_share_apitoken`} style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_apitoken`}
						label={t('API Token')}
						hint={t('saveApiTokenHint')}
						value={newApiToken.apiToken}
						formStyle={{ flex: 1, marginRight: 5, marginBottom: 5 }}
						isCopyField
						editable={false}
						selectTextOnFocus
					/>
					<View style={{}}>
						<RoundButton
							icon={EDefaultIconSet.Clone}
							testID={`${TESTIDPREFIX}_button_addtoclipboard`}
							onPress={() => {
								if (newApiToken.apiToken) {
									addToClipboard(newApiToken.apiToken);
								}
							}}
							title={t('copyApiToken')}
							noMargin
							size="sm"
						/>
					</View>
				</View>
			);
		}
		return null;
	};

	const _renderGenerateCard = () => {
		return (
			<HSCard>
				<SectionHeader label={t('Generate token')} />
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_title`}
					label={t('apiTokenTitle')}
					hint={t('apiTokenTitleHint')}
					isRequired
					value={newApiToken.title}
					onChangeText={(value) => {
						setNewApiToken({ ...newApiToken, title: value });
					}}
				/>

				{_renderFreshApiToken()}
				{_renderCreateOrNewButton()}
			</HSCard>
		);
	};

	const _renderHelpCard = () => {
		const possibleKeys = [
			'report',
			'vote',
			'schedule',
			'comment',
			'analytic',
			'mailhistory',
			'chatmessage',
			'speaker',
			'stage',
			'pushnotification',
			'schedulestatus',
			'mediaitem',
			'expo',
			'attendeesupportrequest',
			'mailtemplate',
			'map',
			'meetingtable',
			'meeting',
			'networkingroom',
			'newsitem',
			'stream',
			'userbooking',
			'chatmessage',
			'webhook',
			'voteanswer',
			'space'
		]
			.sort((a, b) => a.localeCompare(b))
			.reverse();

		const threePartIndex = Math.ceil(possibleKeys.length / 3);

		const thirdPart = possibleKeys.splice(-threePartIndex).reverse();
		const secondPart = possibleKeys.splice(-threePartIndex).reverse();
		const firstPart = possibleKeys.reverse();

		return (
			<HSCard>
				<SectionHeader label={t('Help')} />
				<Text marginBottom>{t('possibleApiTypeHelpText')}</Text>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<View>
						{thirdPart.map((k) => (
							<Text style={{ paddingBottom: 5 }}>{k}</Text>
						))}
					</View>
					<View>
						{secondPart.map((k) => (
							<Text style={{ paddingBottom: 5 }}>{k}</Text>
						))}
					</View>
					<View>
						{firstPart.map((k) => (
							<Text style={{ paddingBottom: 5 }}>{k}</Text>
						))}
					</View>
				</View>
			</HSCard>
		);
	};

	const _renderListOrLoading = () => {
		if (apiTokens?.length > 0) {
			return (
				<HSCard>
					<SectionHeader label={t('API Tokens')} />
					{apiTokens.map((token, index) => {
						return (
							<View
								style={{
									flexDirection: 'row',
									paddingVertical: 10,
									borderTopWidth: index === 0 ? 0 : 1,
									borderColor: theme.lightgray,
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<Text>{token.title}</Text>
								<View style={{ flexDirection: 'row', alignItems: 'center' }}>
									<Text style={{ paddingRight: 10 }}>
										{t('generatedBy')} {token.firstName} {token.lastName} | {t('createdTokenAt')}{' '}
										{moment(token.created_at).format('DD.MM.YYYY HH:mm')}
									</Text>
									<RoundButton
										testID={`${TESTIDPREFIX}_button_deletetoken_${index}`}
										onPress={() => _deleteToken(token.id)}
										icon={EDefaultIconSet.Delete}
										size="xs"
										isLoading={isLoading}
										color={theme.danger}
										noMargin
									/>
								</View>
							</View>
						);
					})}
				</HSCard>
			);
		}
		if (isLoading) {
			return (
				<HSCard>
					<Spinner />
				</HSCard>
			);
		}
		return null;
	};

	const _renderContent = () => {
		return (
			<View>
				{_renderListOrLoading()}
				{_renderGenerateCard()}
				{_renderHelpCard()}
			</View>
		);
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
				testID={`${TESTIDPREFIX}_scrollview`}
			>
				{_renderContent()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const ApiTokenScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('API Tokens')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
