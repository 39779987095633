import { HSCard } from 'components/Card/HSCard';
import { VoteModerationWordCloudModal } from 'components/Modal';
import { SearchBar } from 'components/SearchBar';
import { HorizontalListSeperator } from 'components/Seperator';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { VoteModerationWordCloudListItem } from './VoteModerationWordCloudListItem';

interface IVoteModerationWordCloud {
	answers: IVoteAnswer[];
	isLoading: Record<number, boolean>;
	updateVoteAnswer: (val: IVoteAnswer) => Promise<boolean>;
}

export const VoteModerationWordCloud = (props: IVoteModerationWordCloud) => {
	const { answers, isLoading, updateVoteAnswer } = props;
	const { screenWidth } = useQuery();

	const [searchedAnswers, setSearchedAnswers] = useState<IVoteAnswer[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [tempItem, setTempItem] = useState<IVoteAnswer | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let items = [...answers];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			items = items.filter((item) => {
				let attendeeName = '';

				if (attendees) {
					const attendee = attendees.find((e) => e.userId === item.userId);
					if (attendee) {
						attendeeName = `${attendee.firstName} ${attendee.lastName}`.toLowerCase();
					}
				}

				return (
					item.value?.toLowerCase().includes(lowerSearch) ||
					item.info?.toLowerCase().includes(lowerSearch) ||
					attendeeName.includes(lowerSearch)
				);
			});
		}

		setSearchedAnswers(items);
	}, [answers, searchTerm]);

	return (
		<View style={{ flex: 1, width: screenWidth, alignSelf: 'center' }}>
			<HSCard>
				<SearchBar testID="votemoderationwordcloud_searchbar" value={searchTerm} onChange={(val) => setSearchTerm(val)} />
			</HSCard>
			<FlatList
				data={searchedAnswers}
				extraData={searchedAnswers}
				renderItem={({ item }) => (
					<VoteModerationWordCloudListItem
						item={item}
						onSubmit={(answer) => updateVoteAnswer(answer)}
						isLoading={isLoading[item.id]}
						onEdit={(item) => setTempItem(item)}
					/>
				)}
				ItemSeparatorComponent={() => <HorizontalListSeperator />}
				keyExtractor={(item, idx) => `voteanswertext_${item.id}_${idx}`}
			/>
			<VoteModerationWordCloudModal
				onSubmit={async (answer) => updateVoteAnswer(answer)}
				isLoading={tempItem !== undefined && isLoading[tempItem?.id]}
				onClose={() => setTempItem(undefined)}
				item={tempItem}
			/>
		</View>
	);
};
