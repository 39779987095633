import { IVoteResult } from 'config/interfaces/voteanswer';

export const previewAnswerRating: IVoteResult = {
	isResultObject: true,
	spaceId: 'testch',
	voteId: 90,
	submissionCount: 3,
	options: [
		{
			key: '2',
			value: 1
		},
		{
			key: '5',
			value: 1
		},
		{
			key: '3',
			value: 1
		}
	]
};
