import {
	ICreateExpoFormValues,
	ICreateMediaItemFormValues,
	ICreateNetworkingRoomFormValues,
	ICreateNewsFormValues,
	ICreateScheduleFormValues,
	ICreateSpaceFormValues,
	ICreateSpeakerFormValues,
	ICreateStageFormValues,
	ICreateStreamFormValues,
	IDownload,
	IEditMapFormValues,
	IEditMeetingTableFormValues
} from 'config/interfaces';
import _ from 'lodash';
import i18next, { t } from 'i18next';
import moment from 'moment';
import { IS_WEB } from './platform';

const webLogo = require('../assets/images/defaultPlaceholder/hellospaces.png');
const speakerImage = require('../assets/images/defaultPlaceholder/speaker.jpg');
const media: IDownload[] = [
	{ title: 'Logo', downloadFile: webLogo },
	{ title: 'Background', downloadFile: require('../assets/images/spaceDefault/spaceDefaultRed.png') },
	{ title: 'Icon', downloadFile: require('../assets/images/defaultLogo/defaultLogo.png') },
	{ title: 'Rocket', downloadFile: require('../assets/images/noData/step1.png') }
];

const logo = 'https://hellospaces.b-cdn.net/hspx.png';
const mapExample = require('../assets/images/mapExample/mapExample.png');

const socials = {
	facebookProfileUrl: 'https://facebook.com',
	instagramProfileUrl: 'https://www.instagram.com/hellospacesapp/',
	linkedInProfileUrl: 'https://linkedin.com/company/hellospacesde/',
	tikTokProfileUrl: 'https://tiktok.com',
	twitterProfileUrl: 'https://twitter.com/HelloSpacesApp',
	website: 'https://hellospaces.de',
	xingProfileUrl: 'https://xing.com',
	youTubeProfileUrl: 'https://www.youtube.com/@hellospaces8306'
};

export const getExpoExamples = () => {
	const examples: ICreateExpoFormValues[] = [
		{
			...socials,
			title: 'HelloSpaces GmbH',
			catchphrase: i18next.t('helloSpacesCatchphrase'),
			description: '# All-In-One Event-Lösung für alle Events\n## Onsite - Hybrid - Remote',
			isHidden: false,
			sponsorType: 'none',
			sponsorOrder: 1,
			logo: IS_WEB ? webLogo : logo,
			sponsorBanner: IS_WEB ? webLogo : logo,
			imageGallery: [],
			location: 'Wetzlar',
			website: 'https://hellospaces.de',
			contactName: 'Sven Herchenhein',
			contactPhone: '01234567890',
			contactEmail: 'kontakt@hellospaces.de',
			street: 'Lauerstraße 19',
			zip: '35578',
			city: 'Wetzlar',
			country: i18next.t('Germany'),
			blogUrl: 'https://hellospaces.de/blog',
			speakers: [],
			schedules: [],
			attendees: [],
			mediaitems: [],
			selfServiceEditable: 'yes'
		}
	];
	return examples;
};

export const getSponsorExamples = () => {
	const examples: ICreateExpoFormValues[] = [
		{
			...socials,
			title: 'HelloSpaces GmbH',
			catchphrase: i18next.t('helloSpacesCatchphrase'),
			description: '',
			isHidden: false,
			sponsorType: 'sponsorOnly',
			sponsorOrder: 1,
			logo: IS_WEB ? webLogo : logo,
			sponsorBanner: IS_WEB ? webLogo : logo,
			imageGallery: [],
			location: 'Wetzlar',
			website: 'https://hellospaces.de',
			contactName: 'Sven Herchenhein',
			contactPhone: '',
			contactEmail: 'kontakt@hellospaces.de',
			street: '',
			zip: '',
			city: '',
			country: i18next.t('Germany'),
			speakers: [],
			schedules: [],
			attendees: [],
			mediaitems: [],
			selfServiceEditable: 'yes'
		}
	];
	return examples;
};

export const getFeedExamples = () => {
	const examples: ICreateNewsFormValues[] = [
		{
			title: i18next.t('Welcome'),
			subtitle: '',
			text: i18next.t('welcomeToHelloSpaces'),
			media: [{ media: IS_WEB ? webLogo : logo }],
			showFrom: null,
			showUntil: null,
			referencetype: 'externalUrl',
			externalUrl: 'https://hellospaces.de'
		}
	];
	return examples;
};

export const getSchedulesExample = (space?: ICreateSpaceFormValues) => {
	const examples: ICreateScheduleFormValues[] = [
		{
			title: 'Keynote',
			subtitle: i18next.t('spaceWizardKeynoteSubtitle'),
			description: '',
			languages: 'DE,GB,US',
			startDate: space?.startDate
				? moment(space?.startDate).set('hours', 9).set('minutes', 0).toISOString()
				: moment().add(1, 'hours').set('minutes', 0).toISOString(),
			endDate: space?.endDate
				? moment(space?.startDate).set('hours', 10).set('minutes', 0).toISOString()
				: moment().add(2, 'hours').set('minutes', 0).toISOString(),
			isHidden: false,
			autoUpdateStatus: false,
			previewImage: null,
			stream: [],
			stage: undefined,
			speakers: [],
			expos: [],
			votes: [],
			backgroundColor: undefined,
			textColor: undefined,
			hasNoDetails: false
		},
		{
			title: `Talk 1`,
			subtitle: '', // subtitle: i18next.t('spaceWizardKeynoteSubtitle'),
			description: '',
			languages: 'DE',
			startDate: space?.startDate
				? moment(space?.startDate).set('hours', 10).set('minutes', 0).toISOString()
				: moment().add(2, 'hours').set('minutes', 0).toISOString(),
			endDate: space?.endDate
				? moment(space?.startDate).set('hours', 12).set('minutes', 0).toISOString()
				: moment().add(4, 'hours').set('minutes', 0).toISOString(),
			isHidden: false,
			autoUpdateStatus: false,
			previewImage: null,
			stream: [],
			stage: undefined,
			speakers: [],
			expos: [],
			votes: [],
			backgroundColor: undefined,
			textColor: undefined,
			hasNoDetails: false
		},
		{
			title: 'Pause',
			subtitle: i18next.t('spaceWizardPauseSubtitle'),
			description: '',
			languages: 'DE',
			startDate: space?.startDate
				? moment(space?.startDate).set('hours', 12).set('minutes', 0).toISOString()
				: moment().add(4, 'hours').set('minutes', 0).toISOString(),
			endDate: space?.endDate
				? moment(space?.startDate).set('hours', 13).set('minutes', 0).toISOString()
				: moment().add(5, 'hours').set('minutes', 0).toISOString(),
			isHidden: false,
			autoUpdateStatus: false,
			previewImage: null,
			stream: [],
			stage: undefined,
			speakers: [],
			expos: [],
			votes: [],
			backgroundColor: undefined,
			textColor: undefined,
			hasNoDetails: false
		},
		{
			title: `Talk 2`,
			subtitle: '', // subtitle: i18next.t('spaceWizardKeynoteSubtitle'),
			description: '',
			languages: 'DE,AT',
			startDate: space?.startDate
				? moment(space?.startDate).set('hours', 13).set('minutes', 0).toISOString()
				: moment().add(5, 'hours').set('minutes', 0).toISOString(),
			endDate: space?.endDate
				? moment(space?.startDate).set('hours', 14).set('minutes', 0).toISOString()
				: moment().add(6, 'hours').set('minutes', 0).toISOString(),
			isHidden: false,
			autoUpdateStatus: false,
			previewImage: null,
			stream: [],
			stage: undefined,
			speakers: [],
			expos: [],
			votes: [],
			backgroundColor: undefined,
			textColor: undefined,
			hasNoDetails: false
		}
	];
	return examples;
};

export const getStageExample = () => {
	const example: ICreateStageFormValues = {
		title: 'Stage Example',
		subtitle: '',
		image: null,
		backgroundColor: undefined,
		textColor: undefined,
		isCrossLane: false,
		order: 0,
		schedules: []
	};

	return example;
};

export const getSpeakerExample = () => {
	const examples: ICreateSpeakerFormValues[] = [
		{
			...socials,
			title: 'Sven Herchenhein',
			bio: '',
			jobTitle: 'CEO',
			company: 'HelloSpaces GmbH',
			isHidden: false,
			image: speakerImage,
			companyImage: IS_WEB ? webLogo : logo,
			email: 'kontakt@hellospaces.de',
			blogUrl: 'https://hellospaces.de/blog',
			attendee: undefined,
			schedules: [],
			expos: [],
			mediaitems: [],
			selfServiceEditable: 'no'
		}
	];

	return examples;
};

export const getMediaItemExample = () => {
	const examples: ICreateMediaItemFormValues[] = [
		{
			title: 'HelloSpaces Placeholder',
			subtitle: t('MediaItemExampleSubtitle'),
			isHidden: false,
			isPinned: false,
			description: '',
			previewImage: IS_WEB ? webLogo : logo,
			mediaType: 'iFrame',
			iFrameUrl: 'https://iframe.mediadelivery.net/embed/29313/e52d304d-0bb6-4683-8133-5ea259507c0d',
			speakers: [],
			expos: []
		}
	];

	return examples;
};

export const getNetworkingExample = () => {
	const examples: ICreateNetworkingRoomFormValues[] = [
		{
			title: 'HelloSpaces Networking',
			capacity: 6,
			description: i18next.t('spaceWizardNetworkingDescripion'),
			overviewImage: IS_WEB ? webLogo : logo,
			isClosed: false,
			isMuteOnEnter: false,
			isAudioOnly: false,
			votes: []
		}
	];
	return examples;
};

export const getStreamExample = () => {
	const examples: ICreateStreamFormValues[] = [
		{
			title: 'HelloSpaces Placeholder Video',
			streamType: 'iFrame',
			iFrameUrl: 'https://iframe.mediadelivery.net/embed/29313/e52d304d-0bb6-4683-8133-5ea259507c0d',
			previewImage: undefined,
			languages: 'DE,GB,US'
		}
	];

	return examples;
};

export const getMapExample = () => {
	const examples: IEditMapFormValues[] = [
		{
			title: 'HelloSpaces Map',
			image: mapExample,
			mappositions: [],
			order: 0,
			showBacklinks: true
		}
	];

	return examples;
};

export const getMeetingTableExample = () => {
	const examples: IEditMeetingTableFormValues[] = [
		{
			title: `${t('Table')} #1`,
			subtitle: 'Meeting Area'
		}
	];

	return examples;
};
