import { Icon } from 'components/Icon';
import { IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';

interface IRatingIcon {
	style: ViewStyle | ViewStyle[];
	color: string;
	emptyIcon: string;
	filledIcon: string;
	value: number;
	iconSize: number;
	testID?: string;
}

export const RatingIcon = (props: IRatingIcon) => {
	const { style, color, emptyIcon, filledIcon, value, iconSize, testID } = props;
	const { theme } = useTheme();

	const [progress, setProgress] = useState<number>(0);

	useEffect(() => {
		setProgress(Math.min(Math.max(0, value), 1));
	}, [value]);

	let starStyleMerge: ViewStyle = {
		...style,
		width: iconSize,
		height: iconSize
	};

	return (
		<View style={[starStyleMerge]} testID={testID} pointerEvents="box-only">
			<View style={[{ flexDirection: 'row', alignItems: 'center', position: 'relative', zIndex: 10 }, starStyleMerge]}>
				<Icon name={emptyIcon} size={iconSize} color={theme.gray} />
			</View>
			<View
				style={[
					starStyleMerge,
					{
						position: 'absolute',
						top: IS_WEB ? 0 : 0, // ob firefox 1-2 pixel offset - wir leben damit
						bottom: 0,
						left: 0,
						overflow: 'hidden',
						width: isNaN(iconSize * progress) ? iconSize : iconSize * progress,
						zIndex: 30
					}
				]}
			>
				<Icon name={filledIcon} size={iconSize} color={color} />
			</View>
		</View>
	);
};
