import { IVoteResult } from 'config/interfaces/voteanswer';

export const previewAnswerSlider: IVoteResult = {
	isResultObject: true,
	spaceId: 'testch',
	voteId: 91,
	submissionCount: 3,
	options: [
		{
			key: 'default',
			value: 20
		}
	]
};
