import { ILauncherSpace } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { Keyboard, ScrollView, View } from 'react-native';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { FormTextInput } from 'components/Form';
import { Markdown } from 'components/Markdown';
import { RoundButton } from 'components/Button';
import { SupportRequestModal } from '../SupportRequestModal';
import { hsBottomMargin } from 'config/styleConstants';
import { H2, Text } from 'components/Text';
import { showToast } from 'helper/toast';

interface ITicketModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: () => void;
	space?: ILauncherSpace;
	routeParams?: Record<string, any>;
}

const TESTIDPREFIX = 'ticketmodal';

export const TicketModal = (props: ITicketModal) => {
	const { isVisible, onClose, onSubmit, space, routeParams } = props;
	const { t } = useTranslation();

	const [isUpdateProfileLoading, setIsUpdateProfileLoading] = useState<boolean>(false);
	const [ticketcode, setTicketCode] = useState<string>('');
	const [isSupportRequestModalVisible, setIsSupportRequestModalVisible] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const fetchTicket = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.fetchTicket);
	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('keydown', _handleEnter, false);
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, []);

	useEffect(() => {
		if (!isVisible) {
			setTicketCode('');
		} else if (routeParams.ticketcode !== '_') {
			setTicketCode(routeParams?.ticketcode ? decodeURIComponent(routeParams.ticketcode) : '');
		}
	}, [isVisible]);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			_updateProfile();
		}
	};

	const _updateProfile = async () => {
		if (space && profile) {
			setIsUpdateProfileLoading(true);
			const res = await fetchTicket({ space, ticketcode, redeem: true });
			if (res?.ticketcode) {
				const tickets = profile.tickets ? { ...profile.tickets } : {};
				tickets[space.spaceId] = res;
				const updateSuccess = await updateUser({ user: { tickets } });
				if (updateSuccess) {
					onSubmit();
				}
			}
			setIsUpdateProfileLoading(false);
		}
	};

	const _resendTicket = async () => {
		if (space && profile) {
			const res = await fetchTicket({ space, ticketcode: profile.email });
			switch (res?.error) {
				case 'ticketExistsForMailButNoTicketcode':
					showToast('success', t('Ticket available'), t('auth.form.error.ticketcode.existsforuser'));
					break;

				case 'noTicketForUser':
				default:
					showToast('error', t('auth.form.error.ticketcode.noticketforuser'), t('pleaseAskSupportForTicket'));
					break;
			}
		}
	};

	const _renderTicketIntroText = () => {
		let ticketIntro = space?.ticketSettings?.ticketIntro;

		if (!isEmptyString(ticketIntro)) {
			return <Markdown markdown={ticketIntro} />;
		} else {
			if (space?.ticketSettings?.ticketProvider === 'hellospaces') {
				return <Markdown markdown={t('HelloSpacesTicketIntroPlaceholder')} />;
			}
			return <Markdown markdown={t('XINGTicketIntroPlaceholder')} />;
		}
	};

	const _renderResendTicket = () => {
		if (space?.ticketSettings?.ticketProvider === 'hellospaces') {
			return (
				<View>
					<H2 style={{ marginBottom: hsBottomMargin }}>{t('cannotFindTicket')}</H2>
					<Text style={{ marginBottom: hsBottomMargin }}>{t('resendTicketCodeToMe')}</Text>
					<RoundButton
						isOutline
						isLoading={isUpdateProfileLoading}
						testID={`${TESTIDPREFIX}_button_resendticketcodetome`}
						icon={EDefaultIconSet.Support}
						title={t('resendTicketCode')}
						onPress={() => _resendTicket()}
						alignSelf="center"
					/>
				</View>
			);
		}
		return null;
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			title={t('Enter Ticketcode')}
			isSubmitDisabled={isEmptyString(ticketcode)}
			isSubmitLoading={isUpdateProfileLoading}
		>
			<ScrollView
				style={{ flex: 1 }}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
			>
				{_renderTicketIntroText()}
				<FormTextInput
					formStyle={{ marginBottom: hsBottomMargin }}
					testID={`${TESTIDPREFIX}_formtextinput_ticketcode`}
					label="Ticketcode"
					isRequired
					isDisabled={isUpdateProfileLoading}
					value={ticketcode}
					hint={t('TicketCodeHint')}
					onChangeText={(val) => setTicketCode(val?.toLowerCase().trim())}
					autoCorrect={false}
					autoCapitalize="none"
					autoCompleteType="off"
					keyboardType="email-address"
				/>
				<View style={{ alignSelf: 'center', marginBottom: hsBottomMargin * 3 }}>
					<RoundButton
						isDisabled={isEmptyString(ticketcode)}
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_fetchticket`}
						icon={EDefaultIconSet.Save}
						title={t('checkTicketcode')}
						alignSelf="flex-end"
						isLoading={isUpdateProfileLoading}
						onPress={() => _updateProfile()}
					/>
				</View>
				{_renderResendTicket()}
				<H2 style={{ marginBottom: hsBottomMargin }}>{t('contactSupport')}</H2>
				<Text style={{ marginBottom: hsBottomMargin }}>{t('youGotProblemsGoSupport')}</Text>
				<RoundButton
					isOutline
					isLoading={isUpdateProfileLoading}
					testID={`${TESTIDPREFIX}_button_supportrequest`}
					icon={EDefaultIconSet.Ticket}
					title={t('sideBarSupportRequest')}
					onPress={() => setIsSupportRequestModalVisible(true)}
					alignSelf="center"
				/>
				<SupportRequestModal
					space={space}
					isVisible={isSupportRequestModalVisible}
					onClose={() => setIsSupportRequestModalVisible(false)}
				/>
			</ScrollView>
		</HSModal>
	);
};
