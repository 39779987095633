import { ChildButton } from 'components/Button';
import { IAttendee, IExpo, ISpeaker, TMailTemplateType } from 'config/interfaces';
import { EDefaultIconSet } from 'helper';
import React from 'react';
import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Avatar } from 'components/User';
import { View } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { t } from 'i18next';

interface IMailResultListItem {
	testID: string;
	attendee?: IAttendee;
	expo?: IExpo;
	speaker?: ISpeaker;
	contentType: TMailTemplateType | undefined;
	error?: string;
	onPress: () => void;
}

export const MailResultListItem = (props: IMailResultListItem) => {
	const { testID, attendee, expo, speaker, contentType, error, onPress } = props;
	const { theme } = useTheme();

	switch (contentType) {
		case 'attendee':
		case 'admin':
		case 'moderator':
			if (attendee) {
				return (
					<ChildButton
						testID={testID}
						style={{ paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
						onPress={onPress}
					>
						<Avatar
							avatar={attendee?.smallImageUrl ?? attendee?.imageUrl}
							fullName={`${attendee.firstName} ${attendee.lastName}`}
							userId={attendee.userId}
							size="sm"
						/>
						<View style={{ flex: 1, paddingLeft: 10 }}>
							<Text bold>{`${attendee.firstName} ${attendee.lastName}`}</Text>
							<Text style={{ fontSize: 12 }}>{attendee.email}</Text>
							{error && <Text style={{ fontSize: 12, color: theme.danger, marginTop: 5 }}>{t(error)}</Text>}
						</View>
						{SHOW_CHEVRON_IN_LIST_ITEM && (
							<View style={{ paddingLeft: 10 }}>
								<Icon name={EDefaultIconSet.ChevronRight} size={30} />
							</View>
						)}
					</ChildButton>
				);
			}
			return null;
		case 'ticket':
			return null;
		case 'expo':
			if (expo) {
				return (
					<ChildButton
						testID={testID}
						style={{ paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
						onPress={onPress}
					>
						<Avatar
							avatar={expo?.logo?.url ?? expo?.sponsorBanner?.url}
							fullName={`${expo.title}`}
							userId={expo.id.toString()}
							size="sm"
						/>
						<View style={{ flex: 1, paddingLeft: 10 }}>
							<Text bold>{`${expo?.title}`}</Text>
							<Text style={{ fontSize: 12 }}>{expo?.selfServiceEmail}</Text>
							{error && <Text style={{ fontSize: 12, color: theme.danger, marginTop: 5 }}>{t(error)}</Text>}
						</View>
						{SHOW_CHEVRON_IN_LIST_ITEM && (
							<View style={{ paddingLeft: 10 }}>
								<Icon name={EDefaultIconSet.ChevronRight} size={30} />
							</View>
						)}
					</ChildButton>
				);
			}
			return null;
		case 'speaker':
			if (speaker) {
				return (
					<ChildButton
						testID={testID}
						style={{ paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
						onPress={onPress}
					>
						<Avatar
							avatar={speaker.image?.url ?? speaker?.companyImage?.url}
							fullName={`${speaker.title}`}
							userId={speaker.id.toString()}
							size="sm"
						/>
						<View style={{ flex: 1, paddingLeft: 10 }}>
							<Text bold>{`${speaker?.title}`}</Text>
							<Text style={{ fontSize: 12 }}>{speaker?.selfServiceEmail}</Text>
							{error && <Text style={{ fontSize: 12, color: theme.danger, marginTop: 5 }}>{t(error)}</Text>}
						</View>
						{SHOW_CHEVRON_IN_LIST_ITEM && (
							<View style={{ paddingLeft: 10 }}>
								<Icon name={EDefaultIconSet.ChevronRight} size={30} />
							</View>
						)}
					</ChildButton>
				);
			}
			return null;
		case 'invitation':
		case 'noTemplate':
		default:
			return null;
	}
};
