import React from 'react';

import { useTranslation } from 'react-i18next';

import { FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteExternalUrlForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteExternalUrlForm = (props: IVoteExternalUrlForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm noMedia testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading}>
			<FormTextInput
				isRequired
				isDisabled={isLoading}
				testID={`${testIdPrefix}_textinput_externalUrl`}
				label={t('External Url')}
				hint={t('externalUrlHint')}
				value={values.externalUrl}
				error={errors.externalUrl}
				onChangeText={(value) => onChange({ ...values, externalUrl: value })}
				keyboardType="url"
				autoCapitalize="none"
			/>
		</CommonVoteForm>
	);
};
