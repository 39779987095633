import { ICreateNewsFormValues, IEditNewsFormValues } from 'config/interfaces';
import { getValidURL, isEmptyString } from 'helper';
import i18next from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';
import { getRequiredError, getWebsiteError } from './errors';

export const getCreateNewsFormSchema = (item: ICreateNewsFormValues) => {
	if (item.externalUrl && !isEmptyString(item.externalUrl)) {
		item.externalUrl = getValidURL(item.externalUrl);
	}

	return Yup.object().shape({
		title: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('title'))),
		subtitle: Yup.string().nullable(),
		externalUrl: Yup.string().url(getWebsiteError(item.externalUrl)).nullable(),
		internalUrl: Yup.string().nullable(),
		showFrom: Yup.string()
			.nullable()
			.test(
				'dateAfterMaxiumumError',
				i18next.t('dateAfterMaxiumumError').replace('%MAXDATE%', moment(item.showUntil).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.showUntil) || isEmptyString(item.showFrom)) {
						return true;
					}
					if (moment(item.showFrom).isAfter(moment(item.showUntil))) {
						return false;
					}
					return true;
				}
			),
		showUntil: Yup.string()
			.nullable()
			.test(
				'dateBeforeMinmumError',
				i18next.t('dateBeforeMinmumError').replace('%MINDATE%', moment(item.showFrom).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.showUntil) || isEmptyString(item.showFrom)) {
						return true;
					}
					if (moment(item.showUntil).isBefore(moment(item.showFrom))) {
						return false;
					}
					return true;
				}
			),
		text: Yup.string().nullable(),
		media: Yup.array().of(
			Yup.object().shape({
				media: Yup.mixed().required(getRequiredError(i18next.t('media'))),
				title: Yup.string().nullable(),
				subtitle: Yup.string().nullable(),
				order: Yup.number().nullable().min(0)
			})
		)
	});
};

export const getEditNewsFormSchema = (item: IEditNewsFormValues) => {
	if (item.externalUrl && !isEmptyString(item.externalUrl)) {
		item.externalUrl = getValidURL(item.externalUrl);
	}

	return Yup.object().shape({
		title: Yup.string()
			.nullable()
			.required(getRequiredError(i18next.t('title'))),
		subtitle: Yup.string().nullable(),
		externalUrl: Yup.string().url(getWebsiteError(item.externalUrl)).nullable(),
		internalUrl: Yup.string().nullable(),
		showFrom: Yup.string()
			.nullable()
			.test(
				'dateAfterMaxiumumError',
				i18next.t('dateAfterMaxiumumError').replace('%MAXDATE%', moment(item.showUntil).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.showUntil) || isEmptyString(item.showFrom)) {
						return true;
					}
					if (moment(item.showFrom).isAfter(moment(item.showUntil))) {
						return false;
					}
					return true;
				}
			),
		showUntil: Yup.string()
			.nullable()
			.test(
				'dateBeforeMinmumError',
				i18next.t('dateBeforeMinmumError').replace('%MINDATE%', moment(item.showFrom).format('DD.MM.YYYY HH:mm') ?? ''),
				() => {
					if (isEmptyString(item.showUntil) || isEmptyString(item.showFrom)) {
						return true;
					}
					if (moment(item.showUntil).isBefore(moment(item.showFrom))) {
						return false;
					}
					return true;
				}
			),
		text: Yup.string().nullable(),
		media: Yup.array().of(
			Yup.object().shape({
				media: Yup.mixed().required(getRequiredError(i18next.t('media'))),
				title: Yup.string().nullable(),
				subtitle: Yup.string().nullable(),
				order: Yup.number().nullable().min(0)
			})
		)
	});
};
