import { ILauncherSpace, IMedia, IMediaItem, ISchedule, IStrapiBase, TMedia } from '.';

export interface IStream extends IStrapiBase {
	title: string;
	media?: IMedia;
	streamType: TPlayableMediaType;
	contentflowUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	iFrameUrl?: string;
	schedules?: ISchedule[];
	spaceId: string;
	mediaitem?: IMediaItem;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	previewImage?: IMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	languages?: string;
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	networkinRoomId?: number;
}

export interface ICreateStreamFormValues {
	title?: string;
	streamType?: TPlayableMediaType;
	media?: TMedia;
	contentflowUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	iFrameUrl?: string;
	schedules?: number[];
	mediaitem?: number;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	previewImage?: IMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	languages?: string;
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	networkinRoomId?: number;
}

export interface ICreateStreamFormErrors {
	title?: string;
	streamType?: TPlayableMediaType;
	media?: TMedia;
	contentflowUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	iFrameUrl?: string;
	schedules?: number[];
	mediaitem?: number;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	previewImage?: IMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	languages?: string;
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	networkinRoomId?: string;
}

export interface IUpdateStreamFormValues {
	id?: number;
	title?: string;
	streamType?: TPlayableMediaType;
	contentflowUrl?: string;
	media?: TMedia;
	youTubeUrl?: string;
	vimeoUrl?: string;
	iFrameUrl?: string;
	schedules?: number[];
	mediaitem?: number;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	previewImage?: IMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	languages?: string;
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	networkinRoomId?: number;
	updated_at?: string;
}

export interface IUpdateStreamFormErrors {
	id?: number;
	title?: string;
	streamType?: TPlayableMediaType;
	contentflowUrl?: string;
	media?: TMedia;
	youTubeUrl?: string;
	vimeoUrl?: string;
	iFrameUrl?: string;
	schedules?: number[];
	mediaitem?: number;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	previewImage?: IMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	languages?: string;
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	networkinRoomId?: string;
}

export interface ICreateStreamPayload {
	space?: ILauncherSpace;
	stream: ICreateStreamFormValues;
	noToast?: boolean;
}

export interface IUpdateStreamPayload {
	stream: IUpdateStreamFormValues;
	isDeletion?: boolean;
}

export type TPlayableMediaType =
	| 'contentflow'
	| 'youtube'
	| 'vimeo'
	| 'iFrame'
	| 'mediaLibrary'
	| 'upload'
	| 'local'
	| 'externalMeeting'
	| 'zoom'
	| 'networking';
export const possiblePlayableMediaTypes = ['contentflow', 'youtube', 'vimeo', 'iFrame', 'mediaLibrary', 'upload'];

export interface ICountryOption {
	countryCode: string;
	countryName: string;
}
