import { FormTextInput } from 'components/Form';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { IComment } from 'config/interfaces/comment';
import { View } from 'react-native';
import { hsBorderRadius, hsBottomMargin } from 'config/styleConstants';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface ICommentForm {
	itemId: string;
	contentType: IComment['contentType'];
}

export const CommentForm = (props: ICommentForm) => {
	const { itemId, contentType } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { trackAction } = useTracker();

	const [message, setMessage] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const createComment = useRematchDispatch((dispatch: Dispatch) => dispatch.comment.createComment);

	const _createComment = async () => {
		setIsLoading(true);

		if (profile) {
			const res = await createComment({ itemId, contentType, text: message });
			if (res) {
				trackAction(contentType, 'Comment', itemId);
				setMessage('');
			}
		}

		setIsLoading(false);
	};

	if (!profile) {
		return null;
	}

	return (
		<View
			style={{
				flexDirection: 'row',
				borderWidth: 1,
				borderTopWidth: 1,
				borderRadius: hsBorderRadius,
				borderColor: theme.lightgray,
				paddingHorizontal: EHorizontalScreenPadding.Wide,
				paddingTop: 5,
				backgroundColor: theme.contentBackgroundColor ?? theme.background,
				marginBottom: hsBottomMargin,
				marginTop: 1
			}}
		>
			<View style={{ flex: 1, paddingRight: 40, flexDirection: 'row', alignItems: 'center' }}>
				<FormTextInput
					testID={'comment_textinput'}
					value={message}
					multiline
					messageType
					style={{
						flex: 1,
						color: theme.text
					}}
					formStyle={{ marginBottom: 5, paddingRight: 5 }}
					onChangeText={(val) => setMessage(val)}
					placeholderTextColor={theme.formgray}
					placeholder={t('CommentPlaceholder')}
				/>
				<RoundButton
					testID={`comment_button_send`}
					isDisabled={isEmptyString(message)}
					isLoading={isLoading}
					onPress={() => _createComment()}
					icon={EDefaultIconSet.Rocket}
					size="sm"
					alignSelf="flex-end"
				/>
			</View>
		</View>
	);
};
