import { IContentFlowMediaEntry, IContentTypeField, IExpo, ILauncherSpace, ISpeaker, IStrapiBase, IStream, TPlayableMediaType } from '.';
import { TMedia } from './forms';
import { IMedia } from './main';

export interface IMediaItem extends IStrapiBase {
	title: string;
	spaceId: string;
	subtitle?: string;
	description?: string;
	previewImage?: IMedia;
	expos?: IExpo[];
	speakers?: ISpeaker[];
	isPinned?: boolean;
	media?: IMedia;
	contentflow?: IContentFlowMediaEntry;
	iFrameUrl?: string;
	contentflowUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	mediaType: TPlayableMediaType;
	mediaTitle?: string;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	isHidden?: boolean;
	streams?: IStream[];
}

export interface ICreateMediaItemFormValues {
	title: string;
	subtitle?: string;
	description?: string;
	previewImage?: TMedia;
	expos?: number[];
	speakers?: number[];
	isPinned?: boolean;
	media?: IMedia;
	contentflow?: IContentFlowMediaEntry;
	iFrameUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	mediaType: TPlayableMediaType;
	mediaTitle?: string;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	isHidden?: boolean;
}

export interface IUpdateMediaItemFormValues {
	id?: number;
	title?: string;
	subtitle?: string;
	description?: string;
	previewImage?: TMedia;
	expos?: number[];
	speakers?: number[];
	streams?: number[];
	isPinned?: boolean;
	media?: IMedia;
	contentflow?: IContentFlowMediaEntry;
	iFrameUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	isMute?: boolean;
	isAutoplay?: boolean;
	isLoop?: boolean;
	mediaType: TPlayableMediaType;
	mediaTitle?: string;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
	isHidden?: boolean;
	updated_at?: string;
}

export interface ICreateMediaItemFormErrors {}
export interface IUpdateMediaItemFormErrors {}

export interface ICreateMediaItemPayload {
	space?: ILauncherSpace;
	mediaitem: ICreateMediaItemFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateMediaItemPayload {
	mediaitem: IMediaItem | IUpdateMediaItemFormValues;
	fields: IContentTypeField[];
	isDeletion?: boolean;
	noToast?: boolean;
	space?: ILauncherSpace;
}

export const getCreateMediaItemInitialFormValues = () => {
	const values: ICreateMediaItemFormValues = {
		title: '',
		expos: [],
		speakers: []
	};

	return values;
};
