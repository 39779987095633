import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { MediaItemDetail } from 'components/MediaItem';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import * as RootNavigation from '../../../RootNavigation';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderEditButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { NoData } from 'components/NoData';
import { ScheduleDetail } from 'components/Schedule';

import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { TabView } from 'components/TabView';
import {
	VoteApplause,
	VoteMultipleChoice,
	VoteNps,
	VoteRating,
	VoteScale,
	VoteText,
	VoteWordCloud,
	VotePinOnImage,
	VoteHappinessOMeter
} from 'components/Vote/ActiveVote';
import { VoteQuiz } from 'components/Vote/ActiveVote/VoteQuiz';
import { VoteSurvey } from 'components/Vote/ActiveVote/VoteSurvey';
import { HSWebView } from 'components/WebView';
import { IMediaItem, ISchedule, IScheduleStatus, IVote, TPlayableMediaType } from 'config/interfaces';
import { hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, getIconByVoteType, hasEntryChanged, isEmptyString, IS_WEB } from 'helper';
import { showToast } from 'helper/toast';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, AppStateStatus, Dimensions, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Spinner } from 'components/Spinner';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Media>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Media>;
type RouteParams = StackParamList[ERoutes.Media];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'media';

export const MediaScreen = ({ route, navigation }: Props) => {
	const { isTabletOrMobile } = useQuery();
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	let timer = useRef<NodeJS.Timer>(null);
	const voteAnswerTimer = useRef<NodeJS.Timer>(null);

	const streamType = useRef<TPlayableMediaType>();
	const liveStatus = useRef<IScheduleStatus['status']>('Upcoming');

	const activeSidebarFeatureRef = useRef('');
	const votingsRef = useRef<IVote[]>([]);

	const [activeSidebarFeature, setActiveSidebarFeature] = useState<string>('');
	const [item, setItem] = useState<IMediaItem | ISchedule | undefined>(undefined);
	const [votings, setVotings] = useState<IVote[]>([]);
	const [oldStatus, setOldStatus] = useState<IScheduleStatus['status']>('Upcoming');
	const [currentDate, setCurrentDate] = useState<string>(moment().toISOString());
	const [isVoteAnswersLoading, setIsVoteAnswersLoading] = useState<Record<number, boolean>>({});
	const [screenHeight, setScreenHeight] = useState<number>(Dimensions.get('window').height);

	const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(true);
	const [hasStream, setHasStream] = useState<boolean>(true);

	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const loadVoteAnswersAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteAnswersAndStartSync);
	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		let appStateListener;
		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		if (IS_WEB) {
			window.addEventListener('resize', () => setScreenHeight(Dimensions.get('window').height));
		}

		_startTimer();

		if (route.params.mediaType === 'mediaitem') {
			liveStatus.current = 'Live';
		}

		return () => {
			if (!IS_WEB && appStateListener) {
				appStateListener.remove();
			}

			_stopTimer();

			if (IS_WEB) {
				window.removeEventListener('resize', () => setScreenHeight(Dimensions.get('window').height));
			}

			if (IS_WEB && !isTabletOrMobile && liveStatus.current === 'Live') {
				switch (streamType.current) {
					case 'contentflow':
					case 'iFrame':
					case 'mediaLibrary':
					case 'vimeo':
					case 'youtube':
						setMediaDetail({
							playbackStatus: 'playing',
							viewType: 'collapsed',
							item,
							itemType: route.params.mediaType,
							itemId: route.params.itemId as number
						});
						break;
					default:
						break;
				}
			}
		};
	}, []);

	const _handleStreamTypeUpdate = (type: TPlayableMediaType) => {
		if (liveStatus.current === 'Live') {
			streamType.current = type;
			switch (type) {
				case 'zoom':
					break;
				default:
					window.onbeforeunload = null;
					break;
			}
		}
	};

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			_startTimer();
		} else if (nextState === 'background') {
			_stopTimer();
		}
	};

	useEffect(() => {
		const _options = {
			showEdit: item !== undefined,
			onBackPress: item ? _confirmZoomLeave : undefined
		};

		navigation.setOptions(_options);
	}, [item, streamType.current]);

	useEffect(() => {
		if (route.params.mediaType === 'schedule' && content?.schedulestatuses && item) {
			let _status = content?.schedulestatuses.find((e) => item && e.schedule?.id === item.id);
			if (_status?.status === 'Live') {
				setOldStatus('Live');
				return;
			}
			if (_status?.status === 'Finished' && oldStatus === 'Live') {
				const sameStageSchedules: ISchedule[] = content.schedules
					?.filter((s: ISchedule) => {
						if (s.isDeleted) {
							return false;
						}
						return s.stage?.id === item?.stage?.id;
					})
					?.sort((a, b) => {
						const aVal = isEmptyString(a.startDate) ? moment().subtract(300, 'years') : moment(a.startDate);
						const bVal = isEmptyString(b.startDate) ? moment().subtract(300, 'years') : moment(b.startDate);

						return aVal.isBefore(bVal) ? -1 : 1;
					});
				const oldIndex = sameStageSchedules?.findIndex((s) => s?.id && s?.id === item?.id);
				const newMedia = sameStageSchedules[oldIndex + 1];
				if (!newMedia) {
					return;
				}

				if (newMedia?.stream && item?.stream) {
					const oldIds = item?.stream?.map((s) => s.id) ?? [];
					const newIds = newMedia?.stream?.map((s) => s.id) ?? [];
					const filteredArray = oldIds.filter((value) => {
						return newIds.includes(value);
					});
					if (filteredArray.length > 0) {
						navigation.setParams({
							itemId: newMedia?.id
						});
					}
				}
			}
		}
	}, [content, item]);

	useEffect(() => {
		let media: IMediaItem | ISchedule | undefined = undefined;
		if (route.params.mediaType === 'schedule' && route.params.itemId) {
			media = content?.schedules?.find((s: ISchedule) => {
				if (typeof route?.params?.itemId === 'string') {
					return s.id.toString() === route.params.itemId;
				}
				return s.id === route.params.itemId;
			});
			setMediaDetail({
				...mediaDetail,
				viewType: 'full',
				itemType: 'schedule',
				itemId: route.params.itemId
			});
		}
		if (route.params.mediaType === 'mediaitem' && route.params.itemId) {
			media = content?.mediaitems?.find((s: IMediaItem) => {
				if (typeof route?.params?.itemId === 'string') {
					return s.id.toString() === route.params.itemId;
				}
				return s.id === route.params.itemId;
			});
			setMediaDetail({
				...mediaDetail,
				viewType: 'full',
				itemType: 'mediaitem',
				itemId: route.params.itemId
			});
		}

		const iAmSpaceAdmin = activeSpace?.admins?.find((e) => e.userId === profile?.userId);
		const iAmSpaceModerator = activeSpace?.moderators?.find((e) => e.userId === profile?.userId);

		if (activeSpace && media?.isHidden && !iAmSpaceAdmin && !iAmSpaceModerator) {
			showToast('info', t('EntryNotFound'));
			if (RootNavigation.canGoBack()) {
				navigation.goBack();
			} else {
				RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
			}
			return;
		}

		if (media) {
			if (item && item?.id === route?.params?.itemId) {
				const entryHasChanged = hasEntryChanged(item, media);

				if (entryHasChanged) {
					setItem(media);
					navigation.setOptions({
						title: media.title
					});
				}
			} else {
				setItem(media);
				navigation.setOptions({
					title: media.title
				});
			}
		}
	}, [route, content, item, activeSpace, profile]);

	useEffect(() => {
		const now = moment(currentDate);

		let _votings: IVote[] = [];
		if (item && activeSpace && item.votes?.length > 0 && route.params.itemId) {
			_votings = item?.votes?.filter((e) => {
				if (e.isDeleted) {
					return false;
				}
				if (!e.isActive) {
					return false;
				}
				if (e.openUntil && e.openFrom) {
					return now.isBefore(moment(e.openUntil)) && now.isAfter(moment(e.openFrom));
				}
				if (e.openFrom) {
					return now.isSameOrAfter(moment(e.openFrom));
				}
				if (e.openUntil) {
					return now.isBefore(moment(e.openUntil));
				}
				return true;
			});
		}
		_setVotings(_votings);
	}, [item, votes, activeSpace, currentDate]);

	useEffect(() => {
		let _hasFeature = false;
		if (!isEmptyString(activeSidebarFeature)) {
			const found = votings?.find((v) => v?.id?.toString() === activeSidebarFeature);
			if (found || activeSidebarFeature === 'details') {
				_hasFeature = true;
			}
		}
		if (!_hasFeature) {
			if (votings.length > 0 && votings[0]?.id) {
				_setActiveSidebarFeature(votings[0].id.toString());
			} else if (isTabletOrMobile) {
				_setActiveSidebarFeature('details');
			}
		}
	}, [votings, activeSidebarFeature]);

	const _startTimer = () => {
		timer.current = setInterval(() => {
			const now = moment();
			if (now.get('seconds') === 0) {
				setCurrentDate(now.toISOString());
			}
		}, 1000);

		voteAnswerTimer.current = setInterval(() => {
			_loadVoteAnswers();
		}, 5000);
	};

	const _stopTimer = () => {
		if (timer.current) {
			clearInterval(timer.current);
			timer.current = undefined;
		}
		if (voteAnswerTimer.current) {
			clearInterval(voteAnswerTimer.current);
			voteAnswerTimer.current = undefined;
		}
	};

	const _setVotings = (newVotings: IVote[]) => {
		setVotings(newVotings);
		if (newVotings.length === 0) {
			_setActiveSidebarFeature('details');
		}
		votingsRef.current = newVotings;
	};

	const _setActiveSidebarFeature = (newFeature: string) => {
		setActiveSidebarFeature(newFeature);
		activeSidebarFeatureRef.current = newFeature;
	};

	const _loadVoteAnswers = async () => {
		const _activeVote = votingsRef.current.find((vote) => vote.id.toString() === activeSidebarFeatureRef.current);

		if (_activeVote) {
			setIsVoteAnswersLoading({ ...isVoteAnswersLoading, [_activeVote.id]: true });
			await loadVoteAnswersAndStartSync({ reload: true, voteId: _activeVote.id, nosync: true });
			setIsVoteAnswersLoading({ ...isVoteAnswersLoading, [_activeVote.id]: false });
		}
	};

	const _confirmZoomLeave = async () => {
		let isInZoomCall = false;

		switch (route.params.mediaType) {
			case 'mediaitem':
				if ((item as IMediaItem).mediaType === 'zoom') {
					isInZoomCall = true;
				}
				break;
			case 'schedule':
				if ((item as ISchedule).status?.status === 'Live' && streamType.current === 'zoom') {
					isInZoomCall = true;
				}
				break;
			default:
				break;
		}

		if (isInZoomCall && IS_WEB) {
			return await new Promise((resolve) => {
				showAlert({
					title: t('leaveZoomCallTitle'),
					message: t('leaveZoomCallSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel',
							onPress: () => {
								resolve(false);
							}
						},
						{
							text: t('leaveZoomCallTitle'),
							style: 'destructive',
							onPress: () => {
								resolve(true);
							}
						}
					]
				});
			});
		}
	};

	const _getPreviewImage = () => {
		if (item) {
			let _stream = (item as ISchedule).stream;

			if (item.previewImage) {
				return item.previewImage;
			}
			if (_stream && _stream.length > 0) {
				return _stream[0].previewImage;
			}
			if (item.expos && item.expos?.length > 0 && item.expos[0] && item.expos[0]?.id) {
				const found = content?.expos?.find((e) => e.id === item.expos[0].id);
				if (found?.logo) {
					return found.logo;
				}
			}
		}

		return undefined;
	};

	const _renderContent = (type: 'full' | 'playerOnly' | 'detailsOnly') => {
		if (item) {
			if (route.params.mediaType === 'schedule' && route.params.itemId) {
				return (
					<ScheduleDetail
						itemId={route.params.itemId}
						item={item as ISchedule | undefined}
						previewImage={_getPreviewImage()}
						onStatusChange={(status) => (liveStatus.current = status)}
						getStreamType={(type) => _handleStreamTypeUpdate(type)}
						hasPadding
						hideVotings
						playerOnly={type === 'playerOnly'}
						detailsOnly={type === 'detailsOnly'}
						isAutoplay={route?.params?.isAutoplay}
						setHasStream={(val) => setHasStream(val)}
						// max height is needed for tablet (iPad) in landscape orientaion. otherwise the details are not readable
						maxHeight={isTabletOrMobile && type === 'playerOnly' ? screenHeight * 0.5 : undefined}
					/>
				);
			}
			if (route.params.mediaType === 'mediaitem' && route.params.itemId) {
				return (
					<MediaItemDetail
						itemId={route.params.itemId}
						item={item as IMediaItem | undefined}
						previewImage={_getPreviewImage()}
						getStreamType={(type) => _handleStreamTypeUpdate(type)}
						hasPadding
						playerOnly={type === 'playerOnly'}
						detailsOnly={type === 'detailsOnly'}
						isAutoplay={route?.params?.isAutoplay}
						setHasStream={(val) => setHasStream(val)}
						// max height is needed for tablet (iPad) in landscape orientaion. otherwise the details are not readable
						maxHeight={isTabletOrMobile && type === 'playerOnly' ? screenHeight * 0.5 : undefined}
					/>
				);
			}
		}

		return (
			<View style={{ flex: 1, justifyContent: 'center' }}>
				<Spinner size={'large'} />
			</View>
		);
	};

	const _renderVoting = () => {
		if (activeSidebarFeature === 'details') {
			return <View style={{ flex: 1, paddingTop: hsInnerPadding }}>{_renderContent('detailsOnly')}</View>;
		}
		const _activeVote = votings?.find((vote) => vote.id.toString() === activeSidebarFeature);

		switch (_activeVote?.votingType) {
			case 'applause':
				return <VoteApplause fullwidth vote={_activeVote} />;
			case 'multipleChoice':
				return <VoteMultipleChoice fullwidth vote={_activeVote} />;
			case 'quiz':
				return <VoteQuiz fullwidth vote={_activeVote} />;
			case 'rating':
				return <VoteRating fullwidth vote={_activeVote} />;
			case 'scale':
				return <VoteScale fullwidth vote={_activeVote} />;
			case 'survey':
				return <VoteSurvey fullwidth vote={_activeVote} />;
			case 'text':
				return <VoteText fullwidth vote={_activeVote} renderSortByInCard />;
			case 'wordCloud':
				return <VoteWordCloud fullwidth vote={_activeVote} />;
			case 'nps':
				return <VoteNps fullwidth vote={_activeVote} />;
			case 'externalUrl':
				if (_activeVote.externalUrl) {
					return <HSWebView src={_activeVote.externalUrl} testIdPrefix={TESTIDPREFIX} style={{ flex: 1 }} />;
				}
				return null;
			case 'pinOnImage':
				return <VotePinOnImage fullwidth vote={_activeVote} />;
			case 'happinessOMeter':
				return <VoteHappinessOMeter fullwidth vote={_activeVote} />;
			default:
				return null;
		}
	};

	const _renderVideoDetail = () => {
		return <HSCard style={{ flex: 1 }}>{_renderContent('full')}</HSCard>;
	};

	const _renderSidebarFeature = () => {
		if (isSidebarExpanded) {
			return <View style={{ flex: 1 }}>{_renderVoting()}</View>;
		}

		return (
			<View style={{ flex: 1 }}>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_details`}
					icon={EDefaultIconSet.Info}
					onPress={() => {
						setIsSidebarExpanded(!isSidebarExpanded);
						_setActiveSidebarFeature('details');
					}}
					isOutline
					iconColor={theme.text}
					alignSelf="center"
				/>
				{votings?.map((v) => {
					if (v.isActive) {
						return (
							<RoundButton
								testID={`${TESTIDPREFIX}_button_${v.votingType}`}
								icon={getIconByVoteType(v.votingType)}
								onPress={() => {
									setIsSidebarExpanded(!isSidebarExpanded);
									_setActiveSidebarFeature(v?.id.toString());
								}}
								isOutline
								iconColor={theme.text}
								alignSelf="center"
							/>
						);
					}
					return null;
				})}
			</View>
		);
	};

	const _renderSidebar = (withDetails?: boolean) => {
		const _activeVotings = votings?.filter((v) => v.isActive);
		if (_activeVotings?.length === 0 && !withDetails) {
			return null;
		}

		const tabKeys = votings.map((v) => {
			return { key: v?.id.toString(), label: v.question };
		});

		if (withDetails) {
			tabKeys.unshift({ key: 'details', label: t('Details') });
			if (isEmptyString(activeSidebarFeature)) {
				_setActiveSidebarFeature('details');
			}
		}
		return (
			<HSCard
				style={{
					marginLeft: 10,
					maxWidth: 500,
					width: isSidebarExpanded ? 500 : 'auto',
					paddingHorizontal: isSidebarExpanded ? hsInnerPadding : 0
				}}
			>
				<View style={{ flexDirection: 'row' }}>
					<RoundButton
						testID={`${TESTIDPREFIX}_button_togglesidebar`}
						icon={isSidebarExpanded ? EDefaultIconSet.ChevronRight : EDefaultIconSet.ChevronLeft}
						onPress={() => setIsSidebarExpanded(!isSidebarExpanded)}
						isOutline
						iconColor={theme.text}
						alignSelf="center"
					/>
					{isSidebarExpanded && (
						<TabView
							testIdPrefix={TESTIDPREFIX}
							activeKey={activeSidebarFeature}
							items={tabKeys}
							onPress={(key) => _setActiveSidebarFeature(key)}
							containerStyle={{ flex: 1 }}
						/>
					)}
				</View>
				{_renderSidebarFeature()}
			</HSCard>
		);
	};

	const _renderMobileFeatures = () => {
		return (
			<HSCard style={{ padding: 0, overflow: 'hidden', flex: 1 }}>
				{votings.length > 0 && (
					<TabView
						testIdPrefix={TESTIDPREFIX}
						activeKey={activeSidebarFeature}
						items={[
							{ key: 'details', label: t('Details') },
							...votings.map((v) => {
								return { key: v?.id.toString(), label: v.question };
							})
						]}
						onPress={(key) => _setActiveSidebarFeature(key)}
						containerStyle={{ maxHeight: 40 }}
						scrollEnabled={votings.length > 2}
					/>
				)}
				{_renderVoting()}
			</HSCard>
		);
	};

	if (isTabletOrMobile) {
		return (
			<ScreenContainer>
				{_renderContent('playerOnly')}
				<View
					style={{
						paddingTop: hasStream && route.params.mediaType !== 'mediaitem' ? 0 : hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						flex: 1
					}}
				>
					{_renderMobileFeatures()}
				</View>
			</ScreenContainer>
		);
	}

	// we might be using this later
	const _renderStacked = () => {
		return (
			<ScreenContainer>
				<View
					style={{
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						flex: 1,
						flexDirection: 'row'
					}}
				>
					{_renderVideoDetail()}
					{_renderSidebar()}
				</View>
			</ScreenContainer>
		);
	};

	const _renderThirds = () => {
		return (
			<ScreenContainer>
				<View
					style={{
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						flex: 1,
						flexDirection: 'row'
					}}
				>
					<HSCard style={{ flex: 1 }}>{_renderContent('playerOnly')}</HSCard>
					{_renderSidebar(true)}
				</View>
			</ScreenContainer>
		);
	};

	return _renderThirds();
};

export const MediaScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton openMediaModalOnBack route={route} onPress={options.onBackPress} />
			<NavigationHeaderTitle title={options?.title ?? ''} />
			{options.showEdit && params.mediaType && params.itemId ? (
				<NavigationHeaderEditButton
					route={params.mediaType === 'schedule' ? ERoutes.ScheduleEdit : ERoutes.MediaItemEdit}
					id={params.itemId}
				/>
			) : (
				<NavigationHeaderPlaceholder />
			)}
		</NavigationHeader>
	);
};
