import React from 'react';
import { View } from 'react-native';

import { ChildButton } from 'components/Button';
import { IAttendee } from 'config/interfaces';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { Avatar } from 'components/User';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';

interface IAttendeeReferenceItem {
	testID: string;
	item: IAttendee;
	onPress: () => void;
}

export const AttendeeReferenceItem = (props: IAttendeeReferenceItem) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();

	if (!item) {
		return null;
	}

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (!isEmptyString(item.jobTitle) && !isEmptyString(item.company)) {
			str = `${item.jobTitle} | ${item.company}`;
		} else if (item.jobTitle && !isEmptyString(item.jobTitle)) {
			str = item.jobTitle;
		} else if (item.company && !isEmptyString(item.company)) {
			str = item.company;
		}

		if (!isEmptyString(str)) {
			return <Text style={{ fontSize: 12 }}>{str}</Text>;
		}

		return null;
	};

	return (
		<ChildButton
			testID={`${testID}_button_${item?.id}`}
			onPress={onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: 20,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: 5,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<Avatar
				avatar={item?.smallImageUrl ?? item?.imageUrl}
				fullName={`${item.firstName} ${item.lastName}`}
				size="listItem"
				userId={item.userId}
			/>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text bold>{`${item.firstName} ${item.lastName}`}</Text>
				{_renderJobtitleAndCompany()}
			</View>
			{SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
