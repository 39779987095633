import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { HSModal } from 'components/Modal/Modal';
import { ICreateStreamFormErrors, IUpdateStreamFormValues } from 'config/interfaces';
import { StreamUpdateForm } from 'components/Forms/Stream';
import { IMediaObject } from '../FormStreamSelect';
import { validateForm } from 'helper';
import { getAddStreamFormSchema } from 'config/yupSchemas';

interface IUpdateStreamModal {
	stream?: number | IMediaObject;
	onClose: () => void;
	onSubmit: (streamId: number) => void;
	createMediaJson?: boolean;
	createStreamJson?: boolean;
	hideEditWarning?: boolean;
	isVisible: boolean;
	isSelfService?: boolean;
}

const TESTIDPREFIX = 'updatestreammodal';

export const UpdateStreamModal = (props: IUpdateStreamModal) => {
	const { stream, onClose, onSubmit, createMediaJson, createStreamJson, hideEditWarning, isVisible, isSelfService } = props;
	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [values, setValues] = useState<IUpdateStreamFormValues | undefined>(undefined);
	const [errors, setErrors] = useState<ICreateStreamFormErrors>({});

	const content = useSelector((store: IRootState) => store.content.content);

	const updateStream = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateStream);

	useEffect(() => {
		let _values: typeof values = undefined;
		if (stream && typeof stream === 'object') {
			_values = stream;
		} else if (stream && content?.streams) {
			const __values: any = content.streams.find((e) => e.id === stream);

			_values = {
				...__values,
				schedules: __values.schedules ? __values.schedules.map((sc) => sc.id) : [],
				mediaitem: __values.mediaitem?.id
			};
		}
		setValues(_values);
	}, [stream]);

	const _updateStream = async () => {
		if (values) {
			const formErrors = await validateForm(getAddStreamFormSchema(values), values);
			if (formErrors) {
				setErrors(formErrors);
				return;
			}
			if (createMediaJson || createStreamJson) {
				onSubmit(values);
				return;
			}
			setIsLoading(true);
			const res = await updateStream({ stream: values });
			setIsLoading(false);

			if (res) {
				onSubmit(res.id);
			}
		}
	};

	return (
		<HSModal isVisible={isVisible} title={createMediaJson ? t('Update media') : t('Update Stream')} onClose={onClose}>
			{values && (
				<StreamUpdateForm
					hidePreviewImage={createMediaJson}
					isMediaItem={createMediaJson}
					testID={TESTIDPREFIX}
					values={values}
					errors={errors}
					onSubmit={_updateStream}
					isLoading={isLoading}
					onChange={(values) => setValues(values)}
					isModal
					showWarning={!hideEditWarning}
					isSelfService={isSelfService}
				/>
			)}
		</HSModal>
	);
};
