import React, { useEffect, useRef, useState } from 'react';
import { Animated, ScrollView, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { IKVP } from './FormMultiSwitch';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, getReversedName, isEmptyString, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { IFormBase, ILauncherSpace, IStage } from 'config/interfaces';
import { FormField } from './FormField';
import { Avatar } from 'components/User';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from './constants';
import { useTheme } from 'hooks/useTheme';
import { useSpace } from 'hooks/useSpace';
import moment from 'moment';
import { SearchBar } from 'components/SearchBar';
import { Image } from 'components/Image';
import { ITempReference, TempReferenceModal } from 'components/Modal/TempReferenceModal';
import { hsBottomMargin } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';

interface IFormReferenceSelect extends IFormBase {
	testID: string;
	fieldName?: string;
	multiSelect?: boolean;
	type?: string;
	onSelect: (val: number | number[] | string | null) => void;
	placeholder?: string;
	value?: number | number[] | string;
	formStyle?: ViewStyle;
	contentType?: string;
	space?: ILauncherSpace;
	mapId?: number;
	isSelfService?: boolean;
	itemId?: number;
}

interface IOption extends Omit<IKVP, 'key'> {
	key: number | string;
	line2?: string;
}

export const FormReferenceSelect = (props: IFormReferenceSelect) => {
	const {
		testID,
		fieldName,
		type,
		onSelect,
		placeholder,
		value,
		label,
		hint,
		error,
		isRequired,
		multiSelect,
		isDisabled,
		formStyle,
		contentType,
		space,
		mapId,
		isSelfService,
		itemId
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [options, setOptions] = useState<IOption[]>([]);
	const [selfServiceOptions, setSelfServiceOptions] = useState<IOption[]>([]);
	const [searchedOptions, setSearchedOptions] = useState<IOption[]>([]);

	const [tempReferenceEntry, setTempReferenceEntry] = useState<ITempReference | undefined>(undefined);
	const [isDeleteLoading, setIsDeleteLoading] = useState<number | undefined>(undefined);

	const [searchTerm, setSearchTerm] = useState<string>('');

	const animatedRotation = useRef(new Animated.Value(0));

	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateMediaItem = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMediaItem);
	const addContent = useRematchDispatch((dispatch: Dispatch) => dispatch.content.addContent);

	useEffect(() => {
		Animated.timing(animatedRotation.current, {
			toValue: isExpanded ? 90 : 0,
			duration: 300,
			useNativeDriver: true
		}).start();
	}, [isExpanded]);

	useEffect(() => {
		let _options: typeof options = [];

		const mySpace = space ?? activeSpace;

		if (type && mySpace) {
			switch (type) {
				case 'votings':
					_options = votes
						.filter((e) => e.spaceId === mySpace.spaceId && !e.isDeleted && e.publicId)
						.sort((a, b) => {
							const aVal = a.question.toLowerCase();
							const bVal = b.question.toLowerCase();

							if (aVal === bVal) {
								return a.id < b.id ? -1 : 1;
							}

							return aVal < bVal ? -1 : 1;
						})
						.map((e) => {
							return {
								key: e.id,
								label: e.question,
								line2: t(e.votingType),
								...e
							};
						});
					break;
				case 'attendees':
					_options = attendees
						.filter((e) => {
							const hasName = !isEmptyString(e.firstName) && !isEmptyString(e.lastName);
							const found = e.userSpaceHistory?.spaces.find((hist) => hist.spaceId === mySpace?.spaceId);
							return hasName && found;
						})
						.sort((a, b) => {
							const aVal = getReversedName(`${a.firstName} ${a.lastName}`).toLowerCase();
							const bVal = getReversedName(`${b.firstName} ${b.lastName}`).toLowerCase();

							if (aVal === bVal) {
								return a.id < b.id ? -1 : 1;
							}

							return aVal < bVal ? -1 : 1;
						})
						.map((e) => {
							return {
								key: e.id,
								label: `${e.firstName} ${e.lastName}`,
								...e
							};
						});
					break;
				case 'schedules':
					if (content && content.schedules) {
						_options = content.schedules
							.filter((e) => e.spaceId === mySpace.spaceId)
							.sort((a, b) => {
								const aMoment = moment(a.startDate);
								const bMoment = moment(b.startDate);

								if (aMoment.isSame(bMoment)) {
									const aStage: IStage | undefined = content.stages.find((s) => s.id === a.stage?.id && !s.isDeleted);
									const bSTage: IStage | undefined = content.stages.find((s) => s.id === b.stage?.id && !s.isDeleted);

									return (aStage?.order ?? 0) < (bSTage?.order ?? 0) ? -1 : 1;
								}

								return aMoment.isBefore(bMoment) ? -1 : 1;
							})
							.map((e) => {
								let line2: string | undefined = undefined;
								if (e.startDate && e.endDate) {
									line2 = `${moment(e.startDate).format('DD.MM. HH:mm')} - ${moment(e.endDate).format('HH:mm')}`;
								} else if (e.startDate) {
									line2 = `${t('Start')}: ${moment(e.startDate).format('DD.MM. HH:mm')}`;
								} else if (e.endDate) {
									line2 = `${t('End')}: ${moment(e.endDate).format('DD.MM. HH:mm')}`;
								}

								if (e.stage) {
									line2 = `${line2} [${e.stage?.title}]`;
								}

								return {
									key: e.id,
									label: e.title,
									...e,
									line2
								};
							});
					}
					break;
				case 'networkingrooms':
					if (content?.networkingrooms) {
						_options = content.networkingrooms
							.filter((e) => e.spaceId === mySpace.spaceId && !e.isMeetingRoom)
							.sort((a, b) => {
								const aVal = a.title.toLowerCase();
								const bVal = b.title.toLowerCase();

								if (aVal === bVal) {
									return a.id < b.id ? -1 : 1;
								}

								return aVal < bVal ? -1 : 1;
							})
							.map((e) => {
								return {
									key: e.id,
									label: e.title,
									...e
								};
							});
					}
					break;
				case 'mappositions':
					if (content.mappositions) {
						_options = content.mappositions
							.filter((e) => e.spaceId === mySpace.spaceId && e.map?.id === mapId)
							.map((e) => {
								return {
									key: e.id,
									label: e.title,
									...e
								};
							});

						_options.unshift({
							key: -1,
							label: t('None')
						});
					}
					break;
				case 'features':
					_options =
						mySpace.features?.list
							.filter((e) => (e.contentType === 'iFrame' || e.contentType === 'markdown') && e.isActive)
							.sort((a, b) => {
								const aVal = (a.label ?? a.key).toLowerCase();
								const bVal = (b.label ?? b.key).toLowerCase();

								return aVal < bVal ? -1 : 1;
							})
							.map((e) => {
								return {
									label: e.label ?? e.key,
									line2: e.contentType,
									...e
								};
							}) ?? [];
					break;
				case 'speakers':
					if (content?.speakers) {
						_options = content.speakers
							.filter((e) => e.spaceId === mySpace.spaceId)
							.sort((a, b) => {
								const aVal = getReversedName(a.title).toLowerCase();
								const bVal = getReversedName(b.title).toLowerCase();

								if (aVal === bVal) {
									return a.id < b.id ? -1 : 1;
								}

								return aVal < bVal ? -1 : 1;
							})
							.map((e) => {
								return {
									key: e.id,
									label: `${!isEmptyString(e.speakerTitle) ? `${e.speakerTitle} ` : ''}${e.title}`,
									...e
								};
							});
					}
					break;
				default:
					if (content && content[type]) {
						_options = content[type]
							.filter((e) => e.spaceId === mySpace.spaceId)
							.sort((a, b) => {
								const aVal = a.title?.toLowerCase();
								const bVal = b.title?.toLowerCase();

								if (aVal === bVal) {
									return a.id < b.id ? -1 : 1;
								}

								return aVal < bVal ? -1 : 1;
							})
							.map((e) => {
								return {
									key: e.id,
									label: e.title,
									...e
								};
							});
					}
					break;
			}
		}

		setOptions(_options);
	}, [activeSpace, space, type, content, mapId]);

	useEffect(() => {
		if (isSelfService) {
			let _selfServiceOptions: IOption[] = [];
			if (value) {
				if (multiSelect && Array.isArray(value)) {
					_selfServiceOptions = options.filter((e) => value.includes(Number(e.key)));
				} else {
					_selfServiceOptions = options.filter((e) => e.key === value);
				}
			}
			setSelfServiceOptions(_selfServiceOptions);
		}
	}, [isSelfService, options, value, content]);

	useEffect(() => {
		let _searched: typeof searchedOptions = isSelfService ? [...selfServiceOptions] : [...options];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = _searched.filter((e) => {
				const keys = Object.keys(e);
				for (const key of keys) {
					if (e[key] && typeof e[key] === 'string' && e[key].toLowerCase().includes(lowerSearch)) {
						return true;
					}
				}
				return false;
			});
		}

		_searched
			.filter((item) => item.key >= 0)
			.sort((a, b) => {
				let aVal = '';
				let bVal = '';

				switch (type) {
					case 'speakers':
					case 'attendees':
						const aSplitted = a.label.split(' ');
						aVal = aSplitted[aSplitted.length - 1];
						const bSplitted = b.label.split(' ');
						bVal = bSplitted[bSplitted.length - 1];
						break;
					default:
						aVal = a.label.toLowerCase();
						bVal = b.label.toLowerCase();
						break;
				}

				return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
			});

		setSearchedOptions(_searched);
	}, [options, selfServiceOptions, searchTerm]);

	const _deleteReference = async (itemId: number | string) => {
		if (space) {
			let item;
			switch (fieldName) {
				case 'mediaitems':
					if (content.mediaitems) {
						item = content.mediaitems.find((e) => e.id === itemId);
					}
					break;
				default:
					break;
			}

			if (item) {
				setIsDeleteLoading(Number(itemId));

				switch (fieldName) {
					case 'mediaitems':
						const res = await updateMediaItem({
							fields: [],
							mediaitem: {
								...item,
								isDeleted: true
							},
							isDeletion: true,
							space
						});
						if (res) {
							addContent({ load: false, content: [{ type: 'mediaitems', items: [res] }] });
						}
						break;
					default:
						break;
				}
			}
			setIsDeleteLoading(undefined);
		}
	};

	const _handleMultiSelectPress = (key: number) => {
		let newValue = value ? [...(value as number[])] : [];
		if (newValue.includes(key)) {
			if (!isSelfService) {
				newValue = newValue.filter((e) => e !== key);
			}
		} else {
			newValue.push(key);
		}

		onSelect(newValue);
	};

	const _allowEdit = () => {
		const allowedFieldNames = ['mediaitems'];

		return (contentType === 'expo' || contentType === 'speaker') && fieldName && allowedFieldNames.includes(fieldName);
	};

	const _renderImage = (option: IOption) => {
		switch (type) {
			case 'expos':
				return (
					<View style={{ marginRight: 5 }}>
						<Image style={{ width: 35, aspectRatio: 16 / 9 }} mediaObj={option?.logo} />
					</View>
				);
			case 'speakers':
				return (
					<View style={{ marginRight: 5 }}>
						<Avatar fullName={option.label} size="xs" avatarObj={option?.image} />
					</View>
				);
			case 'attendees':
				return (
					<View style={{ marginRight: 5 }}>
						<Avatar fullName={option.label} size="xs" avatar={option?.smallImageUrl ?? option.imageUrl} />
					</View>
				);
			default:
				return null;
		}
	};

	const _renderOption = (option: IOption, idx: number) => {
		const isLastItem = idx === options.length - 1;
		if (multiSelect) {
			return (
				<ChildButton
					key={`${testID}_button_option_${idx}`}
					testID={`${testID}_button_option_${idx}`}
					style={{ flexDirection: 'row', alignItems: 'center', marginBottom: IS_WEB && isLastItem ? 2 : 4 }}
					onPress={() => _handleMultiSelectPress(Number(option.key))}
				>
					<View
						style={{
							height: 20,
							width: 20,
							justifyContent: 'center',
							alignItems: 'center',
							borderWidth: 1,
							borderColor: theme.lightgray,
							marginRight: 5,
							borderRadius: 20
						}}
					>
						{value && Array.isArray(value) && value.includes(Number(option.key)) && (
							<Icon name={EDefaultIconSet.Save} color={theme.text} />
						)}
					</View>
					{_renderImage(option)}
					<View style={{ flex: 1 }}>
						<Text numberOfLines={!isEmptyString(option.line2) ? 1 : 2}>{`${option.label}${
							option.key >= 0 ? ` (ID: ${option.key})` : ''
						}`}</Text>
						{!isEmptyString(option.line2) && (
							<Text style={{ fontSize: 12 }} numberOfLines={1}>
								{option.line2}
							</Text>
						)}
					</View>
					{_allowEdit() && fieldName && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="xs"
							onPress={() => setTempReferenceEntry({ fieldName, itemId: option.key })}
							noMargin
						/>
					)}
					{isSelfService && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="xs"
							onPress={() => {
								showAlert({
									title: t('ConfirmDeleteSingle').replace('%TITLE%', `"${option.label}"`),
									message: t('ConfirmDeleteSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('Delete'),
											style: 'destructive',
											onPress: async () => _deleteReference(option.key)
										}
									]
								});
							}}
							isLoading={isDeleteLoading === option.key}
							isDisabled={isDeleteLoading !== undefined}
							noMargin
						/>
					)}
				</ChildButton>
			);
		}

		return (
			<ChildButton
				key={`${testID}_button_option_${idx}`}
				testID={`${testID}_button_option_${idx}`}
				style={{ flexDirection: 'row', alignItems: 'center', marginBottom: IS_WEB && isLastItem ? 2 : 4 }}
				onPress={() => onSelect(value === option.key ? null : option.key)}
			>
				<View
					style={{
						height: 18,
						width: 18,
						borderRadius: 10,
						padding: 2,
						borderWidth: 1,
						borderColor: theme.lightgray,
						marginRight: 5
					}}
				>
					{value === option.key && (
						<View style={{ width: '100%', height: '100%', borderRadius: 999, backgroundColor: theme.primary }} />
					)}
				</View>
				{_renderImage(option)}
				<View style={{ flex: 1 }}>
					<Text numberOfLines={!isEmptyString(option.line2) ? 1 : 2}>{`${option.label}${
						option.key >= 0 ? ` (ID: ${option.key})` : ''
					}`}</Text>
					{!isEmptyString(option.line2) && (
						<Text style={{ fontSize: 12 }} numberOfLines={1}>
							{option.line2}
						</Text>
					)}
				</View>
				{_allowEdit() && fieldName && (
					<RoundButton
						testID={`${testID}_button_edit`}
						icon={EDefaultIconSet.Edit}
						title={t('Edit')}
						size="xs"
						onPress={() => setTempReferenceEntry({ fieldName, itemId: option.key })}
					/>
				)}
			</ChildButton>
		);
	};

	const _renderLoading = () => {
		if (type && waitingForSocketResponse && waitingForSocketResponse[type]) {
			return <Spinner size="small" />;
		}

		return null;
	};

	const _renderOptions = () => {
		if (isExpanded) {
			const myOptions = isSelfService ? selfServiceOptions : options;

			if (myOptions?.length === 0) {
				return (
					<View
						style={{
							borderWidth: FORMELEMENTBORDERWIDTH,
							borderRadius: FORMELEMENTBORDERRADIUS,
							borderColor: theme.lightgray,
							marginBottom: hsBottomMargin,
							padding: 5
						}}
					>
						{_renderLoading()}
						<Text>{t('noEntriesAvailable')}</Text>
					</View>
				);
			}

			return (
				<View
					style={{
						borderWidth: FORMELEMENTBORDERWIDTH,
						borderRadius: FORMELEMENTBORDERRADIUS,
						borderColor: theme.lightgray,
						marginBottom: hsBottomMargin,
						padding: 5
					}}
				>
					{myOptions.length > 5 && (
						<View style={{ paddingHorizontal: 5, marginVertical: 5 }}>
							<SearchBar testID={`${testID}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
						</View>
					)}
					{_renderLoading()}
					<ScrollView style={{ maxHeight: 150 }}>
						{searchedOptions.length === 0 ? (
							<View style={{ height: 20, flexDirection: 'row', alignItems: 'center' }}>
								<Text>{t('NoMatchedForSearch')}</Text>
							</View>
						) : null}
						{searchedOptions.map((item, index) => _renderOption(item, index))}
					</ScrollView>
				</View>
			);
		}

		return null;
	};

	const _renderValue = () => {
		if (!multiSelect) {
			if (value) {
				const option = options?.find((e) => e.key === value);
				if (option) {
					return (
						<Text style={{ color: isDisabled ? theme.lightgray : theme.text }} numberOfLines={1}>
							{`${option.label}${option.key >= 0 ? ` (ID: ${option.key})` : ''}`}
						</Text>
					);
				}
			}
		}

		if (multiSelect && value && Array.isArray(value) && value.length > 0) {
			const val: string[] = [];

			value.forEach((v) => {
				const option = options.find((e) => e.key === v);
				if (option) {
					val.push(option.label);
				}
			});

			return (
				<Text numberOfLines={1} style={{ color: isDisabled ? theme.lightgray : theme.text }}>
					{val.join(', ')}
				</Text>
			);
		}

		return <Text style={{ color: isDisabled ? theme.lightgray : theme.text }}>{placeholder ?? t('DropdownPlaceholder')}</Text>;
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<ChildButton
				testID={`${testID}_button_expand`}
				onPress={() => setIsExpanded(!isExpanded)}
				isDisabled={isDisabled}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: 10,
					padding: 5,
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderColor: theme.lightgray
				}}
			>
				<View style={{ flex: 1 }}>{_renderValue()}</View>
				<Animated.View
					style={{
						transform: [
							{
								rotateZ: animatedRotation.current.interpolate({
									inputRange: [0, 90],
									outputRange: ['0deg', '90deg']
								})
							}
						]
					}}
				>
					<Icon name={EDefaultIconSet.ChevronRight} color={isDisabled ? theme.lightgray : theme.text} />
				</Animated.View>
			</ChildButton>
			{_renderOptions()}
			{_allowEdit() && fieldName && (
				<RoundButton
					testID={`${testID}_button_add`}
					onPress={() => setTempReferenceEntry({ fieldName })}
					icon={EDefaultIconSet.Add}
					alignSelf="flex-end"
					size="sm"
					isDisabled={isDisabled}
				/>
			)}
			<TempReferenceModal
				tempReference={tempReferenceEntry}
				onClose={() => setTempReferenceEntry(undefined)}
				contentType={contentType}
				onSubmit={(item) => {
					if (multiSelect) {
						const _val = value ? [...(value as number[])] : [];
						if (!_val.includes(item.id)) {
							_val.push(item.id);
						}
						onSelect(_val);
					} else {
						onSelect(item.id);
					}
					setTempReferenceEntry(undefined);
				}}
				itemId={itemId}
				space={space}
				isSelfService={isSelfService}
			/>
		</FormField>
	);
};
