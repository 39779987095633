import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { RoundButton } from 'components/Button';
import { SpaceForgotPasswordForm, SpaceLoginForm, SpaceRegistrationForm, SpaceResetPasswordForm } from 'components/Forms';
import {
	ILauncherSpace,
	ISpaceForgotPasswordFormErrors,
	ISpaceForgotPasswordFormValues,
	ISpaceLoginFormErrors,
	ISpaceLoginFormValues,
	ISpaceRegistrationFormErrors,
	ISpaceRegistrationFormValues,
	ISpaceResetPasswordFormErrors,
	ISpaceResetPasswordFormValues,
	ISpaceTicketFormValues,
	SpaceForgotPasswordFormInitialValues,
	SpaceLoginFormInitialValues,
	SpaceResetPasswordFormInitialValues
} from 'config/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import {
	EDefaultIconSet,
	generateTheme,
	getTopRadiusStyle,
	isEmptyString,
	IS_WEB,
	showFormErrorToast,
	translateStrapiError,
	validateForm
} from 'helper';
import { getSpaceForgotPasswordFormSchema, getSpaceLoginFormSchema, getSpaceResetPasswordFormSchema } from 'config/yupSchemas';
import { useSelector } from 'react-redux';
import { Keyboard, ScrollView, View } from 'react-native';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';

import { HSCard } from 'components/Card';
import { Hint, Title } from 'components/Text';
import { showToast } from 'helper/toast';
import { SupportRequestModal } from 'components/Modal/SupportRequestModal';
import { Markdown } from 'components/Markdown';
import { HSHeaderCard } from 'components/Card/HSHeaderCard';
import { FormTextInput } from 'components/Form';
import { QRCodeScannerModal } from 'components/Modal';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { isEmptyObject } from 'helper/object';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceAuth>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceAuth>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceAuth];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export type TAuthState =
	| 'ticket'
	| 'register'
	| 'login'
	| 'confirmaccount'
	| 'forgotpw'
	| 'resetpw'
	| 'registerAfterTicket'
	| 'help'
	| 'emailOrTicket';

const TESTIDPREFIX = 'spaceauthentication';

export const SpaceAuthenticationScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { getRegistrationFields, getInitialFormValues, isRegisterSubmitDisabled } = useRegistration();
	const { getFormSchema } = useForm();
	const { trackAction } = useTracker();
	const { activeSpace } = useSpace();
	const { isTabletOrMobile } = useQuery();

	const [isHelpExpanded, setIsHelpExpanded] = useState<boolean>(false);

	const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState<boolean>(false);
	const [forgotPasswordFormValues, setForgotPasswordFormValues] = useState<ISpaceForgotPasswordFormValues>({
		...SpaceForgotPasswordFormInitialValues
	});
	const [forgotPasswordFormErrors, setForgotPasswordFormErrors] = useState<ISpaceForgotPasswordFormErrors>({});

	const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
	const [loginFormValues, setLoginFormValues] = useState<ISpaceLoginFormValues>({ ...SpaceLoginFormInitialValues });
	const [loginFormErrors, setLoginFormErrors] = useState<ISpaceLoginFormErrors>({});

	const [isRegistrationLoading, setIsRegistrationLoading] = useState<boolean>(false);
	const [registrationFormValues, setRegistrationFormValues] = useState<ISpaceRegistrationFormValues>({
		...getInitialFormValues()
	});
	const [registrationFormErrors, setRegistrationFormErrors] = useState<ISpaceRegistrationFormErrors>({});

	const [isResetPasswordLoading, setIsResetPasswordLoading] = useState<boolean>(false);
	const [resetPasswordFormValues, setResetPasswordFormValues] = useState<ISpaceResetPasswordFormValues>({
		...SpaceResetPasswordFormInitialValues
	});
	const [resetPasswordFormErrors, setResetPasswordFormErrors] = useState<ISpaceResetPasswordFormErrors>({});

	const [authState, setAuthState] = useState<TAuthState>('ticket');
	const [isJoinSpaceLoading, setIsJoinSpaceLoading] = useState<boolean>(false);

	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [isSupportRequestModalVisible, setIsSupportRequestModalVisible] = useState<boolean>(false);
	const [isQRCodeModalVisible, setIsQRCodeModalVisible] = useState<boolean>(false);

	const [isTicketcheckLoading, setIsTicketCheckLoading] = useState<boolean>(false);
	const [ticketFormValues, setTicketFormValues] = useState<ISpaceTicketFormValues>({ ticketcode: '' });
	const [hasValidTicket, setHasValidTicket] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const tempSpaceId = useSelector((store: IRootState) => store.temp.tempSpaceId);

	const registerWithEmailAndPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.registerWithEmailAndPassword);
	const loginWithEmailAndPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.loginWithEmailAndPassword);
	const forgotPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.forgotPassword);
	const resetPassword = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.resetPassword);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const setTempSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setTempSpaceId);
	const fetchTicket = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.fetchTicket);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			switch (authState) {
				case 'login':
					_login();
					break;
				case 'register':
					_register();
					break;
				case 'resetpw':
					_resetPassword();
					break;
				case 'forgotpw':
					_forgotPassword();
					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		if (route.params) {
			setResetPasswordFormValues({
				...resetPasswordFormValues,
				email: route.params['email'] ?? '',
				code: route.params['code'] ?? ''
			});
			setForgotPasswordFormValues({ email: route.params['email'] ?? '' });
			setLoginFormValues({ email: route.params['email'] ?? '', password: '' });
			setRegistrationFormValues({ ...getInitialFormValues(), email: route.params['email'] ?? '' });
		}
	}, []);

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('keydown', _handleEnter, false);
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, [authState, loginFormValues, registrationFormValues, resetPasswordFormValues, forgotPasswordFormValues]);

	useEffect(() => {
		let title = t('Registration');
		if (PRESET_SPACEIDS && PRESET_SPACEIDS?.length === 1 && space && route.params.authState === 'register') {
			if (space.ticketSettings?.ticketRequired) {
				title = t('Ticket');
				setAuthState('ticket');
			} else {
				setAuthState('register');
			}
		} else {
			if (route.params?.authState) {
				title = t(route.params.authState);
				setAuthState(route.params.authState);
			}
		}
	}, [route, space]);

	useEffect(() => {
		if (authState === 'register') {
			setRegistrationFormValues({
				...registrationFormValues,
				firstName: userInfos.firstName,
				lastName: userInfos.lastName,
				email: userInfos.email
			});
		}
		navigation.setOptions({
			title: authState === 'ticket' ? t('Ticket') : authState === 'emailOrTicket' ? t('Login') : t(authState)
		});
	}, [authState]);

	useEffect(() => {
		getSpaceById();
	}, [tempSpaceId]);

	const getSpaceById = async () => {
		if (tempSpaceId) {
			const _space = await findSpaceById({ spaceId: tempSpaceId, noToast: true });
			if (_space) {
				setSpace(_space);
			}

			setTempSpaceId(undefined);
		} else if (PRESET_SPACEIDS && PRESET_SPACEIDS?.length === 1) {
			const _space = await findSpaceById({ spaceId: PRESET_SPACEIDS[0], noToast: true });
			if (_space) {
				setSpace(_space);
				setTheme(generateTheme(_space));
			}
		}
	};

	const _register = async () => {
		navigation.replace(ERoutes.SpaceSelect, {});
		const errors = await validateForm(getFormSchema(getRegistrationFields(), registrationFormValues), registrationFormValues);
		if (errors) {
			setRegistrationFormErrors(errors);
			showFormErrorToast(errors, getRegistrationFields());
			return;
		} else {
			setRegistrationFormErrors({});
		}

		let _sendConfirmationMail = route.params?.redirectRoute === ERoutes.SpaceCreate || (space?.forceDoubleOptInForUsers ?? false);

		setIsRegistrationLoading(true);
		const res = await registerWithEmailAndPassword({
			user: {
				...registrationFormValues,
				userId: userInfos.userId
			},
			fields: getRegistrationFields(),
			ticketcode: !isEmptyString(ticketFormValues.ticketcode) ? ticketFormValues.ticketcode : undefined,
			space: space ?? activeSpace,
			handleErrorLocally: true,
			shouldSendConfirmationEmail: _sendConfirmationMail
		});
		setIsRegistrationLoading(false);

		if (!res) {
			showToast('error', 'Error', t('Unknown Error'));
			return;
		}

		let code = '';
		if (res.message && typeof res.message === 'string') {
			code = res.message;
		} else if (Array.isArray(res.message) && res.message[0] && res.message[0].messages && res.message[0].messages[0]) {
			code = res.message[0].messages[0].id;
		}

		if (!isEmptyString(code)) {
			if ((code = 'Auth.form.error.email.taken')) {
				showAlert({
					title: t('accountAlreadyInUse'),
					message: t('accountAlreadyInUseSubtitle'),
					buttons: [
						{
							text: t('Ok'),
							style: 'default'
						},
						{
							text: t('login'),
							style: 'default',
							onPress: async () => {
								setAuthState('login');
							}
						}
					]
				});
			} else {
				showAlert({ title: 'Error', message: translateStrapiError(res) });
			}
		} else {
			trackAction('space', 'Register');

			if (IS_WEB && route.params.redirectUrl) {
				window.open(route.params.redirectUrl, '_self');
			} else if (route.params?.redirectRoute) {
				navigation.replace(route.params.redirectRoute);
			} else if (route.params?.spaceId) {
				_joinSpace(route.params.spaceId);
			} else {
				if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
					navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
				} else {
					navigation.navigate(ERoutes.SpaceSelect, {});
				}
			}
			if (_sendConfirmationMail) {
				showToast(
					'custom',
					t('RegistrationConfirmation'),
					t('RegistrationConfirmationHint').replace('%EMAIL%', registrationFormValues.email)
				);
			}
		}
	};

	const _login = async () => {
		const errors = await validateForm(getSpaceLoginFormSchema(loginFormValues), loginFormValues);
		if (errors) {
			setLoginFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setLoginFormErrors({});
		}

		setIsLoginLoading(true);
		const res = await loginWithEmailAndPassword(loginFormValues);
		setIsLoginLoading(false);

		if (res?.userId) {
			trackAction('space', 'Login');
			if (IS_WEB && route.params.redirectUrl) {
				window.open(route.params.redirectUrl, '_self');
			} else if (route.params?.redirectRoute) {
				if (activeSpace && navigation.canGoBack()) {
					navigation.goBack();
				} else {
					navigation.replace(route.params.redirectRoute);
				}
			} else if (route.params?.spaceId) {
				_joinSpace(route.params.spaceId);
			} else {
				if (PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
					_joinSpace(PRESET_SPACEIDS[0]);
				} else {
					navigation.replace(ERoutes.SpaceSelect, {});
				}
			}
		} else {
			showAlert({ title: t('Login failed'), message: translateStrapiError(res) });
		}
	};

	const _joinSpace = async (spaceId: string) => {
		setIsJoinSpaceLoading(true);
		const space = await findSpaceById({ spaceId });
		setIsJoinSpaceLoading(false);
		if (space) {
			navigation.replace(ERoutes.SpaceSummary, { spaceId });
		}
	};

	const _forgotPassword = async () => {
		const errors = await validateForm(getSpaceForgotPasswordFormSchema(forgotPasswordFormValues), forgotPasswordFormValues);
		if (errors) {
			setForgotPasswordFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setForgotPasswordFormErrors({});
		}

		setIsForgotPasswordLoading(true);
		const res = await forgotPassword(forgotPasswordFormValues);
		setIsForgotPasswordLoading(false);

		showAlert({
			title: t('ForgotPWSuccessTitle'),
			message: t('ForgotPWSuccessSubitle').replace('%EMAIL%', forgotPasswordFormValues.email as string),
			buttons: [
				{
					onPress: () => navigation.setParams({ authState: 'login', code: undefined, email: undefined }),
					text: 'OK'
				}
			]
		});
	};

	const _resetPassword = async () => {
		const errors = await validateForm(getSpaceResetPasswordFormSchema(resetPasswordFormValues), resetPasswordFormValues);
		if (errors) {
			setResetPasswordFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setResetPasswordFormErrors({});
		}

		setIsResetPasswordLoading(true);
		const res = await resetPassword(resetPasswordFormValues);
		setIsResetPasswordLoading(false);

		if (res) {
			showAlert({
				title: t('ResetPWSuccessTitle'),
				message: t('ResetPWSuccessSubtitle'),
				buttons: [
					{
						onPress: () => _updateAuthState('login'),
						text: 'OK'
					}
				]
			});
		}
	};

	const _isLoginDisabled = () => {
		return isEmptyString(loginFormValues.email) || isEmptyString(loginFormValues.password);
	};

	const _isForgotPasswordDisabled = () => {
		return isEmptyString(forgotPasswordFormValues.email);
	};

	const _isResetPasswordDisabled = () => {
		return (
			isEmptyString(resetPasswordFormValues.email) ||
			isEmptyString(resetPasswordFormValues.password) ||
			isEmptyString(resetPasswordFormValues.passwordConfirmation)
		);
	};

	const _updateAuthState = (newState: TAuthState) => {
		setAuthState(newState);
		navigation.setParams({
			authState: newState
		});
	};

	const _renderButtons = () => {
		let _forgotPwButton;

		switch (authState) {
			case 'emailOrTicket':
			case 'login':
				_forgotPwButton = (
					<RoundButton
						isOutline
						testID="authentication_button_forgotpw"
						title={t('ForgotPw?')}
						onPress={() => setAuthState('forgotpw')}
						icon={EDefaultIconSet.ResetPassword}
						textColor={theme.text}
						isDisabled={isLoginLoading || isJoinSpaceLoading}
					/>
				);
		}

		let _helpButton = (
			<RoundButton
				isOutline
				testID="authentication_button_togglehelp"
				title={t('Help')}
				onPress={() => setIsHelpExpanded(!isHelpExpanded)}
				icon={EDefaultIconSet.Help}
				textColor={theme.text}
				isDisabled={isLoginLoading || isJoinSpaceLoading}
			/>
		);

		return (
			<View
				style={{
					paddingTop: 20,
					marginTop: 10,
					borderTopWidth: 1,
					borderTopColor: theme.lightgray
				}}
			>
				<View
					style={{
						flexDirection: isTabletOrMobile ? 'column' : 'row',
						flexWrap: 'wrap',
						justifyContent: 'space-between'
					}}
				>
					{_forgotPwButton}
					<RoundButton
						isOutline
						testID="authentication_button_supportrequest"
						title={t('sideBarSupportRequest')}
						onPress={() => setIsSupportRequestModalVisible(true)}
						icon={EDefaultIconSet.Support}
						textColor={theme.text}
						isDisabled={isLoginLoading || isRegistrationLoading || isJoinSpaceLoading}
					/>
					{_helpButton}
				</View>
				{isHelpExpanded && <Markdown markdown={t('NoSpaceAuthHelpMarkdown')} />}
			</View>
		);
	};

	const _renderTicketIntroText = () => {
		if (!isEmptyString(space?.ticketSettings?.ticketIntro)) {
			return <Markdown markdown={space?.ticketSettings?.ticketIntro} />;
		}

		switch (space?.ticketSettings?.ticketProvider) {
			case 'xingevents':
				return <Markdown markdown={t('XINGTicketIntroPlaceholder')} />;
			case 'emendo':
				return <Markdown markdown={t('EmendoTicketIntroPlaceholder')} />;
			case 'pretix':
				return <Markdown markdown={t('PretixTicketIntroPlaceholder')} />;
			case 'hellospaces':
			default:
				return <Markdown markdown={t('HelloSpacesTicketIntroPlaceholder')} />;
		}
	};

	const _fetchTicket = async (ticketcode?: string) => {
		if (space) {
			setIsTicketCheckLoading(true);
			const res = await fetchTicket({ space, ticketcode: ticketcode ?? ticketFormValues.ticketcode });
			switch (res?.error) {
				case undefined:
					break;
				case 'ticketInUse':
					showAlert({
						title: t('auth.form.error.ticketcode.alreadyinuse'),
						message: t('LoginNow?'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('Login'),
								onPress: () => setAuthState('login')
							}
						]
					});
					break;
				default:
					// Ticket fetch success
					setRegistrationFormValues({ ...getInitialFormValues(space?.registrationFields?.fields, res) });
					setAuthState('registerAfterTicket');
					setHasValidTicket(true);
					break;
			}

			setIsTicketCheckLoading(false);
		}
	};

	const _renderForm = () => {
		switch (authState) {
			case 'forgotpw':
				return (
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<View style={{ marginBottom: hsBottomMargin }}>
							<Title>{t('forgotPasswordTitle')}</Title>
							<Hint>{t('forgotPasswordSubtitle')}</Hint>
						</View>
						<SpaceForgotPasswordForm
							values={forgotPasswordFormValues}
							errors={forgotPasswordFormErrors}
							onChange={(val) => setForgotPasswordFormValues(val)}
							onSubmit={_forgotPassword}
							isLoading={isForgotPasswordLoading}
						/>

						<RoundButton
							title={t('requestPassword')}
							testID="forgotpwform_button_submit"
							isLoading={isForgotPasswordLoading}
							isDisabled={_isForgotPasswordDisabled()}
							onPress={() => _forgotPassword()}
							icon={EDefaultIconSet.Save}
							alignSelf="center"
						/>

						{_renderButtons()}
					</HSCard>
				);
			case 'resetpw':
				if (isEmptyString(resetPasswordFormValues.code)) {
					return (
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<Title>{t('yourUrlIsWrong')}</Title>
							<RoundButton
								title={t('resendForgotPasswordMail')}
								testID="resendforgotpasswordmail_button_submit"
								isLoading={isForgotPasswordLoading}
								isDisabled={_isForgotPasswordDisabled()}
								onPress={() => _forgotPassword()}
								icon={EDefaultIconSet.Save}
								alignSelf="center"
							/>
						</HSCard>
					);
				}
				return (
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<Title>{t('resetPasswordTitle')}</Title>
						<Hint>{t('resetPasswordSubtitle')}</Hint>
						<SpaceResetPasswordForm
							values={resetPasswordFormValues}
							errors={resetPasswordFormErrors}
							onChange={(val) => setResetPasswordFormValues(val)}
						/>
						<RoundButton
							testID="resetpwform_button_submit"
							isLoading={isResetPasswordLoading}
							isDisabled={_isResetPasswordDisabled()}
							onPress={() => _resetPassword()}
							icon={EDefaultIconSet.Save}
							title={t('savePassword')}
							alignSelf="center"
						/>
					</HSCard>
				);
			case 'login':
				return (
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<SpaceLoginForm
							values={loginFormValues}
							errors={loginFormErrors}
							onChange={(val) => setLoginFormValues(val)}
							handleEnter={_handleEnter}
						/>

						<RoundButton
							testID={`loginform_button_login`}
							title={t('Login')}
							isLoading={isLoginLoading}
							isDisabled={_isLoginDisabled()}
							onPress={() => _login()}
							icon={EDefaultIconSet.Save}
							alignSelf="center"
						/>
						{_renderButtons()}
					</HSCard>
				);
			case 'ticket':
				return (
					<View style={{ flex: 1 }}>
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							{_renderTicketIntroText()}
							<View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
								<FormTextInput
									formStyle={{ marginBottom: hsBottomMargin, flex: 1 }}
									testID={`${TESTIDPREFIX}_formtextinput_ticketcode`}
									label={t('Ticketcode')}
									isRequired
									value={ticketFormValues.ticketcode}
									hint={
										space?.ticketSettings?.ticketProvider === 'xingevents'
											? t('TicketCodeHintXingEvents')
											: t('TicketCodeHint')
									}
									onChangeText={(ticketcode) => {
										let _value = ticketcode?.toLowerCase().trim();

										if (space?.ticketSettings?.ticketProvider === 'xingevents') {
											const hyphenIndexes = [4, 9];
											if (
												hyphenIndexes.includes(_value.length) &&
												ticketFormValues.ticketcode.length < _value.length
											) {
												_value += '-';
											}
										}
										setTicketFormValues({ ...ticketFormValues, ticketcode: _value });
									}}
									autoCapitalize="none"
									autoCompleteType="off"
									autoCorrect={false}
									keyboardType="email-address"
								/>
								{!IS_WEB && (
									<View style={{ marginBottom: 5, paddingLeft: 10 }}>
										<RoundButton
											isOutline
											testID="spaceselect_button_qrcodescanner"
											isDisabled={isTicketcheckLoading}
											onPress={() => setIsQRCodeModalVisible(!isQRCodeModalVisible)}
											icon={EDefaultIconSet.QRCode}
										/>
									</View>
								)}
							</View>
							<RoundButton
								isFloatingButton
								isDisabled={isEmptyString(ticketFormValues.ticketcode)}
								testID={`${TESTIDPREFIX}_button_fetchticket`}
								icon={EDefaultIconSet.Save}
								title={t('checkTicketcode')}
								alignSelf="center"
								onPress={() => _fetchTicket()}
								isLoading={isTicketcheckLoading || isJoinSpaceLoading}
							/>
							{_renderButtons()}
						</HSCard>
						<QRCodeScannerModal
							isVisible={isQRCodeModalVisible}
							onResult={(result) => {
								setTicketFormValues({ ...ticketFormValues, ticketcode: result.toLowerCase() });
								_fetchTicket(result.toLowerCase());
								setIsQRCodeModalVisible(false);
							}}
							onClose={() => setIsQRCodeModalVisible(false)}
						/>
					</View>
				);
			case 'registerAfterTicket':
				return (
					<View style={{ flex: 1 }}>
						<HSHeaderCard subtitle={t('registerAfterTicketSubtitle')} style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }} />
						<SpaceRegistrationForm
							values={registrationFormValues}
							errors={registrationFormErrors}
							onChange={(val) => setRegistrationFormValues(val)}
							isModal
							space={space}
							fields={space?.registrationFields?.fields}
							registrationIntro={space?.registrationIntro}
							registerButton={
								<View>
									<RoundButton
										isFloatingButton
										testID="authentication_button_register"
										onPress={() => _register()}
										title={t('Register')}
										icon={EDefaultIconSet.Save}
										textColor={theme.text}
										isLoading={isRegistrationLoading || isJoinSpaceLoading}
										alignSelf="center"
									/>
									{_renderButtons()}
								</View>
							}
						/>
					</View>
				);
			case 'register':
				return (
					<SpaceRegistrationForm
						values={registrationFormValues}
						errors={registrationFormErrors}
						onChange={(val) => setRegistrationFormValues(val)}
						noRoundedTop
						space={space}
						registerButton={
							<View>
								<RoundButton
									testID={`registrationform_button_submit`}
									title={t('Register')}
									isLoading={isRegistrationLoading || isJoinSpaceLoading}
									isDisabled={isRegisterSubmitDisabled(registrationFormValues)}
									onPress={() => _register()}
									icon={EDefaultIconSet.Save}
									alignSelf="center"
								/>
								{_renderButtons()}
							</View>
						}
					/>
				);
			case 'help':
				return (
					<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
						<View>
							<Markdown
								markdown={
									space
										? !isEmptyString(space?.authHelpText)
											? space?.authHelpText
											: t('SpaceAuthHelpMarkdown')
										: t('NoSpaceAuthHelpMarkdown')
								}
							/>
							{_renderButtons()}
						</View>
					</HSCard>
				);
			default:
				return null;
		}
	};

	return (
		<ScreenContainer
			bgImage={!activeSpace ? space?.backgroundImage : undefined}
			bgImageName={!activeSpace ? space?.backgroundImageName : undefined}
			style={{
				backgroundColor:
					!activeSpace && isEmptyObject(space?.backgroundImage) && isEmptyString(space?.backgroundImageName)
						? space?.backgroundColor
						: 'transparent'
			}}
		>
			<View
				style={{
					flex: 1,
					width: screenWidth,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					alignSelf: 'center'
				}}
			>
				<ScrollView
					keyboardShouldPersistTaps="handled"
					testID="authenticationscreen_scrollview"
					onScrollBeginDrag={() => Keyboard.dismiss()}
					scrollEventThrottle={0}
					contentContainerStyle={{
						width: '100%',
						alignSelf: 'center'
					}}
					style={{ width: '100%', alignSelf: 'center', marginBottom: hsBottomMargin }}
				>
					{_renderForm()}
				</ScrollView>
				<SupportRequestModal
					space={space}
					isVisible={isSupportRequestModalVisible}
					onClose={() => setIsSupportRequestModalVisible(false)}
				/>
			</View>
		</ScreenContainer>
	);
};

export const SpaceAuthenticationScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
