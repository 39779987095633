import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import moment from 'moment';

import { ChildButton, RoundButton } from 'components/Button';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { EDefaultIconSet } from 'helper';
import { IAttendee } from 'config/interfaces';
import { H3 } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { WORDCLOUDSPLITSEQUENCE } from 'components/Vote/ActiveVote';
import { Text } from 'components/Text';
import { BUTTONBORDERRADIUS } from 'components/Button/constants';
import { HSCard } from 'components/Card';
import { Avatar, UserHeader } from 'components/User';

interface IVoteModerationWordCloudListItem {
	item: IVoteAnswer;
	isLoading?: boolean;
	onSubmit?: (item: IVoteAnswer) => void;
	onEdit?: (item: IVoteAnswer) => void;
}

export const VoteModerationWordCloudListItem = (props: IVoteModerationWordCloudListItem) => {
	const { item, isLoading, onSubmit, onEdit } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		const _attendee = attendees?.find((e) => e.userId === item.userId);

		setAttendee(_attendee);
	}, [attendees]);

	const _delete = async () => {
		if (item && onSubmit) {
			onSubmit({ ...item, isDeleted: true });
		}
	};

	const _renderValue = () => {
		const splittedWords = item?.value ? item.value.split(WORDCLOUDSPLITSEQUENCE) : [];

		return (
			<View style={{ marginTop: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
				{splittedWords.map((word, idx) => {
					return (
						<ChildButton
							key={`activevote_button_${idx}`}
							testID={`activevote_button_${word}`}
							style={{
								flexDirection: 'row',
								backgroundColor: theme.primary,
								borderRadius: BUTTONBORDERRADIUS,
								marginBottom: 10,
								marginRight: 10,
								paddingHorizontal: 10,
								paddingVertical: 5
							}}
							isDisabled
						>
							<Text style={{ color: theme.primaryContrast }}>{word}</Text>
						</ChildButton>
					);
				})}
			</View>
		);
	};

	return (
		<HSCard>
			<UserHeader attendee={attendee} createdAt={item.created_at} isAnonymous={item.isAnonymousSubmission} />
			{_renderValue()}
			{onEdit && (
				<View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 5 }}>
					<View style={{ marginRight: 10 }}>
						<RoundButton
							isOutline
							testID={`votemoderation_button_${item.id}_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={() => onEdit(item)}
						/>
					</View>
					<RoundButton
						isOutline
						testID={`voteanswer_list_item_${item.id}_delete`}
						icon={EDefaultIconSet.Delete}
						color={theme.danger}
						size="sm"
						onPress={() =>
							showAlert({
								title: t('ConfirmDelete'),
								message: t('ConfirmDeleteSubtitle'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('Delete'),
										style: 'destructive',
										onPress: () => _delete()
									}
								]
							})
						}
						isLoading={isLoading}
					/>
				</View>
			)}
		</HSCard>
	);
};
