import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ScrollView } from 'react-native';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { HSCard } from 'components/Card';
import { Markdown } from 'components/Markdown';
import i18next from 'i18next';
import { hsTopScreenPadding } from 'config/styleConstants';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.CustomUserConstent>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.CustomUserConstent>;
type RouteParams = NoSpaceStackParamList[ERoutes.CustomUserConstent];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const CustomUserConsentScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	return (
		<ScreenContainer>
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<Markdown markdown={activeSpace?.customUserConsentText} />
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const CustomUserConsentScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('sideBarUserConsent')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
