import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import { FlashList as SFlashList, FlashListProps } from '@shopify/flash-list';
import { View } from 'react-native';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { Placeholder, PlaceholderMedia, ShineOverlay, PlaceholderLine } from 'rn-placeholder';
import { getReversedName, isEmptyString, IS_WEB } from 'helper';
import { hsInnerPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { useNavigation } from '@react-navigation/native';

type TData = string | object;
interface IFlashSectionList extends FlashListProps<T> {
	renderSectionHeader: (renderItem) => ReactElement | null;
	hideIndexButtons?: boolean;
	sectionData?: string[];
	showSectionButtonsOnCard?: boolean;
	reverseTitleOnSectionButtonPress?: boolean;
}

export const FlashSectionList = (props: IFlashSectionList) => {
	const {
		data,
		renderSectionHeader,
		renderItem,
		estimatedItemSize,
		hideIndexButtons,
		sectionData,
		showSectionButtonsOnCard,
		reverseTitleOnSectionButtonPress
	} = props;

	const { theme } = useTheme();
	const navigation = useNavigation();

	const listRef = useRef<SFlashList<TData>>(null);

	const [stickyHeaders, setStickyHeaders] = useState<number[]>([]);
	const [offset, setOffset] = useState<number>(0);

	// hier ist ein bisschen ein dirty hack am werk:
	// wenn der erste stickyHeaderIndice 0 ist und beim ändern der daten wieder 0, wird der header nicht neu gerendert. heißt, wenn ich auf matchings oder favoriten wechsel bleibt der erste header immer gleich
	// also gebe ich ein leeren string mit als erstes element, der nicht gerendert wird. dadurch ist der erste indice die 1. dadurch wird es neu gerendert und der header passt sich beim filter wechsel an

	useEffect(() => {
		if (IS_WEB) {
			navigation.addListener('focus', () => _scrollToOld());
		}
	}, [offset]);

	useEffect(() => {
		return () => {
			if (IS_WEB) {
				navigation.removeListener('focus', () => _scrollToOld());
			}
		};
	}, []);

	const _scrollToOld = () => {
		if (IS_WEB) {
			setTimeout(() => {
				listRef.current?.scrollToOffset({ offset: offset });
			}, 100);
		}
	};

	const _setScrollPosition = (scrollEvent) => {
		if (IS_WEB) {
			if (scrollEvent.nativeEvent.contentOffset.y !== 0) {
				setOffset(scrollEvent.nativeEvent.contentOffset.y);
			}
		}
	};

	useEffect(() => {
		const stickyHeaderIndices = data
			?.map((item, index) => {
				if (typeof item === 'string') {
					if (isEmptyString(item)) {
						return null;
					}
					return index;
				} else {
					return null;
				}
			})
			.filter((item) => item !== null) as number[];

		setStickyHeaders(stickyHeaderIndices);
	}, [data]);

	const _renderSectionButton = (header: string) => {
		return (
			<ChildButton
				testID={`sectionlist_${header}`}
				style={{ width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}
				onPress={() => {
					const item = data?.find(
						(e) =>
							e === header ||
							(reverseTitleOnSectionButtonPress
								? getReversedName(e.title).startsWith(header)
								: e.title?.startsWith(header)) ||
							getReversedName(e.lastName).startsWith(header)
					);

					if (item) {
						listRef.current?.scrollToItem({ item, animated: true });
					}
				}}
			>
				<Text bold style={{ color: theme.primary, fontSize: 12 }}>
					{header.includes(' ') ? header.split(' ')[0] : header}
				</Text>
			</ChildButton>
		);
	};

	const _renderEmptyElements = () => {
		const emptyListLength = 10;
		const emptyList: ReactNode[] = [];

		for (let index = 0; index < emptyListLength; index++) {
			emptyList.push(
				<View key={`emptylist_element_${index}`} style={{ height: estimatedItemSize }}>
					<Placeholder Left={PlaceholderMedia} Animation={ShineOverlay}>
						<PlaceholderLine />
						<PlaceholderLine width={50} />
					</Placeholder>
				</View>
			);
		}

		return emptyList;
	};

	const _renderSectionButtonList = () => {
		const listData = sectionData ?? data?.filter((e) => typeof e === 'string');
		if (hideIndexButtons || listData?.length === 0) {
			return null;
		}
		if (showSectionButtonsOnCard) {
			return (
				<HSCard style={{ width: IS_WEB ? hsInnerPadding : hsInnerPadding, padding: 0, marginLeft: 10, height: '100%' }}>
					<SFlashList data={listData} renderItem={(item) => _renderSectionButton(item.item as string)} />
				</HSCard>
			);
		}
		return (
			<View style={{ width: IS_WEB ? hsInnerPadding * 1.5 : hsInnerPadding }}>
				<SFlashList data={listData} renderItem={(item) => _renderSectionButton(item.item as string)} />
			</View>
		);
	};

	if (data?.length === 0) {
		return <View style={{ flex: 1, width: '100%', flexDirection: 'column', overflow: 'hidden' }}>{_renderEmptyElements()}</View>;
	}

	return (
		<View style={{ flex: 1, width: '100%', flexDirection: 'row', paddingHorizontal: IS_WEB && !showSectionButtonsOnCard ? 10 : 0 }}>
			<View style={{ flex: 1 }}>
				<SFlashList
					{...props}
					ref={listRef}
					data={data}
					renderItem={(nextItem) => {
						if (isEmptyString(nextItem.item)) {
							return null;
						}
						if (typeof nextItem.item === 'string') {
							return renderSectionHeader(nextItem);
						}

						if (renderItem) {
							return renderItem(nextItem);
						}

						return null;
					}}
					getItemType={(item) => {
						// To achieve better performance, specify the type based on the item
						return typeof item === 'string' ? 'sectionHeader' : 'row';
					}}
					stickyHeaderIndices={stickyHeaders}
					estimatedItemSize={estimatedItemSize}
					bounces={false}
					onScroll={(e) => {
						if (props.onScroll) {
							props.onScroll(e);
						}
						_setScrollPosition(e);
					}}
				/>
			</View>
			{_renderSectionButtonList()}
		</View>
	);
};
