import React, { useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';

import { ChildButton } from 'components/Button';
import { EMeetingOnsiteType, EMeetingStatus, EMeetingType, IAttendee, IMeeting, IMeetingTable } from 'config/interfaces';
import { useTheme } from 'hooks/useTheme';
import { GRID_SCHEDULE_BORDER_RADIUS, GRID_SCHEDULE_INNER_HORIZONTAL_PADDING, GRID_SCHEDULE_LEFT_BORDER_WIDTH } from '../constants';
import moment from 'moment';
import { Text } from 'components/Text';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { isEmptyString } from 'helper';
import { hsBottomMargin } from 'config/styleConstants';

interface IScheduleMeetingItem {
	item: IMeeting;
	style: ViewStyle;
	onPress?: () => void;
}

export const MEETINGLISTITEMHEIGHT = 70;

export const ScheduleMeetingItem = (props: IScheduleMeetingItem) => {
	const { item, style, onPress } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [partner, setPartner] = useState<IAttendee | undefined>(undefined);
	const [meetingTable, setMeetingTable] = useState<IMeetingTable | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _partner: typeof partner = undefined;

		if (attendees) {
			const partnerId = item.ownerId === profile?.userId ? item.partnerId : item.ownerId;
			_partner = attendees.find((e) => e.userId === partnerId);
		}

		setPartner(_partner);
	}, [attendees, item]);

	useEffect(() => {
		let _table: typeof meetingTable = undefined;

		if (content.meetingtables && item?.meetingtable) {
			_table = content.meetingtables.find((e) => e.id === item?.meetingtable?.id);
		}

		setMeetingTable(_table);
	}, [content, item]);

	const _renderWhere = () => {
		if (item?.meetingType === EMeetingType.Onsite) {
			if (item.meetingOnsiteType === EMeetingOnsiteType.Table && item.status !== EMeetingStatus.Accepted) {
				return null;
			}

			let _title: string | undefined = '';
			let _subtitle: string | undefined = '';
			if (meetingTable) {
				_title = meetingTable.title;
				_subtitle = meetingTable.subtitle;
			} else {
				_title = item.meetingOnsiteLocation;
			}

			if (!isEmptyString(_subtitle)) {
				_title += ` (${_subtitle})`;
			}

			if (!isEmptyString(_title)) {
				return (
					<View style={{ flexDirection: 'row', marginTop: 5 }}>
						<Text bold style={{ fontSize: 10 }}>
							{`${t('Where')}:`}
						</Text>
						<Text style={{ fontSize: 10 }}>{_title}</Text>
					</View>
				);
			}
		}

		return null;
	};

	return (
		<ChildButton
			key={`list_schedule_${item.id}`}
			testID={`list_schedule_${item.id}`}
			activeOpacity={1}
			style={[
				style,
				{
					height: MEETINGLISTITEMHEIGHT,
					paddingRight: GRID_SCHEDULE_INNER_HORIZONTAL_PADDING,
					marginBottom: hsBottomMargin / 2
				}
			]}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ flex: 1, opacity: moment().isSameOrAfter(item?.timeSlot?.end) ? 0.6 : 1 }}>
				<View
					style={{
						width: '100%',
						height: '100%',
						paddingVertical: 5,
						paddingHorizontal: 10,
						backgroundColor: theme.contentBackgroundColor ?? theme.background,
						borderWidth: 1,
						borderLeftWidth: GRID_SCHEDULE_LEFT_BORDER_WIDTH,
						borderRadius: GRID_SCHEDULE_BORDER_RADIUS,
						borderColor: theme.lightgray,
						borderLeftColor: theme.primary,
						overflow: 'hidden'
					}}
				>
					<View
						style={{
							paddingRight: 40,
							marginBottom: 5
						}}
					>
						<Text style={{ fontSize: 10 }}>{`${moment(item.timeSlot.start).format('HH:mm')} - ${moment(
							item.timeSlot.end
						).format('HH:mm')}`}</Text>

						{partner && (
							<Text bold style={{ marginTop: 5, fontSize: 14 }}>
								{`${t('MeetingWith')} ${partner.firstName} ${partner.lastName}`}
							</Text>
						)}
						{partner && item.meetingtable && (
							<View style={{ width: '100%' }}>
								<Text
									style={{
										fontSize: 10,
										marginBottom: 5,
										marginTop: 5
									}}
								>
									{`${item.meetingtable.title}`}
								</Text>
							</View>
						)}
						{_renderWhere()}
					</View>
				</View>
			</View>
		</ChildButton>
	);
};
