import React from 'react';
import { ICreateSpaceFormErrors, ICreateSpaceFormValues } from 'config/interfaces';
import { FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { ScrollView } from 'react-native';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { hsBottomMargin } from 'config/styleConstants';
import { H1, WelcomeHint } from 'components/Text';

interface ISpaceWizardSpaceId {
	values: ICreateSpaceFormValues;
	errors: ICreateSpaceFormErrors;
	onChange: (val: ICreateSpaceFormValues) => void;
	isDisabled?: boolean;
	testID: string;
	isSpaceIdTaken: (spaceId) => Promise<boolean>;
}

export const SpaceWizardSpaceId = (props: ISpaceWizardSpaceId) => {
	const { values, errors, onChange, isDisabled, testID, isSpaceIdTaken } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	return (
		<ScrollView
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingVertical: hsBottomMargin
			}}
		>
			<HSCard
				style={{
					marginBottom: hsBottomMargin
				}}
			>
				<H1>{t('selectSpaceIdHeader')}</H1>
				<WelcomeHint style={{ marginBottom: 20 }}>{t('selectSpaceIdHint')}</WelcomeHint>
				<WelcomeHint style={{ marginBottom: 20 }}>{t('selectSpaceIdUnchangableHint')}</WelcomeHint>
				<WelcomeHint style={{ marginBottom: 20 }}>{t('selectSpaceIdLegalLengthHint')}</WelcomeHint>
				<WelcomeHint>{t('selectSpaceIdExampleHint')}</WelcomeHint>
			</HSCard>
			<HSCard style={{ marginBottom: 0 }}>
				<FormTextInput
					formStyle={{ marginBottom: 0 }}
					testID={`${testID}_textinput_spaceid`}
					label={t('SpaceID')}
					isRequired
					value={values.spaceId}
					error={errors.spaceId}
					isDisabled={isDisabled}
					autoCorrect={false}
					autoCapitalize="none"
					onChangeText={(value) => {
						const newSpaceId = value
							?.replace(/\s/g, '')
							?.replace(/[^a-zA-Z0-9 ]/g, '')
							?.toLowerCase();
						onChange({
							...values,
							spaceId: newSpaceId
						});
						isSpaceIdTaken(newSpaceId);
					}}
					skipTabPress
				/>
			</HSCard>
		</ScrollView>
	);
};
