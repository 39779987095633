import { createModel } from '@rematch/core';
import { RootModel } from './index';
import { IConfigTheme } from 'rematch/interfaces/config';
import { hsBlue } from 'config/styleConstants';
import { TAgendaViewTypes } from 'config/interfaces';

type IDefaultState = {
	theme: IConfigTheme;
	agendaViewType?: TAgendaViewTypes;
};

export const DEFAULTTHEME: IConfigTheme = {
	statusBarStyle: 'dark',
	primary: hsBlue,
	primaryContrast: '#f8f8f8',
	white: '#f8f8f8',
	text: '#1e1e1e',
	navigationTextColor: '#1e1e1e',
	background: '#f8f8f8',
	contentBackgroundColor: '#f8f8f8',
	contrast: '#72b2fc',
	gray: '#666666',
	lightgray: '#dddddd',
	link: '#0032a0',
	danger: '#d80e29',
	warning: '#ff8f00',
	success: '#11e775',
	formgray: '#a0a0a0',
	hsBlue: hsBlue,
	navbarButton: '#000000',
	hsShadowColor: '#000000',

	partnerChatBubble: '#dddddd',
	myChatBubble: hsBlue,
	partnerChatBubbleText: '#000000',
	myChatBubbleText: '#f8f8f8',
	chatMessageRead: '#11e775'
};

export const config = createModel<RootModel>()({
	state: {
		theme: { ...DEFAULTTHEME },
		agendaViewType: undefined
	} as IDefaultState,
	reducers: {
		setTheme(state, theme: IConfigTheme) {
			rematchLog('setTheme');
			return {
				...state,
				theme
			};
		},
		setAgendaViewType(state, agendaViewType: TAgendaViewTypes) {
			rematchLog('setAgendaViewType');
			return {
				...state,
				agendaViewType
			};
		}
	},
	effects: (dispatch) => ({
		async resetApp(payload, store) {
			try {
				dispatch.socket.closeMultiSpaceSocket({});
				dispatch.temp.clear();
				dispatch.attendee.clear();
				dispatch.content.clear();
				dispatch.networking.clear();
				dispatch.pushNotification.clear();
				dispatch.vote.clear();
				dispatch.upload.clear();
				dispatch.booking.clear();
				await dispatch.auth.logout({});
				dispatch.auth.clear();
				await dispatch.space.leaveSpace({});
				// As the launcher socket gets closed
				// It needs to be reopened
				await dispatch.socket.initMultiSpaceSocket({});
			} catch (error) {
				console.log('resetApp', error);
			}
		}
	})
});
