import React, { useEffect, useState } from 'react';
import { View, ScrollView, Keyboard } from 'react-native';
import { HSModal } from '../Modal';
import { IContentTypeField, ISpeaker } from 'config/interfaces';
import { Spinner } from 'components/Spinner';
import { useContent } from 'hooks/useContent';
import { useDetail } from 'hooks/useDetail';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { HSCard } from 'components/Card';
import { Avatar } from 'components/User';
import { Subtitle, Title } from 'components/Text';
import { Image } from 'components/Image';
import { isEmptyString } from 'helper';
import { hsBorderRadius, hsInnerPadding } from 'config/styleConstants';
import { ShareButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';

interface ISpeakerModal {
	speaker?: ISpeaker;
	isVisible: boolean;
	onClose: () => void;
}

const TESTIDPREFIX = 'speakerdetailsmodal';

export const SpeakerModal = (props: ISpeakerModal) => {
	const { speaker, isVisible, onClose } = props;
	const { renderSocialMedia } = useDetail();
	const { getContentTypeFields } = useContent('speaker');
	const [speakerDetailFields, setSpeakerDetailFields] = useState<IContentTypeField[]>([]);
	const [hasCalculatedImage, setHasCalculatedImage] = useState<boolean>(false);
	const [calculatedImageRatio, setCalculatedImageRatio] = useState<number>(16 / 9);
	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		const _fields = getContentTypeFields(true).filter(
			(e) =>
				e.showOnDetailScreen &&
				e.fieldType !== 'avatar' &&
				e.fieldName !== 'title' &&
				e.fieldName !== 'jobTitle' &&
				e.fieldName !== 'company' &&
				e.fieldName !== 'speakerTitle'
		);
		setSpeakerDetailFields(_fields);
	}, [speaker]);

	const _renderJobtitleAndCompany = () => {
		let str = '';

		if (speaker?.jobTitle && speaker.company && !isEmptyString(speaker?.jobTitle) && !isEmptyString(speaker.company)) {
			str = `${speaker.jobTitle} | ${speaker.company}`;
		} else if (speaker?.jobTitle && !isEmptyString(speaker.jobTitle)) {
			str = speaker.jobTitle;
		} else if (speaker?.company && !isEmptyString(speaker.company)) {
			str = speaker.company;
		}

		if (!isEmptyString(str)) {
			return <Subtitle>{str}</Subtitle>;
		}

		return null;
	};

	const _renderCompanyImage = () => {
		return (
			<Image
				onLoad={(e) => {
					if (!hasCalculatedImage) {
						setCalculatedImageRatio(e.source.width / e.source.height);
						setHasCalculatedImage(true);
					}
				}}
				mediaObj={speaker?.companyImage}
				imageSize="small"
				style={{ width: 130, height: 130 / calculatedImageRatio, marginVertical: 10, borderRadius: hsBorderRadius }}
				expectedRatio={16 / 9}
			/>
		);
	};

	const _renderSpeaker = () => {
		if (speaker) {
			return (
				<View>
					<HSCard style={{ alignItems: 'center' }}>
						<Avatar testID={`${TESTIDPREFIX}_avatar`} size="xxl" avatarObj={speaker?.image} fullName={speaker.title} />
						<Title style={{ marginTop: 20, marginBottom: 5 }}>{`${speaker.speakerTitle ? `${speaker.speakerTitle} ` : ''}${
							speaker.title
						}`}</Title>
						{_renderCompanyImage()}
						{_renderJobtitleAndCompany()}
						{renderSocialMedia(
							'speaker',
							speakerDetailFields,
							speaker,
							TESTIDPREFIX,
							null,
							<ShareButton
								testID={`${TESTIDPREFIX}_button_share`}
								key={`${TESTIDPREFIX}_button_share`}
								itemId={speaker.id}
								title={speaker.title}
								message={speaker.bio}
								type="speaker"
								route={ERoutes.SpeakerDetails}
							/>
						)}
					</HSCard>
				</View>
			);
		}

		return null;
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			{speaker ? (
				<ScrollView
					keyboardShouldPersistTaps="handled"
					onScroll={() => Keyboard.dismiss()}
					scrollEventThrottle={0}
					style={{ marginHorizontal: -hsInnerPadding }}
					contentContainerStyle={{
						width: '100%',
						paddingHorizontal: hsInnerPadding,
						paddingTop: 10
					}}
				>
					{_renderSpeaker()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
		</HSModal>
	);
};
