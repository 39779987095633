import { TPushNotificationDeeplinkType } from 'config/interfaces';
import Toast from 'react-native-toast-notifications';

enum EToastVisibilityTime {
	Success = 1500,
	Error = 1000000,
	PushNotification = 1000000,
	Info = 4000,
	InitialLoad = 1000,
	Custom = 8000
}

type TToastType = 'success' | 'error' | 'info' | 'custom' | 'connectionEstablished' | 'connectionLost' | 'initialLoad' | 'pushNotification';

export const showToast = (
	type: TToastType,
	title?: string,
	subtitle?: string,
	iconOrImage?: string,
	deeplinkType?: TPushNotificationDeeplinkType,
	deeplinkItemId?: number
) => {
	let visibilityTime = EToastVisibilityTime.Success;

	switch (type) {
		case 'connectionEstablished':
			visibilityTime = EToastVisibilityTime.Success;
			break;
		case 'connectionLost':
			visibilityTime = EToastVisibilityTime.Success;
			break;
		case 'success':
			visibilityTime = EToastVisibilityTime.Success;
			break;
		case 'error':
			visibilityTime = EToastVisibilityTime.Error;
			break;
		case 'initialLoad':
			visibilityTime = EToastVisibilityTime.InitialLoad;
			break;
		case 'info':
			visibilityTime = EToastVisibilityTime.Info;
			break;
		case 'custom':
			visibilityTime = EToastVisibilityTime.Custom;
			break;
		case 'pushNotification':
			visibilityTime = EToastVisibilityTime.PushNotification;
			break;
		default:
			break;
	}

	try {
		if (toastalert) {
			toastalert?.show(title, {
				...Toast.defaultProps,
				placement: 'top',
				data: { title, subtitle, iconOrImage, deeplinkType, deeplinkItemId },
				duration: visibilityTime,
				type,
				swipeEnabled: false
			});
		} else {
			toast?.show(title, {
				...Toast.defaultProps,
				placement: 'top',
				data: { title, subtitle, iconOrImage, deeplinkType, deeplinkItemId },
				duration: visibilityTime,
				type,
				swipeEnabled: false
			});
		}
	} catch {
		try {
			if (toastmodal) {
				toastmodal?.show(title, {
					...Toast.defaultProps,
					placement: 'top',
					data: { title, subtitle, iconOrImage, deeplinkType, deeplinkItemId },
					duration: visibilityTime,
					type,
					swipeEnabled: false
				});
			} else {
				toast?.show(title, {
					...Toast.defaultProps,
					placement: 'top',
					data: { title, subtitle, iconOrImage, deeplinkType, deeplinkItemId },
					duration: visibilityTime,
					type,
					swipeEnabled: false
				});
			}
		} catch (e) {
			toast?.show(title, {
				...Toast.defaultProps,
				placement: 'top',
				data: { title, subtitle, iconOrImage, deeplinkType, deeplinkItemId },
				duration: visibilityTime,
				type,
				swipeEnabled: false
			});
		}
	}
};
