import React from 'react';

import { useTranslation } from 'react-i18next';

import { FormMultiSwitch, FormNumberSwitch, FormTextInput } from 'components/Form';
import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';
import { View } from 'react-native';

interface IVoteNpsForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteNpsForm = (props: IVoteNpsForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	const _getDefaultOptions = () => {
		if (values.minValue !== undefined && values.maxValue !== undefined) {
			let _options = _getOptions();
			if (_options.length > 1) {
				_options.splice(_options.length - Math.ceil(_options.length / 5), Math.ceil(_options.length / 5));
			}
			values.requestReasonOptions = JSON.stringify(_options);
		}
	};

	const _getOptions = () => {
		let _options: number[] = [];
		if (values.minValue !== undefined && values.maxValue !== undefined) {
			for (let i = values.minValue; i <= values.maxValue; i++) {
				_options.push(i);
			}
		}

		return _options;
	};

	return (
		<CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading}>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_minvalue`}
				label={t('VoteFormNpsMinValue')}
				hint={t('VoteFormNpsMinValueHint')}
				isRequired
				value={values.minValue?.toString()}
				error={errors.minValue}
				onChangeText={(value) => onChange({ ...values, minValue: Number(value) })}
				isDisabled={isLoading}
				keyboardType="number-pad"
				onlyNumbers
			/>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_maxvalue`}
				label={t('VoteFormNpsMaxValue')}
				hint={t('VoteFormNpsMaxValueHint')}
				isRequired
				value={values.maxValue?.toString()}
				error={errors.maxValue}
				onChangeText={(value) => onChange({ ...values, maxValue: Number(value) })}
				onBlur={() => _getDefaultOptions()}
				isDisabled={isLoading}
				keyboardType="number-pad"
				onlyNumbers
			/>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_minvaluetext`}
				label={t('VoteFormNpsMinValueText')}
				hint={t('VoteFormNpsMinValueTextHint')}
				value={values.minValueText}
				error={errors.minValueText}
				onChangeText={(value) => onChange({ ...values, minValueText: value })}
				isDisabled={isLoading}
			/>
			<FormTextInput
				testID={`${testIdPrefix}_textinput_maxvaluetext`}
				label={t('VoteFormNpsMaxValueText')}
				hint={t('VoteFormNpsMaxValueTextHint')}
				value={values.maxValueText}
				error={errors.maxValueText}
				onChangeText={(value) => onChange({ ...values, maxValueText: value })}
				isDisabled={isLoading}
			/>
			<FormMultiSwitch
				testID={`${testIdPrefix}_multiswitch_requestreason`}
				label={t('requestReason')}
				hint={t('requestReasonHint')}
				value={values.requestReason ? 'true' : 'false'}
				options={[
					{
						key: 'true',
						label: t('Yes')
					},
					{
						key: 'false',
						label: t('No')
					}
				]}
				onChange={(val) => onChange({ ...values, requestReason: val === 'true' ? true : false })}
				isDisabled={isLoading}
			/>
			{values.requestReason && (
				<View>
					<FormTextInput
						testID={`${testIdPrefix}_textinput_requestreasontext`}
						label={t('requestReasonText')}
						hint={t('requestReasonTextHint')}
						value={values.requestReasonText}
						error={errors.requestReasonText}
						onChangeText={(value) => onChange({ ...values, requestReasonText: value })}
						isDisabled={isLoading}
					/>
					{values.minValue !== undefined && values.maxValue !== undefined && values.maxValue - values.minValue <= 20 && (
						<FormNumberSwitch
							testID={`${testIdPrefix}_select_requestreasonoptions`}
							label={t('requestReasonOptions')}
							hint={t('requestReasonOptionsHint')}
							value={JSON.parse(values.requestReasonOptions ?? '[]') ?? []}
							options={_getOptions()}
							allowMultiSelection
							onChange={(val) => {
								if (values.requestReasonOptions && values.requestReasonOptions.length > 0) {
									let selected = JSON.parse(values.requestReasonOptions);
									while (typeof selected === 'string') {
										// make sure to get an object to prevent crashes
										selected = JSON.parse(selected);
									}
									if (selected.includes(val)) {
										selected = selected.filter((s) => s !== val);
									} else {
										selected.push(val);
									}

									onChange({ ...values, requestReasonOptions: JSON.stringify([...selected]) });
									return;
								}
								onChange({ ...values, requestReasonOptions: JSON.stringify([val]) });
							}}
						/>
					)}
					{values.minValue !== undefined && values.maxValue !== undefined && values.maxValue - values.minValue > 20 && (
						<FormTextInput
							testID={`${testIdPrefix}_select_requestreasonoptions`}
							label={t('requestReasonOptions')}
							hint={t('requestReasonOptionsHint')}
							value={JSON.parse(values.requestReasonOptions ?? '[]').join(',')}
						/>
					)}
				</View>
			)}
		</CommonVoteForm>
	);
};
