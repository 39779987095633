import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { IKVP } from 'components/Form';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { ChildButton } from 'components/Button';
import { SearchBar } from 'components/SearchBar';
import { Text } from 'components/Text';
import { HSCard } from 'components/Card';
import { FlashList } from 'components/List';
import { ChangelogListItem, IChangelogWithItem } from 'components/Changelog';
import { getLabelForContentType } from 'components/Changelog/changeloghelper';
import { Dropdown } from 'components/Form/Dropdown';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Changelogs>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Changelogs>;
type RouteParams = StackParamList[ERoutes.Changelogs];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'changelogs';

export const ChangelogScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [spaceChangelogs, setSpaceChangelogs] = useState<IChangelogWithItem[]>([]);
	const [filteredChangelogs, setFilteredChangelogs] = useState<IChangelogWithItem[]>([]);
	const [searchedChangeLogs, setSearchedChangelogs] = useState<IChangelogWithItem[]>([]);

	const [filterTypes, setFilterTypes] = useState<IKVP[]>([]);
	const [users, setUsers] = useState<IKVP[]>([]);

	const [searchTerm, setSearchTerm] = useState<string>('');
	const [activeFilterTypes, setActiveFilterTypes] = useState<string[]>(['all']);
	const [activeActionFilters, setActiveActionFilters] = useState<string[]>(['all']);
	const [activeUserFilter, setActiveUserFilter] = useState<string[]>([]);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const changelogs = useSelector((store: IRootState) => store.content.changelogs);
	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const pushNotifications = useSelector((store: IRootState) => store.pushNotification.pushNotifications);

	useEffect(() => {
		let _spaceChangelogs: typeof spaceChangelogs = [];
		let _filterTypes: typeof filterTypes = [];

		if (activeSpace) {
			_spaceChangelogs = (changelogs ?? []).filter((e) => e.spaceId === activeSpace.spaceId);

			_spaceChangelogs.forEach((log) => {
				const found = _filterTypes.find((e) => e.key === log.itemType);
				if (!found) {
					_filterTypes.push({
						key: log.itemType,
						label: getLabelForContentType(log.itemType)
					});
				}

				switch (log.itemType) {
					case 'votes':
						log.item = votes?.find((e) => e.id === log.itemId);
						break;
					case 'pushnotifications':
						log.item = pushNotifications?.find((e) => e.id === log.itemId);
						break;
					case 'expos':
					case 'mappositions':
					case 'maps':
					case 'mediaitems':
					case 'meetingtables':
					case 'networkingrooms':
					case 'newsitems':
					case 'schedules':
					case 'speakers':
					case 'stages':
					case 'streams':
					default:
						if (content[log.itemType]) {
							log.item = content[log.itemType].find((e) => e.id === log.itemId);
						}
						break;
				}
			});

			_filterTypes.sort((a, b) => (a.label < b.label ? -1 : 1));
			_filterTypes.unshift({ key: 'all', label: t('All') });
		}

		setSpaceChangelogs(_spaceChangelogs);
		setFilterTypes(_filterTypes);
	}, [activeSpace, changelogs, content]);

	useEffect(() => {
		let _users: typeof users = [];

		if (attendees) {
			spaceChangelogs.forEach((log) => {
				const foundInUsers = _users.find((e) => e.key === log.userId);
				if (!foundInUsers) {
					const attendee = attendees.find((e) => e.userId === log.userId);
					if (attendee) {
						_users.push({
							key: log.userId,
							label: `${attendee.firstName} ${attendee.lastName}`,
							avatar: attendee.imageUrl
						});
					}
				}
			});

			const hasSelfService = spaceChangelogs.find((e) => e.userId === 'Self Service User');
			if (hasSelfService) {
				_users.unshift({
					key: 'Self Service User',
					label: 'Self Service User'
				});
			}
		}

		setUsers(_users);
	}, [attendees, spaceChangelogs]);

	useEffect(() => {
		let _filtered = [...spaceChangelogs];

		if (!activeFilterTypes.includes('all')) {
			_filtered = _filtered.filter((e) => activeFilterTypes.includes(e.itemType));
		}

		if (!activeActionFilters.includes('all')) {
			_filtered = _filtered.filter((e) => activeActionFilters.includes(e.action));
		}

		setFilteredChangelogs(_filtered);
	}, [spaceChangelogs, activeFilterTypes, activeActionFilters]);

	useEffect(() => {
		let _searched = [...filteredChangelogs];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter((log) => {
				const keys = Object.keys(log.item ?? {});
				for (const key of keys) {
					if (typeof log.item[key] === 'string' && log.item[key].toLowerCase().includes(lowerSearch)) {
						return true;
					}
				}

				return false;
			});
		}

		if (activeUserFilter.length > 0) {
			_searched = _searched.filter((e) => activeUserFilter.includes(e.userId));
		}

		_searched.sort((a, b) => (a.updated_at.toLowerCase() > b.updated_at.toLowerCase() ? -1 : 1));

		setSearchedChangelogs(_searched);
	}, [filteredChangelogs, searchTerm, activeUserFilter]);

	const _handleFilterButtonPress = (key: string) => {
		let _val = [...activeFilterTypes];

		switch (key) {
			case 'all':
				_val = ['all'];
				break;
			default:
				if (_val.includes('all')) {
					_val = [];
				}
				if (_val.includes(key)) {
					_val = _val.filter((e) => e !== key);
				} else {
					_val.push(key);
				}
				if (_val.length === 0) {
					_val = ['all'];
				}
				break;
		}

		setActiveFilterTypes(_val);
	};

	const _handleActionFilterButtonPress = (key: string) => {
		let _val = [...activeActionFilters];

		switch (key) {
			case 'all':
				_val = ['all'];
				break;
			default:
				if (_val.includes('all')) {
					_val = [];
				}
				if (_val.includes(key)) {
					_val = _val.filter((e) => e !== key);
				} else {
					_val.push(key);
				}
				if (_val.length === 0) {
					_val = ['all'];
				}
				break;
		}

		setActiveActionFilters(_val);
	};

	const _renderFilterbarButton = (typeObj: IKVP, color?: string) => {
		const isActive = activeFilterTypes.includes(typeObj.key!);
		const isLastItem = typeObj.key === filterTypes[filterTypes.length - 1].key;

		return (
			<ChildButton
				key={`${TESTIDPREFIX}_button_${typeObj.key}`}
				testID={`${TESTIDPREFIX}_button_${typeObj.key}`}
				onPress={() => _handleFilterButtonPress(typeObj.key!)}
				style={[
					{
						marginRight: isLastItem ? 0 : 10,
						borderWidth: 1,
						borderRadius: 5,
						borderColor: theme.primary,
						paddingHorizontal: 5,
						paddingVertical: 2,
						justifyContent: 'center',
						alignItems: 'center'
					},
					isActive ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
				]}
			>
				<Text
					bold={isActive}
					style={{
						color: isActive ? theme.primaryContrast : theme.primary
					}}
				>
					{typeObj.label}
				</Text>
			</ChildButton>
		);
	};

	const _renderActionFilterButton = (key: string, label: string, isLastItem: boolean, color?: string) => {
		const isActive = activeActionFilters.includes(key);
		return (
			<ChildButton
				key={`${TESTIDPREFIX}_button_actionfilter_${key}`}
				testID={`${TESTIDPREFIX}_button_actionfilter_${key}`}
				onPress={() => _handleActionFilterButtonPress(key)}
				style={[
					{
						marginRight: isLastItem ? 0 : 10,
						borderWidth: 1,
						borderRadius: 5,
						borderColor: color ?? theme.primary,
						paddingHorizontal: 5,
						paddingVertical: 2,
						justifyContent: 'center',
						alignItems: 'center'
					},
					isActive ? { backgroundColor: color ?? theme.primary } : { backgroundColor: theme.primaryContrast }
				]}
			>
				<Text
					bold={isActive}
					style={{
						color: isActive ? theme.primaryContrast : color ?? theme.primary
					}}
				>
					{label}
				</Text>
			</ChildButton>
		);
	};

	const _renderFilterbar = () => {
		if (filterTypes.length > 1) {
			return (
				<View style={{ marginBottom: hsBottomMargin / 2 }}>
					<ScrollView horizontal contentContainerStyle={{ paddingBottom: hsBottomMargin / 2 }}>
						{filterTypes.map((typeObj) => _renderFilterbarButton(typeObj))}
					</ScrollView>
				</View>
			);
		}

		return null;
	};

	const _renderActionFilterbar = () => {
		return (
			<View style={{ marginBottom: hsBottomMargin / 2 }}>
				<ScrollView horizontal contentContainerStyle={{ paddingBottom: hsBottomMargin / 2 }}>
					{_renderActionFilterButton('all', t('All'), false)}
					{_renderActionFilterButton('create', t('Created'), false, theme.success)}
					{_renderActionFilterButton('update', t('Updated'), false, theme.warning)}
					{_renderActionFilterButton('delete', t('Deleted'), true, theme.danger)}
				</ScrollView>
			</View>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="changelogs">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					{_renderFilterbar()}
					{_renderActionFilterbar()}
					{users.length > 0 ? (
						<Dropdown
							testID={`${TESTIDPREFIX}_dropdown`}
							multi
							label={t('User')}
							options={users}
							onSelect={(val) => setActiveUserFilter(val)}
							value={activeUserFilter}
							formStyle={{ marginBottom: 0 }}
							placeholder={t('Select User')}
						/>
					) : null}
					<SearchBar
						testID={`${TESTIDPREFIX}_searchbar`}
						value={searchTerm}
						onChange={(val) => setSearchTerm(val)}
						containerStyle={{ marginHorizontal: 0 }}
					/>
				</HSCard>
				<View style={{ flex: 1 }}>
					<FlashList
						data={searchedChangeLogs}
						renderItem={({ item }) => <ChangelogListItem logItem={item} />}
						estimatedItemSize={150}
						keyExtractor={(item) => `changelog_${item.id}`}
					/>
				</View>
			</View>
		</ScreenContainer>
	);
};

export const ChangelogScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title="Changelog" />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
