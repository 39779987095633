import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { FlatList, ScrollView, View, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ILauncherSpace, IMeeting, ISchedule, IStage } from 'config/interfaces';
import { VerticalGridTimeCell } from './VerticalGridTimeCell';
import { IS_ANDROID, IS_IOS, IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { VerticalGridHeaderCell } from './VerticalGridHeaderCell';
import { VerticalGridItemCell } from './VerticalGridItemCell';
import { ERoutes } from 'components/Navigation/routes';
import { GRID_SCHEDULE_NOW_LINE_HEIGHT, GRID_SCHEDULE_NOW_BUBBLE_SIZE } from '../constants';
import { hsBottomMargin } from 'config/styleConstants';
import { t } from 'i18next';
import { VerticalGridMeetingCell } from './VerticalGridMeetingCell';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface IVerticalGrid {
	items: ISchedule[];
	meetings: IMeeting[];
	isPublic?: boolean;
	space?: ILauncherSpace;
}

interface IVerticalGridLane {
	stage: IStage;
	blocks: any[][];
	meetings: IMeeting[];
}

interface IHorizontalGridLane {
	stage: IStage;
	items: ISchedule[];
	meetings: IMeeting[];
}

const SCHEDULE_STEPS = 15;
export const HEIGHT_PER_MINUTE = 4;
const TIME_COLUMN_WIDTH = 40;
export const LANE_COLUMN_WIDTH_WEB = 180;
export const LANE_COLUMN_WIDTH_APP = 140;
const LANE_COLUMN_HEIGHT = ((IS_WEB ? LANE_COLUMN_WIDTH_WEB : LANE_COLUMN_WIDTH_APP) / 16) * 9;
const HEADERTOGRIDSPACING = 10;
const MYAGENDAID = -1;
const TESTIDPREFIX = 'gridschedule';

export const VerticalGrid = (props: IVerticalGrid) => {
	const { items, meetings, isPublic, space } = props;
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const navigation = useNavigation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const timeCellRef = useRef<FlatList>(null);
	const headerRef = useRef<FlatList>(null);
	const contentRef = useRef<ScrollView>(null);

	const [heightPerMinute, setHeightPerMinute] = useState<number>(HEIGHT_PER_MINUTE);
	const [widthPerStage, setWidthPerStage] = useState<number>(IS_WEB ? LANE_COLUMN_WIDTH_WEB : LANE_COLUMN_WIDTH_APP);
	const [laneColumnHeight, setLaneColumnHeight] = useState<number>(LANE_COLUMN_HEIGHT);

	const [startTime, setStartTime] = useState<string | undefined>(undefined);
	const [viewWidth, setViewWidth] = useState<number>(1);
	const [totalGridWidth, setTotalGridWidth] = useState<number>(0);

	const [timeCells, setTimeCells] = useState<string[]>([]);
	const [vertLanes, setVertLanes] = useState<IVerticalGridLane[]>([]);
	const [crossLanes, setCrossLanes] = useState<IHorizontalGridLane[]>([]);

	const [hasJumpedToNow, setHasJumpedToNow] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const content = useSelector((store: IRootState) => store.content.content);

	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const setMediaDetailViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailViewType);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const GRID_SIZE_LANE_COUNT = IS_WEB ? (isTabletOrMobile ? 2 : 4) : 2;

	useEffect(() => {
		const _space = space ?? activeSpace;
		if (IS_WEB && !isTabletOrMobile) {
			if (_space?.agendaHeightPerMinuteWeb) {
				setHeightPerMinute(_space.agendaHeightPerMinuteWeb);
			}
			if (_space?.agendaWidthPerStageWeb) {
				setWidthPerStage(_space.agendaWidthPerStageWeb);
				setLaneColumnHeight((_space.agendaWidthPerStageWeb / 16) * 9);
			}
		} else {
			if (_space?.agendaHeightPerMinuteApp) {
				setHeightPerMinute(_space.agendaHeightPerMinuteApp);
			}
			if (_space?.agendaWidthPerStageApp) {
				setWidthPerStage(_space.agendaWidthPerStageApp);
				setLaneColumnHeight((_space.agendaWidthPerStageApp / 16) * 9);
			}
		}
	}, [activeSpace, space, isTabletOrMobile, totalGridWidth]);

	useEffect(() => {
		if ((space?.agendaStageAutoWidth || activeSpace?.agendaStageAutoWidth) && vertLanes?.length > 0 && viewWidth > 0) {
			const width = viewWidth / vertLanes?.length;
			if (width > widthPerStage) {
				setWidthPerStage(width);
				setLaneColumnHeight((width / 16) * 9);
			}
		}
	}, [activeSpace, space, viewWidth, vertLanes, widthPerStage]);

	useEffect(() => {
		const _timeCells: string[] = [];
		let earliest: string | undefined = undefined;
		let oldest: string | undefined = undefined;

		if (items?.length > 0 || meetings?.length > 0) {
			if (items?.length > 0) {
				if (!earliest || moment(items[0].startDate).isBefore(moment(earliest))) {
					earliest = items[0].startDate;
				}
				items.forEach((item) => {
					if (!oldest || moment(item.endDate).isAfter(moment(oldest))) {
						oldest = item.endDate;
					}
				});
			}

			if (meetings?.length > 0) {
				if (!earliest || moment(meetings[0].timeSlot.start).isBefore(moment(earliest))) {
					earliest = meetings[0].timeSlot.start;
				}

				meetings.forEach((meeting) => {
					if (!oldest || moment(meeting.timeSlot.end).isAfter(moment(oldest))) {
						oldest = meeting.timeSlot.end;
					}
				});
			}

			if (moment(earliest).get('minutes') % 10 > 0) {
				earliest = moment(earliest)
					.subtract(moment(earliest).get('minutes') % 10, 'minutes')
					.toISOString();
			}

			setStartTime(earliest);

			const start = moment(earliest);
			const end = moment(oldest).add(30, 'minutes');
			while (start.isSameOrBefore(end)) {
				_timeCells.push(start.toISOString());
				start.add(SCHEDULE_STEPS, 'minutes');
			}
		}

		setTimeCells(_timeCells);

		const _lanes: IHorizontalGridLane[] = [];

		items?.forEach((item) => {
			if (item.stage?.id) {
				const alreadyAdded = _lanes.find((lane) => lane.stage.id === item.stage?.id);
				if (alreadyAdded) {
					alreadyAdded.items.push(item);
				} else {
					let _stage: IStage | undefined = undefined;
					if (isPublic) {
						_stage = item.stage;
					} else {
						_stage = content.stages.find(
							(st) => item.stage && !st.isDeleted && st.id === item.stage.id && activeSpace?.spaceId === st.spaceId
						);
					}
					if (_stage) {
						_lanes.push({
							stage: _stage,
							items: [item],
							meetings: []
						});
					}
				}
			}
		});

		_lanes.sort((a, b) => ((a.stage.order ? a.stage.order : 9999) < (b.stage.order ? b.stage.order : 99999) ? -1 : 1));

		if (meetings?.length > 0 && activeSpace) {
			_lanes.unshift({
				stage: {
					id: MYAGENDAID,
					order: 0,
					title: t('My Meetings'),
					subtitle: '',
					backgroundColor: theme.primary,
					textColor: theme.primaryContrast,
					spaceId: activeSpace.spaceId,
					created_at: moment().toISOString(),
					updated_at: moment().toISOString(),
					published_at: moment().toISOString()
				},
				items: [],
				meetings
			});
		}

		const _vertLanes = _lanes
			.filter((lane) => !lane.stage.isCrossLane)
			.map((lane) => {
				const _vertLane: IVerticalGridLane = {
					stage: lane.stage,
					blocks: [],
					meetings: lane.meetings
				};

				lane.meetings.forEach((item) => {
					const itemStart = moment(item.timeSlot.start);
					const itemEnd = moment(item.timeSlot.end);

					for (const block of _vertLane.blocks) {
						const hasOverlap = block.find((blockItem) => {
							const blockItemStart = moment(blockItem.startDate ?? blockItem.timeSlot.start);
							const blockItemEnd = moment(blockItem.endDate ?? blockItem.timeSlot.end);
							return (
								(itemStart.isAfter(blockItemStart) && itemStart.isBefore(blockItemEnd)) ||
								(itemEnd.isAfter(blockItemStart) && itemEnd.isBefore(blockItemEnd)) ||
								itemStart.isSame(blockItemStart)
							);
						});

						if (!hasOverlap) {
							block.push(item);
							return;
						}
					}

					_vertLane.blocks.push([item]);
				});

				lane.items.forEach((item) => {
					const itemStart = moment(item.startDate);
					const itemEnd = moment(item.endDate);

					for (const block of _vertLane.blocks) {
						const hasOverlap = block.find((blockItem) => {
							const blockItemStart = moment(blockItem.startDate ?? blockItem.timeSlot.start);
							const blockItemEnd = moment(blockItem.endDate ?? blockItem.timeSlot.end);
							return (
								(itemStart.isAfter(blockItemStart) && itemStart.isBefore(blockItemEnd)) ||
								(itemEnd.isAfter(blockItemStart) && itemEnd.isBefore(blockItemEnd)) ||
								itemStart.isSame(blockItemStart)
							);
						});

						if (!hasOverlap) {
							block.push(item);
							return;
						}
					}

					_vertLane.blocks.push([item]);
				});

				return _vertLane;
			});

		_vertLanes.sort((a, b) => {
			const aOrder = a.meetings.length > 1 ? -1 : a.stage.order !== null && a.stage.order !== undefined ? a.stage.order : 999;
			const bOrder = b.meetings.length > 1 ? -1 : b.stage.order !== null && b.stage.order !== undefined ? b.stage.order : 999;
			return aOrder < bOrder ? -1 : 1;
		});

		setVertLanes(_vertLanes);
		setCrossLanes(_lanes.filter((lane) => lane.stage.isCrossLane));
	}, [items, meetings, activeSpace]);

	useEffect(() => {
		const now = moment();
		let totalWidth = 0;

		if (vertLanes.length > GRID_SIZE_LANE_COUNT) {
			vertLanes.forEach((vertLane) => {
				totalWidth += vertLane.blocks.length * widthPerStage;
			});
		}

		if (vertLanes.length > 0 && !hasJumpedToNow && startTime && now.isSame(moment(startTime), 'date')) {
			if (process.env.IS_TEST) {
				setHasJumpedToNow(true);
				return;
			}
			setHasJumpedToNow(true);
			const offset = _getItemHeight(startTime, now.toISOString());
			setTimeout(() => {
				contentRef.current?.scrollTo({ animated: true, x: 0, y: offset - 150 });
			}, 1000);
		}

		setTotalGridWidth(Math.max(viewWidth, totalWidth));
	}, [vertLanes, crossLanes, viewWidth]);

	const _getItemHeight = (itemStart: string, itemEnd: string) => {
		return heightPerMinute * moment.duration(moment(itemEnd).diff(moment(itemStart))).asMinutes();
	};

	const _getItemStyle = (laneIdx: number, blockIdx: number, startTime: string, start: string, end: string, isCrossLaneItem?: boolean) => {
		let _width = 0;
		let _itemWidth = widthPerStage;

		const _blocks = vertLanes[laneIdx]?.blocks?.length > 0 ? vertLanes[laneIdx]?.blocks?.length : 1;
		if (vertLanes.length > GRID_SIZE_LANE_COUNT) {
			for (let i = 0; i < laneIdx; i++) {
				_width += vertLanes[i].blocks.length * _itemWidth;
			}
		} else {
			_itemWidth = viewWidth / vertLanes.length;
			if (_blocks > 0) {
				_itemWidth = _itemWidth / _blocks;
			}
		}

		const additionalLeft = blockIdx * _itemWidth;

		let _left = 0;

		if (vertLanes.length > GRID_SIZE_LANE_COUNT) {
			_left = _width + additionalLeft;
		} else {
			_left = (viewWidth / (vertLanes.length > 0 ? vertLanes.length : 1)) * laneIdx + additionalLeft;
		}

		const style: ViewStyle = {
			position: 'absolute',
			left: isCrossLaneItem ? 0 : _left,
			top: _getItemHeight(startTime, start),
			height: _getItemHeight(start, end),
			width: isCrossLaneItem ? totalGridWidth : _itemWidth
		};

		return style;
	};

	const _setMediaDetail = (itemId: number) => {
		let _item;
		if (isPublic) {
			_item = items?.find((i) => i.id === itemId);
			_item.votes = [];
		}
		if (!isPublic) {
			navigation.navigate(ERoutes.Media, {
				itemId,
				mediaType: 'schedule',
				spaceId: activeSpace?.spaceId
			});
			setMediaDetail({
				itemId: undefined,
				viewType: 'full',
				playbackStatus: 'paused'
			});
			return;
		}
		if (mediaDetail.itemId) {
			if (mediaDetail.itemId === itemId) {
				setMediaDetailViewType('full');
			} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
				setMediaDetail({
					itemId,
					itemType: 'schedule',
					viewType: 'full',
					playbackStatus: 'paused',
					item: _item,
					previewImage: _item?.previewImage,
					isPublic: isPublic
				});
			} else {
				showAlert({
					title: t('Switch Stream'),
					message: t('Switch Stream Subtitle'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							style: 'destructive',
							text: t('Switch Stream'),
							onPress: () =>
								setMediaDetail({
									itemId,
									itemType: 'schedule',
									viewType: 'full',
									playbackStatus: 'paused',
									item: _item,
									previewImage: _item?.previewImage,
									isPublic: isPublic
								})
						}
					]
				});
			}
		} else {
			setMediaDetail({
				itemId,
				itemType: 'schedule',
				viewType: 'full',
				playbackStatus: 'paused',
				item: _item,
				previewImage: _item?.previewImage,
				isPublic: isPublic
			});
		}
	};

	const _renderLanes = () => {
		if (startTime && vertLanes.length + crossLanes.length > 0) {
			return (
				<View style={{ flex: 1, flexDirection: 'row' }}>
					{crossLanes.map((lane, idx) => {
						return lane.items.map((item) => {
							if (item.startDate && item.endDate) {
								const _style = _getItemStyle(idx, 0, startTime, item.startDate, item.endDate, true);

								const myDay = vertLanes.find((e) => e.stage.id === MYAGENDAID);
								if (myDay) {
									const myDayWidth =
										vertLanes.length > GRID_SIZE_LANE_COUNT
											? Math.max(1, myDay.blocks.length) * widthPerStage
											: viewWidth / (vertLanes.length > 0 ? vertLanes.length : 1);

									if (_style.width && typeof _style.width === 'number') {
										_style.width = _style.width - myDayWidth;
										_style.left = myDayWidth;
									}
								}

								return (
									<VerticalGridItemCell
										isPublic={isPublic}
										key={`grid_entry_${item.id}`}
										item={item}
										style={_style}
										onPress={!item.hasNoDetails ? () => _setMediaDetail(item.id) : undefined}
										backgroundColor={lane.stage.backgroundColor}
										textColor={lane.stage.textColor}
										isMyDay={lane.stage.id === MYAGENDAID}
									/>
								);
							}

							return null;
						});
					})}
					{vertLanes.map((lane, idx) => {
						return lane.blocks.map((block, blockIdx) => {
							return block.map((item) => {
								if (item.startDate && item.endDate) {
									const _stage = content.stages?.find((e) => e.id === item.stage?.id);
									return (
										<VerticalGridItemCell
											isPublic={isPublic}
											key={`grid_entry_${item.id}`}
											item={item}
											style={_getItemStyle(idx, blockIdx, startTime, item.startDate, item.endDate)}
											onPress={!item.hasNoDetails ? () => _setMediaDetail(item.id) : undefined}
											backgroundColor={_stage?.backgroundColor ?? lane.stage.backgroundColor}
											textColor={_stage?.textColor ?? lane.stage.textColor}
											isMyDay={lane.stage.id === MYAGENDAID}
										/>
									);
								}

								if (item.timeSlot) {
									return (
										<VerticalGridMeetingCell
											key={`grid_meetingentry_${item.id}`}
											item={item}
											style={_getItemStyle(idx, blockIdx, startTime, item.timeSlot.start, item.timeSlot.end)}
											onPress={() =>
												navigation.navigate(ERoutes.Chat, {
													spaceId: activeSpace?.spaceId,
													userId: item.ownerId === profile?.userId ? item.partnerId : item.ownerId
												})
											}
										/>
									);
								}

								return null;
							});
						});
					})}
				</View>
			);
		}

		return null;
	};

	const _renderNowLine = () => {
		const now = moment();

		if (timeCells.length > 0 && now.isAfter(moment(timeCells[timeCells.length - 1]))) {
			return null;
		}

		if (startTime && now.isSame(moment(startTime), 'date')) {
			const minTop = GRID_SCHEDULE_NOW_BUBBLE_SIZE / 4;
			const top = -minTop + _getItemHeight(startTime, now.toISOString());

			if (Math.abs(top) >= minTop) {
				return (
					<View
						pointerEvents="none"
						style={{
							position: 'absolute',
							top: top,
							left: -(GRID_SCHEDULE_NOW_BUBBLE_SIZE / 2),
							width: totalGridWidth,
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<View
							style={{
								height: GRID_SCHEDULE_NOW_BUBBLE_SIZE,
								width: GRID_SCHEDULE_NOW_BUBBLE_SIZE,
								borderRadius: GRID_SCHEDULE_NOW_BUBBLE_SIZE / 2,
								backgroundColor: theme.primary
							}}
						/>
						<View style={{ height: GRID_SCHEDULE_NOW_LINE_HEIGHT, width: '100%', backgroundColor: theme.primary }} />
					</View>
				);
			}
		}

		return null;
	};

	return (
		<View
			onLayout={(e) => setViewWidth(e.nativeEvent.layout.width - TIME_COLUMN_WIDTH)}
			style={{
				flex: 1,
				height: timeCells.length * (heightPerMinute * SCHEDULE_STEPS),
				flexDirection: 'row',
				backgroundColor: theme.contentBackgroundColor ?? theme.background,
				paddingVertical: HEADERTOGRIDSPACING,
				borderRadius: 5,
				marginBottom: activeSpace?.sponsorTypeAgenda?.startsWith('sponsor') ? EHorizontalScreenPadding.Wide : hsBottomMargin
			}}
		>
			<FlatList
				scrollEnabled={false}
				ref={timeCellRef}
				data={timeCells}
				style={{ marginTop: vertLanes.length > 1 ? laneColumnHeight + HEADERTOGRIDSPACING : 0, flexGrow: 0 }}
				contentContainerStyle={{
					height: timeCells.length * (heightPerMinute * SCHEDULE_STEPS) + HEADERTOGRIDSPACING
				}}
				showsVerticalScrollIndicator={false}
				keyExtractor={(item) => `timecell_${item}`}
				renderItem={({ item }) => (
					<VerticalGridTimeCell item={item} height={heightPerMinute * SCHEDULE_STEPS} width={TIME_COLUMN_WIDTH} />
				)}
			/>
			<View style={{ position: 'relative', flex: 1 }}>
				{vertLanes.length > 1 ? (
					<View style={{ height: laneColumnHeight, marginBottom: HEADERTOGRIDSPACING }}>
						<FlatList
							ref={headerRef}
							data={vertLanes}
							horizontal
							scrollEnabled={false}
							showsHorizontalScrollIndicator={false}
							renderItem={({ item }) => (
								<VerticalGridHeaderCell
									testID={`${TESTIDPREFIX}_stage_${item.id}`}
									item={item.stage}
									width={
										vertLanes.length > GRID_SIZE_LANE_COUNT
											? Math.max(1, item.blocks.length) * widthPerStage
											: viewWidth / (vertLanes.length > 0 ? vertLanes.length : 1)
									}
									imageWidth={
										vertLanes.length > GRID_SIZE_LANE_COUNT
											? Math.max(1, item.blocks.length) * widthPerStage
											: viewWidth / (vertLanes.length > 0 ? vertLanes.length : 1)
									}
									height={laneColumnHeight}
									onPress={
										item.stage.id !== MYAGENDAID && (iAmSpaceAdmin || iAmSpaceModerator)
											? () =>
													navigation.navigate(ERoutes.StageEdit, {
														spaceId: activeSpace?.spaceId,
														id: item.stage.id
													})
											: undefined
									}
								/>
							)}
							contentContainerStyle={
								vertLanes.length < GRID_SIZE_LANE_COUNT
									? { width: viewWidth / (vertLanes.length > 0 ? vertLanes.length : 1) }
									: undefined
							}
							keyExtractor={(item) => `header_${item.stage.id}`}
						/>
					</View>
				) : null}
				<ScrollView
					testID="agenda_scrollview"
					ref={contentRef}
					bounces={false}
					horizontal={IS_IOS || IS_WEB}
					nestedScrollEnabled
					scrollEventThrottle={16}
					onScroll={(e) => {
						if (IS_IOS || IS_WEB) {
							headerRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.x });
							if (IS_IOS) {
								timeCellRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.y });
							}
						} else {
							timeCellRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.y });
						}
					}}
					contentContainerStyle={
						IS_IOS
							? {
									width: totalGridWidth,
									height: timeCells.length * (heightPerMinute * SCHEDULE_STEPS) + HEADERTOGRIDSPACING
							  }
							: IS_WEB
							? { width: totalGridWidth }
							: null
					}
				>
					<ScrollView
						bounces={false}
						horizontal={IS_ANDROID}
						nestedScrollEnabled
						contentContainerStyle={
							IS_ANDROID
								? {
										width: totalGridWidth,
										height: timeCells.length * (heightPerMinute * SCHEDULE_STEPS) + HEADERTOGRIDSPACING
								  }
								: IS_WEB
								? { height: timeCells.length * (heightPerMinute * SCHEDULE_STEPS) + HEADERTOGRIDSPACING }
								: null
						}
						scrollEventThrottle={16}
						onScroll={(e) => {
							if (IS_ANDROID || IS_WEB) {
								if (IS_ANDROID) {
									headerRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.x });
								}
								timeCellRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.y });
							}
						}}
					>
						{startTime &&
							timeCells.map((cell, idx) => {
								const split: number[] = cell.split(':').map((e) => Number(e));

								return (
									<View
										key={`timecell_seperator_${cell}`}
										style={{
											position: 'absolute',
											left: 0,
											top: _getItemHeight(startTime, cell),
											width: totalGridWidth,
											height: 1,
											backgroundColor: theme.lightgray
										}}
									/>
								);
							})}
						{_renderLanes()}
						{_renderNowLine()}
					</ScrollView>
				</ScrollView>
			</View>
		</View>
	);
};
