import React, { useEffect, useRef, useState } from 'react';
import { IAttendee, INetworkingRoom } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Avatar } from 'components/User';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { View } from 'react-native';
import { ChildButton } from 'components/Button';
import { IS_WEB } from 'helper';
interface INetworkingRoomListItemUser {
	networkingRoom: INetworkingRoom;
	userId?: string;
	onHover: (e) => void;
	isHovered?: IAttendee;
}

const IMAGESIZE = 50;

export const NetworkingRoomListItemUser = (props: INetworkingRoomListItemUser) => {
	const { networkingRoom, userId, onHover, isHovered } = props;
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();

	const containerRef = useRef<View>(null);

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (attendees && userId) {
			_attendee = attendees.find((e) => e.userId === userId);
		}

		setAttendee(_attendee);
	}, [userId, attendees]);

	const _onHover = async () => {
		const { x, y } = await new Promise((resolve) => {
			containerRef.current?.measureInWindow((x, y) => {
				resolve({ x, y });
			});
		});

		onHover({ attendee, x, y });
	};

	const _onUnhover = () => {
		onHover({ attendee: undefined });
	};

	if (attendee) {
		return (
			<ChildButton
				activeOpacity={1}
				testID=""
				onPress={() => {
					if (isHovered?.userId === attendee.userId) {
						_onUnhover();
					} else {
						_onHover();
					}
				}}
				isDisabled={IS_WEB && !isTabletOrMobile}
			>
				<View testID="" ref={containerRef} onMouseEnter={_onHover} onMouseLeave={_onUnhover}>
					<Avatar
						size="sm"
						avatar={attendee?.smallImageUrl ?? attendee.imageUrl}
						fullName={`${attendee.firstName} ${attendee.lastName}`}
					/>
				</View>
			</ChildButton>
		);
	}

	return (
		<View
			style={{
				width: IMAGESIZE,
				height: IMAGESIZE,
				borderRadius: IMAGESIZE / 2,
				backgroundColor: networkingRoom.seatColor ?? theme.primary
			}}
		/>
	);
};
