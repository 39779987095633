import { createModel } from '@rematch/core';
import { MULTISPACEURL } from 'config/constants';
import { getAccessRightsForSpace, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import { ISpaceSubscriptionInfo } from 'rematch/interfaces/subscription';

import { RootModel } from './index';

type IDefaultState = {
	activeSpaceSubscription: Record<string, ISpaceSubscriptionInfo | undefined>;
};

export const subscription = createModel<RootModel>()({
	state: {
		activeSpaceSubscription: {}
	} as IDefaultState,
	reducers: {
		setActiveSpaceSubscription(
			state,
			{ spaceId, subscription }: { spaceId: string; subscription: ISpaceSubscriptionInfo | undefined }
		) {
			rematchLog('setActiveSpaceSubscription');
			const _activeSpaceSubscription = state.activeSpaceSubscription ? { ...state.activeSpaceSubscription } : {};
			_activeSpaceSubscription[spaceId] = subscription;
			return {
				...state,
				activeSpaceSubscription: _activeSpaceSubscription
			};
		},
		clear(state) {
			rematchLog('clear subscription');
			return {
				activeSpaceSubscription: {}
			};
		}
	},
	effects: (dispatch) => ({
		async getSpaceSubscription(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (activeSpace && (isAdmin || isModerator)) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/subscriptions/getactivesubscription`,
						body
					});

					if (res?.spaceId) {
						dispatch.subscription.setActiveSpaceSubscription({
							spaceId: res.spaceId,
							subscription: res
						});
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('getSpaceSubscription', error);
			}
		}
	})
});
