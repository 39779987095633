import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

export const MEETINGLISTHEADERHEIGHT = 20;

interface IMeetingListHeader {
	text: string;
}

export const MeetingListHeader = (props: IMeetingListHeader) => {
	const { text } = props;
	const { theme } = useTheme();

	return (
		<View
			style={{
				height: MEETINGLISTHEADERHEIGHT,
				borderBottomWidth: 1,
				borderColor: theme.text,
				backgroundColor: theme.contentBackgroundColor ?? theme.background,
				marginBottom: 10
			}}
		>
			<Text bold>{text}</Text>
		</View>
	);
};
