import { IWordListItem } from 'components/Vote/ActiveVote/VoteWordCloud/WordCloud';
import { IVoteOption } from 'config/interfaces/vote';

export const PreviewMultipleChoiceOptions: IVoteOption[] = [
	{ id: 9999996, description: 'previewWordStudio' },
	{ id: 9999997, description: 'previewWordService' },
	{ id: 9999998, description: 'previewWordHouse' },
	{ id: 9999999, description: 'previewWordEnergy' }
];

export const SpaceWizardMultipleChoiceOptions: IVoteOption[] = [
	{ id: 99999997, description: 'spaceWizardSentenceHybrid' },
	{ id: 99999998, description: 'spaceWizardSentenceOnline' },
	{ id: 99999999, description: 'spaceWizardSentenceLive' }
];

export const PreviewWordListMultipleChoice: IWordListItem[] = [
	{ count: 10, text: 'previewWordStudio' },
	{ count: 19, text: 'previewWordService' },
	{ count: 8, text: 'previewWordHouse' },
	{ count: 17, text: 'previewWordEnergy' }
];
