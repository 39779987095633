import { FormNumberSwitch } from 'components/Form/FormNumberSwitch';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

interface INpsScale {
	value: number;
	onChange?: (value: number) => void;
	minValue?: number;
	maxValue?: number;
	largerDesign?: boolean;
	isDisabled?: boolean;
	minValueText?: string;
	maxValueText?: string;
	numberOfVotes?: number;
}

export const NpsScale = (props: INpsScale) => {
	const { value, onChange, maxValue, minValue, largerDesign, isDisabled, minValueText, maxValueText, numberOfVotes } = props;
	const { theme } = useTheme();

	const [options, setOptions] = useState<number[]>([]);

	useEffect(() => {
		let _min = minValue ?? 0;
		let _max = maxValue ?? 10;

		let _options: typeof options = [];

		for (let i = _min; i <= _max; i++) {
			_options.push(i);
		}

		setOptions(_options);
	}, [minValue, maxValue]);

	return (
		<View>
			<FormNumberSwitch
				testID="npsscale"
				options={options}
				onChange={onChange}
				value={value}
				fullWidth
				formStyle={{ marginBottom: 0 }}
				isDisabled={isDisabled || !onChange}
			/>
			{(minValueText || maxValueText) && (
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
					<Text style={{ color: theme.gray, width: '46%', textAlign: 'left' }}>{minValueText}</Text>
					<Text style={{ color: theme.gray, width: '46%', textAlign: 'right' }}>{maxValueText}</Text>
				</View>
			)}
		</View>
	);
};
