import { IVoteOptionVote } from 'components/Forms/Vote/VoteQuiz/VoteQuizOptionsForm';
import { ILauncherSpace, IMedia, IMediaGalleryItem, IVoteOption, TAllowAnonymousAnswers, TVotingType } from 'config/interfaces';
import { v4 } from 'uuid';

export interface ICreateVoteFormValues {
	space?: ILauncherSpace;
	publicId: string;
	question: string;
	hint?: string;
	isActive?: boolean;
	media?: IMediaGalleryItem[];
	votingType?: TVotingType;
	minValue?: number;
	maxValue?: number;
	viewType?: string;
	openFrom?: string | undefined | null;
	openUntil?: string | undefined | null;
	allowAnonymousAnswers?: TAllowAnonymousAnswers;
	options?: IVoteOption[];
	isModerated?: boolean;
	optionsVote?: IVoteOptionVote[];
	allowMedia?: boolean;
	schedules?: number[];
	allowMultipleAnswers?: boolean;
	externalUrl?: string;
	hideResults?: boolean;
	minValueText?: string;
	maxValueText?: string;
	useAvatar?: boolean;
	iconColor?: string;
	requestReason?: boolean;
	requestReasonText?: string;
	requestReasonOptions?: string;
	networkingrooms?: number[];
}

export interface ICreateVotePayload {
	data: ICreateVoteFormValues;
	noToast?: boolean;
}

export type TMedia = IMedia | string | number | null | undefined;

export interface ICreateVoteFormErrors {
	publicId?: string;
	title?: string;
	question?: string;
	hint?: string;
	votingType?: string;
	isActive?: string;
	viewType?: string;
	minValue?: string;
	maxValue?: string;
	media?: string;
	openFrom?: string;
	openUntil?: string;
	allowAnonymousAnswers?: string;
	isModerated?: string;
	options?: string | { description?: string; label?: string; order?: string };
	allowMedia?: string;
	schedules?: string;
	allowMultipleAnswers?: string;
	externalUrl?: string;
	hideResults?: string;
	minValueText?: string;
	maxValueText?: string;
	useAvatar?: string;
	iconColor?: string;
	requestReasonText?: string;
	networkingrooms?: string;
}

export const CreateVoteInitialFormValues: ICreateVoteFormValues = {
	publicId: v4(),
	question: '',
	votingType: '',
	allowAnonymousAnswers: 'never',
	media: [],
	isModerated: false,
	allowMedia: false,
	schedules: [],
	networkingrooms: [],
	allowMultipleAnswers: false,
	isActive: true
};
