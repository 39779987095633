import { RoundButton } from 'components/Button';
import { QRCodeScanner } from 'components/QRCode';
import { EDefaultIconSet } from 'helper';
import { IS_WEB } from 'helper/platform';
import { useTheme } from 'hooks/useTheme';
import React, { useState } from 'react';
import { Dimensions, Modal, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IQRCodeScannerModalComponent {
	isVisible: boolean;
	onResult: (value: string) => void;
	onClose: () => void;
}

const PORTRAITTOP = '25%';
const LANDSCAPETOP = '35%';
const WIDTH = 1;

export const QRCodeScannerModal = (props: IQRCodeScannerModalComponent) => {
	const { isVisible, onResult, onClose } = props;
	const { theme } = useTheme();
	const { left, top } = useSafeAreaInsets();

	const [dimensions, setDimensions] = useState({
		height: 0,
		width: 0,
		isLandscape: false
	});

	if (IS_WEB) {
		return null;
	}

	return (
		<Modal
			animationType="slide"
			visible={isVisible}
			style={{ flex: 1, position: 'relative' }}
			supportedOrientations={['portrait', 'landscape-left', 'landscape-right']}
			onOrientationChange={(value) => {
				setDimensions({
					height: Dimensions.get('screen').height,
					width: Dimensions.get('screen').width,
					isLandscape: Dimensions.get('screen').height < Dimensions.get('screen').width
				});
			}}
		>
			<QRCodeScanner
				onCodeScanned={(res) => {
					const route = res.replace(/.*?:\/\//g, '');
					const urlParts = route.split('/');

					switch (urlParts.length) {
						case 5:
							onResult(urlParts[2]);
							break;
						case 1:
							onResult(urlParts[0]);
							break;
						case 0:
							break;
						default:
							onResult(urlParts[urlParts.length - 1]);
					}
				}}
				style={{ width: '100%', height: '100%', backgroundColor: theme.background }}
			/>
			<View
				style={{
					position: 'absolute',
					left: dimensions.isLandscape ? left + 40 : 40,
					top: dimensions.isLandscape ? 0.5 * top : top + 20,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<RoundButton isOutline testID={''} onPress={() => onClose()} icon={EDefaultIconSet.Close} />
			</View>
			{/* TOP */}
			<View
				style={{
					position: 'absolute',
					left: 0,
					top: dimensions.isLandscape ? PORTRAITTOP : LANDSCAPETOP,
					height: WIDTH,
					width: '100%',
					backgroundColor: theme.lightgray
				}}
			/>
			{/* BOTTOM */}
			<View
				style={{
					position: 'absolute',
					left: 0,
					bottom: dimensions.isLandscape ? PORTRAITTOP : LANDSCAPETOP,
					height: WIDTH,
					width: '100%',
					backgroundColor: theme.lightgray
				}}
			/>
			{/* LEFT */}
			<View
				style={{
					position: 'absolute',
					left: dimensions.isLandscape ? LANDSCAPETOP : PORTRAITTOP,
					top: 0,
					height: '100%',
					width: WIDTH,
					backgroundColor: theme.lightgray
				}}
			/>
			{/* RIGHT */}
			<View
				style={{
					position: 'absolute',
					right: dimensions.isLandscape ? LANDSCAPETOP : PORTRAITTOP,
					top: 0,
					height: '100%',
					width: WIDTH,
					backgroundColor: theme.lightgray
				}}
			/>
		</Modal>
	);
};
