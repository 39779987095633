import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ScrollView, View } from 'react-native';
import { useSpace } from 'hooks/useSpace';
import { ISchedule, IScheduleStatus } from 'config/interfaces';
import { IRootState } from 'rematch/store';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ScheduleGalleryItem } from './ScheduleGalleryItem';

interface ISCheduleGallery {
	onItemPress: (item: ISchedule) => void;
	isVisible?: boolean;
}

const TESTIDPREFIX = 'schedulegallery';

export const SCHEDULEGALLERYITEMMAXWIDTH = 300;

export const ScheduleGallery = (props: ISCheduleGallery) => {
	const { onItemPress, isVisible } = props;

	const content = useSelector((store: IRootState) => store.content.content);
	const { activeSpace } = useSpace();

	const [liveSchedules, setLiveSchedules] = useState<ISchedule[]>([]);
	const [containerSize, setContainerSize] = useState<{ height: number; width: number }>({ height: 0, width: 0 });
	const [itemWidth, setItemWidth] = useState<number>(containerSize.width * 0.75);

	useEffect(() => {
		if (content?.schedules && content?.schedulestatuses) {
			let _schedules: ISchedule[] = [];

			let _liveSchedules = content?.schedules?.filter(
				(e) => e.spaceId === activeSpace?.spaceId && moment(e.startDate)?.isSame(moment(), 'date')
			);
			_liveSchedules = _liveSchedules?.filter((s) => {
				const status = content?.schedulestatuses?.find((e) => e.schedule?.id === s?.id);
				return status?.status === 'Live';
			});

			_liveSchedules?.forEach((ls) => {
				_schedules.push(ls);
			});

			let _upcomingSchedules = content?.schedules?.filter(
				(e) =>
					e.spaceId === activeSpace?.spaceId &&
					moment(e.startDate).isAfter(moment()) &&
					moment().add(30, 'minutes').isSameOrAfter(moment(e.startDate))
			);
			_upcomingSchedules = _upcomingSchedules?.filter((s) => {
				const status = content?.schedulestatuses?.find((e) => e.schedule?.id === s?.id);
				return !status?.status || status?.status === 'Upcoming';
			});
			_upcomingSchedules?.forEach((us) => {
				if (!_schedules.includes(us)) _schedules.push(us);
			});

			_schedules = _schedules?.sort((a, b) => {
				return moment(a.startDate).isBefore(b.startDate) ? -1 : 1;
			});

			setLiveSchedules(_schedules);
		}
	}, [activeSpace, content]);

	useEffect(() => {
		if (containerSize.width > 0) {
			setItemWidth(containerSize.width * 0.75);
		}
	}, [containerSize, liveSchedules]);

	if (isVisible && liveSchedules) {
		return (
			<View
				style={{
					marginHorizontal: EHorizontalScreenPadding.Wide,
					maxWidth: '100%',
					marginBottom: 10,
					justifyContent: 'center'
				}}
			>
				<ScrollView
					horizontal
					scrollEnabled
					persistentScrollbar={true}
					showsHorizontalScrollIndicator
					style={isVisible && liveSchedules && liveSchedules.length > 0 ? { height: 'auto' } : { height: 0, marginBottom: 0 }}
					contentContainerStyle={liveSchedules && liveSchedules.length > 0 ? { marginBottom: 10 } : {}}
					onLayout={(e) => setContainerSize(e.nativeEvent.layout)}
					overScrollMode="always"
				>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						{liveSchedules.map((item, idx) => {
							const isLastItem = idx === liveSchedules.length - 1;

							return (
								<View
									style={{
										marginRight: isLastItem ? 2 : 10,
										width: itemWidth,
										maxWidth: SCHEDULEGALLERYITEMMAXWIDTH
									}}
									key={`gallery_item_${item?.id}`}
								>
									<ScheduleGalleryItem
										testIdPrefix={TESTIDPREFIX}
										item={item}
										onPress={() => onItemPress(item)}
										backgroundColor={item?.stage?.backgroundColor}
									/>
								</View>
							);
						})}
					</View>
				</ScrollView>
			</View>
		);
	}

	return null;
};
