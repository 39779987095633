import { IContentTypeField } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useContent } from './useContent';
import { useRegistration } from './useRegistration';
import { useSpace } from './useSpace';

export const useMatching = () => {
	const { activeSpace } = useSpace();
	const { getRegistrationFields } = useRegistration();
	const { getContentTypeFields: getExpoFields } = useContent('expo');
	const { getContentTypeFields: getSpeakerFields } = useContent('speaker');
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const getMatchCount = (contentType: string, item: any) => {
		let counter = 0;

		if (activeSpace?.attendeeSettings?.matching && profile) {
			let fields: IContentTypeField[] = [];

			switch (contentType) {
				case 'attendee':
					fields = getRegistrationFields(true);
					break;
				case 'expo':
					fields = getExpoFields(true);
					break;
				case 'speaker':
					fields = getSpeakerFields(true);
					break;
				case 'mediaitem':
					fields = getMediaItemFields(true);
				default:
					break;
			}

			fields = fields.filter((e) => e.fieldType === 'category' && e.showOnDetailScreen);

			const relevantMatchings = activeSpace.attendeeSettings.matching.filter(
				(e) => (e.type1 === contentType && e.type2 === 'attendee') || (e.type1 === 'attendee' && e.type2 === contentType)
			);

			relevantMatchings.forEach((match) => {
				if (match.type1 && match.type2 && match.fieldName1 && match.fieldName2) {
					if (match.type1 === match.type2) {
						if (profile[match.fieldName1] && item[match.fieldName2]) {
							const field = fields.find((e) => e.fieldName === match.fieldName1);
							if (field) {
								const profileSplit = profile[match.fieldName1].split(',');
								const itemSplit = item[match.fieldName2].split(',');

								profileSplit.forEach((value) => {
									const option = field.options?.find((e) => e.key === value);
									if (option && itemSplit.includes(value)) {
										counter += 1;
									}
								});
							}
						}

						if (match.fieldName1 !== match.fieldName2 && profile[match.fieldName2] && item[match.fieldName1]) {
							const field = fields.find((e) => e.fieldName === match.fieldName2);
							if (field) {
								const profileSplit = profile[match.fieldName2].split(',');
								const itemSplit = item[match.fieldName1].split(',');

								profileSplit.forEach((value) => {
									const option = field.options?.find((e) => e.key === value);
									if (option && itemSplit.includes(value)) {
										counter += 1;
									}
								});
							}
						}
					} else {
						if (match.type1 === contentType && item[match.fieldName1] && profile[match.fieldName2]) {
							const field = fields.find((e) => e.fieldName === match.fieldName1);

							if (field) {
								const profileSplit = profile[match.fieldName2].split(',');
								const itemSplit = item[match.fieldName1].split(',');

								profileSplit.forEach((value) => {
									const option = field.options?.find((e) => e.key === value);
									if (option && itemSplit.includes(value)) {
										counter += 1;
									}
								});
							}
						} else if (match.type2 === contentType && item[match.fieldName2] && profile[match.fieldName1]) {
							const field = fields.find((e) => e.fieldName === match.fieldName2);

							if (field) {
								const profileSplit = profile[match.fieldName1].split(',');
								const itemSplit = item[match.fieldName2].split(',');

								profileSplit.forEach((value) => {
									const option = field.options?.find((e) => e.key === value);
									if (option && itemSplit.includes(value)) {
										counter += 1;
									}
								});
							}
						}
					}
				}
			});
		}

		return counter;
	};

	return {
		getMatchCount
	};
};
