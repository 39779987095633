import React, { useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { H1, H3 } from 'components/Text';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button/Button';
import { ChildButton } from 'components/Button/ChildButton';
import { RoundButton } from 'components/Button/RoundButton';

import { useTheme } from 'hooks//useTheme';
import { useSpace } from 'hooks/useSpace';
import { useQuery } from 'hooks/useQuery';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { HSCard } from 'components/Card';
import { ToastProps } from 'react-native-toast-notifications/lib/typescript/toast';
import { hsBorderRadius } from 'config/styleConstants';
import { Markdown } from 'components/Markdown';
import { Image } from 'components/Image';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';
interface IToastProps extends ToastProps {
	isModal?: boolean;
}

export const BaseToast = (props: IToastProps) => {
	const { id, data, type, onHide, onPress, isModal } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const screenWidth = Dimensions.get('window').width;

	const [imageRatio, setImageRatio] = useState<number>(1);

	let iconName, color;

	switch (type) {
		case 'custom':
			iconName = data?.iconOrImage ?? EDefaultIconSet.Info;
			color = '#87CEFA';
			break;
		case 'success':
			iconName = EDefaultIconSet.Success;
			color = theme.success;
			break;
		case 'error':
			iconName = EDefaultIconSet.Error;
			color = theme.danger;
			break;
		case 'initialLoad':
		case 'info':
			iconName = EDefaultIconSet.Info;
			color = '#87CEFA';
			break;
		case 'connectionLost':
			iconName = EDefaultIconSet.Error;
			color = theme.danger;
			break;
		case 'connectionEstablished':
			iconName = EDefaultIconSet.Success;
			color = theme.success;
			break;
		case 'pushNotification':
			color = theme.primary;
			break;
		default:
			break;
	}

	const _renderText1 = () => {
		if (data.title && !isEmptyString(data.title)) {
			return (
				<H1 style={{ marginBottom: 5 }} numberOfLines={type === 'pushNotification' ? undefined : 2}>
					{data.title}
				</H1>
			);
		}
		return null;
	};

	const _renderText2 = () => {
		if (data.subtitle && !isEmptyString(data.subtitle)) {
			return <H3 noBottomMargin>{data.subtitle}</H3>;
		}
		return null;
	};

	const _renderImage = () => {
		if (data?.iconOrImage) {
			const IMAGEWIDTH = isTabletOrMobile ? 80 : 120;

			return (
				<Image
					url={data.iconOrImage}
					onLoad={(e) => setImageRatio(e.source.width / e.source.height)}
					style={{ width: IMAGEWIDTH, height: IMAGEWIDTH / imageRatio }}
				/>
			);
		}

		return null;
	};

	const _renderContent = () => {
		switch (type) {
			case 'pushNotification':
				return (
					<View style={{ flex: 1 }}>
						<View style={{ flex: 1, flexDirection: 'row' }}>
							{_renderImage()}
							<ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingLeft: 10, paddingRight: 35, maxHeight: 300 }}>
								{_renderText1()}
								<Markdown markdown={data.subtitle} />
							</ScrollView>
						</View>
						{_renderLink()}
					</View>
				);
			default:
				return (
					<>
						<Icon name={iconName} color={color} size={25} />
						<View style={{ flex: 1, paddingLeft: 10, paddingRight: 35 }}>
							{_renderText1()}
							{_renderText2()}
						</View>
					</>
				);
		}
	};

	const _closeToast = () => {
		onHide();
		if (onPress) {
			onPress(id);
		}
	};

	const _renderLink = () => {
		if (data.deeplinkType) {
			let title;
			let route;
			let obj = { spaceId: activeSpace?.spaceId };

			switch (data.deeplinkType) {
				case 'schedules':
					title = t('ToAgendaEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.Media;
						obj['itemId'] = data.deeplinkItemId;
						obj['mediaType'] = 'schedule';
					} else {
						route = ERoutes.Schedule;
					}
					break;
				case 'speakers':
					title = t('ToSpeakerEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.SpeakerDetails;
						obj['id'] = data.deeplinkItemId;
					} else {
						route = ERoutes.Speakers;
					}
					break;
				case 'expos':
					title = t('ToExpoEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.ExpoDetails;
						obj['id'] = data.deeplinkItemId;
					} else {
						route = ERoutes.Expos;
					}
					break;
				case 'mediaitems':
					title = t('ToMediaItemEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.Media;
						obj['itemId'] = data.deeplinkItemId;
						obj['mediaType'] = 'schedule';
					} else {
						route = ERoutes.MediaItemList;
					}
					break;
				case 'votings':
					title = t('ToVotingEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.ActiveVoting;
						obj['id'] = data.deeplinkItemId;
					} else {
						route = ERoutes.VoteList;
					}
					break;
				case 'newsitems':
					title = t('ToNewsEntry');
					if (data.deeplinkItemId) {
						route = ERoutes.SpeakerDetails;
						obj['id'] = data.deeplinkItemId;
					} else {
						route = ERoutes.Speakers;
					}
					break;
				default:
					break;
			}

			return (
				<Button
					testID=""
					style={{ alignSelf: 'flex-end', marginTop: 15 }}
					isDisabled={!route}
					type="primary"
					onPress={() => {
						_closeToast();
						navigation.navigate(route, obj);
					}}
					title={title}
					buttonType="default"
				/>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID="toast_button_onpress"
			style={{
				paddingHorizontal: 10,
				marginLeft: IS_WEB && !isTabletOrMobile && !isModal ? 300 : 0,
				minWidth: IS_WEB && !isTabletOrMobile ? '40%' : screenWidth > 800 ? '60%' : '80%',
				maxWidth: IS_WEB && !isTabletOrMobile ? (isModal ? '75%' : '50%') : Dimensions.get('window').width
			}}
			onPress={_closeToast}
		>
			<HSCard
				style={{
					width: '100%',
					flexDirection: 'row',
					alignItems: type === 'pushNotification' ? 'flex-start' : 'center',
					borderRadius: hsBorderRadius,
					borderLeftWidth: 5,
					borderColor: color,
					paddingLeft: 10
				}}
			>
				{_renderContent()}
				<View style={{ position: 'absolute', top: 10, right: 15 }}>
					<RoundButton testID="toast_button_close" icon={EDefaultIconSet.Close} size="sm" isOutline onPress={_closeToast} />
				</View>
			</HSCard>
		</ChildButton>
	);
};
