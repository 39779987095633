import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { ChildButton } from 'components/Button';
import { ISchedule } from 'config/interfaces';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, getFixedHeightForText, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { AnimatedLive } from 'components/Animated';
import { hsBorderRadius, hsBottomMargin } from 'config/styleConstants';

interface IScheduleReferenceItem {
	testID: string;
	item: ISchedule;
	onPress: () => void;
}

export const ScheduleReferenceItem = (props: IScheduleReferenceItem) => {
	const { testID, item, onPress } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [liveWidth, setLiveWidth] = useState<number>(0);

	const _renderDate = () => {
		let str = '';

		if (!isEmptyString(item.startDate) && !isEmptyString(item.endDate)) {
			str = `${moment(item.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(item.endDate).format('HH:mm')}`;
		} else if (!isEmptyString(item.startDate)) {
			str = `${t('Start')}: ${moment(item.startDate).format('DD.MM.YYYY HH:mm')}`;
		} else if (!isEmptyString(item.endDate)) {
			str = `${t('End')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
		}

		if (!isEmptyString(str)) {
			return <Text style={{ fontSize: 12 }}>{str}</Text>;
		}

		return null;
	};

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: hsBottomMargin,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: hsBorderRadius,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row', paddingRight: liveWidth }}>
					<View style={{ flex: 1, flexDirection: 'row' }}>
						<View style={{ flex: 1 }}>
							<Text bold numberOfLines={1} style={{ marginRight: item.isHidden ? 5 : undefined }}>
								{item.title}
							</Text>
						</View>
						{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
					</View>
					<AnimatedLive
						isVisible={item.status?.status === 'Live'}
						containerStyle={{ right: 0 }}
						onLayout={(e) => setLiveWidth(e)}
					/>
				</View>

				<Text style={{ height: getFixedHeightForText(14, 1) }} numberOfLines={1}>
					{item.subtitle ?? ''}
				</Text>

				{_renderDate()}

				<Text bold style={{ fontSize: 12, height: getFixedHeightForText(12, 1) }} numberOfLines={1}>
					{item.stage?.title ?? ''}
				</Text>
			</View>
			{SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
