import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useDate } from 'hooks/useDate';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { H3 } from 'components/Text';
import { EDefaultIconSet, generateTheme, isDarkColor, isEmptyString, IS_WEB } from 'helper';
import { FlatList, View } from 'react-native';
import { Image } from 'components/Image';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import i18next from 'i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IScheduleDay } from './ScheduleScreen';
import { IActiveScreenFilter, ILauncherSpace, ISchedule, ISpeaker, IStage } from 'config/interfaces';
import moment from 'moment';
import { NoData } from 'components/NoData';
import { NewGrid, VerticalGrid } from 'components/Schedule';
import { ChildButton, FilterButton, RoundButton } from 'components/Button';
import { Text } from 'components/Text';
import { HSCard } from 'components/Card';
import { ScreenFilterbar } from 'components/Filter';
import { SearchBar } from 'components/SearchBar';
import * as RootNavigation from '../../../RootNavigation';
import { useSelector } from 'react-redux';

const helloSpacesBlack = require('../../../../assets/hellospaces.png');
const helloSpacesWhite = require('../../../../assets/hellospacesWhite.png');

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.PublicAgenda>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.PublicAgenda>;
type RouteParams = StackParamList[ERoutes.PublicAgenda];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface IPublicContent {
	stages: IStage[];
	schedules: ISchedule[];
	speakers: ISpeaker[];
}

const TESTIDPREFIX = 'publicagenda';

export const PublicAgendaScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { getDate, getMonth, getDayOfWeekShort } = useDate();

	const [scheduleDays, setScheduleDays] = useState<IScheduleDay[]>([]);
	const [filteredScheduleDays, setFilteredScheduledDays] = useState<IScheduleDay[]>([]);
	const [searchedScheduleDays, setSearchedScheduleDays] = useState<IScheduleDay[]>([]);
	const [activeScheduleDate, setActiveScheduleDate] = useState<string | undefined>(undefined);
	const [activeScreenFilters, setActiveScreenFilters] = useState<IActiveScreenFilter>({ filters: ['all'] });
	const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [daySet, setDaySet] = useState<boolean>(false);
	const [content, setContent] = useState<IPublicContent>({ stages: [], schedules: [], speakers: [] });
	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [error, setError] = useState<string>('');

	const loadPublicAgenda = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadPublicAgenda);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	useEffect(() => {
		if (route.params?.spaceId) {
			_loadAgenda(route.params?.spaceId);
		}
	}, [route.params]);

	useEffect(() => {
		let _days: typeof scheduleDays = [];

		if (!isEmptyString(route.params?.spaceId) && content?.schedules) {
			let _items = content?.schedules?.filter((e) => e.spaceId === route.params?.spaceId && e.startDate && e.endDate && e.stage?.id);

			_items.sort((a, b) => {
				const aMoment = moment(a.startDate);
				const bMoment = moment(b.startDate);
				return aMoment.isBefore(bMoment) ? -1 : 1;
			});

			_items.forEach((item) => {
				const itemDate = moment(item.startDate).set('hours', 12).set('minutes', 0).set('seconds', 0).toISOString();
				const day = _days.find((day) => day.date === itemDate);
				if (day) {
					day.items.push(item);
				} else {
					_days.push({
						date: itemDate,
						items: [item],
						meetings: []
					});
				}
			});
		}

		_days = _days.filter((day) => day.items.length > 0 || day.meetings.length > 0);

		_days.sort((a, b) => {
			const aVal = moment(a.date);
			const bVal = moment(b.date);

			return aVal.isBefore(bVal) ? -1 : 1;
		});

		if (!activeScheduleDate && _days.length > 0) {
			setActiveScheduleDate(_days[0].date);
		}
		setScheduleDays(_days);
	}, [content]);

	useEffect(() => {
		if (!daySet) {
			const now = moment();

			const found = scheduleDays.find((tab) => tab.date === now.format('DD.MM'));
			if (found) {
				setActiveScheduleDate(found.date);
				setDaySet(true);
			}
		}
	}, [scheduleDays]);

	useEffect(() => {
		let _filtered: typeof scheduleDays = [...scheduleDays];

		if (activeScreenFilters.filters.includes('all')) {
			// Nothing to do
		} else {
			_filtered = [];

			scheduleDays.forEach((day) => {
				const items = day.items.filter((e) => {
					if (activeScreenFilters.fieldName) {
						for (const catItemKey of activeScreenFilters.filters) {
							if (e[activeScreenFilters.fieldName]) {
								const itemSplit = e[activeScreenFilters.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					_filtered.push(obj);
				}
			});
		}

		if (Object.keys(activeFilters).length > 0) {
			const temp: IScheduleDay[] = [];
			_filtered.forEach((day) => {
				const items = day.items.filter((e) => {
					const catKeys = Object.keys(activeFilters);
					for (const catKey of catKeys) {
						const splitted = activeFilters[catKey].split(',');
						for (const catItemKey of splitted) {
							if (e[catKey] && e[catKey]?.toString()?.includes(',')) {
								const itemSplit = e[catKey]?.toString()?.split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}
					}

					return false;
				});

				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					temp.push(obj);
				}
			});
			_filtered = [...temp];
		}

		setFilteredScheduledDays(_filtered);
	}, [scheduleDays, activeFilters, activeScreenFilters]);

	useEffect(() => {
		let _searched: typeof searchedScheduleDays = [...filteredScheduleDays];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = [];
			filteredScheduleDays.forEach((day) => {
				const items = day.items.filter((e) => {
					if (
						e.title.toLowerCase().includes(lowerSearch) ||
						e.subtitle?.toLowerCase().includes(lowerSearch) ||
						e.description?.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}

					for (const sp of e.speakers ?? []) {
						const speaker = content.speakers?.find((e2) => e2.id === sp.id);
						if (
							speaker?.title.toLowerCase().includes(lowerSearch) ||
							speaker?.company?.toLowerCase().includes(lowerSearch) ||
							speaker?.bio?.toLowerCase().includes(lowerSearch)
						) {
							return true;
						}
					}

					return false;
				});

				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					_searched.push(obj);
				}
			});
		}

		setSearchedScheduleDays(_searched);
	}, [filteredScheduleDays, searchTerm]);

	const _loadAgenda = async (spaceId) => {
		const res = await loadPublicAgenda({ spaceId: spaceId });
		if (res?.status === 200) {
			setTheme(generateTheme(res?.space));

			if (res?.space) {
				setSpace(res?.space);
			}
			const udpatedSchedules = res?.schedule?.map((s: ISchedule) => {
				const status = res?.schedulestatus?.find((st) => st.schedule?.id === s.id);
				return {
					...s,
					previewImage: s.stream?.previewImage,
					status
				};
			});
			setContent({ schedules: udpatedSchedules ?? [], stages: res?.stage ?? [], speakers: res?.speaker ?? [] });
		} else {
			setError(res?.message);
		}
	};

	const _renderPoweredBy = () => {
		if (isEmptyString(error)) {
			return (
				<HSCard style={{ position: 'absolute', bottom: 25, right: 25, width: 'auto' }}>
					<H3 center>Powered by</H3>
					<Image
						style={{ width: 150, aspectRatio: 7, alignSelf: 'center' }}
						url={isDarkColor(theme.contentBackgroundColor ?? theme.background) ? helloSpacesWhite : helloSpacesBlack}
					/>
				</HSCard>
			);
		}
	};

	const _renderDaySwitch = () => {
		if (searchedScheduleDays.length > 0) {
			return (
				<View style={{ flexDirection: 'row', marginBottom: hsBottomMargin / 2 }}>
					<FlatList
						data={searchedScheduleDays}
						horizontal
						contentContainerStyle={{ flexGrow: 1 }}
						showsHorizontalScrollIndicator={false}
						keyExtractor={(item) => `${TESTIDPREFIX}_dayselect_${item.date}`}
						renderItem={({ item, index }) => {
							const isActive = activeScheduleDate === item.date;
							const isLastItem = index === searchedScheduleDays.length - 1;

							return (
								<ChildButton
									key={`${TESTIDPREFIX}_button_dayselect_${index}`}
									testID={`${TESTIDPREFIX}_button_dayselect_${index}`}
									onPress={() => setActiveScheduleDate(item.date)}
									style={[
										{
											marginRight: isLastItem ? 2 : 10,
											borderWidth: 1,
											borderRadius: 5,
											borderColor: theme.primary,
											paddingHorizontal: 5,
											paddingVertical: 2
										},
										isActive ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
									]}
								>
									<Text bold={isActive} style={{ color: isActive ? theme.primaryContrast : theme.primary }}>
										{`${getDayOfWeekShort(item.date)} ${getDate(item.date)}.${getMonth(item.date)}`}
									</Text>
								</ChildButton>
							);
						}}
					/>
				</View>
			);
		}
	};

	const _renderSchedule = () => {
		const day = searchedScheduleDays.find((e) => e.date === activeScheduleDate);
		if (day?.meetings.length === 0 && day?.items.length === 0) {
			return <NoData type="NoSchedules" />;
		}

		if (searchedScheduleDays.length === 0) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoSchedules" />;
		}

		return <NewGrid items={day?.items ?? []} meetings={[]} isPublic space={space} />;
		return <VerticalGrid items={day?.items ?? []} meetings={day?.meetings ?? []} isPublic space={space} />;
	};

	const _closePublicAgenda = () => {
		if (RootNavigation.canGoBack()) {
			navigation.goBack();
		} else {
			RootNavigation.replace(profile && space ? (ERoutes.SpaceSummary, { spaceId: space.spaceId }) : ERoutes.SpaceSelect);
		}
	};

	const _renderSearchFilter = () => {
		return (
			<HSCard style={{ marginBottom: hsBottomMargin }}>
				{!IS_WEB && (
					<View style={{ position: 'absolute', top: 5, right: hsBottomMargin, zIndex: 1 }}>
						<RoundButton
							isOutline
							testID={`${TESTIDPREFIX}_button_close`}
							icon={EDefaultIconSet.Close}
							size="sm"
							onPress={_closePublicAgenda}
						/>
					</View>
				)}
				{_renderDaySwitch()}

				<ScreenFilterbar
					type="schedule"
					activeFilters={activeScreenFilters.filters}
					onChange={(filters, fieldName) => setActiveScreenFilters({ filters, fieldName })}
					space={space}
				/>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<View style={{ flex: 1 }}>
						<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
					</View>

					<FilterButton
						testID={`${TESTIDPREFIX}_button_filter`}
						title={t('Filter Agenda')}
						type="schedule"
						activeFilters={activeFilters}
						setActiveFilters={(val) => setActiveFilters(val)}
						space={space}
					/>
				</View>
			</HSCard>
		);
	};

	return (
		<ScreenContainer bgImage={space?.backgroundImage} bgImageName={space?.backgroundImageName}>
			{isEmptyString(error) ? (
				<View
					style={{
						height: '100%',
						overflow: 'hidden',
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						marginBottom: hsBottomMargin
					}}
				>
					{_renderSearchFilter()}
					{_renderSchedule()}
				</View>
			) : (
				<View
					style={{
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					<NoData title={t('publicAgendaErrorHeader')} subtitle={error} />
				</View>
			)}
			{_renderPoweredBy()}
		</ScreenContainer>
	);
};

export const PublicAgendaScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			{IS_WEB ? <NavigationHeaderPlaceholder /> : <NavigationHeaderBackButton />}
			<NavigationHeaderTitle title={i18next.t('Public Agenda')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
