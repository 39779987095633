import { Hint, Title } from 'components/Text';
import { isEmptyString } from 'helper';
import React from 'react';
import { ViewStyle } from 'react-native';
import { HSCard } from './HSCard';

interface IHSHeaderCard {
	title?: string;
	subtitle?: string;
	style?: ViewStyle;
	noShadow?: boolean;
}

export const HSHeaderCard = (props: IHSHeaderCard) => {
	const { title, subtitle, style, noShadow } = props;

	return (
		<HSCard
			style={
				noShadow
					? {
							...style,
							shadowOffset: {
								width: 0,
								height: 0
							},
							shadowOpacity: 0,
							shadowRadius: 0,
							elevation: 0,
							padding: 0
					  }
					: { ...style }
			}
		>
			{title ? <Title style={isEmptyString(subtitle) ? { marginBottom: 0 } : {}}>{title}</Title> : null}
			{!isEmptyString(subtitle) && <Hint>{subtitle}</Hint>}
		</HSCard>
	);
};
