import React, { ReactNode } from 'react';
import { H1, H2, H3, Hint, Title } from 'components/Text';
import { VoteMedia } from 'components/Vote/VoteMedia';
import { IVote } from 'config/interfaces/vote';
import { hsInnerPadding } from 'config/styleConstants';
import { isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import moment from 'moment';
import { useSpace } from 'hooks/useSpace';

interface IActiveVoteHeader {
	activeVote: IVote;
	containerStyle?: ViewStyle;
	showResult?: boolean;
	isCondensed?: boolean;
	largerText?: boolean;
	hideMedia?: boolean;
	children?: ReactNode | ReactNode[];
}

export const ActiveVoteHeader = (props: IActiveVoteHeader) => {
	const { activeVote, containerStyle, showResult, isCondensed, largerText, hideMedia, children } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const _renderHint = () => {
		if (isEmptyString(activeVote.hint)) {
			return null;
		}

		if (!showResult) {
			return <Hint largerText={largerText}>{activeVote.hint}</Hint>;
		}

		return null;
	};

	const _renderVoteNotActive = () => {
		let active = false;
		const now = moment();
		if (activeVote.isActive) {
			if (activeVote.openUntil && activeVote.openFrom) {
				active = now.isBefore(moment(activeVote.openUntil)) && now.isAfter(moment(activeVote.openFrom));
			} else if (activeVote.openUntil) {
				active = now.isBefore(moment(activeVote.openUntil));
			} else if (activeVote.openFrom) {
				active = now.isAfter(moment(activeVote.openFrom));
			} else {
				active = true;
			}
		}

		if (!active) {
			return (
				<View style={{ alignItems: 'center', padding: hsInnerPadding }}>
					<H1 largerText={largerText} center style={{ color: theme.danger, marginBottom: 5 }}>
						{t('VoteNotActiveTitle')}
					</H1>
					<H3 largerText={largerText} center style={{ color: theme.danger }}>
						{iAmSpaceAdmin || iAmSpaceModerator ? t('VoteNotActiveSubtitle') : t('VoteNotActiveSubtitleAttendees')}
					</H3>
				</View>
			);
		}

		return null;
	};

	const _renderTitle = () => {
		if (isCondensed) {
			return (
				<H2 largerText={largerText} noBottomMargin={isEmptyString(activeVote.hint) || showResult} testID="vote_header">
					{activeVote.question}
				</H2>
			);
		}

		return (
			<Title style={isEmptyString(activeVote.hint) || showResult ? { marginBottom: 0 } : {}} largerText={largerText}>
				{activeVote.question}
			</Title>
		);
	};

	return (
		<HSCard style={{ ...containerStyle }}>
			{_renderVoteNotActive()}
			<View>
				{!isCondensed && !hideMedia && <VoteMedia media={activeVote?.media} voteId={activeVote.id} />}
				{_renderTitle()}
				{_renderHint()}
			</View>
			{children}
		</HSCard>
	);
};
