import React from 'react';
import { IMediaGalleryItem } from 'config/interfaces';
import { ChildButton } from 'components/Button';
import { Image } from 'components/Image';
import { hsBorderRadius } from 'config/styleConstants';
import { StreamPlayer } from 'components/Stream';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTracker } from 'hooks/useTracker';
import { View } from 'react-native';
import { getDefaultAspectRatioStyle, getTopRadiusStyle, IS_WEB, openURL } from 'helper';

interface INewsMediaItem {
	item: IMediaGalleryItem;
	width: number;
	onPress?: () => void;
	onPlay?: () => void;
	isRounded?: boolean;
	openImageInNewTab?: boolean;
}

export const NewsMediaItem = (props: INewsMediaItem) => {
	const { item, width, onPress, isRounded, onPlay, openImageInNewTab } = props;
	const { trackAction } = useTracker();

	const spaceMedia = useSelector((store: IRootState) => store.upload.spaceMedia);

	const webCursorStyle = { cursor: openImageInNewTab ? 'pointer' : 'default' };

	const _getMediaFromLibrary = (id: number) => {
		if (spaceMedia) {
			return spaceMedia.find((e) => e.id === id);
		}
	};

	const _renderImageOrVideo = () => {
		if (
			item.media?.mime?.includes('video') ||
			(typeof item.media === 'string' && (item.media.endsWith('mp4') || item.media.startsWith('data:video')))
		) {
			return (
				<StreamPlayer
					type={
						item.media?.url?.includes('mediadelivery')
							? 'iFrame'
							: typeof item.media === 'string' && item.media?.startsWith('data:video')
							? 'local'
							: 'upload'
					}
					url={typeof item.media === 'string' ? item.media : item.media.url}
					containerStyle={getDefaultAspectRatioStyle(width)}
					isAutoplay={false}
					isMute={true}
					isLoop={false}
					onPlay={(e) => {
						if (onPlay) {
							onPlay();
						}
						trackAction('feed', 'Play Video', `${item.id}`);
					}}
					onPause={(e) => trackAction('feed', 'Stop Video', `${item.id}`)}
					onExternalClick={() => trackAction('feed', 'Open External URL', `${item.id}`)}
				/>
			);
		}

		if (
			item?.media?.mime?.includes('image') ||
			(typeof item.media === 'string' && !item.media.endsWith('mp4')) ||
			typeof item === 'number' ||
			(typeof item === 'object' && typeof item.media === 'number') ||
			item?.media?.url
		) {
			const imageSrc =
				typeof item.media === 'string'
					? item.media
					: typeof item === 'number'
					? item
					: item?.media?.url
					? item?.media?.url
					: typeof item === 'object' && typeof item.media === 'number'
					? _getMediaFromLibrary(item.media)?.url
					: '';

			return (
				<ChildButton
					testID={`newsmediaitem_${item.id}`}
					onPress={() => {
						if (openImageInNewTab) {
							openURL(imageSrc);
						}
					}}
					style={IS_WEB ? webCursorStyle : {}}
				>
					<Image
						testID={`newsmediaitem_image`}
						url={imageSrc}
						style={{
							...getDefaultAspectRatioStyle(width),
							...getTopRadiusStyle(hsBorderRadius),
							borderBottomLeftRadius: isRounded ? hsBorderRadius : 0,
							borderBottomRightRadius: isRounded ? hsBorderRadius : 0
						}}
						resizeMode="cover"
						expectedRatio={16 / 9}
						imageSize={IS_WEB ? 'full' : 'small'}
						mediaObj={typeof item?.media === 'object' ? item?.media : undefined}
					/>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderButton = () => {
		if (onPress) {
			return (
				<ChildButton
					testID={`newsmediaitem_${item.id}`}
					isDisabled={!onPress}
					onPress={onPress}
					activeOpacity={1}
					style={{ ...getDefaultAspectRatioStyle(width), position: 'absolute' }}
				/>
			);
		}
	};

	if (item && width) {
		return (
			<View style={{ ...getDefaultAspectRatioStyle(width), position: 'relative' }}>
				{_renderImageOrVideo()}
				{_renderButton()}
			</View>
		);
	}

	return null;
};
