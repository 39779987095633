import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsBottomMargin, hsTextBottomMargin, hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useSelector } from 'react-redux';
import { IMap } from 'config/interfaces';
import i18next from 'i18next';
import { FormLabel } from 'components/Form/FormLabel';
import { ChildButton, RoundButton } from 'components/Button';
import { FormReferenceSelect, FormTextInput } from 'components/Form';
import { FormHint } from 'components/Form/FormHint';
import { Image } from 'components/Image';
import { HSCard } from 'components/Card';
import { v4 } from 'uuid';
import { Text } from 'components/Text';
import { Dropdown } from 'components/Form/Dropdown';
import { debounce } from 'lodash';
import { showToast } from 'helper/toast';
import { SearchBar } from 'components/SearchBar';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MapPositionsEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MapPositionsEdit>;
type RouteParams = StackParamList[ERoutes.MapPositionsEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mappositionsedit';

interface ITempPosition {
	id?: number;
	key?: string;
	title: string;
	map: number;
	x1?: number;
	y1?: number;
	x2?: number;
	y2?: number;
	contentType?: string;
	itemId?: number;
	expo?: number;
	stage?: number;
	meetingtable?: number;
}

export const MapPositionsEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const { screenWidth } = useQuery();
	const imageRef = useRef<View>(null);

	const [map, setMap] = useState<IMap | undefined>(undefined);
	const [mapPositions, setMapPositions] = useState<ITempPosition[]>([]);
	const [searchedPositions, setSearchedPositions] = useState<ITempPosition[]>([]);
	const [addedPositions, setAddedPositions] = useState<ITempPosition[]>([]);
	const [updatedPositions, setUpdatedPositions] = useState<ITempPosition[]>([]);
	const [deletedPositions, setDeletedPositions] = useState<ITempPosition[]>([]);
	const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
	const [deeplinkType, setDeepLinkType] = useState<string | undefined | null>(undefined);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [imageRatio, setImageRatio] = useState<number>(1);

	const [tempPosition, _setTempPosition] = useState<ITempPosition | undefined>(undefined);
	const [isInEditMode, setIsInEditMode] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateMapPositions = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMapPositions);

	useEffect(() => {
		let _map: typeof map = undefined;
		let _positions: typeof mapPositions = [];

		if (activeSpace && content.maps) {
			_map = content.maps.find((e) => e.id === route.params.id && e.spaceId === activeSpace.spaceId);
		}

		if (activeSpace && _map && content.mappositions) {
			_positions = content.mappositions
				.filter((e) => e.spaceId === activeSpace.spaceId && e.map?.id === route.params?.id)
				.map((e) => {
					return {
						...e,
						map: e.map?.id,
						stage: e.stage?.id,
						expo: e.expo?.id,
						meetingtable: e.meetingtable?.id
					};
				});
		}

		setMap(_map);
		setMapPositions(_positions);
	}, [activeSpace, content]);

	useEffect(() => {
		let _searched = [...mapPositions];
		if (searchTerm.length >= 3) {
			if (mapPositions.length > 0) {
				_searched = mapPositions.filter((e) => e.title.toLowerCase().includes(searchTerm.toLowerCase()));
			}
		}
		setSearchedPositions(_searched);
	}, [mapPositions, searchTerm]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMapPositions(),
			isLoading,
			isDisabled: tempPosition !== undefined
		});
	}, [isLoading, mapPositions, addedPositions, updatedPositions, deletedPositions, tempPosition]);

	const debouncedSave = useCallback(
		debounce((value) => _setTempPosition(value), 10),
		[]
	);

	const setTempPosition = (value) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		debouncedSave(value);
	};

	const _updateMapPositions = async () => {
		setIsLoading(true);
		await updateMapPositions({ mapId: route.params.id, addedPositions, updatedPositions, deletedPositions });
		setIsLoading(false);
		navigation.setParams({ prohibitNavigation: false });
		setAddedPositions([]);
		setUpdatedPositions([]);
		setDeletedPositions([]);
		navigation.goBack();
	};

	const _renderInfoText = () => {
		return (
			<View style={{ marginBottom: hsTextBottomMargin }}>
				<Text>{IS_WEB ? t('New Map Positions Hint Web') : t('New Map Positions Hint Mobile')}</Text>
			</View>
		);
	};

	const _renderFilter = () => {
		if (mapPositions.length >= 5) {
			return (
				<View style={{ marginBottom: hsBottomMargin / 2 }}>
					<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
				</View>
			);
		}

		return null;
	};

	const _renderCoordinateInput = (key: string, label: string, value?: number) => {
		return (
			<FormTextInput
				testID={`${TESTIDPREFIX}_formtextinput_position${key}`}
				label={label}
				isRequired
				isDisabled
				formStyle={{ width: '45%', marginBottom: 10 }}
				value={value?.toString()}
			/>
		);
	};

	const _renderTempPositionForm = () => {
		if (tempPosition) {
			return (
				<View>
					<View
						style={{
							width: '95%',
							height: 1,
							alignSelf: 'center',
							marginTop: addedPositions.length > 0 || mapPositions.length > 0 || updatedPositions.length > 0 ? 10 : 0,
							marginBottom: 10,
							backgroundColor: theme.lightgray
						}}
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_formtextinput_positiontitle`}
						label={t('title')}
						isRequired
						value={tempPosition.title}
						onChangeText={(title) => setTempPosition({ ...tempPosition, title })}
						hint={t('MapPositionTitleHint')}
						formStyle={{ marginBottom: 10 }}
					/>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						{_renderCoordinateInput('x1', 'X1', tempPosition.x1)}
						{_renderCoordinateInput('y1', 'Y1', tempPosition.y1)}
					</View>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						{_renderCoordinateInput('x2', 'X2', tempPosition.x2)}
						{_renderCoordinateInput('y2', 'Y2', tempPosition.y2)}
					</View>
					<FormHint testID="tempposition_coord_hint" hint="Alle Koordinaten werden automatisch gesetzt" />
					<FormLabel testID="tempposition_type_label" label={t('Link')} style={{ marginBottom: 5 }} />
					<FormHint testID="tempposition_type_hint" hint={t('InternalLinkingTypeHint')} />
					<Dropdown
						testID={`${TESTIDPREFIX}_dropdown_deeplinktype`}
						value={deeplinkType}
						onSelect={(deeplinkType) => setDeepLinkType(deeplinkType)}
						placeholder={t('DeeplinkSelectType')}
						options={[
							{
								key: 'expos',
								label: t('Expo')
							},
							{
								key: 'stages',
								label: t('Stage')
							},
							{
								key: 'meetingtables',
								label: t('Meeting Tables')
							}
						].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
						formStyle={{ marginBottom: 0 }}
					/>
					{deeplinkType && (
						<FormReferenceSelect
							testID={`${TESTIDPREFIX}_referenceselect_deeplinkitem`}
							value={
								deeplinkType === 'expos'
									? tempPosition.expo
									: deeplinkType === 'stages'
									? tempPosition.stage
									: deeplinkType === 'meetingtables'
									? tempPosition.meetingtable
									: undefined
							}
							placeholder={t('Choose Entry')}
							hint={t('InternalLinkingEntryHint')}
							type={deeplinkType}
							onSelect={(deeplinkItemId) => {
								const _pos = { ...tempPosition };
								switch (deeplinkType) {
									case 'expos':
										_pos.meetingtable = undefined;
										_pos.stage = undefined;
										_pos.expo = deeplinkItemId;
										_pos.title = content.expos?.find((expo) => expo.id === deeplinkItemId)?.title ?? '';
										break;
									case 'stages':
										_pos.expo = undefined;
										_pos.meetingtable = undefined;
										_pos.stage = deeplinkItemId;
										_pos.title = content.stages?.find((stage) => stage.id === deeplinkItemId)?.title ?? '';
										break;
									case 'meetingtables':
										_pos.expo = undefined;
										_pos.stage = undefined;
										_pos.meetingtable = deeplinkItemId;
										break;
									default:
										break;
								}
								setTempPosition(_pos);
							}}
							formStyle={{ marginBottom: 0 }}
						/>
					)}
				</View>
			);
		}

		return null;
	};

	const _renderPosition = (position: ITempPosition) => {
		const { x1, y1, x2, y2 } = position;

		if (x1 && y1 && x2 && y2) {
			return (
				<ChildButton
					key={`${x1}_${y1}_${x2}_${y2}`}
					testID={`${x1}_${y1}_${x2}_${y2}`}
					style={{
						position: 'absolute',
						left: (x1 / 100) * containerWidth,
						top: ((y1 / 100) * containerWidth) / imageRatio,
						width: ((x2 - x1) / 100) * containerWidth,
						height: (((y2 - y1) / 100) * containerWidth) / imageRatio,
						borderColor: theme.danger,
						borderWidth: 2
					}}
					onPress={() => setTempPosition({ ...position })}
					isDisabled={tempPosition !== undefined}
				/>
			);
		}
	};

	const _renderTempPosition = () => {
		if (tempPosition) {
			if (tempPosition.x1 && tempPosition.y1 && tempPosition.x2 && tempPosition.y2) {
				const corrected = { ...tempPosition };
				if (tempPosition.x2 < tempPosition.x1) {
					corrected.x1 = tempPosition.x2;
					corrected.x2 = tempPosition.x1;
				}
				if (tempPosition.y2 < tempPosition.y1) {
					corrected.y1 = tempPosition.y2;
					corrected.y2 = tempPosition.y1;
				}

				const { x1, y1, x2, y2 } = corrected;

				if (x1 && y1 && x2 && y2) {
					return (
						<View
							style={{
								position: 'absolute',
								left: (x1 / 100) * containerWidth,
								top: ((y1 / 100) * containerWidth) / imageRatio,
								width: ((x2 - x1) / 100) * containerWidth,
								height: (((y2 - y1) / 100) * containerWidth) / imageRatio,
								borderColor: theme.danger,
								borderWidth: 2
							}}
							pointerEvents="none"
						/>
					);
				}
			}
		}

		if (isInEditMode) {
			return null;
		}

		let _pos = mapPositions.filter((pos) => {
			const inUpdate = updatedPositions.find((e) => e.id === pos.id);
			const inDelete = deletedPositions.find((e) => e.id === pos.id);

			return !inUpdate && !inDelete;
		});
		_pos = _pos.concat(addedPositions).concat(updatedPositions);

		return <>{_pos.map((pos) => _renderPosition(pos))}</>;
	};

	const _renderPositionInfo = (pos: ITempPosition, idx: number, type: string) => {
		let posType = pos.expo ? t('Expo') : pos.stage ? t('Stage') : pos.meetingtable ? t('Meeting Table') : undefined;

		const isDeleted =
			(!pos.expo && !pos.meetingtable && !pos.stage) ||
			content?.mappositions?.find(
				(mappos) =>
					mappos.spaceId === activeSpace?.spaceId &&
					mappos.id === pos.id &&
					((pos.expo && mappos.expo?.isDeleted) ||
						(pos.meetingtable && mappos.meetingtable?.isDeleted) ||
						(pos.stage && mappos.stage?.isDeleted))
			);
		return (
			<View key={`position_info_${idx}`} style={{ flexDirection: 'row', alignItems: 'center' }}>
				<View style={{ flex: 1, flexDirection: 'row' }}>
					<Text>{pos.title}</Text>
					{!isEmptyString(posType) && <Text style={{ opacity: 0.75 }}>{` (${posType})`}</Text>}
					{isDeleted && <Text style={{ color: theme.danger }}>{` (${t('Not available')})`}</Text>}
				</View>
				<View style={{ flexDirection: 'row' }}>
					<RoundButton
						testID={`position_info_${idx}_edit`}
						icon={EDefaultIconSet.Edit}
						isDisabled={tempPosition !== undefined}
						onPress={() => {
							setTempPosition({ ...pos });
							if (pos.expo) {
								setDeepLinkType('expos');
							} else if (pos.stage) {
								setDeepLinkType('stages');
							} else if (pos.meetingtable) {
								setDeepLinkType('meetingtables');
							}
						}}
						size="sm"
					/>
					<RoundButton
						testID={`position_info_${idx}_delete`}
						icon={EDefaultIconSet.Delete}
						isDisabled={tempPosition !== undefined}
						onPress={() => {
							showAlert({
								title: t('DeletePosition'),
								message: t('DeletePositionSubtitle'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('DeletePosition'),
										style: 'destructive',
										onPress: async () => {
											if (!route.params.prohibitNavigation) {
												navigation.setParams({
													prohibitNavigation: true
												});
											}

											switch (type) {
												case 'old':
													const _del = [...deletedPositions];
													_del.push(pos);
													setDeletedPositions(_del);
													break;
												case 'new':
													const _new = [...addedPositions].filter((e) => e.key !== pos.key);
													setAddedPositions(_new);
													break;
												case 'updated':
													const _updated = [...updatedPositions].filter((e) =>
														pos.id ? e.id !== pos.id : e.key !== pos.key
													);

													setUpdatedPositions(_updated);
													break;
											}
										}
									}
								]
							});
						}}
						color={theme.danger}
						size="sm"
					/>
				</View>
			</View>
		);
	};

	const _renderOldPositions = () => {
		let _pos = [...searchedPositions];

		_pos = _pos.filter((pos) => {
			const inUpdate = updatedPositions.find((e) => e.id === pos.id);
			const inDelete = deletedPositions.find((e) => e.id === pos.id);

			return !inUpdate && !inDelete;
		});

		_pos = _pos.sort((a, b) => a.title.localeCompare(b.title));

		if (!isEmptyString(searchTerm) || _pos.length > 0) {
			return (
				<View>
					<FormLabel testID="positions" label={t('Positions')} style={{ marginBottom: hsTextBottomMarginSmall }} />
					{_renderFilter()}
					{_pos.map((position, idx) => _renderPositionInfo(position, idx, 'old'))}
				</View>
			);
		}

		return null;
	};

	const _renderNewPositions = () => {
		if (addedPositions.length > 0) {
			return (
				<View>
					<FormLabel testID="addedpositions" label={t('New Map Positions')} />
					{addedPositions.map((position, idx) => _renderPositionInfo(position, idx, 'new'))}
				</View>
			);
		}

		return null;
	};

	const _renderUpdatedPositions = () => {
		if (updatedPositions.length > 0) {
			return (
				<View>
					<FormLabel testID="updatedpositions" label={t('Changed Map Positions')} />
					{updatedPositions.map((position, idx) => _renderPositionInfo(position, idx, 'updated'))}
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (!map) {
			return null;
		}

		if (!map?.image || !map?.image?.height || !map?.image?.width) {
			return null;
		}

		return (
			<View>
				<HSCard style={{ width: screenWidth, alignSelf: 'center' }}>
					{_renderInfoText()}
					{_renderOldPositions()}
					{_renderNewPositions()}
					{_renderUpdatedPositions()}
					{_renderTempPositionForm()}
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							marginTop: 10,
							justifyContent: tempPosition ? 'space-between' : 'flex-end'
						}}
					>
						{tempPosition && (
							<RoundButton
								testID={`${TESTIDPREFIX}_button_cancel`}
								icon={EDefaultIconSet.Close}
								title={t('Cancel')}
								isFloatingButton
								onPress={() => {
									setIsInEditMode(false);
									setTempPosition(undefined);
								}}
								size="sm"
							/>
						)}
						<RoundButton
							isFloatingButton
							testID={`${TESTIDPREFIX}_button_addposition`}
							icon={tempPosition ? EDefaultIconSet.Save : EDefaultIconSet.Add}
							title={tempPosition ? t('Save') : t('Add')}
							size="sm"
							isDisabled={
								tempPosition &&
								(isEmptyString(tempPosition.title) ||
									!tempPosition.x1 ||
									!tempPosition.x2 ||
									!tempPosition.y1 ||
									!tempPosition.y2)
							}
							onPress={() => {
								if (tempPosition) {
									setIsInEditMode(false);
									if (tempPosition.key) {
										const _pos = [...addedPositions];
										const idx = _pos.findIndex((e) => e.key === tempPosition.key);
										_pos[idx] = tempPosition;
										setAddedPositions(_pos);
									} else if (tempPosition.id) {
										const idx = updatedPositions.findIndex((e) => e.id === tempPosition.id);
										if (idx !== -1) {
											const _pos = [...updatedPositions];
											_pos[idx] = tempPosition;
											setUpdatedPositions(_pos);
										} else {
											setUpdatedPositions([...updatedPositions, tempPosition]);
										}
									} else {
										const _pos = [...addedPositions];
										_pos.push({ ...tempPosition, key: v4() });
										setAddedPositions(_pos);
									}
									if (!route.params?.prohibitNavigation) {
										navigation.setParams({ prohibitNavigation: true });
									}
									setTempPosition(undefined);
								} else {
									setIsInEditMode(true);
									setTempPosition({
										title: '',
										map: route.params.id
									});
								}
							}}
						/>
					</View>
				</HSCard>
				<View style={{ marginTop: 20 }} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
					<ScrollView>
						<ScrollView horizontal>
							<ChildButton
								activeOpacity={1}
								testID={`${TESTIDPREFIX}_button_map`}
								onPress={async (e) => {
									if (IS_WEB) {
										return;
									}
									if (tempPosition) {
										const { x, y } = await new Promise((resolve) => {
											imageRef.current?.measureInWindow((x, y) => {
												resolve({ x, y });
											});
										});
										const percentX = ((e.clientX - x) / containerWidth) * 100;
										const percentY = ((e.clientY - y) / (containerWidth / imageRatio)) * 100;
										if (!tempPosition.x1 || !tempPosition.y1) {
											setTempPosition({ ...tempPosition, x1: percentX, y1: percentY });
										} else if (!tempPosition.x2 || !tempPosition.y2) {
											setTempPosition({ ...tempPosition, x2: percentX, y2: percentY });
										} else {
											setTempPosition({
												...tempPosition,
												x1: percentX,
												y1: percentY,
												x2: undefined,
												y2: undefined
											});
										}
									}
								}}
								onMouseDown={async (e) => {
									if (!IS_WEB) {
										return;
									}
									if (tempPosition) {
										setIsMouseDown(true);
										const { x, y } = await new Promise((resolve) => {
											imageRef.current?.measureInWindow((x, y) => {
												resolve({ x, y });
											});
										});
										const percentX = ((e.clientX - x) / containerWidth) * 100;
										const percentY = ((e.clientY - y) / (containerWidth / imageRatio)) * 100;

										if (!route.params?.prohibitNavigation) {
											navigation.setParams({ prohibitNavigation: true });
										}
										_setTempPosition({
											...tempPosition,
											x1: percentX,
											y1: percentY,
											x2: undefined,
											y2: undefined
										});
									}
								}}
								onMouseMove={async (e) => {
									if (tempPosition && isMouseDown) {
										const { x, y } = await new Promise((resolve) => {
											imageRef.current?.measureInWindow((x, y) => {
												resolve({ x, y });
											});
										});
										const percentX = ((e.clientX - x) / containerWidth) * 100;
										const percentY = ((e.clientY - y) / (containerWidth / imageRatio)) * 100;

										setTempPosition({ ...tempPosition, x2: percentX, y2: percentY });
									}
								}}
								onMouseUp={(e) => {
									if (tempPosition) {
										const corrected = { ...tempPosition };

										if (tempPosition.x1 && tempPosition.x2 && tempPosition.x2 < tempPosition.x1) {
											corrected.x1 = tempPosition.x2;
											corrected.x2 = tempPosition.x1;
										}
										if (tempPosition.y1 && tempPosition.y2 && tempPosition.y2 < tempPosition.y1) {
											corrected.y1 = tempPosition.y2;
											corrected.y2 = tempPosition.y1;
										}
										setTempPosition(corrected);
									}
									setIsMouseDown(false);
								}}
								isDisabled={!tempPosition}
							>
								<View ref={imageRef} style={{ marginBottom: hsBottomMargin }}>
									<Image
										onLoad={(e) => setImageRatio(e.source.width / e.source.height)}
										style={{ width: containerWidth, height: containerWidth / imageRatio }}
										mediaObj={map.image}
										imageSize="full"
									/>
								</View>
							</ChildButton>
							{_renderTempPosition()}
						</ScrollView>
					</ScrollView>
				</View>
			</View>
		);
	};
	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="maps">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				{_renderContent()}
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MapPositionsEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Positions')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={() => {
					if (props.options.isDisabled) {
						showToast('info', i18next.t('SaveTempPosition'), i18next.t('SaveTempPositionSubtitle'));
					} else {
						props.options.onRightNavPress();
					}
				}}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
