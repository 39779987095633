import React from 'react';
import { H2, Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { HSModal } from '../Modal';
import { RoundButton } from 'components/Button';
import { openURL } from 'helper';
import { View } from 'react-native';
import { hsBottomMargin } from 'config/styleConstants';

interface INetworkingRoomHelpModal {
	isVisible: boolean;
	onClose: () => void;
}

export const NetworkingRoomHelpModal = (props: INetworkingRoomHelpModal) => {
	const { isVisible, onClose } = props;
	const { t } = useTranslation();

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title={t('NetworkingHelpInstructionsHeader')}>
			<View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', marginBottom: hsBottomMargin }}>
				<RoundButton
					icon={'FontAwesome:chrome'}
					testID={''}
					title="Chrome"
					onPress={() => openURL('https://support.google.com/chrome/answer/2693767')}
				/>
				<RoundButton
					icon={'FontAwesome5Brands:firefox'}
					testID={''}
					title="Firefox"
					onPress={() =>
						openURL('https://support.mozilla.org/de/kb/kamera-und-mikrofonberechtigungen-verwalten#firefox:linux:fx107')
					}
				/>
				<RoundButton
					icon={'MaterialCommunityIcons:microsoft-edge'}
					testID={''}
					title="Edge"
					onPress={() =>
						openURL(
							'https://support.microsoft.com/de-de/windows/verwalten-von-app-berechtigungen-f%C3%BCr-ihre-kamera-in-windows-87ebc757-1f87-7bbf-84b5-0686afb6ca6b'
						)
					}
				/>
				<RoundButton
					icon={'FontAwesome5Brands:safari'}
					testID={''}
					title="Safari"
					onPress={() => openURL('https://support.apple.com/de-de/guide/safari/ibrwe2159f50/mac')}
				/>
			</View>
			<H2>{t('NetworkingHelpInstructionsBody1')}</H2>
			<Text marginBottom>{t('NetworkingHelpInstructionsBody2')}</Text>
			<Text marginBottom>{t('NetworkingHelpInstructionsBody3')}</Text>
			<Text marginBottom>{t('NetworkingHelpInstructionsBody4')}</Text>
		</HSModal>
	);
};
