import {
	IAttendee,
	IContentTypeField,
	ILauncherSpace,
	IMapPosition,
	IMedia,
	IMediaGalleryItem,
	IMediaItem,
	ISchedule,
	ISpeaker,
	IStrapiBase,
	TSelfServiceEditable
} from '.';

export interface IExpo extends IStrapiBase {
	title: string;
	spaceId: string;
	logo?: IMedia;
	contactEmail?: string;
	contactName?: string;
	contactPhone?: string;
	street?: string;
	zip?: string;
	isPinned?: boolean;
	city?: string;
	country?: string;
	description?: string;
	website?: string;
	catchphrase?: string;
	selfServiceToken?: string;
	facebookProfileUrl?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	tikTokProfileUrl?: string;
	youTubeProfileUrl?: string;
	speakers?: ISpeaker[];
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	selfServiceEditable: TSelfServiceEditable;
	selfServiceEmail?: string;
	sponsorType?: TSponsorType;
	sponsorOrder?: number;
	sponsorBanner?: IMedia;
	mediaitems?: IMediaItem[];
	attendees?: IAttendee[];
	imageGallery?: IMediaGalleryItem[];
	location?: string;
	mappositions?: IMapPosition[];
	schedules?: ISchedule[];
	isHidden?: boolean;
}

export type TSponsorType = 'none' | 'sponsor' | 'sponsorOnly';

export interface IDownload {
	id?: number;
	title?: string;
	downloadPreview?: IMedia;
	downloadFile?: IMedia;
	downloadUrl?: string;
	description?: string;
	order?: number;
}

export interface ISponsorBlock {
	id: 0;
	title: 'SponsorBlock';
}
export interface ICreateExpoFormValues {
	title: string;
	logo?: IMedia;
	contactEmail?: string;
	contactName?: string;
	contactPhone?: string;
	street?: string;
	zip?: string;
	isPinned?: boolean;
	city?: string;
	country?: string;
	description?: string;
	website?: string;
	catchphrase?: string;
	selfServiceToken?: string;
	facebookProfileUrl?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	tikTokProfileUrl?: string;
	youTubeProfileUrl?: string;
	speakers?: number[];
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	selfServiceEditable: TSelfServiceEditable;
	sponsorType?: TSponsorType;
	sponsorOrder?: number;
	sponsorBanner?: IMedia;
	mediaitems?: number[];
	attendees?: number[];
	imageGallery?: IMediaGalleryItem[];
	location?: string;
	schedules?: number[];
	isHidden?: boolean;
}

export interface IUpdateExpoFormValues {
	id?: number;
	title: string;
	logo?: IMedia;
	contactEmail?: string;
	contactName?: string;
	contactPhone?: string;
	street?: string;
	zip?: string;
	isPinned?: boolean;
	city?: string;
	country?: string;
	description?: string;
	website?: string;
	catchphrase?: string;
	selfServiceToken?: string;
	facebookProfileUrl?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	tikTokProfileUrl?: string;
	youTubeProfileUrl?: string;
	speakers?: number[];
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	selfServiceEditable: TSelfServiceEditable;
	sponsorType?: TSponsorType;
	sponsorOrder?: number;
	sponsorBanner?: IMedia;
	mediaitems?: number[];
	attendees?: number[];
	imageGallery?: IMediaGalleryItem[];
	location?: string;
	mappositions?: number[];
	schedules?: number[];
	isHidden?: boolean;
	updated_at?: string;
}

export interface ICreateExpoFormErrors {
	title?: string;
	logo?: string;
	contactEmail?: string;
	contactName?: string;
	contactPhone?: string;
	street?: string;
	zip?: string;
	city?: string;
	country?: string;
	description?: string;
	website?: string;
	catchphrase?: string;
	selfServiceToken?: string;
	facebookProfileUrl?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	tikTokProfileUrl?: string;
	youTubeProfileUrl?: string;
	speakers?: string;
	checkbox1?: string;
	checkbox2?: string;
	checkbox3?: string;
	checkbox4?: string;
	checkbox5?: string;
	checkbox6?: string;
	checkbox7?: string;
	checkbox8?: string;
	checkbox9?: string;
	checkbox10?: string;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	sponsorType?: string;
	sponsorOrder?: string;
	sponsorBanner?: string;
	mediaitems?: string;
	attendees?: string;
	imageGallery?: string[];
	location?: string;
	schedules?: string;
	isHidden?: string;
}

export interface IUpdateExpoFormErrors {
	title?: string;
	logo?: string;
	contactEmail?: string;
	contactName?: string;
	contactPhone?: string;
	street?: string;
	zip?: string;
	city?: string;
	country?: string;
	description?: string;
	website?: string;
	catchphrase?: string;
	selfServiceToken?: string;
	facebookProfileUrl?: string;
	linkedInProfileUrl?: string;
	xingProfileUrl?: string;
	twitterProfileUrl?: string;
	instagramProfileUrl?: string;
	blogUrl?: string;
	tikTokProfileUrl?: string;
	youTubeProfileUrl?: string;
	speakers?: string;
	checkbox1?: string;
	checkbox2?: string;
	checkbox3?: string;
	checkbox4?: string;
	checkbox5?: string;
	checkbox6?: string;
	checkbox7?: string;
	checkbox8?: string;
	checkbox9?: string;
	checkbox10?: string;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	category1?: string;
	category2?: string;
	category3?: string;
	category4?: string;
	category5?: string;
	category6?: string;
	category7?: string;
	category8?: string;
	category9?: string;
	category10?: string;
	selfServiceEditable?: string;
	sponsorType?: string;
	sponsorOrder?: string;
	sponsorBanner?: string;
	mediaitems?: string;
	attendees?: string;
	imageGallery?: string[];
	location?: string;
	schedules?: string;
	isHidden?: string;
}

export interface ICreateExpoPayload {
	space?: ILauncherSpace;
	expo: ICreateExpoFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateExpoPayload {
	expo: IExpo | IUpdateExpoFormValues;
	fields: IContentTypeField[];
	isDeletion?: boolean;
}

export const getCreateExpoInitialFormValues = (isSponsor: boolean) => {
	const values: ICreateExpoFormValues = {
		title: '',
		speakers: [],
		attendees: [],
		mediaitems: [],
		imageGallery: [],
		schedules: [],
		selfServiceEditable: 'yes',
		sponsorType: isSponsor ? 'sponsor' : 'none'
	};

	return values;
};
