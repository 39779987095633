import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderEditButton,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { IFeatureInfo } from 'config/interfaces';
import { View } from 'react-native';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { HSWebView } from 'components/WebView';
import { IS_WEB } from 'helper';
import { TABBAR_HEIGHT } from 'config/constants';
import { IRootState } from 'rematch/store';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Web>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Web>;
type RouteParams = StackParamList[ERoutes.Web];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'webscreen';

export const WebScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();
	const { screenWidth } = useQuery();

	const [feature, setFeature] = useState<IFeatureInfo | undefined>(undefined);

	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		let _feature: typeof feature = undefined;

		if (activeSpace?.features) {
			_feature = activeSpace.features?.list.find((e) => e.key === route.params.key);
		}

		navigation.setOptions({
			title: _feature?.label ?? ''
		});

		setFeature(_feature);
	}, [activeSpace]);

	const _renderWebview = () => {
		if (feature && feature.iFrameUrl) {
			return (
				<HSWebView
					testIdPrefix={`${TESTIDPREFIX}_${feature.label?.replace(/\s/g, '_')}`}
					src={feature.iFrameUrl}
					iFrameOptions={'accelerometer; fullscreen; gyroscope; autoplay; encrypted-media; picture-in-picture'}
					rounded
					style={{ paddingBottom: hsBottomMargin }}
				/>
			);
		}
		return null;
	};

	return (
		<ScreenContainer>
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				{_renderWebview()}
			</View>
		</ScreenContainer>
	);
};

export const WebScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.EditFeature} id={params?.key} paramType={'key'} />
		</NavigationHeader>
	);
};
export const WebScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.EditFeature} id={params?.key} paramType={'key'} />
		</NavigationHeader>
	);
};
