import React, { useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IToggleLikeParams } from 'rematch/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ILikeObject } from 'config/interfaces';
import { hsBorder, hsShadow } from 'config/styleConstants';
import { EDefaultIconSet, getLikeIconByType } from 'helper';
import { useLike } from 'hooks/useLike';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import { RoundButton } from '../Button/RoundButton';
import { useFocusEffect } from '@react-navigation/native';

interface ILikeButton {
	itemId: string;
	testId: string;
	contentType: IToggleLikeParams['contentType'];
	disableAnmiation?: boolean;
	largerDesign?: boolean;
	size?: 'xs' | 'sm';
	isPreview?: boolean;
	isLoading?: boolean;
}

const LIKE_TYPES: IToggleLikeParams['type'][] = ['applause', 'funny', 'inspiring', 'love', 'thoughtful', 'like'];

const LOWVALUE = 0.3;
const HIGHVALUE = 1;
const ANIMATIONDURATION = 600;
const ANIMATIONINITIALDELAY = 2500;

export const LikeButton = (props: ILikeButton) => {
	const { itemId, testId, disableAnmiation, largerDesign, size, isPreview, isLoading, contentType } = props;
	const { getLikesForItem } = useLike();
	const { theme } = useTheme();
	const { trackAction } = useTracker();

	const animatedOpacityValue = useRef(new Animated.Value(1));

	const [animation, setAnimation] = useState<Animated.CompositeAnimation | undefined>(undefined);
	const [isLikeLoading, setIsLikeLoading] = useState<boolean>(false);
	const [isLikedByMe, setIsLikedByMe] = useState<ILikeObject | undefined>(undefined);
	const [isAnimating, setIsAnimating] = useState<boolean>(false);
	const [isLikeOptionsVisible, setIsLikeOptionsVisible] = useState<boolean>(false);
	const [likedBy, setLikedBy] = useState<ILikeObject[]>([]);
	const [hasPreviewLike, setHasPreviewLike] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const likes = useSelector((store: IRootState) => store.like.likes);
	const isLikeBackdropVisible = useSelector((store: IRootState) => store.like.isLikeBackdropVisible);

	const toggleLike = useRematchDispatch((dispatch: Dispatch) => dispatch.like.toggleLike);
	const setIsLikeBackdropVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.like.setIsLikeBackdropVisible);

	useEffect(() => {
		if (process.env.IS_TEST || disableAnmiation) {
			return;
		}
		const _animation = Animated.loop(
			Animated.sequence([
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					delay: ANIMATIONINITIALDELAY,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				})
			])
		);
		setAnimation(_animation);
	}, []);

	useEffect(() => {
		const _likedByMe = likedBy?.find((l) => l.id === userInfos.userId);
		setIsLikedByMe(_likedByMe);
	}, [likedBy, hasPreviewLike, isPreview]);

	useEffect(() => {
		let res: ILikeObject[] = [];
		if (isPreview) {
			setLikedBy(Array(Math.floor(Math.random() * 10)));
		} else {
			res = getLikesForItem(itemId, contentType);
			setLikedBy(res);
		}

		if (animation) {
			if (likedBy?.find((l) => l.id === userInfos.userId)) {
				animation.stop();
				Animated.timing(animatedOpacityValue.current, {
					toValue: 1,
					duration: 100,
					useNativeDriver: true
				}).start();
				setIsAnimating(false);
			} else if (!isAnimating) {
				animation.start();
				setIsAnimating(true);
			}
		}
	}, [itemId, likes]);

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				setIsLikeOptionsVisible(false);
			};
		}, [])
	);

	const _toggleLike = async (type: IToggleLikeParams['type']) => {
		if (isPreview) {
			setHasPreviewLike(!hasPreviewLike);
		} else {
			if (isLikeOptionsVisible) {
				setIsLikeOptionsVisible(false);
			}
			setIsLikeLoading(true);
			const res = await toggleLike({ contentType: contentType, itemId: itemId, type: type });
			setIsLikeLoading(false);
			if (res) {
				const hasLiked = res.likes.find((e) => e.contentType === contentType && e.itemId === itemId)?.value;
				trackAction(contentType, hasLiked ? 'Like' : 'Un-Like', itemId);
			}
		}
	};

	useEffect(() => {
		if (isLikeOptionsVisible) {
			setIsLikeBackdropVisible(isLikeOptionsVisible);
		}
	}, [isLikeOptionsVisible]);

	useEffect(() => {
		if (!isLikeBackdropVisible) {
			setIsLikeOptionsVisible(false);
		}
	}, [isLikeBackdropVisible]);

	const _renderLikeOptions = () => {
		if (isLikeOptionsVisible) {
			return (
				<Animated.View
					style={{
						position: 'absolute',
						flexDirection: 'row',
						top: -50,
						left: -150,
						zIndex: 10,
						borderWidth: 1,
						borderColor: theme.text,
						...hsBorder,
						...hsShadow,
						backgroundColor: theme.contentBackgroundColor ?? theme.background
					}}
				>
					{LIKE_TYPES.map((type: IToggleLikeParams['type']) => {
						return (
							<RoundButton
								isOutline={isLikedByMe?.type !== type}
								key={`likeoptions_${type}`}
								testID={`likeoptions_${type}`}
								icon={getLikeIconByType(type)}
								onPress={() => _toggleLike(type)}
								size="sm"
							/>
						);
					})}
				</Animated.View>
			);
		}

		return null;
	};

	return (
		<View style={{ overflow: 'visible' }}>
			<Animated.View style={{ opacity: animatedOpacityValue.current }}>
				<RoundButton
					testID={`${testId}_button_togglelike_${itemId}`}
					onPress={() => {
						if (isLikeOptionsVisible) {
							setIsLikeOptionsVisible(false);
						} else if (isLikedByMe) {
							_toggleLike(isLikedByMe.type);
						} else {
							_toggleLike('like');
						}
					}}
					onLongPress={() => {
						setIsLikeOptionsVisible(true);
					}}
					isLoading={isLikeLoading || isLoading}
					icon={isLikedByMe ? getLikeIconByType(isLikedByMe.type) : EDefaultIconSet.ThumbUp}
					isOutline={!isLikedByMe}
					badge={likedBy.length}
					boldBadge={isLikedByMe}
					size={size}
					largerDesign={largerDesign}
				/>
			</Animated.View>
			{_renderLikeOptions()}
		</View>
	);
};
