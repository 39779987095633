import React, { useState } from 'react';
import { RoundButton } from 'components/Button';
import { CardSeparationHeader, H1, WelcomeHint } from 'components/Text';
import { ICreateSpaceFormValues, ILauncherSpace } from 'config/interfaces';
import { EDefaultIconSet, getCardContainerStyle } from 'helper';
import { useTranslation } from 'react-i18next';
import { FlatList, ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { HSCard } from 'components/Card';
import { useSelector } from 'react-redux';
import { Spinner } from 'components/Spinner';
import { SpacePreviewModal } from 'components/Modal';
import { DEFAULTTHEME } from 'rematch/models/config';
import { SpaceTemplateItem } from './SpaceTemplateItem';

interface ISpaceWizardTemplate {
	testID: string;
	setTempSpace: (val) => void;
	space: ICreateSpaceFormValues;
	stepIndex: number;
	setStepIndex: (val) => void;
	suggestedSpaces: ILauncherSpace[];
	ownSpaces: ILauncherSpace[];
	isSpacesLoading?: boolean;
	isOwnSpacesLoading?: boolean;
}

const screenColumnCount = 2;

export const SpaceWizardTemplate = (props: ISpaceWizardTemplate) => {
	const { testID, setTempSpace, space, stepIndex, setStepIndex, suggestedSpaces, ownSpaces, isSpacesLoading, isOwnSpacesLoading } = props;

	const { t } = useTranslation();
	const { isTabletOrMobile, screenWidth } = useQuery();

	const [previewSpace, setPreviewSpace] = useState<ILauncherSpace | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);

	const emptySpace: ILauncherSpace = {
		spaceId: t('emptySpaceDoPreceedWithoutSettingSpace'),
		title: t('customSpaceTemplate'),
		description: t('customSpaceTemplateDescription'),
		multispaceUrl: '',
		multispaceId: 0,
		startDate: '',
		endDate: '',
		id: 0,
		updated_at: '',
		created_at: '',
		published_at: ''
	};

	const _onSelect = (cloneSpace: ILauncherSpace) => {
		if (cloneSpace.spaceId === t('emptySpaceDoPreceedWithoutSettingSpace')) {
			setStepIndex(stepIndex + 1);
		} else {
			const _space = {
				...cloneSpace,
				cloneSpaceId: cloneSpace.spaceId,
				spaceId: space.spaceId,
				title: space.title,
				language: space.language,
				startDate: space.startDate,
				endDate: space.endDate,
				id: undefined
			};

			setTempSpace(_space);
			setStepIndex(stepIndex + 1);
		}
	};

	const _renderButtons = (cloneSpace: ILauncherSpace) => {
		return (
			<View
				style={{
					flexDirection: isTabletOrMobile ? 'column' : 'row',
					justifyContent: cloneSpace.spaceId === t('emptySpaceDoPreceedWithoutSettingSpace') ? 'flex-end' : 'space-between',
					padding: hsInnerPadding / 2,
					paddingTop: 0,
					flex: 1
				}}
			>
				{cloneSpace.spaceId !== t('emptySpaceDoPreceedWithoutSettingSpace') && (
					<RoundButton
						testID={`${testID}_button_preview_${cloneSpace.spaceId}`}
						title={t('Preview')}
						size={isTabletOrMobile ? 'xs' : undefined}
						icon={EDefaultIconSet.HelloSpaces}
						onPress={() => setPreviewSpace(cloneSpace)}
					/>
				)}

				<RoundButton
					testID={`${testID}_button_select_${cloneSpace.spaceId}`}
					title={t('Select')}
					size={isTabletOrMobile ? 'xs' : undefined}
					icon={EDefaultIconSet.Save}
					onPress={() => _onSelect(cloneSpace)}
				/>
			</View>
		);
	};

	const _renderSuggestedSpaces = () => {
		if (isSpacesLoading) {
			return (
				<HSCard>
					<H1 center>{t('loadingYourSuggestedSpaces')}</H1>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={80} />
					</View>
				</HSCard>
			);
		}

		return (
			<FlatList
				style={{ width: '100%' }}
				contentContainerStyle={{
					width: '100%'
				}}
				numColumns={screenColumnCount}
				data={[emptySpace, ...suggestedSpaces]}
				scrollEnabled={false}
				renderItem={({ item, index }) => (
					<View
						style={{
							...getCardContainerStyle(screenColumnCount, index),
							width: `${100 / screenColumnCount}%`,
							flex: undefined
						}}
					>
						<SpaceTemplateItem
							item={{ space: item, date: '' }}
							childButtonStyle={{ flex: 1 }}
							containerStyle={
								screenColumnCount > 1
									? {
											height: '100%',
											flexGrow: 1
									  }
									: undefined
							}
							children={_renderButtons(item)}
						/>
					</View>
				)}
				keyExtractor={(item) => `spaceclonelist_${item.id}`}
			/>
		);
	};

	const _renderOwnSpaces = () => {
		if (isOwnSpacesLoading) {
			return (
				<HSCard style={{ marginBottom: 0 }}>
					<H1 center>{t('loadingYourTemplateSpaces')}</H1>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={80} />
					</View>
				</HSCard>
			);
		}
		if (ownSpaces?.length > 0) {
			return (
				<FlatList
					style={{ width: '100%' }}
					contentContainerStyle={{
						width: '100%'
					}}
					numColumns={screenColumnCount}
					data={ownSpaces}
					scrollEnabled={false}
					renderItem={({ item, index }) => (
						<View
							style={{
								...getCardContainerStyle(screenColumnCount, index),
								width: `${100 / screenColumnCount}%`,
								flex: undefined
							}}
						>
							<SpaceTemplateItem
								item={{ space: item, date: '' }}
								containerStyle={
									screenColumnCount > 1
										? {
												height: '100%',
												flexGrow: 1
										  }
										: undefined
								}
								children={_renderButtons(item)}
							/>
						</View>
					)}
					keyExtractor={(item) => `spaceclonelist_${item.id}`}
				/>
			);
		}
		return null;
	};

	return (
		<ScrollView
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingVertical: hsBottomMargin
			}}
		>
			<HSCard>
				<H1>{t('cloneSpaceHeader')}</H1>
				<WelcomeHint>{t('cloneSpaceHint')}</WelcomeHint>
			</HSCard>
			<CardSeparationHeader label={t('cloneSuggestedSpaceHeader')} style={{ alignSelf: 'flex-start' }} />
			{_renderSuggestedSpaces()}
			{profile?.adminInSpaces && profile?.adminInSpaces?.length > 0 && (
				<CardSeparationHeader label={t('cloneYourSpaceHeader')} style={{ alignSelf: 'flex-start' }} />
			)}
			{_renderOwnSpaces()}

			<SpacePreviewModal
				isVisible={previewSpace ? true : false}
				space={previewSpace}
				onClose={() => {
					setTheme(DEFAULTTHEME);
					setPreviewSpace(undefined);
				}}
			/>
		</ScrollView>
	);
};
