import React, { useEffect, useState } from 'react';
import { IPushNotification } from 'config/interfaces';
import { View } from 'react-native';
import { Text } from 'components/Text';
import moment from 'moment';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, IS_WEB, normalizeFontSize } from 'helper';
import { Image } from 'components/Image';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { FormCheckbox } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/Icon';
import { hsBorder, hsBorderRadius, hsBottomMargin } from 'config/styleConstants';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { InfoPill } from 'components/InfoPill';

const PushIcon = require('../../../assets/icon.png');

interface IPushNotificationComponent {
	testID: string;
	item: IPushNotification;
	isSelected?: boolean;
	onSelect?: () => void;
	onDelete?: () => void;
	onEdit?: () => void;
	onPress?: (item: IPushNotification) => void;
	isDeleteLoading?: boolean;
}

export const PUSHNOTIFICATION_LISTITEM_HEIGHT = 130;
export const PUSHNOTIFICATION_PUSHICON_SIZE = 20;
const IMAGEWIDTH = 60;

export const PushNotification = (props: IPushNotificationComponent) => {
	const { testID, item, isSelected, onSelect, onDelete, onEdit, onPress, isDeleteLoading } = props;
	const { theme } = useTheme();

	const { t } = useTranslation();

	const [linkedImage, setLinkedImage] = useState<string | undefined>(undefined);
	const [imageRatio, setImageRatio] = useState<number>(1);

	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);

	const getLinkedImage = useRematchDispatch((dispatch: Dispatch) => dispatch.content.getLinkedImage);

	useEffect(() => {
		const _linkedImage: typeof linkedImage = getLinkedImage({ type: item.deeplinkType, itemId: item.deeplinkItemId });

		setLinkedImage(_linkedImage);
	}, [item, content, votes]);

	const _renderBadge = () => {
		let title;

		switch (item.receiver) {
			case 'app':
				title = 'App';
				break;
			case 'web':
				title = 'Web';
				break;
			case 'all':
			default:
				title = 'App & Web';
				break;
		}

		return (
			<InfoPill text={title} backgroundColor={theme.primary} textColor={theme.primaryContrast} containerStyle={{ marginRight: 5 }} />
		);
	};

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isDisabled={isDeleteLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isDeleteLoading}
						/>
					)}
				</View>
			);
		}

		if (onEdit && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	const _renderImage = () => {
		let url;

		if (item.image) {
			url = item.image.formats?.medium?.url ?? item.image.url;
		}

		if (linkedImage) {
			url = linkedImage;
		}

		if (!isEmptyString(url)) {
			return (
				<Image
					url={url}
					style={{ width: IMAGEWIDTH, maxHeight: IMAGEWIDTH, height: IMAGEWIDTH / imageRatio }}
					onLoad={(e) => setImageRatio(e.source.width / e.source.height)}
				/>
			);
		}

		return null;
	};

	const _hasNotificationBeenSent = (notification: IPushNotification) => {
		if (notification.hasBeenSend) {
			return true;
		}
		if (notification.receiver === 'web' && !notification.sendOn) {
			return true;
		}
		return false;
	};

	const _renderSendOn = () => {
		const sendDate = item.sendOn ?? item.created_at;
		const hasBeenSent = _hasNotificationBeenSent(item);

		if (hasBeenSent) {
			return <Text style={{ color: theme.success }}>{`${t('Sent On')}: ${moment(sendDate).format('DD.MM.YYYY HH:mm')}`}</Text>;
		}

		if (item.isActive) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<Text style={{ color: theme.warning, fontSize: normalizeFontSize(12), marginRight: 5 }}>
						{t('PushWillBeSendOn').replace('%DATE%', moment(sendDate).format('DD.MM.YY HH:mm'))}
					</Text>
					<Icon name={EDefaultIconSet.Clock} color={theme.warning} />
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		return (
			<View
				style={{
					borderWidth: 1,
					borderRadius: hsBorderRadius,
					padding: 10
				}}
			>
				<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
					<View style={{ flexDirection: 'row' }}>
						{_renderBadge()}
						<InfoPill
							text={item.isActive ? t('Active') : t('Inactive')}
							backgroundColor={item.isActive ? theme.success : theme.warning}
						/>
					</View>
					{_renderSendOn()}
				</View>

				<Image
					style={{ height: PUSHNOTIFICATION_PUSHICON_SIZE, width: PUSHNOTIFICATION_PUSHICON_SIZE, ...hsBorder, marginBottom: 5 }}
					url={PushIcon}
				/>

				<View style={{ flexDirection: 'row' }}>
					<View style={{ flex: 1, paddingRight: 10 }}>
						{!isEmptyString(item.title) && (
							<Text bold numberOfLines={1}>
								{item.title}
							</Text>
						)}
						<Text numberOfLines={2}>{item.subtitle}</Text>
					</View>
					{_renderImage()}
				</View>

				{onSelect || onEdit || onDelete ? (
					<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
						{onSelect && <FormCheckbox testID={`${testID}_checkbox_select`} value={isSelected} onPress={() => onSelect()} />}
						{_renderActions()}
					</View>
				) : null}
			</View>
		);
	};

	if (IS_WEB) {
		return (
			<View
				testID={`${testID}_button_${item.id}`}
				style={{
					marginBottom: hsBottomMargin / 2
				}}
			>
				{_renderContent()}
			</View>
		);
	}

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress ? () => onPress(item) : undefined}
			isDisabled={!onPress}
			style={{
				marginBottom: hsBottomMargin / 2
			}}
		>
			{_renderContent()}
		</ChildButton>
	);
};
