import React from 'react';

import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { EDefaultIconSet } from 'helper/icon';
import { useSpace } from 'hooks/useSpace';

interface INavigationHeaderPickAttendeeButton {
	options: any;
}

export const NavigationHeaderPickAttendeeButton = (props: INavigationHeaderPickAttendeeButton) => {
	const { options } = props;
	const { activeSpace } = useSpace();

	const profile = useSelector((store: IRootState) => store.auth.profile);

	if (
		profile &&
		profile.allowChats &&
		!profile.hideMyProfile &&
		(activeSpace?.forceDoubleOptInForUsers ? profile.isUserConfirmed : true)
	) {
		return (
			<NavigationHeaderIconButton
				testID="header_button_add"
				onPress={() => {
					if (options?.onRightNavPress) {
						options.onRightNavPress();
					}
				}}
				icon={EDefaultIconSet.Add}
			/>
		);
	}

	return null;
};
