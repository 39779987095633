import { getDefaultRegistrationFields } from '../config/defaultFields/defaultRegistrationFields';
import {
	IAttendee,
	IFieldSettings,
	IRegistrationField,
	ISpaceRegistrationFormValues,
	IUserProfile,
	ProfileSettingsInitialValues,
	SpaceRegistrationFormInitialValues
} from '../config/interfaces';
import { isEmptyString } from '../helper';
import { IFormSection } from './useContent';
import { useSpace } from './useSpace';

export const useRegistration = () => {
	const { activeSpace } = useSpace();

	const getRegistrationFields = (unfiltered?: boolean, tempFields?: IRegistrationField[]) => {
		let fields = [...getDefaultRegistrationFields()];

		if (activeSpace?.registrationFields?.fields) {
			fields = [...activeSpace.registrationFields.fields];
		} else if (tempFields) {
			fields = [...tempFields];
		}

		getDefaultRegistrationFields().forEach((field) => {
			const idx = fields.findIndex((e) => e.fieldName === field.fieldName);
			if (idx === -1) {
				fields.push(field);
			}
			if (field.maxLength && fields[idx]) {
				fields[idx].maxLength = field.maxLength;
			}
		});

		if (!unfiltered) {
			fields = fields.filter((e) => e.visibility === 'always' || e.visibility === 'registrationOnly');
		}

		return fields;
	};

	const getEditProfileFields = (showPasswordFields?: boolean) => {
		let fields: IRegistrationField[] = [];

		if (activeSpace?.registrationFields?.fields) {
			fields = [...activeSpace.registrationFields.fields];
		}

		getDefaultRegistrationFields().forEach((field) => {
			const idx = fields.findIndex((e) => e.fieldName === field.fieldName);
			if (idx === -1) {
				fields.push(field);
			}
			if (field.maxLength && fields[idx]) {
				fields[idx].maxLength = field.maxLength;
			}
		});

		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'meetings')) {
			const fieldIndex = fields.findIndex((f) => f.fieldName === 'allowMeetingRequests');
			if (!(fields[fieldIndex]?.visibility === 'always' || fields[fieldIndex]?.visibility === 'editOnly')) {
				fields[fieldIndex].visibility = 'always';
			}
		}

		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'chats')) {
			const fieldIndex = fields.findIndex((f) => f.fieldName === 'allowChats');
			if (!(fields[fieldIndex]?.visibility === 'always' || fields[fieldIndex]?.visibility === 'editOnly')) {
				fields[fieldIndex].visibility = 'always';
			}
		}

		fields = fields.filter((e) => {
			if (showPasswordFields) {
				return (
					e.visibility === 'always' ||
					e.visibility === 'editOnly' ||
					e.fieldType === 'password' ||
					e.fieldType === 'passwordConfirm'
				);
			}
			return e.fieldType !== 'password' && (e.visibility === 'always' || e.visibility === 'editOnly');
		});

		return fields;
	};

	const getInitialFormValues = (tempFields?: IRegistrationField[], ticket?) => {
		const obj = { ...SpaceRegistrationFormInitialValues };

		const fields = getRegistrationFields(false, tempFields);
		fields.forEach((field) => {
			if (ticket && ticket[field.fieldName]) {
				obj[field.fieldName] = ticket[field.fieldName];
			} else if (field.hasOwnProperty('defaultValue')) {
				obj[field.fieldName] = field.defaultValue;
			}
		});

		return obj;
	};

	const _getSections = (fields: IRegistrationField[]) => {
		let _fields = [...fields];
		let _sections: IFormSection[] = [];

		while (_fields.length > 0) {
			const sectionIndex = _fields.findIndex((e) => e.fieldType === 'section');
			if (sectionIndex === -1) {
				_sections.push({
					fields: _fields
				});
				_fields = [];
			} else if (sectionIndex !== 0) {
				_sections.push({
					fields: _fields.slice(0, sectionIndex)
				});
				_fields.splice(0, sectionIndex);
			} else {
				const nextSectionIndex = _fields.findIndex(
					(e) => e.fieldName !== _fields[sectionIndex].fieldName && e.fieldType === 'section'
				);
				if (nextSectionIndex === -1) {
					if (_fields.length > 0) {
						_sections.push({
							fields: _fields
						});
					}
					_fields = [];
				} else {
					if (_fields.slice(0, nextSectionIndex).length > 0) {
						_sections.push({
							fields: _fields.slice(0, nextSectionIndex)
						});
					}
					_fields.splice(0, nextSectionIndex);
				}
			}
		}

		return _sections;
	};

	const getRegistrationSections = (fields: IRegistrationField[]) => {
		return _getSections(fields);
	};

	const getEditProfileSections = (showPasswordFields?: boolean) => {
		return _getSections(getEditProfileFields(showPasswordFields));
	};

	const isRegisterSubmitDisabled = (values: ISpaceRegistrationFormValues) => {
		let fields = getRegistrationFields();

		fields = fields.filter((field) => field.isRequired);

		for (const field of fields) {
			switch (field.fieldType) {
				case 'logo':
				case 'avatar':
					if (!values[field.fieldName]) {
						return true;
					}
					break;
				case 'boolean':
					if (!values.hasOwnProperty(field.fieldName)) {
						return true;
					}
					break;
				case 'category':
					if (!values[field.fieldName] || isEmptyString(values[field.fieldName])) {
						return true;
					}
					break;
				case 'number':
					if (!values[field.fieldName]) {
						return true;
					}
					break;
				case 'email':
				case 'password':
				case 'string':
				case 'website':
					if (isEmptyString(values[field.fieldName])) {
						return true;
					}
					break;
				default:
					return true;
			}
		}

		return false;
	};

	const getProfileFieldSettings = (profile: IUserProfile | IAttendee) => {
		const settingsObj = {};
		const settingsArray = profile?.fieldSettings?.fields ?? ProfileSettingsInitialValues;
		settingsArray.map((element: IFieldSettings) => {
			settingsObj[element.fieldName] = element.showOnPublic;
		});

		return settingsObj;
	};

	enum maxFieldCountForFieldType {
		extra = 10,
		checkbox = 10,
		category = 10,
		section = 999,
		label = 999
	}

	return {
		getRegistrationFields,
		getEditProfileFields,
		getEditProfileSections,
		getRegistrationSections,
		getInitialFormValues,
		isRegisterSubmitDisabled,
		maxFieldCountForFieldType,
		getProfileFieldSettings
	};
};
