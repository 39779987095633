import { Avatar } from 'components/User';
import { IAttendee, IUserBooking } from 'config/interfaces';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Text } from 'components/Text';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { ChildButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { Icon } from 'components/Icon';

interface IBookingAttendeeListItem {
	testID: string;
	item: IUserBooking;
	onRemove?: () => void;
	isLoading?: boolean;
	isDisabled?: boolean;
}

export const BookingAttendeeListItem = (props: IBookingAttendeeListItem) => {
	const { testID, item, onRemove, isLoading, isDisabled } = props;
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (attendees) {
			_attendee = attendees.find((e) => e.userId === item.userId);
		}

		setAttendee(_attendee);
	}, [item, attendees]);

	return (
		<ChildButton
			testID={`${testID}_button_details`}
			style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}
			onPress={() => navigation.navigate(ERoutes.AttendeeDetails, { spaceId: activeSpace.spaceId, userId: item.userId })}
			isDisabled={isDisabled}
		>
			<Avatar
				avatar={attendee?.smallImageUrl ?? attendee?.imageUrl}
				fullName={attendee ? `${attendee.firstName} ${attendee.lastName}` : t('Unknown')}
				size="listItem"
			/>
			<View style={{ flex: 1, paddingLeft: 10, justifyContent: 'center' }}>
				<Text bold style={{ marginBottom: 2 }}>
					{attendee ? `${attendee.firstName} ${attendee.lastName}` : item.userId}
				</Text>
				{!isEmptyString(attendee?.email) && <Text style={{ fontSize: 12 }}>{attendee?.email}</Text>}
			</View>
			{onRemove && (
				<ChildButton testID={`${testID}_button_remove`} onPress={onRemove} isDisabled={isDisabled} isLoading={isLoading}>
					<Icon name={EDefaultIconSet.Delete} size={30} color={theme.danger} />
				</ChildButton>
			)}
		</ChildButton>
	);
};
