import React, { useState } from 'react';
import Modal from 'modal-enhanced-react-native-web';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsBorderRadius, hsInnerPadding, hsToastOffsetWeb, hsTopScreenPadding } from 'config/styleConstants';
import { Dimensions, TextInput, View } from 'react-native';
import { Button, ChildButton, TButtonType } from 'components/Button';
import { useTheme } from 'hooks/useTheme';
import { H1, Hint, Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { EDefaultIconSet } from 'helper';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from 'components/Form/constants';
import Toast from 'react-native-toast-notifications';
import { BaseToast } from 'components/Toast/BaseToast';

interface IHSAlert {}

export const HSAlert = (props: IHSAlert) => {
	const {} = props;
	const { theme } = useTheme();
	const screenWidth = Dimensions.get('window').width;
	const screenHeight = Dimensions.get('window').height;
	const [containerSize, setContainerSize] = useState<number>(0);

	const { isAlertVisible, alertData } = useSelector((store: IRootState) => store.alert);

	const hideAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.hideAlert);

	const _getSize = () => {
		if (screenWidth >= 1000) {
			return '40%';
		} else if (screenWidth >= 800) {
			return '50%';
		} else if (screenWidth >= 600) {
			return '60%';
		} else if (screenWidth >= 400) {
			return '80%';
		} else {
			return '90%';
		}
	};

	const _renderButtons = () => {
		if (alertData.buttons) {
			let buttons = [...alertData.buttons];
			let isShareAlert = false;
			let shareUrl = '';

			buttons.forEach((e) => {
				if (e.text?.startsWith('shareUrl_')) {
					isShareAlert = true;
					shareUrl = e.text.replace('shareUrl_', '');
				}
			});

			buttons = buttons.filter((e) => !e.text?.startsWith('shareUrl_'));

			const displayStacked = (buttons.length > 2 && !isShareAlert) || (buttons.length > 1 && containerSize < 360);
			if (displayStacked) {
				buttons.reverse();
			}

			return (
				<View>
					{isShareAlert && (
						<TextInput
							testID="share_input"
							value={shareUrl}
							style={{
								flex: 1,
								marginTop: 10,
								color: theme.text,
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								borderRadius: FORMELEMENTBORDERRADIUS,
								borderWidth: FORMELEMENTBORDERWIDTH,
								borderColor: theme.lightgray,
								paddingHorizontal: 5,
								paddingVertical: 5,
								textAlignVertical: 'center'
							}}
							multiline
							editable={false}
							selectTextOnFocus
							focusable
						/>
					)}
					<View
						style={{
							flexDirection: displayStacked ? 'column' : 'row',
							justifyContent: 'space-between',
							marginTop: hsTopScreenPadding,
							marginRight: displayStacked ? 0 : -10
						}}
					>
						{buttons.map((b, index) => {
							let _type: TButtonType = 'primary';
							switch (b.style) {
								case 'destructive':
									_type = 'danger';
									break;
								case 'cancel':
									_type = 'cancel';
									break;
								default:
									break;
							}
							return (
								<Button
									key={`alert_button_${b.text ? b.text.replace(/\s/g, '_') : index}`}
									testID={`alert_button_${b.text ? b.text.replace(/\s/g, '_') : index}`}
									title={b.text ?? ''}
									onPress={() => {
										if (b.onPress) {
											b.onPress();
										}

										hideAlert({});
									}}
									fullWidth={displayStacked}
									style={
										displayStacked
											? { marginBottom: index === buttons.length - 1 ? 0 : 10, alignSelf: 'center' }
											: { marginRight: 10, flexShrink: 1 }
									}
									type={_type}
								/>
							);
						})}
					</View>
				</View>
			);
		}

		return null;
	};

	if (isAlertVisible && alertData) {
		return (
			<Modal
				animationType="slide"
				visible={isAlertVisible}
				supportedOrientations={['portrait', 'landscape-left', 'landscape-right']}
				animationInTiming={1000}
				animationOutTiming={1000}
				style={{ width: '100%', alignSelf: 'center' }}
			>
				<ChildButton
					style={{
						position: 'absolute',
						width: '100%',
						height: screenHeight,
						backgroundColor: theme.gray,
						zIndex: isAlertVisible ? 100 : -5,
						opacity: isAlertVisible ? 0.5 : 0,
						flex: 1
					}}
					testID={'alert_backdrop'}
					onPress={() => hideAlert({})}
				/>
				<View
					style={{
						borderRadius: hsBorderRadius,
						backgroundColor: theme.contentBackgroundColor ?? theme.background,
						padding: hsInnerPadding,
						alignSelf: 'center',
						zIndex: 200,
						minWidth: '25%',
						maxWidth: _getSize()
					}}
					onLayout={(e) => setContainerSize(e.nativeEvent.layout.width)}
				>
					<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
						<H1 style={{ marginRight: 10 }} adjustsFontSizeToFit>
							{alertData?.title}
						</H1>
						<ChildButton testID={'hsalert_button_close'} onPress={() => hideAlert({})}>
							<Icon name={EDefaultIconSet.Close} />
						</ChildButton>
					</View>
					<Text>{alertData?.message}</Text>
					<Hint>{alertData?.hint}</Hint>
					{_renderButtons()}
				</View>
				{isAlertVisible && (
					<Toast
						ref={(ref) => (global['toastalert'] = ref)}
						renderToast={(e) => <BaseToast {...e} isModal />}
						offsetTop={hsToastOffsetWeb}
						animationType={'slide-in'}
					/>
				)}
			</Modal>
		);
	}
	return null;
};
