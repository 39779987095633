import React from 'react';

import { Text } from 'components/Text';
import { TFunctionResult } from 'i18next';
import { HSCard } from 'components/Card';
import { View, ViewStyle } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';
import { Spinner } from 'components/Spinner';

interface INumberPresenter {
	number: string | number;
	title: string | TFunctionResult;
	maxNumber?: string | number;
	cardStyle?: ViewStyle;
	numberFontSize?: number;
	textFontSize?: number;
	buttonText?: string;
	onButtonPress?: () => void;
	icon?: string;
	isLoading?: boolean;
}

export const NumberPresenter = (props: INumberPresenter) => {
	const { number, title, cardStyle, numberFontSize, textFontSize, maxNumber, buttonText, onButtonPress, icon, isLoading } = props;
	const { theme } = useTheme();

	const _renderNumberOrLoading = () => {
		if (isLoading) {
			return <Spinner />;
		}

		return (
			<>
				<Text bold style={{ fontSize: numberFontSize ?? 30 }}>
					{number}
				</Text>
				<Text bold style={{ marginLeft: 2, fontSize: 10, color: theme.gray }}>
					{maxNumber}
				</Text>
			</>
		);
	};

	if (number !== undefined && title) {
		return (
			<HSCard style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', ...cardStyle }}>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<Text center bold style={{ fontSize: textFontSize ?? 14 }} numberOfLines={2} adjustsFontSizeToFit>
						{title}
					</Text>
				</View>

				<View style={{ flexDirection: 'row', alignItems: 'baseline', marginTop: 5 }}>{_renderNumberOrLoading()}</View>
				{buttonText && onButtonPress && (
					<RoundButton
						testID={`button_${buttonText}`}
						icon={icon ?? EDefaultIconSet.Save}
						title={buttonText}
						size="sm"
						onPress={onButtonPress}
						alignSelf="center"
					/>
				)}
			</HSCard>
		);
	}

	return null;
};
