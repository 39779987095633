import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { H1, H2, H3 } from 'components/Text';
import { generateTheme, getVoteShareUrl, isDarkColor, isEmptyString, IS_WEB } from 'helper';
import { ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ILauncherSpace, IMedia, IVote } from 'config/interfaces';
import { Image } from 'components/Image';
import {
	VoteApplause,
	VoteHappinessOMeter,
	VoteMultipleChoice,
	VoteNps,
	VoteRating,
	VoteScale,
	VoteText,
	VoteWordCloud
} from 'components/Vote/ActiveVote';
import { VoteQuiz } from 'components/Vote/ActiveVote/VoteQuiz';
import { HSCard } from 'components/Card';
import { hsBottomMargin, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { FullScreenLoading } from 'components/Loading';
import { IResolvedToken } from 'rematch/interfaces';
import i18next from 'i18next';
import { VoteSurvey } from 'components/Vote/ActiveVote/VoteSurvey';
import { HSQRCode } from 'components/QRCode';
import { VotePinOnImage } from 'components/Vote/ActiveVote/VotePinOnImage';

const defaultLogo = require('../../../assets/images/defaultLogo/defaultLogo.png');
const defaultLogoGray = require('../../../assets//images/defaultLogo/defaultLogoGray.png');
const helloSpacesBlack = require('../../../../assets/hellospaces.png');
const helloSpacesWhite = require('../../../../assets/hellospacesWhite.png');

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ResultView>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ResultView>;
type RouteParams = StackParamList[ERoutes.ResultView];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

type decodeStatus = 'decoded' | 'error' | 'noCode';

const TESTIDPREFIX = 'resultview';

export const ResultViewScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();

	const [currentSpace, setCurrentSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [vote, setVote] = useState<IVote | undefined>(undefined);
	const [codeDecodeStatus, setCodeDecodeStatus] = useState<decodeStatus | undefined>(undefined);
	const [isNewTab, setIsNewTab] = useState<boolean>(false);
	const [backgroundImage, setBackgroundImage] = useState<IMedia | undefined>(undefined);
	const [backgroundImageName, setBackgroundImageName] = useState<string>('');
	const [qrSize, setQrSize] = useState<number>(0);
	const [isVoteAnswersLoading, setIsVoteAnswersLoading] = useState<boolean>(false);

	const interval = useRef<NodeJS.Timer>();

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const isMultiSpaceSocketConnected = useSelector((store: IRootState) => store.socket.isMultiSpaceSocketConnected);

	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const loadVoteAnswersAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteAnswersAndStartSync);
	const closeMultiSpaceEventListener = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceEventListener);
	const resolveVoteToken = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.resolveVoteToken);
	const initMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.initMultiSpaceSocket);
	const closeMultiSpaceSocket = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceSocket);
	const loadLikes = useRematchDispatch((dispatch: Dispatch) => dispatch.like.loadLikes);
	const startLikeSync = useRematchDispatch((dispatch: Dispatch) => dispatch.like.startLikeSync);
	const startCommentSync = useRematchDispatch((dispatch: Dispatch) => dispatch.comment.startCommentSync);
	const startAttendeeSync = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.startAttendeeSync);
	const loadAttendeeDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeDelta);
	const loadVoteDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteDelta);
	const setActiveSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setActiveSpaceId);

	useEffect(() => {
		if (route?.params?.code) {
			_resolveVoteToken();
		} else {
			setCodeDecodeStatus('noCode');

			const vote = votes.find((e) => e.id === route.params?.id);
			setVote(vote);
			setCurrentSpace(activeSpace);
		}
	}, [route?.params?.code, vote, activeSpace]);

	useEffect(() => {
		if (currentSpace && vote) {
			_syncAnswers();

			if (!interval.current) {
				const myInterval = setInterval(() => {
					_reloadData();
				}, 5000);

				interval.current = myInterval;
			}
		}
	}, [isMultiSpaceSocketConnected, vote, currentSpace]);

	useEffect(() => {
		return () => {
			if (currentSpace && vote && route?.params?.code && !isNewTab) {
				closeMultiSpaceEventListener({ event: `${currentSpace?.spaceId}_voteanswers_${vote.id}` });
				closeMultiSpaceSocket({});
			}
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, []);

	useEffect(() => {
		const _vote = votes?.find((v) => v.id === vote?.id);
		if (_vote) {
			setVote(_vote);
		}
	}, [votes]);

	const _syncAnswers = async () => {
		if (vote?.id && currentSpace) {
			await initMultiSpaceSocket({ space: currentSpace });
			_reloadData();
			loadAttendeeDelta({ space: currentSpace });
			startAttendeeSync({ space: currentSpace });
			startLikeSync({});
			loadLikes({ spaceId: currentSpace.spaceId });
			startCommentSync({});
		}
	};

	const _reloadData = async () => {
		if (vote && !isVoteAnswersLoading) {
			setIsVoteAnswersLoading(true);
			await loadVoteAnswersAndStartSync({ reload: true, space: currentSpace, voteId: vote.id, nosync: true, load: true });
			setIsVoteAnswersLoading(false);
		}
	};

	const _resolveVoteToken = async () => {
		if (isEmptyString(codeDecodeStatus) && route?.params?.code) {
			let code: string = route?.params?.code;
			if (code?.endsWith('newTabTrue')) {
				code = code.replace('newTabTrue', '');
				setIsNewTab(true);
			}
			const res: IResolvedToken | undefined = await resolveVoteToken({ token: code });
			if (res?.vote && res?.space) {
				setActiveSpaceId({ spaceId: res?.space?.spaceId });
				if (res?.space.backgroundImage) {
					setBackgroundImage(res?.space.backgroundImage);
				} else if (res?.space.backgroundImageName) {
					setBackgroundImageName(res?.space.backgroundImageName);
				}
				navigation.setOptions({
					eventTitle: res?.space?.title
				});
				i18next.changeLanguage(res?.space?.language);
				setTheme(generateTheme(res?.space));
				setCurrentSpace(res?.space);
				setVote(res?.vote);
				setCodeDecodeStatus('decoded');
				loadVoteDelta({ load: true });
			} else {
				setCodeDecodeStatus('error');
			}
		}
	};

	const _renderWebLogo = () => {
		if (IS_WEB && currentSpace) {
			let src = '';
			switch (currentSpace.logoImageName) {
				case 'defaultLogoGray':
					src = defaultLogoGray;
					break;
				case 'defaultLogo':
				case 'default':
				default:
					src = defaultLogo;
					break;
			}
			if (src) {
				return (
					<View style={{ marginBottom: hsBottomMargin * 2 }}>
						<Image
							mediaObj={currentSpace?.logoImage}
							imageSize={IS_WEB ? 'full' : 'small'}
							url={src}
							style={{ width: '100%', aspectRatio: 16 / 9 }}
							expectedRatio={16 / 9}
						/>
						<H2 largerText center>
							{currentSpace.title}
						</H2>
					</View>
				);
			}
		}

		return null;
	};

	const _renderQrCode = () => {
		if (currentSpace && vote) {
			const value = getVoteShareUrl(currentSpace.spaceId, vote.id);
			return (
				<View
					style={{
						width: '100%',
						alignItems: 'center',
						marginBottom: hsBottomMargin,
						flex: 1
					}}
					onLayout={(e) => {
						const height = e.nativeEvent.layout.height;
						const width = e.nativeEvent.layout.width;
						if (width > height) {
							setQrSize(height);
						} else {
							setQrSize(width);
						}
					}}
				>
					<HSQRCode value={value} size={qrSize} />
				</View>
			);
		}
		return null;
	};

	const _renderInviteText = () => {
		if (currentSpace) {
			return (
				<View style={{ marginBottom: hsBottomMargin * 2 }}>
					{t('joinNow')
						.replace('%SPACEID%', currentSpace.spaceId)
						.split('<split>')
						.map((t) => {
							return <H2 center>{t}</H2>;
						})}
					<H1 largerText center>
						{currentSpace.spaceId}
					</H1>
				</View>
			);
		}
		return null;
	};

	const _renderResult = () => {
		switch (vote?.votingType) {
			case 'applause':
				return <VoteApplause fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'multipleChoice':
				return <VoteMultipleChoice fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'quiz':
				return <VoteQuiz fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'rating':
				return <VoteRating fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'scale':
				return <VoteScale fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'survey':
				return <VoteSurvey fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'nps':
				return <VoteNps fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'text':
				return (
					// set this to likes for eubp22 TO-DO: make a toggle and/or discuss a better way
					<VoteText fullwidth vote={vote} sortBy={'likes'} resultsOnly largerDesign={IS_WEB} spaceId={currentSpace?.spaceId} />
				);
			case 'wordCloud':
				return <VoteWordCloud fullwidth vote={vote} resultsOnly isPresenterView />;
			case 'pinOnImage':
				return <VotePinOnImage fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			case 'happinessOMeter':
				return <VoteHappinessOMeter fullwidth vote={vote} resultsOnly largerDesign={IS_WEB} />;
			default:
				return null;
		}
	};

	const _renderPoweredBy = () => {
		return (
			<View style={{ justifyContent: 'flex-end' }}>
				<H3 center>Powered by</H3>
				<Image
					style={{ width: '60%', aspectRatio: 7, alignSelf: 'center' }}
					url={isDarkColor(theme.contentBackgroundColor ?? theme.background) ? helloSpacesWhite : helloSpacesBlack}
				/>
			</View>
		);
	};

	const _renderContent = () => {
		if (codeDecodeStatus) {
			if (IS_WEB && !isTabletOrMobile) {
				return (
					<View
						style={{
							flex: 1,
							flexDirection: 'row'
						}}
					>
						<HSCard style={{ width: 300 }}>
							{_renderWebLogo()}
							{_renderInviteText()}
							{_renderQrCode()}
							{_renderPoweredBy()}
						</HSCard>
						<ScrollView contentContainerStyle={{ flex: 1 }}>{_renderResult()}</ScrollView>
					</View>
				);
			}
		}
		if (!IS_WEB || isTabletOrMobile) {
			return (
				<View
					style={{
						flex: 1
					}}
				>
					{_renderResult()}
					<View style={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}>
						<HSCard style={{ marginBottom: 5, padding: hsInnerPadding / 2 }}>{_renderPoweredBy()}</HSCard>
					</View>
				</View>
			);
		}
		return <FullScreenLoading />;
	};

	return (
		<ScreenContainer bgImage={backgroundImage} bgImageName={backgroundImageName}>
			<View
				testID={`${TESTIDPREFIX}_scrollview`}
				style={{
					paddingTop: hsTopScreenPadding,
					paddingBottom: 0,
					paddingHorizontal: IS_WEB ? EHorizontalScreenPadding.Wide : EHorizontalScreenPadding.None,
					width: '100%',
					alignSelf: 'center',
					flex: 1
				}}
			>
				{_renderContent()}
			</View>
		</ScreenContainer>
	);
};

export const ResultViewScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			{IS_WEB ? <NavigationHeaderPlaceholder /> : <NavigationHeaderBackButton />}
			<NavigationHeaderTitle title={props?.options?.eventTitle ?? i18next.t('Speaker View')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
