import React, { useEffect, useState, ReactNode } from 'react';
import { IS_ANDROID, IS_WEB } from 'helper/platform';
import {
	Animated,
	Dimensions,
	EmitterSubscription,
	Keyboard,
	KeyboardAvoidingView,
	StatusBar,
	StyleSheet,
	TextInput,
	View
} from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface IKeyboardShift {
	children?: ReactNode | ReactNode[];
	noBackground?: boolean;
	isModal?: boolean;
	topPadding?: number;
	oldShift?: boolean;
}

export const KeyboardShift = (props: IKeyboardShift) => {
	const { children, noBackground, isModal, topPadding, oldShift } = props;
	const { theme } = useTheme();
	const headerHeight = isModal ? 0 : useHeaderHeight();

	const { top } = useSafeAreaInsets();

	const [shift, setShift] = useState(new Animated.Value(0));
	const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false);

	const [didShowListener, setDidShowListener] = useState<EmitterSubscription | null>();
	const [didHideListener, setDidHideListener] = useState<EmitterSubscription | null>();

	useEffect(() => {
		if (!IS_WEB) {
			setDidShowListener(Keyboard.addListener('keyboardDidShow', _handleKeyboardDidShow));
			if (IS_ANDROID) {
				setDidHideListener(Keyboard.addListener('keyboardDidHide', _handleKeyboardWillHide));
			} else {
				setDidHideListener(Keyboard.addListener('keyboardWillHide', _handleKeyboardWillHide));
			}
		}

		return () => {
			if (!IS_WEB) {
				if (didShowListener) {
					didShowListener.remove();
				}
				if (didHideListener) {
					didHideListener.remove();
				}
			}
		};
	}, []);

	const _handleKeyboardDidShow = (e) => {
		setIsKeyboardVisible(true);
		const { height: windowHeight } = Dimensions.get('window');
		const keyboardHeight = e.endCoordinates.height;

		const currentlyFocusedInputRef = TextInput.State.currentlyFocusedInput();
		if (currentlyFocusedInputRef) {
			currentlyFocusedInputRef.measure((x, y, width, height, pageX, pageY) => {
				const fieldHeight = height;
				const fieldTop = pageY;
				let gap = 0;

				if (isModal) {
					gap = windowHeight - keyboardHeight - (fieldTop + Math.max(44, fieldHeight)) - (topPadding ?? 0);
				} else {
					gap = windowHeight - keyboardHeight - (fieldTop + Math.max(44, fieldHeight));
				}

				if (gap >= 0) {
					// gap > 0 means that no scrolling is needed
					return;
				}
				Animated.timing(shift, {
					toValue: gap,
					duration: 100,
					useNativeDriver: true
				}).start();
			});
		}
	};

	const _handleKeyboardWillHide = () => {
		setIsKeyboardVisible(false);
		Animated.timing(shift, {
			toValue: 0,
			duration: 100,
			useNativeDriver: true
		}).start();
	};

	if (IS_WEB) {
		return <View style={[styles.container, { backgroundColor: theme.background }]}>{children}</View>;
	}

	if (IS_ANDROID || isModal || oldShift) {
		return (
			<Animated.View
				style={[
					styles.container,
					isKeyboardVisible ? { transform: [{ translateY: IS_ANDROID && !isModal ? 0 : shift }] } : null,
					!noBackground ? { backgroundColor: theme.background } : null
				]}
			>
				{children}
			</Animated.View>
		);
	}

	return (
		<KeyboardAvoidingView
			keyboardVerticalOffset={headerHeight + (StatusBar?.currentHeight ?? 0) + top}
			contentContainerStyle={{ backgroundColor: theme.background }}
			style={[styles.container, { backgroundColor: theme.background }]}
			behavior={'padding'}
		>
			{children}
		</KeyboardAvoidingView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1
	}
});
