import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { IExpo } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { View } from 'react-native';
import { ChildButton } from 'components/Button';
import { Image } from 'components/Image';
import { HSCard } from 'components/Card';
import { hsBorderRadius, hsInnerPadding } from 'config/styleConstants';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface ISponsorPages {
	height?: number;
	handlePress: (sponsor: IExpo) => void;
}

export const SponsorPages = (props: ISponsorPages) => {
	const { height, handlePress } = props;
	const { isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();

	const index = useRef(0);
	const [time, setTime] = useState(new Date().toLocaleTimeString());
	const [rows, setRows] = useState<IExpo[][]>([]);
	const [sponsors, setSponsors] = useState<IExpo[]>([]);
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [hintHeight, setHintHeight] = useState<number>(1);
	const [sponsorRowsPerPage, setSponsorRowsPerPage] = useState<number>(4);

	const content = useSelector((store: IRootState) => store.content.content);
	const calculatedHeight = height ? height - 10 - hintHeight : isTabletOrMobile ? 40 : 80;
	const calculatedWidth = (calculatedHeight * 16) / 9;

	useEffect(() => {
		if (activeSpace && content?.expos) {
			const _sponsors = content.expos.filter(
				(e) => e.spaceId === activeSpace.spaceId && (e.sponsorType === 'sponsorOnly' || e.sponsorType === 'sponsor')
			);

			setSponsors(_sponsors);
		}
	}, [activeSpace, content, containerWidth]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			const date = new Date();
			index.current = index.current + 1;
			if (index.current > rows.length - 1) {
				index.current = 0;
			}
			setTime(date.toLocaleTimeString());
		}, 4000);

		return () => {
			clearTimeout(timeout);
		};
	}, [time]);

	useEffect(() => {
		const _rows: IExpo[][] = [];
		const _sponsorItems = sponsors.filter((e) => e.sponsorBanner || e.logo);
		const withOrder = _sponsorItems.filter((e) => e.sponsorOrder);
		const rest = _sponsorItems.filter((e) => !e.sponsorOrder);
		withOrder.sort((a, b) => (Number(a.sponsorOrder) < Number(b.sponsorOrder) ? -1 : 1));

		let _items = [...withOrder, ...rest];
		while (_items.length > 0) {
			const rowItems: IExpo[] = _items.splice(0, sponsorRowsPerPage);
			_rows.push(rowItems);
		}

		setRows(_rows);
	}, [sponsors, sponsorRowsPerPage]);

	useEffect(() => {
		if (containerWidth > 1) {
			const rowCount = Math.floor(containerWidth / (calculatedWidth + 5));
			setSponsorRowsPerPage(rowCount);
		}
	}, [containerWidth]);

	const _renderRows = () => {
		const row = rows[index.current];
		if (row) {
			return (
				<View
					key={`sponsor1-${index.current}`}
					style={{
						flexDirection: 'row',
						height: height ? height - hintHeight : calculatedHeight,
						justifyContent: 'space-evenly',
						maxWidth: '100%',
						paddingVertical: height ? 5 : 0,
						alignItems: 'center'
					}}
					onLayout={(e) => {
						setContainerWidth(e.nativeEvent.layout.width - (height ? 0 : hsInnerPadding * 2));
					}}
				>
					{row.map((sponsor, index) => {
						if (sponsor?.sponsorBanner || sponsor?.logo) {
							return (
								<ChildButton
									key={`sponsor-${index}`}
									testID={`sponsoritem_pages_${sponsor.id}_${index}`}
									onPress={() => handlePress(sponsor)}
									style={{ marginHorizontal: 'auto' }}
								>
									<Image
										style={{
											width: calculatedWidth,
											height: calculatedHeight,
											borderRadius: hsBorderRadius
										}}
										mediaObj={sponsor?.sponsorBanner ?? sponsor?.logo}
										imageSize="thumbnail"
										resizeMode="contain"
										expectedRatio={16 / 9}
									/>
								</ChildButton>
							);
						}
						return null;
					})}
				</View>
			);
		}
		return null;
	};

	if (sponsors.filter((e) => e.sponsorBanner || e.logo).length > 0) {
		if (height) {
			return (
				<View style={{ padding: 10, marginBottom: EHorizontalScreenPadding.Wide }}>
					{/* <Hint onLayout={(e) => setHintHeight(e.nativeEvent.layout.height)}>{activeSpace?.sponsorTitle ?? t('Sponsors')}</Hint> */}
					{_renderRows()}
				</View>
			);
		}

		return (
			<HSCard style={{ padding: 10, marginBottom: EHorizontalScreenPadding.Wide }}>
				{/* <Hint style={{ marginBottom: 10, marginTop: -10, alignSelf: 'center' }}>{activeSpace?.sponsorTitle ?? t('Sponsors')}</Hint> */}
				{_renderRows()}
			</HSCard>
		);
	}

	return null;
};
