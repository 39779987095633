import React from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';
import * as ImagePicker from 'expo-image-picker';

import { FormMediaPicker, FormTextInput } from 'components/Form';
import { IDownload } from 'config/interfaces';
import { HSModal } from '../Modal';
import { isEmptyString } from 'helper';
import { hsBottomMargin } from 'config/styleConstants';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Dispatch, useRematchDispatch } from 'rematch/store';

interface IDownloadEditModal {
	download: IDownload;
	isVisible: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (value?: IDownload) => void;
	testIdPrefix: string;
}

export const DownloadEditModal = (props: IDownloadEditModal) => {
	const { download, testIdPrefix, isVisible, onClose, onSubmit, onChange } = props;
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			onSubmit={() => {
				onChange({ ...download });
				onSubmit();
			}}
			isSubmitDisabled={!download.downloadFile && isEmptyString(download.downloadUrl)}
		>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					width: '100%',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: 10
				}}
			>
				<FormTextInput
					label={t('downloadTitleLabel')}
					// hint={t('downloadTitleHint')}
					testID={`${testIdPrefix}_textinput_title`}
					value={download.title}
					onChangeText={(val) => onChange({ ...download, title: val })}
				/>
				<FormTextInput
					label={t('downloadDescriptionLabel')}
					hint={t('downloadDescriptionHint')}
					multiline
					testID={`${testIdPrefix}_textinput_description`}
					value={download.description}
					onChangeText={(val) => onChange({ ...download, description: val })}
				/>
				<FormTextInput
					keyboardType="url"
					label={t('downloadUrlLabel')}
					hint={t('downloadUrlHint')}
					testID={`${testIdPrefix}_textinput_downloadurl`}
					value={download.downloadUrl}
					onChangeText={(val) => onChange({ ...download, downloadUrl: val })}
				/>
				<FormMediaPicker
					label={t('downloadFile')}
					hint={t('downloadFileHint')}
					isOutline
					formStyle={{ flex: 1, marginBottom: hsBottomMargin }}
					testID={`${testIdPrefix}_imagepicker_image`}
					value={download?.downloadFile}
					mediaTypes="files"
					onChange={(val) => {
						if (val) {
							onChange({ ...download, downloadFile: val });
						}
					}}
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
					useModal
				/>
				<FormMediaPicker
					label={t('downloadPreview')}
					hint={t('downloadPreviewHint')}
					isOutline
					formStyle={{ flex: 1, marginBottom: hsBottomMargin }}
					testID={`${testIdPrefix}_imagepicker_image`}
					value={download?.downloadPreview}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					onChange={(val) => onChange({ ...download, downloadPreview: val })}
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
					useModal
				/>
			</ScrollView>
		</HSModal>
	);
};
