import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal as RNModal, View, Dimensions } from 'react-native';

import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { Spinner } from 'components/Spinner';
import { H1 } from 'components/Text';
import { IS_WEB } from 'helper';

interface ILoadingModal {
	isLoading: boolean;
	loadingText?: string;
}

export const LoadingModal = (props: ILoadingModal) => {
	const { isLoading, loadingText } = props;

	const { theme } = useTheme();
	const { t } = useTranslation();

	const screenHeight = Dimensions.get('screen').height;

	const _renderContent = () => {
		if (IS_WEB) {
			return (
				<HSCard
					style={{
						alignSelf: 'center',
						height: 200,
						width: 200,
						marginTop: (screenHeight - 400) / 2,
						marginBottom: (screenHeight - 250) / 2
					}}
				>
					<H1 center>{loadingText ?? t('loadingModalHeader')}</H1>
					<Spinner size={80} />
				</HSCard>
			);
		}
		return (
			<View
				style={{
					position: 'relative',
					flex: 0,
					alignSelf: 'center',
					height: 200,
					width: 200,
					marginTop: (screenHeight - 400) / 2
				}}
			>
				<HSCard style={{ flex: 1 }}>
					<H1 center>{loadingText ?? t('loadingModalHeader')}</H1>
					<Spinner size={80} />
				</HSCard>
			</View>
		);
	};

	return (
		<RNModal
			presentationStyle="overFullScreen"
			animationType="slide"
			visible={isLoading}
			transparent
			supportedOrientations={['portrait', 'landscape-left', 'landscape-right']}
			statusBarTranslucent
		>
			<View
				style={{
					flex: 1,
					backgroundColor: theme.contentBackgroundColor ?? theme.background,
					position: 'absolute',
					width: '100%',
					height: '100%',
					opacity: 0.7
				}}
			/>
			{_renderContent()}
		</RNModal>
	);
};
