import React, { useEffect, useState } from 'react';
import { ICreateNewsFormValues, IEditNewsFormValues, INews } from 'config/interfaces';
import { v4 } from 'uuid';
import { NewsListItem } from './NewsListItem';
import { useTranslation } from 'react-i18next';
import { Text, Title } from 'components/Text';
import { HSCard } from 'components/Card';
import { useTheme } from 'hooks/useTheme';
import { ScrollView, View } from 'react-native';

const newsPreview = require('../../assets/images/newsPreview/newsPreviewPlaceholder1024.png');

interface INewsPreview {
	news: ICreateNewsFormValues | IEditNewsFormValues | INews;
}

export const NewsPreview = (props: INewsPreview) => {
	const { news } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [previewNews, setPreviewNews] = useState<INews>({
		isVisible: true,
		title: news.title ?? t('previewTitle'),
		subtitle: news.subtitle ?? t('previewSubtitle'),
		externalUrl: news.externalUrl ?? '',
		internalUrl: news.internalUrl ?? '',
		showFrom: news.showFrom ?? '',
		showUntil: news.showUntil ?? '',
		media: news.media ?? [newsPreview],
		text: news.text ?? t('newsTextPlaceholder'),
		id: news['id'] ?? v4(),
		updated_at: news['updated_at'] ?? '',
		created_at: news['created_at'] ?? '',
		published_at: news['published_at'] ?? '',
		spaceId: news['spaceId'] ?? '',
		userId: news['userId'] ?? ''
	});

	useEffect(() => {
		setPreviewNews({
			isVisible: true,
			title: news.title ?? t('previewTitle'),
			subtitle: news.subtitle ?? t('previewSubtitle'),
			externalUrl: news.externalUrl ?? '',
			internalUrl: news.internalUrl ?? '',
			showFrom: news.showFrom ?? '',
			showUntil: news.showUntil ?? '',
			media: news.media ?? [newsPreview],
			text: news.text ?? t('newsTextPlaceholder'),
			id: news['id'] ?? v4(),
			updated_at: news['updated_at'] ?? '',
			created_at: news['created_at'] ?? '',
			published_at: news['published_at'] ?? '',
			spaceId: news['spaceId'] ?? '',
			userId: news['userId'] ?? ''
		});
	}, [news]);

	const _renderPlaceholderText = () => {
		if (news && (!news.title || !news.subtitle || !news.media || !news.text)) {
			return (
				<HSCard>
					<Title>{t('Preview')}</Title>
					<Text>{t('previewContainsPlaceholder')}</Text>
				</HSCard>
			);
		}
		return null;
	};

	return (
		<ScrollView>
			<View
				style={{
					width: '95%',
					height: 1,
					backgroundColor: theme.contentBackgroundColor ?? theme.background,
					marginBottom: 20,
					alignSelf: 'center'
				}}
			/>
			{_renderPlaceholderText()}
			<NewsListItem testID="newsPreview" item={previewNews} isPreview showText />
		</ScrollView>
	);
};
