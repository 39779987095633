export const shuffleArray = (arr: any[]) => {
	for (let i = arr.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[arr[i], arr[j]] = [arr[j], arr[i]];
	}
	return arr;
};

export const swapArrayItems = (index1: number, index2: number, array: any[]) => {
	if (index1 < 0 || index1 > array.length || index2 < 0 || index2 > array.length) {
		return array;
	}
	const temp = array[index1];
	array[index1] = array[index2];
	array[index2] = temp;
	return array;
};

export const sortArrayAsPyramid = (arr: any[]) => {
	arr.sort(function (a, b) {
		return a.count - b.count;
	});
	var arr1 = arr.slice(0, arr.length / 2);
	var arr2 = arr.slice(arr.length / 2, arr.length);
	arr2.sort(function (a, b) {
		return b.count - a.count;
	});

	return arr1.concat(arr2);
};
