import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { BackHandler, Vibration, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IStage } from 'config/interfaces';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { EDefaultIconSet, isEmptyString, IS_ANDROID, IS_WEB, swapArrayItems } from 'helper';
import { HSCard, HSDragCard } from 'components/Card';
import { FormLabel } from 'components/Form/FormLabel';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { H1, WelcomeHint, Text } from 'components/Text';
import i18next from 'i18next';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { FormCheckbox } from 'components/Form';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StageList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StageList>;
type RouteParams = StackParamList[ERoutes.StageList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'stagelist';

export const StageListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { exportAsCSV } = useExport();
	const { getContentTypeFields } = useContent('stage');
	const { activeSpace } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [stages, setStages] = useState<IStage[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<number | undefined>(undefined);
	const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isMultiDeleteLoading, setIsMultiDeleteLoading] = useState<boolean>(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const updateStage = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateStage);
	const multiUpdate = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiUpdate);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (activeSpace && content.stages && isScreenFocused) {
			let _stages: typeof stages = [];

			_stages = content.stages.filter((e) => e.spaceId === activeSpace.spaceId);

			_stages.sort((a, b) => {
				const aVal = a.order ? a.order : 999;
				const bVal = b.order ? b.order : 999;

				return aVal < bVal ? -1 : 1;
			});

			setStages(_stages);
		}
	}, [activeSpace, content, isScreenFocused]);

	const _delete = async (item: IStage) => {
		setIsDeleteLoading(item.id);
		await updateStage({ stage: { ...item, isDeleted: true }, fields: getContentTypeFields(), isDeletion: true });
		setIsDeleteLoading(undefined);
	};

	const _updateOrders = async () => {
		const items: IStage[] = [];
		stages.forEach((stage, idx) => {
			const newOrder = idx + 1;
			items.push({
				...stage,
				order: newOrder
			});
		});
		setStages(items);
		setIsUpdateLoading(true);
		await multiUpdate({ items, type: 'stage' });
		navigation.setParams({ prohibitNavigation: false });
		setIsUpdateLoading(false);
	};

	const _multiDelete = async () => {
		showAlert({
			title: t('ConfirmDelete'),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsMultiDeleteLoading(true);
						const res = await multiDelete({ selectedIds: selectedEntries, type: 'stage' });
						setIsMultiDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _renderSelectAll = () => {
		if (isEditMode && stages.length > 0) {
			return (
				<HSCard>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedEntries && selectedEntries.length === stages.length}
						onPress={() => {
							if (selectedEntries && selectedEntries.length === stages.length) {
								setSelectedEntries([]);
							} else {
								setSelectedEntries(stages.map((sp) => sp.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _handleActionSheetPress = (action: string) => {
		switch (action) {
			case 'save':
				_updateOrders();
				break;
			case 'add':
				navigation.navigate(ERoutes.StageCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'stage' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IStage[] = [];
				if (content.stages) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.stages.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.stages];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'stages');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'delete':
				_multiDelete();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.StageCreate, {
						spaceId: activeSpace?.spaceId,
						stageId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!stages) {
			return;
		}
		const list: IStage[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, stages);

		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setStages([...list]);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!stages) {
			return;
		}

		const list = [...stages];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}

		setStages([...list]);
	};

	const _renderElement = (params: RenderItemParams<IStage>) => {
		const { item, drag, isActive, index } = params;

		const itemScheduleEntries = item.schedules?.filter((e) => !e.isDeleted) ?? [];

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_list_stage_${index}`}
				isActive={isActive}
				onDrag={drag}
				onSelect={isEditMode ? () => _handleSelect(item.id) : undefined}
				isSelected={selectedEntries.includes(item.id)}
				onEdit={
					isEditMode ? () => navigation.navigate(ERoutes.StageEdit, { spaceId: activeSpace?.spaceId, id: item.id }) : undefined
				}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onRemove={isEditMode ? () => _delete(item) : undefined}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (stages?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				isDisabled={isUpdateLoading}
				deleteTitle={t('ConfirmDeleteSingle').replace('%TITLE%', `"${item.title}"`)}
				deleteSubTitle={t('DeleteStageSubtitle')}
				onPress={isEditMode ? () => _handleSelect(item.id) : undefined}
			>
				<FormLabel testID={`${TESTIDPREFIX}_stage_${item.id}`} label={item.title} style={{ marginBottom: 5 }} />
				{!isEmptyString(item.subtitle) && <Text style={{ fontSize: 12 }}>{item.subtitle}</Text>}
				{itemScheduleEntries.length > 0 && (
					<Text style={{ fontSize: 12 }}>{`${itemScheduleEntries.length} ${t('ScheduleEntries')}`}</Text>
				)}
			</HSDragCard>
		);
	};

	return (
		<ScreenContainer contentKey="stages">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<DraggableFlatList
					data={stages}
					containerStyle={{ flex: 1, marginHorizontal: -EHorizontalScreenPadding.Wide }}
					contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
					ListHeaderComponent={
						<View>
							<HSCard
								style={{
									marginBottom: hsBottomMargin
								}}
							>
								<H1>{t('ManageStagesHeaderTitle')}</H1>
								<WelcomeHint>{t('ManageStagesHeaderSubtitle')}</WelcomeHint>
							</HSCard>
							{_renderSelectAll()}
						</View>
					}
					renderItem={(params) => _renderElement(params)}
					keyExtractor={(item) => `${TESTIDPREFIX}_item_${item.id}`}
					onDragBegin={() => {
						if (IS_ANDROID) {
							Vibration.vibrate(10, false);
						}
					}}
					onDragEnd={({ data }) => {
						setStages(data);
						if (!route.params?.prohibitNavigation) {
							navigation.setParams({ prohibitNavigation: true });
						}
					}}
				/>
				<LoadingModal isLoading={isUpdateLoading || isDeleteLoading !== undefined || isMultiDeleteLoading} />
			</View>
			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_save`}
					icon={EDefaultIconSet.Save}
					label={t('Save')}
					isLoading={isUpdateLoading}
					isDisabled={!route.params?.prohibitNavigation || isDeleteLoading !== undefined || isMultiDeleteLoading}
					onPress={() => _handleActionSheetPress('save')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					onPress={() => _handleActionSheetPress('add')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_editmode`}
					icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
					label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
					isDisabled={isDeleteLoading !== undefined}
					onPress={() => _handleActionSheetPress('editMode')}
				/>

				{IS_WEB && (
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_import`}
						icon={EDefaultIconSet.Import}
						label={t('Import Data')}
						onPress={() => _handleActionSheetPress('import')}
					/>
				)}
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_export`}
					icon={EDefaultIconSet.Export}
					label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
					isDisabled={selectedEntries.length === 0}
					onPress={() => _handleActionSheetPress('export')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
					isDisabled={selectedEntries.length === 0}
					onPress={() => _handleActionSheetPress('delete')}
					iconColor={theme.danger}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_clone`}
					icon={EDefaultIconSet.Clone}
					label={t('clone')}
					isDisabled={isDeleteLoading !== undefined || selectedEntries.length !== 1}
					onPress={() => _handleActionSheetPress('clone')}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const StageListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Stages')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
