import { t } from 'i18next';
import React, { useState } from 'react';
import { Image, View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton } from 'components/Button';
import { IS_ANDROID, IS_IOS, openURL } from 'helper';
import { APP_STORE_URL_IOS, APP_STORE_URL_ANDROID } from 'config/envConstants';

interface INewVersion {}

const HelloSpacesWide = require('../../../assets/hellospaces.png');
const BadgeAndroid = require('../../../assets/store_badge_android.png');
const BadgeIOS = require('../../../assets/store_badge_ios.png');

export const NewVersion = (props: INewVersion) => {
	const [imageRatio, setImageRatio] = useState<number>(1);
	const [containerWidth, setContainerWidth] = useState<number>(1);

	return (
		<View style={{ flex: 1, paddingHorizontal: 20 }}>
			<View style={{ flex: 1, justifyContent: 'center' }} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
				<Image
					source={HelloSpacesWide}
					onLoad={(e) => setImageRatio(e.nativeEvent.source.width / e.nativeEvent.source.height)}
					style={{ height: containerWidth / imageRatio, width: containerWidth, marginBottom: 40 }}
				/>
				<Text center style={{ fontSize: 16 }}>
					{t('NewNativeVersionAvailable')}
				</Text>
				<ChildButton
					testID="button_newversion"
					onPress={() => {
						if (IS_IOS && APP_STORE_URL_IOS) {
							openURL(APP_STORE_URL_IOS);
						} else if (IS_ANDROID && APP_STORE_URL_ANDROID) {
							openURL(APP_STORE_URL_ANDROID);
						}
					}}
				>
					<Image source={IS_IOS ? BadgeIOS : BadgeAndroid} resizeMode="contain" style={{ width: '50%', alignSelf: 'center' }} />
				</ChildButton>
			</View>
		</View>
	);
};
