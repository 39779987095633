import { getTrackingActions, IReport } from 'config/interfaces';

import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import moment from 'moment';

export const REPORTLISTITEMENTRYHEIGHT = 80;

export interface IReportListItem {
	testID: string;
	item: IReport;
	onPress: () => void;
}

export const ReportListItem = (props: IReportListItem) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={testID}
			style={{
				height: REPORTLISTITEMENTRYHEIGHT,
				paddingVertical: 5,
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
			onPress={onPress}
		>
			<View style={{ flex: 1, flexDirection: 'row', paddingLeft: 10, alignItems: 'center' }}>
				<Icon name={EDefaultIconSet.File} size={35} />
				<View style={{ flex: 1, justifyContent: 'center', paddingHorizontal: 10 }}>
					<Text bold>{getTrackingActions(item.type).find((e) => e.key === item.action)?.label}</Text>
					{!isEmptyString(item.data?.title) && <Text style={{ fontSize: 10 }}>{item.data?.title}</Text>}
				</View>
				<Icon name={EDefaultIconSet.Download} size={30} />
			</View>
		</ChildButton>
	);
};
