import { ERoutes } from 'components/Navigation/routes';
import { IFeatureInfo } from 'config/interfaces';
import i18next from 'i18next';
import { EDefaultIconSet } from './icon';

export const getKeyForRoute = (key: string) => {
	switch (key) {
		case ERoutes.MeetingTab:
			return 'meetings';
		case ERoutes.NetworkingTab:
			return 'networking';
		case ERoutes.ChatTab:
			return 'chats';
		case ERoutes.VotingsTab:
			return 'votings';
		case ERoutes.FeedTab:
			return 'feed';
		case ERoutes.AttendeesTab:
			return 'attendees';
		case ERoutes.ExposTab:
			return 'expos';
		case ERoutes.SponsorsTab:
			return 'sponsors';
		case ERoutes.ScheduleTab:
			return 'schedules';
		case ERoutes.MediaItemsTab:
			return 'mediaitems';
		case ERoutes.MapTab:
			return 'maps';
		case ERoutes.SpeakersTab:
			return 'speakers';
		case ERoutes.More:
			return 'administrate';
		default:
			return undefined;
	}
};

export const getSettingsForNavigationKey = (key: string, feature?: IFeatureInfo) => {
	switch (key) {
		case ERoutes.MeetingTab:
		case 'meetings':
			return {
				tab: ERoutes.MeetingTab,
				route: ERoutes.MeetingList,
				icon: feature?.icon ?? EDefaultIconSet.Meetings,
				label: feature?.label ?? i18next.t('meetings')
			};
		case ERoutes.NetworkingTab:
		case 'networking':
			return {
				tab: ERoutes.NetworkingTab,
				route: ERoutes.NetworkingRoomList,
				icon: feature?.icon ?? EDefaultIconSet.Networking,
				label: feature?.label ?? i18next.t('networking')
			};
		case ERoutes.ChatTab:
		case 'chats':
			return {
				tab: ERoutes.ChatTab,
				route: ERoutes.Chat,
				icon: feature?.icon ?? EDefaultIconSet.Chat,
				label: feature?.label ?? 'Chats'
			};
		case ERoutes.VotingsTab:
		case 'votings':
			return {
				tab: ERoutes.VotingsTab,
				route: feature?.itemId ? ERoutes.ActiveVoting : ERoutes.VoteList,
				icon: feature?.icon ?? EDefaultIconSet.Vote,
				label: feature?.label ?? 'Votings'
			};
		case ERoutes.FeedTab:
		case 'feed':
			return {
				tab: ERoutes.FeedTab,
				route: ERoutes.NewsList,
				icon: feature?.icon ?? EDefaultIconSet.News,
				label: feature?.label ?? i18next.t('feed')
			};
		case ERoutes.AttendeesTab:
		case 'attendees':
			return {
				tab: ERoutes.AttendeesTab,
				route: ERoutes.AttendeesList,
				icon: feature?.icon ?? EDefaultIconSet.Attendees,
				label: feature?.label ?? i18next.t('Attendees')
			};
		case ERoutes.ExposTab:
		case 'expos':
			return {
				tab: ERoutes.ExposTab,
				route: ERoutes.Expos,
				icon: feature?.icon ?? EDefaultIconSet.Exhibition,
				label: feature?.label ?? i18next.t('Expos')
			};
		case ERoutes.SponsorsTab:
		case 'sponsors':
			return {
				tab: ERoutes.SponsorsTab,
				route: ERoutes.Sponsors,
				icon: feature?.icon ?? EDefaultIconSet.Sponsors,
				label: feature?.label ?? i18next.t('Sponsors')
			};
		case ERoutes.ScheduleTab:
		case 'schedules':
			return {
				tab: ERoutes.ScheduleTab,
				route: ERoutes.Schedule,
				icon: feature?.icon ?? EDefaultIconSet.Schedule,
				label: feature?.label ?? i18next.t('Agenda')
			};
		case ERoutes.MediaItemsTab:
		case 'mediaitems':
			return {
				tab: ERoutes.MediaItemsTab,
				route: ERoutes.MediaItemList,
				icon: feature?.icon ?? EDefaultIconSet.MediaLibrary,
				label: feature?.label ?? i18next.t('Media Items')
			};
		case ERoutes.MapTab:
		case 'maps':
			return {
				tab: ERoutes.MapTab,
				route: ERoutes.Maps,
				icon: feature?.icon ?? EDefaultIconSet.Map,
				label: feature?.label ?? i18next.t('Maps')
			};
		case ERoutes.SpeakersTab:
		case 'speakers':
			return {
				tab: ERoutes.SpeakersTab,
				route: ERoutes.Speakers,
				icon: feature?.icon ?? EDefaultIconSet.Speakers,
				label: feature?.label ?? i18next.t('Speakers')
			};
		case ERoutes.SettingsTab:
			return {
				tab: ERoutes.Administrate,
				route: ERoutes.Administrate,
				icon: feature?.icon ?? EDefaultIconSet.Settings,
				label: feature?.label ?? i18next.t('Administrate')
			};
		case ERoutes.More:
		case 'administrate':
			return {
				tab: ERoutes.More,
				route: ERoutes.Administrate,
				icon: feature?.icon ?? EDefaultIconSet.DotsHorizontal,
				label: feature?.label ?? i18next.t('More')
			};
		default:
			const obj = {
				icon: feature?.icon ?? EDefaultIconSet.HelloSpaces,
				route: ERoutes.Web,
				tab: ERoutes.WebTab,
				label: feature?.label ?? ''
			};
			switch (feature?.contentType) {
				case 'schedules':
					obj.tab = ERoutes.ScheduleTab;
					obj.route = ERoutes.Schedule;
					break;
				case 'expos':
					obj.tab = ERoutes.ExposTab;
					obj.route = ERoutes.Expos;
					break;
				case 'speakers':
					obj.tab = ERoutes.SpeakersTab;
					obj.route = ERoutes.Speakers;
					break;
				case 'mediaitems':
					obj.tab = ERoutes.MediaItemsTab;
					obj.route = ERoutes.MediaItemList;
					break;
				case 'attendees':
					obj.tab = ERoutes.AttendeesTab;
					obj.route = ERoutes.AttendeesList;
					break;
				case 'markdown':
					obj.tab = ERoutes.MarkdownTab;
					obj.route = ERoutes.Markdown;
					break;
				case 'votings':
					obj.tab = ERoutes.VotingsTab;
					obj.route = feature?.itemId ? ERoutes.ActiveVoting : ERoutes.VoteList;
					break;
				default:
					break;
			}
			return obj;
	}
};
