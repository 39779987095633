import { IMedia } from 'config/interfaces';
import React, { useState } from 'react';
import { Image } from 'components/Image';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { getDefaultAspectRatioStyle, isEmptyString } from 'helper';

interface ISpaceMediaPickerModalPreviewImage {
	item: IMedia;
	onPress: () => void;
}

export const SpaceMediaPickerModalPreviewImage = (props: ISpaceMediaPickerModalPreviewImage) => {
	const { item, onPress } = props;

	const [imageRatio, setImageRatio] = useState<number>(1);
	const [containerWidth, setContainerWidth] = useState<number>(1);

	return (
		<ChildButton
			testID={`spacemediapickermodal_button_${item.id}_submit`}
			style={{ flex: 1, width: '100%', justifyContent: 'center' }}
			onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
			onPress={onPress}
		>
			<Image
				mediaObj={item}
				imageSize="small"
				onLoad={(e) => setImageRatio(e.source.width / e.source.height)}
				resizeMode="contain"
				style={{ ...getDefaultAspectRatioStyle(containerWidth), maxHeight: containerWidth }}
				expectedRatio={16 / 9}
			/>
			{!isEmptyString(item.caption) && <Text style={{ marginTop: 10, fontSize: 12 }}>{item.caption}</Text>}
		</ChildButton>
	);
};
