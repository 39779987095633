import React, { ReactNode, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

import { IExpo } from 'config/interfaces';
import { hsBorderRadius, hsInnerPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { ChildButton } from 'components/Button';
import { Image } from 'components/Image';
import AutoScroller from './AutoScroller';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface ISponsorScrolling {
	height?: number;
	handlePress: (sponsor: IExpo) => void;
}

export const SponsorScrolling = (props: ISponsorScrolling) => {
	const { height, handlePress } = props;
	const { t } = useTranslation();
	const { isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();

	const [sponsors, setSponsors] = useState<IExpo[]>([]);
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [hintHeight, setHintHeight] = useState<number>(1);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		if (activeSpace && content?.expos) {
			let _sponsors = content.expos.filter(
				(e) => e.spaceId === activeSpace.spaceId && (e.sponsorType === 'sponsorOnly' || e.sponsorType === 'sponsor')
			);
			const withOrder = _sponsors.filter((e) => e.sponsorOrder);
			const rest = _sponsors.filter((e) => !e.sponsorOrder);
			withOrder.sort((a, b) => (Number(a.sponsorOrder) < Number(b.sponsorOrder) ? -1 : 1));

			let _items = [...withOrder, ...rest];

			_items = _items.filter((e) => e.sponsorBanner || e.logo);

			setSponsors(_items);
		}
	}, [activeSpace, content, containerWidth]);

	const _renderSponsors = () => {
		const sponsorList: ReactNode[] = [];
		const calculatedHeight = height ? height - 10 - hintHeight : isTabletOrMobile ? 40 : 80;
		const calculatedWidth = (calculatedHeight * 16) / 9;

		sponsors.map((sponsor, index) => {
			if (sponsor?.sponsorBanner || sponsor?.logo) {
				sponsorList.push(
					<ChildButton
						style={{ paddingHorizontal: 5 }}
						key={`sponsor-${index}`}
						testID={`sponsoritem_scrolling_${sponsor.id}_${index}`}
						onPress={() => handlePress(sponsor)}
					>
						<Image
							mediaObj={sponsor?.sponsorBanner ?? sponsor?.logo}
							imageSize="thumbnail"
							style={{
								width: calculatedWidth,
								height: calculatedHeight,
								borderRadius: hsBorderRadius
							}}
							resizeMode="contain"
							expectedRatio={16 / 9}
						/>
					</ChildButton>
				);
			}
			return null;
		});
		// render same elements multiple times to prevent gap between sponsors
		return (
			<View
				style={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					height: calculatedHeight
				}}
			>
				{sponsorList}
				{sponsors.length < 10 && sponsorList}
				{sponsors.length < 5 && sponsorList}
				{sponsors.length < 3 && sponsorList}
			</View>
		);
	};

	const _renderScroll = () => {
		return (
			<View
				style={{
					height: height ? height - hintHeight : 'auto',
					width: '100%',
					paddingVertical: height ? 5 : 0
				}}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width - (height ? 0 : hsInnerPadding * 2))}
			>
				<AutoScroller endPaddingWidth={0} duration={sponsors.length * 8000}>
					{_renderSponsors()}
				</AutoScroller>
			</View>
		);
	};

	if (sponsors.filter((e) => e.sponsorBanner || e.logo).length > 0) {
		if (height) {
			return (
				<View style={{ padding: 10, marginBottom: EHorizontalScreenPadding.Wide }}>
					{/* <Hint onLayout={(e) => setHintHeight(e.nativeEvent.layout.height)}>{activeSpace?.sponsorTitle ?? t('Sponsors')}</Hint> */}
					{_renderScroll()}
				</View>
			);
		}

		return (
			<HSCard style={{ padding: 10, marginBottom: EHorizontalScreenPadding.Wide }}>
				{/* <Hint style={{ marginBottom: 10, marginTop: -10, alignSelf: 'center' }}>{activeSpace?.sponsorTitle ?? t('Sponsors')}</Hint> */}
				{_renderScroll()}
			</HSCard>
		);
	}

	return null;
};
