import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationOptions, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderExportButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IVote, TVotingType } from 'config/interfaces/vote';
import { useSelector } from 'react-redux';
import {
	VoteRating,
	VoteText,
	VoteWordCloud,
	VoteScale,
	VoteApplause,
	VoteMultipleChoice,
	VoteNps,
	VoteHappinessOMeter
} from 'components/Vote/ActiveVote';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { BackHandler, View, Text, AppState, AppStateStatus } from 'react-native';
import { TabSwitcher } from 'components/TabSwitcher';
import { VoteQuiz } from 'components/Vote/ActiveVote/VoteQuiz';
import { FullScreenLoading } from 'components/Loading';
import { EDefaultIconSet, IS_ANDROID, IS_WEB, openURL } from 'helper';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { NAVFONTSIZE, NAVICONSIZE } from 'components/Form/constants';

import { useDate } from 'hooks/useDate';
import { useExport } from 'hooks/useExport';
import { useSpace } from 'hooks/useSpace';
import { VoteSurvey } from 'components/Vote/ActiveVote/VoteSurvey';
import { showToast } from 'helper/toast';
import { VotePinOnImage } from 'components/Vote/ActiveVote/VotePinOnImage';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ActiveVoting>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ActiveVoting>;
type RouteParams = StackParamList[ERoutes.ActiveVoting];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
	options: NativeStackNavigationOptions;
};

export const ActiveVoteScreen = ({ route, navigation, options }: Props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { exportVoteResultAsCSV } = useExport();
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const { getComparedDateString } = useDate();

	const [activeVote, setActiveVote] = useState<IVote>();
	const [currentDate, setCurrentDate] = useState<string>(moment().toISOString());
	const [isVoteAnswersLoading, setIsVoteAnswersLoading] = useState<boolean>(false);

	const timer = useRef<NodeJS.Timer>(null);
	const voteAnswerTimer = useRef<NodeJS.Timer>(null);
	const activeVoteRef = useRef<IVote>(null);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const isMultiSpaceSocketConnected = useSelector((store: IRootState) => store.socket.isMultiSpaceSocketConnected);

	const loadVoteAnswersAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteAnswersAndStartSync);
	const closeMultiSpaceEventListener = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.closeMultiSpaceEventListener);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);

	useEffect(() => {
		navigation.setOptions({
			onSurveyExportPressed: () => exportVoteResultAsCSV(route.params.id)
		});
	}, [activeSpace, profile, voteAnswers, attendees]);

	useEffect(() => {
		let appStateListener;

		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		_startTimer();

		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}

		return () => {
			_stopTimer();
			if (!IS_WEB) {
				appStateListener.remove();
			}

			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, []);

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			_startTimer();
		} else if (nextState === 'background') {
			_stopTimer();
		}
	};

	useEffect(() => {
		if (activeSpace) {
			let _activeVote = votes?.find((v) => v.id === route?.params?.id);
			if (!_activeVote && route.params.key) {
				const feature = activeSpace.features?.list.find((e) => e.key === route.params.key);
				if (feature) {
					_activeVote = votes?.find((v) => v.id === feature.itemId);
				}
			}

			setTimeout(() => {
				if (votes.length > 0 && !_activeVote) {
					_showToastAndGoBack(t('VoteNotFound'), t('TryAgainLater'));
				}
			}, 2000);
			setActiveVote(_activeVote);
			activeVoteRef.current = _activeVote;
		}
	}, [route, votes, activeSpace]);

	useEffect(() => {
		_checkAvailability();
	}, [activeVote, currentDate]);

	useEffect(() => {
		if (isMultiSpaceSocketConnected) {
			_reload(true);
		} else {
			closeMultiSpaceEventListener({ event: `${activeSpace?.spaceId}_voteanswers_${route?.params?.id}` });
		}
	}, [activeSpace, isMultiSpaceSocketConnected]);

	const _startTimer = () => {
		timer.current = setInterval(() => {
			const now = moment();
			if (now.get('seconds') === 0) {
				setCurrentDate(now.toISOString());
			}
		}, 1000);
		const interval = setInterval(() => {
			_reload(true);
		}, 5000);

		voteAnswerTimer.current = interval;
	};

	const _stopTimer = () => {
		if (timer.current) {
			clearInterval(timer.current);
			timer.current = undefined;
		}
		if (voteAnswerTimer.current) {
			clearInterval(voteAnswerTimer.current);
			voteAnswerTimer.current = undefined;
		}
	};

	const backAction = () => {
		setVotingShowResults(false);
		setVotingIsAnswered(false);
		if (allowBack()) {
			navigation.goBack();
		} else {
			showAlert({
				title: t('unsavedChanges'),
				message: t('unsavedChangesSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('leaveWithoutSaving'),
						style: 'destructive',
						onPress: async () => {
							navigation.goBack();
						}
					}
				]
			});
		}

		return true;
	};

	const allowBack = () => {
		return !route?.params || !route?.params['prohibitNavigation'];
	};

	const _reload = async (reload: boolean) => {
		if (activeVoteRef.current && !isVoteAnswersLoading) {
			setIsVoteAnswersLoading(true);
			await loadVoteAnswersAndStartSync({ reload, voteId: activeVoteRef.current.id, nosync: true });
			setIsVoteAnswersLoading(false);
		}
	};

	const _showToastAndGoBack = (text1: string, text2: string) => {
		showToast('error', text1, text2);

		if (navigation.canGoBack()) {
			navigation.goBack();
		}
	};

	const _iAmAdminOrModerator = () => {
		const _admin = activeSpace?.admins?.find((e) => e.userId === profile?.userId) !== undefined;
		const _moderator = activeSpace?.moderators?.find((e) => e.userId === profile?.userId) !== undefined;
		return _admin || _moderator;
	};

	const _checkAvailability = () => {
		if (activeVote) {
			if (activeVote.isDeleted) {
				_showToastAndGoBack(t('voteIsDeleted'), '');
				return;
			}
			if (!activeVote.isActive && activeVote.hideResults && !_iAmAdminOrModerator()) {
				_showToastAndGoBack(t('VoteIsNotActive'), t('TryAgainLater'));
				return;
			}
			if (activeVote.openFrom && !_iAmAdminOrModerator() && moment().isBefore(moment(activeVote.openFrom))) {
				_showToastAndGoBack(
					t('VoteNotAvailableYet'),
					t('ErrorVoteAvailableFrom').replace('%VALUE%', getComparedDateString(activeVote.openFrom))
				);
				return;
			}
			if (activeVote.openUntil && !_iAmAdminOrModerator() && moment().isAfter(moment(activeVote.openUntil))) {
				_showToastAndGoBack(
					t('VoteNotAvailableAnymore'),
					t('ErrorVoteAvailableUntil').replace('%VALUE%', getComparedDateString(activeVote.openUntil))
				);
				return;
			}
		}
	};

	const _renderVoteByType = () => {
		switch (activeVote?.votingType) {
			case 'applause':
				return <VoteApplause vote={activeVote} />;
			case 'multipleChoice':
				return <VoteMultipleChoice vote={activeVote} />;
			case 'quiz':
				return <VoteQuiz vote={activeVote} />;
			case 'rating':
				return <VoteRating vote={activeVote} />;
			case 'scale':
				return <VoteScale vote={activeVote} />;
			case 'survey':
				return <VoteSurvey vote={activeVote} />;
			case 'text':
				return <VoteText vote={activeVote} sortBy={route?.params?.textSortBy ?? 'creation'} />;
			case 'wordCloud':
				return <VoteWordCloud vote={activeVote} />;
			case 'nps':
				return <VoteNps vote={activeVote} />;
			case 'externalUrl':
				if (activeVote?.externalUrl) {
					if (navigation.canGoBack()) {
						openURL(activeVote.externalUrl);
						navigation.goBack();
					} else {
						openURL(activeVote.externalUrl);
						navigation.dispatch(StackActions.replace(ERoutes.VoteList));
					}
				} else {
					if (navigation.canGoBack()) {
						navigation.goBack();
					} else {
						navigation.dispatch(StackActions.replace(ERoutes.VoteList));
					}
				}

				return null;
			case 'pinOnImage':
				return <VotePinOnImage vote={activeVote} />;
			case 'happinessOMeter':
				return <VoteHappinessOMeter vote={activeVote} />;
			default:
				return null;
		}
	};

	const _renderVote = () => {
		if (activeVote) {
			return <View style={{ flex: 1 }}>{_renderVoteByType()}</View>;
		}

		return <FullScreenLoading />;
	};

	return <ScreenContainer contentKey="votings">{_renderVote()}</ScreenContainer>;
};

export const ActiveVoteScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
	let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
	let color = primary === bg ? theme.navigationTextColor : theme.navbarButton;

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);

	const [activeVote, setActiveVote] = useState<IVote>();

	useEffect(() => {
		const _activeVote = votes?.find((v) => v.id === params?.id);
		setActiveVote(_activeVote);
	}, [params, votes]);

	const _getEditHeaderButton = () => {
		if (!iAmSpaceAdmin) {
			return null;
		}
		return (
			<ChildButton
				testID="edit"
				style={{ flexDirection: 'row-reverse', alignItems: 'center' }}
				onPress={() => navigation.navigate(ERoutes.ActiveVotingAdmin, { id: params.id, spaceId: params.spaceId })}
			>
				<Icon name={EDefaultIconSet.Settings} color={color} size={NAVICONSIZE} />
				{IS_WEB ? (
					<Text
						style={{
							color: color,
							fontSize: NAVFONTSIZE,
							paddingHorizontal: 5
						}}
					>
						{t('Settings')}
					</Text>
				) : null}
			</ChildButton>
		);
	};

	const _getResultHeaderButton = () => {
		const typesWithoutResultButton: TVotingType[] | undefined = ['applause', 'externalUrl', 'pinOnImage', 'survey', 'text', 'quiz'];
		const answersForThis = voteAnswers.filter((a) => a.voteId === activeVote?.id && !a.isDeleted);

		if (activeVote?.hideResults || votingShowResults || votingIsAnswered) {
			return null;
		}

		if (!activeVote?.votingType || typesWithoutResultButton.includes(activeVote?.votingType)) {
			return null;
		}

		if (!iAmSpaceAdmin && !iAmSpaceModerator && answersForThis && answersForThis.length <= 0) {
			return null;
		}

		return (
			<ChildButton
				testID="showResult"
				style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}
				onPress={() => setVotingShowResults(true)}
			>
				{IS_WEB ? (
					<Text
						style={{
							color: color,
							fontSize: NAVFONTSIZE,
							paddingHorizontal: 5
						}}
					>
						{t('Show Results')}
					</Text>
				) : null}
				<Icon name={EDefaultIconSet.Result} color={color} size={NAVICONSIZE} />
			</ChildButton>
		);
	};

	const _getRightHeaderComponent = () => {
		switch (activeVote?.votingType) {
			case 'wordCloud':
				if (votingShowResults) {
					return (
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							{(iAmSpaceAdmin || iAmSpaceModerator) && _getEditHeaderButton()}
							{(iAmSpaceAdmin || iAmSpaceModerator) && (
								<ChildButton
									testID="moderate"
									style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}
									onPress={() =>
										navigation.navigate(ERoutes.VotingModeration, { id: params.id, spaceId: params.spaceId })
									}
								>
									{IS_WEB ? (
										<Text
											style={{
												color: color,
												fontSize: NAVFONTSIZE,
												paddingHorizontal: 5
											}}
										>
											{t('Moderate')}
										</Text>
									) : null}
									<Icon name={EDefaultIconSet.Moderate} size={NAVICONSIZE} color={color} />
								</ChildButton>
							)}
						</View>
					);
				}
				if (iAmSpaceAdmin || iAmSpaceModerator) {
					return (
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							{_getResultHeaderButton()}
							{_getEditHeaderButton()}
						</View>
					);
				}

				return _getResultHeaderButton();
			case 'text':
				return (
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{(iAmSpaceAdmin || iAmSpaceModerator) && _getEditHeaderButton()}
						{(iAmSpaceAdmin || iAmSpaceModerator) && (
							<ChildButton
								testID="moderate"
								style={{ marginHorizontal: 10 }}
								onPress={() => navigation.navigate(ERoutes.VotingModeration, { id: params.id, spaceId: params.spaceId })}
							>
								<Icon name={EDefaultIconSet.Moderate} size={NAVICONSIZE} color={color} />
							</ChildButton>
						)}
						<TabSwitcher
							containerStyle={{ marginBottom: 0 }}
							items={[
								{
									key: 'creation',
									icon: EDefaultIconSet.Clock
								},
								{
									key: 'likes',
									icon: EDefaultIconSet.ThumbUp
								}
							]}
							activeKey={params.textSortBy ?? 'creation'}
							onPress={(key) => navigation.setParams({ textSortBy: key })}
						/>
					</View>
				);
			case 'survey':
				if (votingShowResults && iAmSpaceAdmin) {
					return (
						<View style={{ flexDirection: 'row' }}>
							<NavigationHeaderExportButton options={props.options} />
							{_getEditHeaderButton()}
						</View>
					);
				}
			default:
				if (iAmSpaceAdmin || iAmSpaceModerator) {
					return (
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							{_getResultHeaderButton()}
							{_getEditHeaderButton()}
						</View>
					);
				}
				return _getResultHeaderButton();
		}
	};

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton
				route={route}
				onPress={() => {
					setVotingShowResults(false);
					setVotingIsAnswered(false);
				}}
			/>
			<NavigationHeaderTitle title={votingShowResults ? t('result') : ''} />
			<View style={{ padding: 5 }}>{_getRightHeaderComponent()}</View>
		</NavigationHeader>
	);
};
