import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { NumberPresenter } from 'components/Info';
import {
	StackParamList,
	ERoutes,
	NavigationHeaderBackButton,
	NavigationHeader,
	NavigationHeaderTitle,
	NavigationHeaderPlaceholder
} from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { SectionHeader, Text } from 'components/Text';
import { IStatistics } from 'config/interfaces';
import { hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { useQuery } from 'hooks/useQuery';
import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Statistics>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Statistics>;
type RouteParams = StackParamList[ERoutes.Statistics];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'statistics';

export const StatisticsScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();

	const [statistics, setStatistics] = useState<IStatistics | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const loadStatistics = useRematchDispatch((dispatch: Dispatch) => dispatch.tracking.loadStatistics);

	useEffect(() => {
		_loadStatistics();
	}, []);

	const _loadStatistics = async () => {
		setIsLoading(true);
		const res = await loadStatistics({});
		setStatistics(res);
		setIsLoading(false);
	};

	const _renderAmount = (value: number, title: string) => {
		return (
			<View style={{ width: '46%' }}>
				<NumberPresenter number={value} title={title} numberFontSize={18} textFontSize={14} cardStyle={{ flex: 1 }} />
			</View>
		);
	};

	const _renderCardTitle = (title: string) => {
		return <SectionHeader label={title} />;
	};

	const _renderCardSubtitle = (subtitle: string) => {
		return (
			<Text bold center style={{ marginBottom: hsTextBottomMarginSmall }}>
				{subtitle}
			</Text>
		);
	};

	const _renderContent = () => {
		if (isLoading) {
			return (
				<HSCard>
					<Spinner />
				</HSCard>
			);
		}

		if (statistics) {
			return (
				<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
					<HSCard>
						{_renderCardTitle(t('User'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.user.inSpace, t('userInSpace'))}
							{_renderAmount(statistics.onlineUsers, t('OnlineUsers'))}
							{_renderAmount(statistics.user.devices.android, `Android ${t('Devices')}`)}
							{_renderAmount(statistics.user.devices.ios, `iOS ${t('Devices')}`)}
							{_renderAmount(statistics.user.chatNotAllowed, `${t('chats')} ${t('not.allowed')}`)}
							{_renderAmount(statistics.user.meetingNotAllowed, `${t('meetings')} ${t('not.allowed')}`)}
							{_renderAmount(statistics.user.profileHidden, t('ProfileHiddenTitle'))}
						</View>
					</HSCard>
					<HSCard>
						{_renderCardTitle('Lead Scan')}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.leadScan.today, t('Today'))}
							{_renderAmount(statistics.leadScan.overall, t('Total'))}
						</View>
					</HSCard>
					<HSCard>
						{_renderCardTitle('Meetings')}
						{_renderCardSubtitle(t('Meeting Requests'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.meeting.today.request, t('Today'))}
							{_renderAmount(statistics.meeting.overall.request, t('Total'))}
						</View>
						{_renderCardSubtitle(t('Accepted Meeting Requests'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.meeting.today.accepted, t('Today'))}
							{_renderAmount(statistics.meeting.overall.accepted, t('Total'))}
						</View>
						{_renderCardSubtitle(t('Reschedules Meeting Requests'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.meeting.today.rescheduled, t('Today'))}
							{_renderAmount(statistics.meeting.overall.rescheduled, t('Total'))}
						</View>
						{_renderCardSubtitle(t('Declined Meeting Requests'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.meeting.today.declined, t('Today'))}
							{_renderAmount(statistics.meeting.overall.declined, t('Total'))}
						</View>
					</HSCard>
					<HSCard>
						{_renderCardTitle('Chats')}
						{_renderCardSubtitle(t('Sent Messages'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.chat.today.messagesSent, t('Today'))}
							{_renderAmount(statistics.chat.overall.messagesSent, t('Total'))}
						</View>
						{_renderCardSubtitle(t('Active Chat Users'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.chat.today.uniqueUsers, t('Today'))}
							{_renderAmount(statistics.chat.overall.uniqueUsers, t('Total'))}
						</View>
					</HSCard>
					<HSCard>
						{_renderCardTitle(t('Favorites'))}

						{_renderCardSubtitle(t('Attendees'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.favorites.attendee.today.added, t('Added Today'))}
							{_renderAmount(statistics.favorites.attendee.overall.added, t('Added Total'))}
							{_renderAmount(statistics.favorites.attendee.today.removed, t('Removed Today'))}
							{_renderAmount(statistics.favorites.attendee.overall.removed, t('Removed Total'))}
						</View>
						{_renderCardSubtitle(t('expos'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.favorites.expo.today.added, t('Added Today'))}
							{_renderAmount(statistics.favorites.expo.overall.added, t('Added Total'))}
							{_renderAmount(statistics.favorites.expo.today.removed, t('Removed Today'))}
							{_renderAmount(statistics.favorites.expo.overall.removed, t('Removed Total'))}
						</View>
						{_renderCardSubtitle(t('schedules'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.favorites.schedule.today.added, t('Added Today'))}
							{_renderAmount(statistics.favorites.schedule.overall.added, t('Added Total'))}
							{_renderAmount(statistics.favorites.schedule.today.removed, t('Removed Today'))}
							{_renderAmount(statistics.favorites.schedule.overall.removed, t('Removed Total'))}
						</View>
					</HSCard>
					<HSCard>
						{_renderCardTitle(t('feed'))}
						{_renderCardSubtitle(t('Comments'))}
						<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
							{_renderAmount(statistics.feedComments.today, t('Today'))}
							{_renderAmount(statistics.feedComments.overall, t('Total'))}
						</View>
					</HSCard>
				</View>
			);
		}

		return (
			<HSCard>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_reload`}
					onPress={() => _loadStatistics()}
					title={t('Load Statistics')}
					icon={EDefaultIconSet.Reload}
					alignSelf="center"
				/>
			</HSCard>
		);
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
				testID={`${TESTIDPREFIX}_scrollview`}
			>
				{_renderContent()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const StatisticsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Statistics')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
