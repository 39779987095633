import React from 'react';
import { View } from 'react-native';

import {
	ICreateMeetingTableFormErrors,
	ICreateMeetingTableFormValues,
	IEditMeetingTableFormErrors,
	IEditMeetingTableFormValues
} from 'config/interfaces';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { HSCard } from 'components/Card';

interface IExpoForm {
	values: ICreateMeetingTableFormValues | IEditMeetingTableFormValues;
	errors: ICreateMeetingTableFormErrors | IEditMeetingTableFormErrors;
	onChange?: (val: ICreateMeetingTableFormValues | IEditMeetingTableFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'meetingtableform';

export const MeetingTableForm = (props: IExpoForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('meetingtable');
	const { renderFormField } = useForm();

	return (
		<View>
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) =>
						renderFormField('meetingtable', TESTIDPREFIX, values, errors, field, onChange, isLoading)
					)}
				</HSCard>
			))}
		</View>
	);
};
