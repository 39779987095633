import { ILauncherSpace, IUserProfile } from 'config/interfaces';

export const getQRCodeValueForUser = (activeSpace: ILauncherSpace, user: IUserProfile) => {
	let value = ``;

	if (activeSpace.ticketSettings?.ticketRequired) {
		if (user.tickets && user.tickets[activeSpace.spaceId]) {
			if (user.tickets[activeSpace.spaceId]?.ticketIdentifier) {
				value += user.tickets[activeSpace.spaceId]?.ticketIdentifier;
			} else if (user.tickets[activeSpace.spaceId]?.ticketcode) {
				value += user.tickets[activeSpace.spaceId]?.ticketcode;
			} else {
				value += user.userId;
			}
		} else {
			value += user.userId;
		}
	} else {
		value += user.userId;
	}

	return value;
};
