import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderScheduleTypeButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';

import { useContent } from 'hooks/useContent';
import { useDate } from 'hooks/useDate';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import {
	hsBottomMargin,
	hsTopScreenPadding,
	LISTHEADER_ANIMATIONTIME_IN,
	LISTHEADER_ANIMATIONTIME_OUT,
	LISTHEADER_ANIMATION_GUESSED_HEIGHT,
	LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_OPACITY_HIGH,
	LISTHEADER_ANIMATION_OPACITY_LOW,
	LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION
} from 'config/styleConstants';
import { Animated, BackHandler, Dimensions, FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet, filterListByFeature, getTimingAnimation, isEmptyString, IS_ANDROID, IS_WEB } from 'helper';
import { EMeetingStatus, IActiveScreenFilter, IFeatureInfo, IMeeting, ISchedule, IStage } from 'config/interfaces';
import moment from 'moment';
import { NewGrid, ScheduleList, VerticalGrid } from 'components/Schedule';
import { getSchedulesExample, getStageExample } from 'helper/content';
import { NoData } from 'components/NoData';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { SponsorBlock } from '../Sponsors';
import { TABBAR_HEIGHT } from 'config/constants';
import { HSCard } from 'components/Card';
import { ScrollEvent } from 'react-native-spring-scrollview';
import { ContentListHeader } from 'components/List';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { Placeholder, PlaceholderLine, ShineOverlay } from 'rn-placeholder';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Schedule>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Schedule>;
type RouteParams = StackParamList[ERoutes.Schedule];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export type TScheduleViewType = 'verticalGrid' | 'horizontalGrid' | 'list';

const TESTIDPREFIX = 'schedule';

export interface IScheduleDay {
	date: string;
	items: ISchedule[];
	meetings: any[];
}

export const ScheduleScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { getDate, getMonth, getDayOfWeekShort } = useDate();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const { getContentTypeFields: getContentTypeFieldsStage } = useContent('stage');
	const { getContentTypeFields: getContentTypeFieldsSchedule } = useContent('schedule');

	const [feature, setFeature] = useState<IFeatureInfo | undefined>(undefined);

	const [showOnlyFavorites, setShowOnlyFavorites] = useState<boolean>(false);
	const [scheduleDays, setScheduleDays] = useState<IScheduleDay[]>([]);
	const [filteredScheduleDays, setFilteredScheduledDays] = useState<IScheduleDay[]>([]);
	const [searchedScheduleDays, setSearchedScheduleDays] = useState<IScheduleDay[]>([]);

	const [activeScheduleDate, setActiveScheduleDate] = useState<string | undefined>(undefined);
	const [daySet, setDaySet] = useState<boolean>(false);
	const [viewType, setViewType] = useState<TScheduleViewType | 'listAndGrid' | 'list' | 'gridAndList' | 'listOnly' | 'gridOnly'>('list');
	const [isExampleLoading, setIsExampleLoading] = useState<boolean>(false);

	const [activeScreenFilters, setActiveScreenFilters] = useState<IActiveScreenFilter>({ filters: ['all'] });
	const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [hasMyDay, setHasMyDay] = useState<boolean>(true);

	const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
	const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
	const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(LISTHEADER_ANIMATION_GUESSED_HEIGHT));
	const [animatedOpacity, setAnimatedOpacity] = useState(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
	const [scrollableContentHeight, setScrollableContentHeight] = useState<number>(0);

	const scrollOffset = useRef<number>(0);

	const bottomSheetRef = useRef<RBSheet>(null);
	const scheduleDayScrollRef = useRef<FlatList>(null);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const content = useSelector((store: IRootState) => store.content.content);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const bookings = useSelector((store: IRootState) => store.booking.bookings);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const agendaViewType = useSelector((store: IRootState) => store.config.agendaViewType);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const { createSchedule, createStage } = useRematchDispatch((dispatch: Dispatch) => dispatch.content);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);

	useEffect(() => {
		if (scheduleDays && activeScheduleDate) {
			if (scheduleDayScrollRef.current) {
				let index = scheduleDays.findIndex((e) => e.date === activeScheduleDate);
				if (index === 0) scheduleDayScrollRef.current.scrollToOffset({ offset: 0, animated: true });
				if (index > 0) scheduleDayScrollRef.current.scrollToIndex({ index, viewOffset: 5 });
			}
		}
	}, [scheduleDays, activeScheduleDate]);

	useEffect(() => {
		if (!process.env.IS_TEST) {
			setAnimatedHeight(new Animated.Value(headerHeight ?? LISTHEADER_ANIMATION_GUESSED_HEIGHT));
			setAnimatedOpacity(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
		}
		setIsHeaderExpanded(true);

		switch (activeSpace?.agendaViewType) {
			case 'gridAndList':
				setViewType(agendaViewType ?? 'verticalGrid');
				break;
			case 'gridOnly':
				setViewType('verticalGrid');
				break;
			case 'listOnly':
				setViewType('list');
				break;
			case 'listAndGrid':
				setViewType(agendaViewType ?? 'list');
				break;
			default:
				setViewType(agendaViewType ?? 'list');
				break;
		}
	}, [agendaViewType, activeSpace]);

	useEffect(() => {
		let _feature: typeof feature = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Agenda')
			});
		}

		setFeature(_feature);
	}, [route, activeSpace]);

	useEffect(() => {
		navigation.setOptions({ onRightNavPress: () => bottomSheetRef.current?.open() });
	}, [bottomSheetRef]);

	useEffect(() => {
		let _myDayItems: any[] = [];
		let _hasMyDay = true;

		if (scheduleDays) {
			scheduleDays.forEach((day) => {
				const _items = day.items.filter((item) => {
					if (profile?.favorites && profile.favorites.schedule && profile.favorites.schedule[item.id]) {
						return true;
					}

					if (
						bookings.find((e2) => item.spaceId === activeSpace?.spaceId && e2.itemId === item?.id && item?.isBookable) !==
						undefined
					) {
						return true;
					}

					return false;
				});

				_myDayItems = _myDayItems.concat(_items);
			});
		}

		if (meetings) {
			meetings.forEach((meeting) => {
				if (shouldShowMeeting(meeting)) {
					_myDayItems.push(meeting);
				}
			});
		}

		_hasMyDay = _myDayItems.length > 0;

		setHasMyDay(_hasMyDay);
	}, [scheduleDays, profile, activeSpace, bookings, meetings]);

	useEffect(() => {
		let _days: typeof scheduleDays = [];

		if (activeSpace) {
			if (content?.schedules) {
				let _items = content.schedules.filter(
					(e) =>
						e.spaceId === activeSpace.spaceId &&
						e.startDate &&
						e.endDate &&
						e.stage?.id &&
						(!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
				);
				_items = filterListByFeature(_items, feature);

				_items.sort((a: ISchedule, b: ISchedule) => {
					const aMoment = moment(a.startDate);
					const bMoment = moment(b.startDate);
					if (aMoment.isSame(bMoment)) {
						const aStage = content.stages.find((s) => s.id === a.stage?.id && !s.isDeleted);
						const bSTage = content.stages.find((s) => s.id === b.stage?.id && !s.isDeleted);

						return (aStage?.order ?? 0) < (bSTage?.order ?? 0) ? -1 : 1;
					}
					return aMoment.isBefore(bMoment) ? -1 : 1;
				});

				_items.forEach((item) => {
					const itemDate = moment(item.startDate)
						.set('hours', 12)
						.set('minutes', 0)
						.set('seconds', 0)
						.set('milliseconds', 0)
						.toISOString();
					const day = _days.find((day) => day.date === itemDate);
					if (day) {
						day.items.push(item);
					} else {
						_days.push({
							date: itemDate,
							items: [item],
							meetings: []
						});
					}
				});
			}

			if (meetings) {
				meetings
					.filter((e) => shouldShowMeeting(e))
					.forEach((meeting) => {
						const itemDate = moment(meeting.timeSlot.start)
							.set('hours', 12)
							.set('minutes', 0)
							.set('seconds', 0)
							.set('milliseconds', 0)
							.toISOString();
						const day = _days.find((day) => day.date === itemDate);
						if (day) {
							day.meetings.push(meeting);
						} else {
							_days.push({
								date: itemDate,
								items: [],
								meetings: [meeting]
							});
						}
					});
			}

			_days.sort((a, b) => {
				const aVal = moment(a.date);
				const bVal = moment(b.date);

				return aVal.isBefore(bVal) ? -1 : 1;
			});

			setScheduleDays(_days);

			if (!daySet && _days.length > 0) {
				const _nextPossibleDay = _days.find((day) =>
					moment(day.date).isSameOrAfter(moment().set('hours', 0).set('minutes', 0).set('seconds', 0))
				);
				if (_nextPossibleDay) {
					setActiveScheduleDate(_nextPossibleDay.date);
					setDaySet(true);
				} else {
					setActiveScheduleDate(_days[_days.length - 1].date);
					setDaySet(true);
				}
			}
		}
	}, [activeSpace, iAmSpaceAdmin, iAmSpaceModerator, feature, content, meetings, bookings]);

	const shouldShowMeeting = (meeting: IMeeting) => {
		return (
			!meeting.isDeleted &&
			meeting.spaceId === activeSpace?.spaceId &&
			meeting.status === EMeetingStatus.Accepted &&
			moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isBefore(meeting.timeSlot.start)
		);
	};

	useEffect(() => {
		let _filtered: typeof scheduleDays = [...scheduleDays];

		if (activeScreenFilters.filters.includes('all')) {
			// Nothing to do
		} else {
			_filtered = [];

			scheduleDays.forEach((day) => {
				const items = day.items.filter((e) => {
					if (activeScreenFilters.fieldName) {
						for (const catItemKey of activeScreenFilters.filters) {
							if (e[activeScreenFilters.fieldName]) {
								const itemSplit = e[activeScreenFilters.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					_filtered.push(obj);
				}
			});
		}

		if (Object.keys(activeFilters).length > 0) {
			const temp: IScheduleDay[] = [];
			_filtered.forEach((day) => {
				let catKeys = Object.keys(activeFilters);
				catKeys = catKeys.filter((key) => key !== 'stage');
				let items = day.items.filter((e) => {
					for (const catKey of catKeys) {
						const splitted = activeFilters[catKey]?.split(',');
						for (const catItemKey of splitted) {
							if (e[catKey] && catKey !== 'stage') {
								const itemSplit = e[catKey]?.split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}
						return false;
					}

					return true;
				});
				items = items.filter((item) => {
					if (activeFilters['stage']) {
						if (item.stage?.title) {
							const splitted = activeFilters['stage']?.split(',');
							if (splitted && splitted.includes(item.stage?.title)) {
								return true;
							}
						}
						return false;
					}
					return true;
				});

				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					temp.push(obj);
				}
			});
			_filtered = [...temp];
		}

		setFilteredScheduledDays(_filtered);
	}, [scheduleDays, activeFilters, activeScreenFilters]);

	useEffect(() => {
		let _searched: typeof searchedScheduleDays = [...filteredScheduleDays];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();

			_searched = [];
			filteredScheduleDays.forEach((day) => {
				const items = day.items.filter((e) => {
					if (
						e.title.toLowerCase().includes(lowerSearch) ||
						e.subtitle?.toLowerCase().includes(lowerSearch) ||
						e.description?.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}

					for (const sp of e.speakers ?? []) {
						const speaker = content.speakers?.find((e2) => e2.id === sp.id);
						if (
							speaker?.title.toLowerCase().includes(lowerSearch) ||
							speaker?.company?.toLowerCase().includes(lowerSearch) ||
							speaker?.bio?.toLowerCase().includes(lowerSearch)
						) {
							return true;
						}
					}

					return false;
				});

				const obj = { ...day, items };

				if (obj.items.length > 0 || obj.meetings.length > 0) {
					_searched.push(obj);
				}
			});
		}

		if (showOnlyFavorites) {
			_searched = _searched.map((day) => {
				const _day = { ...day };
				_day.items = day.items.filter((e) => {
					if (profile?.favorites && profile.favorites.schedule && profile.favorites.schedule[e.id]) {
						return true;
					}

					if (bookings.find((e2) => e.spaceId === activeSpace?.spaceId && e2.itemId === e?.id && e?.isBookable) !== undefined) {
						return true;
					}

					return false;
				});
				return _day;
			});
		}

		if (!daySet) {
			const now = moment();

			const found = _searched.find((tab) => moment(tab.date).isSame(now, 'date'));
			if (found) {
				setActiveScheduleDate(found.date);
				setDaySet(true);
			}
		}

		setSearchedScheduleDays(_searched);
	}, [filteredScheduleDays, searchTerm, showOnlyFavorites, profile]);

	useEffect(() => {
		if (route.params.filter) {
			let _stage = content.stages.find((s) => !s.isDeleted && s.id === Number(route.params.filter));
			if (_stage) {
				setActiveFilters({ stage: _stage?.title });
			}
		}
	}, [route.params.filter]);

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'addSchedule':
				navigation.navigate(ERoutes.ScheduleCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'manageSchedules':
				navigation.navigate(ERoutes.ScheduleList, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'addStage':
				navigation.navigate(ERoutes.StageCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'manageStages':
				navigation.navigate(ERoutes.StageList, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'addStream':
				navigation.navigate(ERoutes.StreamCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'manageStreams':
				navigation.navigate(ERoutes.StreamList, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'addExample':
				setIsExampleLoading(true);
				let stage: IStage | undefined;
				const currentExampleStage = content?.stages?.find(
					(s) => s.spaceId === activeSpace?.spaceId && s.title === 'Stage Example' && !s.isDeleted
				);
				if (currentExampleStage) {
					stage = currentExampleStage;
				} else {
					const exampleStage = getStageExample();

					stage = await createStage({
						stage: exampleStage,
						fields: getContentTypeFieldsStage(),
						noToast: true
					});
				}

				let speaker = content?.speakers?.find((s) => s.id === 1);
				if (!speaker) speaker = content?.speakers?.find((s) => s.id === 2);
				const scheduleExamples = getSchedulesExample();
				for (const scheduleExample of scheduleExamples) {
					let schedule = scheduleExample;
					if (speaker) {
						schedule = { ...schedule, speakers: [speaker.id] };
					}
					await createSchedule({
						schedule: { ...scheduleExample, stage: stage?.id },
						fields: getContentTypeFieldsSchedule(),
						noToast: true
					});
				}
				setIsExampleLoading(false);
				bottomSheetRef.current?.close();
				break;

			default:
				break;
		}
	};

	const _animateHeader = (e: ScrollEvent) => {
		if (process.env.IS_TEST) {
			return;
		}
		const { y } = e.nativeEvent.contentOffset;

		if (
			headerHeight &&
			!IS_WEB &&
			scrollableContentHeight * LISTHEADER_ANIMATION_HEIGHT_FACTOR > Dimensions.get('window').height - headerHeight
		) {
			if (y > headerHeight * LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR) {
				if (isHeaderExpanded) {
					setIsHeaderExpanded(false);

					Animated.parallel([
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_LOW, LISTHEADER_ANIMATIONTIME_OUT, false),
						getTimingAnimation(animatedHeight, 0, LISTHEADER_ANIMATIONTIME_OUT, false)
					]).start();
				}
			} else if (
				y + (scrollOffset.current < LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION ? 0 : LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION) <
				scrollOffset.current
			) {
				if (!isHeaderExpanded) {
					setIsHeaderExpanded(true);
					Animated.parallel([
						getTimingAnimation(animatedHeight, headerHeight, LISTHEADER_ANIMATIONTIME_IN, false),
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_HIGH, LISTHEADER_ANIMATIONTIME_IN, false)
					]).start();
				}
			}
			scrollOffset.current = y;
		}
	};

	const _renderDaySwitch = () => {
		return (
			<View style={{ flexDirection: 'row', marginBottom: EHorizontalScreenPadding.Wide }}>
				{hasMyDay || showOnlyFavorites ? (
					<ChildButton
						key={`${TESTIDPREFIX}_button_onlyfavorites`}
						testID={`${TESTIDPREFIX}_button_onlyfavorites`}
						onPress={() => {
							setDaySet(true);
							setShowOnlyFavorites(!showOnlyFavorites);
						}}
						style={[
							{
								marginRight: 10,
								borderWidth: 1,
								borderRadius: 5,
								borderColor: theme.primary,
								paddingHorizontal: 5,
								paddingVertical: 2
							},
							showOnlyFavorites ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
						]}
					>
						<Text bold={showOnlyFavorites} style={{ color: showOnlyFavorites ? theme.primaryContrast : theme.primary }}>
							{t('My Day')}
						</Text>
					</ChildButton>
				) : null}
				<FlatList
					data={scheduleDays}
					ref={scheduleDayScrollRef}
					horizontal
					contentContainerStyle={{ flexGrow: 1 }}
					showsHorizontalScrollIndicator={false}
					keyExtractor={(item) => `${TESTIDPREFIX}_dayselect_${item.date}`}
					renderItem={({ item, index }) => {
						const isActive = activeScheduleDate === item.date;
						const isLastItem = index === scheduleDays.length - 1;

						return (
							<ChildButton
								key={`${TESTIDPREFIX}_button_dayselect_${index}`}
								testID={`${TESTIDPREFIX}_button_dayselect_${index}`}
								onPress={() => setActiveScheduleDate(item.date)}
								style={[
									{
										marginRight: isLastItem ? 2 : 10,
										borderWidth: 1,
										borderRadius: 5,
										borderColor: theme.primary,
										paddingHorizontal: 5,
										paddingVertical: 2
									},
									isActive ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
								]}
							>
								<Text bold={isActive} style={{ color: isActive ? theme.primaryContrast : theme.primary }}>
									{`${getDayOfWeekShort(item.date)} ${getDate(item.date)}.${getMonth(item.date)}`}
								</Text>
							</ChildButton>
						);
					}}
					onScrollToIndexFailed={(error) => console.log('scrollToIndex failed', error)} //scheduleDayScrollRef.current?.scrollToIndex({ index: 0 })}
				/>
			</View>
		);
	};

	const _renderEmptyGridAgendaEntry = () => {
		return (
			<Placeholder
				style={{
					height: 150,
					borderWidth: 1,
					borderColor: theme.lightgray,
					justifyContent: 'center',
					marginBottom: 20,
					borderRadius: 5
				}}
				Animation={ShineOverlay}
			>
				<PlaceholderLine style={{ width: '50%', marginTop: 5 }} />
				<PlaceholderLine />
				<PlaceholderLine style={{ height: 5 }} />
				<PlaceholderLine style={{ height: 5 }} />
				<PlaceholderLine style={{ height: 5 }} />
			</Placeholder>
		);
	};

	const _renderEmptyGridAgendaCol = () => {
		return (
			<View style={{ marginRight: 5 }}>
				<Placeholder
					style={{
						height: 70,
						borderWidth: 1,
						borderColor: theme.lightgray,
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 5,
						marginBottom: 20
					}}
					Animation={ShineOverlay}
				>
					<PlaceholderLine noMargin style={{ width: '80%', alignSelf: 'center' }} />
				</Placeholder>
				{_renderEmptyGridAgendaEntry()}
				{_renderEmptyGridAgendaEntry()}
			</View>
		);
	};

	const _renderEmptyAgenda = () => {
		const _viewType = route?.params?.agendaViewType ?? viewType;
		switch (_viewType) {
			case 'verticalGrid':
				return (
					<HSCard style={{ flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
						{_renderEmptyGridAgendaCol()}
						{_renderEmptyGridAgendaCol()}
					</HSCard>
				);
			case 'list':
				return (
					<HSCard style={{ flex: 1, overflow: 'hidden' }}>
						{_renderEmptyGridAgendaEntry()}
						{_renderEmptyGridAgendaEntry()}
						{_renderEmptyGridAgendaEntry()}
					</HSCard>
				);
			default:
				return null;
		}
	};

	const _renderSchedule = () => {
		const day = searchedScheduleDays.find((e) => e.date === activeScheduleDate);
		if (showOnlyFavorites && day?.meetings.length === 0 && day?.items.length === 0) {
			return <NoData type="NoFavorites" />;
		}

		if (day?.meetings.length === 0 && day?.items.length === 0) {
			if (waitingForSocketResponse?.schedules) {
				return _renderEmptyAgenda();
			}
			return <NoData type="NoSchedules" />;
		}

		if (searchedScheduleDays.length === 0 || !day) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}
			return <NoData type="NoSchedules" />;
		}

		switch (viewType) {
			case 'verticalGrid':
				return <NewGrid items={day?.items ?? []} meetings={day?.meetings ?? []} />;
				return <VerticalGrid items={day?.items ?? []} meetings={day?.meetings ?? []} />;
			case 'list':
				return (
					<ScheduleList
						items={day?.items ?? []}
						meetings={day?.meetings ?? []}
						onScroll={(e) => _animateHeader(e)}
						onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
					/>
				);
			default:
				return null;
		}
	};

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params, mediaDetail]);

	const backAction = () => {
		if (mediaDetail?.itemId && mediaDetail.viewType !== 'collapsed') {
			setMediaDetail({
				itemId: undefined,
				viewType: 'full',
				playbackStatus: 'paused'
			});
		}
		if (navigation.canGoBack()) {
			if (navigation.isFocused()) {
				navigation.goBack();
			} else {
				navigation.pop();
			}
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}
		return true;
	};

	return (
		<ScreenContainer contentKey="schedules">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: '100%',
					alignSelf: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				<Animated.View
					style={{
						height: animatedHeight,
						opacity: animatedOpacity,
						marginBottom: isHeaderExpanded ? hsBottomMargin : 0
					}}
				>
					<ContentListHeader
						testID={TESTIDPREFIX}
						type="schedule"
						searchTerm={searchTerm}
						onSearchChange={(val) => setSearchTerm(val)}
						modalFilterTitle={t('Filter Agenda')}
						activeScreenFilters={activeScreenFilters.filters}
						onScreenFilterChange={(values) => setActiveScreenFilters(values)}
						activeModalFilters={activeFilters}
						onModalFilterChange={(val) => {
							setActiveFilters(val);
							navigation.setParams({ filter: '' });
						}}
						feature={feature}
						onLayout={(e) => {
							const { height } = e.nativeEvent.layout;
							if (process.env.IS_TEST) {
								return;
							}
							if (!headerHeight || height > headerHeight) {
								setHeaderHeight(height);
								setAnimatedHeight(new Animated.Value(height));
							}
						}}
						topChildren={_renderDaySwitch()}
					/>
				</Animated.View>
				{_renderSchedule()}
				<View style={{ width: '100%', alignSelf: 'center' }}>
					<SponsorBlock contentType={'agenda'} />
				</View>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_manageschedules`}
						icon={EDefaultIconSet.Edit}
						label={t('Manage Schedules')}
						onPress={() => _handleActionSheetPress('manageSchedules')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_managestages`}
						icon={EDefaultIconSet.Edit}
						label={t('Manage Stages')}
						onPress={() => _handleActionSheetPress('manageStages')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_managestreams`}
						icon={EDefaultIconSet.Edit}
						label={t('Manage Streams')}
						onPress={() => _handleActionSheetPress('manageStreams')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isLoading={isExampleLoading}
						isDisabled={isExampleLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const ScheduleScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderScheduleTypeButton params={params} onPress={(key) => navigation.setParams({ agendaViewType: key })} />
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};

export const ScheduleScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<View style={{ flexDirection: 'row' }}>
				<NavigationHeaderScheduleTypeButton params={params} onPress={(key) => navigation.setParams({ agendaViewType: key })} />
				<NavigationHeaderDropdown options={props.options} />
			</View>
		</NavigationHeader>
	);
};
