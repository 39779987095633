import React from 'react';
import { View, ViewStyle } from 'react-native';
import { FormTextInput } from './FormTextInput';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTranslation } from 'react-i18next';
import { ERoutes } from 'components/Navigation/routes';
import { RoundButton } from 'components/Button';
import { addToClipboard, EDefaultIconSet, IS_WEB, openURL } from 'helper';

interface IFormShareComponent {
	testIdPrefix: string;
	type: 'spacePin' | 'spaceOnboarding' | 'publicAgenda' | 'landingpage' | 'prestart';
	label?: string;
	hint?: string;
	formStyle?: ViewStyle;
	tempPin?: string | null;
}

export const FormShare = (props: IFormShareComponent) => {
	const { testIdPrefix, type, label, hint, formStyle, tempPin } = props;
	const { activeSpace } = useSpace();
	const { t } = useTranslation();
	const { isTabletOrMobile } = useQuery();

	const _getUrl = (type: string) => {
		switch (type) {
			case 'spacePin':
				return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/spacesummary/${activeSpace?.spaceId}/_/${tempPin}`;
			case 'spaceOnboarding':
				return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/${activeSpace?.spaceId}/${ERoutes.Onboarding}`;
			case 'publicAgenda':
				return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}presentation/publicagenda/${activeSpace?.spaceId}`;
			case 'landingpage':
				return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/${activeSpace?.spaceId}/${ERoutes.Landingpage}`;
			case 'prestart':
				return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/${activeSpace?.spaceId}/${ERoutes.Prestart}`;
			default:
				return '';
		}
	};

	return (
		<View style={formStyle} key={`${testIdPrefix}_share_onboarding`}>
			<FormTextInput
				testID={`${testIdPrefix}_${type}_url`}
				label={label}
				hint={hint}
				value={_getUrl(type)}
				formStyle={{ marginBottom: 10 }}
				multiline
				isCopyField
				editable={false}
				selectTextOnFocus
			/>
			<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
				<View style={{ marginRight: 5 }}>
					<RoundButton
						icon={EDefaultIconSet.Clone}
						testID={`${testIdPrefix}_${type}_addtoclipboard`}
						onPress={() => {
							addToClipboard(_getUrl(type));
						}}
						title={t('copyUrl')}
						size={IS_WEB && !isTabletOrMobile ? undefined : 'sm'}
						noMargin
					/>
				</View>
				<RoundButton
					icon={EDefaultIconSet.ExternalUrl}
					testID={`${testIdPrefix}_${type}_openuRL`}
					onPress={() => {
						openURL(_getUrl(type));
					}}
					title={t('openInNewTab')}
					size={IS_WEB && !isTabletOrMobile ? undefined : 'sm'}
					noMargin
				/>
			</View>
		</View>
	);

	return null;
};
