import React from 'react';
import { TContentType } from 'hooks/useContent';
import { IExpo } from 'config/interfaces';

interface ISponsorGrid {
	height?: number;
	handlePress: (sponsor: IExpo) => void;
}

export const SponsorGrid = (props: ISponsorGrid) => {
	const { height, handlePress } = props;

	return null;
};
