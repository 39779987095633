import { ChildButton, RoundButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, isEmptyString, IS_WEB, normalizeFontSize } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode, useEffect, useState } from 'react';
import { TextInput, TouchableOpacity, View, ViewStyle } from 'react-native';
import { HSCard } from './HSCard';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { hsActionButtonMargin, hsBorderRadius } from 'config/styleConstants';

interface IHSDragCard {
	testID: string;
	isActive?: boolean;
	isDisabled?: boolean;
	isSelected?: boolean;
	index: number;
	handleIndexChange: (newIndex: number) => void;
	onDrag?: () => void;
	onSelect?: () => void;
	onEdit?: () => void;
	onRemove?: () => void;
	children?: ReactNode | ReactNode[];
	deleteTitle?: string;
	deleteSubTitle?: string;
	style?: ViewStyle;
	onUpPress?: () => void;
	onDownPress?: () => void;
	onPress?: () => void;
	noCard?: boolean;
}

export const HSDragCard = (props: IHSDragCard) => {
	const {
		testID,
		isActive,
		isDisabled,
		isSelected,
		index,
		handleIndexChange,
		onDrag,
		onSelect,
		onEdit,
		onRemove,
		children,
		deleteTitle,
		deleteSubTitle,
		style,
		onUpPress,
		onDownPress,
		onPress,
		noCard
	} = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [tempIndex, setTempIndex] = useState<string>('');

	useEffect(() => {
		setTempIndex(index.toString());
	}, [index, testID, handleIndexChange]);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _renderAction = () => {
		if (onSelect) {
			return (
				<FormCheckbox
					testID={`${testID}_checkbox_select`}
					value={isSelected}
					onPress={() => onSelect()}
					style={{ marginBottom: 0 }}
				/>
			);
		}
		if (onDrag && !IS_WEB) {
			return (
				<TouchableOpacity testID={`${testID}_drag`} delayLongPress={100} onLongPress={onDrag} disabled={isDisabled}>
					<Icon name={EDefaultIconSet.DragHandler} size={normalizeFontSize(32)} color={theme.formgray} />
				</TouchableOpacity>
			);
		}
		if ((onUpPress || onDownPress) && IS_WEB) {
			return (
				<View
					style={{
						height: '100%',
						flexDirection: 'row',
						alignItems: 'center'
					}}
				>
					<TextInput
						testID={`${testID}_option_input_${tempIndex}`}
						style={{
							borderWidth: 1,
							borderRadius: hsBorderRadius,
							borderColor: theme.lightgray,
							padding: 5,
							maxWidth: 35,
							textAlign: 'center',
							color: theme.text
						}}
						value={tempIndex}
						onChangeText={(val) => setTempIndex(val.replace(/\D/, ''))}
						onBlur={(e) => {
							if (isEmptyString(tempIndex)) {
								setTempIndex(index.toString());
								handleIndexChange(index);
							} else {
								handleIndexChange(Number(e.nativeEvent.text));
								setTempIndex(e.nativeEvent.text);
							}
						}}
					/>
					<View>
						{onUpPress && (
							<RoundButton
								alignSelf="center"
								testID={`${testID}_up`}
								icon={EDefaultIconSet.ArrowUp}
								onPress={onUpPress}
								size="sm"
								isOutline
								noMargin
							/>
						)}
						{onDownPress && (
							<RoundButton
								alignSelf="center"
								testID={`${testID}_down`}
								icon={EDefaultIconSet.ArrowDown}
								onPress={onDownPress}
								size="sm"
								isOutline
								noMargin
							/>
						)}
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		return (
			<>
				{_renderAction()}

				<View style={{ flex: 1, paddingHorizontal: 10 }}>{children}</View>

				<View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end' }}>
					{onEdit && (
						<View style={{ marginRight: hsActionButtonMargin }}>
							<RoundButton
								isOutline
								icon={EDefaultIconSet.Edit}
								testID={`${testID}_edit`}
								onPress={() => onEdit()}
								isDisabled={isDisabled}
								noMargin
							/>
						</View>
					)}
					{onRemove && (
						<RoundButton
							isOutline
							color={theme.danger}
							icon={EDefaultIconSet.Delete}
							testID={`${testID}_remove`}
							onPress={() => {
								showAlert({
									title: deleteTitle ?? t('Delete Option'),
									message: deleteSubTitle ?? t('DeleteOptionSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: deleteTitle ?? t('Delete Option'),
											style: 'destructive',
											onPress: async () => onRemove()
										}
									]
								});
							}}
							isDisabled={isDisabled}
							noMargin
						/>
					)}
				</View>
			</>
		);
	};

	const _renderContainer = () => {
		if (noCard) {
			return (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						backgroundColor: isActive ? theme.lightgray : theme.contentBackgroundColor ?? theme.background,
						width: '100%',
						alignSelf: 'center',
						...style
					}}
				>
					{_renderContent()}
				</View>
			);
		}

		return (
			<HSCard
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					backgroundColor: isActive ? theme.lightgray : theme.contentBackgroundColor ?? theme.background,
					width: '100%',
					alignSelf: 'center',
					...style
				}}
			>
				{_renderContent()}
			</HSCard>
		);
	};

	return (
		<ChildButton onPress={onPress} testID={`${testID}_cardpress`} activeOpacity={onPress ? 0 : 1}>
			{_renderContainer()}
		</ChildButton>
	);
};
