import { DEEPLINK_SHORT, DEFAULT_PLATFORM_URL } from 'config/envConstants';
import * as Linking from 'expo-linking';

export enum ERoutes {
	Onboarding = 'onboarding',
	SpaceOnboarding = 'spaceonboarding',
	SpaceSelect = 'spaceselect',
	SpaceSearch = 'spacesearch',
	SpaceSummary = 'spacesummary',
	SpaceCreate = 'spacecreate',
	MySpaces = 'myspaces',
	Changelogs = 'changelogs',

	SpaceAuth = 'auth',
	ConfirmAccount = 'confirm',

	Web = 'web',
	Markdown = 'markdown',

	VoteList = 'votingslist',
	VotingCreate = 'createvoting',
	VotingEdit = 'editvoting',
	VotingModeration = 'votingmoderation',
	ActiveVoting = 'activevoting',
	ActiveVotingAdmin = 'activevotingadmin',
	VoteAnalytics = 'voteanalytics',

	Administrate = 'administrate',
	EditSpace = 'editspace',
	EditSpaceDesign = 'editspacedesign',
	EditSpaceTexts = 'editspacetexts',
	EditSpaceSponsors = 'editspacesponsors',
	SpaceAdmins = 'spaceadmins',
	CustomUserConstent = 'customuserconstent',
	SpaceRegistrationFields = 'registrationfields',
	Features = 'features',
	EditFeature = 'editfeature',
	ContentTypeFields = 'contenttypefields',
	WelcomeEdit = 'welcomeedit',
	EditLandingpage = 'editlandingpage',
	Landingpage = 'home',
	Prestart = 'prestart',

	PushNotificationList = 'pushnotificationlist',
	PushNotification = 'pushnotification',

	SupportRequestList = 'supportrequestlist',
	SupportRequest = 'supportrequest',

	NewsList = 'newslist',
	NewsCreate = 'newscreate',
	NewsEdit = 'newsedit',
	NewsDetails = 'newsdetails',

	Schedule = 'schedule',
	ScheduleCreate = 'schedulecreate',
	ScheduleEdit = 'scheduleedit',
	ScheduleDetails = 'scheduledetails',
	ScheduleList = 'schedulelist',

	StreamCreate = 'streamcreate',
	StreamEdit = 'streamedit',
	StreamList = 'streamlist',
	StreamDetails = 'streamdetails',

	MediaItemCreate = 'mediaitemcreate',
	MediaItemEdit = 'mediaitemedit',
	MediaItemList = 'mediaitemlist',
	MediaItemDetails = 'mediaitemdetails',

	StageCreate = 'stagecreate',
	StageEdit = 'stageedit',
	StageList = 'stagelist',

	Expos = 'expos',
	ExpoCreate = 'expocreate',
	ExpoEdit = 'expoedit',
	ExpoDetails = 'expodetails',
	Sponsors = 'sponsors',

	Speakers = 'speakers',
	SpeakerDetails = 'speakerdetails',
	SpeakerCreate = 'speakercreate',
	SpeakerEdit = 'speakeredit',

	NetworkingRoomList = 'networkingroomlist',
	NetworkingRoomCreate = 'networkingroomcreate',
	NetworkingRoomUpdate = 'networkingroomupdate',
	NetworkingRoom = 'networkingroom',

	AttendeesList = 'attendeeslist',
	AttendeeDetails = 'attendeedetails',

	MeetingList = 'meetinglist',
	MeetingRequest = 'meetingrequest',
	MeetingSettings = 'meetingsettings',
	Meetings = 'meetings',

	ChatOverview = 'chatoverview',
	Chat = 'chat',

	MailHistory = 'mailhistory',
	MailHistoryDetails = 'mailhistorydetails',
	MailTemplateList = 'mailtemplatelist',
	EditMailTemplate = 'editmailtemplate',
	MailSettings = 'mailsettings',
	SendMail = 'sendmail',

	EditProfile = 'editprofile',
	Tutorial = 'tutorial',
	Components = 'components',
	HspxAdmin = 'hspxadmin',
	More = 'more',
	ImportData = 'importdata',

	SpeakerView = 'speakerview',
	ResultView = 'resultview',
	PublicAgenda = 'publicagenda',

	SelfService = 'selfservice',
	SelfServiceSettings = 'selfservicesettings',

	TicketList = 'ticketlist',
	TicketCreate = 'ticketcreate',
	TicketEdit = 'ticketedit',
	TicketSettings = 'ticketsettings',

	Maps = 'maps',
	ExternalMaps = 'externalmaps',
	MapCreate = 'mapcreate',
	MapEdit = 'mapedit',
	MapPositionsEdit = 'mappositionsedit',
	MapOrder = 'maporder',

	BookingList = 'bookings',
	BookingDetails = 'bookingdetails',
	BookingImport = 'bookingimport',

	MeetingTableList = 'meetingtablelist',
	MeetingTableCreate = 'meetingtablecreate',
	MeetingTableEdit = 'meetingtableedit',

	Webhooks = 'webhooks',
	WebhookCreate = 'webhookcreate',
	WebhookUpdate = 'webhookupdate',
	WebhookHistory = 'webhookhistory',

	Matchings = 'matchings',

	Reports = 'reports',
	Statistics = 'statistics',

	VotingsTab = 'votingstab',
	FeedTab = 'newstab',
	SettingsTab = 'settingstab',
	SpeakersTab = 'speakerstab',
	AttendeesTab = 'attendeestab',
	ExposTab = 'expostab',
	SponsorsTab = 'sponsorstab',
	ScheduleTab = 'scheduletab',
	ChatTab = 'chattab',
	MeetingTab = 'meetingtab',
	NetworkingTab = 'networkingtab',
	MediaItemsTab = 'mediaitemstab',
	MapTab = 'maptab',
	WebTab = 'webtab',
	MarkdownTab = 'markdowntab',
	Media = 'media',
	ApiToken = 'apitoken'
}

const prefix = Linking.createURL('/');

export const getLinking = () => {
	const tabsWithCategories = [
		ERoutes.ScheduleTab,
		ERoutes.SpeakersTab,
		ERoutes.ExposTab,
		ERoutes.AttendeesTab,
		ERoutes.MediaItemsTab,
		ERoutes.WebTab,
		ERoutes.MarkdownTab,
		ERoutes.SponsorsTab
	];

	const obj = {
		prefixes: [DEFAULT_PLATFORM_URL, DEEPLINK_SHORT, prefix],
		config: {
			screens: {
				app: {
					path: 'app',
					screens: {
						tab: {
							screens: {
								[ERoutes.VotingsTab]: {
									path: `:spaceId/${ERoutes.VotingsTab}`
								},
								[ERoutes.FeedTab]: {
									path: `:spaceId/${ERoutes.FeedTab}`
								},
								[ERoutes.ChatTab]: {
									path: `:spaceId/${ERoutes.ChatTab}`
								},
								[ERoutes.MeetingTab]: {
									path: `:spaceId/${ERoutes.MeetingTab}`
								},
								[ERoutes.NetworkingTab]: {
									path: `:spaceId/${ERoutes.NetworkingTab}`
								},
								[ERoutes.SettingsTab]: {
									path: `:spaceId/${ERoutes.SettingsTab}`
								},
								[ERoutes.MapTab]: {
									path: `:spaceId/${ERoutes.MapTab}`
								}
							}
						},
						//
						// Auth
						//
						[ERoutes.ConfirmAccount]: {
							path: `${ERoutes.ConfirmAccount}/:code`
						},
						[ERoutes.SpaceAuth]: {
							path: `${ERoutes.SpaceAuth}/:authState?/:email?/:code?/:redirectUrl?/:redirectRoute?`
						},
						//
						// Vote
						//
						[ERoutes.VoteList]: {
							path: `:spaceId/${ERoutes.VoteList}/:key?`
						},
						[ERoutes.VotingCreate]: {
							path: `:spaceId/${ERoutes.VotingCreate}`
						},
						[ERoutes.VotingEdit]: {
							path: `:spaceId/${ERoutes.VotingEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.VotingModeration]: {
							path: `:spaceId/${ERoutes.VotingModeration}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.ActiveVoting]: {
							path: `:spaceId/${ERoutes.ActiveVoting}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.ActiveVotingAdmin]: {
							path: `:spaceId/${ERoutes.ActiveVotingAdmin}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.VoteAnalytics]: {
							path: `:spaceId/${ERoutes.VoteAnalytics}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Settings
						//
						[ERoutes.Administrate]: {
							path: `:spaceId/${ERoutes.Administrate}`
						},
						[ERoutes.Changelogs]: {
							path: `:spaceId/${ERoutes.Changelogs}`
						},
						[ERoutes.EditSpace]: {
							path: `:spaceId/${ERoutes.EditSpace}`
						},
						[ERoutes.EditSpaceDesign]: {
							path: `:spaceId/${ERoutes.EditSpaceDesign}`
						},
						[ERoutes.EditSpaceTexts]: {
							path: `:spaceId/${ERoutes.EditSpaceTexts}`
						},
						[ERoutes.EditSpaceSponsors]: {
							path: `:spaceId/${ERoutes.EditSpaceSponsors}`
						},
						[ERoutes.SpaceAdmins]: {
							path: `:spaceId/${ERoutes.SpaceAdmins}`
						},
						[ERoutes.EditLandingpage]: {
							path: `:spaceId/${ERoutes.EditLandingpage}`
						},
						[ERoutes.CustomUserConstent]: {
							path: `:spaceId/${ERoutes.CustomUserConstent}`
						},
						[ERoutes.SpaceRegistrationFields]: {
							path: `:spaceId/${ERoutes.SpaceRegistrationFields}`
						},
						[ERoutes.Features]: {
							path: `:spaceId/${ERoutes.Features}`
						},
						[ERoutes.EditFeature]: {
							path: `:spaceId/${ERoutes.EditFeature}`
						},
						[ERoutes.ContentTypeFields]: {
							path: `:spaceId/${ERoutes.ContentTypeFields}/:type`
						},
						[ERoutes.SelfServiceSettings]: {
							path: `:spaceId/${ERoutes.SelfServiceSettings}`
						},
						[ERoutes.SupportRequest]: {
							path: `:spaceId/${ERoutes.SupportRequest}`
						},
						[ERoutes.SupportRequestList]: {
							path: `:spaceId/${ERoutes.SupportRequestList}`
						},
						//
						// Push
						//
						[ERoutes.PushNotificationList]: {
							path: `:spaceId/${ERoutes.PushNotificationList}`
						},
						[ERoutes.PushNotification]: {
							path: `:spaceId/${ERoutes.PushNotification}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Support Requests
						//
						[ERoutes.SupportRequestList]: {
							path: `:spaceId/${ERoutes.SupportRequestList}`
						},
						[ERoutes.SupportRequest]: {
							path: `:spaceId/${ERoutes.SupportRequest}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// News
						//
						[ERoutes.NewsList]: {
							path: `:spaceId/${ERoutes.NewsList}/:key`
						},
						[ERoutes.NewsCreate]: {
							path: `:spaceId/${ERoutes.NewsCreate}/:newsId?`,
							parse: {
								newsId: (newsId) => Number(newsId)
							}
						},
						[ERoutes.NewsEdit]: {
							path: `:spaceId/${ERoutes.NewsEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.NewsDetails]: {
							path: `:spaceId/${ERoutes.NewsDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Schedule
						//
						[ERoutes.Schedule]: {
							path: `:spaceId/${ERoutes.Schedule}/:key?/:filter?`
						},
						[ERoutes.ScheduleCreate]: {
							path: `:spaceId/${ERoutes.ScheduleCreate}/:scheduleId?`,
							parse: {
								scheduleId: (scheduleId) => Number(scheduleId)
							}
						},
						[ERoutes.ScheduleEdit]: {
							path: `:spaceId/${ERoutes.ScheduleEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.ScheduleList]: {
							path: `:spaceId/${ERoutes.ScheduleList}`
						},
						//
						// Stream
						//
						[ERoutes.StreamList]: {
							path: `:spaceId/${ERoutes.StreamList}`
						},
						[ERoutes.StreamCreate]: {
							path: `:spaceId/${ERoutes.StreamCreate}/:streamId?`,
							parse: {
								streamId: (streamId) => Number(streamId)
							}
						},
						[ERoutes.StreamEdit]: {
							path: `:spaceId/${ERoutes.StreamEdit}`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.StreamDetails]: {
							path: `:spaceId/${ERoutes.StreamDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// MediaItem
						//
						[ERoutes.MediaItemList]: {
							path: `:spaceId/${ERoutes.MediaItemList}/:key?`
						},
						[ERoutes.MediaItemCreate]: {
							path: `:spaceId/${ERoutes.MediaItemCreate}/:mediaItemId?`,
							parse: {
								mediaItemId: (mediaItemId) => Number(mediaItemId)
							}
						},
						[ERoutes.MediaItemEdit]: {
							path: `:spaceId/${ERoutes.MediaItemEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Stage
						//
						[ERoutes.StageList]: {
							path: `:spaceId/${ERoutes.StageList}`
						},
						[ERoutes.StageCreate]: {
							path: `:spaceId/${ERoutes.StageCreate}/:stageId?`,
							parse: {
								stageId: (stageId) => Number(stageId)
							}
						},
						[ERoutes.StageEdit]: {
							path: `:spaceId/${ERoutes.StageEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Expo
						//
						[ERoutes.Expos]: {
							path: `:spaceId/${ERoutes.Expos}/:key?`
						},
						[ERoutes.ExpoCreate]: {
							path: `:spaceId/${ERoutes.ExpoCreate}/:expoId?`,
							parse: {
								expoId: (expoId) => Number(expoId)
							}
						},
						[ERoutes.ExpoEdit]: {
							path: `:spaceId/${ERoutes.ExpoEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.ExpoDetails]: {
							path: `:spaceId/${ERoutes.ExpoDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.Sponsors]: {
							path: `:spaceId/${ERoutes.Sponsors}`
						},
						//
						// Speaker
						//
						[ERoutes.Speakers]: {
							path: `:spaceId/${ERoutes.Speakers}/:key?`
						},
						[ERoutes.SpeakerCreate]: {
							path: `:spaceId/${ERoutes.SpeakerCreate}/:speakerId?`,
							parse: {
								speakerId: (speakerId) => Number(speakerId)
							}
						},
						[ERoutes.SpeakerEdit]: {
							path: `:spaceId/${ERoutes.SpeakerEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.SpeakerDetails]: {
							path: `:spaceId/${ERoutes.SpeakerDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Networking
						//
						[ERoutes.NetworkingRoomList]: {
							path: `:spaceId/${ERoutes.NetworkingRoomList}/:key`
						},
						[ERoutes.NetworkingRoomCreate]: {
							path: `:spaceId/${ERoutes.NetworkingRoomCreate}/:networkingRoomId?`,
							parse: {
								networkingRoomId: (networkingRoomId) => Number(networkingRoomId)
							}
						},
						[ERoutes.NetworkingRoomUpdate]: {
							path: `:spaceId/${ERoutes.NetworkingRoomUpdate}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.NetworkingRoom]: {
							path: `:spaceId/${ERoutes.NetworkingRoom}/:id/:title?`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Attendee
						//
						[ERoutes.AttendeesList]: {
							path: `:spaceId/${ERoutes.AttendeesList}/:key`
						},
						[ERoutes.AttendeeDetails]: {
							path: `:spaceId/${ERoutes.AttendeeDetails}/:userId`
						},
						//
						// Meeting
						//
						[ERoutes.MeetingList]: {
							path: `:spaceId/${ERoutes.MeetingList}/:key`
						},
						[ERoutes.MeetingRequest]: {
							path: `:spaceId/${ERoutes.MeetingRequest}/:partnerId/:id?`,
							parse: {
								partnerId: (partnerId) => Number(partnerId),
								id: (id) => Number(id)
							}
						},
						[ERoutes.MeetingSettings]: {
							path: `:spaceId/${ERoutes.MeetingSettings}`
						},
						//
						// Chat
						//
						[ERoutes.ChatOverview]: {
							path: `:spaceId/${ERoutes.ChatOverview}/:key`
						},
						[ERoutes.Chat]: {
							path: `:spaceId/${ERoutes.Chat}/:userId/:title?`
						},
						//
						// Mailing
						//
						[ERoutes.MailHistory]: {
							path: `:spaceId/${ERoutes.MailHistory}`
						},
						[ERoutes.MailHistoryDetails]: {
							path: `:spaceId/${ERoutes.MailHistoryDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.MailTemplateList]: {
							path: `:spaceId/${ERoutes.MailTemplateList}`
						},
						[ERoutes.EditMailTemplate]: {
							path: `:spaceId/${ERoutes.EditMailTemplate}/:id?/:key?`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.MailSettings]: {
							path: `:spaceId/${ERoutes.MailSettings}`
						},
						[ERoutes.SendMail]: {
							path: `:spaceId/${ERoutes.SendMail}`
						},
						//
						// Ticket
						//
						[ERoutes.TicketList]: {
							path: `:spaceId/${ERoutes.TicketList}`
						},
						[ERoutes.TicketCreate]: {
							path: `:spaceId/${ERoutes.TicketCreate}`
						},
						[ERoutes.TicketEdit]: {
							path: `:spaceId/${ERoutes.TicketEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.TicketSettings]: {
							path: `:spaceId/${ERoutes.TicketSettings}`
						},
						//
						// Map
						//
						[ERoutes.Maps]: {
							path: `:spaceId/${ERoutes.Maps}/:key/:mapId/point/:positionId`,
							parse: {
								mapId: (mapId) => Number(mapId),
								positionId: (positionId) => Number(positionId)
							}
						},
						[ERoutes.MapCreate]: {
							path: `:spaceId/${ERoutes.MapCreate}`
						},
						[ERoutes.MapEdit]: {
							path: `:spaceId/${ERoutes.MapEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.MapPositionsEdit]: {
							path: `:spaceId/${ERoutes.MapPositionsEdit}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.MapOrder]: {
							path: `:spaceId/${ERoutes.MapOrder}`
						},
						//
						// Webhook
						//
						[ERoutes.Webhooks]: {
							path: `:spaceId/${ERoutes.Webhooks}/:key?`
						},
						[ERoutes.WebhookCreate]: {
							path: `:spaceId/${ERoutes.WebhookCreate}`
						},
						[ERoutes.WebhookUpdate]: {
							path: `:spaceId/${ERoutes.WebhookUpdate}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						//
						// Misc
						//
						[ERoutes.Media]: {
							path: `:spaceId/${ERoutes.Media}/:mediaType/:itemId`,
							parse: {
								itemId: (itemId) => Number(itemId)
							}
						},
						[ERoutes.Matchings]: {
							path: `:spaceId/${ERoutes.Matchings}`
						},
						[ERoutes.More]: {
							path: `:spaceId/${ERoutes.More}`
						},
						[ERoutes.ImportData]: {
							path: `:spaceId/${ERoutes.ImportData}/:type`
						},
						[ERoutes.SpeakerView]: {
							path: `:spaceId/${ERoutes.SpeakerView}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.SpaceOnboarding]: {
							path: `:spaceId/${ERoutes.Onboarding}`
						},
						[ERoutes.Landingpage]: {
							path: `:spaceId/${ERoutes.Landingpage}`
						},
						[ERoutes.Prestart]: {
							path: `:spaceId/${ERoutes.Prestart}`
						},
						[ERoutes.ExternalMaps]: {
							path: `:spaceId/${ERoutes.ExternalMaps}/point/:positionId`,
							parse: {
								positionId: (positionId) => Number(positionId)
							}
						},
						[ERoutes.SelfService]: {
							path: `${ERoutes.SelfService}/:token/:title?/`
						},
						[ERoutes.Reports]: {
							path: `:spaceId/${ERoutes.Reports}`
						},
						[ERoutes.Statistics]: {
							path: `:spaceId/${ERoutes.Statistics}`
						},
						[ERoutes.ApiToken]: {
							path: `:spaceId/${ERoutes.ApiToken}`
						},
						[ERoutes.SpaceSummary]: {
							path: `${ERoutes.SpaceSummary}/:spaceId/:ticketcode?/:pin?`,
							parse: {
								ticketcode: (ticketcode) => (ticketcode !== '_' ? ticketcode : undefined),
								pin: (pin) => pin
							}
						},
						[ERoutes.Web]: {
							path: `:spaceId/${ERoutes.Web}/:key`
						},
						[ERoutes.Markdown]: {
							path: `:spaceId/${ERoutes.Markdown}/:key`
						},
						[ERoutes.BookingList]: {
							path: `:spaceId/${ERoutes.BookingList}`
						},
						[ERoutes.BookingDetails]: {
							path: `:spaceId/${ERoutes.BookingDetails}/:id`,
							parse: {
								id: (id) => Number(id)
							}
						},
						[ERoutes.BookingImport]: {
							path: `:spaceId/${ERoutes.BookingImport}/:type/:id`,
							parse: {
								id: (id) => Number(id)
							}
						}
					}
				},
				presentation: {
					path: 'presentation',
					screens: {
						[ERoutes.ResultView]: {
							path: `${ERoutes.ResultView}/:code`
						},
						[ERoutes.PublicAgenda]: {
							path: `${ERoutes.PublicAgenda}/:spaceId`
							// parse: {
							// 	spaceId: (spaceId) => Number(spaceId)
							// }
						},
						[ERoutes.Media]: {
							path: `${ERoutes.Media}/:mediaType/:itemId`,
							parse: {
								itemId: (itemId) => Number(itemId)
							}
						}
					}
				},
				[ERoutes.EditProfile]: {
					path: `${ERoutes.EditProfile}/:spaceId?`
				},
				[ERoutes.Tutorial]: {
					path: `${ERoutes.Tutorial}`
				},
				[ERoutes.Components]: {
					path: `${ERoutes.Components}`
				}
			}
		}
	};
	tabsWithCategories.forEach((entry, idx) => {
		obj.config.screens.app.screens.tab.screens[entry] = {
			path: `:spaceId/${entry}`
		};
		for (let i = 1; i <= 5; i++) {
			obj.config.screens.app.screens.tab.screens[entry + i] = {
				path: `:spaceId/${entry}${i}`
			};
		}
	});

	return obj;
};
