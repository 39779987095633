import React, { useEffect, useRef, useState } from 'react';
import { useTheme } from 'hooks/useTheme';
import { SafeAreaView, View } from 'react-native';
import { useFonts } from 'expo-font';
import { Spinner } from 'components/Spinner';
import { StatusBar } from 'expo-status-bar';
import { isDarkColor, isEmptyString, IS_ANDROID, IS_WEB } from 'helper';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { v4 } from 'uuid';
import { SpaceGate } from 'components/Gate';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSAlert } from 'components/Alert';
import { NavigationContainer } from '@react-navigation/native';
import { getLinking } from 'components/Navigation/routes';
import { navigationRef } from './RootNavigation';
import { PushHandler } from 'components/PushHandler';
import { AppNavigator } from 'components/Navigation/AppNavigator';
import { MediaModal, WelcomeModal } from 'components/Modal';
import { ILauncherSpace } from 'config/interfaces';
import { useSpace } from 'hooks/useSpace';
import { optimizeHeavyScreen } from 'react-navigation-heavy-screen';
import NetInfo from '@react-native-community/netinfo';
import * as Device from 'expo-device';
import { cleanupTimes } from 'config/cleanup';
import moment from 'moment';
import Toast from 'react-native-toast-notifications';
import { BaseToast } from 'components/Toast';
import { hsToastOffsetWeb, hsToastOffsetMobile } from 'config/styleConstants';

const MyApp = () => {
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const activeSpaceRef = useRef<ILauncherSpace | undefined>(undefined);

	const [isReady, setIsReady] = useState<boolean>(false);
	const [fontsLoaded] = useFonts({
		Fontello: require('../assets/fonts/Fontello.ttf')
	});

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const lastVoteAnswerReset = useSelector((store: IRootState) => store.vote.lastVoteAnswerReset);

	const setUserInfos = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.setUserInfos);
	const setNetInfoState = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setNetInfoState);
	const setDeviceInfo = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setDeviceInfo);
	const resetLocalVoteAnswers = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.resetLocalVoteAnswers);

	useEffect(() => {
		const netinfoListener = NetInfo.addEventListener((netInfoState) => setNetInfoState({ netInfoState }));
		_init();

		return () => {
			netinfoListener();
		};
	}, []);

	useEffect(() => {
		activeSpaceRef.current = activeSpace;
	}, [activeSpace]);

	const _init = async () => {
		if (isEmptyString(userInfos?.userId)) {
			setUserInfos({ ...userInfos, userId: v4() });
		}

		for (const obj of cleanupTimes) {
			const objDate = moment(obj.date, 'DD.MM.YYYY HH:mm');
			switch (obj.key) {
				case 'voteanswers':
					if (!lastVoteAnswerReset || moment(lastVoteAnswerReset, 'DD.MM.YYYY HH:mm').isBefore(objDate)) {
						resetLocalVoteAnswers(obj.date);
					}
					break;
				default:
					break;
			}
		}

		setDeviceInfo({
			brand: Device.brand,
			deviceName: Device.deviceName,
			deviceYearClass: Device.deviceYearClass,
			factor: Device.deviceYearClass && Device.deviceYearClass > 2020 && !IS_ANDROID ? 1 : 2.5
		});

		setIsReady(true);
	};

	if (!isReady || !fontsLoaded) {
		return (
			<View style={{ height: '100%', width: '100%', justifyContent: 'center' }}>
				<Spinner />
			</View>
		);
	}

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: theme.background }}>
			<StatusBar translucent backgroundColor={theme.background} style={isDarkColor(theme.background) ? 'light' : 'dark'} />

			<ActionSheetProvider>
				<NavigationContainer ref={navigationRef} linking={getLinking()}>
					{IS_WEB && <HSAlert />}
					<SpaceGate>
						<PushHandler />
						<AppNavigator />
						<MediaModal />
						<WelcomeModal />
					</SpaceGate>
					<Toast
						ref={(ref) => (global['toast'] = ref)}
						renderToast={(e) => <BaseToast {...e} />}
						offsetTop={IS_WEB ? hsToastOffsetWeb : hsToastOffsetMobile}
						animationType={'slide-in'}
					/>
				</NavigationContainer>
			</ActionSheetProvider>
		</SafeAreaView>
	);
};

export default optimizeHeavyScreen(MyApp);
