import { FormCheckbox, FormTextInput, FormMultiSwitch } from 'components/Form';
import { FormElementBottomMargin } from 'components/Form/constants';
import { FormHint } from 'components/Form/FormHint';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import { hsInnerPadding } from 'config/styleConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

interface IContentTypeFieldsMetaForm {
	testID: string;
	onChange: (field: string, value: any) => void;
	values: Record<string, any>;
	errors: Record<string, string>;
	isUseAsListFilterDisabled?: boolean;
	contentType: string;
}

export const ContentTypeFieldsMetaForm = (props: IContentTypeFieldsMetaForm) => {
	const { testID, values, errors, onChange, isUseAsListFilterDisabled, contentType } = props;
	const { t } = useTranslation();

	const _renderInitialValueField = () => {
		if (values.visibility === 'hidden') {
			return null;
		}

		switch (values.fieldType) {
			case 'boolean':
				return (
					<FormCheckbox
						testID={`${testID}_checkbox_defaultvalue`}
						label={t('InitialValue')}
						hint={t('InitialValueHintBoolean')}
						value={values.defaultValue as boolean}
						onPress={() => onChange('defaultValue', values.defaultValue ? false : true)}
					/>
				);
			default:
				return null;
		}
	};

	const _getFieldVisibility = () => {
		switch (values?.visibility) {
			case 'visible':
				return t('FieldVisibilityInfoVisible');
			case 'hidden':
				return t('FieldVisibilityInfoHidden');
			default:
				return undefined;
		}
	};

	const _getSelfServiceFieldVisibility = () => {
		switch (values?.selfServiceVisibility) {
			case 'always':
				return t('SelfServiceFieldVisibilityAlways');
			case 'disabled':
				return t('SelfServiceFieldVisibilityDisabled');
			case 'never':
				return t('SelfServiceFieldVisibilityNever');
			default:
				break;
		}
	};

	const _shouldRenderField = () => {
		return values.fieldType !== 'section' && values.fieldType !== 'label' && values.visibility === 'visible';
	};

	const _renderLabelField = () => {
		if (values.fieldType === 'label') {
			return (
				<FormMarkdownEditor
					testID={`${testID}_markdown_fieldlabel`}
					label={t('Field Label')}
					isRequired
					value={values.fieldLabel}
					error={errors['fiedlLabel']}
					onChange={(value) => onChange('fieldLabel', value)}
				/>
			);
		}

		return (
			<FormTextInput
				testID={`${testID}_textinput_fieldlabel`}
				label={t('Field Label')}
				isRequired={values.fieldType !== 'section'}
				value={values.fieldLabel}
				error={errors['fieldLabel']}
				onChangeText={(value) => onChange('fieldLabel', value)}
			/>
		);
	};

	const _renderFieldCsv = () => {
		return (
			<FormTextInput
				testID={`${testID}_textinput_fieldcsv`}
				hint={t('cannotBeEdited')}
				label={t('Name in CSV')}
				value={values.fieldName}
				editable={false}
			/>
		);
	};

	const _renderSectionPosition = () => {
		if (values.fieldType === 'section' || values.fieldType === 'mainsection') {
			return (
				<FormMultiSwitch
					testID={`${testID}_multiswitch_sectionposition`}
					label={t('sectionPosition')}
					isRequired
					options={[
						{
							key: 'left',
							label: t('Left')
						},
						{
							key: 'right',
							label: t('Right')
						}
					]}
					// isDisabled={values.isDefaultField}
					hint={t('sectionPositionHint')}
					value={values.sectionPosition}
					// error={_getFieldVisibility()}
					onChange={(value) => onChange('sectionPosition', value)}
				/>
			);
		}
		return null;
	};

	return (
		<ScrollView style={{ marginHorizontal: -hsInnerPadding }} contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}>
			{_renderLabelField()}
			{_renderFieldCsv()}
			{_renderSectionPosition()}
			<FormMultiSwitch
				testID={`${testID}_multiswitch_visibility`}
				label={t('FieldVisibility')}
				isRequired
				options={[
					{
						key: 'visible',
						label: t('FieldVisibilityVisible')
					},
					{
						key: 'hidden',
						label: t('FieldVisibilityHidden')
					}
				]}
				isDisabled={values.isDefaultField}
				hint={t('FieldVisibilityHintContentType')}
				value={values.visibility}
				error={_getFieldVisibility()}
				onChange={(value) => onChange('visibility', value)}
			/>
			{_shouldRenderField() && (
				<FormCheckbox
					testID={`${testID}_switch_isrequired`}
					label={t('isRequired')}
					value={values.isRequired}
					hint={t('isRequiredHint')}
					isDisabled={values.isDefaultField}
					onPress={() => onChange('isRequired', values.isRequired ? false : true)}
				/>
			)}
			{_shouldRenderField() && (
				<FormTextInput
					testID={`${testID}_textinput_fieldhint`}
					label={t('Field Hint')}
					hint={t('Field Hint Hint')}
					value={values.fieldHint}
					multiline
					onChangeText={(value) => onChange('fieldHint', value)}
				/>
			)}
			{_renderInitialValueField()}
			{_shouldRenderField() && (
				<FormCheckbox
					testID={`${testID}_switch_showondetailscreen`}
					label={t('showOnDetailScreen')}
					hint={values.fieldType === 'category' ? t('isRequiredForMatchings') : undefined}
					value={values.showOnDetailScreen}
					isDisabled={values.isDefaultField}
					onPress={() => onChange('showOnDetailScreen', values.showOnDetailScreen ? false : true)}
				/>
			)}
			{_shouldRenderField() && values.fieldType === 'category' && (
				<View style={{ marginBottom: FormElementBottomMargin }}>
					<FormMultiSwitch
						formStyle={{ marginBottom: 0 }}
						testID={`${testID}_multiswitch_filter`}
						label={t('useAsFilter')}
						hint={t('useAsFilterHint')}
						error={errors.useAsFilter}
						value={values.useAsFilter}
						onChange={(val) => onChange('useAsFilter', val)}
						options={[
							{
								key: 'screen',
								label: t('useAsFilterScreen'),
								isDisabled: isUseAsListFilterDisabled
							},
							{
								key: 'modal',
								label: t('useAsFilterModal')
							},
							{
								key: 'never',
								label: t('useAsFilterNever')
							}
						]}
					/>
					<FormHint
						hint={`${t('useAsFilterScreen')}: ${t('categoryFilterScreenHint')}`}
						testID={`${testID}_multiswitch_filter_screen`}
					/>
					<FormHint
						hint={`${t('useAsFilterModal')}: ${t('categoryFilterModalHint')}`}
						testID={`${testID}_multiswitch_filter_modal`}
					/>
					<FormHint
						hint={`${t('useAsFilterNever')}: ${t('categoryFilterNeverHint')}`}
						testID={`${testID}_multiswitch_filter_never`}
					/>
				</View>
			)}

			<FormMultiSwitch
				testID={`${testID}_multiswitch_selfservicevisibility`}
				label={t('SelfServiceFieldVisibility')}
				isRequired
				options={[
					{
						key: 'always',
						label: t('FieldVisibilityVisibleContentType')
					},
					{
						key: 'disabled',
						label: t('FieldVisibilityDisabledContentType')
					},
					{
						key: 'never',
						label: t('FieldVisibilityNever')
					}
				]}
				isDisabled={values.isDefaultField && values.fieldName !== 'mediaitems'}
				hint={t('FieldSelfServiceVisibilityHintContentType')}
				value={values.selfServiceVisibility}
				error={_getSelfServiceFieldVisibility()}
				onChange={(value) => onChange('selfServiceVisibility', value)}
			/>
		</ScrollView>
	);
};
