import React, { useEffect, useState } from 'react';
import { IChatOverview } from 'config/interfaces';
import { ChildButton } from 'components/Button';
import { Avatar } from 'components/User';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IChatOverviewListItem {
	testID: string;
	item: IChatOverview;
	onPress: () => void;
}

export const ChatOverviewListItem = (props: IChatOverviewListItem) => {
	const { testID, item, onPress } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [unreadCounter, setUnreadCounter] = useState<number>(0);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		const _counter: typeof unreadCounter = item.messages.filter((e) => e.senderId !== profile?.userId && !e.hasBeenRead).length;

		setUnreadCounter(_counter);
	}, [item]);

	const _renderTime = () => {
		let str = '';
		const now = moment();
		const messageTime = moment(
			item?.messages[0]?.type === 'meetingRequest' && item?.messages[0]?.updated_at
				? item?.messages[0]?.updated_at
				: item?.messages[0]?.created_at
		);

		if (now.isSame(messageTime, 'date')) {
			str = messageTime.format('HH:mm');
		} else if (now.subtract(1, 'day').isSame(messageTime, 'date')) {
			str = `${t('YesterdayAt').replace('%VALUE%', `${messageTime.format('HH:mm')}`)}`;
		} else {
			str = messageTime.format('DD.MM HH:mm');
		}

		return (
			<Text numberOfLines={1} adjustsFontSizeToFit style={{ color: theme.formgray, fontSize: 10 }}>
				{str}
			</Text>
		);
	};

	const _renderMessage = () => {
		if (item.messages[0]) {
			const lastMessage = item.messages[0].type === 'chatMessage' ? item.messages[0].content : t(item.messages[0].content);

			return (
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					<View style={{ flex: 1 }}>
						<Text numberOfLines={2} style={{ fontSize: 12 }}>
							{lastMessage}
						</Text>
					</View>
					{unreadCounter > 0 && (
						<View
							style={{
								backgroundColor: theme.danger,
								paddingHorizontal: 6,
								paddingVertical: 2,
								borderRadius: 99,
								alignSelf: 'flex-start'
							}}
						>
							<Text style={{ color: theme.white }}>{unreadCounter}</Text>
						</View>
					)}
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={`${testID}_button`}
			onPress={onPress}
			style={{ flexDirection: 'row', alignItems: 'center', padding: 10, borderRadius: 10, marginBottom: 5 }}
		>
			<Avatar
				testID={`${testID}_avatar`}
				avatar={item?.partner?.smallImageUrl ?? item.partner?.imageUrl}
				fullName={item.partner ? `${item.partner.firstName} ${item.partner.lastName}` : undefined}
				userId={item.partner?.userId}
				size="sm"
			/>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					{item.partner?.firstName || item.partner?.lastName ? (
						<Text bold numberOfLines={1} adjustsFontSizeToFit>{`${item.partner?.firstName ?? ''} ${
							item.partner?.lastName ?? ''
						}`}</Text>
					) : (
						<Text bold numberOfLines={1} adjustsFontSizeToFit>
							{t('Unknown')}
						</Text>
					)}
					<View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>{_renderTime()}</View>
				</View>
				{_renderMessage()}
			</View>
		</ChildButton>
	);
};
