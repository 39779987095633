import { Icon } from 'components/Icon';
import { EDefaultIconSet } from 'helper';
import React from 'react';
import { View } from 'react-native';
import { H1 } from './H1';

interface IVoteSum {
	value?: string | number;
	largerDesign?: boolean;
}

export const VoteSum = (props: IVoteSum) => {
	const { value, largerDesign } = props;

	if (value !== undefined) {
		return (
			<View style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center', justifyContent: 'center' }}>
				<Icon name={EDefaultIconSet.Count} size={largerDesign ? 30 : 20} />
				<H1 largerText={largerDesign} style={{ marginBottom: 0, marginLeft: 5 }}>
					{value?.toString()}
				</H1>
			</View>
		);
	}

	return null;
};
