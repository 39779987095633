import React, { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { canJoinSpace, isEmptyString, IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DrawerView } from './DrawerView';
import { Dimensions, Linking } from 'react-native';
import { NoSpaceStackParamList, StackParamList, PresenterStackParamList } from './interfaces';
import { IFeatureInfo, ILauncherSpace } from 'config/interfaces';
import { ERoutes } from './routes';
import * as RootNavigation from '../../RootNavigation';

import {
	ActiveVoteAdminScreen,
	ActiveVoteAdminScreenHeader,
	ActiveVoteScreen,
	ActiveVoteScreenHeader,
	AdministrateScreen,
	AdministrateScreenHeader,
	AdministrateScreenRootHeader,
	ScheduleScreen,
	ScheduleScreenHeader,
	ScheduleScreenRootHeader,
	ComponentsScreen,
	ComponentsScreenHeader,
	ConfirmAccountScreen,
	ConfirmAccountScreenHeader,
	ContentTypeFieldsScreen,
	ContentTypeFieldsScreenHeader,
	EditMailTemplateScreen,
	EditMailTemplateScreenHeader,
	EditProfileScreen,
	EditProfileScreenHeader,
	CustomUserConsentScreen,
	CustomUserConsentScreenHeader,
	EditSpaceScreen,
	EditSpaceScreenHeader,
	ExpoCreateScreen,
	ExpoCreateScreenHeader,
	ExpoDetailsScreen,
	ExpoDetailsScreenHeader,
	ExpoEditScreen,
	ExpoEditScreenHeader,
	ExpoListScreen,
	ExpoListScreenHeader,
	ExpoListScreenRootHeader,
	MailHistoryDetailsScreen,
	MailHistoryDetailsScreenHeader,
	MailHistoryScreen,
	MailHistoryScreenHeader,
	MailSettingsScreen,
	MailSettingsScreenHeader,
	MailTemplateListScreen,
	MailTemplateListScreenHeader,
	NewsCreateScreen,
	NewsCreateScreenHeader,
	NewsDetailsScreen,
	NewsDetailsScreenHeader,
	NewsEditScreen,
	NewsEditScreenHeader,
	NewsScreen,
	NewsScreenHeader,
	NewsScreenRootHeader,
	OnboardingScreen,
	OnboardingScreenHeader,
	SpaceAdminsScreen,
	SpaceAdminsScreenHeader,
	SpaceAuthenticationScreen,
	SpaceAuthenticationScreenHeader,
	SpaceCreationScreen,
	SpaceCreationScreenHeader,
	SpaceSelectScreen,
	SpaceSelectScreenHeader,
	SpeakerCreateScreen,
	SpeakerCreateScreenHeader,
	SpeakerDetailsScreen,
	SpeakerDetailsScreenHeader,
	SpeakerEditScreen,
	SpeakerEditScreenHeader,
	SpeakerListScreen,
	SpeakerListScreenHeader,
	SpeakerListScreenRootHeader,
	SpeakerViewScreen,
	SpeakerViewScreenHeader,
	TutorialScreen,
	TutorialScreenHeader,
	VoteAnalyticsScreen,
	VoteAnalyticsScreenHeader,
	VoteCreateScreen,
	VoteCreateScreenHeader,
	VoteEditScreen,
	VoteEditScreenHeader,
	VoteListScreen,
	VoteListScreenHeader,
	VoteModerationScreen,
	VoteModerationScreenHeader,
	ScheduleCreateScreen,
	ScheduleCreateScreenHeader,
	ScheduleEditScreen,
	ScheduleEditScreenHeader,
	StageCreateScreen,
	StageCreateScreenHeader,
	StageEditScreen,
	StageEditScreenHeader,
	StageListScreen,
	StageListScreenHeader,
	ScheduleListScreenHeader,
	ScheduleListScreen,
	ChatOverviewScreen,
	ChatOverviewScreenRootHeader,
	ChatOverviewScreenHeader,
	ChatScreen,
	ChatScreenHeader,
	VoteListScreenRootHeader,
	MeetingListScreen,
	MeetingListScreenHeader,
	MeetingRequestScreen,
	MeetingRequestScreenHeader,
	MeetingListScreenRootHeader,
	MeetingSettingsScreenHeader,
	MeetingSettingsScreen,
	SpaceSearchScreen,
	SpaceSearchScreenHeader,
	SpaceSummaryScreen,
	SpaceSummaryScreenHeader,
	AttendeeDetailsScreen,
	AttendeeDetailsScreenHeader,
	AttendeeListScreen,
	AttendeeListScreenHeader,
	AttendeeListScreenRootHeader,
	PushNotificationListScreen,
	PushNotificationListScreenHeader,
	PushNotificationScreen,
	PushNotificationScreenHeader,
	SendMailScreen,
	SendMailScreenHeader,
	ResultViewScreen,
	ResultViewScreenHeader,
	FeaturesScreen,
	FeaturesScreenHeader,
	SelfServiceScreen,
	SelfServiceScreenHeader,
	SelfServiceSettingsScreen,
	SelfServiceSettingsScreenHeader,
	ImportDataScreen,
	ImportDataScreenHeader,
	EditSpaceDesignScreen,
	EditSpaceDesignScreenHeader,
	EditSpaceTextsScreen,
	EditSpaceTextsScreenHeader,
	StreamListScreen,
	StreamListScreenHeader,
	StreamCreateScreen,
	StreamCreateScreenHeader,
	StreamUpdateScreen,
	StreamUpdateScreenHeader,
	StreamDetailsScreen,
	StreamDetailsScreenHeader,
	EditSpaceSponsorScreen,
	EditSpaceSponsorScreenHeader,
	MediaItemCreateScreen,
	MediaItemCreateScreenHeader,
	MediaItemEditScreen,
	MediaItemEditScreenHeader,
	MediaItemListScreen,
	MediaItemListScreenHeader,
	MediaItemListScreenRootHeader,
	MySpacesScreen,
	MySpacesScreenHeader,
	TicketSettingsScreen,
	TicketSettingsScreenHeader,
	TicketListScreen,
	TicketListScreenHeader,
	TicketCreateScreen,
	TicketCreateScreenHeader,
	TicketEditScreen,
	TicketEditScreenHeader,
	MapsScreen,
	ExternalMapsScreen,
	ExternalMapsScreenHeader,
	MapsScreenHeader,
	MapsScreenRootHeader,
	MapCreateScreen,
	MapCreateScreenHeader,
	MapEditScreen,
	MapEditScreenHeader,
	MapPositionsEditScreen,
	MapPositionsEditScreenHeader,
	MapOrderScreen,
	MapOrderScreenHeader,
	PublicAgendaScreen,
	PublicAgendaScreenHeader,
	WelcomeEditScreen,
	WelcomeEditScreenHeader,
	BookingListScreen,
	BookingListScreenHeader,
	BookingDetailsScreen,
	BookingDetailsScreenHeader,
	MatchingsScreen,
	MatchingsScreenHeader,
	ReportsScreen,
	ReportsScreenHeader,
	StatisticsScreen,
	StatisticsScreenHeader,
	EditFeatureScreen,
	EditFeatureScreenHeader,
	WebScreenHeader,
	WebScreen,
	WebScreenRootHeader,
	MarkdownScreen,
	MarkdownScreenHeader,
	MarkdownScreenRootHeader,
	SpaceRegistrationFieldsScreen,
	SpaceRegistrationFieldsHeader,
	SpaceOnboardingScreen,
	SpaceOnboardingScreenHeader,
	LandingpageEditScreen,
	LandingpageEditScreenHeader,
	LandingpageScreen,
	LandingpageScreenHeader,
	BookingImportScreen,
	BookingImportScreenHeader,
	HspxAdminScreen,
	HspxAdminScreenHeader,
	PrestartScreen,
	PrestartScreenHeader,
	ChangelogScreen,
	ChangelogScreenHeader,
	ApiTokenScreen,
	ApiTokenScreenHeader,
	WebhooksListScreen,
	WebhooksListScreenHeader,
	WebhookCreateScreen,
	WebhookCreateScreenHeader,
	WebhookUpdateScreen,
	WebhookUpdateScreenHeader,
	WebhookHistoryScreen,
	WebhookHistoryScreenHeader
} from 'screens';
import { useTranslation } from 'react-i18next';
import { MAXTABCOUNT } from 'components/Forms/Space/SpaceWizard';
import { TabBar } from './TabBar';
import {
	NetworkingRoomCreateScreen,
	NetworkingRoomCreateScreenHeader,
	NetworkingRoomListScreen,
	NetworkingRoomListScreenHeader,
	NetworkingRoomListScreenRootHeader,
	NetworkingRoomScreen,
	NetworkingRoomScreenHeader,
	NetworkingRoomUpdateScreen,
	NetworkingRoomUpdateScreenHeader
} from 'screens/Space/Networking';
import { showToast } from 'helper/toast';
import {
	SupportRequestListScreen,
	SupportRequestListScreenHeader
} from 'screens/Space/Administrate/SupportRequests/SupportRequestListScreen';
import { SupportRequestScreen, SupportRequestScreenHeader } from 'screens/Space/Administrate/SupportRequests';
import { PRESET_SPACEIDS } from 'config/envConstants';
import {
	MeetingTableCreateScreen,
	MeetingTableCreateScreenHeader,
	MeetingTableEditScreen,
	MeetingTableEditScreenHeader,
	MeetingTableListScreen,
	MeetingTableListScreenHeader
} from 'screens/Space/MeetingTable';
import { MediaScreen, MediaScreenHeader } from 'screens/Space/Media/Mediascreen';

const Drawer = createDrawerNavigator();
const NoSpaceStack = createNativeStackNavigator<NoSpaceStackParamList>();
const PresenterStack = createNativeStackNavigator<PresenterStackParamList>();
const SelfServiceStack = createNativeStackNavigator<StackParamList>();
const ExternalMapsStack = createNativeStackNavigator<StackParamList>();

const MainStack = createNativeStackNavigator();

const VotingsStack = createNativeStackNavigator<StackParamList>();
const FeedStack = createNativeStackNavigator<StackParamList>();
const SpeakersStack = createNativeStackNavigator<StackParamList>();
const SettingsStack = createNativeStackNavigator<StackParamList>();
const MoreStack = createNativeStackNavigator<StackParamList>();
const AttendeesStack = createNativeStackNavigator<StackParamList>();
const ExposStack = createNativeStackNavigator<StackParamList>();
const ScheduleStack = createNativeStackNavigator<StackParamList>();
const ChatStack = createNativeStackNavigator<StackParamList>();
const MeetingStack = createNativeStackNavigator<StackParamList>();
const NetworkingStack = createNativeStackNavigator<StackParamList>();
const MediaItemStack = createNativeStackNavigator<StackParamList>();
const MapStack = createNativeStackNavigator<StackParamList>();
const WebStack = createNativeStackNavigator<StackParamList>();
const MarkdownStack = createNativeStackNavigator<StackParamList>();

const SpaceTab = createBottomTabNavigator();

const title = (activeSpace: ILauncherSpace | undefined, t?: string) => {
	return `${activeSpace?.title ?? 'HelloSpaces'} ${!isEmptyString(t) ? '-' : ''} ${t}`;
};

const MainStackNav = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const activeSpaceRef = useRef<ILauncherSpace | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const joinSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.joinSpace);

	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);

	useEffect(() => {
		activeSpaceRef.current = activeSpace;
	}, [activeSpace]);

	useEffect(() => {
		_init();
	}, []);

	const _init = async () => {
		const url = await Linking.getInitialURL();
		const _activeSpace = getActiveSpace({});
		if (url) {
			const route = url.replace(/.*?:\/\//g, '');
			const urlParts = route.split('/');

			const newSpaceId =
				urlParts[0] === 'app'
					? urlParts[1]
					: urlParts[2] === 'editprofile'
					? urlParts[3] !== 'undefined'
						? urlParts[3]
						: undefined
					: urlParts[2];

			if (newSpaceId) {
				const isSpaceOnboarding = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Onboarding;
				const isLandingpage = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Landingpage;
				const isPrestart = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Prestart;
				const isExternalMaps = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.ExternalMaps;
				if (
					(PRESET_SPACEIDS && !PRESET_SPACEIDS.includes(newSpaceId)) ||
					isSpaceOnboarding ||
					isLandingpage ||
					isExternalMaps ||
					isPrestart
				) {
					return;
				}

				if (!_activeSpace || _activeSpace.spaceId !== newSpaceId) {
					const space = await findSpaceById({ spaceId: newSpaceId, noToast: true });
					if (space?.id) {
						if (_activeSpace) {
							await leaveSpace({});
							_joinSpace(space);
						} else {
							_joinSpace(space);
						}
					}
				} else if (_activeSpace) {
					const { isAdmin, needsPin } = canJoinSpace(_activeSpace, profile);
					if (needsPin && !isAdmin) {
						RootNavigation.replace(ERoutes.Prestart, { spaceId: _activeSpace.spaceId });
						return;
					}
				}
			}
		}
	};

	const _joinSpace = async (space: ILauncherSpace) => {
		const { isAdmin, isModerator, isDisabledBySuperAdmin, isDeactivated, needsRegistration, needsToFilloutProfileFields, needsPin } =
			canJoinSpace(space, profile);

		if (isDisabledBySuperAdmin) {
			showToast('error', undefined, isAdmin ? t('spaceIsDeactivatedAlertTitleAdmin') : t('spaceIsDeactivatedAlertTitle'));
			RootNavigation.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary);
			return;
		}

		if (isDeactivated && !(isAdmin || isModerator)) {
			showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
			RootNavigation.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary);
			return;
		}

		if (needsRegistration) {
			// Passing the space id to automatically get to a space overview after register/login
			RootNavigation.replace(ERoutes.SpaceAuth, {
				authState: 'login',
				spaceId: space.spaceId,
				redirectUrl: IS_WEB ? window.location.href : undefined
			});
			showToast('info', t('RegistrationRequired'), t('RegistrationRequiredErrorSubtitle'));
			return;
		}

		if (needsToFilloutProfileFields) {
			RootNavigation.replace(ERoutes.SpaceSummary, { spaceId: space.spaceId, space });
			return;
		}

		if (needsPin && !isAdmin) {
			RootNavigation.replace(ERoutes.Prestart, { spaceId: space.spaceId });
			return;
		}

		await joinSpace({ space });
	};

	return (
		<MainStack.Navigator initialRouteName={!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary}>
			{(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) && (
				<MainStack.Screen
					name={ERoutes.SpaceSelect}
					component={SpaceSelectScreen}
					options={{ header: SpaceSelectScreenHeader, title: title(activeSpaceRef.current, '') }}
				/>
			)}
			<NoSpaceStack.Screen
				name={ERoutes.MySpaces}
				component={MySpacesScreen}
				options={{ header: MySpacesScreenHeader, title: title(undefined, t('My Spaces')) }}
			/>
			<MainStack.Screen name="tab" component={MyTabNav} options={{ headerShown: false }} />
			<MainStack.Screen
				name={ERoutes.ActiveVoting}
				component={ActiveVoteScreen}
				options={{ header: ActiveVoteScreenHeader, title: title(activeSpaceRef.current, t('Active Vote')) }}
			/>
			<MainStack.Screen
				name={ERoutes.VotingCreate}
				component={VoteCreateScreen}
				options={{ header: VoteCreateScreenHeader, title: title(activeSpaceRef.current, t('Create Vote')) }}
			/>
			<MainStack.Screen
				name={ERoutes.VotingEdit}
				component={VoteEditScreen}
				options={{ header: VoteEditScreenHeader, title: title(activeSpaceRef.current, t('Edit Voting')) }}
			/>
			<MainStack.Screen
				name={ERoutes.VotingModeration}
				component={VoteModerationScreen}
				options={{ header: VoteModerationScreenHeader, title: title(activeSpaceRef.current, t('Voting moderation')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ActiveVotingAdmin}
				component={ActiveVoteAdminScreen}
				options={{ header: ActiveVoteAdminScreenHeader, title: title(activeSpaceRef.current, 'Vote Administration') }}
			/>
			<MainStack.Screen
				name={ERoutes.SpeakerView}
				component={SpeakerViewScreen}
				options={{ header: SpeakerViewScreenHeader, title: title(activeSpaceRef.current, t('Speaker View')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ResultView}
				component={ResultViewScreen}
				options={{ header: ResultViewScreenHeader, title: title(activeSpaceRef.current, t('Result View')) }}
			/>
			<MainStack.Screen
				name={ERoutes.VoteAnalytics}
				component={VoteAnalyticsScreen}
				options={{ header: VoteAnalyticsScreenHeader, title: title(activeSpaceRef.current, t('Analytics')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Components}
				component={ComponentsScreen}
				options={{ header: ComponentsScreenHeader, title: title(activeSpaceRef.current, 'Components') }}
			/>
			<MainStack.Screen
				name={ERoutes.HspxAdmin}
				component={HspxAdminScreen}
				options={{ header: HspxAdminScreenHeader, title: title(activeSpaceRef.current, 'Admin') }}
			/>
			<MainStack.Screen
				name={ERoutes.EditProfile}
				component={EditProfileScreen}
				options={{ header: EditProfileScreenHeader, title: title(activeSpaceRef.current, t('EditProfile')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Tutorial}
				component={TutorialScreen}
				options={{ header: TutorialScreenHeader, title: title(activeSpaceRef.current, t('Tutorial')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceAuth}
				component={SpaceAuthenticationScreen}
				options={{ header: SpaceAuthenticationScreenHeader, title: title(activeSpaceRef.current, t('Authentication')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ConfirmAccount}
				component={ConfirmAccountScreen}
				options={{ header: ConfirmAccountScreenHeader, title: title(activeSpaceRef.current, t('ConfirmAccount')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NewsList}
				component={NewsScreen}
				options={{ header: NewsScreenHeader, title: title(activeSpaceRef.current, t('News')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NewsEdit}
				component={NewsEditScreen}
				options={{ header: NewsEditScreenHeader, title: title(activeSpaceRef.current, t('Edit News')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NewsDetails}
				component={NewsDetailsScreen}
				options={{ header: NewsDetailsScreenHeader, title: title(activeSpaceRef.current, t('News')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NewsCreate}
				component={NewsCreateScreen}
				options={{ header: NewsCreateScreenHeader, title: title(activeSpaceRef.current, t('Create News')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditSpace}
				component={EditSpaceScreen}
				options={{ header: EditSpaceScreenHeader, title: title(activeSpaceRef.current, t('Edit Space')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditSpaceDesign}
				component={EditSpaceDesignScreen}
				options={{ header: EditSpaceDesignScreenHeader, title: title(activeSpaceRef.current, t('Edit Space Design')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditSpaceTexts}
				component={EditSpaceTextsScreen}
				options={{ header: EditSpaceTextsScreenHeader, title: title(activeSpaceRef.current, t('Edit Space Texts')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditSpaceSponsors}
				component={EditSpaceSponsorScreen}
				options={{ header: EditSpaceSponsorScreenHeader, title: title(activeSpaceRef.current, t('Edit Space Sponsors')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceAdmins}
				component={SpaceAdminsScreen}
				options={{ header: SpaceAdminsScreenHeader, title: title(activeSpaceRef.current, t('Space Admins')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditLandingpage}
				component={LandingpageEditScreen}
				options={{ header: LandingpageEditScreenHeader, title: title(activeSpaceRef.current, t('Edit Landingpage')) }}
			/>
			<MainStack.Screen
				name={ERoutes.PushNotificationList}
				component={PushNotificationListScreen}
				options={{ header: PushNotificationListScreenHeader, title: title(activeSpaceRef.current, t('Push Notifications')) }}
			/>
			<MainStack.Screen
				name={ERoutes.PushNotification}
				component={PushNotificationScreen}
				options={{ header: PushNotificationScreenHeader, title: title(activeSpaceRef.current, t('Push Notifications')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceCreate}
				component={SpaceCreationScreen}
				options={{ header: SpaceCreationScreenHeader, title: title(activeSpaceRef.current, t('Create Space')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceSummary}
				component={SpaceSummaryScreen}
				options={{ header: SpaceSummaryScreenHeader, title: title(activeSpaceRef.current, t('Summary')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceSearch}
				component={SpaceSearchScreen}
				options={{ header: SpaceSearchScreenHeader, title: title(activeSpaceRef.current) }}
			/>
			<MainStack.Screen
				name={ERoutes.CustomUserConstent}
				component={CustomUserConsentScreen}
				options={{ header: CustomUserConsentScreenHeader, title: title(activeSpaceRef.current) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceRegistrationFields}
				component={SpaceRegistrationFieldsScreen}
				options={{ header: SpaceRegistrationFieldsHeader, title: title(activeSpaceRef.current, t('RegistrationFields')) }}
			/>
			<MainStack.Screen
				name={ERoutes.AttendeesList}
				component={AttendeeListScreen}
				options={{ header: AttendeeListScreenHeader, title: title(activeSpaceRef.current, t('Attendees')) }}
			/>
			<MainStack.Screen
				name={ERoutes.AttendeeDetails}
				component={AttendeeDetailsScreen}
				options={{ header: AttendeeDetailsScreenHeader, title: title(activeSpaceRef.current, t('Attendees')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MailHistory}
				component={MailHistoryScreen}
				options={{ header: MailHistoryScreenHeader, title: title(activeSpaceRef.current, t('Mail History')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MailHistoryDetails}
				component={MailHistoryDetailsScreen}
				options={{ header: MailHistoryDetailsScreenHeader, title: title(activeSpaceRef.current, t('Mail History')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MailTemplateList}
				component={MailTemplateListScreen}
				options={{ header: MailTemplateListScreenHeader, title: title(activeSpaceRef.current, t('Attendees')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditMailTemplate}
				component={EditMailTemplateScreen}
				options={{ header: EditMailTemplateScreenHeader, title: title(activeSpaceRef.current, t('Attendees')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SendMail}
				component={SendMailScreen}
				options={{ header: SendMailScreenHeader, title: title(activeSpaceRef.current, t('Send Mail')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MailSettings}
				component={MailSettingsScreen}
				options={{ header: MailSettingsScreenHeader, title: title(activeSpaceRef.current, t('Mail Settings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Features}
				component={FeaturesScreen}
				options={{ header: FeaturesScreenHeader, title: title(activeSpaceRef.current, t('Features')) }}
			/>
			<MainStack.Screen
				name={ERoutes.EditFeature}
				component={EditFeatureScreen}
				options={{ header: EditFeatureScreenHeader, title: title(activeSpaceRef.current, t('Features')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Speakers}
				component={SpeakerListScreen}
				options={{ header: SpeakerListScreenHeader, title: title(activeSpaceRef.current, t('Speakers')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpeakerDetails}
				component={SpeakerDetailsScreen}
				options={{ header: SpeakerDetailsScreenHeader, title: title(activeSpaceRef.current, t('Speakers')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpeakerCreate}
				component={SpeakerCreateScreen}
				options={{ header: SpeakerCreateScreenHeader, title: title(activeSpaceRef.current, t('Speakers')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpeakerEdit}
				component={SpeakerEditScreen}
				options={{ header: SpeakerEditScreenHeader, title: title(activeSpaceRef.current, t('Speakers')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Administrate}
				component={AdministrateScreen}
				options={{ header: AdministrateScreenHeader, title: title(activeSpaceRef.current, t('Administrate')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Changelogs}
				component={ChangelogScreen}
				options={{ header: ChangelogScreenHeader, title: title(activeSpaceRef.current, 'Changelogs') }}
			/>
			<MainStack.Screen
				name={ERoutes.ContentTypeFields}
				component={ContentTypeFieldsScreen}
				options={{ header: ContentTypeFieldsScreenHeader, title: title(activeSpaceRef.current, t('Fields')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Expos}
				component={ExpoListScreen}
				options={{ header: ExpoListScreenHeader, title: title(activeSpaceRef.current, t('Expos')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Sponsors}
				component={ExpoListScreen}
				options={{ header: ExpoListScreenHeader, title: title(activeSpaceRef.current, t('Sponsors')) }}
				initialParams={{ isSponsors: true }}
			/>
			<MainStack.Screen
				name={ERoutes.ExpoCreate}
				component={ExpoCreateScreen}
				options={{ header: ExpoCreateScreenHeader, title: title(activeSpaceRef.current, t('Expos')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ExpoEdit}
				component={ExpoEditScreen}
				options={{ header: ExpoEditScreenHeader, title: title(activeSpaceRef.current, t('Expos')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ExpoDetails}
				component={ExpoDetailsScreen}
				options={{ header: ExpoDetailsScreenHeader, title: title(activeSpaceRef.current, t('Expos')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Schedule}
				component={ScheduleScreen}
				options={{ header: ScheduleScreenHeader, title: title(activeSpaceRef.current, t('Schedule')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ScheduleCreate}
				component={ScheduleCreateScreen}
				options={{ header: ScheduleCreateScreenHeader, title: title(activeSpaceRef.current, t('Schedule')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ScheduleEdit}
				component={ScheduleEditScreen}
				options={{ header: ScheduleEditScreenHeader, title: title(activeSpaceRef.current, t('Schedule')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ScheduleList}
				component={ScheduleListScreen}
				options={{ header: ScheduleListScreenHeader, title: title(activeSpaceRef.current, t('Schedule')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StageCreate}
				component={StageCreateScreen}
				options={{ header: StageCreateScreenHeader, title: title(activeSpaceRef.current, t('Stage')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StageEdit}
				component={StageEditScreen}
				options={{ header: StageEditScreenHeader, title: title(activeSpaceRef.current, t('Stage')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StageList}
				component={StageListScreen}
				options={{ header: StageListScreenHeader, title: title(activeSpaceRef.current, t('Stages')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ChatOverview}
				component={ChatOverviewScreen}
				options={{ header: ChatOverviewScreenHeader, title: title(activeSpaceRef.current, t('Chat')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Chat}
				component={ChatScreen}
				options={{ header: ChatScreenHeader, title: title(activeSpaceRef.current, t('Chat')) }}
			/>
			<MainStack.Screen
				name={ERoutes.VoteList}
				component={VoteListScreen}
				options={{ header: VoteListScreenHeader, title: title(activeSpace, t('Votings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingList}
				component={MeetingListScreen}
				options={{ header: MeetingListScreenHeader, title: title(activeSpace, t('Meetings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingRequest}
				component={MeetingRequestScreen}
				options={{ header: MeetingRequestScreenHeader, title: title(activeSpace, t('Meetings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingSettings}
				component={MeetingSettingsScreen}
				options={{ header: MeetingSettingsScreenHeader, title: title(activeSpace, t('Meeting Settings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SpaceOnboarding}
				component={SpaceOnboardingScreen}
				options={{ header: SpaceOnboardingScreenHeader, title: title(activeSpace, t('Onboarding')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Landingpage}
				component={LandingpageScreen}
				options={{ header: LandingpageScreenHeader, title: title(activeSpace, t('Landingpage')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Prestart}
				component={PrestartScreen}
				options={{ header: PrestartScreenHeader, title: title(activeSpace, t('Prestart')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SelfService}
				component={SelfServiceScreen}
				options={{ header: SelfServiceScreenHeader, title: title(activeSpace, t('Self Service')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SelfServiceSettings}
				component={SelfServiceSettingsScreen}
				options={{ header: SelfServiceSettingsScreenHeader, title: title(activeSpace, t('Self Service')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ExternalMaps}
				component={ExternalMapsScreen}
				options={{ header: ExternalMapsScreenHeader, title: title(activeSpaceRef.current, t('Maps')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NetworkingRoomList}
				component={NetworkingRoomListScreen}
				options={{ header: NetworkingRoomListScreenHeader, title: title(activeSpace, t('networking')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NetworkingRoomCreate}
				component={NetworkingRoomCreateScreen}
				options={{ header: NetworkingRoomCreateScreenHeader, title: title(activeSpace, t('networking')) }}
			/>
			<MainStack.Screen
				name={ERoutes.NetworkingRoomUpdate}
				component={NetworkingRoomUpdateScreen}
				options={{ header: NetworkingRoomUpdateScreenHeader, title: title(activeSpace, t('networking')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ImportData}
				component={ImportDataScreen}
				options={{ header: ImportDataScreenHeader, title: title(activeSpace, 'Import') }}
			/>
			<MainStack.Screen
				name={ERoutes.NetworkingRoom}
				component={NetworkingRoomScreen}
				options={{ header: NetworkingRoomScreenHeader, title: title(activeSpace, t('networking')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StreamList}
				component={StreamListScreen}
				options={{ header: StreamListScreenHeader, title: title(activeSpace, t('Stream')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StreamCreate}
				component={StreamCreateScreen}
				options={{ header: StreamCreateScreenHeader, title: title(activeSpace, t('Stream')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StreamEdit}
				component={StreamUpdateScreen}
				options={{ header: StreamUpdateScreenHeader, title: title(activeSpace, t('Stream')) }}
			/>
			<MainStack.Screen
				name={ERoutes.StreamDetails}
				component={StreamDetailsScreen}
				options={{ header: StreamDetailsScreenHeader, title: title(activeSpace, t('Stream')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MediaItemCreate}
				component={MediaItemCreateScreen}
				options={{ header: MediaItemCreateScreenHeader, title: title(activeSpace, t('Media Items')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MediaItemEdit}
				component={MediaItemEditScreen}
				options={{ header: MediaItemEditScreenHeader, title: title(activeSpace, t('Media Items')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MediaItemList}
				component={MediaItemListScreen}
				options={{ header: MediaItemListScreenHeader, title: title(activeSpace, t('Media Items')) }}
			/>
			<MainStack.Screen
				name={ERoutes.TicketSettings}
				component={TicketSettingsScreen}
				options={{ header: TicketSettingsScreenHeader, title: title(activeSpace, t('Ticket Settings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.TicketList}
				component={TicketListScreen}
				options={{ header: TicketListScreenHeader, title: title(activeSpace, t('Tickets')) }}
			/>
			<MainStack.Screen
				name={ERoutes.TicketCreate}
				component={TicketCreateScreen}
				options={{ header: TicketCreateScreenHeader, title: title(activeSpace, t('Ticket')) }}
			/>
			<MainStack.Screen
				name={ERoutes.TicketEdit}
				component={TicketEditScreen}
				options={{ header: TicketEditScreenHeader, title: title(activeSpace, t('Ticket')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Maps}
				component={MapsScreen}
				options={{ header: MapsScreenHeader, title: title(activeSpace, t('Maps')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MapCreate}
				component={MapCreateScreen}
				options={{ header: MapCreateScreenHeader, title: title(activeSpace, t('Maps')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MapEdit}
				component={MapEditScreen}
				options={{ header: MapEditScreenHeader, title: title(activeSpace, t('Maps')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MapPositionsEdit}
				component={MapPositionsEditScreen}
				options={{ header: MapPositionsEditScreenHeader, title: title(activeSpace, t('Positions')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MapOrder}
				component={MapOrderScreen}
				options={{ header: MapOrderScreenHeader, title: title(activeSpace, t('Maps')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SupportRequestList}
				component={SupportRequestListScreen}
				options={{ header: SupportRequestListScreenHeader, title: title(activeSpaceRef.current, t('Support Requests')) }}
			/>
			<MainStack.Screen
				name={ERoutes.SupportRequest}
				component={SupportRequestScreen}
				options={{ header: SupportRequestScreenHeader, title: title(activeSpaceRef.current, t('Support Requests')) }}
			/>
			<MainStack.Screen
				name={ERoutes.WelcomeEdit}
				component={WelcomeEditScreen}
				options={{ header: WelcomeEditScreenHeader, title: title(activeSpaceRef.current, t('Welcome')) }}
			/>
			<MainStack.Screen
				name={ERoutes.BookingList}
				component={BookingListScreen}
				options={{ header: BookingListScreenHeader, title: title(activeSpaceRef.current, t('Bookings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.BookingDetails}
				component={BookingDetailsScreen}
				options={{ header: BookingDetailsScreenHeader, title: title(activeSpaceRef.current, t('Bookings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.BookingImport}
				component={BookingImportScreen}
				options={{ header: BookingImportScreenHeader, title: title(activeSpaceRef.current, t('Import Bookings')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Matchings}
				component={MatchingsScreen}
				options={{ header: MatchingsScreenHeader, title: title(activeSpaceRef.current, t('Matches')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingTableList}
				component={MeetingTableListScreen}
				options={{ header: MeetingTableListScreenHeader, title: title(activeSpaceRef.current, t('Meeting Tables')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingTableCreate}
				component={MeetingTableCreateScreen}
				options={{ header: MeetingTableCreateScreenHeader, title: title(activeSpaceRef.current, t('Meeting Tables')) }}
			/>
			<MainStack.Screen
				name={ERoutes.MeetingTableEdit}
				component={MeetingTableEditScreen}
				options={{ header: MeetingTableEditScreenHeader, title: title(activeSpaceRef.current, t('Meeting Tables')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Reports}
				component={ReportsScreen}
				options={{ header: ReportsScreenHeader, title: title(activeSpaceRef.current, t('Reports')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Statistics}
				component={StatisticsScreen}
				options={{ header: StatisticsScreenHeader, title: title(activeSpaceRef.current, t('Statistics')) }}
			/>
			<MainStack.Screen
				name={ERoutes.ApiToken}
				component={ApiTokenScreen}
				options={{ header: ApiTokenScreenHeader, title: title(activeSpaceRef.current, t('API Token')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Web}
				component={WebScreen}
				options={{ header: WebScreenHeader, title: title(activeSpaceRef.current, t('Web')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Markdown}
				component={MarkdownScreen}
				options={{ header: MarkdownScreenHeader, title: title(activeSpaceRef.current, t('Markdown')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Media}
				component={MediaScreen}
				options={{ header: MediaScreenHeader, title: title(activeSpaceRef.current, t('Media')) }}
			/>
			<MainStack.Screen
				name={ERoutes.Webhooks}
				component={WebhooksListScreen}
				options={{ header: WebhooksListScreenHeader, title: title(activeSpaceRef.current, t('Webhooks')) }}
			/>
			<MainStack.Screen
				name={ERoutes.WebhookCreate}
				component={WebhookCreateScreen}
				options={{ header: WebhookCreateScreenHeader, title: title(activeSpaceRef.current, t('Webhooks')) }}
			/>
			<MainStack.Screen
				name={ERoutes.WebhookUpdate}
				component={WebhookUpdateScreen}
				options={{ header: WebhookUpdateScreenHeader, title: title(activeSpaceRef.current, t('Webhooks')) }}
			/>
			<MainStack.Screen
				name={ERoutes.WebhookHistory}
				component={WebhookHistoryScreen}
				options={{ header: WebhookHistoryScreenHeader, title: title(activeSpaceRef.current, t('History')) }}
			/>
		</MainStack.Navigator>
	);
};

const FeedStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<FeedStack.Navigator initialRouteName={ERoutes.NewsList}>
			<FeedStack.Screen
				name={ERoutes.NewsList}
				component={NewsScreen}
				options={{ header: NewsScreenRootHeader, title: title(activeSpace, t('feed')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</FeedStack.Navigator>
	);
};

const VotingsStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<VotingsStack.Navigator initialRouteName={ERoutes.VoteList}>
			<VotingsStack.Screen
				name={ERoutes.VoteList}
				component={VoteListScreen}
				options={{ header: VoteListScreenRootHeader, title: title(activeSpace, t('Votings')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</VotingsStack.Navigator>
	);
};

const ActiveVotingStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<VotingsStack.Navigator initialRouteName={ERoutes.ActiveVoting}>
			<VotingsStack.Screen
				name={ERoutes.ActiveVoting}
				component={ActiveVoteScreen}
				options={{ header: VoteListScreenRootHeader, title: title(activeSpace, t('Votings')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</VotingsStack.Navigator>
	);
};

const SpeakersStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<SpeakersStack.Navigator initialRouteName={ERoutes.Speakers}>
			<SpeakersStack.Screen
				name={ERoutes.Speakers}
				component={SpeakerListScreen}
				options={{ header: SpeakerListScreenRootHeader, title: title(activeSpace, t('Speakers')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</SpeakersStack.Navigator>
	);
};

const AttendeesStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<AttendeesStack.Navigator initialRouteName={ERoutes.AttendeesList}>
			<AttendeesStack.Screen
				name={ERoutes.AttendeesList}
				component={AttendeeListScreen}
				options={{ header: AttendeeListScreenRootHeader, title: title(activeSpace, t('Attendees')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</AttendeesStack.Navigator>
	);
};

const ExposStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<ExposStack.Navigator initialRouteName={ERoutes.Expos}>
			<ExposStack.Screen
				name={ERoutes.Expos}
				component={ExpoListScreen}
				options={{ header: ExpoListScreenRootHeader, title: title(activeSpace, t('Expos')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</ExposStack.Navigator>
	);
};

const SponsorsStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<ExposStack.Navigator initialRouteName={ERoutes.Sponsors}>
			<ExposStack.Screen
				name={ERoutes.Sponsors}
				component={ExpoListScreen}
				options={{ header: ExpoListScreenRootHeader, title: title(activeSpace, t('Expos')) }}
				initialParams={{ isSponsors: true, key: props.route.params.key }}
			/>
		</ExposStack.Navigator>
	);
};

const ScheduleStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<ScheduleStack.Navigator initialRouteName={ERoutes.Schedule}>
			<ScheduleStack.Screen
				name={ERoutes.Schedule}
				component={ScheduleScreen}
				options={{ header: ScheduleScreenRootHeader, title: title(activeSpace, t('Schedule')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</ScheduleStack.Navigator>
	);
};

const ChatStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<ChatStack.Navigator initialRouteName={ERoutes.ChatOverview}>
			<ChatStack.Screen
				name={ERoutes.ChatOverview}
				component={ChatOverviewScreen}
				options={{ header: ChatOverviewScreenRootHeader, title: title(activeSpace, t('Chat')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</ChatStack.Navigator>
	);
};

const SettingsStackNavigator = () => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<SettingsStack.Navigator initialRouteName={ERoutes.Administrate}>
			<SettingsStack.Screen
				name={ERoutes.Administrate}
				component={AdministrateScreen}
				options={{ header: AdministrateScreenRootHeader, title: title(activeSpace, t('Administrate')) }}
			/>
		</SettingsStack.Navigator>
	);
};

const MeetingStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<MeetingStack.Navigator initialRouteName={ERoutes.MeetingList}>
			<MeetingStack.Screen
				name={ERoutes.MeetingList}
				component={MeetingListScreen}
				options={{ header: MeetingListScreenRootHeader, title: title(activeSpace, t('Meetings')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</MeetingStack.Navigator>
	);
};

const NetworkingStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<NetworkingStack.Navigator initialRouteName={ERoutes.NetworkingRoomList}>
			<NetworkingStack.Screen
				name={ERoutes.NetworkingRoomList}
				component={NetworkingRoomListScreen}
				options={{ header: NetworkingRoomListScreenRootHeader, title: title(activeSpace, t('networking')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</NetworkingStack.Navigator>
	);
};

const MediaItemStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<MediaItemStack.Navigator initialRouteName={ERoutes.MediaItemList}>
			<MediaItemStack.Screen
				name={ERoutes.MediaItemList}
				component={MediaItemListScreen}
				options={{ header: MediaItemListScreenRootHeader, title: title(activeSpace, t('Media Items')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</MediaItemStack.Navigator>
	);
};

const MapStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<MapStack.Navigator initialRouteName={ERoutes.Maps}>
			<MapStack.Screen
				name={ERoutes.Maps}
				component={MapsScreen}
				options={{ header: MapsScreenRootHeader, title: title(activeSpace, t('Maps')) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</MapStack.Navigator>
	);
};

const WebStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<WebStack.Navigator initialRouteName={ERoutes.Web}>
			<WebStack.Screen
				name={ERoutes.Web}
				component={WebScreen}
				options={{ header: WebScreenRootHeader, title: title(activeSpace) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</WebStack.Navigator>
	);
};

const MarkdownStackNavigator = (props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	return (
		<MarkdownStack.Navigator initialRouteName={ERoutes.Markdown}>
			<MarkdownStack.Screen
				name={ERoutes.Markdown}
				component={MarkdownScreen}
				options={{ header: MarkdownScreenRootHeader, title: title(activeSpace) }}
				initialParams={{ key: props.route.params.key }}
			/>
		</MarkdownStack.Navigator>
	);
};

const MoreStackNavigator = () => {
	return (
		<MoreStack.Navigator initialRouteName={ERoutes.More}>
			<></>
		</MoreStack.Navigator>
	);
};

const MyTabNav = () => {
	const navigation = useNavigation();
	const { t } = useTranslation();
	const { isTabletOrMobile } = useQuery();
	const { activeSpace, iAmSpaceAdmin } = useSpace();

	const [tabs, setTabs] = useState<any[] | undefined>(undefined);

	const jwt = useSelector((store: IRootState) => store.auth.jwt);

	useEffect(() => {
		if (activeSpace) {
			navigation.setParams({ spaceId: activeSpace.spaceId });
		}
	}, [activeSpace]);

	useEffect(() => {
		let _tabs: any[] = [];
		if (activeSpace) {
			if (activeSpace.features) {
				let activeFeatures = Object.values(activeSpace.features.list).filter((e) => e.isActive);
				const hasAttendeeActive = activeFeatures.find((e) => e.key === 'attendees' && e.isActive);
				if (!hasAttendeeActive) {
					const forbidden = ['chats', 'meetings', 'networking'];
					activeFeatures = activeFeatures.filter((e) => !forbidden.includes(e.key));
				}

				if (IS_WEB && !isTabletOrMobile) {
					_tabs = _tabs.concat(_getTabsForFeatures(activeFeatures, activeFeatures.length));

					_tabs.push(
						<SpaceTab.Screen
							key="tab_settings"
							name={ERoutes.SettingsTab}
							component={SettingsStackNavigator}
							options={{ headerShown: false, title: title(activeSpace, t('Settings')) }}
						/>
					);
				} else if (activeFeatures.length > (activeSpace.features?.tabbarCount ?? MAXTABCOUNT)) {
					_tabs = _tabs.concat(_getTabsForFeatures(activeFeatures, activeSpace.features?.tabbarCount ?? MAXTABCOUNT));

					_tabs.push(
						<SpaceTab.Screen
							key="tab_more"
							name={ERoutes.More}
							component={MoreStackNavigator}
							options={{ headerShown: false }}
						/>
					);
				} else {
					_tabs = _tabs.concat(_getTabsForFeatures(activeFeatures, activeFeatures.length));

					if (jwt && iAmSpaceAdmin) {
						_tabs.push(
							<SpaceTab.Screen
								key="tab_settings"
								name={ERoutes.SettingsTab}
								component={SettingsStackNavigator}
								options={{ headerShown: false, title: title(activeSpace, t('Settings')) }}
							/>
						);
					}
				}
			} else {
				if (jwt && iAmSpaceAdmin) {
					_tabs.push(
						<SpaceTab.Screen
							key="tab_settings"
							name={ERoutes.SettingsTab}
							component={SettingsStackNavigator}
							options={{ headerShown: false, title: title(activeSpace, t('Settings')) }}
						/>
					);
				}
			}
			setTabs(_tabs);
		}
	}, [activeSpace, jwt, iAmSpaceAdmin]);

	const _getTabsForFeatures = (features: IFeatureInfo[], count: number) => {
		const _tabs: JSX.Element[] = [];
		const addedRoutes: Record<string, number> = {};
		for (let i = 0; i < count; i++) {
			let tab = _getTab(features[i], i);

			if (tab) {
				if (features[i].isCustom) {
					if (addedRoutes[tab.name]) {
						addedRoutes[tab.name] += 1;
						switch (features[i].contentType) {
							case 'iFrame':
							case 'markdown':
								tab.name = `${tab.name}${addedRoutes[tab.name]}`;
								break;
							default:
								tab.name = `${tab.name}${addedRoutes[tab.name]}`;

								break;
						}
					} else {
						addedRoutes[tab.name] = 1;
						tab.name = `${tab.name}1`;
					}
				}

				_tabs.push(
					<SpaceTab.Screen
						key={features[i].key}
						name={tab.name}
						component={tab.component}
						initialParams={{ key: features[i].key }}
						options={{ headerShown: false, ...tab.options }}
					/>
				);
			}
		}

		return _tabs;
	};

	const _getTab = (tab: IFeatureInfo, index: number) => {
		switch (tab.key) {
			case 'votings':
				return {
					name: ERoutes.VotingsTab.toString(),
					component: VotingsStackNavigator,
					options: { title: title(activeSpace, t('votings')) }
				};
			case 'feed':
				return {
					name: ERoutes.FeedTab,
					component: FeedStackNavigator,
					options: { title: title(activeSpace, t('feed')) }
				};
			case 'speakers':
				return {
					name: ERoutes.SpeakersTab,
					component: SpeakersStackNavigator,
					options: { title: title(activeSpace, t('speakers')) }
				};
			case 'attendees':
				return {
					name: ERoutes.AttendeesTab,
					component: AttendeesStackNavigator,
					options: { title: title(activeSpace, t('Attendees')) }
				};
			case 'expos':
				return {
					name: ERoutes.ExposTab,
					component: ExposStackNavigator,
					options: { title: title(activeSpace, t('expos')) }
				};
			case 'sponsors':
				return {
					name: ERoutes.SponsorsTab,
					component: SponsorsStackNavigator,
					options: { title: title(activeSpace, t('sponsors')) }
				};
			case 'schedules':
				return {
					name: ERoutes.ScheduleTab,
					component: ScheduleStackNavigator,
					options: { title: title(activeSpace, t('agenda')) }
				};
			case 'chats':
				return {
					name: ERoutes.ChatTab,
					component: ChatStackNavigator,
					options: { title: title(activeSpace, t('chats')) }
				};
			case 'meetings':
				return {
					name: ERoutes.MeetingTab,
					component: MeetingStackNavigator,
					options: { title: title(activeSpace, t('meetings')) }
				};
			case 'networking':
				return {
					name: ERoutes.NetworkingTab,
					component: NetworkingStackNavigator,
					options: { title: title(activeSpace, t('networking')) }
				};
			case 'mediaitems':
				return {
					name: ERoutes.MediaItemsTab,
					component: MediaItemStackNavigator,
					options: { title: title(activeSpace, t('Media Items')) }
				};
			case 'maps':
				return {
					name: ERoutes.MapTab,
					component: MapStackNavigator,
					options: { title: title(activeSpace, t('maps')) }
				};
			default:
				switch (tab.contentType) {
					case 'externalUrl':
					case 'iFrame':
						return {
							name: ERoutes.WebTab,
							component: WebStackNavigator,
							options: { title: title(activeSpace) }
						};
					case 'markdown':
						return {
							name: ERoutes.MarkdownTab,
							component: MarkdownStackNavigator,
							options: { title: title(activeSpace) }
						};
					case 'schedules':
						return {
							name: ERoutes.ScheduleTab,
							component: ScheduleStackNavigator,
							options: { title: title(activeSpace, t('schedules')) }
						};
					case 'expos':
						return {
							name: ERoutes.ExposTab,
							component: ExposStackNavigator,
							options: { title: title(activeSpace, t('expos')) }
						};
					case 'speakers':
						return {
							name: ERoutes.SpeakersTab,
							component: SpeakersStackNavigator,
							options: { title: title(activeSpace, t('speakers')) }
						};
					case 'mediaitems':
						return {
							name: ERoutes.MediaItemsTab,
							component: MediaItemStackNavigator,
							options: { title: title(activeSpace, t('Media Items')) }
						};
					case 'attendees':
						return {
							name: ERoutes.AttendeesTab,
							component: AttendeesStackNavigator,
							options: { title: title(activeSpace, t('Attendees')) }
						};
					case 'votings':
						return {
							name: ERoutes.VotingsTab,
							component: ActiveVotingStackNavigator,
							options: { title: title(activeSpace, t('Votings')) }
						};
					default:
						break;
				}

				return undefined;
		}
	};

	if (!tabs || tabs.length === 0) {
		return null;
	}

	return (
		<SpaceTab.Navigator
			detachInactiveScreens
			initialRouteName={tabs.length > 0 ? tabs[0].name : undefined}
			tabBar={(props) => <TabBar {...props} />}
		>
			{tabs}
		</SpaceTab.Navigator>
	);
};

const NoSpaceStackComponent = () => {
	const { t } = useTranslation();

	return (
		<NoSpaceStack.Navigator
			initialRouteName={
				!PRESET_SPACEIDS ? ERoutes.Onboarding : PRESET_SPACEIDS.length === 1 ? ERoutes.SpaceSummary : ERoutes.SpaceSelect
			}
		>
			{!PRESET_SPACEIDS && (
				<NoSpaceStack.Screen
					name={ERoutes.Onboarding}
					component={OnboardingScreen}
					options={{ header: OnboardingScreenHeader, title: title(undefined, t('Onboarding')) }}
				/>
			)}
			{(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) && (
				<NoSpaceStack.Screen
					name={ERoutes.SpaceSelect}
					component={SpaceSelectScreen}
					options={{ header: SpaceSelectScreenHeader, title: title(undefined, '') }}
				/>
			)}
			<NoSpaceStack.Screen
				name={ERoutes.SpaceAuth}
				component={SpaceAuthenticationScreen}
				options={{ header: SpaceAuthenticationScreenHeader, title: title(undefined, t('Authentication')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.SpaceCreate}
				component={SpaceCreationScreen}
				options={{ header: SpaceCreationScreenHeader, title: title(undefined, t('Create Space')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.EditProfile}
				component={EditProfileScreen}
				options={{ header: EditProfileScreenHeader, title: title(undefined, t('Profile')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.Tutorial}
				component={TutorialScreen}
				options={{ header: TutorialScreenHeader, title: title(undefined, t('Tutorial')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.Components}
				component={ComponentsScreen}
				options={{ header: ComponentsScreenHeader, title: title(undefined, t('Components')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.SpaceSummary}
				component={SpaceSummaryScreen}
				options={{ header: SpaceSummaryScreenHeader, title: title(undefined, t('Space')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.SpaceSearch}
				component={SpaceSearchScreen}
				options={{ header: SpaceSearchScreenHeader, title: title(undefined, t('Search')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.SpaceOnboarding}
				component={SpaceOnboardingScreen}
				options={{ header: SpaceOnboardingScreenHeader, title: title(undefined, t('Onboarding')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.Landingpage}
				component={LandingpageScreen}
				options={{ header: LandingpageScreenHeader, title: title(undefined, t('Landingpage')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.Prestart}
				component={PrestartScreen}
				options={{ header: PrestartScreenHeader, title: title(undefined, t('Prestart')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.SelfService}
				component={SelfServiceScreen}
				options={{ header: SelfServiceScreenHeader, title: title(undefined, t('Self Service')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.MySpaces}
				component={MySpacesScreen}
				options={{ header: MySpacesScreenHeader, title: title(undefined, t('My Spaces')) }}
			/>
			<NoSpaceStack.Screen
				name={ERoutes.ExternalMaps}
				component={ExternalMapsScreen}
				options={{ header: ExternalMapsScreenHeader, title: title(undefined, t('My Spaces')) }}
			/>
		</NoSpaceStack.Navigator>
	);
};

const PresenterStackComponent = () => {
	const { t } = useTranslation();
	return (
		<PresenterStack.Navigator initialRouteName={ERoutes.ResultView}>
			<PresenterStack.Screen
				name={ERoutes.ResultView}
				component={ResultViewScreen}
				options={{ header: ResultViewScreenHeader, title: title(undefined, t('Results')) }}
			/>
			<PresenterStack.Screen
				name={ERoutes.PublicAgenda}
				component={PublicAgendaScreen}
				options={{ header: PublicAgendaScreenHeader, title: title(undefined, t('Public Agenda')), headerShown: false }}
			/>
			<PresenterStack.Screen
				name={ERoutes.Media}
				component={MediaScreen}
				options={{ header: MediaScreenHeader, title: title(undefined, t('Media')) }}
			/>
		</PresenterStack.Navigator>
	);
};

const SelfServiceStackComponent = () => {
	const { t } = useTranslation();
	return (
		<SelfServiceStack.Navigator initialRouteName={ERoutes.SelfService}>
			<SelfServiceStack.Screen
				name={ERoutes.SelfService}
				component={SelfServiceScreen}
				options={{ header: SelfServiceScreenHeader, title: title(undefined, t('Self Service')) }}
			/>
		</SelfServiceStack.Navigator>
	);
};

const ExternalMapsStackComponent = () => {
	const { t } = useTranslation();
	return (
		<ExternalMapsStack.Navigator initialRouteName={ERoutes.ExternalMaps}>
			<ExternalMapsStack.Screen
				name={ERoutes.ExternalMaps}
				component={ExternalMapsScreen}
				options={{ header: ExternalMapsScreenHeader, title: title(undefined, t('Maps')) }}
			/>
		</ExternalMapsStack.Navigator>
	);
};

const routesWithoutSidebar: string[] = [
	ERoutes.SelfService,
	'presentation',
	ERoutes.PublicAgenda,
	ERoutes.ResultView,
	ERoutes.SpaceOnboarding,
	ERoutes.Landingpage,
	ERoutes.Prestart,
	ERoutes.ExternalMaps,
	'external'
];

export const AppNavigator = () => {
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const hasAcceptedPrivacyPolicy = useSelector((store: IRootState) => store.auth.hasAcceptedPrivacyPolicy);

	const setIsTabbarVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setIsTabbarVisible);

	useEffect(() => {
		navigation.addListener('state', _handleStateChange);
	}, []);

	const _handleStateChange = ({ data }) => {
		let tabIndex = 0;
		let stackIndex = 0;

		if (data?.state) {
			tabIndex = data.state.index;
			if (data?.state?.routes && data?.state?.routes[tabIndex] && data?.state?.routes[tabIndex]?.state) {
				if (data?.state?.routes[tabIndex]?.state?.index) {
					stackIndex = data?.state?.routes[tabIndex]?.state?.index;
				}
			}
		}

		if (IS_WEB && !isTabletOrMobile) {
			setIsTabbarVisible(false);
		} else {
			setIsTabbarVisible(stackIndex === 0 || (isTabletOrMobile && stackIndex <= 1));
		}
	};

	const getDrawerWidth = () => {
		const navigationState = navigation?.getState();
		if (navigationState?.routes && navigationState?.routes[0]?.state?.routes?.find((e) => routesWithoutSidebar.includes(e.name))) {
			return 0;
		}

		if (activeSpace) {
			const { isAdmin, needsPin } = canJoinSpace(activeSpace, profile);

			if (!isAdmin && needsPin) {
				return 0;
			}
		}

		if (
			navigationState?.routes &&
			navigationState?.routes[0]?.name &&
			!navigationState?.routes[0]?.state &&
			navigationState?.routes?.find((e) => routesWithoutSidebar.includes(e.name) && e.state)
		) {
			return 0;
		}

		if (IS_WEB) {
			if (hasAcceptedPrivacyPolicy || PRESET_SPACEIDS) {
				if (isTabletOrMobile) {
					return Dimensions.get('window').width * 0.8 > 300 ? 300 : '80%';
				}
				return 300;
			}

			return 0;
		}

		return '80%';
	};

	return (
		<Drawer.Navigator
			useLegacyImplementation={true}
			screenOptions={{
				drawerStyle: {
					width: getDrawerWidth(),
					maxWidth: 300,
					overflow: 'hidden',
					backgroundColor: theme.background,
					borderRightColor: activeSpace ? theme.background : theme.lightgray
				},
				sceneContainerStyle: {
					width: '100%',
					alignSelf: hasAcceptedPrivacyPolicy || (PRESET_SPACEIDS?.length ?? 0) > 0 ? 'flex-start' : 'center',
					backgroundColor: '#fff'
				},
				drawerContentStyle: {}
			}}
			initialRouteName="app"
			drawerContent={(props) => {
				const navigationState = navigation?.getState();

				if (
					navigationState?.routes &&
					navigationState?.routes[0]?.state?.routes?.find((e) => routesWithoutSidebar.includes(e.name))
				) {
					return null;
				}

				if (
					navigationState?.routes &&
					navigationState.routes[0]?.name &&
					!navigationState.routes[0]?.state &&
					navigationState.routes?.find((e) => routesWithoutSidebar.includes(e.name) && e.state)
				) {
					return null;
				}

				return <DrawerView {...props} />;
			}}
		>
			<Drawer.Screen
				name="app"
				component={hasAcceptedPrivacyPolicy || (PRESET_SPACEIDS?.length ?? 0) > 0 ? MainStackNav : NoSpaceStackComponent}
				options={{
					headerShown: false,
					drawerType: IS_WEB && !isTabletOrMobile ? 'permanent' : 'slide',
					swipeEnabled: hasAcceptedPrivacyPolicy || (PRESET_SPACEIDS?.length ?? 0) > 0
				}}
			/>
			<Drawer.Screen
				name="presentation"
				component={PresenterStackComponent}
				options={{
					headerShown: false
				}}
			/>
			<Drawer.Screen
				name={ERoutes.SelfService}
				component={SelfServiceStackComponent}
				options={{
					headerShown: false
				}}
			/>
			<Drawer.Screen
				name="external"
				component={ExternalMapsStackComponent}
				options={{
					headerShown: false
				}}
			/>
		</Drawer.Navigator>
	);
};
