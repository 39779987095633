import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoundButton } from 'components/Button';
import { HSModal } from '../Modal';
import { IVoteOptionVote } from 'components/Forms/Vote/VoteQuiz/VoteQuizOptionsForm';
import { Vibration, View } from 'react-native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { IVoteOption } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, swapArrayItems, validateForm } from 'helper';
import { FormCheckbox, FormTextInput } from 'components/Form';
import { IS_ANDROID } from 'helper/platform';
import { getQuizOptionFormSchema } from 'config/yupSchemas';
import { HSDragCard } from 'components/Card';
import { FormError } from 'components/Form/FormError';
import { FormLabel } from 'components/Form/FormLabel';

interface IDataProtectionModal {
	testID: string;
	isVisible: boolean;
	onChange: (value: IVoteOptionVote) => void;
	onClose: () => void;
	onSubmit: () => void;
	values: IVoteOptionVote;
}
const TESTIDPREFIX = 'votequizmodal';

export const VoteQuizVoteOptionModal = (props: IDataProtectionModal) => {
	const { testID, isVisible, onChange, onClose, values, onSubmit } = props;
	const { t } = useTranslation();

	const [errors, setErrors] = useState<Record<string, string>>({});

	const _addOption = () => {
		let _options = values?.options ?? [];
		_options.push({
			description: '', // is used as answer for the question here
			order: values?.options?.length ?? 0,
			isCorrect: false
		});
		onChange({ ...values, options: _options });
	};

	const _removeOption = (index) => {
		const _options = values.options;
		_options?.splice(index, 1);
		onChange({ ...values, options: _options });
	};

	const _submit = async () => {
		const errors = await validateForm(getQuizOptionFormSchema(values), values);
		if (errors) {
			setErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setErrors({});
		}

		if (onSubmit) {
			onSubmit();
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		const list: IVoteOption[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);

		onChange({ ...values, options: list });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ ...values, options: list });
	};

	const _renderItem = (params: RenderItemParams<IVoteOption>) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_option_${index}`}
				isActive={isActive}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onRemove={() => _removeOption(index)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
			>
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_multiplechoicedescription_${index}`}
					placeholder={`${t('Answer')}*`}
					isRequired
					formStyle={{ marginBottom: 10 }}
					error={errors[`options[${index}].description`]}
					value={item.description}
					onChangeText={(value) => {
						if (values.options && index !== undefined) {
							let _options = values.options;
							_options[index].description = value;
							onChange({ ...values, options: _options });
						}
					}}
				/>

				<FormCheckbox
					style={{ marginBottom: 0 }}
					innerContainerStyle={{ alignItems: 'center' }}
					testID={`${TESTIDPREFIX}_checkbox_markascorrect_${index}`}
					label={t('markAsCorrect')}
					value={item.isCorrect}
					onPress={() => {
						if (values.options && index !== undefined) {
							let _options = values.options;
							const oldVal = _options[index].isCorrect;
							_options.forEach((option) => (option.isCorrect = false));
							_options[index].isCorrect = !oldVal;
							onChange({ ...values, options: _options });
						}
					}}
				/>
			</HSDragCard>
		);
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			onSubmit={() => _submit()}
			isSubmitDisabled={
				!values?.question ||
				!values?.options ||
				values?.options?.length < 2 ||
				!values?.score ||
				!values?.timeOut ||
				values?.options?.filter((e) => isEmptyString(e.description))?.length > 0 ||
				!values?.options?.find((e) => e.isCorrect)
			}
		>
			<View style={{ overflow: 'hidden', flex: 1 }}>
				<DraggableFlatList
					testID={`${TESTIDPREFIX}_scrollview`}
					ListHeaderComponent={
						<View>
							<FormTextInput
								testID={`${TESTIDPREFIX}_textinput_question`}
								label={t('Question')}
								hint={t('questionHint')}
								isRequired
								value={values?.question}
								error={errors.question}
								onChangeText={(value) => onChange({ ...values, question: value })}
							/>
							<FormTextInput
								testID={`${TESTIDPREFIX}_textinput_hint`}
								label={t('Hint')}
								hint={t('VoteHint')}
								value={values?.hint}
								error={errors.hint}
								onChangeText={(value) => onChange({ ...values, hint: value })}
							/>
							<FormTextInput
								isRequired
								testID={`${TESTIDPREFIX}_textinput_timeout`}
								label={t('timeOut')}
								hint={t('timeOutHint')}
								value={values?.timeOut?.toString()}
								error={errors.timeOut}
								onChangeText={(value) => onChange({ ...values, timeOut: Number(value) })}
								keyboardType="number-pad"
							/>
							<FormTextInput
								isRequired
								testID={`${TESTIDPREFIX}_textinput_score`}
								label={t('Points')}
								hint={t('scoreHint')}
								value={values?.score.toString()}
								error={errors.score}
								onChangeText={(value) => onChange({ ...values, score: Number(value) })}
								keyboardType="number-pad"
							/>
							<FormLabel testID={`${TESTIDPREFIX}_list_answeroptions`} label={t('answerOptionsQuiz')} />
						</View>
					}
					ListFooterComponent={() => {
						return (
							<>
								<RoundButton
									testID={`${TESTIDPREFIX}_button_add`}
									icon={EDefaultIconSet.Add}
									onPress={() => _addOption()}
									isDisabled={(values?.options?.length ?? 0) > 100}
									alignSelf="flex-end"
									title={t('addAnswerQuiz')}
								/>
								<FormError testID={`${TESTIDPREFIX}_createMultipleChoiceOption`} error={errors.options} />
							</>
						);
					}}
					data={values?.options ?? []}
					renderItem={(params) => _renderItem(params)}
					keyExtractor={(item, index) => `${TESTIDPREFIX}_createMultipleChoiceOption_${index}`}
					onDragBegin={() => {
						if (IS_ANDROID) {
							Vibration.vibrate(10, false);
						}
					}}
					onDragEnd={({ data }) => {
						data.forEach((opt, index) => (opt.order = index));
						onChange({ ...values, options: data });
					}}
				/>
			</View>
		</HSModal>
	);
};
