import React from 'react';
import { TouchableOpacity, TouchableOpacityProps, ViewStyle } from 'react-native';
import { Text } from 'components/Text/Text';
import { normalizeFontSize } from 'helper/fontSize';
import { Spinner } from 'components/Spinner';
import { useTheme } from 'hooks/useTheme';
import { BUTTONBORDERRADIUS, BUTTONBORDERWIDTH } from './constants';
import { Icon } from 'components/Icon/Icon';
import { TabbarBadge } from 'components/Navigation/TabbarBadge';

export type TButtonType = 'success' | 'warning' | 'danger' | 'primary' | 'cancel';

interface IButtonComponent extends TouchableOpacityProps {
	testID: string;
	title: string;
	isLoading?: boolean;
	type?: TButtonType;
	isOutline?: boolean;
	isDisabled?: boolean;
	fullWidth?: boolean;
	buttonType?: 'default' | 'sectionheader';
	icon?: string;
	style?: ViewStyle;
	badge?: number;
}

export const Button = (props: IButtonComponent) => {
	const { title, isLoading, type, style, fullWidth, isDisabled, isOutline, buttonType, icon, badge } = props;
	const { theme } = useTheme();

	const styles = {
		base: {
			alignSelf: 'flex-start',
			width: null,
			paddingVertical: 10,
			paddingHorizontal: 20,
			justifyContent: 'center',
			alignItems: 'center',
			borderWidth: BUTTONBORDERWIDTH,
			borderRadius: BUTTONBORDERRADIUS,
			flexDirection: 'row'
		},
		text: {
			fontSize: normalizeFontSize(14),
			fontWeight: 'bold'
		},
		sectionHeader: {
			alignSelf: 'flex-start',
			marginTop: 2,
			width: null,
			paddingVertical: 1,
			paddingHorizontal: 3,
			justifyContent: 'center',
			alignItems: 'center',
			borderWidth: BUTTONBORDERWIDTH,
			borderRadius: BUTTONBORDERRADIUS
		},
		sectionHeaderText: {
			fontSize: normalizeFontSize(14),
			fontWeight: 'bold'
		}
	};

	let _style = {};
	let _textStyle = {};
	switch (buttonType) {
		case 'sectionheader':
			_style = styles.sectionHeader;
			_textStyle = styles.sectionHeaderText;
			break;
		default:
			_style = styles.base;
			_textStyle = styles.text;
			break;
	}

	let textColor = theme.primaryContrast;
	if (fullWidth) {
		_style = {
			..._style,
			width: '100%',
			maxWidth: style?.maxWidth ?? 400
		};
	}

	const _getBGColor = (color: string) => {
		if (!isOutline) {
			return isDisabled ? theme.formgray : color;
		}
		return color;
	};

	if (!isOutline) {
		switch (type) {
			case 'success':
				_style = { ..._style, backgroundColor: _getBGColor(theme.success), borderColor: _getBGColor(theme.success) };
				break;
			case 'warning':
				_style = { ..._style, backgroundColor: _getBGColor(theme.warning), borderColor: _getBGColor(theme.warning) };
				break;
			case 'danger':
				_style = { ..._style, backgroundColor: _getBGColor(theme.danger), borderColor: _getBGColor(theme.danger) };
				break;
			case 'cancel':
				_style = { ..._style, backgroundColor: _getBGColor(theme.gray), borderColor: _getBGColor(theme.gray) };
				break;
			case 'primary':
			default:
				_style = { ..._style, backgroundColor: _getBGColor(theme.primary), borderColor: _getBGColor(theme.primary) };
				break;
		}
	} else {
		switch (type) {
			case 'success':
				textColor = isDisabled ? theme.formgray : theme.success;
				_style = {
					..._style,
					backgroundColor: _getBGColor(theme.contentBackgroundColor ?? theme.background),
					borderColor: _getBGColor(theme.success),
					borderWidth: 1
				};
				break;
			case 'warning':
				textColor = isDisabled ? theme.formgray : theme.warning;
				_style = {
					..._style,
					backgroundColor: _getBGColor(theme.contentBackgroundColor ?? theme.background),
					borderColor: _getBGColor(theme.warning)
				};
				break;
			case 'danger':
				textColor = isDisabled ? theme.formgray : theme.danger;
				_style = {
					..._style,
					backgroundColor: _getBGColor(theme.contentBackgroundColor ?? theme.background),
					borderColor: _getBGColor(theme.danger)
				};
				break;
			case 'cancel':
				textColor = isDisabled ? theme.formgray : theme.gray;
				_style = {
					..._style,
					backgroundColor: _getBGColor(theme.contentBackgroundColor ?? theme.background),
					borderColor: _getBGColor(theme.gray)
				};
				break;
			case 'primary':
			default:
				textColor = isDisabled ? theme.formgray : theme.primary;
				_style = {
					..._style,
					backgroundColor: _getBGColor(theme.contentBackgroundColor ?? theme.background),
					borderColor: _getBGColor(theme.primary),
					borderWidth: 1,
					borderStyle: 'solid'
				};
				break;
		}
	}

	return (
		<TouchableOpacity {...props} activeOpacity={1} disabled={isDisabled} style={[_style, style]}>
			{isLoading ? (
				<Spinner color={theme.primaryContrast} />
			) : (
				<>
					<Text numberOfLines={title.includes('\n') ? 2 : 1} adjustsFontSizeToFit style={[_textStyle, { color: textColor }]}>
						{title}
					</Text>
					{icon && <Icon name={icon} color={textColor} containerStyle={{ marginLeft: 10 }} />}
					<TabbarBadge value={badge} right={0} />
				</>
			)}
		</TouchableOpacity>
	);
};
