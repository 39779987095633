import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';
import { selfServiceEditableOptions, sponsorOptions } from 'rematch/interfaces';

export const getDefaultExpoFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'mainsection',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'left'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never',
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'logo',
			fieldLabel: i18next.t('Logo'),
			fieldType: 'logo',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldType: 'title',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'catchphrase',
			fieldLabel: i18next.t('Subtitle'),
			fieldType: 'subtitle',
			visibility: 'visible',
			isDefaultField: true,
			showOnDetailScreen: true,
			selfServiceVisibility: 'always',
			maxLength: 255
		},
		{
			fieldName: 'isPinned',
			fieldLabel: i18next.t('isPinned'),
			fieldHint: i18next.t('mediaItemIsPinnedHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: false,
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'linkedInProfileUrl',
			fieldLabel: i18next.t('linkedInProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'xingProfileUrl',
			fieldLabel: i18next.t('xingProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'twitterProfileUrl',
			fieldLabel: i18next.t('twitterProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'instagramProfileUrl',
			fieldLabel: i18next.t('instagramProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'facebookProfileUrl',
			fieldLabel: i18next.t('facebookProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'youTubeProfileUrl',
			fieldLabel: i18next.t('youtubeProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'tikTokProfileUrl',
			fieldLabel: i18next.t('tikTokProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'blogUrl',
			fieldLabel: i18next.t('blog'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'description',
			fieldLabel: i18next.t('Description'),
			fieldType: 'markdown',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'isHidden',
			fieldLabel: i18next.t('isHidden'),
			fieldHint: i18next.t('isHiddenHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Sponsor'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'left'
		},
		{
			fieldName: 'sponsorType',
			fieldLabel: i18next.t('sponsorType'),
			fieldType: 'multiswitch',
			options: sponsorOptions(),
			isDefaultField: true,
			defaultValue: 'none',
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'sponsorOrder',
			fieldLabel: i18next.t('sponsorOrder'),
			fieldType: 'number',
			visibility: 'visible',
			isDefaultField: true,
			showOnDetailScreen: false,
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'section3',
			fieldLabel: i18next.t('Images & Videos'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'right'
		},
		{
			fieldName: 'sponsorBanner',
			fieldLabel: i18next.t('sponsorBanner'),
			fieldType: 'image',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false
		},
		{
			fieldName: 'imageGallery',
			fieldLabel: i18next.t('Gallery Items'),
			fieldHint: i18next.t('expoMediaFormHint'),
			fieldType: 'gallery',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'section4',
			fieldLabel: i18next.t('Contact'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'right'
		},
		{
			fieldName: 'location',
			fieldLabel: i18next.t('location'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'website',
			fieldLabel: i18next.t('website'),
			fieldType: 'website',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'contactName',
			fieldLabel: i18next.t('contactName'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'contactPhone',
			fieldLabel: i18next.t('contactPhone'),
			fieldType: 'phoneNumber',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'contactEmail',
			fieldLabel: i18next.t('contactEmail'),
			fieldType: 'email',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false
		},
		{
			fieldName: 'street',
			fieldLabel: i18next.t('street'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false,
			maxLength: 255
		},
		{
			fieldName: 'zip',
			fieldLabel: i18next.t('zip'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false,
			maxLength: 255
		},
		{
			fieldName: 'city',
			fieldLabel: i18next.t('city'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false,
			maxLength: 255
		},
		{
			fieldName: 'country',
			fieldLabel: i18next.t('country'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false,
			maxLength: 255
		},
		{
			fieldName: 'section5',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'disabled',
			sectionPosition: 'right'
		},
		{
			fieldName: 'speakers',
			fieldLabel: i18next.t('Speakers'),
			fieldType: 'reference',
			referenceType: 'speakers',
			visibility: 'visible',
			isDefaultField: true,
			selfServiceVisibility: 'disabled',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderSpeakers')
		},
		{
			fieldName: 'schedules',
			fieldLabel: i18next.t('Schedules'),
			fieldType: 'reference',
			referenceType: 'schedules',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'disabled',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderSchedules')
		},
		{
			fieldName: 'attendees',
			fieldLabel: i18next.t('Attendees'),
			fieldType: 'reference',
			referenceType: 'attendees',
			visibility: 'visible',
			isDefaultField: true,
			selfServiceVisibility: 'disabled',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderAttendees')
		},
		{
			fieldName: 'mediaitems',
			fieldLabel: i18next.t('Media Items'),
			fieldType: 'reference',
			referenceType: 'mediaitems',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'disabled',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderMediaItems')
		},
		{
			fieldName: 'section6',
			fieldLabel: i18next.t('Self Service'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'left'
		},
		{
			fieldName: 'selfServiceEditable',
			fieldLabel: i18next.t('SelfServiceItemEditableLabel'),
			fieldType: 'multiswitch',
			options: selfServiceEditableOptions(),
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'selfServiceEmail',
			fieldLabel: i18next.t('selfServiceEmail'),
			fieldHint: i18next.t('selfServiceEmailHint'),
			fieldType: 'email',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false
		}
	];

	return arr;
};
