import { createModel } from '@rematch/core';
import { MULTISPACEURL } from 'config/constants';
import { translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import { RootModel } from './index';

type IDefaultState = {};

export const token = createModel<RootModel>()({
	state: {} as IDefaultState,
	reducers: {},
	effects: (dispatch) => ({
		async createToken(payload: { title: string }, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				if (activeSpace) {
					const { title } = payload;
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							activeSpace: activeSpace,
							userInfos: store.auth.userInfos,
							title: title
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/tokens`,
						body
					});
					return res;
				}
			} catch (error) {
				console.log('trackAction', error);
				return false;
			}
		},
		async loadTokens(payload: {}, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							...payload,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/tokens/loadtokens`,
						body
					});

					if (res) {
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return [];
			} catch (error) {
				console.log('loadReports', error);
				return [];
			}
		},
		async deleteToken(payload: { tokenId: number | string }, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { tokenId } = payload;

					const res = await dispatch.request.authenticatedRequest({
						method: 'DELETE',
						url: `${MULTISPACEURL}/tokens/${tokenId}`
					});

					if (res) {
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('loadReports', error);
				return false;
			}
		}
	})
});
