import React, { useState } from 'react';
import { View } from 'react-native';

interface IYouTubePlayer {
	url?: string;
	autoplay?: boolean;
}

export const YouTubePlayer = (props: IYouTubePlayer) => {
	const { url, autoplay } = props;

	const [containerWidth, setContainerWidth] = useState<number>(1);

	return (
		<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)} style={{ width: '100%' }}>
			<iframe
				src={url}
				style={{ width: containerWidth, height: (containerWidth / 16) * 9 }}
				allow="accelerometer; autoplay; clipboard-write; gyroscope; fullscreen; camera; microphone;encrypted-media; speaker; display-capture; picture-in-picture"
			/>
		</View>
	);
};
