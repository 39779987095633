import { IContentTypeField, ILauncherSpace } from 'config/interfaces';
import i18next from 'i18next';

export interface IResolveSelfServiceTokenPayload {
	token: string;
}

export interface IUpdateSelfServiceEntryPayload {
	token: string;
	space: ILauncherSpace;
	fields: IContentTypeField[];
	item: Record<string, any>;
}

export const selfServiceEditableOptions = () => {
	return [
		{
			key: 'yes',
			label: i18next.t('Yes')
		},
		{
			key: 'readOnly',
			label: i18next.t('ReadOnly')
		},
		{
			key: 'no',
			label: i18next.t('No')
		}
	];
};

export const sponsorOptions = () => {
	return [
		{
			key: 'none',
			label: i18next.t('noSponsor')
		},
		{
			key: 'sponsor',
			label: i18next.t('sponsor')
		},
		{
			key: 'sponsorOnly',
			label: i18next.t('sponsorOnly')
		}
	];
};
