import { EDefaultIconSet } from 'helper';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { Animated, View, Dimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ScheduleDetail } from 'components/Schedule';
import { MediaItemDetail } from 'components/MediaItem';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';
import { IMediaItem, ISchedule } from 'config/interfaces';

interface IMediaModal {}

export const MediaModal = (props: IMediaModal) => {
	const { right } = useSafeAreaInsets();
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);

	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);

	const _handleClose = () => {
		setMediaDetail({
			itemId: undefined,
			viewType: 'full',
			playbackStatus: 'paused'
		});
	};

	const _renderButtons = () => {
		return (
			<View style={{ paddingHorizontal: 10 }}>
				<RoundButton isOutline testID={'modal_button_close'} size="xs" icon={EDefaultIconSet.Close} onPress={_handleClose} />
				<RoundButton
					testID={'modal_button_expand'}
					icon={EDefaultIconSet.BackToFullScreen}
					isOutline
					size="xs"
					onPress={() => {
						navigation.navigate(ERoutes.Media, {
							itemId: mediaDetail.itemId,
							mediaType: mediaDetail.itemType,
							spaceId: activeSpace?.spaceId,
							isAutoplay: true
						});
						_handleClose();
					}}
				/>
			</View>
		);
	};

	const _renderContent = () => {
		switch (mediaDetail.itemType) {
			case 'schedule':
				return (
					<ScheduleDetail
						itemId={mediaDetail.itemId}
						item={mediaDetail.item as ISchedule | undefined}
						previewImage={mediaDetail.previewImage}
						hasPadding
						onClose={_handleClose}
						isPublic={mediaDetail.isPublic}
						isAutoplay
						playerOnly
						hideStreamSelection
						isModal
					/>
				);
			case 'mediaitem':
				return (
					<MediaItemDetail
						itemId={mediaDetail.itemId}
						item={mediaDetail.item as IMediaItem | undefined}
						previewImage={mediaDetail.previewImage}
						hasPadding
						isAutoplay
						playerOnly
						noMargin
						isModal
					/>
				);
			default:
				return null;
		}
	};

	if (mediaDetail.itemId && mediaDetail.itemType && mediaDetail.viewType === 'collapsed') {
		return (
			<Animated.View
				style={{
					position: 'absolute',
					bottom: 20,
					right: 20,
					alignSelf: 'center'
				}}
			>
				<Animated.View
					style={{
						bottom: 0,
						left: 'auto',
						right: 'auto',
						alignSelf: 'flex-end',
						paddingRight: Math.max(EHorizontalScreenPadding.Wide, right)
					}}
				>
					<View
						style={{
							position: 'relative',
							flex: 1,
							width: '100%',
							alignSelf: 'center'
						}}
					>
						<HSCard
							style={{
								borderRadius: 0,
								padding: 0,
								flexDirection: 'row-reverse',
								backgroundColor: theme.contentBackgroundColor ?? theme.background,
								overflow: 'hidden',
								marginBottom: 0
							}}
						>
							{_renderButtons()}
							<View style={{ width: Dimensions.get('screen').width * 0.2 }}>{_renderContent()}</View>
						</HSCard>
					</View>
				</Animated.View>
			</Animated.View>
		);
	}

	return null;
};
