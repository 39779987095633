import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderIconButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { EditSpaceInitialFormValues, IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import { EditSpaceSponsorForm } from 'components/Forms/Space';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { getEditSpaceSponsorsFormSchema } from 'config/yupSchemas';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import i18next from 'i18next';
import { ScrollView } from 'react-native';
import { HSCard } from 'components/Card';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import * as RootNavigation from '../../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditSpaceSponsors>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditSpaceSponsors>;
type RouteParams = StackParamList[ERoutes.EditSpaceSponsors];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editspacesponsores';

export const EditSpaceSponsorScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [formErrors, setFormErrors] = useState<IEditSpaceFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.addListener('focus', () => _updateValues());
		_updateValues();

		return () => navigation.removeListener('focus', () => _updateValues());
	}, []);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateValues();
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading,
			isRightNavPressDisabled: _isRightNavPressDisabled()
		});
	}, [formValues, isLoading, isFormLocked]);

	const _isRightNavPressDisabled = () => {
		return isEmptyString(formValues.sponsorTitle);
	};

	const _updateValues = () => {
		if (activeSpace) {
			setTimeout(() => {
				setFormValues({
					updated_at: activeSpace.updated_at,
					spaceId: activeSpace.spaceId,
					sponsorTitle: activeSpace.sponsorTitle ?? t('Sponsors'),
					sponsorTypeFeed: activeSpace.sponsorTypeFeed ?? 'none',
					sponsorTypeAgenda: activeSpace.sponsorTypeAgenda ?? 'none',
					sponsorTypeExpo: activeSpace.sponsorTypeExpo ?? 'none',
					sponsorTypeAttendees: activeSpace.sponsorTypeAttendees ?? 'none',
					sponsorTypeNetworking: activeSpace.sponsorTypeNetworking ?? 'none',
					sponsorTypeVotings: activeSpace.sponsorTypeVotings ?? 'none',
					sponsorTypeSpeaker: activeSpace.sponsorTypeSpeaker ?? 'none',
					sponsorTypeMediaItem: activeSpace.sponsorTypeMediaItem ?? 'none'
				});
			}, 100);
		}
	};

	const _updateSpace = async () => {
		const errors = await validateForm(getEditSpaceSponsorsFormSchema(formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Space has changed'),
				message: t('SpaceFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsLoading(true);
		const updateSuccess = await updateSpace({ data: formValues });
		setIsLoading(false);
		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _handleUpdate = (values) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(values);
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<EditSpaceSponsorForm values={formValues} errors={formErrors} onChange={_handleUpdate} isLoading={isLoading} />
				</HSCard>
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const EditSpaceSponsorScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Edit')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
