import { INetworkingRoom, IStrapiBase } from '.';
import { IMapPosition } from './map';

export interface ITimeSlot {
	start: string;
	end: string;
}

export enum EMeetingStatus {
	Accepted = 'Accepted',
	Pending = 'Pending',
	Declined = 'Declined'
}

export enum EMeetingType {
	Onsite = 'onsite',
	Remote = 'remote'
}

export enum EMeetingOnsiteType {
	Table = 'table',
	Individual = 'individual'
}

export interface IMeeting extends IStrapiBase {
	ownerId: string;
	partnerId: string;
	lastEditBy: string;
	content: string;
	timeSlot: ITimeSlot;
	meetingType: EMeetingType;
	status: EMeetingStatus;
	spaceId: string;
	networkingroom?: INetworkingRoom;
	meetingtable?: IMeetingTable;
	meetingOnsiteType?: EMeetingOnsiteType;
	meetingOnsiteLocation?: string;
}

export interface IMeetingRequest {
	id?: number;
	ownerId?: string;
	partnerId?: string;
	content?: string;
	timeSlot?: ITimeSlot;
	meetingType?: EMeetingType;
	meetingOnsiteType?: EMeetingOnsiteType;
	meetingOnsiteLocation?: string;
}

export interface IMeetingTable extends IStrapiBase {
	title: string;
	subtitle?: string;
	spaceId?: string;
	mapposition?: IMapPosition;
	meetings?: IMeeting[];
}

export interface ICreateMeetingTableFormValues {
	title?: string;
	subtitle?: string;
}

export interface ICreateMeetingTableFormErrors {
	title?: string;
	subtitle?: string;
}

export interface IEditMeetingTableFormValues {
	title?: string;
	subtitle?: string;
	updated_at?: string;
}

export interface IEditMeetingTableFormErrors {
	title?: string;
	subtitle?: string;
}
