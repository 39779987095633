import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { ActivityIndicator } from 'react-native';

interface ISpinner {
	color?: string;
	size?: number | 'small' | 'large';
	testID?: string;
}

export const Spinner = (props: ISpinner) => {
	const { color, size, testID } = props;
	const { theme } = useTheme();

	let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
	let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
	let _color = primary === bg ? theme.navigationTextColor : theme.primary;

	return (
		<ActivityIndicator
			style={testID?.startsWith('header_') ? { paddingHorizontal: 5 } : null}
			size={size ?? 'small'}
			color={color ?? _color}
		/>
	);
};
