export const getIsImageOrVideoByExtension = (extension: string) => {
	switch (extension?.toLowerCase()?.trim()) {
		case 'png':
		case 'jpg':
		case 'jpeg':
		case 'svg':
		case 'gif':
		case 'tiff':
		case 'ico':
		case 'dvu':
		case 'mpeg':
		case 'mp4':
		case 'wmv':
		case 'avi':
		case 'flv':
		case 'webp':
		case 'svg':
		case 'jfif':
		case 'pjpeg':
		case 'pjp':
		case 'avif':
		case 'apng':
		case 'bmp':
		case 'ico':
		case 'cur':
		case 'tif':
		case 'eps':
		case 'raw':
			return true;
		default:
			return false;
	}
};
