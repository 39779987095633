import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { Keyboard, ScrollView, View } from 'react-native';
import i18next from 'i18next';
import { hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, getSettingsForNavigationKey, getValidURL, isEmptyString, IS_WEB, validateForm } from 'helper';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { HSCard } from 'components/Card';
import { v4 } from 'uuid';
import { IContentTypeField, IFeatureInfo } from 'config/interfaces';
import { FormCheckbox, FormIconPicker, FormMultiSwitch, FormReferenceSelect, FormTagCloud, FormTextInput } from 'components/Form';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import { FormLabel } from 'components/Form/FormLabel';
import { RoundButton } from 'components/Button';
import * as Yup from 'yup';
import { getRequiredError, getWebsiteError } from 'config/yupSchemas/errors';
import { getFeatureValidationSchema } from 'config/yupSchemas';
import { H2, H3 } from 'components/Text';
import { Dropdown } from 'components/Form/Dropdown';
import { FormHint } from 'components/Form/FormHint';
import { HSWebView } from 'components/WebView';
import { NoData } from 'components/NoData';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditFeature>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditFeature>;
type RouteParams = StackParamList[ERoutes.EditFeature];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editfeature';

const TYPES_WITH_CATEGORIES = ['schedules', 'expos', 'speakers', 'mediaitems', 'attendees'];
const TYPES_WITH_SORTING = ['mediaitems'];

export const EditFeatureScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();
	const { getRegistrationFields } = useRegistration();
	const { getContentTypeFields: getExpoFields } = useContent('expo');
	const { getContentTypeFields: getScheduleFields } = useContent('schedule');
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');
	const { getContentTypeFields: getSpeakerFields } = useContent('speaker');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [tempField, setTempField] = useState<IFeatureInfo | undefined>(undefined);
	const [formErrors, setFormErrors] = useState<Record<string, any>>({});
	const [checkedIFrameUrl, setCheckedIFrameUrl] = useState<string | undefined>(undefined);
	const [categoryFields, setCategoryFields] = useState<IContentTypeField[]>([]);
	const [activeKey, setActiveKey] = useState<string>('');

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading
		});
	}, [activeSpace, tempField, isLoading]);

	useEffect(() => {
		if (route.params.key) {
			setActiveKey(route.params.key);
		}
	}, [route]);

	useEffect(() => {
		if (activeSpace) {
			let _field: typeof tempField = undefined;
			if (activeKey) {
				const found = activeSpace.features?.list.find((e) => e.key === activeKey);
				if (found) {
					const { icon, label } = getSettingsForNavigationKey(found.key);
					_field = { ...found, label: found.label ?? label, icon: found?.icon ?? icon };
					if (_field.contentType === 'iFrame' && !isEmptyString(_field.iFrameUrl)) {
						setCheckedIFrameUrl(_field.iFrameUrl);
					}
				}
			}
			if (!_field) {
				_field = { key: v4(), isActive: false, label: '', isCustom: true };
			}

			_getCategories(_field);
			setTempField(_field);
		}
	}, [activeSpace, activeKey]);

	const _getIconName = (icon?: string) => {
		if (icon) {
			let iconName = icon;
			if (iconName.includes(':')) {
				iconName = `${iconName.split(':')[1]} (${iconName.split(':')[0]})`;
				iconName = iconName.replace('hellospacesicon', '');
			}
			return iconName;
		}

		return 'Icon';
	};

	const _getCategories = (field?: IFeatureInfo) => {
		let _fields: typeof categoryFields = [];

		const type = field?.contentType ?? field?.key;
		if (activeSpace) {
			switch (type) {
				case 'attendees':
					_fields = getRegistrationFields(true).filter((e) => e.fieldType === 'category');
					break;
				case 'expos':
					_fields = getExpoFields(true).filter((e) => e.fieldType === 'category');
					break;
				case 'mediaitems':
					_fields = getMediaItemFields(true).filter((e) => e.fieldType === 'category');
					break;
				case 'schedules':
					_fields = getScheduleFields(true).filter((e) => e.fieldType === 'category');
					break;
				case 'speakers':
					_fields = getSpeakerFields(true).filter((e) => e.fieldType === 'category');
					break;
				default:
					if (tempField) {
						setTempField({ ...tempField, filter: undefined });
					}
					break;
			}
		}

		setCategoryFields(_fields);
	};

	const _updateSpace = async () => {
		if (activeSpace?.features && tempField) {
			const errors = await validateForm(getFeatureValidationSchema(tempField), tempField);

			if (errors) {
				setFormErrors(errors);
				return;
			} else {
				setFormErrors({});
			}

			const _values = {
				spaceId: activeSpace.spaceId,
				features: { ...activeSpace.features }
			};

			const idx = _values.features?.list.findIndex((e) => e.key === tempField.key);
			if (idx === -1) {
				_values.features?.list.push({ ...tempField });
			} else {
				_values.features.list[idx] = { ...tempField };
			}

			setIsLoading(true);
			const updateSuccess = await updateSpace({ data: _values });
			setIsLoading(false);

			if (updateSuccess) {
				navigation.setParams({ prohibitNavigation: false });
				navigation.goBack();
			}
		}
	};

	const _checkUrl = (url: string | undefined) => {
		let _url = url;
		if (_url && !isEmptyString(_url)) {
			_url = getValidURL(_url);
		}
		return _url;
	};

	const _checkIFrameURL = async (url?: string) => {
		let iFrameUrl = _checkUrl(url);

		const errors = await validateForm(
			Yup.object().shape({
				iFrameUrl: Yup.string().url(getWebsiteError(iFrameUrl)).required(getRequiredError('iFrame URL')).nullable()
			}),
			{ iFrameUrl }
		);
		if (errors) {
			setFormErrors({ ...formErrors, ...errors });
			return;
		} else {
			setFormErrors({ ...errors });
			setCheckedIFrameUrl(iFrameUrl);
			setTempField({ ...tempField, iFrameUrl: iFrameUrl });
		}
	};

	const _handleChange = (field: string, value: any) => {
		if (tempField) {
			const _temp = tempField;
			if (!route.params.prohibitNavigation) {
				navigation.setParams({
					prohibitNavigation: true
				});
			}
			_temp[field] = value;
			setTempField({ ..._temp });
		}
	};

	const _renderContentType = () => {
		switch (tempField?.contentType) {
			case 'externalUrl':
				return (
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_externalurl`}
						label={t('External Url')}
						isRequired
						isDisabled={isLoading}
						value={tempField.externalUrl}
						error={formErrors.externalUrl}
						keyboardType="url"
						onChangeText={(value) => _handleChange('externalUrl', value)}
					/>
				);
			case 'iFrame':
				return (
					<>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_iframeurl`}
							label="iFrame URL"
							isRequired
							isDisabled={isLoading}
							value={tempField.iFrameUrl}
							hint={t('FeatureIFrameUrlHint')}
							error={formErrors.iFrameUrl}
							keyboardType="url"
							onChangeText={(value) => {
								_handleChange('iFrameUrl', value);
								if (checkedIFrameUrl) {
									setCheckedIFrameUrl(undefined);
								}
							}}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_check`}
							icon={EDefaultIconSet.Checkbox}
							title={t('Check URL')}
							alignSelf="flex-end"
							onPress={() => _checkIFrameURL(tempField.iFrameUrl)}
							isDisabled={isEmptyString(tempField.iFrameUrl)}
						/>
						{checkedIFrameUrl && (
							<HSWebView
								testIdPrefix={TESTIDPREFIX}
								src={checkedIFrameUrl}
								label={t('Preview')}
								height={360}
								width={'100%'}
							/>
						)}
					</>
				);
			case 'markdown':
				return (
					<FormMarkdownEditor
						testID={`${TESTIDPREFIX}_markdowneditor_content`}
						label={t('Content')}
						isRequired
						isDisabled={isLoading}
						error={formErrors.content}
						value={tempField.content}
						onChange={(value) => _handleChange('content', value)}
					/>
				);
			default:
				return null;
		}
	};

	const _getSortingOptions = () => {
		if (tempField) {
			switch (tempField.contentType) {
				case 'mediaitems':
					return [
						{
							key: 'alphabetical',
							label: t('alphabeticalSorting')
						},
						{
							key: 'expoNameAlphabetical',
							label: t('expoNameAlphabeticalSorting')
						},
						{
							key: 'release',
							label: t('releaseSorting')
						}
					];
				default:
					break;
			}
		}
		return [];
	};

	const _renderSortingSelection = () => {
		if (tempField && tempField.contentType && tempField.isCustom && TYPES_WITH_SORTING.includes(tempField.contentType)) {
			return (
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_contenttype`}
					label={t('Sorting')}
					value={tempField.sorting ?? 'release'}
					error={TYPES_WITH_SORTING.includes(tempField.key) ? t('DefaultFeatureType') : formErrors.contentType}
					onChange={(value) => {
						_handleChange('sorting', value);
					}}
					isDisabled={TYPES_WITH_SORTING.includes(tempField.key)}
					isRequired
					options={_getSortingOptions()}
				/>
			);
		}
		return null;
	};

	const _renderFilterKeySelection = () => {
		const field = categoryFields.find((e) => e.fieldName === tempField?.filter?.fieldName);
		if (tempField && field) {
			return (
				<FormTagCloud
					testID={`${TESTIDPREFIX}_tagcloud_keys`}
					label={t('FeatureFilterKeys')}
					options={field.options}
					value={tempField?.filter?.keys}
					minCount={1}
					hint={t('FeatureFilterKeysHint')}
					onChange={(keys) => {
						const filter = {
							fieldName: field.fieldName,
							keys
						};
						_handleChange('filter', filter);
					}}
					formStyle={{ marginTop: 10 }}
					error={formErrors['filter.keys']}
				/>
			);
		}

		return null;
	};

	const _renderFilterDropddown = () => {
		if (
			tempField &&
			((tempField?.contentType && TYPES_WITH_CATEGORIES.includes(tempField.contentType)) ||
				TYPES_WITH_CATEGORIES.includes(tempField.key))
		) {
			if (categoryFields.length === 0) {
				return <H3 center>{t('NoCategoriesFound')}</H3>;
			}

			return (
				<Dropdown
					testID={`${TESTIDPREFIX}_dropdown_category`}
					value={tempField.filter?.fieldName}
					onSelect={(fieldName) => {
						if (fieldName) {
							const filter = {
								fieldName: fieldName.toString(),
								keys: ''
							};

							_handleChange('filter', filter);
						}
					}}
					options={categoryFields.map((e) => {
						return {
							key: e.fieldName,
							label: e.fieldLabel ?? e.fieldName
						};
					})}
					formStyle={{ marginBottom: 0 }}
				/>
			);
		}

		return null;
	};

	const _renderFilterSection = () => {
		if (
			tempField &&
			((tempField.contentType && TYPES_WITH_CATEGORIES.includes(tempField.contentType)) ||
				TYPES_WITH_CATEGORIES.includes(tempField.key))
		) {
			return (
				<View>
					<FormLabel testID="" label={t('Filter Category')} style={{ marginBottom: 10 }} />
					{_renderFilterDropddown()}
					<FormHint testID="" hint={t('FeatureFilterCategoryHint')} />
					{tempField.filter && (
						<RoundButton
							testID={`${TESTIDPREFIX}_button_clearfilter`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							title={t('Clear Filter')}
							alignSelf="flex-end"
							size="sm"
							onPress={() => _handleChange('filter', undefined)}
						/>
					)}
					{_renderFilterKeySelection()}
				</View>
			);
		}

		return null;
	};

	const _renderEntrySelection = () => {
		if (tempField?.contentType === 'votings') {
			return (
				<FormReferenceSelect
					testID={`${TESTIDPREFIX}_referenceselect_votings`}
					onSelect={(id) => _handleChange('itemId', id)}
					value={tempField.itemId}
					type="votings"
				/>
			);
		}

		return null;
	};

	const _renderForm = () => {
		if (tempField) {
			if (!isEmptyString(activeKey) && tempField.key !== activeKey) {
				return (
					<NoData type={'FeatureTokenValidationError'}>
						<RoundButton
							testID={`button_goback`}
							onPress={() => {
								if (navigation.canGoBack()) {
									navigation.goBack();
								} else {
									navigation.navigate(ERoutes.Features, { spaceId: activeSpace?.spaceId });
								}
							}}
							icon={EDefaultIconSet.ArrowLeft}
							title={t('Back')}
							isLoading={isLoading}
							isStacked
						/>
					</NoData>
				);
			}
			return (
				<HSCard>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_label`}
						label="Label"
						isDisabled={isLoading}
						isRequired
						value={tempField.label}
						error={formErrors.label}
						onChangeText={(value) => _handleChange('label', value)}
					/>
					<FormIconPicker
						testID={`${TESTIDPREFIX}_iconpicker_icon`}
						label={_getIconName(tempField.icon)}
						isRequired
						isDisabled={isLoading}
						value={tempField.icon}
						error={formErrors.icon}
						onChange={(value) => _handleChange('icon', value)}
					/>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_isactive`}
						isDisabled={isLoading}
						label={t('Activate Feature')}
						onPress={() => _handleChange('isActive', !tempField.isActive)}
						value={tempField.isActive}
					/>
					{(tempField.isCustom || TYPES_WITH_CATEGORIES.includes(tempField.key)) && (
						<FormMultiSwitch
							testID={`${TESTIDPREFIX}_multiswitch_contenttype`}
							label={t('ContentType')}
							value={TYPES_WITH_CATEGORIES.includes(tempField.key) ? tempField.key : tempField.contentType}
							error={TYPES_WITH_CATEGORIES.includes(tempField.key) ? t('DefaultFeatureType') : formErrors.contentType}
							onChange={(value) => {
								_handleChange('filter', undefined);
								_handleChange('contentType', value);
								_getCategories(tempField);
							}}
							isDisabled={TYPES_WITH_CATEGORIES.includes(tempField.key)}
							isRequired
							options={[
								{
									key: 'iFrame',
									label: 'iFrame'
								},
								{
									key: 'externalUrl',
									label: t('External Url')
								},
								{
									key: 'markdown',
									label: 'Markdown'
								},
								{
									key: 'schedules',
									label: t('Schedules')
								},
								{
									key: 'expos',
									label: t('Expos')
								},
								{
									key: 'speakers',
									label: t('Speakers')
								},
								{
									key: 'mediaitems',
									label: t('Media Items')
								},
								{
									key: 'attendees',
									label: t('Attendees')
								},
								{
									key: 'votings',
									label: t('Votings')
								}
							]}
						/>
					)}
					{_renderContentType()}
					{_renderSortingSelection()}
					{_renderFilterSection()}
					{_renderEntrySelection()}
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderForm()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const EditFeatureScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Features')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
