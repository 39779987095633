import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';

import { RoundButton } from 'components/Button';
import { Markdown } from 'components/Markdown/Markdown';
import { HSModal } from 'components/Modal/Modal';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { H1 } from 'components/Text';
import { EDefaultIconSet, isEmptyString, openURL } from 'helper';
import { FormTextInput } from '../FormTextInput';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { FormHint } from '../FormHint';
import { useTheme } from 'hooks/useTheme';
import { EForumLinks } from 'config/constants';

interface IFormMarkdownEditorModal {
	isVisible: boolean;
	onClose: () => void;
	onChange: (val) => void;
	value?: string;
	testID: string;
}

interface ITextRange {
	start: number;
	end: number;
}

const TESTIDPREFIX = 'formmarkdownmodal';

export const FormMarkdownEditorModal = (props: IFormMarkdownEditorModal) => {
	const { isVisible, onClose, onChange, value, testID } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [markdownText, setMarkdownText] = useState<string>('');
	const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
	const [selectedText, setSelectedText] = useState<ITextRange>({ end: 0, start: 0 });

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (value && isEmptyString(markdownText) && isVisible) {
			setMarkdownText(value);
		}
	}, [isVisible]);

	const scrollRef = useRef<any | undefined>();

	const _scrollToLine = (selectionIndex) => {
		return;
		const splitted = value?.split('\n');
		let scrollRow = 0;
		let sum = 0;
		splitted?.forEach((r) => {
			if (sum < selectionIndex) {
				if (r.includes('#')) {
					scrollRow += 4;
				} else if (r.includes('##')) {
					scrollRow += 2;
				} else if (r.includes('###')) {
					scrollRow++;
				} else {
					scrollRow++;
				}
				if (r.length > 50) {
					let length = r.length;
					while (length > 50) {
						length -= 50;
						scrollRow++;
					}
				}
				sum += r.length;
			}
		});
		if (scrollRef.current) {
			scrollRef.current.scrollTo({ y: scrollRow * 14, animated: true });
		}
	};

	const _onClose = () => {
		setMarkdownText('');
		setIsFormDirty(false);
		onClose();
	};

	const _handleClose = () => {
		if (isFormDirty) {
			showAlert({
				title: t('unsavedChanges'),
				message: t('unsavedChangesSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('leaveWithoutSaving'),
						style: 'destructive',
						onPress: () => _onClose()
					}
				]
			});
		} else {
			_onClose();
		}
	};

	const _handleAction = (type: string) => {
		let _addText = '';
		let _newText = '';

		const preventNewlineAtTheStart = isEmptyString(markdownText?.slice(0, selectedText?.start)) || isEmptyString(markdownText);

		switch (type) {
			case 'addHeader':
				if (selectedText?.start === selectedText.end) {
					_addText = (preventNewlineAtTheStart ? '' : '\n') + `# ${t('Header')}` + '\n';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '# ', markdownText?.slice(selectedText?.start)].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addSmallHeader':
				if (selectedText?.start === selectedText.end) {
					_addText = (preventNewlineAtTheStart ? '' : '\n') + `## ${t('Header')}` + '\n';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '## ', markdownText?.slice(selectedText?.start)].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addBold':
				if (selectedText?.start === selectedText?.end) {
					_addText = '**' + t('addBold') + '**';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '**', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 2), '**', _newText?.slice(selectedText?.end + 2)].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addCenter':
				if (selectedText?.start === selectedText?.end) {
					_addText = '<center>' + t('addCenter') + '</center>';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '<center>', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 8), '</center>', _newText?.slice(selectedText?.end + 8)].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addItalic':
				if (selectedText?.start === selectedText?.end) {
					_addText = '*' + t('addItalic') + '*';
				} else {
					_newText = [markdownText?.slice(0, selectedText?.start), '*', markdownText?.slice(selectedText?.start)].join('');
					_newText = [_newText?.slice(0, selectedText?.end + 1), '*', _newText?.slice(selectedText?.end + 1)].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addImage':
				_addText = `![${t('MarkdownImageText')}](https://hellospaces.b-cdn.net/hspx.png)`;
				break;
			case 'addLink':
				if (selectedText?.start === selectedText?.end) {
					_addText = `[${t('MarkdownLinkText')}](https://hellospaces.de)`;
				} else {
					_newText = [
						markdownText?.slice(0, selectedText?.start),
						`[${t('MarkdownLinkText')}](`,
						markdownText?.slice(selectedText?.start)
					].join('');
					_newText = [
						_newText?.slice(0, selectedText?.end + t('MarkdownLinkText').length + 3),
						')',
						_newText?.slice(selectedText?.end + t('MarkdownLinkText').length + 3)
					].join('');
					setMarkdownText(_newText);
					return;
				}
				break;
			case 'addList':
				_addText = '- A1' + '\n' + '- B2' + '\n' + '- C3';
				break;
			case 'addHorizontalLine':
				_addText = (!isEmptyString(markdownText) ? '\n' : '') + '***' + '\n\n';
				break;
			default:
				break;
		}

		if (selectedText?.start !== undefined && selectedText?.start !== null) {
			_newText = [
				markdownText?.slice(0, selectedText?.start),
				(preventNewlineAtTheStart ? '' : '\n') + _addText,
				markdownText?.slice(selectedText?.start)
			].join('');
		}

		setMarkdownText(_newText);
		setIsFormDirty(true);
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={() => _handleClose()}
			title={t('modalEditTextHeader')}
			onSubmit={() => {
				onChange(markdownText);
				_onClose();
			}}
		>
			<FormHint hint={t('markdownEditHint')} testID={`${TESTIDPREFIX}_textinput_markdowntext`} />
			<ScrollView
				ref={scrollRef}
				contentContainerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
				style={{ marginHorizontal: -EHorizontalScreenPadding.Wide }}
			>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
					<ScrollView horizontal showsHorizontalScrollIndicator={false} style={{ paddingRight: 5 }}>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addheader`}
							icon={EDefaultIconSet.Heading1}
							size="sm"
							title={t('addHeader')}
							isStacked
							onPress={() => _handleAction('addHeader')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addsmallheader`}
							icon={EDefaultIconSet.Heading2}
							size="sm"
							title={t('addSmallHeader')}
							isStacked
							onPress={() => _handleAction('addSmallHeader')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addbold`}
							icon={EDefaultIconSet.Bold}
							size="sm"
							title={t('addBold')}
							isStacked
							onPress={() => _handleAction('addBold')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addcenter`}
							icon={EDefaultIconSet.Centered}
							size="sm"
							title={t('addCenter')}
							isStacked
							onPress={() => _handleAction('addCenter')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_additalic`}
							icon={EDefaultIconSet.Italic}
							size="sm"
							title={t('addItalic')}
							isStacked
							onPress={() => _handleAction('addItalic')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addimage`}
							icon={EDefaultIconSet.Image}
							size="sm"
							title={t('addImage')}
							isStacked
							onPress={() => _handleAction('addImage')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addlink`}
							icon={EDefaultIconSet.Link}
							size="sm"
							title={t('addLink')}
							isStacked
							onPress={() => _handleAction('addLink')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addlist`}
							icon={EDefaultIconSet.List}
							size="sm"
							title={t('addList')}
							isStacked
							onPress={() => _handleAction('addList')}
						/>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_addhorizontalline`}
							icon={EDefaultIconSet.HorizontalRule}
							size="sm"
							title={t('addHorizontalLine')}
							isStacked
							onPress={() => _handleAction('addHorizontalLine')}
						/>
					</ScrollView>
					<RoundButton
						testID={`${TESTIDPREFIX}_button_openmarkdownhelp`}
						icon={EDefaultIconSet.Help}
						size="sm"
						title={t('Help')}
						isStacked
						onPress={() => openURL(EForumLinks.Markdown)}
					/>
				</View>
				<FormTextInput
					autoFocus
					placeholder={t('markdownTextPlaceholder')}
					multiline={true}
					testID={`${TESTIDPREFIX}_textinput_markdowntext`}
					value={markdownText}
					onChangeText={(val) => {
						setMarkdownText(val);
						setIsFormDirty(true);
					}}
					onSelectionChange={(nativeEvent) => {
						setSelectedText(nativeEvent.selection);
						_scrollToLine(nativeEvent.selection.start);
					}}
				/>
				<H1>{t('Preview')}</H1>
				<Markdown markdown={markdownText} />
			</ScrollView>
		</HSModal>
	);
};
