import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultFeedFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldHint: i18next.t('titleHint'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'subtitle',
			fieldLabel: i18next.t('subtitle'),
			fieldHint: i18next.t('subtitleHint'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'text',
			fieldLabel: i18next.t('Text'),
			fieldHint: i18next.t('textHint'),
			fieldType: 'markdown',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'showFrom',
			fieldLabel: i18next.t('datePickerLabel'),
			fieldHint: i18next.t('datePickerHintNews'),
			fieldType: 'dateRange',
			isDefaultField: true,
			showOnDetailScreen: true,
			visibility: 'visible',
			fieldName2: 'showUntil',
			extraLabel1: i18next.t('showFromLabel'),
			extraLabel2: i18next.t('showUntilLabel')
		},
		{
			fieldName: 'externalUrl',
			fieldLabel: i18next.t('External Url'),
			fieldHint: i18next.t('externalUrlHint'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		}
	];

	return arr;
};
