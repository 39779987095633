import React, { createRef, useEffect, useRef, useState } from 'react';
import { RouteProp, useFocusEffect, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LOCAL_GUI_IOS, LOCAL_GUI_ANDROID } from 'react-native-dotenv';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { Animated, Dimensions, ImageBackground, ScrollView, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, IS_IOS, IS_WEB, openURL } from 'helper';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';

import { IFeatureInfo, IMap, IMapPosition } from 'config/interfaces';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import { NoData } from 'components/NoData';
import { getMapExample } from 'helper/content';
import WebView from 'react-native-webview';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';
import { HSWebView } from 'components/WebView';
import { TABBAR_HEIGHT } from 'config/constants';
import { Icon } from 'components/Icon';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Maps>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Maps>;
type RouteParams = StackParamList[ERoutes.Maps];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'maps';

export const MapsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const { screenWidth } = useQuery();

	const bottomSheetRef = useRef<RBSheet>(null);
	const zoomRef = createRef<ReactNativeZoomableView>();
	const animatedOpacityValue = useRef(new Animated.Value(1));

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [spaceMaps, setSpaceMaps] = useState<IMap[]>([]);
	const [mapPositions, setMapPositions] = useState<IMapPosition[]>([]);
	const [mapMarker, setMapMarker] = useState<IMapPosition | undefined>(undefined);
	const [activeMap, setActiveMap] = useState<IMap | undefined>(undefined);
	const [shouldSend, setShouldSend] = useState<boolean>(false);

	const [isMinZoomed, setIsMinZoomed] = useState<boolean>(true);
	const [isMaxZoomed, setIsMaxZoomed] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	const createMap = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMap);
	const updateMap = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMap);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const webViewRef = useRef<WebView>();

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				setMapMarker(undefined);
			};
		}, [])
	);

	useEffect(() => {
		if (IS_WEB) {
			window.addEventListener('message', _handleMessage);
		}

		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});

		return () => {
			if (IS_WEB) {
				window.removeEventListener('message', _handleMessage);
			}
		};
	}, []);

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Maps')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (route.params?.mapId) {
			const newMap = spaceMaps.find((e) => e.id === route.params?.mapId);
			if (newMap) {
				setActiveMap(newMap);
			}
		} else {
			zoomRef.current?.moveBy(0.1, 0.1);
		}
	}, [route.params, spaceMaps]);

	useEffect(() => {
		let _marker: typeof mapMarker = undefined;

		if (route.params?.mapId && route.params?.positionId && content.mappositions) {
			_marker = content.mappositions.find((e) => e.id === route.params.positionId && e.map?.id === route.params.mapId);
		}

		setMapMarker(_marker);
	}, [route.params, content]);

	useEffect(() => {
		let _maps: typeof spaceMaps = [];

		if (content.maps) {
			_maps = content.maps.filter((e) => e.spaceId === activeSpace?.spaceId && !e.isDeleted);
		}

		_maps.sort((a, b) => ((a.order ?? 9999) < (b.order ?? 9999) ? -1 : 1));

		const stillPresent = _maps.find((e) => e.id === activeMap?.id);
		if (!stillPresent) {
			if (_maps.length > 0) {
				setActiveMap(_maps[0]);
			} else {
				setActiveMap(undefined);
			}
		}

		setSpaceMaps(_maps);
	}, [content, activeSpace]);

	useEffect(() => {
		let _positions: typeof mapPositions = [];

		if (activeSpace && activeMap && content.mappositions) {
			_positions = content.mappositions.filter((e) => e.spaceId === activeSpace.spaceId && e.map?.id === activeMap.id);
		}

		_positions = _positions.filter(
			(pos) =>
				(pos.expo && !pos.expo.isDeleted) ||
				(pos.meetingtable && !pos.meetingtable.isDeleted) ||
				(pos.stage && !pos.stage.isDeleted)
		);

		setMapPositions(_positions);
	}, [activeSpace, content, spaceMaps, activeMap]);

	useEffect(() => {
		_sendCurrentMap(activeMap, mapPositions, mapMarker);
	}, [shouldSend, activeMap, mapPositions, mapMarker, webViewRef]);

	useEffect(() => {
		if (route.params.mapId && mapMarker) {
			if (mapMarker.map.id !== route.params.mapId) {
				setMapMarker(undefined);
			}
		}
	}, [route.params.mapId, mapMarker]);

	const _sendCurrentMap = (map, mapPositions, mapMarker) => {
		return;
		if (map) {
			const obj = {
				map,
				space: activeSpace,
				mapPositions,
				mapMarker
			};

			if (IS_WEB) {
				const iFrameRef: HTMLIFrameElement = document.getElementById(`${TESTIDPREFIX}_iFrame`) as HTMLIFrameElement;
				if (iFrameRef) {
					iFrameRef.contentWindow?.postMessage(JSON.stringify(obj));
				}
			} else {
				webViewRef.current?.postMessage(JSON.stringify(obj));
			}

			setShouldSend(false);
		}
	};

	const _handleMessage = (event) => {
		const receivedData = event.nativeEvent ? JSON.parse(event.nativeEvent.data) : event.data;

		switch (receivedData.event) {
			case 'navigate':
				navigation.navigate(receivedData.route, {
					spaceId: receivedData.spaceId,
					id: receivedData.id,
					filter: receivedData.filter
				});
				break;
			case 'ready':
				setShouldSend(true);
				break;
			default:
				return;
		}
	};

	const _handleMapMarkerPress = (position: IMapPosition) => {
		let _route = position.expo ? ERoutes.ExpoDetails : ERoutes.Schedule;
		let _id = position.expo?.id;
		let _filter = _route === ERoutes.Schedule ? position.stage?.id : undefined;

		navigation.navigate(_route, {
			spaceId: position.spaceId,
			id: _id,
			filter: _filter
		});
	};

	const _deleteMap = async () => {
		if (activeMap) {
			showAlert({
				title: `${t('Delete map')} - ${activeMap.title}`,
				message: t('deleteMapSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Delete map'),
						style: 'destructive',
						onPress: async () => {
							setIsLoading(true);

							const res = await updateMap({ map: { isDeleted: true, id: activeMap.id }, isDeletion: true });
							if (res) {
								setIsLoading(false);
								if (!spaceMaps || spaceMaps.length === 0) {
									navigation.goBack();
								}
							} else {
								setIsLoading(false);
							}
						}
					}
				]
			});
		}
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.MapCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'edit':
				if (activeMap) {
					navigation.navigate(ERoutes.MapEdit, { spaceId: activeSpace?.spaceId, id: activeMap.id });
					bottomSheetRef.current?.close();
				}
				break;
			case 'delete':
				_deleteMap();
				bottomSheetRef.current?.close();
				break;
			case 'changeorder':
				navigation.navigate(ERoutes.MapOrder, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'addExample':
				setIsLoading(true);
				const mapExamples = getMapExample();
				const orders = content?.maps?.filter((map) => map.spaceId === activeSpace?.spaceId).map((m) => (m.order ? m.order : 0));
				let _order = orders.length === 0 ? -1 : Math.max(...orders);
				for (const mapExample of mapExamples) {
					_order = _order + 1;
					await createMap({
						map: { ...mapExample, order: _order },
						noToast: true
					});
				}
				setIsLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'help':
				openURL('https://forum.hellospaces.de/t/kartennutzung-fuer-expos/36');
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _getInitialZoom = () => {
		if (activeMap?.image) {
			if (activeMap?.image.width && activeMap?.image.height) {
				const neededWidthFactor = Dimensions.get('window').width / activeMap?.image.width;
				const neededHeightFactor = Dimensions.get('window').height / activeMap?.image.height;

				return Math.min(0.4, neededHeightFactor, neededWidthFactor);
			}
		}
		return 0.4;
	};

	const _renderMapMarker = () => {
		if (mapMarker && activeMap && activeMap.image?.width && activeMap.image?.height && mapMarker.map?.id === activeMap?.id) {
			const { x1, y1, x2, y2 } = mapMarker;

			let calculatedLeft = (x1 / 100) * activeMap.image.width;
			let calculatedTop = (y1 / 100) * activeMap.image.height;
			let calculatedWidth = ((x2 - x1) / 100) * activeMap.image.width;
			let calculatedHeight = ((y2 - y1) / 100) * activeMap.image.height;

			const bubbleSize = Math.min(calculatedHeight, calculatedWidth);

			const borderWidth = Math.min(bubbleSize * 0.15, 30);

			return (
				<TouchableOpacity
					key={`${TESTIDPREFIX}_position_${mapMarker.id}`}
					testID={`${TESTIDPREFIX}_position_${mapMarker.id}`}
					style={{
						position: 'absolute',
						left: calculatedLeft,
						top: calculatedTop,
						width: calculatedWidth,
						height: calculatedHeight
					}}
					activeOpacity={1}
					disabled={activeMap.showBacklinks === false || (!mapMarker.expo && !mapMarker.stage)}
					onPress={() => _handleMapMarkerPress(mapMarker)}
				>
					<Animated.View
						style={{
							opacity: animatedOpacityValue.current,
							height: '100%',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<View style={{ width: bubbleSize, height: bubbleSize, borderRadius: 9999, borderWidth, borderColor: 'red' }} />
					</Animated.View>
				</TouchableOpacity>
			);
		}

		if (activeMap?.showBacklinks === false) {
			return null;
		}

		const marker: JSX.Element[] = [];
		mapPositions.forEach((position) => {
			if (position.expo || position.stage) {
				if (activeMap && activeMap.image?.width && activeMap.image?.height && position.map?.id === activeMap.id) {
					const { x1, y1, x2, y2 } = position;

					let calculatedLeft = (x1 / 100) * activeMap.image.width;
					let calculatedTop = (y1 / 100) * activeMap.image.height;
					let calculatedWidth = ((x2 - x1) / 100) * activeMap.image.width;
					let calculatedHeight = ((y2 - y1) / 100) * activeMap.image.height;

					const bubbleSize = Math.min(calculatedHeight, calculatedWidth) * 0.5;

					marker.push(
						<TouchableOpacity
							key={`${TESTIDPREFIX}_position_${position.id}`}
							testID={`${TESTIDPREFIX}_position_${position.id}`}
							activeOpacity={1}
							disabled={!position.expo && !position.stage}
							style={{
								position: 'absolute',
								left: calculatedLeft,
								top: calculatedTop,
								width: calculatedWidth,
								height: calculatedHeight
							}}
							onPress={() => _handleMapMarkerPress(position)}
						>
							<Animated.View
								key={`mapposition_${position.id}`}
								style={{
									opacity: animatedOpacityValue.current,
									width: '100%',
									height: '100%',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<View
									style={{
										width: bubbleSize,
										height: bubbleSize,
										borderRadius: 9999,
										backgroundColor: theme.lightgray,
										opacity: 0.7
									}}
								/>
							</Animated.View>
						</TouchableOpacity>
					);
				}
			}
		});

		return marker;
	};

	const _renderMapTabs = () => {
		if (spaceMaps.length > 0) {
			return (
				<View style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginBottom: 10 }}>
					<ScrollView horizontal contentContainerStyle={{ marginBottom: 10 }}>
						{spaceMaps.map((map, index) => {
							const isActive = map.id === activeMap?.id;
							const isLastItem = index === spaceMaps.length - 1;

							return (
								<ChildButton
									key={`${TESTIDPREFIX}_map_${index}`}
									onPress={() => {
										navigation.setParams({ mapId: map.id, positionId: undefined });
										setActiveMap(map);
									}}
									testID={`${TESTIDPREFIX}_map_${index}`}
									style={{
										backgroundColor: isActive ? theme.primary : theme.primaryContrast,
										borderColor: theme.primary,
										borderRadius: hsBorderRadius,
										borderWidth: 1,
										paddingVertical: 5,
										paddingHorizontal: 10,
										marginRight: isLastItem ? 2 : 10
									}}
								>
									<Text bold={isActive} style={{ color: isActive ? theme.primaryContrast : theme.primary }}>
										{map.title}
									</Text>
								</ChildButton>
							);
						})}
					</ScrollView>
				</View>
			);
		}

		return null;
	};

	const _renderButtons = () => {
		if (window.parent?.outerWidth && window.parent.outerWidth > 1224) {
			return (
				<View style={{ position: 'absolute', bottom: 10, right: 10, margin: hsInnerPadding, alignItems: 'center' }}>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomIn`}
						onPress={() => {
							if (zoomRef.current && Number(zoomRef.current['zoomLevel'].toFixed(2)) < 1.5) {
								if (Number((zoomRef.current['zoomLevel'] + 0.3).toFixed(2)) >= 1.5) {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										1.5
									);
									setIsMaxZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										zoomRef.current['zoomLevel'] + 0.3
									);
									setIsMinZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMaxZoomed ? 0.6 : 1
						}}
						isDisabled={isMaxZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomIn} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomOut`}
						onPress={() => {
							if (
								zoomRef.current &&
								Number(zoomRef.current['zoomLevel'].toFixed(2)) >= Number(_getInitialZoom().toFixed(2))
							) {
								if (Number((zoomRef.current['zoomLevel'] - 0.3).toFixed(2)) <= Number(_getInitialZoom().toFixed(2))) {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										_getInitialZoom()
									);
									setIsMinZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(
										Dimensions.get('window').width / 2,
										Dimensions.get('window').height / 2,
										zoomRef.current['zoomLevel'] - 0.3
									);
									setIsMaxZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomOut} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_resetZoom`}
						onPress={() => {
							if (zoomRef.current) {
								zoomRef.current._zoomToLocation(
									Dimensions.get('window').width / 2,
									Dimensions.get('window').height / 2,
									_getInitialZoom()
								);
								setIsMinZoomed(true);
								setIsMaxZoomed(false);
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.Fullscreen} color={theme.primaryContrast} />
					</ChildButton>
				</View>
			);
		}

		return null;
	};

	const _renderMap = () => {
		return (
			<ReactNativeZoomableView
				key={activeMap?.id}
				ref={zoomRef}
				maxZoom={1.5}
				initialZoom={_getInitialZoom()}
				initialOffsetX={0}
				initialOffsetY={0}
				minZoom={_getInitialZoom()}
				zoomStep={0.3}
				contentWidth={activeMap?.image?.width}
				contentHeight={activeMap?.image?.height}
				style={{
					backgroundColor: 'transparent'
				}}
				onDoubleTapAfter={(e, zoom) => {
					if (zoom.zoomLevel) {
						setIsMaxZoomed(Number(zoom.zoomLevel.toFixed(2)) >= 1.5);
						setIsMinZoomed(Number(zoom.zoomLevel.toFixed(2)) <= Number(_getInitialZoom().toFixed(2)));
					}
				}}
			>
				<ImageBackground
					source={{ uri: activeMap?.image?.url, scale: 1.0 }}
					imageStyle={{
						width: activeMap?.image?.width,
						height: activeMap?.image?.height,
						backgroundColor: 'transparent'
					}}
					style={{
						width: activeMap?.image?.width,
						height: activeMap?.image?.height
					}}
				>
					{_renderMapMarker()}
				</ImageBackground>
			</ReactNativeZoomableView>
		);
		let baseUrl = __DEV__ ? (IS_WEB ? 'http://localhost:19006/' : IS_IOS ? LOCAL_GUI_IOS : LOCAL_GUI_ANDROID) : DEFAULT_PLATFORM_URL;

		if (!baseUrl?.endsWith('/')) {
			baseUrl += '/';
		}

		return (
			<HSWebView
				ref={webViewRef}
				testIdPrefix={TESTIDPREFIX}
				src={`${baseUrl}app/${activeSpace?.spaceId}/${ERoutes.ExternalMaps}/point/${route.params?.positionId}`}
				height={'100%'}
				width={'100%'}
				style={{ flex: 1, backgroundColor: 'transparent' }}
				onMessage={_handleMessage}
				rounded
				noScroll
				cacheDisabled
			/>
		);
	};

	return (
		<ScreenContainer handleBackPress contentKey="maps">
			<View style={{ flex: 1, marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0 }}>
				{spaceMaps.length === 0 ? (
					<View
						style={{
							flex: 1,
							paddingTop: hsTopScreenPadding,
							paddingHorizontal: EHorizontalScreenPadding.Wide,
							paddingBottom: hsBottomMargin,
							width: screenWidth,
							alignSelf: 'center'
						}}
					>
						{isLoading ? <View /> : <NoData type="NoMaps" />}
					</View>
				) : (
					<View
						style={{
							flex: 1,
							paddingTop: hsTopScreenPadding,
							paddingHorizontal: EHorizontalScreenPadding.Wide,
							paddingBottom: hsBottomMargin,
							width: '100%',
							alignSelf: 'center',
							backgroundColor: 'transparent'
						}}
					>
						{_renderMapTabs()}
						{_renderMap()}
						{_renderButtons()}
					</View>
				)}
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						onPress={() => _handleActionSheetPress('add')}
						isLoading={isLoading}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						label={t('Edit')}
						onPress={() => _handleActionSheetPress('edit')}
						isDisabled={!activeMap}
						isLoading={isLoading}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('Delete map')}
						onPress={() => _handleActionSheetPress('delete')}
						isDisabled={!activeMap}
						isLoading={isLoading}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_changeorder`}
						icon={EDefaultIconSet.List}
						label={t('ChangeOrder')}
						onPress={() => _handleActionSheetPress('changeorder')}
						isDisabled={!activeMap}
						isLoading={isLoading}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isLoading={isLoading}
						isDisabled={isLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_help`}
						icon={EDefaultIconSet.Help}
						label={t('Help')}
						onPress={() => _handleActionSheetPress('help')}
						isLoading={isLoading}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const MapsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};

export const MapsScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
