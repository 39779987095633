import { RoundButton } from 'components/Button';
import { FormSwitch } from 'components/Form/FormSwitch';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormMediaPicker } from 'components/Form/Image/FormMediaPicker';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { VoteModerationTextModal } from 'components/Modal/VoteModerationTextModal';
import { NoData } from 'components/NoData';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { TabSwitcher } from 'components/TabSwitcher';
import { TMedia } from 'config/interfaces';
import { IVote } from 'config/interfaces/vote';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { hsActionButtonMargin, hsBorderRadius, hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { getSubmitVoteTextFormSchema } from 'config/yupSchemas';
import * as ImagePicker from 'expo-image-picker';
import { EDefaultIconSet, getTopRadiusStyle, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { voteTextPreviewAnswers } from 'helper/previewAnswers/previewAnswersText';
import { useLike } from 'hooks/useLike';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Keyboard, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { VoteAnswerTextListItem } from '../VoteAnswer/Text/VoteAnswerTextListItem';
import { ActiveVoteHeader } from './ActiveVoteHeader';

interface IVoteText {
	vote: IVote;
	sortBy?: TSort;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	spaceId?: string;
	fullwidth?: boolean;
	renderSortByInCard?: boolean;
}

export type TSort = 'creation' | 'likes';

const TESTIDPREFIX = 'activevote';

export const VoteText = (props: IVoteText) => {
	const { vote, isPreview, resultsOnly, largerDesign, spaceId, fullwidth, renderSortByInCard, sortBy } = props;
	const { theme } = useTheme();
	const { getLikesForItem } = useLike();
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { trackAction } = useTracker();

	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);

	const [value, setValue] = useState<string>('');
	const [media, setMedia] = useState<TMedia | undefined>(undefined);
	const [answers, setAnswers] = useState<IVoteAnswer[]>([]);
	const [sortedAnswers, setSortedAnswers] = useState<IVoteAnswer[]>([]);
	const [isAnswersLoading, setIsAnswersLoading] = useState<boolean>(true);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [tempItem, setTempItem] = useState<IVoteAnswer | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<Record<number, boolean>>({});
	const [sortAnswersBy, setSortAnswersBy] = useState<TSort>(sortBy ?? 'likes');

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const likes = useSelector((store: IRootState) => store.like.likes);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const updateVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVoteAnswer);

	useEffect(() => {
		let _answers: IVoteAnswer[] = [];
		if (isPreview) {
			_answers = voteTextPreviewAnswers;
		} else if (activeSpace || spaceId) {
			if (voteAnswers && vote) {
				_answers = voteAnswers.filter(
					(e) =>
						!e.isResultObject &&
						!e.isDeleted &&
						(e.spaceId === activeSpace?.spaceId || e.spaceId === spaceId) &&
						e.voteId === vote.id &&
						(!vote.isModerated ||
							e.status === 'Public' ||
							(_iAmAdminOrModerator() && !resultsOnly) ||
							e.userId === userInfos.userId)
				) as IVoteAnswer[];
			}
		}

		if (!profile && !resultsOnly) _answers = _answers.filter((e) => !e.isAnonymousSubmission);

		let _cleanedAnswers: IVoteAnswer[] = [];

		_answers.forEach((a) => {
			const foundIndex = _cleanedAnswers.findIndex((b) => b.tempId === a.tempId);
			if (foundIndex === -1) {
				_cleanedAnswers.push(a);
			} else if (foundIndex > -1) {
				if (!_cleanedAnswers[foundIndex]?.id) {
					_cleanedAnswers[foundIndex] = a;
				}
			}
		});

		if (resultsOnly) _cleanedAnswers = _cleanedAnswers.filter((e) => e.status !== 'Waiting');

		setAnswers(_cleanedAnswers);
	}, [activeSpace, voteAnswers]);

	useEffect(() => {
		const _answers = [...answers];

		switch (sortAnswersBy) {
			case 'likes':
				_answers.sort(_byLikes);
				break;
			case 'creation':
			default:
				_answers.sort(_byCreation);
				break;
		}

		setSortedAnswers(_answers);
		if (answers.length > 0 || isPreview) {
			setIsAnswersLoading(false);
		} else {
			setTimeout(() => {
				setIsAnswersLoading(false);
			}, 500);
		}
	}, [answers, likes, sortAnswersBy]);

	const _iAmAdminOrModerator = () => {
		return iAmSpaceAdmin || iAmSpaceModerator;
	};

	const _byCreation = (a: IVoteAnswer, b: IVoteAnswer) => {
		return moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1;
	};

	const _byLikes = (a: IVoteAnswer, b: IVoteAnswer) => {
		const aVal = getLikesForItem(a.tempId, 'voteanswer');
		const bVal = getLikesForItem(b.tempId, 'voteanswer');

		return aVal.length > bVal.length ? -1 : 1;
	};

	const _updateVoteAnswer = async (answer: IVoteAnswer) => {
		setIsLoading({ ...isLoading, [answer.id]: true });
		const res = await updateVoteAnswer({ answer });
		setIsLoading({ ...isLoading, [answer.id]: false });
		return res?.tempId !== undefined;
	};

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		const errors = await validateForm(getSubmitVoteTextFormSchema(), { value });
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}

		setIsAuthenticationModalVisible(false);

		if (iAmSpaceAdmin || iAmSpaceModerator) vote.isModerated === false;

		setIsSubmitVoteAnswerLoading(true);
		const res = await submitVoteAnswer({
			voteId: vote.id,
			value,
			media,
			isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
		});
		setIsSubmitVoteAnswerLoading(false);

		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			setValue('');
			setMedia(undefined);
			Keyboard.dismiss();
		}
	};

	const _renderAnswers = () => {
		if (isAnswersLoading) {
			return (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner />
				</View>
			);
		}

		return (
			<FlatList
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center'
				}}
				ListHeaderComponent={
					<ActiveVoteHeader largerText={largerDesign} showResult={resultsOnly} activeVote={vote}>
						{renderSortByInCard ? (
							<View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: hsBottomMargin / 2 }}>
								<TabSwitcher
									containerStyle={{ marginBottom: 0 }}
									items={[
										{
											key: 'creation',
											icon: EDefaultIconSet.Clock
										},
										{
											key: 'likes',
											icon: EDefaultIconSet.ThumbUp
										}
									]}
									activeKey={sortAnswersBy ?? 'creation'}
									onPress={(key) => setSortAnswersBy(key as TSort)}
								/>
							</View>
						) : null}
					</ActiveVoteHeader>
				}
				data={sortedAnswers}
				renderItem={({ item, index }) => {
					return (
						<VoteAnswerTextListItem
							itemIndex={index}
							vote={vote}
							item={item}
							isPreview={isPreview}
							onEdit={() => setTempItem(item)}
							disableAnmiation={resultsOnly}
							largerDesign={largerDesign}
							resultsOnly={resultsOnly}
						/>
					);
				}}
				keyExtractor={(item, idx) => `voteanswertext_${item.id}_${idx}`}
				ListEmptyComponent={<NoData type="NoAnswers" />}
			/>
		);
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin' && !resultsOnly) {
			return (
				<View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginVertical: hsActionButtonMargin }}>
					<FormSwitch
						testID={`${TESTIDPREFIX}_switch_anonymous`}
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}
		return null;
	};

	const _renderTextMediaInput = () => {
		if (resultsOnly || !vote.isActive) {
			return null;
		}

		return (
			<View>
				{media && (
					<View
						style={{
							paddingTop: 10,
							paddingHorizontal: EHorizontalScreenPadding.Wide,
							backgroundColor: theme.contentBackgroundColor ?? theme.background,
							borderWidth: 1,
							borderBottomWidth: 0,
							...getTopRadiusStyle(hsBorderRadius),
							borderColor: theme.lightgray
						}}
					>
						<FormMediaPicker
							formStyle={{ marginBottom: 5 }}
							testID={`${TESTIDPREFIX}_button_uplodmedia`}
							value={media}
							error={''}
							onChange={(val) => setMedia(val)}
							mediaTypes={ImagePicker.MediaTypeOptions.All}
							hasDeleteButton
							isPreview
						/>
					</View>
				)}
				<View
					style={{
						flexDirection: 'row',
						borderWidth: 1,
						borderTopWidth: media ? 0 : 1,
						borderBottomLeftRadius: hsBorderRadius,
						borderBottomRightRadius: hsBorderRadius,
						...getTopRadiusStyle(media ? 0 : hsBorderRadius),
						borderColor: theme.lightgray,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: media ? 1 : 5, //otherwise there is a small gap between the elements
						backgroundColor: theme.contentBackgroundColor ?? theme.background,
						marginBottom: hsBottomMargin
					}}
				>
					<View style={{ flex: 1, paddingRight: vote.allowMedia ? 80 : 40, flexDirection: 'row', alignItems: 'center' }}>
						<FormTextInput
							testID={'votetext_textinput'}
							value={value}
							multiline
							messageType
							style={{
								flex: 1,
								color: theme.text
							}}
							formStyle={{ marginBottom: 5, paddingRight: 5 }}
							onChangeText={(value) => setValue(value)}
							placeholderTextColor={theme.formgray}
							placeholder={t('SubmitQuestionPlaceholder')}
						/>
						<View style={{ flexDirection: 'row', alignItems: 'center', alignSelf: 'flex-end' }}>
							{vote.allowMedia && (
								<View>
									<FormMediaPicker
										buttonOnly
										buttonSize="sm"
										icon={EDefaultIconSet.Media}
										testID={`${TESTIDPREFIX}_button_uplodmedia`}
										value={media}
										error={''}
										onChange={(val) => setMedia(val)}
										mediaTypes={ImagePicker.MediaTypeOptions.All}
										hasDeleteButton
										isPreview
									/>
								</View>
							)}
							<View>
								<RoundButton
									testID={`${TESTIDPREFIX}_button_submit`}
									isDisabled={isEmptyString(value)}
									onPress={() => {
										if (!isPreview) _submitVoteAnswer();
									}}
									isLoading={isSubmitVoteAnswerLoading}
									icon={EDefaultIconSet.SubmitTextVoteAnswer}
									size="sm"
									alignSelf="flex-end"
								/>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote && _renderAnswers()}
			{vote ? (
				<View
					style={{
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center',
						minHeight: 50
					}}
				>
					{_renderAnonymousSwitch()}
					{_renderTextMediaInput()}
				</View>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}

			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
			<VoteModerationTextModal
				item={tempItem}
				vote={vote}
				onClose={() => setTempItem(undefined)}
				onSubmit={(answer) => _updateVoteAnswer(answer)}
				isLoading={tempItem && isLoading[tempItem?.id]}
			/>
		</View>
	);
};
