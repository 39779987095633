import { CategoryFilterModal } from 'components/Modal/CategoryFilterModal/CategoryFilterModal';
import { TabbarBadge } from 'components/Navigation/TabbarBadge';
import { IContentTypeField, IFeatureInfo, ILauncherSpace } from 'config/interfaces';
import { EDefaultIconSet } from 'helper/icon';
import { isEmptyString } from 'helper/string';
import { useRegistration } from 'hooks/useRegistration';
import { TContentType, useContent } from 'hooks/useContent';
import { useSpace } from 'hooks/useSpace';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { RoundButton } from './RoundButton';

interface IFilterButton {
	testID: string;
	title?: string;
	type?: TContentType; // dont pass a type when displaying in attendee list
	activeFilters: Record<string, string>;
	setActiveFilters: (val: Record<string, string>) => void;
	space?: ILauncherSpace;
	feature?: IFeatureInfo;
}

export const FilterButton = (props: IFilterButton) => {
	const { testID, title, type, activeFilters, setActiveFilters, feature } = props;
	const { activeSpace } = useSpace();
	const { getRegistrationFields } = useRegistration();
	const { getContentTypeFields } = useContent(type);
	const { t } = useTranslation();

	const [categoryFields, setCategoryFields] = useState<IContentTypeField[]>([]);
	const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let tempFields: IContentTypeField[] | undefined = undefined;
		// tempFields is only for public agenda
		switch (type) {
			case 'schedule':
				tempFields = activeSpace?.scheduleFields?.fields ?? [];
				break;
			default:
				break;
		}

		const fields = type && type !== 'attendee' ? getContentTypeFields(false, tempFields) : getRegistrationFields();
		let _catFields: typeof categoryFields = [...fields.filter((e) => e.fieldType === 'category' && e.useAsFilter === 'modal')];
		const categories: IContentTypeField[] = [];
		if (type === 'schedule') {
			const stages = content?.stages?.filter((s) => !s.isDeleted && s.spaceId === activeSpace?.spaceId);
			if (stages?.length > 1) {
				_catFields?.push({
					fieldType: 'stage',
					fieldLabel: t('Stage'),
					fieldName: 'stage',
					// fieldType: "category"
					// isCustomField: true
					options: stages.map((stage) => {
						return {
							key: stage.title,
							label: stage.title
						};
					}),
					useAsFilter: 'modal',
					visibility: 'visible'
				});
			}
		}
		_catFields.forEach((cat) => {
			const newCat = { ...cat };
			if (newCat.fieldName === feature?.filter?.fieldName) {
				newCat.options = newCat.options?.filter((option) => feature.filter?.keys.includes(option.key));
			}
			categories.push(newCat);
		});
		setCategoryFields(categories);
	}, [activeSpace, type, content, feature]);

	const _getCurrentFilterCount = () => {
		let count = 0;

		Object.keys(activeFilters).forEach((filterKey) => {
			if (!isEmptyString(activeFilters[filterKey])) {
				const splitted = activeFilters[filterKey].split(',');
				count += splitted.length;
			}
		});

		return count;
	};

	if (categoryFields.length === 0) return null;

	return (
		<>
			{categoryFields.length > 0 && (
				<View style={{ paddingLeft: 10 }}>
					<RoundButton
						testID={`${testID}_button_filter`}
						icon={EDefaultIconSet.Filter}
						size="sm"
						onPress={() => setIsFilterModalVisible(true)}
					/>
					<TabbarBadge value={_getCurrentFilterCount()} top={-5} right={-10} />
				</View>
			)}
			<CategoryFilterModal
				title={title}
				isVisible={isFilterModalVisible}
				onClose={() => setIsFilterModalVisible(false)}
				onSubmit={(newFilters) => {
					setActiveFilters(newFilters);
					setIsFilterModalVisible(false);
				}}
				activeFilters={activeFilters}
				categoryFields={categoryFields}
			/>
		</>
	);
};
