import { useNavigation } from '@react-navigation/core';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormSwitch } from 'components/Form/FormSwitch';
import { NumberPresenter } from 'components/Info';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { QuizLeaderboardModal } from 'components/Modal/QuizLeaderboardModal';
import { QuizLeaderboard } from 'components/Modal/QuizLeaderboardModal/QuizLeaderboard';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H1, H3 } from 'components/Text';
import { Text } from 'components/Text/Text';
import { IQuizAnswerValue } from 'config/interfaces';
import { IVote } from 'config/interfaces/vote';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { previewVotingsQuiz } from 'helper/previewVotings/previewVotingsQuiz';
import { byOrder } from 'helper/sort';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Animated as RNAnimated, ScrollView, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { VoteMultipleChoiceOption } from '../VoteMultipleChoice';

interface IVoteQuiz {
	vote: IVote;
	isPreview?: boolean;
	onClose?: () => void;
	largerDesign?: boolean;
	fullwidth?: boolean;
	isAnswered?: boolean;
	resultsOnly?: boolean;
}

const TESTIDPREFIX = 'activevote';
export const QUIZ_QUESTION_DEFAULTTIME = 15;
export const QUIZ_QUESTION_DEFAULTSCORE = 10;

export const VoteQuiz = (props: IVoteQuiz) => {
	const { vote, isPreview, onClose, largerDesign, fullwidth, isAnswered, resultsOnly } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const navigation = useNavigation();
	const { trackAction } = useTracker();

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);

	const [quizVoteList, setQuizVoteList] = useState<IVote[]>([]);
	const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1);
	const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(-1);
	const [totalScore, setTotalScore] = useState(0);
	const [stepScore, setStepScore] = useState(0);
	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [currentTime, setCurrentTime] = useState<number>(0);
	const [countdownInterval, setCountdownInterval] = useState<any | undefined>(undefined);
	const [yourAnswers, setYourAnswer] = useState<IQuizAnswerValue[]>([]);
	const [isAfterQuestion, setIsAfterQuestion] = useState<boolean>(false);
	const [isFinished, setIsFinished] = useState<boolean>(false);

	const [isLeaderboardVisible, setIsLeaderboardVisible] = useState<boolean>(false);

	const scoreAnimationNewValue = useSharedValue(100);
	const scoreAnimationNewOpacity = useSharedValue(1);
	const scoreAnimationStyle = useAnimatedStyle(() => {
		return {
			paddingTop: withTiming(scoreAnimationNewValue.value, {
				duration: 1000
			}),
			opacity: withTiming(scoreAnimationNewOpacity.value, {
				duration: 1500
			})
		};
	});

	const animatedWidth = useRef(new RNAnimated.Value(containerWidth));

	useEffect(() => {
		if (isAnswered) setIsFinished(true);
	}, []);

	useEffect(() => {
		return () => {
			clearInterval(countdownInterval);
		};
	}, []);

	useEffect(() => {
		const myResult = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		);
		if (!myResult && isFinished) {
			setYourAnswer([]);
			setActiveQuestionIndex(-1);
			setSelectedOptionIndex(-1);
			setTotalScore(0);
			setIsFinished(false);
		}
		if (vote) {
			let _quizVotes: IVote[] = [];
			if (isPreview) {
				const previews = previewVotingsQuiz.map((p) => {
					p.options?.map((opt) => {
						opt.description = t(`${opt.description}`);
						return opt;
					});
					return p;
				});
				setQuizVoteList(previews);
				return;
			} else {
				const options = vote?.options?.sort(byOrder);
				options?.forEach((v) => {
					const found = votes.find((vote) => vote.id.toString() === v.label);
					if (found) {
						_quizVotes.push(found);
					}
				});
				setQuizVoteList(_quizVotes);
			}
			if (myResult?.value) {
				const parsedAnswer = JSON.parse(myResult.value);
				const notAnswered: IVote[] = [];
				const answered: IVote[] = [];
				_quizVotes.forEach((quiz) => {
					const found = parsedAnswer.answers.find((answer) => quiz.id === answer.voteId);
					if (found) {
						answered.push(quiz);
					} else {
						notAnswered.push(quiz);
					}
				});

				if (notAnswered.length > 0 && activeQuestionIndex === -1) {
					_quizVotes = [...answered, ...notAnswered];

					const newIndex = _quizVotes.length - notAnswered.length;
					setYourAnswer(parsedAnswer.answers);
					setActiveQuestionIndex(newIndex);
					setSelectedOptionIndex(-1);
					setTotalScore(parsedAnswer.score);
					setStepScore(parsedAnswer.score);

					const _vote = quizVoteList[newIndex];
					const newTimeout = _vote?.timeOut ? _vote?.timeOut * 1000 : QUIZ_QUESTION_DEFAULTTIME * 1000;
					setCurrentTime(newTimeout);
					_startInterval(newTimeout);
					setTimeout(() => {
						RNAnimated.timing(animatedWidth.current, {
							toValue: 0,
							duration: newTimeout - 3000,
							useNativeDriver: false
						}).start();
					}, 3000);
				} else if (_quizVotes.length > 0 && activeQuestionIndex === -1) {
					setYourAnswer(parsedAnswer.answers);
					setActiveQuestionIndex(_quizVotes.length - 1);
					setSelectedOptionIndex(0);
					setTotalScore(parsedAnswer.score);
					setIsFinished(true);
				}
			}
			if (activeQuestionIndex === -1) {
				setQuizVoteList(_quizVotes);
			}
		}
	}, [voteAnswers, vote]);

	useEffect(() => {
		if (currentTime < 0) {
			_endQuizStep();
		}
	}, [currentTime]);

	useEffect(() => {
		if (selectedOptionIndex >= 0 && !isPreview) {
			navigation.setParams({ prohibitNavigation: true });
		}
	}, [selectedOptionIndex]);

	const _endQuiz = async () => {
		setIsFinished(true);
		if (!isPreview) {
			setIsSubmitLoading(true);
			const res = await submitVoteAnswer({
				voteId: vote.id,
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous,
				value: JSON.stringify({ score: totalScore, answers: yourAnswers })
			});
			if (res) {
				trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			}
			navigation.setParams({ prohibitNavigation: false });
			setIsSubmitLoading(false);
		}
	};

	const _startQuizStep = () => {
		scoreAnimationNewValue.value = 100;
		scoreAnimationNewOpacity.value = 1;

		setSelectedOptionIndex(-1);
		setIsAfterQuestion(false);
		const newIndex = activeQuestionIndex + 1;

		setActiveQuestionIndex(newIndex);
		const _vote = quizVoteList[newIndex];
		const newTimeout = _vote?.timeOut ? _vote?.timeOut * 1000 : QUIZ_QUESTION_DEFAULTTIME * 1000;
		setCurrentTime(newTimeout);
		_startInterval(newTimeout);
	};

	const _endQuizStep = () => {
		animatedWidth.current = new RNAnimated.Value(containerWidth);

		clearInterval(countdownInterval);
		setIsAfterQuestion(true);

		const _vote = quizVoteList[activeQuestionIndex];
		if (_vote?.options) {
			const option = _vote?.options[selectedOptionIndex];
			if (option?.isCorrect) {
				setTimeout(() => {
					setStepScore((score) => score + (_vote?.score ?? QUIZ_QUESTION_DEFAULTSCORE));
					setTotalScore((score) => score + (_vote?.score ?? QUIZ_QUESTION_DEFAULTSCORE));
				}, 1000);
			}

			const _yourAnswers = yourAnswers;
			_yourAnswers.push({
				answerText: option?.description ?? '',
				pointsGained: option?.isCorrect ? (_vote.score ? _vote.score : QUIZ_QUESTION_DEFAULTSCORE) : 0,
				voteId: _vote.id
			});
			setYourAnswer([..._yourAnswers]);
		}
	};

	const _startInterval = (time) => {
		const _interval = setInterval(() => {
			setCurrentTime((currentTime) => currentTime - 1000);
		}, 1000);
		setCountdownInterval(_interval);
		if (process.env.IS_TEST) {
			return;
		}
		setTimeout(() => {
			RNAnimated.timing(animatedWidth.current, {
				toValue: 0,
				duration: time,
				useNativeDriver: false
			}).start();
		}, 100);
	};

	const _renderQuizVote = () => {
		const _vote = quizVoteList[activeQuestionIndex];
		if (_vote) {
			return (
				<View style={{ flex: 1 }}>
					<ActiveVoteHeader
						activeVote={{ ..._vote, isActive: true }}
						showResult={votingShowResults || resultsOnly}
						largerText={largerDesign}
					/>
					<HSCard>
						<View
							onLayout={(e) => {
								setContainerWidth(e.nativeEvent.layout.width);
								animatedWidth.current = new RNAnimated.Value(e.nativeEvent.layout.width);
							}}
						>
							<RNAnimated.View
								style={{
									height: 30,
									minWidth: 1,
									width: animatedWidth.current
								}}
							>
								<View
									style={{
										height: '100%',
										width: '100%',
										backgroundColor: theme.primary,
										borderRadius: hsBorderRadius
									}}
								/>
							</RNAnimated.View>
							<Text
								bold
								center
								style={{
									marginTop: 10,
									fontSize: 16,
									color:
										currentTime >= 5000 && currentTime < 10000
											? theme.warning
											: currentTime < 5000
											? theme.danger
											: theme.text
								}}
							>
								{currentTime / 1000}
							</Text>
						</View>
					</HSCard>

					{_vote.options?.map((voteOption, index) => {
						const isActive = selectedOptionIndex === index;

						return (
							<VoteMultipleChoiceOption
								key={`${TESTIDPREFIX}_button_quizoption_${index}`}
								testID={`${TESTIDPREFIX}_button_quizoption_${index}`}
								isActive={isActive}
								onPress={() => setSelectedOptionIndex(isActive ? -1 : index)}
								label={voteOption.description}
							/>
						);
					})}
				</View>
			);
		}

		return null;
	};

	const _renderPrestart = () => {
		return (
			<View style={{ flex: 1 }}>
				<ActiveVoteHeader activeVote={vote} />
			</View>
		);
	};

	const _renderQuestionResult = () => {
		let scoreToAdd = 0;
		let isCorrectAnswer = false;
		const _vote = quizVoteList[activeQuestionIndex];

		let currentMaximumScore = 0;

		for (let i = 0; i <= activeQuestionIndex; i++) {
			currentMaximumScore += quizVoteList[i].score ?? QUIZ_QUESTION_DEFAULTSCORE;
		}

		if (_vote?.options) {
			const option = _vote?.options[selectedOptionIndex];
			if (option?.isCorrect) {
				isCorrectAnswer = true;
				scoreToAdd = _vote.score ?? QUIZ_QUESTION_DEFAULTSCORE;
			}
		}

		if (scoreToAdd > 0) {
			// only animate text if the user got the correct answer
			scoreAnimationNewValue.value = 0;
			scoreAnimationNewOpacity.value = 0;
		}

		return (
			<View style={{ flex: 1 }}>
				<ActiveVoteHeader activeVote={{ ..._vote, isActive: true }} />
				<HSCard>
					<View style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
						<Text>{t('yourAnswerWas')}</Text>
						<Text bold style={{ color: isCorrectAnswer ? theme.success : theme.danger }}>
							{` ${isCorrectAnswer ? t('answerRight') : t('answerWrong')}`}
						</Text>
					</View>
					<View style={{ position: 'relative' }}>
						<H1 center style={{ marginBottom: 5 }}>{`${stepScore} / ${currentMaximumScore}`}</H1>
						<Text center>{t('CurrentScore')}</Text>
						<Animated.View
							style={[
								scoreAnimationStyle,
								{
									position: 'absolute',
									width: '100%'
								}
							]}
						>
							<H1 center>{`+ ${scoreToAdd}`}</H1>
						</Animated.View>
					</View>
				</HSCard>

				{_vote.options?.map((voteOption, index) => {
					const isActive = selectedOptionIndex === index;

					return (
						<VoteMultipleChoiceOption
							key={`${TESTIDPREFIX}_button_quizoption_${index}`}
							testID={`${TESTIDPREFIX}_button_quizoption_${index}`}
							isActive={isActive}
							label={voteOption.description}
							isCorrect={voteOption.isCorrect}
							isFalse={!voteOption.isCorrect && selectedOptionIndex === index}
						/>
					);
				})}
			</View>
		);
	};

	const _renderResult = () => {
		let number = totalScore;
		let maxScore = 0;

		quizVoteList.forEach((vote) => (maxScore += vote.score ?? QUIZ_QUESTION_DEFAULTSCORE));

		return (
			<View>
				<View>
					<ActiveVoteHeader activeVote={{ ...vote, isActive: true }} />
					<NumberPresenter number={number} title={t('YourScore')} maxNumber={`/ ${maxScore}`} />
				</View>
				<FlatList
					data={quizVoteList}
					renderItem={({ item, index }) => {
						const correctOption = item.options?.find((opt) => opt.isCorrect);
						let myAnswer = yourAnswers.find((a) => item.id === a.voteId);
						if (!myAnswer) {
							myAnswer = yourAnswers[index];
						}
						if (myAnswer) {
							return (
								<HSCard>
									<H3>{item.question}</H3>
									<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
										<View>
											<Text>
												{t('correctAnswer')}: {correctOption?.description}
											</Text>
											<Text style={{ color: myAnswer.pointsGained > 0 ? theme.success : theme.danger }}>
												{t('yourAnswer')}: {myAnswer.answerText}
											</Text>
										</View>
										<Text
											bold
											style={{
												alignSelf: 'flex-end',
												color: myAnswer.pointsGained > 0 ? theme.success : theme.danger
											}}
										>
											+ {myAnswer.pointsGained}
										</Text>
									</View>
								</HSCard>
							);
						}
						return null;
					}}
					keyExtractor={(item, idx) => `votequizansweroption_${item.id}_${idx}`}
				/>
			</View>
		);
	};

	const _renderCurrentVoteOrResult = () => {
		if (activeQuestionIndex === -1) {
			return _renderPrestart();
		}

		if (isFinished) {
			return _renderResult();
		}

		if (isAfterQuestion) {
			return _renderQuestionResult();
		}

		return _renderQuizVote();
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<FormSwitch
					testID="votetext_switch_anonymous"
					onChange={() => setAnswerAnonymous(!answerAnonymous)}
					leftLabel={t('Answer Anonymous')}
					value={answerAnonymous}
					size="sm"
					formStyle={{ width: undefined, marginBottom: 10, marginRight: 10 }}
				/>
			);
		}

		return null;
	};

	const _renderLeaderboardActionButton = () => {
		if (isFinished && !vote?.hideResults) {
			return (
				<RoundButton
					testID={`${TESTIDPREFIX}_button_leaderboard`}
					icon={EDefaultIconSet.Quiz}
					onPress={() => setIsLeaderboardVisible(true)}
					title={t('Leaderboard')}
					alignSelf="center"
				/>
			);
		}

		return null;
	};

	const _renderActionButton = () => {
		if (votingShowResults || resultsOnly) {
			return null;
		}
		let element: ReactNode = null;
		if (activeQuestionIndex === -1) {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_startquiz`}
						icon={EDefaultIconSet.Play}
						title={t('Start Quiz')}
						alignSelf="flex-end"
						onPress={() => {
							if (
								(vote.allowAnonymousAnswers === 'never' && !profile) ||
								(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
							) {
								setIsAuthenticationModalVisible(true);
								return;
							} else {
								_startQuizStep();
							}
						}}
						isDisabled={!vote.isActive}
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					{_renderLeaderboardActionButton()}
					<RoundButton
						isFloatingButton={!isFinished}
						isOutline={isFinished}
						testID={`${TESTIDPREFIX}_button_submit`}
						icon={isFinished ? EDefaultIconSet.Save : EDefaultIconSet.ArrowRight}
						isLoading={isSubmitLoading}
						title={isFinished ? t('endQuiz') : isAfterQuestion ? t('Next') : t('submitAnswer')}
						alignSelf={isFinished ? 'center' : 'flex-end'}
						onPress={() => {
							if (isFinished) {
								if (isPreview && onClose) {
									onClose();
								} else {
									navigation.goBack();
								}
							} else {
								if (isAfterQuestion) {
									if (quizVoteList[activeQuestionIndex + 1]) {
										_startQuizStep();
									} else {
										_endQuiz();
									}
								} else {
									_endQuizStep();
								}
							}
						}}
						isDisabled={!isAfterQuestion && selectedOptionIndex === -1}
					/>
				</HSCard>
			);
		}
		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	if (resultsOnly) {
		return (
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center'
				}}
			>
				<QuizLeaderboard vote={vote} userScore={totalScore} resultsOnly largerDesign={largerDesign} />
			</View>
		);
	}

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					contentContainerStyle={{
						paddingTop: !(votingShowResults || resultsOnly) ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					{_renderCurrentVoteOrResult()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButton()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => {
					setIsAuthenticationModalVisible(false);
					_startQuizStep();
				}}
			/>
			<QuizLeaderboardModal
				isVisible={isLeaderboardVisible}
				onClose={() => setIsLeaderboardVisible(false)}
				vote={vote}
				userScore={totalScore}
			/>
		</View>
	);
};
