import React, { ReactNode, useRef, useState } from 'react';
import { TextInput, View, ViewStyle } from 'react-native';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from 'components/Form/constants';
import { useTranslation } from 'react-i18next';
import { ChildButton } from 'components/Button';
import { Dimensions } from 'react-native';

interface ISearchBar {
	value: string | undefined;
	onChange: (val: string) => void;
	isDisabled?: boolean;
	testID: string;
	containerStyle?: ViewStyle;
	rightComponent?: ReactNode;
}

export const SearchBar = (props: ISearchBar) => {
	const { value, onChange, isDisabled, testID, containerStyle, rightComponent } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [text, setText] = useState<string>('');

	const platformStyle = IS_WEB ? { outline: 'none' } : {};
	const screenHeight = Dimensions.get('window').height;
	const screenWidth = Dimensions.get('window').width;

	const inputRef = useRef<TextInput>(null);

	return (
		<View
			style={{
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				borderRadius: FORMELEMENTBORDERRADIUS,
				borderWidth: FORMELEMENTBORDERWIDTH,
				borderColor: theme.lightgray,
				paddingHorizontal: 5,
				paddingVertical: 10,
				marginVertical: screenHeight <= 800 ? -5 : 0,
				marginHorizontal: screenWidth <= 600 ? -5 : 0,
				...containerStyle
			}}
		>
			<Icon name={EDefaultIconSet.Search} color={theme.formgray} containerStyle={{ marginHorizontal: 5 }} size={17} />
			<TextInput
				testID={testID}
				ref={inputRef}
				style={{ flex: 1, color: theme.text, height: 17, ...platformStyle }}
				editable={!isDisabled}
				value={value ?? ''}
				onFocus={() => setIsFocused(true)}
				onBlur={() => {
					if (IS_WEB || !props.value) {
						setTimeout(() => {
							setIsFocused(false);
						}, 125);
					} else {
						setTimeout(() => {
							setIsFocused(false);
						}, 1000);
					}
				}}
				placeholder={`${t('Search')}...`}
				placeholderTextColor={theme.formgray}
				onChangeText={(val) => {
					setText(val);
					onChange(val);
				}}
			/>
			{(isFocused || IS_WEB) && !isDisabled && text !== '' && (
				<ChildButton
					testID={`${testID}_button_clear`}
					style={{
						marginLeft: 5,
						height: 17,
						alignSelf: 'center'
					}}
					onPress={() => {
						if (inputRef.current) {
							inputRef.current.clear();
							setText('');
							onChange('');
							if (IS_WEB && isFocused) {
								setTimeout(() => {
									inputRef.current?.focus();
								}, 150);
							}
						}
					}}
				>
					<Icon name={EDefaultIconSet.Close} color={theme.text} size={17} />
				</ChildButton>
			)}
			{rightComponent}
		</View>
	);
};
