import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { View } from 'react-native';

import { HSCard } from 'components/Card/HSCard';
import { NoData } from 'components/NoData';
import { IActiveScreenFilter, IAttendee, IFeatureInfo } from 'config/interfaces';
import { EDefaultIconSet, filterListByFeature, getCardContainerStyle, getReversedName, isEmptyString, IS_WEB } from 'helper';
import { useRegistration } from 'hooks/useRegistration';
import { FlashSectionList } from 'components/List';
import { RoundButton } from 'components/Button';
import { AttendeeListItem, ATTENDEELISTITEMENTRYHEIGHT } from './AttendeeListItem';
import { AttendeeListHeader } from './AttendeeListHeader';
import moment from 'moment';
import { SponsorBlock } from 'screens/Space/Sponsors';
import { useExport } from 'hooks/useExport';
import { useMatching } from 'hooks/useMatching';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';

import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { useNavigation } from '@react-navigation/native';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { FormCheckbox } from 'components/Form';
import { ScrollEvent, Size } from 'react-native-spring-scrollview';
import { ContentCard, CONTENTCARD_HEIGHT } from 'components/Card';
interface IAttendeeData {
	header: string;
	items: IAttendee[];
}

interface IAttendeeList {
	testID: string;
	selectedAttendees?: string[];
	onSelect?: (userId) => void;
	onSelectAll?: (selected: string[]) => void;
	onPress?: (item: IAttendee) => void;
	filter?: 'chat' | 'meeting' | 'admin' | 'moderator';
	noCard?: boolean;
	noSponsors?: boolean;
	isAdminArea?: boolean;
	searchTerm: string;
	activeScreenFilter?: IActiveScreenFilter;
	activeModalFilters?: Record<string, string>;
	onScroll: (e: ScrollEvent) => void;
	onContentSizeChange: (e: Size) => void;
	feature?: IFeatureInfo;
	isMailReceiver?: boolean;
	isModal?: boolean;
}

export const AttendeeList = (props: IAttendeeList) => {
	const {
		testID,
		selectedAttendees,
		onSelect,
		onPress,
		filter,
		noCard,
		noSponsors,
		onSelectAll,
		searchTerm,
		activeScreenFilter,
		activeModalFilters,
		onScroll,
		onContentSizeChange,
		feature,
		isMailReceiver,
		isModal
	} = props;
	const { theme } = useTheme();
	const { getRegistrationFields } = useRegistration();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getMatchCount } = useMatching();
	const navigation = useNavigation();
	const { exportScannedAttendeesAsCSV } = useExport();
	const { screenColumnCountSmallItems } = useQuery();

	const [spaceAttendees, setSpaceAttendees] = useState<IAttendee[]>([]);
	const [filteredAttendees, setFilteredAttendees] = useState<IAttendee[]>([]);
	const [searchedAttendees, setSearchedAttendees] = useState<IAttendee[]>([]);
	const [attendeeFlashSections, setAttendeeFlashSections] = useState<(string | IAttendee)[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [numColumns, setNumColumns] = useState<number>(IS_WEB ? (screenColumnCountSmallItems ?? 3) + 1 : 2);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const loadTickets = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.loadTickets);
	const loadUserInSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.loadUserInSpace);

	useEffect(() => {
		let _numColumns = Math.floor(containerWidth / 220);

		if (_numColumns <= 2) _numColumns = 2;
		if (_numColumns >= 5) _numColumns = 5;
		if (numColumns !== _numColumns) setNumColumns(_numColumns);
	}, [containerWidth]);

	useEffect(() => {
		if (iAmSpaceAdmin) {
			loadUserInSpace({});
		}
	}, [iAmSpaceAdmin]);

	useEffect(() => {
		if (profile?.scannedUser === undefined || profile?.scannedUser?.length === 0) {
			navigation.setOptions({
				onExportScanned: () => {
					showAlert({
						title: t('EmptyScannedUsers'),
						message: IS_WEB ? t('EmptyScannedUsersTextDesktop') : t('EmptyScannedUsersTextMobile'),
						buttons: [
							{
								text: 'Okay',
								style: 'cancel'
							}
						]
					});
				}
			});
		} else {
			const visibleFields = getRegistrationFields().filter((f) => f.visibility === 'always');
			const _cleanedAttendees = profile.scannedUser.map((fa) => {
				const _attendee = fa;
				visibleFields.map((vF) => {
					if (vF.fieldName === 'password' || vF.fieldName === 'avatar' || vF.fieldName === 'hellospacesNewsletter') {
						return;
					}
					_attendee[vF.fieldName] = fa[vF.fieldName];
				});
				return _attendee;
			});
			navigation.setOptions({
				onExportScanned: () => {
					exportScannedAttendeesAsCSV(_cleanedAttendees);
				}
			});
		}
	}, [activeScreenFilter, profile, filteredAttendees]);

	useEffect(() => {
		if (attendees) {
			let _attendees: typeof spaceAttendees = [];
			let hiddenDomains: string[] = [];

			if (activeSpace?.attendeeSettings?.hiddenDomains) {
				hiddenDomains = activeSpace.attendeeSettings.hiddenDomains.split(',').map((e) => e.replace(/\s/g, ''));
			}

			_attendees = attendees.filter((e) => {
				for (const domain of hiddenDomains) {
					if (e.email.endsWith(domain)) {
						return false;
					}
				}

				return (
					!e.hideMyProfile &&
					(!filter ||
						(filter === 'admin' && activeSpace?.admins?.find((x) => x.userId === e.userId)) ||
						(filter === 'moderator' && activeSpace?.moderators?.find((x) => x.userId === e.userId)) ||
						(filter === 'chat' && e.userId !== profile?.userId && e.allowChats) ||
						(filter === 'meeting' && e.userId !== profile?.userId && e.allowChats && e.allowMeetingRequests)) &&
					(filter === 'admin' ||
						filter === 'moderator' ||
						e.userSpaceHistory?.spaces.find((hist) => hist.spaceId === activeSpace?.spaceId && !hist.isDeleted))
				);
			});

			setSpaceAttendees(_attendees);
		}
	}, [activeSpace, attendees, filter]);

	useEffect(() => {
		if (iAmSpaceAdmin || iAmSpaceModerator) {
			loadTickets({});
		}
	}, [iAmSpaceAdmin, iAmSpaceModerator]);

	useEffect(() => {
		let _filtered: typeof filteredAttendees = [...spaceAttendees];

		_filtered = filterListByFeature(_filtered, feature);

		if (activeScreenFilter) {
			if (activeScreenFilter.filters.includes('all')) {
				// Nothing to do
			} else if (activeScreenFilter.filters.includes('favorites')) {
				_filtered = _filtered.filter((e) => profile?.favorites?.attendee && profile.favorites.attendee[e.userId]);
			} else if (activeScreenFilter.filters.includes('matchings')) {
				_filtered = _filtered.filter((e) => getMatchCount('attendee', e) > 0 && e.userId !== profile?.userId);
			} else if (activeScreenFilter.filters.includes('profileVisitors')) {
				_filtered = _filtered.filter(
					(e) =>
						activeSpace &&
						profile?.visitors &&
						profile?.visitors[activeSpace?.spaceId] &&
						profile?.visitors[activeSpace?.spaceId][e.userId]
				);
			} else if (activeScreenFilter.filters.includes('scannedUser')) {
				_filtered = profile?.scannedUser
					?.filter((u) => u.spaceId === activeSpace?.spaceId)
					?.map((u) => attendees.find((a) => a.userId === u.userId))
					?.filter((a) => a !== undefined || a !== null);
			} else {
				_filtered = _filtered.filter((e) => {
					if (activeScreenFilter.fieldName) {
						for (const catItemKey of activeScreenFilter.filters) {
							if (e[activeScreenFilter.fieldName]) {
								const itemSplit = e[activeScreenFilter.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
			}
		}

		if (activeModalFilters) {
			if (Object.keys(activeModalFilters).length > 0) {
				_filtered = _filtered.filter((e) => {
					const catKeys = Object.keys(activeModalFilters);
					for (const catKey of catKeys) {
						const splitted = activeModalFilters[catKey].split(',');
						for (const catItemKey of splitted) {
							if (e[catKey]) {
								const itemSplit = e[catKey].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}
					}

					return false;
				});
			}
		}

		setFilteredAttendees(_filtered);
	}, [activeSpace, feature, spaceAttendees, activeScreenFilter, activeModalFilters, profile]);

	useEffect(() => {
		let _searched: typeof filteredAttendees = [...filteredAttendees]?.filter(
			(e) => !isEmptyString(e?.firstName) && !isEmptyString(e?.lastName)
		);

		if (searchTerm && searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			const fields = getRegistrationFields().filter(
				(e) => e.fieldType === 'string' || e.fieldType === 'website' || e.fieldType === 'socialmedia'
			);
			_searched = _searched.filter((item) => {
				for (const field of fields) {
					if (item[field.fieldName] && item[field.fieldName].toLowerCase().includes(lowerSearch)) {
						return true;
					}
				}

				if (`${item.firstName} ${item.lastName}`.toLowerCase().includes(lowerSearch)) {
					return true;
				}

				let allCategoryString = '';
				for (let index = 1; index <= 10; index++) {
					const element = item[`category${index}`];
					allCategoryString = allCategoryString.concat(element);
				}

				if (allCategoryString?.toLowerCase().includes(lowerSearch)) {
					return true;
				}

				if (userInSpaces && activeSpace && (iAmSpaceAdmin || iAmSpaceModerator)) {
					const userInSpaceId = userInSpaces.find((e) => e && e.spaceId === activeSpace?.spaceId && e.userId === item.userId);
					if (
						userInSpaceId?.ticketcode?.toLowerCase().includes(lowerSearch) ||
						userInSpaceId?.ticketIdentifier?.toLowerCase().includes(lowerSearch)
					) {
						return true;
					}
				}
				return false;
			});
		}

		_searched.sort((a, b) => {
			const aVal = getReversedName(`${a.firstName} ${a.lastName}`).toLowerCase();
			const bVal = getReversedName(`${b.firstName} ${b.lastName}`).toLowerCase();

			return aVal < bVal ? -1 : 1;
		});

		setSearchedAttendees(_searched);
	}, [filteredAttendees, searchTerm, userInSpaces]);

	useEffect(() => {
		const sections: IAttendeeData[] = [];

		searchedAttendees.forEach((item) => {
			let header;

			if (activeScreenFilter?.filters.includes('profileVisitors')) {
				if (
					activeSpace &&
					profile?.visitors &&
					profile.visitors[activeSpace?.spaceId] &&
					profile.visitors[activeSpace?.spaceId][item.userId]
				) {
					header = moment(profile.visitors[activeSpace?.spaceId][item.userId]).format('DD.MM.YYYY');
				}
			} else if (activeScreenFilter?.filters.includes('matchings')) {
				const matchCount = getMatchCount('attendee', item);
				header = `${matchCount} ${matchCount === 1 ? 'Match' : 'Matchings'}`;
			} else {
				header = getReversedName(item.lastName).charAt(0).toUpperCase();
			}

			if (!isEmptyString(header)) {
				const found = sections.find((e) => e.header === header);
				if (found) {
					found.items.push(item);
				} else {
					sections.push({
						header,
						items: [item]
					});
				}
			}
		});

		if (activeScreenFilter?.filters.includes('profileVisitors')) {
			sections.sort((a, b) => (moment(a.header, 'DD.MM.YYYY').isAfter(moment(b.header, 'DD.MM.YYYY')) ? -1 : 1));
		} else if (activeScreenFilter?.filters.includes('matchings')) {
			sections.sort((a, b) => (Number(a.header.split(' ')[0]) > Number(b.header.split(' ')[0]) ? -1 : 1));
		} else {
			sections.sort((a, b) => (a.header.toLowerCase() < b.header.toLowerCase() ? -1 : 1));
		}

		let newSections: typeof attendeeFlashSections = [];

		sections.forEach((section) => {
			newSections = newSections.concat([section.header, ...section.items]);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}

		setAttendeeFlashSections(newSections);
	}, [searchedAttendees]);

	const _getCurrentFilterCount = () => {
		let count = 0;

		if (activeModalFilters) {
			Object.keys(activeModalFilters).forEach((filterKey) => {
				if (!isEmptyString(activeModalFilters[filterKey])) {
					const splitted = activeModalFilters[filterKey].split(',');
					count += splitted.length;
				}
			});
		}

		return count;
	};

	const _renderList = () => {
		if (
			searchedAttendees.length === 0 &&
			(!waitingForSocketResponse?.attendees || activeScreenFilter?.filters.includes('favorites') || !isEmptyString(searchTerm))
		) {
			const hasScreenFiltersActive =
				!activeScreenFilter?.filters.includes('all') &&
				!activeScreenFilter?.filters.includes('favorites') &&
				!activeScreenFilter?.filters.includes('profileVisitors') &&
				!activeScreenFilter?.filters.includes('scannedUser');

			if (!isEmptyString(searchTerm) || _getCurrentFilterCount() > 0 || hasScreenFiltersActive) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			if (activeScreenFilter?.filters.includes('favorites')) {
				return <NoData type="NoFavorites" />;
			}

			if (activeScreenFilter?.filters.includes('scannedUser')) {
				return <NoData type="NoScannedUsers" />;
			}

			return <NoData type="NoAttendees" />;
		}

		return _renderListContainer();
	};

	const _removeFromScanned = (userId) => {
		showAlert({
			title: t('removeAttendeeFromScannedList'),
			message: t('removeAttendeeFromScannedListSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Remove attendee'),
					style: 'destructive',
					onPress: async () => {
						setIsLoading(true);
						let _scannedUser = profile?.scannedUser ?? [];
						_scannedUser = _scannedUser.filter((u) => u.userId !== userId);
						const res = await updateUser({
							user: {
								scannedUser: _scannedUser
							},
							noToast: true
						});
						setIsLoading(false);
					}
				}
			]
		});
	};

	const _renderSelectAll = () => {
		if (onSelectAll && searchedAttendees.length > 0) {
			if (IS_WEB ? activeSpace?.webLayout === 'list' : activeSpace?.appLayout === 'list') {
				return (
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 10, paddingHorizontal: IS_WEB ? hsInnerPadding : 0 }}
						value={selectedAttendees && selectedAttendees.length >= searchedAttendees.length}
						onPress={() => {
							if (selectedAttendees && selectedAttendees.length >= searchedAttendees.length) {
								onSelectAll([]);
							} else if (selectedAttendees) {
								onSelectAll(searchedAttendees.map((at) => at.userId));
							}
						}}
					/>
				);
			}

			return (
				<HSCard>
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedAttendees && selectedAttendees.length >= searchedAttendees.length}
						onPress={() => {
							if (selectedAttendees && selectedAttendees.length >= searchedAttendees.length) {
								onSelectAll([]);
							} else if (selectedAttendees) {
								onSelectAll(searchedAttendees.map((at) => at.userId));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderAttendeeList = () => {
		let _hideIndexButtons = activeScreenFilter?.filters?.includes('matchings') ?? false;
		let _numberOfColumns = 1;
		let _estimatedSize = ATTENDEELISTITEMENTRYHEIGHT;
		let _data = attendeeFlashSections;
		const _viewType = isMailReceiver || isModal ? 'list' : IS_WEB ? activeSpace?.webLayout : activeSpace?.appLayout;

		switch (_viewType) {
			case 'cards':
				_hideIndexButtons = searchedAttendees.length < 50;
				_numberOfColumns = numColumns;
				_estimatedSize = CONTENTCARD_HEIGHT;
				// This is done to prevent empty cells
				_data = searchedAttendees;
				break;
			case 'list':
			default:
				// See default values above
				break;
		}

		return (
			<FlashSectionList
				data={_data}
				estimatedItemSize={_estimatedSize}
				extraData={[onSelect, selectedAttendees, activeScreenFilter?.filters]}
				numColumns={_numberOfColumns}
				showSectionButtonsOnCard={_viewType === 'cards'}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				sectionData={attendeeFlashSections?.filter((e) => typeof e === 'string') as string[]}
				renderItem={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return (
								<View
									style={getCardContainerStyle(_numberOfColumns, renderItem.index)}
									key={`${testID}_button_attendee_${renderItem.index}`}
								>
									<ContentCard
										testID={testID}
										item={renderItem.item}
										contentType="attendee"
										isLoading={isLoading}
										isSelected={selectedAttendees?.includes(renderItem.item.userId)}
										onPress={onPress ? () => onPress(renderItem.item) : undefined}
										onSelect={onSelect ? () => onSelect(renderItem.item.userId) : undefined}
										onDelete={
											activeScreenFilter?.filters.includes('scannedUser')
												? () => _removeFromScanned(renderItem.item.userId)
												: undefined
										}
										index={renderItem.index}
									/>
								</View>
							);
						case 'list':
						default:
							return (
								<AttendeeListItem
									testID={`${testID}_button_attendee_${renderItem.index}`}
									item={renderItem.item}
									isLoading={isLoading}
									onPress={onPress ? () => onPress(renderItem.item) : undefined}
									isSelected={selectedAttendees?.includes(renderItem.item.userId)}
									onSelect={onSelect ? () => onSelect(renderItem.item.userId) : undefined}
									onDelete={
										activeScreenFilter?.filters.includes('scannedUser')
											? () => _removeFromScanned(renderItem.item.userId)
											: undefined
									}
									isMailReceiver={isMailReceiver}
								/>
							);
					}
				}}
				renderSectionHeader={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return null;
						case 'list':
						default:
							return <AttendeeListHeader text={renderItem.item} />;
					}
				}}
				onScroll={onScroll}
				onContentSizeChange={(w, h) => {
					if (onContentSizeChange) {
						onContentSizeChange({ width: w, height: h });
					}
				}}
				hideIndexButtons={_hideIndexButtons}
			/>
		);
	};

	const _renderListContainer = () => {
		if (noCard) {
			const isTransparentBg = IS_WEB ? activeSpace?.webLayout === 'cards' : activeSpace?.appLayout === 'cards';
			return (
				<View
					style={{
						flex: 1,
						marginBottom: hsBottomMargin,
						backgroundColor: isTransparentBg ? undefined : theme.background
					}}
				>
					{_renderSelectAll()}
					{_renderAttendeeList()}
				</View>
			);
		}

		return (
			<HSCard
				style={{
					flex: 1,
					marginBottom: activeSpace?.sponsorTypeAttendees?.startsWith('sponsor') ? EHorizontalScreenPadding.Wide : hsBottomMargin,
					paddingLeft: IS_WEB || activeSpace?.appLayout === 'cards' ? 0 : hsInnerPadding,
					paddingRight: 0
				}}
			>
				{_renderSelectAll()}
				{_renderAttendeeList()}
			</HSCard>
		);
	};

	if (profile) {
		return (
			<View style={{ flex: 1 }}>
				{_renderList()}
				{!noSponsors && (
					<View style={{ width: '100%', alignSelf: 'center' }}>
						<SponsorBlock contentType={'attendees'} />
					</View>
				)}
			</View>
		);
	}

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<NoData type="NoProfile">
				<RoundButton
					testID={`${testID}_button_authenticate`}
					onPress={() => setIsAuthenticationModalVisible(true)}
					icon={EDefaultIconSet.Add}
					title={t('Login/Register')}
					isStacked
				/>
			</NoData>
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
		</View>
	);
};
