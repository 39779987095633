import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { v4 } from 'uuid';
import i18next from 'i18next';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IEditVoteFormErrors, IEditVoteFormValues, IVote } from 'config/interfaces';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderPreviewVoteButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { isEmptyString, showFormErrorToast, validateForm } from 'helper';
import {
	getEditVoteApplauseFormSchema,
	getEditVoteApplauseFormSchemaStep2,
	getEditVoteExternalUrlFormSchema,
	getEditVoteMultipleChoiceFormSchema,
	getEditVoteMultipleChoiceFormSchemaStep2,
	getEditVoteNpsFormSchema,
	getEditVotePinOnImageFormSchema,
	getEditVoteQuizFormSchema,
	getEditVoteQuizFormSchemaStep2,
	getEditVoteRatingFormSchema,
	getEditVoteScaleFormSchema,
	getEditVoteSurveyFormSchema,
	getEditVoteSurveyFormSchemaStep2,
	getEditVoteTextFormSchema,
	getEditVoteWordCloudFormSchema
} from 'config/yupSchemas/vote';
import { VoteEditForm } from 'components/Forms';
import { PreviewVoteModal } from 'components/Modal/PreviewVote';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { useSpace } from 'hooks/useSpace';
import * as RootNavigation from '../../../RootNavigation';
import { useTranslation } from 'react-i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.VotingEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.VotingEdit>;
type RouteParams = StackParamList[ERoutes.VotingEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const VoteEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<IEditVoteFormValues>({});
	const [editVoteFormErrors, setEditVoteFormErrors] = useState<IEditVoteFormErrors>({});
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const votes = useSelector((store: IRootState) => store.vote.votes);

	const updateVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVote);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (activeSpace) {
			const values = votes.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);
			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [activeSpace, votes]);

	useEffect(() => {
		navigation.setOptions({
			votingType: formValues.votingType
		});
	}, [formValues.votingType]);

	const _applyFormValues = (values: IVote) => {
		setFormValues({
			...values,
			schedules: values.schedules ? values.schedules.map((sc) => sc.id) : [],
			networkingrooms: values.networkingrooms ? values.networkingrooms.map((nr) => nr.id) : []
		});
		setIsFormLocked(false);
	};

	const _validate = async (currentIndex: number) => {
		let schema;

		const _values: typeof formValues = {
			...formValues,
			externalUrl: _checkUrl(formValues.externalUrl),
			publicId: !isEmptyString(formValues?.publicId) ? formValues?.publicId : v4()
		};

		switch (formValues.votingType) {
			case 'text':
				schema = getEditVoteTextFormSchema(_values);
				break;
			case 'externalUrl':
				schema = getEditVoteExternalUrlFormSchema(_values);
				break;
			case 'applause':
				switch (currentIndex) {
					case 1:
						schema = getEditVoteApplauseFormSchemaStep2(_values);
						break;
					case 0:
					default:
						schema = getEditVoteApplauseFormSchema(_values);
						break;
				}
				break;
			case 'multipleChoice':
				switch (currentIndex) {
					case 1:
						schema = getEditVoteMultipleChoiceFormSchemaStep2(_values);
						break;
					case 0:
					default:
						schema = getEditVoteMultipleChoiceFormSchema(_values);
						break;
				}

				break;
			case 'quiz':
				switch (currentIndex) {
					case 1:
						schema = getEditVoteQuizFormSchemaStep2(_values);
						break;
					case 0:
					default:
						schema = getEditVoteQuizFormSchema(_values);
						break;
				}
				break;
			case 'survey':
				switch (currentIndex) {
					case 1:
						schema = getEditVoteSurveyFormSchemaStep2(_values);
						break;
					case 0:
					default:
						schema = getEditVoteSurveyFormSchema(_values);
						break;
				}
				break;
			case 'rating':
				schema = getEditVoteRatingFormSchema(_values);
				break;
			case 'scale':
				schema = getEditVoteScaleFormSchema(_values);
				break;
			case 'survey':
				break;
			case 'wordCloud':
				schema = getEditVoteWordCloudFormSchema(_values);
				break;
			case 'nps':
				schema = getEditVoteNpsFormSchema(_values);
				break;
			case 'pinOnImage':
				schema = getEditVotePinOnImageFormSchema(_values);
				break;
			default:
				return false;
		}

		const errors = await validateForm(schema, _values);

		navigation.setParams({ prohibitNavigation: true });
		if (errors) {
			setEditVoteFormErrors(errors);
			showFormErrorToast(errors);
			return true;
		} else {
			setEditVoteFormErrors({});
			formValues.externalUrl = _values.externalUrl;
			return false;
		}
	};

	const _updateVote = async (currentIndex: number) => {
		const errors = await _validate(currentIndex);
		if (errors) {
			return;
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = votes.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsLoading(true);
		let _values = { ...formValues };
		Object.keys(_values).map((k) => {
			if (typeof _values[k] === 'string') {
				_values[k] = _values[k].trim();
			}
		});
		const res = await updateVote({
			voteId: route.params.id,
			vote: _values
		});
		if (res?.id) {
			navigation.setParams({ prohibitNavigation: false });
			setFormValues(res);
			if (navigation.canGoBack()) {
				navigation.goBack();
			} else {
				navigation.replace(ERoutes.ActiveVotingAdmin, { spaceId: activeSpace?.spaceId, id: res.id });
			}
		}
		setIsLoading(false);
	};

	const updateFormValues = (val) => {
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _checkUrl = (url: string | undefined) => {
		if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
			return 'http://' + url;
		}
		return url;
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="votings">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding
				}}
			>
				<VoteEditForm
					values={formValues}
					errors={editVoteFormErrors}
					onChange={(val) => updateFormValues(val)}
					onNext={_validate}
					onSubmit={_updateVote}
					isLoading={isLoading}
				/>
			</View>
			<PreviewVoteModal
				isVisible={route.params ? route.params?.showModal ?? false : false}
				onClose={() => navigation.setParams({ showModal: false })}
				vote={formValues}
			/>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const VoteEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Edit')} />
			<NavigationHeaderPreviewVoteButton options={props.options} onPress={() => navigation.setParams({ showModal: true })} />
		</NavigationHeader>
	);
};
