import { downloadWebFile } from 'helper/download';
import moment from 'moment';

const ics = require('ics');

export interface ICalenderEntry {
	title: string;
	startDate: string;
	endDate: string;
	timeZone: string;
	notes: string;
	location: string;
}

export const downloadCalenderEntry = (cEntry: ICalenderEntry, spaceTitle: string) => {
	const event = {
		start: _getIcsDateFormat(cEntry.startDate),
		end: _getIcsDateFormat(cEntry.endDate),
		title: cEntry.title,
		description: cEntry.notes,
		location: cEntry.location,
		status: 'CONFIRMED',
		busyStatus: 'BUSY'
	};
	const icsDownload = ics.createEvent(event);

	const file = new Blob([icsDownload.value], { type: 'text/calendar' });
	downloadWebFile(file, `${cEntry.title}-${spaceTitle}.ics`);
};

const _getIcsDateFormat = (date) => {
	const myMoment = moment(date);
	return [myMoment.year(), myMoment.month() + 1, myMoment.date(), myMoment.hour(), myMoment.minute()];
};
