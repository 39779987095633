import React from 'react';
import { isEmptyString } from 'helper';
import { TFunctionResult } from 'i18next';
import { TextStyle, TextProps } from 'react-native';

import { Text } from './Text';
import { hsTextBottomMargin } from 'config/styleConstants';

interface IH1 {
	children?: string | TFunctionResult;
	style?: TextStyle;
	center?: boolean;
	largerText?: boolean;
	noBottomMargin?: boolean;
}
export const H1 = (props: IH1 & TextProps) => {
	const { center, children, style, largerText, noBottomMargin } = props;

	if (!isEmptyString(children)) {
		return (
			<Text
				{...props}
				bold
				center={center}
				style={[{ fontSize: largerText ? 30 : 20, marginBottom: noBottomMargin ? 0 : hsTextBottomMargin }, style]}
			>
				{children}
			</Text>
		);
	}

	return null;
};
