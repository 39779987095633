import React from 'react';
import { View } from 'react-native';

import { ChildButton } from 'components/Button';
import { IExpo } from 'config/interfaces';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, getDefaultAspectRatioStyle, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { Image } from 'components/Image/Image';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';

interface IExpoReferenceItem {
	testID: string;
	item: IExpo;
	onPress: () => void;
}

const IMAGEWIDTH = 60;

export const ExpoReferenceItem = (props: IExpoReferenceItem) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: 20,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: 5,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<View style={{ width: IMAGEWIDTH }}>
				<Image
					style={{ ...getDefaultAspectRatioStyle(IMAGEWIDTH), borderRadius: 5 }}
					mediaObj={item.logo}
					imageSize="small"
					expectedRatio={16 / 9}
					resizeMode={'contain'}
				/>
			</View>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row' }}>
					<Text bold style={{ marginRight: item.isHidden ? 5 : undefined }}>
						{item.title}
					</Text>
					{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
				</View>
				{!isEmptyString(item.catchphrase) && (
					<Text italic style={{ fontSize: 12 }}>
						{item.catchphrase}
					</Text>
				)}
			</View>
			{SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
