import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from '../routes';
import { EDefaultIconSet } from 'helper/icon';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';

interface INavigationHeaderCreateNewsButton {
	isEditMode?: boolean;
	options?: any;
}

export const NavigationHeaderCreateNewsButton = (props: INavigationHeaderCreateNewsButton) => {
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const navigation = useNavigation();

	if (
		!iAmSpaceAdmin &&
		!iAmSpaceModerator &&
		profile?.email &&
		activeSpace?.allowUserGeneratedContentInFeed &&
		activeSpace?.allowUserGeneratedContentInFeed !== 'never'
	) {
		return (
			<NavigationHeaderIconButton
				testID="header_button_createnews"
				icon={EDefaultIconSet.Add}
				onPress={() => {
					navigation.navigate(ERoutes.NewsCreate, { spaceId: activeSpace?.spaceId });
				}}
			/>
		);
	}

	return null;
};
