import React, { useEffect, useState } from 'react';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { CheckVideoInputFeedback, DefaultMeetingReadinessChecker, DefaultMeetingSession } from 'amazon-chime-sdk-js';
import { Dropdown } from 'components/Form/Dropdown';
import { FormLabel } from 'components/Form/FormLabel';
import { View } from 'react-native';

import testSound from '../../../../assets/sounds/test_sound.wav';
import { EDefaultIconSet } from 'helper';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { hsInnerPadding } from 'config/styleConstants';

interface INewDevices {
	audioInputDevice?: MediaDeviceInfo;
	audioOutputDevice?: MediaDeviceInfo;
	videoInputDevice?: MediaDeviceInfo;
}

interface INetworkingRoomSettingsModal {
	isVisible: boolean;
	onSubmit: (newDevices: INewDevices) => void;
	deviceInputList: {
		audioInputDevices: MediaDeviceInfo[];
		audioOutputDevices: MediaDeviceInfo[];
		videoInputDevices: MediaDeviceInfo[];
	};
	meetingReadinessChecker?: DefaultMeetingReadinessChecker;
	meetingSession?: DefaultMeetingSession;
	isAudioOnly?: boolean;
}

const TESTIDPREFIX = 'networkingroomsettingsmodal';

export const NetworkingRoomSettingsModal = (props: INetworkingRoomSettingsModal) => {
	const { isVisible, onSubmit, deviceInputList, meetingReadinessChecker, meetingSession, isAudioOnly } = props;
	const { t } = useTranslation();

	const [data, setData] = useState<INewDevices>({
		audioInputDevice: undefined,
		audioOutputDevice: undefined,
		videoInputDevice: undefined
	});
	const [devices, setDevices] = useState<{
		audioInputDevices: MediaDeviceInfo[];
		audioOutputDevices: MediaDeviceInfo[];
		videoInputDevices: MediaDeviceInfo[];
	}>({ audioInputDevices: [], audioOutputDevices: [], videoInputDevices: [] });

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [videoReadiness, setVideoReadiness] = useState<CheckVideoInputFeedback | undefined>(undefined);

	useEffect(() => {
		const _devices = { ...devices };
		let hasChanged = false;

		if (deviceInputList.audioInputDevices.length !== _devices.audioInputDevices.length) {
			hasChanged = true;
		} else if (deviceInputList.audioOutputDevices.length !== _devices.audioOutputDevices.length) {
			hasChanged = true;
		} else if (deviceInputList.videoInputDevices.length !== _devices.videoInputDevices.length) {
			hasChanged = true;
		}

		if (hasChanged) {
			setDevices(deviceInputList);
		}
	}, [deviceInputList]);

	useEffect(() => {
		const { audioInputDevices, audioOutputDevices, videoInputDevices } = devices;
		const obj = { ...data };

		let hasChanged = false;

		if (!obj.audioInputDevice && audioInputDevices?.length > 0) {
			obj.audioInputDevice = audioInputDevices[0];
			hasChanged = true;
		} else if (obj.audioOutputDevice) {
			const isHere = audioInputDevices.find((e) => e.deviceId === obj.audioInputDevice?.deviceId);
			if (!isHere) {
				obj.audioInputDevice = audioInputDevices[0];
				hasChanged = true;
			}
		}

		if (!obj.audioOutputDevice && audioOutputDevices?.length > 0) {
			obj.audioOutputDevice = audioOutputDevices[0];
			hasChanged = true;
		} else if (obj.audioOutputDevice) {
			const isHere = audioOutputDevices.find((e) => e.deviceId === obj.audioOutputDevice?.deviceId);
			if (!isHere) {
				obj.audioOutputDevice = audioOutputDevices[0];
				hasChanged = true;
			}
		}

		if (!obj.videoInputDevice && videoInputDevices?.length > 0) {
			obj.videoInputDevice = videoInputDevices[0];
			hasChanged = true;
		}

		if (hasChanged) {
			setData(obj);
		}
	}, [devices]);

	useEffect(() => {
		if (isVisible) {
			_checkVideo();
		}
	}, [isVisible, data.videoInputDevice]);

	useEffect(() => {
		if (isVisible) {
			_checkSpeakerConnectivity(document.getElementById(`${TESTIDPREFIX}_audioout_preview`));
		}
	}, [isVisible, data.audioOutputDevice]);

	const _checkSpeakerConnectivity = async (element) => {
		if (meetingSession && devices.audioOutputDevices.length > 0) {
			setIsLoading(true);
			await meetingSession.deviceController.chooseAudioOutput(data.audioOutputDevice?.deviceId ?? null);
			element?.setSinkId(data.audioOutputDevice);
			setIsLoading(false);
		}
	};

	const _playSound = async (element) => {
		element?.play();
	};

	const _checkVideo = async () => {
		if (isVisible && meetingReadinessChecker && meetingSession && data.videoInputDevice?.deviceId) {
			setIsLoading(true);

			const videoFeedback = await meetingReadinessChecker.checkVideoInput(data.videoInputDevice?.deviceId);
			setVideoReadiness(videoFeedback);

			await meetingSession?.deviceController?.startVideoInput(data.videoInputDevice?.deviceId ?? null);

			const el = document.getElementById(`${TESTIDPREFIX}_video_preview`) as HTMLVideoElement;
			if (data.videoInputDevice) {
				meetingSession.audioVideo.startVideoPreviewForVideoInput(el);
			} else {
				meetingSession.audioVideo.stopVideoPreviewForVideoInput(el);
			}

			setIsLoading(false);
		}
	};

	const _renderSpeakerSelection = () => {
		if (devices.audioOutputDevices.length > 0) {
			return (
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'flex-start',
						flexWrap: 'wrap',
						justifyContent: 'space-between',
						marginBottom: 10
					}}
				>
					<View style={{ flex: 1, paddingRight: 20 }}>
						<FormLabel testID={`${TESTIDPREFIX}_label_audioout`} label={t('Headphones')} style={{ marginBottom: 5 }} />
						<Dropdown
							testID={`${TESTIDPREFIX}_dropdown_audioout`}
							onSelect={(val) => {
								const obj: INewDevices = data
									? { ...data, audioOutputDevice: devices.audioOutputDevices.find((e) => e.deviceId === val) }
									: {
											audioInputDevice: undefined,
											audioOutputDevice: devices.audioOutputDevices.find((e) => e.deviceId === val),
											videoInputDevice: undefined
									  };

								setData(obj);
							}}
							value={data.audioOutputDevice?.deviceId}
							options={devices.audioOutputDevices.map((e) => {
								return {
									key: e.deviceId,
									label: e.label
								};
							})}
							isDisabled={isLoading}
						/>
					</View>
					<View style={{ marginTop: 5 }}>
						<ChildButton
							testID={`${TESTIDPREFIX}_button_testsound`}
							style={{ flexDirection: 'row', alignItems: 'center', marginTop: hsInnerPadding }}
							onPress={() => _playSound(document.getElementById(`${TESTIDPREFIX}_audioout_preview`))}
						>
							<Icon name={EDefaultIconSet.Speakers} />
							<Text style={{ marginLeft: 5 }}>{t('Play Sound')}</Text>
						</ChildButton>
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderWebcamSelection = () => {
		return (
			<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
				<View style={{ flex: 1, paddingRight: 20 }}>
					<FormLabel testID={`${TESTIDPREFIX}_label_video`} label={t('Camera')} style={{ marginBottom: 5 }} />
					<Dropdown
						testID={`${TESTIDPREFIX}_dropdown_camera`}
						onSelect={(val) => {
							const obj: INewDevices = data
								? { ...data, videoInputDevice: devices.videoInputDevices.find((e) => e.deviceId === val) }
								: {
										audioInputDevice: undefined,
										audioOutputDevice: undefined,
										videoInputDevice: devices.videoInputDevices.find((e) => e.deviceId === val)
								  };

							setData(obj);
						}}
						value={data.videoInputDevice?.deviceId}
						options={[
							{
								key: null,
								label: t('JoinWithoutCamera')
							},
							...devices.videoInputDevices.map((e) => {
								return {
									key: e.deviceId,
									label: e.label
								};
							})
						]}
						isDisabled={isLoading}
					/>
				</View>
				<video
					id={`${TESTIDPREFIX}_video_preview`}
					style={{ width: data.videoInputDevice && videoReadiness === CheckVideoInputFeedback.Succeeded ? '25%' : 0 }}
				/>
			</View>
		);
	};

	return (
		<HSModal title={t('Settings')} isVisible={isVisible} onSubmit={() => onSubmit(data)}>
			<View style={{ flex: 1 }}>
				<View style={{ marginBottom: 10 }}>
					<FormLabel testID={`${TESTIDPREFIX}_label_microphone`} label={t('Microphone')} style={{ marginBottom: 5 }} />
					<Dropdown
						testID={`${TESTIDPREFIX}_dropdown_microphone`}
						onSelect={(val) => {
							const obj: INewDevices = data
								? { ...data, audioInputDevice: devices.audioInputDevices.find((e) => e.deviceId === val) }
								: {
										audioInputDevice: devices.audioInputDevices.find((e) => e.deviceId === val),
										audioOutputDevice: undefined,
										videoInputDevice: undefined
								  };

							setData(obj);
						}}
						value={data.audioInputDevice?.deviceId}
						options={[
							{
								key: null,
								label: t('JoinWithoutMicrophone')
							},
							...devices.audioInputDevices.map((e) => {
								return {
									key: e.deviceId,
									label: e.label
								};
							})
						]}
						isDisabled={isLoading}
					/>
				</View>
				<audio id={`${TESTIDPREFIX}_audioout_preview`} src={testSound} />

				{_renderSpeakerSelection()}
				{_renderWebcamSelection()}
			</View>
		</HSModal>
	);
};
