import React, { ReactNode, useState } from 'react';
import { Icon } from 'components/Icon';
import { Dimensions, ScrollView, View } from 'react-native';
import { Text, Title } from 'components/Text';
import { EDefaultIconSet, getTopRadiusStyle, normalizeFontSize } from 'helper';
import { useTranslation } from 'react-i18next';
import { Image } from 'components/Image';

import step1 from '../../assets/images/noData/noDataPlaceholderGray.png';
import { HSCard } from 'components/Card';
import { RoundButton } from 'components/Button';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsBottomMargin, hsTextBottomMargin, hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';

interface INoData {
	type?: TNoDataType;
	title?: string;
	subtitle?: string;
	icon?: string;
	media?: string;
	children?: ReactNode | ReactNode[];
	padding?: number;
	noRoundedTop?: boolean;
}

export type TNoDataType =
	| 'NoOptions'
	| 'NoQuizOptions'
	| 'NoApplauseOptions'
	| 'NoMultipleChoiceOptions'
	| 'NoSpaces'
	| 'NoVotings'
	| 'NoAnswers'
	| 'NoPushNotifications'
	| 'NoNews'
	| 'NoAttendees'
	| 'NoMaps'
	| 'NoMailTemplates'
	| 'NoMailHistory'
	| 'NoSpeakers'
	| 'NoExpos'
	| 'NoSponsors'
	| 'NoSchedules'
	| 'NoProfile'
	| 'NoChats'
	| 'NoMeetings'
	| 'NoNetworking'
	| 'NoStreams'
	| 'NoWebhooks'
	| 'NoWebhookHistory'
	| 'NoMediaItems'
	| 'AllowChats'
	| 'AllowMeetings'
	| 'ProfileHidden'
	| 'NotConfirmed'
	| 'MissingSelfServiceToken'
	| 'SpaceIdNotFound'
	| 'ItemNotFound'
	| 'ContentTypeSelfServiceDisabled'
	| 'NoFavorites'
	| 'NoTickets'
	| 'NoSupportRequests'
	| 'NoMeetingTables'
	| 'NoSurveyOptions'
	| 'NoWordCloudWords'
	| 'NoReports'
	| 'NoBookings'
	| 'NoSearchResult'
	| 'NoQuizAnswers'
	| 'NoScannedUsers'
	| 'SelfServiceTokenValidationError'
	| 'loadingData'
	| 'FeatureTokenValidationError';

export const NoData = (props: INoData) => {
	let { title, subtitle, icon, media, type, children, padding, noRoundedTop } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const navigation = useNavigation();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const screenSize = { height: Dimensions.get('window').height, width: Dimensions.get('window').width };
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);

	const cardStyle = noRoundedTop ? { ...getTopRadiusStyle(0), borderTopWidth: 0 } : {};

	let hasSponsors = false;

	switch (type) {
		case 'NoOptions':
			title = `${t('NoOptionsYetTitle')}`;
			subtitle = `${t('NoOptionsYetSubtitle')}`;
			break;
		case 'NoQuizOptions':
			icon = EDefaultIconSet.Quiz;
			title = `${t('NoOptionsYetTitle')}`;
			subtitle = `${t('NoOptionsYetSubtitle')}`;
			break;
		case 'NoSurveyOptions':
			icon = EDefaultIconSet.Survey;
			title = `${t('NoOptionsYetTitle')}`;
			subtitle = `${t('NoOptionsYetSubtitle')}`;
			break;
		case 'NoWordCloudWords':
			icon = EDefaultIconSet.WordCloud;
			title = `${t('NoWCWordsFoundTitle')}`;
			subtitle = `${t('NoWCWordsFoundSubtitle')}`;
			break;
		case 'NoApplauseOptions':
			icon = EDefaultIconSet.Applause;
			title = `${t('NoOptionsYetTitle')}`;
			subtitle = `${t('NoOptionsYetSubtitle')}`;
			break;
		case 'NoMultipleChoiceOptions':
			icon = EDefaultIconSet.MultipleChoice;
			title = `${t('NoOptionsYetTitle')}`;
			subtitle = `${t('NoOptionsYetSubtitle')}`;
			break;
		case 'NoAnswers':
			title = `${t('NoAnswersFoundTitle')}`;
			subtitle = `${t('NoAnswersFoundSubtitle')}`;
			break;
		case 'NoSpaces':
			icon = EDefaultIconSet.Search;
			title = `${t('NoSpacesFoundTitle')}`;
			subtitle = `${t('NoSpacesFoundSubtitle')}`;
			media = step1;
			break;
		case 'NoVotings':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoVotesFoundTitle')}`;
			subtitle = `${iAmSpaceAdmin || iAmSpaceModerator ? '' : t('NoVotesFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeVotings?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoPushNotifications':
			icon = EDefaultIconSet.Search;
			title = `${t('NoPushNotificationsFoundTitle')}`;
			subtitle = `${t('NoPushNotificationsFoundSubtitle')}`;
			media = step1;
			break;
		case 'NoNews':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoNewFoundTitle')}`;
			subtitle = `${t('NoNewsFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeFeed?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoAttendees':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoAttendeesFoundTitle')}`;
			subtitle = `${t('NoAttendeesFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeAttendees?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoMaps':
			// icon = EDefaultIconSet.Map;
			title = `${t('NoMapsFoundTitle')}`;
			// media = step1;
			break;
		case 'NoSpeakers':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoSpeakersFoundTitle')}`;
			subtitle = `${t('NoSpeakersFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeSpeaker?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoExpos':
			icon = EDefaultIconSet.Exhibition;
			title = `${t('NoExposFoundTitle')}`;
			subtitle = `${t('NoSpeakersFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeExpo?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoSponsors':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoSponsorsFoundTitle')}`;
			subtitle = `${t('NoSpeakersFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeExpo?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoSchedules':
			// icon = EDefaultIconSet.Search;
			title = `${t('NoSchedulesFoundTitle')}`;
			subtitle = `${t('NoScheduleFoundSubtitle')}`;
			// media = step1;
			if (activeSpace?.sponsorTypeAgenda?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoMailTemplates':
			icon = EDefaultIconSet.Search;
			title = `${t('NoMailTemplatesFoundTitle')}`;
			subtitle = `${t('NoMailTemplatesFoundSubtitle')}`;
			media = step1;
			break;
		case 'NoMailHistory':
			icon = EDefaultIconSet.Search;
			title = `${t('NoMailHistoryFoundTitle')}`;
			media = step1;
			break;
		case 'NoChats':
			// icon = EDefaultIconSet.User;
			title = t('NoChatsFoundTitle');
			subtitle = t('NoChatsFoundSubtitle');
			// media = step1;
			break;
		case 'NoMeetings':
			// icon = EDefaultIconSet.User;
			title = t('NoMeetingsFoundTitle');
			subtitle = t('NoMeetingsFoundSubtitle');
			// media = step1;
			break;
		case 'NoNetworking':
			// icon = EDefaultIconSet.User;
			title = t('NoNetworkingFoundTitle');
			subtitle = t('NoNetworkingFoundSubtitle');
			// media = step1;
			if (activeSpace?.sponsorTypeNetworking?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoStreams':
			icon = EDefaultIconSet.Stream;
			title = t('NoStreamsFoundTitle');
			subtitle = t('NoStreamsFoundSubtitle');
			media = step1;
			break;
		case 'NoWebhooks':
			icon = EDefaultIconSet.Webhook;
			title = t('NoWebhooksFoundTitle');
			subtitle = t('NoWebhooksFoundSubtitle');
			break;
		case 'NoWebhookHistory':
			icon = EDefaultIconSet.Webhook;
			title = t('NoWebhookHistoryTitle');
			subtitle = t('NoWebhookHistorySubtitle');
			break;
		case 'NoMediaItems':
			// icon = EDefaultIconSet.MediaLibrary;
			title = t('NoMediaItemsFoundTitle');
			subtitle = t('NoMediaItemsFoundSubtitle');
			// media = step1;
			if (activeSpace?.sponsorTypeMediaItem?.startsWith('sponsor')) hasSponsors = true;
			break;
		case 'NoProfile':
			icon = EDefaultIconSet.User;
			title = t('NotAuthenticated');
			subtitle = t('NotAuthenticatedSubtitle');
			media = step1;
			break;
		case 'AllowChats':
			icon = EDefaultIconSet.User;
			title = t('ProfileAllowChatsTitle');
			subtitle = t('ProfileAllowChatsSubtitle');
			media = step1;
			break;
		case 'AllowMeetings':
			icon = EDefaultIconSet.User;
			title = t('ProfileAllowMeetingRequestsTitle');
			subtitle = t('ProfileAllowMeetingRequestsSubtitle');
			media = step1;
			break;
		case 'ProfileHidden':
			icon = EDefaultIconSet.User;
			title = t('ProfileHiddenTitle');
			subtitle = t('ProfileHiddenSubtitle');
			media = step1;
			break;
		case 'NotConfirmed':
			icon = EDefaultIconSet.User;
			title = t('AccountNotConfirmedTitle');
			subtitle = t('AccountNotConfirmedSubtitle');
			media = step1;
			break;
		case 'MissingSelfServiceToken':
			icon = EDefaultIconSet.SelfService;
			title = t('MissingSelfServiceTokenTitle');
			subtitle = t('MissingSelfServiceTokenSubtitle');
			break;
		case 'SpaceIdNotFound':
			icon = EDefaultIconSet.SelfService;
			title = t('SpaceIdNotFoundTitle');
			subtitle = t('SpaceIdNotFoundSubtitle');
			break;
		case 'ItemNotFound':
			icon = EDefaultIconSet.SelfService;
			title = t('ItemNotFoundTitle');
			subtitle = t('ItemNotFoundSubtitle');
			break;
		case 'ContentTypeSelfServiceDisabled':
			icon = EDefaultIconSet.SelfService;
			title = t('ContentTypeSelfServiceDisabledTitle');
			subtitle = t('ContentTypeSelfServiceDisabledSubtitle');
			break;
		case 'NoFavorites':
			icon = EDefaultIconSet.Star;
			title = t('NoFavoritesTitle');
			subtitle = t('NoFavoritesSubtitle');
			break;
		case 'NoTickets':
			icon = EDefaultIconSet.Star;
			title = t('NoTicketTitle');
			subtitle = t('NoTicketsSubtitle');
			break;
		case 'NoSupportRequests':
			icon = EDefaultIconSet.Help;
			title = t('NoSupportRequestsTitle');
			subtitle = t('NoSupportRequestsSubtitle');
			break;
		case 'NoMeetingTables':
			icon = EDefaultIconSet.Meetings;
			title = t('NoMeetingTablesTitle');
			subtitle = t('NoMeetingTablesSubtitle');
			break;
		case 'NoReports':
			icon = EDefaultIconSet.Analytics;
			title = t('No Reports');
			subtitle = t('No Reports Subtitle');
			break;
		case 'NoSearchResult':
			icon = EDefaultIconSet.Search;
			title = t('No Search Result');
			break;
		case 'NoBookings':
			icon = EDefaultIconSet.Bookings;
			title = t('No Bookings');
			break;
		case 'NoQuizAnswers':
			icon = EDefaultIconSet.Quiz;
			title = t('No NoQuizAnswers');
			subtitle = t('No NoQuizAnswers Subtitle');
			break;
		case 'NoScannedUsers':
			icon = EDefaultIconSet.QRCode;
			title = t('NoScannedAttendees');
			subtitle = t('NoScannedAttendeesSubtitle');
			break;
		case 'SelfServiceTokenValidationError':
			icon = EDefaultIconSet.SelfService;
			title = t('URLTokenValidationError');
			subtitle = t('SelfServiceTokenValidationErrorSubtitle');
			break;
		case 'FeatureTokenValidationError':
			icon = EDefaultIconSet.Features;
			title = t('URLTokenValidationError');
			subtitle = t('FeatureTokenValidationErrorSubtitle');
			break;
		case 'loadingData':
			title = t('loadingDataTitle');
			subtitle = t('loadingDataSubtitle');
		default:
			media = step1;
			break;
	}

	const _allowChats = async () => {
		setIsLoading(true);
		await updateUser({ user: { allowChats: true }, noToast: true });
		setIsLoading(false);
	};

	const _allowMeetingRequests = async () => {
		setIsLoading(true);
		await updateUser({ user: { allowMeetingRequests: true }, noToast: true });
		setIsLoading(false);
	};

	const _showProfile = async () => {
		setIsLoading(true);
		await updateUser({ user: { hideMyProfile: false }, noToast: true });
		setIsLoading(false);
	};

	const _renderChildren = () => {
		switch (type) {
			case 'AllowChats':
				return (
					<View style={{ marginTop: 40 }}>
						<RoundButton
							testID={`button_allowchats`}
							onPress={_allowChats}
							icon={EDefaultIconSet.Chat}
							title={t('allowChats')}
							isLoading={isLoading}
						/>
					</View>
				);
			case 'AllowMeetings':
				return (
					<View style={{ marginTop: 40 }}>
						<RoundButton
							testID={`button_allowmeetingrequests`}
							onPress={_allowMeetingRequests}
							icon={EDefaultIconSet.Meetings}
							title={t('allowMeetingRequests')}
							isLoading={isLoading}
						/>
					</View>
				);
			case 'ProfileHidden':
				return (
					<View style={{ marginTop: 40 }}>
						<RoundButton
							testID={`button_showprofile`}
							onPress={_showProfile}
							icon={EDefaultIconSet.User}
							title={t('ShowProfile')}
							isLoading={isLoading}
						/>
					</View>
				);
			case 'NoWebhooks':
				return (
					<View style={{ marginTop: 40 }}>
						<RoundButton
							testID={`button_createwebhook`}
							onPress={() => navigation.navigate(ERoutes.WebhookCreate, { spaceId: activeSpace?.spaceId })}
							icon={EDefaultIconSet.Add}
							title={t('CreateWebhook')}
							isLoading={isLoading}
						/>
					</View>
				);
			default:
				return null;
		}
	};

	const _renderTopPart = () => {
		if (media) {
			let _size = Math.min(screenSize.height / 2, screenSize.width / 2, containerWidth * 0.75);
			return (
				<Image
					url={media}
					style={{
						alignSelf: 'center',
						width: _size,
						height: _size,
						maxWidth: 360,
						maxHeight: 360
					}}
					resizeMode={'contain'}
				/>
			);
		}
		if (icon) {
			return (
				<Icon
					name={icon}
					size={normalizeFontSize(128)}
					color={theme.primary}
					containerStyle={{ alignItems: 'center', justifyContent: 'center' }}
				/>
			);
		}
		return null;
	};

	return (
		<ScrollView
			style={{
				width: '100%',
				alignSelf: 'center',
				paddingHorizontal: 0,
				marginBottom: hasSponsors ? EHorizontalScreenPadding.Wide : hsBottomMargin
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<HSCard style={{ ...cardStyle }}>
				{_renderTopPart()}
				<View
					style={{ width: '100%', marginHorizontal: EHorizontalScreenPadding.Wide, alignSelf: 'center', alignItems: 'center' }}
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				>
					<Title center style={{ marginBottom: 5, marginTop: hsTopScreenPadding }}>
						{title}
					</Title>
					<Text center style={{ marginBottom: children ? hsTextBottomMarginSmall : hsTextBottomMargin }}>
						{subtitle}
					</Text>
				</View>
				<View style={{ alignSelf: 'center', alignItems: 'center', marginVertical: children ? 10 : 0 }}>
					{_renderChildren()}
					{children}
				</View>
			</HSCard>
		</ScrollView>
	);
};
