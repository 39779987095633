import { HSCard } from 'components/Card/HSCard';
import { FormReferenceSelect } from 'components/Form/FormReferenceSelect';
import { MediaTypeChooser } from 'components/Form/FormStreamSelect/StreamModal/MediaTypeChooser';
import { StreamInput } from 'components/Form/FormStreamSelect/StreamModal/StreamInput';
import { StepWizard } from 'components/StepWizard';
import { ICreateStreamFormErrors, ICreateStreamFormValues, IStream } from 'config/interfaces';
import { isEmptyString } from 'helper';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { StreamList } from 'components/Stream/StreamList';
import { hsBottomMargin, hsInnerPadding, hsTextBottomMarginSmall } from 'config/styleConstants';

interface IStreamCreateForm {
	testID: string;
	values: ICreateStreamFormValues;
	errors: ICreateStreamFormErrors;
	onChange: (values: ICreateStreamFormValues) => void;
	onSubmit: () => void;
	onSelect?: (streamId: number) => void;
	isLoading: boolean;
	isModal?: boolean;
	allowReferences?: boolean;
	hidePreviewImage?: boolean;
	hideMediaItem?: boolean;
	isSelfService?: boolean;
	hideLanguage?: boolean;
}

export const StreamCreateForm = (props: IStreamCreateForm) => {
	const {
		testID,
		values,
		errors,
		onChange,
		onSubmit,
		onSelect,
		isLoading,
		isModal,
		allowReferences,
		hidePreviewImage,
		hideMediaItem,
		isSelfService,
		hideLanguage
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [currentStep, setCurrentStep] = useState<number>(0);
	const [streams, setStreams] = useState<IStream[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _streams: typeof streams = [];

		if (content.streams) {
			_streams = [...content.streams];
		}

		setStreams(_streams);
	}, [content]);

	const _isNextDisabled = () => {
		switch (currentStep) {
			case 0:
				return !values.streamType;
			case 1:
				if (values.streamType !== 'upload' && isEmptyString(values.title)) {
					return true;
				}
				switch (values.streamType) {
					case 'contentflow':
						return isEmptyString(values.contentflowUrl);
					case 'mediaLibrary':
						return !values.mediaitem;
					case 'upload':
						return !values.media;
					case 'youtube':
						return isEmptyString(values.youTubeUrl);
					case 'vimeo':
						return isEmptyString(values.vimeoUrl);
					case 'iFrame':
						return isEmptyString(values.iFrameUrl);
					case 'externalMeeting':
						return isEmptyString(values.externalMeetingUrl) || isEmptyString(values.externalMeetingButtonText);
					case 'zoom':
						return (
							isEmptyString(values.zoomMeetingId) ||
							isEmptyString(values.zoomMeetingPassword) ||
							isEmptyString(values.zoomMeetingUrl)
						);
					case 'networking':
						return !values.networkinRoomId;
					default:
						return true;
				}
			default:
				return false;
		}
	};

	const _getNextButtonText = () => {
		if (allowReferences) {
			switch (currentStep) {
				case 2:
					return hideMediaItem ? t('Add media') : t('Create Stream');
				default:
					return undefined;
			}
		} else {
			switch (currentStep) {
				case 1:
					return hideMediaItem ? t('Add media') : t('Create Stream');
				default:
					return undefined;
			}
		}
	};

	const _additionalSteps = () => {
		const arr: ReactNode[] = [];

		if (allowReferences) {
			arr.push(
				<FormReferenceSelect
					key={`${testID}_referenceselect_schedules`}
					testID={`${testID}_referenceselect_schedules`}
					label={t('Agenda')}
					onSelect={(schedules) => onChange({ ...values, schedules: schedules as number[] | undefined })}
					multiSelect
					isDisabled={isLoading}
					type="schedules"
					value={values.schedules}
				/>
			);
		}

		return arr;
	};

	const _renderStreamList = () => {
		if (isModal && streams.length > 0 && onSelect) {
			return (
				<View style={{ flex: 1 }}>
					<View
						style={{
							width: '95%',
							alignSelf: 'center',
							borderTopColor: theme.lightgray,
							marginTop: 20,
							paddingTop: 20,
							marginBottom: hsBottomMargin,
							borderTopWidth: 1
						}}
					>
						<Text center>{t('ChooseExistingStream')}</Text>
					</View>
					<StreamList testID={testID} noCard onPress={(item) => onSelect(item.id)} />
				</View>
			);
		}

		return null;
	};

	const _renderWizard = () => {
		return (
			<StepWizard
				testIdPrefix={testID}
				components={[
					<ScrollView
						keyboardShouldPersistTaps="handled"
						onScrollBeginDrag={() => Keyboard.dismiss()}
						scrollEventThrottle={0}
						style={{ marginHorizontal: -hsInnerPadding }}
						contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
					>
						<Text center style={{ marginBottom: hsTextBottomMarginSmall }}>
							{hideMediaItem ? t('ChooseVideoType') : t('ChooseStreamType')}
						</Text>
						<MediaTypeChooser
							testID={`${testID}_mediatypechooser`}
							value={values.streamType}
							onChange={(streamType) => onChange({ ...values, streamType })}
							hideMediaItem={hideMediaItem}
							allowedTypes={isSelfService ? ['upload', 'externalMeeting'] : undefined}
						/>
						{_renderStreamList()}
					</ScrollView>,
					<StreamInput
						isMediaItem={hideMediaItem}
						testID={`${testID}_mediatypeinput`}
						values={values}
						errors={errors}
						onChange={(values) => onChange(values)}
						hidePreviewImage={hidePreviewImage}
						isSelfService={isSelfService}
						hideLanguage={hideLanguage}
					/>,
					..._additionalSteps()
				]}
				isLoading={isLoading}
				getCurrentIndex={(idx) => setCurrentStep(idx)}
				isNextButtonDisabled={_isNextDisabled()}
				completeFunction={() => onSubmit()}
				nextButtonText={_getNextButtonText()}
			/>
		);
	};

	if (isModal) {
		return <View style={{ flex: 1 }}>{_renderWizard()}</View>;
	}

	return <HSCard style={{ flex: 1 }}>{_renderWizard()}</HSCard>;
};
