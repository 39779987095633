import { ChildButton, FilterButton } from 'components/Button';
import { ScreenFilterbar } from 'components/Filter';
import { SearchBar } from 'components/SearchBar';
import React, { ReactNode, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import { TContentType } from 'hooks/useContent';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { hsInnerPadding } from 'config/styleConstants';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { Icon } from 'components/Icon';
import { IFeatureInfo } from 'config/interfaces';

interface IContentListHeader {
	testID: string;
	type?: TContentType;
	topChildren?: ReactNode | ReactNode[];
	searchTerm?: string;
	onSearchChange?: (value: string) => void;
	activeScreenFilters?: string[];
	onScreenFilterChange?: ({ filters, fieldName }: { filters: string[]; fieldName: string | undefined }) => void;
	modalFilterTitle?: string;
	activeModalFilters?: Record<string, string>;
	onModalFilterChange?: (val: Record<string, string>) => void;
	onLayout?: (e: LayoutChangeEvent) => void;
	onCollapse?: () => void;
	reload?: () => void;
	isReloadDisabled?: boolean;
	feature?: IFeatureInfo;
}

export const ContentListHeader = (props: IContentListHeader) => {
	const {
		testID,
		type,
		topChildren,
		searchTerm,
		onSearchChange,
		modalFilterTitle,
		activeScreenFilters,
		onScreenFilterChange,
		activeModalFilters,
		onModalFilterChange,
		onLayout,
		onCollapse,
		reload,
		isReloadDisabled,
		feature
	} = props;

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const { theme } = useTheme();

	const _renderScreenFilter = () => {
		if (type && onScreenFilterChange) {
			return (
				<View style={{ flex: 1 }}>
					<ScreenFilterbar
						type={type}
						activeFilters={activeScreenFilters ?? []}
						onChange={(filters, fieldName) => onScreenFilterChange({ filters, fieldName })}
						feature={feature}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderCollapseButton = () => {
		if (IS_WEB && (topChildren || activeScreenFilters) && onCollapse) {
			return (
				<ChildButton
					testID={`${testID}_button_collapse`}
					onPress={() => {
						onCollapse;
						setIsCollapsed(!isCollapsed);
					}}
					style={{
						position: 'absolute',
						right: 22,
						top: 15,
						zIndex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						paddingHorizontal: 10,
						paddingVertical: 5
					}}
				>
					<Icon color={theme.primary} name={isCollapsed ? EDefaultIconSet.ChevronDown : EDefaultIconSet.ChevronUp} size={20} />
				</ChildButton>
			);
		}

		return null;
	};

	const _renderSearchBar = () => {
		if (onSearchChange) {
			return (
				<View style={{ flex: 1 }}>
					<SearchBar testID={`${testID}_searchbar`} value={searchTerm} onChange={(val) => onSearchChange(val)} />
				</View>
			);
		}

		return null;
	};

	const _renderFilterModalButton = () => {
		if (type && onModalFilterChange) {
			return (
				<FilterButton
					testID={`${testID}_button_filter`}
					title={modalFilterTitle}
					type={type}
					activeFilters={activeModalFilters ?? {}}
					setActiveFilters={(val) => onModalFilterChange(val)}
					feature={feature}
				/>
			);
		}

		return null;
	};

	const _renderReload = () => {
		if (reload) {
			return (
				<ChildButton testID={`${testID}_button_reload`} onPress={reload} style={{ marginLeft: 10 }} isDisabled={isReloadDisabled}>
					<Icon name={EDefaultIconSet.Reload} color={theme.primary} />
				</ChildButton>
			);
		}

		return null;
	};

	return (
		<HSCard
			style={{ paddingVertical: hsInnerPadding }}
			onLayout={(e) => {
				if (onLayout) {
					onLayout(e);
				}
			}}
		>
			{_renderCollapseButton()}
			{topChildren}
			<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
				{_renderScreenFilter()}
				{_renderReload()}
			</View>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				{_renderSearchBar()}
				{_renderFilterModalButton()}
			</View>
		</HSCard>
	);
};
