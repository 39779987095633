import React from 'react';
import { TFunctionResult } from 'i18next';
import { TextStyle, TextProps } from 'react-native';

import { Text } from './Text';
import { hsTextBottomMarginSmall } from 'config/styleConstants';

interface IH2 {
	children: string | TFunctionResult;
	style?: TextStyle;
	center?: boolean;
	largerText?: boolean;
	noBottomMargin?: boolean;
}
export const H2 = (props: IH2 & TextProps) => {
	const { center, children, style, largerText, noBottomMargin } = props;

	return (
		<Text
			{...props}
			bold
			center={center}
			style={[{ fontSize: largerText ? 24 : 16, marginBottom: noBottomMargin ? 0 : hsTextBottomMarginSmall }, style]}
		>
			{children}
		</Text>
	);
};
