import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import { TContentType } from 'hooks/useContent';
import { useSpace } from 'hooks/useSpace';
import { isEmptyString } from 'helper';
import { SponsorGrid } from './SponsorGrid';
import { SponsorPages } from './SponsorPages';
import { SponsorScrolling } from './SponsorScrolling';
import { IExpo } from 'config/interfaces';
import { ERoutes } from 'components/Navigation/routes';

interface ISponsorBlock {
	contentType: TContentType | 'agenda' | 'sponsor' | 'attendees';
	height?: number;
}

export type SponsorViewType = 'none' | 'sponsorScrolling' | 'sponsorPages' | 'sponsorGrid';

export const SponsorBlock = (props: ISponsorBlock) => {
	const { contentType, height } = props;
	const navigation = useNavigation();
	const { activeSpace } = useSpace();

	const [viewType, setViewType] = useState<SponsorViewType>('none');

	useEffect(() => {
		if (!activeSpace || isEmptyString(contentType)) {
			return;
		}
		switch (contentType) {
			case 'attendees':
				setViewType(activeSpace?.sponsorTypeAttendees ?? 'none');
				break;
			case 'expo':
				setViewType(activeSpace?.sponsorTypeExpo ?? 'none');
				break;
			case 'feed':
				setViewType(activeSpace?.sponsorTypeFeed ?? 'none');
				break;
			case 'speaker':
				setViewType(activeSpace?.sponsorTypeSpeaker ?? 'none');
				break;
			case 'agenda':
			case 'schedule':
				setViewType(activeSpace?.sponsorTypeAgenda ?? 'none');
				break;
			case 'networkingroom':
				setViewType(activeSpace?.sponsorTypeNetworking ?? 'none');
				break;
			case 'voting':
				setViewType(activeSpace?.sponsorTypeVotings ?? 'none');
				break;
			case 'mediaitem':
				setViewType(activeSpace?.sponsorTypeMediaItem ?? 'none');
				break;
			case 'stream':
			default:
				break;
		}
	}, [contentType, activeSpace]);

	const onSponsorPress = (sponsor: IExpo) => {
		navigation.navigate(ERoutes.ExpoDetails, {
			id: sponsor?.id,
			spaceId: activeSpace?.spaceId
		});
	};

	switch (viewType) {
		case 'sponsorGrid':
			return <SponsorGrid height={height} handlePress={onSponsorPress} />;
		case 'sponsorPages':
			return <SponsorPages height={height} handlePress={onSponsorPress} />;
		case 'sponsorScrolling':
			return <SponsorScrolling height={height} handlePress={onSponsorPress} />;
		default:
			return null;
	}
};
