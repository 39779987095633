import React, { useRef, useState } from 'react';

import { Video as ExpoVideo, AVPlaybackStatus } from 'expo-av';
import { ViewStyle } from 'react-native';

interface IVideo {
	testID?: string;
	url: string;
	autoplay?: boolean;
	style: ViewStyle;
	controls?: boolean;
	repeat?: boolean;
}

export const Video = (props: IVideo) => {
	const { testID, url, autoplay, style, controls, repeat } = props;

	const videoRef = useRef<any>(null);

	const [status, setStatus] = useState<AVPlaybackStatus>({});

	if (url) {
		return (
			<ExpoVideo
				{...props}
				testID={testID}
				ref={videoRef}
				source={{ uri: url }}
				style={style}
				status={status}
				isLooping={repeat}
				onLoad={(e) => {
					if (!autoplay && videoRef.current) {
						setStatus(videoRef.current.pauseAsync());
					}

					if (autoplay) {
						setStatus(videoRef.current.playAsync());
					}
				}}
				resizeMode="contain"
				onPlaybackStatusUpdate={(status) => setStatus(() => status)}
				useNativeControls={props.hasOwnProperty('controls') ? controls : true}
			/>
		);
	}
	return null;
};
