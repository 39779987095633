import { HSCard, HSDragCard } from 'components/Card';
import { EDefaultIconSet, IS_ANDROID, IS_WEB, swapArrayItems } from 'helper';
import { IFormBase, TMedia } from 'config/interfaces';
import React, { useState } from 'react';
import { Vibration, View } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { FormError } from './FormError';
import { FormHint } from './FormHint';
import { FormLabel } from './FormLabel';
import { FormMediaPicker, IMAGE_ASPECT_RATIO_TOLERANCE, MAX_FILE_SIZE_IN_MB } from './Image';
import * as ImagePicker from 'expo-image-picker';
import { t } from 'i18next';
import { Spinner } from 'components/Spinner';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { useSpace } from 'hooks/useSpace';

interface IFormImageSelection extends IFormBase {
	media?: TMedia[];
	onChange: (value?: TMedia[]) => void;
	scrollEnabled?: boolean;
	mediaTypes: ImagePicker.MediaTypeOptions | 'files';
	limit?: number;
	ignoreAspectRatio?: boolean;
}

export const FormImageSelection = (props: IFormImageSelection) => {
	const { media, onChange, testID, label, isRequired, hint, error, isDisabled, scrollEnabled, mediaTypes, limit, ignoreAspectRatio } =
		props;
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasSizeError, setHasSizeError] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _removeImage = (index: number) => {
		const _options = media;
		_options?.splice(index, 1);
		onChange(_options);
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!media) {
			return;
		}
		const list: TMedia[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, media);

		onChange(list);
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!media) {
			return;
		}

		const list = [...media];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange(list);
	};

	const _renderDragImage = (params) => {
		const { item, index, drag, isActive } = params;

		if (item) {
			return (
				<HSDragCard
					testID={`${testID}_image_${index}`}
					isActive={isActive}
					onDrag={drag}
					index={index}
					handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
					onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
					onDownPress={index === (media?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				>
					<FormMediaPicker
						isDisabled={isDisabled}
						isOutline
						formStyle={{ flex: 1, marginBottom: 0 }}
						testID={`${testID}_imagepicker_image_${index}`}
						value={item}
						mediaTypes={mediaTypes ?? ImagePicker.MediaTypeOptions.All}
						onChange={(val) => {
							setHasSizeError(false);
							const _media = media ?? [];
							if (val) {
								_media[index] = val;
								onChange(_media);
							} else {
								_media?.splice(index, 1);
								onChange(_media);
							}
						}}
						hasDeleteButton
						onDelete={() => _removeImage(index)}
						deleteTitle={t('Delete Media')}
						deleteSubTitle={t('deleteMediaSubtitle')}
						allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
						onSizeError={() => setHasSizeError(true)}
						aspectRatio={'16/9'}
					/>
				</HSDragCard>
			);
		}

		return null;
	};

	return (
		<View>
			<FormLabel testID={`${testID}_label`} label={label} />
			<FormHint testID={`${testID}_hint`} hint={hint} />

			<DraggableFlatList
				scrollEnabled={scrollEnabled ?? true}
				data={media ?? []}
				renderItem={(params) => _renderDragImage(params)}
				keyExtractor={(item, index) => `${testID}_imagepicker_image_${index}`}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					onChange(data);
				}}
			/>
			{(error || hasSizeError) && (
				<View>
					<FormError testID={`${testID}_error`} error={error} />
					<FormError
						testID={`${testID}_size_error`}
						error={t('maxSizeError').replace('%SIZE%', MAX_FILE_SIZE_IN_MB.toString())}
					/>
				</View>
			)}
			{isLoading && (
				<HSCard>
					<Spinner />
				</HSCard>
			)}
			<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
				<FormMediaPicker
					setIsLoading={(val) => setIsLoading(val)}
					isDisabled={isDisabled || (limit && (media?.length ?? 0) >= limit ? true : false)}
					isRequired={isRequired}
					buttonOnly
					formStyle={{ flex: 1, marginBottom: 0 }}
					testID={`${testID}_imagepicker_image_newimage`}
					value={null}
					icon={EDefaultIconSet.Add}
					mediaTypes={mediaTypes ?? ImagePicker.MediaTypeOptions.All}
					onSizeError={() => setHasSizeError(true)}
					onChange={(val) => {
						setHasSizeError(false);

						if (typeof val === 'string' && !IS_WEB) {
							try {
								let img = new Image();
								img.src = val;
								img.onload = () => {
									const _valueAspect = img.width / img.height;
									if (ignoreAspectRatio || Math.abs(_valueAspect - 16 / 9) < IMAGE_ASPECT_RATIO_TOLERANCE) {
										const _media = media ?? [];
										_media.push(val);
										onChange(_media);
									} else {
										showAlert({
											title: t('wrongAspectRatio'),
											message: t('wrongAspectRatioHint').replace('%ASPECT', '16:9'),
											buttons: [
												{
													text: t('Cancel'),
													style: 'cancel'
												},
												{
													text: t('Continue'),
													style: 'destructive',
													onPress: async () => {
														const _media = media ?? [];
														if (val) {
															_media.push(val);
															onChange(_media);
														}
													}
												}
											]
										});
									}
								};
							} catch (error) {
								const _media = media ?? [];
								if (val) {
									_media.push(val);
									onChange(_media);
								}
							}
						} else {
							const _media = media ?? [];
							if (val) {
								_media.push(val);
								onChange(_media);
							}
						}
					}}
					allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
					ignoreAspectRatio={ignoreAspectRatio}
				/>
			</View>
		</View>
	);
};
