import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { Dispatch, useRematchDispatch } from 'rematch/store';
import { Keyboard, ScrollView, View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EditSpaceInitialFormValues, EMeetingType, IEditSpaceFormValues, IMeetingSettings } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { FormCalendarPicker, FormDatePicker, FormMultiSwitch } from 'components/Form';
import { FormElementBottomMargin, FormElementBottomMarginSmall } from 'components/Form/constants';
import moment from 'moment';
import { EDefaultIconSet } from 'helper';
import { FormHint } from 'components/Form/FormHint';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingSettings>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingSettings>;
type RouteParams = StackParamList[ERoutes.MeetingSettings];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'meetingsettings';

export const MeetingSettingsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);

	useEffect(() => {
		if (activeSpace) {
			const meetingSettings: IMeetingSettings = activeSpace.meetingSettings ?? {
				meetingDuration: 30,
				meetingType: 'onsiteandremote'
			};
			setFormValues({
				spaceId: activeSpace.spaceId,
				meetingSettings
			});
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace()
		});
	}, [formValues]);

	useEffect(() => {
		navigation.setOptions({
			isLoading: isSaveLoading
		});
	}, [isSaveLoading]);

	const _updateSpace = async () => {
		setIsSaveLoading(true);
		const updateSuccess = await updateSpace({ data: formValues });
		setIsSaveLoading(false);

		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _renderMeetingDateHint = () => {
		let str;
		if (formValues.meetingSettings?.allowMeetingsFrom && formValues.meetingSettings.allowMeetingsUntil) {
			str = t('MeetingAvailableFromUntil')
				.replace('%START%', moment(formValues.meetingSettings?.allowMeetingsFrom).format('DD.MM.YYYY'))
				.replace('%END%', moment(formValues.meetingSettings.allowMeetingsUntil).format('DD.MM.YYYY'));
		} else {
			str = t('MeetingAvailableFromUntil')
				.replace('%START%', moment(activeSpace?.startDate).format('DD.MM.YYYY'))
				.replace('%END%', moment(activeSpace?.endDate).format('DD.MM.YYYY'));
		}

		return (
			<View style={{ marginBottom: FormElementBottomMargin }}>
				<FormHint testID={`${TESTIDPREFIX}_hint_daterange`} hint={str} />
			</View>
		);
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard style={{ flex: 1 }}>
					<FormCalendarPicker
						formStyle={{ marginBottom: FormElementBottomMarginSmall }}
						testID={`${TESTIDPREFIX}_calendarpicker`}
						label={t('MeetingSettingsDateFromUntilLabel')}
						hint={t('MeetingSettingsDateFromUntil')}
						startDate={formValues.meetingSettings?.allowMeetingsFrom}
						endDate={formValues.meetingSettings?.allowMeetingsUntil}
						allowRangeSelection
						onStartDateChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.allowMeetingsFrom = val;
							updateFormValues(obj);
						}}
						onEndDateChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.allowMeetingsUntil = val;
							updateFormValues(obj);
						}}
						onReset={() => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.allowMeetingsFrom = undefined;
							obj.meetingSettings.allowMeetingsUntil = undefined;
							updateFormValues(obj);
						}}
					/>
					{_renderMeetingDateHint()}
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_meetinglength`}
						label={t('Meeting Duration')}
						value={formValues.meetingSettings?.meetingDuration?.toString()}
						onChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.meetingDuration = Number(val);
							updateFormValues(obj);
						}}
						options={[
							{
								key: '15',
								label: `15 ${t('Minutes')}`
							},
							{
								key: '20',
								label: `20 ${t('Minutes')}`
							},
							{
								key: '30',
								label: `30 ${t('Minutes')}`
							}
						]}
					/>
					<FormDatePicker
						testID={`${TESTIDPREFIX}_datepicker_start`}
						label={t('MettingSettingsTimeStart')}
						hint={t('MettingSettingsTimeStartHint')}
						mode="time"
						onChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.meetingEarliestStart = val;
							updateFormValues(obj);
						}}
						value={formValues.meetingSettings?.meetingEarliestStart}
						minuteInterval={15}
						onError={(error) => null}
					/>
					<FormDatePicker
						testID={`${TESTIDPREFIX}_datepicker_end`}
						label={t('MettingSettingsTimeEnd')}
						hint={t('MettingSettingsTimeEndHint')}
						mode="time"
						onChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.meetingLatestEnd = val;
							updateFormValues(obj);
						}}
						value={formValues.meetingSettings?.meetingLatestEnd}
						minuteInterval={15}
						onError={(error) => null}
					/>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_meetingtype`}
						label={t('MeetingTypes')}
						hint={t('MeetingTypesHint')}
						value={formValues.meetingSettings?.meetingType ?? 'onsiteandremote'}
						options={[
							{
								key: 'onsiteandremote',
								label: t('OnsiteAndRemote')
							},
							{
								key: EMeetingType.Onsite,
								label: t('OnsiteOnly')
							},
							{
								key: EMeetingType.Remote,
								label: t('RemoteOnly')
							}
						]}
						onChange={(val) => {
							const obj = { ...formValues };
							if (!obj.meetingSettings) {
								obj.meetingSettings = {};
							}
							obj.meetingSettings.meetingType = val;
							updateFormValues(obj);
						}}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const MeetingSettingsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Meeting Settings')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
