import React, { useState } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { Text } from 'components/Text';
import { Image } from 'components/Image';
import { ITutorialItem } from './Tutorial';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { normalizeFontSize } from 'helper';
import { Title } from 'components/Text/Title';
import { useHeaderHeight } from '@react-navigation/elements';

import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBlue, hsBorderRadius, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { IS_WEB } from 'helper/platform';

interface ITutorialItemComponent {
	item: ITutorialItem;
	containerWidth: number;
	webHeight?: number;
}

export const TutorialItem = (props: ITutorialItemComponent) => {
	const { item, containerWidth, webHeight } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const screenHeight = Dimensions.get('window').height;
	const headerHeight = useHeaderHeight();

	const [imageWidth, setImageWidth] = useState<number>();

	const _renderImage = () => {
		if (item.imageUrl) {
			return (
				<HSCard
					onLayout={(e) => {
						setImageWidth(e.nativeEvent.layout.width);
					}}
				>
					<Image
						url={item.imageUrl}
						style={{
							width: IS_WEB ? (imageWidth ?? 0) - hsInnerPadding * 2 : '100%',
							aspectRatio: 16 / 9,
							borderRadius: hsBorderRadius
						}}
						resizeMode="contain"
					/>
					{(item.headerText || item.description) && (
						<View
							style={{
								minHeight: IS_WEB ? 100 : 140,
								marginTop: 16
							}}
						>
							{item.headerText && (
								<Title center style={{ color: hsBlue }}>
									{item.headerText}
								</Title>
							)}
							{item.description && (
								<Text center style={{ marginTop: 0, fontSize: normalizeFontSize(20) }}>
									{item.description}{' '}
								</Text>
							)}
						</View>
					)}
				</HSCard>
			);
		}

		return null;
	};

	const _renderIcon = () => {
		if (item.icon) {
			return (
				<Icon
					name={item.icon}
					containerStyle={{ alignItems: 'center', justifyContent: 'center', marginTop: 16 }}
					color={theme.primary}
					size={normalizeFontSize(64)}
				/>
			);
		}
		return null;
	};

	const _renderChildren = () => {
		if (item?.children) {
			return <View>{item.children}</View>;
		}

		return null;
	};

	return (
		<View
			style={{
				width: containerWidth,
				paddingHorizontal: EHorizontalScreenPadding.Wide,
				paddingTop: hsTopScreenPadding,
				borderRadius: hsBorderRadius
			}}
		>
			{_renderImage()}
			{_renderIcon()}
			{_renderChildren()}
		</View>
	);
};
