import React, { ReactNode, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { ListItemTitle, Text } from 'components/Text';
import { Image, PlaceholderGradient } from 'components/Image';
import { ISpaceHistory } from 'rematch/interfaces';
import { ChildButton } from 'components/Button';
import moment from 'moment';
import { EDefaultIconSet, IS_WEB, normalizeFontSize } from 'helper';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { Icon } from 'components/Icon';
import { Spinner } from 'components/Spinner';

const placeholderSquare = require('../../../assets/placeholder-square.png');
const spaceDefaultBlue = require('../../assets/images/spaceDefault/spaceDefaultBlue.png');
const spaceDefaultRed = require('../../assets/images/spaceDefault/spaceDefaultRed.png');
const spaceDefaultGreen = require('../../assets/images/spaceDefault/spaceDefaultGreen.png');
const spaceDefaultPurple = require('../../assets/images/spaceDefault/spaceDefaultPurple.png');

interface ISpaceHistoryListItemComponent {
	item: ISpaceHistory;
	onItemPress: () => void;
	isLoading: boolean;
	containerStyle?: ViewStyle;
	childButtonStyle?: ViewStyle;
	isDescriptionVisible?: boolean;
	isDisabled?: boolean;
	onDeletePress?: () => void;
	children?: ReactNode | ReactNode[];
	imageOnly?: boolean;
}

export const SpaceHistoryListItem = (props: ISpaceHistoryListItemComponent) => {
	const {
		item,
		onItemPress,
		isLoading,
		containerStyle,
		isDescriptionVisible,
		isDisabled,
		onDeletePress,
		children,
		childButtonStyle,
		imageOnly
	} = props;
	const { theme } = useTheme();

	const [imageWidth, setImageWidth] = useState<number>(1);

	const _renderImageOrGradient = (src) => {
		if (item.space.previewImage) {
			return (
				<Image
					mediaObj={item.space.previewImage}
					imageSize={IS_WEB ? 'full' : 'small'}
					style={{
						width: imageWidth,
						aspectRatio: 16 / 9,
						marginRight: 10,
						borderRadius: hsBorderRadius,
						borderBottomRightRadius: imageOnly ? hsBorderRadius : 0,
						borderBottomLeftRadius: imageOnly ? hsBorderRadius : 0
					}}
					contentFit="cover"
					expectedRatio={16 / 9}
				/>
			);
		}

		if (item?.space?.showGradientOnMissingImage) {
			return (
				<PlaceholderGradient
					itemId={item?.space?.id}
					title={item?.space?.title}
					width={imageWidth}
					rounded={imageOnly ? 'full' : 'top'}
					primaryColor={item?.space?.primaryColor}
				/>
			);
		}

		return (
			<Image
				url={src}
				imageSize={IS_WEB ? 'full' : 'small'}
				style={{
					width: imageWidth,
					aspectRatio: 16 / 9,
					marginRight: 10,
					borderRadius: hsBorderRadius,
					borderBottomRightRadius: imageOnly ? hsBorderRadius : 0,
					borderBottomLeftRadius: imageOnly ? hsBorderRadius : 0
				}}
				expectedRatio={16 / 9}
			/>
		);
	};

	const _renderImage = () => {
		let src = placeholderSquare;

		switch (item.space?.previewImageName) {
			case 'spaceDefaultRed':
				src = spaceDefaultRed;
				break;
			case 'spaceDefaultGreen':
				src = spaceDefaultGreen;
				break;
			case 'spaceDefaultBlue':
				src = spaceDefaultBlue;
				break;
			case 'spaceDefaultPurple':
				src = spaceDefaultPurple;
				break;
			default:
				src = placeholderSquare;
				break;
		}

		return (
			<View
				style={{ position: 'relative', padding: 0, marginBottom: hsBottomMargin / 2 }}
				onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
			>
				<ChildButton
					style={{ flex: 1, position: 'relative' }}
					onPress={() => onItemPress()}
					testID={`spacehistory_list_${item.space.spaceId}_overlay`}
					onLayout={(e) => setImageWidth(e.nativeEvent.layout.width)}
					isDisabled={isDisabled}
				>
					{_renderImageOrGradient(src)}
				</ChildButton>
			</View>
		);
	};

	if (imageOnly) {
		return _renderImage();
	}

	return (
		<View style={[containerStyle, { flex: 1, marginBottom: hsBottomMargin }]}>
			<ChildButton
				testID={`spacehistory_list_${item?.space?.spaceId}`}
				onPress={() => onItemPress()}
				isDisabled={isDisabled}
				style={childButtonStyle ? childButtonStyle : {}}
			>
				<HSCard style={{ flex: 1, marginBottom: 0, padding: 0 }}>
					{_renderImage()}
					<View style={{ padding: hsInnerPadding, paddingTop: 0, flexDirection: 'row' }}>
						<View style={{ flex: 1 }}>
							<ListItemTitle>{item?.space?.title}</ListItemTitle>
							<Text style={{ marginBottom: 5, fontSize: normalizeFontSize(12) }}>
								{moment(item?.space?.startDate).isValid()
									? moment(item?.space?.startDate).format('DD.MM.YYYY') ==
									  moment(item?.space?.endDate).format('DD.MM.YYYY')
										? `${moment(item?.space?.startDate).format('DD.MM.YYYY')} | `
										: `${moment(item?.space?.startDate).format('DD.MM.YYYY')} - ${moment(item?.space?.endDate).format(
												'DD.MM.YYYY'
										  )} | `
									: ''}
								{item?.space?.spaceId}
							</Text>
							{isDescriptionVisible && <Text>{item?.space?.description}</Text>}
						</View>
						{onDeletePress &&
							(isLoading ? (
								<View>
									<Spinner size={15} color={theme.gray} />
								</View>
							) : (
								<ChildButton
									isDisabled={isLoading}
									testID={`spacehistory_list_${item?.space?.spaceId}_remove`}
									onPress={onDeletePress}
								>
									<Icon color={theme.gray} name={EDefaultIconSet.Delete} size={15} />
								</ChildButton>
							))}
					</View>
					{children}
				</HSCard>
			</ChildButton>
		</View>
	);
};
