import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import { IContentTypeField } from 'config/interfaces';
import { StepWizard } from 'components/StepWizard';
import { isEmptyString } from 'helper';
import { ContentTypeFieldsOptionsForm, ContentTypeFieldsMetaForm } from 'components/Forms/ContentType';
import { HSModal } from '../Modal';

interface IEditContentTypeFieldModal {
	field?: IContentTypeField;
	onChange: (field: IContentTypeField) => void;
	onClose: () => void;
	onSubmit: () => void;
	isUseAsListFilterDisabled?: boolean;
	contentType: string;
}

const TESTIDPREFIX = 'editcontenttypefieldmodal';

export const EditContentTypeFieldModal = (props: IEditContentTypeFieldModal) => {
	const { field, onClose, onSubmit, onChange, isUseAsListFilterDisabled, contentType } = props;
	const { t } = useTranslation();

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [currentStep, setCurrentStep] = useState<number>(0);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!field) {
			setErrors({});
		}
	}, [field]);

	const _updateField = (fieldName: string, value: any) => {
		if (field) {
			onChange({ ...field, [fieldName]: value });
			setIsDirty(true);
		}
	};

	const _isNextButtonDisabled = () => {
		switch (currentStep) {
			case 1:
				return _isCategoryInvalid();
			case 0:
				return isEmptyString(field?.fieldLabel);
			default:
				return true;
		}
	};

	const _getExtraStep = () => {
		if (field?.fieldType === 'category' || field?.fieldType === 'multiswitch') {
			return [
				<ContentTypeFieldsOptionsForm
					testID={TESTIDPREFIX}
					values={field}
					errors={errors}
					onChange={(field, value) => _updateField(field, value)}
					hideCountFields={field.fieldType !== 'category'}
				/>
			];
		}

		return [];
	};

	const _isCategoryInvalid = () => {
		if (field) {
			if (!field.options || field.options?.length === 0) {
				return true;
			}
			const foundIndex = field.options.findIndex((o) => isEmptyString(o.key) || isEmptyString(o.label));
			return foundIndex > -1;
		}
		return false;
	};

	return (
		<HSModal
			isVisible={field !== undefined}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={() => onSubmit()}
			isSubmitDisabled={
				!field?.fieldType ||
				isEmptyString(field?.fieldName) ||
				isEmptyString(field.fieldLabel) ||
				(field?.fieldType === 'category' && _isCategoryInvalid())
			}
			title={t('Edit Field')}
		>
			{field && (
				<StepWizard
					testIdPrefix={TESTIDPREFIX}
					getCurrentIndex={(val) => setCurrentStep(val)}
					completeFunction={() => onSubmit()}
					isLoading={false}
					nextButtonText={
						(field?.fieldType === 'category' || field?.fieldType === 'multiswitch') && currentStep < 1 ? t('Next') : t('Save')
					}
					components={[
						<ContentTypeFieldsMetaForm
							testID={TESTIDPREFIX}
							values={field}
							errors={errors}
							onChange={(field, value) => _updateField(field, value)}
							isUseAsListFilterDisabled={isUseAsListFilterDisabled}
							contentType={contentType}
						/>,
						..._getExtraStep()
					]}
					isNextButtonDisabled={_isNextButtonDisabled()}
				/>
			)}
		</HSModal>
	);
};
