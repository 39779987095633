import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { FormTextInput } from 'components/Form/FormTextInput';

interface IStreamInputExternalMeeting {
	testID: string;
	onChange: (externalMeetingUrl: string | null) => void;
	onTitleChange: (externalMeetingButtonText: string | null) => void;
	value: { externalMeetingButtonText: string; externalMeetingUrl: string };
	error?: string;
}

export const StreamInputExternalMeeting = (props: IStreamInputExternalMeeting) => {
	const { testID, onChange, value, error, onTitleChange } = props;
	const { t } = useTranslation();

	return (
		<View>
			<FormTextInput
				isRequired
				testID={`${testID}_textinput_buttontext`}
				onChangeText={(val) => onTitleChange(val)}
				label={t('externalMeetingButtonText')}
				hint={t('externalMeetingButtonTextHint')}
				value={value.externalMeetingButtonText}
			/>
			<FormTextInput
				isRequired
				testID={`${testID}_textinput_externalmeetingurl`}
				onChangeText={(val) => onChange(val)}
				label="URL"
				value={value.externalMeetingUrl}
				error={error}
			/>
		</View>
	);
};
