import { ChildButton } from 'components/Button';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet } from 'helper/icon';
import { IS_WEB } from 'helper/platform';
import { Animated, View, Text, Platform } from 'react-native';
import { Icon } from 'components/Icon';
import { NAVFONTSIZE, NAVICONSIZE } from 'components/Form/constants';
import { Dimensions } from 'react-native';

interface INavigationHeaderDropdown {
	options: any;
}

const TESTIDPREFIX = 'header';

export const NavigationHeaderDropdown = (props: INavigationHeaderDropdown) => {
	const { options } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const animatedRotation = useRef(new Animated.Value(0));
	const screenWidth = Dimensions.get('window').width;

	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		Animated.timing(animatedRotation.current, {
			toValue: isExpanded ? 90 : 0,
			duration: 300,
			useNativeDriver: true
		}).start();
	}, [isExpanded]);

	if (iAmSpaceAdmin || iAmSpaceModerator) {
		let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
		let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
		let color = primary === bg ? theme.navigationTextColor : theme.navbarButton;

		return (
			<View>
				<ChildButton
					testID={'header_button_toggle'}
					onPress={() => {
						if (options?.onRightNavPress) {
							options.onRightNavPress();
						}
						setIsExpanded(!isExpanded);
					}}
					style={{ padding: 5, flexDirection: 'row', alignItems: 'center' }}
				>
					{IS_WEB && Platform.OS !== 'android' && Platform.OS !== 'ios' && screenWidth >= 500 && (
						<Text
							style={{
								color: color,
								fontSize: NAVFONTSIZE,
								paddingHorizontal: 5
							}}
						>
							{t('Settings')}
						</Text>
					)}
					<Animated.View
						style={{
							transform: [
								{
									rotateZ: animatedRotation.current.interpolate({
										inputRange: [0, 90],
										outputRange: ['0deg', '90deg']
									})
								}
							]
						}}
					>
						<Icon name={EDefaultIconSet.Settings} color={color} size={NAVICONSIZE} />
					</Animated.View>
				</ChildButton>
			</View>
		);
	}

	return null;
};
