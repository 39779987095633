import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'rematch/store';
import { useSelector } from 'react-redux';
import { getCardContainerStyle, getReversedName, isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { FlashSectionList } from 'components/List';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { ISpeaker } from 'config/interfaces/speaker';
import { SpeakerListItem, SPEAKERLISTITEMENTRYHEIGHT } from './SpeakerListItem';
import { SpeakerListHeader } from './SpeakerListHeader';
import { HSCard } from 'components/Card/HSCard';
import { IActiveScreenFilter, IFeatureInfo } from 'config/interfaces';
import { SponsorBlock } from 'screens/Space/Sponsors';
import { useMatching } from 'hooks/useMatching';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { ScrollEvent, Size } from 'react-native-spring-scrollview';
import { View } from 'react-native';
import { filterListByFeature } from 'helper';
import { ContentCard, CONTENTCARD_HEIGHT } from 'components/Card';
import { useIsFocused } from '@react-navigation/native';

interface ISpeakerList {
	testID: string;
	selectedSpeakers?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: ISpeaker) => void;
	isLoading?: boolean;
	searchTerm: string;
	activeScreenFilter?: IActiveScreenFilter;
	activeModalFilters?: Record<string, string>;
	feature?: IFeatureInfo;
	onScroll: (e: ScrollEvent) => void;
	onContentSizeChange: (e: Size) => void;
	isMailReceiver?: boolean;
	noCard?: boolean;
}

interface ISpeakerData {
	header: string;
	items: ISpeaker[];
}

export const SpeakerList = (props: ISpeakerList) => {
	const {
		testID,
		selectedSpeakers,
		onSelectAll,
		onSelect,
		onEdit,
		onDelete,
		onPress,
		isLoading,
		searchTerm,
		activeScreenFilter,
		activeModalFilters,
		feature,
		onScroll,
		onContentSizeChange,
		isMailReceiver,
		noCard
	} = props;
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getMatchCount } = useMatching();
	const { screenColumnCountSmallItems } = useQuery();
	const isScreenFocused = useIsFocused();

	const [spaceSpeaker, setSpaceSpeaker] = useState<ISpeaker[]>([]);

	const [filteredSpeaker, setFilteredSpeaker] = useState<ISpeaker[]>([]);
	const [searchedSpeakers, setSearchedSpeakers] = useState<ISpeaker[]>([]);
	const [speakerSections, setSpeakerSections] = useState<(string | ISpeaker)[]>([]);
	const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
	const [numColumns, setNumColumns] = useState<number>(IS_WEB ? (screenColumnCountSmallItems ?? 3) + 1 : 2);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _numColumns = Math.floor(containerWidth / 220);

		if (_numColumns <= 2) _numColumns = 2;
		if (_numColumns >= 5) _numColumns = 5;
		if (numColumns !== _numColumns) setNumColumns(_numColumns);
	}, [containerWidth]);

	useEffect(() => {
		if (isDataLoading) {
			setTimeout(() => {
				setIsDataLoading(false);
			}, 30000);
		}
	}, []);

	useEffect(() => {
		if (activeSpace && content.speakers && isScreenFocused) {
			let _speakers: typeof spaceSpeaker = [];

			_speakers = content.speakers.filter(
				(e) => e.spaceId === activeSpace.spaceId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
			);

			setSpaceSpeaker(_speakers);
		}
	}, [activeSpace, content, iAmSpaceAdmin, iAmSpaceModerator, isScreenFocused]);

	useEffect(() => {
		let _filtered: typeof filteredSpeaker = [...spaceSpeaker];

		_filtered = filterListByFeature(_filtered, feature);

		if (activeScreenFilter) {
			if (activeScreenFilter.filters.includes('all')) {
				// Nothing to do
			} else if (activeScreenFilter.filters.includes('matchings')) {
				_filtered = _filtered.filter((e) => getMatchCount('speaker', e) > 0);
			} else {
				_filtered = _filtered.filter((e) => {
					if (activeScreenFilter.fieldName) {
						for (const catItemKey of activeScreenFilter.filters) {
							if (e[activeScreenFilter.fieldName]) {
								const itemSplit = e[activeScreenFilter.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
			}
		}

		if (activeModalFilters && Object.keys(activeModalFilters).length > 0) {
			_filtered = _filtered.filter((e) => {
				const catKeys = Object.keys(activeModalFilters);
				for (const catKey of catKeys) {
					const splitted = activeModalFilters[catKey].split(',');
					for (const catItemKey of splitted) {
						if (e[catKey]) {
							const itemSplit = e[catKey].split(',');
							if (itemSplit.includes(catItemKey)) {
								return true;
							}
						}
					}
				}

				return false;
			});
		}

		setFilteredSpeaker(_filtered);
	}, [activeSpace, spaceSpeaker, activeModalFilters, activeScreenFilter]);

	useEffect(() => {
		let _searched: typeof filteredSpeaker = [...filteredSpeaker];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter((item) => {
				let allCategoryString = '';
				for (let index = 1; index <= 10; index++) {
					const element = item[`category${index}`];
					allCategoryString = allCategoryString.concat(element);
				}
				return (
					item.title.toLowerCase().includes(lowerSearch) ||
					item.bio?.toLowerCase().includes(lowerSearch) ||
					item.jobTitle?.toLowerCase().includes(lowerSearch) ||
					item.company?.toLowerCase().includes(lowerSearch) ||
					allCategoryString?.toLowerCase().includes(lowerSearch)
				);
			});
		}

		_searched.sort((a, b) => (getReversedName(a.title).toLowerCase() < getReversedName(b.title).toLowerCase() ? -1 : 1));

		setSearchedSpeakers(_searched);
	}, [filteredSpeaker, searchTerm]);

	useEffect(() => {
		const sections: ISpeakerData[] = [];
		let newSections: typeof speakerSections = [];

		searchedSpeakers.forEach((item) => {
			let header;

			if (activeScreenFilter?.filters.includes('matchings')) {
				const matchCount = getMatchCount('speaker', item);
				header = `${matchCount} ${matchCount === 1 ? 'Match' : 'Matchings'}`;
			} else {
				header = getReversedName(item.title).charAt(0).toUpperCase();
			}

			const found = sections.find((e) => e.header === header);
			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: header,
					items: [item]
				});
			}
		});

		if (activeScreenFilter?.filters.includes('matchings')) {
			sections.sort((a, b) => (Number(a.header.split(' ')[0]) > Number(b.header.split(' ')[0]) ? -1 : 1));
		} else {
			sections.sort((a, b) => (a.header.toLowerCase() < b.header.toLowerCase() ? -1 : 1));
		}

		sections.forEach((section) => {
			newSections = newSections.concat([section.header, ...section.items]);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setSpeakerSections(newSections);
	}, [searchedSpeakers]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedSpeakers.length > 0) {
			if (IS_WEB ? activeSpace?.webLayout === 'list' : activeSpace?.appLayout === 'list') {
				return (
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 10, paddingHorizontal: IS_WEB ? hsInnerPadding : 0, paddingBottom: 0 }}
						value={selectedSpeakers && selectedSpeakers.length === searchedSpeakers.length}
						onPress={() => {
							if (selectedSpeakers && selectedSpeakers.length === searchedSpeakers.length) {
								onSelectAll([]);
							} else {
								onSelectAll(searchedSpeakers.map((sp) => sp.id));
							}
						}}
					/>
				);
			}

			return (
				<HSCard>
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedSpeakers && selectedSpeakers.length === searchedSpeakers.length}
						onPress={() => {
							if (selectedSpeakers && selectedSpeakers.length === searchedSpeakers.length) {
								onSelectAll([]);
							} else {
								onSelectAll(searchedSpeakers.map((sp) => sp.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderSpeakerList = () => {
		let _hideIndexButtons = activeScreenFilter?.filters?.includes('matchings') ?? false;
		let _numberOfColumns = 1;
		let _estimatedSize = SPEAKERLISTITEMENTRYHEIGHT;
		let _data = speakerSections;
		const _viewType = isMailReceiver ? 'list' : IS_WEB ? activeSpace?.webLayout : activeSpace?.appLayout;

		switch (_viewType) {
			case 'cards':
				_hideIndexButtons = searchedSpeakers.length < 50;
				_numberOfColumns = numColumns;
				_estimatedSize = CONTENTCARD_HEIGHT;
				// This is done to prevent empty cells
				_data = searchedSpeakers;
				break;
			case 'list':
			default:
				// See default values above
				break;
		}

		return (
			<FlashSectionList
				data={_data}
				estimatedItemSize={_estimatedSize}
				extraData={[onSelect, selectedSpeakers]}
				numColumns={_numberOfColumns}
				showSectionButtonsOnCard={_viewType === 'cards'}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				sectionData={speakerSections?.filter((e) => typeof e === 'string') as string[]}
				reverseTitleOnSectionButtonPress
				renderItem={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return (
								<View
									style={getCardContainerStyle(_numberOfColumns, renderItem.index)}
									key={`${testID}_button_speaker_${renderItem.index}`}
								>
									<ContentCard
										testID={testID}
										item={renderItem.item}
										contentType="speaker"
										isLoading={isLoading}
										isSelected={selectedSpeakers?.includes(renderItem.item.id)}
										onPress={() => {
											if (onPress) {
												onPress(renderItem.item);
											}
										}}
										onSelect={
											onSelect
												? () => {
														onSelect(renderItem.item.id);
												  }
												: undefined
										}
										onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
										onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
										index={renderItem.index}
									/>
								</View>
							);
						case 'list':
						default:
							return (
								<SpeakerListItem
									testID={`${testID}_button_speaker_${renderItem.index}`}
									isLoading={isLoading}
									item={renderItem.item}
									onPress={() => {
										if (onPress) {
											onPress(renderItem.item);
										}
									}}
									isSelected={selectedSpeakers?.includes(renderItem.item.id)}
									onSelect={
										onSelect
											? () => {
													onSelect(renderItem.item.id);
											  }
											: undefined
									}
									onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
									onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
									isMailReceiver={isMailReceiver}
								/>
							);
					}
				}}
				renderSectionHeader={(renderItem) => {
					switch (_viewType) {
						case 'cards':
							return null;
						case 'list':
						default:
							return <SpeakerListHeader text={renderItem.item} />;
					}
				}}
				onScroll={onScroll}
				onContentSizeChange={(width, height) => {
					if (onContentSizeChange) {
						onContentSizeChange({ width, height });
					}
				}}
				hideIndexButtons={_hideIndexButtons}
			/>
		);
	};

	const _renderList = () => {
		if (searchedSpeakers.length === 0 && (!isDataLoading || !isEmptyString(searchTerm))) {
			if (
				!isEmptyString(searchTerm) ||
				!activeScreenFilter?.filters?.includes('all') ||
				(activeModalFilters && Object.keys(activeModalFilters).length > 0)
			) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoSpeakers" />;
		}

		if (noCard) {
			return (
				<View
					style={{
						flex: 1,
						marginBottom: activeSpace?.sponsorTypeSpeaker?.startsWith('sponsor')
							? EHorizontalScreenPadding.Wide
							: hsBottomMargin
					}}
				>
					{_renderSelectAll()}
					{_renderSpeakerList()}
				</View>
			);
		}

		return (
			<HSCard
				style={{
					flex: 1,
					marginBottom: activeSpace?.sponsorTypeSpeaker?.startsWith('sponsor') ? EHorizontalScreenPadding.Wide : hsBottomMargin,
					paddingLeft: IS_WEB ? 0 : hsInnerPadding,
					paddingRight: 0
				}}
			>
				{_renderSelectAll()}
				{_renderSpeakerList()}
			</HSCard>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderList()}
			<View style={{ width: '100%', alignSelf: 'center' }}>
				<SponsorBlock contentType={'speaker'} />
			</View>
		</View>
	);
};
