import { MediaItemForm } from 'components/Forms/MediaItem';
import { IContentTypeField, ILauncherSpace } from 'config/interfaces';
import { hsInnerPadding } from 'config/styleConstants';
import { validateForm } from 'helper';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';

export interface ITempReference {
	itemId?: number | string;
	fieldName: string;
}

interface ITempReferenceModal {
	tempReference?: ITempReference;
	onClose: () => void;
	onSubmit: (item: any) => void;
	contentType?: string;
	space?: ILauncherSpace;
	itemId: number;
	isSelfService?: boolean;
}

const TESTIDPREFIX = 'tempreferencemodal';

export const TempReferenceModal = (props: ITempReferenceModal) => {
	const { tempReference, onClose, onSubmit, contentType, space, itemId, isSelfService } = props;
	const { t } = useTranslation();
	const { getFormSchema } = useForm();
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');

	const [formValues, setFormValues] = useState<any>({});
	const [formErrors, setFormErrors] = useState<Record<string, any>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const createMediaItem = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMediaItem);
	const updateMediaItem = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMediaItem);
	const addContent = useRematchDispatch((dispatch: Dispatch) => dispatch.content.addContent);

	useEffect(() => {
		let _values: typeof formValues = {};

		if (tempReference && content) {
			switch (tempReference.fieldName) {
				case 'mediaitems':
					if (content.mediaitems) {
						_values = tempReference.itemId ? content.mediaitems.find((e) => e.id === tempReference.itemId) : undefined;

						if (_values) {
							_values.speakers = _values.speakers ? _values.speakers.filter((e) => e).map((sp) => sp.id) : [];
							_values.expos = _values.expos ? _values.expos.filter((e) => e).map((sp) => sp.id) : [];
							_values.streams = _values.streams ? _values.streams.filter((e) => e).map((sp) => sp.id) : [];
						} else {
							_values = {};
						}
					}
					break;
				default:
					break;
			}
		} else {
			setFormErrors({});
		}

		setFormValues(_values);
	}, [tempReference, content]);

	const _onSave = async () => {
		if (tempReference && contentType) {
			let fields: IContentTypeField[] = [];

			switch (tempReference?.fieldName) {
				case 'mediaitems':
					fields = getMediaItemFields(undefined, undefined, space);
					break;
				default:
					break;
			}

			const errors = await validateForm(getFormSchema(fields, formValues), formValues);

			if (errors) {
				setFormErrors(errors);
				return;
			} else {
				setFormErrors({});
			}

			setIsSubmitLoading(true);

			let res;
			let field: string | undefined = undefined;
			switch (contentType) {
				case 'expo':
					field = 'expos';
					break;
				case 'speaker':
					field = 'speakers';
					break;
				default:
					break;
			}

			if (formValues.id) {
				const obj = {
					...formValues
				};
				if (field) {
					if (!obj[field]) {
						obj[field] = [itemId];
					} else if (!obj[field].includes(itemId)) {
						obj[field].push(itemId);
					}
				}
				res = await updateMediaItem({ fields, mediaitem: obj, space });
			} else {
				const obj = { ...formValues };
				if (field) {
					obj[field] = [itemId];
				}
				res = await createMediaItem({ fields, mediaitem: obj, space });
			}

			setIsSubmitLoading(false);
			if (res) {
				addContent({ load: false, content: [{ type: 'mediaitems', items: [res] }] });
				onSubmit(res);
			}
		}
	};

	const _renderForm = () => {
		switch (tempReference?.fieldName) {
			case 'mediaitems':
				return (
					<MediaItemForm
						values={formValues}
						errors={formErrors}
						onChange={(val) => setFormValues(val)}
						isLoading={isSubmitLoading}
						hiddenFields={contentType === 'expo' ? ['expos'] : contentType === 'speaker' ? ['speakers'] : undefined}
						isSelfService={isSelfService}
						space={space}
						targetType={contentType}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<HSModal isVisible={tempReference !== undefined} onClose={onClose} onSubmit={_onSave} isSubmitLoading={isSubmitLoading}>
			<ScrollView
				style={{ marginHorizontal: -hsInnerPadding }}
				contentContainerStyle={{
					paddingHorizontal: hsInnerPadding
				}}
			>
				{_renderForm()}
			</ScrollView>
		</HSModal>
	);
};
