import React, { useEffect, useRef, useState } from 'react';
import { Animated, ScrollView, View, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import Flag from 'react-native-flags';

import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from './constants';
import { useTheme } from 'hooks/useTheme';
import { IKVP } from './FormMultiSwitch';
import { hsBottomMargin } from 'config/styleConstants';
import { FormField } from './FormField';
import { EDefaultIconSet, getCountries } from 'helper';
import { Icon } from 'components/Icon';
import { FormTextInput } from './FormTextInput';

interface IDropdown {
	testID: string;
	options?: IKVP[];
	onSelect: (val: string | null) => void;
	placeholder?: string;
	value?: string;
	isDisabled?: boolean;
	multi?: boolean;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	formStyle?: ViewStyle;
	search?: boolean;
	selectionLimit?: number;
}

interface ICountryOption {
	code: string;
	name: string;
}

export const CountryDropdown = (props: IDropdown) => {
	const { testID, onSelect, placeholder, value, isDisabled, multi, label, isRequired, hint, formStyle, search, selectionLimit } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [searchedOptions, setSearchedOptions] = useState<ICountryOption[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const animatedRotation = useRef(new Animated.Value(0));

	useEffect(() => {
		let _options = getCountries();

		if (searchTerm.length > 2) {
			_options = _options.filter((o) => {
				const lowerSearch = searchTerm.toLowerCase();
				return o.name.toLowerCase().includes(lowerSearch);
			});
		}

		setSearchedOptions(_options);
	}, [searchTerm]);

	useEffect(() => {
		Animated.timing(animatedRotation.current, {
			toValue: isExpanded ? 90 : 0,
			duration: 300,
			useNativeDriver: true
		}).start();
	}, [isExpanded]);

	const _renderOptions = () => {
		if (isExpanded) {
			return (
				<View
					style={{
						marginBottom: hsBottomMargin,
						borderWidth: FORMELEMENTBORDERWIDTH,
						borderRadius: FORMELEMENTBORDERRADIUS,
						borderColor: theme.formgray,
						padding: 5,
						maxHeight: 300
					}}
				>
					{search && (
						<FormTextInput
							testID={`${testID}_searchbar`}
							formStyle={{ marginBottom: 10 }}
							onChangeText={(e) => setSearchTerm(e)}
							value={searchTerm}
						/>
					)}
					<ScrollView nestedScrollEnabled>
						{searchedOptions?.map((option, idx) => {
							let _values = value ? value?.split(',') : [];
							let _isDisabled = selectionLimit !== undefined && _values.length >= selectionLimit;

							return (
								<ChildButton
									testID={`${testID}_button_option_${idx}`}
									key={`${testID}_button_option_${idx}`}
									onPress={() => {
										if (multi) {
											if (!option.code) {
												onSelect(null);
											} else {
												if (_values.includes(option.code)) {
													_values = _values.filter((e) => e !== option.code);
												} else {
													if (_isDisabled) {
														return;
													}
													_values.push(option.code);
												}
												onSelect(_values.join(','));
											}
										} else {
											onSelect(option.code);
											setIsExpanded(false);
										}
									}}
									isDisabled={_isDisabled && !_values.includes(option.code)}
									style={{ marginBottom: 5, flexDirection: 'row', flexWrap: 'wrap' }}
								>
									{multi ? (
										<>
											<View
												style={{
													height: 20,
													width: 20,
													borderWidth: 1,
													justifyContent: 'center',
													alignItems: 'center'
												}}
											>
												{option.code && value?.includes(option.code) ? <Text>X</Text> : null}
											</View>
											<Flag code={option.code} size={32} style={{ marginLeft: 10, marginTop: -5 }} />
											<Text style={{ marginLeft: 5, marginTop: 2 }}>{option.name}</Text>
										</>
									) : (
										<Text>{`- ${option.name}`}</Text>
									)}
								</ChildButton>
							);
						})}
					</ScrollView>
				</View>
			);
		}

		return null;
	};

	const _renderValue = () => {
		let label = '';
		if (value) {
			const options = getCountries();
			if (multi) {
				const _val = value.split(',');
				const _options: string[] = [];
				_val.forEach((key) => {
					const option = options?.find((e) => e.code === key);
					if (option) {
						_options.push(option.name);
					}
				});
				label = _options.join(', ');
			} else {
				const option = options?.find((e) => e.code === value);
				if (option) {
					label = option.name;
				}
			}

			return <Text>{label}</Text>;
		}

		return <Text style={{ color: isDisabled ? theme.text : theme.formgray }}>{placeholder ?? t('DropdownPlaceholder')}</Text>;
	};

	return (
		<FormField testID={testID} label={label} hint={hint} isRequired={isRequired} formStyle={formStyle}>
			<ChildButton
				testID={`${testID}_button_expand`}
				onPress={() => setIsExpanded(!isExpanded)}
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: 10,
					padding: 5,
					backgroundColor: isDisabled ? theme.lightgray : theme.contentBackgroundColor ?? theme.background,
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderColor: theme.formgray
				}}
				isDisabled={isDisabled}
			>
				{_renderValue()}
				<Animated.View
					style={{
						transform: [
							{
								rotateZ: animatedRotation.current.interpolate({
									inputRange: [0, 90],
									outputRange: ['0deg', '90deg']
								})
							}
						]
					}}
				>
					<Icon name={EDefaultIconSet.ChevronRight} />
				</Animated.View>
			</ChildButton>

			{_renderOptions()}
		</FormField>
	);
};
