import React from 'react';
import { IEditSpaceFormErrors, IEditSpaceFormValues } from 'config/interfaces';
import { FormMultiSwitch } from 'components/Form';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
interface IEditSpaceSponsorForm {
	values: IEditSpaceFormValues;
	errors: IEditSpaceFormErrors;
	onChange: (val: IEditSpaceFormValues) => void;
	isLoading?: boolean;
}

const TESTIDPREFIX = 'editspace';

export const EditSpaceSponsorForm = (props: IEditSpaceSponsorForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { t } = useTranslation();

	const _getSponsorOptions = () => {
		return [
			{
				key: 'none',
				label: t('dontShowSponsors')
			},
			{
				key: 'sponsorScrolling',
				label: t('sponsorScrolling')
			},
			{
				key: 'sponsorPages',
				label: t('sponsorPages')
			}
		];
	};

	return (
		<View>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypefeed`}
				label={t('sponsorTypeFeed')}
				hint={t('sponsorTypeFeedHint')}
				value={values.sponsorTypeFeed}
				error={errors.sponsorTypeFeed}
				onChange={(value) => onChange({ ...values, sponsorTypeFeed: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypeagenda`}
				label={t('sponsorTypeAgenda')}
				hint={t('sponsorTypeAgendaHint')}
				value={values.sponsorTypeAgenda}
				error={errors.sponsorTypeAgenda}
				onChange={(value) => onChange({ ...values, sponsorTypeAgenda: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypeexpo`}
				label={t('sponsorTypeExpo')}
				hint={t('sponsorTypeExpoHint')}
				value={values.sponsorTypeExpo}
				error={errors.sponsorTypeExpo}
				onChange={(value) => onChange({ ...values, sponsorTypeExpo: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypeattendees`}
				label={t('sponsorTypeAttendees')}
				hint={t('sponsorTypeAttendeesHint')}
				value={values.sponsorTypeAttendees}
				error={errors.sponsorTypeAttendees}
				onChange={(value) => onChange({ ...values, sponsorTypeAttendees: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypenetworking`}
				label={t('sponsorTypeNetworking')}
				hint={t('sponsorTypeNetworkingHint')}
				value={values.sponsorTypeNetworking}
				error={errors.sponsorTypeNetworking}
				onChange={(value) => onChange({ ...values, sponsorTypeNetworking: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypevotings`}
				label={t('sponsorTypeVotings')}
				hint={t('sponsorTypeVotingsHint')}
				value={values.sponsorTypeVotings}
				error={errors.sponsorTypeVotings}
				onChange={(value) => onChange({ ...values, sponsorTypeVotings: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypespeaker`}
				label={t('sponsorTypeSpeaker')}
				hint={t('sponsorTypeSpeakerHint')}
				value={values.sponsorTypeSpeaker}
				error={errors.sponsorTypeSpeaker}
				onChange={(value) => onChange({ ...values, sponsorTypeSpeaker: value })}
				options={_getSponsorOptions()}
			/>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_sponsortypemediaitem`}
				label={t('sponsorTypeMediaItem')}
				hint={t('sponsorTypeMediaItemHint')}
				value={values.sponsorTypeMediaItem}
				error={errors.sponsorTypeMediaItem}
				onChange={(value) => onChange({ ...values, sponsorTypeMediaItem: value })}
				options={_getSponsorOptions()}
			/>
		</View>
	);
};
