import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { INetworkingRoom } from 'config/interfaces';
import { useSpace } from 'hooks/useSpace';
import { H1, Text } from 'components/Text';
import { ChildButton } from 'components/Button';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { Image } from 'components/Image';
import { hsBorderRadius } from 'config/styleConstants';
import { getDefaultAspectRatioStyle } from 'helper';

interface IStreamInputNetworking {
	testID: string;
	onChange: (networkingRoomId: number | null) => void;
	value?: number;
	error?: string;
}

export const StreamInputNetworking = (props: IStreamInputNetworking) => {
	const { testID, onChange, value, error } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const IMAGEWIDTH = 80;

	const [networkingRooms, setNetworkingRooms] = useState<INetworkingRoom[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _networkingrooms: typeof networkingRooms = [];
		if (content?.networkingrooms?.length > 0) {
			_networkingrooms = content.networkingrooms.filter(
				(n: INetworkingRoom) => !n.isDeleted && n.spaceId === activeSpace?.spaceId && !n.isMeetingRoom
			);
		}
		setNetworkingRooms([..._networkingrooms]);
	}, [activeSpace, content]);

	return (
		<View>
			<H1>{t('selectNetworkingRoom')}</H1>
			{networkingRooms.map((room) => {
				const selected = room.id === value;

				return (
					<ChildButton
						testID={`${testID}_button_networkingroomid`}
						onPress={() => onChange(room.id)}
						style={{ flexDirection: 'row', marginBottom: 5, alignItems: 'center' }}
					>
						<FormCheckbox testID={`${testID}_checkbox_${room.id}`} value={selected} style={{ marginBottom: 0 }} />
						<Image
							mediaObj={room.overviewImage}
							imageSize="thumbnail"
							style={{ ...getDefaultAspectRatioStyle(IMAGEWIDTH), borderRadius: hsBorderRadius }}
						/>
						<View style={{ marginLeft: 10, marginTop: -2 }}>
							<Text bold>{room.title}</Text>
							<Text>
								{t('capacity')}: {room.capacity}
							</Text>
						</View>
					</ChildButton>
				);
			})}
		</View>
	);
};
