import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';
import { FlashSectionList } from 'components/List';
import { NoData } from 'components/NoData';
import { SearchBar } from 'components/SearchBar';
import { IStream } from 'config/interfaces';
import { hsBottomMargin } from 'config/styleConstants';
import { isEmptyString } from 'helper';
import { useSpace } from 'hooks/useSpace';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { StreamListItem, STREAMLISTITEMENTRYHEIGHT } from '.';
import { StreamListHeader } from './StreamListHeader';

interface IStreamList {
	testID: string;
	selectedStreams?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: IStream) => void;
	isLoading?: boolean;
	noCard?: boolean;
}

interface IStreamData {
	header: string;
	items: IStream[];
}

export const StreamList = (props: IStreamList) => {
	const { testID, selectedStreams, onSelectAll, onSelect, onEdit, onDelete, onPress, isLoading, noCard } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [streams, setStreams] = useState<IStream[]>([]);
	const [searchedStreams, setSearchedStreams] = useState<IStream[]>([]);
	const [streamSections, setStreamSections] = useState<(string | IStream)[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const content = useSelector((store: IRootState) => store.content.content);

	const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

	useEffect(() => {
		if (isDataLoading) {
			setTimeout(() => {
				setIsDataLoading(false);
			}, 30000);
		}
	}, []);

	useEffect(() => {
		let _streams: typeof streams = [];

		if (content.streams) {
			_streams = content.streams.filter((e) => e.spaceId === activeSpace?.spaceId);
		}

		setStreams(_streams);
	}, [content, activeSpace]);

	useEffect(() => {
		let _searched = [...streams];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter((item) => item.title.toLowerCase().includes(lowerSearch));
		}

		setSearchedStreams(_searched);
	}, [streams, searchTerm]);

	useEffect(() => {
		const sections: IStreamData[] = [];
		let newSections: typeof streamSections = [];

		searchedStreams.forEach((item) => {
			let header = '';
			switch (item.streamType) {
				case 'contentflow':
					header = 'Contentflow';
					break;
				case 'iFrame':
					header = 'iFrame';
					break;
				case 'mediaLibrary':
					header = t('Media Items');
					break;
				case 'upload':
					header = 'Upload';
					break;
				case 'vimeo':
					header = 'Vimeo';
					break;
				case 'youtube':
					header = 'YouTube';
					break;
				default:
					break;
			}

			const found = sections.find((e) => e.header === header);
			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: header,
					items: [item]
				});
			}
		});

		sections.sort((a, b) => (a.header.toLowerCase() < b.header.toLowerCase() ? -1 : 1));

		sections.forEach((section) => {
			section.items = section.items.filter((s) => s.title !== '' && s.title !== null);
			section.items.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));

			newSections.push(section.header);
			newSections = newSections.concat(section.items);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setStreamSections(newSections);
	}, [searchedStreams]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedStreams.length > 0) {
			return (
				<FormCheckbox
					testID={`${testID}_checkbox_selectall`}
					label={t('SelectAll')}
					style={{ marginBottom: 10 }}
					value={selectedStreams && selectedStreams.length === searchedStreams.length}
					onPress={() => {
						if (selectedStreams && selectedStreams.length === searchedStreams.length) {
							onSelectAll([]);
						} else {
							onSelectAll(searchedStreams.map((ex) => ex.id));
						}
					}}
				/>
			);
		}

		return null;
	};

	const _renderList = () => {
		if (streams.length === 0 && (!isDataLoading || !isEmptyString(searchTerm))) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoStreams" />;
		}

		return (
			<View style={{ flex: 1 }}>
				{_renderSelectAll()}
				<FlashSectionList
					data={streamSections}
					extraData={[onSelect, selectedStreams]}
					renderItem={(renderItem) => (
						<StreamListItem
							isLoading={isLoading}
							testID={`${testID}_button_stream_${renderItem.index}`}
							item={renderItem.item}
							onPress={() => {
								if (onPress) {
									onPress(renderItem.item);
								}
							}}
							isSelected={selectedStreams?.includes(renderItem.item.id)}
							onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
							onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
							onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
						/>
					)}
					renderSectionHeader={(renderItem) => <StreamListHeader text={renderItem.item} />}
					estimatedItemSize={STREAMLISTITEMENTRYHEIGHT}
					hideIndexButtons
				/>
			</View>
		);
	};

	const _renderHeader = () => {
		if (noCard) {
			return <View style={{ flexDirection: 'row', alignItems: 'center' }}>{_renderSearch()}</View>;
		}

		return <HSCard style={{ flexDirection: 'row', alignItems: 'center' }}>{_renderSearch()}</HSCard>;
	};

	const _renderSearch = () => {
		return (
			<View style={{ flex: 1, marginBottom: hsBottomMargin }}>
				<SearchBar testID={`${testID}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
			</View>
		);
	};

	const _renderContent = () => {
		if (noCard) {
			return _renderList();
		}

		return <HSCard style={{ flex: 1 }}>{_renderList()}</HSCard>;
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderHeader()}
			{_renderContent()}
		</View>
	);
};
