import { RoundButton } from 'components/Button';
import { IFormBase, IStream, TMedia, TPlayableMediaType } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, IS_ANDROID, swapArrayItems } from 'helper';
import React, { useEffect, useState } from 'react';
import { FormField } from '../FormField';
import { AddStreamModal } from './StreamModal/AddStreamModal';
import { UpdateStreamModal } from './StreamModal/UpdateStreamModal';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Vibration, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { StreamListItem } from 'components/Stream/StreamListItem';
import { isEmptyObject } from 'helper/object';
import { useSpace } from 'hooks/useSpace';
import { HSDragCard } from 'components/Card/HSDragCard';
import DraggableFlatList from 'react-native-draggable-flatlist';
interface IFormStreamSelect extends IFormBase {
	value?: number | number[] | IStream[];
	objectValue?: IMediaObject;
	onChange?: (streamIds: number[] | IMediaObject | null) => void;
	createMediaJson?: boolean;
	createStreamJson?: boolean;
	hideEditWarning?: boolean;
	hideMediaItem?: boolean;
	isSelfService?: boolean;
}

export interface IMediaObject {
	iFrameUrl: string;
	isAutoplay: boolean;
	isLoop: boolean;
	isMute: boolean;
	streamType: TPlayableMediaType;
	title: string;
	contentflowUrl?: string;
	youTubeUrl?: string;
	vimeoUrl?: string;
	media?: TMedia;
	externalMeetingUrl?: string;
	externalMeetingButtonText?: string;
}

export const FormStreamSelect = (props: IFormStreamSelect) => {
	const {
		testID,
		label,
		isRequired,
		hint,
		error,
		value,
		onChange,
		createMediaJson,
		createStreamJson,
		objectValue,
		hideEditWarning,
		hideMediaItem,
		isSelfService,
		isDisabled
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [showAddMediaModal, setShowAddMediaModal] = useState<boolean>(false);
	const [showUpdateStreamModal, setShowUpdateStreamModal] = useState<number | undefined>(undefined);
	const [streams, setStreams] = useState<IStream[] | IMediaObject[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		let _streams: typeof streams = [];
		if (activeSpace && value) {
			if (content.streams && value) {
				if (Array.isArray(value)) {
					value.forEach((v) => {
						const found = content.streams.find((e) => e.spaceId === activeSpace.spaceId && (e.id === v || e.id === v?.id));
						if (found) {
							_streams.push(found);
						}
					});
				} else if (typeof value === 'number') {
					const found = content.streams.find((e) => e.spaceId === activeSpace.spaceId && e.id === value);
					if (found) {
						_streams.push(found);
					}
				}
			}
		}
		if (objectValue) {
			Object.keys(objectValue).forEach((key) => {
				if (objectValue[key] === undefined || objectValue[key] === null || isEmptyString(objectValue[key])) {
					delete objectValue[key];
				}
			});
			if (!isEmptyObject(objectValue)) {
				_streams.push({ ...objectValue });
			}
		}

		setStreams(_streams);
	}, [activeSpace, value, objectValue]);

	const _removeStream = (index: number) => {
		if (Array.isArray(streams)) {
			const _options = [...streams];
			_options?.splice(index, 1);
			handleChange(_options);
		}
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (Array.isArray(streams)) {
			const _options = [...streams];
			const list: IStream[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, _options);
			handleChange(list);
		}
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		const list = [...streams] as IStream[];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		handleChange(list);
	};

	const _renderStream = (stream: IStream) => {
		if (stream) {
			return <StreamListItem testID={`streamselect_${stream.id}`} item={stream} />;
		}

		return null;
	};

	const _renderActions = (stream: IStream, deleteAllowed?: boolean) => {
		if (stream) {
			return (
				<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
					<RoundButton
						testID={`${testID}_button_edit`}
						icon={EDefaultIconSet.Edit}
						isOutline
						onPress={() => setShowUpdateStreamModal(stream.id ?? stream)}
						alignSelf="flex-end"
					/>
					{deleteAllowed && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							isOutline
							onPress={() =>
								showAlert({
									title: t('DeleteStreamReferenceTitle'),
									message: t('DeleteStreamReferenceSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('DeleteStreamReferenceTitle'),
											style: 'destructive',
											onPress: () => {
												_removeStream(0);
											}
										}
									]
								})
							}
							alignSelf="flex-end"
						/>
					)}
				</View>
			);
		}

		return null;
	};

	const _renderDragStream = (params) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${testID}_stream_${index}`}
				isActive={isActive}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onRemove={() => _removeStream(index)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (streams?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				deleteTitle={t('Remove stream')}
				deleteSubTitle={t('removeStreamSubtitle')}
			>
				{_renderStream(item)}
				{_renderActions(item)}
			</HSDragCard>
		);
	};

	const handleChange = (val: number | IStream[] | IMediaObject[] | IMediaObject) => {
		if (onChange) {
			if (Array.isArray(val)) {
				if (val.length === 0) {
					onChange(null);
				}
				if (createMediaJson || createStreamJson) {
					const _newVal = val[0];
					if (_newVal) {
						onChange(_newVal);
					} else {
						onChange(null);
					}
				} else {
					let _streams = val.map((s) => s.id);
					onChange(_streams);
				}
			} else if (typeof val === 'number') {
				let _streams = streams.map((s) => s.id);
				onChange([..._streams, val]);
			} else {
				onChange(val);
			}
		}
	};

	const _renderMedia = () => {
		if (createMediaJson || createStreamJson) {
			const _stream = streams[0];
			if (_stream) {
				return (
					<View>
						{_renderStream(_stream)}
						{_renderActions(_stream, true)}
					</View>
				);
			}
			return _renderAdd();
		}

		return (
			<View>
				<DraggableFlatList
					data={streams ?? []}
					renderItem={(params) => _renderDragStream(params)}
					keyExtractor={(item, index) => `${testID}_streampicker_stream_${index}`}
					onDragBegin={() => {
						if (IS_ANDROID) {
							Vibration.vibrate(10, false);
						}
					}}
					onDragEnd={({ data }) => {
						handleChange(data);
					}}
				/>
				{_renderAdd()}
			</View>
		);
	};

	const _renderAdd = () => {
		return (
			<RoundButton
				testID={`${testID}_button_add`}
				icon={EDefaultIconSet.Add}
				onPress={() => setShowAddMediaModal(true)}
				alignSelf="flex-end"
				isDisabled={isDisabled}
			/>
		);
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error}>
			{_renderMedia()}
			<AddStreamModal
				hideMediaItem={hideMediaItem}
				createMediaJson={createMediaJson}
				createStreamJson={createStreamJson}
				hideSelection={createMediaJson}
				isVisible={showAddMediaModal}
				onClose={() => setShowAddMediaModal(false)}
				onSubmit={(streamId) => {
					handleChange(streamId);
					setShowAddMediaModal(false);
				}}
				isSelfService={isSelfService}
			/>
			<UpdateStreamModal
				isVisible={showUpdateStreamModal !== undefined}
				createMediaJson={createMediaJson}
				createStreamJson={createStreamJson}
				hideEditWarning={hideEditWarning}
				stream={showUpdateStreamModal}
				onClose={() => setShowUpdateStreamModal(undefined)}
				onSubmit={(streamId) => {
					if (createMediaJson || createStreamJson) {
						handleChange([streamId]);
					}
					setShowUpdateStreamModal(undefined);
				}}
				isSelfService={isSelfService}
			/>
		</FormField>
	);
};
