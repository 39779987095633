import { IVote } from 'config/interfaces';
import React from 'react';
import { HSModal } from '../Modal';
import { QuizLeaderboard } from './QuizLeaderboard';

interface IQuizLeaderboardModal {
	isVisible: boolean;
	onClose: () => void;
	vote: IVote;
	userScore: number;
	resultsOnly?: boolean;
}

export const QuizLeaderboardModal = (props: IQuizLeaderboardModal) => {
	const { isVisible, onClose, vote, userScore, resultsOnly } = props;

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			<QuizLeaderboard vote={vote} userScore={userScore} resultsOnly={resultsOnly} />
		</HSModal>
	);
};
