import React, { useEffect, useState } from 'react';

import { View } from 'react-native';
import { Image } from 'components/Image';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IMedia } from 'config/interfaces';

export type TAvatarSize = 'pin' | 'gridCard' | 'listItem' | 'listItemEdit' | 'drawer' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export type AttendeeOnlineStatus = 'Online' | 'Busy' | 'Offline';
interface IAvatarComponent {
	avatar?: string;
	fullName?: string;
	size?: TAvatarSize;
	userId?: string;
	largerDesign?: boolean;
	testID?: string;
	avatarObj?: IMedia;
}

export const Avatar = (props: IAvatarComponent) => {
	const { avatar, fullName, size, userId, largerDesign, testID, avatarObj } = props;
	const { theme } = useTheme();

	const [userStatus, setUserStatus] = useState<AttendeeOnlineStatus>('Offline');

	const onlineUsers = useSelector((store: IRootState) => store.content.onlineUsers);
	const networkingRoomAttendeeOverviews = useSelector((store: IRootState) => store.networking.networkingRoomAttendeeOverviews);

	useEffect(() => {
		let _status: typeof userStatus = 'Offline';

		if (userId) {
			if (onlineUsers) {
				_status = onlineUsers.includes(userId) ? 'Online' : 'Offline';
			}
			if (networkingRoomAttendeeOverviews) {
				const overview = networkingRoomAttendeeOverviews.find((e) => e.roomUsers.find((e2) => e2.userId === userId));
				if (overview) {
					_status = 'Busy';
				}
			}
		}

		setUserStatus(_status);
	}, [userId, onlineUsers, networkingRoomAttendeeOverviews]);

	let _size = 1;

	switch (size) {
		case 'pin':
			_size = 15;
			break;
		case 'gridCard':
			_size = 20;
			break;
		case 'xxs':
			_size = 25;
			break;
		case 'xs':
			_size = 35;
			break;
		case 'sm':
			_size = 50;
			break;
		case 'lg':
			_size = 90;
			break;
		case 'xl':
			_size = 110;
			break;
		case 'xxl':
			_size = 130;
			break;
		case 'listItem':
			_size = 60;
			break;
		case 'listItemEdit':
		case 'drawer':
			_size = 40;
			break;
		case 'md':
		default:
			_size = 70;
			break;
	}

	if (largerDesign) {
		_size = _size * 1.5;
	}

	const _renderStatus = () => {
		if (userId && userStatus && IS_WEB) {
			let badgeColor = '';
			switch (userStatus) {
				case 'Busy':
					badgeColor = theme.warning;
					break;
				case 'Offline':
					badgeColor = theme.danger;
					break;
				case 'Online':
				default:
					badgeColor = theme.success;
					break;
			}

			return (
				<View
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						height: _size / 4,
						width: _size / 4,
						borderRadius: _size / 8,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: theme.white,
						padding: 2
					}}
				>
					<View style={{ height: '100%', width: '100%', borderRadius: _size / 8, backgroundColor: badgeColor }} />
				</View>
			);
		}

		return null;
	};

	if (avatar || avatarObj) {
		return (
			<View style={{ position: 'relative', width: _size, height: _size }}>
				<Image
					testID={`${testID}`}
					mediaObj={avatarObj}
					imageSize="thumbnail"
					url={avatar}
					style={{ width: _size, height: _size, borderRadius: _size / 2 }}
					contentFit="cover"
				/>
				{_renderStatus()}
			</View>
		);
	}

	if (fullName) {
		const splittedName = fullName.split(' ');

		return (
			<View
				style={{
					position: 'relative',
					width: _size,
					height: _size,
					borderRadius: _size / 2,
					backgroundColor: theme.primary,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Text
					style={{ color: theme.primaryContrast, fontSize: largerDesign ? (_size / 3) * 1.5 : _size / 3, fontWeight: 'bold' }}
				>{`${splittedName[0].charAt(0).toUpperCase()}${splittedName[1] ? splittedName[1].charAt(0).toUpperCase() : ''}`}</Text>
				{_renderStatus()}
			</View>
		);
	}

	return (
		<View
			style={{
				backgroundColor: theme.primary,
				width: _size,
				height: _size,
				borderRadius: _size / 2,
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Icon name={EDefaultIconSet.User} size={_size * 0.6} color={theme.primaryContrast} />
		</View>
	);
};
