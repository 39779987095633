import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { H1 } from 'components/Text';
import { ILauncherSpace } from 'config/interfaces';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { ScrollView, View } from 'react-native';
import { hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { Spinner } from 'components/Spinner';
import { Markdown } from 'components/Markdown';
import { Image } from 'components/Image';
import { RoundButton } from 'components/Button';
import i18next from 'i18next';
import { isEmptyObject } from 'helper/object';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.Prestart>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.Prestart>;
type RouteParams = NoSpaceStackParamList[ERoutes.Prestart];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'prestart';

export const PrestartScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);

	useEffect(() => {
		setIsLoading(true);
		if (route.params?.spaceId) {
			getSpaceById(route.params.spaceId);
		}
	}, [route.params]);

	useEffect(() => {
		if (space) {
			i18next.changeLanguage(space.language);
			theme.text = space.textColor ?? theme.text;
			theme.primary = space.primaryColor ?? theme.primary;
			theme.primaryContrast = space.primaryContrastColor ?? theme.primaryContrast;
			theme.contrast = space.contrastColor ?? theme.contrast;
			theme.contentBackgroundColor = space.contentBackgroundColor ?? theme.contentBackgroundColor;
		}
	}, [space]);

	const getSpaceById = async (id: string) => {
		if (id) {
			const space = await findSpaceById({ spaceId: id, noToast: true });
			if (space) {
				setSpace(space);
			}
			setIsLoading(false);
		}
	};

	const _renderContent = () => {
		if (space && !space.isDeleted && !space.isDeactivated) {
			return (
				<View style={{ width: screenWidth === '60%' ? '45%' : '100%', maxHeight: '100%' }}>
					<HSCard style={{ flex: 1 }}>
						<ScrollView
							style={{ marginHorizontal: -hsInnerPadding }}
							contentContainerStyle={{
								alignItems: 'center',
								paddingHorizontal: hsInnerPadding
							}}
						>
							{space.logoImage && (
								<Image
									mediaObj={space.logoImage}
									imageSize={IS_WEB ? 'full' : 'small'}
									url={space.logoImage.url}
									style={{
										width: '60%',
										maxWidth: 300,
										aspectRatio: 16 / 9,
										alignSelf: 'center',
										marginBottom: 2
									}}
								/>
							)}
							<H1 center>{space.title}</H1>
							<Markdown markdown={space.prestartText} />
						</ScrollView>
					</HSCard>
					{isEmptyString(space?.spacePin) && (
						<HSCard>
							<RoundButton
								isFloatingButton
								testID={`${TESTIDPREFIX}_button_login`}
								title={t('spaceIsPublicToSpace')}
								onPress={() => {
									navigation.navigate(ERoutes.SpaceSummary, { spaceId: space.spaceId });
								}}
								icon={EDefaultIconSet.Save}
								alignSelf="center"
							/>
						</HSCard>
					)}
				</View>
			);
		}
		return (
			<View
				style={{
					width: screenWidth === '60%' ? '45%' : '100%',
					maxHeight: '100%'
				}}
			>
				<NoData type="SpaceIdNotFound" />
			</View>
		);
	};

	return (
		<ScreenContainer
			bgImage={space?.backgroundImage}
			bgImageName={space?.backgroundImageName}
			style={{
				backgroundColor:
					isEmptyObject(space?.backgroundImage) && isEmptyString(space?.backgroundImageName)
						? space?.backgroundColor
						: 'transparent'
			}}
		>
			<ScrollView
				style={{
					flex: 1,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				<View
					style={{
						width: '100%',
						height: '100%',
						alignSelf: 'center',
						alignItems: 'center',
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide
					}}
				>
					{isLoading ? <Spinner /> : _renderContent()}
				</View>
			</ScrollView>
		</ScreenContainer>
	);
};

export const PrestartScreenHeader = (props: NativeStackHeaderProps) => {
	return null;
};
