import React, { useState } from 'react';
import { Dimensions, View, ViewStyle } from 'react-native';

import { IFormBase } from 'config/interfaces/form';
import { FormField } from './FormField';

import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import CalendarPicker from 'react-native-calendar-picker';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet } from 'helper';
import { Icon } from 'components/Icon';
import { Hint } from 'components/Text';
import moment from 'moment';
import { useSpace } from 'hooks/useSpace';
import { RoundButton } from 'components/Button';

interface IFormCalendarPicker extends IFormBase {
	formStyle?: ViewStyle;
	containerStyle?: ViewStyle;
	onStartDateChange?: (val: string | null) => void;
	onEndDateChange?: (val: string | null) => void;
	startDate?: string | null;
	initialDate?: string;
	endDate?: string | null;
	allowRangeSelection?: boolean;
	minDate?: string;
	maxDate?: string;
	notPossible?: boolean;
	onReset?: () => void;
	isModal?: boolean;
}

export const FormCalendarPicker = (props: IFormCalendarPicker) => {
	const {
		label,
		isRequired,
		hint,
		error,
		testID,
		formStyle,
		onStartDateChange,
		onEndDateChange,
		isDisabled,
		initialDate,
		startDate: value,
		endDate,
		allowRangeSelection,
		minDate,
		maxDate,
		notPossible,
		onReset,
		isModal
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const screenWidth = Dimensions.get('window').width;

	const _getModalWidth = () => {
		if (containerWidth >= 800) {
			return containerWidth * 0.66;
		} else if (containerWidth >= 600) {
			return containerWidth * 0.75;
		} else if (containerWidth >= 400) {
			return containerWidth * 0.85;
		} else {
			return containerWidth;
		}
	};

	const _getWidth = () => {
		if (isTabletOrMobile) {
			return containerWidth;
		}
		if (screenWidth < 1400) {
			return containerWidth * 0.85;
		} else if (screenWidth < 1600) {
			return containerWidth * 0.75;
		}

		return containerWidth * 0.6;
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<View onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)} style={{ alignItems: 'center' }}>
				<CalendarPicker
					width={isModal ? _getModalWidth() : _getWidth()}
					startFromMonday
					previousComponent={<Icon name={EDefaultIconSet.ChevronLeft} />}
					nextComponent={<Icon name={EDefaultIconSet.ChevronRight} />}
					weekdays={[t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat'), t('Sun')]}
					months={[
						t('January'),
						t('February'),
						t('March'),
						t('April'),
						t('May'),
						t('June'),
						t('July'),
						t('August'),
						t('September'),
						t('October'),
						t('November'),
						t('December')
					]}
					textStyle={{ color: theme.text }}
					selectedDisabledDatesTextStyle={{ color: theme.lightgray }}
					initialDate={initialDate}
					minDate={minDate}
					maxDate={maxDate}
					todayBackgroundColor={theme.lightgray}
					todayTextStyle={{ color: theme.primaryContrast }}
					selectedDayColor={theme.primary}
					selectedDayTextColor={theme.primaryContrast}
					selectedStartDate={value}
					selectedEndDate={endDate}
					enableDateChange={!isDisabled}
					restrictMonthNavigation
					allowRangeSelection={allowRangeSelection}
					onDateChange={(val, val2) => {
						if (allowRangeSelection) {
							switch (val2) {
								case 'START_DATE':
									if (onStartDateChange) {
										onStartDateChange(val === value ? null : val);
									}
									break;
								case 'END_DATE':
									if (onEndDateChange) {
										onEndDateChange(val === value ? null : val);
									}
									break;
								default:
									break;
							}
						} else {
							if (onStartDateChange) {
								onStartDateChange(val === value ? null : val);
							}
						}
					}}
				/>
				{notPossible && (
					<Hint bold style={{ color: theme.danger, alignSelf: 'flex-start' }}>
						{t('NoMeetingSlotsHint') + '\n'}
						{t('MeetingAvailableFromUntil')
							.replace(
								'%START%',
								moment(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate).format('DD.MM.YYYY')
							)
							.replace(
								'%END%',
								moment(activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate).format('DD.MM.YYYY')
							)}
					</Hint>
				)}
				{onReset && (
					<RoundButton
						testID={`${testID}_button_clear`}
						icon={EDefaultIconSet.Delete}
						isDisabled={allowRangeSelection ? !value && !endDate : !value}
						size="sm"
						color={theme.danger}
						alignSelf="flex-end"
						onPress={() => onReset()}
					/>
				)}
			</View>
		</FormField>
	);
};
