import { IEditMailTemplateFormValues, ITempMailSettings } from 'config/interfaces';
import { isEmptyString } from 'helper';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getRequiredError } from './errors';

export const getEditMailTemplateFormSchema = (values: IEditMailTemplateFormValues) => {
	return Yup.object().shape({
		title: Yup.string().required(getRequiredError(i18next.t('Title'))),
		subject: Yup.string().required(getRequiredError(i18next.t('Subject'))),
		body: Yup.string().required(getRequiredError(i18next.t('EmailBody'))),
		useDefaultHeader: Yup.boolean().nullable(),
		useDefaultFooter: Yup.boolean().nullable()
	});
};

export const getMailSettingsFormSchema = (values: ITempMailSettings) => {
	if (
		isEmptyString(values.host) &&
		!values.port &&
		isEmptyString(values.from) &&
		isEmptyString(values.user) &&
		isEmptyString(values.password)
	) {
		return Yup.object().shape({
			senderName: Yup.string().nullable(),
			replyTo: Yup.string().nullable()
		});
	}

	return Yup.object().shape({
		host: Yup.string().required(getRequiredError('SMTP Host')).nullable(),
		port: Yup.number().required(getRequiredError('SMTP Port')).nullable(),
		secure: Yup.boolean().nullable(),
		rejectUnauthorized: Yup.boolean().nullable(),
		from: Yup.string()
			.required(getRequiredError(i18next.t('Sender Address')))
			.nullable(),
		user: Yup.string()
			.required(getRequiredError(i18next.t('Username Mailserver')))
			.nullable(),
		password: Yup.string()
			.required(getRequiredError(i18next.t('Password Mailserver')))
			.nullable(),
		senderName: Yup.string().nullable(),
		replyTo: Yup.string().nullable()
	});
};
