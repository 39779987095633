import { HSCard } from 'components/Card/HSCard';
import { FormReferenceSelect } from 'components/Form/FormReferenceSelect';
import { StreamInput } from 'components/Form/FormStreamSelect/StreamModal/StreamInput';
import { StepWizard } from 'components/StepWizard';
import { Title } from 'components/Text';
import { IUpdateStreamFormErrors, IUpdateStreamFormValues } from 'config/interfaces';
import { hsInnerPadding } from 'config/styleConstants';
import { isEmptyString } from 'helper';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, Text, Keyboard } from 'react-native';
import { useTheme } from 'hooks/useTheme';

interface IUpdateStreamForm {
	testID: string;
	values: IUpdateStreamFormValues;
	errors: IUpdateStreamFormErrors;
	onChange: (values: IUpdateStreamFormValues) => void;
	onSubmit: () => void;
	isLoading: boolean;
	isModal?: boolean;
	allowReferences?: boolean;
	hidePreviewImage?: boolean;
	isMediaItem?: boolean;
	showWarning?: boolean;
	isSelfService?: boolean;
}

export const StreamUpdateForm = (props: IUpdateStreamForm) => {
	const {
		testID,
		values,
		errors,
		onChange,
		onSubmit,
		isLoading,
		isModal,
		allowReferences,
		hidePreviewImage,
		isMediaItem,
		showWarning,
		isSelfService
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [currentStep, setCurrentStep] = useState<number>(0);

	const _isNextDisabled = () => {
		switch (currentStep) {
			case 0:
				if (values.streamType === 'upload' && isEmptyString(values.title)) {
					return true;
				}
				switch (values.streamType) {
					case 'contentflow':
						return isEmptyString(values.contentflowUrl);
					case 'mediaLibrary':
						return !values.mediaitem;
					case 'upload':
						return !values.media;
					case 'youtube':
						return isEmptyString(values.youTubeUrl);
					case 'vimeo':
						return isEmptyString(values.vimeoUrl);
					case 'iFrame':
						return isEmptyString(values.iFrameUrl);
					case 'externalMeeting':
						return isEmptyString(values.externalMeetingUrl) || isEmptyString(values.externalMeetingButtonText);
					case 'zoom':
						return (
							isEmptyString(values.zoomMeetingId) ||
							isEmptyString(values.zoomMeetingPassword) ||
							isEmptyString(values.zoomMeetingUrl)
						);
					case 'networking':
						return !values.networkinRoomId;
					default:
						return true;
				}
			default:
				return false;
		}
	};

	const _getNextButtonText = () => {
		if (allowReferences) {
			switch (currentStep) {
				case 1:
					return isMediaItem ? t('Update media') : t('Update Stream');
				default:
					return undefined;
			}
		} else {
			switch (currentStep) {
				case 0:
					return isMediaItem ? t('Update media') : t('Update Stream');
				default:
					return undefined;
			}
		}
	};

	const _additionalSteps = () => {
		const arr: ReactNode[] = [];

		if (allowReferences) {
			arr.push(
				<FormReferenceSelect
					key={`${testID}_referenceselect_schedules`}
					testID={`${testID}_referenceselect_schedules`}
					label={t('Agenda')}
					onSelect={(schedules) => onChange({ ...values, schedules: schedules as number[] | undefined })}
					multiSelect
					isDisabled={isLoading}
					type="schedules"
					value={values.schedules}
				/>
			);
		}

		return arr;
	};

	const _renderWizard = () => {
		return (
			<StepWizard
				testIdPrefix={testID}
				components={[
					<ScrollView
						keyboardShouldPersistTaps="handled"
						onScrollBeginDrag={() => Keyboard.dismiss()}
						scrollEventThrottle={0}
						style={{ marginHorizontal: -hsInnerPadding }}
						contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
					>
						{showWarning && (
							<View>
								<Title style={{ color: theme.danger, alignSelf: 'center' }}>{t('Attention')}</Title>
								<Text>{t('UpdateStreamModalIntro')}</Text>
								<View
									style={{
										width: '95%',
										height: 1,
										alignSelf: 'center',
										marginVertical: 20,
										backgroundColor: theme.lightgray
									}}
								/>
							</View>
						)}
						<StreamInput
							isMediaItem={isMediaItem}
							testID={`${testID}_mediatypeinput`}
							values={values}
							errors={errors}
							onChange={(values) => onChange(values)}
							hidePreviewImage={hidePreviewImage}
							isSelfService={isSelfService}
						/>
					</ScrollView>,
					..._additionalSteps()
				]}
				isLoading={isLoading}
				getCurrentIndex={(idx) => setCurrentStep(idx)}
				isNextButtonDisabled={_isNextDisabled()}
				completeFunction={() => onSubmit()}
				nextButtonText={_getNextButtonText()}
			/>
		);
	};

	if (isModal) {
		return <View style={{ flex: 1 }}>{_renderWizard()}</View>;
	}

	return <HSCard style={{ flex: 1 }}>{_renderWizard()}</HSCard>;
};
