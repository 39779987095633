import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IVote, IVoteOption } from 'config/interfaces/vote';
import { IVoteResult } from 'config/interfaces/voteanswer';
import { useQuery } from 'hooks/useQuery';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { FormSwitch } from 'components/Form/FormSwitch';
import { PreviewApplauseAnswer, PreviewApplauseOptions } from 'helper/previewAnswers/previewAnswersApplause';
import { BarChart } from 'components/Charts';
import { IWordListItem } from '../VoteWordCloud/WordCloud';
import { HSCard } from 'components/Card';
import { RoundButton } from 'components/Button';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { HORIZONTAL_BARCHART_MAXWIDTH } from 'components/Charts/BarChart/constants';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';

interface IVoteApplause {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
}

const TESTIDPREFIX = 'activevote';

export const VoteApplause = (props: IVoteApplause) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { isPhone, isWeb, isTabletOrMobile, screenWidth } = useQuery();

	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [voteValues, setVoteValues] = useState<IWordListItem[]>([]);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);

	useEffect(() => {
		let answersForThis: IVoteResult | undefined = undefined;
		let _values: typeof voteValues = [];

		if (isPreview) {
			answersForThis = PreviewApplauseAnswer;
		} else {
			answersForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;
		}

		if (isPreview) {
			PreviewApplauseOptions.forEach((option, index) => {
				_values.push({
					text: option.label ?? '',
					count: PreviewApplauseAnswer[index]?.value ?? 0
				});
			});
		} else {
			vote.options?.forEach((option) => {
				const found = answersForThis?.options.find((e) => e.key === option.id?.toString());

				_values.push({
					text: option.label ?? '',
					count: found?.value ?? 0
				});
			});
		}

		setVoteValues(_values);
	}, [voteAnswers, vote]);

	const _shouldDisplayStacked = () => {
		return (isPhone || (isWeb && isTabletOrMobile)) && vote.options && vote.options?.length > 4;
	};

	const _submitVoteAnswerByIndex = async (index: number) => {
		if (vote?.options && vote?.options[index]) {
			_submitVoteAnswer(vote?.options[index]);
		}
	};

	const _submitVoteAnswer = async (voteOption: IVoteOption) => {
		if (isPreview) {
			return;
		}
		if (
			(vote.allowAnonymousAnswers === 'never' && !profile) ||
			(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
		) {
			setIsAuthenticationModalVisible(true);
			return;
		}
		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		let res, value;
		value = (voteOption.id ?? '').toString();
		if (value) {
			res = await submitVoteAnswer({
				voteId: vote.id,
				value: value,
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous,
				noToast: true
			});
		}
		setIsSubmitVoteAnswerLoading(false);
	};

	const _renderChart = () => {
		return (
			<BarChart
				data={voteValues}
				isIcons
				showStatsInBars
				largerDesign={largerDesign}
				showVertical={_shouldDisplayStacked()}
				onPress={_submitVoteAnswerByIndex}
			/>
		);
	};

	const _renderIcons = () => {
		if (resultsOnly || !vote.isActive) {
			return null;
		}
		if (vote && vote.options) {
			return (
				<View
					style={{
						flexDirection: 'row',
						marginTop: 10
					}}
				>
					{vote?.options?.map((option, index) => {
						return (
							<View
								key={`${option.id}_${index}`}
								style={{
									width: `${100 / (vote?.options?.length ?? 1)}%`,
									flexDirection: 'row',
									justifyContent: 'center'
								}}
							>
								<View
									style={{
										width: '100%',
										maxWidth: largerDesign ? HORIZONTAL_BARCHART_MAXWIDTH * 1.5 : HORIZONTAL_BARCHART_MAXWIDTH,
										paddingHorizontal: 5
									}}
								>
									<RoundButton
										alignSelf="center"
										testID={`${TESTIDPREFIX}_button_applauseoption_${index}`}
										onPress={() => {
											_submitVoteAnswer(option);
										}}
										icon={option.label ?? ''}
									/>
								</View>
							</View>
						);
					})}
				</View>
			);
		}

		return null;
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<View
					style={{
						marginTop: hsBottomMargin / 2,
						marginBottom: hsBottomMargin,
						flexDirection: 'row',
						justifyContent: 'flex-end'
					}}
				>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined, marginBottom: 0 }}
					/>
				</View>
			);
		}
		return null;
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					contentContainerStyle={{
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center',
						flexGrow: 1
					}}
				>
					<ActiveVoteHeader activeVote={vote} showResult={resultsOnly} largerText={largerDesign} />
					{_renderAnonymousSwitch()}
					<HSCard style={{ flex: 1 }}>
						{_renderChart()}
						{_renderIcons()}
					</HSCard>
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
		</View>
	);
};
