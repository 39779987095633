import { createModel } from '@rematch/core';
import {
	ICreateSpaceParams,
	IFindMultipleSpacesBySpaceIdsParams,
	IFindSpaceByIdParams,
	IJoinSpaceParams,
	IReloadSpaceAndStartSyncParams,
	IRemoveFromSpaceHistoryPayload,
	ISearchUserByEmailParams,
	ISpaceHistory,
	IUserInSpaceId
} from 'rematch/interfaces';
import { RootModel } from './index';
import { HELLOSPACES_USERS, MULTISPACEURL } from 'config/constants';
import { IEditSpacePayload, ILauncherSpace, IMediaGalleryItem } from 'config/interfaces';
import { canJoinSpace, generateTheme, getAccessRightsForSpace, hasEntryChanged, IS_WEB, translateStrapiError } from 'helper';
import moment from 'moment';
import { DEFAULTTHEME } from './config';
import i18next from 'i18next';
import * as Localization from 'expo-localization';
import * as RootNavigation from '../../RootNavigation';
import { ERoutes } from 'components/Navigation/routes';
import { showToast } from 'helper/toast';

import { ENVIRONMENT, PRESET_SPACEIDS } from 'config/envConstants';

type IDefaultState = {
	activeSpaceId?: string;
	spaceHistory: ISpaceHistory[];
	isWelcomeModalVisible: boolean;
	userInSpaces: IUserInSpaceId[];
	lastUserInSpaceRead: Record<string, string>;
};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const space = createModel<RootModel>()({
	state: {
		activeSpaceId: undefined,
		spaceHistory: [],
		isWelcomeModalVisible: false,
		userInSpaces: [],
		lastUserInSpaceRead: {}
	} as IDefaultState,
	reducers: {
		setActiveSpaceId(state, activeSpaceId?: string) {
			rematchLog('setActiveSpaceId');
			return {
				...state,
				activeSpaceId
			};
		},
		setSpaceHistory(state, spaceHistory: IDefaultState['spaceHistory']) {
			rematchLog('setSpaceHistory');
			return {
				...state,
				spaceHistory: spaceHistory.filter((e) => e.space?.spaceId)
			};
		},
		setIsWelcomeModalVisible(state, isWelcomeModalVisible: boolean) {
			rematchLog('setIsWelcomeModalVisible');
			return {
				...state,
				isWelcomeModalVisible
			};
		},
		addUsersInSpaceId(state, newUsers: IDefaultState['userInSpaces']) {
			rematchLog('addUsersInSpaceId');
			const _users = state.userInSpaces ? [...state.userInSpaces] : [];

			const lastRead = state.lastUserInSpaceRead ? { ...state.lastUserInSpaceRead } : {};

			newUsers.forEach((entry) => {
				const idx = newUsers.findIndex((e) => e.id === entry.id);
				if (idx !== -1) {
					if (entry.isDeleted) {
						_users.splice(idx, 1);
					} else {
						_users[idx] = entry;
					}
				} else if (!entry.isDeleted) {
					_users.push(entry);
				}

				if (!lastRead[entry.spaceId] || entry.updated_at > lastRead[entry.spaceId]) {
					lastRead[entry.spaceId] = entry.updated_at;
				}
			});

			return {
				...state,
				userInSpaces: _users.filter((e) => e),
				lastUserInSpaceRead: lastRead
			};
		}
	},
	effects: (dispatch) => ({
		async reloadSpaceAndStartSync(payload: IReloadSpaceAndStartSyncParams, store) {
			try {
				const { reload } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					if (activeSpace.isDeactivated || activeSpace.isDeleted || activeSpace.disabledBySuperAdmin) {
						const hasLeft = await dispatch.space.leaveSpaceOnInactiveOrDeleted({
							isDeleted: activeSpace.isDeleted ?? false,
							isDisabledBySuperAdmin: activeSpace.disabledBySuperAdmin ?? false
						});
						if (hasLeft) {
							return false;
						}
					}
					if (activeSpace.registrationRequired && !store.auth.profile) {
						const hasLeft = await dispatch.space.leaveSpaceOnRegistrationRequiredAndNoProfile({});
						if (hasLeft) {
							return false;
						}
					}

					if (reload) {
						if (!store.temp.netInfoState?.isConnected) {
							return true;
						}

						const res = await dispatch.space.findSpaceById({ spaceId: activeSpace.spaceId, noToast: true });
						if (res?.isOffline) {
							return true;
						}

						if (res?.id) {
							if (res.isDeactivated || res.isDeleted || activeSpace.disabledBySuperAdmin) {
								const hasLeft = await dispatch.space.leaveSpaceOnInactiveOrDeleted({
									isDeleted: res.isDeleted ?? false,
									isDisabledBySuperAdmin: res.disabledBySuperAdmin ?? false
								});

								if (hasLeft) {
									return false;
								}
							} else if (res.registrationRequired && !store.auth.profile) {
								const hasLeft = await dispatch.space.leaveSpaceOnRegistrationRequiredAndNoProfile({});
								if (hasLeft) {
									return false;
								}
							}

							dispatch.space.spaceConfigs({ space: res, activeSpace });

							if (
								store.socket.multiSpaceSocket &&
								!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}`)
							) {
								store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}`, async (data: ILauncherSpace) => {
									if (data.isDeactivated || data.isDeleted || data.disabledBySuperAdmin) {
										const hasLeft = await dispatch.space.leaveSpaceOnInactiveOrDeleted({
											isDeleted: data.isDeleted ?? false,
											isDisabledBySuperAdmin: data.disabledBySuperAdmin ?? false
										});
										if (hasLeft) {
											return false;
										}
									}
									if (data.registrationRequired && !store.auth.profile) {
										const hasLeft = await dispatch.space.leaveSpaceOnRegistrationRequiredAndNoProfile({});
										if (hasLeft) {
											return false;
										}
									}
									if (data) {
										dispatch.space.spaceConfigs({ space: data, activeSpace: dispatch.temp.getActiveSpace({}) });
									}
								});
							}
						} else {
							if (PRESET_SPACEIDS) {
								return true;
							}

							dispatch.space.leaveSpaceOnInactiveOrDeleted({ isDeleted: true, isDisabledBySuperAdmin: false });
							return false;
						}
					}
					return true;
				}
			} catch (error) {
				console.log('reloadSpaceAndStartSync', error);
			}
		},
		async joinSpace(payload: IJoinSpaceParams, store) {
			try {
				const { space } = payload;

				if (space?.spaceId) {
					// Call to check if i can join space
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: space.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const _canJoinSpace = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/spaces/joinspace`,
						body
					});

					if (_canJoinSpace?.isOffline) {
						const foundInHistory = store.space.spaceHistory.find((e) => !e.isDeleted && e.space.spaceId === space.spaceId);
						if (!foundInHistory) {
							showToast('error', 'Error', i18next.t('NoNetworkConnection'));
							return false;
						}
					} else if (!_canJoinSpace) {
						showToast('error', i18next.t('AttendeeLimitReached'), i18next.t('AttendeeLimitReachedSubtitle'));
						return;
					}

					dispatch.temp.setActiveSpaceId({ spaceId: space.spaceId });

					if (space.welcomeShowType === 'always') {
						dispatch.space.setIsWelcomeModalVisible(true);
					} else if (space.welcomeShowType === 'once') {
						const spaceEntry = store.space.spaceHistory?.find((e) => !e.isDeleted && e.space.spaceId === space.spaceId);
						if (!spaceEntry || (spaceEntry?.date && moment(spaceEntry.date).isBefore(space.welcomeLastUpdateTimeStamp))) {
							dispatch.space.setIsWelcomeModalVisible(true);
						}
					}

					const history = store.space.spaceHistory.filter((e) => e.space.spaceId !== space.spaceId);
					history.unshift({
						date: moment().toISOString(),
						space
					});

					dispatch.space.setSpaceHistory(history);
					dispatch.temp.startAllSyncs({});

					if (IS_WEB) {
						dispatch.auth.markMyselfAsOnline({});
					}

					dispatch.config.setTheme(generateTheme(space));

					if (store.auth.profile) {
						const user = { ...store.auth.profile };
						const userSpaceHistory = user.userSpaceHistory ? { ...user.userSpaceHistory } : { spaces: [] };
						userSpaceHistory.spaces = userSpaceHistory.spaces.filter((e) => e.spaceId !== space.spaceId);

						userSpaceHistory.spaces.unshift({
							date: moment().toISOString(),
							spaceId: space.spaceId
						});
						user.userSpaceHistory = userSpaceHistory;

						await dispatch.auth.updateUser({
							user: {
								id: user.id,
								userSpaceHistory
							},
							noToast: true
						});
					}

					await dispatch.space.reloadSpaceAndStartSync({ reload: true });
					dispatch.socket.emitToMultiSpaceBackend({
						event: IS_WEB ? 'joinSpaceWeb' : 'joinSpace',
						data: { spaceId: space.spaceId }
					});
					if (!store.auth.hasAcceptedPrivacyPolicy) {
						dispatch.auth.setHasAcceptedPrivacyPolicy(true);
					}

					const { isAdmin, needsPin } = canJoinSpace(space, store.auth.profile);

					if (needsPin && !isAdmin) {
						RootNavigation.replace(ERoutes.Prestart, { spaceId: space.spaceId });
						return true;
					}
					return true;
				}

				return false;
			} catch (error) {
				console.log('joinSpace', error);
			}
		},
		async leaveSpace(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && activeSpace.isDeleted) {
					if (store.auth.profile) {
						const user = { ...store.auth.profile };

						const userSpaceHistory = user.userSpaceHistory ? { ...user.userSpaceHistory } : { spaces: [] };
						userSpaceHistory.spaces = userSpaceHistory.spaces.filter((e) => e.spaceId !== activeSpace?.spaceId);
						user.userSpaceHistory = userSpaceHistory;
						let adminInSpaces = user.adminInSpaces ? [...user.adminInSpaces] : [];
						adminInSpaces = adminInSpaces.filter((e) => e.spaceId !== activeSpace?.spaceId);
						user.adminInSpaces = adminInSpaces;
						let moderatorInSpaces = user.moderatorInSpaces ? [...user.moderatorInSpaces] : [];
						moderatorInSpaces = moderatorInSpaces.filter((e) => e.spaceId !== activeSpace?.spaceId);
						user.moderatorInSpaces = moderatorInSpaces;
						await dispatch.auth.updateUser({
							user: {
								id: user.id,
								userSpaceHistory,
								adminInSpaces,
								moderatorInSpaces
							},
							noToast: true
						});
					}
					const updatedHistory = store.space.spaceHistory.filter((e) => e.space.spaceId !== activeSpace?.spaceId);
					dispatch.space.setSpaceHistory(updatedHistory);
				}

				dispatch.temp.setMediaDetail({
					itemId: undefined,
					viewType: 'full',
					playbackStatus: 'paused'
				});
				dispatch.config.setTheme(DEFAULTTHEME);
				i18next.changeLanguage(Localization.locale.split('-')[0]);

				await dispatch.socket.closeMultiSpaceSocket({});
				await dispatch.socket.initMultiSpaceSocket({});

				setTimeout(async () => {
					dispatch.auth.startProfileSync({ load: true });
				}, 100);

				dispatch.temp.clearHasLoadedDataAndWaitingForSocketResponse();

				dispatch.temp.setActiveSpaceId({ spaceId: undefined });
				dispatch.temp.setMediaDetail({ itemId: undefined, itemType: undefined, playbackStatus: 'paused', viewType: 'full' });
				if (IS_WEB) {
					setTimeout(() => {
						dispatch.auth.markMyselfAsOnline({});
					}, 1000);
				}
			} catch (error) {
				console.log('leaveSpace', error);
			}
		},

		async createSpace(payload: ICreateSpaceParams, store) {
			try {
				const { data, noToast } = payload;
				const body = new FormData();
				body.append('data', JSON.stringify(data));

				const res = await dispatch.request.authenticatedRequest({
					url: `${MULTISPACEURL}/spaces`,
					method: 'POST',
					body
				});

				if (res?.id) {
					dispatch.space.setSpaceHistory([{ date: moment().toISOString(), space: res }, ...store.space.spaceHistory]);
					if (!noToast) {
						showToast('success', undefined, i18next.t('SpaceCreated'));
					}
					return res;
				}

				showToast('error', 'Error', translateStrapiError(res));

				return false;
			} catch (error) {
				console.log('createSpace', error);
				return false;
			}
		},
		async updateSpace(payload: IEditSpacePayload, store) {
			try {
				const { data, noToast } = payload;
				const { space } = data;

				const mySpace = space ?? dispatch.temp.getActiveSpace({});
				if (mySpace) {
					let mediaToUpload: IMediaGalleryItem[] = [];

					if (data?.welcomeGallery) {
						mediaToUpload = [...data.welcomeGallery];
						data.welcomeGallery = [];
					} else {
						mediaToUpload = [];
					}

					for (let i = 0; i < mediaToUpload.length; i++) {
						const mediaObj = mediaToUpload[i];

						if (typeof mediaObj.media !== 'number' && data?.welcomeGallery) {
							if (
								typeof mediaObj.media === 'string' ||
								mediaObj.media?.url?.startsWith('file://') ||
								mediaObj.media?.url?.startsWith('data:')
							) {
								let imageId;
								if (typeof mediaObj !== 'string') {
									imageId = mediaObj.id;
								}

								const imageResponse = await dispatch.upload.uploadMedia({
									uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
									imageId,
									caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
								});

								if (imageResponse) {
									data.welcomeGallery[i] = {
										...mediaObj,
										media: imageResponse[0]
									};
								}
							} else {
								data.welcomeGallery[i] = mediaObj;
							}
						} else if (data?.welcomeGallery) {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
							if (libEntry) {
								data.welcomeGallery[i] = {
									...mediaObj,
									media: { ...libEntry }
								};
							}
						}
					}

					const imageKeys = ['previewImage', 'backgroundImage', 'logoImage', 'agendaMyDayImage', 'squareImage'];

					for (const key of imageKeys) {
						const _image = data[key];

						if (
							_image &&
							typeof _image !== 'number' &&
							(typeof _image === 'string' || _image.url.startsWith('file://') || _image?.url?.startsWith('data:'))
						) {
							let imageId;
							if (typeof _image !== 'string') {
								imageId = _image.id;
							}
							const imageResponse = await dispatch.upload.uploadMedia({
								uri: typeof _image === 'string' ? _image : _image.url,
								ref: 'space',
								refId: mySpace.id,
								field: key,
								imageId,
								caption: typeof _image === 'object' ? _image.caption : undefined
							});
							if (imageResponse && Array.isArray(imageResponse) && imageResponse.length > 0) {
								data[key] = imageResponse[0];
							}
						} else if (typeof _image === 'number') {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === _image);
							if (libEntry) {
								data[key] = libEntry;
							}
						}
					}

					const body = new FormData();
					body.append('data', JSON.stringify(data));

					const res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/spaces/${mySpace?.id}`,
						method: 'PUT',
						body
					});

					if (res?.space) {
						const prev = [...store.space.spaceHistory];
						prev[0].space = res.space;
						dispatch.space.setSpaceHistory(prev);
						if (!noToast) {
							showToast('success', i18next.t('SpaceUpdated'));
						}

						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('updateSpace', error);
				return false;
			}
		},

		async findSpaceById(payload: IFindSpaceByIdParams, store) {
			try {
				const { spaceId, noToast } = payload;
				if (spaceId) {
					const body = new FormData();
					body.append('data', JSON.stringify({ spaceId }));

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/spaces/findspacebyid`,
						method: 'POST',
						body
					});

					if (!res || res?.isOffline) {
						const foundInHistory: any = store.space.spaceHistory.find((e) => !e.isDeleted && e.space.spaceId === spaceId);
						if (foundInHistory) {
							return foundInHistory.space;
						}

						showToast('error', 'Error', i18next.t('NoNetworkConnection'));
						return { isOffline: true };
					}

					if (res?.statusCode && !noToast) {
						showToast('error', 'Error', translateStrapiError(res));
						return undefined;
					}

					return res;
				}
				return false;
			} catch (error) {
				console.log('findSpaceById', error);
				return undefined;
			}
		},
		async findMultipleSpacesBySpaceIds(payload: IFindMultipleSpacesBySpaceIdsParams, store) {
			try {
				const { spaceIds } = payload;

				const body = new FormData();
				body.append('data', JSON.stringify({ spaceIds }));

				let res;

				if (store.auth.jwt) {
					res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/spaces/findspacebyid`,
						method: 'POST',
						body
					});
				} else {
					res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/spaces/findspacebyid`,
						method: 'POST',
						body
					});
				}
				return res;
			} catch (error) {
				console.log('findMultipleSpacesBySpaceIds', error);
			}
		},
		async searchSpace(payload: IFindSpaceByIdParams, store) {
			try {
				const { spaceId } = payload;

				const body = new FormData();
				body.append('data', JSON.stringify({ spaceId }));

				let res;

				if (store.auth.jwt) {
					res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/spaces/searchspace`,
						method: 'POST',
						body
					});
				} else {
					res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/spaces/searchspace`,
						method: 'POST',
						body
					});
				}

				return res;
			} catch (error) {
				console.log('findSpaceById', error);
				return undefined;
			}
		},
		async searchUserByEmail(payload: ISearchUserByEmailParams, store) {
			try {
				const { email } = payload;

				const res = await dispatch.request.authenticatedRequest({
					url: `${MULTISPACEURL}/users?isDeleted=false&email=${encodeURIComponent(email)}&_limit=1`,
					method: 'GET'
				});

				if (res && Array.isArray(res) && res[0]) {
					return res[0];
				}

				return undefined;
			} catch (error) {
				console.log('searchUserByEmail', error);
				return undefined;
			}
		},
		async getIsSpaceIdAvailable(payload: { spaceID: string }, store) {
			try {
				const { spaceID } = payload;
				const body = new FormData();
				body.append('data', JSON.stringify({ spaceID }));
				const res = await dispatch.request.authenticatedRequest({
					method: 'POST',
					url: `${MULTISPACEURL}/spaces/available`,
					body
				});

				if (res?.available) {
					return true;
				}
				return false;
			} catch (error) {
				console.log('getIsSpaceIdAvailable', error);
			}
		},
		async leaveSpaceOnInactiveOrDeleted(payload: { isDeleted: boolean; isDisabledBySuperAdmin: boolean }, store) {
			const activeSpace = dispatch.temp.getActiveSpace({});

			if (activeSpace) {
				const { isDeleted, isDisabledBySuperAdmin } = payload;

				const foundAdmin = activeSpace.admins?.find((a) => a.id === store.auth.profile?.id);
				const foundMod = activeSpace.moderators?.find((a) => a.id === store.auth.profile?.id);
				const isHelloSpacesUser = HELLOSPACES_USERS.includes(store.auth.profile?.email ?? 'undefined');

				if (!(foundAdmin || foundMod || isHelloSpacesUser) || isDeleted || isDisabledBySuperAdmin) {
					dispatch.space.leaveSpace({});
					RootNavigation.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary);
					dispatch.alert.showAlert({
						title: !isDeleted ? i18next.t('spaceIsDeactivatedAlertTitle') : i18next.t('spaceIsDeletedAlertTitle'),
						message: !isDeleted ? i18next.t('spaceIsDeactivatedAlertMessage') : '',
						buttons: [
							{
								text: 'Ok',
								style: 'cancel'
							}
						]
					});
					return true;
				}
			}
			return false;
		},
		async leaveSpaceOnRegistrationRequiredAndNoProfile(payload, store) {
			const { noToast } = payload;

			if (!noToast) {
				showToast('info', i18next.t('RegistrationRequired'), i18next.t('RegistrationRequiredLogout'));
			}
			dispatch.space.leaveSpace({});
			if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
				RootNavigation.navigate(ERoutes.SpaceAuth, { authState: 'login' });
			} else {
				RootNavigation.replace(ERoutes.SpaceSummary);
			}
			return true;
		},
		async removeFromSpaceHistory(payload: IRemoveFromSpaceHistoryPayload, store) {
			const { spaceId } = payload;
			if (spaceId) {
				const user = { ...store.auth.profile };
				const userSpaceHistory = user.userSpaceHistory ? { ...user.userSpaceHistory } : { spaces: [] };
				userSpaceHistory.spaces = userSpaceHistory.spaces.filter((e) => e.spaceId !== spaceId);

				user.userSpaceHistory = userSpaceHistory;

				const res = await dispatch.auth.updateUser({
					user: {
						id: user.id,
						userSpaceHistory
					},
					noToast: true
				});

				if (res) {
					let updatedHistory = store.space.spaceHistory ? [...store.space.spaceHistory] : [];
					updatedHistory = updatedHistory.filter((e) => e.space.spaceId !== spaceId);

					dispatch.space.setSpaceHistory(updatedHistory);
				}
			}
		},
		async loadUserInSpace(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (activeSpace && (isAdmin || isModerator) && !store.temp.hasLoadedData?.userinspace) {
					if (store.temp.waitingForSocketResponse?.userinspace) {
						contentLoadLog(3, 'user in space load already in progress. skipping');
						return;
					}

					if (store.temp.hasLoadedData?.userinspace) {
						contentLoadLog(3, 'user in space already loaded. skipping');
						return;
					}

					const lastRead = store.space.lastUserInSpaceRead
						? store.space.lastUserInSpaceRead[activeSpace.spaceId] ?? ZEROHOUR
						: ZEROHOUR;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							lastRead
						})
					);

					contentLoadLog(2, 'loading attendee support requests');
					dispatch.temp.setWaitingForSocketResponse({ key: 'userinspace', value: true });

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/userinspaceids`,
						body
					});
					dispatch.temp.setWaitingForSocketResponse({ key: 'userinspace', value: false });

					if (res && Array.isArray(res)) {
						dispatch.temp.setHasLoadedData('userinspace');
						const updatedEntries = res.filter((obj) => {
							const prev = store.space.userInSpaces.find((e) => e.id === obj.id);

							return hasEntryChanged(prev, obj);
						});

						if (updatedEntries.length > 0) {
							dispatch.space.addUsersInSpaceId(updatedEntries);
						}
					}
				}
			} catch (error) {
				console.log('loadUserInSpace', error);
				dispatch.temp.setWaitingForSocketResponse({ key: 'userinspace', value: false });
			}
		},
		async inviteUnregisteredAdminOrModerator(payload: { email: string; type: 'admin' | 'moderator' }, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});
				if (activeSpace) {
					const { email, type } = payload;
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							email,
							type
						})
					);
					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/spaces/inviteunregisteredadminormoderator`,
						body
					});
					if (res?.status === 200) {
						showToast(
							'success',
							i18next.t('emailToUnregisteredSentTitle'),
							i18next.t('emailToUnregisteredSentSubtitle').replace('%EMAIL%', email)
						);
						return {
							type: 'success',
							text: `${i18next.t('emailToUnregisteredSentTitle')}. ${i18next
								.t('emailToUnregisteredSentSubtitle')
								.replace('%EMAIL%', email)}`
						};
					}
					showToast('error', i18next.t('emailToUnregisteredSentError'));
					return { type: 'error', text: i18next.t('emailToUnregisteredSentError') };
				}
				return { type: 'error', text: i18next.t('noActiveSpace') };
			} catch (error) {
				console.log('inviteUnregisteredAdminOrModerator', error);
				return { type: 'error', text: i18next.t('emailToUnregisteredSentError') };
			}
		},
		async spaceConfigs(payload: { space: ILauncherSpace; activeSpace?: ILauncherSpace }, store) {
			const { space } = payload;

			const generated = generateTheme(space);
			const hasThemeChanged = hasEntryChanged(store.config.theme, generated);

			if (hasThemeChanged) {
				dispatch.config.setTheme(generated);
			}

			if (i18next.language !== space.language) {
				i18next.changeLanguage(space.language ?? Localization.locale.split('-')[0]);
			}

			const history = store.space.spaceHistory ? [...store.space.spaceHistory] : [];

			const prevHistory = history.find((e) => e.space.spaceId === space.spaceId);

			if (prevHistory) {
				const hasSpaceChanged = hasEntryChanged(prevHistory.space, space);

				if (hasSpaceChanged) {
					prevHistory.space = space;
					dispatch.space.setSpaceHistory(history);
				}
			}
		},
		async loadSuggestedSpaces(payload: { language: string }, store) {
			try {
				const { language } = payload;
				const res = await dispatch.request.authenticatedRequest({
					method: 'GET',
					url: `${MULTISPACEURL}/spacetemplates/gettemplates/${language}`
				});

				return res;
			} catch (error) {
				console.log('loadSuggestedSpaces', error);
				return [];
			}
		},
		async loadUpcomingSpaces(payload, store) {
			try {
				const body = new FormData();
				body.append(
					'data',
					JSON.stringify({
						userInfos: store.auth.userInfos
					})
				);

				const res = await dispatch.request.anonymousRequest({
					method: 'POST',
					url: `${MULTISPACEURL}/spaces/upcomingspaces`,
					body
				});

				if (res && Array.isArray(res)) {
					return res;
				}

				showToast('error', 'Error', translateStrapiError(res));
				return [];
			} catch (error) {
				errorLog('loadUpcomingSpaces', error);
				return [];
			}
		}
	})
});
