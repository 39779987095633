import React from 'react';
import { ChildButton } from 'components/Button/ChildButton';
import { Icon } from 'components/Icon';
import { isEmptyString } from 'helper/string';
import { IS_WEB } from 'helper/platform';
import { NAVICONSIZE } from 'components/Form/constants';
import { useTheme } from 'hooks/useTheme';
import { Dimensions, ViewStyle, Text, Platform } from 'react-native';
import { t } from 'i18next';
import { NAVFONTSIZE } from 'components/Form/constants';

interface INavigationHeaderIconButtonComponent {
	testID: string;
	onPress?: Function;
	icon: string;
	color?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	containerStyle?: ViewStyle;
	buttonText?: string;
	textLeft?: boolean;
}

export const NavigationHeaderIconButton = (props: INavigationHeaderIconButtonComponent) => {
	const { testID, onPress, icon, color, isLoading, isDisabled, containerStyle, buttonText, textLeft } = props;

	const { theme } = useTheme();
	const screenWidth = Dimensions.get('window').width;

	const isLeftText =
		textLeft ||
		testID === 'header_button_add' ||
		testID === 'header_button_help' ||
		testID === 'header_button_save' ||
		testID === 'header_button_edit' ||
		testID === 'header_button_createnews';

	const _getButtonText = () => {
		if (buttonText) {
			return buttonText;
		}

		switch (testID) {
			case 'header_button_add':
				return t('Add');
			case 'header_button_navback':
				return t('Back');
			case 'header_button_cancel':
				return t('Cancel');
			case 'header_button_create':
				return t('Create');
			case 'header_button_edit':
				return t('Edit');
			case 'header_button_help':
				return t('Help');
			case 'header_button_openmenu':
				return t('Menu');
			case 'header_button_save':
				return t('Save');
			case 'header_button_toggle':
				return t('Toggle');
			case 'header_button_delete':
				return t('Delete');
			case 'header_button_createnews':
				return t('Create News');
			default:
				return '';
		}
	};

	const _getColor = () => {
		if (isDisabled) {
			return theme.formgray;
		}
		if (color) {
			return color;
		}
		if (testID === 'header_button_delete') {
			return theme.danger;
		}

		let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
		let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;

		if (primary === bg) {
			return theme.navigationTextColor;
		}

		return theme.navbarButton;
	};

	return (
		<ChildButton
			testID={testID}
			isLoading={isLoading}
			isDisabled={isDisabled}
			onPress={() => {
				if (onPress) {
					onPress();
				}
			}}
			style={{
				padding: 5,
				backgroundColor: 'transparent',
				flexDirection: isLeftText ? 'row-reverse' : 'row',
				alignItems: 'center'
			}}
		>
			<Icon name={icon} color={_getColor()} size={NAVICONSIZE} containerStyle={{ ...containerStyle }} />
			{IS_WEB && Platform.OS !== 'android' && Platform.OS !== 'ios' && screenWidth >= 500 && !isEmptyString(_getButtonText()) && (
				<Text
					style={{
						...containerStyle,
						color: _getColor(),
						fontSize: NAVFONTSIZE,
						paddingHorizontal: 5
					}}
				>
					{_getButtonText()}
				</Text>
			)}
		</ChildButton>
	);
};
