import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { IChatMessage } from 'config/interfaces';
import { isFeatureActive, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import { IMarkChatMessagesAsRead, ISendChatMessagePayload } from 'rematch/interfaces';
import { RootModel } from './index';
import moment from 'moment';
import { ENVIRONMENT } from 'config/envConstants';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	chatMessages: IChatMessage[];
	lastChatRead: Record<string, string>;
};

export const chat = createModel<RootModel>()({
	state: {
		chatMessages: [],
		lastChatRead: {}
	} as IDefaultState,
	reducers: {
		addMessages(state, { newMessages, load }: { newMessages: IChatMessage[]; load: boolean }) {
			rematchLog('addMessages');
			const _messages = state.chatMessages ? [...state.chatMessages] : [];

			let _lastChatRead = state.lastChatRead ? state.lastChatRead : {};
			newMessages.forEach((message) => {
				const idx = _messages.findIndex((e) => e.id === message.id);
				if (idx !== -1) {
					if (message.isDeleted) {
						_messages.splice(idx, 1);
					} else {
						_messages[idx] = message;
					}
				} else if (!message.isDeleted) {
					_messages.push(message);
				}

				if (load && (!_lastChatRead[message.spaceId] || _lastChatRead[message.spaceId] < message.updated_at)) {
					_lastChatRead[message.spaceId] = message.updated_at;
				}
			});

			_messages.sort((a, b) => {
				const aVal = a.type === 'meetingRequest' ? a.updated_at : a.created_at;
				const bVal = b.type === 'meetingRequest' ? b.updated_at : b.created_at;
				return moment(aVal).isBefore(moment(bVal)) ? 1 : -1;
			});

			return {
				...state,
				chatMessages: _messages,
				lastChatRead: _lastChatRead
			};
		},
		clear(state) {
			rematchLog('clear chat');
			return {
				chatMessages: [],
				lastChatRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async startChatSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (store.auth.profile && activeSpace && isFeatureActive(activeSpace, 'chats')) {
					if (store.socket.multiSpaceSocket && !store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_chatmessage`)) {
						store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_chatmessage`, async (data) => {
							const _load = !Array.isArray(data) && data.load;
							const _items = Array.isArray(data) ? data : data.items;

							if (_load) {
								contentLoadLog(4, 'chat received');

								dispatch.temp.setWaitingForSocketResponse({ key: 'chats', value: false });
								if (!store.temp.hasLoadedData?.chats) {
									dispatch.temp.setHasLoadedData('chats');
								}
							}

							if (_items.length > 0) {
								dispatch.chat.addMessages({
									load: _load,
									newMessages: _items
								});
							}
						});
					}
				}
			} catch (error) {
				errorLog('satrtChatSync', error);
			}
		},
		async loadChatMessagesDelta(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (store.auth.profile && activeSpace && isFeatureActive(activeSpace, 'chats')) {
					if (!store.temp.hasLoadedData || store.temp.hasLoadedData.chats) {
						contentLoadLog(3, 'chat already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.chats) {
						contentLoadLog(3, 'chat load already in progress. skipping');
						return;
					}

					const _lastRead =
						store.chat.lastChatRead && store.chat.lastChatRead[activeSpace.spaceId]
							? store.chat.lastChatRead[activeSpace.spaceId]
							: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading chat');
							dispatch.temp.setWaitingForSocketResponse({ key: 'chats', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'chatmessages',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									userId: store.auth.profile.userId,
									userInfos: store.auth.userInfos,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								})
							);

							contentLoadLog(2, 'loading chat');
							dispatch.temp.setWaitingForSocketResponse({ key: 'chats', value: true });

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/chatmessages`,
								method: 'POST',
								body
							});
							if (res && Array.isArray(res)) {
								dispatch.temp.setWaitingForSocketResponse({ key: 'chats', value: false });
								// dispatch.temp.setHasLoadedData('chats');

								if (res?.length > 0) {
									dispatch.chat.addMessages({ load: true, newMessages: res });
								}
							}

							break;
					}
				}
			} catch (error) {
				console.log('loadChatMessagesDelta', error);
			}
		},
		async stopChatSync(payload, store) {
			try {
				dispatch.socket.closeMultiSpaceEventListener({ event: 'chatmessage' });
			} catch (error) {
				console.log('stopChatSync', error);
			}
		},
		async sendChatMessage(payload: ISendChatMessagePayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { receiverId, senderId, content, tempId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							receiverId,
							senderId,
							content,
							target: null,
							type: 'chatMessage',
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/newchatmessage`,
						method: 'POST',
						body
					});

					if (res?.id) {
						const toAdd = [res];
						const tempMessage = store.chat.chatMessages.find((e) => e.id.toString() === tempId);
						if (tempMessage) {
							toAdd.push({ ...tempMessage, isDeleted: true });
						}
						dispatch.chat.addMessages({ load: false, newMessages: toAdd });
						return res;
					}

					showToast('error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('sendChatMessage', error);
				return undefined;
			}
		},
		async markChatMessagesAsRead(payload: IMarkChatMessagesAsRead, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { messageIds, partnerId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							messageIds,
							partnerId,
							userInfos: store.auth.userInfos,
							spaceId: activeSpace.spaceId
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/markchatmessagesasread`,
						method: 'POST',
						body
					});

					if (Array.isArray(res)) {
						dispatch.chat.addMessages({ load: false, newMessages: res });
						return;
					}
				}
			} catch (error) {
				console.log('markChatMessagesAsRead', error);
			}
		}
	})
});
