import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IMailHistory } from 'config/interfaces';
import moment from 'moment';
import { View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { MailHistoryList } from 'components/Mailing';
import { HSCard } from 'components/Card';
import { SearchBar } from 'components/SearchBar';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.EditSpace>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.EditSpace>;
type RouteParams = StackParamList[ERoutes.EditSpace];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mailhistory';

export const MailHistoryScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [histories, setHistories] = useState<IMailHistory[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _histories: typeof histories = [];

		if (activeSpace && content.mailhistories) {
			_histories = content.mailhistories.filter((e) => e.spaceId === activeSpace.spaceId);
			_histories.sort((a, b) => (moment(a.sentOn).isAfter(moment(b.sentOn)) ? -1 : 1));
		}

		setHistories(_histories);
	}, [content, activeSpace]);

	return (
		<ScreenContainer isProtectedRoute contentKey="mailtemplates">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
				</HSCard>

				<MailHistoryList TESTIDPREFIX={TESTIDPREFIX} items={histories} searchTerm={searchTerm} />
			</View>
		</ScreenContainer>
	);
};

export const MailHistoryScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Mail History')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
