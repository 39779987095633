import React from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import * as ImagePicker from 'expo-image-picker';

import { IEditSpaceFormErrors, IEditSpaceFormValues, TBackgroundImageName, TLogoImageName, TPreviewImageName } from 'config/interfaces';
import { FormColorPicker } from 'components/Form/FormColorPicker';
import { FormMediaPicker, FormMultiSwitch } from 'components/Form';
import { isEmptyString } from 'helper';
import { SectionHeader } from 'components/Text';
import { HSCard } from 'components/Card';

//default images
const spaceDefaultBlue = require('../../../../assets/images/spaceDefault/spaceDefaultBlue.png');
const spaceDefaultRed = require('../../../../assets/images/spaceDefault/spaceDefaultRed.png');
const spaceDefaultGreen = require('../../../../assets/images/spaceDefault/spaceDefaultGreen.png');
const spaceDefaultPurple = require('../../../../assets/images/spaceDefault/spaceDefaultPurple.png');

const gradientBlueLightblue = require('../../../../assets/images/gradient/gradientBlueLightblue.png');
const gradientLightblueOrange = require('../../../../assets/images/gradient/gradientLightblueOrange.png');
const gradientMagentaBlue = require('../../../../assets/images/gradient/gradientMagentaBlue.png');
const gradientOrangeMagenta = require('../../../../assets/images/gradient/gradientOrangeMagenta.png');

const defaultLogo = require('../../../../assets/images/defaultLogo/defaultLogo.png');
const defaultLogoGray = require('../../../../assets/images/defaultLogo/defaultLogoGray.png');

interface IEditSpaceDesignForm {
	values: IEditSpaceFormValues;
	errors: IEditSpaceFormErrors;
	onChange: (val: IEditSpaceFormValues) => void;
	isLoading?: boolean;
}
const TESTIDPREFIX = 'editspace';

export const EditSpaceDesignForm = (props: IEditSpaceDesignForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { t } = useTranslation();

	const _getPreviewImage = (imageName?: TPreviewImageName) => {
		if (imageName) {
			switch (imageName) {
				case 'spaceDefaultBlue':
					return spaceDefaultBlue;
				case 'spaceDefaultGreen':
					return spaceDefaultGreen;
				case 'spaceDefaultPurple':
					return spaceDefaultPurple;
				case 'spaceDefaultRed':
					return spaceDefaultRed;
				default:
					break;
			}
		}
		return undefined;
	};

	const _getBackgroundImage = (imageName?: TBackgroundImageName) => {
		if (imageName) {
			switch (imageName) {
				case 'gradientBlueLightblue':
					return gradientBlueLightblue;
				case 'gradientLightblueOrange':
					return gradientLightblueOrange;
				case 'gradientMagentaBlue':
					return gradientMagentaBlue;
				case 'gradientOrangeMagenta':
					return gradientOrangeMagenta;
				default:
					break;
			}
		}
		return undefined;
	};

	const _getLogo = (imageName?: TLogoImageName) => {
		if (imageName) {
			switch (imageName) {
				case 'defaultLogoGray':
					return defaultLogoGray;
				case 'defaultLogo':
				case 'default':
				default:
					return defaultLogo;
			}
		}
		return undefined;
	};

	const _getLayoutOptions = () => {
		return [
			{
				key: 'stacked',
				label: t('Centered')
			},
			{
				key: 'thirds_1/3_2/3',
				label: t('Left 1/3 | Right 2/3')
			},
			{
				key: 'thirds_2/3_1/3',
				label: t('Left 2/3 | Right 1/3')
			}
		];
	};

	return (
		<View>
			<HSCard>
				<SectionHeader
					label={t('Colors')}
					action={{ label: t('Preview'), testID: `${TESTIDPREFIX}_button_designpreview` }}
					isLoading={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_primarycolor`}
					onChange={(value) => onChange({ ...values, primaryColor: value })}
					value={values.primaryColor}
					label={t('primaryColor')}
					hint={`${t('primaryColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.primaryColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_primarycontrastcolor`}
					onChange={(value) => onChange({ ...values, primaryContrastColor: value })}
					value={values.primaryContrastColor}
					label={t('primaryContrastColor')}
					hint={`${t('primaryContrastColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.primaryContrastColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_textcolor`}
					onChange={(value) => onChange({ ...values, textColor: value })}
					value={values.textColor}
					label={t('textColor')}
					hint={`${t('textColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.textColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_navigationtextcolor`}
					onChange={(value) => onChange({ ...values, navigationTextColor: value })}
					value={values.navigationTextColor}
					label={t('navigationTextColor')}
					hint={`${t('navigationTextColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.navigationTextColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_successcolor`}
					onChange={(value) => onChange({ ...values, successColor: value })}
					value={values.successColor}
					label={t('successColor')}
					hint={`${t('successColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.successColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_warningcolor`}
					onChange={(value) => onChange({ ...values, warningColor: value })}
					value={values.warningColor}
					label={t('warningColor')}
					hint={`${t('warningColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.warningColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_dangercolor`}
					onChange={(value) => onChange({ ...values, dangerColor: value })}
					value={values.dangerColor}
					label={t('dangerColor')}
					hint={`${t('dangerColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.dangerColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_backgroundcolor`}
					onChange={(value) => onChange({ ...values, backgroundColor: value })}
					value={values.backgroundColor}
					label={t('backgroundColor')}
					hint={`${t('backgroundColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.backgroundColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_contentbackgroundcolor`}
					onChange={(value) => onChange({ ...values, contentBackgroundColor: value })}
					value={values.contentBackgroundColor}
					label={t('contentBackgroundColor')}
					hint={`${t('contentBackgroundColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.contentBackgroundColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_contrastcolor`}
					onChange={(value) => onChange({ ...values, contrastColor: value })}
					value={values.contrastColor}
					label={t('contrastColor')}
					hint={`${t('contrastColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.contrastColor}
					isDisabled={isLoading}
				/>
				<FormColorPicker
					testID={`${TESTIDPREFIX}_colorpicker_graycolor`}
					onChange={(value) => onChange({ ...values, grayColor: value })}
					value={values.grayColor}
					label={t('grayColor')}
					hint={`${t('grayColorDescription')} ${t('ColorPickerHint')}`}
					error={errors.grayColor}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_showgradientonmissingimage`}
					label={t('showGradientOnMissingImage')}
					hint={t('showGradientOnMissingImageHint')}
					error={errors.showGradientOnMissingImage}
					value={values.showGradientOnMissingImage ? 'yes' : 'no'}
					options={[
						{
							key: 'yes',
							label: t('Yes')
						},
						{
							key: 'no',
							label: t('No')
						}
					]}
					onChange={(val) => onChange({ ...values, showGradientOnMissingImage: val === 'yes' })}
					isDisabled={isLoading}
				/>
			</HSCard>
			<HSCard>
				<SectionHeader
					label={t('Layout')}
					action={{ label: t('Preview'), testID: `${TESTIDPREFIX}_button_layoutpreview` }}
					isLoading={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_layoutweb`}
					label={t('WebLayout')}
					hint={t('WebLayoutHint')}
					error={errors.webLayout}
					value={values.webLayout}
					options={[
						{
							key: 'cards',
							label: t('Cards')
						},
						{
							key: 'list',
							label: t('List')
						}
					]}
					onChange={(val) => onChange({ ...values, webLayout: val === 'list' ? 'list' : 'cards' })}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_layoutapp`}
					label={t('AppLayout')}
					hint={t('AppLayoutHint')}
					error={errors.appLayout}
					value={values.appLayout}
					options={[
						{
							key: 'cards',
							label: t('Cards')
						},
						{
							key: 'list',
							label: t('List')
						}
					]}
					onChange={(val) => onChange({ ...values, appLayout: val === 'list' ? 'list' : 'cards' })}
					isDisabled={isLoading}
				/>
			</HSCard>
			<HSCard>
				<SectionHeader
					label={t('Detail')}
					action={{ label: t('Preview'), testID: `${TESTIDPREFIX}_button_detailpreview` }}
					isLoading={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_expodetailviewtype`}
					label={t('expoDetailViewType')}
					hint={t('expoDetailViewTypeHint')}
					error={errors.expoDetailViewType}
					value={values.expoDetailViewType}
					options={_getLayoutOptions()}
					onChange={(val) =>
						onChange({
							...values,
							expoDetailViewType:
								val === 'thirds_1/3_2/3' ? 'thirds_1/3_2/3' : val === 'thirds_2/3_1/3' ? 'thirds_2/3_1/3' : 'stacked'
						})
					}
					isDisabled={isLoading}
				/>
				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_speakerdetailviewtype`}
					label={t('speakerDetailViewType')}
					hint={t('speakerDetailViewTypeHint')}
					error={errors.speakerDetailViewType}
					value={values.speakerDetailViewType}
					options={_getLayoutOptions()}
					onChange={(val) =>
						onChange({
							...values,
							speakerDetailViewType:
								val === 'thirds_1/3_2/3' ? 'thirds_1/3_2/3' : val === 'thirds_2/3_1/3' ? 'thirds_2/3_1/3' : 'stacked'
						})
					}
					isDisabled={isLoading}
				/>

				{/* 
					Maybe enable this later
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_attendeedetailviewtype`}
						label={t('attendeeDetailViewType')}
						hint={t('attendeeDetailViewTypeHint')}
						error={errors.attendeeDetailViewType}
						value={values.attendeeDetailViewType}
						options={[
							{
								key: 'stacked',
								label: t('Stacked')
							},
							{
								key: 'thirds',
								label: t('Thirds')
							}
						]}
						onChange={(val) => onChange({ ...values, attendeeDetailViewType: val === 'stacked' ? 'stacked' : 'thirds' })}
						isDisabled={isLoading}
					/> */}

				<FormMultiSwitch
					testID={`${TESTIDPREFIX}_multiswitch_detailreferencesviewtype`}
					label={t('detailReferencesViewType')}
					hint={t('detailReferencesViewTypeHint')}
					error={errors.detailReferencesViewType}
					value={values.detailReferencesViewType}
					options={[
						{
							key: 'wideCards',
							label: t('wideCards')
						},
						{
							key: 'cards',
							label: t('Cards')
						}
					]}
					onChange={(val) =>
						onChange({
							...values,
							detailReferencesViewType: val === 'wideCards' ? 'wideCards' : 'cards'
						})
					}
					isDisabled={isLoading}
				/>
			</HSCard>
			<HSCard>
				<SectionHeader
					label={t('Media')}
					action={{ label: t('Preview'), testID: `${TESTIDPREFIX}_button_designpreview` }}
					isLoading={isLoading}
				/>
				<FormMediaPicker
					label={t('Preview Image')}
					hint={t('previewImageHint')}
					testID={`${TESTIDPREFIX}_imagepicker_previewimage`}
					aspectRatio="16/9"
					value={!isEmptyString(values.previewImage) ? values.previewImage : _getPreviewImage(values.previewImageName)}
					error={errors.previewImage}
					onChangePreview={(val) => onChange({ ...values, previewImageName: val, previewImage: null })}
					onChange={(val) => {
						if (val === null) {
							let _values = { ...values };
							_values.previewImage = val;
							_values.previewImageName = '';
							onChange({ ..._values });
						} else {
							onChange({ ...values, previewImage: val });
						}
					}}
					previewImagesFor={'previewImage'}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					isDisabled={isLoading}
					isOutline
					hasDeleteButton
				/>
				<FormMediaPicker
					label={t('Logo Image')}
					testID={`${TESTIDPREFIX}_imagepicker_logoimage`}
					aspectRatio="16/9"
					value={!isEmptyString(values.logoImage) ? values.logoImage : _getLogo(values.logoImageName)}
					error={errors.logoImage}
					onChangePreview={(val) => onChange({ ...values, logoImageName: val, logoImage: null })}
					onChange={(val) => {
						if (val === null) {
							let _values = { ...values };
							_values.logoImage = val;
							_values.logoImageName = '';
							onChange({ ..._values });
						} else {
							onChange({ ...values, logoImage: val });
						}
					}}
					previewImagesFor={'logoImage'}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					isDisabled={isLoading}
					isOutline
					hasDeleteButton
				/>
				<FormMediaPicker
					label={t('Square Image')}
					testID={`${TESTIDPREFIX}_imagepicker_squareimage`}
					aspectRatio="1/1"
					value={values.squareImage}
					error={errors.squareImage}
					onChangePreview={(val) => onChange({ ...values, squareImage: val })}
					onChange={(val) => {
						if (val === null) {
							let _values = { ...values };
							_values.squareImage = val;
							onChange({ ..._values });
						} else {
							onChange({ ...values, squareImage: val });
						}
					}}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					isDisabled={isLoading}
					isOutline
					hasDeleteButton
				/>
				<FormMediaPicker
					label={t('Background Image')}
					testID={`${TESTIDPREFIX}_imagepicker_backgroundimage`}
					aspectRatio="9/16"
					value={
						!isEmptyString(values.backgroundImage) ? values.backgroundImage : _getBackgroundImage(values.backgroundImageName)
					}
					error={errors.backgroundImage}
					onChangePreview={(val) => onChange({ ...values, backgroundImageName: val, backgroundImage: null })}
					onChange={(val) => {
						if (val === null) {
							let _values = { ...values };
							_values.backgroundImage = val;
							_values.backgroundImageName = '';
							onChange({ ..._values });
						} else {
							onChange({ ...values, backgroundImage: val });
						}
					}}
					previewImagesFor={'backgroundImage'}
					mediaTypes={ImagePicker.MediaTypeOptions.Images}
					isDisabled={isLoading}
					isOutline
					hasDeleteButton
				/>
			</HSCard>
		</View>
	);
};
