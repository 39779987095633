import { AttendeeList } from '../../Attendee/AttendeeList';
import { IAttendee } from 'config/interfaces';
import React, { useState } from 'react';
import { HSModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { isEmptyString } from 'helper';
import { ContentListHeader } from 'components/List';

interface IPickAttendeeModal {
	isVisible: boolean;
	onClose: () => void;
	onPress: (item: IAttendee) => void;
	filter?: 'chat' | 'meeting';
}

const TESTIDPREFIX = 'pickattendeemodal';

export const PickAttendeeModal = (props: IPickAttendeeModal) => {
	const { isVisible, onClose, onPress, filter } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [searchTerm, setSearchTerm] = useState<string>('');

	return (
		<HSModal isVisible={isVisible} onClose={onClose} title={t('CTA Select Attendee')}>
			<ContentListHeader testID={TESTIDPREFIX} type="attendee" searchTerm={searchTerm} onSearchChange={(val) => setSearchTerm(val)} />
			<AttendeeList
				testID={TESTIDPREFIX}
				onPress={onPress}
				filter={filter}
				noCard={isEmptyString(theme.contentBackgroundColor) || theme.contentBackgroundColor === theme.background}
				noSponsors
				isModal
				searchTerm={searchTerm}
			/>
		</HSModal>
	);
};
