import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { EDefaultIconSet } from 'helper';
import { useTheme } from 'hooks/useTheme';

interface IVoteMultipleChoiceOption {
	testID: string;
	isActive?: boolean;
	label?: string;
	onPress?: () => void;
	isCorrect?: boolean;
	isFalse?: boolean;
}

export const VoteMultipleChoiceOption = (props: IVoteMultipleChoiceOption) => {
	const { testID, isActive, label, onPress, isCorrect, isFalse } = props;
	const { theme } = useTheme();

	const _getBackgroundColor = () => {
		if (isCorrect) {
			return theme.success;
		}

		if (isFalse) {
			return theme.danger;
		}

		return theme.contentBackgroundColor ?? theme.background;
	};

	const _getTextColor = () => {
		if (isCorrect || isFalse) {
			return theme.white;
		}

		return isActive ? theme.primary : theme.text;
	};

	return (
		<HSCard key={testID} style={{ backgroundColor: _getBackgroundColor() }}>
			<ChildButton
				isDisabled={!onPress}
				style={{ flexDirection: 'row', alignItems: 'center' }}
				testID={`${testID}_item`}
				onPress={() => {
					if (onPress) {
						onPress();
					}
				}}
			>
				<View style={{ flex: 1, paddingRight: 10 }}>
					<Text bold={isActive} style={{ color: _getTextColor() }}>
						{label}
					</Text>
				</View>
				<RoundButton
					testID={testID}
					onPress={() => {
						if (onPress) {
							onPress();
						}
					}}
					icon={isActive ? EDefaultIconSet.Save : ''}
					isOutline={!isActive}
					color={isCorrect || isFalse ? theme.white : undefined}
					iconColor={isCorrect || isFalse ? (isCorrect ? theme.success : theme.danger) : undefined}
					size="sm"
					alignSelf="center"
					noMargin
				/>
			</ChildButton>
		</HSCard>
	);
};
