import { FormLabel } from 'components/Form/FormLabel';
import { MeetingTimeSelect } from 'components/Meeting';
import { Spinner } from 'components/Spinner';
import { StepWizard } from 'components/StepWizard';
import { EMeetingOnsiteType, EMeetingType, IAttendee, IMeetingRequest, ITimeSlot } from 'config/interfaces';
import { isEmptyString, IS_WEB } from 'helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Text } from 'components/Text';
import moment from 'moment';
import { FormCalendarPicker } from 'components/Form/FormCalendarPicker';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormMultiSwitch, IKVP } from 'components/Form/FormMultiSwitch';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { FormHint } from 'components/Form/FormHint';

interface IMeetingWizard {
	onSubmit: () => void;
	isLoading: boolean;
	values: IMeetingRequest;
	meetingPartner?: IAttendee;
	onChange: (values: IMeetingRequest) => void;
}

const TESTIDPREFIX = 'meetingwizard';

export const MeetingWizard = (props: IMeetingWizard) => {
	const { onSubmit, isLoading, values, meetingPartner, onChange } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { isPhone, isTablet, isTabletOrMobile } = useQuery();

	const [stepIndex, setStepIndex] = useState<number>(0);
	const [selectedDate, setSelectedDate] = useState<string | null | undefined>(undefined);
	const [hasSelection, setHasSelection] = useState<boolean>(false);
	const [availablePartnerTimes, setAvailablePartnerTimes] = useState<ITimeSlot[]>([]);
	const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState<boolean>(false);
	const [hasMeetingTables, setHasMeetingTables] = useState<boolean>(false);
	const [hasClearedText, setHasClearedText] = useState<boolean>(false);
	const [meetingTypes, setMeetingTypes] = useState<IKVP[]>([]);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const content = useSelector((store: IRootState) => store.content.content);

	const loadAvailablePartnerTimes = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.loadAvailablePartnerTimes);

	useEffect(() => {
		let _hasMeetingTables = false;

		if (content.meetingtables) {
			_hasMeetingTables = content.meetingtables.filter((e) => e.spaceId === activeSpace?.spaceId && !e.isDeleted).length > 0;
		}
		if (_hasMeetingTables) {
			onChange({ ...values, meetingOnsiteType: EMeetingOnsiteType.Table });
		}

		setHasMeetingTables(_hasMeetingTables);
	}, [content, activeSpace]);

	useEffect(() => {
		let today = moment();
		let from = moment(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate);
		let until = moment(activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate);
		if (today.isSameOrAfter(from) && today.isSameOrBefore(until)) {
			setSelectedDate(today.toISOString());
			setHasSelection(true);
		} else {
			if (until && until.isBefore(today)) {
				setSelectedDate(null);
				setHasSelection(false);
			} else {
				setSelectedDate(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate);
				setHasSelection(true);
			}
		}
	}, [activeSpace]);

	useEffect(() => {
		_loadAvailablePartnerTimes();
	}, [selectedDate]);

	useEffect(() => {
		let _types: IKVP[] = [];
		if (activeSpace) {
			const type = activeSpace?.meetingSettings?.meetingType;
			if (isEmptyString(type)) {
				_types.push(
					{
						key: EMeetingType.Onsite,
						label: t('Onsite')
					},
					{
						key: EMeetingType.Remote,
						label: t('Remote')
					}
				);
			} else {
				if (type?.includes(EMeetingType.Onsite)) {
					_types.push({
						key: EMeetingType.Onsite,
						label: t('Onsite')
					});
				}
				if (type?.includes(EMeetingType.Remote)) {
					_types.push({
						key: EMeetingType.Remote,
						label: t('Remote')
					});
				}
			}
		}
		setMeetingTypes(_types);
	}, [activeSpace]);

	const _loadAvailablePartnerTimes = async () => {
		if (selectedDate && meetingPartner) {
			setIsLoadingTimeSlots(true);
			const res = await loadAvailablePartnerTimes({ partnerId: meetingPartner.userId, date: selectedDate });
			const filtered = res.filter((r) => moment().isBefore(r.start));
			setIsLoadingTimeSlots(false);
			if (filtered) {
				setAvailablePartnerTimes(filtered);
			}
		}
	};

	const _isNextDisabled = () => {
		switch (stepIndex) {
			case 0:
				return isEmptyString(selectedDate) || !hasSelection;
			case 1:
				return !values.timeSlot;
			case 2:
				return (
					isEmptyString(values.content) ||
					isEmptyString(values.meetingType) ||
					(values.meetingType === EMeetingType.Onsite &&
						(!values.meetingOnsiteType ||
							(values.meetingOnsiteType === EMeetingOnsiteType.Individual && isEmptyString(values.meetingOnsiteLocation))))
				);
			default:
				return false;
		}
	};

	const _renderCalenderPicker = () => {
		let _from = activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate;
		let _until = activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate;
		return (
			<View>
				<FormLabel testID={`${TESTIDPREFIX}_calendarpicker_label`} label={t('Date')} isRequired />
				<FormHint testID={`${TESTIDPREFIX}_calendarpicker_hint`} hint={t('MeetingDateHint')} />
				<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
					<FormCalendarPicker
						testID={`${TESTIDPREFIX}_calendarpicker`}
						startDate={selectedDate}
						isDisabled={isLoadingTimeSlots}
						onStartDateChange={(val) => setSelectedDate(val)}
						minDate={moment().isSameOrAfter(_from) && moment().isSameOrBefore(_until) ? moment().toISOString() : _from}
						maxDate={_until}
						initialDate={moment().isSameOrAfter(_from) && moment().isSameOrBefore(_until) ? moment().toISOString() : _from}
						notPossible={!hasSelection}
						formStyle={{ width: isPhone || (IS_WEB && isTabletOrMobile) ? '100%' : isTablet ? '50%' : '60%' }}
					/>

					<View
						style={{
							width: isPhone || (IS_WEB && isTabletOrMobile) ? '100%' : isTablet ? '45%' : '40%',
							height: IS_WEB && !isTabletOrMobile ? 220 : undefined
						}}
					>
						<ScrollView>{_renderTimeSelection()}</ScrollView>
					</View>
				</View>
			</View>
		);
	};

	const _renderTimeSelection = () => {
		if (isLoadingTimeSlots) {
			return <Spinner />;
		}
		if (selectedDate) {
			return (
				<MeetingTimeSelect
					date={selectedDate}
					availablePartnerTimes={availablePartnerTimes}
					unavailableTimes={profile?.unavailable?.meetingSlots}
					selected={values.timeSlot ? [values.timeSlot] : []}
					onSelect={(timeSlot) => onChange({ ...values, timeSlot: timeSlot as ITimeSlot })}
				/>
			);
		}
		return null;
	};

	const _renderMeetingRequest = () => {
		return (
			<View>
				{values.timeSlot && (
					<>
						<FormLabel testID={`${TESTIDPREFIX}_label_when`} label={`${t('When')}:`} style={{ marginBottom: 5 }} />
						<Text style={{ marginBottom: 10 }}>{`${moment(values.timeSlot.start).format('DD.MM.YYYY HH:mm')} - ${moment(
							values.timeSlot.end
						).format('HH:mm')}`}</Text>
					</>
				)}
				<FormTextInput
					onFocus={() => {
						if (!hasClearedText) {
							setHasClearedText(true);
							onChange({ ...values, content: '' });
						}
					}}
					testID={`${TESTIDPREFIX}_textinput_content`}
					label={t('Message')}
					isRequired
					placeholder={t('MeetingRequestMessagePlaceholder').replace(
						'%NAME%',
						`${meetingPartner?.firstName} ${meetingPartner?.lastName}`
					)}
					multiline
					scrollEnabled={false}
					style={IS_WEB ? {} : { maxHeight: 10000, height: 'auto', flexGrow: 1 }}
					containerStyle={IS_WEB ? {} : { maxHeight: 10000, height: 'auto', flexGrow: 1 }}
					value={values.content}
					onChangeText={(content) => onChange({ ...values, content })}
				/>
				{!values.id && (
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_meetingtype`}
						label={t('MeetingRequestMeetingType')}
						isRequired
						value={values.meetingType}
						hint={t(`${values.meetingType}MeetingTypeHint`)}
						onChange={(meetingType) => onChange({ ...values, meetingType: meetingType as EMeetingType })}
						options={meetingTypes}
					/>
				)}
				{hasMeetingTables && values.meetingType === EMeetingType.Onsite && (
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_meetingOnsiteType`}
						label={t('MeetingRequestMeetingOnsiteType')}
						isRequired
						value={values.meetingOnsiteType}
						onChange={(meetingOnsiteType) =>
							onChange({ ...values, meetingOnsiteType: meetingOnsiteType as EMeetingOnsiteType })
						}
						formStyle={{ marginBottom: 10 }}
						hint={values.meetingOnsiteType ? t(`${values.meetingOnsiteType}OnsiteHint`) : undefined}
						options={[
							{
								key: EMeetingOnsiteType.Table,
								label: t('Meeting Table')
							},
							{
								key: EMeetingOnsiteType.Individual,
								label: t('Individual')
							}
						]}
					/>
				)}
				{(!hasMeetingTables || values.meetingOnsiteType === EMeetingOnsiteType.Individual) &&
					values.meetingType === EMeetingType.Onsite && (
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_meetingonsitelocation`}
							isRequired
							label={!hasMeetingTables ? t('MeetingRequestMeetingOnsiteType') : undefined}
							placeholder={hasMeetingTables ? t('MeetingRequestOnsiteLocationHint') : undefined}
							multiline
							scrollEnabled={false}
							style={IS_WEB ? {} : { maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							containerStyle={IS_WEB ? {} : { maxHeight: 10000, height: 'auto', flexGrow: 1 }}
							value={values.meetingOnsiteLocation}
							onChangeText={(meetingOnsiteLocation) => onChange({ ...values, meetingOnsiteLocation })}
							maxLength={255}
						/>
					)}
			</View>
		);
	};

	return (
		<StepWizard
			testIdPrefix={TESTIDPREFIX}
			getCurrentIndex={(val) => setStepIndex(val)}
			completeFunction={onSubmit}
			isLoading={isLoading}
			isNextButtonDisabled={_isNextDisabled()}
			values={values}
			components={[_renderCalenderPicker(), _renderMeetingRequest()]}
		/>
	);
};
