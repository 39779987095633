import React from 'react';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet } from 'helper/icon';
import { openURL } from 'helper/url';

interface INavigationHeaderHelpComponent {
	url?: string;
}

export const NavigationHeaderHelpButton = (props: INavigationHeaderHelpComponent) => {
	const { url } = props;
	const { theme } = useTheme();

	let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
	let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
	let color = primary === bg ? theme.navigationTextColor : theme.navbarButton;

	return (
		<NavigationHeaderIconButton
			testID="header_button_help"
			icon={EDefaultIconSet.Help}
			color={color}
			onPress={() => {
				openURL(url ?? 'https://forum.hellospaces.de/');
			}}
		/>
	);
};
