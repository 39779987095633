import React, { ReactNode, useState, useEffect } from 'react';
import { View } from 'react-native';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, getDefaultOptionsVote, getDefaultVotingHint, getDefaultVotingOptions, getDefaultVotingQuestion } from 'helper';
import { FullScreenLoading } from 'components/Loading';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { FloatingView } from 'components/FloatingView';
import { useNavigation } from '@react-navigation/core';
import { t } from 'i18next';
import { ICreateVotePayload } from 'config/interfaces';
import { v4 } from 'uuid';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { ERoutes } from 'components/Navigation/routes';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';

interface IStepWizard {
	onNext?: (currentIdx: number) => any;
	onBack?: (currentIdx: number) => any;
	completeFunction: (currentIdx: number) => void;
	getCurrentIndex?: (currentIdx: number) => void;
	testIdPrefix: string;
	components: ReactNode[];
	isLoading: boolean;
	isNextButtonDisabled?: boolean;
	hideBackButtonOnLastStep?: boolean;
	customCurrentIndex?: number;
	hideNextButton?: boolean;
	spinnerOnLoad?: boolean;
	values?: any;
	nextButtonText?: string;
	needsWidthAndPadding?: boolean;
}

export const StepWizard = (props: IStepWizard) => {
	const {
		components,
		testIdPrefix,
		completeFunction,
		isLoading,
		onNext,
		onBack,
		isNextButtonDisabled,
		hideBackButtonOnLastStep,
		getCurrentIndex,
		customCurrentIndex,
		hideNextButton,
		spinnerOnLoad,
		values,
		nextButtonText,
		needsWidthAndPadding
	} = props;
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const { screenWidth } = useQuery();

	const [isCreateVoteLoading, setIsCreateVoteLoading] = useState<boolean>(false);

	const [currentIdx, setCurrentIdx] = useState<number>(0);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const createVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.createVote);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const isModal = testIdPrefix.includes('modal');

	const deMap = require('../../assets/images/mapExample/DE-Karte.png');

	useEffect(() => {
		if (getCurrentIndex) {
			getCurrentIndex(currentIdx);
		}
	}, [currentIdx]);

	useEffect(() => {
		if (customCurrentIndex !== undefined && customCurrentIndex !== null) {
			if (customCurrentIndex === currentIdx) {
				return;
			}
			setCurrentIdx(customCurrentIndex);
		}
	}, [customCurrentIndex]);

	const _createExampleVoting = async () => {
		setIsCreateVoteLoading(true);
		const createVotePayload: ICreateVotePayload = {
			data: {
				space: activeSpace,
				publicId: v4(),
				question: getDefaultVotingQuestion(values?.votingType),
				hint: getDefaultVotingHint(values?.votingType),
				isActive: true,
				votingType: values?.votingType,
				minValue: values?.votingType === 'multipleChoice' || values?.votingType === 'wordCloud' ? 1 : 0,
				maxValue: values?.votingType === 'multipleChoice' ? 1 : values?.votingType === 'rating' ? 5 : 10,
				options: getDefaultVotingOptions(values?.votingType),
				optionsVote: getDefaultOptionsVote(values?.votingType),
				minValueText: values?.votingType === 'nps' ? t('notLikely') : undefined,
				maxValueText: values?.votingType === 'nps' ? t('veryLikely') : undefined,
				media: values?.votingType === 'pinOnImage' ? [{ media: deMap, order: 0 }] : undefined,
				useAvatar: values?.votingType === 'pinOnImage' ? true : undefined,
				allowAnonymousAnswers: values?.votingType === 'pinOnImage' ? 'never' : undefined,
				allowMultipleAnswers: values?.votingType === 'pinOnImage' ? false : undefined,
				iconColor: undefined,
				requestReason: values?.votingType === 'nps' ? true : undefined,
				requestReasonOptions: values?.votingType === 'nps' ? JSON.stringify([0, 1, 2, 3, 4, 5, 6, 7]) : undefined
			},
			noToast: true
		};
		const res = await createVote(createVotePayload);
		setIsCreateVoteLoading(false);

		if (res) {
			navigation.replace(ERoutes.ActiveVotingAdmin, { spaceId: activeSpace?.spaceId, id: res.id });
		}
	};

	const renderComponent = () => {
		if (isLoading && spinnerOnLoad) {
			return <FullScreenLoading />;
		}

		if (components && components[currentIdx]) {
			return (
				<View
					style={{ flex: 1, paddingBottom: SCROLL_PADDING_FLOATING }}
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				>
					{components[currentIdx]}
				</View>
			);
		}

		return null;
	};

	const _renderPrevButton = () => {
		if ((hideBackButtonOnLastStep && currentIdx === components.length - 1) || values?.votingType === 'externalUrl') {
			return null;
		}

		if (currentIdx === 0 && values?.votingType) {
			let votingType = values.votingType === 'text' ? 'chat' : values.votingType;
			let btnText = t('Create Example').replace('%TYPE%', t(votingType));
			if ((containerWidth < 380 && btnText.includes(' ')) || (btnText.split(' ').length > 2 && containerWidth < 500)) {
				let _splitted = btnText.split(' ');
				switch (_splitted.length) {
					case 3:
						btnText =
							_splitted[0].length + _splitted[1].length > _splitted[2].length
								? _splitted[0] + ' ' + _splitted[1] + '\n' + _splitted[2]
								: _splitted[0] + '\n' + _splitted[1] + ' ' + _splitted[2];
						break;
					case 4:
						btnText = _splitted[0] + ' ' + _splitted[1] + '\n' + _splitted[2] + ' ' + _splitted[3];
						break;
					case 5:
						btnText =
							_splitted[0].length + _splitted[1].length + _splitted[2].length + 3 >
							_splitted[3].length + _splitted[4].length + 2
								? _splitted[0] + ' ' + _splitted[1] + '\n' + _splitted[2] + ' ' + _splitted[3] + ' ' + _splitted[4]
								: _splitted[0] + ' ' + _splitted[1] + ' ' + _splitted[2] + '\n' + _splitted[3] + ' ' + _splitted[4];
						break;
					default:
						btnText = btnText.replace(' ', '\n');
						break;
				}
			}
			return (
				<RoundButton
					isFloatingButton
					size="sm"
					testID="stepwizard_button_createexample"
					onPress={() => {
						showAlert({
							title: t('CreateExampleVotingTitle'),
							message: t('CreateExampleVotingSubtitle').replace('%TYPE%', t(votingType)),
							buttons: [
								{
									text: t('Cancel'),
									style: 'cancel'
								},
								{
									text: t('CreateExampleVotingTitle'),
									onPress: async () => _createExampleVoting()
								}
							]
						});
					}}
					icon={EDefaultIconSet.HelloSpaces}
					isOutline
					title={btnText}
					isLoading={isCreateVoteLoading}
				/>
			);
		}

		if (currentIdx > 0) {
			return (
				<RoundButton
					isFloatingButton
					size="sm"
					testID="stepwizard_button_back"
					onPress={async () => {
						if (currentIdx === 0) {
							if (navigation.canGoBack()) {
								navigation.goBack();
							}
						} else {
							let errors = false;
							if (onBack) {
								errors = await onBack(currentIdx);
							}
							if (!errors) {
								setCurrentIdx(currentIdx - 1);
							}
						}
					}}
					icon={EDefaultIconSet.ArrowLeft}
					title={t('Back')}
				/>
			);
		}

		return null;
	};

	const _renderNextButton = () => {
		if (!hideNextButton) {
			const isLast = currentIdx >= components?.length - 1;
			let _nextButtonText = nextButtonText;
			if (
				(containerWidth < 380 && _nextButtonText?.includes(' ')) ||
				(_nextButtonText && _nextButtonText?.split(' ').length > 2 && containerWidth < 500)
			) {
				let _splitted = _nextButtonText.split(' ');
				switch (_splitted.length) {
					case 3:
						_nextButtonText =
							_splitted[0].length + _splitted[1].length > _splitted[2].length
								? _splitted[0] + ' ' + _splitted[1] + '\n' + _splitted[2]
								: _splitted[0] + '\n' + _splitted[1] + ' ' + _splitted[2];
						break;
					case 4:
						_nextButtonText = _splitted[0] + ' ' + _splitted[1] + '\n' + _splitted[2] + ' ' + _splitted[3];
						break;
					default:
						_nextButtonText = _nextButtonText.replace(' ', '\n');
						break;
				}
			}
			return (
				<RoundButton
					isFloatingButton
					size="sm"
					testID="stepwizard_button_continue"
					isLoading={isLoading}
					title={_nextButtonText ?? t('Next')}
					isDisabled={isNextButtonDisabled || isCreateVoteLoading}
					icon={isLast ? EDefaultIconSet.Save : EDefaultIconSet.ArrowRight}
					onPress={async () => {
						if (isLast) {
							completeFunction(currentIdx);
						} else {
							let errors = false;
							if (onNext) {
								errors = await onNext(currentIdx);
							}
							if (!errors) {
								setCurrentIdx(currentIdx + 1);
							}
						}
					}}
				/>
			);
		}

		return null;
	};

	const _renderActionButtons = () => {
		return (
			<>
				<FloatingView isModal={isModal} alignSelf="left">
					{_renderPrevButton()}
				</FloatingView>
				<FloatingView isModal={isModal} alignSelf="right">
					{_renderNextButton()}
				</FloatingView>
			</>
		);
	};

	if (components?.length > 0) {
		return (
			<View
				style={{
					flex: 1
				}}
			>
				{renderComponent()}
				<View
					style={{
						marginTop: 5,
						width: needsWidthAndPadding ? screenWidth : '100%',
						alignSelf: 'center',
						paddingHorizontal: needsWidthAndPadding ? EHorizontalScreenPadding.Wide : 0
					}}
				>
					{_renderActionButtons()}
				</View>
			</View>
		);
	}

	return null;
};
