import React, { useEffect, useState } from 'react';

import { HSCard } from 'components/Card';
import { IAttendee, ISupportRequest } from 'config/interfaces';
import { H2, Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { ChildButton, RoundButton } from 'components/Button';
import { View } from 'react-native';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { FormCheckbox } from 'components/Form';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { Icon } from 'components/Icon';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { AttendeeListItem } from 'components/Attendee';

interface ISupportRequestListItem {
	testID: string;
	item: ISupportRequest;
	isSelected: boolean;
	onPress: () => void;
	onSelect?: () => void;
	onDelete?: () => void;
	onEdit?: () => void;
	isDeleteLoading?: boolean;
}

export const SupportRequestListItem = (props: ISupportRequestListItem) => {
	const { testID, item, isSelected, onPress, onSelect, onDelete, onEdit, isDeleteLoading } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		if (attendees && item) {
			const _attendee = attendees.find((a) => a.userId === item.userId);
			if (_attendee) {
				setAttendee(_attendee);
			}
		}
	}, [attendees, item]);

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isDeleteLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isDeleteLoading}
						/>
					)}
				</View>
			);
		}
		if (onEdit && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	if (item) {
		return (
			<ChildButton testID={testID} onPress={onPress}>
				<HSCard>
					<View style={{ flexDirection: 'row' }}>
						<Text>
							#{item?.id} {attendee ? '|' : ''} {attendee?.firstName ?? ''} {attendee?.lastName ?? ''}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{onSelect && (
							<View style={{ paddingRight: 10 }}>
								<FormCheckbox
									testID={`${testID}_checkbox_select`}
									value={isSelected}
									onPress={() => onSelect()}
									style={{ marginBottom: 0 }}
								/>
							</View>
						)}
						<View style={{ flex: 1 }}>
							<View
								style={{
									flex: 1,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: isEmptyString(item.subject) ? 'flex-end' : 'space-between'
								}}
							>
								{!isEmptyString(item.subject) && (
									<Text bold numberOfLines={1}>
										{item.subject}
									</Text>
								)}
								<View>
									<Text style={{ fontSize: 12, textAlign: 'right' }}>
										{t('Opened')}: {moment(item.created_at).format('DD.MM HH:mm')}
									</Text>
									{item?.closed_at && (
										<Text style={{ fontSize: 12, textAlign: 'right' }}>
											{t('Closed')}: {moment(item.closed_at).format('DD.MM HH:mm')}
										</Text>
									)}
								</View>
							</View>
							<Text numberOfLines={2}>{item.text}</Text>
						</View>
						{_renderActions()}
					</View>
				</HSCard>
			</ChildButton>
		);
	}
	return null;
};
