import React, { ReactNode } from 'react';
import { ISpaceEmailOrTicketcodeFormValues, ISpaceEmailOrTicketcodeFormErrors } from 'config/interfaces';
import { FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSpace } from 'hooks/useSpace';
import { Markdown } from 'components/Markdown';
import { isEmptyString } from 'helper';

interface ISpaceEmailOrTicketcode {
	values: ISpaceEmailOrTicketcodeFormValues;
	errors: ISpaceEmailOrTicketcodeFormErrors;
	onChange: (val: ISpaceEmailOrTicketcodeFormValues) => void;
	onSubmit?: () => void;
	isLoading: boolean;
	ticketRequired?: boolean;
	loginIntro?: string;
}

const TESTIDPREFIX = 'emailorticketcode';

export const SpaceEmailOrTicketcodeForm = (props: ISpaceEmailOrTicketcode) => {
	const { values, errors, onChange, onSubmit, isLoading, ticketRequired, loginIntro } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const _renderLoginIntro = () => {
		const elements: ReactNode[] = [];

		if (!isEmptyString(loginIntro) || !isEmptyString(activeSpace?.loginIntro)) {
			return (
				<View>
					<Markdown markdown={loginIntro ?? activeSpace?.loginIntro} />
				</View>
			);
		}

		return null;
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderLoginIntro()}
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_emailorticketcode`}
				label={ticketRequired ? t('emailOrTicketcode') : t('email')}
				value={values.emailOrTicket}
				error={errors.emailOrTicket}
				onChangeText={(value) => onChange({ ...values, emailOrTicket: value })}
				isRequired
				skipTabPress
				autoCapitalize={ticketRequired ? 'none' : undefined}
				autoCompleteType={ticketRequired ? 'email' : 'email'}
				autoCorrect={!ticketRequired}
				keyboardType="email-address"
			/>
		</View>
	);
};
