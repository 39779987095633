import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import { ICreateSpaceFormValues } from 'config/interfaces';
import { colorSuggestions, getDominantHexColorValue, hexColorRegex, invertColor, isDarkColor, isEmptyString, IS_WEB } from 'helper';
import { generateTheme } from 'helper';
import { ChildButton } from 'components/Button';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { H1, Text, WelcomeHint } from 'components/Text';
import { hsBottomMargin } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { DEFAULTTHEME } from 'rematch/models/config';
import { FormTextInput } from 'components/Form';

interface ISpaceWizardColors {
	testID: string;
	setTempSpace: (val) => void;
	space: ICreateSpaceFormValues;
	customColor: string;
	setCustomColor: (val) => void;
}

export const SpaceWizardColors = (props: ISpaceWizardColors) => {
	const { testID, setTempSpace, space, customColor, setCustomColor } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const [tempHex, setTempHex] = useState<string>('');
	const [hexError, setHexError] = useState<string>('');
	const platformStyle = IS_WEB ? { outline: 'none' } : {};

	const _submitHex = (val) => {
		let _val: string | undefined = val;

		if (isEmptyString(val)) {
			setCustomColor(null);
			setTempHex(val);
		} else {
			if (!_val?.startsWith('#')) {
				_val = `#${_val}`;
			}
			const res = hexColorRegex.test(_val);
			setTempHex(_val);
			if (res) {
				setHexError('');
				setCustomColor(_val);
				_changeWizardTheme(val);
			} else {
				setHexError(t('HexColorError'));
			}
		}
	};

	const _getPreviewImageName = (color: string) => {
		const type = getDominantHexColorValue(color);
		switch (type) {
			case 'red':
				return 'spaceDefaultRed';
			case 'green':
				return 'spaceDefaultGreen';
			case 'blue':
				return 'spaceDefaultBlue';
			default:
				return '';
		}
	};

	const _getBackgroundImageName = (color: string) => {
		const type = getDominantHexColorValue(color);
		switch (type) {
			// case 'red':
			// 	return 'gradientOrangeMagenta';
			// case 'green':
			// 	return 'gradientLightblueOrange';
			// case 'blue':
			// 	return 'gradientBlueLightblue';

			default:
				return '';
		}
	};

	const _getPrimaryContrast = (color: string) => {
		if (!isDarkColor(color)) {
			return '#111827';
		}

		return DEFAULTTHEME.primaryContrast;
	};

	const _changeWizardTheme = (color: string) => {
		const _space = {
			...space,
			primaryColor: color,
			contrastColor: invertColor(color),
			previewImageName: _getPreviewImageName(color),
			backgroundImageName: _getBackgroundImageName(color),
			primaryContrastColor: _getPrimaryContrast(color)
		};
		setTempSpace(_space);
		setTheme(generateTheme(_space));
	};

	return (
		<ScrollView
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingVertical: hsBottomMargin
			}}
		>
			<HSCard>
				<H1 style={{ marginBottom: isEmptyString(t('selectColorsHint')) ? 0 : hsBottomMargin }}>{t('selectColorsHeader')}</H1>
				{!isEmptyString(t('primaryColorDescription')) && (
					<WelcomeHint>
						{t('primaryColorDescription')}
						{!isEmptyString(t('selectColorsHint')) && '\n'}
						{t('selectColorsHint')}
					</WelcomeHint>
				)}
			</HSCard>
			<View
				style={{
					flex: 1,
					flexDirection: 'row',
					flexWrap: 'wrap',
					marginBottom: 10,
					borderColor: theme.formgray,
					borderWidth: 1,
					borderRadius: 5,
					minHeight: 20,
					width: '100%'
				}}
			>
				{colorSuggestions?.map((color, index) => {
					const radius = 3;
					let borderStyle = {};
					switch (index) {
						case 0:
							borderStyle = { borderTopLeftRadius: radius };
							break;
						case 5:
							borderStyle = { borderTopRightRadius: radius };
							break;
						case 24:
							borderStyle = { borderBottomLeftRadius: radius };
							break;
						case 29:
							borderStyle = { borderBottomRightRadius: radius };
							break;
						default:
							break;
					}
					return (
						<ChildButton
							activeOpacity={0.5}
							key={`${testID}_button_color_${index}`}
							testID={`${testID}_button_color_${index}`}
							style={[{ height: 35, width: `${100 / 6}%`, backgroundColor: color }, borderStyle]}
							onPress={() => _submitHex(color)}
						/>
					);
				})}
			</View>
			<HSCard style={{ flexDirection: 'row', alignSelf: 'flex-start', alignItems: 'center' }}>
				<Text>{t('yourChoice')}:</Text>
				<View
					key={`${testID}_button_activecolor`}
					testID={`${testID}_button_activecolor`}
					style={{
						height: '100%',
						width: `${100 / 6}%`,
						backgroundColor: customColor,
						borderColor: theme.formgray,
						borderWidth: 1,
						borderRadius: 5,
						marginLeft: 10
					}}
				/>
				<FormTextInput
					testID={`${testID}_hex`}
					value={!isEmptyString(tempHex) ? tempHex : customColor}
					error={hexError}
					onChangeText={(val) => _submitHex(val)}
					formStyle={{ marginBottom: 0, ...platformStyle, flex: 1, marginLeft: 10, height: '100%' }}
				/>
			</HSCard>
		</ScrollView>
	);
};
