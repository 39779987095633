import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { Image } from 'components/Image';
import { NetworkingRoomWrapper } from 'components/NetworkingRoom/NetworkingRoomWrapper';
import { IFramePlayer, Video, VimeoPlayer, YouTubePlayer } from 'components/Video';
import { HsZoomClient } from 'components/Zoom';
import { IMedia, TPlayableMediaType } from 'config/interfaces';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';
import { EDefaultIconSet, getDefaultAspectRatioStyle, getTopRadiusStyle, isEmptyString, IS_WEB, openURL } from 'helper';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, View, ViewStyle } from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Text } from 'components/Text';

interface IStreamPlayer {
	url?: string | number;
	type?: TPlayableMediaType;
	previewImage?: IMedia;
	containerStyle?: ViewStyle;
	onPlay?: (e: any) => void;
	onPause?: (e: any) => void;
	isAutoplay?: boolean;
	isMute?: boolean;
	isLoop?: boolean;
	testID?: string;
	controls?: boolean;
	buttonText?: string;
	onExternalClick?: () => void;
	roundedTop?: boolean;
	zoomData?: { zoomMeetingUrl: string; zoomMeetingId: string; zoomMeetingPassword: string; zoomMeetingPreviewImage?: string };
	shouldPause?: boolean;
	playerContainerStyle?: ViewStyle;
}

export const StreamPlayer = (props: IStreamPlayer) => {
	const {
		url,
		type,
		previewImage,
		containerStyle,
		onPlay,
		onPause,
		isAutoplay,
		isMute,
		isLoop,
		testID,
		controls,
		buttonText,
		onExternalClick,
		roundedTop,
		zoomData,
		shouldPause,
		playerContainerStyle
	} = props;

	const { activeSpace } = useSpace();
	const { t } = useTranslation();
	const { theme } = useTheme();

	let headerHeight = 0;

	try {
		headerHeight = useHeaderHeight();
	} catch (error) {
		console.log('could not get headerheight');
	}

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [urlParamater, setUrlParameter] = useState<string>('');
	const [isPlayPressed, setIsPlayPressed] = useState<boolean>(false);

	const activeNetworkingRoom = useSelector((store: IRootState) => store.networking.activeNetworkingRoom);

	useEffect(() => {
		if (shouldPause) {
			setIsPlayPressed(false);
		}
	}, [shouldPause]);

	useEffect(() => {
		if (isAutoplay && onPlay) {
			onPlay({});
		}
	}, []);

	useEffect(() => {
		if (typeof url === 'number') {
			return;
		}

		let _autoplayParam = '';
		let _muteParam = '';
		let _loopParam = '';

		switch (type) {
			case 'youtube':
				if (isAutoplay) {
					_autoplayParam = 'autoplay=1';
					_muteParam = 'mute=1';
				} else {
					_autoplayParam = 'autoplay=0';
				}
				if (isMute) {
					_muteParam = 'mute=1';
				}
				if (isLoop) {
					_loopParam = 'loop=1';
				} else {
					_loopParam = 'loop=0';
				}
				if (isPlayPressed) {
					_autoplayParam = 'autoplay=1';
					_muteParam = 'mute=0';
				}
				break;
			case 'vimeo':
				if (isAutoplay) {
					_autoplayParam = 'autoplay=1';
					_muteParam = 'muted=1';
				} else {
					_autoplayParam = 'autoplay=0';
				}
				if (isMute) {
					_muteParam = 'muted=1';
				}
				if (isLoop) {
					_loopParam = 'loop=1';
				} else {
					_loopParam = 'loop=0';
				}
				if (isPlayPressed) {
					_autoplayParam = 'autoplay=1';
					_muteParam = 'mute=0';
				}
				break;
			case 'upload':
			case 'contentflow':
			case 'iFrame':
				if (isAutoplay) {
					_autoplayParam = 'autoplay=true';
					_muteParam = 'muted=true';
				} else {
					_autoplayParam = 'autoplay=false';
				}
				if (isMute) {
					_muteParam = 'muted=true';
				}
				if (isLoop) {
					_loopParam = 'loop=true';
				} else {
					_loopParam = 'loop=false';
				}
				if (isPlayPressed) {
					_autoplayParam = 'autoplay=true';
					_muteParam = 'muted=false';
				}
				break;
			case 'mediaLibrary':
				_autoplayParam = '';
				_muteParam = '';
				_loopParam = '';
				break;
			case 'zoom':
				setIsPlayPressed(true);
				break;
			default:
				break;
		}
		let params: string[] = [];
		if (!isEmptyString(_autoplayParam)) {
			params.push(_autoplayParam);
		}
		if (!isEmptyString(_muteParam)) {
			params.push(_muteParam);
		}
		if (!isEmptyString(_loopParam)) {
			params.push(_loopParam);
		}
		if (params.length > 0) {
			setUrlParameter(`?${params.join('&')}`);
		}
	}, [type, url, isPlayPressed]);

	const _handlePlay = (e) => {
		if (onPlay) {
			onPlay(e);
		}
	};

	const _handlePause = (e) => {
		if (onPause) {
			onPause(e);
		}
	};

	const _openExternalURL = (url: string) => {
		openURL(url);
		if (onExternalClick) {
			onExternalClick();
		}
	};

	const _renderPlayer = () => {
		if (!isAutoplay && !isPlayPressed && type !== 'externalMeeting') {
			let _previewImage = previewImage;
			if (type === 'youtube' && shouldPause && !previewImage) {
				_previewImage = activeSpace?.logoImage ?? activeSpace?.previewImage;
			}
			if (_previewImage) {
				return (
					<View
						style={{
							position: 'relative',
							...getDefaultAspectRatioStyle(containerWidth)
						}}
					>
						{containerWidth > 1 && (
							<Image
								mediaObj={previewImage}
								imageSize={IS_WEB ? 'full' : 'medium'}
								style={{
									width: '100%',
									height: '100%',
									...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
								}}
								expectedRatio={16 / 9}
								resizeMode="contain"
							/>
						)}
						<ChildButton
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center',
								zIndex: 20
							}}
							testID={`${testID}_button_play`}
							onPress={(e) => {
								setIsPlayPressed(true);
								_handlePlay(e);
							}}
						>
							<Icon name={EDefaultIconSet.Play} color={theme.primaryContrast} size={containerWidth / 4} />
						</ChildButton>

						<View
							style={{
								position: 'absolute',
								backgroundColor: theme.gray,
								width: '100%',
								height: '100%',
								opacity: 0.3,
								zIndex: 10,
								...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
							}}
						/>
					</View>
				);
			}
		}

		const _url = `${url}${urlParamater}`.replace(/\s/g, '');

		switch (type) {
			case 'contentflow':
			case 'upload':
			case 'iFrame':
				return (
					<View style={getDefaultAspectRatioStyle(containerWidth)}>
						<IFramePlayer url={_url} roundedTop={roundedTop} onPlay={_handlePlay} autoplay={isAutoplay} />
					</View>
				);
			case 'local':
				return (
					<Video
						url={_url}
						autoplay={isAutoplay}
						style={getDefaultAspectRatioStyle(containerWidth)}
						onPlay={_handlePlay}
						onPause={_handlePause}
						controls={controls ?? true}
					/>
				);
			case 'vimeo':
				return <VimeoPlayer url={_url} autoplay={isAutoplay || isPlayPressed} onPlay={_handlePlay} onPause={_handlePause} />;
			case 'youtube':
				return <YouTubePlayer url={_url} autoplay={isAutoplay || isPlayPressed} onPlay={_handlePlay} onPause={_handlePause} />;
			case 'externalMeeting':
				if (_url) {
					return (
						<View>
							<ChildButton
								testID={`${testID}_image_externalmeeting`}
								onPress={() => _openExternalURL(_url)}
								style={{ position: 'relative' }}
							>
								<Image
									mediaObj={previewImage}
									imageSize={IS_WEB ? 'full' : 'medium'}
									style={{
										...getDefaultAspectRatioStyle(containerWidth),
										...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
									}}
									expectedRatio={16 / 9}
									resizeMode="contain"
								/>
								<View
									style={{
										position: 'absolute',
										backgroundColor: theme.gray,
										width: '100%',
										height: '100%',
										opacity: 0.3,
										zIndex: 10,
										...getTopRadiusStyle(roundedTop ? hsBorderRadius : 0)
									}}
								/>
								<ChildButton
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										alignItems: 'center',
										justifyContent: 'center',
										zIndex: 20
									}}
									testID={`${testID}_button_play`}
									onPress={(e) => _openExternalURL(_url)}
								>
									<Icon name={EDefaultIconSet.Play} color={theme.primaryContrast} size={containerWidth / 4} />
									<Text bold style={{ fontSize: 22 }}>
										{buttonText ?? t('openExternalMeeting')}
									</Text>
								</ChildButton>
							</ChildButton>
						</View>
					);
				}
				return null;
			case 'zoom':
				return (
					<HsZoomClient
						zoomMeetingId={zoomData?.zoomMeetingId}
						zoomMeetingPassword={zoomData?.zoomMeetingPassword}
						zoomMeetingUrl={zoomData?.zoomMeetingUrl}
						previewImage={previewImage}
						containerWidth={containerWidth}
						roundedTop={roundedTop}
					/>
				);
			case 'networking':
				if (typeof url === 'number') {
					const screenHeight = Dimensions.get('window').height;
					return (
						<View
							style={{
								width: '100%',
								height:
									IS_WEB && activeNetworkingRoom ? screenHeight - 100 - hsTopScreenPadding * 2 - headerHeight : undefined
							}}
						>
							<NetworkingRoomWrapper networkingRoomId={url} testIdPrefix={testID ?? ''} />
						</View>
					);
				}

				return null;
			case 'mediaLibrary':
			default:
				return null;
		}
	};

	if (url && type) {
		return (
			<View style={{ ...containerStyle }}>
				<View
					onLayout={(e) => {
						if (Math.abs(e.nativeEvent.layout.width - containerWidth) > 20) {
							setContainerWidth(e.nativeEvent.layout.width);
						}
					}}
					style={[
						{ alignItems: 'center' },
						roundedTop ? { ...getTopRadiusStyle(hsBorderRadius) } : playerContainerStyle ? playerContainerStyle : {}
					]}
				>
					{_renderPlayer()}
				</View>
			</View>
		);
	}

	return null;
};
