import {
	EMeetingOnsiteType,
	EMeetingStatus,
	EMeetingType,
	IAttendee,
	IChatMessage,
	IMapPosition,
	IMeeting,
	IMeetingTable
} from 'config/interfaces';
import { useCalendar } from 'hooks/useCalendar';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import React, { ReactNode, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { CHATMESSAGEBORDERRADIUS, CHATMESSAGEBOTTOMMARGIN, CHATMESSAGECONTENTSIZE } from '../constants';
import { Text } from 'components/Text';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { useTranslation } from 'react-i18next';
import { ChildButton } from 'components/Button';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';
import moment from 'moment';

interface IMeetingRequestMessage {
	item: IChatMessage;
	partner?: IAttendee;
	fullWidth?: boolean;
	showChatButton?: boolean;
	isOver?: boolean;
}

export const MeetingRequestMessage = (props: IMeetingRequestMessage) => {
	const { item, partner, fullWidth, showChatButton, isOver } = props;
	const { theme } = useTheme();
	const { addToCalendar } = useCalendar();
	const { t } = useTranslation();
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [meeting, setMeeting] = useState<IMeeting | undefined>(undefined);
	const [meetingTable, setMeetingTable] = useState<IMeetingTable | undefined>(undefined);
	const [mapPosition, setMapPosition] = useState<IMapPosition | undefined>(undefined);
	const [isAcceptMeetingRequestLoading, setIsAcceptMeetingRequestLoading] = useState<boolean>(false);
	const [isDeclineMeetingRequestLoading, setIsDeclineMeetingRequestLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const content = useSelector((store: IRootState) => store.content.content);

	const acceptMeetingRequest = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.acceptMeetingRequest);
	const declineMeetingRequest = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.declineMeetingRequest);

	const meetingIsOver = isOver || moment(meeting?.timeSlot.end).isBefore(moment());

	useEffect(() => {
		let _meeting: typeof meeting = undefined;

		if (meetings) {
			_meeting = meetings.find((e) => item.target && e.id === Number(item.target));
		}

		setMeeting(_meeting);
	}, [item, meetings]);

	useEffect(() => {
		let _table: typeof meetingTable = undefined;

		if (content.meetingtables && meeting?.meetingtable) {
			_table = content.meetingtables.find((e) => e.id === meeting?.meetingtable?.id);
		}

		setMeetingTable(_table);
	}, [content, meeting]);

	useEffect(() => {
		let _position: typeof mapPosition = undefined;

		if (content.mappositions && meetingTable) {
			_position = content.mappositions.find((e) => e.meetingtable?.id === meetingTable?.id);
		}

		setMapPosition(_position);
	}, [content, meetingTable]);

	const _getColor = () => {
		switch (meeting?.status) {
			case EMeetingStatus.Accepted:
				return theme.success;
			case EMeetingStatus.Pending:
				if (meetingIsOver) return theme.danger;
				return theme.warning;
			case EMeetingStatus.Declined:
				return theme.danger;
			default:
				return theme.text;
		}
	};

	const _acceptMeetingRequest = async () => {
		if (meeting) {
			setIsAcceptMeetingRequestLoading(true);
			const res = await acceptMeetingRequest({ meetingRequest: meeting });
			setIsAcceptMeetingRequestLoading(false);

			if (res) {
				trackAction('meeting', 'Accept Meeting', `${meeting.id}`);
			}
		}
	};

	const _declineMeetingRequest = async () => {
		if (meeting) {
			setIsDeclineMeetingRequestLoading(true);
			const res = await declineMeetingRequest({ meetingRequest: meeting });
			setIsDeclineMeetingRequestLoading(false);

			if (res) {
				trackAction('meeting', 'Decline Meeting', `${meeting.id}`);
			}
		}
	};

	const _isRemoteMeeting = () => {
		return !meeting?.meetingType || meeting.meetingType === EMeetingType.Remote;
	};

	const _addToCalendar = async () => {
		if (partner && meeting) {
			addToCalendar(`${t('MeetingWith')} ${partner.firstName} ${partner.lastName}`, meeting.timeSlot.start, meeting.timeSlot.end);
			trackAction('meeting', 'Add to Calendar', `${item.id}`);
		}
	};

	const _renderIcon = () => {
		let icon = '';
		let text = '';

		switch (meeting?.status) {
			case EMeetingStatus.Accepted:
				icon = EDefaultIconSet.Save;
				text = t('Accepted');
				break;
			case EMeetingStatus.Pending:
				icon = EDefaultIconSet.Clock;
				text = t('Pending');
				break;
			case EMeetingStatus.Declined:
				icon = EDefaultIconSet.Error;
				text = t('Cancelled');
				break;
			default:
				return null;
		}

		return (
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				<Icon name={icon} color={_getColor()} size={14} />
				<Text numberOfLines={1} adjustsFontSizeToFit style={{ marginLeft: 5, fontSize: 10, color: _getColor() }}>
					{text}
				</Text>
			</View>
		);
	};

	const _renderAddToCalendar = () => {
		if (meeting?.status === EMeetingStatus.Accepted) {
			return (
				<ChildButton
					testID={`meeting_${meeting.id}_addcalendar`}
					onPress={_addToCalendar}
					style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5, width: '48%' }}
				>
					<Icon name={EDefaultIconSet.Calendar} size={14} />
					<Text numberOfLines={1} adjustsFontSizeToFit style={{ fontSize: 10, marginLeft: 5 }}>
						{t('AddToCalendar')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderShowOnMap = () => {
		if (meeting?.status === EMeetingStatus.Accepted && mapPosition) {
			return (
				<ChildButton
					testID={`meeting_${meeting.id}_showonmap`}
					onPress={() =>
						navigation.navigate(ERoutes.Maps, {
							spaceId: activeSpace?.spaceId,
							mapId: mapPosition.map,
							positionId: mapPosition.id,
							key: 'maps'
						})
					}
					style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 5, width: '48%' }}
				>
					<Icon name={EDefaultIconSet.MapMarker} size={14} />
					<Text numberOfLines={1} adjustsFontSizeToFit style={{ fontSize: 10, marginLeft: 5 }}>
						{t('Show on map')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderMessage = () => {
		let message = item.content;

		if (message.startsWith('I18n')) {
			message = t(message);
		}

		if (!isEmptyString(message)) {
			return (
				<View>
					<Text style={{ fontSize: CHATMESSAGECONTENTSIZE }}>
						<Text bold style={{ fontSize: CHATMESSAGECONTENTSIZE }}>{`${
							meeting?.lastEditBy === profile?.userId ? t('You') : partner?.firstName
						}: `}</Text>
						{message}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderJoinMeetingButton = () => {
		if (meetingIsOver) {
			return null;
		}

		return (
			<ChildButton
				key={`meeting_${meeting?.id}_join`}
				testID={`meeting_${meeting?.id}_join`}
				onPress={() =>
					navigation.navigate(ERoutes.NetworkingRoom, { spaceId: activeSpace?.spaceId, id: meeting?.networkingroom?.id })
				}
				isDisabled={isAcceptMeetingRequestLoading || isDeclineMeetingRequestLoading || !meeting?.networkingroom?.id}
				style={[
					styles.actionButton,
					{ borderBottomLeftRadius: showChatButton ? 0 : CHATMESSAGEBORDERRADIUS, backgroundColor: theme.success }
				]}
			>
				<Text numberOfLines={1} adjustsFontSizeToFit style={[styles.actionButtonText, { color: theme.white }]}>
					{t('Join')}
				</Text>
			</ChildButton>
		);
	};

	const _renderChatButton = () => {
		return (
			<ChildButton
				key={`meeting_${meeting?.id}_chat`}
				testID={`meeting_${meeting?.id}_chat`}
				onPress={() => navigation.navigate(ERoutes.Chat, { spaceId: activeSpace?.spaceId, userId: partner?.userId })}
				isDisabled={isAcceptMeetingRequestLoading || isDeclineMeetingRequestLoading}
				style={[styles.actionButton, { borderBottomLeftRadius: CHATMESSAGEBORDERRADIUS, backgroundColor: theme.primary }]}
			>
				<Text numberOfLines={1} adjustsFontSizeToFit style={[styles.actionButtonText, { color: theme.text }]}>
					Chat
				</Text>
			</ChildButton>
		);
	};

	const _renderAcceptMeetingButton = () => {
		if (!meetingIsOver) {
			return (
				<ChildButton
					key={`meeting_${meeting?.id}_accept`}
					testID={`meeting_${meeting?.id}_accept`}
					onPress={_acceptMeetingRequest}
					isLoading={isAcceptMeetingRequestLoading}
					isDisabled={isDeclineMeetingRequestLoading}
					style={[
						styles.actionButton,
						{
							borderBottomLeftRadius: showChatButton ? 0 : CHATMESSAGEBORDERRADIUS,
							backgroundColor: isDeclineMeetingRequestLoading ? theme.lightgray : theme.success,
							opacity: isDeclineMeetingRequestLoading ? 0.7 : 1
						}
					]}
				>
					<Text numberOfLines={1} adjustsFontSizeToFit style={[styles.actionButtonText, { color: theme.white }]}>
						{t('Accept')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderRescheduleMeetingButton = (isFirst?: boolean) => {
		if (!meetingIsOver) {
			return (
				<ChildButton
					key={`meeting_${meeting?.id}_reschedule`}
					testID={`meeting_${meeting?.id}_reschedule`}
					isDisabled={isAcceptMeetingRequestLoading || isDeclineMeetingRequestLoading}
					style={[
						styles.actionButton,
						{
							backgroundColor: theme.warning,
							borderBottomLeftRadius: isFirst ? (showChatButton ? 0 : CHATMESSAGEBORDERRADIUS) : 0
						}
					]}
					onPress={() =>
						navigation.navigate(ERoutes.MeetingRequest, {
							spaceId: activeSpace?.spaceId,
							partnerId: partner?.userId,
							id: meeting?.id
						})
					}
				>
					<Text numberOfLines={1} adjustsFontSizeToFit style={[styles.actionButtonText, { color: theme.text }]}>
						{t('Reschedule')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderDeclineMeetingButton = () => {
		if (!meetingIsOver) {
			let isMyMeeting = meeting?.lastEditBy === profile?.userId;
			return (
				<ChildButton
					key={`meeting_${meeting?.id}_decline`}
					testID={`meeting_${meeting?.id}_decline`}
					isLoading={isDeclineMeetingRequestLoading}
					isDisabled={isAcceptMeetingRequestLoading}
					style={[
						styles.actionButton,
						{
							borderBottomRightRadius: CHATMESSAGEBORDERRADIUS,
							backgroundColor: isAcceptMeetingRequestLoading ? theme.lightgray : theme.danger,
							opacity: isAcceptMeetingRequestLoading ? 0.7 : 1
						}
					]}
					onPress={_declineMeetingRequest}
				>
					<Text numberOfLines={1} adjustsFontSizeToFit style={[styles.actionButtonText, { color: theme.white }]}>
						{isMyMeeting ? t('Cancel') : t('Decline')}
					</Text>
				</ChildButton>
			);
		}

		return null;
	};

	const _renderActions = () => {
		if (meeting && profile) {
			const elements: ReactNode[] = [];

			if (meeting.lastEditBy === profile.userId) {
				switch (meeting.status) {
					case EMeetingStatus.Accepted:
						if (_isRemoteMeeting()) {
							elements.push(_renderJoinMeetingButton());
						}
					case EMeetingStatus.Pending:
						elements.push(_renderRescheduleMeetingButton(!_isRemoteMeeting()));
						elements.push(_renderDeclineMeetingButton());
						break;
					case EMeetingStatus.Declined:
						break;
					default:
						break;
				}
			} else {
				switch (meeting.status) {
					case EMeetingStatus.Accepted:
						if (meeting.meetingType === 'remote') {
							elements.push(_renderJoinMeetingButton());
						}
						elements.push(_renderRescheduleMeetingButton(!_isRemoteMeeting()));
						elements.push(_renderDeclineMeetingButton());
						break;
					case EMeetingStatus.Pending:
						elements.push(_renderAcceptMeetingButton());
						elements.push(_renderRescheduleMeetingButton());
						elements.push(_renderDeclineMeetingButton());
						break;
					case EMeetingStatus.Declined:
						break;
					default:
						break;
				}
			}

			if (showChatButton) {
				elements.unshift(_renderChatButton());
			}

			return <View style={{ flexDirection: 'row' }}>{elements}</View>;
		}

		return null;
	};

	const _renderWhen = () => {
		if (meeting) {
			return (
				<View>
					<Text bold style={styles.whenWhereText}>
						{`${t('When')}:`}
					</Text>
					{moment(meeting.timeSlot.start).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') ? (
						<Text style={styles.whenWhereText}>{`${t('Today')} (${moment(meeting.timeSlot.start).format('DD.MM.YYYY')})`}</Text>
					) : (
						<Text style={styles.whenWhereText}>{moment(meeting.timeSlot.start).format('DD.MM.YYYY')}</Text>
					)}
					<Text style={styles.whenWhereText}>{`${moment(meeting.timeSlot.start).format('HH:mm')} - ${moment(
						meeting.timeSlot.end
					).format('HH:mm')}`}</Text>
				</View>
			);
		}

		return null;
	};

	const _renderWhere = () => {
		if (meeting?.meetingType === EMeetingType.Onsite) {
			if (meeting.meetingOnsiteType === EMeetingOnsiteType.Table && meeting.status !== EMeetingStatus.Accepted) {
				return null;
			}

			let _title: string | undefined = '';
			let _subtitle: string | undefined = '';
			if (meetingTable) {
				_title = meetingTable.title;
				_subtitle = meetingTable.subtitle;
			} else {
				_title = meeting.meetingOnsiteLocation;
			}

			if (!isEmptyString(_title)) {
				return (
					<View>
						<Text bold style={styles.whenWhereText}>
							{`${t('Where')}:`}
						</Text>
						<Text style={styles.whenWhereText}>{_title}</Text>
						{!isEmptyString(_subtitle) && <Text style={styles.whenWhereText}>{_subtitle}</Text>}
					</View>
				);
			}
		}

		return null;
	};

	return (
		<View
			style={{
				width: fullWidth ? '100%' : '80%',
				alignSelf: 'center',
				backgroundColor: theme.contentBackgroundColor ?? theme.background,
				marginBottom: CHATMESSAGEBOTTOMMARGIN,
				borderRadius: CHATMESSAGEBORDERRADIUS,
				borderWidth: 1,
				borderColor: theme.lightgray
			}}
		>
			<View style={{ padding: 10 }}>
				<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
					<View style={{ flex: 1, paddingRight: 10 }}>
						<Text
							bold
							numberOfLines={1}
							adjustsFontSizeToFit
							style={{ fontSize: 12, color: meeting?.status === EMeetingStatus.Pending ? theme.text : _getColor() }}
						>{`${t('MeetingWith')} ${partner?.firstName} ${partner?.lastName}`}</Text>
					</View>
					{_renderIcon()}
				</View>
				{_renderMessage()}
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }}>
					{_renderWhen()}
					{_renderWhere()}
				</View>
				<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
					{_renderAddToCalendar()}
					{_renderShowOnMap()}
				</View>
			</View>
			{_renderActions()}
		</View>
	);
};

const styles = StyleSheet.create({
	whenWhereText: {
		fontSize: CHATMESSAGECONTENTSIZE - 1
	},
	actionButton: {
		flex: 1,
		paddingVertical: 10,
		justifyContent: 'center',
		alignItems: 'center'
	},
	actionButtonText: {
		fontSize: CHATMESSAGECONTENTSIZE - 1
	}
});
