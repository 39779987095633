import React from 'react';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteQuizForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteQuizForm = (props: IVoteQuizForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;

	return <CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading} />;
};
