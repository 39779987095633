import React, { useEffect, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import {
	EditProfileInitialFormValues,
	IEditProfileFormErrors,
	IEditProfileFormValues,
	ILauncherSpace,
	ITabSwitcherButton
} from 'config/interfaces';
import { EDefaultIconSet, getTopRadiusStyle, isEmptyString, IS_WEB, showFormErrorToast, validateForm } from 'helper';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, View } from 'react-native';

import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormCalendarPicker, FormCheckbox } from 'components/Form';
import { Text } from 'components/Text';
import { MeetingTimeSelect } from 'components/Meeting';
import i18next from 'i18next';
import { hsTopScreenPadding } from 'config/styleConstants';
import { PRESET_SPACEIDS } from 'config/envConstants';
import moment from 'moment';
import { showToast } from 'helper/toast';
import * as RootNavigation from '../../RootNavigation';
import { TabView } from 'components/TabView';
import { FormLabel } from 'components/Form/FormLabel';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.EditProfile>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.EditProfile>;
type RouteParams = NoSpaceStackParamList[ERoutes.EditProfile];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'editprofile';

export const EditProfileScreen = ({ route, navigation }: Props) => {
	const { screenWidth, isTabletOrMobile, isPhone, isTablet } = useQuery();
	const { theme } = useTheme();
	const { getEditProfileFields, getEditProfileSections, getProfileFieldSettings, getRegistrationFields } = useRegistration();
	const { renderFormField, getFormSchema, renderFormFieldUserSettings } = useForm();
	const { activeSpace } = useSpace();
	const { t } = useTranslation();

	const [editProfileFormValues, setEditProfileFormValues] = useState<IEditProfileFormValues>({ ...EditProfileInitialFormValues });
	const [editProfileFormErrors, setEditProfileFormErrors] = useState<IEditProfileFormErrors>({});
	const [editProfileFieldSettings, setEditProfileFieldSettings] = useState({});
	const [editProfileFieldSettingsErrors, setEditProfileFieldSettingsError] = useState({});
	const [isUpdateUserLoading, setIsUpdateUserLoading] = useState<boolean>(false);
	const [isRemoveMeFromSpaceLoading, setIsRemoveMeFromSpaceLoading] = useState<boolean>(false);
	const [isDeleteMyAccountLoading, setIsDeleteMyAccountLoading] = useState<boolean>(false);
	const [isResetMeetingsLoading, setIsResetMeetingsLoading] = useState<boolean>(false);

	const [tabs, setTabs] = useState<ITabSwitcherButton[]>([]);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [selectedDate, setSelectedDate] = useState<string | null | undefined>(undefined);
	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const jwt = useSelector((store: IRootState) => store.auth.jwt);
	const tempSpaceId = useSelector((store: IRootState) => store.temp.tempSpaceId);

	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const logout = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.logout);
	const removeUserFromSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.removeUserFromSpace);
	const deleteUserAccount = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.deleteUserAccount);
	const setTempSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setTempSpaceId);
	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);

	useEffect(() => {
		if (profile) {
			const fields = getEditProfileFields();
			const obj: IEditProfileFormValues = {
				firstName: !isEmptyString(editProfileFormValues?.firstName) ? editProfileFormValues?.firstName : profile.firstName,
				lastName: !isEmptyString(editProfileFormValues?.lastName) ? editProfileFormValues?.lastName : profile.lastName,
				email: !isEmptyString(editProfileFormValues?.email) ? editProfileFormValues?.email : profile.email,
				unavailable: !isEmptyString(editProfileFormValues?.unavailable) ? editProfileFormValues?.unavailable : profile.unavailable,
				pushSettings: !isEmptyString(editProfileFormValues?.pushSettings)
					? editProfileFormValues?.pushSettings
					: profile.pushSettings ?? {}
			};
			fields.forEach((field) => {
				obj[field.fieldName] = !isEmptyString(editProfileFormValues[field.fieldName])
					? editProfileFormValues[field.fieldName]
					: profile[field.fieldName];
			});
			setEditProfileFormValues(obj);
			const settingsObj = getProfileFieldSettings(profile);

			setEditProfileFieldSettings(settingsObj);
		}
	}, [activeSpace, profile]);

	useEffect(() => {
		let _tabs: typeof tabs = [{ key: 'profile', label: t('Profile') }];

		if (activeSpace?.features?.list.find((e) => e.isActive && e.key === 'meetings')) {
			_tabs.push({ key: 'meetings', label: t('Meetings') });
		}

		const shouldDisplayPushTab = activeSpace?.features?.list.find((e) => e.isActive && (e.key === 'chats' || e.key === 'meetings'));
		if (shouldDisplayPushTab || activeSpace?.allowFeedComments) {
			_tabs.push({ key: 'pushnotifications', label: t('Notifications') });
		}

		if (!_tabs[activeTabIndex]) {
			setActiveTabIndex(0);
		}
		setTabs(_tabs);
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateProfile(),
			isLoading: isUpdateUserLoading
		});
	}, [activeSpace, editProfileFormValues, isUpdateUserLoading, editProfileFieldSettings]);

	useEffect(() => {
		getSpaceById();
	}, [tempSpaceId]);

	useEffect(() => {
		if (!selectedDate) {
			const now = moment();
			if (
				now.isSameOrAfter(moment(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate), 'date') &&
				now.isSameOrBefore(moment(activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate), 'date')
			) {
				setSelectedDate(now.toISOString());
			} else {
				setSelectedDate(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate);
			}
		}
	}, [activeSpace, selectedDate]);

	const getSpaceById = async () => {
		if (tempSpaceId) {
			const space = await findSpaceById({ spaceId: tempSpaceId, noToast: true });
			if (space) {
				setSpace(space);
			}

			setTempSpaceId(undefined);
		}
	};

	const _updateProfile = async () => {
		const hasPasswordFields =
			!isEmptyString(editProfileFormValues.password) || !isEmptyString(editProfileFormValues.passwordConfirmation);

		const errors = await validateForm(
			getFormSchema(getEditProfileFields(hasPasswordFields), editProfileFormValues),
			editProfileFormValues
		);
		if (errors) {
			setEditProfileFormErrors(errors);
			showFormErrorToast(errors, getEditProfileFields());
			return;
		} else {
			setEditProfileFormErrors({});
		}

		if (!hasPasswordFields) {
			editProfileFormValues.password = undefined;
			editProfileFormValues.passwordConfirmation = undefined;
		}

		setIsUpdateUserLoading(true);
		const updatedUser = { ...editProfileFormValues };
		const userFieldSettings = Object.keys(editProfileFieldSettings).map((key) => {
			return { fieldName: key, showOnPublic: editProfileFieldSettings[key] };
		});
		updatedUser.fieldSettings = { fields: userFieldSettings };
		const updateSuccess = await updateUser({ user: updatedUser });
		setIsUpdateUserLoading(false);
		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		}
	};

	const _updateFormValues = (val) => {
		if (!route?.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setEditProfileFormValues(val);
	};

	const _updateFieldSettingValues = (val) => {
		if (!route?.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setEditProfileFieldSettings(val);
	};

	const _logout = async () => {
		showAlert({
			title: t('Logout'),
			message: activeSpace?.spaceId ? `${t('logoutSubtitle')} ${t('logoutSubtitleLeaveSpace')}` : t('logoutSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Logout'),
					style: 'destructive',
					onPress: async () => {
						await logout({});
						if (activeSpace) {
							await leaveSpace({});

							if (navigation.canGoBack() && !IS_WEB) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
						if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
							navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
						} else {
							navigation.navigate(ERoutes.SpaceSelect, {});
						}
					}
				}
			]
		});
	};

	const _deleteUserAccount = async (shouldDeleteSpace: boolean) => {
		if (profile) {
			const success = await deleteUserAccount({ userId: profile.userId, shouldDeleteSpace });

			if (success) {
				await logout({});
				if (activeSpace) {
					await leaveSpace({});

					if (navigation.canGoBack()) {
						navigation.dispatch(StackActions.popToTop());
					}

					navigation.dispatch(
						StackActions.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary)
					);
				}
				navigation.navigate(ERoutes.SpaceSelect, {});
			}

			setIsDeleteMyAccountLoading(false);
		}
	};

	const _removeMeFromSpace = async () => {
		if (activeSpace && profile) {
			const isAdmin = activeSpace.admins?.find((e) => e.userId === profile?.userId);
			const isOnlyAdmin = isAdmin && (activeSpace.admins ?? []).length === 1;

			showAlert({
				title: isOnlyAdmin ? t('DeleteSpaceDataAndSpace') : t('DeleteSpaceData'),
				message: isOnlyAdmin ? t('DeleteSpaceDataOnlyAdmin') : t('DeleteSpaceDataNotOnlyAdmin'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: isOnlyAdmin ? t('DeleteSpaceDataAndSpace') : t('DeleteSpaceData'),
						style: 'destructive',
						onPress: async () => {
							setIsRemoveMeFromSpaceLoading(true);

							const success = await removeUserFromSpace({ userId: profile.userId, shouldDeleteSpace: isOnlyAdmin === true });
							if (success) {
								await leaveSpace({});
								navigation.goBack();
								RootNavigation.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								);
							}

							setIsRemoveMeFromSpaceLoading(false);
						}
					}
				]
			});
		}
	};

	const _askToDeleteAccount = async () => {
		if (profile) {
			setIsDeleteMyAccountLoading(true);
			if (activeSpace) {
				const isAdmin = activeSpace.admins?.find((e) => e.userId === profile?.userId);
				const isOnlyAdmin = isAdmin && (activeSpace.admins ?? []).length === 1;

				if (isOnlyAdmin) {
					showAlert({
						title: t('DeleteProfileAndSpace'),
						message: t('DeleteProfileAndSpaceSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel',
								onPress: () => setIsDeleteMyAccountLoading(false)
							},
							{
								text: t('DeleteProfileAndSpace'),
								style: 'destructive',
								onPress: async () => {
									_deleteUserAccount(true);
									return;
								}
							}
						]
					});
				} else {
					showAlert({
						title: t('DeleteAccount'),
						message: t('DeleteAccountSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel',
								onPress: () => setIsDeleteMyAccountLoading(false)
							},
							{
								text: t('DeleteAccount'),
								style: 'destructive',
								onPress: async () => _deleteUserAccount(false)
							}
						]
					});
				}
			} else {
				showAlert({
					title: t('DeleteAccount'),
					message: t('DeleteAccountSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel',
							onPress: () => setIsDeleteMyAccountLoading(false)
						},
						{
							text: t('DeleteAccount'),
							style: 'destructive',
							onPress: async () => _deleteUserAccount(false)
						}
					]
				});
			}
		}
	};

	const _resetMeetingSlots = () => {
		showAlert({
			title: t('ResetMeetings'),
			message: t('ResetMeetingsSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Reset'),
					style: 'destructive',
					onPress: () => {
						setIsResetMeetingsLoading(true);
						const obj = { ...editProfileFormValues };
						if (obj.unavailable) obj.unavailable.meetingSlots = [];

						_updateFormValues(obj);
						setSelectedDate(activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate);
						setIsResetMeetingsLoading(false);
					}
				}
			]
		});
	};

	const _renderDefaultSpaceActions = () => {
		if (jwt) {
			return (
				<HSCard>
					<View
						style={{
							flexDirection: 'row',
							flexWrap: 'wrap'
						}}
					>
						<View style={{ width: '50%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
							<RoundButton
								isOutline
								isStacked
								testID={`${TESTIDPREFIX}_button_logout`}
								icon={EDefaultIconSet.Logout}
								color={theme.danger}
								size="sm"
								title={t('Logout')}
								isDisabled={isRemoveMeFromSpaceLoading || isUpdateUserLoading || isDeleteMyAccountLoading}
								onPress={_logout}
							/>
						</View>
						{activeSpace && !PRESET_SPACEIDS && (
							<View style={{ width: '50%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
								<RoundButton
									isOutline
									isStacked
									testID={`${TESTIDPREFIX}_button_removemefromspace`}
									icon={EDefaultIconSet.Logout}
									color={theme.danger}
									size="sm"
									title={t('DeleteSpaceData')}
									isDisabled={isUpdateUserLoading || isDeleteMyAccountLoading}
									isLoading={isRemoveMeFromSpaceLoading}
									onPress={_removeMeFromSpace}
								/>
							</View>
						)}
						<View style={{ width: '50%', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
							<RoundButton
								isOutline
								isStacked
								alignSelf="center"
								testID={`${TESTIDPREFIX}_button_deleteaccount`}
								icon={EDefaultIconSet.Delete}
								color={theme.danger}
								size="sm"
								title={t('DeleteAccount')}
								isDisabled={isRemoveMeFromSpaceLoading || isUpdateUserLoading}
								isLoading={isDeleteMyAccountLoading}
								onPress={_askToDeleteAccount}
							/>
						</View>
					</View>
				</HSCard>
			);
		}
		return null;
	};

	const _handleNotificationSettingChange = (field: string) => {
		const values = { ...editProfileFormValues };
		if (!values.pushSettings) {
			values.pushSettings = {};
		}
		if (values.pushSettings[field] === false) {
			values.pushSettings[field] = true;
		} else {
			values.pushSettings[field] = false;
		}

		_updateFormValues(values);
	};

	const _renderContent = () => {
		if (tabs[activeTabIndex]) {
			switch (tabs[activeTabIndex].key) {
				case 'profile':
					return (
						<View>
							{getEditProfileSections(true).map((section, sectionIdx) => (
								<HSCard
									key={`${TESTIDPREFIX}_section_${sectionIdx}`}
									style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}
								>
									{section.fields.map((field) => {
										return (
											<View key={`${TESTIDPREFIX}_view_${field.fieldName}`}>
												{renderFormField(
													'attendee',
													TESTIDPREFIX,
													editProfileFormValues,
													editProfileFormErrors,
													field,
													(value) => _updateFormValues(value)
												)}
												{field.showOnDetailScreen &&
													renderFormFieldUserSettings(
														TESTIDPREFIX,
														editProfileFieldSettings,
														editProfileFieldSettingsErrors,
														field,
														(value) => _updateFieldSettingValues(value)
													)}
											</View>
										);
									})}
								</HSCard>
							))}
							{_renderDefaultSpaceActions()}
						</View>
					);
				case 'meetings':
					return (
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<View style={{ flexDirection: 'row', marginBottom: 10, alignItems: 'center' }}>
								<Text style={{ flex: 1, paddingRight: 10 }}>{t('EditProfileMeetingTimeSlotsIntro')}</Text>
								{editProfileFormValues.unavailable?.meetingSlots &&
									editProfileFormValues.unavailable.meetingSlots.length > 0 && (
										<RoundButton
											testID={`${TESTIDPREFIX}_button_resetmeetingslots`}
											icon={EDefaultIconSet.Delete}
											title={t('Reset')}
											onPress={_resetMeetingSlots}
											isDisabled={editProfileFormValues.unavailable.meetingSlots.length === 0}
											isLoading={isResetMeetingsLoading}
											size="sm"
										/>
									)}
							</View>
							<FormLabel testID={`${TESTIDPREFIX}_label_calendarpicker`} label={t('Date')} />
							<View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
								<FormCalendarPicker
									testID={`${TESTIDPREFIX}_calendarpicker`}
									startDate={selectedDate ?? activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate}
									onStartDateChange={(val) => setSelectedDate(val)}
									minDate={
										activeSpace?.meetingSettings?.allowMeetingsFrom && activeSpace?.meetingSettings?.allowMeetingsUntil
											? activeSpace?.meetingSettings?.allowMeetingsFrom
											: activeSpace?.startDate
									}
									maxDate={
										activeSpace?.meetingSettings?.allowMeetingsFrom && activeSpace?.meetingSettings?.allowMeetingsUntil
											? activeSpace?.meetingSettings?.allowMeetingsUntil
											: activeSpace?.endDate
									}
									initialDate={activeSpace?.meetingSettings?.allowMeetingsFrom ?? activeSpace?.startDate}
									isDisabled={moment().isAfter(
										moment(activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate)
									)}
									formStyle={{ width: isPhone || (IS_WEB && isTabletOrMobile) ? '100%' : isTablet ? '50%' : '60%' }}
								/>
								{selectedDate &&
								!moment().isAfter(moment(activeSpace?.meetingSettings?.allowMeetingsUntil ?? activeSpace?.endDate)) ? (
									<View
										style={{
											width: isPhone || (IS_WEB && isTabletOrMobile) ? '100%' : isTablet ? '45%' : '40%',
											height: IS_WEB && !isTabletOrMobile ? 220 : undefined
										}}
									>
										<ScrollView>
											<MeetingTimeSelect
												date={selectedDate}
												multiSelect
												selected={editProfileFormValues.unavailable?.meetingSlots}
												onSelect={(val) => {
													const obj = { ...editProfileFormValues };
													if (!obj.unavailable) {
														obj.unavailable = {};
													}
													if (!obj.unavailable.meetingSlots) {
														obj.unavailable.meetingSlots = [];
													}
													obj.unavailable.meetingSlots = val;
													_updateFormValues(obj);
												}}
												isProhibited
											/>
										</ScrollView>
									</View>
								) : (
									<Text style={{ color: theme.danger }}>{t('MeetingsNotAllowed')}</Text>
								)}
							</View>
						</HSCard>
					);
				case 'pushnotifications':
					return (
						<HSCard style={{ ...getTopRadiusStyle(0), borderTopWidth: 0 }}>
							<Text style={{ marginBottom: 10 }}>{t('EditProfileNotificationsIntro')}</Text>
							{activeSpace?.features?.list.find((e) => e.isActive && e.key === 'chats') ? (
								<FormCheckbox
									testID={`${TESTIDPREFIX}_checkbox_chat`}
									value={editProfileFormValues.pushSettings?.chat !== false}
									label={t('Chat Messages')}
									hint={t('EditProfilePushChatHint')}
									onPress={() => _handleNotificationSettingChange('chat')}
								/>
							) : null}
							{activeSpace?.features?.list.find((e) => e.isActive && e.key === 'meetings') ? (
								<FormCheckbox
									testID={`${TESTIDPREFIX}_checkbox_meetings`}
									value={editProfileFormValues.pushSettings?.meeting !== false}
									label={t('Meetings')}
									hint={t('EditProfilePushMeetingHint')}
									onPress={() => _handleNotificationSettingChange('meeting')}
								/>
							) : null}
							{activeSpace?.allowFeedComments && activeSpace?.allowFeedComments !== 'never' ? (
								<FormCheckbox
									testID={`${TESTIDPREFIX}_checkbox_feedcomments`}
									value={editProfileFormValues.pushSettings?.feedComments !== false}
									label={t('Feed Comments')}
									hint={t('EditProfilePushFeedCommentsHint')}
									onPress={() => _handleNotificationSettingChange('feedComments')}
								/>
							) : null}
						</HSCard>
					);
				default:
					return null;
			}
		}

		return null;
	};

	return (
		<ScreenContainer
			bgImage={!activeSpace ? space?.backgroundImage : undefined}
			bgImageName={!activeSpace ? space?.backgroundImageName : undefined}
		>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={(e) => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<TabView
					testIdPrefix={TESTIDPREFIX}
					activeKey={tabs[activeTabIndex]?.key}
					items={tabs}
					onPress={(key) => {
						let index = tabs
							.map((e) => {
								return e.key;
							})
							.indexOf(key);
						setActiveTabIndex(index);
					}}
					isDisabled={tabs.length < 1}
				/>
				{_renderContent()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const EditProfileScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('EditProfile')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isRightNavPressDisabled}
			/>
		</NavigationHeader>
	);
};
