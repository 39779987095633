import React from 'react';
import { View } from 'react-native';

import { ICreateExpoFormErrors, ICreateExpoFormValues, IUpdateExpoFormErrors, IUpdateExpoFormValues } from 'config/interfaces';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { HSCard } from 'components/Card';

interface IExpoForm {
	values: ICreateExpoFormValues | IUpdateExpoFormValues;
	errors: ICreateExpoFormErrors | IUpdateExpoFormErrors;
	onChange?: (val: ICreateExpoFormValues | IUpdateExpoFormValues) => void;
	isLoading: boolean;
}

const TESTIDPREFIX = 'expoform';

export const ExpoForm = (props: IExpoForm) => {
	const { values, errors, onChange, isLoading } = props;
	const { getContentTypeSections } = useContent('expo');
	const { renderFormField } = useForm();

	return (
		<View>
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) => renderFormField('expo', TESTIDPREFIX, values, errors, field, onChange, isLoading))}
				</HSCard>
			))}
		</View>
	);
};
