import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { IUpdateWebhookFormValues, IWebhookFormErrors } from 'config/interfaces';
import { WebhookForm } from 'components/Forms/Webhook';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { getUpdateWebhookFormSchema } from 'config/yupSchemas';
import { validateForm, showFormErrorToast, EDefaultIconSet } from 'helper';
import * as RootNavigation from '../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.WebhookUpdate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.WebhookUpdate>;
type RouteParams = StackParamList[ERoutes.WebhookUpdate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'webhookupdate';

export const WebhookUpdateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();

	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const [formValues, setFormValues] = useState<IUpdateWebhookFormValues | undefined>(undefined);
	const [formErrors, setFormErrors] = useState<IWebhookFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const webhooks = useSelector((store: IRootState) => store.content.webhooks);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateWebhook = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateWebhook);

	useEffect(() => {
		if (webhooks && route.params.id) {
			const values = webhooks.find((e) => e.id === route.params.id);
			if (values) {
				if (formValues && formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [webhooks, activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateWebhook(),
			isLoading: isLoading
		});
	}, [activeSpace, formValues, isLoading, formErrors, isFormLocked]);

	const _applyFormValues = (values) => {
		setFormValues({ ...values });
		setIsFormLocked(false);
	};

	const _update = async () => {
		if (formValues) {
			setIsLoading(true);
			const res = await updateWebhook({ webhook: formValues });
			if (res) {
				setIsLoading(false);
				navigation.setParams({ prohibitNavigation: false });
				navigation.goBack();
			} else {
				setIsLoading(false);
			}
		}
	};

	const _updateWebhook = async () => {
		if (formValues) {
			const errors = await validateForm(getUpdateWebhookFormSchema(formValues), formValues);
			console.log(errors)
			if (errors) {
				setFormErrors(errors);
				showFormErrorToast(errors);
				return;
			} else {
				setFormErrors({});
			}

			if (isFormLocked) {
				showAlert({
					title: t('Item has changed'),
					message: t('ItemFormLockedSubtitle'),
					buttons: [
						{
							text: t('Apply Changes'),
							onPress: () => {
								const values = webhooks.find((e) => e.id === route.params.id);
								_applyFormValues(values!);
							}
						},
						{
							text: t('Cancel'),
							style: 'destructive'
						}
					]
				});
				return;
			}

			if (formValues.isActive) {
				_update();
			} else {
				showAlert({
					title: t('WebhookInactive'),
					message: t('WebhookInactiveMessage'),
					buttons: [
						{
							text: t('Save'),
							onPress: () => _update()
						},
						{
							text: t('Cancel'),
							style: 'cancel'
						}
					]
				});
			}
		}
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _renderForm = () => {
		if (formValues) {
			return <WebhookForm values={formValues} errors={formErrors} isLoading={isLoading} onChange={updateFormValues} />;
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="webhooks">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderForm()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const WebhookUpdateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title="Webhooks" />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
