import { ChildButton, RoundButton } from 'components/Button';
import { IFormBase } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { FormField } from './FormField';
import { Text } from 'components/Text';
import DateTimePickerModal, { DateTimePickerProps } from 'react-native-modal-datetime-picker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { TextInput, View, ViewStyle, ScrollView } from 'react-native';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from './constants';
import { IS_IOS, IS_WEB } from 'helper/platform';
import { useSpace } from 'hooks/useSpace';
import { FormHint } from './FormHint';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { HSModal } from 'components/Modal/Modal';
import { FormCalendarPicker } from './FormCalendarPicker';
import { FormTextInput } from './FormTextInput';
import { FormLabel } from './FormLabel';

interface IFormDatePicker extends Omit<DateTimePickerProps, 'onConfirm' | 'onCancel'>, IFormBase {
	placeholder?: string;
	testID: string;
	value?: string | null | undefined;
	onChange?: (value: string | undefined | null) => void;
	formStyle?: ViewStyle;
	minimumMomentDate?: string;
	maximumMomentDate?: string;
	onError?: (error: string | undefined) => void;
	formLabel?: string;
}

export const FormDatePicker = (props: IFormDatePicker) => {
	const {
		testID,
		label,
		isRequired,
		hint,
		error,
		isDisabled,
		mode,
		date,
		value,
		onChange,
		formStyle,
		placeholder,
		minuteInterval,
		minimumMomentDate,
		maximumMomentDate,
		onError,
		formLabel
	} = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);
	const [isWebPickerVisible, setIsWebPickerVisible] = useState<boolean>(false);
	const [tempVal, setTempVal] = useState<string>('');
	const [tempTime, setTempTime] = useState<{ hours: string | undefined; minutes: string | undefined }>({
		hours: undefined,
		minutes: undefined
	});

	const [inputContainerSize, setInputContainerSize] = useState<number>(0);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const platformStyle = IS_WEB ? { outline: 'none' } : {};

	useEffect(() => {
		if (value) {
			const _format = _getFormat();
			setTempVal(moment(value).format(_format));
		}
	}, [value]);

	useEffect(() => {
		if (tempVal && mode?.includes('time')) {
			setTempTime({
				hours: tempTime.hours ?? moment(tempVal, _getFormat()).format('HH'),
				minutes: tempTime.minutes ?? moment(tempVal, _getFormat()).format('mm')
			});
		}
	}, [tempVal, mode]);

	const _getFormat = () => {
		let format = 'DD.MM.YYYY HH:mm';
		switch (mode) {
			case 'datetime':
				format = 'DD.MM.YYYY HH:mm';
				break;
			case 'time':
				format = 'HH:mm';
				break;
			case 'date':
				format = 'DD.MM.YYYY';
			default:
				break;
		}
		return format;
	};

	const _handleConfirm = (date: Date) => {
		if (onChange) {
			onChange(moment(date).toISOString());
			setIsPickerVisible(false);
		}
	};

	const _checkDate = (setValue?: boolean) => {
		if (isEmptyString(tempVal)) {
			if (onChange) {
				onChange(null);
			}
			return;
		}
		const _format = _getFormat();

		if (setValue) {
			if (moment(tempVal, _format).isValid()) {
				const val = tempVal ? moment(tempVal, _format) : undefined;
				if (onError) {
					onError(undefined);
				}
				if (val?.year() && val?.year() < moment().year()) {
					val.set('year', moment().year());
				}

				if (tempTime) {
					val?.set('hours', Number(tempTime.hours));
					val?.set('minutes', Number(tempTime.minutes));
				}

				if (!isEmptyString(minimumMomentDate) && val && val.isBefore(moment(minimumMomentDate, _format))) {
					const minDateString = moment(minimumMomentDate, _format).format(_format);
					if (onError) {
						onError(t('dateBeforeMinmumError').replace('%MINDATE%', minDateString));
					}
					setTempVal('');
					if (onChange) {
						onChange(null);
					}
					return;
				}
				if (!isEmptyString(maximumMomentDate) && val && val.isAfter(moment(maximumMomentDate, _format))) {
					const maxDateString = moment(maximumMomentDate, _format).format(_format);
					if (onError) {
						onError(t('dateAfterMaxiumumError').replace('%MAXDATE%', maxDateString));
					}
					setTempVal('');
					if (onChange) {
						onChange(null);
					}
					return;
				}
				if (val) {
					setTempVal(val.format(_format));
				} else {
					setTempVal('');
				}
				if (onChange) {
					if (val) {
						onChange(moment(val, _format).toISOString());
					} else {
						onChange(null);
					}
				}
			} else {
				if (onError) {
					onError(t('dateformatError').replace('%FORMAT%', _format).replace('Y', 'YYY'));
				}
			}
		} else {
			setTempVal(value ? moment(value).format(_format) : '');
		}
	};

	const _checkTime = () => {
		let _cleanedHours = tempTime.hours;
		let _cleanedMinutes = tempTime.minutes;
		if (!_cleanedHours || isEmptyString(_cleanedHours)) {
			_cleanedHours = '00';
		} else {
			if (Number(_cleanedHours) < 0) {
				_cleanedHours = '00';
			}
			if (Number(_cleanedHours) > 23) {
				_cleanedHours = '23';
			}
			if (_cleanedHours.length === 1) {
				_cleanedHours = '0' + _cleanedHours;
			}
		}

		if (!_cleanedMinutes || isEmptyString(_cleanedHours)) {
			_cleanedMinutes = '00';
		} else {
			if (Number(_cleanedMinutes) < 0) {
				_cleanedMinutes = '00';
			}
			if (Number(_cleanedMinutes) > 59) {
				_cleanedMinutes = '59';
			}
			if (_cleanedMinutes.length === 1) {
				_cleanedMinutes = '0' + _cleanedMinutes;
			}
		}

		setTempTime({ hours: _cleanedHours, minutes: _cleanedMinutes });
	};

	const _renderValue = () => {
		const val = value ? moment(value).format(_getFormat()) : '';

		return (
			<View
				style={[
					{
						flexDirection: 'row',
						borderRadius: FORMELEMENTBORDERRADIUS,
						borderWidth: FORMELEMENTBORDERWIDTH,
						borderColor: theme.lightgray,
						paddingHorizontal: 5,
						paddingVertical: 10
					}
				]}
			>
				<Text style={{ color: isDisabled ? theme.lightgray : theme.text }}>{!isEmptyString(val) ? val : placeholder}</Text>
			</View>
		);
	};

	const _getHint = () => {
		switch (mode) {
			case 'date':
				return t('DateHintDateOnly');
			case 'time':
				return t('DateHintTimeOnly');
			case 'datetime':
			default:
				return t('DateHintFullDate');
		}
	};

	const _getTitle = () => {
		let _title = '';

		if (label) {
			_title = label;
		} else if (formLabel) {
			_title = formLabel;
		} else {
			switch (mode) {
				case 'date':
					_title = t('SelectDateTime').replace('%MODE%', t('Date'));
					break;
				case 'time':
					_title = t('SelectDateTime').replace('%MODE%', t('Time'));
					break;
				case 'datetime':
				default:
					_title = t('SelectDateTime').replace('%MODE%', t('DateAndTime'));
					break;
			}
		}

		return _title;
	};

	const _renderPicker = () => {
		if (IS_WEB) {
			return (
				<>
					<FormHint testID="" hint={_getHint()} />

					<View
						style={[
							{
								flexDirection: 'row',
								justifyContent: 'space-between',
								borderRadius: FORMELEMENTBORDERRADIUS,
								borderWidth: FORMELEMENTBORDERWIDTH,
								borderColor: theme.lightgray,
								paddingHorizontal: 5,
								paddingVertical: 10
							}
						]}
						onLayout={(e) => setInputContainerSize(e.nativeEvent.layout.height)}
					>
						<TextInput
							testID={`${testID}_input`}
							placeholder={placeholder}
							style={[
								{ flex: 1, maxWidth: '100%', ...platformStyle, paddingRight: inputContainerSize },
								!isDisabled ? { color: isDisabled ? theme.lightgray : theme.text } : null
							]}
							value={tempVal ?? ''}
							onChangeText={(val) => {
								let _value = val.replace(/[^0-9.:\s]/g, '');
								setTempVal(_value);
								setTempTime({
									hours: moment(_value, _getFormat()).format('HH'),
									minutes: moment(_value, _getFormat()).format('mm')
								});
							}}
							onBlur={() => {
								setTimeout(() => {
									setIsFocused(false);
								}, 100);
								_checkDate(true);
							}}
							editable={!isDisabled}
							placeholderTextColor={theme.formgray}
							onFocus={() => setIsFocused(true)}
						/>
						<View
							style={{
								position: 'absolute',
								right: 0,
								top: 0,
								bottom: 0,
								width: inputContainerSize,
								alignItems: 'center',
								justifyContent: 'center',
								borderTopRightRadius: hsBorderRadius,
								borderBottomRightRadius: hsBorderRadius,
								borderLeftWidth: 1,
								borderColor: theme.formgray
							}}
						>
							<ChildButton
								testID={`${testID}_button_opencalendar`}
								style={{ alignItems: 'center' }}
								isDisabled={isDisabled}
								onPress={() => {
									if (!isFocused) setIsWebPickerVisible(true);
								}}
								onPressOut={() => {
									if (isFocused) setIsWebPickerVisible(true);
								}}
							>
								<Icon
									name={EDefaultIconSet.Calendar}
									size={inputContainerSize < 32 ? Math.round(inputContainerSize - 8) : 24}
								/>
							</ChildButton>
						</View>
						<HSModal
							isVisible={isWebPickerVisible}
							title={_getTitle()}
							onClose={() => {
								setTempVal(value ?? '');
								setTempTime({
									hours: value ? moment(value).format('HH') : '',
									minutes: value ? moment(value).format('mm') : ''
								});
								_checkDate(false);
								setIsWebPickerVisible(false);
							}}
							onSubmit={() => {
								_checkTime();
								_checkDate(true);
								setIsWebPickerVisible(false);
							}}
							isSubmitDisabled={!tempVal}
						>
							<ScrollView
								testID={`${testID}_modal_scrollview`}
								style={{ marginHorizontal: -hsInnerPadding }}
								contentContainerStyle={{
									padding: hsInnerPadding,
									paddingTop: hsTopScreenPadding / 2,
									alignItems: 'center'
								}}
							>
								{mode?.includes('date') && (
									<FormCalendarPicker
										testID={`${testID}_modal_calendar_date`}
										startDate={value ? moment(value).toISOString() : undefined}
										initialDate={value ? moment(value).toISOString() : undefined}
										minDate={minimumMomentDate}
										maxDate={maximumMomentDate}
										isDisabled={isDisabled}
										onStartDateChange={(date) => {
											setTempVal(date ?? '');
										}}
										isModal
									/>
								)}
								{mode?.includes('time') && (
									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center',
											marginBottom: hsBottomMargin
										}}
									>
										<FormLabel testID={`${testID}_label_time`} label={`${t('Time')}:`} style={{ marginRight: 10 }} />
										<FormTextInput
											testID={`${testID}_modal_input_hours`}
											value={tempTime.hours?.toString() === 'Invalid date' ? '' : tempTime.hours?.toString()}
											onChangeText={(val) => setTempTime({ ...tempTime, hours: val })}
											onBlur={() => _checkTime()}
											formStyle={{ width: 40, marginBottom: 0 }}
											placeholder="HH"
											keyboardType="number-pad"
											onlyNumbers
											hideButtons
										/>
										<Text largerText center style={{ marginHorizontal: 10 }}>
											:
										</Text>
										<FormTextInput
											testID={`${testID}_modal_input_minutes`}
											value={tempTime.minutes?.toString() === 'Invalid date' ? '' : tempTime.minutes?.toString()}
											onChangeText={(val) => setTempTime({ ...tempTime, minutes: val })}
											onBlur={() => _checkTime()}
											formStyle={{ width: 40, marginBottom: 0 }}
											placeholder="mm"
											keyboardType="number-pad"
											onlyNumbers
											hideButtons
										/>
									</View>
								)}
								{!isRequired && !isEmptyString(tempVal) && (
									<RoundButton
										testID={`${testID}_button_reset`}
										title={t('Reset')}
										alignSelf={'flex-end'}
										icon={EDefaultIconSet.Delete}
										color={theme.danger}
										size="sm"
										onPress={() => {
											setTempTime({ hours: undefined, minutes: undefined });
											setTempVal('');
											if (onChange) {
												onChange(null);
											}
											setIsWebPickerVisible(false);
										}}
										isOutline
									/>
								)}
							</ScrollView>
						</HSModal>
					</View>
				</>
			);
		}

		return (
			<View>
				<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
					<ChildButton
						testID={`${testID}_open`}
						style={{ flex: 1 }}
						isDisabled={isDisabled || !onChange}
						onPress={() => setIsPickerVisible(!isPickerVisible)}
					>
						{_renderValue()}
					</ChildButton>
					{value && (
						<ChildButton
							testID={`${testID}_clear`}
							style={{
								position: 'absolute',
								right: 0,
								top: 0,
								bottom: 0,
								zIndex: 1,
								justifyContent: 'center',
								paddingHorizontal: 5
							}}
							disabled={isDisabled || !onChange}
							onPress={() => {
								if (onChange) {
									onChange(null);
								}
							}}
						>
							<Icon name={EDefaultIconSet.Close} />
						</ChildButton>
					)}
				</View>
				<DateTimePickerModal
					testID={`${testID}_picker`}
					isVisible={isPickerVisible}
					mode={mode ?? 'date'}
					onConfirm={_handleConfirm}
					onCancel={() => setIsPickerVisible(false)}
					locale={activeSpace?.language ?? 'en'}
					date={date}
					cancelButtonTestID={`${testID}_cancel`}
					confirmButtonTestID={`${testID}_confirm`}
					cancelTextIOS={t('Cancel')}
					confirmTextIOS={t('Confirm')}
					//@ts-ignore
					minuteInterval={props.minuteInterval}
					//@ts-ignore
					display={IS_IOS && mode === 'datetime' ? 'inline' : 'spinner'}
				/>
			</View>
		);
	};

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			{_renderPicker()}
		</FormField>
	);
};
