import { ChildButton, RoundButton } from 'components/Button';
import { HSCard, HSDragCard } from 'components/Card';
import { FormReferenceSelect, FormTextInput } from 'components/Form';
import { ICreateScheduleFormValues, ISpeakerGroup, IUpdateScheduleFormValues } from 'config/interfaces';
import { EDefaultIconSet } from 'helper/icon';
import { swapArrayItems } from 'helper/array';
import { validateForm } from 'helper/validation';
import { IS_ANDROID } from 'helper/platform';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { ScrollView, Vibration } from 'react-native';
import { NestableDraggableFlatList, NestableScrollContainer } from 'react-native-draggable-flatlist';
import { HSModal } from '../Modal';
import * as Yup from 'yup';
import { getRequiredError } from 'config/yupSchemas/errors';
import { H2, Text } from 'components/Text';
import { showToast } from 'helper/toast';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { SpeakerListItem } from 'components/Speaker';
import { v4 } from 'uuid';
import { Icon } from 'components/Icon';

interface ISpeakerModal {
	isVisible: boolean;
	onClose: () => void;
	values: ICreateScheduleFormValues | IUpdateScheduleFormValues;
	onChange: (val: ICreateScheduleFormValues | IUpdateScheduleFormValues) => void;
}

const TESTIDPREFIX = 'speakergroupsmodal';

export const SpeakerGroupsModal = (props: ISpeakerModal) => {
	const { isVisible, onClose, values, onChange } = props;

	const [tempSpeakerGroups, setTempSpeakerGroups] = useState<ISpeakerGroup[]>([]);
	const [speakersWithoutGroup, setSpeakersWithoutGroup] = useState<number[]>([]);
	const [isGroupExpanded, setIsGroupExpanded] = useState<Record<string, boolean>>({});
	const [errors, setErrors] = useState<Record<string, string>>({});

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		if (isVisible) {
			setTempSpeakerGroups(values.speakerGroups ? [...values.speakerGroups.groups] : []);
		} else {
			setTempSpeakerGroups([]);
			setSpeakersWithoutGroup([]);
		}
	}, [isVisible]);

	useEffect(() => {
		const _spIds: typeof speakersWithoutGroup = [];
		values.speakers?.forEach((speakerId) => {
			const found = tempSpeakerGroups.find((e) => e.speakerIds.includes(speakerId));
			if (!found) {
				_spIds.push(speakerId);
			}
		});
		setSpeakersWithoutGroup(_spIds);
	}, [tempSpeakerGroups]);

	const _handleSubmit = async () => {
		const obj = {
			speakerGroups: tempSpeakerGroups
		};
		const schema = Yup.object().shape({
			speakerGroups: Yup.array()
				.of(
					Yup.object().shape({
						title: Yup.string()
							.required(getRequiredError(t('Title')))
							.nullable()
					})
				)
				.nullable()
		});
		const _errors = await validateForm(schema, obj);
		if (_errors) {
			setErrors(_errors);
			showToast('error', 'Error', t('FormHasErrors'));
			return;
		} else {
			setErrors({});
		}
		const newSpeakers: number[] = [...speakersWithoutGroup];
		tempSpeakerGroups.forEach((group) => {
			group.speakerIds.forEach((id) => {
				if (!newSpeakers.includes(id)) {
					newSpeakers.push(id);
				}
			});
		});

		onChange({ ...values, speakerGroups: { groups: tempSpeakerGroups }, speakers: newSpeakers });
		onClose();
	};

	const _addGroup = () => {
		const _groups = [...tempSpeakerGroups];
		const groupKey = v4();
		_groups.push({
			key: groupKey,
			title: '',
			speakerIds: []
		});
		setIsGroupExpanded({ ...isGroupExpanded, [groupKey]: true });
		setTempSpeakerGroups(_groups);
	};

	const _removeGroup = (index: number) => {
		const _groups = [...tempSpeakerGroups];
		_groups.splice(index, 1);
		setTempSpeakerGroups(_groups);
	};

	const _handleMove = (index, direction: 'up' | 'down') => {
		let _groups = [...tempSpeakerGroups];

		_groups = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, _groups);
		setTempSpeakerGroups(_groups);
	};

	const _handleGroupIndexChange = (oldIndex: number, newIndex: number) => {
		const _groups = [...tempSpeakerGroups];

		const element = _groups.splice(oldIndex, 1)[0];
		_groups.splice(newIndex, 0, element);

		setTempSpeakerGroups(_groups);
	};

	const _handleSpeakerMove = (groupIndex: number, index: number, direction: 'up' | 'down') => {
		let _groups = [...tempSpeakerGroups];

		_groups[groupIndex].speakerIds = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, _groups[groupIndex].speakerIds);
		setTempSpeakerGroups(_groups);
	};

	const _handleSpeakerIndexChange = (groupIndex: number, oldIndex: number, newIndex: number) => {
		const _groups = [...tempSpeakerGroups];

		const element = _groups[groupIndex].speakerIds.splice(oldIndex, 1)[0];
		_groups[groupIndex].speakerIds.splice(newIndex, 0, element);

		setTempSpeakerGroups(_groups);
	};

	const _handleFieldUpdate = (index: number, fieldName: string, value: any) => {
		const _groups = [...tempSpeakerGroups];
		_groups[index][fieldName] = value;
		setTempSpeakerGroups(_groups);
	};

	const _toggleGroupExpand = (groupKey: string) => {
		const _expanded = { ...isGroupExpanded };
		if (_expanded[groupKey]) {
			delete _expanded[groupKey];
		} else {
			_expanded[groupKey] = true;
		}

		setIsGroupExpanded(_expanded);
	};

	const _renderGroup = (params) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSCard>
				<HSDragCard
					testID={`${TESTIDPREFIX}_group_${item.title}`}
					onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
					onDownPress={index === (tempSpeakerGroups.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
					onRemove={() => _removeGroup(index)}
					onDrag={drag}
					noCard
					index={index}
					handleIndexChange={(newIndex) => _handleGroupIndexChange(index, newIndex)}
				>
					<FormTextInput
						testID={`${TESTIDPREFIX}_formtextinput_${index}_title`}
						label={t('Title')}
						isRequired
						error={errors[`speakerGroups[${index}].title`]}
						value={item.title}
						onChangeText={(value) => _handleFieldUpdate(index, 'title', value)}
					/>
				</HSDragCard>
				<ChildButton
					testID={`${TESTIDPREFIX}_group_${index}_toggleexpand`}
					style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginVertical: 10 }}
					onPress={() => _toggleGroupExpand(item.key)}
				>
					<Text bold>{t('Speakers')}</Text>
					<Icon name={isGroupExpanded[item.key] ? EDefaultIconSet.ChevronDown : EDefaultIconSet.ChevronRight} size={25} />
				</ChildButton>

				{isGroupExpanded[item.key] ? (
					<>
						<FormReferenceSelect
							key={`${TESTIDPREFIX}_referenceselect_${index}_speakers`}
							testID={`${TESTIDPREFIX}_referenceselect_${index}_speakers`}
							fieldName="speakerIds"
							placeholder={t('Select Speakers')}
							type="speakers"
							multiSelect
							error={errors[`speakerGroups[${index}].speakerIds`]}
							value={item.speakerIds}
							onSelect={(value) => _handleFieldUpdate(index, 'speakerIds', value)}
							contentType="speakers"
						/>

						<NestableDraggableFlatList
							data={tempSpeakerGroups[index].speakerIds}
							renderItem={(params) => _renderGroupEntry(params, index)}
							keyExtractor={(item, idx) => `speakergroup_${idx}}`}
							onDragBegin={() => {
								if (IS_ANDROID) {
									Vibration.vibrate(10, false);
								}
							}}
							onDragEnd={({ data }) => {
								const _groups = [...tempSpeakerGroups];
								_groups[index].speakerIds = data;
								setTempSpeakerGroups(_groups);
							}}
						/>
					</>
				) : null}
			</HSCard>
		);
	};

	const _renderGroupEntry = (params, groupIndex: number) => {
		const { item, index, drag, isActive } = params;

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_option_${index}`}
				onUpPress={index === 0 ? undefined : () => _handleSpeakerMove(groupIndex, index, 'up')}
				onDownPress={
					index === (tempSpeakerGroups[groupIndex].speakerIds.length ?? 9999) - 1
						? undefined
						: () => _handleSpeakerMove(groupIndex, index, 'down')
				}
				onDrag={drag}
				noCard
				index={index}
				handleIndexChange={(newIndex) => _handleSpeakerIndexChange(groupIndex, index, newIndex)}
			>
				<SpeakerListItem
					testID={`${TESTIDPREFIX}_speaker_${item}`}
					item={content.speakers.find((e) => e.id === Number(item))!}
					avatarSize="drawer"
					height={50}
					onDelete={() => {
						const val = [...tempSpeakerGroups];
						val[groupIndex].speakerIds = val[groupIndex].speakerIds.filter((e) => e !== item);
						setTempSpeakerGroups(val);
					}}
				/>
			</HSDragCard>
		);
	};

	const _renderSpeakersWithoutGroups = () => {
		if (speakersWithoutGroup.length > 0) {
			return (
				<HSCard>
					<H2 center>{t('Ungrouped Speakers')}</H2>
					{speakersWithoutGroup.map((e) => (
						<SpeakerListItem
							key={`withoutgroup_${e}`}
							item={content.speakers.find((e2) => e2.id === e)}
							avatarSize="drawer"
							height={50}
							onDelete={() => {
								const _val = [...speakersWithoutGroup].filter((e2) => e2 !== e);
								setSpeakersWithoutGroup(_val);
							}}
						/>
					))}
				</HSCard>
			);
		}

		return null;
	};

	return (
		<HSModal isVisible={isVisible} onClose={onClose} onSubmit={_handleSubmit} title={t('Speaker Groups')}>
			<Text>{t('SpeakerGroupsIntroText')}</Text>
			<ScrollView style={{ flex: 1 }}>
				<NestableScrollContainer>
					<NestableDraggableFlatList
						data={tempSpeakerGroups}
						renderItem={_renderGroup}
						keyExtractor={(item, idx) => `speakergroup_${idx}}`}
						onDragBegin={() => {
							if (IS_ANDROID) {
								Vibration.vibrate(10, false);
							}
						}}
						onDragEnd={({ data }) => setTempSpeakerGroups(data)}
					/>
					{/* {_renderSpeakersWithoutGroups()} */}
					<RoundButton
						testID={`${TESTIDPREFIX}_button_addspeakergroup`}
						onPress={() => _addGroup()}
						icon={EDefaultIconSet.Add}
						title={t('Add Group')}
						alignSelf="flex-end"
					/>
				</NestableScrollContainer>
			</ScrollView>
		</HSModal>
	);
};
