import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import {
	NavigationHeader,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle,
	NavigationHeaderBackButton
} from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { IActiveScreenFilter, IMailTemplate } from 'config/interfaces';
import { Animated, Dimensions, FlatList, Keyboard, ScrollView, View } from 'react-native';
import { ISendMailPayload } from 'rematch/interfaces';
import {
	hsBottomMargin,
	LISTHEADER_ANIMATIONTIME_IN,
	LISTHEADER_ANIMATIONTIME_OUT,
	LISTHEADER_ANIMATION_GUESSED_HEIGHT,
	LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_HEIGHT_FACTOR,
	LISTHEADER_ANIMATION_OPACITY_HIGH,
	LISTHEADER_ANIMATION_OPACITY_LOW,
	LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION
} from 'config/styleConstants';

import { TContentType } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EDefaultIconSet, getCardContainerStyle, getTimingAnimation, isEmptyString, IS_WEB } from 'helper';
import { HSCard } from 'components/Card';
import { StepWizard } from 'components/StepWizard';
import { H1, SectionHeader } from 'components/Text';
import { useSelector } from 'react-redux';
import { NoData } from 'components/NoData';
import { MailPreview, MailTemplateListItem } from 'components/Mailing';
import { MailTemplateForm } from 'components/Forms';
import { AttendeeList, AttendeeListItem } from 'components/Attendee';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { ExpoList, ExpoMailReceiverListItem } from 'components/Expo';
import { SpeakerList, SpeakerMailReceiverListItem } from 'components/Speaker';
import { SpaceAdministratorListEntry } from 'components/SpaceAdministratorList';
import { FormHint } from 'components/Form/FormHint';
import {
	_getAccountConfirmationEmail,
	_getExpoSelfServiceInvitation,
	_getHelloSpacesTicketInvitation,
	_getPasswordResetEmail,
	_getSpeakerSelfServiceInvitation,
	_getDefaultHelloSpacesInvitation,
	_getInviteUnregisteredAdmin
} from 'config/mailing';
import { TicketList, TicketMailReceiverListItem } from 'components/Ticket';
import { FormTextInput } from 'components/Form';
import { ContentListHeader } from 'components/List';
import { ScrollEvent } from 'react-native-spring-scrollview';
import moment from 'moment';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.SendMail>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.SendMail>;
type RouteParams = StackParamList[ERoutes.SendMail];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'sendmail';

export const SendMailScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth, screenColumnCount } = useQuery();
	const { activeSpace } = useSpace();

	const [sendMailFormValues, setSendMailFormValues] = useState<ISendMailPayload>({
		title: '',
		subject: '',
		body: '',
		contentType: 'noTemplate',
		selectedEntries: [],
		useDefaultHeader: true,
		useDefaultFooter: true
	});
	const [sendMailFormErrors, setSendMailFormErrors] = useState<Record<string, string>>({});
	const [isSendMailLoading, setIsSendMailLoading] = useState<boolean>(false);
	const [receivers, setReceivers] = useState<any[]>([]);
	const [spaceTemplates, setSpaceTemplates] = useState<IMailTemplate[]>([]);
	const [currentStep, setCurentStep] = useState<number>(0);
	const [selectedIds, setSelectedIds] = useState<any[]>([]);
	const [newReceiver, setNewReceiver] = useState<string>('');
	const [mailReceivers, setMailReceivers] = useState<string[]>([]);
	const [hasValues, setHasValues] = useState<boolean>(false);
	const [hasNoTemplate, setHasNoTemplate] = useState<boolean>(false);

	const [activeScreenFilters, setActiveScreenFilters] = useState<IActiveScreenFilter>({ filters: ['all'] });
	const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);
	const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(true);
	const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(LISTHEADER_ANIMATION_GUESSED_HEIGHT));
	const [animatedOpacity, setAnimatedOpacity] = useState(new Animated.Value(LISTHEADER_ANIMATION_OPACITY_HIGH));
	const [scrollableContentHeight, setScrollableContentHeight] = useState<number>(0);
	const scrollOffset = useRef<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const tickets = useSelector((store: IRootState) => store.ticket.tickets);

	const sendMail = useRematchDispatch((dispatch: Dispatch) => dispatch.content.sendMail);

	useEffect(() => {
		let _templates: typeof spaceTemplates = [];

		if (content.mailtemplates && activeSpace?.spaceId) {
			_templates = content.mailtemplates.filter((e) => e.spaceId === activeSpace.spaceId);
			const _type = route?.params?.contentType ?? _getType();
			switch (_type) {
				case 'expo':
					_templates = _templates.filter((e) => e.contentType === 'expo');
					const hasExpoSelfServiceInvitationTemplate = _templates.find((e) => e.key === 'expoSelfServiceInvitation');
					if (!hasExpoSelfServiceInvitationTemplate) {
						_templates.push(_getExpoSelfServiceInvitation());
					}
					break;
				case 'speaker':
					_templates = _templates.filter((e) => e.contentType === 'speaker');
					const hasSpeakerSelfServiceInvitationTemplate = _templates.find((e) => e.key === 'speakerSelfServiceInvitation');
					if (!hasSpeakerSelfServiceInvitationTemplate) {
						_templates.push(_getSpeakerSelfServiceInvitation());
					}
					break;
				case 'attendee':
					_templates = _templates.filter((e) => e.contentType === 'attendee');
					const hasPWRecoveryTemplate = _templates.find((e) => e.key === 'passwordRecovery');
					if (!hasPWRecoveryTemplate) {
						_templates.push(_getPasswordResetEmail());
					}
					const hasConfirmAccountTemplate = _templates.find((e) => e.key === 'confirmAccount');
					if (!hasConfirmAccountTemplate) {
						_templates.push(_getAccountConfirmationEmail());
					}
					break;
				case 'admin':
					_templates = _templates.filter((e) => e.contentType === 'admin');
					break;
				case 'moderator':
					_templates = _templates.filter((e) => e.contentType === 'moderator');
					break;
				case 'ticket':
					_templates = _templates.filter((e) => e.contentType === 'ticket');
					const hasTicketInvitationTemplate = _templates.find((e) => e.key === 'helloSpacesTicketInvitation');
					if (!hasTicketInvitationTemplate) {
						_templates.push(_getHelloSpacesTicketInvitation());
					}
					break;
				case 'invitation':
				default:
					const hasInviteUnregisteredAdminTemplate = _templates.find((e) => e.key === 'newUnregisteredAdmin');
					if (!hasInviteUnregisteredAdminTemplate) {
						_templates.push(_getInviteUnregisteredAdmin());
					}
					const hasDefaultInvitationTemplate = _templates.find((e) => e.key === 'defaultHelloSpacesInvitation');
					if (!hasDefaultInvitationTemplate) {
						_templates.push(_getDefaultHelloSpacesInvitation(activeSpace.ticketSettings?.ticketRequired));
					}

					break;
			}
		}

		const inactiveFeatureKeys =
			activeSpace?.features?.list?.filter((feature) => !feature.isActive)?.map((f) => f.key?.slice(0, -1)) ?? [];

		if (inactiveFeatureKeys?.length > 0) {
			_templates = _templates?.filter((t) => !inactiveFeatureKeys?.includes(t?.contentType ?? '') || t?.contentType === 'attendee');
		}

		const uniqueTemplates: typeof _templates = [];

		_templates
			.sort((a, b) => {
				return moment(a.updated_at).isBefore(moment(b.updated_at)) ? 1 : -1;
			})
			.forEach((e) => {
				if (!uniqueTemplates.find((e2) => e.key === e2.key)) {
					uniqueTemplates.push(e);
				}
			});

		uniqueTemplates.sort((a, b) => {
			const aVal = a.contentType;
			const bVal = b.contentType;
			if (aVal.toLowerCase() === bVal.toLowerCase()) {
				return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
			}
			return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
		});

		setSpaceTemplates(uniqueTemplates);
	}, [content, activeSpace]);

	useEffect(() => {
		if (route.params.template) {
			setSendMailFormValues({
				title: route.params.template.title,
				subject: route.params.template.subject,
				body: route.params.template.body,
				mailTemplateId: route.params.template.id,
				useDefaultHeader: route.params.template.useDefaultHeader,
				useDefaultFooter: route.params.template.useDefaultFooter,
				contentType: route.params.template.contentType,
				selectedEntries: []
			});
			navigation.setParams({ contentType: route.params.template.contentType });
		} else {
			if (route.params.contentType) {
				setSendMailFormValues({
					...sendMailFormValues,
					contentType: route.params.contentType
				});
			}
		}

		if (route.params.selectedEntries) {
			setSelectedIds(route.params.selectedEntries);
		}
	}, []);

	useEffect(() => {
		const _receivers: typeof receivers = [];

		route.params.selectedEntries?.forEach((selectedId) => {
			switch (_getType()) {
				case 'attendee':
				case 'admin':
				case 'moderator':
					const attendee = attendees.find((e) => e.userId === selectedId);
					if (attendee) {
						_receivers.push(attendee);
					}
					break;
				case 'ticket':
					const ticket = tickets?.find((e) => e.id === selectedId);
					if (ticket) {
						_receivers.push(ticket);
					}
					break;
				case 'expo':
					const expo = content.expos?.find((e) => e.id === selectedId);
					if (expo) {
						_receivers.push(expo);
					}
					break;
				case 'speaker':
					const speaker = content.speakers?.find((e) => e.id === selectedId);
					if (speaker) {
						_receivers.push(speaker);
					}
					break;
				case 'invitation':
				case 'noTemplate':
				default:
					sendMailFormValues.contentType = _getType();
					break;
			}
		});

		selectedIds.forEach((selectedId) => {
			switch (_getType()) {
				case 'attendee':
				case 'admin':
				case 'moderator':
					const attendee = attendees.find((e) => e.userId === selectedId);
					if (attendee && !_receivers.find((e) => e.userId === selectedId)) {
						_receivers.push(attendee);
					}
					break;
				case 'ticket':
					const ticket = tickets?.find((e) => e.id === selectedId);
					if (ticket && !_receivers.find((e) => e.id === selectedId)) {
						_receivers.push(ticket);
					}
					break;
				case 'expo':
					const expo = content.expos?.find((e) => e.id === selectedId);
					if (expo && !_receivers.find((e) => e.id === selectedId)) {
						_receivers.push(expo);
					}
					break;
				case 'speaker':
					const speaker = content.speakers?.find((e) => e.id === selectedId);
					if (speaker && !_receivers.find((e) => e.id === selectedId)) {
						_receivers.push(speaker);
					}
					break;
				case 'invitation':
				case 'noTemplate':
				default:
					sendMailFormValues.contentType = _getType();
					break;
			}
		});

		setReceivers(_receivers);
	}, [attendees, content, selectedIds]);

	const _getType = () => {
		let _type;

		if (sendMailFormValues.mailTemplateId) {
			_type = spaceTemplates.find((e) => e.id === sendMailFormValues.mailTemplateId)?.contentType;
		}

		//für andere Typen noch hinzufügen, da diese noch keine ID haben.
		if (!_type && !hasNoTemplate) {
			_type = spaceTemplates.find((e) => e.key === 'defaultHelloSpacesInvitation')?.contentType;
		}

		if (hasNoTemplate) _type = 'noTemplate';

		return route.params.contentType ?? _type;
	};

	useEffect(() => {
		if (selectedIds.length > 0) setSelectedIds([]);
		if (mailReceivers.length > 0) setMailReceivers([]);
	}, [_getType()]);

	const _handleSelect = (itemId) => {
		let _selected = [...selectedIds];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedIds(_selected);
	};

	const _handleSelectAll = (itemIds: any[]) => {
		let _selected = [...selectedIds];

		if (itemIds.length === 0) {
			_selected = [];
		} else {
			itemIds.forEach((itemId) => {
				if (!_selected.find((item) => item === itemId)) _selected.push(itemId);
			});
		}

		setSelectedIds(_selected);
	};

	const _isNextButtonDisabled = () => {
		switch (route.params.entryPoint) {
			case 'receivers':
				switch (currentStep) {
					case 0:
					case 1:
						if (_getType() === 'noTemplate' || _getType() === 'invitation') return mailReceivers.length === 0;
						return selectedIds.length === 0;
					case 2:
						return isEmptyString(sendMailFormValues.subject) || isEmptyString(sendMailFormValues.body);
					default:
						break;
				}
				break;
			case 'template':
				switch (currentStep) {
					case 0:
					case 1:
						if (_getType() === 'noTemplate' || _getType() === 'invitation') return mailReceivers.length === 0;
						return selectedIds.length === 0;
					case 2:
						return isEmptyString(sendMailFormValues.subject) || isEmptyString(sendMailFormValues.body);
					default:
						break;
				}
				break;
			default:
				const componentCount = _getComponents().length;
				if (componentCount === 3) {
					switch (currentStep) {
						case 0:
							return !sendMailFormValues.mailTemplateId;
						case 1:
							return (
								isEmptyString(sendMailFormValues.subject) ||
								isEmptyString(sendMailFormValues.body) ||
								(_getType() !== 'attendee' && receivers.find((e) => !isEmptyString(e.selfServiceEmail)) === undefined)
							);
						default:
							break;
					}
				} else if (componentCount === 2) {
					switch (currentStep) {
						case 0:
							return (
								isEmptyString(sendMailFormValues.subject) ||
								isEmptyString(sendMailFormValues.body) ||
								(_getType() !== 'attendee' && receivers.find((e) => !isEmptyString(e.selfServiceEmail)) === undefined)
							);
						default:
							break;
					}
				}
				break;
		}

		return false;
	};

	const _onSubmit = async () => {
		const template = sendMailFormValues.mailTemplateId
			? spaceTemplates.find((e) => e.id === sendMailFormValues.mailTemplateId)
			: undefined;
		setIsSendMailLoading(true);
		const res = await sendMail({
			...sendMailFormValues,
			mailTemplateKey: template?.key,
			selectedEntries: route.params.selectedEntries
				? [...route.params.selectedEntries]
				: _getType() === 'noTemplate' || _getType() === 'invitation'
				? mailReceivers
				: selectedIds,
			sendingInformation:
				route.params.linkedType || route.params.linkedItemId
					? {
							linkedType: route.params.linkedType,
							linkedItemId: route.params.linkedItemId
					  }
					: undefined
		});
		setIsSendMailLoading(false);
		if (res?.id) {
			navigation.replace(ERoutes.MailHistory, { spaceId: activeSpace?.spaceId });
		} else if (res === true) {
			navigation.goBack();
		}
	};

	const _updateFormValues = (values: ISendMailPayload) => {
		setSendMailFormValues(values);
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _getMailPreviewBody = () => {
		let body = sendMailFormValues.body;

		if (sendMailFormValues.useDefaultHeader && !isEmptyString(activeSpace?.defaultMailTemplateHeader)) {
			body = activeSpace?.defaultMailTemplateHeader + body;
		}

		if (sendMailFormValues.useDefaultFooter && !isEmptyString(activeSpace?.defaultMailTemplateFooter)) {
			body += activeSpace?.defaultMailTemplateFooter;
		}

		return body;
	};

	const _addReceiver = (rec: string) => {
		const _existingUser = attendees.find((e) => e.email === rec);
		if (
			_getType() === 'invitation' &&
			_existingUser &&
			_existingUser.userSpaceHistory?.spaces.find((e2) => e2.spaceId === activeSpace?.spaceId)
		) {
			setSendMailFormErrors({
				...sendMailFormErrors,
				email: t('UserIsAlreadyInSpace')
			});
		} else if (mailReceivers.find((e) => e === rec)) {
			setSendMailFormErrors({
				...sendMailFormErrors,
				email: t('EntryAlreadySelected')
			});
		} else {
			let match =
				/\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b/.test(rec) &&
				!rec.includes(' ') &&
				rec.split('@').length === 2 &&
				!/[.]{2,}/.test(rec) &&
				!rec.split('@').find((e) => e.startsWith('.') || e.endsWith('.'));
			if (match) {
				let _receivers = mailReceivers;
				_receivers.push(rec);
				setMailReceivers(_receivers);
				setNewReceiver('');
				_updateFormValues({ ...sendMailFormValues, contentType: _getType() });
				setSendMailFormErrors({
					...sendMailFormErrors,
					email: ''
				});
			} else {
				setSendMailFormErrors({
					...sendMailFormErrors,
					email: t('FormFieldEmailError').replace('%LABEL%', t('email'))
				});
			}
		}
	};

	const _removeReceiver = (rec: string) => {
		let _receivers = mailReceivers;
		_receivers = _receivers.filter((e) => e !== rec);
		setMailReceivers(_receivers);
	};

	const _animateHeader = (e: ScrollEvent) => {
		const { y } = e.nativeEvent.contentOffset;

		if (
			headerHeight &&
			!IS_WEB &&
			scrollableContentHeight > LISTHEADER_ANIMATION_HEIGHT_FACTOR * Dimensions.get('window').height - headerHeight
		) {
			if (y > headerHeight * LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR && y > scrollOffset.current) {
				if (isHeaderExpanded) {
					setIsHeaderExpanded(false);

					Animated.parallel([
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_LOW, LISTHEADER_ANIMATIONTIME_OUT, false),
						getTimingAnimation(animatedHeight, 0, LISTHEADER_ANIMATIONTIME_OUT, false)
					]).start();
				}
			} else if (
				y + (scrollOffset.current < LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION ? 0 : LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION) <
				scrollOffset.current
			) {
				if (!isHeaderExpanded) {
					setIsHeaderExpanded(true);
					Animated.parallel([
						getTimingAnimation(animatedHeight, headerHeight, LISTHEADER_ANIMATIONTIME_IN, false),
						getTimingAnimation(animatedOpacity, LISTHEADER_ANIMATION_OPACITY_HIGH, LISTHEADER_ANIMATIONTIME_IN, false)
					]).start();
				}
			}
			scrollOffset.current = y;
		}
	};

	const _renderContentListHeader = (type: TContentType, modalFilterTitle: string) => {
		return (
			<Animated.View
				style={{
					height: animatedHeight,
					opacity: animatedOpacity,
					marginBottom: isHeaderExpanded ? hsBottomMargin : 0
				}}
			>
				<ContentListHeader
					testID={TESTIDPREFIX}
					type={type}
					searchTerm={searchTerm}
					onSearchChange={(val) => setSearchTerm(val)}
					modalFilterTitle={modalFilterTitle}
					activeScreenFilters={activeScreenFilters.filters}
					onScreenFilterChange={(values) => setActiveScreenFilters(values)}
					activeModalFilters={activeFilters}
					onModalFilterChange={(val) => setActiveFilters(val)}
					topChildren={<SectionHeader label={t('PleaseChooseMailTemplateReceivers')} />}
					onLayout={(e) => {
						const { height } = e.nativeEvent.layout;

						if (!headerHeight || height > headerHeight) {
							setHeaderHeight(height);
							setAnimatedHeight(new Animated.Value(height));
						}
					}}
				/>
			</Animated.View>
		);
	};

	const _renderReceiverList = () => {
		const type = _getType();

		switch (type) {
			case 'attendee':
			case 'admin':
			case 'moderator':
				return (
					<>
						{_renderContentListHeader('attendee', t('Filter Attendees'))}

						<AttendeeList
							noSponsors
							testID={TESTIDPREFIX}
							selectedAttendees={selectedIds}
							onSelect={(itemId) => _handleSelect(itemId)}
							onSelectAll={(itemIds) => _handleSelectAll(itemIds)}
							onPress={(item) =>
								navigation.navigate(ERoutes.AttendeeDetails, {
									userId: item.userId,
									spaceId: activeSpace?.spaceId
								})
							}
							filter={type !== 'attendee' ? type : undefined}
							isAdminArea
							searchTerm={searchTerm}
							activeScreenFilter={activeScreenFilters}
							activeModalFilters={activeFilters}
							onScroll={(e) => _animateHeader(e)}
							onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
							isMailReceiver
						/>
					</>
				);
			case 'ticket':
				return (
					<TicketList
						testID={TESTIDPREFIX}
						selectedTickets={selectedIds}
						onSelect={(itemId) => _handleSelect(itemId)}
						onSelectAll={(itemIds) => _handleSelectAll(itemIds)}
						onPress={(item) => navigation.navigate(ERoutes.TicketEdit, { spaceId: activeSpace?.spaceId, id: item.id })}
						isMailReceiver
					/>
				);
			case 'expo':
				return (
					<>
						{_renderContentListHeader('expo', t('Filter Expos'))}

						<ExpoList
							testID={TESTIDPREFIX}
							selectedExpos={selectedIds}
							onSelect={(itemId) => _handleSelect(itemId)}
							onSelectAll={(itemIds) => _handleSelectAll(itemIds)}
							onPress={(item) =>
								navigation.navigate(ERoutes.ExpoDetails, {
									id: item.id,
									spaceId: activeSpace?.spaceId
								})
							}
							searchTerm={searchTerm}
							activeScreenFilter={activeScreenFilters}
							activeModalFilters={activeFilters}
							onScroll={(e) => _animateHeader(e)}
							onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
							isMailReceiver
						/>
					</>
				);
			case 'speaker':
				return (
					<>
						{_renderContentListHeader('speaker', t('Filter Speaker'))}

						<SpeakerList
							testID={TESTIDPREFIX}
							selectedSpeakers={selectedIds}
							onSelect={(itemId) => _handleSelect(itemId)}
							onSelectAll={(itemIds) => _handleSelectAll(itemIds)}
							onPress={(item) =>
								navigation.navigate(ERoutes.SpeakerDetails, {
									id: item.id,
									spaceId: activeSpace?.spaceId
								})
							}
							searchTerm={searchTerm}
							activeScreenFilter={activeScreenFilters}
							activeModalFilters={activeFilters}
							onScroll={(e) => _animateHeader(e)}
							onContentSizeChange={(e) => setScrollableContentHeight(e.height)}
							isMailReceiver
						/>
					</>
				);
			case 'invitation':
			case 'noTemplate':
			default:
				const _numColumns = screenColumnCount ?? 2;
				return (
					<View>
						<HSCard>
							<View style={{ flexDirection: 'row', paddingRight: 40 }}>
								<FormTextInput
									blurOnSubmit={false}
									testID={`${TESTIDPREFIX}_input_email`}
									label={t('PleaseChooseMailTemplateReceivers')}
									hint={t('EnterMailReceiverHint')}
									value={newReceiver?.toLowerCase()}
									error={sendMailFormErrors.email}
									isRequired
									onChangeText={(val) => {
										if (!val) setSendMailFormErrors({ ...sendMailFormErrors, email: '' });
										navigation.setParams({ prohibitNavigation: true });
										setNewReceiver(val);
									}}
									formStyle={{ marginBottom: 5, paddingRight: 5 }}
									onKeyPress={(e) => {
										if (e.nativeEvent.key === 'Enter') {
											if (!isEmptyString(newReceiver) && newReceiver) {
												if (newReceiver.includes(',')) {
													let _receiver = newReceiver.toLowerCase().split(',');
													_receiver.forEach((r) => {
														_addReceiver(r.trim());
													});
												} else {
													_addReceiver(newReceiver.toLowerCase());
												}
											}
										}
									}}
								/>
								<View
									style={{
										alignSelf: 'flex-end',
										marginBottom: isEmptyString(sendMailFormErrors.email) ? 0 : hsBottomMargin
									}}
								>
									<RoundButton
										testID="activevote_button_addreceiver"
										icon={EDefaultIconSet.Add}
										size="sm"
										isStacked
										onPress={() => {
											if (!isEmptyString(newReceiver) && newReceiver) {
												if (newReceiver.includes(',')) {
													let _receiver = newReceiver.toLowerCase().split(',');
													_receiver.forEach((r) => {
														_addReceiver(r.trim());
													});
												} else {
													_addReceiver(newReceiver.toLowerCase());
												}
											}
										}}
									/>
								</View>
							</View>
						</HSCard>
						{mailReceivers.length > 0 && (
							<HSCard>
								<ScrollView
									contentContainerStyle={{ flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row' }}
									key={`${TESTIDPREFIX}_receiver_scrollview`}
									testID={`${TESTIDPREFIX}_receiver_scrollview`}
								>
									{mailReceivers.map((item, index) => {
										return (
											<View
												key={`${TESTIDPREFIX}_receiver_${index}`}
												style={{
													...getCardContainerStyle(_numColumns, index),
													width: `${100 / _numColumns}%`,
													flex: undefined
												}}
											>
												<HSCard style={{ marginBottom: 5, flexDirection: 'row' }}>
													<View
														style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}
														testID={`${TESTIDPREFIX}_receiver_${index}`}
													>
														<View style={{ flex: 1, justifyContent: 'center' }}>
															<Text>{item}</Text>
														</View>
														<RoundButton
															testID={`${TESTIDPREFIX}_button_removereceiver`}
															icon={EDefaultIconSet.Delete}
															size="sm"
															isStacked
															onPress={() => {
																_removeReceiver(item);
															}}
														/>
													</View>
												</HSCard>
											</View>
										);
									})}
								</ScrollView>
							</HSCard>
						)}
					</View>
				);
		}
	};

	const _renderMailTemplateList = () => {
		return (
			<ScrollView
				key="select_template_step"
				contentContainerStyle={{
					width: screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsBottomMargin
				}}
			>
				<FlatList
					testID={`${TESTIDPREFIX}_scrollview`}
					data={spaceTemplates}
					ListHeaderComponent={
						<View key={`${TESTIDPREFIX}_template_empty`}>
							<HSCard>
								<H1 center style={{ marginBottom: 0 }}>
									{t('PleaseChooseMailTemplate')}
								</H1>
							</HSCard>
							<ChildButton
								testID={`${TESTIDPREFIX}_button_continuewithouttemplate`}
								onPress={() => {
									setHasNoTemplate(true);
									_updateFormValues({
										...sendMailFormValues,
										title: '',
										subject: '',
										body: ''
									});
									setCurentStep(currentStep + 1);
								}}
							>
								<HSCard>
									<Text center>{t('ContinueWithoutTemplate')}</Text>
								</HSCard>
							</ChildButton>
						</View>
					}
					ListEmptyComponent={<NoData type="NoMailTemplates" />}
					keyExtractor={(item, index) => `${TESTIDPREFIX}_template_${index}`}
					renderItem={({ item }) => (
						<MailTemplateListItem
							testID={TESTIDPREFIX}
							key={`${TESTIDPREFIX}_template_${item}`}
							item={item}
							onSelect={() => {
								setHasNoTemplate(false);
								_updateFormValues({
									...sendMailFormValues,
									mailTemplateId: item.id,
									title: item.title,
									subject: item.subject,
									body: item.body
								});
								setCurentStep(currentStep + 1);
							}}
						/>
					)}
				/>
			</ScrollView>
		);
	};

	const _getMailValues = () => {
		sendMailFormValues.contentType = _getType();
		let _values = sendMailFormValues;

		if (route.params.entryPoint === 'receivers' && route.params.contentType === 'invitation' && !hasValues) {
			let _template = spaceTemplates.find((s) => s.key === 'defaultHelloSpacesInvitation');

			if (_template) {
				setSendMailFormValues({
					...sendMailFormValues,
					subject: _template?.subject ?? '',
					body: _template?.body ?? '',
					contentType: 'invitation'
				});
				setHasValues(true);
			}
		}

		while (_values.subject?.includes('%ROLE%')) {
			_values.subject = _values.subject.replace('%ROLE%', activeSpace?.language === 'de' ? 'Administrator' : 'administrator');
		}

		while (_values.body?.includes('%ROLE%')) {
			_values.body = _values.body.replace('%ROLE%', activeSpace?.language === 'de' ? 'Administrator' : 'administrator');
		}

		return _values;
	};

	const _getComponents = () => {
		const components: ReactNode[] = [];

		switch (route.params.entryPoint) {
			case 'receivers':
				components.push(
					<View
						key="select_attendees_step"
						style={{
							flex: 1,
							width: screenWidth,
							alignSelf: 'center',
							paddingHorizontal: EHorizontalScreenPadding.Wide,
							paddingTop: hsBottomMargin
						}}
					>
						{_renderReceiverList()}
					</View>
				);
				break;
			case 'template':
			default:
				if (spaceTemplates.length > 0) {
					components.push(_renderMailTemplateList());
				}
				if (!route.params.selectedEntries) {
					components.push(
						<View
							key="select_attendees_step"
							style={{
								flex: 1,
								width: screenWidth,
								alignSelf: 'center',
								paddingHorizontal: EHorizontalScreenPadding.Wide,
								paddingTop: hsBottomMargin
							}}
						>
							{_renderReceiverList()}
						</View>
					);
				}
				break;
		}

		components.push(
			<ScrollView
				key="mailtemplateform"
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsBottomMargin,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MailTemplateForm
					values={_getMailValues()}
					errors={sendMailFormErrors}
					onChange={(values) => _updateFormValues({ ...sendMailFormValues, ...values })}
					isLoading={isSendMailLoading}
					hideFileSelect
					hideTitle
					contentType={_getType()}
				/>
				<HSCard>
					<FlatList
						data={_getType() === 'noTemplate' || _getType() === 'invitation' ? mailReceivers : receivers}
						ListHeaderComponent={<SectionHeader label={t('Receivers')} />}
						keyExtractor={(item, idx) => `${TESTIDPREFIX}_attendee_${idx}`}
						ListFooterComponent={
							<FormHint
								testID="hint"
								hint={_getType() === 'noTemplate' || _getType() === 'invitation' ? '' : t('MissingEmailHint')}
							/>
						}
						renderItem={({ item, index }) => {
							return (
								<View style={{ marginBottom: 5 }}>
									{_getType() === 'attendee' ? (
										<AttendeeListItem isAdminArea testID={`${TESTIDPREFIX}_button_attendee_${index}`} item={item} />
									) : _getType() === 'admin' || _getType() === 'moderator' ? (
										<SpaceAdministratorListEntry item={item} />
									) : _getType() === 'ticket' ? (
										<TicketMailReceiverListItem item={item} />
									) : _getType() === 'expo' ? (
										<ExpoMailReceiverListItem item={item} />
									) : _getType() === 'speaker' ? (
										<SpeakerMailReceiverListItem item={item} />
									) : (
										<HSCard style={{ marginBottom: 5 }}>
											<Text bold>{item}</Text>
										</HSCard>
									)}
								</View>
							);
						}}
					/>
				</HSCard>
			</ScrollView>
		);

		components.push(
			<ScrollView
				key="mailpreview"
				contentContainerStyle={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsBottomMargin,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MailPreview
					subject={sendMailFormValues.subject}
					body={_getMailPreviewBody()}
					attachments={content.mailtemplates
						.find((e) => e.id === sendMailFormValues.mailTemplateId)
						?.attachments?.map((e) => e.media)}
				/>
			</ScrollView>
		);

		return components;
	};

	const _getNextBtnText = () => {
		let btnText = t('Next');
		switch (currentStep) {
			case 3:
				btnText = t('Send');
				break;
			case 0:
			case 1:
			case 2:
			default:
				break;
		}
		return btnText;
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="sendmail">
			<View
				style={{
					flex: 1,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				<StepWizard
					testIdPrefix={TESTIDPREFIX}
					getCurrentIndex={(idx) => {
						if (_getComponents().length === 2 && idx === 0) {
							setSendMailFormValues({
								...sendMailFormValues,
								mailTemplateId: undefined,
								subject: '',
								body: '',
								useDefaultHeader: true,
								useDefaultFooter: true
							});
						}
						setCurentStep(idx);
					}}
					isLoading={isSendMailLoading}
					completeFunction={() => _onSubmit()}
					isNextButtonDisabled={_isNextButtonDisabled()}
					components={_getComponents()}
					customCurrentIndex={currentStep}
					nextButtonText={_getNextBtnText()}
				/>
			</View>
		</ScreenContainer>
	);
};

export const SendMailScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Send Mail')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
