import React from 'react';
import { isEmptyString, normalizeFontSize } from 'helper';
import { Text } from './Text';
import { hsTextBottomMarginSmall } from 'config/styleConstants';

interface IItemTitle {
	text: string;
	hasNoMargin?: boolean;
	doTruncate?: boolean;
	numberOfLines?: number;
}

export const ItemTitle = (props: IItemTitle) => {
	const { text, hasNoMargin, doTruncate, numberOfLines } = props;

	if (!isEmptyString(text)) {
		return (
			<Text
				{...props}
				bold
				numberOfLines={numberOfLines ?? doTruncate ? 3 : 99}
				doTruncate={doTruncate}
				adjustsFontSizeToFit
				style={[{ fontSize: normalizeFontSize(14), marginBottom: hasNoMargin ? 0 : hsTextBottomMarginSmall }]}
			>
				{text}
			</Text>
		);
	}

	return null;
};
