export const hsShadow = {
	shadowOffset: {
		width: 5,
		height: 5
	},
	shadowOpacity: 0.2,
	shadowRadius: 8,
	elevation: 10
};

export const hsBorderRadius = 5;
export const hsBorderRadiusSmall = 5;
export const hsBorder = { borderRadius: hsBorderRadius };
export const hsBorderSmall = { borderRadius: hsBorderRadiusSmall };
export const hsCard = { ...hsShadow, ...hsBorder };
export const hsInnerPadding = 20;
export const hsBottomMargin = 20;
export const hsTextBottomMargin = 20;
export const hsTextBottomMarginSmall = 10;
export const hsBlue = '#4895EF';
export const hsNavBarBottomMargin = 20;
export const hsTopScreenPadding = 20;
export const hsImageResizeWidth = 2000;
export const hsImageResizeHeight = 2000;
export const hsActionButtonMargin = 5;
export const hsToastOffsetMobile = 40;
export const hsToastOffsetWeb = 20;
export const hsQRCodeBorderWidth = 10;

export const LISTHEADER_ANIMATIONTIME_IN = 250;
export const LISTHEADER_ANIMATIONTIME_OUT = 400;
export const LISTHEADER_ANIMATION_HEIGHT_FACTOR = 2;
export const LISTHEADER_ANIMATION_HEADER_HEIGHT_FACTOR = 1.25;
export const LISTHEADER_ANIMATION_GUESSED_HEIGHT = 120;
export const LISTHEADER_ANIMATION_OPACITY_LOW = 0;
export const LISTHEADER_ANIMATION_OPACITY_HIGH = 1;
export const LISTHEADER_ANIMATION_SCROLLBACK_CORRECTION = 125;
