import React, { ReactNode, useState, useEffect } from 'react';
import { IAttendee, IMeeting, IMeetingTable, ISchedule, IScheduleStatus, ISpeaker, IUserBooking } from 'config/interfaces';
import { hsBorderRadius, hsBottomMargin, hsTextBottomMargin } from 'config/styleConstants';
import { ChildButton } from 'components/Button';
import { HSCard } from './HSCard';
import { Dimensions, View } from 'react-native';
import { EDefaultIconSet, getDefaultAspectRatioStyle, getFixedHeightForText, isEmptyString } from 'helper';
import { Image, PlaceholderGradient } from 'components/Image';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { H2, Text } from 'components/Text';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import { Icon } from 'components/Icon';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { Avatar } from 'components/User';
import { InfoPill } from 'components/InfoPill';
import { useTranslation } from 'react-i18next';
import { AnimatedLive } from 'components/Animated';

interface IAgendaCardProps {
	testID: string;
	index: number;
	isLoading?: boolean;
	onPress?: () => void;
	mostSpeakerAmount?: number;
}

interface IMeetingProps extends IAgendaCardProps {
	item: IMeeting;
	contentType: 'meeting';
}

interface IScheduleProps extends IAgendaCardProps {
	item: ISchedule;
	contentType: 'schedule';
}

type IProps = IMeetingProps | IScheduleProps;

enum EStyleConstants {
	FontSize_Title = 16,
	FontSize_JobTitleCompany = 16,
	FontSize_Info = 12,
	NumberOfLines_JobtitleCompany = 1,
	NumberOfLines_Info = 2,
	TopMargin_Title = hsTextBottomMargin,
	Height_AdditionalInfoSchedule = 20
}

export const AgendaCard = (props: IProps) => {
	const { item, testID, index, isLoading, onPress, mostSpeakerAmount, contentType } = props;
	const { activeSpace } = useSpace();
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { t } = useTranslation();
	const screenWidth = Dimensions.get('window').width;

	const content = useSelector((store: IRootState) => store.content.content);
	const allBookings = useSelector((store: IRootState) => store.booking.allBookings);
	const bookings = useSelector((store: IRootState) => store.booking.bookings);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [scheduleStatus, setScheduleStatus] = useState<IScheduleStatus | undefined>(undefined);
	const [isBooked, setIsBooked] = useState<boolean>(false);
	const [itemBookings, setItemBookings] = useState<IUserBooking[]>([]);

	const [partner, setPartner] = useState<IAttendee | undefined>(undefined);
	const [meetingTable, setMeetingTable] = useState<IMeetingTable | undefined>(undefined);

	useEffect(() => {
		if (contentType === 'meeting') {
			let _partner: typeof partner = undefined;

			if (attendees) {
				const partnerId = item?.ownerId === profile?.userId ? item?.partnerId : item?.ownerId;
				_partner = attendees.find((e) => e.userId === partnerId);
			}

			setPartner(_partner);
		}
	}, [attendees, item]);

	useEffect(() => {
		if (contentType === 'meeting') {
			let _table: typeof meetingTable = undefined;

			if (content.meetingtables && item?.meetingtable) {
				_table = content.meetingtables.find((e) => e.id === item?.meetingtable?.id);
			}

			setMeetingTable(_table);
		}
	}, [content, item]);

	useEffect(() => {
		if (contentType === 'schedule') {
			let _isBooked = false;

			if (activeSpace && bookings) {
				_isBooked =
					bookings.find((e) => e.spaceId === activeSpace.spaceId && e.itemId === item?.id && item?.isBookable) !== undefined;
			}

			setIsBooked(_isBooked);
		}
	}, [activeSpace, bookings, item]);

	useEffect(() => {
		if (contentType === 'schedule') {
			let _itemBookings: typeof itemBookings = [];

			if (activeSpace && allBookings) {
				_itemBookings = allBookings.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === item?.id);
			}

			setItemBookings(_itemBookings);
		}
	}, [allBookings, item, activeSpace]);

	useEffect(() => {
		if (contentType === 'schedule') {
			let _scheduleStatus: typeof scheduleStatus = undefined;
			if (content.schedulestatuses) {
				_scheduleStatus = content.schedulestatuses.find((e) => e.schedule?.id === item?.id);
			}

			setScheduleStatus(_scheduleStatus);
		}
	}, [item, content]);

	const _renderImage = () => {
		switch (contentType) {
			case 'meeting':
				if (partner?.imageUrl) {
					return (
						<Avatar
							avatar={partner?.imageUrl}
							fullName={`${partner?.firstName} ${partner?.lastName}`}
							size="md"
							userId={partner.userId}
						/>
					);
				}
				break;
			case 'schedule':
				if (item?.previewImage) {
					return (
						<Image
							style={{
								...getDefaultAspectRatioStyle(containerWidth / 2),
								borderRadius: hsBorderRadius
							}}
							mediaObj={item?.previewImage}
							imageSize="small"
							expectedRatio={16 / 9}
							resizeMode={'contain'}
						/>
					);
				}

				break;
			default:
				break;
		}

		if (item && activeSpace?.showGradientOnMissingImage) {
			return (
				<View style={{ alignItems: 'center' }}>
					<PlaceholderGradient
						smallText={!isTabletOrMobile}
						tinyText={isTabletOrMobile}
						itemId={item?.id}
						title={contentType === 'schedule' ? item?.title : `${partner?.firstName} ${partner?.lastName}`}
						width={containerWidth / 2}
						rounded={'full'}
					/>
				</View>
			);
		}
		return null;
	};

	const _renderTitle = () => {
		let text = '';
		let color = theme.text;

		switch (contentType) {
			case 'schedule':
				text = item?.title;
				color = item?.textColor ?? color;
				break;
			case 'meeting':
				text = `${partner?.firstName} ${partner?.lastName}`;
				break;
			default:
				break;
		}

		return (
			<H2
				center
				numberOfLines={2}
				style={{
					height: getFixedHeightForText(EStyleConstants.FontSize_Title, 2),
					lineHeight: getFixedHeightForText(EStyleConstants.FontSize_Title, 2) / 2,
					fontSize: EStyleConstants.FontSize_Title,
					marginTop: EStyleConstants.TopMargin_Title,
					color: color
				}}
			>
				{text}
			</H2>
		);
	};

	const _renderSubtitle = () => {
		let text = '';
		let color = theme.text;
		switch (contentType) {
			case 'schedule':
				text = item?.title;
				color = item?.textColor ?? color;
				break;
			case 'meeting':
				text = `${partner?.firstName} ${partner?.lastName}`;
				break;
			default:
				break;
		}

		return (
			<Text
				style={{
					fontSize: EStyleConstants.FontSize_Info,
					height: getFixedHeightForText(EStyleConstants.FontSize_Info, EStyleConstants.NumberOfLines_Info),
					lineHeight:
						getFixedHeightForText(EStyleConstants.FontSize_Info, EStyleConstants.NumberOfLines_Info) /
						EStyleConstants.NumberOfLines_Info,
					color: color
				}}
				numberOfLines={EStyleConstants.NumberOfLines_Info}
				center
			>
				{text}
			</Text>
		);
	};

	const _renderStageAndTime = () => {
		switch (contentType) {
			case 'schedule':
				const foundStage = content.stages.find((s) => s.id === item?.stage?.id && !s.isDeleted);
				return (
					<Text
						style={{
							fontSize: EStyleConstants.FontSize_JobTitleCompany,
							height: getFixedHeightForText(
								EStyleConstants.FontSize_JobTitleCompany,
								EStyleConstants.NumberOfLines_JobtitleCompany
							),
							lineHeight:
								getFixedHeightForText(
									EStyleConstants.FontSize_JobTitleCompany,
									EStyleConstants.NumberOfLines_JobtitleCompany
								) / EStyleConstants.NumberOfLines_JobtitleCompany,
							marginVertical: EStyleConstants.TopMargin_Title,
							color: item?.textColor ?? theme.text
						}}
						numberOfLines={EStyleConstants.NumberOfLines_JobtitleCompany}
						center
						adjustsFontSizeToFit={false}
						bold
					>
						{`${moment(item?.startDate).format('HH:mm')} - ${moment(item?.endDate).format('HH:mm')}`}
						{foundStage?.title ? ' | ' : ''}
						{foundStage?.title ?? ''}
					</Text>
				);
			case 'meeting':
				return (
					<Text
						style={{
							fontSize: EStyleConstants.FontSize_JobTitleCompany,
							height: getFixedHeightForText(
								EStyleConstants.FontSize_JobTitleCompany,
								EStyleConstants.NumberOfLines_JobtitleCompany
							),
							lineHeight:
								getFixedHeightForText(
									EStyleConstants.FontSize_JobTitleCompany,
									EStyleConstants.NumberOfLines_JobtitleCompany
								) / EStyleConstants.NumberOfLines_JobtitleCompany,
							marginVertical: EStyleConstants.TopMargin_Title,
							color: theme.text
						}}
						numberOfLines={EStyleConstants.NumberOfLines_JobtitleCompany}
						center
						adjustsFontSizeToFit={false}
						bold
					>
						{`${moment(item?.timeSlot.start).format('HH:mm')} - ${moment(item?.timeSlot.end).format('HH:mm')}`}
						{meetingTable?.title ? ' | ' : ''}
						{meetingTable?.title ?? ''}
					</Text>
				);
			default:
				break;
		}
	};

	const renderSpeakers = () => {
		const elements: ReactNode[] = [];
		if (contentType === 'schedule') {
			if (item?.speakers && item?.speakers.length > 0) {
				let speakers = item?.speakers;
				// const elements: JSX.Element[] = [];
				const unhandledSpeakers: ISpeaker[] = [];

				if ((item?.speakerGroups?.groups ?? []).length > 0) {
					item?.speakerGroups?.groups.forEach((group) => {
						const _groupSpeakers = speakers.filter((e) => group.speakerIds.includes(e.id));

						const groupSpeakerElements = _groupSpeakers.map((e) => {
							const speaker = content.speakers.find((s) => s.id === e.id);
							if (!speaker) {
								return null;
							}
							return (
								<View
									style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}
								>
									<Avatar
										size="gridCard"
										avatar={speaker.image?.formats?.thumbnail?.url}
										avatarObj={speaker.image}
										fullName={speaker.title}
										userId={speaker?.attendee?.userId}
									/>
									<Text style={{ marginLeft: 3 }}>{speaker.title}</Text>
								</View>
							);
						});
						const textStyle = { fontSize: 10, color: !isEmptyString(item?.textColor) ? item?.textColor : theme.text };

						elements.push(
							<View>
								<Text key={`speakerstring_${group.key}`} bold style={textStyle}>
									{`${group.title}: `}
								</Text>
								{groupSpeakerElements}
							</View>
						);
					});
				}

				speakers.forEach((speaker) => {
					const foundInGroup = item?.speakerGroups?.groups.find((e) => e.speakerIds.includes(speaker.id));
					if (!foundInGroup) {
						unhandledSpeakers.push(speaker);
					}
				});

				if (unhandledSpeakers.length > 0) {
					const unhandledSpeakerElements = unhandledSpeakers.map((e, index) => {
						const speaker = content.speakers.find((s) => s.id === e.id);
						if (!speaker) {
							return null;
						}
						return (
							<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
								{index > 0 && <Text> | </Text>}
								<Avatar
									size="gridCard"
									avatar={speaker.image?.formats?.thumbnail?.url}
									avatarObj={speaker.image}
									fullName={speaker.title}
									userId={speaker?.attendee?.userId}
								/>
								<Text style={{ marginLeft: 3 }}>{speaker.title}</Text>
							</View>
						);
					});
					elements.push(<View style={{ flexDirection: 'row', marginHorizontal: 'auto' }}>{unhandledSpeakerElements}</View>);
				}
			}
		}

		return <View style={{ overflow: 'hidden', height: EStyleConstants.Height_AdditionalInfoSchedule }}>{elements}</View>;
	};

	const _renderStatus = () => {
		if (contentType === 'schedule' && scheduleStatus) {
			if (scheduleStatus.status === 'Finished' || (scheduleStatus.status !== 'Replay' && moment().isAfter(item?.endDate))) {
				return null;
			}

			return (
				<InfoPill
					text={t(scheduleStatus.status)}
					borderColor={!isEmptyString(item?.textColor) ? item?.textColor : theme.text}
					textColor={!isEmptyString(item?.textColor) ? item?.textColor : theme.text}
					containerStyle={{ marginBottom: -5 }}
					size={screenWidth <= 1600 ? 'xs' : 'default'}
				/>
			);
		}

		return null;
	};

	const _renderIsHiddenAndLive = () => {
		if (contentType === 'schedule') {
			return (
				<View
					style={{
						position: 'absolute',
						left: -10,
						top: -10,
						zIndex: 5,
						flexDirection: 'column'
					}}
				>
					<View style={{ flexDirection: 'row', marginBottom: 10, alignItems: 'center', justifyContent: 'flex-start' }}>
						{scheduleStatus?.status === 'Live' ? (
							<AnimatedLive isVisible={scheduleStatus?.status === 'Live'} containerStyle={{ position: 'relative' }} />
						) : (
							_renderStatus()
						)}
						{item?.isHidden && (
							<Icon containerStyle={{ marginLeft: 3 }} name={EDefaultIconSet.AnswerWaiting} color={theme.warning} />
						)}
					</View>
					<View>{_renderBooking()}</View>
				</View>
			);
		}
		return null;
	};

	const _renderFavoriteButton = () => {
		return (
			<View
				style={{
					position: 'absolute',
					right: -10,
					top: -10,
					zIndex: 5,
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end'
				}}
			>
				<FavoriteButton testID={`${testID}_button_schedule_${index}_favorite`} id={item?.id} type={contentType} />
			</View>
		);
	};

	const _renderBooking = () => {
		if (contentType === 'schedule') {
			if (isBooked) {
				return (
					<InfoPill
						text={t('Booked')}
						backgroundColor={theme.success}
						textColor={theme.white}
						size={screenWidth <= 1600 ? 'xs' : 'default'}
					/>
				);
			}

			if (item?.isBookable) {
				if (item?.bookingCapacity !== undefined && item?.bookingCapacity !== null && itemBookings.length >= item?.bookingCapacity) {
					return (
						<InfoPill
							text={t('Fully booked')}
							borderColor={theme.danger}
							textColor={!isEmptyString(item?.textColor) ? item?.textColor : theme.danger}
							size={screenWidth <= 1600 ? 'xs' : 'default'}
						/>
					);
				}

				return (
					<InfoPill
						text={t('Bookable')}
						borderColor={!isEmptyString(item?.textColor) ? item?.textColor : theme.text}
						textColor={!isEmptyString(item?.textColor) ? item?.textColor : theme.text}
						size={screenWidth <= 1600 ? 'xs' : 'default'}
					/>
				);
			}
		}

		return null;
	};

	const _isFinished = () => {
		let isFinished = false;

		switch (contentType) {
			case 'schedule':
				isFinished =
					moment().isSameOrAfter(item.endDate) && scheduleStatus?.status !== 'Live' && scheduleStatus?.status !== 'Replay';
				break;
			case 'meeting':
				isFinished = moment().isSameOrAfter(item.timeSlot.end);
				break;
			default:
				break;
		}

		return isFinished;
	};

	return (
		<ChildButton testID={`${testID}_button_schedule_${index}`} style={{ flex: 1, alignItems: 'center' }} onPress={onPress}>
			<HSCard
				style={{
					flex: 1,
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: hsBottomMargin / 2,
					backgroundColor:
						contentType === 'schedule'
							? item?.backgroundColor ?? theme.contentBackgroundColor ?? theme.background
							: theme.contentBackgroundColor ?? theme.background,
					opacity: _isFinished() ? 0.6 : 1
				}}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
			>
				<View style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
					{_renderIsHiddenAndLive()}
					{_renderFavoriteButton()}
					{_renderImage()}
					<View style={{ width: '100%' }}>
						{_renderTitle()}
						{_renderStageAndTime()}
						{renderSpeakers()}
					</View>
				</View>
			</HSCard>
		</ChildButton>
	);
};
