import React, { useEffect, useState } from 'react';
import { IVote, TVotingType } from 'config/interfaces';
import { FlatList, ScrollView, View } from 'react-native';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { HSCard } from 'components/Card';
import { H3, Text } from 'components/Text';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { VerticalBarChart } from 'components/Charts/BarChart/VerticalBarChart';
import { NumberPresenter } from 'components/Info';
import { useTranslation } from 'react-i18next';
import { Rating } from '../VoteRating/Rating';
import { EDefaultIconSet } from 'helper';
import { VoteScaleSlider } from '../VoteScale/VoteScaleSlider';
import { IWordListItem, WordCloud } from '../VoteWordCloud/WordCloud';
import { WORDCLOUDSPLITSEQUENCE } from '../VoteWordCloud';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useTheme } from 'hooks/useTheme';
import { NpsScale } from '../VoteNps';

interface IVoteSurvey {
	vote: IVote;
	largerDesign?: boolean;
	fullwidth?: boolean;
	resultsOnly?: boolean;
}

interface ISurveyResult {
	answers: IQuestionResult[];
	voteText: string;
	type: TVotingType;
	maxValue?: number;
	ratingType?: string;
}

interface IQuestionResult {
	answer: string;
	count: number;
	type: TVotingType;
	voteId: number;
}

export const SurveyReport = (props: IVoteSurvey) => {
	const { vote, largerDesign, fullwidth, resultsOnly } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();

	const [aggregatedSurveyResults, setAggregatedSurveyResults] = useState<ISurveyResult[]>([]);
	const [totalAnswers, setTotalAnswers] = useState<number>(0);

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const votes = useSelector((store: IRootState) => store.vote.votes);

	useEffect(() => {
		const answersForThis = voteAnswers?.filter((e) => !e.isDeleted && e.voteId === vote.id);
		setTotalAnswers(answersForThis?.length ?? 0);
		const results: ISurveyResult[] = [];
		if (answersForThis?.length > 0) {
			const questionResult: IQuestionResult[] = [];
			answersForThis?.map((answer) => {
				if (answer.value) {
					let answerObj = undefined;
					try {
						answerObj = JSON.parse(answer.value);
					} catch (error) {
						console.log('error on json parse', answer.value);
					}
					if (answerObj?.answers) {
						answerObj?.answers?.map((a) => {
							if (a.type === 'multipleChoice') {
								const foundIndex = questionResult.findIndex((r) => r.answer === a.answerText && r.type === a.type);

								if (foundIndex > -1) {
									questionResult[foundIndex] = {
										answer: questionResult[foundIndex].answer,
										count: questionResult[foundIndex].count + 1,
										type: questionResult[foundIndex].type,
										voteId: questionResult[foundIndex].voteId
									};
								} else {
									questionResult.push({
										answer: a.answerText,
										count: 1,
										type: a.type,
										voteId: a.voteId
									});
								}
							} else {
								questionResult.push({
									answer: a.answerText,
									count: 1,
									type: a.type,
									voteId: a.voteId
								});
							}
						});
					}
				}
			});
			vote?.options?.map((option) => {
				const filtered = questionResult.filter((qr) => qr.voteId?.toString() === option.label);
				const foundVote = votes.find((vote) => vote.id.toString() === option.label);
				results.push({
					voteText: option.description ?? '',
					answers: filtered,
					type: foundVote?.viewType,
					maxValue: foundVote?.maxValue,
					ratingType: foundVote?.ratingType
				});
			});
			setAggregatedSurveyResults(results);
		}
	}, [vote, voteAnswers, votes]);

	const _renderResultByType = (answer: ISurveyResult) => {
		switch (answer.type) {
			case 'multipleChoice':
				const mcCounts = answer.answers.map((d) => d.count);
				const mcMax = Math.max(...mcCounts);
				let mcSum = 0;
				for (const count of mcCounts) {
					mcSum += count;
				}
				return (
					<VerticalBarChart
						hideSum
						data={answer.answers.map((a) => {
							return { text: a.answer, count: a.count };
						})}
						totalVotesSubmitted={mcSum}
						highestCount={mcMax}
					/>
				);
			case 'text':
				return (
					<View>
						{answer.answers.map((a, index) => {
							return (
								<View key={`answer_${index}`} style={{ flexDirection: 'column' }}>
									<Text>{a.answer}</Text>
									<View
										style={{
											width: '100%',
											height: 1,
											alignSelf: 'center',
											borderTopWidth: 0,
											backgroundColor: theme.gray,
											marginVertical: 15
										}}
									/>
								</View>
							);
						})}
					</View>
				);
			case 'rating':
				const rCounts = answer.answers.map((d) => (!isNaN(Number(d.answer)) ? Number(d.answer) : 0));
				let rSum = 0;
				for (const count of rCounts) {
					rSum += count;
				}
				rSum = Math.round((rSum / rCounts.length + Number.EPSILON) * 10) / 10;

				let empty = EDefaultIconSet.StarOutline;
				let filled = EDefaultIconSet.Star;
				if (answer.ratingType === 'thumbs') {
					empty = EDefaultIconSet.ThumbUpOutline;
					filled = EDefaultIconSet.ThumbUp;
				}
				return <Rating isDisabled value={rSum} maxValue={answer.maxValue ?? 5} emptyIcon={empty} filledIcon={filled} />;
			case 'scale':
				const sCounts = answer.answers.map((d) => (!isNaN(Number(d.answer)) ? Number(d.answer) : 0));
				let sSum = 0;
				for (const count of sCounts) {
					sSum += count;
				}
				sSum = Math.round((sSum / sCounts.length + Number.EPSILON) * 10) / 10;
				return (
					<VoteScaleSlider
						isDisabled
						testID="activevote_slider_value"
						minValue={0}
						maxValue={answer.maxValue ?? 5}
						value={sSum}
						submitted
					/>
				);
			case 'nps':
				const nCounts = answer.answers.map((d) => (!isNaN(Number(d.answer)) ? Number(d.answer) : 0));
				let nSum = 0;
				for (const count of nCounts) {
					nSum += count;
				}
				nSum = Math.round((nSum / nCounts.length + Number.EPSILON) * 10) / 10;
				return <NpsScale isDisabled minValue={0} maxValue={answer.maxValue ?? 10} value={nSum} />;
			case 'wordCloud':
				let words: string[] = [];
				let _wordList: IWordListItem[] = [];
				answer.answers.map((a) => (words = words.concat(a.answer.split(WORDCLOUDSPLITSEQUENCE))));
				words?.forEach((word) => {
					const lower = word.toLowerCase();
					const found = _wordList.find((e) => e.text.toLowerCase() === lower);
					if (found) {
						found.count = found.count + 1;
					} else {
						_wordList.push({
							text: word,
							count: 1
						});
					}
				});
				return <WordCloud wordList={_wordList} minFontSize={largerDesign ? 20 : 12} maxFontSize={largerDesign ? 48 : 18} />;
			default:
				return null;
		}
	};

	return (
		<ScrollView
			contentContainerStyle={{
				paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
				paddingHorizontal: EHorizontalScreenPadding.Wide,
				width: fullwidth ? '100%' : screenWidth,
				alignSelf: 'center'
			}}
		>
			<ActiveVoteHeader activeVote={vote} />
			<NumberPresenter number={totalAnswers} title={t('Attendees')} />
			<FlatList
				scrollEnabled={false}
				data={aggregatedSurveyResults}
				renderItem={({ item }) => {
					return (
						<HSCard>
							<H3>{item.voteText}</H3>
							{_renderResultByType(item)}
						</HSCard>
					);
				}}
				keyExtractor={(item, idx) => `aggregatedSurveyResults_${idx}`}
			/>
		</ScrollView>
	);
};
