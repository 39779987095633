import { Button } from 'components/Button';
import { Spinner } from 'components/Spinner';
import { StreamPlayer } from 'components/Stream';
import { Subtitle } from 'components/Text';
import { IContentTypeField, IExpo, IMedia, IMediaItem, ISpeaker } from 'config/interfaces';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { getDefaultAspectRatioStyle, isEmptyString, IS_WEB } from 'helper';
import { useContent } from 'hooks/useContent';
import { useDetail } from 'hooks/useDetail';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Image, PlaceholderGradient } from 'components/Image';
import { downloadURL } from 'helper/download';
import { HSCard } from 'components/Card';

interface IMediaItemDetail {
	itemId?: number;
	setTitle?: (title?: string) => void;
	setHasStream?: (hasStream: boolean) => void;
	getStreamType?: (streamType: any) => void;
	item?: IMediaItem;
	previewImage?: IMedia;
	hasPadding?: boolean;
	playerOnly?: boolean;
	detailsOnly?: boolean;
	isAutoplay?: boolean;
	noMargin?: boolean;
	isModal?: boolean;
	maxHeight?: number;
}

const TESTIDPREFIX = 'mediadetail';

export const MediaItemDetail = (props: IMediaItemDetail) => {
	const {
		itemId,
		setTitle,
		setHasStream,
		getStreamType,
		item,
		previewImage,
		hasPadding,
		playerOnly,
		detailsOnly,
		isAutoplay,
		noMargin,
		isModal,
		maxHeight
	} = props;
	const { getContentTypeFields } = useContent('mediaitem');
	const { renderField, renderReferenceField } = useDetail();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { trackAction } = useTracker();

	const [currentItem, setCurrentItem] = useState<IMediaItem | undefined>(undefined);
	const [mediaItemDetailFields, setMediaItemDetailFields] = useState<IContentTypeField[]>([]);
	const [referencedExpos, setReferencedExpos] = useState<IExpo[]>([]);
	const [referencedSpeakers, setReferencedSpeakers] = useState<ISpeaker[]>([]);
	const [containerWidth, setContainerWidth] = useState<number>(1);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const content = useSelector((store: IRootState) => store.content.content);

	const setMediaDetailPlaybackStatus = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailPlaybackStatus);

	useEffect(() => {
		trackAction('mediaitem', 'Open Detail', `${itemId}`);
	}, []);

	useEffect(() => {
		let _mediaItem: typeof currentItem = undefined;
		const _referencedExpos: typeof referencedExpos = [];
		const _referencedSpeakers: typeof referencedSpeakers = [];

		if (currentItem && item) {
			_mediaItem = item;
		} else if (content.mediaitems) {
			_mediaItem = content.mediaitems.find((e) => e.id === itemId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator));
		}

		if (_mediaItem) {
			_mediaItem.expos?.forEach((sc) => {
				const found = content.expos?.find((e2) => e2.id === sc.id && (!sc.isHidden || iAmSpaceAdmin || iAmSpaceModerator));
				if (found) {
					_referencedExpos.push(found);
				}
			});
			_mediaItem.speakers?.forEach((sc) => {
				const found = content.speakers?.find((e2) => e2.id === sc.id && (!sc.isHidden || iAmSpaceAdmin || iAmSpaceModerator));
				if (found) {
					_referencedSpeakers.push(found);
				}
			});
		}

		if (setTitle) {
			setTitle(_mediaItem?.title);
		}

		if (setHasStream) {
			setHasStream(
				((!isEmptyString(_mediaItem?.media?.url) || !isEmptyString(_mediaItem?.iFrameUrl)) ?? false) &&
					_mediaItem?.mediaType !== 'externalMeeting'
			);
		}

		if (getStreamType) {
			getStreamType(_mediaItem?.mediaType);
		}

		setCurrentItem(_mediaItem);
		setReferencedExpos(_referencedExpos);
		setReferencedSpeakers(_referencedSpeakers);
	}, [content, item, iAmSpaceAdmin, iAmSpaceModerator]);

	useEffect(() => {
		const _fields = getContentTypeFields(true).filter(
			(e) => e.showOnDetailScreen && e.fieldName !== 'title' && e.fieldName !== 'subtitle' && e.fieldName !== 'iFrameUrl'
		);
		setMediaItemDetailFields(_fields);
	}, [activeSpace]);

	const _renderDownload = () => {
		if (currentItem?.mediaType === 'upload' && currentItem?.media?.url && profile && activeSpace) {
			return (
				<Button
					style={{ alignSelf: 'center', marginVertical: hsBottomMargin }}
					testID={`${TESTIDPREFIX}_button_download`}
					title="Download"
					onPress={() => downloadURL(currentItem.media!.url, currentItem.media?.caption ?? currentItem.media?.name)}
				/>
			);
		}

		return null;
	};

	const _getURL = () => {
		switch (currentItem?.mediaType) {
			case 'contentflow':
				if (!isEmptyString(currentItem?.contentflowUrl)) {
					return currentItem?.contentflowUrl;
				}
			case 'iFrame':
				if (!isEmptyString(currentItem?.iFrameUrl)) {
					return currentItem?.iFrameUrl;
				}
			case 'mediaLibrary':
			case 'upload':
				if (!isEmptyString(currentItem?.media?.url) && currentItem.media?.mime?.includes('video')) {
					return currentItem?.media?.url;
				}
			case 'vimeo':
				if (!isEmptyString(currentItem?.vimeoUrl)) {
					return currentItem?.vimeoUrl;
				}
			case 'youtube':
				if (!isEmptyString(currentItem?.youTubeUrl)) {
					return currentItem?.youTubeUrl;
				}
			case 'externalMeeting':
				if (!isEmptyString(currentItem?.externalMeetingUrl)) {
					return currentItem?.externalMeetingUrl;
				}
			default:
				return undefined;
		}
	};

	const _renderPlayer = () => {
		if (detailsOnly) {
			return null;
		}
		const url = _getURL();

		if (currentItem && url) {
			return (
				<StreamPlayer
					testID={TESTIDPREFIX}
					buttonText={currentItem.externalMeetingButtonText}
					url={url}
					type={currentItem?.mediaType}
					previewImage={previewImage ? previewImage : currentItem?.previewImage}
					containerStyle={{
						marginBottom: (playerOnly && currentItem?.mediaType !== 'externalMeeting') || noMargin ? 0 : 20,
						width:
							mediaDetail.viewType === 'full'
								? maxHeight
									? Math.min((maxHeight * 16) / 9, containerWidth)
									: '100%'
								: mediaDetail.viewType === 'collapsed'
								? '100%'
								: undefined,
						alignSelf: 'center'
					}}
					onPlay={() => {
						setMediaDetailPlaybackStatus('playing');
						trackAction('mediaitem', 'Play Video', `${currentItem.id}`);
					}}
					onPause={() => {
						setMediaDetailPlaybackStatus('paused');
						trackAction('mediaitem', 'Stop Video', `${currentItem.id}`);
					}}
					onExternalClick={() => trackAction('mediaitem', 'Open External URL', `${currentItem.id}`)}
					isAutoplay={isAutoplay}
				/>
			);
		}

		if (previewImage || currentItem?.previewImage || currentItem?.media?.mime?.includes('image')) {
			return (
				<Image
					testID={`medialistitem_image`}
					mediaObj={previewImage ?? currentItem.media ?? currentItem?.previewImage}
					imageSize={IS_WEB ? 'full' : 'small'}
					style={{ ...getDefaultAspectRatioStyle(containerWidth), maxHeight: maxHeight, alignSelf: 'center' }}
					resizeMode="contain"
					expectedRatio={16 / 9}
				/>
			);
		}

		if (currentItem && activeSpace?.showGradientOnMissingImage) {
			return (
				<View style={{ maxHeight: maxHeight, alignItems: 'center' }}>
					<PlaceholderGradient
						itemId={currentItem?.id}
						title={currentItem?.title}
						width={maxHeight ? Math.min((maxHeight * 16) / 9, containerWidth) : containerWidth}
						rounded={'top'}
					/>
				</View>
			);
		}

		return null;
	};

	if (currentItem) {
		if (playerOnly) {
			if (mediaDetail.viewType === 'collapsed') {
				return _renderPlayer();
			}
			return (
				<HSCard
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
					style={{
						paddingBottom: hsInnerPadding,
						marginBottom: 0,
						maxHeight: maxHeight ? maxHeight + hsInnerPadding * 2 : undefined
					}}
				>
					{_renderPlayer()}
				</HSCard>
			);
		}

		return (
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				scrollEnabled={mediaDetail.viewType === 'full'}
				style={{
					paddingHorizontal: hasPadding && mediaDetail.viewType === 'full' ? hsInnerPadding : 0,
					marginBottom: noMargin ? 0 : hsBottomMargin
				}}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
			>
				{_renderPlayer()}
				{_renderDownload()}
				{mediaDetail.viewType === 'full' && (
					<View style={{ marginBottom: hsBottomMargin }}>
						<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
							{!isEmptyString(currentItem.subtitle) && (
								<Subtitle style={{ marginBottom: 0 }}>{currentItem.subtitle}</Subtitle>
							)}
						</View>
					</View>
				)}
				{mediaDetail.viewType === 'full' &&
					mediaItemDetailFields.map((field) => {
						if (field.fieldType === 'reference') {
							let items: any[] = [];

							switch (field.referenceType) {
								case 'speakers':
									items = referencedSpeakers;
									break;
								case 'expos':
									items = referencedExpos;
									break;
								default:
									break;
							}
							return renderReferenceField(field, items, TESTIDPREFIX, activeSpace, isModal);
						}

						return renderField(field, currentItem, TESTIDPREFIX, 'mediaitem', activeSpace);
					})}
			</ScrollView>
		);
	}

	return (
		<View style={{ flex: 1, justifyContent: 'center' }}>
			<Spinner size={'large'} />
		</View>
	);
};
