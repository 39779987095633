import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';

import i18next from 'i18next';
import { View } from 'react-native';
import { IStream } from 'config/interfaces';
import { hsTopScreenPadding } from 'config/styleConstants';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet, IS_WEB } from 'helper';
import RBSheet from 'react-native-raw-bottom-sheet';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { StreamList } from 'components/Stream/StreamList';
import { getStreamExample } from 'helper/content';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StreamList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StreamList>;
type RouteParams = StackParamList[ERoutes.StreamList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'streamlist';

export const StreamListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('stream');
	const { theme } = useTheme();
	const { exportAsCSV } = useExport();
	const { activeSpace } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [spaceStreams, setSpaceStreams] = useState<IStream[]>([]);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const createStream = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createStream);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (activeSpace && content.streams && isScreenFocused) {
			let _streams: typeof spaceStreams = [];

			_streams = content.streams.filter((e) => e.spaceId === activeSpace.spaceId);

			setSpaceStreams(_streams);
		}
	}, [activeSpace, content, isScreenFocused]);

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		const singleItem = spaceStreams.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'stream' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.StreamCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'stream' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IStream[] = [];
				if (content.streams) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.streams.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.streams];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'streams');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'addExample':
				setIsLoading(true);
				const streamExamples = getStreamExample();
				for (const streamExample of streamExamples) {
					await createStream({
						stream: streamExample,
						noToast: true
					});
				}
				setIsLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.StreamCreate, {
						spaceId: activeSpace?.spaceId,
						streamId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="streams">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<StreamList
					isLoading={isDeleteLoading}
					testID={TESTIDPREFIX}
					selectedStreams={selectedEntries}
					onPress={(item) => _handleSelect(item.id)}
					onSelectAll={(selected) => setSelectedEntries(selected)}
					onSelect={(itemId) => _handleSelect(itemId)}
					onEdit={(itemId) => navigation.navigate(ERoutes.StreamEdit, { spaceId: activeSpace?.spaceId, id: itemId })}
					onDelete={(itemId) => {
						setSelectedEntries([itemId]);
						_delete(itemId);
					}}
				/>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						onPress={() => _handleActionSheetPress('add')}
					/>

					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isDisabled={isDeleteLoading || isLoading}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_clone`}
						icon={EDefaultIconSet.Clone}
						label={t('clone')}
						isDisabled={isDeleteLoading || selectedEntries.length !== 1}
						isLoading={isLoading}
						onPress={() => _handleActionSheetPress('clone')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const StreamListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Streams')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
