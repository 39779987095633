import { ChildButton } from 'components/Button/ChildButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { IS_WEB } from 'helper/platform';
import { EDefaultIconSet } from 'helper/icon';
import { View, Text, Platform, Dimensions } from 'react-native';
import { Icon } from 'components/Icon';
import { NAVFONTSIZE, NAVICONSIZE } from 'components/Form/constants';

interface INavigationHeaderExportButton {
	options: any;
}

const TESTIDPREFIX = 'header';

export const NavigationHeaderExportButton = (props: INavigationHeaderExportButton) => {
	const { options } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const screenWidth = Dimensions.get('window').width;

	if (iAmSpaceAdmin || iAmSpaceModerator) {
		let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
		let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
		let color = primary === bg ? theme.navigationTextColor : theme.navbarButton;

		return (
			<View>
				<ChildButton
					testID={'header_button_export'}
					onPress={() => {
						if (options?.onSurveyExportPressed) {
							options.onSurveyExportPressed();
						}
					}}
					style={{ padding: 5, flexDirection: 'row', alignItems: 'center' }}
				>
					{IS_WEB && Platform.OS !== 'android' && Platform.OS !== 'ios' && screenWidth >= 440 && (
						<Text
							style={{
								color: color,
								fontSize: NAVFONTSIZE,
								paddingHorizontal: 5
							}}
						>
							{t('Export')}
						</Text>
					)}

					<Icon name={EDefaultIconSet.Export} color={color} size={NAVICONSIZE} />
				</ChildButton>
			</View>
		);
	}

	return null;
};
