import { HSCard } from 'components/Card';
import { ERoutes } from 'components/Navigation/routes';
import { NoData } from 'components/NoData';
import { IMailHistory } from 'config/interfaces';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FlashSectionList } from 'components/List';
import { MailHistoryListHeader } from './MailHistoryListHeader';
import { MAILHISTORYLISTITEMHEIGHT, MailHistoryListItem } from './MailHistoryListItem';
import { useNavigation } from '@react-navigation/native';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Spinner';
import { View } from 'react-native';

interface IMailHistoryList {
	TESTIDPREFIX: string;
	items: IMailHistory[];
	searchTerm?: string;
}

interface IMailHistoryData {
	header: string;
	items: IMailHistory[];
}

export const MailHistoryList = (props: IMailHistoryList) => {
	const { TESTIDPREFIX, items, searchTerm } = props;
	const navigation = useNavigation();
	const { activeSpace } = useSpace();
	const { t }: { t: any } = useTranslation();

	const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

	const [searchedHistories, setSearchedHistories] = useState<(string | IMailHistory)[]>([]);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		if (isDataLoading) {
			setTimeout(() => {
				setIsDataLoading(false);
			}, 5000);
		}
	}, []);

	useEffect(() => {
		const sections: IMailHistoryData[] = [];
		let newSections: typeof searchedHistories = [];

		let list: IMailHistory[] = [...items];

		if (searchTerm && searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			list = items.filter((e) => {
				if (e.templateTitle?.toLowerCase().includes(lowerSearch) || e.subject.toLowerCase().includes(lowerSearch)) {
					return true;
				}

				const splitted = e.receivers.split(',');
				for (const userId of splitted) {
					const attendee = attendees.find((e) => e.userId === userId);
					if (attendee && `${attendee.firstName} ${attendee.lastName}`.toLowerCase().includes(lowerSearch)) {
						return true;
					}
				}

				return false;
			});
		}

		list.forEach((historyItem) => {
			const date = moment(historyItem.sentOn).format('DD.MM.YYYY');

			const found = sections.find((e) => e.header === date);
			if (found) {
				found.items.push(historyItem);
			} else {
				sections.push({
					header: date,
					items: [historyItem]
				});
			}
		});

		sections.forEach((section) => {
			newSections.push(section.header);
			newSections = newSections.concat(section.items);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setSearchedHistories(newSections);
		setIsDataLoading(false);
	}, [items, searchTerm]);

	const _renderList = () => {
		if (searchedHistories.length === 0 && (!isDataLoading || !isEmptyString(searchTerm))) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoMailHistory" />;
		}

		return (
			<HSCard style={{ flex: 1 }}>
				{isDataLoading ? (
					<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
						<Spinner size={'large'} />
					</View>
				) : (
					<FlashSectionList
						data={searchedHistories}
						renderItem={(renderItem) => (
							<MailHistoryListItem
								testID={`${TESTIDPREFIX}_list_${renderItem.index}`}
								item={renderItem.item}
								onPress={() =>
									navigation.navigate(ERoutes.MailHistoryDetails, {
										spaceId: activeSpace?.spaceId,
										id: renderItem.item.id
									})
								}
							/>
						)}
						renderSectionHeader={(renderItem) => <MailHistoryListHeader text={renderItem.item} />}
						estimatedItemSize={MAILHISTORYLISTITEMHEIGHT}
						hideIndexButtons
					/>
				)}
			</HSCard>
		);
	};

	return _renderList();
};
