import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderEditButton,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { IFeatureInfo } from 'config/interfaces';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { Markdown } from 'components/Markdown';
import { HSCard } from 'components/Card';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { ScrollView, View } from 'react-native';
import { IS_WEB } from 'helper';
import { TABBAR_HEIGHT } from 'config/constants';
import { IRootState } from 'rematch/store';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Markdown>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Markdown>;
type RouteParams = StackParamList[ERoutes.Markdown];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'markdown';

export const MarkdownScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();
	const { screenWidth } = useQuery();

	const [feature, setFeature] = useState<IFeatureInfo | undefined>(undefined);

	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		let _feature: typeof feature = undefined;

		if (activeSpace?.features) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params?.key);

			if (_feature) {
				navigation.setOptions({
					title: _feature?.label
				});
			}
		}

		setFeature(_feature);
	}, [activeSpace]);

	return (
		<ScreenContainer>
			<View style={{ flex: 1, marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0 }}>
				<ScrollView
					style={{ flex: 1, marginBottom: isTabbarVisible ? 0 : hsBottomMargin }}
					contentContainerStyle={{
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						paddingTop: hsTopScreenPadding,
						width: screenWidth,
						alignSelf: 'center'
					}}
					testID={`${TESTIDPREFIX}_scrollview`}
				>
					<HSCard>
						<Markdown markdown={feature?.content} />
					</HSCard>
				</ScrollView>
			</View>
		</ScreenContainer>
	);
};

export const MarkdownScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.EditFeature} id={params?.key} paramType={'key'} />
		</NavigationHeader>
	);
};

export const MarkdownScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route, options } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.EditFeature} id={params?.key} paramType={'key'} />
		</NavigationHeader>
	);
};
