import { IContentTypeField, IExpo, ILauncherSpace, IMedia, ISpeaker, IStage, IStrapiBase, IStream, IVote, TMedia } from '.';

export interface ISpeakerGroup {
	key: string;
	title: string;
	speakerIds: number[];
}
export interface ISchedule extends IStrapiBase {
	title: string;
	spaceId: string;
	startDate?: string;
	endDate?: string;
	description?: string;
	subtitle?: string;
	stage?: IStage;
	status: IScheduleStatus;
	speakers?: ISpeaker[];
	hasNoDetails?: boolean;
	backgroundColor?: string;
	textColor?: string;
	votes?: IVote[];
	stream?: IStream[];
	previewImage?: IMedia;
	isBookable?: boolean;
	bookingCapacity?: number | null;
	expos?: IExpo[];
	autoUpdateStatus?: boolean;
	languages?: string;
	isHidden?: boolean;
	speakerGroups?: {
		groups: ISpeakerGroup[];
	};
	category1?:string
	category2?:string
	category3?:string
	category4?:string
	category5?:string
	category6?:string
	category7?:string
	category8?:string
	category9?:string
	category10?:string
}

export interface IScheduleStatus extends IStrapiBase {
	status: 'Upcoming' | 'Live' | 'Replay' | 'Finished';
	schedule: ISchedule;
}

export interface ICreateScheduleFormValues {
	title: string;
	startDate?: string;
	endDate?: string;
	description?: string;
	subtitle?: string;
	stage?: number;
	speakers?: number[];
	hasNoDetails?: boolean;
	backgroundColor?: string;
	textColor?: string;
	votes?: number[];
	stream?: number[];
	expos?: number[];
	bookingCapacity?: number | null;
	autoUpdateStatus?: boolean;
	previewImage?: TMedia;
	languages?: string;
	isHidden?: boolean;
	speakerGroups?: {
		groups: ISpeakerGroup[];
	};
}

export interface IUpdateScheduleFormValues {
	id?: number;
	title?: string;
	startDate?: string;
	endDate?: string;
	description?: string;
	subtitle?: string;
	stage?: number;
	speakers?: number[];
	status?: number;
	hasNoDetails?: boolean;
	backgroundColor?: string;
	textColor?: string;
	votes?: number[];
	stream?: number[];
	expos?: number[];
	bookingCapacity?: number | null;
	autoUpdateStatus?: boolean;
	previewImage?: TMedia;
	languages?: string;
	isHidden?: boolean;
	speakerGroups?: {
		groups: ISpeakerGroup[];
	};
	updated_at?: string;
}

export interface ICreateScheduleFormErrors {
	title?: string;
	startDate?: string;
	endDate?: string;
	description?: string;
	subtitle?: string;
	stage?: string;
	hasNoDetails?: string;
	backgroundColor?: string;
	textColor?: string;
	expos?: string;
	autoUpdateStatus?: string;
	previewImage?: string;
	languages?: string;
	isHidden?: string;
}

export interface IUpdateScheduleFormErrors {
	title?: string;
	startDate?: string;
	endDate?: string;
	description?: string;
	subtitle?: string;
	stage?: string;
	hasNoDetails?: string;
	backgroundColor?: string;
	textColor?: string;
	expos?: string;
	autoUpdateStatus?: string;
	previewImage?: string;
	languages?: string;
	isHidden?: boolean;
}

export interface ICreateSchedulePayload {
	space?: ILauncherSpace;
	schedule: ICreateScheduleFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateSchedulePayload {
	schedule: ISchedule | IUpdateScheduleFormValues;
	isDeletion?: boolean;
}

export interface IUpdateScheduleStatusPayload {
	newStatus: IScheduleStatus['status'];
	statusId: number;
}

export const getCreateScheduleInitialFormValues = () => {
	return {
		title: '',
		speakers: [],
		votes: []
	};
};

export interface ILoadPublicAgendaPayload {
	spaceId: string;
}

export interface IRefillRedisPayload {
	onlyCurrentSpace?: boolean;
}

export interface ISetCacheTimerPayload {
	onlyCurrentSpace?: boolean;
	timer?: number;
}
