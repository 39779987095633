import React from 'react';
import { StyleSheet } from 'react-native';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { BUTTONBORDERRADIUS, BUTTONBORDERWIDTH } from 'components/Button/constants';
import { Icon } from 'components/Icon';

interface ITabSwitcherButton {
	testID: string;
	label?: string;
	icon?: string;
	isActive: boolean;
	isFirst: boolean;
	isLast: boolean;
	onPress: () => void;
}

export const TabSwitcherButton = (props: ITabSwitcherButton) => {
	const { testID, label, isActive, isFirst, isLast, onPress, icon } = props;
	const { theme } = useTheme();

	let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
	let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
	let color = primary === bg ? theme.navigationTextColor : theme.primary;

	return (
		<ChildButton
			testID={testID}
			style={[
				styles.baseBorder,
				isActive ? { backgroundColor: theme.primary } : null,
				!isLast ? styles.middleBorder : null,
				isFirst ? styles.firstBorder : isLast ? styles.lastBorder : null,
				{ borderColor: color, paddingHorizontal: 10, paddingVertical: 5, alignItems: 'center' }
			]}
			onPress={onPress}
		>
			{icon ? (
				<Icon name={icon} color={isActive ? theme.primaryContrast : theme.primary} size={15} />
			) : (
				<Text style={{ color: isActive ? theme.primaryContrast : theme.primary }}>{label}</Text>
			)}
		</ChildButton>
	);
};

const styles = StyleSheet.create({
	baseBorder: {
		borderTopWidth: BUTTONBORDERWIDTH,
		borderBottomWidth: BUTTONBORDERWIDTH
	},
	firstBorder: {
		borderLeftWidth: BUTTONBORDERWIDTH,
		borderTopLeftRadius: BUTTONBORDERRADIUS,
		borderBottomLeftRadius: BUTTONBORDERRADIUS
	},
	middleBorder: {
		borderRightWidth: BUTTONBORDERWIDTH
	},
	lastBorder: {
		borderRightWidth: BUTTONBORDERWIDTH,
		borderTopRightRadius: BUTTONBORDERRADIUS,
		borderBottomRightRadius: BUTTONBORDERRADIUS
	}
});
