import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { EDefaultIconSet } from 'helper/icon';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { ERoutes } from '../routes';
import * as RootNavigation from '../../../RootNavigation';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { openURL } from 'helper';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';
interface INavigationHeaderBackButtonComponent {
	onPress?: Function;
	route?: any;
	options?: any;
	openMediaModalOnBack?: boolean;
}

export const NavigationHeaderBackButton = (props: INavigationHeaderBackButtonComponent) => {
	const { onPress, route, options, openMediaModalOnBack } = props;
	const navigation = useNavigation();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);

	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);

	return (
		<NavigationHeaderIconButton
			testID="header_button_navback"
			icon={EDefaultIconSet.ArrowLeft}
			onPress={async () => {
				if (onPress) {
					const onPressRes = await onPress();
					if (onPressRes === false) {
						return;
					}
				}

				if (openMediaModalOnBack && mediaDetail.playbackStatus === 'playing') {
					setMediaDetail({
						itemId: route?.params.scheduleId ? Number(route?.params.scheduleId) : Number(route?.params.mediaItemId),
						viewType: 'collapsed',
						playbackStatus: 'playing',
						itemType: route?.params?.scheduleId ? 'schedule' : 'mediaitem'
					});
				}

				if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
					if (route.name === ERoutes.Media) {
						setMediaDetail({
							itemId: undefined,
							viewType: 'full',
							playbackStatus: 'paused'
						});
						try {
							navigation.navigate(ERoutes.PublicAgenda, { spaceId: PRESET_SPACEIDS[0] });
						} catch (error) {
							openURL(`${DEFAULT_PLATFORM_URL}presentation/publicagenda/${PRESET_SPACEIDS[0]}`, true);
						}
					} else {
						navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
					}
				} else if (!route?.params || !route?.params['prohibitNavigation']) {
					if (RootNavigation.canGoBack()) {
						navigation.goBack();
					} else {
						RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
					}
				} else {
					const buttons = [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('leaveWithoutSaving'),
							style: 'destructive',
							onPress: async () => {
								if (RootNavigation.canGoBack()) {
									navigation.goBack();
								} else {
									RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
								}
							}
						}
					];
					if (options?.onBackProhibitedSave) {
						buttons.push({
							text: t('Save'),
							style: 'default',
							onPress: async () => {
								await options?.onBackProhibitedSave();
							}
						});
					}
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: buttons
					});
				}
			}}
		/>
	);
};
