import React from 'react';
import { useTranslation } from 'react-i18next';

import { HSModal } from '../Modal';
import { hsInnerPadding } from 'config/styleConstants';
import { ScrollView, View } from 'react-native';
import { IWebhookHistoryItem } from 'config/interfaces';
import { IS_WEB } from 'helper';
import WebView from 'react-native-webview';
import moment from 'moment';

interface IWebhookHistoryModal {
	item?: IWebhookHistoryItem;
	onClose: () => void;
}

const testIdPrefix = 'webhookhistorymodal';

export const WebhookHistoryModal = (props: IWebhookHistoryModal) => {
	const { item, onClose } = props;
	const { t } = useTranslation();

	const _getHeader = (text:string) => {
		return `<h2>${text}</h2>`
	}

	const _getSubHeader = (text:string) => {
		return `<h4>${text}</h4>`
	}

	const _getStringifiedJSON = (json:Record<string,any>) => {
		return `<div><pre>${JSON.stringify(json,null,2)}</pre></div>`
	}


	const _getEventAndDate = () => {
		return `${_getHeader('Infos')}${_getStringifiedJSON({
				date: moment(item?.date).format('DD.MM.YYYY HH:mm.ss'),
				event: item?.body.event,
				retryCount: item?.calls.count,
				next: item?.calls.next ? moment(item.calls.next).format('DD.MM.YYYY HH:mm'): item && item?.status >= 300 ? 'Maximum number of retries reached' :undefined
			})}`;
	};

	const _getAnswer = () => {
		return  `${_getHeader(t('HTTPAnswer'))}${_getStringifiedJSON({ status: item?.status, statusText: item?.error })}`;
	};

	const _getRequest = () => {
		return `${_getHeader(t('Request'))}${item?.header ?`${_getSubHeader('Request Header')}${_getStringifiedJSON(item?.header)}`:''}${_getSubHeader('Request Body')}${_getStringifiedJSON(item?.body.item)}`;
	};

	const _getMarkdown = () => {
		return `${_getEventAndDate()}${_getAnswer()}${_getRequest()}`;
	};

	const jsInjection = `const meta = document.createElement('meta'); meta.setAttribute('content', 'initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta);`;

	return (
		<HSModal isVisible={item !== undefined} onClose={onClose}>
			<View style={{ marginHorizontal: -hsInnerPadding, flex: 1 }}>
				{IS_WEB ? (
					<ScrollView contentContainerStyle={{ paddingHorizontal: 10 }}>
						<div dangerouslySetInnerHTML={{ __html: _getMarkdown() }} />
					</ScrollView>
				) : (
					<WebView originWhitelist={['*']} source={{ html: _getMarkdown() }} injectedJavaScript={jsInjection} />
				)}
			</View>
		</HSModal>
	);
};
