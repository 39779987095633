import { ICreateVoteFormValues, IEditVoteFormValues } from 'config/interfaces';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getEmailError, getMaxOptionsError, getMinNumberError, getMinOptionsError, getRequiredError, getWebsiteError } from './errors';
import moment from 'moment';
import { getValidURL, isEmptyString } from 'helper';

const _getBaseSchema = (item) => {
	return {
		publicId: Yup.string()
			.required(getRequiredError(i18next.t('Vote Public Id')))
			.nullable(),
		title: Yup.string().nullable(),
		question: Yup.string().required(getRequiredError(i18next.t('Question'))),
		hint: Yup.string().nullable(),
		votingType: Yup.string().required(i18next.t('Voting type')),
		openFrom: Yup.string().nullable(),
		openUntil: Yup.string()
			.test('voteCreateDateAfterOpenFrom', i18next.t('FormErrorDateAfter').replace('%FIELD%', i18next.t('OpenFromLabel')), () => {
				if (!item.openFrom || !item.openUntil) {
					return true;
				}
				return moment(item.openUntil).isAfter(moment(item.openFrom));
			})
			.nullable()
	};
};
export const getCreateVoteTextFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getEditVoteTextFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		isActive: Yup.boolean().nullable()
	});
};

export const getCreateVoteExternalUrlFormSchema = (item: ICreateVoteFormValues) => {
	if (item.externalUrl && !isEmptyString(item.externalUrl)) {
		item.externalUrl = getValidURL(item.externalUrl);
	}

	return Yup.object().shape({
		..._getBaseSchema(item),
		externalUrl: Yup.string().url(getWebsiteError(item.externalUrl)).nullable()
	});
};

export const getEditVoteExternalUrlFormSchema = (item: IEditVoteFormValues) => {
	if (item.externalUrl && !isEmptyString(item.externalUrl)) {
		item.externalUrl = getValidURL(item.externalUrl);
	}

	return Yup.object().shape({
		..._getBaseSchema(item),
		externalUrl: Yup.string().url(getWebsiteError(item.externalUrl)).nullable()
	});
};

export const getCreateVoteRatingFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		viewType: Yup.string().nullable(),
		maxValue: Yup.number()
			.nullable()
			.min(2, i18next.t('VoteFormMinValue').replace('%VALUE%', '2'))
			.max(10, i18next.t('VoteFormMaxValue').replace('%VALUE%', '10'))
	});
};

export const getEditVoteRatingFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		viewType: Yup.string().nullable(),
		maxValue: Yup.number()
			.nullable()
			.min(2, i18next.t('VoteFormMinValue').replace('%VALUE%', '2'))
			.max(10, i18next.t('VoteFormMaxValue').replace('%VALUE%', '10'))
	});
};

export const getCreateVoteApplauseFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getCreateVoteApplauseFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.required(getMinOptionsError(1))
			.min(1, getMinOptionsError(1))
			.max(100, getMaxOptionsError(100))
			.of(
				Yup.object().shape({
					label: Yup.string()
						.nullable()
						.required(getRequiredError(i18next.t('Icon'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			)
	});
};

export const getEditVoteApplauseFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getEditVoteApplauseFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.required(getMinOptionsError(1))
			.min(1, getMinOptionsError(1))
			.max(100, getMaxOptionsError(100))
			.of(
				Yup.object().shape({
					label: Yup.string()
						.nullable()
						.required(getRequiredError(i18next.t('Icon'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			)
	});
};

export const getCreateVoteMultipleChoiceFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.max(100, i18next.t('VoteFormMaxValue').replace('%VALUE%', '100'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.max(100, i18next.t('VoteFormMaxValue').replace('%VALUE%', '100'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue')))
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormScaleMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormScaleMaxValue')),
				() => {
					if ((item.minValue || item.minValue === 0) && item.maxValue && item.minValue <= item.maxValue) {
						return true;
					}

					return false;
				}
			)
	});
};

export const getCreateVoteMultipleChoiceFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.required(getMinOptionsError(2))
			.min(Math.max(item.minValue ?? 1, 2), getMinOptionsError(Math.max(item.minValue ?? 1, 2)))
			.max(100, getMaxOptionsError(100))
			.of(
				Yup.object().shape({
					description: Yup.string()
						.nullable()
						.required(getRequiredError(i18next.t('Text'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			)
	});
};

export const getEditVoteMultipleChoiceFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.max(100, i18next.t('VoteFormMaxValue').replace('%VALUE%', '100'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.max(100, i18next.t('VoteFormMaxValue').replace('%VALUE%', '100'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue')))
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormScaleMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormScaleMaxValue')),
				() => {
					if ((item.minValue || item.minValue === 0) && item.maxValue && item.minValue <= item.maxValue) {
						return true;
					}

					return false;
				}
			)
	});
};

export const getEditVoteMultipleChoiceFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.required(getMinOptionsError(2))
			.min(Math.max(item.minValue ?? 1, 2), getMinOptionsError(Math.max(item.minValue ?? 1, 2)))
			.max(100, getMaxOptionsError(100))
			.of(
				Yup.object().shape({
					description: Yup.string()
						.nullable()
						.required(getRequiredError(i18next.t('Text'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			)
	});
};

export const getCreateVoteScaleFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number().required(getRequiredError(i18next.t('VoteFormScaleMinValue'))),
		maxValue: Yup.number()
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormScaleMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormScaleMaxValue')),
				() => {
					if ((item.minValue || item.minValue === 0) && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormScaleMaxValue')))
	});
};

export const getEditVoteScaleFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number().required(getRequiredError(i18next.t('VoteFormScaleMinValue'))),
		maxValue: Yup.number()
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormScaleMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormScaleMaxValue')),
				() => {
					if ((item.minValue || item.minValue === 0) && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormScaleMaxValue')))
	});
};

export const getCreateVoteWordCloudFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.max(100, i18next.t('VoteFormMaxValue').replace('%VALUE%', '100'))
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormWordCloudMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormWordCloudMaxValue')),
				() => {
					if (item.minValue && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormWordCloudMaxValue')))
	});
};

export const getEditVoteWordCloudFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(1, i18next.t('VoteFormMinValue').replace('%VALUE%', '1'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.max(100)
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormWordCloudMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormWordCloudMaxValue')),
				() => {
					if (item.minValue && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormWordCloudMaxValue')))
	});
};

export const getCreateVoteNpsFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(0, i18next.t('VoteFormMinValue').replace('%VALUE%', '0'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.max(100)
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormWordCloudMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormWordCloudMaxValue')),
				() => {
					if (item.minValue !== undefined && item.minValue !== null && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormWordCloudMaxValue')))
	});
};

export const getEditVoteNpsFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		minValue: Yup.number()
			.min(0, i18next.t('VoteFormMinValue').replace('%VALUE%', '0'))
			.required(getRequiredError(i18next.t('VoteFormWordCloudMinValue'))),
		maxValue: Yup.number()
			.max(100)
			.test(
				'minValueLTMaxValue',
				i18next
					.t('FormMinMaxError')
					.replace('%FIELD1%', i18next.t('VoteFormWordCloudMinValue'))
					.replace('%FIELD2%', i18next.t('VoteFormWordCloudMaxValue')),
				() => {
					if (item.minValue !== undefined && item.minValue !== null && item.maxValue && item.minValue < item.maxValue) {
						return true;
					}

					return false;
				}
			)
			.required(getRequiredError(i18next.t('VoteFormWordCloudMaxValue')))
	});
};

export const getCreateVotePinOnImageFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getEditVotePinOnImageFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getNonAnonymousVoteFormSchema = (item) => {
	return Yup.object().shape({
		firstName: Yup.string().required(getRequiredError(i18next.t('firstName'))),
		lastName: Yup.string().required(getRequiredError(i18next.t('lastName'))),
		email: Yup.string()
			.email(getEmailError('E-Mail'))
			.required(getRequiredError(i18next.t('E-Mail')))
	});
};

export const getCreateVoteQuizFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getCreateVoteQuizFormSchemaStep2 = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.min(2, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
			.required(getMinOptionsError(2))
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Question'))),
					label: Yup.string().nullable(),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			),
		optionVote: Yup.array().of(
			Yup.object().shape({
				question: Yup.string().required(getRequiredError(i18next.t('Question'))),
				hint: Yup.string().nullable(),
				score: Yup.number()
					.typeError(i18next.t('FormValidationErrorNumber'))
					.required(getRequiredError(i18next.t('score')))
					.min(1, getMinNumberError(1)),
				timeOut: Yup.number()
					.typeError(i18next.t('FormValidationErrorNumber'))
					.required(getRequiredError(i18next.t('timeOut')))
					.min(5, getMinNumberError(5)),
				options: Yup.array()
					.of(
						Yup.object().shape({
							description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
							order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
							isCorrect: Yup.boolean().nullable()
						})
					)
					.required(getMinOptionsError(2))
					.min(2, getMinOptionsError(2))
					.max(100, getMaxOptionsError(100))
					.test('min1Correct', i18next.t('OneOptionCorrect'), () => item.options?.find((e) => e.isCorrect) !== undefined)
			})
		)
	});
};

export const getCreateVoteSurveyFormSchema = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getCreateVoteSurveyFormSchemaStep2 = (item: ICreateVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.min(2, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
			.required(getMinOptionsError(2))
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Question'))),
					label: Yup.string().nullable(),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			),
		optionVote: Yup.array().of(
			Yup.object().shape({
				question: Yup.string().required(getRequiredError(i18next.t('Question'))),
				hint: Yup.string().nullable(),
				options: Yup.array()
					.of(
						Yup.object().shape({
							description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
							order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
						})
					)
					.required(getMinOptionsError(2))
					.min(2, getMinOptionsError(2))
					.max(100, getMaxOptionsError(100))
			})
		)
	});
};

export const getSurveyOptionFormSchema = (form) => {
	let _minValue = form.type === 'wordCloud' ? 1 : form.type === 'rating' ? 2 : 0;
	let _maxValue = form.type === 'rating' ? 10 : form.type === 'scale' ? 1000 : 100;
	return Yup.object().shape({
		question: Yup.string().required(getRequiredError(i18next.t('Question'))),
		hint: Yup.string().nullable(),
		minValue: Yup.number()
			.nullable()
			.min(form.type === 'rating' ? 0 : _minValue, i18next.t('VoteFormMinValue').replace('%VALUE%', _minValue.toString()))
			.max(_maxValue, i18next.t('VoteFormMaxValue').replace('%VALUE%', _maxValue.toString())),
		maxValue: Yup.number()
			.nullable()
			.min(
				_minValue,
				form.type === 'rating'
					? i18next.t('VoteFormMinValue').replace('%VALUE%', '2')
					: i18next.t('VoteFormMaxValue').replace('%VALUE%', _minValue.toString())
			)
			.max(_maxValue, i18next.t('VoteFormMaxValue').replace('%VALUE%', _maxValue.toString())),
		options: Yup.array()
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
					isCorrect: Yup.boolean().nullable()
				})
			)
			.required(getMinOptionsError(2))
			.min(form.type === 'multipleChoice' ? 2 : 0, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
	});
};

export const getQuizOptionFormSchema = (form) => {
	return Yup.object().shape({
		question: Yup.string().required(getRequiredError(i18next.t('Question'))),
		hint: Yup.string().nullable(),
		score: Yup.number()
			.typeError(i18next.t('FormValidationErrorNumber'))
			.required(getRequiredError(i18next.t('score')))
			.min(1, getMinNumberError(1)),
		timeOut: Yup.number()
			.typeError(i18next.t('FormValidationErrorNumber'))
			.required(getRequiredError(i18next.t('timeOut')))
			.min(5, getMinNumberError(5)),
		options: Yup.array()
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
					isCorrect: Yup.boolean().nullable()
				})
			)
			.test('min1Correct', i18next.t('OneOptionCorrect'), () => form.options?.find((e) => e.isCorrect) !== undefined)
			.required(getMinOptionsError(2))
			.min(2, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
	});
};

export const getEditVoteSurveyFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getEditVoteSurveyFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.min(2, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
			.required(getMinOptionsError(2))
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Question'))),
					label: Yup.string().nullable(),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			),
		optionVote: Yup.array().of(
			Yup.object().shape({
				question: Yup.string().required(getRequiredError(i18next.t('Question'))),
				hint: Yup.string().nullable(),
				options: Yup.array().of(
					Yup.object().shape({
						description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
						label: Yup.string().nullable(),
						order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
					})
				)
			})
		)
	});
};

export const getEditVoteQuizFormSchema = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item)
	});
};

export const getEditVoteQuizFormSchemaStep2 = (item: IEditVoteFormValues) => {
	return Yup.object().shape({
		..._getBaseSchema(item),
		options: Yup.array()
			.min(2, getMinOptionsError(2))
			.max(100, getMaxOptionsError(100))
			.required(getMinOptionsError(2))
			.of(
				Yup.object().shape({
					description: Yup.string().required(getRequiredError(i18next.t('Question'))),
					label: Yup.string().nullable(),
					order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable()
				})
			),
		optionVote: Yup.array().of(
			Yup.object().shape({
				question: Yup.string().required(getRequiredError(i18next.t('Question'))),
				hint: Yup.string().nullable(),
				score: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
				timeOut: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
				options: Yup.array().of(
					Yup.object().shape({
						description: Yup.string().required(getRequiredError(i18next.t('Answer'))),
						label: Yup.string().nullable(),
						order: Yup.number().typeError(i18next.t('FormValidationErrorNumber')).nullable(),
						isCorrect: Yup.boolean().nullable()
					})
				)
			})
		)
	});
};
