import * as bodyPix from '@tensorflow-models/body-pix';

import { CanvasVideoFrameBuffer, VideoFrameBuffer, VideoFrameProcessor } from 'amazon-chime-sdk-js';
import { IS_WEB } from 'helper';
import React from 'react';
import { TBackgroundBlur } from './NetworkingRoom';

let model: any = null;

const loadBodyPix = async () => {
	model = await bodyPix.load({
		architecture: 'MobileNetV1',
		outputStride: 16,
		multiplier: 0.5,
		quantBytes: 4
	});
};

type MyProps = {
	blur: TBackgroundBlur;
};

IS_WEB && loadBodyPix();

export class BlurBackgroundProcerssor extends React.Component<MyProps> implements VideoFrameProcessor {
	private targetCanvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;

	private canvasVideoFrameBuffer = new CanvasVideoFrameBuffer(this.targetCanvas);
	async process(buffers: VideoFrameBuffer[]): Promise<VideoFrameBuffer[]> {
		for (let i = 0; i < buffers.length; i++) {
			const canvas = await buffers[i].asCanvasElement();

			if (canvas.width === 0 || canvas.height === 0) {
				return buffers;
			}

			let backgroundBlurAmount = 0;
			let edgeBlurAmount = 0;

			switch (this.props.blur) {
				case 'strong':
					backgroundBlurAmount = 20;
					edgeBlurAmount = 20;
					break;
				case 'light':
					backgroundBlurAmount = 3;
					edgeBlurAmount = 3;
					break;
				default:
					break;
			}

			const flipHorizontal = false;

			try {
				const segmentation = await model.segmentPerson(canvas);
				bodyPix.drawBokehEffect(this.targetCanvas, canvas, segmentation, backgroundBlurAmount, edgeBlurAmount, flipHorizontal);
			} catch (error) {
				console.log('blur', error);
			}
			buffers[i] = this.canvasVideoFrameBuffer;
		}

		return buffers;
	}

	async destroy(): Promise<void> {}
}
