import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';
import * as RootNavigation from '../../../RootNavigation';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderEditButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { IAttendee, IContentTypeField, IExpo, IMediaItem, ISchedule, ISpeaker } from 'config/interfaces';
import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { ExpoDetail } from 'components/Expo';
import { ScrollView } from 'react-native';
import { showToast } from 'helper/toast';
import { useTranslation } from 'react-i18next';
import { getDetailWidth } from 'helper';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ExpoDetails>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ExpoDetails>;
type RouteParams = StackParamList[ERoutes.ExpoDetails];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'expodetails';

export const ExpoDetailsScreen = ({ route, navigation }: Props) => {
	const { getContentTypeFields } = useContent('expo');
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();
	const { screenWidth } = useQuery();
	const { t } = useTranslation();

	const [expo, setExpo] = useState<IExpo | undefined>(undefined);
	const [expoDetailFields, setExpoDetailFields] = useState<IContentTypeField[]>([]);
	const [referencedSpeakers, setReferencesSpeakers] = useState<ISpeaker[]>([]);
	const [referencedMediaItems, setReferencedMediaItems] = useState<IMediaItem[]>([]);
	const [referencedAttendees, setReferencedattendees] = useState<IAttendee[]>([]);
	const [referencedSchedules, setReferencedSchedules] = useState<ISchedule[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		trackAction('expo', 'Open Detail', `${route.params.id}`);
	}, []);

	useEffect(() => {
		let _expo: typeof expo = undefined;
		let _referencedSpeakers: typeof referencedSpeakers = [];
		let _referencedMediaItems: typeof referencedMediaItems = [];
		let _referencedAttendees: typeof referencedAttendees = [];
		let _referencedSchedules: typeof referencedSchedules = [];

		const iAmSpaceAdmin = activeSpace?.admins?.find((e) => e.userId === profile?.userId);
		const iAmSpaceModerator = activeSpace?.moderators?.find((e) => e.userId === profile?.userId);

		if (activeSpace && content.expos) {
			if (content.expos) {
				_expo = content.expos.find((e) => e.id === route.params.id);
				if (_expo) {
					if (_expo.isHidden && !iAmSpaceAdmin && !iAmSpaceModerator) {
						showToast('info', t('EntryNotFound'));
						if (RootNavigation.canGoBack()) {
							navigation.goBack();
						} else {
							RootNavigation.replace(activeSpace ? 'tab' : ERoutes.SpaceSelect);
						}
						return;
					}
					navigation.setOptions({ title: _expo.title });
				}
			}

			if (_expo) {
				_expo.speakers?.forEach((sp) => {
					const found = content.speakers.find((e) => e.id === sp.id && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator));
					if (found) {
						_referencedSpeakers.push(found);
					}
				});

				_expo.mediaitems?.forEach((mi) => {
					const found = content.mediaitems.find(
						(e) => e.id === mi.id && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator) && e.spaceId === activeSpace.spaceId
					);
					if (found) {
						_referencedMediaItems.push(found);
					}
				});
				_expo.attendees?.forEach((at) => {
					const found = attendees.find((a) => a.id === at.id);
					if (found) {
						_referencedAttendees.push(found);
					}
				});
				_expo.schedules?.forEach((sc) => {
					const found = content.schedules.find((e) => e.id === sc.id && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator));
					if (found) {
						_referencedSchedules.push(found);
					}
				});
			}
		}

		setExpo(_expo);
		setReferencesSpeakers(_referencedSpeakers);
		setReferencedMediaItems(_referencedMediaItems);
		setReferencedattendees(_referencedAttendees);
		setReferencedSchedules(_referencedSchedules);
	}, [content, activeSpace, profile, route.params.id]);

	useEffect(() => {
		const _fields = getContentTypeFields(true).filter(
			(e) => (e.showOnDetailScreen || e.fieldType === 'section' || e.fieldType === 'mainsection') && e.fieldType !== 'avatar'
		);
		setExpoDetailFields(_fields);
	}, [activeSpace]);

	return (
		<ScreenContainer handleBackPress contentKey="expos">
			<ScrollView
				contentContainerStyle={{
					flex: 1,
					width: getDetailWidth(activeSpace?.expoDetailViewType ?? activeSpace?.globalDetailViewType ?? '', screenWidth),
					alignSelf: 'center'
				}}
			>
				<ExpoDetail
					testID={TESTIDPREFIX}
					expo={expo}
					space={activeSpace}
					detailFields={expoDetailFields}
					referencedAttendees={referencedAttendees}
					referencedSpeakers={referencedSpeakers}
					referencedMediaItems={referencedMediaItems}
					referencedSchedules={referencedSchedules}
				/>
			</ScrollView>
		</ScreenContainer>
	);
};

export const ExpoDetailsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options?.title ?? ''} />
			<NavigationHeaderEditButton route={ERoutes.ExpoEdit} id={params?.id} title={params.title} />
		</NavigationHeader>
	);
};
