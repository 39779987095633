import React from 'react';

import { Icon } from 'components/Icon';
import { normalizeFontSize } from 'helper';
import { ChildButton } from 'components/Button';
import { Text } from 'components/Text';
import { View, ViewStyle } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { BUTTONBORDERRADIUS, BUTTONBORDERWIDTH } from 'components/Button/constants';

interface IVoteAdminButton {
	testID: string;
	onPress?: () => void;
	icon?: string;
	label?: string;
	containerStyle?: ViewStyle;
	isActive?: boolean;
	isLoading?: boolean;
}

export const VoteAdminButton = (props: IVoteAdminButton) => {
	const { testID, onPress, icon, label, containerStyle, isActive, isLoading } = props;
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={`${testID}_button_${label}`}
			style={[
				containerStyle,
				{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					borderWidth: BUTTONBORDERWIDTH,
					borderColor: isActive ? theme.primary : theme.lightgray,
					padding: 10,
					borderRadius: BUTTONBORDERRADIUS
				}
			]}
			isDisabled={!onPress}
			isLoading={isLoading}
			onPress={() => {
				if (onPress) {
					onPress();
				}
			}}
		>
			{icon && <Icon name={icon} size={normalizeFontSize(16)} color={isActive ? theme.primary : theme.text} />}
			{label && (
				<View style={{ flex: 1 }}>
					<Text numberOfLines={1} adjustsFontSizeToFit style={{ color: isActive ? theme.primary : theme.text, marginLeft: 10 }}>
						{label}
					</Text>
				</View>
			)}
		</ChildButton>
	);
};
