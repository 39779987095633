import { Spinner } from 'components/Spinner';
import { IInternalSettings } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { FormTextInput } from 'components/Form';
import { Button, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';

interface IEmendoSettings {
	testID: string;
}

export const EmendoSettings = (props: IEmendoSettings) => {
	const { testID } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [settings, setSettings] = useState<IInternalSettings | undefined>(undefined);
	const [emendoApiUrl, setEmendoApiUrl] = useState<string>('');
	const [emendoApiUsername, setEmendoApiUserName] = useState<string>('');
	const [emendoApiPassword, setEmendoApiPassword] = useState<string>('');

	const [isInternalSettingsLoading, setIsInternalSettingsLoading] = useState<boolean>(false);
	const [isUpdateInternalSettingsLoading, setIsUpdateInternalSettingsLoading] = useState<boolean>(false);
	const [isTestSettingsLoading, setIsTestSettingsLoading] = useState<boolean>(false);

	const getInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.getInternalSettings);
	const setInternalSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.internalsettings.setInternalSettings);
	const testEmendoSettings = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.testEmendoSettings);

	useEffect(() => {
		_loadInternalSettings();
	}, []);

	const _loadInternalSettings = async () => {
		setIsInternalSettingsLoading(true);
		const res = await getInternalSettings({});
		setSettings(res);
		setEmendoApiUserName(res?.emendoApiUsername);
		setIsInternalSettingsLoading(false);
	};

	const _setInternalSettings = async () => {
		setIsUpdateInternalSettingsLoading(true);
		const res = await setInternalSettings({ settings: { emendoApiUrl, emendoApiUsername, emendoApiPassword } });
		setSettings(res);
		setIsUpdateInternalSettingsLoading(false);
	};

	const _testEmendoSettings = async () => {
		setIsTestSettingsLoading(true);
		await testEmendoSettings({
			emendoApiUrl: settings?.emendoApiUrl ?? emendoApiUrl,
			emendoApiPassword: settings?.emendoApiPassword ?? emendoApiPassword,
			emendoApiUsername
		});
		setIsTestSettingsLoading(false);
	};

	const _renderEmenoStatus = () => {
		if (isInternalSettingsLoading) {
			return <Spinner />;
		}

		if (!settings) {
			// This should only happen if backend throws any error
			return <Text>{t('Could not get settings')}</Text>;
		}

		if (!settings.emendoApiUrl || !settings.emendoApiUsername || !settings.emendoApiPassword) {
			return (
				<View>
					<Text style={{ color: theme.danger, marginBottom: 10 }}>{t('EmendoAPINotConfigured')}</Text>
					<FormTextInput
						testID={`${testID}_textinput_emendoapiurl`}
						label="Emendo API URL"
						hint={t('apiKeyHint')}
						isRequired
						value={emendoApiUrl}
						onChangeText={(url) => setEmendoApiUrl(url)}
						formStyle={{ marginBottom: 10 }}
					/>
					<FormTextInput
						testID={`${testID}_textinput_emendoapiusername`}
						label="Emendo API Username"
						hint={t('apiKeyHint')}
						isRequired
						value={emendoApiUsername}
						onChangeText={(username) => setEmendoApiUserName(username)}
						formStyle={{ marginBottom: 10 }}
					/>
					<FormTextInput
						testID={`${testID}_textinput_emendoapipassword`}
						label="Emendo API Password"
						hint={t('apiKeyHint')}
						isRequired
						value={emendoApiPassword}
						onChangeText={(pw) => setEmendoApiPassword(pw)}
						formStyle={{ marginBottom: 10 }}
						secureTextEntry
					/>
					<RoundButton
						testID={`${testID}_button_savesettings`}
						icon={EDefaultIconSet.Save}
						onPress={_setInternalSettings}
						isLoading={isUpdateInternalSettingsLoading}
						isDisabled={isEmptyString(emendoApiUsername) || isEmptyString(emendoApiPassword)}
						alignSelf="flex-end"
					/>
				</View>
			);
		}

		return (
			<View>
				<Text style={{ color: theme.success, marginBottom: 10 }}>{t('EmendoAPIConfigured')}</Text>
				<Button
					testID={`${testID}_button_testsettings`}
					title={t('Test XING Events Settings')}
					isLoading={isTestSettingsLoading}
					onPress={_testEmendoSettings}
					style={{ alignSelf: 'center', marginBottom: 10 }}
					type="success"
				/>
				<Button
					testID={`${testID}_button_editsettings`}
					title={t('Swap XING Events Settings')}
					onPress={() => {
						const _settings = { ...settings };
						delete _settings.emendoApiUsername;
						delete _settings.emendoApiPassword;
						setSettings(_settings);
					}}
					isDisabled={isTestSettingsLoading}
					style={{ alignSelf: 'center' }}
					isOutline
				/>
			</View>
		);
	};

	return <View>{_renderEmenoStatus()}</View>;
};
