import React, { useState } from 'react';
import { Vibration, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormErrors, IEditVoteFormValues, IVoteOption } from 'config/interfaces';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString, swapArrayItems } from 'helper';
import { FormError } from 'components/Form/FormError';
import { FormIconPicker, FormIconPickerModal } from 'components/Form/FormIconPicker';
import { IS_ANDROID } from 'helper/platform';
import { HSDragCard } from 'components/Card';
import { NoData } from 'components/NoData';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';

interface IVoteApplauseOptionsForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteApplauseOptionsForm = (props: IVoteApplauseOptionsForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const [isIconModalVisible, setIsIconModalVisible] = useState<boolean>(false);

	const _getIconName = (icon?: string) => {
		if (icon) {
			let iconName = icon;
			if (iconName.includes(':')) {
				iconName = `${iconName.split(':')[1]} (${iconName.split(':')[0]})`;
				iconName = iconName.replace('hellospacesicon', '');
			}
			return iconName;
		}

		return 'Icon';
	};

	const _removeOption = (index) => {
		const _options = values.options;
		_options?.splice(index, 1);
		onChange({ ...values, options: _options });
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!values?.options) {
			return;
		}
		const list: IVoteOption[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, values.options);

		onChange({ ...values, options: list });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!values?.options) {
			return;
		}

		const list = [...values.options];

		const element = list.splice(oldIndex, 1)[0];
		list.splice(newIndex, 0, element);

		onChange({ ...values, options: list });
	};

	const _renderItem = (params: RenderItemParams<IVoteOption>) => {
		const { item, index, drag, isActive } = params;
		const option = item;

		return (
			<HSDragCard
				testID={`${testIdPrefix}_option_${index}`}
				isActive={isActive}
				onDrag={drag}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (values?.options?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				isDisabled={isLoading}
				onRemove={() => _removeOption(index)}
			>
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<FormIconPicker
						testID={`${testIdPrefix}_button_applauseicon_${index}`}
						isRequired
						value={option.label}
						label={_getIconName(option.label)}
						onChange={(value) => {
							if (values.options && index !== undefined) {
								let _options = values.options;
								_options[index].label = value;
								onChange({ ...values, options: _options });
							}
						}}
						isDisabled={isLoading}
					/>
				</View>
				<FormError testID={`${testIdPrefix}_textinput_applauseicon_${index}_error`} error={errors[`options[${index}].label`]} />
			</HSDragCard>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<DraggableFlatList
				containerStyle={{ flex: 1 }}
				contentContainerStyle={{ flexGrow: 1, width: screenWidth, alignSelf: 'center', padding: EHorizontalScreenPadding.Wide }}
				scrollEnabled={values.options !== undefined && values.options.length > 0}
				data={values.options ?? []}
				ListEmptyComponent={() => {
					return <NoData type="NoApplauseOptions" />;
				}}
				ListFooterComponent={() => (
					<View style={{ width: '100%', alignSelf: 'center' }}>
						<RoundButton
							testID={`${testIdPrefix}_button_add`}
							icon={EDefaultIconSet.Add}
							onPress={() => setIsIconModalVisible(true)}
							isDisabled={
								(values?.options?.length ?? 0) > 100 ||
								isLoading ||
								(values.options?.find((o) => isEmptyString(o.description) && isEmptyString(o.label)) ? true : false)
							}
							alignSelf="flex-end"
							title={t('addOptionApplause')}
						/>
					</View>
				)}
				renderItem={(params) => _renderItem(params)}
				keyExtractor={(item, index) => `${testIdPrefix}_${item.label}_${index}`}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					data.map((opt, index) => (opt.order = index));
					onChange({ ...values, options: data });
				}}
			/>
			<FormIconPickerModal
				testID={testIdPrefix}
				onClose={() => setIsIconModalVisible(false)}
				isVisible={isIconModalVisible}
				onChange={(val) => {
					let _options = values.options ?? [];
					_options?.push({
						label: val, // is used as icon here
						description: '', // is used as text here
						// color: '', might be added later
						order: values.options?.length ?? 0
					});
					onChange({ ...values, options: _options });
					setIsIconModalVisible(false);
				}}
				label={t('CTAFormIconPickerModal')}
			/>
		</View>
	);
};
