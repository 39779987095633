import { createModel } from '@rematch/core';
import { IUserProfile } from 'config/interfaces';

import {
	IConfirmAccountParams,
	IForgotPasswordParams,
	ILoginWithEmailAndPasswordParams,
	IRegisterWithEmailAndPasswordParams,
	IRemoveUserPayload,
	IResetPasswordParams,
	IStartProfileSyncParams,
	IStorePushTokenParams,
	IToggleFavoritePayload,
	IUpdateLastReadPayload,
	IUpdateUserParams
} from 'rematch/interfaces/auth';
import { MULTISPACEURL } from 'config/constants';
import { isEmptyString, stripNullValues, translateStrapiError } from 'helper';
import { ISpaceHistory } from 'rematch/interfaces';
import moment from 'moment';
import i18next from 'i18next';
import { v4 } from 'uuid';
import { showToast } from 'helper/toast';
import { RootModel } from '.';
import * as Device from 'expo-device';
import { ENVIRONMENT, PRESET_SPACEIDS } from 'config/envConstants';
import { ERoutes } from 'components/Navigation/routes';
import * as RootNavigation from '../../RootNavigation';
import { detailedDiff } from 'deep-object-diff';

type IDefaultState = {
	hasAcceptedPrivacyPolicy: boolean;
	userInfos: {
		firstName: string;
		lastName: string;
		email: string;
		imageUrl?: string;
		userId: string;
	};
	jwt?: string;
	profile?: IUserProfile;
	pushTokens?: {
		deviceToken: string;
		spaceId: string;
	}[];
};

export const auth = createModel<RootModel>()({
	state: {
		hasAcceptedPrivacyPolicy: false,
		userInfos: {
			firstName: '',
			lastName: '',
			email: '',
			userId: '',
			imageUrl: undefined
		},
		jwt: undefined,
		profile: undefined,
		pushTokens: []
	} as IDefaultState,
	reducers: {
		setHasAcceptedPrivacyPolicy(state, hasAcceptedPrivacyPolicy: IDefaultState['hasAcceptedPrivacyPolicy']) {
			rematchLog('setHasAcceptedPrivacyPolicy');
			return {
				...state,
				hasAcceptedPrivacyPolicy
			};
		},
		setUserInfos(state, userInfos: IDefaultState['userInfos']) {
			rematchLog('setUserInfos');
			return {
				...state,
				userInfos
			};
		},
		setJwt(state, jwt: IDefaultState['jwt']) {
			rematchLog('setJWT');
			return {
				...state,
				jwt
			};
		},
		setProfile(state, profile: IDefaultState['profile']) {
			rematchLog('setProfile');
			return {
				...state,
				profile
			};
		},
		addPushToken(state, { deviceToken, spaceId }) {
			rematchLog('addPushTokens');
			const _tokens = state.pushTokens ? [...state.pushTokens] : [];
			const alreadyAdded = _tokens.find((e) => e.deviceToken === deviceToken && e.spaceId === spaceId);
			if (!alreadyAdded) {
				_tokens.push({
					deviceToken,
					spaceId
				});
			}

			return {
				...state,
				pushTokens: _tokens
			};
		},
		resetUser(state) {
			return {
				...state,
				jwt: undefined,
				profile: undefined,
				userInfos: {
					firstName: '',
					lastName: '',
					email: '',
					userId: v4(),
					imageUrl: undefined
				}
			};
		},
		clear(state) {
			rematchLog('clear auth');
			return {
				...state,
				pushTokens: [],
				hasAcceptedPrivacyPolicy: false
			};
		}
	},
	effects: (dispatch) => ({
		async registerWithEmailAndPassword(payload: IRegisterWithEmailAndPasswordParams, store) {
			const { user, shouldSendConfirmationEmail, fields, ticketcode, space, handleErrorLocally } = payload;

			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				let _shouldSendConfirmationEmail = shouldSendConfirmationEmail;
				if (activeSpace?.forceDoubleOptInForUsers) {
					_shouldSendConfirmationEmail = activeSpace?.forceDoubleOptInForUsers;
				}

				let tickets = {};

				let ticketResult;

				if (ticketcode && space) {
					ticketResult = await dispatch.ticket.fetchTicket({ space, ticketcode, redeem: true });
					if (ticketResult) {
						tickets[space.spaceId] = ticketResult;
					}
				}

				Object.keys(user).map((k) => (user[k] = typeof user[k] == 'string' ? user[k].trim() : user[k]));

				const avatarFields = fields.filter((e) => e.fieldType === 'avatar');
				const tempMedia = {};
				avatarFields.forEach((field) => {
					if (user[field.fieldName]) {
						tempMedia[field.fieldName] = user[field.fieldName];
					}
					delete user[field.fieldName];
				});

				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/auth/local/register`,
					method: 'POST',
					body: JSON.stringify({
						username: user.email,
						...user,
						shouldSendConfirmationEmail: _shouldSendConfirmationEmail,
						spaceId: activeSpace?.spaceId,
						tickets
					}),
					contentType: 'application/json'
				});

				if (res?.user) {
					dispatch.auth.setUserInfos({
						...store.auth.userInfos,
						firstName: res.user.firstName,
						lastName: res.user.lastName,
						email: res.user.email,
						imageUrl: res.user.avatar?.url
					});
					dispatch.auth.setJwt(res.jwt);
					dispatch.auth.setProfile(res.user);
					dispatch.socket.emitToMultiSpaceBackend({ event: 'login', data: { userId: res.userId } });

					dispatch.chat.startChatSync({});
					dispatch.meeting.startMyMeetingsSync({});
					dispatch.vote.startMyVoteAnswersSync({});

					if (avatarFields.length > 0) {
						const obj = {};
						for (const field of avatarFields) {
							if (
								tempMedia[field.fieldName] &&
								(typeof tempMedia[field.fieldName] === 'string' || tempMedia[field.fieldName].url.startsWith('file://'))
							) {
								let imageId;
								if (typeof tempMedia[field.fieldName] !== 'string') {
									imageId = tempMedia[field.fieldName].id;
								}

								const imageResponse = await dispatch.upload.uploadMedia({
									uri:
										typeof tempMedia[field.fieldName] === 'string'
											? tempMedia[field.fieldName]
											: tempMedia[field.fieldName].url,
									ref: 'user',
									refId: res.user.id,
									field: 'avatar',
									source: 'users-permissions',
									imageId
								});

								if (imageResponse && Array.isArray(imageResponse) && imageResponse.length > 0) {
									obj[field.fieldName] = imageResponse[0];
								}
							}
						}
						if (Object.keys(obj).length > 0) {
							await dispatch.auth.updateUser({ user: obj, noToast: true });
						}
					}

					dispatch.auth.startProfileSync({ load: true });

					if (!_shouldSendConfirmationEmail) {
						showToast('success', undefined, i18next.t('ProfileCreated'));
					}

					return res.user;
				}

				if (handleErrorLocally) {
					return res;
				}

				dispatch.alert.showAlert({ title: 'Error', message: translateStrapiError(res) });

				return undefined;
			} catch (error) {
				console.log('registerWithEmailAndPassword', error);
				return undefined;
			}
		},
		async confirmAccount(payload: IConfirmAccountParams, store) {
			try {
				const { code } = payload;

				const body = new FormData();
				body.append('data', JSON.stringify({ code }));
				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/auth/custom-email-confirmation`,
					method: 'POST',
					body
				});

				if (res?.id) {
					showToast('success', i18next.t('AccountConfirmed'));
					return true;
				}

				if (res.message === 'token.invalid') {
					showToast('error', i18next.t('error'), i18next.t('InvalidTokenError'));
					return false;
				}

				showToast('error', i18next.t('error'), translateStrapiError(res));
				return false;
			} catch (error) {
				console.log('error', error);
			}
		},
		async resendConfirmationLink(payload, store) {
			try {
				const headers = {
					Authorization: `Bearer ${store.auth.jwt}`
				};
				const res = await fetch(`${MULTISPACEURL}/auth/resend-email-confirmation`, { headers }).then((res) => res.json());
				if (res?.sent) {
					showToast('success', i18next.t('Email Sent'), i18next.t('Check Spam'));
					return true;
				}

				if (res?.message === 'already.confirmed') {
					showToast('success', i18next.t('AccountConfirmed'), translateStrapiError(res));
					return false;
				}

				showToast('error', i18next.t('error'), translateStrapiError(res));
			} catch (error) {
				console.log('resendConfirmationLink', error);
			}
		},
		async loginWithEmailAndPassword(payload: ILoginWithEmailAndPasswordParams, store) {
			try {
				const { email, password } = payload;

				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/auth/local`,
					method: 'POST',
					body: JSON.stringify({ identifier: email, password }),
					contentType: 'application/json'
				});

				if (res?.user) {
					dispatch.auth.setUserInfos({
						firstName: res.user.firstName,
						lastName: res.user.lastName,
						email: res.user.email,
						userId: res.user.userId,
						imageUrl: res.user.avatar?.url
					});

					dispatch.auth.setJwt(res.jwt);
					dispatch.auth.setProfile(res.user);
					dispatch.socket.emitToMultiSpaceBackend({ event: 'login', data: { userId: res.user.userId } });

					dispatch.chat.startChatSync({});
					dispatch.meeting.startMyMeetingsSync({});
					dispatch.vote.startMyVoteAnswersSync({});

					if (res.user.userSpaceHistory) {
						const history: ISpaceHistory[] = [];
						const spaceIds: string[] = [];

						for (const obj of res.user.userSpaceHistory.spaces) {
							if (!isEmptyString(obj.spaceId)) {
								spaceIds.push(obj.spaceId);
							}
						}

						const spaceIdsRes = await dispatch.space.findMultipleSpacesBySpaceIds({ spaceIds });

						if (spaceIdsRes) {
							Object.keys(spaceIdsRes).forEach((key) => {
								if (spaceIdsRes[key]) {
									const e = res.user.userSpaceHistory?.spaces.find((e) => e.spaceId === key);
									if (e && !spaceIdsRes[key].isDeleted) {
										history.push({
											date: e.date,
											space: spaceIdsRes[key]
										});
									}
								}
							});
						}

						history.sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1));
						dispatch.space.setSpaceHistory(history);
					}

					dispatch.auth.startProfileSync({ load: false });

					return res.user;
				}

				return res;
			} catch (error) {
				console.log('loginWithEmailAndPassword', error);
				return undefined;
			}
		},
		async forgotPassword(payload: IForgotPasswordParams, store) {
			try {
				const { email, spaceId } = payload;
				const activeSpace = dispatch.temp.getActiveSpace({});
				const activeSpaceId = dispatch.temp.getActiveSpaceId({});

				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/auth/forgot-password`,
					method: 'POST',
					body: JSON.stringify({ email, spaceId: spaceId ?? activeSpace?.spaceId ?? activeSpaceId }),
					contentType: 'application/json'
				});

				if (res?.ok) {
					return true;
				}

				// don't show an alert to prevent users from being able to check other users existence
				// dispatch.alert.showAlert({ title: 'Error', message: translateStrapiError(res) });

				return false;
			} catch (error) {
				console.log('registerWithEmailAndPassword', error);

				return false;
			}
		},
		async resetPassword(payload: IResetPasswordParams, store) {
			try {
				const { email, code, password, passwordConfirmation } = payload;

				const currentSpaceId = dispatch.temp.getActiveSpaceId({});

				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/auth/reset-password`,
					method: 'POST',
					body: JSON.stringify({
						email,
						code,
						password,
						passwordConfirmation,
						userInfos: store.auth.userInfos,
						spaceId: currentSpaceId
					}),
					contentType: 'application/json'
				});

				if (res?.user?.id) {
					return true;
				}

				dispatch.alert.showAlert({ title: 'Error', message: translateStrapiError(res) });

				return false;
			} catch (error) {
				console.log('resetPassword', error);
				return false;
			}
		},
		async updateUser(payload: IUpdateUserParams, store) {
			try {
				const { user, noToast, noSet } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				if (store.auth.profile) {
					if (user.avatar && (typeof user.avatar === 'string' || !user.avatar.url.startsWith('https'))) {
						const imageResponse = await dispatch.upload.uploadMedia({
							uri: typeof user.avatar === 'string' ? user.avatar : user.avatar.url,
							ref: 'user',
							refId: store.auth.profile.id,
							field: 'avatar',
							source: 'users-permissions'
						});

						if (imageResponse && Array.isArray(imageResponse) && imageResponse.length > 0) {
							payload.user.avatar = imageResponse[0];
						}
					}
					Object.keys(user).map((k) => (user[k] = typeof user[k] == 'string' ? user[k].trim() : user[k]));

					const res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/users/${store.auth.profile.id}`,
						method: 'PUT',
						body: JSON.stringify({ ...user, spaceId: activeSpace?.spaceId }),
						contentType: 'application/json'
					});

					if (res?.isOffline) {
						if (!noToast) {
							showToast('error', 'Error', i18next.t('NoNetworkConnection'));
						}
						return false;
					}

					if (res?.id) {
						if (!noToast) {
							showToast('success', undefined, i18next.t('ProfileUpdated'));
						}

						if (!noSet) {
							dispatch.auth.setProfile(res);
						}

						return true;
					}

					if (!noToast && __DEV__) {
						showToast('error', undefined, translateStrapiError(res));
					}
				}

				return false;
			} catch (error) {
				console.log('updateUser', error);
				return false;
			}
		},
		async loadProfile(payload, store) {
			try {
				if (store.auth.profile) {
					const profile = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/users/${store.auth.profile.id}`,
						method: 'GET'
					});

					if (profile?.id) {
						if (profile.isDeleted) {
							showToast('info', i18next.t('Account Deleted'), i18next.t('Account Deleted Subtitle'));
							await dispatch.auth.logout({});

							const activeSpace = dispatch.temp.getActiveSpace({});
							if (activeSpace) {
								await dispatch.space.leaveSpace({});

								if (RootNavigation.canGoBack()) {
									RootNavigation.popToTop();
								}

								RootNavigation.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								);
							}
							RootNavigation.navigate(ERoutes.SpaceSelect, {});
							return;
						}

						const diff = detailedDiff(store.auth.profile, profile);
						const changedProfileKeys = [
							...Object.keys(diff['added']),
							...Object.keys(diff['updated']),
							...Object.keys(diff['deleted'])
						];

						let changedScans = 0;
						if (profile.scannedUser) {
							for (const obj of profile.scannedUser) {
								const prev = store.auth.profile?.scannedUser?.find(
									(e) => obj.spaceId === e.spaceId && obj.userId === e.userId
								);

								if (!prev) {
									changedScans += 1;
									break;
								}
								const scanDiff = detailedDiff(stripNullValues(prev), stripNullValues(obj));

								const changedScanKeys = [
									...Object.keys(scanDiff['added']),
									...Object.keys(scanDiff['updated']),
									...Object.keys(scanDiff['deleted'])
								];

								if (changedScanKeys.length > 0) {
									changedScans += 1;
								}
							}
						}

						if ((changedProfileKeys.length > 0 && !changedProfileKeys.includes('scannedUser')) || changedScans > 0) {
							dispatch.auth.setProfile(profile);
						}

						const activeSpace = dispatch.temp.getActiveSpace({});
						if (profile.userSpaceHistory && activeSpace) {
							const found = profile.userSpaceHistory.spaces.find((s) => s.spaceId === activeSpace.spaceId);
							if (found?.isDeleted) {
								await dispatch.space.leaveSpace();
								RootNavigation.replace(ERoutes.SpaceSelect, {});
								showToast('error', i18next.t('youHaveBeenKickedFromSpace'));
							}
						}
						if (profile.userSpaceHistory && !activeSpace) {
							const history: ISpaceHistory[] = [];
							const spaceIds: string[] = [];

							for (const obj of profile.userSpaceHistory.spaces) {
								if (!isEmptyString(obj.spaceId)) {
									spaceIds.push(obj.spaceId);
								}
							}

							const spaceIdsRes = await dispatch.space.findMultipleSpacesBySpaceIds({ spaceIds });

							if (spaceIdsRes) {
								Object.keys(spaceIdsRes).forEach((key) => {
									if (spaceIdsRes[key] !== undefined) {
										const e = profile.userSpaceHistory?.spaces.find((e) => e.spaceId === key);
										if (e && !spaceIdsRes[key].isDeleted) {
											history.push({
												date: e.date,
												space: spaceIdsRes[key]
											});
										}
									}
								});
							}

							history.sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1));
							dispatch.space.setSpaceHistory(history);
						}
					}
				}
			} catch (error) {
				console.log('loadProfile', error);
			}
		},
		async startProfileSync(payload: IStartProfileSyncParams, store) {
			try {
				const { load } = payload;

				if (store.auth.profile) {
					if (load) {
						await dispatch.auth.loadProfile({});
					}

					await dispatch.socket.emitToMultiSpaceBackend({
						event: 'login',
						data: {
							userId: store.auth.profile.userId
						}
					});

					if (
						store.socket.multiSpaceSocket &&
						!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${store.auth.profile.userId}`)
					) {
						store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${store.auth.profile.userId}`, async (data: IUserProfile) => {
							if (data) {
								if (data.isDeleted) {
									showToast('info', i18next.t('Account Deleted'), i18next.t('Account Deleted Subtitle'));
									await dispatch.auth.logout({});
									const activeSpace = dispatch.temp.getActiveSpace({});
									if (activeSpace) {
										await dispatch.space.leaveSpace({});

										if (RootNavigation.canGoBack()) {
											RootNavigation.popToTop();
										}

										RootNavigation.replace(
											!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
										);
									}
									RootNavigation.navigate(ERoutes.SpaceSelect, {});
									return;
								}

								dispatch.auth.setProfile(data);
							}
						});
					}
				}
			} catch (error) {
				console.log('startProfileSync', error);
			}
		},
		async stopProfileSync(payload, store) {
			try {
				dispatch.socket.closeMultiSpaceEventListener({ event: store?.auth?.profile?.userId ?? 'profile' });
			} catch (error) {
				console.log('stopProfileSync', error);
			}
		},
		async storePushToken(payload: IStorePushTokenParams, store) {
			try {
				const { token, deviceToken } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				const myAttendee = store.attendee.attendees.find((e) => e.userId === store.auth.userInfos.userId);
				const addedInAttendeeProfile = myAttendee?.userPushTokens?.tokens.find((e) => e.deviceToken === deviceToken);

				if (activeSpace) {
					const pushTokenAddedLocally = store.auth.pushTokens?.find((e) => e.spaceId === activeSpace.spaceId) !== undefined;
					if (!pushTokenAddedLocally || !addedInAttendeeProfile) {
						const body = new FormData();
						body.append(
							'data',
							JSON.stringify({
								userInfos: store.auth.userInfos,
								pushToken: {
									token,
									deviceToken,
									os: Device.osName ?? '',
									model: Device.modelName ?? ''
								},
								spaceId: activeSpace.spaceId
							})
						);

						const res = await dispatch.request.anonymousRequest({
							url: `${MULTISPACEURL}/attendees/createorupdate`,
							method: 'POST',
							body
						});

						if (res?.id) {
							dispatch.auth.addPushToken({ deviceToken, spaceId: activeSpace.spaceId });
						}
					}
				}
			} catch (error) {
				console.log('storePushToken', error);
			}
		},
		async logout(payload, store) {
			const { noToast } = payload;

			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				await dispatch.chat.stopChatSync({});
				await dispatch.meeting.stopMeetingSync({});
				await dispatch.auth.stopProfileSync({});

				dispatch.chat.clear();
				dispatch.meeting.clear();
				dispatch.auth.resetUser();

				dispatch.space.setSpaceHistory([]);

				if (activeSpace?.registrationRequired) {
					dispatch.space.leaveSpaceOnRegistrationRequiredAndNoProfile({ noToast });
				}
			} catch (error) {
				console.log('logout', error);
			}
		},
		async updateLastRead(payload: IUpdateLastReadPayload, store) {
			const { types } = payload;
			const activeSpace = dispatch.temp.getActiveSpace({});

			if (store.auth.profile && activeSpace) {
				let lastRead = {};
				if (store.auth.profile.lastRead) {
					lastRead = { ...store.auth.profile.lastRead };
				}

				if (!lastRead[activeSpace.spaceId]) {
					lastRead[activeSpace.spaceId] = {};
				}

				let shouldSend = false;

				types.forEach((type) => {
					if (activeSpace && store.content.content && store.content.content[type]) {
						const items = store.content.content[type].filter((e, index) => {
							if (e.spaceId !== activeSpace?.spaceId || e.isDeleted) {
								return false;
							}
							if (
								activeSpace?.admins?.find((e) => e.id === store.auth.profile?.id) ||
								activeSpace?.moderators?.find((e) => e.id === store.auth.profile?.id)
							) {
								return true;
							}
							if (
								type === 'newsitems' &&
								(activeSpace?.allowUserGeneratedContentInFeed !== 'moderated' ||
									e.userId === store.auth.userInfos.userId ||
									e.isVisible)
							) {
								return true;
							}
							return true;
						});
						if (items.length > 0) {
							items.sort((a, b) => moment(a.updated_at).isAfter(b.updated_at)) ? -1 : 1;
							items.forEach((i) => {
								if (
									activeSpace &&
									(!lastRead[activeSpace.spaceId][type] || lastRead[activeSpace.spaceId][type] < i.updated_at)
								) {
									shouldSend = true;
									lastRead[activeSpace.spaceId][type] = i.updated_at;
								}
							});
						}
					}
				});
				if (shouldSend) {
					await dispatch.auth.updateUser({ user: { id: store.auth.profile.id, lastRead }, noToast: true });
				}
			}
		},
		async toggleFavorite(payload: IToggleFavoritePayload, store) {
			try {
				if (store.auth.profile) {
					const { type, id } = payload;

					let isNowFavorite = false;

					const favorites = store.auth.profile.favorites ? { ...store.auth.profile.favorites } : {};

					if (!favorites[type]) {
						favorites[type] = {};
					}
					if (favorites[type][id]) {
						delete favorites[type][id];
					} else {
						favorites[type][id] = true;
						isNowFavorite = true;
					}

					await dispatch.auth.updateUser({ user: { favorites }, noToast: true, noSet: true });

					return isNowFavorite;
				}
			} catch (error) {
				console.log('toggleFavorite', error);
			}
		},
		async markMyselfAsOnline(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (store.auth.profile) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace?.spaceId,
							userInfos: store.auth.userInfos
						})
					);
					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/users/markmyselfasonline`,
						body
					});

					if (res && Array.isArray(res)) {
						dispatch.content.setOnlineUsers(res);
					}
				}
			} catch (error) {
				console.log('markMySelfAsOnline', error);
			}
		},
		async removeUserFromSpace(payload: IRemoveUserPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.auth.jwt && store.auth.profile) {
					const { userId, shouldDeleteSpace } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							userId,
							shouldDeleteSpace,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/users/removeuserfromspace`,
						body
					});
					if (res?.success) {
						if (userId === store.auth.profile?.userId) {
							let history = [...store.space.spaceHistory];

							history = history.filter((e) => e.space.spaceId !== activeSpace.spaceId);

							dispatch.space.setSpaceHistory(history);
						}

						if (shouldDeleteSpace) {
							await dispatch.space.updateSpace({
								data: {
									title: activeSpace.title,
									id: activeSpace.id,
									spaceId: activeSpace.spaceId,
									isDeleted: true
								}
							});
						}

						showToast('success', i18next.t('DataDeleted'));

						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('removeUserFromSpace', error);
				return false;
			}
		},
		async deleteUserAccount(payload: IRemoveUserPayload, store) {
			try {
				if (store.auth.jwt) {
					const { userId, shouldDeleteSpace } = payload;

					const activeSpace = dispatch.temp.getActiveSpace({});

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							userId,
							spaceId: activeSpace?.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/users/deleteuseraccount`,
						body
					});

					if (res?.success) {
						if (userId === store.auth.profile?.userId && activeSpace) {
							let history = [...store.space.spaceHistory];

							history = history.filter((e) => e.space.spaceId !== activeSpace.spaceId);

							dispatch.space.setSpaceHistory(history);
						}

						if (activeSpace && shouldDeleteSpace) {
							await dispatch.space.updateSpace({
								data: {
									title: activeSpace.title,
									id: activeSpace.id,
									spaceId: activeSpace.spaceId,
									isDeleted: true
								}
							});
						}

						showToast('success', i18next.t('Account Deleted'));

						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return false;
			} catch (error) {
				console.log('deleteUserAccount', error);
				return false;
			}
		},
		async checkIfUserExists(payload: { email: string; spaceId: string }, store) {
			try {
				const { email, spaceId } = payload;
				if (email) {
					const body = new FormData();
					body.append('data', JSON.stringify({ email, spaceId }));
					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/auth/existsbymail`,
						method: 'POST',
						body
					});

					if (res.status === 200) {
						return { exist: true, alreadyIn: res.alreadyIn };
					}
					return { exist: false };
				}
			} catch (error) {}
		}
	})
});
