import React, { useEffect, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { Keyboard, ScrollView } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { IUpdateMediaItemFormValues, IUpdateMediaItemFormErrors, getCreateMediaItemInitialFormValues, IMediaItem } from 'config/interfaces';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import i18next, { t } from 'i18next';
import { MediaItemForm } from 'components/Forms';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MediaItemEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MediaItemEdit>;
type RouteParams = StackParamList[ERoutes.MediaItemEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mediaitemedit';

export const MediaItemEditScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('mediaitem');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IUpdateMediaItemFormValues>({ ...getCreateMediaItemInitialFormValues() });
	const [formErrors, setFormErrors] = useState<IUpdateMediaItemFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateMediaItem = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMediaItem);

	useEffect(() => {
		if (activeSpace && content.mediaitems && route.params.id) {
			const values = content.mediaitems.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);

			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [activeSpace, content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateMediaItem(),
			isLoading: isLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [activeSpace, formValues, isLoading, formErrors, isFormLocked]);

	useEffect(() => {
		if (Object.keys(formErrors) && Object.keys(formErrors).length > 0) {
			navigation.setOptions({
				isDisabled: false
			});
		}
	}, [formValues]);

	const _applyFormValues = (values: IMediaItem) => {
		setFormValues({
			...values,
			expos: values.expos ? values.expos.map((expo) => expo.id) : [],
			speakers: values.speakers ? values.speakers.map((speaker) => speaker.id) : [],
			streams: values.streams ? values.streams.map((stream) => stream.id) : []
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _updateMediaItem = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors, getContentTypeFields());
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = content.mediaitems.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await updateMediaItem({ mediaitem: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="mediaitems">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MediaItemForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const MediaItemEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Media Items')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
