import React, { useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import { IFormBase } from 'config/interfaces';
import { FormHint } from './FormHint';
import { FormError } from './FormError';
import { FormElementBottomMarginSmall } from './constants';
import { EDefaultIconSet, isEmptyString, normalizeFontSize, openURL } from 'helper';
import { ChildButton, RoundButton } from 'components/Button';
import { BUTTONBORDERWIDTH } from 'components/Button/constants';
import { Icon } from 'components/Icon';
import { hsBorderRadius } from 'config/styleConstants';
import { Markdown } from 'components/Markdown/Markdown';

interface IFormCheckboxComponent extends IFormBase {
	testID: string;
	value?: boolean;
	onPress?: (() => void) | ((val) => void);
	url?: string;
	style?: ViewStyle;
	innerContainerStyle?: ViewStyle;
	markdownText?: string;
	thickerBorder?: boolean;
	largerDesign?: boolean;
}

export const FormCheckbox = (props: IFormCheckboxComponent) => {
	const {
		testID,
		label,
		hint,
		error,
		value,
		onPress,
		isDisabled,
		isRequired,
		url,
		style,
		innerContainerStyle,
		markdownText,
		thickerBorder,
		largerDesign
	} = props;
	const { theme } = useTheme();
	const [isMultiline, setIsMultiline] = useState<boolean>(false);
	const SIZE = 30;

	const _renderText = () => {
		if (!isEmptyString(markdownText)) {
			return <Markdown markdown={markdownText} containerStyle={{ flex: 1, marginTop: -13 }} />;
		}
		if (!isEmptyString(label)) {
			return (
				<View style={{ flexDirection: 'row', flexShrink: 1 }}>
					<Text
						largerText={largerDesign}
						onTextLayout={(e) => {
							if (e?.nativeEvent?.lines?.length > 1) {
								setIsMultiline(true);
							}
						}}
						testID={`${testID}_label`}
						style={{
							flexWrap: 'wrap',
							fontSize: largerDesign ? 22 : 15
						}}
						adjustsFontSizeToFit
					>
						{label}
					</Text>
					{isRequired && <Text style={{ color: 'red', marginLeft: 2, marginTop: -3, fontSize: normalizeFontSize(14) }}>*</Text>}
				</View>
			);
		}
	};

	return (
		<View style={{ marginBottom: style?.marginBottom ?? FormElementBottomMarginSmall, ...style }}>
			<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: hint || error ? 5 : 0, ...innerContainerStyle }}>
				<ChildButton testID={testID} isDisabled={isDisabled || !onPress} onPress={onPress}>
					<View
						style={{
							width: SIZE,
							height: SIZE,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: value ? 0 : BUTTONBORDERWIDTH,
							borderRadius: hsBorderRadius,
							borderColor: value ? theme.primary : thickerBorder ? theme.formgray : theme.lightgray,
							marginRight: 10,
							backgroundColor: isDisabled
								? theme.lightgray
								: value
								? theme.primary
								: theme.contentBackgroundColor ?? theme.background
						}}
					>
						{value && <Icon color={theme.primaryContrast} name={EDefaultIconSet.Save} />}
					</View>
				</ChildButton>
				{_renderText()}
				{!isEmptyString(url) && (
					<View style={{}}>
						<RoundButton
							isOutline
							icon={EDefaultIconSet.ExternalUrl}
							testID={`${testID}_button_url`}
							onPress={() => {
								if (url) {
									openURL(url);
								}
							}}
							isDisabled={isDisabled}
							alignSelf={'flex-start'}
						/>
					</View>
				)}
			</View>
			<FormHint testID={testID} hint={hint} />
			<FormError testID={testID} error={error} />
		</View>
	);
};
