import React from 'react';
import { Dimensions, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useHeaderHeight } from '@react-navigation/elements';
import { detailedDiff } from 'deep-object-diff';

import { IMedia } from 'config/interfaces';
import { isEmptyString, IS_IOS, IS_WEB } from 'helper';
import { Image } from './Image';
import { useQuery } from 'hooks/useQuery';

const gradientBlueLightblue = require('../../assets/images/gradient/gradientBlueLightblue.png');
const gradientLightblueOrange = require('../../assets/images/gradient/gradientLightblueOrange.png');
const gradientMagentaBlue = require('../../assets/images/gradient/gradientMagentaBlue.png');
const gradientOrangeMagenta = require('../../assets/images/gradient/gradientOrangeMagenta.png');

interface IBackgroundimage {
	backgroundImage?: IMedia;
	bgImage?: IMedia;
	bgImageName?: string;
}

const Backgroundimage = (props: IBackgroundimage) => {
	const { backgroundImage, bgImage, bgImageName } = props;

	const { isTabletOrMobile } = useQuery();
	const { top } = useSafeAreaInsets();
	const screenHeight = Dimensions.get('window').height;
	const headerHeight = useHeaderHeight();

	let src = '';

	if (backgroundImage?.url || bgImage?.url) {
		src = IS_WEB && !isTabletOrMobile ? backgroundImage?.url ?? bgImage!.url : backgroundImage?.formats?.medium?.url ?? '';
	} else {
		switch (bgImageName) {
			case 'gradientBlueLightblue':
				src = gradientBlueLightblue;
				break;
			case 'gradientLightblueOrange':
				src = gradientLightblueOrange;
				break;
			case 'gradientMagentaBlue':
				src = gradientMagentaBlue;
				break;
			case 'gradientOrangeMagenta':
				src = gradientOrangeMagenta;
				break;
			default:
				src = '';
				break;
		}
	}
	if (!isEmptyString(src)) {
		return (
			<View style={{ position: 'absolute', width: '100%', height: IS_IOS ? screenHeight - headerHeight - top : '100%' }}>
				<Image style={{ width: '100%', height: '100%' }} contentFit="cover" url={src} hideLoading />
			</View>
		);
	}

	return null;
};

const areEqual = (prevProps: IBackgroundimage, nextProps: IBackgroundimage) => {
	/*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */

	const propDiff = detailedDiff(prevProps, nextProps);

	const addedKeys = Object.keys(propDiff['added']);
	const updatedKeys = Object.keys(propDiff['updated']);
	const removedKeys = Object.keys(propDiff['deleted']);

	return [...addedKeys, ...updatedKeys, ...removedKeys].length === 0;
};

export default React.memo(Backgroundimage, areEqual);
