import React from 'react';
import { TextStyle } from 'react-native';

import { H1 } from './H1';
import { hsTextBottomMarginSmall } from 'config/styleConstants';

interface ICardSeparationHeader {
	label: string;
	style?: TextStyle;
}

export const CardSeparationHeader = (props: ICardSeparationHeader) => {
	const { label, style } = props;

	return <H1 style={[{ marginBottom: hsTextBottomMarginSmall }, style]}>{label}</H1>;
};
