import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { IVote } from 'config/interfaces/vote';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { VoteAnalytics } from 'components/Vote/VoteAnalytics';
import { ScrollView } from 'react-native';
import { useQuery } from 'hooks/useQuery';
import { H1 } from 'components/Text';
import i18next from 'i18next';
import { hsTopScreenPadding } from 'config/styleConstants';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.VoteAnalytics>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.VoteAnalytics>;
type RouteParams = StackParamList[ERoutes.VoteAnalytics];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const VoteAnalyticsScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();

	const [vote, setVote] = useState<IVote | undefined>(undefined);

	const votes = useSelector((store: IRootState) => store.vote.votes);

	useEffect(() => {
		const vote = votes.find((e) => e.id === route.params.id);
		setVote(vote);
	}, [votes]);

	// TODO Analytics
	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<VoteAnalytics vote={vote} />
				<H1>This Screen is not finished yet</H1>
			</ScrollView>
		</ScreenContainer>
	);
};

export const VoteAnalyticsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Analytics')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
