import React from 'react';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormValues, IEditVoteFormErrors } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';
import { useTranslation } from 'react-i18next';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';

interface IVoteTextForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val: ICreateVoteFormValues | IEditVoteFormValues) => void;
	isLoading?: boolean;
}

export const VoteTextForm = (props: IVoteTextForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	return (
		<CommonVoteForm
			testIdPrefix={testIdPrefix}
			values={values}
			errors={errors}
			onChange={onChange}
			isLoading={isLoading}
			additionalFields={[
				<FormMultiSwitch
					testID={`${testIdPrefix}_multiswitch_ismoderated`}
					label={t('IsModerated')}
					hint={t('IsModeratedHint')}
					error={errors.isModerated}
					value={values.isModerated ? 'true' : 'false'}
					options={[
						{
							key: 'true',
							label: t('Moderated')
						},
						{
							key: 'false',
							label: t('Not moderated')
						}
					]}
					onChange={(val) => onChange({ ...values, isModerated: val === 'true' ? true : false })}
					isDisabled={isLoading}
				/>
				// <FormMultiSwitch
				// 	testID={`${testIdPrefix}_multiswitch_allowmedia`}
				// 	label={t('allowMedia')}
				// 	hint={t('allowMediaHint')}
				// 	error={errors.allowMedia}
				// 	value={values.allowMedia ? 'true' : 'false'}
				// 	options={[
				// 		{
				// 			key: 'true',
				// 			label: t('allowMediaTrue')
				// 		},
				// 		{
				// 			key: 'false',
				// 			label: t('allowMediaFalse')
				// 		}
				// 	]}
				// 	onChange={(val) => onChange({ ...values, allowMedia: val === 'true' ? true : false })}
				// 	isDisabled={isLoading}
				// />
			]}
		/>
	);
};
