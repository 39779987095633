import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { ENVIRONMENT } from 'config/envConstants';
import {
	attendeeUpdateFields,
	IAttendee,
	ICreateSupportRequestPayload,
	ILauncherSpace,
	ISupportRequest,
	IUpdateAttendeePayload,
	IUpdateSupportRequestPayload
} from 'config/interfaces';
import { getAccessRightsForSpace, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import moment from 'moment';
import { RootModel } from './index';
import { detailedDiff } from 'deep-object-diff';
import _ from 'lodash';

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

type IDefaultState = {
	attendees: IAttendee[];
	lastAttendeeRead: Record<string, string>;
	supportRequests: ISupportRequest[];
	lastSupportRequestRead: Record<string, string>;
};

export const attendee = createModel<RootModel>()({
	state: {
		attendees: [],
		lastAttendeeRead: {},
		supportRequests: [],
		lastSupportRequestRead: {}
	} as IDefaultState,
	reducers: {
		addAttendees(state, { newAttendees, spaceId, load }: { newAttendees: IDefaultState['attendees']; spaceId: string; load: boolean }) {
			rematchLog('addAttendees');
			const _newAttendees = state.attendees ? [...state.attendees] : [];
			let lastRead = state.lastAttendeeRead ? { ...state.lastAttendeeRead } : {};
			newAttendees.forEach((attendee) => {
				const idx = _newAttendees.findIndex((e) => e.userId === attendee.userId);
				if (idx === -1) {
					_newAttendees.push(attendee);
				} else {
					_newAttendees[idx] = attendee;
				}
				if (load && (!lastRead[spaceId] || attendee.updated_at > lastRead[spaceId])) {
					lastRead[spaceId] = attendee.updated_at;
				}
			});

			return {
				...state,
				attendees: _newAttendees,
				lastAttendeeRead: lastRead
			};
		},
		setSupportReqeusts(state, { load, supportRequests }: { load: boolean; supportRequests: IDefaultState['supportRequests'] }) {
			rematchLog('setSupportRequests');
			let _supportRequests = [...state.supportRequests];
			let _lastRead = state.lastSupportRequestRead ? { ...state.lastSupportRequestRead } : {};

			supportRequests.forEach((sRequest) => {
				if (sRequest.isDeleted) {
					_supportRequests = _supportRequests.filter((e) => e.id !== sRequest.id);
				} else {
					const idx = _supportRequests.findIndex((e) => e.id === sRequest.id);
					if (idx === -1) {
						_supportRequests.push(sRequest);
					} else {
						_supportRequests[idx] = sRequest;
					}
				}

				if ((load && !_lastRead[sRequest.spaceId]) || sRequest.updated_at > _lastRead[sRequest.spaceId]) {
					_lastRead[sRequest.spaceId] = sRequest.updated_at;
				}
			});

			return {
				...state,
				supportRequests: _supportRequests.sort((a, b) => (moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 1)),
				lastSupportRequestRead: _lastRead
			};
		},
		clear(state) {
			rematchLog('attendee clear');
			return {
				attendees: [],
				lastAttendeeRead: {},
				supportRequests: [],
				lastSupportRequestRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async startAttendeeSync(payload: { space?: ILauncherSpace }, store) {
			try {
				const { space } = payload;

				const currentSpace = space ?? dispatch.temp.getActiveSpace({});

				if (
					currentSpace &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${currentSpace.spaceId}_attendees`)
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${currentSpace.spaceId}_attendees`, (data) => {
						if (currentSpace?.spaceId) {
							let _items = Array.isArray(data) ? data : data.items;
							const _load = !Array.isArray(data) && data.load;

							_items = _items.filter((e) => {
								if (e.userId === store.auth.userInfos.userId) {
									return true;
								}

								const found = store.attendee.attendees.find((e2) => e2.userId === e.userId);
								if (found) {
									const diff = detailedDiff(found, e);
									const addedKeys = Object.keys(diff['added']);
									const updatedKeys = Object.keys(diff['updated']);
									const removedKeys = Object.keys(diff['deleted']);

									const allKeys = [...addedKeys, ...updatedKeys, ...removedKeys].filter((key) =>
										attendeeUpdateFields.includes(key)
									);

									if (allKeys.length === 1 && (allKeys[0] === 'spaceHistory' || allKeys[0] === 'userSpaceHistory')) {
										const oldSpaceHistoryIds =
											found.userSpaceHistory?.spaces.map((space) => {
												return { spaceId: space.spaceId, isDeleted: space.isDeleted };
											}) ?? [];
										const newSpaceHistoryIds = e.userSpaceHistory?.spaces.map((space) => {
											return { spaceId: space.spaceId, isDeleted: space.isDeleted };
										});

										const notInNew = _.difference(oldSpaceHistoryIds, newSpaceHistoryIds);
										const notInOld = _.difference(newSpaceHistoryIds, oldSpaceHistoryIds);
										if (notInNew.length === 0 && notInOld.length === 0) {
											return false;
										}
									}
								}

								return true;
							});

							if (_load) {
								contentLoadLog(4, 'attendees received');

								dispatch.temp.setWaitingForSocketResponse({ key: 'attendees', value: false });
								if (!store.temp.hasLoadedData?.attendees) {
									dispatch.temp.setHasLoadedData('attendees');
								}
							}

							if (_items.length > 0) {
								dispatch.attendee.addAttendees({
									spaceId: currentSpace.spaceId,
									newAttendees: _items,
									load: _load
								});
							}
						}
					});
				}
			} catch (error) {
				console.log('startAttendeeSync', error);
			}
		},
		async loadAttendeeDelta(payload: { space?: ILauncherSpace; ignoreInterval?: boolean }, store) {
			try {
				const { space, ignoreInterval } = payload;
				const now = moment().unix();

				const activeSpace = space ?? dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					if (
						ignoreInterval ||
						!store.temp.lastAttendeeLoad ||
						!activeSpace.reloadIntervalAttendee ||
						now - store.temp.lastAttendeeLoad > activeSpace.reloadIntervalAttendee * 60
					) {
						const _lastRead = store.attendee.lastAttendeeRead[activeSpace.spaceId]
							? store.attendee.lastAttendeeRead[activeSpace.spaceId]
							: ZEROHOUR;

						let _strategy = DATA_LOAD_STRATEGY;

						if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
							_strategy = EDataLoadStrategy.Default;
						}

						if (!store.temp.hasLoadedData || store.temp.hasLoadedData.attendees) {
							contentLoadLog(3, 'attendees already loaded. skipping');
							return;
						}

						if (store.temp.waitingForSocketResponse?.attendees) {
							contentLoadLog(3, 'attendee load already in progress. skipping');
							return;
						}

						switch (_strategy) {
							case EDataLoadStrategy.Offline:
								break;
							case EDataLoadStrategy.Socket:
								contentLoadLog(2, 'loading attendees');
								dispatch.temp.setWaitingForSocketResponse({ key: 'attendees', value: true });

								dispatch.socket.emitToMultiSpaceBackend({
									event: 'loadContent',
									data: {
										type: 'attendees',
										userId: store.auth.userInfos.userId,
										spaceId: activeSpace.spaceId,
										lastRead: _lastRead
									}
								});
								dispatch.temp.setLastAttendeeLoad(moment().unix());

								break;
							case EDataLoadStrategy.Default:
							default:
								const body = new FormData();
								body.append(
									'data',
									JSON.stringify({
										spaceId: activeSpace.spaceId,
										lastRead: _lastRead
									})
								);
								dispatch.temp.setWaitingForSocketResponse({ key: 'attendees', value: true });

								const res = await dispatch.request.anonymousRequest({
									method: 'POST',
									url: `${MULTISPACEURL}/attendees`,
									body
								});

								dispatch.temp.setWaitingForSocketResponse({ key: 'attendees', value: false });

								if (res && Array.isArray(res)) {
									dispatch.temp.setHasLoadedData('attendees');

									const _items = res.filter((e) => {
										if (e.userId === store.auth.userInfos.userId) {
											return true;
										}

										const found = store.attendee.attendees.find((e2) => e2.userId === e.userId);
										if (found) {
											const diff = detailedDiff(found, e);
											const addedKeys = Object.keys(diff['added']);
											const updatedKeys = Object.keys(diff['updated']);
											const removedKeys = Object.keys(diff['deleted']);

											const allKeys = [...addedKeys, ...updatedKeys, ...removedKeys].filter((key) =>
												attendeeUpdateFields.includes(key)
											);

											if (
												allKeys.length === 1 &&
												(allKeys[0] === 'spaceHistory' || allKeys[0] === 'userSpaceHistory')
											) {
												const oldSpaceHistoryIds =
													found.userSpaceHistory?.spaces.map((space) => {
														return { spaceId: space.spaceId, isDeleted: space.isDeleted };
													}) ?? [];
												const newSpaceHistoryIds = e.userSpaceHistory?.spaces.map((space) => {
													return { spaceId: space.spaceId, isDeleted: space.isDeleted };
												});

												const notInNew = _.difference(oldSpaceHistoryIds, newSpaceHistoryIds);
												const notInOld = _.difference(newSpaceHistoryIds, oldSpaceHistoryIds);
												if (notInNew.length === 0 && notInOld.length === 0) {
													return false;
												}
											}
										}

										return true;
									});

									if (_items.length > 0) {
										dispatch.attendee.addAttendees({
											spaceId: activeSpace?.spaceId,
											newAttendees: _items,
											load: true
										});
									}
								}
								break;
						}
					}
				}
			} catch (error) {
				console.log('loadAttendeeDelta', error);
			}
		},
		async visitAttendeeProfile(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.auth.profile) {
					const { attendeeId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							attendeeId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					await dispatch.request.authenticatedRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/users/visit`,
						body
					});
				}
			} catch (error) {
				console.log('visitAttendeeProfile', error);
			}
		},
		async createSupportRequest(payload: ICreateSupportRequestPayload, store) {
			try {
				const { space, subject, text, email, deviceInfo, name, userId, ticketcode } = payload;
				if (space) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: space.spaceId,
							subject,
							text,
							email,
							name,
							ticketcode,
							userId,
							deviceInfo
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/attendeesupportrequests`,
						body
					});

					if (res?.id) {
						return true;
					}
				}
				return false;
			} catch (error) {
				console.log('createSupportRequest', error);
				return false;
			}
		},
		async updateSupportRequest(payload: IUpdateSupportRequestPayload, store) {
			try {
				const { id, space, answer, status, internalComment, isSend, closedById } = payload;
				if (space) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							userInfos: store.auth.userInfos,
							spaceId: space.spaceId,
							status,
							internalComment,
							answer,
							isSend,
							closedById
						})
					);

					await dispatch.request.anonymousRequest({
						method: 'PUT',
						url: `${MULTISPACEURL}/attendeesupportrequests/${id}`,
						body
					});
				}
			} catch (error) {
				console.log('updateSupportRequest', error);
			}
		},
		async startAttendeeSupportRequestsSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				const { isAdmin, isModerator } = getAccessRightsForSpace(store.auth.userInfos.userId, activeSpace);

				if (
					activeSpace &&
					(isAdmin || isModerator) &&
					store.socket.multiSpaceSocket &&
					!store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_${activeSpace.spaceId}_attendeesupportrequests`)
				) {
					store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_${activeSpace.spaceId}_attendeesupportrequests`, (data) => {
						const _load = !Array.isArray(data) && data.load;
						const _items = Array.isArray(data) ? data : data.items;

						if (_load) {
							contentLoadLog(4, 'attendee support requests received');

							dispatch.temp.setWaitingForSocketResponse({ key: 'attendeesupportrequests', value: false });
							if (!store.temp.hasLoadedData?.attendeesupportrequests) {
								dispatch.temp.setHasLoadedData('attendeesupportrequests');
							}
						}

						if (_items.length > 0) {
							dispatch.attendee.setSupportReqeusts({ load: _load, supportRequests: _items });
						}
					});
				}
			} catch (error) {
				errorLog('startAttendeeSupportRequestsSync', error);
			}
		},
		async loadAttendeeSupportRequests(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && activeSpace.admins?.find((e) => e.userId === store.auth.profile?.userId)) {
					const _lastRead =
						store.attendee.lastSupportRequestRead && store.attendee.lastSupportRequestRead[activeSpace.spaceId]
							? store.attendee.lastSupportRequestRead[activeSpace.spaceId]
							: ZEROHOUR;

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					debugLog('support request strategy', _strategy);
					switch (_strategy) {
						case EDataLoadStrategy.Socket:
							if (store.temp.hasLoadedData?.attendeesupportrequests) {
								contentLoadLog(3, 'attendee support requests already loaded. skipping');
								return;
							}

							if (store.temp.waitingForSocketResponse?.attendeesupportrequests) {
								contentLoadLog(3, 'attendee support requests load already in progress. skipping');
								return;
							}

							contentLoadLog(2, 'loading attendee support requests');
							dispatch.temp.setWaitingForSocketResponse({ key: 'attendeesupportrequests', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'attendeesupportrequests',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									spaceId: activeSpace.spaceId,
									userInfos: store.auth.userInfos
								})
							);

							dispatch.temp.setWaitingForSocketResponse({ key: 'attendeesupportrequests', value: true });
							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/attendeesupportrequests/find?spaceId=${activeSpace.spaceId}&isDeleted=false&updated_at_gt=${_lastRead}&_limit=-1`,
								method: 'POST',
								body
							});
							dispatch.temp.setWaitingForSocketResponse({ key: 'attendeesupportrequests', value: false });

							if (res && Array.isArray(res) && res.length > 0) {
								dispatch.attendee.setSupportReqeusts({
									supportRequests: res,
									load: true
								});
							}
							break;
					}
				}
			} catch (error) {
				errorLog('loadAttendeeSupportRequests', error);
				dispatch.temp.setWaitingForSocketResponse({ key: 'attendeesupportrequests', value: false });
			}
		},
		async updateAttendee(payload: IUpdateAttendeePayload, store) {
			try {
				const { attendee, noToast } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				Object.keys(attendee).map((k) => (attendee[k] = typeof attendee[k] == 'string' ? attendee[k].trim() : attendee[k]));
				const userIdRes = await dispatch.request.authenticatedRequest({
					url: `${MULTISPACEURL}/users/getuserid/${attendee.userId}`,
					method: 'GET'
				});

				if (userIdRes?.status === 200 && userIdRes.id) {
					const res = await dispatch.request.authenticatedRequest({
						url: `${MULTISPACEURL}/users/${userIdRes.id}`,
						method: 'PUT',
						body: JSON.stringify({ ...attendee, spaceId: activeSpace?.spaceId }),
						contentType: 'application/json'
					});

					if (res?.id) {
						if (!noToast) {
							showToast('success', undefined, i18next.t('AttendeeUpdated'));
						}

						return true;
					}

					if (!noToast && __DEV__) {
						showToast('error', undefined, translateStrapiError(res));
					}
				}
			} catch (error) {
				console.log('updateAttendee', error);
			}
		}
	})
});
