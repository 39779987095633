import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { IMeetingTable } from 'config/interfaces';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';

export const MEETINGTABLELISTITEMENTRYHEIGHT = 80;

export interface IMeetingTableListItem {
	testID: string;
	item: IMeetingTable;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
}

export const MeetingTableListItem = (props: IMeetingTableListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, isLoading } = props;
	const { theme } = useTheme();

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isLoading}
						/>
					)}
				</View>
			);
		}

		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	return (
		<View
			style={{ height: MEETINGTABLELISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}

			<View style={{ flex: 1, paddingLeft: 10, justifyContent: 'flex-start' }}>
				<Text bold style={{ marginBottom: 2 }}>
					{item.title}
				</Text>
				{!isEmptyString(item.subtitle) && (
					<Text italic style={{ fontSize: 12 }}>
						{item.subtitle}
					</Text>
				)}
			</View>
			<View>{_renderActions()}</View>
		</View>
	);
};
