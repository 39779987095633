import React, { ReactNode, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, getDefaultAspectRatioStyle, getTopRadiusStyle, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { IMediaItem } from 'config/interfaces';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import { Image, PlaceholderGradient } from 'components/Image';
import { hsActionButtonMargin, hsBorderRadius } from 'config/styleConstants';
import { StreamPlayer } from 'components/Stream';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { ListItemMatchCount } from 'components/Matching';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

export interface IMediaItemListItem {
	testID: string;
	item: IMediaItem;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	onPin?: () => void;
	isLoading?: boolean;
	containerStyle?: ViewStyle;
}

export const MediaItemListItem = (props: IMediaItemListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, onPin, isLoading, containerStyle } = props;
	const { theme } = useTheme();
	const { trackAction } = useTracker();
	const { activeSpace } = useSpace();

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [shouldPause, setShouldPause] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const _renderAction = () => {
		const elements: ReactNode[] = [];

		if (onEdit) {
			elements.push(
				<View key={`mediaitem_${item.id}_edit`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						isOutline
						testID="button_edit"
						key="button_edit"
						isLoading={isLoading}
						onPress={onEdit}
						icon={EDefaultIconSet.Edit}
						size="sm"
					/>
				</View>
			);
		}

		if (onPin) {
			elements.push(
				<View key={`mediaitem_${item.id}_wrap_vpin`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						key={`mediaitem_list_item_${item.id}_pin`}
						testID={`mediaitem_list_item_${item.id}_pin`}
						onPress={onPin}
						isLoading={isLoading}
						isOutline={!item.isPinned}
						icon={EDefaultIconSet.Pin}
						size="sm"
					/>
				</View>
			);
		}

		if (elements.length > 0 || onSelect || onDelete) {
			return (
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 10, paddingRight: 0 }}>
					<View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
						{onSelect && (
							<FormCheckbox
								testID={`${testID}_checkbox_select`}
								value={isSelected}
								onPress={() => onSelect()}
								style={{ marginBottom: 0, marginTop: 6, marginRight: hsActionButtonMargin }}
							/>
						)}
						{onDelete && (
							<RoundButton
								isOutline
								testID="button_delete"
								onPress={onDelete}
								isLoading={isLoading}
								icon={EDefaultIconSet.Delete}
								color={theme.danger}
								size="sm"
							/>
						)}
					</View>

					<View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>{elements}</View>
				</View>
			);
		}
	};

	if (!item) {
		return null;
	}

	const _renderPin = () => {
		if (item.isPinned) {
			return (
				<View style={{ marginLeft: 10 }}>
					<Icon name={EDefaultIconSet.Pin} />
				</View>
			);
		}

		return null;
	};

	const _getURL = () => {
		switch (item?.mediaType) {
			case 'contentflow':
				if (!isEmptyString(item?.contentflowUrl)) {
					return item?.contentflowUrl;
				}
			case 'iFrame':
				if (!isEmptyString(item?.iFrameUrl)) {
					return item?.iFrameUrl;
				}
			case 'mediaLibrary':
			case 'upload':
				if (!isEmptyString(item?.media?.url) && item.media?.mime?.includes('video')) {
					return item?.media?.url;
				}
			case 'vimeo':
				if (!isEmptyString(item?.vimeoUrl)) {
					return item?.vimeoUrl;
				}
			case 'youtube':
				if (!isEmptyString(item?.youTubeUrl)) {
					return item?.youTubeUrl;
				}
			default:
				return undefined;
		}
	};

	const _getPreviewImage = () => {
		if (item?.previewImage) {
			return item.previewImage;
		}
		if (item?.expos && item?.expos?.length > 0 && item?.expos[0] && item.expos[0].id) {
			const found = content?.expos?.find((e) => e.id === item.expos[0].id);
			if (found?.logo) {
				return found.logo;
			}
		}

		return undefined;
	};

	const _renderStreamOrImage = () => {
		const url = _getURL();

		if (!url) {
			if (_getPreviewImage()) {
				return (
					<ChildButton
						testID={`medialistitem_${item.id}_image`}
						onPress={onPress}
						activeOpacity={1}
						style={getTopRadiusStyle(hsBorderRadius)}
					>
						<Image
							testID={`medialistitem_image`}
							mediaObj={_getPreviewImage()}
							imageSize="small"
							style={{
								...getDefaultAspectRatioStyle(containerWidth),
								...getTopRadiusStyle(hsBorderRadius)
							}}
							resizeMode="contain"
							expectedRatio={16 / 9}
						/>
					</ChildButton>
				);
			}

			if (activeSpace?.showGradientOnMissingImage) {
				return <PlaceholderGradient itemId={item?.id} title={item?.title} width={containerWidth} rounded={'top'} />;
			}
		}

		return (
			<StreamPlayer
				testID={testID}
				url={url}
				type={item?.mediaType}
				previewImage={_getPreviewImage()}
				containerStyle={{
					...getDefaultAspectRatioStyle(containerWidth),
					...getTopRadiusStyle(hsBorderRadius)
				}}
				onPlay={() => {
					setShouldPause(false);
					trackAction('mediaitem', 'Play Video', `${item.id}`);
				}}
				onPause={() => trackAction('mediaitem', 'Stop Video', `${item.id}`)}
				onExternalClick={() => trackAction('mediaitem', 'Open External URL', `${item.id}`)}
				roundedTop
				shouldPause={shouldPause}
			/>
		);
	};

	const _renderTitle = () => {
		if (!isEmptyString(item.title)) {
			return (
				<Text bold numberOfLines={2} style={{ fontSize: 14, marginBottom: 5, marginRight: item.isHidden ? 5 : undefined }}>
					{item.title}
				</Text>
			);
		}

		return null;
	};

	const _renderSubtitle = () => {
		if (!isEmptyString(item.subtitle)) {
			return (
				<Text numberOfLines={2} italic>
					{item.subtitle}
				</Text>
			);
		}

		return null;
	};

	const _renderExpo = () => {
		let str = '';
		item?.expos?.map((e) => {
			const found = content?.expos?.find((expo) => expo.id === e.id && !expo.isDeleted);
			if (found) {
				if (str.length > 0) {
					str = str.concat(`, ${found.title}`);
				} else {
					str = str.concat(found.title);
				}
			}
		});
		if (!isEmptyString(str)) {
			return (
				<Text numberOfLines={2} style={{ marginBottom: 5 }}>
					{str}
				</Text>
			);
		}

		return null;
	};

	return (
		<View style={[{ flex: 1 }, containerStyle]}>
			<HSCard
				style={{
					flex: 1,
					padding: 0,
					position: 'relative',
					overflow: 'hidden'
				}}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
			>
				{_renderStreamOrImage()}
				<ChildButton
					activeOpacity={1}
					testID={`medialistitem_${item.id}_text`}
					style={{ flex: 1, padding: 10 }}
					onPress={() => {
						setShouldPause(true);
						if (onPress) {
							onPress();
						}
					}}
					isDisabled={!onPress}
				>
					<View style={{ flex: 1, flexDirection: 'row' }}>
						<View style={{ flex: 1, paddingRight: 10 }}>
							<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
								<View style={{ flex: 1, flexDirection: 'row', paddingRight: 10 }}>
									{_renderTitle()}
									{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
								</View>
								<ListItemMatchCount contentType="mediaitem" item={item} />
							</View>
							{_renderExpo()}
							{_renderSubtitle()}
						</View>
						{_renderPin()}
					</View>
				</ChildButton>
				{_renderAction()}
			</HSCard>
		</View>
	);
};
