import { Text } from 'components/Text';
import { hsBorderRadius, hsInnerPadding } from 'config/styleConstants';
import { LinearGradient } from 'expo-linear-gradient';
import { colorMixer, hexToRGBA, isDarkColor, isEmptyString, rgbaToHex } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

interface IPlaceholderGradient {
	itemId: number;
	width: number;
	title: string;
	subtitle?: string;
	rounded?: 'full' | 'top' | 'bottom';
	primaryColor?: string;
	smallText?: boolean;
	tinyText?: boolean;
}

interface GradientDirection {
	x: number;
	y: number;
}

export const PlaceholderGradient = (props: IPlaceholderGradient) => {
	const { theme } = useTheme();
	const { itemId, title, width, subtitle, rounded, primaryColor, smallText, tinyText } = props;

	const [locations, setLocations] = useState<number[]>([]);
	const [start, setStart] = useState<GradientDirection>({ x: 0, y: 0 });
	const [end, setEnd] = useState<GradientDirection>({ x: 0, y: 0 });
	const [colors, setColors] = useState<string[]>([]);

	useEffect(() => {
		let first = (title.charCodeAt(0) * title.length * 2) % itemId;
		while (first > 1) {
			first = first / 10;
		}
		let second = (title.charCodeAt(1) * title.length * 5) % itemId;
		while (second > 1) {
			second = second / 10;
		}

		while (Math.abs(first - second) < 0.4) {
			if (first > second) {
				first = first + 0.1;
				second = second - 0.1 > 0 ? second - 0.1 : second;
			} else {
				first = first - 0.1 > 0 ? first - 0.1 : first;
				second = second + 0.1;
			}
		}

		switch (itemId % 2) {
			case 0:
				setStart({ x: first, y: first });
				setEnd({ x: second, y: second });
				break;
			case 1:
				setStart({ x: second, y: first });
				setEnd({ x: first, y: second });
				break;

			default:
				break;
		}

		const primary = primaryColor ?? theme.primary;

		const mixed = colorMixer(hexToRGBA(primary), hexToRGBA(theme.contentBackgroundColor ?? theme.background), 0.7);

		const secondary = rgbaToHex(mixed);

		switch (itemId % 4) {
			case 0:
			case 3:
				setColors([primary, secondary]);
				break;
			case 1:
			case 2:
				setColors([secondary, primary]);
				break;
			default:
				break;
		}

		setLocations([first, second].sort((a, b) => (a > b ? 1 : -1)));
	}, [itemId, title]);

	if (!width || width < 2) {
		return null;
	}

	return (
		<LinearGradient
			style={{
				width: width,
				height: (width * 9) / 16,
				position: 'relative',
				padding: hsInnerPadding,
				borderTopRightRadius: rounded === 'full' || rounded === 'top' ? hsBorderRadius : 0,
				borderTopLeftRadius: rounded === 'full' || rounded === 'top' ? hsBorderRadius : 0,
				borderBottomRightRadius: rounded === 'full' || rounded === 'bottom' ? hsBorderRadius : 0,
				borderBottomLeftRadius: rounded === 'full' || rounded === 'bottom' ? hsBorderRadius : 0
			}}
			start={start}
			end={end}
			locations={locations}
			colors={colors}
		>
			<View
				style={{
					width: '100%',
					height: subtitle ? '50%' : '100%',
					justifyContent: subtitle ? 'flex-end' : 'center'
				}}
			>
				<Text
					numberOfLines={subtitle ? 2 : 4}
					bold
					style={{
						textAlign: 'center',
						fontSize: tinyText ? 10 : smallText ? 20 : subtitle ? 30 : 40,
						color: isDarkColor(primaryColor ?? theme.primary) ? theme.contentBackgroundColor ?? theme.background : theme.text
					}}
				>
					{title}
				</Text>
			</View>
			{!isEmptyString(subtitle) && (
				<View
					style={{
						width: '100%',
						height: 1,
						marginVertical: 10,
						backgroundColor: isDarkColor(primaryColor ?? theme.primary)
							? theme.contentBackgroundColor ?? theme.background
							: theme.text
					}}
				/>
			)}
			{!isEmptyString(subtitle) && (
				<View
					style={{
						width: '100%',
						height: '50%',
						justifyContent: 'flex-start'
					}}
				>
					<Text
						bold
						numberOfLines={2}
						style={{
							textAlign: 'center',
							fontSize: smallText ? 10 : 20,
							color: isDarkColor(primaryColor ?? theme.primary)
								? theme.contentBackgroundColor ?? theme.background
								: theme.text
						}}
					>
						{subtitle}
					</Text>
				</View>
			)}
		</LinearGradient>
	);
};
