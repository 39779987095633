import { hsTextBottomMargin } from 'config/styleConstants';
import { isEmptyString } from 'helper';
import { TFunctionResult } from 'i18next';
import React from 'react';
import { TextStyle, TextProps } from 'react-native';

import { Text } from './Text';

interface ITitle {
	children?: string | TFunctionResult;
	style?: TextStyle;
	center?: boolean;
	largerText?: boolean;
}
export const Title = (props: ITitle & TextProps) => {
	const { children, style, largerText } = props;

	if (!isEmptyString(children)) {
		return (
			<Text {...props} bold style={[{ fontSize: largerText ? 36 : 24, marginBottom: hsTextBottomMargin }, style]}>
				{children}
			</Text>
		);
	}

	return null;
};
