import React, { useEffect, useState } from 'react';
import { IQuizAnswerValue, IVote } from 'config/interfaces';
import { isEmptyString } from 'helper';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { ILeaderboardEntry, QuizLeaderboardListEntry, QUIZLEADERBOARDLISTENTRYHEIGHT } from './QuizLeaderboardListEntry';
import { QuizLeaderboardListHeader } from './QuizLeaderboardListHeader';
import { NumberPresenter } from 'components/Info';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { HSCard } from 'components/Card';
import { useSpace } from 'hooks/useSpace';
import { NoData } from 'components/NoData';
import { FlashSectionList } from 'components/List';

interface IQuizLeaderboardModal {
	vote: IVote;
	userScore: number;
	resultsOnly?: boolean;
	largerDesign?: boolean;
}

interface IListData {
	header: string;
	items: ILeaderboardEntry[];
}

export const QuizLeaderboard = (props: IQuizLeaderboardModal) => {
	const { vote, userScore, resultsOnly, largerDesign } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [leaderboard, setLeaderboard] = useState<(string | ILeaderboardEntry)[]>([]);
	const [userPlacement, setUserPlacement] = useState<string>('');

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

	useEffect(() => {
		if (isDataLoading) {
			setTimeout(() => {
				setIsDataLoading(false);
			}, 30000);
		}
	}, []);

	useEffect(() => {
		const _leaderboard: IListData[] = [];
		let newSections: typeof leaderboard = [];

		if (voteAnswers) {
			const answers = voteAnswers?.filter((e) => !e.isDeleted && e.voteId === vote.id);

			answers.forEach((answer) => {
				if (answer.value && !isEmptyString(answer.value)) {
					const parsed = JSON.parse(answer.value);
					let totalAnswerScore = 0;
					parsed.answers.forEach((givenAnswer: IQuizAnswerValue) => {
						totalAnswerScore += givenAnswer.pointsGained;
					});

					const attendee = attendees.find((e) => e.userId === answer.userId);
					const found = _leaderboard.find((e) => e.header === totalAnswerScore.toString());
					if (found) {
						found.items.push({
							attendee
						});
					} else {
						_leaderboard.push({
							header: totalAnswerScore.toString(),
							items: [{ attendee }]
						});
					}
				}
			});

			_leaderboard.sort((a, b) => (Number(a.header) > Number(b.header) ? -1 : 1));

			const myPlace = _leaderboard.findIndex((e) => e.items.find((i) => i?.attendee?.userId === profile?.userId)) + 1;
			setUserPlacement(_getPlacement(myPlace));

			_leaderboard.forEach((e, idx) => {
				const _idx = idx + 1;
				if (activeSpace?.language === 'de') {
					e.header = `${_idx}. ${t('place')} - ${e.header} ${t('Points')} (${e.items.length} ${
						e.items.length === 1 ? t('Attendee') : t('Attendees')
					})`;
				} else {
					if (_idx.toString().endsWith('1')) {
						e.header = `${_idx}st ${t('place')} - ${e.header} ${t('Points')}`;
					} else if (_idx.toString().endsWith('2')) {
						e.header = `${_idx}nd ${t('place')} - ${e.header} ${t('Points')}`;
					} else if (_idx.toString().endsWith('3')) {
						e.header = `${_idx}rd ${t('place')} - ${e.header} ${t('Points')}`;
					} else {
						e.header = `${_idx}th ${t('place')} - ${e.header} ${t('Points')}`;
					}
				}

				newSections.push(e.header);
				newSections = newSections.concat(e.items);
			});
		}

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setLeaderboard(newSections);
	}, [vote, voteAnswers, attendees, userScore]);

	const _getPlacement = (place: number) => {
		if (activeSpace?.language === 'de') {
			return `${place}. ${t('place')}`;
		}
		if (place.toString().endsWith('1')) {
			return `${place}st ${t('place')}`;
		} else if (place.toString().endsWith('1')) {
			return `${place}nd ${t('place')}`;
		} else if (place.toString().endsWith('1')) {
			return `${place}rd ${t('place')}`;
		}

		return `${place}th`;
	};

	const _renderUserPlacement = () => {
		if (resultsOnly) {
			return null;
		}
		return <NumberPresenter number={userPlacement} title={t('YourPlacement')} />;
	};

	const _getItem = (item: ILeaderboardEntry) => {
		let _isAnonymous = voteAnswers.find((e) => e.userId === item.attendee?.userId && e.isAnonymousSubmission) ? true : false;
		if (_isAnonymous && item.attendee) {
			item.attendee = undefined;
		}

		return item;
	};

	const _renderPlacementList = () => {
		if (leaderboard?.length > 0 || isDataLoading) {
			if (resultsOnly) {
				return (
					<HSCard style={{ flex: 1 }}>
						<FlashSectionList
							data={leaderboard}
							renderItem={(renderItem) => (
								<QuizLeaderboardListEntry item={_getItem(renderItem.item)} largerDesign={largerDesign} />
							)}
							renderSectionHeader={(renderItem) => (
								<QuizLeaderboardListHeader text={renderItem.item} largerDesign={largerDesign} />
							)}
							estimatedItemSize={largerDesign ? QUIZLEADERBOARDLISTENTRYHEIGHT * 1.5 : QUIZLEADERBOARDLISTENTRYHEIGHT}
							hideIndexButtons
						/>
					</HSCard>
				);
			}

			return (
				<FlashSectionList
					data={leaderboard}
					renderItem={(renderItem) => <QuizLeaderboardListEntry item={_getItem(renderItem.item)} />}
					renderSectionHeader={(renderItem) => <QuizLeaderboardListHeader text={renderItem.item} />}
					estimatedItemSize={QUIZLEADERBOARDLISTENTRYHEIGHT}
					hideIndexButtons
				/>
			);
		}
		return <NoData type="NoQuizAnswers" />;
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderUserPlacement()}
			{_renderPlacementList()}
		</View>
	);
};
