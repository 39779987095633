import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Icon } from 'components/Icon';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { IAttendee, IHSPXTicket } from 'config/interfaces';
import { IUserInSpaceId } from 'rematch/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useSpace } from 'hooks/useSpace';
import { Avatar } from 'components/User';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';

export const TICKETLISTITEMENTRYHEIGHT = 80;

export interface ITicketListItem {
	testID: string;
	item: IHSPXTicket;
	onPress?: () => void;
	isSelected?: boolean;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
	isMailReceiver?: boolean;
}

export const TicketListItem = (props: ITicketListItem) => {
	const { testID, item, onPress, isSelected, onSelect, onEdit, onDelete, isLoading, isMailReceiver } = props;
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();

	const [userInSpaceIdEntry, setUserInSpaceIdEntry] = useState<IUserInSpaceId | undefined>(undefined);
	const [ticketHolder, setTicketHolder] = useState<IAttendee | undefined>(undefined);

	const userInSpaces = useSelector((store: IRootState) => store.space.userInSpaces);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _userInSpaceIdEntry: typeof userInSpaceIdEntry = undefined;

		if (activeSpace && userInSpaces) {
			_userInSpaceIdEntry = userInSpaces
				.filter((e) => e)
				.find((e) => e.spaceId === activeSpace.spaceId && e.ticketcode === item.ticketcode && e.ticketProvider === 'hellospaces');
		}

		setUserInSpaceIdEntry(_userInSpaceIdEntry);
	}, [userInSpaces, activeSpace, item]);

	useEffect(() => {
		let _attendee: typeof ticketHolder = undefined;

		if (attendees && userInSpaceIdEntry) {
			_attendee = attendees.find((e) => e.userId === userInSpaceIdEntry.userId);
		}

		setTicketHolder(_attendee);
	}, [attendees, userInSpaceIdEntry]);

	const _renderActions = () => {
		if (onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', paddingLeft: 10 }}>
					{onEdit && (
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isLoading={isLoading}
						/>
					)}
					{onDelete && (
						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isLoading}
						/>
					)}
				</View>
			);
		}

		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	if (!item) {
		return null;
	}

	const _renderName = () => {
		let name = '';

		if (!isEmptyString(item.firstName) && !isEmptyString(item.lastName)) {
			name = `${item.firstName} ${item.lastName}`;
		} else if (!isEmptyString(item.firstName)) {
			name = `${item.firstName}`;
		} else if (!isEmptyString(item.lastName)) {
			name = `${item.lastName}`;
		}

		if (!isEmptyString(name)) {
			return <Text style={{ fontSize: 12 }}>{name}</Text>;
		}

		return null;
	};

	const _renderAttendee = () => {
		if (ticketHolder) {
			return (
				<ChildButton
					testID={`${testID}_button_ticketholder`}
					style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}
					onPress={() =>
						navigation.navigate(ERoutes.AttendeeDetails, { spaceId: activeSpace?.spaceId, userId: ticketHolder.userId })
					}
				>
					<Avatar
						size="gridCard"
						avatar={ticketHolder?.smallImageUrl ?? ticketHolder.imageUrl}
						fullName={`${ticketHolder.firstName} ${ticketHolder.lastName}`}
					/>
					<Text style={{ marginLeft: 5, fontSize: 12 }}>{`${ticketHolder.firstName} ${ticketHolder.lastName}`}</Text>
				</ChildButton>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{ height: TICKETLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
			isDisabled={!onPress}
			onPress={isMailReceiver ? onSelect : onPress}
		>
			{onSelect && (
				<View style={{ paddingRight: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={() => onSelect()}
						style={{ marginBottom: 0 }}
					/>
				</View>
			)}

			{isMailReceiver && (
				<ChildButton testID={`${testID}_avatar`} onPress={onPress}>
					<Avatar
						fullName={item?.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.ticketcode}
						size="listItem"
					/>
				</ChildButton>
			)}
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text bold style={{ marginBottom: 2 }}>
					{item.ticketcode}
				</Text>
				{_renderName()}
				{!isEmptyString(item.email) && <Text style={{ fontSize: 12 }}>{item.email}</Text>}
				{_renderAttendee()}
			</View>
			{_renderActions()}
		</ChildButton>
	);
};
