import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form/FormTextInput';
import { hsBottomMargin } from 'config/styleConstants';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView } from 'react-native';
import { HSModal } from '../Modal';

interface IPinModal {
	isVisible: boolean;
	value: string;
	onClose: () => void;
	onChange: (val) => void;
	onSubmit: () => void;
	routeParams?: Record<string, any>;
	isLoading?: boolean;
}

const TESTIDPREFIX = 'pinmodal';

export const PinModal = (props: IPinModal) => {
	const { isVisible, value, onChange, onClose, onSubmit, routeParams, isLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	useEffect(() => {
		if (routeParams?.pin) {
			onChange(routeParams.pin);
		}
	}, [routeParams]);

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('keydown', _handleEnter, false);
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, []);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			onSubmit();
		}
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={onClose}
			onSubmit={() => onSubmit()}
			title={t('Enter Space PIN')}
			isSubmitDisabled={isEmptyString(value)}
			isSubmitLoading={isLoading}
		>
			<ScrollView
				style={{ flex: 1 }}
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
			>
				<FormTextInput
					formStyle={{ marginBottom: hsBottomMargin }}
					testID={`${TESTIDPREFIX}_formtextinput_pin`}
					label={t('spacePin')}
					isRequired
					isDisabled={isLoading}
					value={value}
					hint={t('pinHint')}
					onChangeText={(val) => onChange(val?.trim())}
					autoCorrect={false}
					autoCapitalize="none"
					autoCompleteType="off"
				/>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_launch`}
					onPress={() => onSubmit()}
					title={t('Launch')}
					icon={EDefaultIconSet.Save}
					textColor={theme.text}
					isLoading={isLoading}
					alignSelf="center"
					isDisabled={isEmptyString(value)}
				/>
			</ScrollView>
		</HSModal>
	);
};
