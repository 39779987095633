import i18next from 'i18next';
import { IRegistrationField } from '../interfaces';

export const getDefaultRegistrationFields = () => {
	let fields: IRegistrationField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'never',
			showOnDetailScreen: true
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'avatar',
			fieldLabel: i18next.t('Profile Image'),
			fieldType: 'avatar',
			visibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'firstName',
			fieldLabel: i18next.t('firstName'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'lastName',
			fieldLabel: i18next.t('lastName'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'email',
			fieldLabel: i18next.t('email'),
			fieldType: 'email',
			isDefaultField: true,
			isRequired: true,
			visibility: 'always',
			activateShowOnDetailsEdit: true
		},
		{
			fieldName: 'password',
			fieldLabel: i18next.t('password'),
			fieldHint: i18next.t('passwordHint'),
			fieldType: 'password',
			isDefaultField: true,
			isRequired: true,
			visibility: 'always'
		},
		{
			fieldName: 'passwordConfirmation',
			fieldLabel: i18next.t('passwordConfirmation'),
			fieldHint: i18next.t('passwordConfirmHint'),
			fieldType: 'password',
			isDefaultField: true,
			isRequired: true,
			visibility: 'registrationOnly'
		},
		{
			fieldName: 'hellospacesNewsletter',
			fieldLabel: i18next.t('RegisterNewsletter'),
			fieldHint: i18next.t('RegisterNewsletterHint'),
			fieldType: 'boolean',
			visibility: 'always'
		},
		{
			fieldName: 'jobTitle',
			fieldLabel: i18next.t('jobTitle'),
			fieldType: 'string',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'company',
			fieldLabel: i18next.t('company'),
			fieldType: 'string',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'aboutMe',
			fieldLabel: i18next.t('aboutMe'),
			fieldHint: i18next.t('aboutMeHint'),
			fieldType: 'string',
			visibility: 'always',
			showOnDetailScreen: true,
			showOnPreview: true,
			maxLength: 255
		},
		{
			fieldName: 'website',
			fieldLabel: i18next.t('website'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'linkedInProfileUrl',
			fieldLabel: i18next.t('linkedInProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'xingProfileUrl',
			fieldLabel: i18next.t('xingProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'twitterProfileUrl',
			fieldLabel: i18next.t('twitterProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'blogUrl',
			fieldLabel: i18next.t('blog'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'facebookProfileUrl',
			fieldLabel: i18next.t('facebookProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'instagramProfileUrl',
			fieldLabel: i18next.t('instagramProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'youTubeProfileUrl',
			fieldLabel: i18next.t('youTubeProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'tikTokProfileUrl',
			fieldLabel: i18next.t('tikTokProfile'),
			fieldType: 'website',
			visibility: 'never',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'typeOfAttendence',
			fieldLabel: i18next.t('typeOfAttendence'),
			fieldType: 'multiswitch',
			options: [
				{
					key: 'onsite',
					label: i18next.t('Onsite')
				},
				{
					key: 'remote',
					label: i18next.t('Remote')
				}
			],
			visibility: 'never'
		},
		{
			fieldName: 'salutation',
			fieldLabel: i18next.t('Salutation'),
			fieldType: 'multiswitch',
			options: [
				{
					key: 'mr',
					label: i18next.t('SalutationMr')
				},
				{
					key: 'mrs',
					label: i18next.t('SalutationMrs')
				}
			],
			visibility: 'never'
		},
		{
			fieldName: 'allowChats',
			fieldLabel: i18next.t('allowChats'),
			fieldHint: i18next.t('allowChatsHint'),
			fieldType: 'boolean',
			defaultValue: true,
			visibility: 'always'
		},
		{
			fieldName: 'hideMyProfile',
			fieldLabel: i18next.t('hideMyProfile'),
			fieldHint: i18next.t('hideMyProfileHint'),
			fieldType: 'boolean',
			visibility: 'never'
		},
		{
			fieldName: 'allowMeetingRequests',
			fieldLabel: i18next.t('allowMeetingRequests'),
			fieldHint: i18next.t('allowMeetingRequestsHint'),
			fieldType: 'boolean',
			defaultValue: true,
			visibility: 'always'
		},
		{
			fieldName: 'doNotTrack',
			fieldLabel: i18next.t('doNotTrack'),
			fieldHint: i18next.t('doNotTrackHint'),
			fieldType: 'spaceBoolean',
			visibility: 'never'
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'never',
			showOnDetailScreen: true
		},
		{
			fieldName: 'expos',
			fieldLabel: i18next.t('Expos'),
			fieldType: 'reference',
			referenceType: 'expos',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderExpos')
		},
		{
			fieldName: 'speaker',
			fieldLabel: i18next.t('Speaker'),
			fieldType: 'singlereference',
			referenceType: 'speakers',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderSpeakers')
		}
	];

	return fields;
};
