import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton, RoundButton } from 'components/Button';
import { Icon, VoteIcon } from 'components/Icon';
import moment from 'moment';
import { EDefaultIconSet, isEmptyString, openURL } from 'helper';
import { IVote } from 'config/interfaces/vote';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { hsBorderRadius, hsTextBottomMarginSmall } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';
import { useDate } from 'hooks/useDate';
import { useSpace } from 'hooks/useSpace';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { InfoPill } from 'components/InfoPill';
import { IVoteAnswer } from 'config/interfaces';

interface IVoteListItemComponent {
	item: IVote;
	isLoading?: boolean;
	onPress?: (id: number, isAnswered?: boolean) => void;
	onSelect?: () => void;
	isSelected?: boolean;
	onActionButtonPress?: () => void;
	onSpeakerViewPress?: () => void;
	index: number;
	onDelete?: () => void;
	toggleActivation?: () => void;
	isEditMode?: boolean;
}

const LOWVALUE = 0.3;
const HIGHVALUE = 1;
const ANIMATIONDURATION = 600;
const ANIMATIONINITIALDELAY = 2500;

export const VoteListItem = (props: IVoteListItemComponent) => {
	const {
		item,
		isLoading,
		onPress,
		onActionButtonPress,
		index,
		onSelect,
		isSelected,
		onDelete,
		onSpeakerViewPress,
		toggleActivation,
		isEditMode
	} = props;
	const { t }: { t: any } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getComparedDateString } = useDate();

	const animatedOpacityValue = useRef(new Animated.Value(1));

	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const [iHaveAnsweredThisVoting, setIHaveAnsweredThisVoting] = useState<boolean>(false);
	const [answersForThis, setAnswersForThis] = useState<IVoteAnswer[]>([]);

	useEffect(() => {
		if (process.env.IS_TEST) {
			return;
		}
		Animated.loop(
			Animated.sequence([
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					delay: ANIMATIONINITIALDELAY,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: LOWVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				}),
				Animated.timing(animatedOpacityValue.current, {
					toValue: HIGHVALUE,
					duration: ANIMATIONDURATION,
					useNativeDriver: true
				})
			])
		).start();
	}, []);

	useEffect(() => {
		let _answersForThis: typeof answersForThis = [];
		let _answered = false;

		if (activeSpace && voteAnswers && item) {
			_answersForThis = voteAnswers.filter(
				(a) => !a.isResultObject && !a.isDeleted && a.spaceId === activeSpace.spaceId && a.voteId === item.id
			) as IVoteAnswer[];

			if (!profile) {
				_answersForThis = _answersForThis.filter((a) => !a.isAnonymousSubmission);
			} else {
				_answered = _answersForThis.find((voteAnswer) => voteAnswer.userId === profile.userId) !== undefined;
			}
		}

		setAnswersForThis(_answersForThis);
		setIHaveAnsweredThisVoting(_answered);
	}, [activeSpace, voteAnswers, profile, item]);

	const _renderAvailability = () => {
		if (item.openFrom || item.openUntil) {
			const now = moment();
			const isFinished = item.openUntil && now.isAfter(item.openUntil);
			const hasStarted = !item.openFrom || now.isAfter(item.openFrom);

			let text = '';
			let color = theme.text;

			if (isFinished) {
				color = theme.danger ?? 'red';
			} else if (hasStarted) {
				color = theme.success ?? 'green';
			}

			if (item.openFrom) {
				const openFromMoment = moment(item.openFrom);

				if (now.isBefore(openFromMoment)) {
					text = t('VoteAvailableFrom').replace('%DATE%', getComparedDateString(item.openFrom));
				} else if (isEmptyString(item.openUntil)) {
					return null;
				}
			}

			if (item.openUntil && isEmptyString(text)) {
				const openUntilMoment = moment(item.openUntil);

				if (now.isBefore(openUntilMoment)) {
					text = t('VoteAvailableUntil').replace('%DATE%', getComparedDateString(item.openUntil));
				} else {
					text = t('VoteAvailableUntilDone').replace('%DATE%', getComparedDateString(item.openUntil));
				}
			}

			return (
				<View style={{ flexDirection: 'row', marginTop: 5 }}>
					<Icon name={EDefaultIconSet.Clock} color={color} size={15} />
					<Text style={{ fontSize: 12, marginLeft: 5, color }}>{text}</Text>
				</View>
			);
		}

		return null;
	};

	const _renderStatus = () => {
		let active = false;
		const now = moment();
		if (item.isActive) {
			if (item.openUntil && item.openFrom) {
				active = now.isBefore(moment(item.openUntil)) && now.isAfter(moment(item.openFrom));
			} else if (item.openUntil) {
				active = now.isBefore(moment(item.openUntil));
			} else if (item.openFrom) {
				active = now.isAfter(moment(item.openFrom));
			} else {
				active = true;
			}
		}
		if (active) {
			return (
				<Animated.View
					style={[
						{
							opacity: animatedOpacityValue.current,
							backgroundColor: theme.danger,
							paddingHorizontal: 6,
							paddingVertical: 2,
							borderRadius: hsBorderRadius,
							alignSelf: 'flex-start'
						}
					]}
				>
					<Text style={{ fontSize: 12, color: theme.primaryContrast }}>Live</Text>
				</Animated.View>
			);
		}

		return <InfoPill text={t('Inactive')} backgroundColor={theme.formgray} />;
	};

	const _renderAnswerStatus = () => {
		if (!iHaveAnsweredThisVoting) {
			return <InfoPill text={t('NotAnswered')} borderColor={theme.danger} textColor={theme.danger} centerText />;
		}

		return null;
	};

	const _iAmAdminOrModerator = () => {
		return iAmSpaceModerator || iAmSpaceAdmin;
	};

	const _renderAdminButtons = () => {
		const elements: ReactNode[] = [];
		if (onDelete) {
			elements.push(
				<RoundButton
					key={`votelist_button_delete_${index}`}
					testID={`votelist_button_delete_${index}`}
					isOutline
					color={theme.danger}
					isDisabled={!_iAmAdminOrModerator()}
					onPress={() => onDelete()}
					isLoading={isLoading}
					icon={EDefaultIconSet.Delete}
					size="sm"
				/>
			);
		}
		if (onSpeakerViewPress && item.votingType !== 'externalUrl') {
			elements.push(
				<RoundButton
					key={`votelist_button_speakerview_${index}`}
					testID={`votelist_button_speakerview_${index}`}
					isOutline
					isDisabled={!_iAmAdminOrModerator()}
					onPress={onSpeakerViewPress}
					isLoading={isLoading}
					icon={EDefaultIconSet.Present}
					size="sm"
				/>
			);
		}

		if (toggleActivation && _iAmAdminOrModerator()) {
			elements.push(
				<RoundButton
					key={`votelist_button_activate_${index}`}
					testID={`votelist_button_activate_${index}`}
					isOutline
					isDisabled={!_iAmAdminOrModerator()}
					onPress={() => toggleActivation()}
					isLoading={isLoading}
					icon={item.isActive ? EDefaultIconSet.Pause : EDefaultIconSet.Play}
					size="sm"
					color={item.isActive ? theme.danger : theme.success}
				/>
			);
		}
		if (onActionButtonPress && _iAmAdminOrModerator()) {
			elements.push(
				<RoundButton
					key={`votelist_button_admin_${index}`}
					testID={`votelist_button_admin_${index}`}
					isOutline
					isDisabled={!_iAmAdminOrModerator()}
					onPress={() => onActionButtonPress()}
					isLoading={isLoading}
					icon={EDefaultIconSet.Edit}
					size="sm"
					noMargin
				/>
			);
		}

		if (elements.length > 0) {
			return (
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'flex-end',
						flexWrap: 'wrap',
						marginRight: -5
					}}
				>
					{elements}
				</View>
			);
		}
		return null;
	};

	const _renderCount = () => {
		if (answersForThis && answersForThis.length > 0) {
			return (
				<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
					<View style={{ width: 1, backgroundColor: theme.text, marginRight: 5 }} />
					<Text>{`#${answersForThis.length}`}</Text>
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={`vote_list_${index}`}
			onPress={() => {
				if (item.votingType === 'externalUrl' && !isEditMode) {
					if (item.externalUrl) {
						openURL(item.externalUrl);
					}
				} else if (onPress) {
					onPress(item.id, iHaveAnsweredThisVoting);
				}
			}}
			style={{ flex: 1 }}
		>
			<HSCard style={{ flex: 1 }}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: hsTextBottomMarginSmall
					}}
				>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<VoteIcon
							voteType={item.votingType}
							testID={`votelistitem_voteicon`}
							onPress={() => {
								if (onPress) {
									onPress(item.id);
								}
							}}
							smallerSize
							isCardLayout
							containerStyle={{ marginRight: 5 }}
						/>
						{_renderCount()}
					</View>
					<View style={{ justifyContent: 'center', alignItems: 'center' }}>{_renderStatus()}</View>
				</View>
				<View
					style={{
						flex: 1,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						marginBottom: 5
					}}
				>
					{onSelect && (
						<FormCheckbox
							testID={`votelistitem_checkbox_select`}
							value={isSelected}
							onPress={() => onSelect()}
							style={{ marginBottom: 0 }}
						/>
					)}
					<View
						style={{
							flex: 1,
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
							alignSelf: 'flex-start',
							marginBottom: 5
						}}
					>
						<Text bold numberOfLines={4} style={{ fontSize: 18 }}>
							{item.question}
						</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent:
							item.openFrom || item.openUntil || (item.answerCount && item.answerCount > 0) ? 'space-between' : 'flex-end'
					}}
				>
					<View style={{ width: iHaveAnsweredThisVoting ? '50%' : '30%', alignItems: 'flex-start', justifyContent: 'center' }}>
						{_renderAvailability()}
					</View>
					<View style={{ width: iHaveAnsweredThisVoting ? 0 : '40%', alignItems: 'center', justifyContent: 'center' }}>
						{_renderAnswerStatus()}
					</View>
					<View style={{ width: iHaveAnsweredThisVoting ? '50%' : '30%', alignItems: 'flex-end', justifyContent: 'center' }}>
						{_renderAdminButtons()}
					</View>
				</View>
			</HSCard>
		</ChildButton>
	);
};
