import React from 'react';

import { H3 } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

interface IVoteAdminButton {
	testID: string;
	status: 'live' | undefined;
	label?: string;
}

export const VoteStatus = (props: IVoteAdminButton) => {
	const { testID, label, status } = props;
	const { theme } = useTheme();

	return (
		<H3
			testID={`${testID}_label_${label}`}
			adjustsFontSizeToFit
			center
			style={{
				color: status === 'live' ? theme.danger : theme.text
			}}
		>
			{label ?? status}
		</H3>
	);
};
