import React from 'react';
import { View } from 'react-native';

import { PieChart, BarChart } from 'components/Charts';
import { IWordListItem } from '../VoteWordCloud/WordCloud';

interface IMultipleChoicResultList {
	wordList: IWordListItem[];
	answerCount?: number;
	viewType?: string;
	largerDesign?: boolean;
}

export const VoteMultipleChoiceResults = (props: IMultipleChoicResultList) => {
	const { wordList, answerCount, viewType, largerDesign } = props;

	const _renderResult = () => {
		switch (viewType) {
			case 'pie':
			// return <PieChart data={wordList} />;
			case 'barchart':
			default:
				return <BarChart showVertical data={wordList} answerCount={answerCount} largerDesign={largerDesign} />;
		}
	};

	return <View style={{ flex: 1 }}>{_renderResult()}</View>;
};
