import React from 'react';
import { FlashList as SFlashList, FlashListProps } from '@shopify/flash-list';

interface IFlashList extends FlashListProps<T> {}

export const FlashList = React.forwardRef((props: IFlashList, ref: any) => {
	const { renderItem } = props;

	return (
		<SFlashList
			ref={ref}
			{...props}
			renderItem={(item) => {
				if (renderItem) {
					return renderItem(item);
				}
				return null;
			}}
		/>
	);
});
