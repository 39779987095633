import React, { useState, useEffect, ReactNode } from 'react';
import { ScrollView, View } from 'react-native';
import { CardSeparationHeader, H1 } from 'components/Text';
import { VoteListItem } from './VoteListItem';
import { IVote } from 'config/interfaces/vote';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import moment from 'moment';
import { HSCard } from 'components/Card';
import { FlashSectionList } from 'components/List';
import { getCardContainerStyle, isEmptyString, IS_WEB } from 'helper';
import { hsBottomMargin } from 'config/styleConstants';

interface IVoteListComponent {
	isLoading?: boolean;
	items?: IVote[];
	onPress?: (id: number, isAnswered?: boolean) => void;
	onSelect?: (itemId: number) => void;
	selectedVotings: number[];
	onDelete?: (itemId: number) => void;
	onSpeakerViewPress?: (vote: IVote) => void;
	isEditMode?: boolean;
	toggleActivation?: (vote: IVote) => void;
}

interface IVoteData {
	title: string;
	data: IVote[];
}

export const VoteList = (props: IVoteListComponent) => {
	const { isLoading, items, onPress, onSelect, selectedVotings, onDelete, isEditMode, onSpeakerViewPress, toggleActivation } = props;
	const navigation = useNavigation();
	const { t } = useTranslation();
	const { isPhone, screenColumnCount } = useQuery();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const [voteData, setVoteData] = useState<(string | IVote)[]>([]);

	const profile = useSelector((store: IRootState) => store.auth.profile);

	useEffect(() => {
		if (items) {
			const now = moment();
			let _items = items;
			let _activeVotes: IVote[] = [];
			let _inActiveVotes: IVote[] = [];
			if (!profile) _items = _items.filter((e) => e.allowAnonymousAnswers !== 'always');

			_activeVotes = _items.filter((e) => {
				if (!e.isActive) {
					return false;
				}
				if (e.openUntil && e.openFrom) {
					return now.isBefore(moment(e.openUntil)) && now.isAfter(moment(e.openFrom));
				}
				if (e.openUntil) {
					return now.isBefore(moment(e.openUntil));
				}
				if (e.openFrom) {
					return now.isAfter(moment(e.openFrom));
				}
				return true;
			});
			if (iAmSpaceAdmin || iAmSpaceModerator) {
				_inActiveVotes = _items.filter((e) => {
					if (!e.isActive) {
						return true;
					}
					if (e.openFrom && e.openUntil) {
						return now.isBefore(moment(e.openFrom)) || now.isAfter(moment(e.openUntil));
					}
					if (e.openFrom) {
						return !now.isAfter(moment(e.openFrom));
					}
					if (e.openUntil) {
						return !now.isBefore(moment(e.openUntil));
					}
					return false;
				});
			}

			let sections: typeof voteData = [];

			if (_activeVotes.length > 0) {
				sections = sections.concat(_activeVotes);
			}

			if (_inActiveVotes.length > 0) {
				if (isPhone) {
					sections.push(t('Inactive Votes'));
				}
				sections = sections.concat(_inActiveVotes);
			}

			setVoteData(sections);
		}
	}, [iAmSpaceAdmin, iAmSpaceModerator, items, isPhone]);

	if (voteData && voteData.length > 0 && screenColumnCount) {
		return (
			<View
				style={{
					flex: 1
				}}
			>
				<FlashSectionList
					data={voteData}
					extraData={[onSelect, selectedVotings]}
					hideIndexButtons
					numColumns={isPhone ? 1 : screenColumnCount}
					renderItem={(renderItem) => (
						<View style={getCardContainerStyle(isPhone ? 1 : screenColumnCount, renderItem.index)}>
							<VoteListItem
								key={`votelist_item_${renderItem.index}`}
								item={renderItem.item}
								isLoading={isLoading}
								onPress={onPress}
								onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
								onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
								onSpeakerViewPress={onSpeakerViewPress ? () => onSpeakerViewPress(renderItem.item) : undefined}
								toggleActivation={toggleActivation ? () => toggleActivation(renderItem.item) : undefined}
								isSelected={selectedVotings.includes(renderItem.item.id)}
								index={renderItem.index}
								onActionButtonPress={() =>
									navigation.navigate(ERoutes.ActiveVotingAdmin, {
										spaceId: activeSpace?.spaceId,
										id: renderItem.item.id
									})
								}
								isEditMode={isEditMode}
							/>
						</View>
					)}
					renderSectionHeader={(renderItem) => {
						return (
							<HSCard>
								<H1 center noBottomMargin>
									{renderItem.item}
								</H1>
							</HSCard>
						);
					}}
					estimatedItemSize={165}
				/>
			</View>
		);
	}

	return null;
};
