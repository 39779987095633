import React from 'react';

import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper/fontSize';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks/useTheme';
import { isEmptyString } from 'helper/string';
interface IFormErrorComponent {
	testID: string;
	error?: string;
	needsPadding?: boolean;
}

export const FormError = (props: IFormErrorComponent) => {
	const { testID, error, needsPadding } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	if (error && !isEmptyString(error)) {
		return (
			<Text
				testID={`${testID}_error`}
				style={{
					color: theme.danger ?? 'red',
					marginTop: 3,
					marginBottom: 3,
					fontSize: normalizeFontSize(12),
					marginRight: needsPadding ? 60 : undefined
				}}
			>
				{t(error)}
			</Text>
		);
	}

	return null;
};
