import { ILauncherSpace, IMeeting, ISchedule, IStage } from 'config/interfaces';
import { useEffect, useRef, useState } from 'react';

import { FlashList, MasonryFlashList, MasonryFlashListRef, MasonryListRenderItem } from '@shopify/flash-list';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';

import { useTranslation } from 'react-i18next';
import { VerticalGridHeaderCell } from '../VerticalGrid/VerticalGridHeaderCell';
import { ScrollView, View } from 'react-native';
import { IS_WEB } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { VerticalGridMeetingCell } from '../VerticalGrid/VerticalGridMeetingCell';
import { VerticalGridItemCell } from '../VerticalGrid/VerticalGridItemCell';
import { VerticalGridTimeCell } from '../VerticalGrid/VerticalGridTimeCell';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';

interface IProps {
	items: ISchedule[];
	meetings: IMeeting[];
	isPublic?: boolean;
	space?: ILauncherSpace;
}

interface IHorizontalGridLane {
	stage: IStage;
	items: ISchedule[];
	meetings: IMeeting[];
}

interface IVerticalGridLane {
	stage: IStage;
	items: (IScheduleWithHeight | IMeetingWithHeight | IEmptyItem)[];
}

interface IScheduleWithHeight extends ISchedule {
	itemHeight: number;
}
interface IMeetingWithHeight extends IMeeting {
	itemHeight: number;
}

interface IEmptyItem {
	startDate?: string;
	endDate?: string;
	isEmpty: boolean;
	itemHeight: number;
}

const SCHEDULE_STEPS = 15;
export const HEIGHT_PER_MINUTE = 4;
const TIME_COLUMN_WIDTH = 40;
export const LANE_COLUMN_WIDTH_WEB = 180;
export const LANE_COLUMN_WIDTH_APP = 140;
const LANE_COLUMN_HEIGHT = ((IS_WEB ? LANE_COLUMN_WIDTH_WEB : LANE_COLUMN_WIDTH_APP) / 16) * 9;
const MYAGENDAID = -1;

export const NewGrid = (props: IProps) => {
	const { items, meetings, isPublic, space } = props;
	const { activeSpace } = useSpace();
	const { theme } = useTheme();
	const { isTabletOrMobile } = useQuery();
	const { t } = useTranslation();
	const navigation = useNavigation();

	const startTimeRef = useRef<string | undefined>(undefined);

	const timeCellRef = useRef<FlashList<string>>(null);
	const laneHeaderRef = useRef<FlashList<IStage>>(null);
	const mainListRef = useRef<MasonryFlashListRef<any>>(null);

	const [timeCells, setTimeCells] = useState<string[]>([]);
	const [viewWidth, setViewWidth] = useState<number>(1);

	const [heightPerMinute, setHeightPerMinute] = useState<number>(HEIGHT_PER_MINUTE);
	const [widthPerStage, setWidthPerStage] = useState<number>(IS_WEB ? LANE_COLUMN_WIDTH_WEB : LANE_COLUMN_WIDTH_APP);
	const [laneColumnHeight, setLaneColumnHeight] = useState<number>(LANE_COLUMN_HEIGHT);

	const [data, setData] = useState<{
		headers: IStage[];
		items: (IScheduleWithHeight | IMeetingWithHeight | IEmptyItem)[];
		numColumns: number;
	}>({
		headers: [],
		items: [],
		numColumns: 1
	});

	const [hasJumpedToNow, setHasJumpedToNow] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);

	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const setMediaDetailViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailViewType);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const GRID_SIZE_LANE_COUNT = IS_WEB ? (isTabletOrMobile ? 2 : 4) : 2;

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		const _space = space ?? activeSpace;
		if (IS_WEB && !isTabletOrMobile) {
			if (_space?.agendaHeightPerMinuteWeb) {
				setHeightPerMinute(_space.agendaHeightPerMinuteWeb);
			}
			if (_space?.agendaWidthPerStageWeb) {
				setWidthPerStage(_space.agendaWidthPerStageWeb);
				setLaneColumnHeight((_space.agendaWidthPerStageWeb / 16) * 9);
			}
		} else {
			if (_space?.agendaHeightPerMinuteApp) {
				setHeightPerMinute(_space.agendaHeightPerMinuteApp);
			}
			if (_space?.agendaWidthPerStageApp) {
				setWidthPerStage(_space.agendaWidthPerStageApp);
				setLaneColumnHeight((_space.agendaWidthPerStageApp / 16) * 9);
			}
		}
	}, [activeSpace, space, isTabletOrMobile]);

	useEffect(() => {
		const _timeCells: string[] = [];
		let earliest: string | undefined = undefined;
		let oldest: string | undefined = undefined;
		const _data: typeof data = { headers: [], items: [], numColumns: 1 };

		if (items?.length > 0 || meetings?.length > 0) {
			if (items?.length > 0) {
				if (!earliest || moment(items[0].startDate).isBefore(moment(earliest))) {
					earliest = items[0].startDate;
				}
				items.forEach((item) => {
					if (!oldest || moment(item.endDate).isAfter(moment(oldest))) {
						oldest = item.endDate;
					}
				});
			}

			if (meetings?.length > 0) {
				if (!earliest || moment(meetings[0].timeSlot.start).isBefore(moment(earliest))) {
					earliest = meetings[0].timeSlot.start;
				}

				meetings.forEach((meeting) => {
					if (!oldest || moment(meeting.timeSlot.end).isAfter(moment(oldest))) {
						oldest = meeting.timeSlot.end;
					}
				});
			}

			if (moment(earliest).get('minutes') % 10 > 0) {
				earliest = moment(earliest)
					.subtract(moment(earliest).get('minutes') % 10, 'minutes')
					.toISOString();
			}

			if (moment(oldest).get('minutes') % 10 > 0) {
				oldest = moment(oldest)
					.add(moment(oldest).get('minutes') % 10, 'minutes')
					.toISOString();
			}

			const start = moment(earliest);
			const end = moment(oldest);
			while (start.isSameOrBefore(end)) {
				_timeCells.push(start.toISOString());
				start.add(SCHEDULE_STEPS, 'minutes');
			}
		}

		startTimeRef.current = earliest;

		setTimeCells(_timeCells);

		const _lanes: IHorizontalGridLane[] = [];

		items?.forEach((item) => {
			if (item.stage?.id) {
				const alreadyAdded = _lanes.find((lane) => lane.stage.id === item.stage?.id);
				if (alreadyAdded) {
					const itemStart = moment(item.startDate);
					const itemEnd = moment(item.endDate);

					const hasOverlap = alreadyAdded.items.find((blockItem) => {
						const blockItemStart = moment(blockItem.startDate);
						const blockItemEnd = moment(blockItem.endDate);
						return (
							(itemStart.isAfter(blockItemStart) && itemStart.isBefore(blockItemEnd)) ||
							(itemEnd.isAfter(blockItemStart) && itemEnd.isBefore(blockItemEnd)) ||
							itemStart.isSame(blockItemStart)
						);
					});
					if (!hasOverlap) {
						alreadyAdded.items.push(item);
					}
				} else {
					let _stage: IStage | undefined = undefined;
					if (isPublic) {
						_stage = item.stage;
					} else {
						_stage = content.stages.find(
							(st) => item.stage && !st.isDeleted && st.id === item.stage.id && activeSpace?.spaceId === st.spaceId
						);
					}
					if (_stage) {
						_lanes.push({
							stage: _stage,
							items: [item],
							meetings: []
						});
					}
				}
			}
		});

		if (meetings?.length > 0 && activeSpace) {
			_lanes.unshift({
				stage: {
					id: MYAGENDAID,
					order: 0,
					title: t('My Meetings'),
					subtitle: '',
					backgroundColor: theme.primary,
					textColor: theme.primaryContrast,
					spaceId: activeSpace.spaceId,
					created_at: moment().toISOString(),
					updated_at: moment().toISOString(),
					published_at: moment().toISOString()
				},
				items: [],
				meetings
			});
		}

		_lanes.sort((a, b) => ((a.stage.order ? a.stage.order : 9999) < (b.stage.order ? b.stage.order : 99999) ? -1 : 1));

		const _vertLanes = _lanes
			.filter((lane) => !lane.stage.isCrossLane)
			.map((lane) => {
				const _vertLane: IVerticalGridLane = {
					items: [],
					stage: lane.stage
				};

				// Check if first item starts same as startTime
				let laneStart;
				if (lane.meetings.length > 0) {
					laneStart = lane.meetings[0].timeSlot.start;
				} else {
					laneStart = lane.items[0].startDate;
				}

				if (moment(earliest).isBefore(moment(laneStart))) {
					_vertLane.items.push({
						startDate: earliest,
						endDate: laneStart,
						isEmpty: true,
						itemHeight: heightPerMinute * moment.duration(moment(laneStart).diff(moment(earliest))).asMinutes()
					});
				}

				if (lane.meetings) {
					lane.meetings.forEach((meeting, idx) => {
						_vertLane.items.push({
							...meeting,
							itemHeight:
								heightPerMinute *
								moment.duration(moment(meeting.timeSlot.end).diff(moment(meeting.timeSlot.start))).asMinutes()
						});

						if (lane.meetings[idx + 1]) {
							if (lane.meetings[idx + 1].timeSlot.start !== meeting.timeSlot.end) {
								_vertLane.items.push({
									startDate: meeting.timeSlot.end,
									endDate: lane.meetings[idx + 1].timeSlot.start,
									isEmpty: true,
									itemHeight:
										heightPerMinute *
										moment
											.duration(moment(lane.meetings[idx + 1].timeSlot.start).diff(moment(meeting.timeSlot.end)))
											.asMinutes()
								});
							}
						}
					});
				}

				if (lane.items) {
					lane.items.forEach((item, idx) => {
						_vertLane.items.push({
							...item,
							itemHeight: heightPerMinute * moment.duration(moment(item.endDate).diff(moment(item.startDate))).asMinutes()
						});

						if (lane.items[idx + 1]) {
							if (lane.items[idx + 1].startDate !== item.endDate) {
								_vertLane.items.push({
									startDate: item.endDate,
									endDate: lane.items[idx + 1].startDate,
									isEmpty: true,
									itemHeight:
										heightPerMinute *
										moment.duration(moment(lane.items[idx + 1].startDate).diff(moment(item.endDate))).asMinutes()
								});
							}
						}
					});
				}

				return _vertLane;
			});

		let mostItems = 0;

		_vertLanes.forEach((lane) => {
			if (_vertLanes.length > 1) {
				_data.headers.push(lane.stage);
			}
			if (lane.items.length > mostItems) {
				mostItems = lane.items.length;
			}
		});

		for (let i = 0; i < mostItems; i++) {
			_vertLanes.forEach((lane) => {
				if (lane.items[i]) {
					_data.items.push(lane.items[i]);
				} else {
					_data.items.push(undefined);
				}
			});
		}

		setData({ ..._data, numColumns: _vertLanes.length });
	}, [items, meetings, activeSpace, heightPerMinute]);

	useEffect(() => {
		const now = moment();

		if (
			data.items.length > 0 &&
			mainListRef.current &&
			!hasJumpedToNow &&
			startTimeRef.current &&
			now.isSame(moment(startTimeRef.current), 'date')
		) {
			if (process.env.IS_TEST) {
				setHasJumpedToNow(true);
				return;
			}
			setHasJumpedToNow(true);
			const offset = heightPerMinute * moment.duration(now.diff(moment(startTimeRef.current))).asMinutes();

			setTimeout(() => {
				mainListRef.current?.scrollToOffset({ offset: offset - 150, animated: true });
			}, 1000);
		}
	}, [data]);

	const _setMediaDetail = (itemId: number) => {
		let _item;
		if (isPublic) {
			_item = items?.find((i) => i.id === itemId);
			_item.votes = [];
			navigation.navigate(ERoutes.Media, {
				itemId,
				mediaType: 'schedule',
				spaceId: activeSpace?.spaceId
			});
			return;
		}
		if (!isPublic) {
			navigation.navigate(ERoutes.Media, {
				itemId,
				mediaType: 'schedule',
				spaceId: activeSpace?.spaceId
			});
			setMediaDetail({
				itemId: undefined,
				viewType: 'full',
				playbackStatus: 'paused'
			});
			return;
		}
		if (mediaDetail.itemId) {
			if (mediaDetail.itemId === itemId) {
				setMediaDetailViewType('full');
			} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
				setMediaDetail({
					itemId,
					itemType: 'schedule',
					viewType: 'full',
					playbackStatus: 'paused',
					item: _item,
					previewImage: _item?.previewImage,
					isPublic: isPublic
				});
			} else {
				showAlert({
					title: t('Switch Stream'),
					message: t('Switch Stream Subtitle'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							style: 'destructive',
							text: t('Switch Stream'),
							onPress: () =>
								setMediaDetail({
									itemId,
									itemType: 'schedule',
									viewType: 'full',
									playbackStatus: 'paused',
									item: _item,
									previewImage: _item?.previewImage,
									isPublic: isPublic
								})
						}
					]
				});
			}
		} else {
			setMediaDetail({
				itemId,
				itemType: 'schedule',
				viewType: 'full',
				playbackStatus: 'paused',
				item: _item,
				previewImage: _item?.previewImage,
				isPublic: isPublic
			});
		}
	};

	return (
		<View
			style={{
				flex: 1,
				flexDirection: 'row',
				paddingVertical: 10,
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
			onLayout={(e) => setViewWidth(e.nativeEvent.layout.width - TIME_COLUMN_WIDTH)}
		>
			<View style={{ width: TIME_COLUMN_WIDTH, marginTop: data.numColumns > 1 ? laneColumnHeight : 0 }}>
				<FlashList
					ref={timeCellRef}
					scrollEnabled={false}
					data={timeCells}
					renderItem={({ item }) => (
						<VerticalGridTimeCell item={item} height={heightPerMinute * SCHEDULE_STEPS} width={TIME_COLUMN_WIDTH} />
					)}
					estimatedItemSize={heightPerMinute * SCHEDULE_STEPS}
				/>
			</View>
			<View style={{ flex: 1 }}>
				{data.headers.length > 0 ? (
					<View style={{ height: laneColumnHeight }}>
						<FlashList
							ref={laneHeaderRef}
							scrollEnabled={false}
							data={data.headers}
							renderItem={({ item }) => (
								<VerticalGridHeaderCell
									width={
										data.numColumns > GRID_SIZE_LANE_COUNT
											? widthPerStage
											: viewWidth / (data.numColumns > 0 ? data.numColumns : 1)
									}
									height={laneColumnHeight}
									imageWidth={
										data.numColumns > GRID_SIZE_LANE_COUNT
											? widthPerStage
											: viewWidth / (data.numColumns > 0 ? data.numColumns : 1)
									}
									item={item}
									testID=""
								/>
							)}
							horizontal
							estimatedItemSize={heightPerMinute * SCHEDULE_STEPS}
						/>
					</View>
				) : null}
				<ScrollView
					horizontal
					scrollEventThrottle={16}
					onScroll={(e) => laneHeaderRef.current?.scrollToOffset({ offset: e.nativeEvent.contentOffset.x, animated: false })}
					bounces={false}
				>
					<View
						style={{
							width: data.numColumns > GRID_SIZE_LANE_COUNT ? data.numColumns * widthPerStage : viewWidth,
							height: '100%'
						}}
					>
						{data.items.length > 0 ? (
							<MasonryFlashList
								ref={mainListRef}
								onScroll={(e) => {
									timeCellRef.current?.scrollToOffset({ animated: false, offset: e.nativeEvent.contentOffset.y });
								}}
								renderItem={({ item }) => {
									if (!item) {
										return null;
									}
									if ('isEmpty' in item) {
										return <View style={{ height: item.itemHeight, opacity: 0 }} />;
									}

									if ('timeSlot' in item) {
										return (
											<VerticalGridMeetingCell
												item={item}
												style={{
													height: item.itemHeight
												}}
												onPress={() =>
													navigation.navigate(ERoutes.Chat, {
														spaceId: activeSpace?.spaceId,
														userId: item.ownerId === profile?.userId ? item.partnerId : item.ownerId
													})
												}
											/>
										);
									}

									if ('startDate' in item) {
										return (
											<VerticalGridItemCell
												item={item}
												style={{
													height: item.itemHeight
												}}
												onPress={!item.hasNoDetails ? () => _setMediaDetail(item.id) : undefined}
												backgroundColor={item.stage?.backgroundColor}
												textColor={item.stage?.textColor}
												isMyDay={item.stage?.id === MYAGENDAID}
											/>
										);
									}

									return null;
								}}
								data={data.items}
								numColumns={data.numColumns}
								estimatedItemSize={120}
							/>
						) : null}
					</View>
				</ScrollView>
			</View>
		</View>
	);
};
