import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './src/config/locales/de.json';
import en from './src/config/locales/en.json';
import { LogBox, Platform, StatusBar, Text, View } from 'react-native';
import MyApp from './src/MyApp';
import * as Localization from 'expo-localization';
import { IS_WEB } from './src/helper/platform';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { store } from './src/rematch/store';
import './web/index.css';
import { Spinner } from './src/components/Spinner';
import * as Sentry from 'sentry-expo';
import { APP_LANGUAGE, SENTRY_DSN } from './src/config/envConstants';
import { enableScreens } from 'react-native-screens';
import { useQuery } from './src/hooks/useQuery';
import './global.ts';

enableScreens();

process.env.IS_TEST ? LogBox.ignoreAllLogs() : null;

Sentry.init({
	dsn: SENTRY_DSN,
	enableNative: !IS_WEB,
	enabled: !__DEV__
});

i18n.use(initReactI18next).init({
	lng: APP_LANGUAGE ?? Localization.locale.split('-')[0],
	resources: {
		de,
		en
	},
	compatibilityJSON: 'v3',
	keySeparator: false,
	fallbackLng: 'en',
	debug: false,
	interpolation: {
		escapeValue: false
	}
});

const WrappedApp = () => {
	const { isTabletOrMobile } = useQuery();

	return (
		<View style={{ height: IS_WEB && !isTabletOrMobile ? '100vh' : '100%' }}>
			<SafeAreaProvider style={{ paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0 }}>
				<PersistGate
					loading={
						<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
							<Spinner />
						</View>
					}
					persistor={getPersistor()}
				>
					<MyApp />
				</PersistGate>
			</SafeAreaProvider>
		</View>
	);
};

export default function App() {
	return (
		<View style={{ flex: 1 }}>
			<Provider store={store}>
				<WrappedApp />
			</Provider>
		</View>
	);
}
