import { alert } from './alert';
import { attendee } from './attendee';
import { auth } from './auth';
import { booking } from './booking';
import { chat } from './chat';
import { config } from './config';
import { content } from './content';
import { internalsettings } from './internalsettings';
import { meeting } from './meeting';
import { networking } from './networking';
import { like } from './like';
import { comment } from './comment';
import { pushNotification } from './pushNotification';
import { request } from './request';
import { selfservice } from './selfservice';
import { socket } from './socket';
import { space } from './space';
import { temp } from './temp';
import { ticket } from './ticket';
import { tracking } from './tracking';
import { upload } from './upload';
import { vote } from './vote';
import { subscription } from './subscription';
import { token } from './token';

import { Models } from '@rematch/core';

export interface RootModel extends Models<RootModel> {
	alert: typeof alert;
	attendee: typeof attendee;
	auth: typeof auth;
	booking: typeof booking;
	chat: typeof chat;
	config: typeof config;
	content: typeof content;
	internalsettings: typeof internalsettings;
	meeting: typeof meeting;
	networking: typeof networking;
	like: typeof like;
	comment: typeof comment;
	pushNotification: typeof pushNotification;
	request: typeof request;
	selfservice: typeof selfservice;
	socket: typeof socket;
	space: typeof space;
	subscription: typeof subscription;
	temp: typeof temp;
	ticket: typeof ticket;
	tracking: typeof tracking;
	upload: typeof upload;
	vote: typeof vote;
	token: typeof token;
}

export const models: RootModel = {
	alert,
	attendee,
	auth,
	booking,
	chat,
	config,
	content,
	comment,
	internalsettings,
	meeting,
	networking,
	like,
	pushNotification,
	request,
	selfservice,
	socket,
	space,
	subscription,
	temp,
	ticket,
	tracking,
	upload,
	vote,
	token
};
