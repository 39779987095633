import { createModel } from '@rematch/core';
import { MULTISPACEURL } from 'config/constants';
import { translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import { IResolveSelfServiceTokenPayload, IUpdateSelfServiceEntryPayload } from 'rematch/interfaces';
import { RootModel } from './index';

type IDefaultState = {};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const selfservice = createModel<RootModel>()({
	state: {} as IDefaultState,
	reducers: {},
	effects: (dispatch) => ({
		async resolveSelfServiceToken(payload: IResolveSelfServiceTokenPayload, store) {
			try {
				const { token } = payload;

				const body = new FormData();
				body.append(
					'data',
					JSON.stringify({
						token
					})
				);

				const res = await dispatch.request.anonymousRequest({
					url: `${MULTISPACEURL}/spaces/resolveselfservicetoken`,
					method: 'POST',
					body
				});

				if (res.space) {
					const attendeeBody = new FormData();
					attendeeBody.append(
						'data',
						JSON.stringify({
							spaces: {
								[res.space.spaceId]: ZEROHOUR
							}
						})
					);
					const attendeeRes = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/attendees`,
						body: attendeeBody
					});
					if (attendeeRes && Array.isArray(attendeeRes) && attendeeRes.length > 0) {
						res.attendees = attendeeRes[0].attendees;
					}
				}

				return res;
			} catch (error) {
				console.log('resolveSelfServiceToken', error);
			}
		},
		async updateSelfServiceEntry(payload: IUpdateSelfServiceEntryPayload, store) {
			try {
				const { token, space, fields, item } = payload;

				const body = new FormData();

				const tempMedia = {};

				fields.forEach((field) => {
					if ((field.fieldType === 'image' || field.fieldType === 'logo') && item[field.fieldName]) {
						tempMedia[field.fieldName] = item[field.fieldName];
						delete item[field.fieldName];
					}
					if (field.fieldType === 'gallery' && item[field.fieldName]) {
						tempMedia[field.fieldName] = item[field.fieldName];
						item[field.fieldName] = [];
					}
				});

				const keys = Object.keys(tempMedia);

				if (keys.length > 0) {
					for (const key of keys) {
						if (Array.isArray(tempMedia[key])) {
							for (let i = 0; i < tempMedia[key].length; i++) {
								let mediaObj = tempMedia[key][i];
								if (key === 'imageGallery') {
									if (typeof mediaObj.media !== 'number') {
										if (
											typeof mediaObj.media === 'string' ||
											mediaObj.media?.url?.startsWith('file://') ||
											mediaObj.media?.url?.startsWith('data:')
										) {
											let imageId;
											if (typeof mediaObj !== 'string') {
												imageId = mediaObj.id;
											}

											const imageResponse = await dispatch.upload.uploadMedia({
												endpoint: MULTISPACEURL,
												uri: typeof mediaObj.media === 'string' ? mediaObj.media : mediaObj.media.url,
												imageId,
												space,
												caption: typeof mediaObj.media === 'object' ? mediaObj.media.caption : undefined
											});

											if (imageResponse) {
												item[key][i] = {
													...mediaObj,
													media: imageResponse[0]
												};
											}
										} else {
											item[key][i] = mediaObj;
										}
									} else if (tempMedia[key]) {
										const libEntry = store.upload.spaceMedia?.find((e) => e.id === mediaObj.media);
										if (libEntry) {
											item[key][i] = {
												...mediaObj,
												media: { ...libEntry }
											};
										}
									}
								}
							}
						} else if (typeof tempMedia[key] !== 'number') {
							if (
								typeof tempMedia[key] === 'string' ||
								tempMedia[key].url?.startsWith('file://') ||
								tempMedia[key].url?.startsWith('data:')
							) {
								let imageId;
								if (typeof tempMedia[key] !== 'string') {
									imageId = tempMedia[key].id;
								}

								const mediaResponse = await dispatch.upload.uploadMedia({
									endpoint: MULTISPACEURL,
									uri: typeof tempMedia[key] === 'string' ? tempMedia[key] : tempMedia[key].url,
									imageId,
									space,
									caption: typeof tempMedia[key] === 'object' ? tempMedia[key].caption : undefined
								});
								if (mediaResponse && Array.isArray(mediaResponse) && mediaResponse.length > 0) {
									item[key] = mediaResponse[0];
								}
							}
						} else {
							const libEntry = store.upload.spaceMedia?.find((e) => e.id === tempMedia[key]);
							if (libEntry) {
								item[key] = libEntry;
							}
						}
					}
				}

				body.append(
					'data',
					JSON.stringify({
						item,
						token
					})
				);

				const res = await dispatch.request.anonymousRequest({
					method: 'PUT',
					url: `${MULTISPACEURL}/selfservice`,
					body
				});

				if (res?.item) {
					showToast('success', i18next.t('EntryUpdated'));
					return res.item;
				}

				showToast('error', translateStrapiError(res.error));
				return undefined;
			} catch (error) {
				showToast('error', JSON.stringify(error));
				return undefined;
			}
		}
	})
});
