import React, { useEffect, useState } from 'react';
import { ICreateSpaceFormErrors, ICreateSpaceFormValues } from 'config/interfaces';
import { FormCalendarPicker, FormFromUntilDatePicker, FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';
import i18next from 'i18next';
import { HSCard } from 'components/Card';
import { Keyboard, ScrollView } from 'react-native';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment';
import { IS_WEB } from 'helper';
import { hsBottomMargin } from 'config/styleConstants';

interface ICreateSpaceForm {
	values: ICreateSpaceFormValues;
	errors: ICreateSpaceFormErrors;
	onChange: (val: ICreateSpaceFormValues) => void;
	isDisabled?: boolean;
	testID: string;
}

export const CreateSpaceForm = (props: ICreateSpaceForm) => {
	const { values, errors, onChange, isDisabled, testID } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const [fromDate, setFromDate] = useState<string | undefined>(values.startDate);
	const [untilDate, setUntilDate] = useState<string | undefined>(values.endDate);

	useEffect(() => {
		if (IS_WEB) {
			onChange({
				...values,
				startDate: moment(fromDate).set('hours', 0).set('minutes', 0).set('seconds', 0).toISOString(true),
				endDate: moment(untilDate).set('hours', 23).set('minutes', 59).set('seconds', 59).toISOString(true)
			});
		}
	}, [fromDate, untilDate]);

	const _renderPicker = () => {
		if (IS_WEB) {
			return (
				<FormCalendarPicker
					label={t('SpaceStartEndDate')}
					hint={t('SpaceStartEndDateHint')}
					testID={`${testID}_calendar_date`}
					startDate={fromDate}
					endDate={untilDate}
					minDate={moment().toISOString()}
					allowRangeSelection
					isDisabled={isDisabled}
					onStartDateChange={(date) => setFromDate(date ?? undefined)}
					onEndDateChange={(date) => setUntilDate(date ?? undefined)}
					onReset={() => {
						setFromDate(undefined);
						setUntilDate(undefined);
						onChange({ ...values, startDate: '' });
						onChange({ ...values, endDate: '' });
					}}
				/>
			);
		}
		return (
			<FormFromUntilDatePicker
				testIdPrefix={`${testID}_datepicker`}
				fromFieldName="startDate"
				fromLabel={t('StartDate')}
				from={values.startDate}
				fromError={errors.startDate}
				untilFieldName="endDate"
				untilLabel={t('EndDate')}
				until={values.endDate}
				untilError={errors.endDate}
				label={t('SpaceStartEndDate')}
				isRequired
				isDisabled={isDisabled}
				hint={t('SpaceStartEndDateHint')}
				mode="date"
				onChange={(field, value) => {
					let val = value;
					if (val) {
						if (field === 'startDate') {
							val = moment(val).set('hours', 0).set('minutes', 0).set('seconds', 0).toISOString(true);
						} else {
							val = moment(val).set('hours', 23).set('minutes', 59).set('seconds', 59).toISOString(true);
						}
					}
					onChange({ ...values, [field]: val });
				}}
			/>
		);
	};

	return (
		<ScrollView
			keyboardShouldPersistTaps="handled"
			onScrollBeginDrag={() => Keyboard.dismiss()}
			scrollEventThrottle={0}
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingTop: hsBottomMargin
			}}
		>
			<HSCard>
				<FormTextInput
					testID={`${testID}_textinput_title`}
					label={t('Space Title')}
					isRequired
					hint={t('spaceTitleHint')}
					value={values.title}
					error={errors.title}
					isDisabled={isDisabled}
					onChangeText={(value) => onChange({ ...values, title: value, sidebarTitle: value })}
					onEndEditing={(value) => {
						if (IS_WEB) {
							return;
						}
						if (!values.spaceId) {
							onChange({
								...values,
								spaceId: value?.nativeEvent?.text
									?.replace(/\s/g, '')
									?.replace(/[^a-zA-Z0-9 ]/g, '')
									?.substring(0, 15)
									?.toLowerCase()
							});
						}
					}}
					onBlur={(value) => {
						if (!IS_WEB) {
							return;
						}
						if (!values.spaceId) {
							onChange({
								...values,
								spaceId: value?.nativeEvent?.text
									?.replace(/\s/g, '')
									?.replace(/[^a-zA-Z0-9 ]/g, '')
									?.substring(0, 15)
									?.toLowerCase()
							});
						}
					}}
					skipTabPress
				/>
				{_renderPicker()}

				<FormMultiSwitch
					testID={`${testID}_multiswitch_language`}
					label={t('Language')}
					hint={t('LanguageHint')}
					error={errors.language}
					value={values.language}
					options={[
						{
							key: 'de',
							label: 'Deutsch'
						},
						{
							key: 'en',
							label: 'English'
						}
					]}
					onChange={(val) => {
						onChange({ ...values, language: val });
						i18next.changeLanguage(val);
					}}
					isDisabled={isDisabled}
				/>
			</HSCard>
		</ScrollView>
	);
};
