import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { View } from 'react-native';

import { RoundButton } from 'components/Button';
import { FormTextInput } from 'components/Form/FormTextInput';
import { EDefaultIconSet, isEmptyString, IS_ANDROID } from 'helper';
import { YouTubePlayer } from 'components/Video';

interface IStreamInputYouTube {
	testID: string;
	onChange: (youtTubeUrl: string | null) => void;
	value?: string;
	error?: string;
}

export const StreamInputYouTube = (props: IStreamInputYouTube) => {
	const { testID, onChange, value, error } = props;

	const [input, setInput] = useState<string>('');
	const [validatedUrl, setValidatedUrl] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (value) {
			setInput(value);
			setValidatedUrl(value);
		}
	}, []);

	const _validateUrl = () => {
		if (!input.startsWith('https://') && !input.startsWith('www')) {
			_setValidatedUrl(input);
		} else if (input.startsWith('https://www.youtube.com/watch') || input.startsWith('https://youtube.com/watch')) {
			let splitted: string[] = [];
			if (input.startsWith('https://www.youtube.com/watch')) {
				splitted = input.split('https://www.youtube.com/watch?');
			} else {
				splitted = input.split('https://youtube.com/watch?');
			}

			const splittedParams = splitted[1].split('&');
			for (const part of splittedParams) {
				const split = part.split('=');
				if (split[0] === 'v') {
					_setValidatedUrl(split[1]);
				}
			}
		} else if (input.startsWith('https://youtu.be/') || input.startsWith('https://www.youtu.be/')) {
			const splitted = input.split('?');
			let split: string[] = [];
			if (input.startsWith('https://youtu.be/')) {
				split = splitted[0].split('https://youtu.be/');
			} else {
				split = splitted[0].split('https://www.youtu.be/');
			}
			_setValidatedUrl(split[1]);
		} else if (input.startsWith('https://www.youtube.com/embed/')) {
			const splitted = input.split('https://www.youtube.com/embed/');
			_setValidatedUrl(splitted[1]);
		} else {
			setValidatedUrl(input);
		}
	};

	const _setValidatedUrl = (videoId: string) => {
		if (videoId.includes('?v=')) {
			videoId = videoId.split('?v=')[1];
		}
		if (videoId.includes('?')) {
			videoId = videoId.split('?')[0];
		}

		const embedUrl = `https://www.youtube.com/embed/${videoId}`;
		setValidatedUrl(embedUrl);
		onChange(embedUrl);
	};

	const _renderPlayer = () => {
		if (!isEmptyString(validatedUrl) && !IS_ANDROID) {
			return (
				<YouTubePlayer
					url={validatedUrl} // The YouTube video ID
				/>
			);
		}
		return null;
	};

	return (
		<View>
			<FormTextInput
				testID={`${testID}_textinput_url`}
				label="URL"
				isRequired
				hint={t('YouTubeUrlHint')}
				value={input}
				error={error}
				onChangeText={(val) => {
					setInput(val);
					setValidatedUrl(undefined);
					onChange(null);
				}}
				formStyle={{ marginBottom: 10 }}
			/>
			<RoundButton
				testID={`${testID}_button_validate`}
				icon={EDefaultIconSet.Save}
				onPress={_validateUrl}
				title={t('Validate')}
				isDisabled={isEmptyString(input)}
				alignSelf="flex-end"
			/>

			{_renderPlayer()}
		</View>
	);
};
