import React, { useState, useEffect, useRef } from 'react';

import {
	ConsoleLogger,
	DefaultActiveSpeakerPolicy,
	DefaultBrowserBehavior,
	DefaultDeviceController,
	DefaultMeetingReadinessChecker,
	DefaultMeetingSession,
	DefaultModality,
	DefaultVideoTransformDevice,
	LogLevel,
	MeetingSessionConfiguration,
	VoiceFocusDeviceTransformer,
	VoiceFocusTransformDevice
} from 'amazon-chime-sdk-js';
import { INetworkingRoom } from 'config/interfaces';

import { INetworkingRoomAttendee, INetworkingRoomMeeting } from 'rematch/interfaces';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { NetworkingRoomAttendeeVideo } from './NetworkingRoomAttendeeVideo';
import { NetworkingRoomAttendeeAudio } from './NetworkingRoomAttendeeAudio';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { NetworkingRoomControleButton } from './NetworkingRoomControleButton';
import { EDefaultIconSet, IS_WEB } from 'helper';
import { NetworkingRoomHelpModal, NetworkingRoomSettingsModal } from 'components/Modal/Networking';
import { useTranslation } from 'react-i18next';
import { NetworkingRoomContentShare } from './NetworkingRoomContentShare';
import { playSound } from 'helper/sound';
import { showToast } from 'helper/toast';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as tf from '@tensorflow/tfjs';
import { BlurBackgroundProcerssor } from './BlurBackgroundProcessor';
import { HSCard } from 'components/Card';

export type TBackgroundBlur = 'off' | 'strong' | 'light';
// IS_WEB && tf.setBackend('webgl');

interface INetworkingRoomComponent {
	item: INetworkingRoom;
	connection: {
		attendee: INetworkingRoomAttendee;
		meeting: INetworkingRoomMeeting;
	};
}

export interface IRosterMember {
	tileId?: number;
	internalVideoElementId?: number;
	boundAttendeeId: string;
	boundExternalUserId: string;
	hasCameraOn?: boolean;
	isSharingContent?: boolean;
	isMuted?: boolean;
	isActiveSpeaker?: boolean;
}

const MAXCONTENTSHARER = 1;
const ATTENDEEVIDEOBEGININDEX = 2;
const MAXVIDEOELEMENTS = 16;
const TESTIDPREFIX = 'networkingroom';

export const NetworkingRoom = (props: INetworkingRoomComponent) => {
	const { item, connection } = props;
	const { isPhone, isTablet, isWeb, isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const { t } = useTranslation();
	const navigation = useNavigation();

	const logger = new ConsoleLogger('MyLogger', LogLevel.ERROR);
	const browserBehavior = new DefaultBrowserBehavior();

	const rosterRef = useRef<Record<string, IRosterMember>>({});
	const meetingSessionRef = useRef<DefaultMeetingSession>();
	const meetingReadinessCheckerRef = useRef<DefaultMeetingReadinessChecker>();
	const deviceChangeObserverRef = useRef<any>(null);
	const contentShareObserverRef = useRef<any>(null);

	const audioInputDevicesRef = useRef<MediaDeviceInfo[]>([]);
	const audioOutputDevicesRef = useRef<MediaDeviceInfo[]>([]);
	const videoInputDevicesRef = useRef<MediaDeviceInfo[]>([]);
	const voiceFocusDeviceRef = useRef<VoiceFocusTransformDevice>();

	const selectedAudioInputDeviceRef = useRef<MediaDeviceInfo | undefined>(undefined);
	const selectedAudioOutputDeviceRef = useRef<MediaDeviceInfo | undefined>(undefined);
	const selectedVideoInputDeviceRef = useRef<MediaDeviceInfo | undefined>(undefined);

	const [voiceFocusTransformer, setVoiceFocusTransformer] = useState<VoiceFocusDeviceTransformer | undefined>(undefined);
	const [meetingSession, _setMeetingSession] = useState<DefaultMeetingSession | undefined>(undefined);
	const [meetingReadinessChecker, _setMeetingReadinessChecker] = useState<DefaultMeetingReadinessChecker | undefined>(undefined);

	const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
	const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

	const [audioInputDevices, _setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
	const [audioOutputDevices, _setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);
	const [videoInputDevices, _setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);

	const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<MediaDeviceInfo | undefined>(undefined);
	const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<MediaDeviceInfo | undefined>(undefined);
	const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<MediaDeviceInfo | undefined>(undefined);
	const [blurBackground, setBlurBackground] = useState<TBackgroundBlur>('off');
	const [isBlurDisabled, setIsBlurDisabled] = useState<boolean>(false);

	const [roster, _setRoster] = useState<Record<string, IRosterMember>>({});
	const [isMuted, setIsMuted] = useState<boolean>(false);
	const [hideMyVideo, setHideMyVideo] = useState<boolean>(false);
	const [isContentShared, setIsContentShared] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);

	const sendNetworkingRoomHeartbeat = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.sendNetworkingRoomHeartbeat);
	const setActiveNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.setActiveNetworkingRoom);
	const addMyselfToNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.addMyselfToNetworkingRoom);
	const leaveNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.leaveNetworkingRoom);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		_configure();

		sendNetworkingRoomHeartbeat({});

		const roomHeartbeatInterval = setInterval(() => {
			sendNetworkingRoomHeartbeat({});
		}, 20000);

		return () => {
			_leaveNetworkingRoom();
		};
	}, []);

	useEffect(() => {
		const handleTabOrWindowClose = (event) => {
			event.preventDefault();

			return (event.returnValue = `${t('NetworkingCloseTabOrWindowAlertMessage')}`);
		};

		// window.addEventListener('beforeunload', handleTabOrWindowClose);

		return () => {
			window.removeEventListener('beforeunload', handleTabOrWindowClose);
		};
	}, []);

	useEffect(() => {
		_selectVideoInputDevice(selectedVideoInputDevice);
	}, [blurBackground]);

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				_leaveNetworkingRoom();
			};
		}, [])
	);

	const setRoster = (updatedRoster) => {
		rosterRef.current = updatedRoster;
		_setRoster(updatedRoster);
	};

	const setMeetingSession = (updatedMeetingSession) => {
		meetingSessionRef.current = updatedMeetingSession;
		navigation.setOptions({
			onBack: () => _leaveNetworkingRoom()
		});
		_setMeetingSession(updatedMeetingSession);
	};

	const setMeetingReadinessChecker = (checker) => {
		meetingReadinessCheckerRef.current = checker;
		_setMeetingReadinessChecker(checker);
	};

	const setAudioInputDevices = async (freshDeviceList: MediaDeviceInfo[]) => {
		audioInputDevicesRef.current = freshDeviceList;
		const stillPresent = freshDeviceList.find((e) => e.deviceId === selectedAudioInputDeviceRef.current?.deviceId);
		if (!stillPresent) {
			await _selectAudioInputDevice(freshDeviceList[0]);
		}
		_setAudioInputDevices(freshDeviceList);
	};

	const setAudioOutputDevices = async (freshDeviceList: MediaDeviceInfo[]) => {
		audioOutputDevicesRef.current = freshDeviceList;
		const stillPresent = freshDeviceList.find((e) => e.deviceId === selectedAudioOutputDeviceRef.current?.deviceId);
		if (!stillPresent) {
			await _selectAudioOutputDevice(freshDeviceList[0]);
		}
		_setAudioOutputDevices(freshDeviceList);
	};

	const setVideoInputDevices = async (freshDeviceList: MediaDeviceInfo[]) => {
		videoInputDevicesRef.current = freshDeviceList;
		const stillPresent = freshDeviceList.find((e) => e.deviceId === selectedVideoInputDeviceRef.current?.deviceId);
		if (!stillPresent) {
			await _selectVideoInputDevice(freshDeviceList[0]);
		}
		_setVideoInputDevices(freshDeviceList);
	};

	const _configure = async () => {
		await tf.setBackend('webgl');
		const isVoiceFocusSupported = await VoiceFocusDeviceTransformer.isSupported();

		const configuration = new MeetingSessionConfiguration(connection.meeting, connection.attendee);
		const deviceController = new DefaultDeviceController(logger, {
			enableWebAudio: isVoiceFocusSupported
		});

		const _meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

		if (isVoiceFocusSupported) {
			const _voiceFocusTransformer = await VoiceFocusDeviceTransformer.create(
				{ name: 'default' },
				{ logger },
				undefined,
				connection.meeting.Meeting,
				connection.attendee.Attendee
			);
			setVoiceFocusTransformer(_voiceFocusTransformer);
		}

		const deviceChangeObserver = {
			audioInputsChanged: async (freshDeviceList: MediaDeviceInfo[]) => {
				setAudioInputDevices(freshDeviceList.filter((e) => e.label !== 'Standard'));
			},
			audioOutputsChanged: async (freshDeviceList: MediaDeviceInfo[]) => {
				setAudioOutputDevices(freshDeviceList);
			},
			videoInputsChanged: async (freshDeviceList: MediaDeviceInfo[]) => {
				setVideoInputDevices(freshDeviceList);
			}
		};

		_meetingSession.audioVideo.addDeviceChangeObserver(deviceChangeObserver);
		deviceChangeObserverRef.current = deviceChangeObserver;

		const _audioInputDevices = await _meetingSession.audioVideo.listAudioInputDevices();
		const _audioOutputDevices = await _meetingSession.audioVideo.listAudioOutputDevices();
		const _videoInputDevices = await _meetingSession.audioVideo.listVideoInputDevices();

		const audioVideoObserver = {
			audioVideoDidStart: () => {
				if (!item.isAudioOnly) {
					meetingSessionRef.current?.audioVideo.startLocalVideoTile();
				}
			},
			audioVideoDidStop: async (sessionStatus) => {
				const sessionStatusCode = sessionStatus.statusCode();

				switch (sessionStatusCode) {
					default:
						console.log('Stopped with a session status code: ', sessionStatusCode);
						break;
				}
			},
			audioVideoDidStartConnecting: (reconnecting) => {}
		};
		_meetingSession.audioVideo.addObserver(audioVideoObserver);

		_meetingSession.audioVideo.subscribeToActiveSpeakerDetector(new DefaultActiveSpeakerPolicy(), (ids) => {
			if (ids.length > 0) {
				const loudestSpeakerId = ids[0];

				const _roster = { ...rosterRef.current };
				Object.keys(_roster).forEach((key) => {
					_roster[key].isActiveSpeaker = loudestSpeakerId === key;
				});

				setRoster(_roster);
			}
		});
		_meetingSession.audioVideo.bindAudioElement(document.getElementById('audio') as HTMLAudioElement);

		const videoObserver = {
			videoTileDidUpdate: async (tileState) => {
				if (!tileState.boundAttendeeId || (tileState.boundExternalUserId === userInfos.userId && tileState.isContent)) {
					return;
				}

				if (tileState.boundAttendeeId === connection.attendee.Attendee.AttendeeId) {
					try {
						_meetingSession.audioVideo.bindVideoElement(
							tileState.tileId,
							document.getElementById(`attendeevideo_1`) as HTMLVideoElement
						);
					} catch (error) {
						console.log(error);
					}
				} else {
					try {
						const res = await _acquireVideoElement(
							tileState.tileId,
							tileState.boundAttendeeId,
							tileState.boundExternalUserId,
							tileState.isContent
						);
						if (res) {
							_meetingSession.audioVideo.bindVideoElement(tileState.tileId, res as HTMLVideoElement);
						}
					} catch (error) {
						console.log(error);
					}
				}
			},
			videoTileWasRemoved: (tileId) => {
				const _roster = { ...rosterRef.current };
				Object.keys(_roster).forEach((key) => {
					if (_roster[key].tileId === tileId) {
						_roster[key].isSharingContent = false;
						_roster[key].hasCameraOn = false;
						delete _roster[key].tileId;
						delete _roster[key].internalVideoElementId;
					}
				});

				setRoster(_roster);
			}
		};
		_meetingSession.audioVideo.addObserver(videoObserver);

		const contentShareObserver = {
			contentShareDidStart: () => setIsContentShared(true),
			contentShareDidStop: () => setIsContentShared(false)
		};

		_meetingSession.audioVideo.addContentShareObserver(contentShareObserver);
		contentShareObserverRef.current = contentShareObserver;

		_meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present, externalUserId) => {
			_meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
				presentAttendeeId,
				(attendeeId, volume, muted, signalStrength) => {
					const baseAttendeeId = new DefaultModality(attendeeId).base();
					if (muted !== null) {
						const _roster = { ...rosterRef.current };
						Object.keys(_roster).forEach((key) => {
							if (_roster[key].boundAttendeeId === baseAttendeeId) {
								_roster[key].isMuted = muted;
							}
						});

						setRoster(_roster);
					}
				}
			);

			if (externalUserId) {
				const _roster = { ...rosterRef.current };
				if (!present) {
					delete _roster[presentAttendeeId];
					playSound('userLeave');
				} else if (presentAttendeeId !== connection.attendee.Attendee.AttendeeId) {
					if (!_roster[presentAttendeeId]) {
						playSound('userJoin');
						_roster[presentAttendeeId] = {
							tileId: undefined,
							boundAttendeeId: presentAttendeeId,
							boundExternalUserId: externalUserId.split('_')[1],
							hasCameraOn: false,
							isSharingContent: false,
							isMuted: item.isMuteOnEnter === true,
							isActiveSpeaker: false
						};
					}
				}

				setRoster(_roster);
			}
		});

		setMeetingReadinessChecker(new DefaultMeetingReadinessChecker(logger, _meetingSession));
		setMeetingSession(_meetingSession);

		setAudioInputDevices(_audioInputDevices);
		setAudioOutputDevices(_audioOutputDevices);
		setVideoInputDevices(_videoInputDevices);

		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 500);
		});

		await _selectDevices(_audioInputDevices[0], _audioOutputDevices[0], _videoInputDevices[0]);

		if (item.isMuteOnEnter) {
			_muteLocalAudio(false);
		}

		_addMyselfToNetworkingRoom();

		_meetingSession.audioVideo.start();
	};

	const _addMyselfToNetworkingRoom = () => {
		addMyselfToNetworkingRoom({
			networkingRoomId: item?.id,
			attendee: {
				AttendeeId: connection.attendee.Attendee.AttendeeId,
				ExternalUserId: connection.attendee.Attendee.ExternalUserId
			}
		});
	};

	const _leaveNetworkingRoom = async () => {
		if (isContentShared) {
			meetingSessionRef.current?.audioVideo.stopContentShare();
		}

		await meetingSessionRef.current?.audioVideo.stopAudioInput();
		await meetingSessionRef.current?.audioVideo.stopVideoInput();

		meetingSessionRef.current?.audioVideo.removeDeviceChangeObserver(deviceChangeObserverRef.current);
		meetingSessionRef.current?.audioVideo.removeContentShareObserver(contentShareObserverRef.current);
		await new Promise((resolve) => {
			meetingSessionRef.current?.audioVideo.stop();
			setTimeout(() => {
				resolve(true);
			}, 500);
		});

		leaveNetworkingRoom({
			networkingRoomId: item?.id,
			attendee: {
				AttendeeId: connection.attendee.Attendee.AttendeeId,
				ExternalUserId: connection.attendee.Attendee.ExternalUserId
			}
		});
		setActiveNetworkingRoom(undefined);
	};

	const _selectDevices = async (
		audioInputDevice?: MediaDeviceInfo,
		audioOutputDevice?: MediaDeviceInfo,
		videoInputDevice?: MediaDeviceInfo
	) => {
		await _selectAudioInputDevice(audioInputDevice);
		await _selectAudioOutputDevice(audioOutputDevice);
		await _selectVideoInputDevice(videoInputDevice);
	};

	const _selectAudioInputDevice = async (deviceInfo?: MediaDeviceInfo) => {
		if (meetingSessionRef.current) {
			const _deviceId = deviceInfo?.deviceId ?? null;
			if (voiceFocusTransformer && _deviceId) {
				if (!voiceFocusDeviceRef.current) {
					voiceFocusDeviceRef.current = await voiceFocusTransformer.createTransformDevice(_deviceId);
				} else {
					voiceFocusDeviceRef.current = await voiceFocusDeviceRef.current.chooseNewInnerDevice(_deviceId);
				}
			} else {
				await meetingSessionRef.current.audioVideo.startAudioInput(_deviceId);
			}
			if (deviceInfo && selectedAudioInputDeviceRef.current?.deviceId !== deviceInfo.deviceId) {
				showToast('custom', undefined, `${t('Switching to')} ${deviceInfo.label}`, EDefaultIconSet.Microphone);
			}
			setSelectedAudioInputDevice(deviceInfo);
			selectedAudioInputDeviceRef.current = deviceInfo;
		}
	};

	const _selectAudioOutputDevice = async (deviceInfo?: MediaDeviceInfo) => {
		if (meetingSessionRef.current) {
			const _deviceId = deviceInfo?.deviceId ?? null;

			await meetingSessionRef.current.audioVideo.chooseAudioOutput(_deviceId);
			setSelectedAudioOutputDevice(deviceInfo);
			selectedAudioOutputDeviceRef.current = deviceInfo;
			if (deviceInfo && selectedAudioOutputDeviceRef.current?.deviceId !== deviceInfo.deviceId) {
				showToast('custom', undefined, `${t('Switching to')} ${deviceInfo.label}`, EDefaultIconSet.Speakers);
			}
		}
	};

	const _selectVideoInputDevice = async (deviceInfo?: MediaDeviceInfo) => {
		if (meetingSessionRef.current) {
			const _deviceId = deviceInfo?.deviceId ?? null;
			if (_deviceId) {
				if (blurBackground !== 'off' && IS_WEB) {
					setIsBlurDisabled(true);
					let blurDevice;
					await new Promise(async (resolve) => {
						blurDevice = await new DefaultVideoTransformDevice(logger, _deviceId, [
							await new BlurBackgroundProcerssor({ blur: blurBackground })
						]);
						setTimeout(() => {
							resolve(true);
						}, 500);
					});
					await meetingSessionRef.current.audioVideo.startVideoInput(blurDevice);
					setIsBlurDisabled(false);
				} else {
					await meetingSessionRef.current.audioVideo.startVideoInput(_deviceId);
				}
			}
			setSelectedVideoInputDevice(deviceInfo);

			if (!_deviceId) {
				meetingSessionRef.current.audioVideo.stopLocalVideoTile();
			} else {
				meetingSessionRef.current.audioVideo.startLocalVideoTile();
			}
			if (deviceInfo && selectedVideoInputDeviceRef.current?.deviceId !== deviceInfo.deviceId) {
				showToast('custom', undefined, `${t('Switching to')} ${deviceInfo.label}`, EDefaultIconSet.CameraOn);
			}
			selectedVideoInputDeviceRef.current = deviceInfo;
		}
	};

	const _muteLocalAudio = (currentlyMuted?: boolean) => {
		if (currentlyMuted) {
			meetingSessionRef.current?.audioVideo.realtimeUnmuteLocalAudio();
			setIsMuted(false);
		} else {
			meetingSessionRef.current?.audioVideo.realtimeMuteLocalAudio();
			setIsMuted(true);
		}
	};

	const _acquireVideoElement = async (
		tileId: number,
		boundAttendeeId: string,
		boundExternalUserId: string,
		isSharingContent: boolean
	) => {
		const _roster = { ...rosterRef.current };
		let element: HTMLElement | null = null;

		const bound = Object.values(_roster).find((e) => e.tileId === tileId);
		if (bound) {
			_roster[boundAttendeeId].hasCameraOn = true;
			_roster[boundAttendeeId].isSharingContent = isSharingContent;
			if (isSharingContent) {
				element = document.getElementById('attendeevideo_content1');
			} else {
				element = document.getElementById(`$attendeevideo_${bound.internalVideoElementId}`);
			}
		} else {
			if (!_roster[boundAttendeeId]) {
				_roster[boundAttendeeId] = {
					boundAttendeeId,
					boundExternalUserId: boundExternalUserId.split('_')[1],
					isMuted: item.isMuteOnEnter === true
				};
			}
			_roster[boundAttendeeId].tileId = tileId;
			_roster[boundAttendeeId].hasCameraOn = true;
			_roster[boundAttendeeId].isSharingContent = isSharingContent;

			if (isSharingContent) {
				element = document.getElementById('attendeevideo_content1');
			} else {
				for (let i = ATTENDEEVIDEOBEGININDEX; i < ATTENDEEVIDEOBEGININDEX + MAXVIDEOELEMENTS; i++) {
					const found = Object.values(_roster).find((e) => e.internalVideoElementId === i);
					if (!found) {
						_roster[boundAttendeeId].internalVideoElementId = i;
						element = document.getElementById(`attendeevideo_${i}`);
						break;
					}
				}
			}
		}

		setRoster(_roster);
		return element;
	};

	const _getWidth = (activeElements: number, isContentShareActive: boolean) => {
		let str = '';

		if (isContentShareActive) {
			return 100;
		}

		if (isPhone) {
			return '100%';
		}

		switch (activeElements) {
			case 1:
				str = '100%';
				break;
			case 2:
			case 3:
			case 4:
				str = isPhone ? '50%' : '50%';
				break;
			case 5:
			case 6:
				str = isPhone ? '100%' : isTablet ? '50%' : '33%';
				break;
			default:
				str = isPhone ? '100%' : isTablet ? '50%' : '25%';
				break;
		}

		return str;
	};

	const _getHeight = (activeElements: number, isContentShareActive: boolean) => {
		let str = '';

		if (isContentShareActive) {
			return '100%';
		}

		switch (activeElements) {
			case 1:
			case 2:
				str = !isTabletOrMobile ? '100%' : '';
				break;
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
			case 8:
				str = !isTabletOrMobile ? '50%' : '';
				break;
			case 9:
			case 10:
			case 11:
				str = !isTabletOrMobile ? '33%' : '';
				break;
			default:
				str = !isTabletOrMobile ? '25%' : '';
				break;
		}

		return str;
	};

	const _renderAttendees = () => {
		const rosterEntries = Object.values(rosterRef.current);

		if (item.isAudioOnly) {
			return (
				<View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
					<NetworkingRoomAttendeeAudio
						rosterEntry={{
							boundExternalUserId: connection.attendee.Attendee.ExternalUserId.split('_')[1],
							boundAttendeeId: connection.attendee.Attendee.AttendeeId,
							isMuted,
							tileId: undefined,
							hasCameraOn: false,
							isActiveSpeaker: false,
							isSharingContent: false
						}}
					/>
					{rosterEntries.map((entry) => {
						return <NetworkingRoomAttendeeAudio key={entry.boundAttendeeId} rosterEntry={entry} />;
					})}
				</View>
			);
		}

		const sharedScreenElements: JSX.Element[] = [];
		const attendeeVideoElements: JSX.Element[] = [];

		const sharingUsers = rosterEntries.filter((item) => item.boundAttendeeId.includes('#content'));

		for (let i = 0; i < MAXCONTENTSHARER; i++) {
			const key = `attendeevideo_content${i + 1}`;
			const _item = rosterEntries.find((item) => item.boundAttendeeId.includes('#content'));
			sharedScreenElements.push(<NetworkingRoomContentShare key={key} id={key} rosterEntry={_item} />);
		}

		let activeElements = rosterEntries.filter((e) => e.tileId && !e.boundAttendeeId.includes('#content') && e.hasCameraOn).length;

		if (!hideMyVideo && !item.isAudioOnly && selectedVideoInputDevice) {
			activeElements += 1;
		}

		const isContentShareActive = sharingUsers.length > 0;

		for (let i = ATTENDEEVIDEOBEGININDEX; i < ATTENDEEVIDEOBEGININDEX + MAXVIDEOELEMENTS; i++) {
			const _item = rosterEntries.find((item) => item.internalVideoElementId === i);
			const key = `attendeevideo_${i}`;

			if (!_item?.boundAttendeeId.includes('#content')) {
				attendeeVideoElements.push(
					<NetworkingRoomAttendeeVideo
						id={key}
						key={key}
						rosterEntry={_item}
						isContentShareActive={isContentShareActive}
						width={_getWidth(activeElements, isContentShareActive)}
						height={_getHeight(activeElements, isContentShareActive)}
					/>
				);
			}
		}

		const audioElements = rosterEntries
			.filter((e) => !e.internalVideoElementId && !e.boundAttendeeId.includes('#content'))
			.map((e) => <NetworkingRoomAttendeeAudio key={e.boundAttendeeId} rosterEntry={e} />);

		const contentSharer = Object.values(rosterRef.current).filter((entry: IRosterMember) => entry.isSharingContent);
		const imSharingContent = contentSharer.find((entry: IRosterMember) => entry.boundExternalUserId === userInfos.userId);

		if (hideMyVideo || item.isAudioOnly || !selectedVideoInputDevice) {
			attendeeVideoElements.push(<video key="attendeevideo_1" id="attendeevideo_1" style={{ width: 0 }} />);
			audioElements.unshift(
				<NetworkingRoomAttendeeAudio
					key="attendeeaudio_me"
					rosterEntry={{
						boundExternalUserId: connection.attendee.Attendee.ExternalUserId.split('_')[1],
						boundAttendeeId: connection.attendee.Attendee.AttendeeId,
						isMuted,
						tileId: 1,
						hasCameraOn: !hideMyVideo,
						isActiveSpeaker: false,
						isSharingContent: imSharingContent !== undefined
					}}
				/>
			);
		} else {
			attendeeVideoElements.unshift(
				<NetworkingRoomAttendeeVideo
					key="attendeevideo_1"
					id="attendeevideo_1"
					rosterEntry={{
						boundExternalUserId: connection.attendee.Attendee.ExternalUserId.split('_')[1],
						boundAttendeeId: connection.attendee.Attendee.AttendeeId,
						isMuted,
						tileId: 1,
						hasCameraOn: !hideMyVideo,
						isActiveSpeaker: false,
						isSharingContent: imSharingContent !== undefined
					}}
					isContentShareActive={isContentShareActive}
					width={_getWidth(activeElements, isContentShareActive)}
					height={_getHeight(activeElements, isContentShareActive)}
				/>
			);
		}

		return (
			<View style={{ flex: 1 }}>
				<View style={isContentShareActive ? { flex: 1 } : undefined}>{sharedScreenElements}</View>
				<View style={[{ width: '100%' }, !isContentShareActive ? { flex: 1 } : undefined]}>
					<ScrollView
						scrollEnabled={isContentShareActive || isTabletOrMobile}
						style={{ height: '100%', width: '100%' }}
						contentContainerStyle={!isContentShareActive ? (isPhone ? undefined : { flexWrap: 'wrap', flex: 1 }) : undefined}
					>
						<View style={[isContentShareActive ? { height: 100 } : { flex: 1 }, { flexDirection: 'row', flexWrap: 'wrap' }]}>
							{attendeeVideoElements}
							{isContentShareActive && audioElements}
						</View>
					</ScrollView>
					{!isContentShareActive && (
						<View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 15, flexShrink: 0 }}>{audioElements}</View>
					)}
				</View>
			</View>
		);
	};

	const contentSharer = Object.values(rosterRef.current).filter((entry: IRosterMember) => entry.isSharingContent);
	const imSharingContent = contentSharer.find((entry: IRosterMember) => entry.boundExternalUserId === userInfos?.userId);

	return (
		<View style={{ flex: 1, flexDirection: isTabletOrMobile ? 'column-reverse' : 'column' }}>
			<audio id="audio" autoPlay />
			<View style={{ flex: 1 }}>{_renderAttendees()}</View>
			<HSCard
				style={{
					height: 100,
					width: '100%',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<NetworkingRoomControleButton
					testID={`${TESTIDPREFIX}_button_microphone`}
					onPress={() => _muteLocalAudio(meetingSessionRef.current?.audioVideo.realtimeIsLocalAudioMuted())}
					icon={isMuted ? EDefaultIconSet.MicrophoneMuted : EDefaultIconSet.Microphone}
					isDisabled={audioInputDevices.length === 0}
				/>
				{!item.isAudioOnly ? (
					<NetworkingRoomControleButton
						testID={`${TESTIDPREFIX}_button_blur`}
						onPress={() => {
							switch (blurBackground) {
								case 'strong':
									setBlurBackground('light');
									break;
								case 'light':
									setBlurBackground('off');
									break;
								case 'off':
								default:
									setBlurBackground('strong');
									break;
							}
						}}
						icon={
							blurBackground === 'off'
								? 'MaterialCommunityIcons:blur'
								: blurBackground === 'light'
								? 'MaterialCommunityIcons:blur-off'
								: 'MaterialCommunityIcons:blur-radial'
						}
						isLoading={isBlurDisabled}
					/>
				) : null}
				<NetworkingRoomControleButton
					testID={`${TESTIDPREFIX}_button_settings`}
					onPress={() => setShowSettingsModal(true)}
					icon={EDefaultIconSet.Settings}
				/>
				{!item.isAudioOnly && (
					<NetworkingRoomControleButton
						testID={`${TESTIDPREFIX}_button_camera`}
						onPress={async () => {
							if (hideMyVideo) {
								await _selectVideoInputDevice(selectedVideoInputDevice);
								meetingSessionRef.current?.audioVideo.startLocalVideoTile();
								await setHideMyVideo(false);
							} else {
								meetingSessionRef.current?.audioVideo.stopLocalVideoTile();
								await meetingSessionRef.current?.audioVideo.stopVideoInput();
								setHideMyVideo(true);
							}
						}}
						icon={hideMyVideo ? EDefaultIconSet.CameraOff : EDefaultIconSet.CameraOn}
						isDisabled={videoInputDevices.length === 0}
					/>
				)}
				{!item.isAudioOnly && IS_WEB && (
					<NetworkingRoomControleButton
						testID={`${TESTIDPREFIX}_button_screenshare`}
						onPress={async () => {
							if (isContentShared) {
								meetingSessionRef.current?.audioVideo.stopContentShare();
								if (!hideMyVideo && selectedVideoInputDevice) {
									await _selectVideoInputDevice(selectedVideoInputDevice);
									meetingSessionRef.current?.audioVideo.startLocalVideoTile();
								}
							} else {
								try {
									await meetingSessionRef.current?.audioVideo.startContentShareFromScreenCapture();
								} catch (error) {
									console.log(error);
								}
							}
						}}
						icon={isContentShared ? EDefaultIconSet.ScreenSharingOff : EDefaultIconSet.ScreenSharing}
						isDisabled={contentSharer.length === MAXCONTENTSHARER && !imSharingContent}
					/>
				)}
				<NetworkingRoomControleButton
					testID={`${TESTIDPREFIX}_button_showhelpmodal`}
					onPress={async () => setShowHelpModal(true)}
					icon={EDefaultIconSet.Help}
				/>
				<NetworkingRoomControleButton
					testID={`${TESTIDPREFIX}_button_leave`}
					onPress={async () => {
						showAlert({
							title: t('LeaveNetworkingRoom'),
							buttons: [
								{
									text: t('Cancel'),
									style: 'cancel'
								},
								{
									text: t('LeaveNetworkingRoom'),
									onPress: () => {
										_leaveNetworkingRoom();
									}
								}
							]
						});
					}}
					icon={EDefaultIconSet.HangUp}
					backgroundColor={theme.danger}
					iconColor={theme.white}
				/>
			</HSCard>
			<NetworkingRoomHelpModal isVisible={showHelpModal} onClose={() => setShowHelpModal(false)} />
			<NetworkingRoomSettingsModal
				isVisible={showSettingsModal}
				onSubmit={async (newDevices) => {
					await _selectAudioInputDevice(newDevices.audioInputDevice);

					if (browserBehavior.supportsSetSinkId()) {
						await _selectAudioOutputDevice(newDevices.audioOutputDevice);
					}

					if (!item.isAudioOnly) {
						await _selectVideoInputDevice(newDevices.videoInputDevice);
					}
					setShowSettingsModal(false);
				}}
				meetingReadinessChecker={meetingReadinessCheckerRef.current}
				meetingSession={meetingSessionRef.current}
				isAudioOnly={item.isAudioOnly}
				deviceInputList={{
					audioInputDevices,
					audioOutputDevices,
					videoInputDevices
				}}
			/>
		</View>
	);
};
