import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';
import { selfServiceEditableOptions } from 'rematch/interfaces';

export const getDefaultSpeakerFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'mainsection',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'right'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never',
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'image',
			fieldLabel: i18next.t('Image'),
			fieldType: 'logo',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			aspectRatio: '1/1'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('Name'),
			fieldType: 'title',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'speakerTitle',
			fieldLabel: i18next.t('AcademicDegree'),
			fieldHint: i18next.t('speakerTitleHint'),
			fieldType: 'subtitle',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'companyImage',
			fieldLabel: i18next.t('Company Image'),
			fieldType: 'image',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'linkedInProfileUrl',
			fieldLabel: i18next.t('linkedInProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'xingProfileUrl',
			fieldLabel: i18next.t('xingProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'twitterProfileUrl',
			fieldLabel: i18next.t('twitterProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'instagramProfileUrl',
			fieldLabel: i18next.t('instagramProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'facebookProfileUrl',
			fieldLabel: i18next.t('facebookProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'youTubeProfileUrl',
			fieldLabel: i18next.t('youtubeProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'tikTokProfileUrl',
			fieldLabel: i18next.t('tikTokProfile'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'blogUrl',
			fieldLabel: i18next.t('blog'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'website',
			fieldLabel: i18next.t('website'),
			fieldType: 'socialmedia',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'bio',
			fieldLabel: i18next.t('Biography'),
			fieldType: 'markdown',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true
		},
		{
			fieldName: 'jobTitle',
			fieldLabel: i18next.t('jobTitle'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'company',
			fieldLabel: i18next.t('company'),
			fieldType: 'string',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: true,
			maxLength: 255
		},

		{
			fieldName: 'email',
			fieldLabel: i18next.t('email'),
			fieldType: 'email',
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false
		},

		{
			fieldName: 'section2',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			sectionPosition: 'left'
		},
		{
			fieldName: 'attendee',
			fieldLabel: i18next.t('Attendee'),
			fieldType: 'singlereference',
			referenceType: 'attendees',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderAttendees').replace('attendees', 'attendee')
		},
		{
			fieldName: 'schedules',
			fieldLabel: i18next.t('Schedules'),
			fieldType: 'reference',
			referenceType: 'schedules',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderSchedules')
		},
		{
			fieldName: 'expos',
			fieldLabel: i18next.t('Expos'),
			fieldType: 'reference',
			referenceType: 'expos',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderExpos')
		},
		{
			fieldName: 'mediaitems',
			fieldLabel: i18next.t('Media Items'),
			fieldType: 'reference',
			referenceType: 'mediaitems',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: true,
			placeholder: i18next.t('PlaceholderMediaItems')
		},
		{
			fieldName: 'section3',
			fieldLabel: i18next.t('Self Service'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			sectionPosition: 'left'
		},
		{
			fieldName: 'selfServiceEditable',
			fieldLabel: i18next.t('SelfServiceItemEditableLabel'),
			fieldType: 'multiswitch',
			options: selfServiceEditableOptions(),
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'selfServiceEmail',
			fieldLabel: i18next.t('selfServiceEmail'),
			fieldHint: i18next.t('selfServiceEmailHint'),
			fieldType: 'email',
			isDefaultField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always',
			showOnDetailScreen: false
		},
		{
			fieldName: 'isHidden',
			fieldLabel: i18next.t('isHidden'),
			fieldHint: i18next.t('isHiddenHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		}
	];

	return arr;
};
