import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { View, TextInputProps, ViewStyle, TextInput } from 'react-native';

import { IFormBase } from 'config/interfaces/form';
import { FormField } from './FormField';
import { useTheme } from 'hooks/useTheme';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { FormLabel } from './FormLabel';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH, FormElementBottomMarginSmall } from './constants';
import { IS_ANDROID, IS_WEB } from 'helper/platform';
import { Dropdown } from './Dropdown';
import { IKVP } from './FormMultiSwitch';
import { useQuery } from 'hooks/useQuery';
import { EDefaultIconSet, isEmptyString, normalizeFontSize } from 'helper';
import { Text } from 'components/Text';

interface IInsertionOption {
	prefix: string;
	fields: IKVP[];
	placeholder?: string;
}
interface IFormTextInputComponent extends Omit<TextInputProps, 'testID'>, IFormBase {
	formStyle?: ViewStyle;
	containerStyle?: ViewStyle;
	rightComponent?: ReactNode;
	leftLabel?: string;
	children?: ReactNode | ReactNode[];
	insertionOptions?: IInsertionOption[];
	autoCompleteType?: TextInputProps['autoCompleteType'];
	messageType?: boolean;
	biggerTextInput?: boolean;
	onlyNumbers?: boolean;
	isCopyField?: boolean;
	skipTabPress?: boolean;
	hideButtons?: boolean;
	value?: string | null;
	maxInputLength?: number;
	noBorder?: boolean;
}

export const FormTextInput = (props: IFormTextInputComponent) => {
	const {
		style,
		label,
		isRequired,
		hint,
		error,
		isDisabled,
		isCopyField,
		onChangeText,
		keyboardType,
		testID,
		containerStyle,
		rightComponent,
		leftLabel,
		children,
		formStyle,
		insertionOptions,
		autoCompleteType,
		messageType,
		onlyNumbers,
		biggerTextInput,
		skipTabPress,
		hideButtons,
		maxInputLength,
		noBorder
	} = props;
	const { isTabletOrMobile } = useQuery();
	const { theme } = useTheme();

	const inputRef = useRef<TextInput>(null);

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [cursorPosition, setCursorPosition] = useState<number>(0);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const platformStyle = IS_WEB ? { outline: 'none' } : {};
	const defaultHeight = 17;
	const [inputHeight, setInputHeight] = useState<number>(defaultHeight);

	useEffect(() => {
		if (isEmptyString(props.value)) {
			setInputHeight(defaultHeight);
		}
	}, [props.value]);

	const _renderPlaceholderInsertion = () => {
		if (insertionOptions) {
			const elements: ReactNode[] = [];
			insertionOptions.forEach((insertionOption, idx) => {
				if (insertionOption.fields.length > 0) {
					const lastItem = idx === insertionOptions.length - 1;
					elements.push(
						<Dropdown
							key={`${testID}_dropdown_${idx}`}
							testID={`${testID}_dropdown`}
							options={insertionOption.fields}
							onSelect={(fieldName) => {
								const insertion = `<%= ${insertionOption.prefix}.${fieldName} %>`;
								let result = props.value ? props.value : '';
								result = result.slice(0, cursorPosition) + insertion + result.slice(cursorPosition);
								if (onChangeText) {
									onChangeText(result);
								}
							}}
							placeholder={insertionOption.placeholder}
							formStyle={{ marginBottom: lastItem ? FormElementBottomMarginSmall : 0 }}
						/>
					);
				}
			});

			if (elements.length > 0) {
				return <View>{elements}</View>;
			}
		}

		return null;
	};

	return (
		<FormField
			testID={testID}
			label={label}
			isRequired={isRequired}
			hint={hint}
			error={error}
			formStyle={formStyle}
			needsErrorPadding={(maxInputLength ?? 0) > 0 && !isDisabled}
		>
			{_renderPlaceholderInsertion()}
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				{leftLabel && <FormLabel testID={`${testID}_label`} label={leftLabel} style={{ marginBottom: 0, marginRight: 10 }} />}
				<View
					style={[
						{
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							borderRadius: noBorder ? 0 : FORMELEMENTBORDERRADIUS,
							borderWidth: noBorder ? 0 : FORMELEMENTBORDERWIDTH,
							borderColor: noBorder ? undefined : theme.lightgray,
							paddingHorizontal: 5,
							paddingVertical: 5,
							minHeight: defaultHeight,
							maxHeight: 160
						},
						containerStyle
					]}
				>
					<TextInput
						//@ts-ignore Workaround. Needed to prevent autofill in chrome.
						autoCompleteType={autoCompleteType ?? IS_ANDROID ? 'off' : 'new-password'}
						textContentType={props.secureTextEntry ? 'oneTimeCode' : undefined}
						{...props}
						value={props.value ?? ''}
						ref={inputRef}
						style={[
							style,
							{
								flex: 1,
								...platformStyle,
								color: isDisabled ? theme.lightgray : theme.text,
								minHeight: defaultHeight,
								maxHeight: props.multiline ? style?.maxHeight ?? 150 : style?.maxHeight,
								height: IS_WEB ? (props.multiline ? inputHeight : style?.height) : style?.height ?? 'auto',
								fontSize: biggerTextInput ? normalizeFontSize(20) : 14,
								paddingTop: 0
							},
							IS_WEB && !isTabletOrMobile && props.multiline && !messageType
								? { minHeight: isCopyField && props.editable === false ? inputHeight : 150 }
								: null,
							formStyle?.width
								? { maxWidth: !isNaN(Number(formStyle.width)) ? Number(formStyle.width) - 10 - 2 : undefined }
								: null
						]}
						secureTextEntry={props.secureTextEntry && !showPassword}
						onContentSizeChange={(e) => {
							setInputHeight(e.nativeEvent.contentSize.height);
						}}
						onChangeText={(val) => {
							if (onChangeText) {
								if (keyboardType === 'email-address') {
									onChangeText(val.toLowerCase());
								} else if ((keyboardType === 'number-pad' || keyboardType === 'numeric') && onlyNumbers) {
									onChangeText(val.replace(/\D/, ''));
								} else {
									onChangeText(val);
								}
							}
						}}
						onKeyPress={(e) => {
							if (props.onKeyPress) {
								props.onKeyPress(e);
							}
						}}
						onLayout={(e) => {
							props.value && props.multiline ? setInputHeight(e.nativeEvent.layout.height) : null;
						}}
						onFocus={(e) => {
							if (IS_WEB && cursorPosition) {
								e.target['selectionStart'] = cursorPosition;
							}
							setIsFocused(true);
							if (props.onFocus) {
								props.onFocus(e);
							}
						}}
						onBlur={(e) => {
							if (IS_WEB || !props.value) {
								setTimeout(() => {
									setIsFocused(false);
									if (props.onBlur) {
										props.onBlur(e);
									}
								}, 125);
							} else {
								setTimeout(() => {
									setIsFocused(false);
									if (props.onBlur) {
										props.onBlur(e);
									}
								}, 1000);
							}
						}}
						onSelectionChange={({ nativeEvent }) => {
							setCursorPosition(nativeEvent.selection.end);
							if (props.onSelectionChange) {
								props.onSelectionChange(nativeEvent);
							}
						}}
						allowFontScaling={false}
						editable={props.editable !== null && props.editable !== undefined ? props.editable : !isDisabled}
						placeholderTextColor={theme.formgray}
						autoCorrect={keyboardType === 'email-address' || keyboardType === 'url' ? false : props.autoCorrect}
						autoCapitalize={
							keyboardType === 'email-address' || keyboardType === 'url'
								? 'none'
								: props.autoCapitalize
								? props.autoCapitalize
								: 'sentences'
						}
					/>
					{isFocused && !isDisabled && props.value !== '' && !isCopyField && props.editable !== false && !hideButtons && (
						<ChildButton
							testID={`${testID}_button_clear`}
							style={{
								marginLeft: 5,
								height: biggerTextInput ? normalizeFontSize(20) : defaultHeight,
								alignSelf:
									props.multiline && inputHeight > (biggerTextInput ? normalizeFontSize(20) : defaultHeight)
										? 'flex-start'
										: 'center',
								justifyContent: 'center'
							}}
							onPress={() => {
								if (inputRef.current) {
									inputRef.current.clear();
									if (IS_WEB && isFocused) {
										setTimeout(() => {
											inputRef.current?.focus();
										}, 150);
									}
								}
								if (onChangeText) {
									onChangeText('');
								}
							}}
							onLayout={(e) => {
								if (skipTabPress) {
									try {
										e.nativeEvent.target.tabIndex = -1;
									} catch {}
								}
							}}
						>
							<Icon
								name={EDefaultIconSet.Close}
								color={theme.text}
								size={biggerTextInput ? normalizeFontSize(20) : defaultHeight}
							/>
						</ChildButton>
					)}

					{props.secureTextEntry && props.value !== '' && !hideButtons && (
						<ChildButton
							testID={`${testID}_show`}
							style={{
								marginLeft: 5,
								height: props.multiline ? inputHeight : biggerTextInput ? normalizeFontSize(20) : defaultHeight,
								maxHeight: 150,
								alignSelf: 'center'
							}}
							onPress={() => {
								setShowPassword(!showPassword);
								if (IS_WEB && isFocused) {
									setTimeout(() => {
										inputRef.current?.focus();
									}, 150);
								}
							}}
							onLayout={(e) => {
								if (skipTabPress) {
									try {
										e.nativeEvent.target.tabIndex = -1;
									} catch {}
								}
							}}
						>
							<Icon
								name={showPassword ? 'MaterialCommunityIcons:eye-off-outline' : 'MaterialCommunityIcons:eye-outline'}
								color={theme.gray}
								size={biggerTextInput ? normalizeFontSize(20) : defaultHeight}
							/>
						</ChildButton>
					)}
					{rightComponent}
				</View>
			</View>
			{maxInputLength !== undefined && !isDisabled && (
				<Text
					style={{
						alignSelf: 'flex-end',
						marginTop: 2,
						paddingRight: 2,
						marginBottom: -16,
						color: (props.value?.length ?? 0) > maxInputLength ? theme.danger : theme.lightgray,
						fontSize: 12
					}}
				>
					{`${props.value?.length ?? 0}/${maxInputLength}`}
				</Text>
			)}
			{children}
		</FormField>
	);
};
