import i18next from 'i18next';
import * as Yup from 'yup';
import { getRequiredError } from './errors';

export const getSubmitVoteTextFormSchema = () => {
	return Yup.object().shape({
		value: Yup.string().required(getRequiredError(i18next.t('Message')))
	});
};

export const getSubmitWordCloudFormSchema = (minValue?: number, maxValue?: number) => {
	return Yup.object().shape({
		value: Yup.array()
			.of(Yup.string().required(getRequiredError(i18next.t('WordCloudMinError').replace('%VALUE%', minValue?.toString() ?? '1'))))
			.min(minValue ?? 1, i18next.t('WordCloudMinError').replace('%VALUE%', minValue?.toString() ?? '1'))
			.max(maxValue || 999, i18next.t('WordCloudMaxError').replace('%VALUE%', maxValue?.toString() ?? '1'))
	});
};

export const getSubmitRatingFormSchema = () => {
	return Yup.object().shape({
		value: Yup.number().required()
	});
};

export const getSubmitNpsFormSchema = () => {
	return Yup.object().shape({
		value: Yup.number().required()
	});
};

export const getSubmitScaleFormSchema = (minValue, maxValue) => {
	return Yup.object().shape({
		value: Yup.number()
			.test('in range', i18next.t('ScaleRangeError').replace('%MINVALUE%', minValue).replace('%MAXVALUE%', maxValue), (value) => {
				if (value !== undefined) {
					return value >= minValue && value <= maxValue;
				}
				return false;
			})
			.required()
	});
};

export const getSubmitMultipleChoiceFormSchema = () => {
	return Yup.object().shape({
		value: Yup.lazy((val) => (Array.isArray(val) ? Yup.array().of(Yup.number()).required() : Yup.number().required().moreThan(-1)))
	});
};
