import { IVote } from 'config/interfaces/vote';
import { getSubmitRatingFormSchema } from 'config/yupSchemas';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import React, { ReactNode, useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Rating } from './Rating';
import { useSelector } from 'react-redux';
import { IVoteResult } from 'config/interfaces/voteanswer';
import { previewAnswerRating } from 'helper/previewAnswers/previewAnswersRating';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { FormSwitch } from 'components/Form/FormSwitch';
import { useTranslation } from 'react-i18next';
import { HSCard } from 'components/Card';
import { ActiveVoteHeader } from '../ActiveVoteHeader';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { RoundButton } from 'components/Button';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { hsTopScreenPadding } from 'config/styleConstants';
import { Spinner } from 'components/Spinner';
import { useNavigation } from '@react-navigation/native';

interface IVoteRating {
	vote: IVote;
	isPreview?: boolean;
	resultsOnly?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
	isAnswered?: boolean;
}

interface IRatingIcons {
	empty: string;
	filled: string;
}

interface IRatingResult {
	answerCount: number;
	averageAnswer: number;
}

const TESTIDPREFIX = 'activevote';

export const VoteRating = (props: IVoteRating) => {
	const { vote, isPreview, resultsOnly, largerDesign, fullwidth } = props;
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { trackAction } = useTracker();
	const navigation = useNavigation();

	const [result, setResult] = useState<IRatingResult>({
		answerCount: 0,
		averageAnswer: 0
	});
	const [valueSubmitted, setValueSubmitted] = useState<boolean>(false);
	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [value, setValue] = useState<number>(0);
	const [icons, setIcons] = useState<IRatingIcons>({
		empty: EDefaultIconSet.StarOutline,
		filled: EDefaultIconSet.Star
	});
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [lockScrolling, setLockScrolling] = useState<boolean>(false);
	const [showAnswersPressed, setShowAnswersPressed] = useState<boolean>(false);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);
	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered && !vote.hideResults) {
			setVotingShowResults(true);
		}
	}, [vote, votingIsAnswered]);

	useEffect(() => {
		let empty;
		let filled;
		switch (vote.viewType) {
			case 'thumbs':
				empty = EDefaultIconSet.ThumbUpOutline;
				filled = EDefaultIconSet.ThumbUp;
				break;
			case 'star':
			default:
				empty = EDefaultIconSet.StarOutline;
				filled = EDefaultIconSet.Star;
				break;
		}
		setIcons({
			empty,
			filled
		});
	}, [vote]);

	useEffect(() => {
		let answerForThis: IVoteResult | undefined = undefined;
		if (isPreview) {
			answerForThis = previewAnswerRating;
		} else {
			answerForThis = voteAnswers?.find((e) => e.isResultObject && e.voteId === vote.id) as IVoteResult | undefined;
		}

		const myAnswer = voteAnswers?.find(
			(e) => !e.isResultObject && !e.isDeleted && e.voteId === vote.id && e.userId === userInfos.userId
		);
		if (myAnswer) {
			setValue(Number(myAnswer.value));
			setValueSubmitted(true);
			setVotingIsAnswered(true);
		} else if (!showAnswersPressed && valueSubmitted) {
			setValue(0);
			setValueSubmitted(false);
			setVotingShowResults(false);
		}

		if (answerForThis) {
			let total = 0;
			answerForThis.options.forEach((option) => {
				total += option.value * Number(option.key);
			});
			setResult({
				answerCount: answerForThis.submissionCount,
				averageAnswer: Math.floor(total / answerForThis?.submissionCount)
			});
		} else {
			setResult({
				answerCount: 0,
				averageAnswer: 0
			});
		}
	}, [voteAnswers]);

	const _submitVoteAnswer = async (byPassModal?: boolean) => {
		if (isPreview) {
			setVotingShowResults(true);
			return;
		}
		const errors = await validateForm(getSubmitRatingFormSchema(), { value });
		if (errors) {
			showFormErrorToast(errors);
			return;
		}

		if (!byPassModal) {
			if (
				(vote.allowAnonymousAnswers === 'never' && !profile) ||
				(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
			) {
				setIsAuthenticationModalVisible(true);
				return;
			}
		}

		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		const res = await submitVoteAnswer({
			voteId: vote.id,
			value,
			isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
		});
		navigation.setParams({ prohibitNavigation: false });
		setIsSubmitVoteAnswerLoading(false);
		if (res) {
			trackAction('vote', 'Submit Vote Answer', vote.id.toString());
			setValueSubmitted(true);
			setVotingShowResults(true);
		}
	};

	const _renderResult = () => {
		return (
			<HSCard>
				<Rating
					value={result.averageAnswer}
					isDisabled
					maxValue={vote.maxValue}
					emptyIcon={icons.empty}
					filledIcon={icons.filled}
					numberOfVotes={result.answerCount}
					largerDesign={largerDesign}
					isResult={true}
				/>
			</HSCard>
		);
	};

	const _renderAnonymousSwitch = () => {
		if (vote?.allowAnonymousAnswers === 'optin') {
			return (
				<FormSwitch
					testID="votemultiplechoice_switch_anonymous"
					onChange={() => setAnswerAnonymous(!answerAnonymous)}
					leftLabel={t('Answer Anonymous')}
					value={answerAnonymous}
					size="sm"
					isDisabled={isSubmitVoteAnswerLoading}
					formStyle={{ width: undefined, marginBottom: 0, marginRight: 10 }}
				/>
			);
		}

		return null;
	};

	const _renderForm = () => {
		return (
			<HSCard>
				<Rating
					value={value}
					onChange={(val) => {
						setValue(val);
						if (!isPreview) navigation.setParams({ prohibitNavigation: true });
					}}
					maxValue={vote.maxValue}
					emptyIcon={icons.empty}
					filledIcon={icons.filled}
					lockScrolling={(val) => setLockScrolling(val)}
					largerDesign={largerDesign}
				/>
			</HSCard>
		);
	};

	const _renderContent = () => {
		if ((votingShowResults && !vote?.hideResults) || resultsOnly || !vote.isActive) {
			return _renderResult();
		}

		return _renderForm();
	};

	const _renderActionButtons = () => {
		if (resultsOnly || (votingShowResults && vote?.hideResults) || !vote.isActive) {
			return null;
		}
		let element: ReactNode = null;
		if (votingShowResults && !vote?.hideResults) {
			element = (
				<HSCard>
					<RoundButton
						isFloatingButton
						testID={`${TESTIDPREFIX}_button_edit`}
						icon={EDefaultIconSet.Edit}
						onPress={() => {
							setVotingShowResults(false);
							setShowAnswersPressed(false);
							navigation.setOptions({ isAnswered: undefined });
						}}
						title={!valueSubmitted ? t('answerOptions') : t('changeAnswer')}
						alignSelf="center"
					/>
				</HSCard>
			);
		} else {
			element = (
				<HSCard>
					{_renderAnonymousSwitch()}
					<RoundButton
						isFloatingButton
						icon={EDefaultIconSet.Save}
						testID={`${TESTIDPREFIX}_button_submit`}
						title={t('submitAnswer')}
						isLoading={isSubmitVoteAnswerLoading}
						onPress={() => {
							_submitVoteAnswer();
							setVotingShowResults(true);
						}}
						alignSelf="center"
					/>
				</HSCard>
			);
		}

		return (
			<View
				style={{
					width: fullwidth ? '100%' : screenWidth,
					alignSelf: 'center',
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					marginTop: hsTopScreenPadding / 2
				}}
			>
				{element}
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{vote ? (
				<ScrollView
					testID={`${TESTIDPREFIX}_scrollview`}
					scrollEnabled={!lockScrolling}
					contentContainerStyle={{
						paddingBottom: SCROLL_PADDING_FLOATING,
						paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: fullwidth ? '100%' : screenWidth,
						alignSelf: 'center'
					}}
				>
					<ActiveVoteHeader largerText={largerDesign} showResult={resultsOnly} activeVote={vote} />
					{_renderContent()}
				</ScrollView>
			) : (
				<View style={{ flex: 1, justifyContent: 'center' }}>
					<Spinner size={'large'} />
				</View>
			)}
			{vote && _renderActionButtons()}
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _submitVoteAnswer(true)}
			/>
		</View>
	);
};
