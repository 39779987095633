import React from 'react';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { normalizeFontSize } from 'helper';

interface IMissingPermissionComponent {
	type: 'camera' | 'microphone' | 'contacts';
}

export const MissingPermission = (props: IMissingPermissionComponent) => {
	const { type } = props;
	const { t }: { t: any } = useTranslation();

	const _renderIcon = () => {
		let icon;
		switch (type) {
			case 'camera':
				icon = 'MaterialCommunityIcons:camera-off';
				break;
			case 'contacts':
				icon = 'MaterialCommunityIcons:account-off';
				break;
			case 'microphone':
				icon = 'MaterialCommunityIcons:microphone-off';
				break;
			default:
				break;
		}

		if (icon) {
			return (
				<View style={{ marginBottom: 20 }}>
					<Icon name={icon} size={normalizeFontSize(22)} />
				</View>
			);
		}

		return null;
	};

	const _getTitle = () => {
		switch (type) {
			case 'camera':
				return t('MissingCameraPermissionTitle');
			case 'contacts':
				return t('MissingContactsPermissionTitle');
			case 'microphone':
				return t('MissingMicrophonePermissionTitle');
			default:
				return undefined;
		}
	};
	return (
		<View style={{ alignItems: 'center', borderWidth: 1, borderRadius: 5, padding: 20, marginVertical: 20 }}>
			{_renderIcon()}
			<Text bold style={{ fontSize: normalizeFontSize(16), marginBottom: 5 }}>
				{_getTitle()}
			</Text>
			<Text>{t('MissingPermissionSubtitle')}</Text>
		</View>
	);
};
