import React, { memo } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

export const ATTENDEELISTHEADERHEIGHT = 20;

interface IAttendeeListHeader {
	text: string;
}

export const AttendeeListHeader = memo((props: IAttendeeListHeader) => {
	const { text } = props;
	const { theme } = useTheme();

	return (
		<View
			style={{
				height: ATTENDEELISTHEADERHEIGHT,
				borderBottomWidth: 1,
				borderColor: theme.text,
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
		>
			<Text bold>{text}</Text>
		</View>
	);
});
