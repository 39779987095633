import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { ScreenContainer } from 'components/ScreenContainer';

import { NavigationHeader, NavigationHeaderPreviewVoteButton, NavigationHeaderTitle } from 'components/Navigation/Header';
import { VoteCreateForm } from 'components/Forms/Vote';
import { CreateVoteInitialFormValues, ICreateVoteFormErrors, ICreateVoteFormValues } from 'config/interfaces';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { showFormErrorToast, validateForm } from 'helper';
import {
	getCreateVoteApplauseFormSchema,
	getCreateVoteApplauseFormSchemaStep2,
	getCreateVoteExternalUrlFormSchema,
	getCreateVoteMultipleChoiceFormSchema,
	getCreateVoteMultipleChoiceFormSchemaStep2,
	getCreateVoteNpsFormSchema,
	getCreateVoteQuizFormSchema,
	getCreateVoteQuizFormSchemaStep2,
	getCreateVoteRatingFormSchema,
	getCreateVoteScaleFormSchema,
	getCreateVoteSurveyFormSchema,
	getCreateVoteSurveyFormSchemaStep2,
	getCreateVoteTextFormSchema,
	getCreateVoteWordCloudFormSchema,
	getCreateVotePinOnImageFormSchema
} from 'config/yupSchemas/vote';

import { PreviewVoteModal } from 'components/Modal';
import { logboxIgnore } from 'helper/logbox';
import { View } from 'react-native';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header/NavigationHeaderCancelButton';
import { hsTopScreenPadding } from 'config/styleConstants';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import { useSpace } from 'hooks/useSpace';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.VotingCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.VotingCreate>;
type RouteParams = StackParamList[ERoutes.VotingCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

logboxIgnore();

export const VoteCreateScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();

	const [isCreateVoteLoading, setIsCreateVoteLoading] = useState<boolean>(false);

	const [createVoteFormValues, setCreateVoteFormValues] = useState<ICreateVoteFormValues>({
		...CreateVoteInitialFormValues,
		media: []
	});
	const [createVoteFormErrors, setCreateVoteFormErrors] = useState<ICreateVoteFormErrors>({});

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const createVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.createVote);

	useEffect(() => {
		navigation.setOptions({
			votingType: createVoteFormValues.votingType
		});
	}, [createVoteFormValues.votingType]);

	const _resetValues = () => {
		setCreateVoteFormValues({ ...CreateVoteInitialFormValues, media: [] });
	};

	const _validate = async (currentIndex: number) => {
		let schema;

		if (currentIndex === 0) {
			return createVoteFormValues.votingType === undefined;
		}

		switch (createVoteFormValues.votingType) {
			case 'text':
				schema = getCreateVoteTextFormSchema(createVoteFormValues);
				break;
			case 'externalUrl':
				schema = getCreateVoteExternalUrlFormSchema(createVoteFormValues);
				break;
			case 'applause':
				switch (currentIndex) {
					case 2:
						schema = getCreateVoteApplauseFormSchemaStep2(createVoteFormValues);
						break;
					case 1:
					default:
						schema = getCreateVoteApplauseFormSchema(createVoteFormValues);
						break;
				}
				break;
			case 'multipleChoice':
				switch (currentIndex) {
					case 2:
						schema = getCreateVoteMultipleChoiceFormSchemaStep2(createVoteFormValues);
						break;
					case 1:
					default:
						schema = getCreateVoteMultipleChoiceFormSchema(createVoteFormValues);
						break;
				}
				break;
			case 'quiz':
				switch (currentIndex) {
					case 2:
						schema = getCreateVoteQuizFormSchemaStep2(createVoteFormValues);
						break;
					case 1:
						schema = getCreateVoteQuizFormSchema(createVoteFormValues);
					default:
						break;
				}
				break;
			case 'survey':
				switch (currentIndex) {
					case 2:
						schema = getCreateVoteSurveyFormSchemaStep2(createVoteFormValues);
						break;
					case 1:
						schema = getCreateVoteSurveyFormSchema(createVoteFormValues);
					default:
						break;
				}
				break;
			case 'rating':
				schema = getCreateVoteRatingFormSchema(createVoteFormValues);
				break;
			case 'scale':
				schema = getCreateVoteScaleFormSchema(createVoteFormValues);
				break;
			case 'survey':
				break;
			case 'wordCloud':
				schema = getCreateVoteWordCloudFormSchema(createVoteFormValues);
				break;
			case 'nps':
				schema = getCreateVoteNpsFormSchema(createVoteFormValues);
				break;
			case 'pinOnImage':
				schema = getCreateVotePinOnImageFormSchema(createVoteFormValues);
				break;
			default:
				return false;
		}

		const errors = await validateForm(schema, createVoteFormValues);

		if (errors) {
			setCreateVoteFormErrors(errors);
			showFormErrorToast(errors);
			return true;
		} else {
			setCreateVoteFormErrors({});
			return false;
		}
	};

	const _createVote = async (currentIndex: number) => {
		const errors = await _validate(currentIndex);

		if (errors) {
			return;
		}

		let _values = createVoteFormValues;
		Object.keys(_values).map((k) => {
			if (typeof _values[k] === 'string') {
				_values[k] = _values[k].trim();
			}
		});
		setIsCreateVoteLoading(true);
		const res = await createVote({ data: _values });
		setIsCreateVoteLoading(false);
		if (res) {
			navigation.setParams({ prohibitNavigation: false });
			navigation.replace(ERoutes.ActiveVotingAdmin, { spaceId: activeSpace?.spaceId, id: res.id });
		}
	};

	const updateFormValues = (val) => {
		setCreateVoteFormValues(val);
		navigation.setParams({ prohibitNavigation: true });
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="votings">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding
				}}
			>
				<VoteCreateForm
					values={createVoteFormValues}
					errors={createVoteFormErrors}
					onChange={(val) => updateFormValues(val)}
					onNext={_validate}
					onSubmit={_createVote}
					isLoading={isCreateVoteLoading}
					resetValues={_resetValues}
				/>
			</View>
			<PreviewVoteModal
				isVisible={route?.params?.showModal ?? false}
				onClose={() => navigation.setParams({ showModal: false })}
				vote={createVoteFormValues}
			/>
			<LoadingModal isLoading={isCreateVoteLoading} />
		</ScreenContainer>
	);
};

export const VoteCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Create Vote')} />
			<NavigationHeaderPreviewVoteButton options={props.options} onPress={() => navigation.setParams({ showModal: true })} />
		</NavigationHeader>
	);
};
