import { Text } from 'components/Text';
import { Avatar } from 'components/User';
import { IAttendee, IChangelog } from 'config/interfaces';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import { getLabelForContentType } from './changeloghelper';
import { InfoPill } from 'components/InfoPill';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Markdown } from 'components/Markdown';
import { Icon } from 'components/Icon';
import { ChildButton } from 'components/Button';
import { HSCard } from 'components/Card';
export interface IChangelogWithItem extends IChangelog {
	item?: any;
}

interface IProps {
	logItem: IChangelogWithItem;
}

export const ChangelogListItem = (props: IProps) => {
	const { logItem } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const animatedRotation = useRef(new Animated.Value(0));

	const [user, setUser] = useState<IAttendee | string | undefined>(undefined);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _user: typeof user = logItem.userId;

		if (attendees && logItem.userId !== 'Self Service User') {
			_user = attendees.find((e) => e.userId === _user);
		}

		setUser(_user);
	}, [attendees, logItem]);

	useEffect(() => {
		Animated.timing(animatedRotation.current, {
			toValue: isExpanded ? 90 : 0,
			duration: 300,
			useNativeDriver: true
		}).start();
	}, [isExpanded]);

	const _renderUser = () => {
		if (user) {
			if (typeof user === 'string') {
				return <Text bold>{user}</Text>;
			}

			const fullName = `${user.firstName} ${user.lastName}`;

			return (
				<View style={{ flexDirection: 'row', alignItems: 'center' }}>
					<Avatar avatar={user.imageUrl} fullName={fullName} size="xs" />
					<Text bold style={{ marginLeft: 5 }}>
						{fullName}
					</Text>
				</View>
			);
		}
		return null;
	};

	const _renderInfos = () => {
		let actionText = '';
		let actionColor = '';

		switch (logItem.action) {
			case 'create':
				actionText = t('Created');
				actionColor = theme.success;
				break;
			case 'update':
				actionText = t('Updated');
				actionColor = theme.warning;
				break;
			case 'delete':
				actionText = t('Deleted');
				actionColor = theme.danger;
				break;
			default:
				break;
		}
		return (
			<View style={{ flexDirection: 'row' }}>
				<InfoPill text={actionText} textColor={actionColor} borderColor={actionColor} containerStyle={{ marginRight: 5 }} />
				<InfoPill text={getLabelForContentType(logItem.itemType)} textColor={theme.primary} borderColor={theme.primary} />
			</View>
		);
	};

	const _renderItemInfo = () => {
		let title = '';

		if (logItem.itemTitle) {
			title = logItem.itemTitle;
		} else if (logItem.item) {
			title = logItem.item.title;

			switch (logItem.itemType) {
				case 'schedules':
					if (logItem.item.startDate && logItem.item.endDate) {
						title += ` (${moment(logItem.item.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(logItem.item.endDate).format(
							'HH:mm'
						)})`;
					} else if (logItem.item.startDate) {
						title += ` (Start: ${moment(logItem.item.startDate).format('DD.MM.YYYY HH:mm')})`;
					} else if (logItem.item.endDate) {
						title += ` (End: ${moment(logItem.item.endDate).format('DD.MM.YYYY HH:mm')})`;
					}
					break;
				default:
					break;
			}
		}

		if (!isEmptyString(title)) {
			return (
				<View style={{ flex: 1 }}>
					<Text>{`"${title}"`}</Text>
				</View>
			);
		}

		return null;
	};

	const _renderExpand = () => {
		if (logItem.action !== 'delete') {
			return (
				<Animated.View
					style={{
						transform: [
							{
								rotateZ: animatedRotation.current.interpolate({
									inputRange: [0, 90],
									outputRange: ['0deg', '90deg']
								})
							}
						]
					}}
				>
					<Icon name={EDefaultIconSet.ChevronRight} color={theme.text} size={25} />
				</Animated.View>
			);
		}

		return null;
	};

	return (
		<HSCard style={{}}>
			<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 5 }}>
				{_renderUser()}
				<Text style={{ fontSize: 10 }}>{moment(logItem.updated_at).format('DD.MM.YYYY HH:mm')}</Text>
			</View>
			{_renderInfos()}
			<ChildButton
				testID={`button_expand`}
				onPress={() => setIsExpanded(!isExpanded)}
				isDisabled={logItem.action === 'delete'}
				style={{ flexDirection: 'row', justifyContent: 'space-between' }}
			>
				{_renderItemInfo()}
				{_renderExpand()}
			</ChildButton>
			{isExpanded ? <Markdown markdown={'`' + JSON.stringify(logItem.diff, null, 2) + '`'} /> : null}
		</HSCard>
	);
};
