import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderMenuButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { VoteList } from 'components/VoteList';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { NoData } from 'components/NoData';
import { useTranslation } from 'react-i18next';
import { IVote } from 'config/interfaces/vote';
import { AppState, AppStateStatus, BackHandler, ScrollView, View } from 'react-native';
import { RoundButton } from 'components/Button';
import { IS_ANDROID, IS_WEB } from 'helper/platform';
import { FullScreenLoading } from 'components/Loading';
import { EDefaultIconSet, openURL } from 'helper';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { hsTopScreenPadding } from 'config/styleConstants';
import { SponsorBlock } from '../Sponsors';
import { TABBAR_HEIGHT } from 'config/constants';
import { DEFAULT_PLATFORM_URL, PRESET_SPACEIDS } from 'config/envConstants';
import moment from 'moment';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { IFeatureInfo } from 'config/interfaces';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.VoteList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.VoteList>;
type RouteParams = StackParamList[ERoutes.VoteList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'votelist';

export const VoteListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth } = useQuery();
	const { exportAsCSV } = useExport();
	const { getContentTypeFields } = useContent('voting');
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);
	let timer = useRef<NodeJS.Timer>(null);
	let voteTimer = useRef<NodeJS.Timer>(null);

	const [activeVotes, setActiveVotes] = useState<IVote[]>([]);
	const [voteItems, setVoteItems] = useState<IVote[]>([]);
	const [isVotesLoading, setIsVoteLoading] = useState<boolean>(true);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [currentDate, setCurrentDate] = useState<string>(moment().toISOString());

	const [isEditMode, setIsEditMode] = useState<boolean>(false);

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateVote = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.updateVote);
	const startMyVoteAnswersSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.startMyVoteAnswersSync);

	const isScreenFocused = useIsFocused();

	useEffect(() => {
		let appStateListener;

		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});

		return () => {
			if (!IS_WEB) {
				appStateListener.remove();
			}
		};
	}, []);

	const _iAmSpaceAdminOrModerator = () => {
		return iAmSpaceAdmin || iAmSpaceModerator;
	};

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Votings')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (isScreenFocused) {
			_startTimer();
		} else {
			_stopTimer();
		}
	}, [isScreenFocused]);

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			_startTimer();
		} else if (nextState === 'background') {
			_stopTimer();
		}
	};

	useEffect(() => {
		if (activeSpace && isScreenFocused) {
			const now = moment(currentDate);

			const _items = votes?.filter((e) => e.spaceId === activeSpace.spaceId && !e.isDeleted && e.votingType !== 'optionVote');

			setVoteItems(_items);
			const _activeVotes = _items.filter((e) => {
				if (!e.isActive) {
					return false;
				}
				if (e.openUntil && e.openFrom) {
					return now.isBefore(moment(e.openUntil)) && now.isAfter(moment(e.openFrom));
				}
				if (e.openFrom) {
					return now.isSameOrAfter(moment(e.openFrom));
				}
				if (e.openUntil) {
					return now.isBefore(moment(e.openUntil));
				}
				return true;
			});

			_activeVotes.sort((a, b) => (a.id < b.id ? -1 : 1));

			setActiveVotes(_activeVotes);
		}
	}, [activeSpace, votes, currentDate, isScreenFocused]);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const _startTimer = () => {
		timer.current = setInterval(() => {
			const now = moment();
			if (now.get('seconds') === 0) {
				setCurrentDate(now.toISOString());
			}
		}, 1000);
		voteTimer.current = setInterval(() => {
			startMyVoteAnswersSync({});
		}, 60000);
	};

	const _stopTimer = () => {
		if (timer.current) {
			clearInterval(timer.current);
			timer.current = undefined;
		}
		if (voteTimer.current) {
			clearInterval(voteTimer.current);
			voteTimer.current = undefined;
		}
	};

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	const _delete = async (itemId?: number) => {
		const singleItem = voteItems.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.question}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'vote' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _handleActionSheetPress = (action: string) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.VotingCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'editMode':
				if (isEditMode) {
					setSelectedEntries([]);
				}
				setIsEditMode(!isEditMode);
				bottomSheetRef.current?.close();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'vote' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IVote[] = [];
				if (votes) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = votes.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...votes];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'votes');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			default:
				break;
		}
	};

	const openSpeakerViewInTab = (vote: IVote) => {
		if (vote?.speakerViewToken) {
			const url = `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}presentation/resultview/${encodeURIComponent(
				vote?.speakerViewToken
			)}`;
			openURL(`${url}newTabTrue`);
		}
	};

	const _updateVote = async (vote: IVote, fieldName: string, value: any) => {
		if (vote) {
			setIsVoteLoading(true);
			await updateVote({ voteId: vote.id, vote: { [fieldName]: value } });
			setIsVoteLoading(false);
		}
	};

	const _renderSelectAll = () => {
		if (isEditMode && voteItems.length > 0) {
			return (
				<View
					style={{
						alignSelf: 'center',
						width: screenWidth,
						paddingTop: hsTopScreenPadding,
						paddingHorizontal: EHorizontalScreenPadding.Wide
					}}
				>
					<HSCard>
						<FormCheckbox
							testID={`${TESTIDPREFIX}_checkbox_selectall`}
							label={t('SelectAll')}
							style={{ marginBottom: 0 }}
							value={selectedEntries && selectedEntries.length === voteItems.length}
							onPress={() => {
								if (selectedEntries && selectedEntries.length === voteItems.length) {
									setSelectedEntries([]);
								} else {
									setSelectedEntries(voteItems.map((sp) => sp.id));
								}
							}}
						/>
					</HSCard>
				</View>
			);
		}

		return null;
	};

	const _renderContent = () => {
		if (activeSpace && (activeVotes?.length > 0 || ((iAmSpaceAdmin || iAmSpaceModerator) && voteItems.length > 0))) {
			return (
				<VoteList
					items={_iAmSpaceAdminOrModerator() ? voteItems : activeVotes}
					onSelect={isEditMode ? (itemId) => _handleSelect(itemId) : undefined}
					onSpeakerViewPress={
						isEditMode
							? IS_WEB
								? (vote) => openSpeakerViewInTab(vote)
								: (vote: IVote) => navigation.navigate(ERoutes.ResultView, { spaceId: activeSpace?.spaceId, id: vote.id })
							: undefined
					}
					selectedVotings={selectedEntries}
					isLoading={isDeleteLoading}
					onPress={(id, isAnswered) => {
						if (isEditMode) {
							_handleSelect(id);
						} else {
							navigation.navigate(ERoutes.ActiveVoting, { spaceId: activeSpace?.spaceId, id, isAnswered });
						}
					}}
					onDelete={
						isEditMode
							? (itemId) => {
									setSelectedEntries([itemId]);
									_delete(itemId);
							  }
							: undefined
					}
					isEditMode={isEditMode}
					toggleActivation={
						isEditMode
							? (vote: IVote) =>
									showAlert({
										title: vote?.isActive ? t('deactivateVote') : t('activateVote'),
										message: vote?.isActive ? t('deactivateVoteAlertSubtitle') : t('activateVoteAlertSubtitle'),
										buttons: [
											{
												text: t('Cancel'),
												style: 'cancel'
											},
											{
												text: vote?.isActive ? t('deactivateVote') : t('activateVote'),
												onPress: () => _updateVote(vote, 'isActive', !vote?.isActive),
												style: 'default'
											}
										]
									})
							: undefined
					}
				/>
			);
		}

		if (isVotesLoading) {
			return <FullScreenLoading />;
		}

		return (
			<NoData type="NoVotings">
				{_iAmSpaceAdminOrModerator() && (
					<RoundButton
						testID={'votelist_button_create'}
						onPress={() => navigation.navigate(ERoutes.VotingCreate, { spaceId: activeSpace?.spaceId })}
						icon={EDefaultIconSet.Add}
						title={t('Add')}
						isStacked
					/>
				)}
			</NoData>
		);
	};

	return (
		<ScreenContainer contentKey="votings">
			<View
				style={{
					flex: 1,
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0,
					paddingTop: isEditMode && voteItems.length > 0 ? 0 : hsTopScreenPadding
				}}
			>
				{_renderSelectAll()}

				<View style={{ flex: 1, paddingHorizontal: IS_WEB ? 0 : EHorizontalScreenPadding.Wide }}>{_renderContent()}</View>
				<View
					style={{
						paddingHorizontal: EHorizontalScreenPadding.Wide,
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					<SponsorBlock contentType={'voting'} />
				</View>
				<LoadingModal isLoading={isDeleteLoading} />
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						onPress={() => _handleActionSheetPress('add')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_editmode`}
						icon={isEditMode ? EDefaultIconSet.Close : EDefaultIconSet.Edit}
						label={isEditMode ? t('LeaveEditMode') : t('EnterEditMode')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('editMode')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						iconColor={theme.danger}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const VoteListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};

export const VoteListScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
