import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, ScrollView, Keyboard } from 'react-native';
import { HsvColorPicker } from './HsvColorPicker';

import { useTheme } from 'hooks/useTheme';
import { ChildButton, RoundButton } from 'components/Button';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH } from 'components/Form/constants';
import { colorSuggestions, hexColorRegex, hexToRGBA, hsvToRGBA, IHSV, IRGBA, rgbaToHex, rgbToHSV } from 'helper/color';
import { EDefaultIconSet, isEmptyString, IS_WEB, normalizeFontSize } from 'helper';
import { Text } from 'components/Text';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormInputSlider } from 'components/Form/FormInputSlider';
import { FloatingView } from 'components/FloatingView';
import { SCROLL_PADDING_FLOATING } from 'config/constants';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { useFocusEffect } from '@react-navigation/native';

interface IColorpicker {
	testID: string;
	value?: string;
	onClose: () => void;
	onColorChange: (val) => void;
	hideTransparency?: boolean;
	hideRGB?: boolean;
}

export const Colorpicker = (props: IColorpicker) => {
	const { testID, value, onClose, onColorChange, hideTransparency, hideRGB } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const platformStyle = IS_WEB ? { outline: 'none' } : {};

	const [containerHeight, setContainerHeight] = useState<number>(1);
	const [rgba, setRGBA] = useState<IRGBA>({
		r: 0,
		g: 0,
		b: 0,
		a: 1.0
	});
	const [hsv, setHSV] = useState<IHSV>({
		hue: 0,
		sat: 0,
		val: 1
	});
	const [tempHex, setTempHex] = useState<string>('');
	const [hexError, setHexError] = useState<string>('');

	useEffect(() => {
		_getColorValues();
	}, [value]);

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				_getColorValues();
			};
		}, [value])
	);

	const _getColorValues = () => {
		if (value && hexColorRegex.test(value)) {
			const _rgba = hexToRGBA(value);

			setRGBA(_rgba);
			setHSV(rgbToHSV(_rgba.r, _rgba.g, _rgba.b));
			setTempHex(value);
		} else {
			setRGBA({
				r: 0,
				g: 0,
				b: 0,
				a: 1.0
			});
			setHSV({ hue: 0, sat: 0, val: 1 });
		}
	};

	const onHuePickerChange = ({ hue, saturation, value }) => {
		setHSV({ hue, sat: saturation, val: value });
		const _rgba = hsvToRGBA(hue, saturation, value, rgba.a);
		setRGBA(_rgba);
		setTempHex(rgbaToHex(_rgba));
		_submitHex(rgbaToHex(_rgba));
	};

	const onSatValPickerChange = ({ saturation, value, hue }) => {
		setHSV({ sat: saturation, val: value, hue });
		const _rgba = hsvToRGBA(hue, saturation, value, rgba.a);
		setRGBA(_rgba);
		setTempHex(rgbaToHex(_rgba));
		_submitHex(rgbaToHex(_rgba));
	};

	const _submitHex = (val) => {
		let _val: string | undefined = val;

		if (isEmptyString(val)) {
			setTempHex(val);
		} else {
			if (!_val?.startsWith('#')) {
				_val = `#${_val}`;
			}
			if (_val.length > 9) {
				_val = `${_val.slice(0, 7)}ff`;
			}
			const res = hexColorRegex.test(_val);
			setTempHex(_val);
			if (res) {
				setHexError('');
				const _rgba = hexToRGBA(_val);
				setRGBA(_rgba);
				setHSV(rgbToHSV(_rgba.r, _rgba.g, _rgba.b));
			} else {
				setHexError(t('HexColorError'));
			}
		}
	};

	const _renderRGBComponent = (comptestID: string, label: string, value: number) => {
		return (
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				<Text bold style={{ fontSize: normalizeFontSize(15), marginRight: 10 }}>
					{label}
				</Text>
				<TextInput
					testID={`${testID}_${comptestID}`}
					style={{
						color: theme.text,
						borderRadius: FORMELEMENTBORDERRADIUS,
						borderWidth: FORMELEMENTBORDERWIDTH,
						borderColor: theme.lightgray,
						width: 50,
						paddingHorizontal: 10,
						paddingVertical: 10
					}}
					textAlign="center"
					value={isNaN(value) ? '0' : value.toString()}
					maxLength={3}
					onChangeText={(e) => {
						let _val = Number(e);
						if (_val.toString().length > 0) {
							if (_val > 255) {
								_val = 255;
							}
						}
						const newRGBA = { ...rgba, [comptestID]: _val };
						setRGBA(newRGBA);
						setTempHex(rgbaToHex(newRGBA));
						setHSV(rgbToHSV(newRGBA.r, newRGBA.g, newRGBA.b));
					}}
					keyboardType="number-pad"
				/>
			</View>
		);
	};

	const _renderActionButtons = () => {
		return (
			<>
				<FloatingView alignSelf="left" isModal>
					<RoundButton
						title={t('Delete')}
						testID="colorpicker_button_delete"
						icon={EDefaultIconSet.Delete}
						onPress={() => {
							onColorChange(null);
							onClose();
						}}
						isFloatingButton
					/>
				</FloatingView>
				<FloatingView alignSelf="right" isModal>
					<RoundButton
						testID="colorpicker_button_save"
						icon={EDefaultIconSet.Save}
						isDisabled={!isEmptyString(hexError)}
						onPress={() => {
							onColorChange(isEmptyString(tempHex) ? null : tempHex);
							onClose();
						}}
						isFloatingButton
					/>
				</FloatingView>
			</>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				style={{
					flex: 1,
					marginHorizontal: -hsInnerPadding,
					marginBottom: SCROLL_PADDING_FLOATING
				}}
				contentContainerStyle={{ padding: hsInnerPadding }}
			>
				<View
					style={{
						flex: 1,
						flexDirection: 'row',
						flexWrap: 'wrap',
						marginBottom: 10,
						borderColor: theme.formgray,
						borderWidth: 1,
						borderRadius: 5,
						minHeight: 20
					}}
					onLayout={(e) => setContainerHeight(e.nativeEvent.layout.height - hsBottomMargin)}
				>
					{colorSuggestions?.map((color, index) => {
						const radius = 3;
						let borderStyle = {};
						switch (index) {
							case 0:
								borderStyle = { borderTopLeftRadius: radius };
								break;
							case 5:
								borderStyle = { borderTopRightRadius: radius };
								break;
							case 24:
								borderStyle = { borderBottomLeftRadius: radius };
								break;
							case 29:
								borderStyle = { borderBottomRightRadius: radius };
								break;
							default:
								break;
						}
						return (
							<ChildButton
								activeOpacity={0.5}
								key={`${testID}_button_color_${index}`}
								testID={`${testID}_button_color_${index}`}
								style={[{ height: '20%', width: `${100 / 6}%`, backgroundColor: color }, borderStyle]}
								onPress={() => _submitHex(color)}
							/>
						);
					})}
				</View>
				<View style={{ flex: 1, marginBottom: hsBottomMargin }}>
					<HsvColorPicker
						hue={hsv.hue}
						saturation={hsv.sat}
						value={hsv.val}
						onHuePickerDragMove={onHuePickerChange}
						onHuePickerPress={onHuePickerChange}
						onSatValPickerDragMove={onSatValPickerChange}
						onSatValPickerPress={onSatValPickerChange}
						saturationValuePickerSize={IS_WEB ? containerHeight : containerHeight * 2}
						containerStyle={{ alignSelf: 'center', marginBottom: 5 }}
					/>
					<FormTextInput
						testID={`${testID}_hex`}
						leftLabel="HEX"
						value={tempHex}
						error={hexError}
						onChangeText={(val) => _submitHex(val)}
						formStyle={{ marginBottom: 15, ...platformStyle }}
					/>
					{!hideRGB && (
						<View
							style={{
								flexDirection: 'row',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginBottom: 15
							}}
						>
							{_renderRGBComponent('r', 'R', rgba.r)}
							{_renderRGBComponent('g', 'G', rgba.g)}
							{_renderRGBComponent('b', 'B', rgba.b)}
						</View>
					)}
					{!hideTransparency && (
						<View style={{ width: '100%' }}>
							<FormInputSlider
								testID={`${testID}_a`}
								inlineLabel="A"
								minimumValue={0}
								maximumValue={1}
								value={rgba.a}
								onChange={(val) => {
									const newRGBA = { ...rgba, a: val };
									setRGBA(newRGBA);
									setTempHex(rgbaToHex(newRGBA));
									setHSV(rgbToHSV(newRGBA.r, newRGBA.g, newRGBA.b));
								}}
							/>
						</View>
					)}
				</View>
			</ScrollView>
			<View
				style={{
					marginTop: 5,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				{_renderActionButtons()}
			</View>
		</View>
	);
};
