import React, { useEffect } from 'react';
import { View } from 'react-native';

import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { HSCard } from 'components/Card/HSCard';
import {
	ICreateTicketFormErrors,
	ICreateTicketFormValues,
	IUpdateTicketFormErrors,
	IUpdateTicketFormValues
} from 'config/interfaces/ticket';
import { useSpace } from 'hooks/useSpace';

interface ITicketForm {
	values: ICreateTicketFormValues | IUpdateTicketFormValues;
	errors: ICreateTicketFormErrors | IUpdateTicketFormErrors;
	onChange: (val: ICreateTicketFormValues | IUpdateTicketFormValues) => void;
	isLoading: boolean;
	ticketInUse?: boolean;
}

const TESTIDPREFIX = 'ticketform';

export const TicketForm = (props: ITicketForm) => {
	const {activeSpace} = useSpace()
	const { values, errors, onChange, isLoading, ticketInUse } = props;
	const { getContentTypeSections } = useContent('ticket');
	const { renderFormField } = useForm();

	useEffect(()=> {
		getContentTypeSections()
	},[activeSpace])

	return (
		<View testID="ticketedit_card_scrollview">
			{getContentTypeSections().map((section, idx) => (
				<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
					{section.fields.map((field) =>
						renderFormField(
							'ticket',
							TESTIDPREFIX,
							values,
							errors,
							field,
							onChange,
							field.fieldName === 'ticketcode' && ticketInUse
						)
					)}
				</HSCard>
			))}
		</View>
	);
};
