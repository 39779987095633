import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Keyboard, ScrollView, View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';

import { useQuery } from 'hooks/useQuery';
import { useTracker } from 'hooks/useTracker';
import { useSpace } from 'hooks/useSpace';

import { EMeetingOnsiteType, EMeetingType, IAttendee, IMeetingRequest } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { Avatar } from 'components/User';
import { H1 } from 'components/Text';
import { MeetingWizard } from 'components/Forms';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingRequest>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingRequest>;
type RouteParams = StackParamList[ERoutes.MeetingRequest];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'meetingrequest';

export const MeetingRequestScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { trackAction } = useTracker();

	const [formValues, setFormValues] = useState<IMeetingRequest>({});
	const [meetingPartner, setMeetingPartner] = useState<IAttendee | undefined>(undefined);
	const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);

	const saveMeetingRequest = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.saveMeetingRequest);

	useEffect(() => {
		if (route.params.id) {
			const meeting = meetings.find((e) => e.id === route.params.id);
			if (meeting) {
				setFormValues({ ...meeting, content: t('defaultMeetingMessage') });
			} else {
				setFormValues({
					ownerId: profile?.userId,
					partnerId: route.params.partnerId,
					meetingType: EMeetingType.Onsite,
					content: t('defaultMeetingMessage'),
					meetingOnsiteType: EMeetingOnsiteType.Table
				});
			}
		} else {
			setFormValues({
				ownerId: profile?.userId,
				partnerId: route.params.partnerId,
				meetingType: EMeetingType.Onsite,
				content: t('defaultMeetingMessage'),
				meetingOnsiteType: EMeetingOnsiteType.Table
			});
		}
	}, []);

	useEffect(() => {
		if (activeSpace) {
			if (!activeSpace.meetingSettings?.meetingType || activeSpace.meetingSettings?.meetingType?.includes(EMeetingType.Onsite)) {
				setFormValues({ ...formValues, meetingType: EMeetingType.Onsite });
			} else {
				setFormValues({ ...formValues, meetingType: EMeetingType.Remote });
			}
		}
	}, [activeSpace]);

	useEffect(() => {
		let _meetingPartner: typeof meetingPartner = undefined;
		if (attendees && formValues.ownerId && formValues.partnerId) {
			const _partnerId = profile?.userId === formValues.ownerId ? formValues.partnerId : formValues.ownerId;
			_meetingPartner = attendees.find((e) => e.userId === _partnerId);
		}

		setMeetingPartner(_meetingPartner);
	}, [formValues, attendees]);

	const _saveMeetingRequest = async () => {
		if (meetingPartner) {
			setIsSaveLoading(true);
			const res = await saveMeetingRequest({ meetingRequest: formValues });
			setIsSaveLoading(false);

			if (res) {
				trackAction('meeting', formValues.id ? 'Reschedule Meeting' : 'Request Meeting', `${res.id}`);
				navigation.replace(ERoutes.Chat, {
					spaceId: activeSpace?.spaceId,
					userId: meetingPartner.userId
				});
			}
		}
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _renderPartner = () => {
		if (meetingPartner) {
			return (
				<View style={{ alignItems: 'center', marginBottom: 20 }}>
					<H1>{t('Meeting with')}</H1>
					<Avatar
						testID={`${TESTIDPREFIX}_avatar`}
						avatar={meetingPartner?.smallImageUrl ?? meetingPartner.imageUrl}
						fullName={`${meetingPartner.firstName} ${meetingPartner.lastName}`}
						size="xxl"
						userId={meetingPartner?.userId}
					/>
					<H1 style={{ marginTop: 20 }}>{`${meetingPartner.firstName} ${meetingPartner.lastName}`}</H1>
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer contentKey="meetings">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
				testID={`${TESTIDPREFIX}_scrollview`}
			>
				<HSCard style={{ flex: 1 }}>
					{_renderPartner()}
					<MeetingWizard
						values={formValues}
						isLoading={isSaveLoading}
						onSubmit={_saveMeetingRequest}
						meetingPartner={meetingPartner}
						onChange={(values) => updateFormValues(values)}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const MeetingRequestScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Meetings')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
