import { EMeetingStatus, ITimeSlot } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import { View } from 'react-native';
import { showToast } from 'helper/toast';
import { MeetingTimeSelectListItem } from './MeetingTimeSelectListItem';
import { Text } from 'components/Text';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { t } from 'i18next';

interface IMeetingTimeSelect {
	date: string;
	multiSelect?: boolean;
	unavailableTimes?: ITimeSlot[];
	selected?: ITimeSlot[];
	availablePartnerTimes?: ITimeSlot[];
	onSelect?: (val: ITimeSlot | ITimeSlot[]) => void;
	isProhibited?: boolean;
}

export interface IMeetingTimeRangeInactive extends ITimeSlot {
	partnerName?: string;
}

export const MeetingTimeSelect = (props: IMeetingTimeSelect) => {
	const { date, multiSelect, unavailableTimes, selected, onSelect, availablePartnerTimes, isProhibited } = props;
	const { activeSpace } = useSpace();
	const { isPhone, isTablet, isTabletOrMobile } = useQuery();

	const [timeSlots, setTimeSlots] = useState<IMeetingTimeRangeInactive[]>([]);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);

	useEffect(() => {
		let _timeSlots: typeof timeSlots = [];

		const timeSlotLength = activeSpace?.meetingSettings?.meetingDuration ?? 30;

		let start = moment(date).set('hours', 8).set('minutes', 0).set('seconds', 0);
		let end = start.clone().set('hours', 18);

		if (activeSpace?.meetingSettings?.meetingEarliestStart) {
			const splitted = moment(activeSpace.meetingSettings.meetingEarliestStart).format('HH:mm').split(':');
			if (splitted.length === 2) {
				start = moment(date).set('hours', Number(splitted[0])).set('minutes', Number(splitted[1]));
			}
		}

		if (activeSpace?.meetingSettings?.meetingLatestEnd) {
			const splitted = moment(activeSpace.meetingSettings.meetingLatestEnd).format('HH:mm').split(':');
			if (splitted.length === 2) {
				end = end.clone().set('hours', Number(splitted[0])).set('minutes', Number(splitted[1]));
			}
		}

		if (availablePartnerTimes) {
			_timeSlots = [...availablePartnerTimes];
		} else {
			while (start.isSameOrBefore(end)) {
				const _slotStart = start.toISOString();
				const _slotEnd = start.clone().add(timeSlotLength, 'minutes').toISOString();

				_timeSlots.push({
					start: _slotStart,
					end: _slotEnd
				});
				start.add(timeSlotLength, 'minutes');
			}
		}

		_timeSlots.forEach((slot) => {
			const meeting = meetings?.find(
				(e) => e.status === EMeetingStatus.Accepted && e.timeSlot.start === slot.start && e.timeSlot.end === slot.end
			);

			if (meeting) {
				const _partnerId = meeting.ownerId === profile?.userId ? meeting.partnerId : meeting.ownerId;
				const partner = attendees.find((e) => e.userId === _partnerId);
				if (partner) {
					slot.partnerName = `${partner.firstName} ${partner.lastName}`;
				}
			}
		});

		if (unavailableTimes && !multiSelect) {
			unavailableTimes.forEach((timeSlot) => {
				_timeSlots = _timeSlots.filter((e) => e.start !== timeSlot.start && e.end !== timeSlot.end);
			});
		}

		if (_timeSlots.length > 0) {
			const now = moment();

			_timeSlots = _timeSlots.filter((e) => moment(e.start).isSameOrAfter(now) && moment(e.end).isSameOrBefore(end));
		}

		setTimeSlots(_timeSlots);
	}, [date, profile, meetings, multiSelect, availablePartnerTimes, activeSpace]);

	const _isActive = (item: ITimeSlot) => {
		return selected?.find((e) => e.start === item.start && e.end === item.end) !== undefined;
	};

	if (timeSlots.length === 0) {
		const now = moment();
		let err = t('Unknown Error');

		if (moment(date).isBefore(now, 'date')) {
			err = t('EditProfileMeetingTimeSlotsPast');
		}

		if (moment(date).format('DD.MM.YYYY') === now.format('DD.MM.YYYY')) {
			err = t('EditProfileMeetingTimeSlotsEmpty');
		}

		return (
			<View style={{ alignItems: 'center', marginVertical: 30, flex: 1 }}>
				<Text bold style={{ fontSize: 16 }}>
					{err}
				</Text>
			</View>
		);
	}

	return (
		<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
			{timeSlots.map((item) => {
				return (
					<MeetingTimeSelectListItem
						key={`timeselect_item_${item.start}_${item.end}`}
						slot={item}
						isActive={_isActive(item)}
						unavailableTimes={unavailableTimes}
						singleSelect={!multiSelect}
						onPress={() => {
							if (item.partnerName) {
								showToast('info', undefined, `You already have a meeting with ${item.partnerName} for this time slot`);
							} else {
								if (onSelect) {
									if (multiSelect) {
										let val = selected ? [...selected] : [];
										if (_isActive(item)) {
											val = val.filter((e) => e.start !== item.start && e.end !== item.end);
										} else {
											val.push(item);
										}
										onSelect(val);
									} else {
										onSelect(item);
									}
								}
							}
						}}
						isProhibited={isProhibited && (selected?.find((e) => e.start === item.start) ? true : false)}
						style={{ width: isPhone ? '20%' : isTablet ? `${100 / 7}%` : isTabletOrMobile ? '20%' : '25%' }}
					/>
				);
			})}
		</View>
	);
};
