import React, { ReactNode } from 'react';
import { ISpaceLoginFormErrors, ISpaceLoginFormValues } from 'config/interfaces';
import { useTranslation } from 'react-i18next';
import { FormTextInput } from 'components/Form';
import { View } from 'react-native';
import { isEmptyString } from 'helper';
import { Markdown } from 'components/Markdown';
import { useSpace } from 'hooks/useSpace';

interface ISpaceLoginForm {
	values: ISpaceLoginFormValues;
	errors: ISpaceLoginFormErrors;
	onChange: (val: ISpaceLoginFormValues) => void;
	handleEnter?: (e: any) => void;
	emailDisabled?: boolean;
	loginIntro?: string;
}

const TESTIDPREFIX = 'loginform';

export const SpaceLoginForm = (props: ISpaceLoginForm) => {
	const { values, errors, onChange, handleEnter, emailDisabled, loginIntro } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const _renderLoginIntro = () => {
		const elements: ReactNode[] = [];

		if (!isEmptyString(loginIntro) || !isEmptyString(activeSpace?.loginIntro)) {
			return (
				<View>
					<Markdown markdown={loginIntro ?? activeSpace?.loginIntro} />
				</View>
			);
		}

		return null;
	};

	return (
		<View>
			{_renderLoginIntro()}
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_email`}
				label={t('email')}
				value={values.email}
				error={errors.email}
				onChangeText={(value) => onChange({ ...values, email: value })}
				onKeyPress={handleEnter}
				autoCompleteType="email"
				keyboardType="email-address"
				isRequired
				skipTabPress
				isDisabled={emailDisabled}
			/>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_password`}
				label={t('password')}
				value={values.password}
				error={errors.password}
				onChangeText={(value) => onChange({ ...values, password: value })}
				onKeyPress={handleEnter}
				autoCompleteType="password"
				secureTextEntry
				isRequired
				skipTabPress
			/>
		</View>
	);
};
