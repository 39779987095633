import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { ChildButton } from 'components/Button';
import { IContentTypeField, IMediaItem, IOption } from 'config/interfaces';
import { Icon } from 'components/Icon';
import { EDefaultIconSet, getDefaultAspectRatioStyle, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { Image } from 'components/Image/Image';
import { useTheme } from 'hooks/useTheme';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { useContent } from 'hooks/useContent';
import { useSpace } from 'hooks/useSpace';
import { InfoPill } from 'components/InfoPill';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IMediaItemReferenceItem {
	testID: string;
	item: IMediaItem;
	onPress: () => void;
}

const IMAGEWIDTH = 60;

export const MediaItemReferenceItem = (props: IMediaItemReferenceItem) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const { getContentTypeFields } = useContent('mediaitem');

	const [mediaItemCategories, setMediaItemCategories] = useState<IContentTypeField[]>([]);
	const [categories, setCategories] = useState<IOption[]>([]);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _fields: typeof mediaItemCategories = [];

		if (activeSpace) {
			_fields = getContentTypeFields().filter((e) => e.showOnDetailScreen && e.fieldType === 'category');
		}

		setMediaItemCategories(_fields);
	}, [activeSpace]);

	useEffect(() => {
		const arr: typeof categories = [];

		mediaItemCategories.forEach((field) => {
			if (item[field.fieldName]) {
				const splitted = item[field.fieldName].split(',');
				splitted.forEach((key) => {
					const option = field.options?.find((e) => e.key === key);
					if (option) {
						const alreadyIn = arr.find((e) => e.label === option.label);
						if (!alreadyIn) {
							arr.push(option);
						}
					}
				});
			}
		});

		setCategories(arr);
	}, [mediaItemCategories, item]);

	const _renderCategoryTags = () => {
		if (categories.length > 0) {
			return (
				<View style={{ flexDirection: 'row', flexWrap: 'wrap', paddingTop: 5 }}>
					{categories.map((cat, idx) => {
						return (
							<InfoPill
								key={`${cat.key}_${idx}`}
								text={cat.label}
								backgroundColor={cat.backgroundColor ?? theme.primary}
								textColor={cat.textColor ?? theme.primaryContrast}
								containerStyle={{ marginRight: 5, marginBottom: 5 }}
							/>
						);
					})}
				</View>
			);
		}

		return null;
	};

	const _getPreviewImage = () => {
		if (item?.previewImage) {
			return item.previewImage;
		}
		if (item?.expos && item?.expos?.length > 0 && item?.expos[0] && item.expos[0].id) {
			const found = content?.expos?.find((e) => e.id === item.expos[0].id);
			if (found?.logo) {
				return found.logo;
			}
		}

		return undefined;
	};

	return (
		<ChildButton
			testID={`${testID}_button_${item.id}`}
			onPress={onPress}
			style={{
				flex: 1,
				padding: 10,
				marginBottom: 20,
				borderWidth: 1,
				borderLeftWidth: 5,
				borderRadius: 5,
				borderColor: theme.lightgray,
				borderLeftColor: theme.primary,
				flexDirection: 'row',
				alignItems: 'center'
			}}
		>
			<View style={{ width: IMAGEWIDTH }}>
				<Image
					style={{ ...getDefaultAspectRatioStyle(IMAGEWIDTH), borderRadius: 5 }}
					mediaObj={_getPreviewImage()}
					imageSize="small"
					expectedRatio={16 / 9}
					resizeMode={'contain'}
				/>
			</View>
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<View style={{ flexDirection: 'row' }}>
					<Text bold style={{ marginRight: item.isHidden ? 5 : undefined }}>
						{item.title}
					</Text>
					{item.isHidden ? <Icon name={EDefaultIconSet.AnswerWaiting} color={theme.warning} /> : null}
				</View>
				{!isEmptyString(item.subtitle) && (
					<Text italic style={{ fontSize: 12 }}>
						{item.subtitle}
					</Text>
				)}
				{_renderCategoryTags()}
			</View>
			{SHOW_CHEVRON_IN_LIST_ITEM && (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			)}
		</ChildButton>
	);
};
