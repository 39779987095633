import React, { useEffect, useRef, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { useHeaderHeight } from '@react-navigation/elements';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { EDefaultIconSet, isEmptyString, IS_ANDROID, IS_WEB, showFormErrorToast, swapArrayItems, validateForm } from 'helper';

import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { hsBottomMargin, hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { Dimensions, Vibration, View } from 'react-native';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { EditSpaceInitialFormValues, IEditSpaceFormErrors, IEditSpaceFormValues, IFeatureInfo, ILauncherSpace } from 'config/interfaces';
import { MAXTABCOUNT, possibleFeatures } from 'components/Forms/Space/SpaceWizard';
import { HSCard, HSDragCard } from 'components/Card';
import { FormCheckbox, FormNumberSwitch } from 'components/Form';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import { FormHint } from 'components/Form/FormHint';
import { H1, ItemTitle, WelcomeHint } from 'components/Text';
import i18next from 'i18next';
import { getEditSpaceFeaturesFormSchema } from 'config/yupSchemas';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import RBSheet from 'react-native-raw-bottom-sheet';
import { RoundButton } from 'components/Button';
import * as RootNavigation from '../../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Features>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Features>;
type RouteParams = StackParamList[ERoutes.Features];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'features';

export const FeaturesScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth, isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const headerHeight = useHeaderHeight();
	const screenHeight = Dimensions.get('window').height;
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [formErrors, setFormErrors] = useState<IEditSpaceFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateValues();
		}
	}, [activeSpace]);

	const _updateValues = () => {
		if (activeSpace) {
			let features: ILauncherSpace['features'] = {
				tabbarCount: MAXTABCOUNT,
				list: []
			};
			if (activeSpace.features) {
				features = { ...activeSpace.features };
			}

			possibleFeatures.forEach((feature) => {
				const found = features?.list.find((e) => e.key === feature.key);
				if (!found) {
					features?.list.push({
						key: feature.key,
						isActive: false
					});
				}
			});
			setFormValues({
				updated_at: activeSpace.updated_at,
				spaceId: activeSpace.spaceId,
				features
			});
		}
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'save':
				await _updateSpace();
				bottomSheetRef.current?.close();
				break;
			case 'add':
				navigation.navigate(ERoutes.EditFeature, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _updateSpace = async () => {
		if (activeSpace) {
			const errors = await validateForm(getEditSpaceFeaturesFormSchema(formValues), formValues);
			if (errors) {
				setFormErrors(errors);
				showFormErrorToast(errors);
				return;
			} else {
				setFormErrors({});
			}

			if (isFormLocked) {
				showAlert({
					title: t('Space has changed'),
					message: t('SpaceFormLockedSubtitle'),
					buttons: [
						{
							text: t('Apply Changes'),
							onPress: () => {
								_updateValues();
								setIsFormLocked(false);
							}
						},
						{
							text: t('Cancel'),
							style: 'destructive'
						}
					]
				});
				return;
			}

			const _values = {
				...formValues,
				registrationFields: { ...activeSpace.registrationFields }
			};

			const hasMeetingsActive = _values.features?.list.find((e) => e.isActive && e.key === 'meetings');
			if (hasMeetingsActive) {
				const allowChatsField = _values.registrationFields?.fields?.find((e) => e.fieldName === 'allowChats');
				if (allowChatsField) {
					allowChatsField.visibility = 'always';
				}
				const allowMeetingRequestsField = _values.registrationFields?.fields?.find((e) => e.fieldName === 'allowMeetingRequests');
				if (allowMeetingRequestsField) {
					allowMeetingRequestsField.visibility = 'always';
				}
			}

			const hasChatsActive = _values.features?.list.find((e) => e.isActive && e.key === 'chats');
			if (hasChatsActive) {
				const allowChatsField = _values.registrationFields?.fields?.find((e) => e.fieldName === 'allowChats');
				if (allowChatsField) {
					allowChatsField.visibility = 'always';
				}
			}

			setIsLoading(true);
			const updateSuccess = await updateSpace({ data: _values });
			setIsLoading(false);

			if (updateSuccess) {
				navigation.setParams({ prohibitNavigation: false });
				navigation.goBack();
			}
		}
	};

	const _handleChange = (values: IEditSpaceFormValues) => {
		setFormValues(values);
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _renderVisibilityHint = (info: IFeatureInfo) => {
		if (formValues?.features?.tabbarCount && info.isActive) {
			const index = formValues.features.list.filter((e) => e.isActive).findIndex((e) => e.key === info.key);
			return (
				<FormHint
					testID="visibility"
					hint={index < formValues.features.tabbarCount ? t('FeatureVisibilityInfoTabBar') : t('FeatureVisibilityInfoMore')}
				/>
			);
		}
		return null;
	};

	const _handleMove = (index: number, direction: 'up' | 'down') => {
		if (!formValues?.features?.list) {
			return;
		}
		const list: IFeatureInfo[] = swapArrayItems(index, direction === 'up' ? index - 1 : index + 1, formValues.features?.list);

		const features = formValues.features ? { ...formValues.features, list: list } : { list: list };
		_handleChange({ ...formValues, features });
	};

	const _handleIndexChange = (oldIndex: number, newIndex: number) => {
		if (!formValues?.features?.list) {
			return;
		}

		const features = { ...formValues.features };

		const element = features.list.splice(oldIndex, 1)[0];
		features.list.splice(newIndex, 0, element);

		_handleChange({ ...formValues, features });
	};

	const _renderItem = (params) => {
		const { item, index, drag, isActive } = params;

		let isDisabled = false;
		let disabledInfo = '';

		switch (item.key) {
			case 'chats':
			case 'meetings':
			case 'networking':
				isDisabled =
					!formValues.features ||
					!formValues.features.list ||
					!formValues.features.list.find((e) => e.key === 'attendees')?.isActive;
				disabledInfo = t('DisabledInfoAttendeeFeatures').replace('%FEATURE%', t(item.key));
				break;
			default:
				break;
		}

		return (
			<HSDragCard
				testID={`${TESTIDPREFIX}_dragitem_${item.key}`}
				isActive={isActive}
				onDrag={drag}
				onUpPress={index === 0 ? undefined : () => _handleMove(index, 'up')}
				onDownPress={index === (formValues?.features?.list?.length ?? 9999) - 1 ? undefined : () => _handleMove(index, 'down')}
				index={index}
				handleIndexChange={(newIndex) => _handleIndexChange(index, newIndex)}
				style={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}
			>
				<View style={{ flex: 1, alignItems: 'flex-start', marginRight: 10 }}>
					<ItemTitle text={item.label ?? t(item.key)} />
					{isDisabled && <FormHint testID={`${item.key}_hint`} hint={disabledInfo} />}
				</View>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: FormElementBottomMarginSmall }}>
					<View style={{ flex: 1 }}>
						<FormCheckbox
							style={{ marginBottom: 0 }}
							testID={`${TESTIDPREFIX}_checkbox_${item.label ? item.label.replace(/\s/g, '_') : item.key}`}
							isDisabled={isDisabled || isLoading}
							label={t('Activate')}
							onPress={() => {
								let _active = formValues.features ? { ...formValues.features } : { list: [] };
								const found = _active.list.find((e) => e.key === item.key);
								if (found) {
									found.isActive = !found.isActive;
								} else {
									_active.list.push({
										key: item.key,
										isActive: true
									});
								}

								_handleChange({ ...formValues, features: _active });
							}}
							value={formValues.features?.list?.find((e) => e.key === item.key)?.isActive}
						/>
					</View>
					<View style={{ flexDirection: 'row', alignSelf: 'center', flexWrap: 'wrap' }}>
						<RoundButton
							testID={`${TESTIDPREFIX}_${item.label ? item.label.replace(/\s/g, '_') : t(item.key)}_button_edit`}
							icon={EDefaultIconSet.Edit}
							onPress={() => navigation.navigate(ERoutes.EditFeature, { spaceId: activeSpace?.spaceId, key: item.key })}
							noMargin
						/>
						{item.isCustom && (
							<RoundButton
								testID={`${TESTIDPREFIX}_${item.label ? item.label.replace(/\s/g, '_') : t(item.key)}_button_delete`}
								icon={EDefaultIconSet.Delete}
								onPress={() => {
									if (formValues.features) {
										let features = { ...formValues.features };
										features.list = features.list.filter((e) => e.key !== item.key);
										_handleChange({ ...formValues, features });
									}
								}}
								color={theme.danger}
								noMargin
							/>
						)}
					</View>
				</View>
				<View style={{ marginTop: -FormElementBottomMarginSmall }}>{_renderVisibilityHint(item)}</View>
			</HSDragCard>
		);
	};

	return (
		<ScreenContainer isProtectedRoute>
			<DraggableFlatList
				testID={`${TESTIDPREFIX}_scrollview`}
				data={formValues.features?.list ?? []}
				style={{ height: IS_WEB ? screenHeight - headerHeight : '100%' }}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
				renderItem={(params) => _renderItem(params)}
				keyExtractor={(item, index) => `${TESTIDPREFIX}_${item.key}`}
				ListHeaderComponent={
					<HSCard
						style={{
							marginBottom: hsBottomMargin * 2
						}}
					>
						<H1 style={{ marginBottom: isEmptyString(t('editFeaturesHint')) ? 0 : hsTextBottomMarginSmall }}>
							{t('selectFeaturesHeader')}
						</H1>
						<WelcomeHint>{t('editFeaturesHint')}</WelcomeHint>
						<FormNumberSwitch
							testID={`${TESTIDPREFIX}_selection_tabbarcount`}
							label={t('TabBarCount')}
							hint={t('TabBarCountHint').replace('%COUNT%', MAXTABCOUNT)}
							value={formValues.features?.tabbarCount ?? MAXTABCOUNT}
							error={formErrors['features.tabbarCount']}
							options={[1, 2, 3, 4, 5]}
							onChange={(count) => {
								let _features = formValues.features
									? {
											...formValues.features,
											tabbarCount: count ?? undefined
									  }
									: {
											tabbarCount: count ?? MAXTABCOUNT,
											list: []
									  };
								_handleChange({ ...formValues, features: _features });
							}}
							formStyle={{ marginTop: 20, marginBottom: 0, justifyContent: 'flex-start' }}
							isRequired
						/>
					</HSCard>
				}
				onDragBegin={() => {
					if (IS_ANDROID) {
						Vibration.vibrate(10, false);
					}
				}}
				onDragEnd={({ data }) => {
					const features = formValues.features ? { ...formValues.features, list: data } : { list: data };
					_handleChange({ ...formValues, features });
				}}
			/>
			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_save`}
					icon={EDefaultIconSet.Save}
					label={t('Save')}
					isLoading={isLoading}
					isDisabled={!formValues.features?.list || formValues.features?.list.length < 1}
					onPress={() => _handleActionSheetPress('save')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					isDisabled={isLoading}
					onPress={() => _handleActionSheetPress('add')}
				/>
			</BottomSheet>
		</ScreenContainer>
	);
};

export const FeaturesScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Features')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
