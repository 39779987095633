import React, { useEffect, useRef, useState } from 'react';
import { Animated, PanResponder, PanResponderGestureState, TouchableWithoutFeedback, View } from 'react-native';
import chroma from 'chroma-js';
import { LinearGradient } from 'expo-linear-gradient';
import { IS_WEB } from 'helper';

interface IHuePicker {
	hue: number;
	saturation: number;
	value: number;
	onDragStart?: Function;
	onDragMove: Function;
	onDragEnd?: Function;
	onPress: Function;
	barHeight?: number;
}

const BARWIDTH = 12;
const BARHEIGHT = 200;
const SLIDERSIZE = 24;

export const HuePicker = (props: IHuePicker) => {
	const { hue, saturation, value, onPress, onDragStart, onDragEnd, barHeight } = props;

	const myValue = useRef({ saturation: 1, value: 1, hue: 0 });
	const dragStartValue = useRef({
		saturation: 1,
		value: 1,
		hue: 0
	});
	const sliderY = useRef<number>(0);

	const sizeRef = useRef<number>(BARHEIGHT);

	const [slider, setSlider] = useState<number>(0);

	useEffect(() => {
		sizeRef.current = barHeight ?? BARHEIGHT;
	}, [barHeight]);

	const [panResponder, setPandResponder] = useState(
		PanResponder.create({
			onStartShouldSetPanResponder: () => true,
			onStartShouldSetPanResponderCapture: () => true,
			onMoveShouldSetPanResponder: () => true,
			onMoveShouldSetPanResponderCapture: () => true,
			onPanResponderGrant: (evt, gestureState) => {
				if (onDragStart) {
					onDragStart();
				}
				dragStartValue.current = myValue.current;
				_fireDragEvent('onDragStart', gestureState);
			},
			onPanResponderMove: (evt, gestureState) => {
				_fireDragEvent('onDragMove', gestureState);
			},
			onPanResponderTerminationRequest: () => true,
			onPanResponderRelease: (evt, gestureState) => {
				if (onDragEnd) {
					onDragEnd();
				}
				_fireDragEvent('onDragEnd', gestureState);
			},
			onPanResponderTerminate: (evt, gestureState) => {
				_fireDragEvent('onDragTerminate', gestureState);
			},
			onShouldBlockNativeResponder: () => true
		})
	);

	useEffect(() => {
		myValue.current = { hue, saturation, value };
		sliderY.current = (_getHeight() * hue) / 360;
		setSlider((_getHeight() * hue) / 360);
	}, [hue, saturation, value]);

	const _getCurrentColor = () => {
		return chroma.hsl(hue, 1, 0.5).hex();
	};

	const _normalizeValue = (value: number) => {
		if (value < 0) return 0;
		if (value > 1) return 1;
		return value;
	};

	const _computeHueValueDrag = (gestureState: PanResponderGestureState) => {
		const { dy } = gestureState;
		const diff = dy / sizeRef.current;

		return _normalizeValue(dragStartValue.current.hue / 360 + diff) * 360;
	};

	const _computeHueValuePress = (event) => {
		const { nativeEvent } = event;
		if (IS_WEB) {
			const { layerY } = nativeEvent;
			return _normalizeValue(layerY / sizeRef.current) * 360;
		}
		const { locationY } = nativeEvent;
		return _normalizeValue(locationY / sizeRef.current) * 360;
	};

	const _fireDragEvent = (eventName: string, gestureState: PanResponderGestureState) => {
		if (props[eventName]) {
			props[eventName]({
				...myValue.current,
				hue: _computeHueValueDrag(gestureState),
				gestureState
			});
		}
	};

	const _firePressEvent = (event) => {
		if (onPress) {
			onPress({
				...myValue.current,
				hue: _computeHueValuePress(event),
				nativeEvent: event.nativeEvent
			});
		}
	};

	const _getHeight = () => {
		return barHeight ?? BARHEIGHT;
	};

	return (
		<View
			style={[
				{
					justifyContent: 'center',
					alignItems: 'center',
					paddingVertical: SLIDERSIZE / 2,
					paddingHorizontal: SLIDERSIZE - (SLIDERSIZE - BARWIDTH) / 2
				}
			]}
		>
			<TouchableWithoutFeedback onPress={_firePressEvent}>
				<LinearGradient
					colors={['#ff0000', '#ffff00', '#00ff00', '#00ffff', '#0000ff', '#ff00ff', '#ff0000']}
					style={{
						borderRadius: 5
					}}
				>
					<View
						style={{
							width: BARWIDTH,
							height: _getHeight()
						}}
					/>
				</LinearGradient>
			</TouchableWithoutFeedback>
			<Animated.View
				{...panResponder.panHandlers}
				style={{
					top: 0,
					position: 'absolute',
					borderColor: '#fff',
					width: SLIDERSIZE,
					height: SLIDERSIZE,
					borderRadius: SLIDERSIZE / 2,
					borderWidth: SLIDERSIZE / 10,
					backgroundColor: _getCurrentColor(),
					transform: [
						{
							translateY: sliderY.current
						}
					]
				}}
			/>
		</View>
	);
};
