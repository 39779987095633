import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import React from 'react';

interface INetworkingRoomControleButton {
	testID: string;
	icon: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	onPress: () => void;
	backgroundColor?: string;
	iconColor?: string;
}

export const NetworkingRoomControleButton = (props: INetworkingRoomControleButton) => {
	const { testID, icon, isDisabled, isLoading, onPress, backgroundColor, iconColor } = props;
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={testID}
			onPress={onPress}
			isDisabled={isDisabled}
			isLoading={isLoading}
			style={{
				marginRight: 10,
				backgroundColor: backgroundColor ?? theme.primary,
				padding: 10,
				borderRadius: 999
			}}
		>
			<Icon name={icon} color={iconColor ?? theme.primaryContrast} />
		</ChildButton>
	);
};
