import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import {
	ICreateMediaItemFormErrors,
	ICreateMediaItemFormValues,
	ILauncherSpace,
	IUpdateMediaItemFormErrors,
	IUpdateMediaItemFormValues
} from 'config/interfaces';
import { IFormSection, useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { HSCard } from 'components/Card/HSCard';
import { useSpace } from 'hooks/useSpace';

interface IMediaItemForm {
	values: ICreateMediaItemFormValues | IUpdateMediaItemFormValues;
	errors: ICreateMediaItemFormErrors | IUpdateMediaItemFormErrors;
	onChange: (val: ICreateMediaItemFormValues | IUpdateMediaItemFormValues) => void;
	isLoading: boolean;
	hiddenFields?: string[];
	space?: ILauncherSpace;
	isSelfService?: boolean;
	targetType?: string;
}

const TESTIDPREFIX = 'mediaitemform';

export const MediaItemForm = (props: IMediaItemForm) => {
	const { values, errors, onChange, hiddenFields, isLoading, space, isSelfService, targetType } = props;
	const { activeSpace } = useSpace();

	const { getContentTypeSections, getContentTypeFields } = useContent('mediaitem');
	const { renderFormField } = useForm();

	const [sections, setSections] = useState<IFormSection[]>([]);

	useEffect(() => {
		let _fields = getContentTypeFields(undefined, undefined, space);

		if (hiddenFields) {
			_fields = _fields.filter((field) => !hiddenFields.includes(field.fieldName));
		}

		setSections(getContentTypeSections(isSelfService, _fields));
	}, [activeSpace, space, hiddenFields]);

	return (
		<View testID="mediaitemedit_card">
			{sections.map((section, idx) => {
				return (
					<HSCard key={`${TESTIDPREFIX}_section_${idx}`}>
						{section.fields.map((field) =>
							renderFormField(
								'mediaitem',
								TESTIDPREFIX,
								values,
								errors,
								field,
								onChange,
								isLoading,
								space,
								isSelfService,
								targetType
							)
						)}
					</HSCard>
				);
			})}
		</View>
	);
};
