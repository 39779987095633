import React, { useEffect } from 'react';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormErrors, IEditVoteFormValues } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';
import { FormMultiSwitch } from 'components/Form/FormMultiSwitch';
import { useTranslation } from 'react-i18next';

interface IVoteApplauseForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteApplauseForm = (props: IVoteApplauseForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;
	const { t } = useTranslation();

	useEffect(() => {
		if ((values && values?.allowMultipleAnswers === null) || values?.allowMultipleAnswers === undefined) {
			onChange({ ...values, allowMultipleAnswers: true });
		}
	}, [values]);

	return (
		<CommonVoteForm
			testIdPrefix={testIdPrefix}
			values={values}
			errors={errors}
			onChange={onChange}
			isLoading={isLoading}
			additionalFields={
				<FormMultiSwitch
					testID={`${testIdPrefix}_multiswitch_allowmultipleanswers`}
					label={t('allowMultipleAnswersLabel')}
					hint={t('allowMultipleAnswersHint')}
					error={errors.allowMultipleAnswers}
					value={values.allowMultipleAnswers ? 'multiple' : 'once'}
					options={[
						{ key: 'multiple', label: t('Multiple') },
						{ key: 'once', label: t('Once') }
					]}
					onChange={(val) => onChange({ ...values, allowMultipleAnswers: val === 'multiple' ? true : false })}
					isDisabled={isLoading}
				/>
			}
		/>
	);
};
