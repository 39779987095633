import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { View } from 'react-native';

import { INewRegistrationField, IRegistrationField } from 'config/interfaces';
import { HSModal } from '../Modal';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Text } from 'components/Text';
import { FormHint } from 'components/Form/FormHint';
import { StepWizard } from 'components/StepWizard';
import { RegistrationFieldsMetaForm } from 'components/Forms/Space/RegistrationFields/RegistrationFieldsMetaForm';
import { useTheme } from 'hooks/useTheme';
import { useRegistration } from 'hooks/useRegistration';
import { ContentTypeFieldsOptionsForm } from 'components/Forms';
import { hsTextBottomMarginSmall } from 'config/styleConstants';

interface IAddRegistrationFieldModal {
	isVisible: boolean;
	onClose: () => void;
	onSubmit: (field: INewRegistrationField) => void;
	currentFields: IRegistrationField[];
}

const TESTIDPREFIX = 'addregistrationfieldmodal';

export const AddRegistrationFieldModal = (props: IAddRegistrationFieldModal) => {
	const { isVisible, onClose, onSubmit, currentFields } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { maxFieldCountForFieldType } = useRegistration();

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [errors, setErrors] = useState<Record<string, string>>({});
	const [values, setValues] = useState<INewRegistrationField>({});
	const [currentStep, setCurrentStep] = useState<number>(0);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!isVisible) {
			setValues({});
			setErrors({});
			setCurrentStep(0);
		}
	}, [isVisible]);

	const _updateField = (fieldName: string, value: any) => {
		setValues({ ...values, [fieldName]: value });
		setIsDirty(true);
	};

	const _submit = () => {
		if (values?.minCount && values.maxCount) {
			if (values.minCount > values.maxCount) {
				let _error = t('FormMinMaxError').replace('%FIELD1%', t('CategoryMinCount')).replace('%FIELD2%', t('CategoryMaxCount'));
				setErrors({ maxCount: _error });
				return;
			}
		}
		onSubmit(values);
		setErrors({});
		setValues({});
		setCurrentStep(0);
	};

	const _isFieldTypeButtonDisabled = (type: string) => {
		const currentFieldsForType = currentFields.filter((e) => e.fieldName.startsWith(type));

		return currentFieldsForType.length === maxFieldCountForFieldType[type];
	};

	const _getNewFieldNameForFieldType = (type: string) => {
		let currentieldsForType = currentFields.filter((e) => e.fieldName.startsWith(type));

		for (let i = 1; i <= maxFieldCountForFieldType[type]; i++) {
			const field = currentieldsForType.find((e) => e.fieldName === type + i);
			if (!field) {
				return type + i;
			}
		}
	};

	const _renderFieldTypeButton = (key: string, icon: string, title: string) => {
		let fieldType;

		switch (key) {
			case 'extra':
				fieldType = 'string';
				break;
			case 'checkbox':
				fieldType = 'boolean';
				break;
			case 'category':
				fieldType = 'category';
				break;
			case 'section':
				fieldType = 'section';
				break;
			case 'label':
				fieldType = 'label';
				break;
			default:
				break;
		}

		return (
			<View style={{ width: '33%', marginBottom: 10 }}>
				<RoundButton
					isOutline={!values.fieldName?.includes(key)}
					isStacked
					testID={`${TESTIDPREFIX}_fieldtype_${key}`}
					icon={icon}
					onPress={() => {
						setValues({
							fieldName: _getNewFieldNameForFieldType(key),
							fieldType,
							visibility: 'always',
							isCustomField: true,
							useAsFilter: 'never'
						});
					}}
					title={title}
					isDisabled={_isFieldTypeButtonDisabled(key)}
					alignSelf="center"
				/>
			</View>
		);
	};

	const _renderFieldTypeInfo = () => {
		if (!isEmptyString(values.fieldName)) {
			let str = '';
			if (values.fieldName?.startsWith('extra')) {
				str = t('FieldTypeInfoExtra');
			} else if (values.fieldName?.startsWith('checkbox')) {
				str = t('FieldTypeInfoCheckbox');
			} else if (values.fieldName?.startsWith('section')) {
				str = t('FieldTypeInfoSection');
			} else if (values.fieldName?.startsWith('label')) {
				str = t('FieldTypeInfoLabel');
			}

			if (str) {
				return (
					<View style={{ marginBottom: 5, width: '90%', alignSelf: 'center' }}>
						<FormHint testID={`${TESTIDPREFIX}_fieldtype`} hint={str} />
					</View>
				);
			}

			return null;
		}

		return null;
	};

	const _renderCurrentCountForFieldType = (type: string, label: string) => {
		const currentieldsForType = currentFields.filter((e) => e.fieldName.startsWith(type));

		return (
			<Text
				style={{
					fontSize: 12,
					color:
						currentieldsForType.length === maxFieldCountForFieldType[type]
							? theme.danger
							: currentieldsForType.length > maxFieldCountForFieldType[type] * 0.6
							? theme.warning
							: theme.text
				}}
			>
				{t('FieldTypeCountInfo')
					.replace('%CURRENT%', currentieldsForType.length.toString())
					.replace('%MAX%', maxFieldCountForFieldType[type])
					.replace('%LABEL%', label)}
			</Text>
		);
	};

	const _isNextButtonDisabled = () => {
		switch (currentStep) {
			case 2:
				return !values.options ||
					values.options.length === 0 ||
					values.options.find((o) => isEmptyString(o.key) || isEmptyString(o.label))
					? true
					: false;
			case 1:
				return isEmptyString(values.fieldLabel);
			case 0:
				return isEmptyString(values.fieldName);
			default:
				return true;
		}
	};

	const _getExtraStep = () => {
		if (values.fieldType === 'category' || values.fieldType === 'multiswitch') {
			return [
				<ContentTypeFieldsOptionsForm
					testID={TESTIDPREFIX}
					values={values}
					errors={errors}
					onChange={(field, value) => _updateField(field, value)}
					hideCountFields={values.fieldType !== 'category'}
				/>
			];
		}

		return [];
	};

	const _renderFieldTypeButtons = () => {
		return (
			<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
				{_renderFieldTypeButton('extra', EDefaultIconSet.TextField, 'Text')}
				{_renderFieldTypeButton('checkbox', EDefaultIconSet.Checkbox, 'Checkbox')}
				{_renderFieldTypeButton('category', EDefaultIconSet.Category, t('Category'))}
				{_renderFieldTypeButton('section', EDefaultIconSet.Section, t('Section'))}
				{_renderFieldTypeButton('label', EDefaultIconSet.Label, t('Label'))}
			</View>
		);
	};

	return (
		<HSModal
			isVisible={isVisible}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => {
									onClose();
									setValues({});
									setCurrentStep(0);
								}
							}
						]
					});
				} else {
					onClose();
					setValues({});
					setCurrentStep(0);
				}
			}}
			onSubmit={_submit}
			isSubmitDisabled={
				!values.fieldType ||
				isEmptyString(values.fieldName) ||
				isEmptyString(values.fieldLabel) ||
				(values.fieldType === 'category' && (!values.options || values.options.length === 0))
			}
			title={t('Add Field')}
		>
			<View style={{ flex: 1 }}>
				<StepWizard
					customCurrentIndex={currentStep}
					testIdPrefix={TESTIDPREFIX}
					getCurrentIndex={(val) => setCurrentStep(val)}
					completeFunction={() => _submit()}
					isLoading={false}
					components={[
						<View style={{ flex: 1 }}>
							<Text center style={{ marginBottom: hsTextBottomMarginSmall }}>
								{t('RegistrationFieldSelectType')}
							</Text>
							{_renderFieldTypeButtons()}
							{_renderFieldTypeInfo()}
							<View style={{ width: '90%', alignSelf: 'center' }}>
								{_renderCurrentCountForFieldType('extra', 'Text')}
								{_renderCurrentCountForFieldType('checkbox', 'Checkbox')}
								{_renderCurrentCountForFieldType('category', t('Category'))}
							</View>
						</View>,
						<RegistrationFieldsMetaForm
							testID={TESTIDPREFIX}
							values={values}
							errors={errors}
							onChange={(field, value) => _updateField(field, value)}
						/>,
						..._getExtraStep()
					]}
					isNextButtonDisabled={_isNextButtonDisabled()}
					nextButtonText={currentStep < 1 || (values.fieldType === 'category' && currentStep < 2) ? t('Next') : t('Save')}
				/>
			</View>
		</HSModal>
	);
};
