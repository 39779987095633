import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { Dispatch, useRematchDispatch } from 'rematch/store';
import { ScrollView, View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EditSpaceInitialFormValues, IEditSpaceFormValues, ITicketSettings, TTicketProvider } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { EDefaultIconSet } from 'helper';
import i18next from 'i18next';
import { FormMultiSwitch } from 'components/Form';
import { EmendoSettings, PretixSettings, XINGEventsSettings } from 'components/Ticket';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.TicketSettings>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.TicketSettings>;
type RouteParams = StackParamList[ERoutes.TicketSettings];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'ticketsettings';

export const TicketSettingsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
	const [editSpaceFormValues, setEditSpaceFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (activeSpace) {
			const ticketSettings: ITicketSettings = activeSpace.ticketSettings ?? {
				ticketProvider: 'hellospaces'
			};
			setFormValues({
				spaceId: activeSpace.spaceId,
				ticketSettings
			});
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isSaveLoading
		});
	}, [formValues, isSaveLoading]);

	const _updateSpace = async () => {
		if (formValues.ticketSettings?.ticketRequired && !activeSpace?.registrationRequired) {
			showAlert({
				title: t('RegistrationRequiredForTickets'),
				message: t('RegistrationRequiredForTicketsHint'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('TurnOnRegistrationRequired'),
						style: 'destructive',
						onPress: async () => {
							setIsSaveLoading(true);

							const regActivated = { ...formValues, registrationRequired: true };

							const updateSuccess = await updateSpace({ data: regActivated });
							setIsSaveLoading(false);
							if (updateSuccess) {
								navigation.setParams({ prohibitNavigation: false });
								navigation.goBack();
							}
						}
					}
				]
			});
		} else {
			setIsSaveLoading(true);
			const updateSuccess = await updateSpace({ data: formValues });
			setIsSaveLoading(false);

			if (updateSuccess) {
				navigation.setParams({ prohibitNavigation: false });
				navigation.goBack();
			}
		}
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _renderTicketIntroText = () => {
		return (
			<FormMarkdownEditor
				testID={`${TESTIDPREFIX}_markdown_intro`}
				label={t('Ticket Intro')}
				hint={t('Ticket Intro Hint')}
				value={formValues.ticketSettings?.ticketIntro}
				onChange={(val) => {
					const values = { ...editSpaceFormValues };

					if (!values.ticketSettings) {
						values.ticketSettings = {};
					}

					values.ticketSettings.ticketIntro = val;
					setEditSpaceFormValues(values);
					if (!route.params.prohibitNavigation) {
						navigation.setParams({ prohibitNavigation: true });
					}

					const ticketSettings: ITicketSettings = formValues.ticketSettings
						? { ...formValues.ticketSettings, ticketIntro: val }
						: { ticketIntro: val };
					updateFormValues({ ...formValues, ticketSettings });
				}}
				isDisabled={isSaveLoading}
			/>
		);
	};

	const _getTicketProviderInfo = () => {
		switch (formValues.ticketSettings?.ticketProvider) {
			case 'hellospaces':
				return t('TicketProviderHintHelloSpaces');
			case 'doo':
				return t('TicketProviderHintDoo');
			case 'xingevents':
				return t('TicketProviderHintXingEvents');
			case 'emendo':
				return t('TicketProviderHintEmendo');
			case 'pretix':
				return t('TicketProviderHintPretix');
			default:
				return undefined;
		}
	};

	const _renderTicketProviderSettings = () => {
		switch (formValues.ticketSettings?.ticketProvider) {
			case 'emendo':
				return <EmendoSettings testID={TESTIDPREFIX} />;
			case 'xingevents':
				return <XINGEventsSettings testID={TESTIDPREFIX} />;
			case 'pretix':
				return <PretixSettings testID={TESTIDPREFIX} />;
			case 'doo':
			case 'hellospaces':
			default:
				return null;
		}
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard style={{ flex: 1 }}>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_ticketrequired`}
						label={t('TicketRequired')}
						hint={t('TicketRequiredHint')}
						value={formValues.ticketSettings?.ticketRequired ? 'yes' : 'no'}
						options={[
							{
								key: 'yes',
								label: t('Yes')
							},
							{
								key: 'no',
								label: t('No')
							}
						]}
						onChange={(val) => {
							const ticketRequired = val === 'yes' ? true : false;
							const ticketSettings: ITicketSettings = formValues.ticketSettings
								? { ...formValues.ticketSettings, ticketRequired }
								: { ticketRequired };

							updateFormValues({ ...formValues, ticketSettings });
						}}
						isDisabled={isSaveLoading}
					/>
					<View>{_renderTicketIntroText()}</View>
					<FormMultiSwitch
						testID={`${TESTIDPREFIX}_multiswitch_ticketprovider`}
						label={t('Ticket Provider')}
						hint={t('TicketProviderHint')}
						error={_getTicketProviderInfo()}
						value={formValues.ticketSettings?.ticketProvider}
						options={[
							{
								key: 'hellospaces',
								label: 'HelloSpaces'
							},
							{
								key: 'xingevents',
								label: 'XING Events'
							},
							{
								key: 'emendo',
								label: 'Emendo'
							},
							{
								key: 'pretix',
								label: 'Pretix'
							},
							{
								key: 'doo',
								label: 'Doo'
							}
						].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
						onChange={(val) => {
							const ticketSettings: ITicketSettings = formValues.ticketSettings
								? { ...formValues.ticketSettings, ticketProvider: val as TTicketProvider }
								: { ticketRequired: false, ticketProvider: val as TTicketProvider };

							updateFormValues({ ...formValues, ticketSettings });
						}}
						isDisabled={isSaveLoading}
					/>
					{_renderTicketProviderSettings()}
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const TicketSettingsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Ticket Settings')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
