import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';

import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { View } from 'react-native';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { ICreateStreamFormValues } from 'config/interfaces';
import { EDefaultIconSet } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import { StreamCreateForm } from 'components/Forms';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StreamCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StreamCreate>;
type RouteParams = StackParamList[ERoutes.StreamCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'streamcreate';

export const StreamCreateScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('stream');
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<ICreateStreamFormValues>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const createStream = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createStream);

	useEffect(() => {
		if (route?.params?.streamId) {
			const found = content.streams.find((s) => s.id === route?.params?.streamId);

			if (found) {
				let newItem: ICreateStreamFormValues = { ...formValues };
				const fields = getContentTypeFields();
				fields.forEach((field) => {
					if (field.fieldName === 'title') {
						newItem[field.fieldName] = `${found[field.fieldName]} - ${t('copy')}`;
					} else if (field.fieldType === 'image' || field.fieldType === 'logo') {
						newItem[field.fieldName] = found[field.fieldName]?.url;
					} else if (field.fieldType === 'reference') {
						newItem[field.fieldName] = found[field.fieldName]?.map((item) => item.id);
					} else if (field.fieldType === 'singlereference') {
						newItem[field.fieldName] = found[field.fieldName]?.id;
					} else {
						newItem[field.fieldName] = found[field.fieldName];
					}
				});

				setFormValues(newItem);
			}
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createStream(),
			isLoading
		});
	}, [activeSpace, formValues, isLoading]);

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _createStream = async () => {
		setIsLoading(true);
		const res = await createStream({ stream: formValues });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="streams">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<StreamCreateForm
					testID={TESTIDPREFIX}
					values={formValues}
					onSubmit={_createStream}
					isLoading={isLoading}
					onChange={(values) => updateFormValues(values)}
					allowReferences
				/>
				<LoadingModal isLoading={isLoading} />
			</View>
		</ScreenContainer>
	);
};

export const StreamCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Stream')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
