import { ERoutes } from 'components/Navigation/routes';
import * as Linking from 'expo-linking';
import { IS_WEB } from './platform';
import { DEFAULT_PLATFORM_URL, DEFAULT_PLATFORM_SHARE_URL } from 'config/envConstants';
import { isEmptyString } from './string';

export const getSpaceShareUrl = (spaceId?: string) => {
	if (spaceId) return `${__DEV__ ? 'http://localhost:19006/app/spacesummary/' : DEFAULT_PLATFORM_SHARE_URL}${spaceId}`;
	return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}`;
};
export const getVoteShareUrl = (spaceId: string, voteId: number) => {
	return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/${spaceId}/${ERoutes.ActiveVoting}/${voteId}`;
};

export const getDetailShareUrl = (spaceId: string, itemTypeRoute: ERoutes, itemId: number) => {
	return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/${spaceId}/${itemTypeRoute}/${itemId}`;
};

export const openURL = async (url: string, self?: boolean) => {
	if (IS_WEB) {
		window.open(url, self ? '_self' : '_blank', 'noopener noreferrer');
		return;
	}

	try {
		const canOpen = await Linking.canOpenURL(url);
		if (canOpen) {
			Linking.openURL(url);
		}
	} catch (error) {
		console.log('could not open URL: ', url);
	}
};

export const getSelfServiceUrl = (selfServiceToken: string) => {
	return `${__DEV__ ? 'http://localhost:19006/' : DEFAULT_PLATFORM_URL}app/selfservice/${encodeURIComponent(selfServiceToken)}`;
};

export const getValidURL = (url: string) => {
	let validUrl = url;
	if (!isEmptyString(validUrl)) {
		if (!validUrl.toLowerCase().startsWith('https://')) {
			if (validUrl.toLowerCase().startsWith('http://')) {
				let splitted = validUrl.split('://');
				if (splitted.length === 2) {
					validUrl = `${splitted[0].toLowerCase()}://${splitted[1]}`;
				}
				validUrl = validUrl.replace('http://', 'https://');
			} else {
				validUrl = `https://${validUrl}`;
			}
		} else {
			let splitted = validUrl.split('://');
			if (splitted.length === 2) {
				validUrl = `${splitted[0].toLowerCase()}://${splitted[1]}`;
			}
		}
	}
	return validUrl;
};
