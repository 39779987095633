import { IEditMapFormValues } from 'config/interfaces';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getRequiredError } from './errors';

export const getMapValidationSchema = (values: IEditMapFormValues) => {
	return Yup.object().shape({
		title: Yup.string()
			.required(getRequiredError(i18next.t('title')))
			.nullable()
	});
};
