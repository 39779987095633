import i18next from 'i18next';

export type TStrapiError = IFormattedStrapiError | IStrapiErrorUnauthorized;
interface IFormattedStrapiError {
	statusCode: number;
	error: string;
	message: IStrapiMessageError[];
}
interface IStrapiMessageError {
	messages: IStrapiErrorMessage[];
}

interface IStrapiErrorMessage {
	id: string;
	message: string;
	field?: string;
}

interface IStrapiErrorUnauthorized {
	error: string;
	message: string;
}

export const translateStrapiError = (error: TStrapiError) => {
	let code;

	if (error.message && typeof error.message === 'string') {
		code = error.message;
	} else if (Array.isArray(error.message) && error.message[0] && error.message[0].messages && error.message[0].messages[0]) {
		code = error.message[0].messages[0].id;
	}

	if (code) {
		return i18next.t(code);
	}

	return i18next.t('UnknownError');
};
