import React, { useEffect } from 'react';
import { ISpaceResetPasswordFormErrors, ISpaceResetPasswordFormValues } from 'config/interfaces';
import { FormTextInput } from 'components/Form';
import { useTranslation } from 'react-i18next';
import { useRoute } from '@react-navigation/native';
import { View, Text } from 'react-native';
import { FormLabel } from 'components/Form/FormLabel';
import { hsBottomMargin } from 'config/styleConstants';

interface ISpaceResetPasswordForm {
	values: ISpaceResetPasswordFormValues;
	errors: ISpaceResetPasswordFormErrors;
	onChange: (val: ISpaceResetPasswordFormValues) => void;
}

const TESTIDPREFIX = 'resetpwform';

export const SpaceResetPasswordForm = (props: ISpaceResetPasswordForm) => {
	const { values, errors, onChange } = props;
	const { t } = useTranslation();
	const { params } = useRoute();

	useEffect(() => {
		if (params) {
			onChange({ ...values, email: params['email'], code: params['code'] });
		}
	}, []);

	return (
		<View>
			<View style={{ marginVertical: hsBottomMargin }}>
				<FormLabel testID="email_formlabel" label="E-Mail" />
				<Text style={{ marginVertical: 5 }}>{values.email}</Text>
			</View>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_password`}
				label={t('password')}
				isRequired
				value={values.password}
				error={errors.password}
				onChangeText={(value) => onChange({ ...values, password: value })}
				secureTextEntry
				skipTabPress
			/>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_passwordconfirmation`}
				label={t('passwordConfirmation')}
				isRequired
				value={values.passwordConfirmation}
				error={errors.passwordConfirmation}
				onChangeText={(value) => onChange({ ...values, passwordConfirmation: value })}
				secureTextEntry
				skipTabPress
			/>
		</View>
	);
};
