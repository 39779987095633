import React, { ReactNode } from 'react';
import { View, ViewStyle } from 'react-native';
import { FormElementBottomMarginSmall } from './constants';
import { FormError } from './FormError';
import { FormHint } from './FormHint';
import { FormLabel } from './FormLabel';

interface IFormFieldComponent {
	testID: string;
	children: ReactNode;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	error?: string;
	formStyle?: ViewStyle;
	needsErrorPadding?: boolean;
}

export const FormField = (props: IFormFieldComponent) => {
	const { testID, children, label, isRequired, hint, error, formStyle, needsErrorPadding } = props;

	return (
		<View style={{ width: '100%', marginBottom: FormElementBottomMarginSmall, ...formStyle }}>
			<FormLabel testID={testID} label={label} isRequired={isRequired} style={{ marginBottom: 5 }} />
			<FormHint testID={testID} hint={hint} />
			{children}
			<FormError testID={testID} error={error} needsPadding={needsErrorPadding} />
		</View>
	);
};
