import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { Keyboard, ScrollView } from 'react-native';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { TicketForm } from 'components/Forms/Ticket';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next, { t } from 'i18next';
import { getCreateTicketInitialFormValues, ICreateTicketFormValues } from 'config/interfaces/ticket';
import { useSelector } from 'react-redux';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.TicketCreate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.TicketCreate>;
type RouteParams = StackParamList[ERoutes.TicketCreate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'ticketcreate';

export const TicketCreateScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('ticket');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<ICreateTicketFormValues>({ ...getCreateTicketInitialFormValues() });
	const [formErrors, setFormErrors] = useState({});
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const tickets = useSelector((store: IRootState) => store.ticket.tickets);

	const createTicket = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.createTicket);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _createTicket(),
			isLoading
		});
	}, [activeSpace, formValues, isLoading]);

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _createTicket = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormErrors({});
		}

		if (
			!isEmptyString(formValues.email) &&
			tickets.find((t) => t.spaceId === activeSpace?.spaceId && t.email === formValues.email && t.id !== formValues.id)
		) {
			setFormErrors({ email: t('TicketcodeEmailInUse') });
			return;
		}

		const values = { ...formValues };
		values.ticketcode = values.ticketcode.toLowerCase().trim();
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
			}
		});
		setFormValues(values);

		setIsLoading(true);
		const res = await createTicket({ ticket: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="tickets">
			<ScrollView
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<TicketForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const TicketCreateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Ticket')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
