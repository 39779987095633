import { ILauncherSpace, ITrackingObject, TTrackingAction, TTrackingContentType } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import ReactGA from 'react-ga4';
import { useHspxMatomo } from 'components/Matomo';
import { IS_WEB } from 'helper';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';

export const useTracker = () => {
	const matomo = useHspxMatomo();

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);

	const trackActionAPI = useRematchDispatch((dispatch: Dispatch) => dispatch.tracking.trackAction);
	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);

	const trackAction = (
		type: TTrackingContentType,
		action: TTrackingAction,
		itemId?: string,
		info?: Record<string, string>,
		space?: ILauncherSpace
	) => {
		const activeSpace = getActiveSpace({});
		const currentSpace = space ?? activeSpace;

		if (currentSpace) {
			if (currentSpace.disableTracking) {
				return;
			}

			if (profile?.doNotTrack && profile.doNotTrack[currentSpace.spaceId]) {
				return;
			}

			const trackingObject: ITrackingObject = {
				type,
				action,
				spaceId: space ? space.spaceId : currentSpace.spaceId,
				userId: currentSpace?.anonymousTracking ? undefined : userInfos.userId,
				itemId,
				info
			};

			trackActionAPI({ trackingObject, space: currentSpace });

			if (action !== 'Open Detail') {
				matomo.trackEvent({
					category: 'Interactions',
					action,
					name: `ContentType: ${type}; ItemId: ${itemId}; ${info ? `AdditionalInfos: ${info}` : ''}`
				});

				if (ReactGA.isInitialized) {
					ReactGA.event({
						category: 'Interactions',
						action: `${action} ${itemId}`
					});
				}
			}
		}
	};

	const trackPageView = (page: string) => {
		const activeSpace = getActiveSpace({});

		if (activeSpace) {
			if (activeSpace.disableTracking) {
				return;
			}

			if (profile?.doNotTrack && profile.doNotTrack[activeSpace.spaceId]) {
				return;
			}

			const href = `${DEFAULT_PLATFORM_URL}/app/${activeSpace.spaceId}/${page}`;

			if (ReactGA.isInitialized) {
				ReactGA.send({ hitType: 'screenview', page: href });
			}

			if (IS_WEB) {
				matomo.trackPageView({
					href
				});
			} else {
				matomo.trackScreenView({
					name: href
				});
			}
		}
	};

	return {
		trackAction,
		trackPageView
	};
};
