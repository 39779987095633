import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, Dimensions, EmitterSubscription, Keyboard, View } from 'react-native';

import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { IS_ANDROID, IS_WEB } from 'helper';
import { useQuery } from 'hooks/useQuery';

interface IFloatingView {
	children?: ReactNode | ReactNode[];
	alignSelf: 'left' | 'right';
	isModal?: boolean;
}

export const FloatingView = (props: IFloatingView) => {
	const { children, alignSelf, isModal } = props;
	const { screenWidth } = useQuery();

	const shiftAndroid = useRef(new Animated.Value(0));

	const viewRef = useRef<View>(null);

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [absoluteHorizontal, setAbsoluteHorizontal] = useState<number>(1);
	const [shiftIOS, setShift] = useState(new Animated.Value(0));
	const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false);
	const [didShowListener, setDidShowListener] = useState<EmitterSubscription | null>();
	const [didHideListener, setDidHideListener] = useState<EmitterSubscription | null>();

	useEffect(() => {
		if (!IS_WEB) {
			setDidShowListener(Keyboard.addListener('keyboardDidShow', _handleKeyboardDidShow));
			if (IS_ANDROID) {
				setDidHideListener(Keyboard.addListener('keyboardDidHide', _handleKeyboardWillHide));
			} else {
				setDidHideListener(Keyboard.addListener('keyboardWillHide', _handleKeyboardWillHide));
			}
		}

		return () => {
			if (!IS_WEB) {
				if (didShowListener) {
					didShowListener.remove();
				}
				if (didHideListener) {
					didHideListener.remove();
				}
			}
		};
	}, []);

	const _handleKeyboardDidShow = (e) => {
		setIsKeyboardVisible(true);
		const { height: windowHeight } = Dimensions.get('window');
		const keyboardHeight = e.endCoordinates.height;
		viewRef.current?.measure((x, y, width, height, pageX, pageY) => {
			const fieldHeight = height;
			const fieldTop = pageY;
			let gap = windowHeight - keyboardHeight - (fieldTop + fieldHeight);
			if (gap >= 0) {
				// gap > 0 means that no scrolling is needed
				return;
			}
			setAbsoluteHorizontal(width);
			// not necessary if keyboardshift is active
			// if (isModal) {
			// 	Animated.timing(IS_ANDROID ? shiftAndroid.current : shiftIOS, {
			// 		toValue: gap,
			// 		duration: 100,
			// 		useNativeDriver: true
			// 	}).start();
			// }
		});
	};

	const _handleKeyboardWillHide = () => {
		setIsKeyboardVisible(false);
		Animated.timing(IS_ANDROID ? shiftAndroid.current : shiftIOS, {
			toValue: 0,
			duration: 100,
			useNativeDriver: true
		}).start();
	};

	const _getAbsoluteHorizontal = () => {
		// if (isKeyboardVisible) {
		// 	if (alignSelf === 'right') {
		// 		return Math.floor(containerWidth / 2 - absoluteHorizontal / 2);
		// 	}
		// }

		return 0;
	};

	const _renderAbsoluteView = () => {
		const alignNumber = _getAbsoluteHorizontal();
		let alignment = {};
		if (!isNaN(alignNumber)) {
			alignment =
				alignSelf === 'left' ? { left: Number(alignNumber), right: undefined } : { right: Number(alignNumber), left: undefined };
		}
		return (
			<Animated.View
				ref={viewRef}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				style={[
					{
						position: 'absolute',
						paddingHorizontal: isModal ? 0 : EHorizontalScreenPadding.Wide,
						bottom: 0,
						flexDirection: 'row',
						alignItems: 'center',
						transform: [{ translateY: IS_ANDROID ? shiftAndroid.current : shiftIOS }]
					},
					{ ...alignment }
				]}
			>
				{children}
			</Animated.View>
		);
	};

	if (IS_ANDROID) {
		return _renderAbsoluteView();
	}

	return <View style={{ width: isModal ? '100%' : screenWidth, alignSelf: 'center' }}>{_renderAbsoluteView()}</View>;
};
