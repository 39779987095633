import { ICreateSpaceFormValues, ILauncherSpace } from 'config/interfaces';
import { IConfigTheme } from 'rematch/interfaces';
import { DEFAULTTHEME } from 'rematch/models/config';
import { isDarkColor } from './color';

export const generateTheme = (space?: ILauncherSpace | ICreateSpaceFormValues) => {
	const newTheme: IConfigTheme = {
		...DEFAULTTHEME,
		primary: space?.primaryColor ?? DEFAULTTHEME.primary,
		background: space?.backgroundColor ?? DEFAULTTHEME.background,
		text: space?.textColor ?? DEFAULTTHEME.text,
		primaryContrast: space?.primaryContrastColor ?? DEFAULTTHEME.primaryContrast,
		contrast: space?.contrastColor ?? DEFAULTTHEME.contrast,
		navbarButton: space?.primaryColor ?? DEFAULTTHEME.primary,
		success: space?.successColor ?? DEFAULTTHEME.success,
		warning: space?.warningColor ?? DEFAULTTHEME.warning,
		danger: space?.dangerColor ?? DEFAULTTHEME.danger,
		gray: space?.grayColor ?? DEFAULTTHEME.gray,
		lightgray: space?.lightGrayColor ?? space?.grayColor ?? DEFAULTTHEME.lightgray,
		formgray: space?.formGrayColor ?? space?.grayColor ?? DEFAULTTHEME.formgray,
		contentBackgroundColor: space?.contentBackgroundColor ?? DEFAULTTHEME.contentBackgroundColor,
		navigationTextColor: space?.navigationTextColor ?? DEFAULTTHEME.navigationTextColor
	};

	if (newTheme.background && isDarkColor(newTheme.background)) {
		newTheme.hsShadowColor = newTheme.lightgray;
	}

	return newTheme;
};
