import { hsBorderRadius } from 'config/styleConstants';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useRef } from 'react';
import { Animated, View } from 'react-native';

interface IHorizontalBar {
	height: number;
	width: number;
}

export const HorizontalBar = (props: IHorizontalBar) => {
	const { height, width } = props;
	const { theme } = useTheme();

	const animatedWidth = useRef(new Animated.Value(1));

	useEffect(() => {
		Animated.timing(animatedWidth.current, {
			toValue: width,
			duration: 300,
			useNativeDriver: false
		}).start();
	}, [width]);

	return (
		<Animated.View
			style={{
				height,
				minWidth: 1,
				width: animatedWidth.current
			}}
		>
			<View
				style={{
					height: '100%',
					width: '100%',
					backgroundColor: theme.primary,
					borderRadius: hsBorderRadius
				}}
			/>
		</Animated.View>
	);
};
