import moment from 'moment';
import 'moment/locale/de';
import { useSpace } from 'hooks/useSpace';
import { isEmptyString } from 'helper';

export const useDate = () => {
	const { activeSpace } = useSpace();

	const getDayOfWeekShort = (date: string) => {
		try {
			if (activeSpace && !isEmptyString(activeSpace?.language)) {
				moment.locale(activeSpace?.language);
			}
		} catch (error) {
			console.log('could not set language', error);
		}
		return moment(date).format('dd');
	};

	const getDate = (date: string) => {
		try {
			if (activeSpace && !isEmptyString(activeSpace?.language)) {
				moment.locale(activeSpace?.language);
			}
		} catch (error) {
			console.log('could not set language', error);
		}
		return moment(date).get('date');
	};

	const getMonth = (date: string) => {
		try {
			if (activeSpace && !isEmptyString(activeSpace?.language)) {
				moment.locale(activeSpace?.language);
			}
		} catch (error) {
			console.log('could not set language', error);
		}
		return moment(date).format('M');
	};

	const getComparedDateString = (date: string) => {
		const now = moment();
		const dateMoment = moment(date);

		if (activeSpace && activeSpace?.language === 'de') {
			moment.locale('de');
		}

		if (now.isSame(dateMoment, 'date')) {
			return dateMoment.format('HH:mm');
		} else if (now.isSame(dateMoment, 'month')) {
			return dateMoment.format('DD. MMMM HH:mm');
		} else if (now.isSame(dateMoment, 'year')) {
			return dateMoment.format('DD MMMM HH:mm');
		}

		return dateMoment.format('DD.MM.YYYY HH:mm');
	};

	return {
		getDayOfWeekShort,
		getDate,
		getMonth,
		getComparedDateString
	};
};
