import React, { useState } from 'react';

import { View, ViewStyle } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { ChildButton } from 'components/Button';
import { normalizeFontSize } from 'helper';
import { Icon } from 'components/Icon';
import { FormIconPickerModal } from './FormIconPickerModal';
import { BUTTONBORDERWIDTH } from 'components/Button/constants';
import { FormHint } from '../FormHint';
import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';
import { t } from 'i18next';
import { FormElementBottomMarginSmall } from '../constants';

interface IFormIconPicker {
	testID: string;
	value?: string;
	onChange: (value: string | undefined) => void;
	label?: string;
	isRequired?: boolean;
	hint?: string;
	error?: string;
	isDisabled?: boolean;
	style?: ViewStyle;
}

const SIZE = 40;
const ICONSIZE = SIZE / 2;

export const FormIconPicker = (props: IFormIconPicker) => {
	const { testID, value, onChange, label, isRequired, hint, error, isDisabled, style } = props;
	const { theme } = useTheme();

	const [isIconPickerModalVisible, setIsIconPickerModalVisible] = useState<boolean>(false);

	const _renderIcon = () => {
		if (value) {
			return <Icon size={normalizeFontSize(ICONSIZE)} name={value} />;
		}

		return null;
	};

	return (
		<View style={[{ marginBottom: FormElementBottomMarginSmall }, style]}>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				<ChildButton
					testID={testID}
					style={{
						backgroundColor: theme.contentBackgroundColor ?? theme.background,
						height: SIZE,
						width: SIZE,
						borderRadius: SIZE / 2,
						borderWidth: BUTTONBORDERWIDTH,
						borderColor: theme.lightgray,
						alignItems: 'center',
						justifyContent: 'center'
					}}
					isDisabled={isDisabled}
					onPress={() => setIsIconPickerModalVisible(!isIconPickerModalVisible)}
				>
					{_renderIcon()}
				</ChildButton>
				<FormLabel testID={testID} label={label} style={{ marginLeft: 10 }} isRequired={isRequired} noBoldLabel />
			</View>
			<FormHint testID={testID} hint={hint} />
			<FormError testID={testID} error={error} />
			<FormIconPickerModal
				testID={testID}
				onClose={() => setIsIconPickerModalVisible(false)}
				isVisible={isIconPickerModalVisible}
				onChange={(val) => {
					onChange(val);
					setIsIconPickerModalVisible(false);
				}}
				label={t('CTAFormIconPickerModal')}
			/>
		</View>
	);
};
