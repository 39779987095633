import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { NavigationHeader, NavigationHeaderDropdown, NavigationHeaderTitle } from 'components/Navigation/Header';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { NavigationHeaderCancelButton } from 'components/Navigation/Header';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, IS_ANDROID, showFormErrorToast, validateForm } from 'helper';
import { BackHandler, FlatList, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { EditSpaceInitialFormValues, IEditSpaceFormErrors, IEditSpaceFormValues, IMailTemplate } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { getEditSpaceFormSchema } from 'config/yupSchemas';
import { FormTextInput } from 'components/Form';
import { MailTemplateListItem } from 'components/Mailing';
import {
	_getAccountConfirmationEmail,
	_getPasswordResetEmail,
	_getNewAdministratorEmail,
	_getNewModeratorEmail,
	_getExpoSelfServiceInvitation,
	_getSpeakerSelfServiceInvitation,
	_getInviteUnregisteredAdmin,
	_getHelloSpacesTicketInvitation,
	_getDefaultHelloSpacesInvitation
} from 'config/mailing';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { NoData } from 'components/NoData';
import { hsTopScreenPadding } from 'config/styleConstants';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { HEIGHT_PER_MINUTE, LANE_COLUMN_WIDTH_APP, LANE_COLUMN_WIDTH_WEB } from 'components/Schedule';
import moment from 'moment';
import { CardSeparationHeader } from 'components/Text';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MailTemplateList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MailTemplateList>;
type RouteParams = StackParamList[ERoutes.MailTemplateList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'mailtemplatelist';

export const MailTemplateListScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [spaceTemplates, setSpaceTemplates] = useState<IMailTemplate[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isUpdateSpaceLoading, setIsUpdateSpaceLoading] = useState<boolean>(false);
	const [editSpaceFormValues, setEditSpaceFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [editSpaceFormErrors, setEditSpaceFormErrors] = useState<IEditSpaceFormErrors>({});
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isSendToMeLoading, setIsSendToMeLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const updateMailTemplate = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateMailTemplate);
	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const sendMail = useRematchDispatch((dispatch: Dispatch) => dispatch.content.sendMail);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (IS_ANDROID) {
			BackHandler.addEventListener('hardwareBackPress', backAction);
		}
		return () => {
			if (IS_ANDROID) {
				BackHandler.removeEventListener('hardwareBackPress', backAction);
			}
		};
	}, [route.params]);

	const backAction = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
			showAlert({
				title: t('Leave Space'),
				message: t('LeaveSpaceSubtitle'),
				buttons: [
					{
						text: t('Cancel'),
						style: 'cancel'
					},
					{
						text: t('Leave Space'),
						style: 'destructive',
						onPress: async () => {
							await leaveSpace({});
							if (navigation.canGoBack()) {
								navigation.dispatch(StackActions.popToTop());
							}

							navigation.dispatch(
								StackActions.replace(
									!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary
								)
							);
						}
					}
				]
			});
		}

		return true;
	};

	useEffect(() => {
		let _templates: typeof spaceTemplates = [];

		if (content.mailtemplates && activeSpace?.spaceId) {
			_templates = content.mailtemplates.filter((e) => e.spaceId === activeSpace.spaceId);
			const hasPWRecoveryTemplate = _templates.find((e) => e.key === 'passwordRecovery');
			if (!hasPWRecoveryTemplate) {
				_templates.push(_getPasswordResetEmail());
			}

			const hasConfirmAccountTemplate = _templates.find((e) => e.key === 'confirmAccount');
			if (!hasConfirmAccountTemplate) {
				_templates.push(_getAccountConfirmationEmail());
			}

			const hasNewAdminTemplate = _templates.find((e) => e.key === 'newAdmin');
			if (!hasNewAdminTemplate) {
				_templates.push(_getNewAdministratorEmail());
			}

			const hasNewModeratorTemplate = _templates.find((e) => e.key === 'newModerator');
			if (!hasNewModeratorTemplate) {
				_templates.push(_getNewModeratorEmail());
			}

			const hasExpoSelfServiceInvitationTemplate = _templates.find((e) => e.key === 'expoSelfServiceInvitation');
			if (!hasExpoSelfServiceInvitationTemplate) {
				_templates.push(_getExpoSelfServiceInvitation());
			}

			const hasSpeakerSelfServiceInvitationTemplate = _templates.find((e) => e.key === 'speakerSelfServiceInvitation');
			if (!hasSpeakerSelfServiceInvitationTemplate) {
				_templates.push(_getSpeakerSelfServiceInvitation());
			}

			const hasHelloSpacesTicketInvitationTemplate = _templates.find((e) => e.key === 'helloSpacesTicketInvitation');
			if (!hasHelloSpacesTicketInvitationTemplate) {
				_templates.push(_getHelloSpacesTicketInvitation());
			}

			const hasNewUnregisteredAdminTemplate = _templates.find((e) => e.key === 'newUnregisteredAdmin');
			if (!hasNewUnregisteredAdminTemplate) {
				_templates.push(_getInviteUnregisteredAdmin());
			}

			const hasDefaultHelloSpacesInvitationTemplate = _templates.find((e) => e.key === 'defaultHelloSpacesInvitation');
			if (!hasDefaultHelloSpacesInvitationTemplate) {
				_templates.push(_getDefaultHelloSpacesInvitation(activeSpace.ticketSettings?.ticketRequired));
			}
		}
		const inactiveFeatureKeys =
			activeSpace?.features?.list?.filter((feature) => !feature.isActive)?.map((f) => f.key?.slice(0, -1)) ?? [];
		if (inactiveFeatureKeys?.length > 0) {
			_templates = _templates?.filter((t) => !inactiveFeatureKeys?.includes(t?.contentType ?? '') || t?.contentType === 'attendee');
		}

		const uniqueTemplates: typeof _templates = [];

		_templates
			.sort((a, b) => {
				return moment(a.updated_at).isBefore(moment(b.updated_at)) ? 1 : -1;
			})
			.forEach((e) => {
				if (!uniqueTemplates.find((e2) => e.key === e2.key)) {
					uniqueTemplates.push(e);
				}
			});

		uniqueTemplates.sort((a, b) => {
			const aVal = a.contentType;
			const bVal = b.contentType;
			if (aVal.toLowerCase() === bVal.toLowerCase()) {
				return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
			}
			return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
		});

		setSpaceTemplates(uniqueTemplates);
	}, [content, activeSpace]);

	useEffect(() => {
		if (activeSpace) {
			setEditSpaceFormValues({
				spaceId: activeSpace.spaceId,
				title: activeSpace.title,
				startDate: activeSpace.startDate,
				endDate: activeSpace.endDate,
				defaultMailTemplateHeader: activeSpace.defaultMailTemplateHeader,
				defaultMailTemplateFooter: activeSpace.defaultMailTemplateFooter,
				agendaHeightPerMinuteApp: activeSpace.agendaHeightPerMinuteApp ?? HEIGHT_PER_MINUTE,
				agendaHeightPerMinuteWeb: activeSpace.agendaHeightPerMinuteWeb ?? HEIGHT_PER_MINUTE,
				agendaWidthPerStageApp: activeSpace.agendaWidthPerStageApp ?? LANE_COLUMN_WIDTH_APP,
				agendaWidthPerStageWeb: activeSpace.agendaWidthPerStageWeb ?? LANE_COLUMN_WIDTH_WEB
			});
		}
	}, [activeSpace]);

	const _deleteTemplate = async (template: IMailTemplate) => {
		setIsDeleteLoading(true);
		await updateMailTemplate({ mailTemplate: { ...template, isDeleted: true } });
		setIsDeleteLoading(false);
	};

	const _updateSpace = async () => {
		const errors = await validateForm(getEditSpaceFormSchema(editSpaceFormValues), editSpaceFormValues);
		if (errors) {
			setEditSpaceFormErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setEditSpaceFormErrors({});
		}

		setIsUpdateSpaceLoading(true);
		const updateSuccess = await updateSpace({ data: editSpaceFormValues });
		setIsUpdateSpaceLoading(false);
		if (updateSuccess) {
			navigation.setParams({ prohibitNavigation: false });
		}
	};

	const _updateValues = (newValues: IEditSpaceFormValues) => {
		setEditSpaceFormValues(newValues);
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];
		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}
		setSelectedEntries(_selected);
	};

	const _filterDefaultEntries = (entries: number[]) => {
		const filtered = entries.filter((tId: number) => !spaceTemplates.find((t) => t.id === tId)?.key);
		if (filtered) {
			return filtered;
		}
		return entries;
	};

	const _handleActionSheetPress = async (key: string) => {
		const selectedTemplate = spaceTemplates.find((e) => e.id === selectedEntries[0]);

		switch (key) {
			case 'add':
				navigation.navigate(ERoutes.EditMailTemplate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'sendMail':
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					template: selectedEntries.length === 1 && selectedTemplate ? selectedTemplate : undefined,
					contentType: selectedEntries.length === 1 && selectedTemplate ? selectedTemplate.contentType : undefined,
					entryPoint: selectedEntries.length === 1 && selectedTemplate ? 'receivers' : 'template'
				});
				bottomSheetRef.current?.close();
				break;
			case 'sendMailToMe':
				if (selectedTemplate && profile) {
					bottomSheetRef.current?.close();
					setIsSendToMeLoading(true);
					await sendMail({
						...selectedTemplate,
						mailTemplateId: selectedTemplate.id,
						selectedEntries: [profile.userId],
						noHistory: true
					});
					setIsSendToMeLoading(false);
				}
				break;
			case 'delete':
				const filteredEntries = _filterDefaultEntries(selectedEntries);
				if (filteredEntries?.length > 0) {
					setIsDeleteLoading(true);
					filteredEntries?.forEach(async (templateId) => {
						const template = spaceTemplates.find((t) => t.id === templateId);
						if (template) {
							await updateMailTemplate({ mailTemplate: { ...template, isDeleted: true } });
						}
					});
					setSelectedEntries([]);
					setIsDeleteLoading(false);
					bottomSheetRef.current?.close();
				}
				break;
		}
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="mailtemplates">
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<CardSeparationHeader label={t('Settings')} />
				<HSCard>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_defaultmailtemplateheader`}
						label={t('defaultMailTemplateHeader')}
						hint={t('defaultMailTemplateHeaderHint')}
						value={editSpaceFormValues.defaultMailTemplateHeader}
						onChangeText={(defaultMailTemplateHeader) => _updateValues({ ...editSpaceFormValues, defaultMailTemplateHeader })}
						error={editSpaceFormErrors.defaultMailTemplateHeader}
						isDisabled={isUpdateSpaceLoading}
						multiline
					/>
					<FormTextInput
						testID={`${TESTIDPREFIX}_textinput_defaultmailtemplatefooter`}
						label={t('defaultMailTemplateFooter')}
						hint={t('defaultMailTemplateFooterHint')}
						value={editSpaceFormValues.defaultMailTemplateFooter}
						onChangeText={(defaultMailTemplateFooter) => _updateValues({ ...editSpaceFormValues, defaultMailTemplateFooter })}
						error={editSpaceFormErrors.defaultMailTemplateFooter}
						isDisabled={isUpdateSpaceLoading}
						multiline
					/>
					<RoundButton
						testID={`${TESTIDPREFIX}_button_submit`}
						onPress={() => _updateSpace()}
						title={t('Save')}
						icon={EDefaultIconSet.Save}
						isLoading={isUpdateSpaceLoading}
						alignSelf="flex-end"
					/>
				</HSCard>
				<FlatList
					testID={`${TESTIDPREFIX}_scrollview`}
					data={spaceTemplates}
					ListHeaderComponent={<CardSeparationHeader label={t('Mail Templates')} />}
					ListEmptyComponent={<NoData type="NoMailTemplates" />}
					keyExtractor={(item, index) => `${TESTIDPREFIX}_template_${index}`}
					renderItem={({ item }) => (
						<MailTemplateListItem
							testID={TESTIDPREFIX}
							item={item}
							onEdit={() =>
								navigation.navigate(ERoutes.EditMailTemplate, {
									spaceId: activeSpace?.spaceId,
									id: item.id,
									key: item.key
								})
							}
							onDelete={() => _deleteTemplate(item)}
							isDeleteLoading={item.id !== undefined && isDeleteLoading === item.id}
							onSelect={(itemId) => _handleSelect(itemId)}
							isSelected={item.id !== undefined && selectedEntries.includes(item.id)}
						/>
					)}
				/>
			</ScrollView>

			<BottomSheet ref={bottomSheetRef}>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_add`}
					icon={EDefaultIconSet.Add}
					label={t('Add')}
					isLoading={isDeleteLoading || isUpdateSpaceLoading}
					onPress={() => _handleActionSheetPress('add')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_sendmailtome`}
					icon={EDefaultIconSet.Mail}
					label={t('Send Mail')}
					isDisabled={selectedEntries.length !== 1}
					isLoading={isDeleteLoading || isUpdateSpaceLoading}
					onPress={() => _handleActionSheetPress('sendMail')}
				/>
				<BottomSheetViewButton
					testID={`${TESTIDPREFIX}_button_sendmailtome`}
					icon={EDefaultIconSet.Mail}
					label={t('Send Mail To Me')}
					isDisabled={selectedEntries.length !== 1}
					isLoading={isDeleteLoading || isUpdateSpaceLoading}
					onPress={() => _handleActionSheetPress('sendMailToMe')}
				/>
				<BottomSheetViewButton
					isDisabled={_filterDefaultEntries(selectedEntries)?.length < 1}
					testID={`${TESTIDPREFIX}_button_delete`}
					icon={EDefaultIconSet.Delete}
					label={`${t('Delete')} ${
						(_filterDefaultEntries(selectedEntries)?.length ?? 0) > 0
							? `(${_filterDefaultEntries(selectedEntries)?.length})`
							: ''
					}`}
					onPress={() => _handleActionSheetPress('delete')}
					isLoading={isDeleteLoading || isUpdateSpaceLoading}
					iconColor={theme.danger}
				/>
			</BottomSheet>

			<LoadingModal isLoading={isSendToMeLoading} />
		</ScreenContainer>
	);
};

export const MailTemplateListScreenHeader = (props) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Mail Templates')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
