import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultNetworkingRoomFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			maxLength: 255
		},
		{
			fieldName: 'capacity',
			fieldLabel: i18next.t('capacity'),
			fieldHint: i18next.t('capacityHint').replace('%MAXCOUNT%', '16'),
			fieldType: 'number',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			minCount: 2,
			maxCount: 16
		},
		{
			fieldName: 'description',
			fieldLabel: i18next.t('Description'),
			fieldType: 'markdown',
			visibility: 'visible'
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Media'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'overviewImage',
			fieldLabel: i18next.t('Overview Image'),
			fieldType: 'image',
			visibility: 'visible'
		},
		{
			fieldName: 'section3',
			fieldLabel: i18next.t('Additional Settings'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'seatColor',
			fieldLabel: i18next.t('Seat Color'),
			fieldHint: i18next.t('Seat Color hint'),
			fieldType: 'color',
			visibility: 'visible'
		},
		{
			fieldName: 'isClosed',
			fieldLabel: i18next.t('isClosed'),
			fieldHint: i18next.t('isClosedHint'),
			fieldType: 'boolean',
			visibility: 'visible'
		},
		{
			fieldName: 'isMuteOnEnter',
			fieldLabel: i18next.t('isMuteOnEnter'),
			fieldHint: i18next.t('isMuteOnEnterHint'),
			fieldType: 'boolean',
			visibility: 'visible'
		},
		{
			fieldName: 'isAudioOnly',
			fieldLabel: i18next.t('isAudioOnly'),
			fieldHint: i18next.t('isAudioOnlyHint'),
			fieldType: 'boolean',
			visibility: 'visible'
		},
		{
			fieldName: 'votes',
			fieldLabel: i18next.t('Votings'),
			fieldType: 'reference',
			referenceType: 'votings',
			placeholder: i18next.t('PlaceholderVotings'),
			isDefaultField: true,
			visibility: 'visible'
		}
	];

	return arr;
};
