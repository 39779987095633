import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import { FormColorPicker, FormTextInput } from 'components/Form';
import { isEmptyString, IS_WEB } from 'helper';
import { HSModal } from '../Modal';
import { v4 } from 'uuid';

interface ICategoryFieldOptionModal {
	option: any;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (item) => void;
	keys?: string[];
}

const TESTIDPREFIX = 'categoryfieldoptionmodal';

export const CategoryFieldOptionModal = (props: ICategoryFieldOptionModal) => {
	const { option, onClose, onSubmit, onChange, keys } = props;
	const { t } = useTranslation();

	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [isDuplicateKey, setIsDuplicateKey] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (!option) {
			setIsDirty(false);
		}
	}, [option]);

	const _generateCategoryKey = (label: string) => {
		let newKey = label
			?.replace(/\s/g, '')
			.replace(/[^a-zA-Z0-9 ]/g, '')
			?.toLowerCase();

		while (keys?.includes(newKey)) {
			newKey = newKey + v4().toString().replace(/-/g, '').substring(0, 2).toLowerCase();
		}
		onChange({
			...option,
			key: newKey
		});
	};

	const _checkForUniqueKey = (key?: string) => {
		if (keys?.includes(key ?? option.key)) {
			setIsDuplicateKey(true);
		} else {
			setIsDuplicateKey(false);
		}
	};

	return (
		<HSModal
			isVisible={option !== undefined}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={onSubmit}
			isSubmitDisabled={isEmptyString(option?.key) || isEmptyString(option.label) || isDuplicateKey}
			title={t('Edit Option')}
		>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_label`}
				label={t('Label')}
				hint={t('LabelHint')}
				isRequired
				value={option?.label}
				onChangeText={(value) => onChange({ ...option, label: value })}
				onEndEditing={(value) => {
					if (IS_WEB) {
						return;
					}
					if (!option.key) {
						_generateCategoryKey(value?.nativeEvent?.text);
					}
				}}
				onBlur={(value) => {
					if (!IS_WEB) {
						return;
					}
					if (!option.key) {
						_generateCategoryKey(value?.nativeEvent?.text);
					}
				}}
			/>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_key`}
				label={t('Key')}
				hint={t('KeyHint')}
				isRequired
				value={option?.key}
				error={isDuplicateKey ? t('duplicateKeyError') : ''}
				onChangeText={(value) => {
					onChange({
						...option,
						key: value
							.trim()
							.replace(/[^a-zA-Z0-9 ]/g, '')
							.toLowerCase()
					});
					_checkForUniqueKey(value);
				}}
				onEndEditing={(value) => {
					if (IS_WEB) {
						return;
					}
					_checkForUniqueKey();
				}}
				onBlur={(value) => {
					if (!IS_WEB) {
						return;
					}
					_checkForUniqueKey();
				}}
			/>
			<FormColorPicker
				testID={`${TESTIDPREFIX}_colorpicker_backgroundColor`}
				onChange={(value) => onChange({ ...option, backgroundColor: value })}
				value={option?.backgroundColor}
				label={t('backgroundColor')}
				hint={t('ColorPickerHint')}
				description={t('CategoryOptionBackgroundColorDescription')}
			/>
			<FormColorPicker
				testID={`${TESTIDPREFIX}_colorpicker_textcolor`}
				onChange={(value) => onChange({ ...option, textColor: value })}
				value={option?.textColor}
				label={t('textColor')}
				hint={t('ColorPickerHint')}
				description={t('CategoryOptionTextColorDescription')}
			/>
		</HSModal>
	);
};
