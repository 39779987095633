import React, { useState, useEffect } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderHelloSpaces,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';
import { ILauncherSpace } from 'config/interfaces';
import { FlatList, View } from 'react-native';
import { HSCard } from 'components/Card';
import { FormTextInput } from 'components/Form';
import { EDefaultIconSet, getCardContainerStyle, isEmptyString, IS_WEB } from 'helper';
import { RoundButton } from 'components/Button';
import { SpaceHistoryListItem } from 'components/SpaceHistory';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { hsTopScreenPadding } from 'config/styleConstants';
import { showToast } from 'helper/toast';
import { NoData } from 'components/NoData';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceSearch>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceSearch>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceSearch];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const SpaceSearchScreen = ({ route, navigation }: Props) => {
	const { searchTerm } = route.params;

	const { screenColumnCount } = useQuery();
	const { t } = useTranslation();
	const { screenWidth } = useQuery();

	const [spaceId, setSpaceId] = useState<string>('');
	const [isCheckSpaceIdLoading, setIsCheckSpaceIdLoading] = useState<string | undefined>(undefined);
	const [searchSpaceResult, setSearchSpaceResult] = useState<ILauncherSpace[]>([]);

	const spaceHistory = useSelector((store: IRootState) => store.space.spaceHistory);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const { findSpaceById, searchSpace, setSpaceHistory } = useRematchDispatch((dispatch: Dispatch) => dispatch.space);

	useEffect(() => {
		if (route.params.spaces) {
			setSearchSpaceResult(route.params.spaces);
		}
	}, [route.params.spaces]);

	useEffect(() => {
		if (route.params.searchTerm) {
			setSpaceId(route.params.searchTerm);
		}
	}, [searchSpaceResult]);

	const _handleEnter = (e) => {
		if (e.key === 'Enter') {
			_searchForSpace();
		}
	};

	useEffect(() => {
		if (IS_WEB) {
			document.addEventListener('keydown', _handleEnter, false);
		}

		return () => {
			if (IS_WEB) {
				document.removeEventListener('keydown', _handleEnter, false);
			}
		};
	}, [spaceId]);

	const _findSpaceById = async (id: string) => {
		setIsCheckSpaceIdLoading(id);
		const res = await findSpaceById({ spaceId: id });
		setIsCheckSpaceIdLoading(undefined);
		if (res) {
			const foundAdmin = res.admins?.find((a) => a.id === profile?.id);
			const foundMod = res.admins?.find((a) => a.id === profile?.id);
			if (res.isDeactivated && !foundAdmin && !foundMod) {
				if (foundAdmin) {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitleAdmin'));
				} else {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
				}
			} else {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: res.spaceId });
				setSpaceId(id);
			}
		} else {
			setSpaceHistory(spaceHistory.filter((e) => e.space.spaceId !== id));
		}
	};

	const _searchForSpace = async () => {
		const trimmed = spaceId.trim();
		setSpaceId(trimmed);
		setIsCheckSpaceIdLoading(trimmed);
		const res = await searchSpace({ spaceId: trimmed });

		if (res) {
			if (res?.id) {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: res.spaceId });
				setSearchSpaceResult([res]);
			} else {
				setSearchSpaceResult(res);
			}
		} else {
			setSearchSpaceResult([]);
		}
		setIsCheckSpaceIdLoading(undefined);
	};

	const _renderContent = () => {
		return (
			<View style={{ flex: 1, paddingTop: hsTopScreenPadding, width: screenWidth, alignSelf: 'center' }}>
				<View style={{ paddingHorizontal: EHorizontalScreenPadding.Wide }}>
					<HSCard
						style={{
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						<FormTextInput
							formStyle={{ marginBottom: 0, flex: 1, marginRight: 10 }}
							testID="spaceselect_textinput_spaceid"
							placeholder={'Space ID'}
							value={spaceId}
							onChangeText={(val) => setSpaceId(val)}
							autoCapitalize="none"
							onKeyPress={_handleEnter}
							biggerTextInput
						/>
						<RoundButton
							testID="spaceselect_button_search"
							onPress={() => _searchForSpace()}
							isLoading={isCheckSpaceIdLoading !== undefined}
							isDisabled={isEmptyString(spaceId) || spaceId.length < 3 || isCheckSpaceIdLoading !== undefined}
							icon={EDefaultIconSet.Search}
						/>
					</HSCard>
				</View>
				{screenColumnCount && (
					<FlatList
						contentContainerStyle={{
							paddingHorizontal: EHorizontalScreenPadding.Wide
						}}
						numColumns={screenColumnCount}
						scrollEnabled
						data={searchSpaceResult}
						renderItem={({ item, index }) => (
							<SpaceHistoryListItem
								item={{ space: item, date: '' }}
								onItemPress={() => _findSpaceById(item.spaceId)}
								isLoading={isCheckSpaceIdLoading === item.spaceId}
								containerStyle={{
									...getCardContainerStyle(screenColumnCount, index, EHorizontalScreenPadding.Wide),
									maxWidth: `${100 / screenColumnCount}%`,
									flex: undefined
								}}
							/>
						)}
						ListEmptyComponent={
							<HSCard>
								<NoData type="NoSpaces" />
							</HSCard>
						}
						keyExtractor={(item) => `spacehistory_${item.id}`}
					/>
				)}
			</View>
		);
	};

	return <ScreenContainer>{_renderContent()}</ScreenContainer>;
};

export const SpaceSearchScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={''} />
			<NavigationHeaderHelloSpaces />
		</NavigationHeader>
	);
};
