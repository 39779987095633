import React from 'react';
import { Text } from 'components/Text';

interface IVerticalBarStats {
	percentage: string | number;
	largerDesign?: boolean;
}

export const VerticalBarStats = (props: IVerticalBarStats) => {
	const { percentage, largerDesign } = props;

	return <Text bold center style={{ fontSize: largerDesign ? 30 : 20, transform: [{ translateY: 0 }] }}>{`${percentage}%`}</Text>;
};
