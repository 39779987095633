import { IFormBase } from 'config/interfaces/form';
import React from 'react';
import { useTheme } from 'hooks/useTheme';
import { View, ViewStyle } from 'react-native';
import { FormField } from './FormField';
import { ChildButton } from 'components/Button/ChildButton';
import { Text } from 'components/Text';
import { hsBorderRadius } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';

interface IFormNumberSwitchComponent extends IFormBase {
	testID: string;
	value: number | number[];
	options: number[];
	onChange?: (val: number) => void;
	formStyle?: ViewStyle;
	optionStyle?: ViewStyle;
	size?: 'xs' | 'default';
	fullWidth?: boolean;
	allowMultiSelection?: boolean;
}

export const FormNumberSwitch = (props: IFormNumberSwitchComponent) => {
	const {
		testID,
		label,
		isRequired,
		hint,
		error,
		value,
		isDisabled,
		onChange,
		options,
		formStyle,
		optionStyle,
		size,
		fullWidth,
		allowMultiSelection
	} = props;
	const { theme } = useTheme();
	const { isTabletOrMobile, isPhone } = useQuery();

	const _getSize = (type: 'paddingHorizontal' | 'paddingVertical' | 'fontSize') => {
		switch (type) {
			case 'paddingHorizontal':
				switch (size) {
					case 'xs':
						if (isPhone) return 2;
						return 5;
					default:
						if (isPhone) return 4;
						return 10;
				}
			case 'paddingVertical':
				switch (size) {
					case 'xs':
						return 5;
					default:
						return 10;
				}
			case 'fontSize':
				switch (size) {
					case 'xs':
						if (isPhone) return 8;
						return 10;
					default:
						if (isPhone) return 12;
						return 14;
				}
			default:
				break;
		}
	};

	const _renderOption = (option: number, index: number) => {
		const _isActive = allowMultiSelection && typeof value !== 'number' ? value.includes(option) : value === option;
		const isLastItemInLine = options && index === options?.length - 1;
		const _maxWidth = (size === 'xs' ? 45 : 75) + option.toString().length * 5;

		const contrastColor = theme.primaryContrast === theme.primary ? theme.text : theme.primaryContrast;

		return (
			<ChildButton
				testID={`${testID}_button_number_${option}`}
				key={`${testID}_button_number_${option}`}
				style={{
					flex: 1,
					minWidth: isTabletOrMobile ? 20 : 50,
					justifyContent: 'center',
					alignItems: 'center',
					paddingHorizontal: _getSize('paddingHorizontal'),
					paddingVertical: _getSize('paddingVertical'),
					borderTopLeftRadius: index === 0 ? hsBorderRadius : 0,
					borderBottomLeftRadius: index === 0 ? hsBorderRadius : 0,
					borderTopRightRadius: isLastItemInLine ? hsBorderRadius : 0,
					borderBottomRightRadius: isLastItemInLine ? hsBorderRadius : 0,
					borderWidth: 1,
					borderColor: theme.gray,
					backgroundColor: _isActive ? theme.primary : theme.contentBackgroundColor,
					...optionStyle,
					maxWidth: fullWidth || isTabletOrMobile ? undefined : _maxWidth
				}}
				onPress={() => {
					if (onChange) {
						onChange(option);
					}
				}}
				isDisabled={isDisabled || !onChange}
			>
				<Text
					bold
					center
					style={{
						color: _isActive ? contrastColor : theme.text,
						fontSize: _getSize('fontSize'),
						overflow: fullWidth ? 'visible' : undefined
					}}
					adjustsFontSizeToFit
					numberOfLines={1}
				>
					{option}
				</Text>
			</ChildButton>
		);
	};

	if (options.length > 0) {
		return (
			<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
				<View
					style={{
						width: '100%',
						flexWrap: 'wrap',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: formStyle?.justifyContent ?? 'center'
					}}
				>
					{options.map((option, index) => _renderOption(option, index))}
				</View>
			</FormField>
		);
	}

	return null;
};
