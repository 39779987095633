import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton } from 'components/Button';
import { useTheme } from 'hooks/useTheme';
import { IBookingEntry } from 'screens';
import { SHOW_CHEVRON_IN_LIST_ITEM } from 'config/constants';
import { Icon } from 'components/Icon';
import { EDefaultIconSet } from 'helper';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useSpace } from 'hooks/useSpace';
import { IStage } from 'config/interfaces';

export const BOOKINGLISTITEMENTRYHEIGHT = 75;

export interface IBookingListItem {
	testID: string;
	entry: IBookingEntry;
	onPress?: () => void;
}

export const BookingListItem = (props: IBookingListItem) => {
	const { testID, entry, onPress } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [stage, setStage] = useState<IStage | undefined>(undefined);

	const content = useSelector((store: IRootState) => store.content.content);

	useEffect(() => {
		let _stage: typeof stage = undefined;

		if (activeSpace && content.stages && entry.item.stage) {
			_stage = content.stages.find((e) => e.spaceId === activeSpace.spaceId && e.id === entry.item.stage?.id);
		}

		setStage(_stage);
	}, [activeSpace, content.stages]);

	const _renderActions = () => {
		if (onPress && SHOW_CHEVRON_IN_LIST_ITEM) {
			return (
				<View style={{ paddingLeft: 10 }}>
					<Icon name={EDefaultIconSet.ChevronRight} size={30} />
				</View>
			);
		}

		return null;
	};

	return (
		<ChildButton
			testID={testID}
			style={{ height: BOOKINGLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ flex: 1 }}>
				<Text bold numberOfLines={2}>
					{`${entry.item.title}${
						entry.item.startDate && entry.item.endDate
							? ` (${moment(entry.item.startDate).format('HH:mm')} - ${moment(entry.item.endDate).format('HH:mm')})`
							: ''
					}`}
				</Text>
				{stage && <Text style={{ marginBottom: 2 }}>{stage.title}</Text>}
				<Text
					style={{
						color: entry.item.bookingCapacity
							? entry.bookings.length / entry.item.bookingCapacity >= 0.9 || entry.item.bookingCapacity <= 0
								? theme.danger
								: entry.bookings.length / entry.item.bookingCapacity >= 0.5
								? theme.warning
								: theme.success
							: theme.success
					}}
				>{`${t('Bookings')}: ${entry.bookings.length}${entry.item.bookingCapacity ? `/${entry.item.bookingCapacity}` : ''}`}</Text>
			</View>
			<View>{_renderActions()}</View>
		</ChildButton>
	);
};
