import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import {
	CreatePushNotificationInitialFormValues,
	ICreatePushNotificationFormErrors,
	ICreatePushNotificationFormValues,
	IUpdatePushNotificationFormErrors,
	IUpdatePushNotificationFormValues,
	UpdatePushNotificationInitialFormValues
} from 'config/interfaces';
import { CreateNotificationForm, UpdateNotificationForm } from 'components/Forms/Notification';
import { EDefaultIconSet, isEmptyString, showFormErrorToast, validateForm } from 'helper';
import { getCreatePushNotificationFormSchema, getUpdatePushNotificationFormSchema } from 'config/yupSchemas';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useSelector } from 'react-redux';
import { Keyboard, ScrollView } from 'react-native';
import { showToast } from 'helper/toast';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.PushNotification>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.PushNotification>;
type RouteParams = StackParamList[ERoutes.PushNotification];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const PushNotificationScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();

	const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
	const [formCreateValues, setFormCreateValues] = useState<ICreatePushNotificationFormValues>({
		...CreatePushNotificationInitialFormValues
	});
	const [formCreateErrors, setFormCreateErrors] = useState<ICreatePushNotificationFormErrors>({});
	const [formUpdateValues, setFormUpdateValues] = useState<IUpdatePushNotificationFormValues>({
		...UpdatePushNotificationInitialFormValues
	});
	const [formUpdateErrors, setFormUpdateErrors] = useState<IUpdatePushNotificationFormErrors>({});

	const pushNotifications = useSelector((store: IRootState) => store.pushNotification.pushNotifications);

	const createPushNotification = useRematchDispatch((dispatch: Dispatch) => dispatch.pushNotification.createPushNotification);
	const updatePushNotification = useRematchDispatch((dispatch: Dispatch) => dispatch.pushNotification.updatePushNotification);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (route.params?.id) {
			const noti = pushNotifications.find((e) => e.id === route.params?.id);

			if (noti) {
				setFormUpdateValues({ ...noti });
			} else {
				showToast('error', 'Error', t('PushNotificationNotFound'));
			}
		} else if (route.params.linkedType && route.params.linkedItemId) {
			let deeplinkType;
			switch (route.params.linkedType) {
				case 'schedule':
					deeplinkType = 'schedules';
					break;
				default:
					break;
			}
			setFormCreateValues({
				...CreatePushNotificationInitialFormValues,
				deeplinkType,
				deeplinkItemId: route.params.linkedItemId
			});
		}
	}, []);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: route.params?.id
				? formUpdateValues.hasBeenSend && formUpdateValues.receiver === 'app'
					? undefined
					: () => _updateNotification()
				: () => _createNotification(),
			isLoading: isSaveLoading
		});
	}, [formCreateValues, formUpdateValues, isSaveLoading]);

	const _create = async () => {
		setIsSaveLoading(true);
		const res = await createPushNotification({
			notification: {
				...formCreateValues,
				sendingInformation:
					route.params.linkedType || route.params.linkedItemId
						? {
								linkedType: route.params.linkedType,
								linkedItemId: route.params.linkedItemId
						  }
						: undefined
			}
		});
		if (res) {
			navigation.goBack();
		}
		setIsSaveLoading(false);
	};

	const _createNotification = async () => {
		const errors = await validateForm(getCreatePushNotificationFormSchema(formCreateValues), formCreateValues);
		if (errors) {
			setFormCreateErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormCreateErrors({});
		}

		if (formCreateValues.isActive) {
			_create();
		} else {
			showAlert({
				title: t('PushNotificationInactive'),
				message: t('PushNotificationInactiveMessage'),
				buttons: [
					{
						text: t('Save'),
						onPress: () => _create()
					},
					{
						text: t('Cancel'),
						style: 'cancel'
					}
				]
			});
		}
	};

	const _update = async () => {
		setIsSaveLoading(true);
		const res = await updatePushNotification({ notification: formUpdateValues });
		if (res) {
			setFormUpdateValues({ ...res });
			navigation.goBack();
		}
		setIsSaveLoading(false);
	};

	const _updateNotification = async () => {
		const errors = await validateForm(getUpdatePushNotificationFormSchema(formUpdateValues), formUpdateValues);
		if (errors) {
			setFormUpdateErrors(errors);
			showFormErrorToast(errors);
			return;
		} else {
			setFormUpdateErrors({});
		}

		if (formCreateValues.isActive) {
			_update();
		} else {
			showAlert({
				title: t('PushNotificationInactive'),
				message: t('PushNotificationInactiveMessage'),
				buttons: [
					{
						text: t('Save'),
						onPress: () => _update()
					},
					{
						text: t('Cancel'),
						style: 'cancel'
					}
				]
			});
		}
	};

	const _handleChange = (values) => {
		if (route.params.id) {
			setFormUpdateValues(values);
		} else {
			setFormCreateValues(values);
		}
		if (!route.params.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
	};

	const _renderForm = () => {
		if (route.params?.id) {
			return (
				<UpdateNotificationForm
					values={formUpdateValues}
					errors={formUpdateErrors}
					onChange={(newValues) => _handleChange(newValues)}
					isDisabled={isSaveLoading || formUpdateValues.hasBeenSend}
				/>
			);
		}

		return (
			<CreateNotificationForm
				values={formCreateValues}
				errors={formCreateErrors}
				onChange={(newValues) => _handleChange(newValues)}
				isDisabled={isSaveLoading}
			/>
		);
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="pushnotifications">
			<ScrollView
				testID="pushnotification_scrollview"
				keyboardShouldPersistTaps="handled"
				onScrollBeginDrag={() => Keyboard.dismiss()}
				scrollEventThrottle={0}
				contentContainerStyle={{
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				{_renderForm()}
			</ScrollView>
		</ScreenContainer>
	);
};

export const PushNotificationScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Push Notifications')} />
			{props.options.onRightNavPress ? (
				<NavigationHeaderIconButton
					testID="header_button_save"
					icon={EDefaultIconSet.Save}
					onPress={props.options.onRightNavPress}
					isLoading={props.options?.isLoading}
					isDisabled={props.options?.isRightNavPressDisabled}
				/>
			) : (
				<NavigationHeaderPlaceholder />
			)}
		</NavigationHeader>
	);
};
