import { IExpo } from 'config/interfaces';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Image } from 'components/Image/Image';
import { Text } from 'components/Text';
import { EXPOLISTITEMENTRYHEIGHT } from './ExpoListItem';
import { isEmptyString } from 'helper';
import { t } from 'i18next';
import { useTheme } from 'hooks/useTheme';

interface IExpoMailReceiverListItem {
	item: IExpo;
}

const IMAGEWIDTH = 60;

export const ExpoMailReceiverListItem = (props: IExpoMailReceiverListItem) => {
	const { item } = props;
	const { theme } = useTheme();

	const [imageRatio, setImageRatio] = useState<number>(1);

	return (
		<View style={{ height: EXPOLISTITEMENTRYHEIGHT, paddingVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
			<View style={{ width: IMAGEWIDTH }}>
				<Image
					style={{
						width: IMAGEWIDTH,
						height: IMAGEWIDTH / imageRatio
					}}
					onLoad={(e) => setImageRatio(e.source.width / e.source.height)}
					mediaObj={item.logo}
					imageSize="small"
					expectedRatio={16 / 9}
				/>
			</View>

			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text bold style={{ marginBottom: 2 }}>
					{item.title}
				</Text>
				<Text italic style={{ fontSize: 12, color: isEmptyString(item.selfServiceEmail) ? theme.danger : theme.success }}>
					{isEmptyString(item.selfServiceEmail) ? t('MissingSelfServiceEmail') : item.selfServiceEmail}
				</Text>
			</View>
		</View>
	);
};
