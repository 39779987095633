import { createModel } from '@rematch/core';
import { DATA_LOAD_STRATEGY, EDataLoadStrategy, MULTISPACEURL } from 'config/constants';
import { IMeeting } from 'config/interfaces';
import { isFeatureActive, translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import i18next from 'i18next';
import { IAcceptMeetingRequest, IDeclineMeetingRequest, ILoadAvailablePartnerTimes, ISaveMeetingRequestParams } from 'rematch/interfaces';
import { RootModel } from './index';
import moment from 'moment';
import { ENVIRONMENT } from 'config/envConstants';

type IDefaultState = {
	meetings: IMeeting[];
	lastMeetingRead: Record<string, string>;
};

const ZEROHOUR = '2020-01-01T12:00:00.094Z';

export const meeting = createModel<RootModel>()({
	state: {
		meetings: [],
		lastMeetingRead: {}
	} as IDefaultState,
	reducers: {
		addMeetings(state, { load, meetings }: { load: boolean; meetings: IDefaultState['meetings'] }) {
			rematchLog('addMeetings');
			const _meetings = state.meetings ? [...state.meetings] : [];
			let _lastMeetingRead = state.lastMeetingRead ? { ...state.lastMeetingRead } : {};

			meetings.forEach((meeting) => {
				const idx = _meetings.findIndex((e) => e.id === meeting.id);
				if (idx !== -1) {
					_meetings[idx] = meeting;
				} else {
					_meetings.push(meeting);
				}
				if (load && (!_lastMeetingRead[meeting.spaceId] || _lastMeetingRead[meeting.spaceId] < meeting.updated_at)) {
					_lastMeetingRead[meeting.spaceId] = meeting.updated_at;
				}
			});

			_meetings.sort((a, b) => {
				const aVal = moment(a.timeSlot.start);
				const bVal = moment(b.timeSlot.start);

				return aVal.isBefore(bVal) ? -1 : 1;
			});
			return {
				...state,
				meetings: _meetings,
				lastMeetingRead: _lastMeetingRead
			};
		},
		clear(state) {
			rematchLog('clear meeting');
			return {
				meetings: [],
				lastMeetingRead: {}
			};
		}
	},
	effects: (dispatch) => ({
		async startMyMeetingsSync(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace && store.auth.profile && isFeatureActive(activeSpace, 'meetings')) {
					if (store.socket.multiSpaceSocket && !store.socket.multiSpaceSocket.hasListeners(`${ENVIRONMENT}_meetings`)) {
						store.socket.multiSpaceSocket.on(`${ENVIRONMENT}_meetings`, async (data) => {
							const _load = !Array.isArray(data) && data.load;
							const _items = Array.isArray(data) ? data : data.items;

							if (_load) {
								contentLoadLog(4, 'mymeetings received');

								dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: false });
								if (!store.temp.hasLoadedData?.meetings) {
									dispatch.temp.setHasLoadedData('meetings');
								}
							}

							if (_items.length > 0) {
								dispatch.meeting.addMeetings({
									load: _load,
									meetings: _items
								});
							}
						});
					}
				}
			} catch (error) {
				errorLog('starMyMeetingsSync', error);
			}
		},
		async loadMyMeetingsDelta(payload, store) {
			try {
				const { force } = payload;

				const activeSpace = dispatch.temp.getActiveSpace({});

				if (store.auth.profile && activeSpace && isFeatureActive(activeSpace, 'meetings')) {
					const _lastRead =
						!force && store.meeting.lastMeetingRead && store.meeting.lastMeetingRead[activeSpace.spaceId]
							? store.meeting.lastMeetingRead[activeSpace.spaceId]
							: ZEROHOUR;

					if (!store.temp.hasLoadedData || store.temp.hasLoadedData.meetings) {
						contentLoadLog(3, 'mymeetings already loaded. skipping');
						return;
					}

					if (store.temp.waitingForSocketResponse?.meetings) {
						contentLoadLog(3, 'mymeetings load already in progress. skipping');
						return;
					}

					let _strategy = DATA_LOAD_STRATEGY;

					if (!store.socket.isMultiSpaceSocketConnected && store.temp.netInfoState?.isConnected) {
						_strategy = EDataLoadStrategy.Default;
					}

					switch (_strategy) {
						case EDataLoadStrategy.Offline:
							break;
						case EDataLoadStrategy.Socket:
							contentLoadLog(2, 'loading mymeetings');
							dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: true });

							dispatch.socket.emitToMultiSpaceBackend({
								event: 'loadContent',
								data: {
									type: 'meetings',
									userId: store.auth.userInfos.userId,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								}
							});
							break;
						case EDataLoadStrategy.Default:
						default:
							const body = new FormData();
							body.append(
								'data',
								JSON.stringify({
									userId: store.auth.profile.userId,
									userInfos: store.auth.userInfos,
									spaceId: activeSpace.spaceId,
									lastRead: _lastRead
								})
							);

							dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: true });

							const res = await dispatch.request.anonymousRequest({
								url: `${MULTISPACEURL}/mymeetings`,
								method: 'POST',
								body
							});

							if (res && Array.isArray(res)) {
								dispatch.temp.setHasLoadedData('meetings');
								dispatch.temp.setWaitingForSocketResponse({ key: 'meetings', value: false });

								if (res?.length > 0) {
									dispatch.meeting.addMeetings({ load: true, meetings: res });
								}
							}

							break;
					}
				}
			} catch (error) {
				console.log('loadMyMeetings', error);
			}
		},
		async stopMeetingSync(payload, store) {
			try {
				dispatch.socket.closeMultiSpaceEventListener({ event: 'meetings' });
			} catch (error) {
				console.log('stopMeetingSync', error);
			}
		},
		async loadAvailablePartnerTimes(payload: ILoadAvailablePartnerTimes, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { date, partnerId } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							date,
							partnerId,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/meetings/loadavailablepartnertimes`,
						body
					});

					if (res && Array.isArray(res)) {
						return res;
					}
				}

				return [];
			} catch (error) {
				console.log('loadAvailablePartnerTimes', error);
				return [];
			}
		},
		async saveMeetingRequest(payload: ISaveMeetingRequestParams, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { meetingRequest } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							meetingRequest,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos,
							partnerId: meetingRequest.partnerId
						})
					);

					if (meetingRequest.id) {
						const res = await dispatch.request.anonymousRequest({
							url: `${MULTISPACEURL}/meetings/reschedulemeeting`,
							method: 'POST',
							body
						});
						if (res?.id) {
							showToast('success', i18next.t('MeetingRescheduledTitle'), i18next.t('MeetingRescheduledSubtitle'));
							return true;
						} else {
							showToast('error', 'Error', translateStrapiError(res));
							return false;
						}
					} else {
						const res = await dispatch.request.anonymousRequest({
							url: `${MULTISPACEURL}/meetings`,
							method: 'POST',
							body
						});

						if (res?.id) {
							showToast('success', i18next.t('MeetingCreatedTitle'), i18next.t('MeetingCreatedSubtitle'));
							return res;
						}

						showToast('error', 'Error', translateStrapiError(res));
					}
				}

				return undefined;
			} catch (error) {
				console.log('saveMeetingRequest', error);
				return undefined;
			}
		},
		async acceptMeetingRequest(payload: IAcceptMeetingRequest, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { meetingRequest } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							meetingRequest,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/meetings/acceptmeeting`,
						method: 'POST',
						body
					});

					if (res?.id) {
						showToast('success', i18next.t('MeetingAcceptedTitle'), i18next.t('MeetingAcceptedSubtitle'));
						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
					return false;
				}
			} catch (error) {
				console.log('acceptMeetingRequest', error);
			}
		},
		async declineMeetingRequest(payload: IDeclineMeetingRequest, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const { meetingRequest } = payload;

					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							meetingRequest,
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						url: `${MULTISPACEURL}/meetings/declinemeeting`,
						method: 'POST',
						body
					});

					if (res?.id) {
						showToast('success', i18next.t('MeetingDeclinedTitle'), i18next.t('MeetingDeclinedSubtitle'));
						return true;
					}

					showToast('error', 'Error', translateStrapiError(res));
					return false;
				}
			} catch (error) {
				console.log('declineMeetingRequest', error);
			}
		}
	})
});
