import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import { IToggleLikeParams } from 'rematch/interfaces';
import { IRootState } from 'rematch/store';

import { Icon } from 'components/Icon';
import { Avatar } from 'components/User';
import { ILikeObject } from 'config/interfaces';
import { hsInnerPadding } from 'config/styleConstants';
import { getLikeIconByType } from 'helper';
import { useLike } from 'hooks/useLike';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { ChildButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';

interface ILikeList {
	itemId: string;
	testId: string;
	contentType: IToggleLikeParams['contentType'];
}

export const LikeList = (props: ILikeList) => {
	const { itemId, testId, contentType } = props;
	const { getLikesForItem } = useLike();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();

	const [likedBy, setLikedBy] = useState<ILikeObject[]>([]);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const likes = useSelector((store: IRootState) => store.like.likes);

	useEffect(() => {
		let res: ILikeObject[] = [];

		res = getLikesForItem(itemId, contentType);
		setLikedBy(res);
	}, [itemId, likes]);

	if (likedBy?.length > 0) {
		return (
			<FlatList
				horizontal
				data={likedBy}
				inverted
				contentContainerStyle={{
					paddingHorizontal: hsInnerPadding / 2,
					paddingVertical: hsInnerPadding / 2,
					alignItems: 'flex-end'
				}}
				renderItem={({ item }) => {
					const attendee = attendees.find((attendee) => attendee.userId === item?.id);
					if (attendee) {
						return (
							<ChildButton
								testID=""
								style={{ position: 'relative', marginLeft: 10 }}
								onPress={() =>
									navigation.navigate(ERoutes.AttendeeDetails, { spaceId: activeSpace?.spaceId, userId: attendee.userId })
								}
							>
								<Avatar
									avatar={attendee?.smallImageUrl ?? attendee?.imageUrl}
									fullName={`${attendee.firstName} ${attendee.lastName}`}
									userId={attendee.userId}
									size={'xs'}
								/>
								<Icon
									name={getLikeIconByType(item.type)}
									size={20}
									color={theme.primary}
									containerStyle={{
										position: 'absolute',
										bottom: -5,
										right: -10
									}}
								/>
							</ChildButton>
						);
					}
					return null;
				}}
			/>
		);
	}

	return null;
};
