import { IStrapiBase } from './main';
import { IContentTypeField } from './registration';
import { ILauncherSpace } from './space';

export interface IHSPXTicket extends IStrapiBase {
	firstName?: string;
	lastName?: string;
	email?: string;
	jobTitle?: string;
	company?: string;
	ticketcode: string;
	spaceId: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
}

export interface ICreateTicketFormValues {
	firstName?: string;
	lastName?: string;
	email?: string;
	jobTitle?: string;
	company?: string;
	ticketcode: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
}
export interface IUpdateTicketFormValues {
	id?: number;
	firstName?: string;
	lastName?: string;
	email?: string;
	jobTitle?: string;
	company?: string;
	ticketcode: string;
	checkbox1?: boolean;
	checkbox2?: boolean;
	checkbox3?: boolean;
	checkbox4?: boolean;
	checkbox5?: boolean;
	checkbox6?: boolean;
	checkbox7?: boolean;
	checkbox8?: boolean;
	checkbox9?: boolean;
	checkbox10?: boolean;
	extra1?: string;
	extra2?: string;
	extra3?: string;
	extra4?: string;
	extra5?: string;
	extra6?: string;
	extra7?: string;
	extra8?: string;
	extra9?: string;
	extra10?: string;
	updated_at?: string;
}

export interface ICreateTicketFormErrors {
	ticketcode?: string;
	email?: string;
}
export interface IUpdateTicketFormErrors {
	ticketcode?: string;
	email?: string;
}

export const getCreateTicketInitialFormValues = () => {
	const values: ICreateTicketFormValues = {
		ticketcode: ''
	};

	return values;
};

export interface ICreateTicketPayload {
	space?: ILauncherSpace;
	ticket: ICreateTicketFormValues;
	fields: IContentTypeField[];
	noToast?: boolean;
}

export interface IUpdateTicketPayload {
	ticket: IHSPXTicket | IUpdateTicketFormValues;
	fields: IContentTypeField[];
	isDeletion?: boolean;
}
