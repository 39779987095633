import React, { useEffect, useState } from 'react';
import { TextInput, View } from 'react-native';
import Slider from '@react-native-community/slider';
import { useTheme } from 'hooks/useTheme';
import { Text } from 'components/Text';
import { normalizeFontSize } from 'helper';
import { FORMELEMENTBORDERRADIUS, FORMELEMENTBORDERWIDTH, FormElementBottomMarginSmall } from './constants';

interface IFormInputSlider {
	testID: string;
	minimumValue: number;
	maximumValue: number;
	value?: number;
	onChange: (val: number) => void;
	inlineLabel?: string;
}

export const FormInputSlider = (props: IFormInputSlider) => {
	const { testID, minimumValue, maximumValue, value, onChange, inlineLabel } = props;
	const { theme } = useTheme();

	const [fieldValue, setFieldValue] = useState<string>('1');

	useEffect(() => {
		setFieldValue(value?.toString() ?? '1');
	}, [value]);

	return (
		<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: FormElementBottomMarginSmall }}>
			{inlineLabel && (
				<Text bold style={{ fontSize: normalizeFontSize(15), marginRight: 10 }}>
					{inlineLabel}
				</Text>
			)}
			<Slider
				testID={testID}
				style={{ flex: 1, height: 40 }}
				minimumValue={minimumValue}
				maximumValue={maximumValue}
				thumbTintColor={theme.contrast}
				minimumTrackTintColor={theme.primary}
				maximumTrackTintColor={theme.lightgray}
				value={value}
				onValueChange={(val) => onChange(+val.toFixed(2))}
			/>

			<TextInput
				testID={`${testID}_input`}
				style={{
					color: theme.text,
					borderRadius: FORMELEMENTBORDERRADIUS,
					borderWidth: FORMELEMENTBORDERWIDTH,
					borderColor: theme.lightgray,
					marginLeft: 10,
					paddingHorizontal: 10,
					paddingVertical: 10,
					width: 50
				}}
				onChangeText={(val) => {
					let _val = val;
					_val = _val.replace(',', '.');

					setFieldValue(_val);

					if (Number(_val)) {
						onChange(+Number(_val).toFixed(2));
					} else if (!_val.endsWith('.')) {
						onChange(_val);
					}
				}}
				textAlign="center"
				value={fieldValue}
			/>
		</View>
	);
};
