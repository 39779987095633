import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FlatList, View, ViewStyle } from 'react-native';
import moment from 'moment';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';

import { IAttendee, INews } from 'config/interfaces';
import { EDefaultIconSet, isDarkColor, isEmptyString, normalizeFontSize, openURL } from 'helper';
import { ChildButton, RoundButton, ShareButton } from 'components/Button';
import { ItemTitle, Text } from 'components/Text';
import { NewsMediaItem } from './NewsMediaItem';
import { Icon } from 'components/Icon';
import { useTheme } from 'hooks/useTheme';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Markdown } from 'components/Markdown';
import { ListBubbles } from 'components/List';
import { IS_WEB } from 'helper/platform';
import { HSCard } from 'components/Card';
import { UserHeader } from 'components/User';
import { hsActionButtonMargin, hsBottomMargin } from 'config/styleConstants';
import { FormCheckbox } from 'components/Form';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { useUnreadCount } from 'hooks/useUnreadCount';
import { TabbarBadge } from 'components/Navigation/TabbarBadge';
import { LikeButton, LikeList } from 'components/Like';
import { CommentList } from 'components/Comment';
import { IComment } from 'config/interfaces/comment';
import { isEmptyObject } from 'helper/object';
import { PlaceholderGradient } from 'components/Image';

interface INewsListItem {
	testID: string;
	item: INews;
	showText?: boolean;
	isPreview?: boolean;
	isSelected?: boolean;
	containerStyle?: ViewStyle;
	onSelect?: () => void;
	isEditMode?: boolean;
	isInDetail?: boolean;
}

export const NewsListItem = (props: INewsListItem) => {
	const { testID, isSelected, item, showText, isPreview, containerStyle, onSelect, isEditMode, isInDetail } = props;
	const { theme } = useTheme();
	const navigation = useNavigation();
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { trackAction } = useTracker();
	const { counter: pendingCommentCount } = useUnreadCount('comment', item?.id);

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [attendee, setAttendee] = useState<IAttendee | undefined>(undefined);
	const [unreadNewsCount, setUnreadNewsCount] = useState<number>(0);
	const [commentCount, setCommentCount] = useState<number>(0);
	const [isValidReference, setIsValidReference] = useState<boolean>(false);
	const [isScrollStopped, setIsScrollStopped] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateNews = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateNews);
	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const content = useSelector((store: IRootState) => store.content.content);
	const comments = useSelector((store: IRootState) => store.comment.comments);
	const votes = useSelector((store: IRootState) => store.vote.votes);

	const flatlistRef = useRef<any | undefined>(undefined);

	useFocusEffect(
		React.useCallback(() => {
			let interval;
			if (activeSpace?.enableGalleryScroll && flatlistRef.current && containerWidth > 1 && isInDetail) {
				let _currentIndex = -1;
				let _iteration = 0;

				interval = setInterval(() => {
					if (isScrollStopped) {
						clearInterval(interval);
						return;
					}
					if (flatlistRef.current && item.media && item.media.length > 1) {
						if (_currentIndex >= item.media.length) {
							_iteration++;
							if (_iteration >= 5) {
								clearInterval(interval);
								return;
							} else {
								_currentIndex = -1;
							}
						}

						_currentIndex = _currentIndex + 1;
						flatlistRef.current.scrollToOffset({ offset: _currentIndex * containerWidth });

						if (item.media[_currentIndex]?.media && item.media[_currentIndex].media?.mime?.includes('video')) {
							clearInterval(interval);
							return;
						}
					}
				}, (activeSpace?.galleryScrollInterval ?? 5) * 1000);
			}
			return () => {
				clearInterval(interval);
			};
		}, [flatlistRef, activeSpace?.enableGalleryScroll, containerWidth, isScrollStopped, item])
	);

	useEffect(() => {
		let _valid = true;

		switch (item.referencetype) {
			case 'expos':
				if (item.expo && content.expos && !content.expos.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.expo?.id))
					_valid = false;
				break;
			case 'externalUrl':
				if (isEmptyString(item.externalUrl)) _valid = false;
				break;
			case 'features':
				if (
					isEmptyObject(item.feature) ||
					(activeSpace?.features?.list && !activeSpace.features.list.find((e) => e.key === item.feature?.key && e.isActive))
				)
					_valid = false;
				break;
			case 'maps':
				if (item.map && content.maps && !content.maps.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.map?.id))
					_valid = false;
				break;
			case 'mediaitems':
				if (
					item.mediaitem &&
					content.mediaitems &&
					!content.mediaitems.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.mediaitem?.id)
				)
					_valid = false;
				break;
			case 'networkingrooms':
				if (
					item.networkingroom &&
					content.networkingrooms &&
					!content.networkingrooms.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.networkingroom?.id)
				) {
					_valid = false;
				}
				break;
			case 'schedules':
				if (
					(item.schedule &&
						content.schedules &&
						!content.schedules.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.schedule?.id)) ||
					item.schedule?.hasNoDetails
				)
					_valid = false;
				break;
			case 'speakers':
				if (
					item.speaker &&
					content.speakers &&
					!content.speakers.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.speaker?.id)
				)
					_valid = false;
				break;
			case 'stages':
				if (
					item.stage &&
					content.stages &&
					!content.stages.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.stage?.id)
				)
					_valid = false;
				break;
			case 'votings':
				if (item.vote && votes && !votes.find((e) => e.spaceId === activeSpace?.spaceId && e?.id === item.vote?.id)) _valid = false;
				break;
			default:
				_valid = true;
		}

		setIsValidReference(_valid);
	}, [item, content, votes, activeSpace]);

	useEffect(() => {
		if (activeSpace?.allowFeedComments && activeSpace?.allowFeedComments !== 'never' && item) {
			let _comments: IComment[] = [];

			_comments = comments?.filter(
				(c) => c.contentType === 'feed' && c.itemId === item?.id?.toString() && c.spaceId === activeSpace?.spaceId && !c.isDeleted
			);

			if (!iAmSpaceAdmin && !iAmSpaceModerator) {
				_comments = _comments?.filter((e) => e.status === 'Public' || e.userId === profile?.userId);
			}

			setCommentCount(_comments.length);
		}
	}, [item, activeSpace, comments]);

	useEffect(() => {
		let _attendee: typeof attendee = undefined;

		if (attendees && (item.userId || profile?.userId)) {
			if (isPreview) {
				_attendee = attendees.find((e) => e.userId === profile?.userId);
			} else {
				_attendee = attendees.find((e) => e.userId === item.userId);
			}
		}

		setAttendee(_attendee);
	}, [attendees, profile, item]);

	useEffect(() => {
		let _unreadNewsCount: typeof unreadNewsCount = 0;
		if (activeSpace && unreadNewsCount == 0) {
			if (content.newsitems && profile) {
				let newsForThisSpace = content?.newsitems?.filter((e) => e.spaceId === activeSpace?.spaceId);
				if (
					newsForThisSpace &&
					profile?.lastRead &&
					profile?.lastRead[activeSpace.spaceId] &&
					profile?.lastRead[activeSpace.spaceId]?.newsitems
				) {
					newsForThisSpace = newsForThisSpace?.filter(
						(e) =>
							(activeSpace?.admins?.find((e) => e?.id === profile?.id) ||
								activeSpace?.moderators?.find((e) => e?.id === profile?.id) ||
								e.isVisible) &&
							moment(!isEmptyString(item.showFrom) ? item.showFrom : item.created_at).isSameOrAfter(
								moment(profile?.lastRead![activeSpace.spaceId]!.newsitems!)
							)
					);
				}
				_unreadNewsCount = newsForThisSpace.length;
			}
			setUnreadNewsCount(_unreadNewsCount);
		}
	}, [activeSpace, content.newsitems, profile]);

	const _handlePress = () => {
		if (!isPreview) {
			item.isClickedOn = true;
			if (_hasReference() && (isEmptyString(item.text) || isInDetail)) {
				_handleNavigation();
			} else {
				navigation.navigate(ERoutes.NewsDetails, { spaceId: activeSpace?.spaceId, id: item?.id });
			}
		}
	};

	const _renderTitle = () => {
		let str = item.title;

		if (!isEmptyString(str)) {
			return <ItemTitle text={str} numberOfLines={isInDetail ? undefined : 3} />;
		}

		return null;
	};

	const _renderSubtitle = () => {
		let str = item.subtitle;

		if (!isEmptyString(str)) {
			return (
				<Text numberOfLines={isInDetail ? undefined : 3} italic>
					{str}
				</Text>
			);
		}

		return null;
	};

	const _renderDate = () => {
		const now = moment();
		const dateToUse = !isEmptyString(item.showFrom) ? moment(item.showFrom) : moment(item.created_at);

		const days = now.diff(dateToUse, 'days');
		const hours = now.diff(dateToUse, 'hours');
		const minutes = now.diff(dateToUse, 'minutes');
		const seconds = now.diff(dateToUse, 'seconds');

		let str = '';

		if (days > 1) {
			str = t('daysAgo').replace('%DAYS%', days.toString());
		} else if (days === 1) {
			str = t('dayAgo').replace('%DAYS%', days.toString());
		} else if (hours > 1) {
			str = `${hours} hours ago`;
			str = t('hoursAgo').replace('%HOURS%', hours.toString());
		} else if (hours === 1) {
			str = `${hours} hour ago`;
			str = t('hourAgo').replace('%HOURS%', hours.toString());
		} else if (minutes > 1) {
			str = `${minutes} minutes ago`;
			str = t('minutesAgo').replace('%MINUTES%', minutes.toString());
		} else if (minutes === 1) {
			str = `${minutes} minute ago`;
			str = t('minuteAgo').replace('%MINUTES%', minutes.toString());
		} else if (seconds > 1) {
			str = `${seconds} seconds ago`;
			str = t('secondsAgo').replace('%SECONDS%', seconds.toString());
		} else if (seconds === 1) {
			str = `${seconds} second ago`;
			str = t('secondAgo').replace('%SECONDS%', seconds.toString());
		}

		return (
			<Text
				numberOfLines={1}
				ellipsizeMode="tail"
				style={{
					color: isDarkColor(theme.contentBackgroundColor ?? theme.background) ? theme.lightgray : theme.gray,
					fontSize: 12
				}}
			>
				{str}
			</Text>
		);
	};

	const _updateVisibility = async () => {
		setIsLoading(true);
		const _isAnswerVisible = item.isVisible;
		showAlert({
			title: _isAnswerVisible ? t('hideAnswer') : t('showAnswer'),
			message: _isAnswerVisible ? t('hideAnswerSubtitle') : t('showAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isAnswerVisible ? t('hideAnswer') : t('showAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsLoading(true);
						await updateNews({ news: { id: item?.id, isVisible: !_isAnswerVisible } });
						setIsLoading(false);
					}
				}
			]
		});

		setIsLoading(false);
	};

	const _updatePinned = async () => {
		setIsLoading(true);
		const _isPinned = item.isPinned;
		showAlert({
			title: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
			message: _isPinned ? t('removePinAnswerSubtitle') : t('pinAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isPinned ? t('removePinAnswer') : t('pinAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsLoading(true);
						await updateNews({ news: { id: item?.id, isPinned: !_isPinned }, noToast: true });
						setIsLoading(false);
					}
				}
			]
		});

		setIsLoading(false);
	};

	const _renderVisibility = () => {
		if (activeSpace?.allowUserGeneratedContentInFeed === 'moderated' && !item.isVisible) {
			return (
				<View style={{ flex: 1 }}>
					<Text center style={{ fontSize: normalizeFontSize(12), color: theme.contrast, marginVertical: 5 }}>
						{t('AnswerWaiting')}
					</Text>
				</View>
			);
		}
		if (
			(!activeSpace?.allowUserGeneratedContentInFeed || activeSpace?.allowUserGeneratedContentInFeed === 'never') &&
			item.userId === userInfos?.userId &&
			!(iAmSpaceAdmin || iAmSpaceModerator)
		) {
			return (
				<View style={{ flex: 1 }}>
					<Text center style={{ fontSize: normalizeFontSize(12), color: theme.contrast, marginVertical: 5 }}>
						{t('Only visible to you')}
					</Text>
				</View>
			);
		}

		if (
			(((!activeSpace?.allowUserGeneratedContentInFeed || activeSpace?.allowUserGeneratedContentInFeed === 'never') &&
				!item.isVisible) ||
				(item.showUntil && moment(item.showUntil).isBefore(moment()))) &&
			(iAmSpaceAdmin || iAmSpaceModerator)
		) {
			return (
				<View style={{ flex: 1 }}>
					<Text
						center
						style={{
							fontSize: normalizeFontSize(12),
							color: theme.contrast,
							marginVertical: 5
						}}
					>
						{t('onlyVisibleForModeratorsAndAdmins')}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _delete = async () => {
		setIsLoading(true);
		const res = await updateNews({ news: { id: item?.id, isDeleted: true }, isDeletion: true });
		if (res) {
			if (navigation.canGoBack()) {
				navigation.goBack();
			} else {
				navigation.navigate(ERoutes.NewsList, { spaceId: activeSpace?.spaceId });
			}
		}
		setIsLoading(false);
	};

	const _getUnreadCommentsCount = () => {
		if (activeSpace && activeSpace.allowFeedComments && activeSpace.allowFeedComments !== 'never') {
			let _unreadCommentsCount = 0;
			if (commentCount && profile && profile?.lastRead) {
				_unreadCommentsCount = comments?.filter((c) => {
					moment(!isEmptyString(item.showFrom) ? item.showFrom : item.created_at).isAfter(
						moment(profile.lastRead[activeSpace.spaceId]?.newsitems)
					);
				})?.length;
			}

			if (_unreadCommentsCount < 0) {
				return _unreadCommentsCount;
			}

			return null;
		}

		return null;
	};

	const _handleNavigation = () => {
		if (item) {
			if (item.referencetype === 'externalUrl') {
				openURL(item.externalUrl ?? '');
				trackAction('feed', 'Open External URL', item?.id?.toString());
				return;
			}

			let route: ERoutes | undefined = undefined;
			const params = { spaceId: activeSpace?.spaceId };

			switch (item.referencetype) {
				case 'expos':
					if (item?.expo?.id) {
						route = ERoutes.ExpoDetails;
						params['id'] = item?.expo?.id;
					} else {
						route = ERoutes.Expos;
					}
					break;
				case 'features':
					switch (item.feature?.type) {
						case 'iFrame':
							route = ERoutes.Web;
							break;
						case 'markdown':
							route = ERoutes.Markdown;
							break;
					}
					params['key'] = item.feature?.key;
					break;
				case 'maps':
				case 'mappositions':
					route = ERoutes.Maps;
					params['mapId'] = item?.map?.id;
					params['positionId'] = item.mapposition?.id;
					break;
				case 'mediaitems':
					if (item.mediaitem?.id) {
						navigation.navigate(ERoutes.Media, {
							itemId: item?.mediaitem?.id,
							mediaType: 'mediaitem',
							spaceId: activeSpace?.spaceId
						});
						setMediaDetail({
							itemId: undefined,
							viewType: 'full',
							playbackStatus: 'paused'
						});
						return;
					} else {
						route = ERoutes.MediaItemList;
					}
					break;
				case 'networkingrooms':
					if (item.networkingroom?.id) {
						route = ERoutes.NetworkingRoom;
						params['id'] = item.networkingroom?.id;
					} else {
						route = ERoutes.NetworkingRoomList;
					}
					break;
				case 'schedules':
					if (item.schedule?.id) {
						navigation.navigate(ERoutes.Media, {
							itemId: item?.schedule?.id,
							mediaType: 'schedule',
							spaceId: activeSpace?.spaceId
						});
						setMediaDetail({
							itemId: undefined,
							viewType: 'full',
							playbackStatus: 'paused'
						});
						return;
					} else {
						route = ERoutes.Schedule;
					}
					break;
				case 'speakers':
					if (item.speaker?.id) {
						route = ERoutes.SpeakerDetails;
						params['id'] = item.speaker?.id;
					} else {
						route = ERoutes.Speakers;
					}
					break;
				case 'stages':
					route = ERoutes.Schedule;
					params['filter'] = item.stage?.id;
					break;
				case 'votings':
					if (item.vote?.id) {
						route = ERoutes.ActiveVoting;
						params['id'] = item.vote?.id;
					} else {
						route = ERoutes.VoteList;
					}
					break;
				default:
					break;
			}

			if (route) {
				console.log('route', route);
				console.log('params', params);
				navigation.navigate(route, params);
				trackAction('feed', 'Open Internal Reference', item?.id?.toString());
			}
		}
	};

	const _hasReference = () => {
		let refType = item.referencetype;
		if (refType === 'networkingrooms') {
			refType = 'networking';
		}
		return (
			refType &&
			(refType === 'externalUrl' ||
				refType === 'stages' ||
				activeSpace?.features?.list.find((e) => (e.key === refType || e.contentType === refType) && e.isActive) ||
				activeSpace?.features?.list.find((e) => e.key === item.feature?.key && e.isActive)) &&
			isValidReference
		);
	};

	const _renderAction = () => {
		if (isPreview) {
			return null;
		}
		const isOwner = item.userId === userInfos.userId;
		const elements: ReactNode[] = [];
		if (activeSpace?.allowFeedComments && activeSpace?.allowFeedComments !== 'never' && !isInDetail) {
			elements.push(
				<View key={`${testID}_button_comments`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						key={`${testID}_comments`}
						testID={`${testID}_comments`}
						onPress={() => navigation.navigate(ERoutes.NewsDetails, { spaceId: activeSpace?.spaceId, id: item?.id })}
						isLoading={isLoading}
						isOutline
						icon={EDefaultIconSet.Text}
						size="sm"
						leftBadge={_getUnreadCommentsCount() ?? pendingCommentCount}
						badge={commentCount}
					/>
				</View>
			);
		}
		if (activeSpace?.allowFeedLikes) {
			elements.push(
				<View key={`${testID}_button_togglelike`} style={{ marginRight: hsActionButtonMargin }}>
					<LikeButton
						size="sm"
						isLoading={isLoading}
						itemId={item?.id?.toString()}
						testId={`${testID}_like`}
						contentType="feed"
					/>
				</View>
			);
		}
		if (isEditMode) {
			elements.push(
				<View key={`${testID}_wrap_pin`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						key={`${testID}_pin`}
						testID={`${testID}_pin`}
						onPress={() => _updatePinned()}
						isLoading={isLoading}
						isOutline={!item.isPinned}
						icon={EDefaultIconSet.Pin}
						size="sm"
					/>
				</View>
			);
		}
		if (isEditMode && activeSpace?.allowUserGeneratedContentInFeed === 'moderated') {
			elements.push(
				<View key={`${testID}_wrap_visibility`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						key={`${testID}_visibility`}
						testID={`${testID}_visibility`}
						onPress={() => _updateVisibility()}
						isLoading={isLoading}
						isOutline={!item.isVisible}
						icon={item.isVisible ? EDefaultIconSet.AnswerPublic : EDefaultIconSet.AnswerWaiting}
						size="sm"
					/>
				</View>
			);
		}
		if (isEditMode || isOwner) {
			elements.push(
				<View key={`${testID}_edit`} style={{ marginRight: hsActionButtonMargin }}>
					<RoundButton
						isOutline
						testID={`${testID}_button_edit`}
						key={`${testID}_button_edit`}
						isLoading={isLoading}
						onPress={() => navigation.navigate(ERoutes.NewsEdit, { spaceId: activeSpace?.spaceId, id: item?.id })}
						icon={EDefaultIconSet.Edit}
						size="sm"
					/>
				</View>
			);
		}
		if (item.isVisible && activeSpace?.allowSharing) {
			elements.push(
				<ShareButton
					testID={`${testID}_button_share`}
					key={`${testID}_button_share`}
					itemId={item?.id}
					title={item.title}
					message={item.subtitle}
					route={ERoutes.NewsDetails}
					type="feed"
					containerStyle={{ marginRight: hsActionButtonMargin }}
					isRoundButton
					isLoading={isLoading}
				/>
			);
		}
		if (_hasReference()) {
			elements.push(
				<View key={`${testID}_${item.referencetype}`}>
					<RoundButton
						isOutline
						testID={`${testID}_button_${item.referencetype}`}
						key={`${testID}_button_${item.referencetype}`}
						onPress={() => _handleNavigation()}
						icon={item.referencetype === 'externalUrl' ? EDefaultIconSet.ExternalUrl : EDefaultIconSet.ChevronRight}
						size="sm"
					/>
				</View>
			);
		}

		const renderDelete = isEditMode || isOwner;
		return (
			<View
				style={{
					flexDirection: 'row',
					justifyContent: renderDelete ? 'space-between' : 'flex-end',
					padding: hsActionButtonMargin,
					paddingHorizontal: hsActionButtonMargin,
					flexWrap: 'wrap',
					flex: 1,
					alignItems: 'flex-end',
					marginBottom: hsBottomMargin
				}}
			>
				<View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginRight: hsActionButtonMargin }}>
					{isEditMode && onSelect && (
						<FormCheckbox
							testID={`${testID}_checkbox_select`}
							value={isSelected}
							onPress={() => onSelect()}
							style={{ marginBottom: 0, marginTop: 6, marginHorizontal: hsActionButtonMargin }}
						/>
					)}
					{renderDelete && (
						<RoundButton
							isOutline
							testID={`${testID}_button_delete`}
							onPress={() =>
								showAlert({
									title: t('ConfirmDeleteSingle').replace('%TITLE%', `"${item.title}"`),
									message: t('ConfirmDeleteSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('Delete'),
											style: 'destructive',
											onPress: () => _delete()
										}
									]
								})
							}
							isLoading={isLoading}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
						/>
					)}
				</View>

				<View style={{ flexDirection: 'row', justifyContent: 'flex-end', flex: 1, flexWrap: 'wrap' }}>{elements}</View>
			</View>
		);
	};

	const _renderText = () => {
		if (showText && !isEmptyString(item.text)) {
			return <Markdown containerStyle={{ paddingHorizontal: EHorizontalScreenPadding.Wide }} markdown={item.text} />;
		}

		return null;
	};

	const _renderVisibleHintForModerator = () => {
		if (iAmSpaceAdmin || iAmSpaceModerator) {
			const now = moment();
			if (now.isAfter(moment(item.showUntil))) {
				return (
					<Text style={{ color: theme.contrast, padding: 10 }}>
						{t('adminVisibleUntilNotifier')} {moment(item.showUntil).format('DD.MM.YY HH:mm')}
					</Text>
				);
			}
			if (now.isBefore(moment(item.showFrom))) {
				return (
					<Text style={{ color: theme.contrast, padding: 10 }}>
						{t('adminVisibleFromNotifier')} {moment(item.showFrom).format('DD.MM.YY HH:mm')}
					</Text>
				);
			}
			return null;
		}
		return null;
	};

	const _renderCurrentMediaIndex = () => {
		if (item.media && item.media.length > 1) {
			const length = item?.media?.filter((i) => i.media !== undefined && i.media !== null)?.length;

			if (length > 0) {
				if (IS_WEB) {
					return (
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'center',
								marginTop: 0,
								alignSelf: 'center',
								position: 'absolute',
								left: 0,
								right: 0
							}}
						>
							<ChildButton
								style={{ marginRight: 30 }}
								testID={`${testID}_button_left`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex - 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronLeft} />
							</ChildButton>
							<ListBubbles totalCount={length} activeIndex={currentMediaIndex} noTopMargin />
							<ChildButton
								style={{ marginLeft: 30 }}
								testID={`${testID}_button_right`}
								onPress={() => {
									setIsScrollStopped(true);
									flatlistRef.current.scrollToOffset({ offset: (currentMediaIndex + 1) * containerWidth });
								}}
							>
								<Icon name={EDefaultIconSet.ChevronRight} />
							</ChildButton>
						</View>
					);
				}
				return (
					<View style={{ alignSelf: 'center', position: 'absolute', left: 0, right: 0, paddingTop: 1 }}>
						<ListBubbles totalCount={length} activeIndex={currentMediaIndex} size="sm" noTopMargin />
					</View>
				);
			}
		}
		return null;
	};

	const _renderPin = () => {
		if (item.isPinned) {
			return (
				<View style={{ marginLeft: 6 }}>
					<Icon name={EDefaultIconSet.Pin} />
				</View>
			);
		}

		return null;
	};

	const _renderAuthor = () => {
		if (activeSpace?.allowUserGeneratedContentInFeed === 'moderated' || activeSpace?.allowUserGeneratedContentInFeed === 'always') {
			return <UserHeader attendee={attendee} isPreview={isPreview} />;
		}
		return null;
	};

	const _renderReactions = () => {
		if (isInDetail && activeSpace?.allowFeedLikes) {
			return <LikeList itemId={item?.id?.toString()} testId={testID} contentType={'feed'} />;
		}

		return null;
	};

	const _renderComments = () => {
		if (isInDetail) {
			return <CommentList itemId={item?.id?.toString()} testId={testID} contentType={'feed'} />;
		}

		return null;
	};

	const _getReferenceItemTitle = () => {
		let title;
		switch (item.referencetype) {
			case 'expos':
				title = item.expo?.title;
				break;
			case 'externalUrl':
				title = item.externalUrl;
				break;
			case 'features':
				title = item.feature?.label;
				break;
			case 'mappositions':
				title = item.mapposition?.title;
				break;
			case 'maps':
				title = item.map?.title;
				break;
			case 'mediaitems':
				title = item.mediaitem?.title;
				break;
			case 'networkingrooms':
				title = item.networkingroom?.title;
				break;
			case 'schedules':
				title = item.schedule?.title;
				break;
			case 'speakers':
				title = item.speaker?.title;
				break;
			case 'stages':
				title = item.stage?.title;
				break;
			case 'votings':
				title = item.vote?.question;
				break;
			default:
				title = '';
				break;
		}
		if (title) {
			return title;
		}
		return '';
	};

	const _renderImageOrGradient = () => {
		if (item.media && item?.media?.length > 0) {
			return (
				<FlatList
					ref={flatlistRef}
					scrollEnabled={item.media && item?.media?.length > 1}
					data={item.media}
					renderItem={({ item }) => {
						if (!item.media) {
							return null;
						}
						return (
							<NewsMediaItem
								item={item}
								width={containerWidth}
								onPress={isEditMode ? onSelect : !isInDetail ? _handlePress : undefined}
								onPlay={() => setIsScrollStopped(true)}
							/>
						);
					}}
					keyExtractor={(item, index) => `news_${item?.id}_${index}`}
					style={{ flexGrow: 0 }}
					pagingEnabled
					horizontal
					showsHorizontalScrollIndicator={false}
					onMomentumScrollEnd={(e) => setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth)}
					onScroll={(e) => {
						if (IS_WEB) {
							setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth);
						}
					}}
					onTouchMove={() => setIsScrollStopped(true)}
				/>
			);
		}

		if (activeSpace?.showGradientOnMissingImage) {
			return (
				<PlaceholderGradient itemId={item.id} title={item?.title} width={containerWidth} subtitle={item.subtitle} rounded="top" />
			);
		}

		return null;
	};

	if (!item) {
		return null;
	}

	const isNewItem =
		unreadNewsCount > 0 &&
		activeSpace &&
		profile?.lastRead &&
		moment(!isEmptyString(item.showFrom) ? item.showFrom : item.created_at).isSameOrAfter(
			moment(profile?.lastRead[activeSpace?.spaceId]?.newsitems)
		) &&
		// Badge hides if the item is older than 30 minutes
		moment(!isEmptyString(item.showFrom) ? item.showFrom : item.created_at).isAfter(moment().subtract(30, 'minutes'));

	return (
		<View style={[{ flex: 1 }, containerStyle]}>
			<HSCard style={{ flex: 1, padding: 0, position: 'relative' }} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
				<View style={{ flexDirection: 'column' }}>
					{_renderVisibility()}
					{_renderVisibleHintForModerator()}
					{_renderImageOrGradient()}
					{item.media && item.media.length > 1 && (
						<View style={{ alignSelf: 'center', zIndex: 1, marginTop: 10, marginBottom: IS_WEB ? 5 : 0 }}>
							{_renderCurrentMediaIndex()}
						</View>
					)}
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent:
								activeSpace?.allowUserGeneratedContentInFeed === 'moderated' ||
								activeSpace?.allowUserGeneratedContentInFeed === 'always'
									? 'space-between'
									: 'flex-end',
							padding: 10,
							flexWrap: 'wrap'
						}}
					>
						{_renderAuthor()}
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-end',
								flexWrap: 'wrap',
								overflow: 'hidden'
							}}
						>
							{isNewItem && !item.isClickedOn && <TabbarBadge value={t('New').toString()} textBadge />}
							{_renderDate()}
							{_renderPin()}
						</View>
					</View>
					<ChildButton
						activeOpacity={1}
						testID={testID}
						style={{ padding: 10 }}
						isDisabled={isInDetail}
						onPress={isEditMode ? onSelect : !isInDetail ? _handlePress : undefined}
					>
						<View style={{ flexDirection: 'row' }}>
							<View style={{ flex: 1, paddingRight: 10 }}>
								{_renderTitle()}
								{_renderSubtitle()}
							</View>
						</View>
					</ChildButton>
					{_renderText()}
				</View>
				{_renderAction()}
				{_renderReactions()}
			</HSCard>
			<View>{_renderComments()}</View>
		</View>
	);
};
