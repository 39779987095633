import React from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { H1, WelcomeHint } from 'components/Text';
import { hsBottomMargin, hsCard } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { ChildButton } from 'components/Button';
import { ICreateSpaceFormValues } from 'config/interfaces';

interface ISpaceWizardContent {
	testID: string;
	setSelectedContentTypes: (val) => void;
	features?: TPossibleContentType[] | string[];
	stepIndex: number;
	setStepIndex: (val) => void;
	isClone?: boolean;
	onChange?: (val: ICreateSpaceFormValues['cloneData']) => void;
}

export type TPossibleContentType = 'schedules' | 'speakers' | 'expos' | 'feed' | 'networking' | 'votings' | 'mediaitems' | 'maps';

export const SpaceWizardContent = (props: ISpaceWizardContent) => {
	const { testID, stepIndex, setStepIndex, setSelectedContentTypes, features, isClone, onChange } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { width } = Dimensions.get('window');
	const { isTabletOrMobile, screenWidth } = useQuery();

	const types: TPossibleContentType[] = ['schedules', 'speakers', 'expos', 'feed', 'networking', 'votings', 'mediaitems', 'maps'];

	const _renderExampleButton = (type: string, text: string) => {
		return (
			<ChildButton
				key={`${testID}_button_createexamples_${type}`}
				testID={`${testID}_button_createexamples_${type}`}
				onPress={() => {
					if (isClone && onChange) {
						if (type === 'yes') {
							onChange(true);
						}
						if (type === 'no') {
							onChange(false);
						}
					} else {
						if (type === 'yes') {
							let _types = types.filter((t) => features?.includes(t));
							setSelectedContentTypes(_types);
						}
						if (type === 'no') {
							setSelectedContentTypes([]);
						}
					}

					setStepIndex(stepIndex + 1);
				}}
				style={{
					...hsCard,
					shadowColor: theme.hsShadowColor,
					backgroundColor: theme.background,
					width: isTabletOrMobile ? width / 3 : width / 8,
					height: isTabletOrMobile ? width / 3 : width / 8,
					borderRadius: 5,
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<H1 noBottomMargin style={{ color: theme.text }}>
					{t(text)}
				</H1>
			</ChildButton>
		);
	};

	return (
		<ScrollView
			style={{
				width: screenWidth,
				alignSelf: 'center',
				paddingHorizontal: EHorizontalScreenPadding.Wide
			}}
			contentContainerStyle={{
				justifyContent: 'center',
				alignItems: 'center',
				paddingVertical: hsBottomMargin
			}}
		>
			<HSCard
				style={{
					marginBottom: hsBottomMargin
				}}
			>
				<H1>{isClone ? t('cloneContentHeader') : t('selectContentHeader')}</H1>
				<WelcomeHint>{isClone ? t('cloneContentHint') : t('selectContentHint')}</WelcomeHint>
			</HSCard>

			<View
				style={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'space-around'
				}}
			>
				{_renderExampleButton('yes', t('Yes'))}
				{_renderExampleButton('no', t('No'))}
			</View>
		</ScrollView>
	);
};
