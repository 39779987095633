import { IAttendee, IMeeting } from 'config/interfaces';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import { Avatar } from 'components/User';
import { isEmptyString } from 'helper';
import { useTranslation } from 'react-i18next';
import { ChildButton } from 'components/Button';
import { ERoutes } from 'components/Navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useSpace } from 'hooks/useSpace';

interface IMeetingListItem {
	item: IMeeting;
}

export const MeetingListItem = (props: IMeetingListItem) => {
	const { item } = props;
	const { t } = useTranslation();
	const navigation = useNavigation();
	const { activeSpace } = useSpace();

	const [owner, setOwner] = useState<IAttendee | undefined>(undefined);
	const [partner, setPartner] = useState<IAttendee | undefined>(undefined);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);

	useEffect(() => {
		let _userA: typeof owner = undefined;
		let _userB: typeof partner = undefined;

		if (item && attendees) {
			_userA = attendees.find((e) => e.userId === item.ownerId);
			_userB = attendees.find((e) => e.userId === item.partnerId);
		}

		setOwner(_userA);
		setPartner(_userB);
	}, [item, attendees]);

	const _renderUser = (user?: IAttendee) => {
		return (
			<ChildButton
				testID=""
				style={{ flexDirection: 'row', marginBottom: 5 }}
				onPress={() => navigation.navigate(ERoutes.AttendeeDetails, { spaceId: activeSpace?.spaceId, userId: user?.userId })}
				disabled={!user}
			>
				<Avatar
					testID={`meetinglistitem_avatar`}
					size="xs"
					avatar={user?.smallImageUrl ?? user?.imageUrl}
					fullName={user ? `${user.firstName} ${user.lastName}` : t('Unknown User')}
				/>
				<View style={{ flex: 1, paddingLeft: 5 }}>
					<Text bold>{`${user ? `${user.firstName} ${user.lastName}` : t('Unknown User')}`}</Text>
					{!isEmptyString(user?.jobTitle) && <Text>{user?.jobTitle}</Text>}
					{!isEmptyString(user?.company) && <Text>{user?.company}</Text>}
				</View>
			</ChildButton>
		);
	};

	return (
		<View>
			<Text bold style={{ marginBottom: 5 }}>{`${moment(item.timeSlot.start).format('HH:mm')} - ${moment(item.timeSlot.end).format(
				'HH:mm'
			)}`}</Text>
			{_renderUser(owner)}
			{_renderUser(partner)}
		</View>
	);
};
