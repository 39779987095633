import { IVoteOption } from 'config/interfaces';
import { IVoteAnswer } from 'config/interfaces/voteanswer';

export const PreviewPinOnImageOptions: IVoteOption[] = [
	{ description: 'Heart', label: 'SimpleLineIcons:heart', order: 1 },
	{ description: 'Rocket', label: 'SimpleLineIcons:rocket', order: 2 }
];

export const PreviewPinOnImageAnswers: IVoteAnswer[] = [
	{
		created_at: '2021-08-31T12:38:01.000Z',
		id: null,
		info: undefined,
		isDeleted: false,
		media: undefined,
		optionId: null,
		optionIds: null,
		spaceId: 'testdemo',
		status: undefined,
		tempId: '1ca74d4d-71ff-4e68-8803-9230ac962075',
		updated_at: '2021-08-31T12:38:01.000Z',
		userId: '6b4c560c-c89e-4718-bc22-7bf1d4cd7f367',
		value: '{"x":34.06940063091483,"y":57.57575757575758}',
		voteId: 103
	}
];
