import React, { useState } from 'react';
import { View } from 'react-native';
import { useTheme } from 'hooks/useTheme';

import { Text } from 'components/Text';
import { isEmptyString, IS_ANDROID } from 'helper';
import { GRID_SCHEDULE_INNER_HORIZONTAL_PADDING } from '../constants';
import { Image } from 'components/Image';
import { ChildButton } from 'components/Button';
import { useSpace } from 'hooks/useSpace';

interface IVerticalGridHeaderCell {
	testID: string;
	item: any;
	height: number;
	width: number;
	imageWidth: number;
	onPress?: () => void;
}

export const VerticalGridHeaderCell = (props: IVerticalGridHeaderCell) => {
	const { testID, item, height, imageWidth, width, onPress } = props;
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const [imageContainerWidth, setImageContainerWidth] = useState<number>(1);
	const [enableAutoAdjust, setEnableAutoAdjust] = useState<boolean>(false);

	const _renderContent = () => {
		const img = _getImage();
		if (img) {
			return <Image mediaObj={img} imageSize="thumbnail" style={{ width: imageContainerWidth, height: height, borderRadius: 5 }} />;
		}

		return (
			<View style={{ width: width, paddingHorizontal: 10 }}>
				<Text
					bold
					center
					numberOfLines={1}
					adjustsFontSizeToFit={IS_ANDROID ? enableAutoAdjust : true}
					onLayout={(event) => {
						if (IS_ANDROID && event.nativeEvent.layout.width > width - 40) {
							setEnableAutoAdjust(true);
						} else {
							setEnableAutoAdjust(false);
						}
					}}
					style={{
						color: !isEmptyString(item.textColor) ? item.textColor : theme.text,
						fontSize: 18
					}}
				>
					{item.title}
				</Text>
				{!isEmptyString(item.subtitle) && (
					<View
						style={{
							width: '100%',
							height: 1,
							marginVertical: 5,
							backgroundColor: !isEmptyString(item.textColor) ? item.textColor : theme.text
						}}
					/>
				)}
				{!isEmptyString(item.subtitle) && (
					<Text
						numberOfLines={1}
						adjustsFontSizeToFit
						center
						style={{ color: !isEmptyString(item.textColor) ? item.textColor : theme.text, fontSize: 12 }}
					>
						{item.subtitle}
					</Text>
				)}
			</View>
		);
	};

	const _getImage = () => {
		if (item.id === -1) {
			return activeSpace?.agendaMyDayImage;
		}

		return item.image;
	};

	return (
		<ChildButton
			testID={testID}
			style={{
				height,
				width,
				flexDirection: 'row',
				justifyContent: 'center'
			}}
			activeOpacity={1}
			isDisabled={!onPress}
			onPress={onPress}
		>
			<View style={{ width: _getImage() ? imageWidth : width, paddingRight: GRID_SCHEDULE_INNER_HORIZONTAL_PADDING }}>
				<View
					onLayout={(e) => setImageContainerWidth(e.nativeEvent.layout.width)}
					style={{
						height: '100%',
						width: _getImage() ? imageWidth : width,
						backgroundColor: _getImage() ? undefined : item.backgroundColor ?? theme.contentBackgroundColor ?? theme.background,
						borderWidth: _getImage() ? 0 : 1,
						borderColor: theme.lightgray,
						paddingHorizontal: 15,
						justifyContent: 'center',
						alignItems: 'center',
						borderRadius: 5
					}}
				>
					{_renderContent()}
				</View>
			</View>
		</ChildButton>
	);
};
