import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';

import { Dispatch, useRematchDispatch } from 'rematch/store';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { hsTopScreenPadding } from 'config/styleConstants';

import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { HSCard } from 'components/Card';
import {
	EditSpaceInitialFormValues,
	ICategoryMatching,
	IContentTypeField,
	IEditSpaceFormValues,
	ISpaceAttendeeSettings
} from 'config/interfaces';

import { EDefaultIconSet, openURL } from 'helper';
import { RoundButton } from 'components/Button';
import { v4 } from 'uuid';
import { SectionHeader, Text } from 'components/Text';
import { Dropdown } from 'components/Form/Dropdown';
import { IKVP } from 'components/Form';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import { FormError } from 'components/Form/FormError';
import { FormLabel } from 'components/Form/FormLabel';
import { EForumLinks } from 'config/constants';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Matchings>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Matchings>;
type RouteParams = StackParamList[ERoutes.Matchings];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'matchings';

export const MatchingsScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { getRegistrationFields } = useRegistration();
	const { getContentTypeFields: getExpoFields } = useContent('expo');
	const { getContentTypeFields: getSpeakerFields } = useContent('speaker');
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');
	const { theme } = useTheme();

	const [formValues, setFormValues] = useState<IEditSpaceFormValues>({ ...EditSpaceInitialFormValues });
	const [formErrors, setFormErrors] = useState<Record<string, string>>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const [attendeeCategories, setAttendeeCategories] = useState<IContentTypeField[]>([]);
	const [expoCategories, setExpoCategories] = useState<IContentTypeField[]>([]);
	const [speakerCategories, setSpeakerCategories] = useState<IContentTypeField[]>([]);
	const [mediaItemCategories, setMediaItemCategories] = useState<IContentTypeField[]>([]);

	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (formValues.updated_at && activeSpace?.updated_at !== formValues.updated_at && !isLoading) {
			showAlert({
				title: t('Space has changed'),
				message: t('Space has changed subtitle'),
				buttons: [
					{
						text: t('Save my changes'),
						onPress: () => setIsFormLocked(true)
					},
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateFormValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Leave form'),
						style: 'destructive',
						onPress: () => {
							if (navigation.canGoBack()) {
								navigation.goBack();
							} else {
								RootNavigation.replace('tab');
							}
						}
					}
				]
			});
		} else {
			_updateFormValues();
		}
	}, [activeSpace]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateSpace(),
			isLoading: isLoading,
			isDisabled: Object.keys(formErrors).length > 0
		});
	}, [formValues, isLoading, formErrors, isFormLocked]);

	const _updateFormValues = () => {
		if (activeSpace) {
			const attendeeSettings: ISpaceAttendeeSettings = activeSpace.attendeeSettings
				? { ...activeSpace.attendeeSettings }
				: { matching: [] };

			if (!attendeeSettings.matching) {
				attendeeSettings.matching = [];
			}

			setFormValues({
				updated_at: activeSpace.updated_at,
				spaceId: activeSpace.spaceId,
				attendeeSettings
			});
			setAttendeeCategories(getRegistrationFields().filter((e) => e.fieldType === 'category' && e.showOnDetailScreen));
			setExpoCategories(getExpoFields().filter((e) => e.fieldType === 'category' && e.showOnDetailScreen));
			setSpeakerCategories(getSpeakerFields().filter((e) => e.fieldType === 'category' && e.showOnDetailScreen));
			setMediaItemCategories(getMediaItemFields().filter((e) => e.fieldType === 'category' && e.showOnDetailScreen));
		} else {
			setAttendeeCategories([]);
			setExpoCategories([]);
			setSpeakerCategories([]);
			setMediaItemCategories([]);
		}
	};

	const _updateValues = (values) => {
		if (!route.params.prohibitNavigation) {
			navigation.setParams({
				prohibitNavigation: true
			});
		}
		setFormValues(values);
	};

	const _updateSpace = async () => {
		if (isFormLocked) {
			showAlert({
				title: t('Space has changed'),
				message: t('SpaceFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							_updateFormValues();
							setIsFormLocked(false);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		setIsLoading(true);

		const hasErrors = _checkForErrors(formValues.attendeeSettings?.matching ?? [], true);

		if (!hasErrors) {
			const res = await updateSpace({ data: formValues });
			if (res) {
				navigation.goBack();
			}
			setIsLoading(false);
		}
	};

	const _checkForErrors = (matchings: ICategoryMatching[], save?: boolean) => {
		let _errors = {};

		matchings.forEach((match, index) => {
			if (match.type1 && match.type2 && match.fieldName1 && match.fieldName2) {
				const error = matchings.find(
					(e) =>
						e.key !== match.key &&
						((match.type1 === e.type1 &&
							match.fieldName1 === e.fieldName1 &&
							match.type2 === e.type2 &&
							match.fieldName2 === e.fieldName2) ||
							(match.type1 === e.type2 &&
								match.fieldName1 === e.fieldName2 &&
								match.type2 === e.type1 &&
								match.fieldName2 === e.fieldName1))
				);
				if (error) {
					const idx = matchings.findIndex((e) => e.key === error.key);

					_errors[match.key] = t('SameMatch').replace('%LINE%', `${idx + 1}`);
					_errors[error.key] = t('SameMatch').replace('%LINE%', `${index + 1}`);
				}
			} else if (save) {
				_errors[match.key] = t('PleaseSelectCategories');
			}
		});

		setFormErrors(_errors);

		return Object.keys(_errors).length;
	};

	const _getOptions = () => {
		const arr: IKVP[] = [];

		attendeeCategories.forEach((field) => {
			arr.push({
				key: `attendee.${field.fieldName}`,
				label: `${t('Attendee')}: ${field.fieldLabel ?? field.fieldName}`
			});
		});

		expoCategories.forEach((field) => {
			arr.push({
				key: `expo.${field.fieldName}`,
				label: `${t('Expo')}: ${field.fieldLabel ?? field.fieldName}`
			});
		});

		speakerCategories.forEach((field) => {
			arr.push({
				key: `speaker.${field.fieldName}`,
				label: `${t('Speaker')}: ${field.fieldLabel ?? field.fieldName}`
			});
		});

		mediaItemCategories.forEach((field) => {
			arr.push({
				key: `mediaitem.${field.fieldName}`,
				label: `${t('mediaitems')}: ${field.fieldLabel ?? field.fieldName}`
			});
		});

		return arr;
	};

	const _renderElements = () => {
		if (formValues.attendeeSettings?.matching) {
			return formValues.attendeeSettings.matching.map((e, idx) => {
				return (
					<View key={e.key} style={{ marginBottom: FormElementBottomMarginSmall }}>
						<FormLabel testID={`${e.key}_label`} label={`Match ${idx + 1}`} style={{ marginBottom: 2 }} />
						<View key={e.key} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
							<View style={{ maxWidth: '45%' }}>
								<Dropdown
									testID={`${TESTIDPREFIX}_dropdown_${idx}_source`}
									value={e.type1 && e.fieldName1 ? `${e.type1}.${e.fieldName1}` : undefined}
									onSelect={(val) => {
										if (val && typeof val === 'string') {
											const splitted = val.split('.');
											const value = formValues.attendeeSettings?.matching
												? [...formValues.attendeeSettings?.matching]
												: [];
											const idx = value.findIndex((e2) => e2.key === e.key);
											if (idx !== -1) {
												value[idx].type1 = splitted[0];
												value[idx].fieldName1 = splitted[1];
											}
											const attendeeSettings: ISpaceAttendeeSettings = formValues.attendeeSettings
												? { ...formValues.attendeeSettings, matching: value }
												: { matching: value };

											_checkForErrors(value);
											_updateValues({ ...formValues, attendeeSettings });
										}
									}}
									options={_getOptions()}
									formStyle={{ marginBottom: 0 }}
								/>
							</View>
							<View
								style={{
									position: 'absolute',
									left: '46%',
									right: '46%',
									alignSelf: 'flex-start',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<Text center style={{ alignSelf: 'center', textAlignVertical: 'center', marginTop: 5 }}>{`<->`}</Text>
							</View>
							<View style={{ maxWidth: '45%' }}>
								<Dropdown
									testID={`${TESTIDPREFIX}_dropdown_${idx}_dest`}
									value={e.type2 && e.fieldName2 ? `${e.type2}.${e.fieldName2}` : undefined}
									onSelect={(val) => {
										if (val && typeof val === 'string') {
											const splitted = val.split('.');
											const value = formValues.attendeeSettings?.matching
												? [...formValues.attendeeSettings?.matching]
												: [];
											const idx = value.findIndex((e2) => e2.key === e.key);
											if (idx !== -1) {
												value[idx].type2 = splitted[0];
												value[idx].fieldName2 = splitted[1];
											}
											const attendeeSettings: ISpaceAttendeeSettings = formValues.attendeeSettings
												? { ...formValues.attendeeSettings, matching: value }
												: { matching: value };

											_checkForErrors(value);
											_updateValues({ ...formValues, attendeeSettings });
										}
									}}
									isDisabled={!e.type1 || !e.fieldName1}
									options={_getOptions()}
									formStyle={{ marginBottom: 0 }}
								/>
							</View>
						</View>
						<FormError testID={`${e.key}_error`} error={formErrors[e.key]} />
						<RoundButton
							testID={`${e.key}_button_delete`}
							icon={EDefaultIconSet.Delete}
							alignSelf="flex-end"
							size="sm"
							color={theme.danger}
							onPress={() => {
								showAlert({
									title: t('DeleteMatch'),
									message: t('DeleteMatchSubtitle'),
									buttons: [
										{
											text: t('Cancel'),
											style: 'cancel'
										},
										{
											text: t('DeleteMatch'),
											style: 'destructive',
											onPress: () => {
												const attendeeSettings: ISpaceAttendeeSettings = formValues.attendeeSettings
													? { ...formValues.attendeeSettings }
													: { matching: [] };
												const value = attendeeSettings.matching?.filter((e2) => e2.key !== e.key) ?? [];
												attendeeSettings.matching = value;
												_checkForErrors(value);
												_updateValues({ ...formValues, attendeeSettings });
											}
										}
									]
								});
							}}
						/>
					</View>
				);
			});
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					<SectionHeader label={t('AddMatchingPairs')} />
					<Text>
						{t('AddMatchingPairIntro')}
						<Text onPress={() => openURL(EForumLinks.Matchings)} style={{ textDecorationLine: 'underline' }}>
							{t('here')}
						</Text>
						{'.'}
					</Text>
					<Text style={{ marginBottom: 6 }}>{`${t('AddMatchingsPairDetailsHint')}`}</Text>
					{_renderElements()}
					<RoundButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						alignSelf="flex-end"
						onPress={() => {
							const _val = { ...formValues };
							if (!_val.attendeeSettings) {
								_val.attendeeSettings = { matching: [] };
							}
							_val.attendeeSettings.matching?.push({
								key: v4()
							});
							setFormValues(_val);
						}}
					/>
				</HSCard>
			</ScrollView>
		</ScreenContainer>
	);
};

export const MatchingsScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title="Matchings" />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
				isDisabled={props.options?.isDisabled}
			/>
		</NavigationHeader>
	);
};
