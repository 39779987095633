import { IFormBase } from 'config/interfaces/form';
import React from 'react';
import Animated, { Easing, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { useTheme } from 'hooks/useTheme';
import { Icon } from 'components/Icon';
import { FormField } from './FormField';
import { EDefaultIconSet } from 'helper';
import { TouchableOpacity, View, ViewStyle } from 'react-native';
import { FormLabel } from './FormLabel';

interface IFormSwitchComponent extends IFormBase {
	testID: string;
	value?: boolean;
	onChange: (val: boolean) => void;
	icon?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	leftLabel?: string;
	rightLabel?: string;
	formStyle?: ViewStyle;
}

export const FormSwitch = (props: IFormSwitchComponent) => {
	const { testID, label, isRequired, hint, error, value, isDisabled, onChange, size, icon, leftLabel, rightLabel, formStyle } = props;
	const { theme } = useTheme();

	let SIZE = 30;
	const PADDING = 5;

	switch (size) {
		case 'xs':
			SIZE = 20;
			break;
		case 'sm':
			SIZE = 25;
			break;
		case 'lg':
			SIZE = 35;
			break;
		case 'xl':
			SIZE = 40;
			break;
		case 'md':
		default:
			break;
	}

	const optionsStyle = useAnimatedStyle(() => {
		const toValue = value ? theme.primary : theme.formgray;
		const backgroundColor = withTiming(toValue, {
			duration: 50,
			easing: Easing.linear
		});

		return {
			backgroundColor
		};
	});
	const switchStyle = useAnimatedStyle(() => {
		const toValue = value ? SIZE : 0;
		const translateX = withTiming(toValue, {
			duration: 150,
			easing: Easing.linear
		});

		return {
			transform: [{ translateX }]
		};
	});

	const _toggleValue = React.useCallback(() => {
		if (!isDisabled) {
			onChange(!value);
		}
	}, [value, onChange, isDisabled]);

	return (
		<FormField testID={testID} label={label} isRequired={isRequired} hint={hint} error={error} formStyle={formStyle}>
			<View style={{ flexDirection: 'row', alignItems: 'center' }}>
				{leftLabel && (
					<FormLabel style={{ marginRight: 10 }} testID={`${testID}_label`} label={leftLabel} isRequired={isRequired} />
				)}
				<TouchableOpacity testID={`${testID}_switch`} activeOpacity={1} onPress={_toggleValue}>
					<Animated.View style={[{ width: 2 * SIZE, borderRadius: SIZE / 2 }, optionsStyle]}>
						<Animated.View
							style={[
								{
									height: SIZE,
									width: SIZE,
									justifyContent: 'center',
									alignItems: 'center'
								},
								switchStyle
							]}
						>
							<Icon name={icon ?? EDefaultIconSet.Success} size={SIZE - PADDING} color={theme.primaryContrast} />
						</Animated.View>
					</Animated.View>
				</TouchableOpacity>
				{rightLabel && (
					<FormLabel style={{ marginLeft: 10 }} testID={`${testID}_label`} label={rightLabel} isRequired={isRequired} />
				)}
			</View>
		</FormField>
	);
};
