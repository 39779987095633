import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

import { ILikeObject } from 'config/interfaces';

export const useLike = () => {
	const likes = useSelector((store: IRootState) => store.like.likes);

	const getLikesForItem = (itemId: string, contentType: string) => {
		let likedBy: ILikeObject[] = [];
		let userIds: string[] = [];

		if (likes) {
			const found = likes.find((e) => e.itemId === itemId && contentType === contentType);
			if (found) {
				const likeStrings = [...found.likes].filter((e) => e !== null && e !== undefined);
				if (Array.isArray(likeStrings[0])) {
					userIds = likeStrings[0];
				} else {
					userIds = likeStrings;
				}
			}
		}
		likedBy = userIds.map((userId) => {
			try {
				// Kann ein Stringified String sein:
				// {
				// 	id:''
				// 	type:''
				// }

				const parsed = JSON.parse(userId);
				return parsed;
			} catch (error) {
				return {
					id: userId,
					type: 'like'
				};
			}
		});

		return likedBy;
	};
	return {
		getLikesForItem
	};
};
