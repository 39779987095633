import i18next from 'i18next';

export const getLabelForContentType = (type: string) => {
	switch (type) {
		case 'schedules':
			return i18next.t('Schedules');
		case 'speakers':
			return i18next.t('Speakers');
		case 'votes':
			return i18next.t('votings');
		case 'pushnotifications':
			return i18next.t('Push Notifications');
		case 'expos':
			return i18next.t('expos');
		case 'mappositions':
			return i18next.t('Map Positions');
		case 'maps':
			return i18next.t('Maps');
		case 'mediaitems':
			return i18next.t('mediaitems');
		case 'meetingtables':
			return i18next.t('Meeting Tables');
		case 'networkingrooms':
			return i18next.t('networking');
		case 'newsitems':
			return i18next.t('feed');
		case 'stages':
			return i18next.t('stages');
		case 'streams':
			return i18next.t('Stream');
		case 'space':
			return 'Space';
		default:
			return type;
	}
};
