import React, { ReactNode, useEffect, useState } from 'react';
import { RouteProp, useFocusEffect } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import Papa from 'papaparse';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import * as DocumentPicker from 'expo-document-picker';

import { ScrollView, View } from 'react-native';

import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';
import { IContentTypeField, IOption } from 'config/interfaces';
import { EDefaultIconSet, isEmptyString, validateForm } from 'helper';
import { LoadingModal } from 'components/Modal/LoadingModal';
import { Button, ChildButton, RoundButton } from 'components/Button';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';
import { Text } from 'components/Text';
import { v4 } from 'uuid';
import moment from 'moment';
import { HSCard } from 'components/Card';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { showToast } from 'helper/toast';
import { getStreamFormSchema } from 'config/yupSchemas';
import { Spinner } from 'components/Spinner';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ImportData>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ImportData>;
type RouteParams = StackParamList[ERoutes.ImportData];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'importdata';

export const ImportDataScreen = ({ route, navigation }: Props) => {
	const { screenWidth } = useQuery();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { getContentTypeFields: getExpoFields } = useContent('expo');
	const { getContentTypeFields: getFeedFields } = useContent('feed');
	const { getContentTypeFields: getNetworkingRoomFields } = useContent('networkingroom');
	const { getContentTypeFields: getScheduleFields } = useContent('schedule');
	const { getContentTypeFields: getSpeakerFields } = useContent('speaker');
	const { getContentTypeFields: getStageFields } = useContent('stage');
	const { getContentTypeFields: getVotingFields } = useContent('voting');
	const { getContentTypeFields: getStreamFields } = useContent('stream');
	const { getContentTypeFields: getMediaItemFields } = useContent('mediaitem');
	const { getContentTypeFields: getTicketFields } = useContent('ticket');
	const { getContentTypeFields: getMeetingTableFields } = useContent('meetingtable');

	const { theme } = useTheme();

	const { getRegistrationFields } = useRegistration();

	const { getFormSchema, renderFormField } = useForm();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [fields, setFields] = useState<IContentTypeField[]>([]);
	const [isParseLoading, setIsParseLoading] = useState<boolean>(false);

	const [parsedData, setParsedData] = useState<any[]>([]);
	const [parsedDataWithErrors, setParsedDataWithErrors] = useState<any[]>([]);
	const [showData, setShowData] = useState<'data' | 'error'>('data');
	const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
	const [hasReferenceError, setHasReferenceError] = useState<boolean>(false);

	const checkImportItems = useRematchDispatch((dispatch: Dispatch) => dispatch.content.checkImportItems);
	const importData = useRematchDispatch((dispatch: Dispatch) => dispatch.content.importData);

	useFocusEffect(
		React.useCallback(() => {
			return () => {
				setParsedData([]);
				setParsedDataWithErrors([]);
				setFields([]);
				setCurrentItemIndex(0);
			};
		}, [])
	);

	useEffect(() => {
		navigation.setOptions({
			isRightNavPressDisabled: true
		});

		let _fields: IContentTypeField[] = [];

		switch (route.params.type) {
			case 'attendee':
				_fields = getRegistrationFields(true);
				break;
			case 'expo':
				_fields = getExpoFields(true);
				break;
			case 'newsitem':
				_fields = getFeedFields(true);
				break;
			case 'networkingroom':
				_fields = getNetworkingRoomFields(true);
				break;
			case 'schedule':
				_fields = getScheduleFields(true);
				break;
			case 'speaker':
				_fields = getSpeakerFields(true);
				break;
			case 'stage':
				_fields = getStageFields(true);
				break;
			case 'vote':
				_fields = getVotingFields(true);
				break;
			case 'stream':
				_fields = getStreamFields(true);
				break;
			case 'mediaitem':
				_fields = getMediaItemFields(true);
				break;
			case 'ticket':
				_fields = getTicketFields(true);
				break;
			case 'meetingtable':
				_fields = getMeetingTableFields(true);
				break;
			default:
				break;
		}

		setFields(_fields);
	}, [activeSpace, route.params.type]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _importData(),
			isRightNavPressDisabled: parsedData.length === 0 || parsedDataWithErrors.length > 0,
			isLoading
		});
	}, [parsedData, parsedDataWithErrors, isLoading]);

	const _importData = async () => {
		if (parsedData.length > 0) {
			setIsLoading(true);
			const res = await importData({ items: parsedData, type: route.params.type });
			setIsLoading(false);

			const _receivedItems = [...res.createdItems, ...res.updatedItems];

			let itemsWithErrors = _receivedItems.filter((e) => e.importStatus);
			showToast(
				'info',
				t('ImportDone'),
				itemsWithErrors.length > 0
					? t('ImportWithErrors')
							.replace('%ERRORCOUNT%', itemsWithErrors.length.toString())
							.replace('%SUCCESSCOUNT%', (parsedData.length - itemsWithErrors.length).toString())
					: t('ImportWithoutErrors').replace('%SUCCESSCOUNT%', parsedData.length.toString())
			);

			if (itemsWithErrors.length === 0) {
				navigation.goBack();
				return;
			}

			_receivedItems.forEach((item) => {
				switch (route.params.type) {
					case 'expo':
						if (item.speakers) {
							item.speakers = item.speakers.map((sp) => (typeof sp === 'string' ? sp : sp.id));
						}

						if (item.schedules) {
							item.schedules = item.schedules.map((sc) => (typeof sc === 'string' ? sc : sc.id));
						}
						break;
					case 'schedule':
						if (item.stage) {
							item.stage = typeof item.stage === 'string' ? item.stage : item.stage.id;
						}

						if (item.speakers) {
							item.speakers = item.speakers.map((sp) => (typeof sp === 'string' ? sp : sp.id));
						}

						if (item.votes) {
							item.votes = item.votes.map((vo) => (typeof vo === 'string' ? vo : vo.id));
						}

						if (item.stream) {
							item.stream = typeof item.stream === 'string' ? item.stream : item.stream.id;
						}

						if (item.expos) {
							item.expos = typeof item.expos === 'string' ? item.expos : item.expos.id;
						}

						break;
					case 'speaker':
						if (item.schedules) {
							item.schedules = item.schedules.map((sc) => (typeof sc === 'string' ? sc : sc.id));
						}

						if (item.expos) {
							item.expos = item.expos.map((ex) => (typeof ex === 'string' ? ex : ex.id));
						}
						break;
					case 'stage':
						if (item.schedules) {
							item.schedules = item.schedules.map((sc) => (typeof sc === 'string' ? sc : sc.id));
						}
						break;
					case 'stream':
						if (item.schedules) {
							item.schedules = item.schedules.map((sc) => (typeof sc === 'string' ? sc : sc.id));
						}
						break;
					default:
						break;
				}
			});

			setParsedData(_receivedItems.filter((e) => !e.importStatus));
			setParsedDataWithErrors(_receivedItems.filter((e) => !e.importStatus));
		} else {
			showToast('error', t('ImportAllError'), t('ImportAllErrorSubtitle'));
		}
	};

	const _getLabelForField = (fieldName: string) => {
		const field = fields.find((e) => e.fieldName === fieldName);

		return field?.fieldLabel ?? fieldName;
	};

	const _pickFile = async () => {
		const selectedFile = await DocumentPicker.getDocumentAsync({
			copyToCacheDirectory: true,
			multiple: false,
			type: ['text/csv']
		});

		if (selectedFile.type === 'success') {
			setHasReferenceError(false);
			Papa.parse(selectedFile.file, {
				skipEmptyLines: true,
				header: true,
				delimiter: '',

				error: function (error) {
					console.log('error', error);
				},

				complete: async (result) => {
					if (result) {
						const _mapping = {};
						Object.keys(_mapping).forEach((key) => {
							if (!result.meta.fields.includes(_mapping[key])) {
								delete _mapping[key];
							}
						});
						fields.forEach((field) => {
							if (result.meta.fields.includes(field.fieldName) && !_mapping[field.fieldName]) {
								_mapping[field.fieldName] = field.fieldName;
							}
						});
						if (!_mapping['id']) {
							showToast('error', t('ImportErrorMissingID'), t('ImportErrorMissingIDSubtitle'));
							return;
						}

						_parseData(result.data ?? [], _mapping);
					}
				}
			});
		}
	};

	const _checkDate = (date: string) => {
		if (moment(date, 'DD.MM.YYYY HH:mm').isValid()) {
			return moment(date, 'DD.MM.YYYY HH:mm').toISOString();
		} else if (moment(date)) {
			return moment(date).toISOString();
		}

		return undefined;
	};

	const _parseData = async (rawData, mapping) => {
		setIsParseLoading(true);
		setParsedData([]);
		setParsedDataWithErrors([]);

		if (Object.keys(mapping).length === 0) {
			setIsParseLoading(false);
			return;
		}

		let _parsed: any[] = [];

		for (const item of rawData) {
			const obj = {
				key: v4(),
				isDeleted: false
			};

			if (
				route.params.type === 'vote' &&
				(item.votingType === 'applause' || item.votingType === 'externalUrl' || item.votingType === 'text')
			) {
				if (mapping['hideResults']) delete mapping['hideResults'];
			}

			Object.keys(mapping).forEach((key) => {
				const field = fields.find((e) => e.fieldName === key);
				const destKey = mapping[key];

				if (key === 'id') {
					if (item.hasOwnProperty(destKey)) {
						obj['id'] = item[destKey] && Number(item[destKey]) ? Number(item[destKey]) : '_new';
					} else {
						obj['id'] = item?.id && Number(item?.id) ? Number(item.id) : '_new';
					}
				} else {
					if (field) {
						if (field.fieldType === 'dateRange') {
							if (item.hasOwnProperty(field.fieldName)) {
								obj[field.fieldName] = _checkDate(item[field.fieldName]);
							}
							if (field.fieldName2 && item.hasOwnProperty(field.fieldName2)) {
								obj[field.fieldName2] = _checkDate(item[field.fieldName2]);
							}
						} else {
							if (item.hasOwnProperty(destKey)) {
								const fieldValue = item[destKey];

								switch (field.fieldType) {
									case 'boolean':
										obj[field.fieldName] =
											fieldValue.toLowerCase() === 'ja' ||
											fieldValue.toLowerCase() === 'yes' ||
											fieldValue.toLowerCase() === 'wahr' ||
											fieldValue.toLowerCase() === 'true' ||
											fieldValue === '1' ||
											fieldValue === 1 ||
											fieldValue === true;
										break;
									case 'component':
										if (fieldValue) {
											obj[field.fieldName] = JSON.parse(fieldValue);
										}
										break;
									case 'multiswitch':
										if (fieldValue) {
											const foundOption = field.options?.find((e) => e.key === fieldValue);
											if (foundOption) {
												obj[field.fieldName] = fieldValue;
											}
										}
										break;
									case 'reference':
									case 'stream':
										obj[field.fieldName] = [];
										if (!isEmptyString(fieldValue)) {
											obj[field.fieldName] = fieldValue
												.split(',')
												.filter((e) => Number(e.trim()))
												.map((e) => Number(e.trim()));
										}
										break;
									case 'singlereference':
										obj[field.fieldName] = fieldValue && Number(fieldValue) ? Number(fieldValue) : null;
										break;
									case 'number':
										obj[field.fieldName] = fieldValue && !isEmptyString(fieldValue) ? Number(fieldValue) : null;
										break;
									case 'email':
										obj[field.fieldName] = fieldValue ? fieldValue.trim().toLowerCase() : null;
										break;
									case 'password':
									case 'color':
									case 'category':
									case 'markdown':
									case 'logo':
									case 'image':
									case 'avatar':
									case 'string':
									case 'title':
									case 'subtitle':
									case 'contactEmail':
									case 'location':
									case 'socialmedia':
										if (field.fieldName === 'publicId') {
											if (isEmptyString(fieldValue)) {
												obj[field.fieldName] = v4();
											} else {
												obj[field.fieldName] = fieldValue.trim();
											}
										} else {
											if (field.fieldType === 'category') {
												obj[field.fieldName] = fieldValue
													?.split(',')
													.map((e) => e.trim())
													.join(',');
											} else {
												obj[field.fieldName] = Number(fieldValue) ? Number(fieldValue) : fieldValue.trim();
											}
										}
										break;
									case 'website':
										obj[field.fieldName] = fieldValue ? fieldValue.trim() : null;
										break;
									default:
										break;
								}
							} else {
								switch (field.fieldType) {
									case 'boolean':
										delete obj[field.fieldName];
										break;
									default:
										break;
								}
							}
						}
					}
				}
			});

			fields.forEach((field) => {
				switch (field.fieldType) {
					case 'color':
					case 'dateRange':
					case 'email':
					case 'markdown':
					case 'phoneNumber':
					case 'string':
					case 'website':
						if (obj[field.fieldName] && typeof obj[field.fieldName] === 'string') {
							obj[field.fieldName] = obj[field.fieldName].trim();
						}
						break;
				}
			});

			delete obj['selfServiceToken'];
			delete obj['speakerViewToken'];

			let formSchema = getFormSchema(fields, obj);

			switch (route.params.type) {
				case 'attendee':
					if (obj['id'] !== '_new') {
						formSchema = getFormSchema(
							fields.filter((e) => e.fieldType !== 'password'),
							obj
						);
						fields
							.filter((e) => e.fieldType === 'password')
							.forEach((field) => {
								delete obj[field.fieldName];
							});
					}
					break;
				case 'stream':
					formSchema = getStreamFormSchema(obj);
					break;
				case 'ticket':
					if (obj['ticketcode'] && !isEmptyString(obj['ticketcode'])) {
						obj['ticketcode'] = obj['ticketcode'].toLowerCase().trim();
					}
					break;
				default:
					break;
			}

			const validationResult = await validateForm(formSchema, obj);

			if (validationResult) {
				delete validationResult['id'];

				_parsed.unshift({
					...obj,
					importStatus: validationResult
				});
			} else {
				_parsed.push(obj);
			}
		}

		if (_parsed.length < currentItemIndex) {
			setCurrentItemIndex(0);
		}

		const checkedItems = await checkImportItems({
			items: _parsed.filter((e) => !e.importStatus || Object.keys(e.importStatus).length === 0),
			type: route.params.type
		});

		checkedItems.forEach((item) => {
			const idx = _parsed.findIndex((e) => e.key === item.key);
			_parsed[idx] = item;
		});

		const itemsWithoutError = _parsed.filter((e) => !e.importStatus);

		setParsedData(itemsWithoutError);

		const itemsWithErrors = _parsed.filter((e) => e.importStatus);

		if (itemsWithErrors.length > 0) {
			showToast('error', t('ImportErrors'), t('ImportErrorsSubtitle'));
		}
		if (itemsWithoutError.length === 0) {
			setShowData('error');
		}
		setParsedDataWithErrors(itemsWithErrors);

		setIsParseLoading(false);
	};

	const _renderFilePicker = () => {
		return (
			<Button
				testID={`${TESTIDPREFIX}_button_filepicker`}
				isLoading={isParseLoading}
				onPress={_pickFile}
				title={t('SelectFile')}
				style={{ marginBottom: 20, alignSelf: 'center' }}
			/>
		);
	};

	const _renderFilePickerHint = () => {
		return (
			<Text style={{ marginBottom: 10, fontSize: 16 }}>
				<ul>
					<li>{t('ImportFileHasToBeCSV')}</li>
					<li>{t('ImportFileCoding')}</li>
					<li>{t('ImportFileMustHaveID')}</li>
					<li>{t('ImportCreatesNewID')}</li>
					<li>{t('ImportUpdatesEntry')}</li>
				</ul>
			</Text>
		);
	};

	const _renderSwitchItem = () => {
		if (isParseLoading) {
			return <Spinner />;
		}

		const totalCount = parsedData.length + parsedDataWithErrors.length;
		let itemCount = showData === 'data' ? parsedData.length : parsedDataWithErrors.length;
		let _data = showData === 'data' ? [...parsedData] : [...parsedDataWithErrors];
		let _hasError = parsedDataWithErrors.length > parsedData.length;

		if (totalCount > 0) {
			return (
				<View>
					{_hasError && (
						<Text style={{ marginBottom: 16, fontSize: 18, color: theme.danger }}>{t('NoImportPossibleWithErrorsHint')}</Text>
					)}
					{parsedDataWithErrors.length > 0 && (
						<View style={{ flexDirection: 'row', marginBottom: 10, justifyContent: 'center' }}>
							<Button
								testID={`${TESTIDPREFIX}_button_data`}
								title={t('ImportItemsWithoutError')}
								style={{ marginRight: 10 }}
								isOutline={showData === 'error'}
								onPress={() => {
									setCurrentItemIndex(0);
									setShowData('data');
								}}
							/>
							<Button
								testID={`${TESTIDPREFIX}_button_errors`}
								title={t('ImportItemsWithError')}
								isOutline={showData === 'data'}
								onPress={() => {
									setCurrentItemIndex(0);
									setShowData('error');
								}}
							/>
						</View>
					)}
					{parsedDataWithErrors.length > 0 && (
						<Button
							testID={`${TESTIDPREFIX}_button_errors`}
							title={t('DontImportAllEntries')}
							onPress={() => {
								setParsedDataWithErrors([]);
								setShowData('data');
								setCurrentItemIndex(0);
							}}
							style={{ marginVertical: 20, alignSelf: 'center' }}
						/>
					)}
					{_data.length > 1 ? (
						<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
							<RoundButton
								testID={`${TESTIDPREFIX}_button_back`}
								icon={EDefaultIconSet.ArrowLeft}
								isOutline
								onPress={() => {
									let newIndex = currentItemIndex - 1;
									if (!_data[newIndex]) {
										newIndex = _data.length - 1;
									}

									setCurrentItemIndex(newIndex);
								}}
							/>
							{_data[currentItemIndex] && (
								<Text>{`${
									_data[currentItemIndex].title ?? _data[currentItemIndex].question ?? _data[currentItemIndex].id
										? 'ID: ' + _data[currentItemIndex].id
										: ''
								} (${currentItemIndex + 1}/${_data.length})`}</Text>
							)}
							<RoundButton
								testID={`${TESTIDPREFIX}_button_forward`}
								icon={EDefaultIconSet.ArrowRight}
								isOutline
								onPress={() => {
									let newIndex = currentItemIndex + 1;
									if (!_data[newIndex]) {
										newIndex = 0;
									}

									setCurrentItemIndex(newIndex);
								}}
							/>
						</View>
					) : (
						<View style={{ alignItems: 'center', marginBottom: 10 }}>
							{_data[currentItemIndex] && (
								<Text>{`${
									_data[currentItemIndex].title ?? _data[currentItemIndex].question ?? _data[currentItemIndex].id
										? 'ID: ' + _data[currentItemIndex].id
										: ''
								}`}</Text>
							)}
						</View>
					)}
				</View>
			);
		}

		return null;
	};

	const _quickFix = (item: any, fieldName: string, error: string, dontImport: boolean, fixAll?: boolean) => {
		let _parsed = [...parsedData];
		let _withError = [...parsedDataWithErrors];

		if (dontImport) {
			if (fixAll) {
				const itemsWithThisErrorKeys = _withError.filter((e) => e.importStatus[fieldName] === error).map((e) => e.key);
				_withError = _withError.filter((e) => !itemsWithThisErrorKeys.includes(e.key));
			} else {
				_withError = _withError.filter((e) => e.key !== item.key);
			}
		} else {
			const _item = { ...item };

			let itemsToFix = [_item];
			if (fixAll) {
				itemsToFix = _withError.filter((e) => e.importStatus[fieldName] === error);
			}

			switch (error) {
				case 'I18nItemOutOfSpace':
				case 'I18nNoEntryWithThisId':
					itemsToFix.forEach((e) => {
						e.id = '_new';
						delete e.importStatus[fieldName];
					});
					break;
				case 'I18nReferencesOutOfSpace':
					itemsToFix.forEach((e) => {
						e[fieldName] = undefined;
						delete e.importStatus[fieldName];
					});
					break;
				case t('Invalid URL Subtitle'):
					itemsToFix.forEach((e) => {
						e[fieldName] = ('https://' + e[fieldName]).replace(/\s+/g, '');
						delete e.importStatus[fieldName];
					});
				default:
					break;
			}

			itemsToFix.forEach((e) => {
				if (Object.keys(e.importStatus).length === 0) {
					delete e.importStatus;
					_withError = _withError.filter((e2) => e2.key !== e.key);
					_parsed.push(e);
				} else {
					const idx = _withError.findIndex((e2) => e2.key === e.key);
					_withError[idx] = e;
				}
			});
		}

		if (_withError.length === 0) {
			setShowData('data');
			setCurrentItemIndex(0);
		} else if (!_withError[currentItemIndex]) {
			setCurrentItemIndex(0);
		}

		setParsedData(_parsed);
		setParsedDataWithErrors(_withError);
	};

	const _renderErrorEntry = (
		fieldLabel: string,
		fieldName: string,
		error: string,
		item: any,
		cta?: string,
		hasMore?: boolean,
		hasMoreCta?: string
	) => {
		return (
			<View key={`importerror_${fieldName}`} style={{ marginBottom: 10 }}>
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
					<View
						style={{
							height: 5,
							width: 5,
							borderRadius: 2.5,
							marginTop: 2,
							marginRight: 5,
							backgroundColor: theme.primary
						}}
					/>
					<Text style={{ fontSize: 12 }}>{`${fieldLabel}: ${t(error)}`}</Text>
				</View>
				<View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', paddingLeft: 10 }}>
					<ChildButton
						testID={`importerror_${fieldName}`}
						style={{
							backgroundColor: theme.danger,
							paddingVertical: 5,
							paddingHorizontal: 10
						}}
						onPress={() => _quickFix(item, fieldName, error, true, false)}
					>
						<Text style={{ fontSize: 12, color: theme.white }}>{t('DontImportEntry')}</Text>
					</ChildButton>
					{hasMore && (
						<ChildButton
							testID={`importerror_${fieldName}`}
							style={{
								marginLeft: 10,
								backgroundColor: theme.danger,
								paddingVertical: 5,
								paddingHorizontal: 10
							}}
							onPress={() => _quickFix(item, fieldName, error, true, true)}
						>
							<Text style={{ fontSize: 12, color: theme.white }}>{t('DontImportEntriesWithThisError')}</Text>
						</ChildButton>
					)}
					{cta && (
						<ChildButton
							testID={`importerror_${fieldName}`}
							style={{
								marginLeft: 10,
								borderWidth: 1,
								borderRadius: hsBorderRadius,
								borderColor: theme.primary,
								paddingVertical: 5,
								paddingHorizontal: EHorizontalScreenPadding.Wide
							}}
							onPress={() => _quickFix(item, fieldName, error, false, false)}
						>
							<Text style={{ fontSize: 12, color: theme.primary }}>{cta}</Text>
						</ChildButton>
					)}
					{hasMore && hasMoreCta && (
						<ChildButton
							testID={`importerror_${fieldName}_multi`}
							style={{
								marginLeft: 10,
								borderWidth: 1,
								borderRadius: hsBorderRadius,
								borderColor: theme.primary,
								paddingVertical: 5,
								paddingHorizontal: EHorizontalScreenPadding.Wide
							}}
							onPress={() => _quickFix(item, fieldName, error, false, true)}
						>
							<Text style={{ fontSize: 12, color: theme.primary }}>{hasMoreCta}</Text>
						</ChildButton>
					)}
				</View>
			</View>
		);
	};

	const _renderErrorFix = () => {
		let _data = showData === 'data' ? [...parsedData] : [...parsedDataWithErrors];

		if (_data.length === 0 || !_data[currentItemIndex]) {
			return null;
		}

		const item = _data[currentItemIndex];

		const elements: ReactNode[] = [];
		let hasMoreItemsWithSameError = false;

		if (hasReferenceError) {
			elements.push(
				<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
					<View
						style={{
							height: 5,
							width: 5,
							borderRadius: 2.5,
							marginTop: 2,
							marginRight: 5,
							backgroundColor: theme.primary
						}}
					/>
					<Text style={{ fontSize: 12 }}>{`${t('importItemHasReferences')}`}</Text>
				</View>
			);
		}

		if (item.importStatus) {
			switch (item.importStatus['id']) {
				case 'AttendeeLimitReachedSubtitle':
				case 'I18nItemOutOfSpace':
				case 'I18nNoEntryWithThisId':
					hasMoreItemsWithSameError =
						parsedDataWithErrors.filter((e) => e.importStatus['id'] && e.importStatus['id'] === item.importStatus['id'])
							.length > 1;
					elements.push(
						_renderErrorEntry(
							'ID',
							'id',
							item.importStatus['id'],
							item,
							t('ImportAsNew'),
							hasMoreItemsWithSameError,
							t('ImportAsNewMulti')
						)
					);
					break;
				default:
					break;
			}
			fields.forEach((field) => {
				switch (field.fieldType) {
					case 'reference':
					case 'singlereference':
						if (item?.importStatus && item.importStatus && item.importStatus[field.fieldName]) {
							switch (item.importStatus[field.fieldName]) {
								case 'I18nReferencesOutOfSpace':
									hasMoreItemsWithSameError =
										parsedDataWithErrors.filter(
											(e) =>
												e.importStatus[field.fieldName] &&
												e.importStatus[field.fieldName] === item.importStatus[field.fieldName]
										).length > 1;
									_quickFix(item, field.fieldName, 'I18nReferencesOutOfSpace', false, true);
									//setHasReferenceError(true);
									break;

								default:
									break;
							}
						}
						break;
					case 'website':
						switch (item.importStatus[field.fieldName]) {
							case t('Invalid URL Subtitle'):
								hasMoreItemsWithSameError =
									parsedDataWithErrors.filter(
										(e) =>
											e.importStatus[field.fieldName] &&
											e.importStatus[field.fieldName] === item.importStatus[field.fieldName]
									).length > 1;
								elements.push(
									_renderErrorEntry(
										field.fieldLabel ?? t(field.fieldName),
										field.fieldName,
										item.importStatus[field.fieldName],
										item,
										t('fixUrl'),
										hasMoreItemsWithSameError,
										t('fixUrlMulti')
									)
								);
								break;
							default:
								break;
						}
						break;
					default:
						if (field.fieldName !== 'id' && item.importStatus && item.importStatus[field.fieldName]) {
							hasMoreItemsWithSameError =
								parsedDataWithErrors.filter(
									(e) =>
										e.importStatus[field.fieldName] &&
										e.importStatus[field.fieldName] === item.importStatus[field.fieldName]
								).length > 1;
							elements.push(
								_renderErrorEntry(
									field.fieldLabel,
									field.fieldName,
									item.importStatus[field.fieldName],
									item,
									undefined,
									hasMoreItemsWithSameError
								)
							);
						}
						break;
				}
			});
		}

		if (elements.length > 0) {
			return <View style={{ marginBottom: 20 }}>{elements}</View>;
		}

		return null;
	};

	const _renderForm = () => {
		const _data = showData === 'data' ? [...parsedData] : [...parsedDataWithErrors];

		if (_data.length > 0 && _data[currentItemIndex]) {
			return (
				<HSCard>
					<View>
						{fields.map((field) =>
							renderFormField(
								route.params.type,
								TESTIDPREFIX,
								_data[currentItemIndex],
								_data[currentItemIndex].importStatus ?? {},
								field,
								undefined,
								true
							)
						)}
					</View>
				</HSCard>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					{_renderFilePicker()}
					{_renderFilePickerHint()}
					{_renderSwitchItem()}
					{_renderErrorFix()}
				</HSCard>
				{_renderForm()}
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const ImportDataScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title="Import" />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isDisabled={props.options.isRightNavPressDisabled}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
