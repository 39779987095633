import { FormCheckbox } from 'components/Form';
import { IAttendee, INetworkingRoom } from 'config/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { View, ViewStyle } from 'react-native';
import { Text } from 'components/Text';
import { EDefaultIconSet, getDefaultAspectRatioStyle } from 'helper';
import { ChildButton, RoundButton } from 'components/Button';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { t } from 'i18next';
import { useNavigation } from '@react-navigation/native';
import { ERoutes } from 'components/Navigation/routes';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { Image, PlaceholderGradient } from 'components/Image';
import { INetworkingRoomAttendeeOverview } from 'rematch/interfaces';
import { NetworkingRoomListItemUser } from './NetworkingRoomListItemUser';
import { HSCard } from 'components/Card';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';

interface INetworkingRoomListItem {
	testID: string;
	item: INetworkingRoom;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isSelected?: boolean;
	isLoading?: boolean;
	containerStyle?: ViewStyle;
	onPress?: () => void;
}

const MAXSEATS = 12;
const TABLEPADDING = 10;
const SEATMARGIN = 5;

export const NetworkingRoomListItem = (props: INetworkingRoomListItem) => {
	const { testID, item, onSelect, onEdit, onDelete, isSelected, isLoading, containerStyle, onPress } = props;
	const navigation = useNavigation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();

	const containerRef = useRef<View>(null);

	const [networkingRoomOverview, setNetworkingRoomOverview] = useState<INetworkingRoomAttendeeOverview | undefined>(undefined);
	const [imageWidth, setImageWidth] = useState<number>(1);
	const [meetingPartner, setMeetingPartner] = useState<IAttendee | undefined>(undefined);
	const [hoveredAttendee, setHoveredAttendee] = useState<{ attendee: IAttendee; style: ViewStyle } | undefined>(undefined);

	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const networkingRoomAttendeeOverviews = useSelector((store: IRootState) => store.networking.networkingRoomAttendeeOverviews);

	useEffect(() => {
		let _overview: typeof networkingRoomOverview = undefined;

		if (networkingRoomAttendeeOverviews && activeSpace) {
			_overview = networkingRoomAttendeeOverviews.find(
				(e) => e.externalMeetingId === `${activeSpace.spaceId}_networkingroom_${item.id}`
			);
		}

		setNetworkingRoomOverview(_overview);
	}, [activeSpace, item, networkingRoomAttendeeOverviews]);

	useEffect(() => {
		let _meetingPartner: typeof meetingPartner = undefined;

		if (attendees && item.isMeetingRoom) {
			const meeting = meetings.find((mt) => mt.networkingroom?.id === item.id);
			if (meeting) {
				const partnerId = meeting.ownerId === userInfos.userId ? meeting.partnerId : meeting.ownerId;
				_meetingPartner = attendees.find((e) => e.userId === partnerId);
			}
		}

		setMeetingPartner(_meetingPartner);
	}, [item, meetings, attendees]);

	const _renderSeat = (index: number) => {
		const count = item?.capacity ? (item?.capacity < MAXSEATS ? item?.capacity : MAXSEATS) : MAXSEATS;

		if (count > index) {
			let marginRight = 0;
			let marginBottom = 0;

			if (count > index + 1) {
				if (index <= 7) {
					marginRight = SEATMARGIN;
				} else {
					marginBottom = SEATMARGIN;
				}
			}

			return (
				<View style={{ marginRight, marginBottom, overflow: 'visible' }}>
					<NetworkingRoomListItemUser
						networkingRoom={item}
						userId={
							networkingRoomOverview && networkingRoomOverview.roomUsers[index]
								? networkingRoomOverview && networkingRoomOverview.roomUsers[index].userId
								: undefined
						}
						isHovered={hoveredAttendee?.attendee}
						onHover={async (e) => {
							if (e.attendee) {
								const { x } = await new Promise((resolve) => {
									containerRef.current?.measureInWindow((x, y) => {
										resolve({ x });
									});
								});
								let top = 0;
								switch (index) {
									case 0:
									case 2:
									case 4:
									case 6:
										top = 55;
										break;
									case 8:
									case 9:
										top = 110;
										break;
									case 10:
									case 11:
										top = 165;
										break;
									case 1:
									case 3:
									case 5:
									case 7:
										top = 230;
										break;
								}
								setHoveredAttendee(e.attendee ? { attendee: e.attendee, style: { left: e.x - x, top } } : undefined);
							} else {
								setHoveredAttendee(undefined);
							}
						}}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderImage = () => {
		if (meetingPartner?.smallImageUrl || meetingPartner?.imageUrl || item.overviewImage) {
			return (
				<Image
					url={meetingPartner?.smallImageUrl ?? meetingPartner?.imageUrl ?? item.overviewImage}
					mediaObj={item.overviewImage}
					imageSize="small"
					style={getDefaultAspectRatioStyle(imageWidth)}
					resizeMode="contain"
					expectedRatio={16 / 9}
				/>
			);
		}

		if (activeSpace?.showGradientOnMissingImage) {
			return <PlaceholderGradient itemId={item?.id} title={item?.title} width={imageWidth} rounded={'full'} smallText />;
		}

		return <View style={{ ...getDefaultAspectRatioStyle(imageWidth), backgroundColor: theme.lightgray }} />;
	};

	const _isRoomFull = () => {
		if (networkingRoomOverview) {
			if (networkingRoomOverview.roomUsers.find((e) => e.userId === userInfos.userId)) {
				return false;
			}

			if (item) {
				return networkingRoomOverview?.roomUsers.length === item.capacity;
			}
		}

		return false;
	};

	const _renderActionRow = () => {
		if (!item.isMeetingRoom && (onSelect || onEdit || onDelete)) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
					{onSelect && (
						<FormCheckbox
							testID={`${testID}_checkbox_select`}
							value={isSelected}
							onPress={() => onSelect()}
							style={{ marginBottom: 0 }}
						/>
					)}

					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						{onEdit && (
							<RoundButton
								testID={`${testID}_button_edit`}
								icon={EDefaultIconSet.Edit}
								size="sm"
								onPress={onEdit}
								isOutline
								isLoading={isLoading}
							/>
						)}
						{onDelete && (
							<RoundButton
								testID={`${testID}_button_delete`}
								icon={EDefaultIconSet.Delete}
								color={theme.danger}
								size="sm"
								onPress={onDelete}
								isOutline
								isLoading={isLoading}
							/>
						)}
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderTitle = () => {
		let str = item.title;

		if (meetingPartner) {
			str = `${t('MeetingWith')} ${meetingPartner.firstName} ${meetingPartner.lastName}`;
		}

		return <Text bold>{str}</Text>;
	};

	return (
		<ChildButton testID={`${testID}_cardpress`} onPress={onPress} activeOpacity={onPress ? 0 : 1} style={{ flex: 1 }}>
			<HSCard testID={testID} style={{ flex: 1 }} onLayout={(e) => setImageWidth(e.nativeEvent.layout.width / 2)}>
				{_renderActionRow()}
				<View style={{ position: 'relative', alignItems: 'center' }}>
					<View ref={containerRef} style={{ alignItems: 'center', marginBottom: 10, zIndex: 2 }}>
						<View style={{ flexDirection: 'row', paddingBottom: TABLEPADDING }}>
							{_renderSeat(0)}
							{_renderSeat(2)}
							{_renderSeat(4)}
							{_renderSeat(6)}
						</View>
						<View style={{ flexDirection: 'row', alignItems: 'center' }}>
							<View style={{ paddingRight: TABLEPADDING }}>
								{_renderSeat(8)}
								{_renderSeat(10)}
							</View>
							{_renderImage()}
							<View style={{ paddingLeft: TABLEPADDING }}>
								{_renderSeat(9)}
								{_renderSeat(11)}
							</View>
						</View>
						<View style={{ flexDirection: 'row', paddingTop: TABLEPADDING }}>
							{_renderSeat(1)}
							{_renderSeat(3)}
							{_renderSeat(5)}
							{_renderSeat(7)}
						</View>
						{hoveredAttendee && (
							<View
								style={{
									position: 'absolute',
									zIndex: 10,
									...hoveredAttendee.style,
									backgroundColor: theme.contentBackgroundColor,
									paddingHorizontal: 10,
									paddingVertical: 5,
									borderRadius: hsBorderRadius,
									borderWidth: 1,
									borderColor: theme.lightgray
								}}
							>
								<Text
									numberOfLines={1}
								>{`${hoveredAttendee.attendee.firstName} ${hoveredAttendee.attendee.lastName}`}</Text>
							</View>
						)}
					</View>
					{_renderTitle()}
					<View style={{ alignSelf: 'center', marginTop: hsTopScreenPadding }}>
						<RoundButton
							testID={`${testID}_button_join`}
							title={_isRoomFull() ? t('NetworkingRoomFull') : item.isClosed ? t('NetworkingRoomClosed') : t('JoinRoom')}
							isFloatingButton
							onPress={() => navigation.navigate(ERoutes.NetworkingRoom, { spaceId: activeSpace?.spaceId, id: item.id })}
							isLoading={isLoading}
							borderColor={item.isClosed ? theme.danger : undefined}
						/>
					</View>
				</View>
			</HSCard>
		</ChildButton>
	);
};
