import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormCheckbox } from 'components/Form';
import { IWebhook } from 'config/interfaces';
import { hsBottomMargin } from 'config/styleConstants';
import { EDefaultIconSet } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { isLoading } from 'expo-font';

interface IProps {
	testID: string;
	item: IWebhook;
	isSelected?: boolean;
	onPress: () => void;
	onSelect?: () => void;
	onEdit?: () => void;
	onDelete?: () => void;
	isLoading?: boolean;
	isDeleteLoading?: boolean;
}

export const WEBHOOKLISTITEM_SIZE = 150;

export const WebhookListItem = (props: IProps) => {
	const { testID, item, isSelected, onPress, onSelect, onEdit, onDelete, isLoading, isDeleteLoading } = props;
	const { theme } = useTheme();

	const _renderActions = () => {
		if (onSelect || onEdit || onDelete) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
					<FormCheckbox
						testID={`${testID}_checkbox_select`}
						value={isSelected}
						onPress={onSelect}
						isDisabled={!onSelect}
						style={{ marginBottom: 0 }}
					/>
					<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
						<RoundButton
							testID={`${testID}_button_edit`}
							icon={EDefaultIconSet.Edit}
							size="sm"
							onPress={onEdit}
							isOutline
							isDisabled={isDeleteLoading || isLoading}
						/>

						<RoundButton
							testID={`${testID}_button_delete`}
							icon={EDefaultIconSet.Delete}
							color={theme.danger}
							size="sm"
							onPress={onDelete}
							isOutline
							isLoading={isDeleteLoading}
						/>
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderStatus = () => {
		const size = 15;
		const inset = 10;

		return (
			<View
				style={{
					position: 'absolute',
					top: inset,
					right: inset,
					height: size,
					width: size,
					borderRadius: size / 2,
					backgroundColor: item.isActive ? theme.success : theme.formgray
				}}
			/>
		);
	};

	return (
		<ChildButton
			testID={testID}
			style={{
				flexDirection: 'row',
				alignItems: 'center'
			}}
			onPress={onPress}
		>
			<HSCard style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: hsBottomMargin / 2 }}>
				<View style={{ width: '100%', paddingTop: 5 }}>
					<Text bold style={{ marginBottom: 5, alignSelf: 'center' }}>
						{item.title}
					</Text>
					<Text style={{ marginBottom: 5 }}>{`${item.events.split(',').length} Event(s)`}</Text>
					<Text numberOfLines={2}>{item.url}</Text>
					{_renderActions()}
				</View>
				{_renderStatus()}
			</HSCard>
		</ChildButton>
	);
};
