import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultStreamFields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldHint: i18next.t('titleHint'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'streamType',
			fieldLabel: i18next.t('Type'),
			fieldType: 'multiswitch',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			options: [
				{
					key: 'contentflow',
					label: 'Contentflow'
				},
				{
					key: 'iFrame',
					label: 'iFrame'
				},
				{
					key: 'mediaLibrary',
					label: i18next.t('Media Library Content')
				},
				{
					key: 'upload',
					label: 'Video Upload'
				},
				{
					key: 'vimeo',
					label: 'Vimeo'
				},
				{
					key: 'youtube',
					label: 'YouTube'
				}
			]
		},
		{
			fieldName: 'media',
			fieldLabel: 'Media',
			fieldType: 'image',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'youTubeUrl',
			fieldLabel: 'YouTube URL',
			fieldType: 'website',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'vimeoUrl',
			fieldLabel: 'Vimeo Url',
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'iFrameUrl',
			fieldLabel: 'iFrame Url',
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'schedules',
			fieldLabel: i18next.t('Schedules'),
			fieldType: 'reference',
			referenceType: 'schedules',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'mediaitem',
			fieldLabel: 'Media Item',
			fieldType: 'singlereference',
			referenceType: 'mediaitems',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'contentflowUrl',
			fieldLabel: 'Contentflow Url',
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		}
	];

	return arr;
};
