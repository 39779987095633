import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { View } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { isDarkColor } from 'helper';

interface IListBubbles {
	totalCount: number;
	activeIndex: number;
	size?: 'sm';
	noTopMargin?: boolean;
}

export const ListBubbles = (props: IListBubbles) => {
	const { totalCount, activeIndex, size, noTopMargin } = props;
	const { theme } = useTheme();

	const [bubbleWidth, setBubbleWidth] = useState<number>(8);

	useEffect(() => {
		if (size === 'sm') {
			setBubbleWidth(6);
		}
	}, []);

	if (totalCount < 2) {
		return null;
	}

	return (
		<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: noTopMargin ? 0 : 15 }}>
			{_.times(totalCount, (idx) => {
				const isActive = idx === Math.round(activeIndex);
				const activeColor = theme.primary;
				const inactiveColor = isDarkColor(theme.contentBackgroundColor ?? theme.background) ? theme.gray : theme.lightgray;

				return (
					<View
						key={`listbubble_bubble_${idx}`}
						style={{
							width: bubbleWidth,
							height: bubbleWidth,
							borderRadius: bubbleWidth / 2,
							backgroundColor: isActive ? activeColor : inactiveColor,
							marginRight: bubbleWidth / 2
						}}
					/>
				);
			})}
		</View>
	);
};
