import { SPONSOR_BLOCK, SPONSOR_FREQUENCY } from 'config/constants';
import {
	getDefaultExpoFields,
	getDefaultMeetingTableFields,
	getDefaultNetworkingRoomFields,
	getDefaultScheduleFields,
	getDefaultSpeakerFields,
	getDefaultStageFields,
	getDefaultTicketFields,
	getDefaultVotingFields
} from 'config/defaultFields';
import { getDefaultFeedFields } from 'config/defaultFields/defaultFeedFields';
import { getDefaultMediaItemfields } from 'config/defaultFields/defaultMediaItemFields';
import { getDefaultStreamFields } from 'config/defaultFields/defaultStreamFields';
import { IContentTypeField, ILauncherSpace } from 'config/interfaces';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { useFeature } from './useFeature';
import { useSpace } from './useSpace';

export type TContentType =
	| 'chat'
	| 'expo'
	| 'feed'
	| 'speaker'
	| 'stage'
	| 'schedule'
	| 'networkingroom'
	| 'voting'
	| 'stream'
	| 'mediaitem'
	| 'ticket'
	| 'meeting'
	| 'meetingtable'
	| 'attendee';

export type TContentTypePlural =
	| 'changelogs'
	| 'chats'
	| 'expos'
	| 'newsitems'
	| 'comments'
	| 'bookings'
	| 'mailtemplates'
	| 'sendmail'
	| 'mailhistories'
	| 'speakers'
	| 'stages'
	| 'schedules'
	| 'schedulestatuses'
	| 'networkingrooms'
	| 'networkingroomattendees'
	| 'votings'
	| 'likes'
	| 'pushnotifications'
	| 'maps'
	| 'mybookings'
	| 'mappositions'
	| 'streams'
	| 'subscription'
	| 'mediaitems'
	| 'tickets'
	| 'meetings'
	| 'myvoteanswers'
	| 'meetingtables'
	| 'userinspace'
	| 'attendees'
	| 'attendeesupportrequests'
	| 'webhooks'
	| 'allBookingCounts';

export interface IFormSection {
	fields: IContentTypeField[];
}

export const useContent = (contentType?: TContentType) => {
	const { isFeatureActive } = useFeature();
	const { activeSpace } = useSpace();

	const content = useSelector((store: IRootState) => store.content.content);

	const getContentTypeFields = (unfiltered?: boolean, tempFields?: IContentTypeField[], space?: ILauncherSpace) => {
		let fields: IContentTypeField[] = [];

		const mySpace = space ?? activeSpace;

		if (mySpace) {
			switch (contentType) {
				case 'expo':
					if (mySpace?.expoFields?.fields) {
						fields = [...mySpace.expoFields.fields];
					}
					break;
				case 'speaker':
					if (mySpace?.speakerFields?.fields) {
						fields = [...mySpace.speakerFields.fields];
					}
					break;
				case 'ticket':
					if (mySpace?.ticketFields?.fields) {
						fields = [...mySpace.ticketFields.fields];
					}
					break;
				case 'mediaitem':
					if (mySpace?.mediaItemFields?.fields) {
						fields = [...mySpace.mediaItemFields.fields];
					}
					break;
				case 'schedule':
					if (mySpace?.scheduleFields?.fields) {
						fields = [...mySpace.scheduleFields.fields];
					}
					break;
				default:
					break;
			}
		}

		if (tempFields && contentType === 'schedule' && tempFields.length > 0) {
			fields = [...tempFields];
		}

		switch (contentType) {
			case 'feed':
				fields = getDefaultFeedFields();
				break;
			case 'expo':
				getDefaultExpoFields().forEach((field) => {
					const idx = fields.findIndex((e) => {
						return e.fieldName === field.fieldName || (e.fieldType === field.fieldType && e.fieldLabel === field.fieldLabel);
					});
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
					if (fields[idx] && fields[idx].fieldType !== field.fieldType) {
						fields[idx].fieldType = field.fieldType;
					}
					if (field.sectionPosition && fields[idx] && !fields[idx].sectionPosition) {
						fields[idx].sectionPosition = field.sectionPosition;
					}
				});
				break;
			case 'schedule':
				getDefaultScheduleFields().forEach((field) => {
					const idx = fields.findIndex((e) => e.fieldName === field.fieldName);
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
				});
				break;
			case 'speaker':
				getDefaultSpeakerFields().forEach((field) => {
					const idx = fields.findIndex((e) => {
						return e.fieldName === field.fieldName || (e.fieldType === field.fieldType && e.fieldLabel === field.fieldLabel);
					});
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
					if (field.aspectRatio && fields[idx]) {
						fields[idx].aspectRatio = field.aspectRatio;
					}
					if (fields[idx] && fields[idx].fieldType !== field.fieldType) {
						fields[idx].fieldType = field.fieldType;
					}
					if (field.sectionPosition && fields[idx] && !fields[idx].sectionPosition) {
						fields[idx].sectionPosition = field.sectionPosition;
					}
				});
				break;
			case 'stage':
				fields = getDefaultStageFields();
				break;
			case 'networkingroom':
				fields = getDefaultNetworkingRoomFields();
				break;
			case 'stream':
				fields = getDefaultStreamFields();
				break;
			case 'voting':
				fields = getDefaultVotingFields();
				break;
			case 'mediaitem':
				getDefaultMediaItemfields().forEach((field) => {
					const idx = fields.findIndex((e) => e.fieldName === field.fieldName);
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
				});
				break;
			case 'ticket':
				getDefaultTicketFields().forEach((field) => {
					const idx = fields.findIndex((e) => e.fieldName === field.fieldName);
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
				});
				break;
			case 'meetingtable':
				fields = getDefaultMeetingTableFields();
				break;
			default:
				break;
		}

		if (!unfiltered) {
			fields = fields.filter((e) => e.visibility === 'visible');
			fields = fields.filter((e) => e.fieldType !== 'reference' || isFeatureActive(e.referenceType));
		}

		return fields;
	};

	const getContentTypeSections = (selfService?: boolean, fields?: IContentTypeField[], detail?: boolean, space?: ILauncherSpace) => {
		let _sections: IFormSection[] = [];

		let _fields = fields ?? getContentTypeFields();

		if (selfService) {
			_fields = getSelfServiceFields(fields ?? []);
		}

		if (detail) {
			_fields = _fields.filter((e) => e.showOnDetailScreen || e.fieldType === 'section' || e.fieldType === 'mainsection');
		}

		while (_fields.length > 0) {
			const sectionIndex = _fields.findIndex((e) => e.fieldType === 'section' || e.fieldType === 'mainsection');
			if (sectionIndex === -1) {
				_sections.push({
					fields: _fields
				});
				_fields = [];
			} else if (sectionIndex !== 0) {
				_sections.push({
					fields: _fields.slice(0, sectionIndex)
				});
				_fields.splice(0, sectionIndex);
			} else {
				const nextSectionIndex = _fields.findIndex(
					(e) => e.fieldName !== _fields[sectionIndex].fieldName && (e.fieldType === 'section' || e.fieldType === 'mainsection')
				);
				if (nextSectionIndex === -1) {
					if (_fields.length > 0) {
						_sections.push({
							fields: _fields
						});
					}
					_fields = [];
				} else {
					if (_fields.slice(0, nextSectionIndex).length > 0) {
						_sections.push({
							fields: _fields.slice(0, nextSectionIndex)
						});
					}
					_fields.splice(0, nextSectionIndex);
				}
			}
		}

		return _sections;
	};

	const getSelfServiceFields = (fields: IContentTypeField[]) => {
		let _fields = [...fields];

		switch (contentType) {
			case 'expo':
				getDefaultExpoFields().forEach((field) => {
					const idx = fields.findIndex((e) => {
						return e.fieldName === field.fieldName || (e.fieldType === field.fieldType && e.fieldLabel === field.fieldLabel);
					});
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
					if (fields[idx] && fields[idx].fieldType !== field.fieldType) {
						fields[idx].fieldType = field.fieldType;
					}
					if (field.sectionPosition && fields[idx] && !fields[idx].sectionPosition) {
						fields[idx].sectionPosition = field.sectionPosition;
					}
				});
				break;
			case 'schedule':
				getDefaultScheduleFields().forEach((field) => {
					const idx = _fields.findIndex((e) => e.fieldName === field.fieldName);
					if (idx === -1) {
						_fields.push(field);
					}
				});
				break;
			case 'speaker':
				getDefaultSpeakerFields().forEach((field) => {
					const idx = fields.findIndex((e) => {
						return e.fieldName === field.fieldName || (e.fieldType === field.fieldType && e.fieldLabel === field.fieldLabel);
					});
					if (idx === -1) {
						fields.push(field);
					}
					if (field.maxLength && fields[idx]) {
						fields[idx].maxLength = field.maxLength;
					}
					if (field.aspectRatio && fields[idx]) {
						fields[idx].aspectRatio = field.aspectRatio;
					}
					if (fields[idx] && fields[idx].fieldType !== field.fieldType) {
						fields[idx].fieldType = field.fieldType;
					}
					if (field.sectionPosition && fields[idx] && !fields[idx].sectionPosition) {
						fields[idx].sectionPosition = field.sectionPosition;
					}
				});
				break;
			case 'stage':
				getDefaultStageFields().forEach((field) => {
					const idx = _fields.findIndex((e) => e.fieldName === field.fieldName);
					if (idx === -1) {
						_fields.push(field);
					}
				});
				break;
			default:
				break;
		}

		_fields = _fields.filter((e) => e.selfServiceVisibility !== 'never');

		return _fields;
	};

	const getInitialFormValues = () => {
		const obj = {};

		const fields = getContentTypeFields();
		fields.forEach((field) => {
			if (field.hasOwnProperty('defaultValue')) {
				obj[field.fieldName] = field.defaultValue;
			}
		});

		return obj;
	};

	enum maxFieldCountForFieldType {
		extra = 10,
		checkbox = 10,
		category = 10,
		section = 999,
		label = 999
	}

	const addSponsorToContentArray = (array: any[]) => {
		if (
			content?.expos?.filter(
				(e) => e.spaceId === activeSpace?.spaceId && (e.sponsorType === 'sponsorOnly' || e.sponsorType === 'sponsor')
			)?.length > 0
		) {
			const count = Math.floor(array?.length / SPONSOR_FREQUENCY);
			array.splice(2, 0, SPONSOR_BLOCK);
			_.times(count, (idx) => {
				array.splice((idx + 1) * SPONSOR_FREQUENCY + 2, 0, SPONSOR_BLOCK);
			});
		}

		return array;
	};

	return {
		getContentTypeFields,
		getContentTypeSections,
		getInitialFormValues,
		maxFieldCountForFieldType,
		getSelfServiceFields,
		addSponsorToContentArray
	};
};
