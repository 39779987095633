import React, { ReactNode } from 'react';
import { Spinner } from 'components/Spinner';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';

interface IChildButtonComponent extends TouchableOpacityProps {
	testID: string;
	children?: ReactNode | ReactNode[];
	isDisabled?: boolean;
	isLoading?: boolean;
}

export const ChildButton = (props: IChildButtonComponent) => {
	const { children, isDisabled, isLoading, style, testID } = props;

	return (
		<TouchableOpacity {...props} disabled={isDisabled} style={style} activeOpacity={1}>
			{isLoading ? <Spinner testID={testID} /> : children ? children : null}
		</TouchableOpacity>
	);
};
