import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'components/SearchBar';
import { IRootState } from 'rematch/store';
import { useSelector } from 'react-redux';
import { isEmptyString, IS_WEB } from 'helper';
import { NoData } from 'components/NoData';
import { FlashSectionList } from 'components/List';
import { FormCheckbox } from 'components/Form/FormCheckbox';

import { HSCard } from 'components/Card/HSCard';
import { IHSPXTicket } from 'config/interfaces/ticket';
import { TicketListHeader } from './TicketListHeader';
import { TicketListItem, TICKETLISTITEMENTRYHEIGHT } from './TicketListItem';
import { useSpace } from 'hooks/useSpace';
import { hsInnerPadding } from 'config/styleConstants';

interface ITicketList {
	testID: string;
	selectedTickets?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: IHSPXTicket) => void;
	isLoading?: boolean;
	isMailReceiver?: boolean;
}

interface ITicketData {
	header: string;
	items: IHSPXTicket[];
}

export const TicketList = (props: ITicketList) => {
	const { testID, selectedTickets, onSelectAll, onSelect, onEdit, onDelete, onPress, isLoading, isMailReceiver } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const [spaceTickets, setSpaceTickets] = useState<IHSPXTicket[]>([]);
	const [searchedTickets, setSearchedTickets] = useState<IHSPXTicket[]>([]);
	const [ticketSections, setTicketSections] = useState<(string | IHSPXTicket)[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const tickets = useSelector((store: IRootState) => store.ticket.tickets);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	useEffect(() => {
		let _tickets: typeof spaceTickets = [];

		if (activeSpace && tickets) {
			_tickets = tickets.filter((e) => e.spaceId === activeSpace.spaceId);
		}

		setSpaceTickets(_tickets);
	}, [activeSpace, tickets]);

	useEffect(() => {
		let _searched: typeof spaceTickets = [...spaceTickets];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter(
				(item) =>
					item.firstName?.toLowerCase().includes(lowerSearch) ||
					item.lastName?.toLowerCase().includes(lowerSearch) ||
					item.jobTitle?.toLowerCase().includes(lowerSearch) ||
					item.company?.toLowerCase().includes(lowerSearch) ||
					item.email?.toLowerCase().includes(lowerSearch) ||
					item.ticketcode?.toLowerCase().includes(lowerSearch)
			);
		}

		setSearchedTickets(_searched);
	}, [spaceTickets, searchTerm]);

	useEffect(() => {
		const sections: ITicketData[] = [];
		let newSections: typeof ticketSections = [];

		let _searched = [...searchedTickets];

		_searched.forEach((item, index) => {
			let header = '';

			if (item.lastName && !isEmptyString(item.lastName)) {
				header = item.lastName.charAt(0).toUpperCase();
			}

			const found = sections.find((e) => e.header === header);
			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: header,
					items: [item]
				});
			}
		});

		sections.sort((a, b) => (a.header.toLowerCase() < b.header.toLowerCase() ? -1 : 1));

		sections.forEach((section) => {
			section.items.sort((a, b) => {
				const aVal = a.lastName ? a.lastName : 'ZZZZ';
				const bVal = b.lastName ? b.lastName : 'ZZZZ';
				return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
			});
		});

		sections.forEach((section) => {
			newSections.push(section.header);
			newSections = newSections.concat(section.items);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setTicketSections(newSections);
	}, [searchedTickets]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedTickets.length > 0) {
			return (
				<FormCheckbox
					testID={`${testID}_checkbox_selectall`}
					label={t('SelectAll')}
					style={{ paddingHorizontal: IS_WEB ? hsInnerPadding : 0, marginBottom: 10 }}
					value={selectedTickets && selectedTickets.length === searchedTickets.length}
					onPress={() => {
						if (selectedTickets && selectedTickets.length === searchedTickets.length) {
							onSelectAll([]);
						} else {
							onSelectAll(searchedTickets.map((sp) => sp.id));
						}
					}}
				/>
			);
		}

		return null;
	};

	const _renderList = () => {
		if (searchedTickets.length === 0 && (!waitingForSocketResponse?.tickets || !isEmptyString(searchTerm))) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoTickets" />;
		}

		return (
			<HSCard style={{ flex: 1, paddingLeft: IS_WEB ? 0 : hsInnerPadding, paddingRight: 0 }}>
				{_renderSelectAll()}
				<FlashSectionList
					data={ticketSections}
					extraData={[onSelect, selectedTickets]}
					renderItem={(renderItem) => (
						<TicketListItem
							testID={`${testID}_button_ticket_${renderItem.index}`}
							isLoading={isLoading}
							item={renderItem.item}
							onPress={() => {
								if (onPress) {
									onPress(renderItem.item);
								}
							}}
							isSelected={selectedTickets?.includes(renderItem.item.id)}
							onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
							onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
							onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
							isMailReceiver={isMailReceiver}
						/>
					)}
					renderSectionHeader={(renderItem) => <TicketListHeader text={renderItem.item} />}
					estimatedItemSize={TICKETLISTITEMENTRYHEIGHT}
				/>
			</HSCard>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			<HSCard style={{ flexDirection: 'row', alignItems: 'center' }}>
				<View style={{ flex: 1 }}>
					<SearchBar testID={`${testID}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
				</View>
			</HSCard>
			{_renderList()}
		</View>
	);
};
