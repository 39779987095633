import { IAttendee, IEditAttendeeFormErros, IEditAttendeeFormValues, IRegistrationField } from 'config/interfaces';
import { hsInnerPadding } from 'config/styleConstants';
import { showFormErrorToast, validateForm } from 'helper';
import { useForm } from 'hooks/useForm';
import { useRegistration } from 'hooks/useRegistration';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dispatch, useRematchDispatch } from 'rematch/store';
import { HSModal } from '../Modal';
import { ScrollView } from 'react-native';
interface IEditAttendeeModal {
	isVisible: boolean;
	onClose: () => void;
	isLoading: boolean;
	attendee?: IAttendee;
}

const TESTIDPREFIX = 'editattendeemodal';
const forbiddenFields = ['avatar', 'password', 'hellospacesNewsletter'];

export const EditAttendeeModal = (props: IEditAttendeeModal) => {
	const { isVisible, onClose, isLoading, attendee } = props;

	const { t } = useTranslation();
	const { theme } = useTheme();
	const { getRegistrationFields } = useRegistration();
	const { activeSpace } = useSpace();
	const { renderFormField, getFormSchema } = useForm();

	const [formFields, setFormFields] = useState<IRegistrationField[]>([]);
	const [attendeeValues, setAttendeeValues] = useState<IEditAttendeeFormValues>({});
	const [errors, setErrors] = useState<IEditAttendeeFormErros>({});
	const [isAttendeUpdateLoading, setIsAttendeUpdateLoading] = useState<boolean>(false);

	const updateAttendee = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.updateAttendee);

	useEffect(() => {
		const unfiltered = activeSpace?.registrationFields?.fields ?? getRegistrationFields();
		const filtered = unfiltered.filter((e) => e.visibility === 'always' && !forbiddenFields.includes(e.fieldName));
		setFormFields(filtered);
	}, [activeSpace]);

	useEffect(() => {
		if (attendee) {
			const fields = formFields;
			const obj: IEditAttendeeFormValues = {
				firstName: attendee.firstName,
				lastName: attendee.lastName,
				email: attendee.email
			};
			fields.forEach((field) => {
				obj[field.fieldName] = attendee[field.fieldName];
			});
			setAttendeeValues(obj);
		}
	}, [formFields, attendee]);

	const _updateUser = async () => {
		const errors = await validateForm(getFormSchema(formFields, attendeeValues), attendeeValues);
		if (errors) {
			setErrors(errors);
			showFormErrorToast(errors, formFields);
			return;
		} else {
			setErrors({});
		}
		setIsAttendeUpdateLoading(true);
		const res = await updateAttendee({ attendee: { userId: attendee?.userId, ...attendeeValues } });
		setIsAttendeUpdateLoading(false);
		if (res) {
			onClose();
		}
	};

	if (attendeeValues) {
		return (
			<HSModal
				isVisible={isVisible}
				isSubmitLoading={isAttendeUpdateLoading}
				onClose={onClose}
				onSubmit={_updateUser}
				title={t('editAttendee')}
			>
				<ScrollView style={{ marginHorizontal: -hsInnerPadding }} contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}>
					{formFields.map((field) => renderFormField('attendee', TESTIDPREFIX, attendeeValues, errors, field, setAttendeeValues))}
				</ScrollView>
			</HSModal>
		);
	}

	return null;
};
