import React, { useEffect, useRef, useState } from 'react';
import { ILauncherSpace } from 'config/interfaces';
import { Dimensions, ScrollView, View, ViewStyle } from 'react-native';
import { H2, Text } from 'components/Text';
import { useTranslation } from 'react-i18next';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { RoundButton } from 'components/Button';
import { useNavigation, useRoute } from '@react-navigation/native';
import { HSCard } from 'components/Card';
import { useSelector } from 'react-redux';
import { canJoinSpace, EDefaultIconSet, generateTheme, getSettingsForNavigationKey, isEmptyString, IS_WEB } from 'helper';
import { Markdown } from 'components/Markdown';
import { showToast } from 'helper/toast';
import { FormCheckbox } from 'components/Form';
import { Image } from 'components/Image';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { SpaceHistoryListItem } from 'components/SpaceHistory';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding, hsTextBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import * as RootNavigation from '../../RootNavigation';
import { RequiredProfileFieldsModal } from 'components/Modal/RequiredProfileFieldsModal';
import { HSModal } from 'components/Modal/Modal';
import { PinModal } from 'components/Modal/PinModal';
import { TicketModal } from 'components/Modal/TicketModal';
import { SupportRequestModal } from 'components/Modal/SupportRequestModal';
import { DATAPROTECTIONURL, HELLOSPACES_USERS } from 'config/constants';
import { ERoutes } from 'components/Navigation/routes';
import { PRESET_SPACEIDS } from 'config/envConstants';
import moment from 'moment';

//bg images
const gradientBlueLightblue = require('../../assets/images/gradient/gradientBlueLightblue.png');
const gradientLightblueOrange = require('../../assets/images/gradient/gradientLightblueOrange.png');
const gradientMagentaBlue = require('../../assets/images/gradient/gradientMagentaBlue.png');
const gradientOrangeMagenta = require('../../assets/images/gradient/gradientOrangeMagenta.png');

interface ISpaceSummary {
	spaceId: string;
	space: ILauncherSpace;
	hideButtons?: boolean;
	backgroundBorderRadius?: boolean;
	contentContainerStyle?: ViewStyle;
	isPreview?: boolean;
}

const TESTIDPREFIX = 'spacesummary';

export const SpaceSummary = (props: ISpaceSummary) => {
	const { spaceId, space, hideButtons, backgroundBorderRadius, contentContainerStyle, isPreview } = props;
	const navigation = useNavigation();
	const { screenWidth, isTabletOrMobile, screenType } = useQuery();
	const screenHeight = Dimensions.get('screen').height;
	const route = useRoute();
	const { activeSpace } = useSpace();
	const scrollRef = useRef<ScrollView>(null);

	const { t } = useTranslation();

	const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false);
	const [isTermsAcknowledged, setIsTermsAcknowledged] = useState<boolean>(false);
	const [isPrivacyAgreed, setIsPrivacyAgreed] = useState<boolean>(false);
	const [acceptTermsError, setAcceptTermsError] = useState<string>('');
	const [acknowledgeTermsError, setAcknowledgeTermsError] = useState<string>('');
	const [acceptPrivacyError, setAcceptPrivacyError] = useState<string>('');
	const [isJoinSpaceLoading, setIsJoinSpaceLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isRequiredProfileFieldsModalVisible, setIsRequiredFieldsModalVisible] = useState<boolean>(false);
	const [isTicketModalVisible, setIsTicketModalVisible] = useState<boolean>(false);
	const [isSupportRequestModalVisible, setIsSupportRequestModalVisible] = useState<boolean>(false);
	const [shouldJoinSpace, setShouldJoinSpace] = useState<boolean>(false);
	const [isPinModalVisible, setIsPinModalVisible] = useState<boolean>(false);
	const [spacePin, setSpacePin] = useState<string>('');
	const [isTermsModalVisible, setIsTermsModalVisible] = useState<boolean>(false);
	const [showHsAdminButton, setShowHsAdminButton] = useState<boolean>(false);
	const [containerWidth, setContainerWidth] = useState<number>(0);

	const [scrollViewHeight, setScrollViewHeight] = useState<number>(0);
	const [markdownHeight, setMarkdownHeight] = useState<number>(0);
	const [onPressEnabled, setOnPressEnabled] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const hasAcceptedPrivacyPolicy = useSelector((store: IRootState) => store.auth.hasAcceptedPrivacyPolicy);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const joinSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.joinSpace);
	const setTheme = useRematchDispatch((dispatch: Dispatch) => dispatch.config.setTheme);
	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);
	const getActiveSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpaceId);
	const setTempSpaceId = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setTempSpaceId);

	useEffect(() => {
		setTheme(generateTheme(space));
	}, [space]);

	useEffect(() => {
		if (profile && space) {
			const isHelloSpacesUser = HELLOSPACES_USERS.includes(profile?.email);
			const foundAdmin = space.admins?.find((a) => a.userId === profile.userId);
			const foundHistory = profile.userSpaceHistory?.spaces.find((e) => e.spaceId === spaceId);

			if (isHelloSpacesUser && !foundAdmin && !foundHistory) {
				setShowHsAdminButton(true);
			}
		}
	}, [profile]);

	useEffect(() => {
		if (profile && (profile?.userSpaceHistory?.spaces.length ?? 0) > 0 && !isTermsAgreed) {
			const found = profile.userSpaceHistory?.spaces.find((e) => e.spaceId === spaceId);
			if (found) {
				setIsTermsAgreed(true);
				setIsTermsAcknowledged(true);
			}
		}
	}, [profile, space]);

	useEffect(() => {
		if (profile && shouldJoinSpace) {
			setShouldJoinSpace(false);
			_joinSpace();
		}
	}, [profile, shouldJoinSpace]);

	useEffect(() => {
		navigation.addListener('focus', () => _onFocus());
	}, [activeSpace]);

	useEffect(() => {
		if (scrollViewHeight > 0 && markdownHeight > 0) {
			if (markdownHeight > scrollViewHeight * 1.25) {
				setOnPressEnabled(true);
			} else {
				setOnPressEnabled(false);
			}
		}
	}, [scrollViewHeight, markdownHeight]);

	const _onFocus = () => {
		const _active = getActiveSpace({});
		if (_active) {
			if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
				RootNavigation.popToTop();
			} else {
				RootNavigation.replace('tab', {});
			}
		}
	};

	const _renderSpacePrivacy = (largerDesign?: boolean) => {
		if (hideButtons) {
			return null;
		}
		if (!isEmptyString(space?.customUserConsentText) || space?.dataProtectionUrl || space?.readTermsUrl) {
			return (
				<View>
					<FormCheckbox
						largerDesign={largerDesign}
						error={acknowledgeTermsError}
						style={{ marginBottom: hsBottomMargin / 4 }}
						testID={`spacesummary_checkbox_acknowledgeterms`}
						onPress={() => {
							setIsTermsAcknowledged(!isTermsAcknowledged);
							if (!isEmptyString(acknowledgeTermsError)) {
								setAcknowledgeTermsError('');
							}
						}}
						isDisabled={isJoinSpaceLoading}
						value={isTermsAcknowledged}
						label={
							!isEmptyString(space.dataProtectionAcknowledgedText)
								? space.dataProtectionAcknowledgedText
								: t('acknowledgeTerms')
						}
						url={space?.readTermsUrl}
						isRequired
					/>
					<FormCheckbox
						largerDesign={largerDesign}
						error={acceptTermsError}
						style={{ marginBottom: 0 }}
						testID={`spacesummary_checkbox_agreetoterms`}
						onPress={() => {
							setIsTermsAgreed(!isTermsAgreed);
							if (!isEmptyString(acceptTermsError)) {
								setAcceptTermsError('');
							}
						}}
						url={space?.dataProtectionUrl}
						isDisabled={isJoinSpaceLoading}
						value={isTermsAgreed}
						label={!isEmptyString(space.dataProtectionAcceptedText) ? space.dataProtectionAcceptedText : t('agreeToTerms')}
						isRequired
					/>
				</View>
			);
		}

		return null;
	};

	const _renderCustomUserConsentText = (largerDesign?: boolean) => {
		if (isPreview) {
			return null;
		}
		if (
			!isEmptyString(space?.customUserConsentText) ||
			!isEmptyString(space?.dataProtectionUrl) ||
			!isEmptyString(space?.readTermsUrl) ||
			!(hasAcceptedPrivacyPolicy || PRESET_SPACEIDS || hideButtons)
		) {
			return (
				<HSCard
					style={{
						maxHeight: largerDesign
							? screenHeight * 0.5
							: !hasAcceptedPrivacyPolicy
							? screenHeight * 0.35
							: screenHeight * 0.325,
						overflow: 'hidden'
					}}
				>
					{!isEmptyString(space?.customUserConsentText) && <H2 largerText={largerDesign}>{t('User Consent')}</H2>}
					{!isEmptyString(space?.customUserConsentText) && (
						<ScrollView
							style={{ marginBottom: hsBottomMargin, flexGrow: 1, overflow: 'hidden' }}
							onLayout={(e) => setScrollViewHeight(e.nativeEvent.layout.height)}
						>
							<Markdown
								largerDesign={largerDesign}
								markdown={space.customUserConsentText}
								onPress={onPressEnabled ? () => setIsTermsModalVisible(true) : undefined}
								onLayout={(e) => setMarkdownHeight(e.nativeEvent.layout.height)}
							/>
						</ScrollView>
					)}
					{_renderSpacePrivacy(largerDesign)}
					{_renderPrivacyProtection(largerDesign)}
				</HSCard>
			);
		}

		return null;
	};

	const _renderBackgroundImage = () => {
		let src = '';

		if (space?.backgroundImage?.url) {
			src = space?.backgroundImage?.url;
		} else {
			switch (space?.backgroundImageName) {
				case 'gradientBlueLightblue':
					src = gradientBlueLightblue;
					break;
				case 'gradientLightblueOrange':
					src = gradientLightblueOrange;
					break;
				case 'gradientMagentaBlue':
					src = gradientMagentaBlue;
					break;
				case 'gradientOrangeMagenta':
					src = gradientOrangeMagenta;
					break;
				default:
					src = '';
					break;
			}
		}
		if (!isEmptyString(src)) {
			return (
				<View style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<Image
						style={{
							width: '100%',
							height: '100%',
							borderRadius: backgroundBorderRadius ? hsBorderRadius : 0
						}}
						contentFit="cover"
						url={src}
					/>
				</View>
			);
		}

		return null;
	};

	const _joinSpace = async (skip?: boolean) => {
		if (
			((space?.customUserConsentText || space?.dataProtectionUrl || space?.readTermsUrl) &&
				(!isTermsAgreed || !isTermsAcknowledged)) ||
			(!hasAcceptedPrivacyPolicy && !isPrivacyAgreed && !PRESET_SPACEIDS)
		) {
			if (
				(space?.customUserConsentText || space?.dataProtectionUrl || space?.readTermsUrl) &&
				(!isTermsAgreed || !isTermsAcknowledged)
			) {
				if (!isTermsAcknowledged) {
					setAcknowledgeTermsError(t('acknowledgeTermsError'));
				}
				if (!isTermsAgreed) {
					setAcceptTermsError(t('acceptTermsError'));
				}
			}

			if (!hasAcceptedPrivacyPolicy && !isPrivacyAgreed && !PRESET_SPACEIDS) {
				setAcceptPrivacyError(t('acceptPrivacyError'));
			}

			setTimeout(() => {
				if (scrollRef.current) {
					scrollRef.current.scrollToEnd();
				}
			}, 100);
			return;
		}

		setIsJoinSpaceLoading(true);
		const res = await findSpaceById({ spaceId: space.spaceId });
		setIsJoinSpaceLoading(false);

		if (res?.spaceId) {
			const {
				isAdmin,
				isModerator,
				isDisabledBySuperAdmin,
				isDeactivated,
				needsRegistration,
				needsConfirmation,
				needsToFilloutProfileFields,
				needsTicket,
				needsPin,
				isHelloSpacesUser
			} = canJoinSpace(res, profile, spacePin ?? route?.params?.pin);

			if (!skip && !isHelloSpacesUser) {
				if (isDisabledBySuperAdmin) {
					showToast('error', undefined, isAdmin ? t('spaceIsDeactivatedAlertTitleAdmin') : t('spaceIsDeactivatedAlertTitle'));
					return;
				}

				if (isDeactivated && !(isAdmin || isModerator)) {
					showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
					return;
				}

				if (needsRegistration) {
					setIsAuthenticationModalVisible(true);
					return;
				}

				if (needsConfirmation && !(isAdmin || isModerator)) {
					setIsAuthenticationModalVisible(true);
					return;
				}

				if (needsToFilloutProfileFields) {
					setIsRequiredFieldsModalVisible(true);
					return;
				}

				if (needsTicket) {
					setIsTicketModalVisible(true);
					return;
				}
			}

			setIsJoinSpaceLoading(true);
			const joinSpaceSuccess = await joinSpace({ space });
			setIsAuthenticationModalVisible(false);
			setIsTicketModalVisible(false);
			setIsJoinSpaceLoading(false);

			if (joinSpaceSuccess) {
				setTempSpaceId(undefined);
				if (IS_WEB) {
					if (needsPin && !isAdmin) {
						RootNavigation.replace(ERoutes.Prestart, { spaceId: res.spaceId });
					} else {
						RootNavigation.replace(getActiveSpaceId({}) ? 'tab' : ERoutes.SpaceSelect);
					}
				} else {
					if (needsPin && !isAdmin) {
						RootNavigation.replace(ERoutes.Prestart, { spaceId: res.spaceId });
					} else if (!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1) {
						RootNavigation.popToTop();
					} else {
						RootNavigation.replace('tab', {});
					}
				}
			}
		}
	};

	const _retryJoinSpace = async () => {
		setIsRequiredFieldsModalVisible(false);
		setShouldJoinSpace(true);
	};

	const _renderPrivacyProtection = (largerDesign?: boolean) => {
		if (hasAcceptedPrivacyPolicy || PRESET_SPACEIDS || hideButtons) {
			return null;
		}
		return (
			<FormCheckbox
				largerDesign={largerDesign}
				error={acceptPrivacyError}
				testID={`${TESTIDPREFIX}_checkbox_privacypolicy`}
				url={DATAPROTECTIONURL}
				value={isPrivacyAgreed}
				onPress={() => {
					setIsPrivacyAgreed(!isPrivacyAgreed);
					if (!isEmptyString(acceptPrivacyError)) {
						setAcceptPrivacyError('');
					}
				}}
				label={t('dataProtectionPolicy')}
				style={{ marginBottom: 0, marginTop: hsBottomMargin / 4 }}
				isDisabled={isJoinSpaceLoading}
			/>
		);
	};

	const isJoinSpaceDisabled = () => {
		const blockedByUserConsent =
			space?.customUserConsentText || space?.dataProtectionUrl || space?.readTermsUrl
				? !isTermsAgreed || !isTermsAcknowledged
				: false;
		const blockedByPrivacyPolicy = hasAcceptedPrivacyPolicy || PRESET_SPACEIDS ? false : !isPrivacyAgreed;

		return blockedByUserConsent || blockedByPrivacyPolicy;
	};

	const _renderMobileView = () => {
		return (
			<View>
				<SpaceHistoryListItem
					item={{ space: space, date: '' }}
					onItemPress={() => _joinSpace()}
					isLoading={false}
					isDisabled={isJoinSpaceLoading || hideButtons}
					isDescriptionVisible
				/>
				{_renderCustomUserConsentText()}
				{_renderButtons()}
			</View>
		);
	};

	const _renderButtons = (largerDesign?: boolean) => {
		return (
			<View>
				{!hideButtons && (
					<View style={{ paddingBottom: 20, flexDirection: 'row', justifyContent: 'center' }}>
						<RoundButton
							largerDesign={largerDesign}
							isFloatingButton
							testID="spacesummary_button_enterspace"
							title={t('Launch')}
							onPress={() => _joinSpace()}
							isDisabled={isJoinSpaceDisabled()}
							isLoading={isJoinSpaceLoading}
							icon={EDefaultIconSet.Rocket}
						/>
					</View>
				)}
				{showHsAdminButton && !hideButtons && (
					<RoundButton
						largerDesign={largerDesign}
						isFloatingButton
						testID="spacesummary_button_enterspace"
						title={t('HelloSpaces join, skip checks')}
						onPress={() => _joinSpace(true)}
						isDisabled={isJoinSpaceDisabled()}
						isLoading={isJoinSpaceLoading}
						icon={EDefaultIconSet.Rocket}
						alignSelf="center"
					/>
				)}
			</View>
		);
	};

	const _renderWebView = () => {
		const hasLeftSection = true;
		const hasRightSection = true;
		return (
			<View style={{ width: '100%', flexDirection: 'row' }}>
				<View
					key={`${TESTIDPREFIX}_sections_left`}
					testID={`${TESTIDPREFIX}_sections_left`}
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width - hsInnerPadding * 2)}
					style={{
						alignItems: 'center',
						width: hasRightSection ? '66%' : screenWidth,
						maxWidth: '66%',
						flexDirection: 'column',
						alignSelf: hasRightSection ? 'auto' : 'center'
					}}
				>
					<View style={{ width: containerWidth }}>
						<SpaceHistoryListItem
							imageOnly
							item={{ space: space, date: '' }}
							onItemPress={() => _joinSpace()}
							isLoading={false}
							isDisabled={isJoinSpaceLoading || hideButtons}
							isDescriptionVisible
						/>
					</View>
				</View>

				<View
					key={`${TESTIDPREFIX}_sections_right`}
					testID={`${TESTIDPREFIX}_sections_right`}
					style={{
						alignItems: 'center',
						width: hasLeftSection ? '33%' : screenWidth,
						flexDirection: 'column',
						alignSelf: hasLeftSection ? 'auto' : 'center'
					}}
				>
					<HSCard>
						<Text bold style={{ marginBottom: hsTextBottomMargin, fontSize: screenType === 'xl' ? 72 : 54 }}>
							{space?.title}
						</Text>
						<Text style={{ marginBottom: 5, fontSize: screenType === 'xl' ? 20 : 14 }}>
							{moment(space?.startDate).isValid()
								? moment(space?.startDate).format('DD.MM.YYYY') == moment(space?.endDate).format('DD.MM.YYYY')
									? `${moment(space?.startDate).format('DD.MM.YYYY')} | `
									: `${moment(space?.startDate).format('DD.MM.YYYY')} - ${moment(space?.endDate).format('DD.MM.YYYY')} | `
								: ''}
							{space?.spaceId}
						</Text>
						{space?.description && <Text style={{ fontSize: screenType === 'xl' ? 30 : 20 }}>{space?.description}</Text>}
					</HSCard>
					{_renderCustomUserConsentText(screenType === 'xl')}
					{_renderButtons(screenType === 'xl')}
				</View>
			</View>
		);
	};

	const _renderContent = () => {
		if (isTabletOrMobile || screenType === 'small') {
			return _renderMobileView();
		}
		return _renderWebView();
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderBackgroundImage()}
			<ScrollView
				testID={'spacesummary_scrollview'}
				ref={scrollRef}
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: !isTabletOrMobile ? '100%' : screenWidth,
					alignSelf: 'center',
					...contentContainerStyle
				}}
			>
				{_renderContent()}
			</ScrollView>
			<HSModal
				testID="spacesummary_userconsentmodal"
				isVisible={isTermsModalVisible}
				onClose={() => setIsTermsModalVisible(false)}
				title={`${!isEmptyString(space.title) ? `${space.title}\n` : ''}${t('User Consent')}`}
			>
				<ScrollView
					style={{ flex: 1, marginHorizontal: -hsInnerPadding }}
					contentContainerStyle={{ paddingHorizontal: hsInnerPadding }}
				>
					<Markdown markdown={space.customUserConsentText} />
				</ScrollView>
			</HSModal>
			<SupportRequestModal
				space={space}
				isVisible={isSupportRequestModalVisible}
				onClose={() => setIsSupportRequestModalVisible(false)}
			/>
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => _retryJoinSpace()}
				space={space}
				routeParams={route.params}
				isJoinSpaceLoading={isJoinSpaceLoading}
			/>
			<RequiredProfileFieldsModal
				isVisible={isRequiredProfileFieldsModalVisible}
				onClose={() => setIsRequiredFieldsModalVisible(false)}
				onSubmit={() => _retryJoinSpace()}
				space={space}
			/>
			<TicketModal
				isVisible={isTicketModalVisible}
				onClose={() => setIsTicketModalVisible(false)}
				onSubmit={() => _retryJoinSpace()}
				space={space}
				routeParams={route.params}
			/>
			<PinModal
				isLoading={isJoinSpaceLoading}
				isVisible={isPinModalVisible}
				value={spacePin}
				onChange={(val) => setSpacePin(val)}
				onClose={() => setIsPinModalVisible(false)}
				onSubmit={() => {
					setIsPinModalVisible(false);
					_joinSpace();
				}}
				routeParams={route.params}
			/>
		</View>
	);
};
