import { MarkdownExplanation } from 'components/Markdown';
import React from 'react';
import { HSModal } from '../Modal';

interface IMarkdownExplanationModal {
	isVisible: boolean;
	onClose: () => void;
}

export const MarkdownExplanationModal = (props: IMarkdownExplanationModal) => {
	const { isVisible, onClose } = props;

	return (
		<HSModal isVisible={isVisible} onClose={onClose}>
			<MarkdownExplanation />
		</HSModal>
	);
};
