import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { View } from 'react-native';
import { hsTopScreenPadding } from 'config/styleConstants';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { IMeetingTable } from 'config/interfaces';
import { EDefaultIconSet, IS_WEB } from 'helper';
import i18next from 'i18next';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { getMeetingTableExample } from 'helper/content';
import { MeetingTableList } from 'components/MeetingTable';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingTableList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingTableList>;
type RouteParams = StackParamList[ERoutes.MeetingTableList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'meetingtablelist';

export const MeetingTableListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const { theme } = useTheme();
	const { getContentTypeFields } = useContent('meetingtable');
	const { exportAsCSV, exportMeetingsAsCSV } = useExport();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [spaceMeetingTables, setSpaceMeetingTables] = useState<IMeetingTable[]>([]);

	const [isAddExampleLoading, setIsAddExampleLoading] = useState<boolean>(false);
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isExportMeetingsLoading, setIsExportMeetingsLoading] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);

	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const createMeetingTable = useRematchDispatch((dispatch: Dispatch) => dispatch.content.createMeetingTable);
	const loadMeetings = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadMeetings);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (activeSpace && content.meetingtables && isScreenFocused) {
			let _meetingTables: typeof spaceMeetingTables = [];

			_meetingTables = content.meetingtables.filter((e) => e.spaceId === activeSpace.spaceId);

			setSpaceMeetingTables(_meetingTables);
		}
	}, [activeSpace, content, isScreenFocused]);

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _delete = async (itemId?: number) => {
		const singleItem = spaceMeetingTables.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'meetingtable' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.MeetingTableCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'meetingtable' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: IMeetingTable[] = [];
				if (content.meetingtables) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.meetingtables.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.meetingtables];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'meetingtables');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'exportMeetings':
				setIsExportMeetingsLoading(true);
				const res = await loadMeetings({ meetingTableIds: selectedEntries });
				const transformed = res.map((e) => {
					const table = content.meetingtables?.find((e2) => e2.id === e.meetingTableId);
					return {
						id: e.meetingTableId,
						title: table?.title,
						subtitle: table?.subtitle,
						days: e.sections
					};
				});

				exportMeetingsAsCSV(transformed);
				setIsExportMeetingsLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'addExample':
				setIsAddExampleLoading(true);

				for (const example of getMeetingTableExample()) {
					await createMeetingTable({
						meetingtable: example,
						fields: getContentTypeFields(),
						noToast: true
					});
				}
				setIsAddExampleLoading(false);
				bottomSheetRef.current?.close();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.ExpoCreate, {
						spaceId: activeSpace?.spaceId,
						expoId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	return (
		<ScreenContainer isProtectedRoute contentKey="meetingtables">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<MeetingTableList
					isLoading={isDeleteLoading}
					testID={TESTIDPREFIX}
					selectedTables={selectedEntries}
					onSelectAll={(selected) => setSelectedEntries(selected)}
					onSelect={(itemId) => _handleSelect(itemId)}
					isEditMode
					onEdit={(itemId) => navigation.navigate(ERoutes.MeetingTableEdit, { spaceId: activeSpace?.spaceId, id: itemId })}
					onDelete={(itemId) => {
						setSelectedEntries([itemId]);
						_delete(itemId);
					}}
					onPress={(item) => _handleSelect(item.id)}
				/>
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading || isAddExampleLoading || isExportMeetingsLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							isDisabled={isAddExampleLoading}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0 || isAddExampleLoading || isExportMeetingsLoading}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_exportmeetings`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection Meetings').replace(
							'%COUNT%',
							selectedEntries.length > 0 ? ` (${selectedEntries.length})` : ''
						)}
						isLoading={isExportMeetingsLoading}
						isDisabled={selectedEntries.length === 0 || isAddExampleLoading}
						onPress={() => _handleActionSheetPress('exportMeetings')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0 || isAddExampleLoading || isExportMeetingsLoading}
						isLoading={isDeleteLoading}
						onPress={() => _handleActionSheetPress('delete')}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_addexample`}
						icon={EDefaultIconSet.HelloSpaces}
						label={t('addExample')}
						isDisabled={isDeleteLoading || isExportMeetingsLoading || isAddExampleLoading}
						isLoading={isAddExampleLoading}
						onPress={() => _handleActionSheetPress('addExample')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const MeetingTableListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	const profile = useSelector((store: IRootState) => store.auth.profile);

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Meeting Tables')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
