import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import { View, ViewStyle, Dimensions, BackHandler } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { canJoinSpace } from 'helper';
import { IS_ANDROID, IS_WEB } from 'helper/platform';
import * as Linking from 'expo-linking';
import { ILauncherSpace, IMedia } from 'config/interfaces';
import { KeyboardShift } from 'components/Keyboard/KeyboardShift';
import { showToast } from 'helper/toast';
import { useTranslation } from 'react-i18next';
import { ERoutes } from 'components/Navigation/routes';
import * as RootNavigation from '../../RootNavigation';
import { TContentTypePlural } from 'hooks/useContent';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { PRESET_SPACEIDS } from 'config/envConstants';
import Backgroundimage from 'components/Image/Backgroundimage';
import { Spinner } from 'components/Spinner';

interface IScreenContainerComponent {
	children?: ReactNode | ReactNode[];
	style?: ViewStyle;
	handleBackPress?: boolean;
	bgImage?: IMedia;
	bgImageName?: string;
	isProtectedRoute?: boolean;
	oldShift?: boolean;
	contentKey?: TContentTypePlural | 'allreferences' | 'administrate' | 'webhooks';
}

export enum EHorizontalScreenPadding {
	None = 0,
	Small = 5,
	Wide = 10
}

export const ScreenContainer = (props: IScreenContainerComponent) => {
	const { children, style, handleBackPress, bgImage, bgImageName, isProtectedRoute, oldShift, contentKey } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const navigation = useNavigation();
	const route = useRoute();
	const isScreenFocused = useIsFocused();
	const headerHeight = useHeaderHeight();
	const { trackPageView } = useTracker();

	const activeSpaceRef = useRef<ILauncherSpace | undefined>(undefined);

	const [screenHeight, setScreenHeight] = useState<number>(Dimensions.get('window').height);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const isMultiSpaceSocketConnected = useSelector((store: IRootState) => store.socket.isMultiSpaceSocketConnected);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const joinSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.joinSpace);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const getActiveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.getActiveSpace);
	const loadAttendeeDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeDelta);
	const loadChatMessagesDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.chat.loadChatMessagesDelta);
	const loadMyMeetingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.meeting.loadMyMeetingsDelta);
	const loadContentDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadContentDelta);
	const loadVoteDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteDelta);
	const loadMyVoteAnswers = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadMyVoteAnswers);
	const loadTickets = useRematchDispatch((dispatch: Dispatch) => dispatch.ticket.loadTickets);
	const loadMyBookings = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadMyBookings);
	const loadAllBookingsDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadAllBookingsDelta);
	const loadComments = useRematchDispatch((dispatch: Dispatch) => dispatch.comment.loadComments);
	const loadNetworkingRoomAttendees = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.loadNetworkingRoomAttendees);
	const loadLikes = useRematchDispatch((dispatch: Dispatch) => dispatch.like.loadLikes);
	const loadPushNotifications = useRematchDispatch((dispatch: Dispatch) => dispatch.pushNotification.loadPushNotifications);
	const loadAttendeeSupportRequests = useRematchDispatch((dispatch: Dispatch) => dispatch.attendee.loadAttendeeSupportRequests);
	const getSpaceSubscription = useRematchDispatch((dispatch: Dispatch) => dispatch.subscription.getSpaceSubscription);
	const loadUserInSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.loadUserInSpace);
	const loadChangelogDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadChangelogDelta);
	const loadWebhooksDelta = useRematchDispatch((dispatch: Dispatch) => dispatch.content.loadWebhooksDelta);
	const getAllBookingCounts = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.getAllBookingCounts);

	useEffect(() => {
		const linkingListener = Linking.addEventListener('url', _handleURL);

		if (IS_WEB) {
			window.addEventListener('resize', _handleResize);
		}

		return () => {
			linkingListener.remove();

			if (IS_WEB) {
				window.removeEventListener('resize', _handleResize);
			}
		};
	}, []);

	useEffect(() => {
		if (isScreenFocused) {
			const state = navigation.getState();
			if (state) {
				const routeState = state.routes[state.index];

				let page: string = routeState.name;

				if (routeState.params && (routeState.params['id'] || routeState.params['itemId'])) {
					page += `/${routeState.params['id'] ?? routeState.params['itemId']}`;
				}

				trackPageView(page);
			}
		}
	}, [isScreenFocused]);

	useEffect(() => {
		// if (isMultiSpaceSocketConnected && isScreenFocused) {
		// 	_loadData();
		// }

		if (isScreenFocused) {
			_loadData();
		}
	}, [isMultiSpaceSocketConnected, isScreenFocused]);

	useEffect(() => {
		activeSpaceRef.current = activeSpace;
	}, [activeSpace]);

	useEffect(() => {
		if (activeSpace && profile) {
			const isAdmin = activeSpace.admins?.find((e) => e.userId === profile.userId);
			const isMod = activeSpace.moderators?.find((e) => e.userId === profile.userId);

			if (isProtectedRoute && !isAdmin && !isMod) {
				_navigateAwayFromProtectedRoute(activeSpace.spaceId, false);
				return;
			}
		}
	}, [isProtectedRoute, activeSpace]);

	useEffect(() => {
		if (handleBackPress) {
			if (!IS_ANDROID) {
				return;
			}
			const backAction = () => {
				if (route?.params?.currentIndex === 5) {
					return false;
				} else if (!route?.params || !route?.params?.prohibitNavigation) {
					navigation.goBack();
				} else {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: async () => {
									navigation.goBack();
								}
							}
						]
					});
				}
				return true;
			};
			const backHandler = BackHandler.addEventListener('hardwareBackPress', () => backAction());

			return () => backHandler.remove();
		}
	}, [route?.params]);

	const _loadData = async () => {
		// Keep this timeout to avoid race condition with socket not being set yet
		await new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, 500);
		});

		// These types are needed for interactive features and badges
		// They will always be loaded
		contentLoadLog(
			1,
			'Should load interactive types (meetings, chats, schedule, schedulestatus, votings, support requests, push notifications)'
		);
		loadMyMeetingsDelta({});
		loadChatMessagesDelta({});
		loadContentDelta({ type: 'interactive' });
		loadVoteDelta({});
		loadAttendeeSupportRequests({});
		loadPushNotifications({});

		switch (contentKey) {
			case 'administrate':
				contentLoadLog(1, 'Should load all administrate things (support requests, push notifications, subscription, userinspace)');
				loadAttendeeSupportRequests({});
				loadPushNotifications({});
				getSpaceSubscription({});
				loadUserInSpace({});
				loadChangelogDelta({});
				loadWebhooksDelta({});
				break;
			case 'allreferences':
				contentLoadLog(1, 'Should load all references (expos,schedules,stages,maps,mediaitems,speakers,votings)');
				loadContentDelta({ type: 'references' });
				loadVoteDelta({});
				break;
			case 'attendees':
				contentLoadLog(1, 'Should load attendees (attendees,expos, tickets, speakers)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'attendees' });
				loadTickets({});
				loadUserInSpace({});
				loadAllBookingsDelta({});
				break;
			case 'bookings':
				contentLoadLog(1, 'Should load bookings (bookings, attendes,push notifications)');
				loadAllBookingsDelta({});
				loadAttendeeDelta({});
				loadPushNotifications({});
				break;
			case 'changelogs':
				contentLoadLog(1, 'Should load changelogs (attendees, content)');
				loadContentDelta({ type: 'changelogs' });
				loadChangelogDelta({});
				break;
			case 'expos':
				contentLoadLog(1, 'should load expos (expos, attendees, mediaitems, schedules, speakers, maps, mappositions)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'expos' });
				break;
			case 'chats':
				contentLoadLog(1, 'Should load chat (meetingtables, attendees)');
				loadAttendeeDelta({});
				break;
			case 'newsitems':
				contentLoadLog(1, 'Should load feed (newsitems, attendees,comments,likes)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'newsitems' });
				loadComments({});
				loadLikes({});
				break;
			case 'sendmail':
			case 'mailtemplates':
				contentLoadLog(1, 'Should load mail templates');
				loadContentDelta({ type: 'mailtemplates' });
				break;
			case 'maps':
				contentLoadLog(1, 'Should load maps (maps, mappositions, stages, meetingtables, expos)');
				loadContentDelta({ type: 'maps' });
				break;
			case 'mediaitems':
				contentLoadLog(1, 'Should load media items (mediaitems, speakers, expos )');
				loadContentDelta({ type: 'mediaitems' });
				break;
			case 'meetings':
				contentLoadLog(1, 'Should load meetings (attendees, meetingtables)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'meetings' });
				break;
			case 'meetingtables':
				contentLoadLog(1, 'Should load meeting tables (meetingtables)');
				loadContentDelta({ type: 'meetingtables' });
				break;
			case 'networkingrooms':
				contentLoadLog(1, 'Should load networkingrooms (networkingrooms, attendees,networkingroomattendees)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'networkingrooms' });
				loadNetworkingRoomAttendees({});
				loadLikes({});
				break;
			case 'pushnotifications':
				contentLoadLog(1, 'Should load push notifications (notifications, attendees)');
				loadContentDelta({ type: 'references' });
				loadPushNotifications({});
				break;
			case 'schedules':
				contentLoadLog(1, 'Should load schedules (schedules,expos, schedulestatus, stages, speakers, meetings, streams,votings)');
				loadContentDelta({ type: 'schedules' });
				loadVoteDelta({});
				loadMyBookings({});
				loadLikes({});
				getAllBookingCounts({});
				break;
			case 'speakers':
				contentLoadLog(1, 'Should load speakers (speakers,expos,schedules,attendees,mediaitems)');
				loadAttendeeDelta({});
				loadContentDelta({ type: 'speakers' });
				break;
			case 'stages':
				contentLoadLog(1, 'Should load stages (stages, schedules)');
				loadContentDelta({ type: 'stages' });
				break;
			case 'streams':
				contentLoadLog(1, 'Should load streams (streams, schedules, mediaitems)');
				loadContentDelta({ type: 'streams' });
				break;
			case 'tickets':
				contentLoadLog(1, 'Should load tickets (tickets, attendees, userinspace id)');
				loadAttendeeDelta({});
				loadTickets({});
				loadUserInSpace({});
				break;
			case 'votings':
				contentLoadLog(1, 'Should load votings (votings, schedules, myvoteanswers, attendees)');
				loadAttendeeDelta({});
				loadVoteDelta({});
				loadContentDelta({ type: 'votings' });
				loadMyVoteAnswers({});
				loadLikes({});
				break;
			case 'webhooks':
				loadWebhooksDelta({});
				break;
			default:
				break;
		}
	};

	const _isLoading = () => {
		if (waitingForSocketResponse) {
			const {
				attendees,
				attendeesupportrequests,
				bookings,
				chats,
				comments,
				expos,
				likes,
				mappositions,
				maps,
				mailtemplates,
				mailhistories,
				mediaitems,
				meetings,
				meetingtables,
				mybookings,
				myvoteanswers,
				networkingroomattendees,
				networkingrooms,
				newsitems,
				pushnotifications,
				schedules,
				schedulestatuses,
				speakers,
				stages,
				streams,
				subscription,
				tickets,
				userinspace,
				votings
			} = waitingForSocketResponse;
			switch (contentKey) {
				case 'administrate':
					return attendeesupportrequests || pushnotifications || subscription || userinspace || mailtemplates;
				case 'attendees':
					return attendees || speakers || expos;
				case 'attendeesupportrequests':
					return attendees || attendeesupportrequests;
				case 'bookings':
					return mybookings || pushnotifications || bookings;
				case 'chats':
					return attendees || chats || meetings || meetingtables;
				case 'expos':
					return expos || mediaitems || schedules || speakers || maps || mappositions || attendees;
				case 'mailtemplates':
					return mailtemplates || mailhistories;
				case 'mappositions':
				case 'maps':
					return maps || mappositions || stages || expos || meetingtables;
				case 'mediaitems':
					return expos || mediaitems || speakers;
				case 'meetings':
					return attendees || meetings || meetingtables;
				case 'meetingtables':
					return meetings || meetingtables;
				case 'networkingrooms':
					return attendees || networkingrooms || networkingroomattendees;
				case 'newsitems':
					return newsitems || attendees || comments || likes || expos || maps || mediaitems || schedules || speakers || stages;
				case 'pushnotifications':
					return pushnotifications || attendees || expos || maps || mediaitems || schedules || speakers || stages;
				case 'schedules':
					return schedules || schedulestatuses || meetings || stages || speakers || streams || expos || votings || mybookings;
				case 'speakers':
					return attendees || speakers || expos || mediaitems;
				case 'stages':
					return schedules || stages;
				case 'streams':
					return schedules || streams || mediaitems;
				case 'subscription':
					return subscription;
				case 'tickets':
					return attendees || tickets || userinspace;
				case 'votings':
					return votings || myvoteanswers || schedules;
				default:
					return false;
			}
		}

		return false;
	};

	const _handleResize = (e) => {
		const newHeight = Dimensions.get('window').height;
		if (screenHeight !== newHeight) {
			setScreenHeight(newHeight);
		}
	};

	const _handleURL = async ({ url }: { url: string }) => {
		const route = url.replace(/.*?:\/\//g, '');
		const urlParts = route.split('/');

		if (urlParts.includes(ERoutes.ExternalMaps)) {
			return;
		}

		const _activeSpace = getActiveSpace({});
		const newSpaceId =
			urlParts[0] === 'app'
				? urlParts[1]
				: urlParts[2] === 'editprofile'
				? urlParts[3] !== 'undefined'
					? urlParts[3]
					: undefined
				: urlParts[2];

		if (newSpaceId) {
			if (PRESET_SPACEIDS && !PRESET_SPACEIDS.includes(newSpaceId)) {
				return;
			}

			if (!_activeSpace || _activeSpace.spaceId !== newSpaceId) {
				if (
					navigation.getState()?.routes?.length > 0 &&
					navigation.getState()?.routes[navigation.getState()?.routes.length - 1] &&
					navigation.getState()?.routes[navigation.getState()?.routes.length - 1].name === newSpaceId
				) {
					return;
				}

				const isSpaceOnboarding = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Onboarding;
				const isSpaceCreation = (urlParts[0] === 'app' ? urlParts[1] : urlParts[2]).includes(ERoutes.SpaceCreate);
				const isPrestart = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Prestart;
				// const isLandingpage = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Landingpage;
				// const isExternalMaps = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.ExternalMaps;

				if ((PRESET_SPACEIDS && !PRESET_SPACEIDS.includes(newSpaceId)) || isSpaceOnboarding || isSpaceCreation || isPrestart) {
					return;
				}
				const space = await findSpaceById({ spaceId: newSpaceId, noToast: true });

				if (space?.id) {
					if (_activeSpace) {
						await leaveSpace({});
						_joinSpace(space);
					} else {
						_joinSpace(space);
					}
				}
			} else if (_activeSpace) {
				const isPrestart = (urlParts[0] === 'app' ? urlParts[2] : urlParts[3]) === ERoutes.Prestart;

				const { isAdmin, needsPin } = canJoinSpace(_activeSpace, profile);
				if (needsPin && !isAdmin && !isPrestart) {
					RootNavigation.replace(ERoutes.Prestart, { spaceId: _activeSpace.spaceId });
					return;
				}
			}
		}
	};

	const _navigateAwayFromProtectedRoute = async (spaceId, removedFromSpace) => {
		const space = await findSpaceById({ spaceId: spaceId, noToast: true });
		if (navigation.canGoBack()) {
			navigation.goBack();
		} else {
			await leaveSpace({});

			if (space?.id && !removedFromSpace) {
				RootNavigation.replace(ERoutes.SpaceSummary, { spaceId });
			}

			if (removedFromSpace) {
				RootNavigation.replace(ERoutes.SpaceSelect, {});
			}
		}
	};

	const _joinSpace = async (space: ILauncherSpace) => {
		const { isAdmin, isModerator, isDisabledBySuperAdmin, isDeactivated, needsRegistration, needsToFilloutProfileFields, needsPin } =
			canJoinSpace(space, profile);

		if (isDisabledBySuperAdmin) {
			showToast('error', undefined, isAdmin ? t('spaceIsDeactivatedAlertTitleAdmin') : t('spaceIsDeactivatedAlertTitle'));
			RootNavigation.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary);
			return;
		}

		if (isDeactivated && !(isAdmin || isModerator)) {
			showToast('error', undefined, t('spaceIsDeactivatedAlertTitle'));
			RootNavigation.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary);
			return;
		}

		if (needsRegistration) {
			// Passing the space id to automatically get to a space overview after register/login
			// RootNavigation.replace(ERoutes.SpaceAuth, { authState: 'login', spaceId: space.spaceId });
			// showToast('info', t('RegistrationRequired'), t('RegistrationRequiredErrorSubtitle'));
			return;
		}

		if (needsToFilloutProfileFields) {
			RootNavigation.replace(ERoutes.SpaceSummary, { spaceId: space.spaceId });
			return;
		}

		if (needsPin && !isAdmin) {
			RootNavigation.replace(ERoutes.Prestart, { spaceId: space.spaceId });
			return;
		}

		await joinSpace({ space });
	};

	const _renderLoading = () => {
		if (_isLoading()) {
			return (
				<View
					style={{
						position: 'absolute',
						zIndex: 999,
						alignSelf: 'center',
						top: 5,
						backgroundColor: theme.background,
						paddingHorizontal: 20,
						paddingVertical: 5,
						borderRadius: 999
					}}
				>
					<Spinner size="small" />
				</View>
			);
		}
	};

	return (
		<KeyboardShift oldShift={oldShift}>
			<Backgroundimage
				backgroundImage={activeSpace?.backgroundImage}
				bgImage={bgImage}
				bgImageName={bgImageName ?? activeSpace?.backgroundImageName}
			/>

			<View
				style={{
					...style,
					height: IS_WEB ? screenHeight - headerHeight : '100%',
					width: '100%'
				}}
			>
				<View
					style={{
						height: '100%',
						width: '100%',
						alignSelf: 'center'
					}}
				>
					{_renderLoading()}
					{children}
				</View>
			</View>
		</KeyboardShift>
	);
};
