import React, { ReactNode, useEffect, useState } from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderMenuButton,
	NavigationHeaderPickAttendeeButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import { ScrollView, View } from 'react-native';
import { hsBottomMargin, hsTopScreenPadding } from 'config/styleConstants';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { EMeetingStatus, IAttendee, IChatMessage, IFeatureInfo, IMeeting, ITabSwitcherButton } from 'config/interfaces';
import { HSCard } from 'components/Card';
import { EDefaultIconSet, getTopRadiusStyle, IS_WEB } from 'helper';
import { AuthenticationModal, PickAttendeeModal } from 'components/Modal';
import { NoData } from 'components/NoData';
import { RoundButton } from 'components/Button';
import { MeetingRequestMessage } from 'components/Chat';
import moment from 'moment';
import { MeetingListHeader } from 'components/Meeting';
import { TABBAR_HEIGHT } from 'config/constants';
import { H2 } from 'components/Text';
import { TabView } from 'components/TabView';
import { FlashList, FlashSectionList } from 'components/List';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.MeetingList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.MeetingList>;
type RouteParams = StackParamList[ERoutes.MeetingList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface ISection {
	title: string;
	data: {
		message: IChatMessage;
		partner?: IAttendee;
	}[];
}

const TESTIDPREFIX = 'meetinglist';

export const MeetingListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();
	const isScreenFocused = useIsFocused();

	const [tabs, setTabs] = useState<ITabSwitcherButton[]>([]);
	const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
	const [todaysMeetings, setTodaysMeetings] = useState<ISection['data']>([]);
	const [upcomingMeetings, setUpcomingMeetings] = useState<(string | ISection['data'])[]>([]);
	const [meetingHistory, setMeetingHistory] = useState<(string | ISection['data'])[]>([]);
	const [sections, setSections] = useState<ISection[]>([]);

	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [isPickAttendeeModalVisible, setIsPickAttendeeModalVisible] = useState<boolean>(false);

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const chatMessages = useSelector((store: IRootState) => store.chat.chatMessages);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => setIsPickAttendeeModalVisible(!isPickAttendeeModalVisible)
		});
	}, [isPickAttendeeModalVisible]);

	useEffect(() => {
		let _feature: IFeatureInfo | undefined = undefined;

		if (activeSpace?.features && route.params.key) {
			_feature = activeSpace.features.list.find((e) => e.key === route.params.key);
			navigation.setOptions({
				title: _feature?.label ?? t('Meetings')
			});
		}
	}, [route, activeSpace]);

	useEffect(() => {
		if (activeSpace && meetings && profile && chatMessages && isScreenFocused) {
			const _sections: typeof sections = [];
			let _myMeetings: IMeeting[] = [];

			_myMeetings = meetings.filter((e) => e.spaceId === activeSpace.spaceId && e.status !== EMeetingStatus.Declined);

			_myMeetings.forEach((meeting) => {
				const meetingDate = moment(meeting.timeSlot.start).format('DD.MM.YYYY');
				const partnerId = meeting.ownerId === profile.userId ? meeting.partnerId : meeting.ownerId;
				const partner = attendees.find((e) => e.userId === partnerId);
				const message = chatMessages.find((e) => e.target && Number(e.target) === meeting.id);

				if (message) {
					const found = _sections.find((e) => e.title === meetingDate);
					if (found) {
						found.data.push({
							message,
							partner
						});
					} else {
						_sections.push({
							title: meetingDate,
							data: [
								{
									message,
									partner
								}
							]
						});
					}
				}
			});

			_sections.sort((a, b) => {
				const aVal = moment(a.title, 'DD.MM.YYYY');
				const bVal = moment(b.title, 'DD.MM.YYYY');

				return aVal.isAfter(bVal) ? 1 : -1;
			});

			setSections(_sections);
		}
	}, [activeSpace, meetings, attendees, profile, chatMessages, isScreenFocused]);

	useEffect(() => {
		let _todaysMeetings: typeof todaysMeetings = [];
		let _upcomingMeetings: typeof upcomingMeetings = [];
		let _meetingHistory: typeof meetingHistory = [];

		if (sections) {
			sections.forEach((section) => {
				if (section.data && moment(section.title, 'DD.MM.YYYY').format('DD.MM.YYYY') === moment().format('DD.MM.YYYY')) {
					_todaysMeetings = [...section.data];
				} else if (section.data && moment(section.title, 'DD.MM.YYYY').isAfter(moment())) {
					_upcomingMeetings.push(section.title);
					_upcomingMeetings = _upcomingMeetings.concat(section.data);
				} else {
					_meetingHistory.push(section.title);
					_meetingHistory = _meetingHistory.concat(section.data);
				}
			});
		}

		let _tabs: typeof tabs = [{ key: 'meetings', label: t('meetings') }];

		if (_meetingHistory.length > 0) {
			_tabs.push({ key: 'history', label: t('MeetingHistory') });
		}

		if (_tabs.length >= activeTabIndex || !_tabs[activeTabIndex]) {
			setActiveTabIndex(0);
		}

		setTodaysMeetings(_todaysMeetings);
		setUpcomingMeetings(_upcomingMeetings);
		setMeetingHistory(_meetingHistory);

		setTabs(_tabs);
	}, [sections]);

	const _renderCurrentMeetings = () => {
		const elements: ReactNode[] = [];

		if (todaysMeetings.length > 0) {
			elements.push(
				<HSCard
					style={{ paddingBottom: hsBottomMargin / 2, ...getTopRadiusStyle(0), borderTopWidth: 0 }}
					key={`${TESTIDPREFIX}_today`}
				>
					{upcomingMeetings.length > 0 && (
						<H2 center style={{ paddingTop: 5 }}>
							{t('TodaysMeetings')}
						</H2>
					)}
					<FlashList
						data={todaysMeetings}
						renderItem={(renderItem) => (
							<MeetingRequestMessage
								item={renderItem.item.message}
								partner={renderItem.item.partner}
								showChatButton
								fullWidth
							/>
						)}
						estimatedItemSize={160}
					/>
				</HSCard>
			);
		}

		if (upcomingMeetings.length > 0) {
			elements.push(
				<HSCard
					style={
						todaysMeetings.length === 0
							? { paddingBottom: hsBottomMargin / 2, ...getTopRadiusStyle(0), borderTopWidth: 0 }
							: { paddingBottom: hsBottomMargin / 2 }
					}
					key={`${TESTIDPREFIX}_upcoming`}
				>
					{todaysMeetings.length > 0 && (
						<H2 center style={{ paddingTop: 5 }}>
							{t('UpcomingMeetings')}
						</H2>
					)}
					<FlashSectionList
						data={upcomingMeetings}
						renderItem={(renderItem) => (
							<MeetingRequestMessage
								item={renderItem.item.message}
								partner={renderItem.item.partner}
								showChatButton
								fullWidth
							/>
						)}
						renderSectionHeader={(renderItem) => <MeetingListHeader text={renderItem.item} />}
						hideIndexButtons
						estimatedItemSize={160}
					/>
				</HSCard>
			);
		}

		if (elements.length > 0) {
			return elements;
		}

		return (
			<NoData type="NoMeetings" noRoundedTop>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_meeting`}
					onPress={() => setIsPickAttendeeModalVisible(true)}
					icon={EDefaultIconSet.Add}
					title={t('FindAttendee')}
					isStacked
				/>
			</NoData>
		);
	};

	const _renderMeetingHistory = () => {
		if (meetingHistory.length > 0) {
			return (
				<HSCard
					style={{ paddingBottom: hsBottomMargin / 2, ...getTopRadiusStyle(0), borderTopWidth: 0 }}
					key={`${TESTIDPREFIX}_history`}
				>
					<FlashSectionList
						data={meetingHistory}
						renderItem={(renderItem) => (
							<MeetingRequestMessage
								item={renderItem.item.message}
								partner={renderItem.item.partner}
								showChatButton
								fullWidth
								isOver
							/>
						)}
						renderSectionHeader={(renderItem) => <MeetingListHeader text={renderItem.item} />}
						estimatedItemSize={160}
						hideIndexButtons
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderMeetings = () => {
		switch (tabs[activeTabIndex].key) {
			case 'meetings':
				return <>{_renderCurrentMeetings()}</>;
			case 'history':
				return <>{_renderMeetingHistory()}</>;
			default:
				return null;
		}
	};

	const _renderOverview = () => {
		if (profile) {
			if (activeSpace?.forceDoubleOptInForUsers && !profile.isUserConfirmed) {
				return (
					<NoData type="NotConfirmed">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_authenticate`}
							onPress={() => setIsAuthenticationModalVisible(true)}
							icon={EDefaultIconSet.User}
							title={t('Login/Register')}
							isStacked
						/>
					</NoData>
				);
			}

			if (!profile.allowMeetingRequests) {
				return <NoData type="AllowMeetings" />;
			}

			if (!profile.allowChats) {
				return <NoData type="AllowChats" />;
			}

			if (profile.hideMyProfile) {
				return <NoData type="ProfileHidden" />;
			}

			if (sections.length === 0) {
				return (
					<NoData type="NoMeetings">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_meeting`}
							onPress={() => setIsPickAttendeeModalVisible(true)}
							icon={EDefaultIconSet.Add}
							title={t('FindAttendee')}
							isStacked
						/>
					</NoData>
				);
			}

			return (
				<View style={{ width: '100%' }}>
					<TabView
						testIdPrefix={TESTIDPREFIX}
						items={tabs}
						activeKey={tabs[activeTabIndex]?.key}
						onPress={(key) => {
							let index = tabs
								.map((e) => {
									return e.key;
								})
								.indexOf(key);
							setActiveTabIndex(index);
						}}
						isDisabled={tabs.length < 1}
					/>
					{_renderMeetings()}
				</View>
			);
		}

		return (
			<NoData type="NoProfile">
				<RoundButton
					testID={`${TESTIDPREFIX}_button_authenticate`}
					onPress={() => setIsAuthenticationModalVisible(true)}
					icon={EDefaultIconSet.Add}
					title={t('Login/Register')}
					isStacked
				/>
			</NoData>
		);
	};

	const _renderContent = () => {
		return (
			<ScrollView
				style={{ width: '100%' }}
				contentContainerStyle={{
					width: screenWidth,
					alignItems: 'center',
					alignSelf: 'center',
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide
				}}
			>
				{_renderOverview()}
			</ScrollView>
		);
	};

	return (
		<ScreenContainer handleBackPress contentKey="meetings">
			<View
				style={{
					flex: 1,
					width: '100%',
					alignItems: 'center',
					marginBottom: IS_WEB && isTabbarVisible ? TABBAR_HEIGHT : 0
				}}
			>
				{_renderContent()}
			</View>
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
			<PickAttendeeModal
				isVisible={isPickAttendeeModalVisible}
				onPress={(item) => {
					navigation.navigate(ERoutes.MeetingRequest, {
						spaceId: activeSpace?.spaceId,
						partnerId: item.userId
					});

					setIsPickAttendeeModalVisible(false);
				}}
				onClose={() => setIsPickAttendeeModalVisible(false)}
				filter="meeting"
			/>
		</ScreenContainer>
	);
};

export const MeetingListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderPickAttendeeButton options={props.options} />
		</NavigationHeader>
	);
};

export const MeetingListScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={props.options.title} />
			<NavigationHeaderPickAttendeeButton options={props.options} />
		</NavigationHeader>
	);
};
