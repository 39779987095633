import { Button } from 'components/Button';
import { HsZoomProxyUrl, MULTISPACEURL } from 'config/constants';
import { DEFAULT_MULTISPACE_URL } from 'config/envConstants';
import { IMedia } from 'config/interfaces';
import { hsBottomMargin } from 'config/styleConstants';
import { isEmptyString, openURL } from 'helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';

interface IHsZoomClient {
	zoomMeetingUrl?: string;
	zoomMeetingId?: string;
	zoomMeetingPassword?: string;
	previewImage?: IMedia;
	containerWidth: number;
}

const TESTIDPREFIX = 'zoomclient';

export const HsZoomClient = (props: IHsZoomClient) => {
	const { zoomMeetingUrl, zoomMeetingId, zoomMeetingPassword, previewImage, containerWidth } = props;
	const { t } = useTranslation();

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const jwt = useSelector((store: IRootState) => store.auth.jwt);
	const [serverUrl, setServerUrl] = useState<string>('');

	const _meetingId = zoomMeetingId?.replace(/\s/g, '');

	useEffect(() => {
		if (isEmptyString(zoomMeetingUrl) || isEmptyString(zoomMeetingId) || isEmptyString(zoomMeetingPassword)) {
			return;
		}
		const _serverUrl = `${HsZoomProxyUrl}/?eventcode=demo&meetingId=${_meetingId}&password=${encodeURIComponent(
			zoomMeetingPassword ?? ''
		)}&email=${encodeURIComponent(profile?.email)}&displayName=${
			profile ? encodeURIComponent(`${profile?.firstName} ${profile?.lastName}`) : 'Unknown'
		}&itemId=${profile?.userId.toString()}&token=${jwt}&previewImage=${
			previewImage ? encodeURIComponent(previewImage.formats?.large?.url ?? '') : ''
		}&joinText=${t('JoinNow')}&signature=${MULTISPACEURL}/zoom/getsignature`;

		if (_serverUrl !== serverUrl) {
			setServerUrl(_serverUrl);
		}
	}, [HsZoomProxyUrl, _meetingId, zoomMeetingPassword, profile, previewImage, serverUrl]);

	if (isEmptyString(serverUrl) || containerWidth < 2) {
		return null;
	}

	return (
		<View>
			<View style={{ marginBottom: hsBottomMargin }}>
				<iframe
					width={containerWidth < 600 ? '600px' : `${containerWidth}px`}
					height={`${(containerWidth / 16) * 9}px`}
					style={{ width: containerWidth, height: (containerWidth / 16) * 9, minHeight: 600 }}
					allow="camera;microphone"
					className="h-full w-full"
					title="Zoom"
					src={serverUrl}
					allowFullScreen
				/>
			</View>
			<View
				style={{
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'center',
					alignItems: 'center',
					alignSelf: 'center',
					marginBottom: hsBottomMargin / 2
				}}
			>
				<Button
					testID={`${TESTIDPREFIX}_button_openinnewtab`}
					title={t('openMeetingInNewTab')}
					onPress={() => openURL(serverUrl)}
					style={{ alignSelf: 'center', marginHorizontal: hsBottomMargin / 2, marginBottom: hsBottomMargin / 2 }}
				/>
				<Button
					testID={`${TESTIDPREFIX}_button_openinzoomclient`}
					title={t('openMeetingInZoomClient')}
					onPress={() => openURL(zoomMeetingUrl ?? '')}
					style={{ alignSelf: 'center', marginHorizontal: hsBottomMargin / 2, marginBottom: hsBottomMargin / 2 }}
				/>
			</View>
		</View>
	);
};
