import {
	IAttendee,
	IContentTypeField,
	ILauncherSpace,
	IMeeting,
	IReport,
	IScannedUser,
	ISchedule,
	IUserBooking,
	TNewsReferenceType
} from 'config/interfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'rematch/store';
import moment from 'moment';
import Papa from 'papaparse';
import { exportFile } from 'helper/export';
import { useTranslation } from 'react-i18next';
import { getExportDataForVotingType, getSelfServiceUrl, IS_WEB } from 'helper';
import { useSpace } from './useSpace';
import { showToast } from 'helper/toast';

export const useExport = () => {
	const { t } = useTranslation();
	const { activeSpace } = useSpace();

	const votes = useSelector((store: IRootState) => store.vote.votes);
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);
	const allBookings = useSelector((store: IRootState) => store.booking.allBookings);
	const content = useSelector((store: IRootState) => store.content.content);

	const exportAllBookingsAsCSV = async () => {
		if (activeSpace && profile && content.schedules) {
			const _items: any[] = [];

			const bookable = content.schedules.filter((e) => e.isBookable);

			bookable.forEach((entry) => {
				const bookings = allBookings.filter((e) => e.itemId === entry.id && e.spaceId === activeSpace.spaceId);
				if (bookings.length > 0) {
					const title = `${entry.title} (${moment(entry.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(entry.endDate).format(
						'HH:mm'
					)}, ID: ${entry.id}) `;

					bookings.forEach((booking) => {
						const attendee = attendees.find((e) => e.userId === booking.userId);
						if (attendee) {
							_items.push({
								ID: entry.id,
								[t('title')]: entry.title,
								[t('Start')]: moment(entry.startDate).format('DD.MM.YYYY HH:mm'),
								[t('End')]: moment(entry.endDate).format('DD.MM.YYYY HH:mm'),
								[t('Stage')]: entry.stage?.title ?? '',
								userId: attendee.userId,
								[t('firstName')]: attendee.firstName,
								[t('lastName')]: attendee.lastName,
								[t('email')]: attendee.email,
								[t('jobTitle')]: attendee.jobTitle,
								[t('company')]: attendee.company,
								[t('ticketcode')]:
									attendee.tickets && attendee.tickets[activeSpace.spaceId]
										? attendee.tickets[activeSpace.spaceId].ticketcode
										: ''
							});
						} else {
							_items.push({
								userId: booking.userId,
								title: title
							});
						}
					});
				}
			});

			const columns = [
				'ID',
				t('Title'),
				t('Start'),
				t('End'),
				t('Stage'),
				t('firstName'),
				t('lastName'),
				t('email'),
				t('jobTitle'),
				t('company'),
				'userId'
			];

			if (activeSpace.ticketSettings?.ticketRequired) {
				columns.push(t('ticketcode'));
			}

			const csv = Papa.unparse(_items, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const fileName = `${activeSpace.spaceId}_alluserbookings-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t('Bookings'));
		}
	};
	const exportBookingsAsCSV = async (bookings: IUserBooking[], item: ISchedule) => {
		if (activeSpace && profile) {
			const _items: any[] = [];
			bookings.forEach((booking) => {
				const attendee = attendees.find((e) => e.userId === booking.userId);
				if (attendee) {
					_items.push({
						ID: attendee.userId,
						[t('firstName')]: attendee.firstName,
						[t('lastName')]: attendee.lastName,
						[t('email')]: attendee.email,
						[t('jobTitle')]: attendee.jobTitle,
						[t('company')]: attendee.company,
						[t('ticketcode')]:
							attendee.tickets && attendee.tickets[activeSpace.spaceId]
								? attendee.tickets[activeSpace.spaceId].ticketcode
								: ''
					});
				} else {
					_items.push({
						ID: booking.userId
					});
				}
			});

			const columns = ['ID', t('firstName'), t('lastName'), t('email'), t('jobTitle'), t('company')];

			if (activeSpace.ticketSettings?.ticketRequired) {
				columns.push(t('ticketcode'));
			}

			const csv = Papa.unparse(_items, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const title = item.title.replace(/\s/g, '-');

			const fileName = `${activeSpace.spaceId}_userbookings_${title}-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t('Bookings'));
		}
	};

	const exportScannedAttendeesAsCSV = async (scannedAttendees: IScannedUser[]) => {
		if (activeSpace && profile) {
			const _items: any[] = [];
			scannedAttendees.forEach((scannedAttendee) => {
				const attendee = attendees.find((e) => e.userId === scannedAttendee.userId);
				if (attendee) {
					_items.push({
						ID: attendee.userId,
						[t('firstName')]: attendee.firstName,
						[t('lastName')]: attendee.lastName,
						[t('email')]: attendee.email,
						[t('jobTitle')]: attendee.jobTitle,
						[t('company')]: attendee.company,
						[t('note')]: scannedAttendee.userNote
					});
				} else {
					_items.push({
						ID: scannedAttendee.userId
					});
				}
			});

			const columns = ['ID', t('firstName'), t('lastName'), t('email'), t('jobTitle'), t('company'), t('note')];

			const csv = Papa.unparse(_items, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const fileName = `${activeSpace.spaceId}_scannedAttendees-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t('scannedUser'));
		}
	};

	const exportVoteResultAsCSV = async (voteId: number) => {
		if (activeSpace && profile) {
			const vote = votes?.find((e) => e.id === voteId);
			if (!vote) {
				return;
			}

			let answersForThis = voteAnswers?.filter((e) => !e.isDeleted && (e.voteId === voteId || e.voteId === vote.id));

			const { columns, data } = getExportDataForVotingType(vote.votingType, vote, votes, answersForThis, attendees, activeSpace);

			if (!data || data.length === 0 || !columns || columns.length === 0) {
				showToast('error', t('NoExportPossible'), t('NoVotingAnswersFound'));
				return;
			}

			const csv = Papa.unparse(data, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const title = vote.question.replace(/\s/g, '-');

			const fileName = `${activeSpace.spaceId}_voteresult_${title}_${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t('result'));
		}
	};

	const exportAsCSV = async (fields: IContentTypeField[], items: any[], type: string) => {
		let _fields: typeof fields = fields;

		if (items.length === 0 || !profile || !activeSpace) {
			return;
		}

		let _items = items.map((row) => {
			const obj = { ...row };

			switch (type) {
				case 'attendees':
					delete obj.spaceHistory;
					delete obj.userSpaceHistory;
					delete obj.visitors;
					delete obj.unavailable;
					delete obj.fieldSettings;
					delete obj.pushSettings;

					if (obj.tickets && obj.tickets[activeSpace.spaceId]) {
						obj.tickets = obj.tickets[activeSpace.spaceId].ticketcode;
					} else {
						obj.tickets = '';
					}
					break;
				case 'schedules':
					delete obj.status;
					delete obj.speakerGroups;
					break;
				case 'feed':
					let _itemReference;
					switch (obj.referencetype as TNewsReferenceType) {
						case 'expos':
							_itemReference = 'expo';
							break;
						case 'externalUrl':
							break;
						case 'features':
							_itemReference = 'feature';
							break;
						case 'mappositions':
							_itemReference = 'mapposition';
							break;
						case 'maps':
							_itemReference = 'map';
							break;
						case 'mediaitems':
							_itemReference = 'mediaitem';
							break;
						case 'networkingrooms':
							_itemReference = 'networkingroom';
							break;
						case 'schedules':
							_itemReference = 'schedule';
							break;
						case 'speakers':
							_itemReference = 'speaker';
							break;
						case 'stages':
							_itemReference = 'stage';
							break;
						case 'votings':
							_itemReference = 'vote';
							break;
						default:
							break;
					}
					if (_itemReference && obj[_itemReference]) {
						obj[_itemReference] = obj[_itemReference]?.id;
					}
					break;
				default:
					break;
			}

			_fields.forEach((field) => {
				if (!obj[field.fieldName]) {
					obj[field.fieldName] = null;
				}

				switch (field.fieldType) {
					case 'avatar':
						if (type === 'attendee') {
							if (obj.smallImageUrl || obj.imageUrl) {
								obj[field.fieldName] = obj.smallImageUrl ?? obj.imageUrl;
							}
						}

						break;
					case 'boolean':
						obj[field.fieldName] = obj[field.fieldName] ? 'true' : 'false';
						break;
					case 'component':
						if (obj[field.fieldName]) {
							obj[field.fieldName] = JSON.stringify(obj[field.fieldName]);
						}
						break;
					case 'dateRange':
						if (obj[field.fieldName]) {
							obj[field.fieldName] = moment(obj[field.fieldName]).format('DD.MM.YYYY HH:mm');
						}
						if (field.fieldName2) {
							obj[field.fieldName2] = obj[field.fieldName2] ? moment(obj[field.fieldName2]).format('DD.MM.YYYY HH:mm') : null;
						}

						break;
					case 'image':
					case 'logo':
					case 'video':
					case 'media':
						if (obj[field.fieldName] && obj[field.fieldName].url) {
							obj[field.fieldName] = obj[field.fieldName].url;
						}
						break;
					case 'stream':
					case 'reference':
						if (field.referenceType === 'votings') {
							obj[`${field.fieldName}_questions`] = '';
						} else {
							obj[`${field.fieldName}_titles`] = '';
						}
						if (obj[field.fieldName]) {
							if (Array.isArray(obj[field.fieldName])) {
								const filtered = obj[field.fieldName].filter((e) => !e.isDeleted);
								if (field.referenceType === 'votings') {
									obj[`${field.fieldName}_questions`] = filtered.map((e) => e.question ?? e.key).join(', ');
								} else {
									obj[`${field.fieldName}_titles`] = filtered.map((e) => e.title ?? e.key).join(', ');
								}
								obj[field.fieldName] = filtered.map((e) => e.id).join(', ');
							}
						}
						break;
					case 'singlereference':
						if (field.referenceType === 'votings') {
							obj[`${field.fieldName}_question`] = '';
						} else {
							obj[`${field.fieldName}_title`] = '';
						}
						if (obj[field.fieldName] && !obj[field.fieldName].isDeleted) {
							if (field.referenceType === 'votings') {
								obj[`${field.fieldName}_question`] = obj[field.fieldName].question ?? '';
							} else if (field.referenceType === 'attendees') {
								obj[`${field.fieldName}_title`] = `${obj[field.fieldName].firstName} ${obj[field.fieldName].lastName}`;
							} else {
								obj[`${field.fieldName}_title`] = obj[field.fieldName].title ?? '';
							}
							obj[field.fieldName] = obj[field.fieldName].id;
						}
						break;
					case 'number':
					case 'multiswitch':
					case 'markdown':
					case 'email':
					case 'color':
					case 'category':
					case 'string':
					case 'title':
					case 'subtitle':
					case 'contactEmail':
					case 'location':
					case 'socialmedia':
						if (obj[field.fieldName]) {
							obj[field.fieldName] = obj[field.fieldName].toString();
						}
						break;
					default:
						break;
				}
			});

			if (obj.selfServiceToken) {
				obj.selfServiceLink = getSelfServiceUrl(obj.selfServiceToken);
			}

			return obj;
		});

		const columns: string[] = [];

		Object.keys(_items[0]).forEach((key) => {
			if (key !== 'id') {
				columns.push(key);
			}
		});

		columns.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
		columns.unshift('id');

		const csv = Papa.unparse(_items, {
			quotes: true,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ';',
			header: true,
			newline: '\r\n',
			columns
		});

		const file = new Blob(['\uFEFF' + csv], {
			type: 'text/plain;charset=utf-8;'
		});

		const fileName = `${activeSpace.spaceId}_${type}-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

		await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t(type));
	};

	const exportMeetingsAsCSV = async (
		tables: { id: number; title?: string; subtitle?: string; days: { header: string; items: IMeeting[] }[] }[]
	) => {
		if (activeSpace && tables.length > 0 && profile) {
			const _items: any[] = [];
			tables.forEach((table) => {
				table.days.forEach((day) => {
					if (tables.length > 0) {
						_items.push({
							ID: `${table.title ?? ''} (ID: ${table.id})`,
							[t('Start')]: table.subtitle ?? ''
						});
					}
					day.items.forEach((meeting) => {
						const user1 = attendees.find((e) => e.userId === meeting.ownerId);
						const user2 = attendees.find((e) => e.userId === meeting.partnerId);

						_items.push({
							ID: meeting.id,
							[t('Start')]: moment(meeting.timeSlot.start).format('DD.MM.YYYY HH:mm'),
							[t('End')]: moment(meeting.timeSlot.end).format('DD.MM.YYYY HH:mm'),
							['User 1 - ID']: meeting.ownerId,
							['User 1']: user1 ? `${user1.firstName} ${user1.lastName}` : '',
							['User 1 - Email']: user1?.email ?? '',
							['User 2 - ID']: meeting.partnerId,
							['User 2']: user2 ? `${user2.firstName} ${user2.lastName} ` : '',
							['User 2  - Email']: user2?.email ?? ''
						});
					});
				});
			});
			const columns = [
				'ID',
				t('Start'),
				t('End'),
				'User 1 - ID',
				'User 1',
				'User 1 - Email',
				'User 2 - ID',
				'User 2',
				'User 2  - Email'
			];

			const csv = Papa.unparse(_items, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const fileName = `${activeSpace.spaceId}_meetings-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, 'meetings');
		}
	};

	const exportReport = async (item: IReport, title: string) => {
		if (activeSpace && attendees && profile?.email) {
			let columns: string[] = [];
			const _items: any[] = [];

			if (item.type === 'space') {
				columns = [t('Date'), t('AttendeeCount')];

				if (item.data?.timeSlots) {
					item.data.timeSlots.forEach((slot) => {
						_items.push({
							[t('Date')]: moment(slot.date).format('DD.MM.YYYY HH:mm'),
							[t('AttendeeCount')]: slot.userIds.length
						});
					});
				}
			} else {
				if (item.data?.userIds) {
					item.data.userIds
						.sort((a, b) => {
							return moment(a.date).isBefore(moment(b.date)) ? -1 : 1;
						})
						.forEach((user) => {
							let attendee = attendees.find((e) => e.userId === user.userId);

							_items.push({
								[t('Date')]: moment(user.date).format('DD.MM.YYYY HH:mm'),
								ID: attendee?.userId ?? t('Anonymous'),
								[t('firstName')]: attendee?.firstName,
								[t('lastName')]: attendee?.lastName,
								[t('email')]: attendee?.email,
								[t('jobTitle')]: attendee?.jobTitle,
								[t('company')]: attendee?.company
							});
						});
				}

				columns = [t('Date'), 'ID', t('firstName'), t('lastName'), t('email'), t('jobTitle'), t('company')];
			}

			const csv = Papa.unparse(_items, {
				quotes: true,
				quoteChar: '"',
				escapeChar: '"',
				delimiter: ';',
				header: true,
				newline: '\r\n',
				columns
			});

			const file = new Blob(['\uFEFF' + csv], {
				type: 'text/plain;charset=utf-8;'
			});

			const _title = title.replace(/\s/g, '-');

			const fileName = `${activeSpace.spaceId}_report_${_title}-${moment(item.date).format('YYYY-MM-DD-HH-mm')}.csv`;

			await exportFile(IS_WEB ? file : csv, fileName, profile.email, activeSpace.title, t('Reports'));
		}
	};

	const exportSelfServiceReports = async (reports: IReport[], space: ILauncherSpace, attendees: IAttendee[], email: string) => {
		const _items: any[] = [];

		reports.forEach((report) => {
			report.data?.userIds.forEach((userObj) => {
				const attendee = attendees.find((e) => e.userId === userObj.userId);

				_items.push({
					[t('Date')]: moment(userObj.date).format('DD.MM.YYYY HH:mm'),
					Action: report.action,
					ID: userObj.userId ?? t('Anonymous'),
					[t('firstName')]: attendee?.firstName ?? '',
					[t('lastName')]: attendee?.lastName ?? '',
					[t('email')]: attendee?.email ?? '',
					[t('jobTitle')]: attendee?.jobTitle ?? '',
					[t('company')]: attendee?.company ?? ''
				});
			});
		});

		_items.sort((a, b) => (moment(a[t('Date')], 'DD.MM.YYYY HH:mm').isAfter(moment(b[t('Date')], 'DD.MM.YYYY HH:mm')) ? -1 : 1));

		const columns = [t('Date'), 'Action', 'ID', t('firstName'), t('lastName'), t('email'), t('jobTitle'), t('company')];

		const csv = Papa.unparse(_items, {
			quotes: true,
			quoteChar: '"',
			escapeChar: '"',
			delimiter: ';',
			header: true,
			newline: '\r\n',
			columns
		});

		const file = new Blob(['\uFEFF' + csv], {
			type: 'text/plain;charset=utf-8;'
		});

		const fileName = `${space.spaceId}_reports_-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;

		await exportFile(IS_WEB ? file : csv, fileName, email, space.title, t('Reports'));
	};

	return {
		exportAsCSV,
		exportAllBookingsAsCSV,
		exportBookingsAsCSV,
		exportScannedAttendeesAsCSV,
		exportMeetingsAsCSV,
		exportReport,
		exportSelfServiceReports,
		exportVoteResultAsCSV
	};
};
