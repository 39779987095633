import { createModel } from '@rematch/core';
import { MULTISPACEURL } from 'config/constants';
import { translateStrapiError } from 'helper';
import { showToast } from 'helper/toast';
import { ILoadReportsPayload, ITrackActionPayload } from 'rematch/interfaces';
import { RootModel } from './index';

type IDefaultState = {};

export const tracking = createModel<RootModel>()({
	state: {} as IDefaultState,
	reducers: {},
	effects: (dispatch) => ({
		async trackAction(payload: ITrackActionPayload, store) {
			try {
				const { trackingObject, space } = payload;

				const body = new FormData();
				body.append(
					'data',
					JSON.stringify({
						trackingObject,
						userInfos: store.auth.userInfos
					})
				);

				await dispatch.request.anonymousRequest({
					method: 'POST',
					url: `${MULTISPACEURL}/analytics`,
					body
				});
			} catch (error) {
				console.log('trackAction', error);
			}
		},
		async loadReports(payload: ILoadReportsPayload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				const mySpace = payload.space ?? activeSpace;

				if (mySpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							...payload,
							spaceId: mySpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/reports/loadreports`,
						body
					});

					if (res && Array.isArray(res)) {
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return [];
			} catch (error) {
				console.log('loadReports', error);
				return [];
			}
		},
		async loadStatistics(payload, store) {
			try {
				const activeSpace = dispatch.temp.getActiveSpace({});

				if (activeSpace) {
					const body = new FormData();
					body.append(
						'data',
						JSON.stringify({
							spaceId: activeSpace.spaceId,
							userInfos: store.auth.userInfos
						})
					);

					const res = await dispatch.request.anonymousRequest({
						method: 'POST',
						url: `${MULTISPACEURL}/reports/loadstatistics`,
						body
					});

					if (res && res.favorites) {
						return res;
					}

					showToast('error', 'Error', translateStrapiError(res));
				}

				return undefined;
			} catch (error) {
				console.log('loadStatistics', error);
				return undefined;
			}
		}
	})
});
