import { hsTextBottomMargin } from 'config/styleConstants';
import { isEmptyString } from 'helper';
import { TFunctionResult } from 'i18next';
import React from 'react';
import { TextStyle, TextProps } from 'react-native';

import { Text } from './Text';

interface ISubtitle {
	children?: string | TFunctionResult;
	style?: TextStyle;
	center?: boolean;
	largerText?: boolean;
	italic?: boolean;
}
export const Subtitle = (props: ISubtitle & TextProps) => {
	const { children, style, largerText, italic } = props;

	if (!isEmptyString(children)) {
		return (
			<Text {...props} italic={italic} style={[{ fontSize: largerText ? 26 : 14, marginBottom: hsTextBottomMargin }, style]}>
				{children}
			</Text>
		);
	}

	return null;
};
