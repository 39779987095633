import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { SpaceHistoryListItem } from './SpaceHistoryListItem';
import { useQuery } from 'hooks/useQuery';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { ISpaceHistory } from 'rematch/interfaces';
import { PRESET_SPACEIDS } from 'config/envConstants';
import moment from 'moment';
import { getCardContainerStyle } from 'helper';
import { CardSeparationHeader } from 'components/Text';

interface ISpaceHistoryListComponent {
	onItemPress: (spaceId: string) => void;
	isLoading?: string;
	label: string;
	list?: ISpaceHistory[];
	disableScrolling?: boolean;
	noHeader?: boolean;
	onDeletePress?: (item) => void;
	sortBy?: 'date' | 'title' | 'visited';
}

export const SpaceHistoryList = (props: ISpaceHistoryListComponent) => {
	const { onItemPress, isLoading, list, disableScrolling, noHeader, onDeletePress, label, sortBy } = props;
	const { screenColumnCount } = useQuery();

	const [items, setItems] = useState<ISpaceHistory[]>([]);

	const spaceHistory = useSelector((store: IRootState) => store.space.spaceHistory);

	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const netInfoState = useSelector((store: IRootState) => store.temp.netInfoState);

	useEffect(() => {
		if (netInfoState?.isConnected) {
			if (PRESET_SPACEIDS) {
				_load();
			}
		}
	}, [netInfoState]);

	useEffect(() => {
		if (!PRESET_SPACEIDS) {
			let _items: typeof items = [];

			if (list && list.length > 0) {
				_items = list;
			} else {
				_items = spaceHistory;
			}

			if (_items.length > 0) {
				switch (sortBy) {
					case 'date':
						_items = _items.sort((a, b) => {
							const aDate = a.space.startDate;
							const bDate = b.space.startDate;

							if (moment(aDate).isSame(moment(bDate))) {
								const aTitle = a.space.title.toLowerCase();
								const bTitle = b.space.title.toLowerCase();

								return aTitle < bTitle ? -1 : 1;
							}

							return moment(aDate).isBefore(moment(bDate)) ? -1 : 1;
						});

						_items = orderByEndDate(_items);
						break;
					case 'title':
						_items = _items.sort((a, b) => {
							const aTitle = a.space.title.toLowerCase();
							const bTitle = b.space.title.toLowerCase();

							if (aTitle === bTitle) {
								const aDate = a.space.startDate;
								const bDate = b.space.startDate;

								return moment(aDate).isBefore(moment(bDate)) ? -1 : 1;
							}

							return aTitle < bTitle ? -1 : 1;
						});
						break;
					case 'visited':
						_items = _items.sort((a, b) => {
							const aDate = a.date;
							const bDate = b.date;

							return moment(aDate).isAfter(moment(bDate)) ? -1 : 1;
						});
					default:
						break;
				}
			}

			setItems(_items);
		}
	}, [list, spaceHistory, sortBy]);

	const orderByEndDate = (items: ISpaceHistory[]) => {
		// move past spaces to end of the list
		let _sortedItems: typeof items = [];

		items
			.filter((i) => moment(i.space.endDate).isSameOrAfter(moment()))
			.forEach((item) => {
				_sortedItems.push(item);
			});

		items
			.filter((i) => moment(i.space.endDate).isBefore(moment()))
			.forEach((item) => {
				_sortedItems.push(item);
			});

		return _sortedItems;
	};

	const _load = async () => {
		let _items: typeof items = [];

		if (PRESET_SPACEIDS) {
			for (const spaceId of PRESET_SPACEIDS) {
				const found = spaceHistory.find((e) => e.space.spaceId === spaceId);
				if (found) {
					_items.push(found);
				} else {
					const res = await findSpaceById({ spaceId, noToast: true });
					if (res?.spaceId) {
						_items.push({
							space: res,
							date: moment().toISOString()
						});
					}
				}
			}
		}

		setItems(_items);
	};

	if (items.length > 0) {
		return (
			<View
				style={{
					marginLeft: -EHorizontalScreenPadding.Wide,
					marginRight: -EHorizontalScreenPadding.Wide
				}}
			>
				{screenColumnCount && (
					<FlatList
						contentContainerStyle={{
							paddingHorizontal: EHorizontalScreenPadding.Wide
						}}
						numColumns={screenColumnCount}
						data={items}
						scrollEnabled={!disableScrolling}
						ListHeaderComponent={() => {
							if (noHeader) {
								return null;
							}
							return <CardSeparationHeader label={label} />;
						}}
						renderItem={({ item, index }) => (
							<SpaceHistoryListItem
								item={item}
								onItemPress={() => onItemPress(item.space.spaceId)}
								isLoading={isLoading === item.space.spaceId}
								containerStyle={{
									...getCardContainerStyle(screenColumnCount, index, EHorizontalScreenPadding.Wide),
									maxWidth: `${100 / screenColumnCount}%`,
									flex: undefined
								}}
								onDeletePress={!PRESET_SPACEIDS && onDeletePress ? () => onDeletePress(item) : undefined}
							/>
						)}
						keyExtractor={(item) => `spacehistory_${item.space.id}`}
					/>
				)}
			</View>
		);
	}

	return null;
};
