import React from 'react';
import { ICreatePushNotificationFormErrors, ICreatePushNotificationFormValues, TPushNotificationReceiver } from 'config/interfaces';
import { useTranslation } from 'react-i18next';
import { FormDatePicker, FormReferenceSelect, FormMultiSwitch, FormTextInput, FormMediaPicker, FormCheckbox } from 'components/Form';
import moment from 'moment';
import { HSCard } from 'components/Card';
import { Dropdown } from 'components/Form/Dropdown';
import { FormLabel } from 'components/Form/FormLabel';
import { FormHint } from 'components/Form/FormHint';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet } from 'helper';
import { PushSendingInformation } from './PushSendingInformation';
import { FormMarkdownEditor } from 'components/Form/FormMarkdownEditor';
import * as ImagePicker from 'expo-image-picker';
import { useSpace } from 'hooks/useSpace';
interface ICreateNotificationForm {
	values: ICreatePushNotificationFormValues;
	errors: ICreatePushNotificationFormErrors;
	onChange: (val: ICreatePushNotificationFormValues) => void;
	isDisabled?: boolean;
	onError?: (fieldName: string, error: string | undefined) => void;
}

const TESTIDPREFIX = 'createnotificationform';

export const CreateNotificationForm = (props: ICreateNotificationForm) => {
	const { values, errors, onChange, isDisabled, onError } = props;
	const { t } = useTranslation();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	return (
		<HSCard>
			<FormMultiSwitch
				testID={`${TESTIDPREFIX}_multiswitch_receiver`}
				label={t('Receivers')}
				isRequired
				hint={t('PushNotificationReceiverHint')}
				isDisabled={isDisabled}
				value={values.receiver?.toString()}
				onChange={(receiver) => onChange({ ...values, receiver: receiver as TPushNotificationReceiver })}
				error={errors.receiver}
				options={[
					{
						key: 'all',
						label: t('All')
					},
					{
						key: 'web',
						label: t('PushReceiverWebOnly')
					},
					{
						key: 'app',
						label: t('PushReceiverAppOnly')
					}
				]}
			/>
			<FormCheckbox
				testID={`${TESTIDPREFIX}_checkbox_isactive`}
				label={t('ActivateNotification')}
				hint={t('ActivateNotificationHint')}
				value={values.isActive}
				onPress={() => onChange({ ...values, isActive: !values.isActive })}
			/>
			<FormTextInput
				testID={`${TESTIDPREFIX}_textinput_title`}
				label={t('Title')}
				hint={t('PushNotificationTitleHint')}
				value={values.title}
				error={errors.title}
				isDisabled={isDisabled}
				onChangeText={(title) => onChange({ ...values, title })}
			/>
			{values.receiver !== 'web' ? (
				<FormTextInput
					testID={`${TESTIDPREFIX}_textinput_subtitle`}
					label="Body"
					isRequired
					hint={t('PushNotificationBodyHint')}
					value={values.subtitle}
					error={errors.subtitle}
					isDisabled={isDisabled}
					onChangeText={(subtitle) => onChange({ ...values, subtitle })}
					maxInputLength={255}
				/>
			) : null}
			{values.receiver !== 'app' ? (
				<FormMarkdownEditor
					testID={`${TESTIDPREFIX}_markdown_websubtitle`}
					label="Web Body"
					isRequired
					hint={t('WebPushNotificationBodyHint')}
					value={values.webSubtitle}
					error={errors.webSubtitle}
					isDisabled={isDisabled}
					onChange={(webSubtitle) => onChange({ ...values, webSubtitle })}
				/>
			) : null}
			<FormMediaPicker
				isDisabled={isDisabled}
				isOutline
				label={t('Image')}
				testID={`${TESTIDPREFIX}_imagepicker_image`}
				value={values.image}
				hasDeleteButton={values.image !== null && values.image !== undefined}
				mediaTypes={ImagePicker.MediaTypeOptions.Images}
				onChange={(image) => onChange({ ...values, image })}
				onDelete={() => onChange({ ...values, image: null })}
				deleteTitle={t('Delete Image')}
				deleteSubTitle={t('deleteImageSubtitle')}
				allowMediaLibraryPicking={iAmSpaceAdmin || iAmSpaceModerator}
			/>
			<FormDatePicker
				testID={`${TESTIDPREFIX}_datepicker_sendon`}
				label={t('Send On')}
				hint={t('PushNotificationSendOnHint')}
				value={values.sendOn}
				error={errors.sendOn}
				onChange={(val) => onChange({ ...values, sendOn: val })}
				mode="datetime"
				isDisabled={isDisabled}
				minimumDate={moment().toDate()}
				onError={(error) => {
					if (onError) {
						onError('sendOn', error);
					}
				}}
			/>
			<FormLabel testID={`${TESTIDPREFIX}_label_deeplink`} label="Deeplink" style={{ marginBottom: 5 }} />
			<FormHint testID={`${TESTIDPREFIX}_hint_deeplink`} hint={t('DeeplinkHint')} />
			<Dropdown
				testID={`${TESTIDPREFIX}_dropdown_deeplinktype`}
				value={values.deeplinkType}
				isDisabled={isDisabled}
				onSelect={(deeplinkType) => onChange({ ...values, deeplinkType, deeplinkItemId: undefined })}
				placeholder={t('DeeplinkSelectType')}
				options={[
					{
						key: 'schedules',
						label: t('Agenda')
					},
					{
						key: 'speakers',
						label: t('Speaker')
					},
					{
						key: 'expos',
						label: t('Expo')
					},
					{
						key: 'mediaitems',
						label: t('Media Items')
					},
					{
						key: 'votings',
						label: 'Voting'
					},
					{
						key: 'newsitems',
						label: t('feed')
					}
				].sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))}
				formStyle={{ marginBottom: 0 }}
			/>
			{values.deeplinkType && (
				<FormReferenceSelect
					testID={`${TESTIDPREFIX}_referenceselect_deeplinkitem`}
					value={values.deeplinkItemId}
					type={values.deeplinkType}
					isDisabled={isDisabled}
					onSelect={(deeplinkItemId) => onChange({ ...values, deeplinkItemId })}
					formStyle={{ marginBottom: 0 }}
				/>
			)}
			{(values.deeplinkType || values.deeplinkItemId) && (
				<RoundButton
					testID={`${TESTIDPREFIX}_button_removedeeplink`}
					icon={EDefaultIconSet.Delete}
					size="sm"
					title={t('RemoveDeeplink')}
					alignSelf="flex-end"
					onPress={() => onChange({ ...values, deeplinkType: undefined, deeplinkItemId: undefined })}
				/>
			)}
			<PushSendingInformation TESTIDPREFIX={TESTIDPREFIX} informations={values.sendingInformation} />
		</HSCard>
	);
};
