import React, { useEffect, useState } from 'react';
import { FlatList, NativeScrollEvent, NativeSyntheticEvent, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'rematch/store';
import { useSelector } from 'react-redux';
import { filterListByFeature, isEmptyString, sortListByFeature } from 'helper';
import { NoData } from 'components/NoData';
import { FormCheckbox } from 'components/Form/FormCheckbox';
import { IActiveScreenFilter, IExpo, IFeatureInfo, IMediaItem } from 'config/interfaces';
import { MediaItemListItem } from './MediaItemListItem';
import { useMatching } from 'hooks/useMatching';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { hsBottomMargin } from 'config/styleConstants';
import { SponsorBlock } from 'screens/Space/Sponsors';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { HSCard } from 'components/Card';
import { Placeholder, PlaceholderLine, PlaceholderMedia, ShineOverlay } from 'rn-placeholder';
import { useIsFocused } from '@react-navigation/native';

interface IMediaItemList {
	testID: string;
	selectedMediaItems?: number[];
	onSelectAll?: (selected: number[]) => void;
	onSelect?: (itemId: number) => void;
	onEdit?: (itemId: number) => void;
	onDelete?: (itemId: number) => void;
	onPress?: (item: IMediaItem) => void;
	onPin?: (item: IMediaItem) => void;
	isLoading?: boolean;
	hasPadding?: boolean;
	searchTerm: string;
	activeScreenFilter?: IActiveScreenFilter;
	activeModalFilters?: Record<string, string>;
	onScroll: (e: NativeSyntheticEvent<NativeScrollEvent>) => void;
	onContentSizeChange: (e: { width: number; height: number }) => void;
	feature?: IFeatureInfo;
}

export const MediaItemList = (props: IMediaItemList) => {
	const {
		testID,
		selectedMediaItems,
		onSelectAll,
		onSelect,
		onEdit,
		onDelete,
		onPin,
		onPress,
		isLoading,
		hasPadding,
		searchTerm,
		activeScreenFilter,
		activeModalFilters,
		onScroll,
		onContentSizeChange,
		feature
	} = props;
	const { t } = useTranslation();
	const { screenColumnCount } = useQuery();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { getMatchCount } = useMatching();
	const isScreenFocused = useIsFocused();

	const [spaceMediaItems, setSpaceMediaItems] = useState<IMediaItem[]>([]);
	const [filteredMediaItems, setFilteredMediaItems] = useState<IMediaItem[]>([]);
	const [searchedMediaItems, setSearchedMediaItems] = useState<IMediaItem[]>([]);

	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);
	const content = useSelector((store: IRootState) => store.content.content);
	const isTabbarVisible = useSelector((store: IRootState) => store.temp.isTabbarVisible);

	useEffect(() => {
		if (activeSpace && content.mediaitems && isScreenFocused) {
			let _mediaItems: typeof spaceMediaItems = [];

			_mediaItems = content.mediaitems.filter(
				(e) => e.spaceId === activeSpace.spaceId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
			);

			_mediaItems = _mediaItems.map((m) => {
				const expos: IExpo[] = [];
				m.expos?.map((e) => {
					const found = content?.expos?.find((expo) => expo.id === e.id && !expo.isDeleted);
					if (found) {
						expos.push(found);
					}
				});
				return { ...m, expos };
			});

			setSpaceMediaItems(_mediaItems);
		}
	}, [activeSpace, content, iAmSpaceAdmin, iAmSpaceModerator, isScreenFocused]);

	useEffect(() => {
		let _filtered: typeof filteredMediaItems = [...spaceMediaItems];
		_filtered = filterListByFeature(_filtered, feature);

		if (activeScreenFilter) {
			if (activeScreenFilter.filters.includes('all')) {
				// Nothing to do
			} else if (activeScreenFilter.filters.includes('matchings')) {
				_filtered = _filtered.filter((e) => getMatchCount('mediaitem', e) > 0);
			} else {
				_filtered = _filtered.filter((e) => {
					if (activeScreenFilter.fieldName) {
						for (const catItemKey of activeScreenFilter.filters) {
							if (e[activeScreenFilter.fieldName]) {
								const itemSplit = e[activeScreenFilter.fieldName].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}

						return false;
					}

					return true;
				});
			}
		}

		if (activeModalFilters) {
			if (Object.keys(activeModalFilters).length > 0) {
				_filtered = _filtered.filter((e) => {
					const catKeys = Object.keys(activeModalFilters);
					for (const catKey of catKeys) {
						const splitted = activeModalFilters[catKey].split(',');
						for (const catItemKey of splitted) {
							if (e[catKey]) {
								const itemSplit = e[catKey].split(',');
								if (itemSplit.includes(catItemKey)) {
									return true;
								}
							}
						}
					}

					return false;
				});
			}
		}
		_filtered = sortListByFeature(_filtered, feature);

		setFilteredMediaItems(_filtered);
	}, [activeSpace, feature, spaceMediaItems, activeModalFilters, activeScreenFilter]);

	useEffect(() => {
		let _searched: typeof filteredMediaItems = [...filteredMediaItems];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter(
				(item) =>
					item.title.toLowerCase().includes(lowerSearch) ||
					item.subtitle?.toLowerCase().includes(lowerSearch) ||
					item.description?.toLowerCase().includes(lowerSearch) ||
					(item.expos?.filter((e) => e.title?.toLowerCase().includes(lowerSearch) ?? '').length ?? 0) > 0
			);
		}

		const pinned = _searched.filter((e) => e.isPinned);
		const notPinned = _searched.filter((e) => !e.isPinned);

		setSearchedMediaItems(pinned.concat(notPinned));
	}, [filteredMediaItems, searchTerm]);

	const _renderSelectAll = () => {
		if (onSelectAll && searchedMediaItems.length > 0) {
			return (
				<HSCard>
					<FormCheckbox
						testID={`${testID}_checkbox_selectall`}
						label={t('SelectAll')}
						style={{ marginBottom: 0 }}
						value={selectedMediaItems && selectedMediaItems.length === searchedMediaItems.length}
						onPress={() => {
							if (selectedMediaItems && selectedMediaItems.length === searchedMediaItems.length) {
								onSelectAll([]);
							} else {
								onSelectAll(searchedMediaItems.map((sp) => sp.id));
							}
						}}
					/>
				</HSCard>
			);
		}

		return null;
	};

	const _renderEmptyMediaEntry = () => {
		return (
			<HSCard style={{ height: 270 }}>
				<Placeholder Animation={ShineOverlay}>
					<PlaceholderMedia style={{ width: '100%', height: 150, marginBottom: hsBottomMargin }} />
					<PlaceholderLine style={{ marginBottom: hsBottomMargin }} />
					<PlaceholderLine style={{ marginBottom: hsBottomMargin }} />
				</Placeholder>
			</HSCard>
		);
	};

	const _renderEmptyData = () => {
		return (
			<View>
				{_renderEmptyMediaEntry()}
				{_renderEmptyMediaEntry()}
				{_renderEmptyMediaEntry()}
			</View>
		);
	};

	const _renderList = () => {
		if (searchedMediaItems.length === 0) {
			if (
				!isEmptyString(searchTerm) ||
				!activeScreenFilter?.filters?.includes('all') ||
				(activeModalFilters && Object.keys(activeModalFilters).length > 0)
			) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			if (waitingForSocketResponse?.mediaitems) {
				return _renderEmptyData();
			}

			return <NoData type="NoMediaItems" />;
		}

		return (
			<View
				style={{
					flex: 1
				}}
			>
				{_renderSelectAll()}
				{screenColumnCount && (
					<View
						style={{
							flex: 1,
							marginBottom: activeSpace?.sponsorTypeMediaItem?.startsWith('sponsor')
								? EHorizontalScreenPadding.Wide
								: isTabbarVisible
								? 0
								: hsBottomMargin,
							marginHorizontal: hasPadding ? -EHorizontalScreenPadding.Wide : 0
						}}
					>
						<FlatList
							data={searchedMediaItems}
							renderItem={(renderItem) => (
								<View
									key={`${testID}_mediaitem_${renderItem.item.id}`}
									style={{
										width: `${100 / screenColumnCount}%`,
										flexDirection: 'row'
									}}
								>
									<MediaItemListItem
										item={renderItem.item}
										isSelected={selectedMediaItems?.includes(renderItem.item.id)}
										onPress={onPress ? () => onPress(renderItem.item) : undefined}
										onSelect={onSelect ? () => onSelect(renderItem.item.id) : undefined}
										onEdit={onEdit ? () => onEdit(renderItem.item.id) : undefined}
										onDelete={onDelete ? () => onDelete(renderItem.item.id) : undefined}
										onPin={onPin ? () => onPin(renderItem.item) : undefined}
										isLoading={isLoading}
										containerStyle={{
											paddingHorizontal: hasPadding ? EHorizontalScreenPadding.Wide : 0
										}}
										testID={`${testID}_mediaitem_${renderItem.item.id}`}
									/>
								</View>
							)}
							numColumns={screenColumnCount}
							onScroll={onScroll}
							onContentSizeChange={(width, height) => {
								if (onContentSizeChange) {
									onContentSizeChange({
										width,
										height
									});
								}
							}}
						/>
					</View>
				)}
			</View>
		);
	};

	return (
		<View style={{ flex: 1 }}>
			{_renderList()}
			<View style={{ width: '100%', alignSelf: 'center' }}>
				<SponsorBlock contentType={'mediaitem'} />
			</View>
		</View>
	);
};
