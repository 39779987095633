import { IVoteOptionVote } from 'components/Forms/Vote/VoteQuiz';
import { IMediaGalleryItem, IVoteOption } from 'config/interfaces';
import { TAllowAnonymousAnswers, TVotingType } from 'config/interfaces/vote';
import { v4 } from 'uuid';

export interface IEditVoteFormValues {
	publicId?: string;
	question?: string;
	hint?: string;
	media?: IMediaGalleryItem[];
	isActive?: boolean;
	votingType?: TVotingType;
	viewType?: string;
	minValue?: number;
	maxValue?: number;
	openFrom?: string | null | undefined;
	openUntil?: string | null | undefined;
	allowAnonymousAnswers?: TAllowAnonymousAnswers;
	options?: IVoteOption[];
	isModerated?: boolean;
	optionsVote?: IVoteOptionVote[];
	allowMedia?: boolean;
	schedules?: number[];
	allowMultipleAnswers?: boolean;
	externalUrl?: string;
	hideResults?: boolean;
	minValueText?: string;
	maxValueText?: string;
	useAvatar?: boolean;
	iconColor?: string;
	requestReason?: boolean;
	requestReasonText?: string;
	requestReasonOptions?: string;
	networkingrooms?: number[];
	updated_at?: string;
}

export interface IEditVoteFormErrors {
	publicId?: string;
	title?: string;
	question?: string;
	hint?: string;
	votingType?: string;
	viewType?: string;
	minValue?: string;
	maxValue?: string;
	media?: string;
	openFrom?: string;
	openUntil?: string;
	allowAnonymousAnswers?: string;
	options?: string;
	isActive?: string;
	isModerated?: string;
	allowMedia?: string;
	schedules?: string;
	allowMultipleAnswers?: string;
	externalUrl?: string;
	hideResults?: string;
	minValueText?: string;
	maxValueText?: string;
	useAvatar?: string;
	iconColor?: string;
	requestReasonText?: string;
	networkingrooms?: string;
}

export const EditVoteInitialFormValues: IEditVoteFormValues = {
	publicId: v4(),
	title: undefined,
	question: '',
	votingType: undefined,
	allowAnonymousAnswers: 'never',
	media: [],
	isModerated: false,
	allowMedia: false,
	allowMultipleAnswers: false
};
