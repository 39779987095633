import React from 'react';
import { ChildButton } from 'components/Button';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import { View } from 'react-native';
import { useTheme } from 'hooks/useTheme';
import { Spinner } from 'components/Spinner';

interface IBottomSheetViewButton {
	testID: string;
	icon: string;
	label: string;
	onPress: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	iconColor?: string;
}

export const BOTTOMSHEETVIEWBUTTONHEIGHT = 35;

export const BottomSheetViewButton = (props: IBottomSheetViewButton) => {
	const { testID, icon, label, onPress, isDisabled, isLoading, iconColor } = props;
	const { theme } = useTheme();

	return (
		<ChildButton
			testID={testID}
			onPress={onPress}
			isDisabled={isDisabled}
			style={{
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				height: BOTTOMSHEETVIEWBUTTONHEIGHT,
				paddingHorizontal: 10
			}}
		>
			{isLoading ? (
				<Spinner color={theme.primary} />
			) : (
				<Icon name={icon} color={isDisabled ? theme.gray : iconColor ?? theme.navigationTextColor ?? theme.primary} size={25} />
			)}
			<View style={{ flex: 1, paddingLeft: 10 }}>
				<Text style={{ color: isDisabled ? theme.gray : theme.navigationTextColor ?? theme.text }}>{label}</Text>
			</View>
		</ChildButton>
	);
};
