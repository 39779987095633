import { IContentTypeField } from 'config/interfaces';
import i18next from 'i18next';

export const getDefaultMediaItemfields = () => {
	const arr: IContentTypeField[] = [
		{
			fieldName: 'section1',
			fieldLabel: i18next.t('General Informations'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'id',
			fieldLabel: 'ID',
			fieldType: 'number',
			isDefaultField: true,
			visibility: 'never'
		},
		{
			fieldName: 'title',
			fieldLabel: i18next.t('title'),
			fieldType: 'string',
			isDefaultField: true,
			isRequired: true,
			visibility: 'visible',
			showOnDetailScreen: true,
			maxLength: 255
		},
		{
			fieldName: 'subtitle',
			fieldLabel: i18next.t('subtitle'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'isHidden',
			fieldLabel: i18next.t('isHidden'),
			fieldHint: i18next.t('isHiddenHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible'
		},
		{
			fieldName: 'isPinned',
			fieldLabel: i18next.t('isPinned'),
			fieldHint: i18next.t('mediaItemIsPinnedHint'),
			fieldType: 'boolean',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: false,
			selfServiceVisibility: 'never'
		},
		{
			fieldName: 'description',
			fieldLabel: i18next.t('Description'),
			fieldType: 'markdown',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'section2',
			fieldLabel: i18next.t('Media'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'previewImage',
			fieldLabel: i18next.t('previewImage'),
			fieldHint: i18next.t('mediaItemPreviewImageHint'),
			fieldType: 'image',
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'mediaselection',
			fieldLabel: i18next.t('mediaselection'),
			fieldType: 'mediaselection',
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'mediaType',
			fieldLabel: i18next.t('mediaType'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'mediaTitle',
			fieldLabel: i18next.t('mediaTitle'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'vimeoUrl',
			fieldLabel: i18next.t('vimeoUrl'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'youTubeUrl',
			fieldLabel: i18next.t('youTubeUrl'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'iFrameUrl',
			fieldLabel: i18next.t('iFrameUrl'),
			fieldType: 'string',
			isDefaultField: true,
			visibility: 'never',
			showOnDetailScreen: false
		},
		{
			fieldName: 'section4',
			fieldLabel: i18next.t('References'),
			fieldType: 'section',
			isCustomField: true,
			visibility: 'visible',
			selfServiceVisibility: 'always'
		},
		{
			fieldName: 'speakers',
			fieldLabel: i18next.t('Speakers'),
			fieldType: 'reference',
			referenceType: 'speakers',
			placeholder: i18next.t('PlaceholderSpeakers'),
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'expos',
			fieldLabel: i18next.t('Expos'),
			fieldType: 'reference',
			referenceType: 'expos',
			placeholder: i18next.t('PlaceholderExpos'),
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		},
		{
			fieldName: 'streams',
			fieldLabel: i18next.t('Streams'),
			fieldType: 'reference',
			referenceType: 'streams',
			placeholder: i18next.t('PlaceholderStreams'),
			isDefaultField: true,
			visibility: 'visible',
			showOnDetailScreen: true
		}
	];

	return arr;
};
