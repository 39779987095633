import { IWebhookHistoryItem } from 'config/interfaces';
import { View } from 'react-native';
import { Text } from 'components/Text';
import { ChildButton } from 'components/Button';
import moment from 'moment';
import { useTheme } from 'hooks/useTheme';
import { InfoPill } from 'components/InfoPill';

interface IProps {
	testID: string;
	item: IWebhookHistoryItem;
	onPress: () => void;
}

export const WebhookHistoryListItem = (props: IProps) => {
	const { testID, item, onPress } = props;
	const { theme } = useTheme();

	const _renderStatus = () => {
		let backgroundColor = theme.success;
		let textColor = theme.text;

		if (item.status !== 200) {
			backgroundColor = theme.danger;
			textColor = theme.white;
		}

		return (
			<InfoPill
				text={item.status.toString()}
				containerStyle={{ marginRight: 5 }}
				backgroundColor={backgroundColor}
				borderColor={backgroundColor}
				textColor={textColor}
			/>
		);
	};

	return (
		<ChildButton
			testID={testID}
			style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingVertical: 10 }}
			onPress={onPress}
		>
			<View style={{ flexDirection: 'row' }}>
				{_renderStatus()}
				<Text>{item.body.event}</Text>
			</View>
			<Text>{moment(item.date).format('HH:mm:ss')}</Text>
		</ChildButton>
	);
};
