import React from 'react';
import { View } from 'react-native';

import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';

export const EXPOLISTHEADERHEIGHT = 20;

interface IExpoListHeader {
	text: string;
}

export const ExpoListHeader = (props: IExpoListHeader) => {
	const { text } = props;
	const { theme } = useTheme();

	return (
		<View
			style={{
				height: EXPOLISTHEADERHEIGHT,
				borderBottomWidth: 1,
				borderColor: theme.text,
				backgroundColor: theme.contentBackgroundColor ?? theme.background
			}}
		>
			<Text bold>{text}</Text>
		</View>
	);
};
