import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { hsTopScreenPadding } from 'config/styleConstants';
import { FlatList, ScrollView, View } from 'react-native';

import i18next from 'i18next';
import { IAttendee, ISchedule, IStage, IUserBooking } from 'config/interfaces';
import { useSelector } from 'react-redux';
import { HSCard } from 'components/Card';
import { EDefaultIconSet, isEmptyString, validateForm } from 'helper';
import { H1, H2, Subtitle, Text } from 'components/Text';
import { Button } from 'components/Button';
import * as DocumentPicker from 'expo-document-picker';
import { showToast } from 'helper/toast';
import { BookingAttendeeListItem } from 'components/Booking';
import * as Yup from 'yup';
import { NoData } from 'components/NoData';
import { getEmailError } from 'config/yupSchemas/errors';
import { AttendeeListItem } from 'components/Attendee';
import moment from 'moment';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.BookingImport>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.BookingImport>;
type RouteParams = StackParamList[ERoutes.BookingImport];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'bookinglist';

export interface IBookingEntry {
	item: ISchedule;
	bookings: IUserBooking[];
}

interface IErrorLine {
	line: number;
	error: string;
}

const emailFields = ['E-Mail', 'e-mail', 'Email', 'email', 'Mail', 'mail'];

export const BookingImportScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { screenWidth } = useQuery();
	const { activeSpace } = useSpace();

	const [item, setItem] = useState<ISchedule | undefined>(undefined);
	const [stage, setStage] = useState<IStage | undefined>(undefined);
	const [bookings, setBookings] = useState<IUserBooking[]>([]);

	const [isImportLoading, setIsImportLoading] = useState<boolean>(false);
	const [rawData, setRawData] = useState<any[]>([]);
	const [errorLines, setErrorLines] = useState<IErrorLine[]>([]);
	const [emailField, setEmailField] = useState<string | undefined>(undefined);
	const [newAttendees, setNewAttendees] = useState<IAttendee[]>([]);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const content = useSelector((store: IRootState) => store.content.content);
	const allBookings = useSelector((store: IRootState) => store.booking.allBookings);

	const addBooking = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.addBooking);

	useEffect(() => {
		let _bookings: typeof bookings = [];

		if (activeSpace && allBookings) {
			_bookings = allBookings.filter((e) => e.spaceId === activeSpace.spaceId && e.itemId === route.params.id);
		}

		setBookings(_bookings);
	}, [activeSpace, allBookings]);

	useEffect(() => {
		let _item: typeof item = undefined;
		let _stage: typeof stage = undefined;

		if (activeSpace && content.schedules) {
			_item = content.schedules.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);
			if (_item && content.stages) {
				_stage = content.stages.find((e) => e.id === _item?.stage?.id);
			}
		}

		setItem(_item);
		setStage(_stage);
	}, [activeSpace, content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _importBookings(),
			isRightNavPressDisabled: newAttendees.length === 0,
			isLoading: isImportLoading
		});
	}, [isImportLoading, newAttendees]);

	useEffect(() => {
		_checkData();
	}, [rawData, emailField, attendees]);

	const _importBookings = async () => {
		setIsImportLoading(true);
		const res = await addBooking({
			emails: newAttendees.map((e) => e.email),
			itemId: route.params.id,
			type: route.params.type
		});
		setIsImportLoading(false);
		if (res) {
			navigation.goBack();
		}
	};

	const _checkData = async () => {
		const _newAttendees: typeof newAttendees = [];
		const _errors: typeof errorLines = [];

		if (rawData.length > 0 && emailField) {
			for (let i = 0; i < rawData.length; i++) {
				const entry = rawData[i];
				const line = i + 2;

				if (entry[emailField]) {
					if (isEmptyString(entry[emailField])) {
						_errors.push({
							line,
							error: t('MissingEmail')
						});
					} else {
						const schema = Yup.object().shape({
							[emailField]: Yup.string().email(getEmailError(emailField)).required().nullable()
						});
						const errors = await validateForm(schema, { [emailField]: entry[emailField] });
						if (errors) {
							_errors.push({
								line,
								error: `${errors[emailField]} (${entry[emailField]})`
							});
						} else {
							const attendee = attendees.find((e) => e.email === entry[emailField]);
							if (attendee) {
								const alreadyAdded = bookings.find((e) => e.userId === attendee.userId);
								if (alreadyAdded) {
									_errors.push({
										line,
										error: `${t('BookingAlreadyExists')}: (${attendee.firstName} ${attendee.lastName} ${
											entry[emailField]
										})`
									});
								} else {
									_newAttendees.push(attendee);
								}
							} else {
								_errors.push({
									line,
									error: `${t('AttendeeNotFound')} (${entry[emailField]})`
								});
							}
						}
					}
				} else {
					_errors.push({
						line,
						error: t('MissingEmail')
					});
				}
			}
		}

		setNewAttendees(_newAttendees);
		setErrorLines(_errors);
	};

	const _pickFile = async () => {
		const selectedFile = await DocumentPicker.getDocumentAsync({
			copyToCacheDirectory: true,
			multiple: false,
			type: ['text/csv']
		});

		if (selectedFile.type === 'success') {
			Papa.parse(selectedFile.file, {
				skipEmptyLines: true,
				header: true,
				delimiter: '',

				error: function (error) {
					console.log('error', error);
				},

				complete: async (result) => {
					setNewAttendees([]);
					setErrorLines([]);
					navigation.setParams({ prohibitNavigation: true });

					if (result) {
						const _emailField = result.meta.fields.find((e) => emailFields.includes(e));

						if (!_emailField) {
							setRawData([]);
							setEmailField(undefined);
							showToast('error', t('ImportErrorMissingEmail'), t('ImportErrorMissingEmailSubtitle'));
							return;
						}

						setRawData(result.data ?? []);
						setEmailField(_emailField);
					} else {
						setRawData([]);
						setEmailField(undefined);
					}
				}
			});
		}
	};

	const _renderFilePicker = () => {
		return (
			<Button
				testID={`${TESTIDPREFIX}_button_pickfile`}
				onPress={_pickFile}
				title={t('SelectFile')}
				style={{ marginBottom: 20, alignSelf: 'center' }}
			/>
		);
	};

	const _renderFilePickerHint = () => {
		return (
			<Text style={{ marginBottom: 10, fontSize: 16 }}>
				<ul>
					<li>{t('ImportFileHasToBeCSV')}</li>
					<li>{t('ImportFileCoding')}</li>
					<li>{t('ImportFileMustHaveEmail').replace('%VALUE%', emailFields.join(', '))}</li>
					<li>{t('ImportAddsOnlyNew')}</li>
					<li>{t('ImportWontChangeExisting')}</li>
				</ul>
			</Text>
		);
	};

	const _renderDate = () => {
		let str = '';

		if (item) {
			if (!isEmptyString(item.startDate) && !isEmptyString(item.endDate)) {
				str = `${moment(item.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(item.endDate).format('HH:mm')}`;
			} else if (!isEmptyString(item.startDate)) {
				str = `${t('Start')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
			} else if (!isEmptyString(item.endDate)) {
				str = `${t('End')}: ${moment(item.endDate).format('DD.MM.YYYY HH:mm')}`;
			}

			if (!isEmptyString(str)) {
				return <Text>{str}</Text>;
			}
		}

		return null;
	};

	const _renderItem = () => {
		if (item) {
			return (
				<View style={{ alignItems: 'center', marginBottom: 20 }}>
					<H1>{item.title}</H1>
					{_renderDate()}
					{stage && <Text>{stage.title}</Text>}
					{!isEmptyString(item.subtitle) && <Subtitle>{item.subtitle}</Subtitle>}
				</View>
			);
		}

		return null;
	};

	return (
		<ScreenContainer isProtectedRoute>
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard>
					{_renderFilePicker()}
					{_renderFilePickerHint()}
				</HSCard>
				<HSCard>
					<FlatList
						data={bookings}
						ListHeaderComponent={
							<View style={{ marginBottom: 20, paddingHorizontal: 5 }}>
								{_renderItem()}
								<H2 center>{`${t('Bookings')} (${bookings.length})`}</H2>
							</View>
						}
						ListEmptyComponent={<NoData type="NoBookings" />}
						keyExtractor={(item) => `${TESTIDPREFIX}_item_${item.id}`}
						renderItem={({ item }) => <BookingAttendeeListItem testID={`${TESTIDPREFIX}_list_${item.id}`} item={item} />}
					/>
				</HSCard>
				{errorLines.length > 0 && (
					<HSCard>
						<FlatList
							data={errorLines}
							ListHeaderComponent={
								<View style={{ marginBottom: 20, paddingHorizontal: 5 }}>
									<H2 center>{`${t('Errors')} (${errorLines.length})`}</H2>
								</View>
							}
							keyExtractor={(item) => `${TESTIDPREFIX}_item_${item.line}`}
							renderItem={({ item }) => (
								<View>
									<Text>{`${t('Line')} ${item.line}: ${item.error}`}</Text>
								</View>
							)}
						/>
					</HSCard>
				)}
				{newAttendees.length > 0 && (
					<HSCard>
						<FlatList
							data={newAttendees}
							ListHeaderComponent={
								<View style={{ marginBottom: 20, paddingHorizontal: 5 }}>
									<H2 center>{`${t('New Bookings')} (${newAttendees.length})`}</H2>
								</View>
							}
							keyExtractor={(item) => `${TESTIDPREFIX}_item_${item.userId}`}
							renderItem={({ item, index }) => (
								<AttendeeListItem testID={`${TESTIDPREFIX}_attendee_${index}`} item={item} hideInteractions />
							)}
						/>
					</HSCard>
				)}
			</ScrollView>
		</ScreenContainer>
	);
};

export const BookingImportScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Import Bookings')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isDisabled={props.options.isRightNavPressDisabled}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
