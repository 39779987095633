import { ReactNativeZoomableView } from '@openspacelabs/react-native-zoomable-view';
import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { FormSwitch } from 'components/Form/FormSwitch';
import { Icon } from 'components/Icon';
import { AuthenticationModal } from 'components/Modal/AuthenticationModal';
import { NoData } from 'components/NoData';
import { EHorizontalScreenPadding } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { Avatar } from 'components/User';
import { IVote } from 'config/interfaces/vote';
import { IVoteAnswer } from 'config/interfaces/voteanswer';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding, hsTopScreenPadding } from 'config/styleConstants';
import { BlurView } from 'expo-blur';
import { EDefaultIconSet, IS_WEB, isDarkColor, isEmptyString } from 'helper';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageBackground, Modal as RNModal, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Image from 'react-native-scalable-image';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';

import { ActiveVoteHeader } from '../ActiveVoteHeader';

interface IVotePinOnImage {
	vote: IVote;
	isPreview?: boolean;
	largerDesign?: boolean;
	fullwidth?: boolean;
	resultsOnly?: boolean;
}

const TESTIDPREFIX = 'activevote';

export const VotePinOnImage = (props: IVotePinOnImage) => {
	const { vote, isPreview, largerDesign, fullwidth, resultsOnly } = props;
	const { t } = useTranslation();
	const { activeSpace } = useSpace();
	const { screenWidth, isPhone, isTabletOrMobile } = useQuery();
	const { theme } = useTheme();
	const zoomRef = createRef<ReactNativeZoomableView>();

	const [isSubmitVoteAnswerLoading, setIsSubmitVoteAnswerLoading] = useState<boolean>(false);
	const [isAuthenticationModalVisible, setIsAuthenticationModalVisible] = useState<boolean>(false);
	const [answerAnonymous, setAnswerAnonymous] = useState<boolean>(false);
	const [pinPosition, setPinPosition] = useState<{ x: number; y: number; icon?: string }>({ x: 0, y: 0 });
	const [tempPinPosition, setTempPinPosition] = useState<{ x: number; y: number; icon?: string }>({ x: 0, y: 0 });
	const [pinAnswers, setPinAnswers] = useState<IVoteAnswer[]>([]);
	const [activeIcon, setActiveIcon] = useState<string | undefined>(undefined);
	const [isMinZoomed, setIsMinZoomed] = useState<boolean>(true);
	const [isMaxZoomed, setIsMaxZoomed] = useState<boolean>(false);
	const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
	const [myAnswer, setMyAnswer] = useState<IVoteAnswer>();
	const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0
	});
	const [imageRatio, setImageRatio] = useState<number>(1);
	const [containerSize, setContainerSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
	const [modalContainerSize, setModalContainerSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
	const [targetID, setTargetID] = useState<number | string | undefined>(undefined);
	const [isSingleTap, setIsSingleTap] = useState<boolean>(true);
	const [dragPosition, setDragPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
	const [timestamp, setTimeStamp] = useState<number>(0);
	const { top, bottom } = useSafeAreaInsets();
	const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const voteAnswers = useSelector((store: IRootState) => store.vote.voteAnswers);

	const submitVoteAnswer = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.submitVoteAnswer);

	const votingShowResults = useSelector((store: IRootState) => store.temp.votingShowResults);
	const votingIsAnswered = useSelector((store: IRootState) => store.temp.votingIsAnswered);

	const setVotingShowResults = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingShowResults);
	const setVotingIsAnswered = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setVotingIsAnswered);

	useEffect(() => {
		if (votingIsAnswered && !vote.hideResults) {
			setVotingShowResults(true);
		}
	}, [vote, votingIsAnswered]);

	useEffect(() => {
		if (votingShowResults) setIsFullscreen(false);
	}, [votingShowResults]);

	useEffect(() => {
		if (voteAnswers) {
			let _answers = voteAnswers.filter(
				(a) => !a.isResultObject && !a.isDeleted && a.spaceId === activeSpace?.spaceId && a.voteId === vote.id
			) as IVoteAnswer[];
			setPinAnswers(_answers);
		}
	}, [vote, voteAnswers, activeSpace]);

	useEffect(() => {
		if (pinAnswers) {
			let _myAnswer = pinAnswers.find((a) => a.userId === profile?.userId);
			setMyAnswer(_myAnswer);
		}
	}, [pinAnswers, profile]);

	useEffect(() => {
		if (vote && myAnswer) {
			if (myAnswer.value) {
				setTempPinPosition(JSON.parse(myAnswer.value));
				setPinPosition(JSON.parse(myAnswer.value));
				setVotingIsAnswered(true);
			}

			if (vote.options && !vote.useAvatar && (!activeIcon || !vote.options.find((icon) => icon.label !== activeIcon))) {
				setActiveIcon(vote.options[0]?.label);
			}
		}
	}, [vote, myAnswer]);

	useEffect(() => {
		if (vote.options && vote.options.length > 0) {
			if (pinPosition.icon && vote.options?.find((icon) => icon.label === pinPosition.icon)) {
				setActiveIcon(pinPosition.icon);
			} else {
				setActiveIcon(vote.options[0].label);
			}
		}
	}, [vote.options, pinPosition.icon]);

	const _submitVoteAnswer = async () => {
		if (isPreview) {
			setVotingShowResults(true);
			return;
		}
		if (
			(vote.allowAnonymousAnswers === 'never' && !profile) ||
			(vote.allowAnonymousAnswers === 'optin' && !profile && !answerAnonymous)
		) {
			setIsAuthenticationModalVisible(true);
			return;
		}
		setIsAuthenticationModalVisible(false);

		setIsSubmitVoteAnswerLoading(true);
		let res, value;
		value = JSON.stringify(pinPosition);

		if (value) {
			res = await submitVoteAnswer({
				voteId: vote.id,
				value: value,
				isAnonymousSubmission: vote.allowAnonymousAnswers === 'always' || answerAnonymous
			});
			setVotingShowResults(true);
		}
		setIsSubmitVoteAnswerLoading(false);
	};

	const _getIconColor = () => {
		let color = theme.danger;

		// TODO? random colors for pins?

		return color;
	};

	const _renderIconOptions = () => {
		if (votingShowResults || resultsOnly) {
			return null;
		}

		if (!vote.useAvatar && vote.options && vote.options?.length > 0) {
			return (
				<HSCard style={{ flexDirection: 'row', width: 'auto', paddingVertical: 10, marginBottom: 0 }}>
					<View
						style={{
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<ScrollView horizontal={true} style={{ flexDirection: 'row' }}>
							{vote.options?.map((option, idx) => {
								if (option.label) {
									const isLastItem = idx === (vote.options?.length ?? 1) - 1;
									const isActive = option.label === activeIcon;

									return (
										<ChildButton
											testID={`${TESTIDPREFIX}_button_icon_${idx}`}
											onPress={() => setActiveIcon(option.label)}
											style={{
												marginRight: isLastItem ? 0 : 10,
												borderWidth: 1,
												borderColor: theme.primary,
												borderRadius: hsBorderRadius,
												padding: 5,
												backgroundColor: isActive ? theme.primary : undefined,
												alignItems: 'center',
												justifyContent: 'center'
											}}
											isDisabled={isActive}
										>
											<Icon
												name={option.label}
												size={isPhone ? 20 : 25}
												color={isActive ? theme.contentBackgroundColor : undefined}
											/>
										</ChildButton>
									);
								}
								return null;
							})}
						</ScrollView>
					</View>
				</HSCard>
			);
		}

		return null;
	};

	const _renderButtons = () => {
		if (IS_WEB && !isTabletOrMobile && !resultsOnly) {
			const _size = isFullscreen ? modalContainerSize : containerSize;
			return (
				<View
					style={{
						position: 'absolute',
						bottom: 0,
						right: 0,
						margin: hsInnerPadding,
						alignItems: 'center'
					}}
				>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomIn`}
						onPress={() => {
							if (zoomRef.current && Number(zoomRef.current['zoomLevel'].toFixed(2)) < 2.4) {
								if (Number((zoomRef.current['zoomLevel'] + 0.3).toFixed(2)) >= 2.4) {
									zoomRef.current._zoomToLocation(_size.width / 2, _size.height / 2, 2.4);
									setIsMaxZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(_size.width / 2, _size.height / 2, zoomRef.current['zoomLevel'] + 0.3);
									setIsMinZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMaxZoomed ? 0.6 : 1
						}}
						isDisabled={isMaxZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomIn} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_zoomOut`}
						onPress={() => {
							if (zoomRef.current && zoomRef.current['zoomLevel'] > _getInitialZoom()) {
								if (Number((zoomRef.current['zoomLevel'] - 0.3).toFixed(2)) <= Number(_getInitialZoom().toFixed(2))) {
									zoomRef.current._zoomToLocation(_size.width / 2, _size.height / 2, _getInitialZoom());
									setIsMinZoomed(true);
								} else {
									zoomRef.current._zoomToLocation(_size.width / 2, _size.height / 2, zoomRef.current['zoomLevel'] - 0.3);
									setIsMaxZoomed(false);
								}
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							marginBottom: 10,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.ZoomOut} color={theme.primaryContrast} />
					</ChildButton>
					<ChildButton
						testID={`${TESTIDPREFIX}_button_resetZoom`}
						onPress={() => {
							if (zoomRef.current) {
								zoomRef.current._zoomToLocation(_size.width / 2, _size.height / 2, _getInitialZoom());
								setIsMinZoomed(true);
								setIsMaxZoomed(false);
							}
						}}
						style={{
							backgroundColor: theme.primary,
							borderColor: theme.primary,
							borderRadius: 9999,
							width: 38,
							height: 38,
							alignItems: 'center',
							justifyContent: 'center',
							borderWidth: 1,
							opacity: isMinZoomed ? 0.6 : 1
						}}
						isDisabled={isMinZoomed}
					>
						<Icon name={EDefaultIconSet.Fullscreen} color={theme.primaryContrast} />
					</ChildButton>
				</View>
			);
		}

		return null;
	};

	const _getInitialZoom = () => {
		return 1.0;
	};

	const _handlePress = (x: number, y: number) => {
		if (
			x < 0 ||
			x > imageSize.width * (zoomRef.current ? zoomRef.current['zoomLevel'] : 1) ||
			y < 0 ||
			y > imageSize.height * (zoomRef.current ? zoomRef.current['zoomLevel'] : 1)
		) {
			return;
		}

		let xPos = (x / imageSize.width) * 100;
		let yPos = (y / imageSize.height) * 100;

		if (IS_WEB && zoomRef.current) {
			xPos = xPos / zoomRef.current['zoomLevel'];
			yPos = yPos / zoomRef.current['zoomLevel'];
		}

		setPinPosition({ x: xPos, y: yPos, icon: activeIcon });
	};

	const _renderBackground = () => {
		if (vote.media && vote.media[0]) {
			let _source = '';

			if (typeof vote.media[0] === 'string') {
				_source = vote.media[0];
			} else {
				if (vote.media[0].media?.url) {
					_source = vote.media[0].media?.url;
				}
			}

			const _size = isFullscreen ? modalContainerSize : containerSize;

			if (IS_WEB) {
				return (
					<View
						style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
						onLayout={(e) => {
							if (isFullscreen) {
								setModalContainerSize(e.nativeEvent.layout);
							} else {
								setContainerSize(e.nativeEvent.layout);
							}
						}}
					>
						<ReactNativeZoomableView
							key={vote.id}
							ref={zoomRef}
							initialOffsetX={0}
							initialOffsetY={0}
							minZoom={_getInitialZoom()}
							initialZoom={_getInitialZoom()}
							maxZoom={1.8}
							zoomStep={0.3}
							bindToBorders
							style={{ flex: 1, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}
							contentHeight={_size.height}
							contentWidth={_size.width}
							onDoubleTapAfter={(e, zoom) => {
								if (zoom.zoomLevel) {
									setIsMaxZoomed(Number(zoom.zoomLevel.toFixed(2)) >= 2.4);
									setIsMinZoomed(Number(zoom.zoomLevel.toFixed(2)) <= Number(_getInitialZoom().toFixed(2)));
								}
							}}
						>
							<ChildButton
								testID={isFullscreen ? `${TESTIDPREFIX}_button_addpin` : `${TESTIDPREFIX}_button_open`}
								key={isFullscreen ? `${TESTIDPREFIX}_button_addpin` : `${TESTIDPREFIX}_button_open`}
								onLayout={(e) =>
									setOffset({
										x: (e.nativeEvent.layout.width - imageSize.width) / 2,
										y: (e.nativeEvent.layout.height - imageSize.height) / 2
									})
								}
								onPressIn={(e) => setDragPosition({ x: e.nativeEvent.locationX, y: e.nativeEvent.locationY })}
								onPressOut={(e) => {
									if (e.nativeEvent.touches.length <= 1) {
										if (e.nativeEvent.target === targetID) {
											return;
										}

										const { locationX, locationY } = e.nativeEvent;

										if (locationX !== dragPosition.x || locationY !== dragPosition.y) {
											const lowX = dragPosition.x - 25;
											const lowY = dragPosition.y - 25;
											const highX = dragPosition.x + 25;
											const highY = dragPosition.y + 25;

											if (locationX < lowX || locationX > highX || locationY < lowY || locationY > highY) {
												return;
											}
										}

										if (!isFullscreen) {
											setIsFullscreen(true);
											setVotingShowResults(false);
										} else {
											_handlePress(
												locationX - Math.max(offset.x * (zoomRef.current ? zoomRef.current['zoomLevel'] : 1), 0),
												locationY - Math.max(offset.y * (zoomRef.current ? zoomRef.current['zoomLevel'] : 1), 0)
											);
										}
									}
								}}
								isDisabled={resultsOnly || isSubmitVoteAnswerLoading}
								activeOpacity={1}
								style={{
									flex: 1,
									zIndex: 10,
									alignSelf: 'center',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								{!isEmptyString(_source) ? (
									<Image
										key={`${TESTIDPREFIX}_image_${_source}`}
										source={{ uri: _source }}
										style={{ maxHeight: _size.height, maxWidth: _size.width }}
										resizeMode="contain"
										resizeMethod="scale"
										height={_size.height}
										width={_size.width}
										onLayout={(e) => {
											setTargetID(e.nativeEvent.target);
										}}
										onSize={(e) => {
											if (e.height !== imageSize.height || e.width !== imageSize.width) {
												setImageSize({ ...e });
											}
										}}
									/>
								) : (
									<Spinner size={'large'} />
								)}
								{_renderPins()}
							</ChildButton>
						</ReactNativeZoomableView>
						{!isFullscreen && _renderButtons()}
					</View>
				);
			}

			return (
				<View
					style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
					onLayout={(e) => {
						if (isFullscreen) {
							setModalContainerSize(e.nativeEvent.layout);
						} else {
							setContainerSize(e.nativeEvent.layout);
						}
					}}
				>
					<View style={{ height: _size.height, width: _size.width, alignItems: 'center', justifyContent: 'center' }}>
						<ReactNativeZoomableView
							key={vote.id}
							ref={zoomRef}
							initialOffsetX={0}
							initialOffsetY={0}
							minZoom={_getInitialZoom()}
							initialZoom={_getInitialZoom()}
							maxZoom={isFullscreen ? 2.4 : 1.8}
							zoomStep={0.3}
							bindToBorders
							style={{ flex: 1, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}
							contentHeight={_size.height}
							contentWidth={_size.width}
							onDoubleTapAfter={(e, zoom) => {
								if (zoom.zoomLevel) {
									setIsMaxZoomed(Number(zoom.zoomLevel.toFixed(2)) >= 1.5);
									setIsMinZoomed(Number(zoom.zoomLevel.toFixed(2)) <= Number(_getInitialZoom().toFixed(2)));
								}
							}}
						>
							<View
								testID={isFullscreen ? `${TESTIDPREFIX}_button_addpin` : `${TESTIDPREFIX}_button_open`}
								key={isFullscreen ? `${TESTIDPREFIX}_button_addpin` : `${TESTIDPREFIX}_button_open`}
								style={{
									alignSelf: 'center',
									alignItems: 'center',
									justifyContent: 'center'
								}}
								onTouchStart={(e) => {
									if (e.nativeEvent.touches.length <= 1) {
										setIsSingleTap(true);
									}
								}}
								onTouchMove={() => {
									setIsSingleTap(false);
								}}
								onTouchEnd={(e) => {
									if (e.nativeEvent.touches.length <= 1) {
										if (isSingleTap) {
											if (!timestamp || timestamp <= e.nativeEvent.timestamp - 500) {
												if (!isFullscreen) {
													setIsFullscreen(true);
													setVotingShowResults(false);
												} else {
													_handlePress(e.nativeEvent.locationX, e.nativeEvent.locationY);
												}
											}
											setTimeStamp(e.nativeEvent.timestamp);
										}
									}
								}}
								pointerEvents={resultsOnly || isSubmitVoteAnswerLoading ? 'none' : 'auto'}
							>
								{!isEmptyString(_source) && (
									<ImageBackground
										key={`${TESTIDPREFIX}_image_${_source}`}
										source={{ uri: _source }}
										style={{
											width: _size.width,
											height: _size.width / imageRatio
										}}
										resizeMode="contain"
										resizeMethod="scale"
										height={_size.width / imageRatio}
										width={_size.width}
										onLoad={(e) => setImageRatio(e.nativeEvent.source.width / e.nativeEvent.source.height)}
										onLayout={(e) => {
											if (
												e.nativeEvent.layout.height !== imageSize.height ||
												e.nativeEvent.layout.width !== imageSize.width
											) {
												setImageSize({ ...e.nativeEvent.layout });
											}
										}}
									/>
								)}
							</View>
							{_renderPins()}
						</ReactNativeZoomableView>
					</View>
				</View>
			);
		}

		return <NoData type="ItemNotFound" />;
	};

	const _renderPins = () => {
		if (vote && vote.media) {
			const { x, y } = pinPosition;

			const iconSize = isTabletOrMobile ? (containerSize.width < 350 && !isFullscreen ? 15 : 20) : 25;
			const avatarSize = isTabletOrMobile ? (containerSize.width < 350 && !isFullscreen ? 'pin' : 'gridCard') : 'xxs';
			const color = vote.iconColor ?? _getIconColor();

			if (votingShowResults || resultsOnly) {
				if (isPreview) {
					const attendee = attendees.find((a) => a.userId === profile?.userId);
					const _avatar = attendee?.smallImageUrl ?? attendee?.imageUrl;
					const _name = attendee?.firstName + (attendee?.lastName ? ' ' + attendee?.lastName : '');

					return (
						<View
							style={{
								position: 'absolute',
								width: imageSize.width,
								height: imageSize.height,
								backgroundColor: 'transparent'
							}}
							pointerEvents={'box-none'}
						>
							<View
								testID={`${TESTIDPREFIX}_pin_user`}
								style={{
									position: 'absolute',
									top: (y / 100) * imageSize.height - 10,
									left: (x / 100) * imageSize.width - 10,
									width: 20,
									height: 20,
									borderRadius: 999,
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								<View
									style={{
										borderColor: theme.danger,
										borderWidth: 2,
										borderRadius: 999,
										padding: 1,
										aspectRatio: 1
									}}
								>
									{vote.useAvatar ? (
										<Avatar avatar={_avatar} fullName={_name} size={avatarSize} />
									) : (
										<Icon name={activeIcon ?? EDefaultIconSet.MapMarker} size={iconSize} color={color} />
									)}
								</View>
							</View>
						</View>
					);
				}

				return (
					<View
						style={{
							position: 'absolute',
							width: imageSize.width,
							height: imageSize.height,
							backgroundColor: 'transparent'
						}}
						pointerEvents={'box-none'}
					>
						{pinAnswers.map((pin) => {
							if (pin.value) {
								const _position = JSON.parse(pin.value);

								const attendee = attendees.find((a) => a.userId === pin.userId);
								const _avatar = attendee?.smallImageUrl ?? attendee?.imageUrl;
								const _name = attendee?.firstName + (attendee?.lastName ? ' ' + attendee?.lastName : '');

								let _icon = _position.icon;

								if (!vote.options?.find((o) => o.label === _icon)) {
									_icon = EDefaultIconSet.MapMarker;
								}

								const isDisabled =
									resultsOnly ||
									((vote.allowAnonymousAnswers === 'always' ||
										(vote.allowAnonymousAnswers === 'optin' && pin.isAnonymousSubmission)) &&
										pin.userId !== profile?.userId);

								return (
									<ChildButton
										testID={`${TESTIDPREFIX}_pin_user`}
										style={{
											position: 'absolute',
											top: (_position.y / 100) * imageSize.height - 10,
											left: (_position.x / 100) * imageSize.width - 10,
											width: 20,
											height: 20,
											borderRadius: 999,
											alignItems: 'center',
											justifyContent: 'center'
										}}
										isDisabled={isDisabled}
									>
										<View
											style={{
												borderColor: theme.danger,
												borderWidth: attendee?.userId === profile?.userId ? 2 : 0,
												borderRadius: 999,
												padding: 1,
												aspectRatio: 1
											}}
										>
											{(vote.useAvatar && vote.allowAnonymousAnswers !== 'always') ||
											(vote.allowAnonymousAnswers !== 'optin' && !pin.isAnonymousSubmission) ? (
												<Avatar avatar={_avatar} fullName={_name} size={avatarSize} />
											) : (
												<Icon name={_icon} size={iconSize} color={color} />
											)}
										</View>
									</ChildButton>
								);
							}

							return null;
						})}
					</View>
				);
			}

			const attendee = attendees.find((a) => a.userId === profile?.userId);
			const _avatar = attendee?.smallImageUrl ?? attendee?.imageUrl;
			const _name = attendee?.firstName + (attendee?.lastName ? ' ' + attendee?.lastName : '');

			return (
				<View
					style={{
						position: 'absolute',
						width: imageSize.width,
						height: imageSize.height,
						backgroundColor: 'transparent'
					}}
					pointerEvents={'none'}
				>
					{x > 0 && y > 0 && (
						<View
							testID={`${TESTIDPREFIX}_pin_user`}
							style={{
								position: 'absolute',
								top: (y / 100) * imageSize.height - 10,
								left: (x / 100) * imageSize.width - 10,
								width: 20,
								height: 20,
								borderRadius: 999,
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							{vote.useAvatar ? (
								<Avatar avatar={_avatar} fullName={_name} size={avatarSize} />
							) : (
								<Icon name={activeIcon ?? EDefaultIconSet.MapMarker} size={iconSize} color={color} />
							)}
						</View>
					)}
				</View>
			);
		}

		return null;
	};

	const _renderAnonymousSwitch = () => {
		if (vote.allowAnonymousAnswers === 'optin') {
			return (
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginRight: hsInnerPadding,
						marginTop: 5
					}}
				>
					<FormSwitch
						testID="votemultiplechoice_switch_anonymous"
						onChange={() => setAnswerAnonymous(!answerAnonymous)}
						leftLabel={t('Answer Anonymous')}
						value={answerAnonymous}
						size="sm"
						isDisabled={isSubmitVoteAnswerLoading}
						formStyle={{ width: undefined }}
					/>
				</View>
			);
		}
		return null;
	};

	return (
		<View
			style={{
				flex: 1,
				width: '100%',
				height: '100%',
				alignSelf: 'center',
				paddingTop: !resultsOnly ? hsTopScreenPadding : 0,
				paddingHorizontal: EHorizontalScreenPadding.Wide,
				alignItems: 'center'
			}}
		>
			<View style={{ height: '100%', width: fullwidth || isPreview ? '100%' : screenWidth }}>
				<ActiveVoteHeader activeVote={vote} showResult={resultsOnly} largerText={largerDesign} hideMedia />
				<View style={{ flex: 1 }}>
					<HSCard
						style={{
							flex: 1,
							marginBottom: isTabletOrMobile && !vote.useAvatar ? EHorizontalScreenPadding.Wide : hsBottomMargin
						}}
					>
						{_renderBackground()}
					</HSCard>
				</View>
			</View>
			<RNModal
				presentationStyle="overFullScreen"
				animationType="slide"
				visible={isFullscreen}
				transparent
				onRequestClose={() => {
					setIsFullscreen(false);
					setPinPosition({ ...tempPinPosition });
				}}
				supportedOrientations={['portrait', 'landscape-left', 'landscape-right']}
				statusBarTranslucent
				style={{ alignItems: 'center' }}
			>
				{IS_WEB && (
					<BlurView
						intensity={15}
						tint={isDarkColor(theme.contentBackgroundColor ?? theme.background) ? 'light' : 'dark'}
						style={{ position: 'absolute', width: '100%', height: '100%' }}
					/>
				)}
				<View
					style={{
						flex: 1,
						position: 'relative',
						width: screenWidth,
						alignSelf: 'center'
					}}
				>
					<HSCard
						style={{
							flex: 1,
							borderRadius: screenWidth === '100%' ? 0 : hsBorderRadius,
							padding: 0,
							marginTop: Math.max(hsTopScreenPadding, top),
							marginBottom: Math.max(hsBottomMargin, bottom)
						}}
						pointerEvents={'box-none'}
					>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								padding: EHorizontalScreenPadding.Wide
							}}
						>
							<RoundButton
								isOutline
								testID={'modal_button_close'}
								icon={EDefaultIconSet.Close}
								onPress={() => {
									setPinPosition({ ...tempPinPosition });
									setVotingShowResults(true);
									setIsFullscreen(false);
								}}
								isLoading={isSubmitVoteAnswerLoading}
							/>

							{_renderIconOptions()}

							<RoundButton
								testID={'modal_button_submit'}
								icon={EDefaultIconSet.Save}
								onPress={() => {
									_submitVoteAnswer();
									setIsFullscreen(false);
								}}
								isLoading={isSubmitVoteAnswerLoading}
							/>
						</View>
						<View style={{ flex: 1 }}>
							{_renderBackground()}
							{_renderButtons()}
						</View>
						{_renderAnonymousSwitch()}
					</HSCard>
				</View>
			</RNModal>
			<AuthenticationModal
				isVisible={isAuthenticationModalVisible}
				onClose={() => setIsAuthenticationModalVisible(false)}
				onSubmit={() => setIsAuthenticationModalVisible(false)}
			/>
		</View>
	);
};
