import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { H1 } from 'components/Text';
import { IVote } from 'config/interfaces/vote';
import { Text } from 'components/Text/Text';
import { useTheme } from 'hooks/useTheme';
import { useTranslation } from 'react-i18next';
import { ICreateVoteFormValues, IEditVoteFormValues, ITabSwitcherButton } from 'config/interfaces';
import { v4 } from 'uuid';
import {
	VoteApplause,
	VoteHappinessOMeter,
	VoteMultipleChoice,
	VoteNps,
	VoteRating,
	VoteScale,
	VoteText,
	VoteWordCloud
} from 'components/Vote/ActiveVote';
import { isEmptyString, normalizeFontSize } from 'helper';
import { TabSwitcher } from 'components/TabSwitcher';
import { PreviewMultipleChoiceOptions } from 'helper/previewAnswers/previewAnswersMultipleChoice';
import { PreviewApplauseOptions } from 'helper/previewAnswers/previewAnswersApplause';
import { Icon } from 'components/Icon';
import { IS_ANDROID, IS_IOS } from 'helper/platform';
import { VoteQuiz } from 'components/Vote/ActiveVote/VoteQuiz';
import { VotePinOnImage } from 'components/Vote/ActiveVote/VotePinOnImage';
import { hsTextBottomMargin } from 'config/styleConstants';

interface IVotePreview {
	item: ICreateVoteFormValues | IEditVoteFormValues | IVote;
	isModal?: boolean;
	onClose?: () => void;
}
export const VotePreview = (props: IVotePreview) => {
	const { item, isModal, onClose } = props;
	const { theme } = useTheme();
	const { t } = useTranslation();

	const [vote, setVote] = useState<IVote>({
		spaceId: item['spaceId'] ?? v4(),
		publicId: item.publicId ?? '',
		question: item.question ?? '',
		hint: item.hint,
		votingType: item.votingType ?? 'text',
		viewType: item.viewType,
		minValue: item.minValue,
		maxValue: item.maxValue,
		options: item.options,
		allowAnonymousAnswers: item.allowAnonymousAnswers ?? 'optin',
		media: item.media ?? [],
		id: item['id'] ?? v4(),
		updated_at: item['updated_at'] ?? '',
		created_at: item['created_at'] ?? '',
		published_at: item['published_at'] ?? ''
	});

	useEffect(() => {
		if (item) {
			let _vote = {
				...vote,
				isActive: true,
				spaceId: item['spaceId'] ?? v4(),
				publicId: item.publicId ?? '',
				question: item.question ?? t('questionPreview') ?? '',
				hint: item.hint,
				votingType: item.votingType ?? 'text',
				viewType: item.viewType,
				minValue: item.minValue,
				maxValue: item.maxValue,
				options: item.options,
				allowAnonymousAnswers: item.allowAnonymousAnswers ?? 'optin',
				media: item.media ?? [],
				id: item['id'] ?? v4(),
				updated_at: item['updated_at'] ?? '',
				created_at: item['created_at'] ?? '',
				published_at: item['published_at'] ?? '',
				useAvatar: item['useAvatar'] ?? false
			};

			if (isEmptyString(_vote.question)) {
				_vote.question = t('voteQuestionPreview');
			}
			if (isEmptyString(_vote.hint)) {
				_vote.hint = t('voteHintPreview');
			}
			setVote(_vote);
		}
	}, [item]);

	const _renderTabSwitcher = () => {
		let items: ITabSwitcherButton[] = [];
		switch (vote.votingType) {
			case 'scale':
				items = [
					{
						key: 'slider',
						label: t('Slider')
					},
					{
						key: 'tacho',
						label: t('Tacho')
					}
				];
			case 'text':
			case 'applause':
			case 'multipleChoice':
			case 'quiz':
			case 'rating':
			case 'survey':
			case 'wordCloud':
			case 'nps':
			default:
				break;
		}
		if (items.length > 0) {
			return (
				<TabSwitcher items={items} activeKey={vote.viewType ?? 'slider'} onPress={(key) => setVote({ ...vote, viewType: key })} />
			);
		}
		return null;
	};

	const _renderPreview = () => {
		switch (vote.votingType) {
			case 'text':
				return <VoteText vote={vote} isPreview sortBy={'creation'} />;
			case 'applause':
				return (
					<VoteApplause
						vote={{ ...vote, options: (vote.options?.length ?? 0) > 0 ? vote.options : PreviewApplauseOptions }}
						isPreview
					/>
				);
			case 'multipleChoice':
				const _options = PreviewMultipleChoiceOptions.map((e) => {
					return { id: e.id, description: t(e.description ?? '') };
				});
				return (
					<VoteMultipleChoice vote={{ ...vote, options: (vote.options?.length ?? 0) > 0 ? vote.options : _options }} isPreview />
				);
			case 'quiz':
				return <VoteQuiz vote={vote} isPreview onClose={onClose} />;
			case 'rating':
				return <VoteRating vote={vote} isPreview />;
			case 'scale':
				return <VoteScale vote={{ ...vote, maxValue: 10 }} isPreview />;
			case 'survey':
				break;
			case 'wordCloud':
				return <VoteWordCloud vote={vote} isPreview />;
			case 'nps':
				return <VoteNps vote={vote} isPreview />;
			case 'pinOnImage':
				return <VotePinOnImage vote={vote} isPreview />;
			case 'happinessOMeter':
				return <VoteHappinessOMeter vote={vote} isPreview />;
			default:
				return null;
		}
	};

	const _allowScroll = () => {
		switch (vote.votingType) {
			case 'applause':
			case 'multipleChoice':
			case 'wordCloud':
				return true;
			default:
				return false;
		}
	};

	const _renderDeviceMockTop = () => {
		if (IS_IOS) {
			return (
				<View style={{ position: 'absolute', alignItems: 'center', width: '100%', top: 10 }}>
					<View
						style={{
							borderColor: '#a0a0a0',
							backgroundColor: '#a0a0a0',
							borderWidth: 1,
							borderRadius: 999,
							width: 10,
							height: 10
						}}
					/>
					<View
						style={{
							marginTop: 10,
							borderColor: '#a0a0a0',
							backgroundColor: '#a0a0a0',
							borderWidth: 1,
							borderRadius: 5,
							width: 50,
							height: 5
						}}
					/>
				</View>
			);
		}
		if (IS_ANDROID) {
			return (
				<View
					style={{
						position: 'absolute',
						alignSelf: 'center',
						top: 5,
						borderColor: '#a0a0a0',
						backgroundColor: '#a0a0a0',
						borderWidth: 1,
						borderRadius: 999,
						width: 10,
						height: 10
					}}
				/>
			);
		}
	};

	const _renderDeviceMockBottom = () => {
		if (IS_IOS) {
			return (
				<View
					style={{
						borderColor: '#a0a0a0',
						borderWidth: 1,
						borderRadius: 999,
						width: 30,
						height: 30,
						position: 'absolute',
						bottom: 10,
						alignSelf: 'center'
					}}
				/>
			);
		}
		if (IS_ANDROID) {
			return (
				<View
					style={{
						flexDirection: 'row',
						position: 'absolute',
						marginHorizontal: 'auto',
						bottom: 10,
						alignSelf: 'center',
						justifyContent: 'space-around',
						width: '100%'
					}}
				>
					<Icon name="SimpleLineIcons:menu" color="#a0a0a0" containerStyle={{ transform: [{ rotate: '90deg' }] }} />
					<View style={{ borderWidth: 1.5, borderColor: '#a0a0a0', borderRadius: 8, width: 20, height: 20 }} />
					<Icon name="SimpleLineIcons:arrow-left" color="#a0a0a0" />
				</View>
			);
		}
	};

	const _getScreenStyle = () => {
		if (IS_IOS) {
			return {
				borderWidth: 1,
				marginTop: 50,
				marginBottom: 50,
				marginHorizontal: 10,
				borderColor: '#a0a0a0',
				minHeight: 550,
				paddingBottom: 10
			};
		}

		return {};
	};

	if (!item) {
		return null;
	}

	if (isModal) {
		return (
			<ScrollView
				contentContainerStyle={{ flex: 1 }}
				scrollEnabled={_allowScroll()}
				style={{
					flex: 1,
					marginTop: 20
				}}
			>
				{_renderTabSwitcher()}
				{_renderPreview()}
			</ScrollView>
		);
	}

	return (
		<ScrollView
			scrollEnabled={_allowScroll()}
			style={{
				flex: 1,
				marginTop: 40
			}}
		>
			<H1>{`${t('Preview for')} "${t(vote.votingType)}"`}</H1>
			<Text testID="vote_hint" style={{ fontSize: normalizeFontSize(14), marginBottom: hsTextBottomMargin, color: theme.gray }}>
				{t('previewDataHint')}
			</Text>
			{_renderTabSwitcher()}
			<View
				style={{
					minHeight: IS_ANDROID ? 600 : 0,
					backgroundColor: theme.contentBackgroundColor ?? theme.background,
					shadowColor: '#171717',
					shadowOffset: { width: 8, height: 8 },
					shadowOpacity: 0.2,
					shadowRadius: 3,
					borderStyle: 'solid',
					borderColor: '#a0a0a0',
					borderWidth: 1,
					borderRadius: 35,
					margin: 20,
					elevation: 10,
					position: 'relative'
				}}
			>
				{_renderDeviceMockTop()}
				{_renderDeviceMockBottom()}
				<View style={_getScreenStyle()}>{_renderPreview()}</View>
			</View>
		</ScrollView>
	);
};
