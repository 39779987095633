import { ChildButton } from 'components/Button';
import React, { useEffect, useState } from 'react';
import { HSModal } from './Modal';
import { useTranslation } from 'react-i18next';
import { EDefaultIconSet, isEmptyString } from 'helper';
import { Text } from 'components/Text/Text';
import { IVoteAnswer } from 'config/interfaces';
import { View } from 'react-native';
import { HSCard } from 'components/Card';
import { VoteModerationWordCloudListItem } from 'components/Vote/VoteModeration/VoteModerationWordCloud/VoteModerationWordCloudListItem';
import { H3 } from 'components/Text';
import { BUTTONBORDERRADIUS } from 'components/Button/constants';
import { useTheme } from 'hooks/useTheme';
import { Icon } from 'components/Icon';
import { WORDCLOUDSPLITSEQUENCE } from 'components/Vote/ActiveVote';
import { Dispatch, useRematchDispatch } from 'rematch/store';

interface IVoteModerationWordCloudModal {
	item?: IVoteAnswer;
	onClose: () => void;
	onSubmit: (answer: IVoteAnswer) => Promise<boolean>;
	isLoading?: boolean;
}

const TESTIDPREFIX = 'votemoderationtexttmodal';

export const VoteModerationWordCloudModal = (props: IVoteModerationWordCloudModal) => {
	const { item, onClose, onSubmit, isLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [values, setValues] = useState<IVoteAnswer | undefined>(undefined);
	const [value, setValue] = useState<string[]>([]);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (item) {
			setValues({ ...item });
			if (item.value) {
				setValue(item.value?.split(WORDCLOUDSPLITSEQUENCE));
			}
		} else {
			setValues(undefined);
			setValue([]);
		}
	}, [item]);

	const _save = async () => {
		if (values) {
			const res = await onSubmit({ ...values, value: value.join(WORDCLOUDSPLITSEQUENCE) });
			setIsDirty(false);
			if (res) {
				onClose();
			}
		}
	};

	const _renderMyWords = () => {
		if (value.length > 0) {
			return (
				<View style={{ width: '100%' }}>
					<H3 style={{ marginBottom: 15 }}>{t('MyChoice')}</H3>
					<View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
						{value.map((word, idx) => {
							return (
								<ChildButton
									key={`activevote_button_${idx}`}
									testID={`activevote_button_${word}`}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										borderRadius: BUTTONBORDERRADIUS,
										backgroundColor: theme.primary,
										marginBottom: 20,
										marginRight: 20,
										paddingHorizontal: 10,
										paddingVertical: 5
									}}
									isDisabled={isLoading}
									onPress={() => {
										const _newValue = [...value];
										_newValue.splice(idx, 1);
										setValue(_newValue);
										setIsDirty(true);
									}}
								>
									<Text style={{ color: theme.primaryContrast, fontSize: 16 }}>{word}</Text>
									<View
										style={{
											position: 'absolute',
											top: -10,
											right: -5,
											borderWidth: 1,
											borderColor: theme.primary,
											paddingHorizontal: 2,
											paddingVertical: 1,
											backgroundColor: theme.primaryContrast,
											borderRadius: 999
										}}
									>
										<Icon name={EDefaultIconSet.Close} color={theme.primary} size={13} />
									</View>
								</ChildButton>
							);
						})}
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderForm = () => {
		if (values && item) {
			return (
				<View style={{ flex: 1 }}>
					<Text bold>{t('Current')}</Text>
					<VoteModerationWordCloudListItem item={item} onSubmit={(item) => onSubmit(item)} isLoading={isLoading} />
					<HSCard>{_renderMyWords()}</HSCard>
				</View>
			);
		}

		return null;
	};

	return (
		<HSModal
			isVisible={item !== undefined}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={() => _save()}
			isSubmitLoading={isLoading}
			isSubmitDisabled={isEmptyString(values?.value)}
			title={t('Edit')}
		>
			{_renderForm()}
		</HSModal>
	);
};
