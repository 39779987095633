import { HSCard } from 'components/Card';
import { UserHeader } from 'components/User';
import { IComment } from 'config/interfaces/comment';
import React, { ReactNode, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Text } from 'components/Text';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { RoundButton } from 'components/Button';
import { EDefaultIconSet, normalizeFontSize, openURL } from 'helper';
import { useTranslation } from 'react-i18next';
import ParsedText from 'react-native-parsed-text';
import { CHATMESSAGECONTENTSIZE } from 'components/Chat/constants';

interface ICommentProps {
	comment: IComment;
	index: number;
}

export const Comment = (props: ICommentProps) => {
	const { comment, index } = props;

	const { t } = useTranslation();
	const { theme } = useTheme();
	const { activeSpace } = useSpace();
	const { iAmSpaceAdmin, iAmSpaceModerator } = useSpace();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isVisibilityLoading, setIsVisibilityLoading] = useState<boolean>(false);

	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const updateComment = useRematchDispatch((dispatch: Dispatch) => dispatch.comment.updateComment);
	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	const _deleteComment = async () => {
		setIsLoading(true);
		await updateComment({ ...comment, isDeletion: true });
		setIsLoading(false);
	};

	const _updateVisibility = async () => {
		setIsVisibilityLoading(true);
		const _isCommentVisible = comment.status === 'Public';
		showAlert({
			title: _isCommentVisible ? t('hideAnswer') : t('showAnswer'),
			message: _isCommentVisible ? t('hideAnswerSubtitle') : t('showAnswerSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: _isCommentVisible ? t('hideAnswer') : t('showAnswer'),
					style: 'destructive',
					onPress: async () => {
						setIsVisibilityLoading(true);
						await updateComment({ ...comment, isDeletion: false, status: _isCommentVisible ? 'Waiting' : 'Public' });
						setIsVisibilityLoading(false);
					}
				}
			]
		});

		setIsVisibilityLoading(false);
	};

	const _handleEmailPress = (email: string) => {
		openURL(`mailto:${email}`);
	};

	const _handlePhonePress = (phoneNumber: string) => {
		openURL(`tel:${phoneNumber}`);
	};

	const _handleUrlPress = (url: string) => {
		openURL(url);
	};

	const _renderVisibility = () => {
		if (comment.status === 'Waiting') {
			return (
				<View style={{ flex: 1 }}>
					<Text center style={{ fontSize: normalizeFontSize(12), color: theme.contrast, marginBottom: 15 }}>
						{t('AnswerWaiting')}
					</Text>
				</View>
			);
		}

		return null;
	};

	const _renderLikes = () => {
		return null;
	};
	const _renderMedia = () => {
		return null;
	};

	const _renderControls = () => {
		const elements: ReactNode[] = [];

		if ((activeSpace?.allowFeedComments === 'moderated' || comment.status === 'Waiting') && (iAmSpaceAdmin || iAmSpaceModerator)) {
			elements.push(
				<RoundButton
					testID={`comment_item_${index}_togglevisibility`}
					onPress={() => _updateVisibility()}
					isLoading={isVisibilityLoading}
					isOutline={comment.status === 'Waiting'}
					icon={comment.status === 'Public' ? EDefaultIconSet.AnswerPublic : EDefaultIconSet.AnswerWaiting}
					size="sm"
				/>
			);
		}

		if (comment.userId === profile?.userId || iAmSpaceAdmin || iAmSpaceModerator) {
			return (
				<View style={{ justifyContent: 'flex-start' }}>
					<RoundButton
						isOutline
						size="sm"
						color={theme.danger}
						icon={EDefaultIconSet.Delete}
						testID={`comment_item_${index}_remove`}
						onPress={() => {
							showAlert({
								title: t('deleteComment'),
								message: t('deleteCommentSubtitle'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: t('deleteComment'),
										style: 'destructive',
										onPress: async () => _deleteComment()
									}
								]
							});
						}}
						isLoading={isLoading}
					/>
					{elements.length > 0 && <View>{elements}</View>}
				</View>
			);
		}

		return null;
	};

	if (comment) {
		const attendee = attendees.find((a) => a.userId === comment.userId);
		return (
			<HSCard testID={`comment_item_${index}`}>
				{_renderVisibility()}
				<View style={{ flexDirection: 'row' }}>
					<View style={{ flex: 1 }}>
						<View style={{ flexDirection: 'row', marginBottom: 10, justifyContent: 'space-between' }}>
							<UserHeader attendee={attendee} isAnonymous={!attendee} createdAt={comment.created_at} />
							{_renderLikes()}
						</View>
						{_renderMedia()}
						<ParsedText
							style={{
								fontSize: CHATMESSAGECONTENTSIZE,
								color: theme.text
							}}
							parse={[
								{ type: 'url', style: styles.link, onPress: _handleUrlPress },
								{ type: 'phone', style: styles.link, onPress: _handlePhonePress },
								{ type: 'email', style: styles.link, onPress: _handleEmailPress },
								{ pattern: /#(\w+)/ }
							]}
						>
							{comment.text}
						</ParsedText>
					</View>
					{_renderControls()}
				</View>
			</HSCard>
		);
	}

	return null;
};

const styles = StyleSheet.create({
	link: {
		textDecorationLine: 'underline'
	}
});
