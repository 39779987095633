import { Dimensions, PixelRatio } from 'react-native';
import { IS_IOS } from './platform';

const { height, width } = Dimensions.get('screen');

const wscale: number = (width > height ? height : width) / 375;

export const normalizeFontSize = (fontSize: number) => {
	const newSize = fontSize * wscale;
	return fontSize;
	if (IS_IOS) {
		return Math.round(PixelRatio.roundToNearestPixel(newSize));
	} else {
		return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
	}
};
