import React, { useEffect, useRef, useState } from 'react';
import Flag from 'react-native-flags';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import moment from 'moment';
import { ScrollView, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { useContent } from 'hooks/useContent';
import { useDetail } from 'hooks/useDetail';
import { useCalendar } from 'hooks/useCalendar';
import { useTheme } from 'hooks/useTheme';
import { useSpace } from 'hooks/useSpace';
import { useTracker } from 'hooks/useTracker';
import { useQuery } from 'hooks/useQuery';
import { IContentTypeField, IExpo, IMedia, IMediaItem, ISchedule, IScheduleStatus, ISpeaker, IStream, IVote } from 'config/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { Button, ChildButton } from 'components/Button';
import { FavoriteButton } from 'components/Button/FavoriteButton';
import { Icon } from 'components/Icon';
import { isEmptyString, EDefaultIconSet, IS_WEB, getQRCodeValueForUser, getDefaultAspectRatioStyle } from 'helper';
import { H2, Text } from 'components/Text';
import { FormLabel } from 'components/Form/FormLabel';
import { Spinner } from 'components/Spinner';
import { FormMultiSwitch, FormTagCloud } from 'components/Form';
import { FormElementBottomMarginSmall } from 'components/Form/constants';
import { StreamPlayer } from 'components/Stream';
import { ERoutes } from 'components/Navigation/routes';
import { Image, PlaceholderGradient } from 'components/Image';
import { hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { showToast } from 'helper/toast';
import { Markdown } from 'components/Markdown';
import { HSCard } from 'components/Card';
import { FormHint } from 'components/Form/FormHint';
import { HSQRCode } from 'components/QRCode';

interface IScheduleDetail {
	itemId?: number;
	setTitle?: (title?: string) => void;
	setHasStream?: (hasStream: boolean) => void;
	getStreamType?: (streamType: any) => void;
	onStatusChange?: (status: IScheduleStatus['status']) => void;
	item?: ISchedule;
	previewImage?: IMedia;
	hasPadding?: boolean;
	onClose?: () => void;
	isPublic?: boolean;
	hasStream?: boolean;
	hideVotings?: boolean;
	detailsOnly?: boolean;
	playerOnly?: boolean;
	isAutoplay?: boolean;
	hideStreamSelection?: boolean;
	noMargin?: boolean;
	isModal?: boolean;
	maxHeight?: number;
}

interface IStateCategories {
	screen?: IContentTypeField;
	rest: IContentTypeField[];
}

interface IStateContent {
	currentItem?: ISchedule;
	scheduleStatus?: IScheduleStatus;
	streams: IStream[];
	speakers: ISpeaker[];
	votings: IVote[];
	expos: IExpo[];
}

const TESTIDPREFIX = 'scheduledetail';

export const ScheduleDetail = (props: IScheduleDetail) => {
	const {
		itemId,
		setTitle,
		setHasStream,
		getStreamType,
		onStatusChange,
		item,
		previewImage,
		hasPadding,
		onClose,
		isPublic,
		hasStream,
		hideVotings,
		detailsOnly,
		playerOnly,
		isAutoplay,
		hideStreamSelection,
		noMargin,
		isModal,
		maxHeight
	} = props;
	const { getContentTypeFields } = useContent('schedule');
	const { renderField, renderReferenceField } = useDetail();
	const { addToCalendar } = useCalendar();
	const { theme } = useTheme();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const { trackAction } = useTracker();
	const navigation = useNavigation();
	const { isTabletOrMobile } = useQuery();

	const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

	const [stateContent, setStateContent] = useState<IStateContent>({
		currentItem: undefined,
		scheduleStatus: undefined,
		streams: [],
		speakers: [],
		votings: [],
		expos: []
	});

	const [scheduleDetailFields, setScheduleDetailFields] = useState<IContentTypeField[]>([]);
	const [previousStatus, setPreviousStatus] = useState<IScheduleStatus | undefined>(undefined);
	const [streamMediaItems, setStreamMediaItems] = useState<IMediaItem[]>([]);
	const [qrCodeVal, setQrCodeVal] = useState<string>();

	const [containerWidth, setContainerWidth] = useState<number>(1);

	const [didInitialBookingLoad, setDidInitialBookingLoad] = useState<boolean>(false);
	const [isBookingLoading, setIsBookingLoading] = useState<boolean>(false);
	const [bookedSlots, setBookedSlots] = useState<number>(0);
	const [iHaveBookedThisEntry, setIHaveBookedThisEntry] = useState<boolean>(false);

	const [showStream, setShowStream] = useState<boolean>(false);

	const [categories, setCategories] = useState<IStateCategories>({ screen: undefined, rest: [] });

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const content = useSelector((store: IRootState) => store.content.content);
	const bookings = useSelector((store: IRootState) => store.booking.bookings);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateScheduleStatus = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateScheduleStatus);
	const setMediaDetailPlaybackStatus = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailPlaybackStatus);
	const setMediaDetailActiveStreamIndex = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailActiveStreamIndex);
	const getBookingCount = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.getBookingCount);
	const loadMyBookings = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.loadMyBookings);
	const bookSlot = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.bookSlot);
	const cancelBooking = useRematchDispatch((dispatch: Dispatch) => dispatch.booking.cancelBooking);

	useEffect(() => {
		trackAction('schedule', 'Open Detail', `${itemId}`);
	}, []);

	useEffect(() => {
		setPreviousStatus(stateContent.scheduleStatus);
		if (stateContent.scheduleStatus?.status) {
			if (stateContent.scheduleStatus.status === 'Live' || stateContent.scheduleStatus.status === 'Replay') {
				setShowStream(true);
			} else {
				if (previousStatus?.status === 'Live') {
					if (stateContent.scheduleStatus.status === 'Upcoming') {
						setShowStream(false);
					} else {
						setShowStream(true);
					}
				} else {
					setShowStream(false);
				}
			}
		}
	}, [stateContent.scheduleStatus]);

	useEffect(() => {
		if (activeSpace && profile) {
			const newQr = getQRCodeValueForUser(activeSpace, profile);
			if (newQr !== qrCodeVal) {
				setQrCodeVal(newQr);
			}
		}
	}, [activeSpace, profile]);

	useEffect(() => {
		let screenCategory: IStateCategories['screen'] = undefined;
		let catFields: IStateCategories['rest'] = [];

		if (activeSpace) {
			getContentTypeFields().forEach((field) => {
				if (field.fieldType === 'category') {
					if (field.useAsFilter === 'modal') {
						catFields.push(field);
					} else if (field.useAsFilter === 'screen') {
						screenCategory = field;
					}
				}
			});
		}

		if (scheduleDetailFields) {
			let _fields = scheduleDetailFields.filter(
				(field) => field.fieldType === 'category' && field.showOnDetailScreen && catFields.includes(field)
			);
			if (_fields) {
				catFields = catFields.filter((field) => {
					if (field.showOnDetailScreen && _fields.includes(field)) {
						return false;
					}
					return true;
				});
			}
		}

		setCategories({
			screen: screenCategory,
			rest: catFields
		});
	}, [activeSpace, scheduleDetailFields]);

	useEffect(() => {
		let _item: IStateContent['currentItem'] = undefined;
		let _status: IStateContent['scheduleStatus'] = undefined;
		let _streams: IStateContent['streams'] = [];
		let _referencedSpeakers: ISpeaker[] = [];
		let _referencedVotings: IVote[] = [];
		let _referencedExpos: IExpo[] = [];

		if (item) {
			_item = item;

			if (content.schedulestatuses) {
				_status = content.schedulestatuses.find((e) => _item && e.schedule?.id === _item.id);
			}

			if (content.streams && _item?.stream && _item?.stream?.length > 0) {
				_item?.stream.map((s) => {
					const found = content.streams.find((e) => e.id === s.id);
					if (found) {
						_streams.push(found);
					}
				});
			}
		} else if (itemId) {
			if (content.schedules) {
				_item = content.schedules.find((e) => e.id === itemId);
			}

			if (content.schedulestatuses) {
				_status = content.schedulestatuses.find((e) => _item && e.schedule?.id === _item.id);
			}

			if (content.streams && _item?.stream && _item?.stream?.length > 0) {
				_item?.stream.map((s) => {
					const found = content.streams.find((e) => e.id === s.id);
					if (found) {
						_streams.push(found);
					}
				});
			}
		}

		if (setHasStream) {
			setHasStream(
				(_streams &&
					_streams.filter((s) => s.streamType !== 'externalMeeting')?.length > 1 &&
					(_status?.status === 'Live' || _status?.status === 'Replay')) ??
					false
			);
		}

		if (setTitle) {
			setTitle(_item?.title);
		}

		if (_item) {
			if (_item.speakers) {
				_referencedSpeakers = _item.speakers
					.map((s) => content.speakers.find((c) => c.id === s.id))
					.filter((e) => e && !e.isDeleted && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator));

				if (_referencedSpeakers.length > 1) {
					_referencedSpeakers = _referencedSpeakers.sort((a, b) => {
						const aSplit = a.title.split(' ');
						const bSplit = b.title.split(' ');

						const aVal = aSplit[aSplit.length - 1] + aSplit[0];
						const bVal = bSplit[bSplit.length - 1] + bSplit[0];

						return aVal.toLowerCase() < bVal.toLowerCase() ? -1 : 1;
					});
				}
			}

			if (_item.votes && activeSpace) {
				_referencedVotings = _item.votes.filter((e) => !e.isDeleted && e.publicId);

				if (_referencedVotings.length > 1) {
					_referencedVotings = _referencedVotings.sort((a, b) => (a.id < b.id ? -1 : 1));
				}
			}

			if (_item.expos && activeSpace) {
				_referencedExpos = _item.expos.filter((e) => !e.isDeleted && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator));

				if (_referencedExpos.length > 1) {
					_referencedExpos = _referencedExpos.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
				}
			}
		}

		setStateContent({
			currentItem: _item,
			scheduleStatus: _status,
			streams: _streams,
			speakers: _referencedSpeakers,
			votings: _referencedVotings,
			expos: _referencedExpos
		});
	}, [itemId, item, content, activeSpace]);

	useEffect(() => {
		if (getStreamType) {
			getStreamType(_getStreamType());
		}

		if (onStatusChange) {
			onStatusChange(stateContent.scheduleStatus?.status ?? 'Upcoming');
		}
	}, [stateContent, mediaDetail]);

	useEffect(() => {
		if (activeSpace && bookings) {
			const _iHaveBookedThisEntry =
				bookings.find((e) => e.spaceId === activeSpace.spaceId && e.type === 'schedule' && e.itemId === itemId) !== undefined;

			setIHaveBookedThisEntry(_iHaveBookedThisEntry);
		}
	}, [activeSpace, bookings]);

	useEffect(() => {
		// TODO
		let _mediaItems: typeof streamMediaItems = [];

		if (stateContent.streams.length > 0 && content.mediaitems) {
			stateContent.streams.map((s) => {
				const found = content.mediaitems.find((e) => e.id === s.mediaitem?.id);
				if (found) {
					_mediaItems.push(found);
				}
			});
		}

		setStreamMediaItems(_mediaItems);
	}, [stateContent.streams, content]);

	useEffect(() => {
		const _fields = getContentTypeFields(true).filter(
			(e) =>
				e.showOnDetailScreen &&
				e.fieldType !== 'avatar' &&
				e.fieldName !== 'title' &&
				e.fieldName !== 'startDate' &&
				e.fieldName !== 'subtitle' &&
				e.fieldName !== 'description'
		);
		setScheduleDetailFields(_fields);
	}, [activeSpace]);

	useEffect(() => {
		if (stateContent.currentItem?.isBookable) {
			_getBookingCount();
		}
	}, [stateContent.currentItem]);

	useEffect(() => {
		// TODO
	}, [stateContent.currentItem, iAmSpaceAdmin, iAmSpaceModerator, content]);

	const _getBookingCount = async () => {
		if (!didInitialBookingLoad && itemId) {
			setDidInitialBookingLoad(true);
			setIsBookingLoading(true);
			await loadMyBookings({});
			const count = await getBookingCount({ itemId, type: 'schedule' });
			setBookedSlots(count);
			setIsBookingLoading(false);
		}
	};

	const _bookSlot = async () => {
		if (itemId && bookings && stateContent.currentItem) {
			const itemStart = moment(stateContent.currentItem.startDate);
			const itemEnd = moment(stateContent.currentItem.endDate);
			if (!activeSpace?.allowParallelBooking) {
				const hasOverlap = bookings.find((e) => {
					const bookedItem = content.schedules.find((e2) => e.itemId === e2.id);
					if (!bookedItem) {
						return false;
					}
					const blockItemStart = moment(bookedItem.startDate);
					const blockItemEnd = moment(bookedItem.endDate);

					return (
						(itemStart.isAfter(blockItemStart) && itemStart.isBefore(blockItemEnd)) ||
						(itemEnd.isAfter(blockItemStart) && itemEnd.isBefore(blockItemEnd)) ||
						itemStart.isSame(blockItemStart)
					);
				});
				if (hasOverlap) {
					showToast('info', t('Booking not possible'), t('schedule.parallel.booking'));
					return;
				}
			}

			setIsBookingLoading(true);
			const res = await bookSlot({
				type: 'schedule',
				itemId
			});

			if (res) {
				trackAction('schedule', 'Book Slot', itemId.toString());
			}

			setIsBookingLoading(false);
		}
	};

	const _cancelBooking = async () => {
		if (itemId) {
			setIsBookingLoading(true);
			const newCount = await cancelBooking({
				type: 'schedule',
				itemId
			});
			setBookedSlots(newCount);
			trackAction('schedule', 'Cancel Booking', itemId.toString());
			setIsBookingLoading(false);
		}
	};

	const _updateStatus = async (newStatus: IScheduleStatus['status']) => {
		if (stateContent.scheduleStatus) {
			setIsStatusLoading(true);
			const res = await updateScheduleStatus({ newStatus, statusId: stateContent.scheduleStatus.id });
			setIsStatusLoading(false);
		}
	};

	const _getStreamURL = () => {
		let _item: IStream | IMediaItem | undefined = stateContent.streams[mediaDetail?.activeStreamIndex ?? 0];

		if (_item?.streamType === 'mediaLibrary' && streamMediaItems?.length > 0) {
			const _mediaItem = streamMediaItems.find((m) => m.id === _item?.mediaitem?.id);
			if (_mediaItem) {
				_item = _mediaItem;
				_item.streamType = _mediaItem.mediaType;
			}
		}
		switch (_item?.streamType) {
			case 'contentflow':
				return _item?.contentflowUrl;
			case 'iFrame':
				return _item?.iFrameUrl;
			case 'upload':
				return _item?.media?.url;
			case 'vimeo':
				return _item?.vimeoUrl;
			case 'youtube':
				return _item?.youTubeUrl;
			case 'externalMeeting':
				return _item?.externalMeetingUrl;
			case 'networking':
				return _item?.networkinRoomId;
			case 'zoom':
				return _item?.zoomMeetingUrl;
			default:
				return undefined;
		}
	};

	const _getZoomData = () => {
		let _item: IStream | IMediaItem | undefined = stateContent.streams[mediaDetail?.activeStreamIndex ?? 0];

		if (_item?.streamType === 'mediaLibrary' && streamMediaItems?.length > 0) {
			const _mediaItem = streamMediaItems.find((m) => m.id === _item?.mediaitem?.id);
			if (_mediaItem) {
				_item = _mediaItem;
				_item.streamType = _mediaItem.mediaType;
			}
		}
		if (_item?.streamType === 'zoom') {
			return {
				zoomMeetingUrl: _item.zoomMeetingUrl,
				zoomMeetingId: _item.zoomMeetingId,
				zoomMeetingPassword: _item.zoomMeetingPassword
			};
		}

		return undefined;
	};

	const _getStreamType = () => {
		const _item = stateContent.streams[mediaDetail.activeStreamIndex ?? 0];
		switch (_item?.streamType) {
			case 'mediaLibrary':
				const streamMediaItem = streamMediaItems.find((m) => m.id === _item?.mediaitem?.id);
				if (streamMediaItem) {
					return streamMediaItem.mediaType;
				}
				return undefined;
			default:
				return _item?.streamType;
		}
	};

	const _renderDate = () => {
		let str = '';

		if (!isEmptyString(stateContent.currentItem?.startDate) && !isEmptyString(stateContent.currentItem?.endDate)) {
			str = `${moment(stateContent.currentItem?.startDate).format('DD.MM.YYYY HH:mm')} - ${moment(
				stateContent.currentItem?.endDate
			).format('HH:mm')}`;
		} else if (!isEmptyString(stateContent.currentItem?.startDate)) {
			str = `${t('Start')}: ${moment(stateContent.currentItem?.endDate).format('DD.MM.YYYY HH:mm')}`;
		} else if (!isEmptyString(stateContent.currentItem?.endDate)) {
			str = `${t('End')}: ${moment(stateContent.currentItem?.endDate).format('DD.MM.YYYY HH:mm')}`;
		}

		if (!isEmptyString(str)) {
			return <Text>{str}</Text>;
		}

		return null;
	};

	const _renderBookingIntro = () => {
		if (stateContent.currentItem?.isBookable) {
			return <View style={{ marginBottom: hsBottomMargin }}>{_renderBookingButton()}</View>;
		}

		return null;
	};

	const _renderBookingButton = () => {
		if (activeSpace && profile && stateContent.currentItem?.isBookable) {
			if (iHaveBookedThisEntry) {
				return (
					<View style={{ alignItems: 'center' }}>
						<Button
							testID={`${TESTIDPREFIX}_button_book`}
							title={t('CancelBooking')}
							isLoading={isBookingLoading}
							type="danger"
							onPress={_cancelBooking}
							style={{ alignSelf: 'center', marginVertical: 20 }}
						/>
						<HSQRCode value={qrCodeVal} size={containerWidth * 0.3} />
						<Text style={{ fontSize: 10, marginTop: 10 }}>{qrCodeVal}</Text>
					</View>
				);
			}

			if (
				stateContent.currentItem?.bookingCapacity !== undefined &&
				stateContent.currentItem?.bookingCapacity !== null &&
				bookedSlots >= stateContent.currentItem.bookingCapacity
			) {
				return (
					<Button
						testID={`${TESTIDPREFIX}_button_book`}
						title={t('Fully booked')}
						type="warning"
						isLoading={isBookingLoading}
						style={{ alignSelf: 'center', marginTop: 20 }}
					/>
				);
			}

			return (
				<Button
					testID={`${TESTIDPREFIX}_button_book`}
					title={t('Book Slot')}
					isLoading={isBookingLoading}
					type="success"
					onPress={_bookSlot}
					style={{ alignSelf: 'center', marginTop: 20 }}
				/>
			);
		}

		return null;
	};

	const _renderActions = () => {
		if (stateContent.currentItem) {
			return (
				<View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
					{!stateContent.currentItem.isBookable && !isPublic && profile && (
						<FavoriteButton testID={`${TESTIDPREFIX}_button_favorite`} type="schedule" id={stateContent.currentItem.id} />
					)}
					{stateContent.currentItem && stateContent.currentItem.startDate && stateContent.currentItem.endDate ? (
						<ChildButton
							testID={`${TESTIDPREFIX}_button_calendar`}
							style={{ marginLeft: 10 }}
							onPress={() => {
								addToCalendar(
									stateContent.currentItem!.title,
									stateContent.currentItem!.startDate,
									stateContent.currentItem!.endDate,
									stateContent.currentItem!.subtitle,
									stateContent.currentItem!.stage?.title
								);
								trackAction('schedule', 'Add to Calendar', `${itemId}`);
							}}
						>
							<Icon name={EDefaultIconSet.CalendarAdd} size={25} />
						</ChildButton>
					) : null}
				</View>
			);
		}

		return null;
	};

	const _getPreviewImage = (item?: IStream, mediaItem?: IMediaItem) => {
		// Dont change this
		// Zoom needs to use the previewimage from the zoom stream item in order to continue on media screen when agenda item changes
		// when the url changes, the call reloads and people need to join in again
		// the url always changes when the previewimage changes

		if (item?.streamType === 'zoom') {
			return item?.previewImage;
		}
		return previewImage ?? item?.previewImage ?? mediaItem?.previewImage;
	};

	const _renderPlayer = () => {
		if (detailsOnly) {
			return null;
		}

		const _item = stateContent.streams[mediaDetail.activeStreamIndex ?? 0];
		const _mediaItem = streamMediaItems.find((m) => m.id === _item?.mediaitem?.id);
		if (_item) {
			if (showStream && !(stateContent.currentItem?.isBookable && !iHaveBookedThisEntry)) {
				return (
					<StreamPlayer
						url={_getStreamURL()}
						type={_getStreamType()}
						buttonText={_item?.externalMeetingButtonText}
						previewImage={_getPreviewImage(_item, _mediaItem)}
						containerStyle={{
							marginBottom: playerOnly ? 0 : hsBottomMargin,
							width: maxHeight ? Math.min((maxHeight * 16) / 9, containerWidth) : '100%',
							alignSelf: 'center'
						}}
						onPlay={() => {
							setMediaDetailPlaybackStatus('playing');
							trackAction('schedule', 'Play Video', `${itemId}`);
						}}
						onPause={() => {
							setMediaDetailPlaybackStatus('paused');
							trackAction('schedule', 'Stop Video', `${itemId}`);
						}}
						onExternalClick={() => trackAction('schedule', 'Open External URL', `${itemId}`)}
						isMute={IS_WEB}
						isAutoplay={IS_WEB || isAutoplay}
						zoomData={_getZoomData()}
					/>
				);
			}
		}

		if (previewImage || _item?.previewImage || _mediaItem?.previewImage) {
			return (
				<View>
					<Image
						testID={`${TESTIDPREFIX}_image`}
						mediaObj={previewImage ?? _item?.previewImage ?? _mediaItem?.previewImage}
						imageSize="large"
						style={{
							...getDefaultAspectRatioStyle(containerWidth * 0.95),
							alignSelf: 'center',
							marginBottom: hsBottomMargin,
							maxHeight: maxHeight
						}}
						contentFit="contain"
					/>
				</View>
			);
		}

		if (item && activeSpace?.showGradientOnMissingImage) {
			return (
				<View style={{ maxHeight: maxHeight, alignItems: 'center' }}>
					<PlaceholderGradient
						itemId={item?.id}
						title={item?.title}
						width={maxHeight ? Math.min((maxHeight * 16) / 9, containerWidth) : containerWidth}
						rounded={'full'}
					/>
				</View>
			);
		}
		return null;
	};

	const _renderFlags = (languages: string) => {
		const flags = languages.split(',');
		return (
			<View style={{ flexDirection: 'row' }}>
				{flags.map((flag) => (
					<View key={`${TESTIDPREFIX}_${item?.id}_flag_${flag}`} style={{ paddingRight: 2 }}>
						<Flag code={flag} size={24} style={{ alignSelf: 'center' }} />
					</View>
				))}
			</View>
		);
	};

	const _renderVideoSelection = () => {
		if (stateContent.currentItem?.isBookable && !iHaveBookedThisEntry) {
			return null;
		}
		if (detailsOnly || hideStreamSelection) {
			return null;
		}
		if (hasStream && stateContent.streams?.length > 0) {
			return (
				<View
					style={{
						flexDirection: 'row',
						marginBottom: playerOnly ? 0 : hsBottomMargin,
						padding: playerOnly ? hsInnerPadding / 2 : 0
					}}
				>
					{stateContent.streams.map((stream, index) => {
						const isActive = index === mediaDetail.activeStreamIndex ?? 0;
						return (
							<ChildButton
								key={`${TESTIDPREFIX}_button_stream_${index}`}
								testID={`${TESTIDPREFIX}_button_stream_${index}`}
								onPress={() => {
									setMediaDetailActiveStreamIndex(index);
								}}
								style={[
									{
										marginRight: 10,
										borderWidth: 1,
										borderRadius: 5,
										borderColor: theme.primary,
										paddingHorizontal: 5,
										paddingVertical: 2,
										justifyContent: 'center'
									},
									isActive ? { backgroundColor: theme.primary } : { backgroundColor: theme.primaryContrast }
								]}
							>
								{stream.languages && !isEmptyString(stream.languages) ? (
									_renderFlags(stream.languages)
								) : (
									<Text
										bold={isActive}
										style={{
											color: isActive ? theme.primaryContrast : theme.primary,
											fontSize: 12
										}}
									>
										{stream.title}
									</Text>
								)}
							</ChildButton>
						);
					})}
				</View>
			);
		}
		return null;
	};

	const _renderStatus = () => {
		if (stateContent.currentItem && mediaDetail.viewType === 'full' && (iAmSpaceAdmin || iAmSpaceModerator)) {
			return (
				<View style={{ marginTop: 5 }}>
					<FormLabel testID={`${TESTIDPREFIX}_label_status`} label={t('ScheduleStatus')} style={{ marginBottom: 5 }} />
					<View>
						{isStatusLoading && (
							<View
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									alignItems: 'center',
									justifyContent: 'flex-end'
								}}
							>
								<Spinner size={'small'} />
							</View>
						)}
						<FormMultiSwitch
							formStyle={{ marginBottom: 0 }}
							testID={`${TESTIDPREFIX}_multiswitch_status`}
							onChange={(newValue) => {
								if (newValue && newValue !== stateContent.scheduleStatus?.status) {
									showAlert({
										title: t('UpdateStatusTitle'),
										message: t('UpdateStatusSubtitle').replace('%STATUS%', newValue),
										buttons: [
											{
												text: t('Cancel'),
												style: 'cancel'
											},
											{
												text: t('UpdateStatusTitle'),
												onPress: () => _updateStatus(newValue as IScheduleStatus['status'])
											}
										]
									});
								}
							}}
							isDisabled={isStatusLoading}
							value={stateContent.scheduleStatus?.status}
							options={[
								{
									key: 'Upcoming',
									label: t('Upcoming')
								},
								{
									key: 'Live',
									label: t('Live'),
									backgroundColor: theme.danger,
									textColor: theme.white
								},
								{
									key: 'Replay',
									label: t('Replay')
								},
								{
									key: 'Finished',
									label: t('Finished')
								}
							]}
						/>
						<View style={{ marginBottom: 5, alignItems: 'center' }}>
							<FormHint
								testID={''}
								hint={`${t('autoUpdateStatus')}: ${
									stateContent.currentItem?.autoUpdateStatus ? t('Active') : t('Inactive')
								}`}
							/>
						</View>
					</View>
				</View>
			);
		}

		return null;
	};

	const _renderAdminSection = () => {
		if (iAmSpaceAdmin || iAmSpaceModerator) {
			return (
				<View style={{ marginVertical: hsBottomMargin, alignSelf: 'center' }}>
					<H2 center>{t('Administrate')}</H2>
					{_renderStatus()}
					{stateContent.currentItem?.isBookable && (
						<Button
							testID={`${TESTIDPREFIX}_button_manageBookings`}
							title={t('Manage Bookings')}
							isDisabled={isBookingLoading}
							onPress={() => {
								if (onClose) {
									onClose();
								}
								navigation.navigate(ERoutes.BookingDetails, {
									spaceId: activeSpace?.spaceId,
									id: stateContent.currentItem?.id
								});
							}}
							style={{ alignSelf: 'center' }}
						/>
					)}
				</View>
			);
		}
		return null;
	};

	const _getWebBottomMargin = () => {
		if (!isTabletOrMobile || !IS_WEB) {
			return 0;
		}
		const isZoom = _getStreamType() === 'zoom';
		let margin = Math.max((containerWidth / 16) * 9 + (isZoom ? 100 : 0), isZoom ? 700 : 0);
		if (maxHeight && margin > maxHeight) {
			margin = maxHeight;
		}
		return margin;
	};

	if (stateContent.currentItem) {
		if (playerOnly) {
			return (
				<HSCard
					style={{
						padding: 0,
						marginBottom: _getWebBottomMargin(),
						flex: hideStreamSelection ? 1 : 0,
						maxHeight: maxHeight
					}}
					onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				>
					{_renderPlayer()}
					{_renderVideoSelection()}
				</HSCard>
			);
		}
		console.log(scheduleDetailFields.filter((e) => e.fieldType === 'reference'));

		return (
			<ScrollView
				testID={`${TESTIDPREFIX}_scrollview`}
				scrollEnabled={mediaDetail.viewType === 'full'}
				onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
				style={{
					paddingHorizontal: hasPadding && mediaDetail.viewType === 'full' ? hsInnerPadding : 0,
					marginBottom: noMargin ? 0 : hsBottomMargin
				}}
			>
				{mediaDetail.viewType === 'full' && (
					<View style={{ alignItems: 'center' }}>
						<View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
							{_renderDate()}
							{_renderActions()}
						</View>
						{!isEmptyString(stateContent.currentItem.subtitle) && (
							<H2 style={{ fontSize: 18, fontWeight: 'bold' }}>{stateContent.currentItem.subtitle}</H2>
						)}
					</View>
				)}
				{_renderPlayer()}
				{_renderVideoSelection()}
				{mediaDetail.viewType === 'full' && (
					<View>
						<View style={{ alignItems: 'center' }}>
							{categories.screen && (
								<FormTagCloud
									testID={`${TESTIDPREFIX}_tagcloud_screen`}
									options={categories.screen.options}
									value={stateContent.currentItem[categories.screen.fieldName]}
									isDisabled
									showOnlyValues
									formStyle={{ justifyContent: 'center', marginBottom: 10 }}
								/>
							)}
							{!isEmptyString(stateContent.currentItem.description) && (
								<Markdown
									markdown={stateContent.currentItem.description}
									containerStyle={{ marginBottom: FormElementBottomMarginSmall }}
								/>
							)}
							{_renderBookingIntro()}
							{categories.rest.map((field) => (
								<FormTagCloud
									key={`tagcloud_${field.fieldName}`}
									testID={`${TESTIDPREFIX}_tagcloud_${field.fieldName}`}
									options={field.options}
									label={field.fieldLabel}
									showOnlyValues
									formStyle={{ justifyContent: 'center' }}
									value={stateContent.currentItem ? stateContent.currentItem[field.fieldName] : undefined}
									isDisabled
								/>
							))}
						</View>
						{scheduleDetailFields.map((field) => {
							if (field.fieldType === 'category' && field.useAsFilter === 'screen') {
								return null;
							}

							let items: any[] = [];

							switch (field.referenceType) {
								case 'speakers':
									items = stateContent.speakers;
									break;
								case 'votings':
									if (hideVotings) {
										return null;
									}
									items = stateContent.votings;
									break;
								case 'expos':
									items = stateContent.expos;
									break;
								default:
									break;
							}
							if (field.fieldType === 'reference') {
								if (field.fieldName === 'speakers') {
									if ((item?.speakerGroups?.groups ?? []).length > 0) {
										const unhandledSpeakers: ISpeaker[] = [];
										items.forEach((spItem) => {
											const foundInGroup = item?.speakerGroups?.groups.find((e) => e.speakerIds.includes(spItem.id));
											if (!foundInGroup) {
												unhandledSpeakers.push(spItem);
											}
										});
										return (
											<>
												{item?.speakerGroups?.groups.map((group) => {
													const _groupItems = items.filter((e) => group.speakerIds.includes(e.id));
													return renderReferenceField(
														{ ...field, fieldName: group.key, fieldLabel: group.title },
														_groupItems,
														TESTIDPREFIX,
														activeSpace,
														isModal,
														isPublic,
														hasStream,
														false,
														true
													);
												})}
												{unhandledSpeakers.length > 0
													? renderReferenceField(
															field,
															unhandledSpeakers,
															TESTIDPREFIX,
															activeSpace,
															isModal,
															isPublic,
															hasStream,
															false
													  )
													: null}
											</>
										);
									}
								}

								return renderReferenceField(field, items, TESTIDPREFIX, activeSpace, isModal, isPublic, hasStream);
							}

							return renderField(field, stateContent.currentItem, TESTIDPREFIX, 'schedule', activeSpace);
						})}
					</View>
				)}
				{_renderAdminSection()}
			</ScrollView>
		);
	}

	return (
		<View style={{ flex: 1, justifyContent: 'center' }}>
			<Spinner size={'large'} />
		</View>
	);
};
