import { HSCard } from 'components/Card';
import { FormLabel } from 'components/Form/FormLabel';
import { FormTextInput } from 'components/Form/FormTextInput';
import { FormMediaPicker } from 'components/Form/Image';
import { Text } from 'components/Text/Text';
import { VoteAnswerTextListItem } from 'components/Vote/VoteAnswer/Text/VoteAnswerTextListItem';
import { IVote, IVoteAnswer } from 'config/interfaces';
import { hsBottomMargin } from 'config/styleConstants';
import * as ImagePicker from 'expo-image-picker';
import { isEmptyString } from 'helper';
import { useTheme } from 'hooks/useTheme';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, View } from 'react-native';
import { Dispatch, useRematchDispatch } from 'rematch/store';

import { HSModal } from './Modal';

interface IVoteModerationTextModal {
	vote: IVote;
	item?: IVoteAnswer;
	onClose: () => void;
	onSubmit: (answer: IVoteAnswer) => Promise<boolean>;
	isLoading?: boolean;
}

const TESTIDPREFIX = 'votemoderationtexttmodal';

export const VoteModerationTextModal = (props: IVoteModerationTextModal) => {
	const { vote, item, onClose, onSubmit, isLoading } = props;
	const { t } = useTranslation();
	const { theme } = useTheme();

	const [values, setValues] = useState<IVoteAnswer | undefined>(undefined);
	const [isDirty, setIsDirty] = useState<boolean>(false);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);

	useEffect(() => {
		if (item) {
			setValues({ ...item });
		} else {
			setValues(undefined);
		}
	}, [item]);

	const _save = async () => {
		if (values) {
			const res = await onSubmit(values);
			if (res) {
				setIsDirty(false);
				onClose();
			}
		}
	};

	const _renderForm = () => {
		if (values && item) {
			return (
				<ScrollView
					keyboardShouldPersistTaps="handled"
					onScrollBeginDrag={() => Keyboard.dismiss()}
					scrollEventThrottle={0}
					style={{ flex: 1, marginHorizontal: -hsBottomMargin }}
					contentContainerStyle={{ paddingHorizontal: hsBottomMargin }}
				>
					<Text bold>{t('Current')}</Text>
					<VoteAnswerTextListItem itemIndex={0} vote={vote} item={item} isPreview />
					<HSCard>
						{vote.allowMedia && (
							<View>
								<FormLabel
									testID={`${TESTIDPREFIX}_label_uplodmedia`}
									label={values.media ? t('Change Media') : t('Upload Media')}
								/>
								<FormMediaPicker
									formStyle={{ marginBottom: hsBottomMargin }}
									testID={`${TESTIDPREFIX}_button_uplodmedia`}
									value={values.media ?? null}
									error={''}
									onChange={(val) => setValues({ ...values, media: val })}
									mediaTypes={ImagePicker.MediaTypeOptions.All}
									hasDeleteButton
								/>
							</View>
						)}
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_value`}
							label={t('Entry')}
							isRequired
							multiline
							value={values?.value}
							onChangeText={(val) => {
								setValues({ ...values, value: val });
								setIsDirty(true);
							}}
						/>
						<FormTextInput
							testID={`${TESTIDPREFIX}_textinput_info`}
							label="Feedback"
							multiline
							value={values.info}
							onChangeText={(val) => {
								setValues({ ...values, info: val });
								setIsDirty(true);
							}}
						/>
					</HSCard>
				</ScrollView>
			);
		}

		return null;
	};

	return (
		<HSModal
			isVisible={item !== undefined}
			onClose={() => {
				if (isDirty) {
					showAlert({
						title: t('unsavedChanges'),
						message: t('unsavedChangesSubtitle'),
						buttons: [
							{
								text: t('Cancel'),
								style: 'cancel'
							},
							{
								text: t('leaveWithoutSaving'),
								style: 'destructive',
								onPress: () => onClose()
							}
						]
					});
				} else {
					onClose();
				}
			}}
			onSubmit={() => _save()}
			isSubmitLoading={isLoading}
			isSubmitDisabled={isEmptyString(values?.value)}
			title={t('Edit Entry')}
		>
			{_renderForm()}
		</HSModal>
	);
};
