import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { Dimensions, Platform, ScrollView, View } from 'react-native';
import { ERoutes } from 'components/Navigation/routes';
import { NoSpaceStackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { H1, H2, Text } from 'components/Text';
import { ILauncherSpace } from 'config/interfaces';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { hsBorderRadius, hsTopScreenPadding } from 'config/styleConstants';
import { HSCard } from 'components/Card';
import { isEmptyString, IS_ANDROID, IS_IOS, IS_WEB, openURL } from 'helper';
import { NoData } from 'components/NoData';
import { Spinner } from 'components/Spinner';
import { EDefaultIconSet, getSpaceShareUrl } from 'helper';
import { Image } from 'components/Image';
import { RoundButton } from 'components/Button';
import { FormCheckbox, FormTextInput } from 'components/Form';
import { HSQRCode } from 'components/QRCode';
import { isEmptyObject } from 'helper/object';
import { DEFAULT_PLATFORM_URL, GOOGLE_PLAYSTORE_URL, IOS_APPSTORE_URL } from 'config/envConstants';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.SpaceOnboarding>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.SpaceOnboarding>;
type RouteParams = NoSpaceStackParamList[ERoutes.SpaceOnboarding];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'spaceonboarding';
const defaultLogo = require('../../assets/images/defaultLogo/defaultLogo.png');
const defaultLogoGray = require('../../assets/images/defaultLogo/defaultLogoGray.png');

export const SpaceOnboardingScreen = ({ route, navigation }: Props) => {
	const { isTabletOrMobile, MOBILE_BREAKPOINT } = useQuery();
	const { t } = useTranslation();
	const { theme } = useTheme();
	const [space, setSpace] = useState<ILauncherSpace | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [qrCodeSize, setQrCodeSize] = useState<number>(0);
	const findSpaceById = useRematchDispatch((dispatch: Dispatch) => dispatch.space.findSpaceById);
	const isWelcomeModalVisible = useSelector((store: IRootState) => store.space.isWelcomeModalVisible);
	const setIsWelcomeModalVisible = useRematchDispatch((dispatch: Dispatch) => dispatch.space.setIsWelcomeModalVisible);
	const screenWidth = Dimensions.get('window').width;

	const _maxWidth = screenWidth <= MOBILE_BREAKPOINT ? '100%' : '85%';

	useEffect(() => {
		if (!IS_WEB) {
			navigation.replace(ERoutes.SpaceSummary, { spaceId: route.params.spaceId });
		}
	}, []);

	useEffect(() => {
		setIsWelcomeModalVisible(false);
	}, [isWelcomeModalVisible]);

	useEffect(() => {
		setIsLoading(true);
		if (route.params?.spaceId) {
			getSpaceById(route.params.spaceId);
		}
	}, [route.params]);

	useEffect(() => {
		if (space) {
			i18next.changeLanguage(space.language);
			theme.text = space.textColor ?? theme.text;
			theme.primary = space.primaryColor ?? theme.primary;
			theme.primaryContrast = space.primaryContrastColor ?? theme.primaryContrast;
			theme.contrast = space.contrastColor ?? theme.contrast;
			theme.contentBackgroundColor = space.contentBackgroundColor ?? theme.contentBackgroundColor;
		}
	}, [space]);

	const getSpaceById = async (id: string) => {
		if (id) {
			const res = await findSpaceById({ spaceId: id, noToast: true });
			if (res?.spaceId) {
				setSpace(res);
			}
			setIsLoading(false);
		}
	};

	const getDeviceOS = () => {
		try {
			const toMatchAndroid = [/Android/i];
			const toMatchIOS = [/iPhone/i, /iPad/i, /iPod/i];
			if (IS_WEB) {
				if (
					toMatchAndroid.some((toMatchItem) => {
						return navigator.userAgent.match(toMatchItem);
					})
				) {
					return 'android';
				}

				if (
					toMatchIOS.some((toMatchItem) => {
						return navigator.userAgent.match(toMatchItem);
					})
				) {
					return 'ios';
				}

				return 'web';
			}
			if (IS_IOS) {
				return 'ios';
			}
			if (IS_ANDROID) {
				return 'android';
			}
		} catch (error) {
			return undefined;
		}
	};

	const _renderLogo = () => {
		if (space) {
			let src = '';
			switch (space.logoImageName) {
				case 'defaultLogoGray':
					src = defaultLogoGray;
					break;
				case 'defaultLogo':
				case 'default':
				default:
					src = defaultLogo;
					break;
			}
			if (!isEmptyString(src) || space.logoImage) {
				return (
					<View style={{ width: '100%', alignItems: 'center' }}>
						<Image
							mediaObj={space.logoImage}
							imageSize={IS_WEB ? 'full' : 'small'}
							url={src ?? space.logoImage?.url}
							style={{ width: '60%', maxWidth: 300, aspectRatio: 16 / 9, alignSelf: 'center', marginBottom: 2 }}
						/>
						<H1 center noBottomMargin>
							{space.title}
						</H1>
					</View>
				);
			}
		}

		return null;
	};

	const _renderSpaceSelection = () => {
		return (
			<View
				style={{
					width: '100%',
					maxWidth: 400,
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderRadius: hsBorderRadius,
					borderWidth: 1,
					borderColor: space?.primaryColor,
					paddingHorizontal: 8,
					paddingVertical: 2,
					marginTop: 10
				}}
			>
				<FormTextInput
					formStyle={{ marginBottom: 0, flex: 1, justifyContent: 'center' }}
					style={{ paddingLeft: 2 }}
					testID={`${TESTIDPREFIX}_textinput_spaceid`}
					value={space?.spaceId}
					autoCapitalize="none"
					biggerTextInput
					editable={false}
				/>
				<View
					style={{
						flexDirection: 'row',
						paddingLeft: 5,
						alignSelf: 'center',
						alignItems: 'center'
					}}
				>
					{getDeviceOS() !== 'web' && (
						<RoundButton testID={`${TESTIDPREFIX}_button_qrcodescanner`} icon={EDefaultIconSet.QRCode} isOutline noMargin />
					)}
					<RoundButton testID={`${TESTIDPREFIX}_button_search`} icon={EDefaultIconSet.Search} isStacked noMargin />
				</View>
			</View>
		);
	};

	const _renderDeviceButton = () => {
		if (!IS_WEB) {
			return null;
		}
		if (getDeviceOS() === 'android' || Platform.OS === 'android') {
			return (
				<View style={{ borderRadius: hsBorderRadius }}>
					<img
						alt="Android"
						src="https://hellospaces.de/_next/static/media/loadGooglePlayDe.9f51bf08.png"
						onClick={() => openURL(GOOGLE_PLAYSTORE_URL)}
						style={{ maxWidth: 200, cursor: 'pointer' }}
					/>
				</View>
			);
		}
		if (getDeviceOS() === 'ios' || Platform.OS === 'ios') {
			return (
				<View style={{ borderRadius: hsBorderRadius }}>
					<img
						alt="iOS"
						src="https://hellospaces.de/_next/static/media/loadAppStoreDe.9ade067d.png"
						onClick={() => openURL(IOS_APPSTORE_URL)}
						style={{ maxWidth: 200, cursor: 'pointer' }}
					/>
				</View>
			);
		}
		if (getDeviceOS() === 'web' || Platform.OS === 'web') {
			const _url = space?.spaceId ? getSpaceShareUrl(space.spaceId) : DEFAULT_PLATFORM_URL;
			return (
				<View style={{ borderRadius: hsBorderRadius }}>
					<img
						alt="Web"
						src="https://hellospaces.de/_next/static/media/openWebDe.ef8cbddd.png"
						onClick={() => openURL(_url)}
						style={{ maxWidth: 200, cursor: 'pointer' }}
					/>
				</View>
			);
		}
	};

	const _renderTermsForm = () => {
		return (
			<View
				style={{
					width: '100%',
					maxWidth: 500,
					borderRadius: hsBorderRadius,
					borderWidth: 1,
					borderColor: space?.primaryColor,
					paddingHorizontal: 8,
					paddingVertical: 6,
					marginTop: 10,
					alignItems: 'center'
				}}
			>
				<View>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_acknowledgeterms`}
						label={
							!isEmptyString(space?.dataProtectionAcknowledgedText)
								? space?.dataProtectionAcknowledgedText
								: t('acknowledgeTerms')
						}
						value={true}
						style={{ marginBottom: 5 }}
						isRequired
					/>
					<FormCheckbox
						testID={`${TESTIDPREFIX}_checkbox_agreetoterms`}
						label={!isEmptyString(space?.dataProtectionAcceptedText) ? space?.dataProtectionAcceptedText : t('agreeToTerms')}
						value={true}
						style={{ marginBottom: 0 }}
						isRequired
					/>
				</View>
			</View>
		);
	};

	const _renderLaunchButton = () => {
		return (
			<View
				style={{
					width: '100%',
					maxWidth: 400,
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					alignSelf: 'center',
					flexWrap: 'wrap',
					marginTop: 10
				}}
			>
				{space?.ticketSettings?.ticketRequired && (
					<View style={{ alignSelf: 'center', marginBottom: 5 }}>
						<RoundButton
							testID={`${TESTIDPREFIX}_button_fetchticket`}
							title={t('checkTicketcode')}
							icon={EDefaultIconSet.Save}
							isFloatingButton
						/>
					</View>
				)}
				<RoundButton
					testID={`${TESTIDPREFIX}_button_enterspace`}
					title={t('Launch')}
					icon={EDefaultIconSet.Rocket}
					isFloatingButton
				/>
			</View>
		);
	};

	const _renderRegisterButton = () => {
		return (
			<View style={{ marginTop: 10, flexDirection: 'row', justifyContent: 'center', alignSelf: 'center' }}>
				<RoundButton testID="authentication_button_register" title={t('Register')} icon={EDefaultIconSet.Save} isFloatingButton />
			</View>
		);
	};

	const _renderSteps = () => {
		let _step = 0;

		if (space && !space.isDeleted && !space.isDeactivated) {
			return (
				<View
					style={{
						minWidth: '45%',
						maxWidth: '100%',
						alignSelf: 'center',
						alignItems: 'center'
					}}
				>
					<HSCard>
						<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
							<H1 center largerText>
								{t('SpaceOnboardingTitle')}
							</H1>
							{_renderLogo()}
						</View>
					</HSCard>
					<HSCard>
						<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
							<H2 center>{`${++_step}. ${t('InstallApp')}`}</H2>
							<View
								style={{
									flex: 1,
									flexDirection: 'row',
									alignSelf: 'center',
									alignItems: 'center',
									justifyContent: 'center',
									width: '100%',
									flexWrap: 'wrap',
									marginTop: 2,
									marginBottom: -5
								}}
							>
								{_renderDeviceButton()}
							</View>
						</View>
					</HSCard>
					<HSCard onLayout={(e) => setQrCodeSize(e.nativeEvent.layout.width * 0.6)}>
						<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
							<H2 center>{`${++_step}. ${t('SelectSpace')}`}</H2>
							<Text>
								{t('SpaceOnboardingSelectSpaceByIdText').replace(`'%SpaceID%'`, '')}
								{`'`}
								<Text bold>{space?.spaceId}</Text>
								{`'`}
								{` ${t('SpaceOnboardingSelectSpaceQrAddon')}`}
								{':'}
							</Text>
							{_renderSpaceSelection()}
							{getDeviceOS() === 'web' && !isTabletOrMobile && (
								<View style={{ alignSelf: 'center', marginTop: 20 }}>
									<HSQRCode value={getSpaceShareUrl(space?.spaceId)} size={qrCodeSize > 300 ? 300 : qrCodeSize} />
								</View>
							)}
						</View>
					</HSCard>
					{(!isEmptyString(space?.customUserConsentText) || space?.dataProtectionUrl || space?.readTermsUrl) && (
						<HSCard>
							<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
								<H2 center>{`${++_step}. ${t('AcceptTerms')}`}</H2>
								<Text>{t('agreeToTerms')}</Text>
								{_renderTermsForm()}
							</View>
						</HSCard>
					)}
					<HSCard>
						{space?.ticketSettings?.ticketRequired ? (
							<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
								<H2 center>{`${++_step}. ${t('Enter Ticketcode')} & ${t('StartSpace').toLowerCase()}`}</H2>
								<Text>{`${t('HelloSpacesTicketIntroPlaceholder')}\n${t('SpaceOnBoardingStartSpaceText').replace(
									'%START%',
									t('Launch')
								)}`}</Text>
							</View>
						) : (
							<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
								<H2 center>{`${++_step}. ${t('StartSpace')}`}</H2>
								<Text>{t('SpaceOnBoardingStartSpaceText').replace('%START%', t('Launch'))}</Text>
							</View>
						)}
						{_renderLaunchButton()}
					</HSCard>
					<HSCard>
						<View style={{ width: _maxWidth, alignSelf: 'center', alignItems: 'center' }}>
							<H2 center>{`${++_step}. ${t('CreateProfile')}`}</H2>
							<Text>{`${t('SpaceOnboardingLogInText')}`}</Text>
							{_renderRegisterButton()}
						</View>
					</HSCard>
				</View>
			);
		}

		if (IS_WEB) {
			return (
				<View style={{ width: screenWidth <= MOBILE_BREAKPOINT ? '100%' : '45%', alignSelf: 'center', alignItems: 'center' }}>
					<NoData type="SpaceIdNotFound">
						<RoundButton
							testID={`${TESTIDPREFIX}_button_goback`}
							onPress={() => navigation.navigate(ERoutes.SpaceSelect, {})}
							icon={EDefaultIconSet.ArrowLeft}
							title={t('Back')}
							isFloatingButton
						/>
					</NoData>
				</View>
			);
		}
		return null;
	};

	return (
		<ScreenContainer
			bgImage={space?.backgroundImage}
			bgImageName={space?.backgroundImageName}
			style={{
				backgroundColor:
					isEmptyObject(space?.backgroundImage) && isEmptyString(space?.backgroundImageName)
						? space?.backgroundColor
						: 'transparent'
			}}
		>
			<View
				style={{
					flex: 1,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				<ScrollView
					style={{
						width: '100%',
						height: '100%',
						alignSelf: 'center',
						paddingTop: hsTopScreenPadding
					}}
					contentContainerStyle={{
						paddingHorizontal: EHorizontalScreenPadding.Wide
					}}
				>
					{isLoading ? <Spinner /> : _renderSteps()}
				</ScrollView>
			</View>
		</ScreenContainer>
	);
};

export const SpaceOnboardingScreenHeader = (props: NativeStackHeaderProps) => {
	return null;
};
