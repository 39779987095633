import { ChildButton } from 'components/Button';
import { ITabSwitcherButton } from 'config/interfaces';
import React, { useState } from 'react';
import { ScrollView, View, ViewStyle } from 'react-native';
import { Text } from 'components/Text';
import { useQuery } from 'hooks/useQuery';
import { useTheme } from 'hooks/useTheme';
import { hsBorderRadius } from 'config/styleConstants';
import { getTopRadiusStyle } from 'helper';

interface ITabView {
	testIdPrefix?: string;
	items: ITabSwitcherButton[];
	activeKey?: any;
	onPress: (key: string) => void;
	isDisabled?: boolean;
	scrollEnabled?: boolean;
	containerStyle?: ViewStyle;
	autoWidth?: boolean;
}

export const TabView = (props: ITabView) => {
	const { testIdPrefix, items, activeKey, onPress, isDisabled, scrollEnabled, containerStyle, autoWidth } = props;
	const { isTabletOrMobile, screenColumnCountSmallItems } = useQuery();
	const { theme } = useTheme();
	const [scrollWidth, setScrollWidth] = useState<number>(0);

	const tabColumns = screenColumnCountSmallItems ? screenColumnCountSmallItems + 1 : !isTabletOrMobile ? 4 : 3;

	const _scrollEnabled = scrollEnabled || items.length > tabColumns;

	const _renderButtons = () => {
		return items.map((item) => {
			return (
				<ChildButton
					key={`${testIdPrefix}_button_${item.key}`}
					testID={`${testIdPrefix}_button_${item.key}`}
					onPress={() => onPress(item.key)}
					isDisabled={isDisabled}
					style={[
						{
							borderBottomColor: item.key === activeKey ? theme.primary : theme.contentBackgroundColor,
							alignItems: 'center',
							justifyContent: 'center',
							width: autoWidth || _scrollEnabled ? 'auto' : `${100 / items.length}%`,
							borderBottomWidth: 2,
							padding: 10
						},
						_scrollEnabled ? { minWidth: scrollWidth / items.length } : {},
						autoWidth ? { paddingHorizontal: 15 } : {}
					]}
					activeOpacity={1}
				>
					<Text
						center
						bold={item.key === activeKey}
						adjustsFontSizeToFit
						minimumFontScale={0.75}
						numberOfLines={1}
						style={{ color: theme.primary, fontSize: 16 }}
					>
						{item.label}
					</Text>
				</ChildButton>
			);
		});
	};

	if (_scrollEnabled) {
		return (
			<ScrollView
				testID={`${testIdPrefix}_scrollview`}
				horizontal
				scrollEnabled
				overScrollMode="always"
				style={{
					backgroundColor: theme.contentBackgroundColor,
					...getTopRadiusStyle(hsBorderRadius),
					...containerStyle
				}}
				onLayout={(e) => setScrollWidth(e.nativeEvent.layout.width)}
				contentContainerStyle={containerStyle}
			>
				{_renderButtons()}
			</ScrollView>
		);
	}

	return (
		<View
			style={{
				flexDirection: 'row',
				backgroundColor: theme.contentBackgroundColor,
				...getTopRadiusStyle(hsBorderRadius),
				...containerStyle
			}}
		>
			{_renderButtons()}
		</View>
	);
};
