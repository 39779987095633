import React, { memo } from 'react';
import { View } from 'react-native';

import { Text } from 'components/Text';
import { useTheme } from 'hooks/useTheme';
import moment from 'moment';

interface IVerticalGridTimeCell {
	item: string;
	height: number;
	width: number;
}

export const VerticalGridTimeCell = memo((props: IVerticalGridTimeCell) => {
	const { item, height, width } = props;
	const { theme } = useTheme();

	return (
		<View style={{ width, height, alignItems: 'center', borderTopWidth: 1, borderColor: theme.lightgray }}>
			<Text style={{ position: 'absolute', top: 0, color: theme.lightgray, fontSize: 12 }}>{moment(item).format('HH:mm')}</Text>
		</View>
	);
});
