import React, { useState } from 'react';
import { IMediaGalleryItem } from 'config/interfaces';
import { FlatList, View } from 'react-native';
import { VoteMediaItem } from './VoteMediaItem';
import { FormElementBottomMargin } from 'components/Form/constants';
import { ListBubbles } from 'components/List';
import { IS_WEB } from 'helper/platform';

interface IVoteMedia {
	media?: IMediaGalleryItem[];
	voteId: number;
}

export const VoteMedia = (props: IVoteMedia) => {
	const { media, voteId } = props;

	const [containerWidth, setContainerWidth] = useState<number>(1);
	const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(0);

	if (media && media.length > 0) {
		return (
			<View style={{ marginBottom: FormElementBottomMargin }} onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}>
				<FlatList
					data={media}
					pagingEnabled
					showsHorizontalScrollIndicator={false}
					renderItem={({ item }) => <VoteMediaItem voteId={voteId} item={item.media} containerWidth={containerWidth} />}
					keyExtractor={(item, index) => `votemedia_${voteId}_${item ? item['id'] : index}`}
					horizontal
					style={{ width: containerWidth }}
					onMomentumScrollEnd={(e) => setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth)}
					onScroll={(e) => {
						if (IS_WEB) {
							setCurrentMediaIndex(e.nativeEvent.contentOffset.x / containerWidth);
						}
					}}
				/>
				<ListBubbles totalCount={media.length} activeIndex={currentMediaIndex} />
			</View>
		);
	}

	return null;
};
