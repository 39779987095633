import React, { useEffect, useRef, useState } from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderDropdown,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useTranslation } from 'react-i18next';
import { useContent } from 'hooks/useContent';
import { useExport } from 'hooks/useExport';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { hsTopScreenPadding } from 'config/styleConstants';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import RBSheet from 'react-native-raw-bottom-sheet';
import { BottomSheet, BottomSheetViewButton } from 'components/BottomSheet';
import { EDefaultIconSet, isEmptyString, IS_WEB } from 'helper';
import { SearchBar } from 'components/SearchBar';
import { HSCard } from 'components/Card';
import { ISchedule, IScheduleStatus } from 'config/interfaces';
import moment from 'moment';
import { NoData } from 'components/NoData';
import { FlashSectionList } from 'components/List';
import { ScheduleListHeader, ScheduleListItem, SCHEDULELISTITEMENTRYHEIGHT } from 'components/Schedule';
import { FormCheckbox } from 'components/Form';
import i18next from 'i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.ScheduleList>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.ScheduleList>;
type RouteParams = StackParamList[ERoutes.ScheduleList];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

interface IScheduleData {
	header: string;
	items: ISchedule[];
}

const TESTIDPREFIX = 'schedulelist';

export const ScheduleListScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { theme } = useTheme();
	const { getContentTypeFields } = useContent('schedule');
	const { exportAsCSV } = useExport();
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const isScreenFocused = useIsFocused();

	const bottomSheetRef = useRef<RBSheet>(null);

	const [spaceSchedules, setSpaceSchedules] = useState<ISchedule[]>([]);
	const [searchedSchedules, setSearchedSchedules] = useState<ISchedule[]>([]);
	const [scheduleSections, setScheduleSections] = useState<(string | ISchedule)[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedEntries, setSelectedEntries] = useState<number[]>([]);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
	const [isStatusLoading, setIsStatusLoading] = useState<number>(0);

	const content = useSelector((store: IRootState) => store.content.content);
	const mediaDetail = useSelector((store: IRootState) => store.temp.mediaDetail);
	const waitingForSocketResponse = useSelector((store: IRootState) => store.temp.waitingForSocketResponse);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const multiDelete = useRematchDispatch((dispatch: Dispatch) => dispatch.content.multiDelete);
	const setMediaDetail = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetail);
	const setMediaDetailViewType = useRematchDispatch((dispatch: Dispatch) => dispatch.temp.setMediaDetailViewType);
	const updateScheduleStatus = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateScheduleStatus);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => bottomSheetRef.current?.open()
		});
	}, []);

	useEffect(() => {
		if (activeSpace && content.schedules && isScreenFocused) {
			let _schedules: typeof spaceSchedules = [];
			_schedules = content.schedules.filter(
				(e) => e.spaceId === activeSpace.spaceId && (!e.isHidden || iAmSpaceAdmin || iAmSpaceModerator)
			);

			setSpaceSchedules(_schedules);
		}
	}, [activeSpace, content, iAmSpaceAdmin, iAmSpaceModerator, isScreenFocused]);

	useEffect(() => {
		let _searched: typeof spaceSchedules = [...spaceSchedules];

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_searched = _searched.filter(
				(item) =>
					item.title.toLowerCase().includes(lowerSearch) ||
					item.subtitle?.toLowerCase().includes(lowerSearch) ||
					item.description?.toLowerCase().includes(lowerSearch) ||
					item.stage?.title.toLowerCase().includes(lowerSearch)
			);
		}

		setSearchedSchedules(_searched);
	}, [spaceSchedules, searchTerm]);

	useEffect(() => {
		const sections: IScheduleData[] = [];
		let newSections: typeof scheduleSections = [];

		searchedSchedules.forEach((item) => {
			let header = '';
			if (item.startDate) {
				header = moment(item.startDate).format('DD.MM.YYYY HH:mm');
			}
			const found = sections.find((e) => e.header === header);
			if (found) {
				found.items.push(item);
			} else {
				sections.push({
					header: header,
					items: [item]
				});
			}
		});

		sections.sort((a, b) => {
			const aVal = isEmptyString(a.header) ? moment().subtract(300, 'years') : moment(a.header, 'DD.MM.YYYY HH:mm');
			const bVal = isEmptyString(b.header) ? moment().subtract(300, 'years') : moment(b.header, 'DD.MM.YYYY HH:mm');

			return aVal.isBefore(bVal) ? -1 : 1;
		});

		sections.forEach((section) => {
			newSections.push(section.header);
			newSections = newSections.concat(section.items);
		});

		if (newSections.length > 0) {
			newSections = ['', ...newSections];
		}
		setScheduleSections(newSections);
	}, [searchedSchedules]);

	const _handleActionSheetPress = async (action: string, itemId?: number) => {
		switch (action) {
			case 'add':
				navigation.navigate(ERoutes.ScheduleCreate, { spaceId: activeSpace?.spaceId });
				bottomSheetRef.current?.close();
				break;
			case 'delete':
				_delete();
				break;
			case 'import':
				navigation.navigate(ERoutes.ImportData, { spaceId: activeSpace?.spaceId, type: 'schedule' });
				bottomSheetRef.current?.close();
				break;
			case 'export':
				let items: ISchedule[] = [];
				if (content.schedules) {
					if (selectedEntries.length > 0) {
						selectedEntries.forEach((id) => {
							const _item = content.schedules.find((e) => e.id === id);
							if (_item) {
								items.push(_item);
							}
						});
					} else {
						items = [...content.schedules];
					}
				}
				exportAsCSV(getContentTypeFields(true), items, 'schedules');
				if (IS_WEB) bottomSheetRef.current?.close();
				break;
			case 'clone':
				if (selectedEntries.length === 1) {
					navigation.navigate(ERoutes.ScheduleCreate, {
						spaceId: activeSpace?.spaceId,
						scheduleId: selectedEntries[0],
						prohibitNavigation: true
					});
					setSelectedEntries([]);
				}
				bottomSheetRef.current?.close();
				break;
			default:
				break;
		}
	};

	const _handleSelect = (itemId: number) => {
		let _selected = [...selectedEntries];

		if (_selected.includes(itemId)) {
			_selected = _selected.filter((e) => e !== itemId);
		} else {
			_selected.push(itemId);
		}

		setSelectedEntries(_selected);
	};

	const _handlePress = (itemId: number) => {
		if (mediaDetail.itemId) {
			if (mediaDetail.itemId === itemId) {
				setMediaDetailViewType('full');
			} else if (!IS_WEB && mediaDetail.playbackStatus === 'paused') {
				setMediaDetail({
					itemId,
					itemType: 'schedule',
					viewType: 'full',
					playbackStatus: 'paused'
				});
			} else {
				showAlert({
					title: t('Switch Stream'),
					message: t('Switch Stream Subtitle'),
					buttons: [
						{
							style: 'cancel',
							text: t('Cancel')
						},
						{
							style: 'destructive',
							text: t('Switch Stream'),
							onPress: () =>
								setMediaDetail({
									itemId,
									viewType: 'full',
									playbackStatus: 'paused'
								})
						}
					]
				});
			}
		} else {
			setMediaDetail({
				itemId,
				itemType: 'schedule',
				viewType: 'full',
				playbackStatus: 'paused'
			});
		}
	};

	const _delete = async (itemId?: number) => {
		const singleItem = spaceSchedules.find((e) => e.id === itemId);

		showAlert({
			title: itemId
				? t('ConfirmDeleteSingle').replace('%TITLE%', `"${singleItem?.title}"`)
				: t('ConfirmDeleteCount').replace('%COUNT%', selectedEntries.length.toString()),
			message: t('ConfirmDeleteSubtitle'),
			buttons: [
				{
					text: t('Cancel'),
					style: 'cancel'
				},
				{
					text: t('Delete'),
					style: 'destructive',
					onPress: async () => {
						setIsDeleteLoading(true);
						const res = await multiDelete({ selectedIds: itemId ? [itemId] : selectedEntries, type: 'schedule' });
						setIsDeleteLoading(false);

						if (res) {
							setSelectedEntries([]);
							bottomSheetRef.current?.close();
						}
					}
				}
			]
		});
	};

	const _renderSelectAll = () => {
		if (searchedSchedules.length > 0) {
			return (
				<FormCheckbox
					testID={`${TESTIDPREFIX}_checkbox_selectall`}
					label={t('SelectAll')}
					style={{ marginBottom: 10 }}
					value={selectedEntries && selectedEntries.length === searchedSchedules.length}
					onPress={() => {
						if (selectedEntries && selectedEntries.length === searchedSchedules.length) {
							setSelectedEntries([]);
						} else {
							setSelectedEntries(searchedSchedules.map((sc) => sc.id));
						}
					}}
				/>
			);
		}

		return null;
	};

	const _updateStatus = async (newStatus: IScheduleStatus['status'], statusId: number, scheduleId) => {
		if (statusId && newStatus) {
			setIsStatusLoading(scheduleId);
			await updateScheduleStatus({ newStatus, statusId });
			setIsStatusLoading(0);
		}
	};

	const _renderList = () => {
		if (
			(searchedSchedules.length === 0 || scheduleSections.length === 0) &&
			(!waitingForSocketResponse?.schedules || !isEmptyString(searchTerm))
		) {
			if (!isEmptyString(searchTerm)) {
				return <NoData title={t('NoMatchedForSearch')} subtitle={t('NoMatchedForSearchSubtitle')} />;
			}

			return <NoData type="NoSchedules" />;
		}

		return (
			<HSCard style={{ flex: 1 }}>
				{_renderSelectAll()}
				<FlashSectionList
					data={scheduleSections}
					extraData={[selectedEntries]}
					renderItem={(renderItem) => (
						<ScheduleListItem
							testID={`${TESTIDPREFIX}_button_schedule_${renderItem.index}`}
							item={renderItem.item}
							isLoading={isDeleteLoading}
							onPress={() => _handleSelect(renderItem.item.id)}
							isSelected={selectedEntries.includes(renderItem.item.id)}
							onSelect={() => _handleSelect(renderItem.item.id)}
							onEdit={() =>
								navigation.navigate(ERoutes.ScheduleEdit, {
									spaceId: activeSpace?.spaceId,
									id: renderItem.item.id
								})
							}
							onDelete={() => {
								setSelectedEntries([renderItem.item.id]);
								_delete(renderItem.item.id);
							}}
							onStatusChange={(status, statusId, scheduleId) => {
								_updateStatus(status, statusId, scheduleId);
							}}
							isStatusLoading={isStatusLoading}
						/>
					)}
					renderSectionHeader={(renderItem) => <ScheduleListHeader text={renderItem.item} />}
					estimatedItemSize={SCHEDULELISTITEMENTRYHEIGHT}
					hideIndexButtons
				/>
			</HSCard>
		);
	};

	return (
		<ScreenContainer contentKey="schedules">
			<View
				style={{
					flex: 1,
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<HSCard style={{ flexDirection: 'row', alignItems: 'center' }}>
					<View style={{ flex: 1 }}>
						<SearchBar testID={`${TESTIDPREFIX}_searchbar`} value={searchTerm} onChange={(val) => setSearchTerm(val)} />
					</View>
				</HSCard>
				{_renderList()}
				<BottomSheet ref={bottomSheetRef}>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_add`}
						icon={EDefaultIconSet.Add}
						label={t('Add')}
						isDisabled={isDeleteLoading}
						onPress={() => _handleActionSheetPress('add')}
					/>
					{IS_WEB && (
						<BottomSheetViewButton
							testID={`${TESTIDPREFIX}_button_import`}
							icon={EDefaultIconSet.Import}
							label={t('Import Data')}
							onPress={() => _handleActionSheetPress('import')}
						/>
					)}
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_export`}
						icon={EDefaultIconSet.Export}
						label={t('Export Selection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('export')}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_delete`}
						icon={EDefaultIconSet.Delete}
						label={t('DeleteSelection').replace('%COUNT%', selectedEntries.length > 0 ? ` (${selectedEntries.length})` : '')}
						isDisabled={selectedEntries.length === 0}
						onPress={() => _handleActionSheetPress('delete')}
						isLoading={isDeleteLoading}
						iconColor={theme.danger}
					/>
					<BottomSheetViewButton
						testID={`${TESTIDPREFIX}_button_clone`}
						icon={EDefaultIconSet.Clone}
						label={t('clone')}
						isDisabled={isDeleteLoading || selectedEntries.length !== 1}
						onPress={() => _handleActionSheetPress('clone')}
					/>
				</BottomSheet>
			</View>
		</ScreenContainer>
	);
};

export const ScheduleListScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Agenda')} />
			<NavigationHeaderDropdown options={props.options} />
		</NavigationHeader>
	);
};
