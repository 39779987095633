import { IAttendee, INetworkingRoom, IVote } from 'config/interfaces';

import { useContent } from 'hooks/useContent';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import { useTracker } from 'hooks/useTracker';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { INetworkingRoomAttendeeOverview } from 'rematch/interfaces';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AppState, AppStateStatus, ScrollView, View } from 'react-native';
import { Text, Title } from 'components/Text';
import { ERoutes } from 'components/Navigation/routes';
import { ContentCard, HSCard } from 'components/Card';
import { EDefaultIconSet, getCardContainerStyle, getIconByVoteType, isEmptyString, IS_WEB, openURL } from 'helper';
import { Markdown } from 'components/Markdown';
import { RoundButton } from 'components/Button';
import { Spinner } from 'components/Spinner';
import { NetworkingRoom } from './NetworkingRoom';
import { hsBorderRadius, hsBottomMargin, hsInnerPadding } from 'config/styleConstants';
import { Image } from 'components/Image';
import { FlashList } from 'components/List';
import { TabView } from 'components/TabView';
import {
	VoteApplause,
	VoteHappinessOMeter,
	VoteMultipleChoice,
	VoteNps,
	VotePinOnImage,
	VoteRating,
	VoteScale,
	VoteText,
	VoteWordCloud,
	VoteQuiz,
	VoteSurvey
} from 'components/Vote/ActiveVote';
import { HSWebView } from 'components/WebView';
import moment from 'moment';
import { DEFAULT_PLATFORM_URL } from 'config/envConstants';

interface INetworkingRoomWrapper {
	networkingRoomId: number;
	testIdPrefix: string;
}

export const NetworkingRoomWrapper = (props: INetworkingRoomWrapper) => {
	const { networkingRoomId, testIdPrefix } = props;

	const { screenColumnCountSmallItems, isTabletOrMobile } = useQuery();
	const { t } = useTranslation();
	const { activeSpace, iAmSpaceAdmin } = useSpace();
	const { trackAction } = useTracker();
	const { getContentTypeFields } = useContent('networkingroom');
	const navigation = useNavigation();
	const route = useRoute();
	const { theme } = useTheme();
	const voteAnswerTimer = useRef<NodeJS.Timer>(null);
	const votingsRef = useRef<IVote[]>([]);
	const activeSidebarFeatureRef = useRef('');

	let timer = useRef<NodeJS.Timer>(null);

	const [networkingRoom, setNetworkingRoom] = useState<INetworkingRoom | undefined>(undefined);
	const [networkingRoomOverview, setNetworkingRoomOverview] = useState<INetworkingRoomAttendeeOverview | undefined>(undefined);
	const [attendeesInNetworkingRoom, setAttendeesInNetworkingRoom] = useState<IAttendee[]>([]);
	const [isJoinNetworkingRoomLoading, setIsJoinNetworkingRoomLoading] = useState<boolean>(false);
	const [meetingPartner, setMeetingPartner] = useState<IAttendee | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [votings, setVotings] = useState<IVote[]>([]);
	const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(true);
	const [activeSidebarFeature, setActiveSidebarFeature] = useState<string>('');
	const [currentDate, setCurrentDate] = useState<string>(moment().toISOString());
	const [isVoteAnswersLoading, setIsVoteAnswersLoading] = useState<Record<number, boolean>>({});

	const profile = useSelector((store: IRootState) => store.auth.profile);
	const attendees = useSelector((store: IRootState) => store.attendee.attendees);
	const meetings = useSelector((store: IRootState) => store.meeting.meetings);
	const userInfos = useSelector((store: IRootState) => store.auth.userInfos);
	const content = useSelector((store: IRootState) => store.content.content);
	const votes = useSelector((store: IRootState) => store.vote.votes);
	const isMultiSpaceSocketConnected = useSelector((store: IRootState) => store.socket.isMultiSpaceSocketConnected);
	const activeNetworkingRoom = useSelector((store: IRootState) => store.networking.activeNetworkingRoom);
	const networkingRoomAttendeeOverviews = useSelector((store: IRootState) => store.networking.networkingRoomAttendeeOverviews);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const joinNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.networking.joinNetworkingRoom);
	const updateNetworkingRoom = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateNetworkingRoom);
	const loadVoteAnswersAndStartSync = useRematchDispatch((dispatch: Dispatch) => dispatch.vote.loadVoteAnswersAndStartSync);

	useEffect(() => {
		let appStateListener;
		if (!IS_WEB) {
			appStateListener = AppState.addEventListener('change', _handleAppStateChange);
		}

		_startTimer();

		return () => {
			_stopTimer();

			if (!IS_WEB && appStateListener) {
				appStateListener.remove();
			}
		};
	}, []);

	useEffect(() => {
		if (isMultiSpaceSocketConnected) {
			votings.forEach(async (vote: IVote) => {
				loadVoteAnswersAndStartSync({ reload: true, voteId: vote.id });
			});
		}
	}, [votings, isMultiSpaceSocketConnected]);

	useEffect(() => {
		let _networkingroom: typeof networkingRoom = undefined;

		if (activeSpace && content?.networkingrooms) {
			_networkingroom = content.networkingrooms.find((e) => e.spaceId === activeSpace.spaceId && e.id === networkingRoomId);
		}

		navigation.setOptions({
			title: _networkingroom?.title ?? ''
		});

		setNetworkingRoom(_networkingroom);
	}, [activeSpace, content]);

	useEffect(() => {
		let _hasFeature = false;
		if (!isEmptyString(activeSidebarFeature)) {
			const found = votings?.find((v) => v?.id?.toString() === activeSidebarFeature);
			if (found || activeSidebarFeature === 'details') {
				_hasFeature = true;
			}
		}
		if (!_hasFeature) {
			if (votings.length > 0 && votings[0]?.id) {
				_setActiveSidebarFeature(votings[0].id.toString());
			} else if (isTabletOrMobile) {
				_setActiveSidebarFeature('details');
			}
		}
	}, [votings, activeSidebarFeature]);

	useEffect(() => {
		let _overview: typeof networkingRoomOverview = undefined;

		if (networkingRoomAttendeeOverviews && activeSpace && networkingRoom) {
			_overview = networkingRoomAttendeeOverviews.find(
				(e) => e.externalMeetingId === `${activeSpace.spaceId}_networkingroom_${networkingRoom.id}`
			);
		}

		setNetworkingRoomOverview(_overview);
	}, [networkingRoomAttendeeOverviews, activeSpace, networkingRoom]);

	useEffect(() => {
		let _partner: typeof meetingPartner = undefined;

		if (activeSpace && attendees && networkingRoom?.isMeetingRoom && profile) {
			const meeting = meetings.find((e) => e.spaceId === activeSpace.spaceId && e.id === networkingRoom.meeting?.id);
			if (meeting) {
				const partnerId = meeting.ownerId === profile.userId ? meeting.partnerId : meeting.ownerId;
				_partner = attendees.find((att) => att.userId === partnerId);
			}
		}

		setMeetingPartner(_partner);
	}, [activeSpace, attendees, networkingRoom]);

	useEffect(() => {
		let _attendees: typeof attendeesInNetworkingRoom = [];

		if (networkingRoomOverview) {
			networkingRoomOverview.roomUsers.forEach((roomUser) => {
				const _attendee = attendees.find((e) => e.userId === roomUser.userId);
				if (_attendee) {
					_attendees.push(_attendee);
				}
			});
		}

		setAttendeesInNetworkingRoom(_attendees);
	}, [networkingRoomOverview, attendees]);

	useEffect(() => {
		if (activeNetworkingRoom) {
			if (!route?.params?.prohibitNavigation) {
				navigation.setParams({ prohibitNavigation: true });
			}
		} else {
			if (route?.params?.prohibitNavigation) {
				navigation.setParams({ prohibitNavigation: undefined });
			}
		}
	}, [activeNetworkingRoom]);

	useEffect(() => {
		const now = moment(currentDate);
		let _votings: IVote[] = votes.filter(
			(v) => v.spaceId === activeSpace?.spaceId && networkingRoom?.votes?.find((v2) => v.id === v2.id)
		);

		if (_votings) {
			_votings = _votings.filter((v) => {
				if (v.isDeleted) {
					return false;
				}
				if (!v.isActive) {
					return false;
				}
				if (v.openUntil && v.openFrom) {
					return now.isBefore(moment(v.openUntil)) && now.isAfter(moment(v.openFrom));
				}
				if (v.openFrom) {
					return now.isSameOrAfter(moment(v.openFrom));
				}
				if (v.openUntil) {
					return now.isBefore(moment(v.openUntil));
				}
				return true;
			});

			_votings;
		}

		_setVotings(_votings);
	}, [votes, networkingRoom, currentDate]);

	const _startTimer = () => {
		timer.current = setInterval(() => {
			const now = moment();
			if (now.get('seconds') === 0) {
				setCurrentDate(now.toISOString());
			}
		}, 1000);

		voteAnswerTimer.current = setInterval(() => {
			_loadVoteAnswers();
		}, 5000);
	};

	const _stopTimer = () => {
		if (timer.current) {
			clearInterval(timer.current);
			timer.current = undefined;
		}
		if (voteAnswerTimer.current) {
			clearInterval(voteAnswerTimer.current);
			voteAnswerTimer.current = undefined;
		}
	};

	const _setVotings = (newVotings: IVote[]) => {
		setVotings(newVotings);
		votingsRef.current = newVotings;
	};

	const _setActiveSidebarFeature = (newFeature: string) => {
		setActiveSidebarFeature(newFeature);
		activeSidebarFeatureRef.current = newFeature;
	};

	const _loadVoteAnswers = async () => {
		const _activeVote = votingsRef.current.find((vote) => vote.id.toString() === activeSidebarFeatureRef.current);

		if (_activeVote) {
			setIsVoteAnswersLoading({ ...isVoteAnswersLoading, [_activeVote.id]: true });
			await loadVoteAnswersAndStartSync({ reload: true, voteId: _activeVote.id, nosync: true });
			setIsVoteAnswersLoading({ ...isVoteAnswersLoading, [_activeVote.id]: false });
		}
	};

	const _handleAppStateChange = async (nextState: AppStateStatus) => {
		if (nextState === 'active') {
			_startTimer();
		} else if (nextState === 'background') {
			_stopTimer();
		}
	};

	const _joinNetworkingRoom = async () => {
		if (iAmSpaceAdmin && networkingRoom?.isClosed) {
			showAlert({
				title: t('NetworkingRoomClosed'),
				message: t('networkingroom.closed'),
				buttons: [
					{
						text: t('openSettings'),
						style: 'destructive',
						onPress: () =>
							navigation.navigate(ERoutes.NetworkingRoomUpdate, { id: networkingRoom.id, spaceId: activeSpace?.spaceId })
					},
					{
						text: t('OK'),
						style: 'cancel'
					}
				]
			});
			return;
		}

		setIsJoinNetworkingRoomLoading(true);
		const res = await joinNetworkingRoom({ networkingRoomId: networkingRoomId });
		setIsJoinNetworkingRoomLoading(false);

		if (res) {
			trackAction('networkingroom', 'Join Room', `${networkingRoomId}`);
		}
	};

	const _renderAttendees = () => {
		return (
			<View style={{ width: '100%' }}>
				<Text bold style={{ marginBottom: 10 }}>
					{t('AttendeesInNetworkingRoom')}
				</Text>

				{screenColumnCountSmallItems ? (
					<FlashList
						data={attendeesInNetworkingRoom}
						numColumns={screenColumnCountSmallItems}
						renderItem={(renderItem) => {
							return (
								<View
									style={getCardContainerStyle(screenColumnCountSmallItems, renderItem.index)}
									key={`${testIdPrefix}_button_attendee_${renderItem.index}`}
								>
									<ContentCard
										testID={testIdPrefix}
										item={renderItem.item}
										contentType="attendee"
										hideInfos
										onPress={() =>
											navigation.navigate(ERoutes.AttendeeDetails, {
												spaceId: activeSpace?.spaceId,
												userId: renderItem.item.userId
											})
										}
										index={renderItem.index}
									/>
								</View>
							);
						}}
						estimatedItemSize={220}
						ListEmptyComponent={() => {
							return <Text italic>{t('EmptyNetworkingRoom')}</Text>;
						}}
					/>
				) : null}
			</View>
		);
	};

	const _isRoomFull = () => {
		if (networkingRoomOverview) {
			if (networkingRoomOverview.roomUsers.find((e) => e.userId === userInfos.userId)) {
				return false;
			}

			if (networkingRoom) {
				return networkingRoomOverview?.roomUsers.length === networkingRoom.capacity;
			}
		}

		return false;
	};

	const _renderTitle = () => {
		let str = '';
		if (networkingRoom) {
			str = networkingRoom.title;
			if (networkingRoom.isMeetingRoom && meetingPartner && profile) {
				str = `Meeting - ${profile.firstName} ${profile.lastName} & ${meetingPartner.firstName} ${meetingPartner.lastName}`;
			}
		}

		return <Title>{str}</Title>;
	};

	const _renderImage = () => {
		if (networkingRoom?.overviewImage?.formats?.large) {
			return (
				<Image
					mediaObj={networkingRoom?.overviewImage}
					imageSize={'medium'}
					style={{
						width: '100%',
						aspectRatio: 16 / 9,
						borderRadius: hsBorderRadius,
						marginBottom: hsBottomMargin
					}}
					expectedRatio={16 / 9}
					resizeMode="contain"
				/>
			);
		}
	};

	const _renderVoting = () => {
		const _activeVote = votings?.find((vote) => vote.id.toString() === activeSidebarFeature);

		switch (_activeVote?.votingType) {
			case 'applause':
				return <VoteApplause fullwidth vote={_activeVote} />;
			case 'multipleChoice':
				return <VoteMultipleChoice fullwidth vote={_activeVote} />;
			case 'quiz':
				return <VoteQuiz fullwidth vote={_activeVote} />;
			case 'rating':
				return <VoteRating fullwidth vote={_activeVote} />;
			case 'scale':
				return <VoteScale fullwidth vote={_activeVote} />;
			case 'survey':
				return <VoteSurvey fullwidth vote={_activeVote} />;
			case 'text':
				return <VoteText fullwidth vote={_activeVote} renderSortByInCard />;
			case 'wordCloud':
				return <VoteWordCloud fullwidth vote={_activeVote} />;
			case 'nps':
				return <VoteNps fullwidth vote={_activeVote} />;
			case 'externalUrl':
				if (_activeVote.externalUrl) {
					return <HSWebView src={_activeVote.externalUrl} testIdPrefix={testIdPrefix} style={{ flex: 1 }} />;
				}
				return null;
			case 'pinOnImage':
				return <VotePinOnImage fullwidth vote={_activeVote} />;
			case 'happinessOMeter':
				return <VoteHappinessOMeter fullwidth vote={_activeVote} />;
			default:
				return null;
		}
	};

	const _renderSidebarFeature = () => {
		if (isSidebarExpanded) {
			return <View style={{ flex: 1 }}>{_renderVoting()}</View>;
		}
		return (
			<View style={{ flex: 1 }}>
				{votings?.map((v) => {
					if (v.isActive) {
						return (
							<RoundButton
								testID={`${testIdPrefix}_button_${v.votingType}`}
								key={`${testIdPrefix}_button_${v.votingType}`}
								icon={getIconByVoteType(v.votingType)}
								onPress={() => {
									setIsSidebarExpanded(!isSidebarExpanded);
									_setActiveSidebarFeature(v?.id.toString());
								}}
								isOutline
								iconColor={theme.text}
								alignSelf="center"
							/>
						);
					}
					return null;
				})}
			</View>
		);
	};

	const _renderSidebar = () => {
		const _activeVotings = votings?.filter((v) => v.isActive);
		if (_activeVotings?.length === 0) {
			return null;
		}
		return (
			<HSCard
				style={{
					marginLeft: 10,
					maxWidth: 500,
					width: isSidebarExpanded ? 500 : 'auto',
					paddingHorizontal: isSidebarExpanded ? hsInnerPadding : 0
				}}
			>
				<View style={{ flexDirection: 'row' }}>
					<RoundButton
						testID={`${testIdPrefix}_button_togglesidebar`}
						icon={isSidebarExpanded ? EDefaultIconSet.ChevronRight : EDefaultIconSet.ChevronLeft}
						onPress={() => setIsSidebarExpanded(!isSidebarExpanded)}
						isOutline
						iconColor={theme.text}
						alignSelf="center"
					/>
					{isSidebarExpanded && (
						<TabView
							testIdPrefix={testIdPrefix}
							activeKey={activeSidebarFeature}
							items={votings.map((v) => {
								return { key: v?.id.toString(), label: v.question };
							})}
							onPress={(key) => _setActiveSidebarFeature(key)}
							containerStyle={{ flex: 1 }}
						/>
					)}
				</View>
				{_renderSidebarFeature()}
			</HSCard>
		);
	};

	const _renderCloseRoomButton = () => {
		if (iAmSpaceAdmin && networkingRoom) {
			return (
				<View style={{ marginBottom: 20 }}>
					<RoundButton
						testID={`${testIdPrefix}_button_opennetworkingroom`}
						icon={networkingRoom.isClosed ? EDefaultIconSet.Unlock : EDefaultIconSet.Lock}
						title={networkingRoom.isClosed ? t('OpenRoom') : t('CloseRoom')}
						alignSelf="center"
						isFloatingButton
						borderColor={networkingRoom.isClosed ? theme.success : undefined}
						onPress={async () => {
							showAlert({
								title: networkingRoom.isClosed ? t('OpenRoom') : t('CloseRoom'),
								message: networkingRoom.isClosed ? t('OpenRoomMessage') : t('CloseRoomMessage'),
								buttons: [
									{
										text: t('Cancel'),
										style: 'cancel'
									},
									{
										text: networkingRoom.isClosed ? t('OpenRoom') : t('CloseRoom'),
										style: networkingRoom.isClosed ? 'default' : 'destructive',
										onPress: async () => {
											const values = content.networkingrooms.find((e) => e.id === networkingRoomId);

											let updatedValues;

											if (values) {
												updatedValues = {
													...values,
													isClosed: !networkingRoom.isClosed
												};
											}

											setIsLoading(true);
											await updateNetworkingRoom({
												networkingroom: updatedValues,
												fields: getContentTypeFields()
											});
											setIsLoading(false);
										}
									}
								]
							});
						}}
						isLoading={isLoading}
					/>
				</View>
			);
		}

		return null;
	};

	const _renderJoinRoomButton = () => {
		if (IS_WEB) {
			return (
				<RoundButton
					testID={`${testIdPrefix}_button_opennetworkingroom`}
					title={_isRoomFull() ? t('NetworkingRoomFull') : networkingRoom?.isClosed ? t('NetworkingRoomClosed') : t('JoinRoom')}
					isFloatingButton
					icon={EDefaultIconSet.Rocket}
					onPress={_joinNetworkingRoom}
					isLoading={isJoinNetworkingRoomLoading}
					isDisabled={networkingRoom?.isClosed || _isRoomFull()}
					borderColor={networkingRoom?.isClosed ? theme.danger : undefined}
				/>
			);
		}

		return (
			<View>
				<Text marginBottom style={{ marginTop: hsBottomMargin / 2 }}>
					{t('NetworkingWebOnly')}
				</Text>
				<RoundButton
					testID={`${testIdPrefix}_button_opennetworkingroomonweb`}
					title={t('OpenRoomOnWeb')}
					isFloatingButton
					icon={EDefaultIconSet.Website}
					onPress={() => openURL(`${DEFAULT_PLATFORM_URL}app/${activeSpace?.spaceId}/networkingroom/${networkingRoom?.id}`)}
					alignSelf="center"
				/>
			</View>
		);
	};

	if (!networkingRoom) {
		return (
			<HSCard style={{ flex: 1, justifyContent: 'center' }}>
				<Spinner />
			</HSCard>
		);
	}

	if (activeNetworkingRoom) {
		return (
			<View style={{ flex: 1, flexDirection: 'row' }}>
				<NetworkingRoom
					item={networkingRoom}
					connection={{
						attendee: activeNetworkingRoom.attendee,
						meeting: activeNetworkingRoom.meeting
					}}
				/>
				{_renderSidebar()}
			</View>
		);
	}

	return (
		<ScrollView>
			<HSCard style={{ flex: 1, alignItems: 'center' }}>
				{_renderImage()}
				{_renderTitle()}
				{!isEmptyString(networkingRoom.description) && <Markdown markdown={networkingRoom.description} />}
				{_renderAttendees()}

				<View
					style={{
						width: '100%',
						flexDirection: 'row',
						justifyContent: isTabletOrMobile ? 'center' : 'space-evenly',
						alignItems: 'center',
						flexWrap: 'wrap',
						marginTop: 20
					}}
				>
					{_renderCloseRoomButton()}
					{_renderJoinRoomButton()}
				</View>
			</HSCard>
		</ScrollView>
	);

	return null;
};
