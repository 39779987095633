import React, { useEffect, useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderCancelButton,
	NavigationHeaderIconButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';

import { useContent } from 'hooks/useContent';
import { useForm } from 'hooks/useForm';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { hsTopScreenPadding } from 'config/styleConstants';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { getCreateStageInitialFormValues, IStage, IUpdateStageFormErrors, IUpdateStageFormValues } from 'config/interfaces';
import { EDefaultIconSet, showFormErrorToast, validateForm } from 'helper';
import { StageForm } from 'components/Forms/Schedule';
import { LoadingModal } from 'components/Modal/LoadingModal';
import i18next from 'i18next';
import * as RootNavigation from '../../../RootNavigation';
import { useTranslation } from 'react-i18next';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.StageEdit>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.StageEdit>;
type RouteParams = StackParamList[ERoutes.StageEdit];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'stageedit';

export const StageEditScreen = ({ route, navigation }: Props) => {
	const { t } = useTranslation();
	const { screenWidth } = useQuery();
	const { getContentTypeFields } = useContent('stage');
	const { getFormSchema } = useForm();
	const { activeSpace } = useSpace();

	const [formValues, setFormValues] = useState<IUpdateStageFormValues>({ ...getCreateStageInitialFormValues() });
	const [formErrors, setFormErrors] = useState<IUpdateStageFormErrors>({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFormLocked, setIsFormLocked] = useState<boolean>(false);

	const content = useSelector((store: IRootState) => store.content.content);
	const stages = useSelector((store: IRootState) => store.content.content.stages);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateStage = useRematchDispatch((dispatch: Dispatch) => dispatch.content.updateStage);

	useEffect(() => {
		if (activeSpace && content.stages && route.params.id) {
			const values = content.stages.find((e) => e.spaceId === activeSpace.spaceId && e.id === route.params.id);
			if (values) {
				if (formValues.updated_at && values.updated_at !== formValues.updated_at && !isLoading) {
					showAlert({
						title: t('Item has changed'),
						message: t('Item has changed subtitle'),
						buttons: [
							{
								text: t('Save my changes'),
								onPress: () => setIsFormLocked(true)
							},
							{
								text: t('Apply Changes'),
								onPress: () => _applyFormValues(values)
							},
							{
								text: t('Leave form'),
								style: 'destructive',
								onPress: () => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									} else {
										RootNavigation.replace('tab');
									}
								}
							}
						]
					});
				} else {
					_applyFormValues(values);
				}
			}
		}
	}, [activeSpace, content]);

	useEffect(() => {
		navigation.setOptions({
			onRightNavPress: () => _updateStage(),
			isLoading
		});
	}, [activeSpace, formValues, isLoading, isFormLocked]);

	const _applyFormValues = (values: IStage) => {
		setFormValues({
			...values,
			schedules: values.schedules ? values.schedules.map((sc) => sc.id) : [],
			mapposition: values.mapposition?.id
		});
		setIsFormLocked(false);
	};

	const updateFormValues = (val) => {
		if (!route.params?.prohibitNavigation) {
			navigation.setParams({ prohibitNavigation: true });
		}
		setFormValues(val);
	};

	const _updateStage = async () => {
		const errors = await validateForm(getFormSchema(getContentTypeFields(), formValues), formValues);
		if (errors) {
			setFormErrors(errors);
			showFormErrorToast(errors, getContentTypeFields());
			return;
		} else {
			setFormErrors({});
		}

		if (isFormLocked) {
			showAlert({
				title: t('Item has changed'),
				message: t('ItemFormLockedSubtitle'),
				buttons: [
					{
						text: t('Apply Changes'),
						onPress: () => {
							const values = content.stages.find((e) => e.id === route.params.id);
							_applyFormValues(values!);
						}
					},
					{
						text: t('Cancel'),
						style: 'destructive'
					}
				]
			});
			return;
		}

		const values = { ...formValues };
		getContentTypeFields().forEach((field) => {
			switch (field.fieldType) {
				case 'color':
				case 'dateRange':
				case 'email':
				case 'markdown':
				case 'string':
				case 'website':
					if (values[field.fieldName]) {
						values[field.fieldName] = values[field.fieldName].trim();
					}
					break;
				default:
					break;
			}
		});

		if (!values.order) {
			const orders = stages.filter((stage) => stage.spaceId === activeSpace?.spaceId).map((s) => (s.order ? s.order : 0));
			const maxOrder = Math.max(...orders);
			values.order = maxOrder + 1;
		}

		setFormValues(values);

		setIsLoading(true);
		const res = await updateStage({ stage: values, fields: getContentTypeFields() });
		if (res) {
			setIsLoading(false);
			navigation.setParams({ prohibitNavigation: false });
			navigation.goBack();
		} else {
			setIsLoading(false);
		}
	};

	return (
		<ScreenContainer handleBackPress isProtectedRoute contentKey="stages">
			<ScrollView
				contentContainerStyle={{
					paddingTop: hsTopScreenPadding,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					width: screenWidth,
					alignSelf: 'center'
				}}
			>
				<StageForm values={formValues} onChange={updateFormValues} errors={formErrors} isLoading={isLoading} />
			</ScrollView>
			<LoadingModal isLoading={isLoading} />
		</ScreenContainer>
	);
};

export const StageEditScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderCancelButton route={route} />
			<NavigationHeaderTitle title={i18next.t('Stage')} />
			<NavigationHeaderIconButton
				testID="header_button_save"
				icon={EDefaultIconSet.Save}
				onPress={props.options.onRightNavPress}
				isLoading={props.options?.isLoading}
			/>
		</NavigationHeader>
	);
};
