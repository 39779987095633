import { hsBottomMargin, hsCard, hsInnerPadding } from 'config/styleConstants';
import { useTheme } from 'hooks/useTheme';
import React, { ReactNode } from 'react';
import { View, ViewStyle, ViewProps } from 'react-native';

interface IHSCardProps extends ViewProps {
	children: ReactNode | ReactNode[];
	style?: ViewStyle;
}

export const HSCard = (props: IHSCardProps) => {
	const { children, style } = props;
	const { theme } = useTheme();
	return (
		<View
			{...props}
			style={{
				width: '100%',
				...hsCard,
				shadowColor: theme.hsShadowColor,
				backgroundColor: theme.contentBackgroundColor ?? theme.background,
				padding: hsInnerPadding,
				marginBottom: hsBottomMargin,
				...style
			}}
		>
			{children}
		</View>
	);
};
