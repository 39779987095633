import React, { useEffect } from 'react';

import { ICreateVoteFormValues, ICreateVoteFormErrors, IEditVoteFormErrors, IEditVoteFormValues } from 'config/interfaces';
import { CommonVoteForm } from '../CommonVoteForm';

interface IVoteHappinessOMeterForm {
	testIdPrefix: string;
	values: ICreateVoteFormValues | IEditVoteFormValues;
	errors: ICreateVoteFormErrors | IEditVoteFormErrors;
	onChange: (val) => void;
	isLoading?: boolean;
}

export const VoteHappinessOMeterForm = (props: IVoteHappinessOMeterForm) => {
	const { onChange, values, errors, testIdPrefix, isLoading } = props;

	useEffect(() => {
		if ((values && values?.allowMultipleAnswers === null) || values?.allowMultipleAnswers === undefined) {
			onChange({ ...values, allowMultipleAnswers: true });
		}
	}, [values]);

	return <CommonVoteForm testIdPrefix={testIdPrefix} values={values} errors={errors} onChange={onChange} isLoading={isLoading} />;
};
