import React, { useState } from 'react';
import { Text } from 'components/Text';
import { Image, View } from 'react-native';
import { ChildButton } from 'components/Button';
import { openURL } from 'expo-linking';
import { lastPublish } from 'config/Publish';
import { isDarkColor } from 'helper';
import { useTheme } from 'hooks/useTheme';

const HelloSpacesWide = require('../../../assets/hellospaces.png');
const HelloSpacesWhite = require('../../../assets/hellospacesWhite.png');

const SIZE = 30;
const HSTEXTSIZE = 10;

export const PoweredByHelloSpaces = () => {
	const [imageRatio, setImageRatio] = useState<number>(1530 / 220);
	const { theme } = useTheme();

	return (
		<View style={{ width: '100%', alignItems: 'center', marginTop: 5, marginBottom: 2 }}>
			<ChildButton
				testID="hellospaces"
				onPress={() => openURL(`https://hellospaces.de/?pk_campaign=hspxapp`)}
				style={{ alignItems: 'center', marginBottom: 10 }}
			>
				<Text style={{ fontSize: HSTEXTSIZE, color: isDarkColor(theme.background) ? '#ffffff' : '#111111' }}>powered by</Text>
				<Image
					source={isDarkColor(theme.background) ? HelloSpacesWhite : HelloSpacesWide}
					style={{ height: SIZE, width: SIZE * imageRatio, marginVertical: 10 }}
				/>
				<Text style={{ fontSize: HSTEXTSIZE, color: isDarkColor(theme.background) ? '#ffffff' : '#111111' }}>
					Connecting People & Content
				</Text>
			</ChildButton>
			<Text style={{ fontSize: 8, color: isDarkColor(theme.background) ? '#ffffff' : '#111111' }}>{lastPublish}</Text>
		</View>
	);
};
