import React, { useState, useEffect } from 'react';
import { RouteProp, StackActions } from '@react-navigation/native';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';

import { ERoutes } from 'components/Navigation/routes';
import { StackParamList } from 'components/Navigation';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer/ScreenContainer';

import {
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderMenuButton,
	NavigationHeaderHelpButton,
	NavigationHeaderTitle
} from 'components/Navigation/Header';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { H1, H2, H3, Text } from 'components/Text';
import { addToClipboard, EDefaultIconSet, getAdministrateData, getSpaceShareUrl, isEmptyString, IS_WEB, openURL, share } from 'helper';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import { QRCodeShowCodeModal } from 'components/Modal/QRCode/QRCodeShowCodeModal';
import { ChildButton, RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import { useQuery } from 'hooks/useQuery';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import i18next from 'i18next';
import { _getPasswordResetEmail } from 'config/mailing';
import { hsInnerPadding, hsTextBottomMarginSmall, hsTopScreenPadding } from 'config/styleConstants';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { IAdministrateButton, IAdministrateData } from 'config/interfaces';
import { SearchBar } from 'components/SearchBar';
import { NoData } from 'components/NoData';
import MasonryList from '@react-native-seoul/masonry-list';
import { Icon } from 'components/Icon';
import { TabbarBadge } from 'components/Navigation/TabbarBadge';

type ScreenRouteProps = RouteProp<StackParamList, ERoutes.Administrate>;
type ScreenNavigationProp = NativeStackNavigationProp<StackParamList, ERoutes.Administrate>;
type RouteParams = StackParamList[ERoutes.Administrate];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

const TESTIDPREFIX = 'administrate';

export const AdministrateScreen = ({ route, navigation }: Props) => {
	const { t }: { t: any } = useTranslation();
	const { theme } = useTheme();
	const { screenWidth, isTabletOrMobile } = useQuery();
	const { activeSpace } = useSpace();

	const [isQRModalVisible, setIsQRModalVisible] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [unreadSupportRequests, setUnreadSupportRequests] = useState<number>(0);

	const [settingsCards, setSettingsCards] = useState<IAdministrateData[][]>([]);
	const [pinnedSettings, setPinnedSettings] = useState<IAdministrateButton[]>([]);
	const [spaceActions, setSpaceActions] = useState<IAdministrateButton[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [isPinLoading, setIsPinLoading] = useState<boolean>(false);

	const supportRequests = useSelector((store: IRootState) => store.attendee.supportRequests);
	const content = useSelector((store: IRootState) => store.content.content);
	const profile = useSelector((store: IRootState) => store.auth.profile);

	const showAlert = useRematchDispatch((dispatch: Dispatch) => dispatch.alert.showAlert);
	const updateSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.updateSpace);
	const emitToMultiSpaceBackend = useRematchDispatch((dispatch: Dispatch) => dispatch.socket.emitToMultiSpaceBackend);
	const leaveSpace = useRematchDispatch((dispatch: Dispatch) => dispatch.space.leaveSpace);
	const updateUser = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.updateUser);

	useEffect(() => {
		let _settingsCards = getAdministrateData();
		let _pinned: typeof pinnedSettings = [];

		if (_settingsCards?.length > 0 && profile) {
			_settingsCards.map((card) => {
				card.items.map((i) => {
					if (!isEmptyString(profile?.pinnedSettings) && profile?.pinnedSettings?.split(',')?.includes(i.key)) {
						_pinned.push(i);
					}
				});
			});
		}

		_settingsCards.push({
			key: 'spaceactions',
			cardTitle: t('Danger'),
			items: spaceActions,
			column: 3
		});

		if (_pinned.length > 0) {
			_settingsCards.unshift({
				key: 'pinned',
				cardTitle: t('myPinnedSettings'),
				items: _pinned,
				column: 1
			});
		}

		if (searchTerm.length >= 3) {
			const lowerSearch = searchTerm.toLowerCase();
			_settingsCards = _settingsCards.map((card) => {
				if (card.cardTitle.toLowerCase().includes(lowerSearch)) {
					return {
						cardTitle: card.cardTitle,
						items: card.items,
						key: card.key,
						column: card.column
					};
				}
				let filtered = card.items.filter((i) => {
					if (i.title.toLowerCase().includes(lowerSearch)) {
						return true;
					}
					if (i.subtitle.toLowerCase().includes(lowerSearch)) {
						return true;
					}
					if (i.keywords.toString().toLowerCase().includes(lowerSearch)) {
						return true;
					}
					return false;
				});
				return {
					cardTitle: card.cardTitle,
					items: filtered,
					key: card.key,
					column: card.column
				};
			});
			_settingsCards = _settingsCards.filter((c) => c.items.length > 0);
		}

		let column1: IAdministrateData[] = [];
		let column2: IAdministrateData[] = [];
		let column3: IAdministrateData[] = [];

		if (_settingsCards.length <= 3) {
			_settingsCards.map((card, index) => {
				switch (index + (1 % 3)) {
					case 1:
						column1.push(card);
						break;
					case 2:
						column2.push(card);
						break;
					case 0:
					default:
						column3.push(card);
						break;
				}
			});
		} else {
			column1 = _settingsCards.filter((c) => c.column === 1);
			column2 = _settingsCards.filter((c) => c.column === 2);
			column3 = _settingsCards.filter((c) => c.column === 3);
		}

		setPinnedSettings(_pinned);
		setSettingsCards([column1, column2, column3]);
	}, [searchTerm, profile?.pinnedSettings, spaceActions]);

	useEffect(() => {
		let _spaceActions: IAdministrateButton[] = [];
		if (activeSpace) {
			if (activeSpace.isDeactivated) {
				_spaceActions.push({
					icon: EDefaultIconSet.Play,
					key: 'activate',
					keywords: [],
					title: t('Activate Space'),
					subtitle: t('activateSubtitle'),
					iconColor: theme.success
				});
			} else {
				_spaceActions.push({
					icon: EDefaultIconSet.Pause,
					key: 'deactivate',
					keywords: [],
					title: t('Deactivate Space'),
					subtitle: t('deactivateSubtitle'),
					iconColor: theme.danger
				});
			}
			_spaceActions.push({
				icon: EDefaultIconSet.DeleteSpace,
				key: 'delete',
				keywords: [],
				title: t('Delete Space'),
				subtitle: t('deleteSubtitle'),
				iconColor: theme.danger
			});
		}
		setSpaceActions(_spaceActions);
	}, [activeSpace]);

	useEffect(() => {
		let counter = 0;

		if (supportRequests && activeSpace) {
			counter = supportRequests.filter((sr) => sr.spaceId === activeSpace.spaceId && sr.status === 'open').length;
		}

		setUnreadSupportRequests(counter);
	}, [activeSpace, supportRequests]);

	const _renderActionButton = (
		key: string,
		icon: string,
		label?: string,
		isLoading?: boolean,
		color?: string,
		iconColor?: string,
		badge?: number
	) => {
		return (
			<View style={{ width: '46%', flexDirection: 'row', justifyContent: 'center' }}>
				<RoundButton
					testID={`${TESTIDPREFIX}_button_${key}`}
					title={label}
					icon={icon}
					onPress={isEmptyString(key) ? undefined : () => _handlePress(key)}
					isLoading={isLoading}
					isStacked
					color={color}
					iconColor={iconColor}
					badge={badge}
				/>
			</View>
		);
	};

	const _getBadgeByKey = (key) => {
		switch (key) {
			case 'supportRequests':
				return unreadSupportRequests;
			default:
				return undefined;
		}
	};

	const _getIsPinAllowedByKey = (key) => {
		switch (key) {
			case 'activate':
			case 'deactivate':
			case 'delete':
				return false;
			default:
				return true;
		}
	};

	const _pinSettings = async (key: string) => {
		if (profile) {
			setIsPinLoading(true);
			let keys = profile.pinnedSettings?.split(',') ?? [];
			const foundIndex = keys.findIndex((k) => k === key);
			if (foundIndex > -1) {
				keys?.splice(foundIndex, 1);
			} else {
				keys?.push(key);
			}
			const res = await updateUser({
				user: {
					pinnedSettings: keys?.join(',') ?? []
				},
				noToast: true
			});
			setIsPinLoading(false);
		}
	};

	const _renderNewActionButton = (
		key: string,
		icon: string,
		label?: string,
		subtitle?: string,
		isLoading?: boolean,
		color?: string,
		iconColor?: string,
		badge?: number
	) => {
		return (
			<ChildButton
				testID={`${TESTIDPREFIX}_button_${key}`}
				key={`${TESTIDPREFIX}_button_${key}`}
				style={{ flexDirection: 'row', marginVertical: 10, paddingHorizontal: IS_WEB ? hsInnerPadding : hsInnerPadding / 2 }}
				onPress={isEmptyString(key) ? undefined : () => _handlePress(key)}
			>
				<View>
					<Icon
						containerStyle={{ paddingRight: 10 }}
						testID={`${TESTIDPREFIX}_button_icon_${key}`}
						color={iconColor ?? theme.primary}
						name={icon}
						size={25}
					/>
					<TabbarBadge top={-5} right={0} value={_getBadgeByKey(key)} size={'sm'} />
				</View>
				<View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignSelf: 'center' }}>
					<View style={{ justifyContent: 'flex-start', flex: 1, marginLeft: 2, marginRight: 5 }}>
						<H3 style={{ marginBottom: subtitle && subtitle?.length > 0 ? 5 : 0 }}>{label}</H3>
						{subtitle && subtitle?.length > 0 && <Text adjustsFontSizeToFit>{subtitle}</Text>}
					</View>
					{_getIsPinAllowedByKey(key) && (
						<RoundButton
							onPress={isEmptyString(key) ? undefined : () => _pinSettings(key)}
							icon={EDefaultIconSet.Pin}
							testID={`${TESTIDPREFIX}_button_pin_${key}`}
							size="xs"
							alignSelf="center"
							isLoading={isPinLoading}
							iconColor={
								pinnedSettings.find((e) => e.key.includes(key))
									? theme.background ?? theme.contentBackgroundColor
									: theme.gray
							}
							color={theme.gray}
							isAdministrateButton={pinnedSettings.find((e) => e.key.includes(key)) ? true : false}
							isAdministratePinButton={pinnedSettings.find((e) => e.key.includes(key)) ? false : true}
						/>
					)}
				</View>
			</ChildButton>
		);
	};

	const _updateSpace = async (data) => {
		if (activeSpace) {
			setIsLoading(true);
			if (data.isDeleted) {
				await emitToMultiSpaceBackend({
					event: 'leaveSpace',
					data: {
						spaceId: activeSpace?.spaceId
					}
				});
			}

			await updateSpace({ data: { title: activeSpace.title, id: activeSpace.id, spaceId: activeSpace.spaceId, ...data } });
			if (data.isDeleted) {
				await leaveSpace({});

				if (navigation.canGoBack()) {
					navigation.dispatch(StackActions.popToTop());
				}

				navigation.dispatch(
					StackActions.replace(!PRESET_SPACEIDS || PRESET_SPACEIDS.length > 1 ? ERoutes.SpaceSelect : ERoutes.SpaceSummary)
				);
			}
			setIsLoading(false);
		}
	};

	const _handlePress = (key: string) => {
		switch (key) {
			case 'changelog':
				navigation.navigate(ERoutes.Changelogs, { spaceId: activeSpace?.spaceId });
				break;
			case 'reports':
				navigation.navigate(ERoutes.Reports, { spaceId: activeSpace?.spaceId });
				break;
			case 'statistics':
				navigation.navigate(ERoutes.Statistics, { spaceId: activeSpace?.spaceId });
				break;
			case 'token':
				navigation.navigate(ERoutes.ApiToken, { spaceId: activeSpace?.spaceId });
				break;
			case 'matchings':
				navigation.navigate(ERoutes.Matchings, { spaceId: activeSpace?.spaceId });
				break;
			case 'ticketSettings':
				navigation.navigate(ERoutes.TicketSettings, { spaceId: activeSpace?.spaceId });
				break;
			case 'tickets':
				navigation.navigate(ERoutes.TicketList, { spaceId: activeSpace?.spaceId });
				break;
			case 'selfServiceSettings':
				navigation.navigate(ERoutes.SelfServiceSettings, { spaceId: activeSpace?.spaceId });
				break;
			case 'meetingSettings':
				navigation.navigate(ERoutes.MeetingSettings, { spaceId: activeSpace?.spaceId });
				break;
			case 'meetingTables':
				navigation.navigate(ERoutes.MeetingTableList, { spaceId: activeSpace?.spaceId });
				break;
			case 'scheduleFields':
				navigation.navigate(ERoutes.ContentTypeFields, { spaceId: activeSpace?.spaceId, type: 'schedule' });
				break;
			case 'expoFields':
				navigation.navigate(ERoutes.ContentTypeFields, { spaceId: activeSpace?.spaceId, type: 'expo' });
				break;
			case 'speakerFields':
				navigation.navigate(ERoutes.ContentTypeFields, { spaceId: activeSpace?.spaceId, type: 'speaker' });
				break;
			case 'mediaItemFields':
				navigation.navigate(ERoutes.ContentTypeFields, { spaceId: activeSpace?.spaceId, type: 'mediaitem' });
				break;
			case 'features':
				navigation.navigate(ERoutes.Features, { spaceId: activeSpace?.spaceId });
				break;
			case 'mailsettings':
				navigation.navigate(ERoutes.MailSettings, { spaceId: activeSpace?.spaceId });
				break;
			case 'mailhistory':
				navigation.navigate(ERoutes.MailHistory, { spaceId: activeSpace?.spaceId });
				break;
			case 'mailtemplates':
				navigation.navigate(ERoutes.MailTemplateList, { spaceId: activeSpace?.spaceId });
				break;
			case 'attendees':
				navigation.navigate(ERoutes.AttendeesList, { spaceId: activeSpace?.spaceId, noSponsors: true, key: '' });
				break;
			case 'spaceRegistration':
				navigation.navigate(ERoutes.SpaceRegistrationFields, { spaceId: activeSpace?.spaceId });
				break;
			case 'editspace':
				navigation.navigate(ERoutes.EditSpace, { spaceId: activeSpace?.spaceId });
				break;
			case 'editspacedesign':
				navigation.navigate(ERoutes.EditSpaceDesign, { spaceId: activeSpace?.spaceId });
				break;
			case 'editspacetexts':
				navigation.navigate(ERoutes.EditSpaceTexts, { spaceId: activeSpace?.spaceId });
				break;
			case 'editspacesponsors':
				navigation.navigate(ERoutes.EditSpaceSponsors, { spaceId: activeSpace?.spaceId });
				break;
			case 'push':
				navigation.navigate(ERoutes.PushNotificationList, { spaceId: activeSpace?.spaceId });
				break;
			case 'allBookings':
				navigation.navigate(ERoutes.BookingList, { spaceId: activeSpace?.spaceId });
				break;
			case 'sendMail':
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					entryPoint: 'template'
				});
				break;
			case 'resetAttendePw':
				const _templates = content.mailtemplates.filter((e) => e.spaceId === activeSpace?.spaceId);
				let hasPWRecoveryTemplate = _templates.find((e) => e.key === 'passwordRecovery');
				if (!hasPWRecoveryTemplate) {
					hasPWRecoveryTemplate = _getPasswordResetEmail();
				}
				navigation.navigate(ERoutes.SendMail, {
					spaceId: activeSpace?.spaceId,
					contentType: 'attendee',
					template: hasPWRecoveryTemplate,
					entryPoint: 'receivers',
					noSponsors: true
				});
				break;
			case 'qrcode':
				setIsQRModalVisible(true);
				break;
			case 'deactivate':
				showAlert({
					title: t('Deactivate Space'),
					message: t('deactivateSpaceSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('Deactivate Space'),
							style: 'destructive',
							onPress: async () => {
								_updateSpace({ isDeactivated: true });
							}
						}
					]
				});
				break;
			case 'activate':
				showAlert({
					title: t('Activate Space'),
					message: t('activateSpaceSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('Activate Space'),
							style: 'destructive',
							onPress: async () => {
								_updateSpace({ isDeactivated: false });
							}
						}
					]
				});
				break;
			case 'delete':
				showAlert({
					title: t('Delete Space'),
					message: t('deleteSpaceSubtitle'),
					buttons: [
						{
							text: t('Cancel'),
							style: 'cancel'
						},
						{
							text: t('Delete Space'),
							style: 'destructive',
							onPress: async () => {
								_updateSpace({ isDeleted: true });
							}
						}
					]
				});
				break;
			case 'administrators':
				navigation.navigate(ERoutes.SpaceAdmins, {
					spaceId: activeSpace?.spaceId,
					state: 'Administrators'
				});
				break;
			case 'moderators':
				navigation.navigate(ERoutes.SpaceAdmins, {
					spaceId: activeSpace?.spaceId,
					state: 'Moderators'
				});
				break;
			case 'invite':
				if (activeSpace?.spaceId) {
					if (Platform.OS === 'android' || Platform.OS === 'ios') {
						share(
							activeSpace?.title ?? '',
							activeSpace?.title ?? '',
							getSpaceShareUrl(activeSpace?.spaceId),
							activeSpace?.description ?? ''
						);
					} else {
						showAlert({
							title: t('Invite Attendees'),
							message: t('ShareSpaceText'),
							buttons: [
								{
									text: `shareUrl_${getSpaceShareUrl(activeSpace?.spaceId)}`
								},
								{
									text: t('CopyLink'),
									onPress: () => addToClipboard(getSpaceShareUrl(activeSpace?.spaceId)),
									style: 'default'
								},
								{
									text: t('email'),
									onPress: () => {
										navigation.navigate(ERoutes.SendMail, {
											spaceId: activeSpace.spaceId,
											entryPoint: 'receivers',
											contentType: 'invitation'
										});
									},
									style: 'default'
								}
							]
						});
					}
				}
				break;
			case 'supportRequests':
				navigation.navigate(ERoutes.SupportRequestList, { spaceId: activeSpace?.spaceId });
				break;
			case 'welcometext':
				navigation.navigate(ERoutes.WelcomeEdit, { spaceId: activeSpace?.spaceId });
				break;
			case 'editlandingpage':
				navigation.navigate(ERoutes.EditLandingpage, { spaceId: activeSpace?.spaceId });
				break;
			case 'webhooks':
				navigation.navigate(ERoutes.Webhooks, { spaceId: activeSpace?.spaceId });
				break;
			default:
				break;
		}
	};

	const _renderDeactivated = () => {
		if (activeSpace?.isDeactivated) {
			return (
				<HSCard style={{ alignItems: 'center' }}>
					{_renderActionButton('', EDefaultIconSet.Warning, undefined, isLoading, theme.danger)}
					<H1 center style={{ color: theme.danger, marginBottom: 5 }}>
						{t('SpaceNotActiveTitle')}
					</H1>
					<H2 center style={{ color: theme.danger }}>
						{t('SpaceNotActiveSubtitle')}
					</H2>
					{_renderActionButton('activate', EDefaultIconSet.Play, t('Activate Space'), isLoading)}
				</HSCard>
			);
		}
		return null;
	};

	const _renderCard = (card: IAdministrateData, index: number) => {
		return (
			<View
				key={`card_${card.cardTitle}_${index}`}
				style={{
					paddingLeft: !isTabletOrMobile && index % 3 !== 0 ? hsInnerPadding : 0,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				<HSCard
					style={{
						justifyContent: 'flex-start',
						alignSelf: 'center',
						flex: 1,
						paddingBottom: hsInnerPadding,
						paddingHorizontal: 10,
						marginBottom: hsInnerPadding
					}}
				>
					<H1
						center
						style={{
							flex: 1,
							marginBottom: hsTextBottomMarginSmall
						}}
					>
						{card.cardTitle}
					</H1>
					{card.items.map((item) => {
						if (item.key === 'delete') {
							return _renderNewActionButton(
								item.key,
								item.icon,
								item.title,
								item.subtitle,
								isLoading,
								item.color,
								item.iconColor
							);
						}
						if (item.key === 'activate' || item.key === 'deactivate') {
							return _renderNewActionButton(
								item.key,
								item.icon,
								item.title,
								item.subtitle,
								isLoading,
								item.color,
								item.iconColor
							);
						}
						if (item.key === 'editspacetexts' && !__DEV__) {
							return null;
						}
						return _renderNewActionButton(
							item.key,
							item.icon,
							item.title,
							item.subtitle,
							isLoading,
							item.color,
							item.iconColor
						);
					})}
				</HSCard>
			</View>
		);
	};

	const _renderContent = () => {
		return (
			<MasonryList
				testID={`${TESTIDPREFIX}_scrollview`}
				data={settingsCards}
				renderItem={({ item, i }) => {
					return item.map((a) => _renderCard(a, i));
				}}
				numColumns={isTabletOrMobile ? 1 : 3}
				keyExtractor={(item, index) => `${TESTIDPREFIX}_${item.id}_${index}`}
				ListHeaderComponent={_renderHeader()}
			/>
		);
	};

	const _renderHeader = () => {
		return (
			<View style={{ width: screenWidth, alignSelf: 'center' }}>
				{_renderDeactivated()}
				<View
					style={{ width: '100%', flexDirection: isTabletOrMobile ? 'column-reverse' : 'row', justifyContent: 'space-between' }}
				>
					<HSCard
						style={
							isTabletOrMobile
								? {
										flex: 1,
										paddingBottom: isTabletOrMobile && searchTerm.length >= 3 ? 5 : hsInnerPadding,
										flexDirection: !isTabletOrMobile ? 'row' : 'column'
								  }
								: {
										width: '100%',
										paddingBottom: isTabletOrMobile && searchTerm.length >= 3 ? 5 : hsInnerPadding,
										flexDirection: !isTabletOrMobile ? 'row' : 'column'
								  }
						}
					>
						<SearchBar
							testID={`${TESTIDPREFIX}_searchbar`}
							value={searchTerm}
							onChange={(val) => setSearchTerm(val)}
							containerStyle={{ flex: 1 }}
						/>
						{searchTerm.length >= 3 && (
							<View
								style={
									!isTabletOrMobile
										? {
												justifyContent: 'center',
												marginLeft: 10
										  }
										: { marginLeft: -5, marginTop: 5 }
								}
							>
								<RoundButton
									testID={`${TESTIDPREFIX}_button_searchinforum`}
									title={t('searchInForum')}
									icon={EDefaultIconSet.Search}
									onPress={() => openURL(`https://forum.hellospaces.de/search?q=${encodeURIComponent(searchTerm)}`)}
									size={!isTabletOrMobile ? 'sm' : 'xs'}
									noMargin={!isTabletOrMobile}
								/>
							</View>
						)}
					</HSCard>
				</View>
				{(settingsCards?.length === 0 ||
					(settingsCards[0]?.length ?? 0) + (settingsCards[1]?.length ?? 0) + (settingsCards[2]?.length ?? 0) === 0) && (
					<View style={{ width: '100%', alignSelf: 'center' }}>
						<NoData type="NoSearchResult" />
					</View>
				)}
			</View>
		);
	};

	return (
		<ScreenContainer isProtectedRoute handleBackPress contentKey="administrate">
			<View
				style={{
					flex: 1,
					paddingHorizontal: EHorizontalScreenPadding.Wide,
					paddingTop: hsTopScreenPadding,
					width: '100%',
					alignSelf: 'center'
				}}
			>
				{_renderContent()}
			</View>
			<QRCodeShowCodeModal
				isVisible={isQRModalVisible}
				onClose={() => setIsQRModalVisible(false)}
				title={activeSpace?.title}
				value={getSpaceShareUrl(activeSpace?.spaceId)}
			/>
		</ScreenContainer>
	);
};

export const AdministrateScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('Administrate')} />
			<NavigationHeaderHelpButton />
		</NavigationHeader>
	);
};

export const AdministrateScreenRootHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderMenuButton />
			<NavigationHeaderTitle title={i18next.t('Administrate')} />
			<NavigationHeaderHelpButton />
		</NavigationHeader>
	);
};
