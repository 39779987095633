import { RouteProp } from '@react-navigation/core';
import { NativeStackHeaderProps, NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RoundButton } from 'components/Button';
import { HSCard } from 'components/Card';
import {
	NoSpaceStackParamList,
	NavigationHeader,
	NavigationHeaderBackButton,
	NavigationHeaderPlaceholder,
	NavigationHeaderTitle
} from 'components/Navigation';
import { ERoutes } from 'components/Navigation/routes';
import { EHorizontalScreenPadding, ScreenContainer } from 'components/ScreenContainer';
import { Spinner } from 'components/Spinner';
import { H1, H3 } from 'components/Text';
import { PRESET_SPACEIDS } from 'config/envConstants';
import { EDefaultIconSet } from 'helper';
import { showToast } from 'helper/toast';
import { useSpace } from 'hooks/useSpace';
import { useTheme } from 'hooks/useTheme';
import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { Dispatch, IRootState, useRematchDispatch } from 'rematch/store';
import * as RootNavigation from '../../../RootNavigation';

type ScreenRouteProps = RouteProp<NoSpaceStackParamList, ERoutes.ConfirmAccount>;
type ScreenNavigationProp = NativeStackNavigationProp<NoSpaceStackParamList, ERoutes.ConfirmAccount>;
type RouteParams = NoSpaceStackParamList[ERoutes.ConfirmAccount];

type Props = {
	route: ScreenRouteProps;
	navigation: ScreenNavigationProp;
};

export const ConfirmAccountScreen = ({ route, navigation }: Props) => {
	const { activeSpace } = useSpace();
	const profile = useSelector((store: IRootState) => store.auth.profile);
	const confirmAccount = useRematchDispatch((dispatch: Dispatch) => dispatch.auth.confirmAccount);
	const { theme } = useTheme();
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);

	useEffect(() => {
		if (profile?.isUserConfirmed) {
			showToast('success', t('already.confirmed'));
			setIsConfirmed(true);
			if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
			}
		} else {
			setIsConfirmed(false);
			_confirmAccount();
		}
	}, []);

	useEffect(() => {
		if (profile?.isUserConfirmed) {
			setIsConfirmed(true);
		}
	}, [profile]);

	const _confirmAccount = async () => {
		const res = await confirmAccount({ code: route.params.code });
		if (res) {
			if (profile) profile.isUserConfirmed = true;
			setIsConfirmed(true);
			if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
				navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
			}
		} else {
			setIsConfirmed(false);
			setHasError(true);
		}
	};

	return (
		<ScreenContainer>
			<View style={{ flex: 1, paddingHorizontal: EHorizontalScreenPadding.Small, justifyContent: 'center' }}>
				<HSCard style={{ alignItems: 'center' }}>
					{isConfirmed ? (
						<View>
							<H1 center>{`${t('AccountConfirmed')}!`}</H1>
							<RoundButton
								isFloatingButton
								alignSelf="flex-end"
								testID={`confirmaccount_button_continue`}
								title={t('Confirm and continue')}
								icon={EDefaultIconSet.Save}
								textColor={theme.text}
								isOutline
								onPress={() => {
									if (navigation.canGoBack()) {
										navigation.goBack();
									}
									if (!activeSpace && PRESET_SPACEIDS && PRESET_SPACEIDS.length === 1) {
										navigation.navigate(ERoutes.SpaceSummary, { spaceId: PRESET_SPACEIDS[0] });
									}
									RootNavigation.replace(ERoutes.SpaceSelect);
								}}
							/>
						</View>
					) : hasError ? (
						<View>
							<H3 center>{`${t('AnErrorOccurred')}.\n${t('ConfirmationErrorOccurred')}`}</H3>
						</View>
					) : (
						<View>
							<H3 center>{t('ConfirmingAccount')}</H3>
							<Spinner />
						</View>
					)}
				</HSCard>
			</View>
		</ScreenContainer>
	);
};

export const ConfirmAccountScreenHeader = (props: NativeStackHeaderProps) => {
	const { navigation, route } = props;
	const params = route.params as RouteParams;

	return (
		<NavigationHeader>
			<NavigationHeaderBackButton />
			<NavigationHeaderTitle title={i18next.t('ConfirmAccount')} />
			<NavigationHeaderPlaceholder />
		</NavigationHeader>
	);
};
