import { possiblePlayableMediaTypes } from 'config/interfaces';
import { getLabelForStreamType, getValidURL, isEmptyString } from 'helper';
import i18next from 'i18next';
import * as Yup from 'yup';
import { getRequiredError, getWebsiteError } from './errors';

export const getStreamFormSchema = (item) => {
	if (item.youTubeUrl && !isEmptyString(item.youTubeUrl)) {
		item.youTubeUrl = getValidURL(item.youTubeUrl);
	}

	if (item.vimeoUrl && !isEmptyString(item.vimeoUrl)) {
		item.vimeoUrl = getValidURL(item.vimeoUrl);
	}

	if (item.iFrameUrl && !isEmptyString(item.iFrameUrl)) {
		item.iFrameUrl = getValidURL(item.iFrameUrl);
	}

	if (item.externalMeetingUrl && !isEmptyString(item.externalMeetingUrl)) {
		item.externalMeetingUrl = getValidURL(item.externalMeetingUrl);
	}

	return Yup.object().shape({
		title: Yup.string()
			.required(getRequiredError(i18next.t('Title')))
			.nullable(),
		type: Yup.string()
			.required(getRequiredError(i18next.t('Type')))
			.oneOf(possiblePlayableMediaTypes, i18next.t('Wrong Stream Type'))
			.nullable(),
		youTubeUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'youtube') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.youTubeUrl);
				}
				return true;
			})
			.nullable(),
		vimeoUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'vimeo') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.vimeoUrl);
				}
				return true;
			})
			.nullable(),
		iFrameUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'iFrame') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.iFrameUrl);
				}
				return true;
			})
			.nullable(),
		externalMeetingUrl: Yup.string().url(getWebsiteError()).nullable()
	});
};

export const getAddStreamFormSchema = (item) => {
	return Yup.object().shape({
		title:
			item.streamType === 'upload'
				? Yup.string()
				  .nullable()
				: Yup.string()
						.required(getRequiredError(i18next.t('Title')))
						.nullable(),
		youTubeUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'youtube') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.youTubeUrl);
				}
				return true;
			})
			.nullable(),
		vimeoUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'vimeo') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.vimeoUrl);
				}
				return true;
			})
			.nullable(),
		iFrameUrl: Yup.string()
			.url(getWebsiteError())
			.test('error', i18next.t('StreamFieldRequiredForType').replace('%TYPE%', getLabelForStreamType(item.streamType)), async () => {
				if (item.streamType === 'iFrame') {
					const schema = Yup.string().required().url().nullable();
					return await schema.isValid(item.iFrameUrl);
				}
				return true;
			})
			.nullable(),
		externalMeetingUrl: Yup.string().url(getWebsiteError()).nullable()
	});
};
