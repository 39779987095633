import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { NavigationHeaderIconButton } from './NavigationHeaderIconButton';
import { useTheme } from 'hooks/useTheme';
import { EDefaultIconSet } from 'helper/icon';
import { NavigationHeaderPlaceholder } from './NavigationHeaderPlaceholder';
import { useSpace } from 'hooks/useSpace';

interface INavigationHeaderEditButton {
	route: string;
	id: number | string;
	paramType?: string;
}

export const NavigationHeaderEditButton = (props: INavigationHeaderEditButton) => {
	const { route, id, paramType } = props;
	const { activeSpace, iAmSpaceAdmin, iAmSpaceModerator } = useSpace();
	const navigation = useNavigation();
	const { theme } = useTheme();

	if (iAmSpaceAdmin || iAmSpaceModerator) {
		let primary = theme.primary.length === 7 ? theme.primary + 'ff' : theme.primary;
		let bg = theme.background.length === 7 ? theme.background + 'ff' : theme.background;
		let color = primary === bg ? theme.navigationTextColor : theme.navbarButton;

		const params = {
			spaceId: activeSpace?.spaceId
		};

		if (typeof id === 'string') {
			params[paramType ?? 'key'] = id;
		} else {
			params['id'] = id;
		}

		return (
			<NavigationHeaderIconButton
				testID="header_button_edit"
				icon={EDefaultIconSet.Edit}
				color={color}
				onPress={() => {
					navigation.navigate(route, params);
				}}
			/>
		);
	}

	return <NavigationHeaderPlaceholder />;
};
