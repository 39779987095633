import i18next from 'i18next';

export const getRequiredError = (fieldLabel: string) => {
	return i18next.t('FormFieldRequiredError').replace('%LABEL%', fieldLabel);
};

export const getEmailError = (fieldLabel: string) => {
	return i18next.t('FormFieldEmailError').replace('%LABEL%', fieldLabel);
};

export const getMinError = (minLength: number) => {
	return i18next.t('FormFieldMinLengthError').replace('%COUNT%', minLength.toString());
};

export const getMaxError = (minLength: number) => {
	return i18next.t('FormFieldMaxLengthError').replace('%COUNT%', minLength.toString());
};

export const getMinOptionsError = (minCount: number) => {
	return i18next.t('FormFieldMinOptionsError').replace('%COUNT%', minCount.toString());
};

export const getMaxOptionsError = (maxCount: number) => {
	return i18next.t('FormFieldMaxOptionsError').replace('%COUNT%', maxCount.toString());
};

export const getMinNumberError = (value: number) => {
	return i18next.t('FormFieldMinNumberError').replace('%VALUE%', value.toString());
};

export const getMaxNumberError = (value: number) => {
	return i18next.t('FormFieldMaxNumberError').replace('%VALUE%', value.toString());
};

export const getWebsiteError = (url?: string) => {
	if (url?.startsWith('http://') || url?.startsWith('https://')) return i18next.t('FormFieldWebsiteError');
	return i18next.t('Invalid URL Subtitle');
};
